import { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import pluralize from "pluralize";
import {
  AlphaDesignTheme,
  AlphaDesignThemeContextProvider,
  Typography,
  useThemeTokens,
  SelectMenu,
  SelectOption,
} from "@alphasights/alphadesign-components";
import { formatTimeInHoursAndMinutes } from "helpers/interactionHelpers";

export const WorkRequestMaxDuration = ({
  value,
  label = "Maximum task duration",
  onChange = (value) => {},
  placeholder = "Select option",
  showCreditsDifference = false,
  allowOnlyIncrease = false,
  labelWithRequired = false,
  showCallGuideOption = true,
  errorMessage = null,
  disabled = false,
  minValue: minValueInput = 0,
  size = "medium",
}) => {
  const initialValue = value;
  const minValue = minValueInput || initialValue ? Math.max(minValueInput ?? 0, initialValue ?? 0) : 0;

  const labelForTaskDuration = (value) => {
    if (value === -1) {
      return "0 minutes (Call Guide)";
    }

    let label = formatTimeInHoursAndMinutes(value);

    if (initialValue && showCreditsDifference) {
      const diff = value - initialValue;
      const credits = Math.round((diff / 60 + Number.EPSILON) * 100) / 100;

      if (diff !== 0) {
        const creditsLabel = ` (${diff > 0 ? "+" : ""}${credits} ${pluralize("credit", Math.abs(credits))})`;

        label = (
          <Typography display="flex" flexDirection="row" size="small">
            {label}&nbsp;
            <Typography color="assistive" size="smal">
              {creditsLabel}
            </Typography>{" "}
          </Typography>
        );
      }
    }

    return <Typography key={value}>{label}</Typography>;
  };

  const halfHourTaskDurations = Array.from({ length: 8 }, (x, i) => ({
    value: (i + 1) * 30,
    label: labelForTaskDuration((i + 1) * 30),
  }));

  const allTasksDuration = [
    { value: 15, label: labelForTaskDuration(15) },
    { value: 45, label: labelForTaskDuration(45) },
    ...halfHourTaskDurations,
  ].sort(function (a, b) {
    return a.value - b.value;
  });

  if (showCallGuideOption) {
    allTasksDuration.push({ value: -1, label: labelForTaskDuration(-1) });
  }

  const [selectedTaskDuration, setSelectedTaskDuration] = useState(value === 0 ? -1 : value);

  const selectValue = (value) => {
    setSelectedTaskDuration(value);
    onChange(value !== -1 ? value : 0);
  };

  useEffect(() => {
    setSelectedTaskDuration(value === 0 ? -1 : value);
  }, [value]);

  const { spacing, color } = useThemeTokens();

  const style = {
    borderColor: errorMessage ? color.border.danger : color.border.neutral.default,
    cursor: disabled ? "not-allowed" : "pointer",
  };

  return (
    <AlphaDesignThemeContextProvider theme={AlphaDesignTheme.portal}>
      {label && (
        <x.div pb={spacing.inner.base}>
          <Typography color="assistive" component="span">
            {label}
          </Typography>
          {labelWithRequired && (
            <Typography color="danger" component="span">
              {" *"}
            </Typography>
          )}
        </x.div>
      )}
      <SelectMenu
        style={style}
        dataAttributes={{ "data-testid": "work-request-maximum-duration-select" }}
        value={selectedTaskDuration}
        placeholder={placeholder}
        children={allTasksDuration}
        onChange={selectValue}
        error={errorMessage}
        renderSelectedValue={(item) => labelForTaskDuration(item.value)}
        disabled={disabled}
        size={size}
        disableInput
      >
        {allTasksDuration
          .filter((item) => !allowOnlyIncrease || item.value >= minValue)
          .map((option) => (
            <x.div key={option.value} data-testid={`work-request-maximum-duration-select-option-${option.value}`}>
              <SelectOption key={option.value} {...option} />
            </x.div>
          ))}
      </SelectMenu>
    </AlphaDesignThemeContextProvider>
  );
};
