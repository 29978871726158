import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = ({ newQuestion = false }: { newQuestion?: boolean } = {}) => {
  const { spacing, color } = useThemeTokens();

  const styles = {
    question: {
      display: "flex",
      flexDirection: "column",
    },
    highlightStyle: {
      display: "inline-block",
      width: "fit-content",
      bg: {
        hover: color.background.highlightBase,
      },
    },
    extendedHighlightStyle: {
      display: "inline-block",
      width: "fit-content",
      bg: color.background.highlightBase,
    },
    questionText: {
      pb: spacing.inner.base03,
    },
    questionLoading: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
      padding: spacing.inner.base03,
    },
    answerWrapper: {
      display: "flex",
      gap: spacing.inner.base04,
      padding: spacing.inner.base,
    },
    quote: {
      borderLeft: "2px solid rgba(8, 135, 95, 0.08)",
      fontStyle: "italic",
      paddingLeft: spacing.inner.base02,
    },
    questionWrapper: {
      w: "100%",
      padding: `${spacing.inner.base03} ${newQuestion ? spacing.inner.base06 : spacing.inner.base03}`,
    },
    questionContent: {
      display: "flex",
      flexDirection: "column",
      padding: spacing.inner.base03,
      gap: spacing.inner.base04,
    },
    qaWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base05,
    },
    quotesWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base02,
    },
    errorWrapper: {
      w: "fit-content",
      margin: `${spacing.inner.base10} auto`,
    },
    errorAlert: {
      pt: spacing.inner.base,
      pb: spacing.inner.base03,
      px: spacing.inner.base03,
    },
    loadingMessageWrapper: {
      display: "flex",
      gap: spacing.inner.base04,
      alignItems: "center",
      py: spacing.inner.base04,
    },
    skeletonWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base02,
      pl: spacing.inner.base08,
    },
    suggestions: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base,
    },
    suggestionLabel: {
      padding: spacing.inner.base02,
      color: color.text.assistive,
    },
    suggestionItem: {
      display: "flex",
      gap: spacing.inner.base02,
      py: spacing.inner.base02,
      px: spacing.inner.base03,
      cursor: "pointer",
    },
    addSuggestion: {
      color: color.icon.secondary,
    },
    purple: {
      color: color.icon.ai,
    },
  };

  return styles;
};
