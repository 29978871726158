import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  margin: 0 auto;
`;

export const StyledBulletPoint = styled(x.ul)(() => {
  const { font, spacing } = useThemeTokens();
  return {
    listStyleType: "disc",
    fontSize: font.size["01"],
    margin: `${spacing.inner.base03} 0 ${spacing.inner.base02} ${spacing.inner.base04}`,
  };
});

export const StyledPoint = styled(Typography)(() => {
  const { spacing } = useThemeTokens();
  return {
    paddingBottom: spacing.inner.base,
  };
});

export const StyledQuote = styled(Typography)(() => {
  const { color, spacing, shape } = useThemeTokens();
  return {
    borderLeft: `${shape.border.width.sm} solid`,
    borderColor: color.border.strong._,
    marginLeft: spacing.inner.base02,
    marginBottom: spacing.inner.base04,
    fontStyle: "italic",
    paddingLeft: spacing.inner.base02,
  };
});

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)(() => {
  const { spacing } = useThemeTokens();
  return {
    marginBottom: spacing.inner.base02,
  };
});
