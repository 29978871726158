import { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, TabItem, Tabs } from "@alphasights/alphadesign-components";
import { DotLabel } from "pages/AlphaNowPage/primers/components";
import SegmentationTab from "./SegmentationTab";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { withNumberParam } from "helpers/tabHelper";

type SegmentationTabsProps = {
  currency: string;
  segments: Segment[];
  segmentLabels: string[];
  chartColors: string[];
  onClick: (index: number) => void;
  selectedChartSegment: number | null;
};

const SegmentationTabs = ({
  currency,
  segments,
  segmentLabels,
  chartColors,
  onClick,
  selectedChartSegment,
}: SegmentationTabsProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { isAccessible } = usePrimersStore();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    setSelectedTab(selectedChartSegment ?? 0);
  }, [selectedChartSegment]);

  useEffect(() => {
    setSelectedTab(0);
  }, [segments]);

  return (
    <x.div pt={inner.base04}>
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <Tabs
          data-testid="marketPrimerSegmentationTabs"
          colorVariant="primary"
          index={selectedTab || 0}
          onChange={withNumberParam((index) => {
            handleTabChange(index);
            onClick(index);
          })}
        >
          {segmentLabels.map((label, index) => (
            <TabItem key={label} label={<DotLabel dotColor={chartColors[index]} label={label} />} />
          ))}
        </Tabs>
      </SensitiveDataContainer>
      <x.div display="flex" gap="20px" flexDirection="column">
        <SegmentationTab currency={currency} segment={segments[selectedTab < segments.length ? selectedTab : 0]} />
      </x.div>
    </x.div>
  );
};

export default SegmentationTabs;
