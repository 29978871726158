import { useMemo } from "react";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import * as fns from "date-fns";
import { Navigate } from "react-big-calendar";

const Directions = {
  FORWARD: 1,
  BACKWARD: -1,
};

export const isWeekend = (date, sundayWorkday) => {
  if (sundayWorkday) {
    return fns.isFriday(date) || fns.isSaturday(date);
  } else {
    return fns.isSaturday(date) || fns.isSunday(date);
  }
};

export const findValidBaseDate = (baseDate, showWeekend, sundayWorkday, direction = Directions.FORWARD) => {
  while (!showWeekend && isWeekend(baseDate, sundayWorkday)) baseDate = fns.addDays(baseDate, direction);
  return baseDate;
};

const collectDays = (baseDate, count, showWeekend, sundayWorkday, direction) => {
  const days = [];
  let date = baseDate;

  while (days.length < count) {
    date = fns.addDays(date, direction);

    if (isWeekend(date, sundayWorkday)) {
      if (showWeekend) days.push(date);
    } else days.push(date);
  }

  return direction === -1 ? days.reverse() : days;
};

const range = (dayCount) => (date, showWeekend, sundayWorkday) => {
  return [date, ...collectDays(date, dayCount, showWeekend, sundayWorkday, Directions.FORWARD)].slice(0, dayCount);
};

const navigate = (dayCount) => (date, action, { showWeekend, sundayWorkday }) => {
  switch (action) {
    case Navigate.PREVIOUS: {
      let baseDate = fns.subDays(date, dayCount);
      return findValidBaseDate(baseDate, showWeekend, sundayWorkday, Directions.BACKWARD);
    }

    case Navigate.NEXT: {
      let baseDate = fns.addDays(date, dayCount);
      return findValidBaseDate(baseDate, showWeekend, sundayWorkday, Directions.FORWARD);
    }

    default:
      return date;
  }
};

export const OneDay = ({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  showWeekend,
  sundayWorkday,
  ...props
}) => {
  const currRange = useMemo(() => OneDay.range(date, showWeekend, sundayWorkday), [date, showWeekend, sundayWorkday]);

  return (
    <TimeGridOverride
      date={date}
      eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      {...props}
    />
  );
};

OneDay.range = range(1);
OneDay.navigate = navigate(1);
OneDay.title = () => "";

export const ThreeDays = ({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  showWeekend,
  sundayWorkday,
  ...props
}) => {
  const currRange = useMemo(() => ThreeDays.range(date, showWeekend, sundayWorkday), [
    date,
    showWeekend,
    sundayWorkday,
  ]);

  return (
    <TimeGridOverride
      date={date}
      eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      {...props}
    />
  );
};

ThreeDays.range = range(3);
ThreeDays.navigate = navigate(3);
ThreeDays.title = () => "";

export const Week = ({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  showWeekend,
  sundayWorkday,
  ...props
}) => {
  const currRange = useMemo(() => Week.range(date, showWeekend, sundayWorkday), [date, showWeekend, sundayWorkday]);

  return (
    <TimeGridOverride
      date={date}
      eventOffset={15}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      {...props}
    />
  );
};

Week.range = (date, showWeekend, sundayWorkday) => {
  const weekStartsOn = sundayWorkday ? 6 : 0;

  let start = fns.startOfWeek(date, { weekStartsOn });
  let end = fns.endOfWeek(date, { weekStartsOn });

  let range = [];

  while (fns.isBefore(start, end)) {
    range.push(start);
    start = fns.addDays(start, 1);
  }

  if (!showWeekend) range = range.slice(1, 6);

  return range;
};

Week.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return fns.subWeeks(date, 1);

    case Navigate.NEXT:
      return fns.addWeeks(date, 1);

    default:
      return date;
  }
};

Week.title = () => "";

// This class prevents calendar from centering on 'scrollToTime' every time you paginate
// while still allowing to force scroll when needed (by scrolling to a diff value)
// https://github.com/jquense/react-big-calendar/issues/1717
class TimeGridOverride extends TimeGrid {
  constructor(props) {
    super(props);
    this.lastScrollToTime = props.scrollToTime;
  }

  calculateScroll(props) {
    const firstRender = !props;
    if (firstRender) {
      super.calculateScroll();
    } else if (!fns.isEqual(props.scrollToTime, this.lastScrollToTime)) {
      super.calculateScroll(props);
      this.lastScrollToTime = props.scrollToTime;
    }
  }
}
