import { ButtonVariant, useThemeTokens } from "@alphasights/alphadesign-components";
import { css } from "@xstyled/styled-components";

export const useMessagesStyles = () => {
  const {
    color: { border: borderColor, icon, text },
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();

  const wrapper = {
    display: "flex",
    gap: inner.base04,
    flexDirection: "column",
  };

  const messageContainer = {
    display: "flex",
    gap: inner.base04,
    flexDirection: "column",
  };

  const messageContent = {
    color: text.strong._,
  };

  const messageAttachmentsContainer = {
    display: "flex",
  };

  const messageReplyContainer = {
    display: "flex",
  };

  const messageHeader = {
    display: "flex",
    justifyContent: "space-between",
  };

  const messageInfo = {
    display: "flex",
    gap: inner.base02,
  };

  const messageSender = {
    color: text.strong._,
  };

  const messageDate = {
    color: text.secondary,
  };

  const messageExtraInfo = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: inner.base02,
  };

  const messageAttachmentIcon = {
    color: icon.secondary,
  };

  const messageAttachmentCount = {
    color: text.secondary,
  };

  const attachmentContainer = {
    display: "flex",
    justifyContent: "space-between",
    border: `1px solid`,
    borderColor: borderColor.neutral.default,
    borderRadius: border.radius.small,
    alignItems: "center",
    py: inner.base02,
    px: inner.base04,
  };

  const attachmentFile = {
    display: "flex",
    gap: inner.base02,
    alignItems: "center",
    flex: 1,
    overflow: "hidden",
    justifyContent: "space-between",
  };

  const attachmentFileInfo = {
    color: text.assistive,
  };

  const attachmentsSection = {
    display: "flex",
    gap: inner.base,
    flexDirection: "column",
  };

  const attachmentsCount = {
    color: text.secondary,
  };

  const headerSlot = {
    display: "flex",
    gap: inner.base04,
  };

  const messageSettingsFields = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
  };

  const messageSettingsButton = {
    variant: "outline" as ButtonVariant,
    marginTop: inner.base06,
  };

  const titleContent = {
    display: "flex",
    gap: inner.base04,
  };

  const markdownCustomStyles = css`
    padding: 0;

    & ul {
      list-style-type: disc;
    }
  `;

  return {
    wrapper,
    messageContainer,
    messageContent,
    messageReplyContainer,
    messageAttachmentsContainer,
    messageHeader,
    messageInfo,
    messageSender,
    messageDate,
    messageExtraInfo,
    messageAttachmentIcon,
    messageAttachmentCount,
    attachmentsSection,
    attachmentsCount,
    attachmentContainer,
    attachmentFile,
    attachmentFileInfo,
    headerSlot,
    messageSettingsFields,
    messageSettingsButton,
    titleContent,
    markdownCustomStyles,
  };
};
