import { useCallback, useEffect, useState } from "react";
import { Checkbox, TextField } from "@alphasights/alphadesign-components";
import * as S from "./BillingAgreementForm.styled";
import { useNavigate } from "react-router-dom";
import { sharedProjectsService } from "services/sharedProjectsService";
import { SharedProject } from "models/SharedProjects";
import { Header } from "../Header";

export const BillingAgreementForm = ({ sharedProject }: { sharedProject: SharedProject }) => {
  const { projectTitle, projectEntityName, clientEntityName, token } = sharedProject;
  const {
    loading,
    agreed,
    setAgreed,
    setFirstName,
    setLastName,
    setEmail,
    disableSubmission,
    submitForm,
  } = useBillingAgreementForm({ token });
  const navigateToSuccess = useNavigateToSuccess({ token, email: sharedProject.clientEmail });

  useEffect(
    function redirectToSuccessIfAlreadySigned() {
      if (sharedProject.signed) {
        navigateToSuccess();
      }
    },
    [navigateToSuccess, sharedProject.signed]
  );

  return (
    <S.Wrapper>
      <Header percentage={50} />
      <S.ContentWrapper>
        <S.FormWrapper>
          <S.FormContainer>
            <S.FormTitle>Billing Confirmation</S.FormTitle>
            <S.FormDisclamer>
              AlphaSights has set up a shared project on {projectTitle} between {projectEntityName} and{" "}
              {clientEntityName}. By default, {projectEntityName} will pay all fees for the shared project.
              <br />
              <br />
              If instead you would like all shared project fees to be paid by {clientEntityName} in accordance with{" "}
              {clientEntityName}'s contract with AlphaSights, please confirm this below.
            </S.FormDisclamer>
            <S.CheckboxWrapper>
              <Checkbox size="small" checked={agreed} onChange={({ target: { checked } }) => setAgreed(checked)}>
                {clientEntityName} will pay all shared projects fees for the project on {projectTitle} with{" "}
                {projectEntityName}, in accordance with {clientEntityName}'s contract with AlphaSights.
              </Checkbox>
            </S.CheckboxWrapper>
            <S.FieldsWrapper>
              <S.ClientNameWrapper>
                <TextField
                  placeholder="First name"
                  name="firstName"
                  onChange={({ target: { value } }) => setFirstName(value)}
                />
                <TextField
                  placeholder="Last name"
                  name="lastName"
                  onChange={({ target: { value } }) => setLastName(value)}
                />
              </S.ClientNameWrapper>
              <TextField
                type="email"
                placeholder="Email address"
                onChange={({ target: { value } }) => setEmail(value)}
              />
            </S.FieldsWrapper>
            <S.Button
              type="submit"
              disabled={disableSubmission}
              onClick={() => !disableSubmission && submitForm()}
              loading={loading}
            >
              Confirm
            </S.Button>
          </S.FormContainer>
        </S.FormWrapper>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

const useNavigateToSuccess = ({ token, email }: { token: string; email?: string }) => {
  const navigate = useNavigate();
  return () => navigate(`/project-sharing/${token}/success`, { state: { email }, replace: true });
};

const useBillingAgreementForm = ({ token }: { token: string }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [agreed, setAgreed] = useState(false);
  const navigateToSuccess = useNavigateToSuccess({ token, email });
  const [loading, setLoading] = useState(false);

  const submitForm = useCallback(() => {
    setLoading(true);
    sharedProjectsService
      .submitBillingAgreement(token, { firstName, lastName, email })
      .then(() => {
        navigateToSuccess();
      })
      .finally(() => setLoading(false));
  }, [email, firstName, lastName, navigateToSuccess, token]);

  return {
    loading,
    agreed,
    setAgreed,
    setFirstName,
    setLastName,
    setEmail,
    submitForm,
    disableSubmission: !firstName || !lastName || !email || !agreed || loading,
  };
};
