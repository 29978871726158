import { useCallback, useEffect, useState } from "react";
import { fetch } from "../../hooks/useApi";
import { format } from "date-fns-tz";
import { useTimezone } from "../../providers/TimezoneProvider";
import { Typography } from "@alphasights/alphadesign-components";
import { Option, Select } from "@alphasights/alphadesign-components";
import { debounce } from "lodash";

const formatTimeZone = (timeZone, mask) => {
  return format(new Date(), mask, { timeZone });
};

const formatTimeZoneLabel = (timeZone, cityName = "") => {
  const suffix = formatTimeZone(timeZone, "OOOO");
  const tz = timeZone.replace("_", " ");
  if (cityName) {
    return `${cityName} (${tz}, ${suffix})`;
  } else {
    return `${tz}, ${suffix}`;
  }
};

const defaultLoadOptions = async (inputValue = "") => {
  const res = await fetch({ url: `/api/cities/${inputValue}` });
  return res.json();
};

export const TimezoneSearchInput = ({
  loadOptions = defaultLoadOptions,
  onTimezoneChange,
  timezone,
  size = "medium",
}) => {
  const tz = useTimezone();
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const value = timezone || tz.currentTimezone;
  const noResultsMessage = loading ? "Loading..." : query ? "No results found" : "Search for the nearest city";

  const search = useCallback(
    (query) =>
      loadOptions(query)
        .then((res) => setOptions(res))
        .finally(() => setLoading(false)),
    [loadOptions]
  );

  const debouncedSearch = debounce(search, 500);

  useEffect(() => {
    if (query) {
      setLoading(true);
      debouncedSearch(query);
    }
  }, [debouncedSearch, query]);

  const onChange = useCallback(
    (timeZone) => {
      setQuery("");
      if (onTimezoneChange) onTimezoneChange(timeZone);
      else tz.setCurrentTimezone(timeZone);
    },
    [onTimezoneChange, tz]
  );

  return (
    <Select
      input
      hideResultsIfSearchEmpty
      noResultsMessage={noResultsMessage}
      value={value}
      size={size}
      renderSelectedValue={(v) => formatTimeZoneLabel(v.value)}
      onChange={onChange}
      onInputChange={(event) => setQuery(event.target.value)}
      data-testid="modal-tz-selector-select"
    >
      {options.map((option) => {
        const label = formatTimeZoneLabel(option.timeZone, option.name);
        return (
          <Option key={label} value={option.timeZone} label={label}>
            <Typography component="div" data-testid="modal-tz-selector-dropdown">
              {label}
            </Typography>
          </Option>
        );
      })}
    </Select>
  );
};
