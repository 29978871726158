import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { SurveyMetadata } from "views/SurveysResultsView/api/types";

type SurveyStatusProps = Pick<SurveyMetadata, "startDate" | "endDate" | "status">;

const SurveyStatus = ({ startDate, endDate, status }: SurveyStatusProps) => {
  const { color } = useThemeTokens();

  if (status === "live") {
    return (
      <Typography variant="body-small" color={color.text.secondary}>
        <span data-testid="live-status-date">{startDate}</span>
        {" - "}
        <x.span data-testid="live-status-live" color={color.text.processing} animation="pulse">
          Live
        </x.span>
      </Typography>
    );
  }

  if (status === "draft") {
    return (
      <Typography data-testid="draft-status" variant="body-small" color={color.text.info}>
        Demo
      </Typography>
    );
  }

  return (
    <Typography data-testid="closed-status" variant="body-small" color={color.text.secondary}>
      {`${startDate}${endDate ? ` - ${endDate}` : ""}`}
    </Typography>
  );
};

export default SurveyStatus;
