import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const ResearchMobileContentContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    marginTop: spacing.inner.base06,
  };
});

export const ResearchContainerBackground = styled.div(() => {
  const { color, spacing } = useThemeTokens();
  return {
    backgroundColor: color.background.surface.recessed,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBlock: spacing.inner.base06,
  };
});

export const ResearchContentContainer = styled.div(() => {
  const { color, shape } = useThemeTokens();
  return {
    width: "75%",
    backgroundColor: color.background.action.outline.default,
    border: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
    borderRadius: shape.border.radius[12],
  };
});

export const HeaderContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: spacing.inner.base06,
    paddingBlock: spacing.inner.base04,
  };
});

export const StyledHeader = styled(Typography)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.strong._,
  };
});

export const StyledSecondaryLabel = styled(Typography)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.secondary,
  };
});

export const MainBodyContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    padding: spacing.inner.base06,
  };
});
