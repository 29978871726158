import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const CONTAINER_HEIGHT = "60px";

export const TranscriptMobileActionBarContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: spacing.inner.base02,
    paddingRight: spacing.inner.base04,
    height: CONTAINER_HEIGHT,
  };
});

export const TranscriptSearchBarContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    paddingInline: spacing.inner.base04,
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    gap: spacing.inner.base04,
    height: CONTAINER_HEIGHT,
  };
});

export const TranscriptActionsContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    gap: spacing.inner.base04,
  };
});
