import { Pill, Icon as AdsIcon, useThemeTokens } from "@alphasights/alphadesign-components";
import { Quote } from "@alphasights/alphadesign-icons";

interface TotalHighlightsProps {
  totalHighlights: number;
}

export const TotalHighlights = ({ totalHighlights, ...props }: TotalHighlightsProps) => {
  const { spacing } = useThemeTokens();

  return (
    <Pill
      variant="blue"
      size="x-small"
      ml={spacing.inner.base02}
      isInteractive={false}
      leftAccessories={
        <AdsIcon color="info">
          <Quote />
        </AdsIcon>
      }
      {...props}
    >
      {totalHighlights}
    </Pill>
  );
};
