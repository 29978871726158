import { getDefaultExpert } from "utils/thirdPartyDocumentsUtils";
import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { OPEN_EDIT_MODAL_PROMPT } from "./constants";

const getOpenEditModalPrompt = (interaction: ThirdPartyInteraction) => {
  const {
    documentType,
    attribute: { title, experts },
  } = interaction;
  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const { angle, companyName, position } = getDefaultExpert(experts);
  if (isTranscript) {
    if (!angle) {
      return OPEN_EDIT_MODAL_PROMPT.addAngle;
    }
    if (!companyName || !position) {
      return OPEN_EDIT_MODAL_PROMPT.addExpertInfo;
    }
  } else if (!title) {
    return OPEN_EDIT_MODAL_PROMPT.addDocumentInfo;
  }

  return "";
};

export { getOpenEditModalPrompt };
