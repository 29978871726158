import { fetch } from "hooks/useApi";
import { suggestionsInfo } from "./helpers";
import { contentService } from "services/content";
import thirdPartyDocumentsService from "services/thirdPartyDocumentsService";

const deliverablesService = {
  getBookmarks: (project) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverable-bookmarks`,
    })
      .then((res) => res.json())
      .then(({ interactionsIds }) => interactionsIds);
  },
  setBookmark: ({ project, id, bookmarked }) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverable-bookmarks`,
      method: "POST",
      body: JSON.stringify({ interactionId: id, toBookmark: bookmarked }),
    })
      .then((res) => res.json())
      .then(({ bookmarked }) => bookmarked);
  },
  fetchDeliverable: ({ project, transcriptRequest, keywords }) => {
    const keywordParams = keywords ? `keywords=${keywords.map(encodeURIComponent).join(",")}` : "";

    return fetch({
      url: `/api/projects/${project.token}/transcripts/${transcriptRequest.token}/json?${keywordParams}`,
      skipAlert: true,
    }).then((res) => res.json());
  },
  fetchAlphaNowContent: ({ project, keywords }) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverables/content${keywords ? `?keywords=${keywords}` : ""}`,
      skipAlert: true,
    })
      .then((res) => res.json())
      .then(({ suggestedContent, privateContent, pitchedContent = [], ...others }) => {
        return {
          ...others,
          suggestedContent: suggestedContent.map(suggestionsInfo),
          privateContent: privateContent.map(suggestionsInfo),
          pitchedContent: pitchedContent.map(suggestionsInfo),
        };
      })
      .catch((err) => {
        console.error(err);
        return {
          freeSamples: {},
          pitchedContent: [],
          suggestedContent: [],
          privateContent: [],
        };
      });
  },
  fetchAlphaNowContentById: ({ project, id }) => {
    return fetch({
      url: `/api/projects/${project.token}/deliverables/content/${id}`,
      skipAlert: true,
    })
      .then((res) => res.json())
      .then((res) => suggestionsInfo(res));
  },
  getInteractionsWithDeliverables: (token) => {
    return fetch({
      url: `/api/projects/${token}/interactions/find`,
      method: "POST",
      skipAlert: true,
      body: JSON.stringify({
        filters: {
          transcript_status: ["Available", "Requested", "Completed"],
          profile_activity: ["HiddenOrNot"],
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        return {
          ...json,
          edges: json.edges.filter((edge) =>
            edge.node.recordings.find((rec) => rec.visibleToClient || rec.transcriptRequests.find((tr) => tr.completed))
          ),
        };
      });
  },
  fetchContentBookmark: (contentIds) => {
    return fetch({
      url: `/api/client-bookmarks?contentIds=${contentIds}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    })
      .then((res) => res.json())
      .then((bookmarks) => bookmarks[0]);
  },
  postContentBookmark: (projectToken, contentId) => {
    return fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/bookmark`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
    });
  },
  deleteContentBookmark: (projectToken, contentId) => {
    return fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/bookmark`,
      handleForbidden: true,
      skipAlert: true,
      method: "DELETE",
    });
  },
  fetchContentCredits: (projectToken, contentId) => {
    return fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/credits`,
      skipAlert: true,
    }).then((res) => res.json());
  },
  getAiSummaries: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  getAiSummariesStatus: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}/status`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
  generateAiSummary: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
    }).then((res) => res.json());
  },
  deleteAiSummaries: (projectToken, interactionId, recordingId) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}`,
      handleForbidden: true,
      skipAlert: true,
      method: "DELETE",
    }).then((res) => res.json());
  },
  submitAiSummaryFeedback: (projectToken, interactionId, recordingId, summaryType, feedbackType) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}/${summaryType}/feedback`,
      handleForbidden: true,
      skipAlert: false,
      method: "PUT",
      body: JSON.stringify({ type: feedbackType.toUpperCase() }),
    });
  },
  removeAiSummaryFeedback: (projectToken, interactionId, recordingId, summaryType) => {
    return fetch({
      url: `/api/projects/${projectToken}/ai-summaries/${interactionId}/${recordingId}/${summaryType}/feedback`,
      handleForbidden: true,
      skipAlert: false,
      method: "DELETE",
    });
  },
  fetchContentById: (contentId, projectToken) => contentService.fetchContentById(contentId, undefined, projectToken),
  createContentVisit: ({ recommendationId, contentId, projectToken }) =>
    fetch({
      url: `/api/projects/${projectToken}/deliverables/content/${contentId}/visit`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({ recommendationId }),
    }),
  // third party documents service
  fetchDocumentUploadUrl: (projectToken, fileName) => {
    return thirdPartyDocumentsService.fetchDocumentUploadUrl(projectToken, fileName);
  },
  uploadDocumentToS3: (url, file) => {
    return thirdPartyDocumentsService.uploadDocumentToS3(url, file);
  },
  createDocument: (projectToken, id) => {
    return thirdPartyDocumentsService.createDocument(projectToken, id);
  },
  deleteDocument: (projectToken, documentId) => {
    return thirdPartyDocumentsService.deleteDocument(projectToken, documentId);
  },
  updateDocumentAttributes: (projectToken, documentId, attributes) => {
    return thirdPartyDocumentsService.updateDocumentAttributes(projectToken, documentId, attributes);
  },
  addProjectIdToDocument: (projectToken, documentId) => {
    return thirdPartyDocumentsService.addProjectIdToDocument(projectToken, documentId);
  },
  fetchDocument: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchDocument(projectToken, documentId);
  },
  fetchDocuments: (projectToken) => {
    return thirdPartyDocumentsService.fetchDocuments(projectToken);
  },
  fetchDocumentSummary: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchDocumentSummary(projectToken, documentId);
  },
  fetchDocumentSummaryStatus: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchDocumentSummaryStatus(projectToken, documentId);
  },
  fetchDocumentQuestions: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchDocumentQuestions(projectToken, documentId);
  },
  submitDocumentQuestion: (projectToken, documentId, question) => {
    return thirdPartyDocumentsService.submitDocumentQuestion(projectToken, documentId, question);
  },
  fetchDocumentQuestionStatus: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchDocumentQuestionStatus(projectToken, documentId);
  },
  fetchFile: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchFile(projectToken, documentId);
  },
  fetchFileAsPdf: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchFileAsPdf(projectToken, documentId);
  },
  fetchDocumentJson: (projectToken, documentId) => {
    return thirdPartyDocumentsService.fetchJson(projectToken, documentId);
  },
  fetchUnassignedEmailUploads: (projectToken) => {
    return thirdPartyDocumentsService.fetchUnassignedEmailUploads(projectToken);
  },
};
export default deliverablesService;
