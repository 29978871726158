import styled from "@xstyled/styled-components";
import { Popover, useThemeTokens } from "@alphasights/alphadesign-components";

const OptionsList = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base02};
  `;
});

const StyledPopover = styled(Popover)(() => {
  const { color, shape } = useThemeTokens();
  return `
    box-shadow: ${shape.shadow.secondary};
    border: ${shape.border.width.small} solid ${color.border.neutral};
    padding: 0;
  `;
});

export { OptionsList, StyledPopover };
