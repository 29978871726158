import { ReactNode } from "react";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import CitedMarkdown from "components/CitedMarkdown";
import { x } from "@xstyled/styled-components";
import PrimerFieldTitle from "../PrimerFieldTitle/PrimerFieldTitle";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import { TypographyVariation, useThemeTokens } from "@alphasights/alphadesign-components";

type CitationWrapperProps = {
  value: any;
  label: string;
  verticalLabel?: boolean;
  children?: ReactNode;
  labelWidth?: string;
  dataTestId?: string;
  titleToContentGap?: string;
  mt?: string;
  titleProperties?: {
    variant?: TypographyVariation;
    transform?: string;
    color?: string;
    isTitleSensitive?: boolean;
  };
};

const CitationWrapper = ({
  value,
  verticalLabel,
  label,
  children,
  labelWidth = "",
  dataTestId = "citationWrapper",
  titleToContentGap,
  mt = "",
  titleProperties,
}: CitationWrapperProps) => {
  const { spacing } = useThemeTokens();

  return (
    <x.div
      display="flex"
      flexDirection={verticalLabel && "column"}
      gap={titleToContentGap ?? spacing.inner.base03}
      alignItems={!verticalLabel && "top"}
      mt={mt ?? ""}
      data-testid={dataTestId}
    >
      {/* Field title should always be blurred in case there is sensitiveData unless we specifically don't want it to be */}
      <SensitiveDataContainer
        isSensitive={
          hasSensitiveData(value) && !titleProperties?.isTitleSensitive
            ? titleProperties?.isTitleSensitive
            : hasSensitiveData(value)
        }
      >
        <PrimerFieldTitle
          variant={titleProperties?.variant}
          text={label}
          w={labelWidth}
          data-testid={`${dataTestId}Label`}
          transform={titleProperties?.transform}
          color={titleProperties?.color}
        />
      </SensitiveDataContainer>
      <SensitiveDataContainer isSensitive={hasSensitiveData(value)}>
        <div>
          {children ? (
            children
          ) : (
            <CitedMarkdown data-testid={`${dataTestId}Description`} value={value as CitableValue<string>} />
          )}
        </div>
      </SensitiveDataContainer>
    </x.div>
  );
};

export default CitationWrapper;
