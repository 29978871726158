import { apiClient, ProjectFeature } from "@alphasights/portal-api-client";
import { noop } from "lodash";
import { CustomerKnowledgeRecord } from "pages/CustomerExpertPage/helpers";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Project, ComplianceResourcesResponse } from "@alphasights/portal-api-client";

const defaultComplianceVideo: ComplianceResourcesResponse = {
  description: "Watch our video explaining interaction expectations.",
  file: null,
  thumbUrl:
    "https://embed-ssl.wistia.com/deliveries/94eb9285a1fac59299672cd6abc1d4a9ccbb1fff.jpg?image_crop_resized=320x180",
  title: "AlphaSights Compliance",
  type: "video",
  videoId: "yw3qlh3dne",
};

type ComplianceResouce = typeof defaultComplianceVideo;

export interface CurrentProjectContextState {
  complianceResources?: ComplianceResouce[];
  project?: Project;
  isFeatureDisabled: (feature: ProjectFeature) => boolean;
  isLoading?: boolean;
  initialized?: boolean;
  customerKnowledge: CustomerKnowledgeRecord[];
  getCustomerKnowledgeByInteraction?: (id: string) => CustomerKnowledge[];
  refresh?: () => Promise<void> | undefined;
  isLoadingCK: boolean;
}

export const CurrentProjectContext = createContext<CurrentProjectContextState>({
  customerKnowledge: [],
  complianceResources: [defaultComplianceVideo],
  isFeatureDisabled: () => false,
  isLoadingCK: false,
});

export const CurrentProjectProvider = ({
  project,
  isLoading,
  complianceResources,
  children,
  ...props
}: {
  project?: Project;
  isLoading?: boolean;
  complianceResources?: ComplianceResouce[];
  children: JSX.Element;
}) => {
  const [customerKnowledge, setCustomerKnowledge] = useState<CustomerKnowledgeRecord[]>([]);
  const [isLoadingCK, setIsLoadingCK] = useState(false);

  useEffect(
    function fetchCustomerKnowledge() {
      if (!project) return;

      setIsLoadingCK(true);

      const angles = (project as any).angles || [];

      if (!angles.some((a: Angle) => a.type?.name === "End Customers")) return;

      apiClient
        .get<CustomerKnowledgeRecord[]>(`/api/projects/${project.token}/customer-knowledge`)
        .then((res) => {
          setCustomerKnowledge(res);
        })
        .catch(noop)
        .finally(() => {
          setIsLoadingCK(false);
        });
    },
    [project]
  );

  const getCustomerKnowledgeByInteraction = useCallback(
    (id: string) => {
      return customerKnowledge.find((ck) => ck.id === id)?.customerKnowledges || [];
    },
    [customerKnowledge]
  );

  const isFeatureDisabled = (feature: ProjectFeature): boolean => !!project?.disabledFeatures.includes(feature);

  const value = {
    project,
    isFeatureDisabled,
    isLoading,
    customerKnowledge,
    complianceResources: project?.complianceResources ?? [defaultComplianceVideo],
    getCustomerKnowledgeByInteraction,
    isLoadingCK,
  };

  return <CurrentProjectContext.Provider value={value} children={children} {...props} />;
};

export const useCurrentProjectContext = () => {
  const ctx = useContext(CurrentProjectContext);
  // Lazy fetch
  !ctx.initialized && ctx.refresh && ctx.refresh();
  return ctx;
};
