import { createContext, useContext, useState } from "react";

interface MobileSidebarContextState {
  isOpen: boolean;
  toggleSidebar: () => void;
}

export const MobileSidebarContext = createContext<undefined | MobileSidebarContextState>(undefined);

export const MobileSidebarProvider = ({ children }: { children: JSX.Element }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const context = {
    isOpen,
    toggleSidebar,
  };

  return <MobileSidebarContext.Provider value={context} children={children} />;
};

export const useMobileSidebarContext = () => {
  const context = useContext(MobileSidebarContext);

  if (!context) throw new Error("MobileSidebarContext should only be used within the MobileSidebarProvider");

  return context;
};
