import { useMemo } from "react";
import * as React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { SectionTitle, PrimerFieldTitle, LabeledIcon } from "pages/AlphaNowPage/primers/components";
import { SectionWithTopics, StackedSingleBarChart } from "pages/AlphaNowPage/primers/CustomerPrimer/components";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state/store";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const Churn = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { color, spacing } = useThemeTokens();

  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const primerTitle = usePrimersStore(({ primerTitle }) => primerTitle);
  const churn = useCustomerPrimersStore(({ churn }) => churn);
  const { ranks, barriersToSwitch, driveToSwitch } = churn;

  const chartColors = [
    color.base.green[600],
    color.chart.categorical.base03,
    color.base.yellow[500],
    color.base.red[500],
    color.base.red[600],
  ];
  const chartSeriesNames = [
    "Extremely easy",
    "Somewhat easy",
    "Neither easy nor difficult",
    "Somewhat difficult",
    "Extremely difficult",
  ];

  const calculatedRanks = useMemo(
    () => [
      ...[...Array(5).keys()].map((i) => ({
        citedBy: ranks
          .filter(({ value }) => value === i + 1)
          .map(({ citedBy }) => citedBy)
          .flat(),
        isSensitive: false,
        value: ranks.filter(({ value }) => value === i + 1).length,
      })),
    ],
    [ranks]
  );

  return (
    <SectionWrapper ref={ref} px={spacing.inner.base06}>
      <SectionTitle dataTestId="CompetitorDynamicsSectionTitle" text="Vendor Switching" />
      <PrimerFieldTitle
        text="How easy is it for customers to switch vendors in the market?"
        mt={spacing.inner.base05}
      />
      <LabeledIcon
        labelText={`Customers rated the ease of switching to a different vendor in the ${primerTitle} market. Click into a bar to see contributing customer insights.`}
        iconItem={<Info />}
        mt={spacing.inner.base}
        labelColor={color.text.assistive}
        iconColor={color.text.assistive}
      />
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <StackedSingleBarChart
          ranks={calculatedRanks}
          seriesNames={chartSeriesNames}
          chartColors={chartColors}
          sectionName="CHURN"
        />
      </SensitiveDataContainer>

      <SectionWithTopics
        sectionTitle={"What are the barriers to switching vendors in the market?"}
        summary={barriersToSwitch.summary}
        categories={barriersToSwitch.categories}
        mt={spacing.layout.base05}
      />

      <SectionWithTopics
        sectionTitle={"What would drive customers in the market to switch vendors?"}
        summary={driveToSwitch.summary}
        categories={driveToSwitch.categories}
        mt={spacing.layout.base05}
      />
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Churn));
