import { FC, useEffect } from "react";
import { Icon } from "@alphasights/alphadesign-components";
import { TickCircleFilled } from "@alphasights/alphadesign-icons";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { AlphaNowContentType, useTrackUserAction } from "@alphasights/client-portal-shared";
import {
  CarouselsContainer,
  LandingPageContentContainer,
} from "pages/AlphaNowPage/components/LandingPage/LandingPageContent/LandingPageContent.styled";
import LandingPageHeader from "pages/AlphaNowPage/components/LandingPage/LandingPageHeader";
import ContentCardCarousel from "pages/AlphaNowPage/components/ContentCardCarousel";
import {
  IN_THE_NEWS_TITLE,
  RECOMMENDED_INVESTOR_LED_TITLE,
  PURCHASED_RESEARCH_TITLE,
  BOOKMARKED_RESEARCH_TITLE,
  RECOMMENDED_CONTENT_TITLE,
} from "pages/AlphaNowPage/components/LandingPage/LandingPageContent/constants";
import SeeAllContentCard from "pages/AlphaNowPage/components/SeeAllContentCard";
import { PURCHASE_TYPE } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/PurchasedFilter";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { Filters } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import {
  LandingPageContentProps,
  SingleFilterKeyProps,
  Recommendations,
} from "pages/AlphaNowPage/components/LandingPage/LandingPageContent/types";
import RecommendedContentCardCarousel from "pages/AlphaNowPage/components/RecommendedContentCardCarousel";
import { NUMBER_CONTENT_CARDS_VISIBLE_DESKTOP } from "pages/AlphaNowPage/components/ContentCardCarousel/consts";

const DATA_TEST_IDS = {
  InTheNewsSection: "in-the-news-section",
  RecommendedInvestorLedContentSection: "recommended-investor-led-content-section",
  PurchasedContentSection: "purchased-content-section",
  BookmarkedContentSection: "bookmarked-content-section",
  RecommendedContentSection: "recommended-content-section",
};

const getRecommendedContent = (recommendations: Recommendations[]) => recommendations.map(({ content }) => content);
const getContentIds = (content: Content[]) => content.map(({ id }) => id);

const LandingPageContent: FC<LandingPageContentProps> = ({ data }) => {
  const {
    purchasedContent,
    bookmarkedContent,
    inTheNewsContent,
    recommendedInvestorLedContent,
    contentRecommendations,
  } = data;
  const {
    query: { filters: queryFilters },
    updateQuery,
  } = useAppSearchContext();
  const { logHit } = useTrackUserAction();

  const investorLedContent = getRecommendedContent(recommendedInvestorLedContent);
  const newsContent = getRecommendedContent(inTheNewsContent);
  const recommendedContent = getRecommendedContent(contentRecommendations);

  useEffect(() => {
    logHit({
      origin: HitOrigin.landingPage,
      action: HitAction.viewLandingPage,
      details: {
        recommendedContentIds: getContentIds(recommendedContent),
        newsContentIds: getContentIds(newsContent),
        recommendedInvestorLedContentIds: getContentIds(investorLedContent),
        purchasedContentIds: getContentIds(purchasedContent),
        bookmarkedContentIds: getContentIds(bookmarkedContent),
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const PurchasedIcon = () => (
    <Icon size="small" color="success">
      <TickCircleFilled />
    </Icon>
  );

  const handleSeeAllClick = (updatedFilters: SingleFilterKeyProps) =>
    updateQuery({ filters: { ...queryFilters, ...updatedFilters } as Filters });

  const handleSelectContentCard = ({
    content,
    cardPosition,
    title,
  }: {
    content: Content;
    cardPosition: number;
    title: string;
  }) => {
    updateQuery({ selectedContentId: content.id });
    logHit({
      origin: HitOrigin.landingPage,
      action:
        content.contentType === AlphaNowContentType.srm
          ? HitAction.alphaNowViewCompanyPrimer
          : HitAction.alphaNowViewTranscript,

      details: {
        contentId: content.id,
        inView: cardPosition < NUMBER_CONTENT_CARDS_VISIBLE_DESKTOP,
        cardScrollPosition: cardPosition,
        section: title,
      },
    });
  };

  return (
    <LandingPageContentContainer>
      <LandingPageHeader />
      <CarouselsContainer>
        {contentRecommendations.length > 0 && (
          <RecommendedContentCardCarousel
            title={RECOMMENDED_CONTENT_TITLE}
            data={getRecommendedContent(contentRecommendations)}
            dataTestId={DATA_TEST_IDS.RecommendedContentSection}
          />
        )}
        {inTheNewsContent.length > 0 && (
          <ContentCardCarousel
            title={IN_THE_NEWS_TITLE}
            data={getRecommendedContent(inTheNewsContent)}
            dataTestId={DATA_TEST_IDS.InTheNewsSection}
            onClick={handleSelectContentCard}
          />
        )}
        {recommendedInvestorLedContent.length > 0 && (
          <ContentCardCarousel
            title={RECOMMENDED_INVESTOR_LED_TITLE}
            data={getRecommendedContent(recommendedInvestorLedContent)}
            rightAccessories={<PurchasedIcon />}
            dataTestId={DATA_TEST_IDS.RecommendedInvestorLedContentSection}
            onClick={handleSelectContentCard}
          />
        )}
        {purchasedContent.length > 0 && (
          <ContentCardCarousel
            title={PURCHASED_RESEARCH_TITLE}
            data={purchasedContent}
            rightAccessories={<PurchasedIcon />}
            customCard={
              <SeeAllContentCard
                onClick={() => handleSeeAllClick({ purchasedFilter: [PURCHASE_TYPE.byMe, PURCHASE_TYPE.byColleagues] })}
              />
            }
            onClick={handleSelectContentCard}
            dataTestId={DATA_TEST_IDS.PurchasedContentSection}
          />
        )}
        {bookmarkedContent.length > 0 && (
          <ContentCardCarousel
            title={BOOKMARKED_RESEARCH_TITLE}
            data={bookmarkedContent}
            customCard={<SeeAllContentCard onClick={() => handleSeeAllClick({ bookmarkFilter: true })} />}
            dataTestId={DATA_TEST_IDS.BookmarkedContentSection}
            onClick={handleSelectContentCard}
          />
        )}
      </CarouselsContainer>
    </LandingPageContentContainer>
  );
};

export { LandingPageContent as default, DATA_TEST_IDS };
