import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing, color } = useThemeTokens();

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${color.border.divider}`,
  };

  const headerStyle = {
    display: "flex",
    w: "100%",
    justifyContent: "space-between",
    padding: spacing.inner.base04,
  };

  const navBarStyle = {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    gap: spacing.layout.base03,
    w: "100%",
    padding: spacing.inner.base04,
  };

  return {
    pageStyle,
    headerStyle,
    navBarStyle,
  };
};
