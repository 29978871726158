import { useState, useEffect, useCallback } from "react";
import { fetch } from "./useApi";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { currentView } from "../helpers/currentView";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { HitAction } from "@alphasights/portal-api-client";
import { useUserPreferencesContext } from "providers/UserPreferencesProvider";
import { Preference, PreferenceScope, PreferenceType } from "providers/types";

const projectlize = (preference: Preference, token?: string) => {
  if (preference.scope === PreferenceScope.GLOBAL && token) {
    const { id, ...attrs } = preference;

    return {
      ...attrs,
      scope: PreferenceScope.PROJECT,
      projectToken: token,
    };
  }

  return preference;
};

export const usePreference = (type: PreferenceType, { token }: { token?: string } = {}) => {
  const { preference, setPreference } = useUserPreferencesContext({ type, token });
  const isLoggedIn = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true);
  const { logHit } = useTrackUserAction();

  // Since this hook is used on some components outside any route, we
  // need to get the current page from the document object instead of
  // using the useLocation hook.
  const currentPage = currentView(document.location.pathname);

  useEffect(() => {
    if (!isLoggedIn || preference) {
      setIsLoading(false);
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const params = new URLSearchParams();
        params.append("projectToken", token || "");
        const url = `/api/auth-user/preferences/type/${type}?${params}`;
        const res = await fetch({ url, method: "GET", skipAlert: true });

        const loadedPreference = await res.json();
        setPreference(projectlize(loadedPreference, token));
        setIsLoading(false);
      } catch (err) {
        setPreference(
          token
            ? { type, scope: PreferenceScope.PROJECT, projectToken: token, attributes: {} }
            : { type, scope: PreferenceScope.GLOBAL, attributes: {} }
        );
        setIsLoading(false);
      }
    })();
  }, [isLoggedIn, token, type]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitPreference = useCallback(
    async (preference: Partial<Preference>) => {
      const url = "/api/auth-user/preferences";
      const body = JSON.stringify(preference);
      const res = await fetch({ url, method: "PUT", body });

      const savedPreference = await res.json();
      setPreference(projectlize(savedPreference, token));
    },
    [setPreference, token]
  );

  const updatePreference = useCallback(
    (partialAttributes: { [key: string]: any }) => {
      return submitPreference({
        ...preference,
        attributes: {
          ...(preference?.attributes || {}),
          ...partialAttributes,
        },
      }).then(() => logPreferenceUpdate(preference!));
    },
    [preference, submitPreference] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const logPreferenceUpdate = (preference: Preference) => {
    const preferenceActions: { [key: string]: string } = {
      DEFAULT_ADVISOR_VIEW: "SET_DEFAULT_ADVISOR_VIEW",
      TABLE_VIEW_COLUMNS: "UPDATE_TABLE_VIEW_COLUMNS",
      CALENDAR: "CALENDAR_VIEW_PREFERENCE_UPDATE",
      TIMEZONE: "TIMEZONE_PREFERENCE_UPDATE",
    };

    const action = preferenceActions[preference.type];
    if (action) {
      logHit({
        origin: currentPage,
        action: action as HitAction,
        projectToken: token,
      });
    }
  };

  return {
    preference: isLoggedIn ? preference : undefined,
    updatePreference: isLoggedIn ? updatePreference : () => Promise.resolve(),
    isLoading,
  };
};
