import { useEffect, useState } from "react";
import { useMutation, useQuery } from "query-utils";

import { useNotifications } from "@alphasights/client-portal-shared";
import { contentService } from "services/content";
import { useCurrentUser } from "@alphasights/portal-auth-react";

enum NewsletterPreferencesError {
  PARSE = "Failed to parse newsletter settings.",
  FETCH = "Failed to retrieve newsletter settings.",
  UPDATE = "Failed to update newsletter settings.",
}

const useNewsletterPreferences = (trackingId?: string, updateSubscription?: boolean) => {
  const currentUser = useCurrentUser();
  const isCurrentUserInternal = currentUser?.id;
  const [isSubscribed, setIsSubscribed] = useState<boolean | undefined>();
  const [error, setError] = useState("");
  const { showErrorBanner } = useNotifications();

  const updateNewsletterPreferencesMutation = useMutation(
    (value: boolean) => {
      if (isCurrentUserInternal) {
        return contentService.updateNewsletterPreferences(value);
      }
      if (trackingId) {
        return contentService.updateExternalNewsletterPreferences(value, trackingId);
      }
      throw new Error(NewsletterPreferencesError.UPDATE);
    },
    {
      onSuccess: (res: { subscribed: boolean; frequencyInDays: number }) => {
        const subscribed = res.subscribed;
        if (typeof subscribed == "boolean") {
          setIsSubscribed(subscribed);
        } else {
          setError(NewsletterPreferencesError.PARSE);
        }
      },
      onError: () => {
        setError(NewsletterPreferencesError.UPDATE);
      },
    }
  );

  useQuery(
    ["newsletter-preferences", trackingId],
    () => {
      if (isCurrentUserInternal) {
        return contentService.getNewsletterPreferences();
      }
      if (trackingId) {
        return contentService.getExternalNewsletterPreferences(trackingId);
      }
      throw new Error(NewsletterPreferencesError.FETCH);
    },
    {
      onSuccess: (res: { subscribed: boolean; frequencyInDays: number }) => {
        const subscribed = res.subscribed;
        if (typeof subscribed == "boolean") {
          if (updateSubscription && subscribed) {
            updateNewsletterPreferencesMutation.mutate(!subscribed);
          } else {
            setIsSubscribed(subscribed);
          }
        } else {
          setError(NewsletterPreferencesError.PARSE);
        }
      },
      onError: () => {
        setError(NewsletterPreferencesError.FETCH);
      },
    }
  );

  useEffect(() => {
    if (error) {
      showErrorBanner(error);
    }
  }, [error, showErrorBanner]);

  const updateNewsletterPreferences = (value: boolean) => updateNewsletterPreferencesMutation.mutate(value);

  return { isSubscribed, error, updateNewsletterPreferences };
};

export default useNewsletterPreferences;
