import { x } from "@xstyled/styled-components";
import { Avatar, Loading, Typography } from "@alphasights/alphadesign-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";

const AlphaGPTLoading = () => {
  const { wrapper } = useAlphaGPTLoadingStyles();
  const { loadingMessage } = useAlphaGPTContext();

  if (!loadingMessage) return null;

  return (
    <x.div {...wrapper}>
      <Avatar flex="0 0 auto" size="large">
        <Loading />
      </Avatar>
      <Typography variant="body-large">{loadingMessage.message}</Typography>
    </x.div>
  );
};

const useAlphaGPTLoadingStyles = () => {
  const {
    spacing: { inner, layout },
  } = useThemeTokens();

  const wrapper = {
    padding: layout.base03,
    gap: inner.base08,
    display: "flex",
    alignItems: "center",
    w: "100%",
    maxWidth: "724px",
  };

  return {
    wrapper,
  };
};

export default AlphaGPTLoading;
