import PropTypes from "prop-types";
import { x } from "@xstyled/styled-components";
import ReactHtmlParser from "react-html-parser";
import showdown from "showdown";

import { makeParagraphs } from "pages/AlphaNowPage/utils";

const PlainTranscript = ({ addRefToMention, transcript }) => {
  // Depending on the endpoint we use (search within transcript or simply search for the transcript text)
  // the transcript object can have different children, so we need to extract the transcript text from the correct object prop

  let transcriptText = "";

  if (!!transcript?.results) {
    transcriptText = transcript.results[0].highlight.transcript_content[0];
  } else if (!!transcript?.contents) {
    transcriptText = transcript.contents;
  }

  const converter = new showdown.Converter();
  const paragraphs = makeParagraphs(transcriptText);
  const parsedTranscript = converter.makeHtml(paragraphs);

  return parsedTranscript.split("\n").map((str, line) => (
    <x.div key={line} pb={300} lineHeight="450" className="mentions-container" px="800">
      {ReactHtmlParser(str, {
        transform: addRefToMention,
      })}
    </x.div>
  ));
};

PlainTranscript.defaultProps = {
  transcript: {},
};

PlainTranscript.propTypes = {
  addRefToMention: PropTypes.func.isRequired,
  transcript: PropTypes.oneOfType([
    PropTypes.shape({
      contents: PropTypes.string,
      transcriptId: PropTypes.number,
    }),
    PropTypes.shape({
      results: PropTypes.arrayOf(
        PropTypes.shape({
          highlight: PropTypes.shape({
            transcript_content: PropTypes.arrayOf(PropTypes.string),
          }),
        })
      ),
    }),
  ]).isRequired,
};

export default PlainTranscript;
