import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { isEmpty, isEqual } from "lodash";

import { useTrackUserAction } from "@alphasights/client-portal-shared";

import { CitationTrackingProvider } from "./CitationTrackingContext";
import { onSelectCitationEffect } from "./effects";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { isInStorybookContext } from "utils/storybook";

export type CitationState = {
  isCitationClicked: boolean;

  /** The selected content ID */
  selectedContentId?: string;

  /** All speakers */
  speakers?: Speaker[];

  /** The speakers that are selected */
  selectedSpeakerIds: number[];
  selectedSpeakers: Speaker[];
  onSelectSpeakers: (speakers: Speaker[]) => void;

  /** The specific citation that is selected */
  selectedCitation: CitableValue<any> | null;
  onSelectCitation: (citation: CitableValue<any>, isEnabled: boolean) => void;

  /** The purchase status of the selected content */
  citationsEnabled: boolean;
};

/**
 * A context that stores the data for selecting and highlighting citations.
 *
 * Do not directly interact with it:
 *   - Use {@link CitationProvider} to wrap the components that need it
 *     - If in Storybook, use the {@link } decorator
 *   - Use {@link useCitationContext} to access the data stored within the context.
 */
const CitationContext = React.createContext<CitationState | undefined>(undefined);

interface CitationProviderProps {
  children: React.ReactNode;

  selectedContentId?: string;
  contentPaymentRequired?: boolean;
  speakers?: Speaker[];
}

export const CitationProvider: FC<CitationProviderProps> = ({
  children,
  selectedContentId,
  contentPaymentRequired,
  speakers,
}) => {
  const currentUser = useCurrentUser();
  // @ts-ignore
  const userId = currentUser?.id ? String(currentUser?.id) : "";
  const { logHit } = useTrackUserAction();

  const [isCitationClicked, setIsCitationClicked] = useState<boolean>(false);
  const [selectedSpeakers, setSelectedSpeakers] = useState<Speaker[]>([]);
  const [selectedSpeakerIds, setSelectedSpeakerIds] = useState<number[]>([]);
  const [selectedCitation, setSelectedCitation] = useState<CitableValue<any> | null>(null);
  const [citationsEnabled, setCitationsEnabled] = useState<boolean>(!contentPaymentRequired);

  useEffect(() => {
    setSelectedSpeakerIds([]);
    setSelectedCitation(null);
    setIsCitationClicked(false);
    setCitationsEnabled(!contentPaymentRequired);
  }, [selectedContentId, contentPaymentRequired]);

  useEffect(() => {
    setSelectedSpeakerIds(selectedSpeakers.map((speaker) => speaker.speakerId));
  }, [selectedSpeakers]);

  const onSelectSpeakers = useCallback(
    (speakers: Speaker[]) => {
      if (selectedCitation !== null) {
        setSelectedCitation(null);
        setIsCitationClicked(false);
      }
      const speakerIds = speakers.map((speaker) => speaker.speakerId);
      setSelectedSpeakers(!isEqual(selectedSpeakerIds, speakerIds) ? speakers : []);
    },
    [selectedCitation, selectedSpeakerIds]
  );

  const onSelectCitation = useCallback(
    (citation: CitableValue<any> | null, isEnabled: boolean) => {
      if (isEnabled) {
        const isSelectedCitation = isEqual(citation, selectedCitation);

        if (isSelectedCitation) {
          setSelectedCitation(null);
          setSelectedSpeakers([]);
          setIsCitationClicked(false);
        } else {
          !isEmpty(selectedSpeakers) && setSelectedSpeakers([]);
          setIsCitationClicked(true);
        }

        const setCitationContributors = () => {
          const citationContributors =
            speakers?.filter((speaker) => citation?.citedBy.includes(speaker.speakerId)) ?? [];
          setSelectedSpeakers(citationContributors);
        };

        onSelectCitationEffect({
          citation,
          details: { contentId: selectedContentId, citation, userId },
          logHit,
          setSelectedCitation,
          setCitationContributors,
          shouldUpdateCitation: !isEmpty(citation?.citedBy) && !isSelectedCitation,
        });
      }
    },
    [speakers, selectedSpeakers, selectedCitation, selectedContentId, logHit, userId]
  );

  const memoizedValue: CitationState = useMemo(
    () => ({
      isCitationClicked,
      selectedContentId,
      selectedSpeakerIds,
      selectedSpeakers,
      selectedCitation,
      onSelectCitation,
      onSelectSpeakers,
      citationsEnabled,
    }),
    [
      isCitationClicked,
      selectedContentId,
      selectedSpeakerIds,
      selectedSpeakers,
      selectedCitation,
      onSelectCitation,
      onSelectSpeakers,
      citationsEnabled,
    ]
  );

  return (
    <CitationContext.Provider value={memoizedValue}>
      <CitationTrackingProvider {...{ selectedSpeakerIds, selectedContentId }}>{children}</CitationTrackingProvider>
    </CitationContext.Provider>
  );
};

export const useCitationContext = (): CitationState => {
  const citationContext = useContext(CitationContext);

  if (citationContext === undefined) {
    throw new Error("useCitationContext must be used within a CitationProvider");
  }

  return citationContext;
};

/**
 * A Storybook Decorator to provide a CitationProvider.
 *
 * Can only be used within Storybook.
 *
 * Example:
 * ```Jsx
 * export default: {
 *   component: ExampleComponent,
 *   title: "components/Example Component",
 *   decorators: [withCitationProvider],
 * }
 * ```
 */
export const withCitationProvider = (Story: FC<any>) => {
  if (!isInStorybookContext()) {
    throw Error("withCitationProvider can only be used within Storybook");
  }

  return (
    <CitationProvider>
      <Story />
    </CitationProvider>
  );
};
