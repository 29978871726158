import { FC, useState } from "react";
import * as React from "react";
import { Typography, useThemeTokens, Divider, Icon } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import useExpertRequest from "pages/AlphaNowPage/hooks/useExpertRequest";
import BulletPoints from "components/Experts/ExpertCard/RelevanceStatements/BulletPoints/BulletPoints";
import RequestSpeakExpertButton, {
  RequestSpeakExpertButtonProps,
} from "components/Experts/ExpertCard/RelevanceStatements/RequestSpeakExpertButton/RequestSpeakExpertButton";

const relevantExperienceLabel = "Relevant Experience";

type RelevanceStatementsProps = {
  selectedExpertId: number;
  contentId: string;
  bio: string;
  handleOnSpeakToExpertClick: (e: React.MouseEvent<HTMLButtonElement>) => void;

  isRequestDisabled?: boolean;
};

type RelevanceStatementsOrientationProps = {
  bio: string;
  isRequestDisabled?: boolean;
} & RequestSpeakExpertButtonProps;

const MobileRelevanceStatements: FC<RelevanceStatementsOrientationProps> = ({
  selectedExpertId,
  allExpertsRequest,
  isRequestExpertLoading,
  expertRequestExists,
  bio,
  handleOnSpeakToExpertClick,
  isRequestDisabled = false,
}) => {
  const {
    spacing: { inner },
    color: {
      background: { decorative },
    },
  } = useThemeTokens();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const handleOnClickDetails = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsDetailsOpen((prevState) => !prevState);
  };

  return (
    <x.div display="flex" flexDirection="column" gap={inner.base02} data-testid="relevant-experience-mobile-section">
      <x.div display="flex" alignItems="center" gap={inner.base02} onClick={handleOnClickDetails}>
        <Typography color={decorative.base02} variant={"body-em"}>
          {relevantExperienceLabel}
        </Typography>
        <Icon>{isDetailsOpen ? <ChevronUp /> : <ChevronDown />}</Icon>
      </x.div>
      {isDetailsOpen && (
        <>
          <BulletPoints items={bio} />
          <x.div pt={inner.base} display="flex" justifyContent="flex-end">
            <RequestSpeakExpertButton
              selectedExpertId={selectedExpertId}
              allExpertsRequest={allExpertsRequest}
              isRequestExpertLoading={isRequestExpertLoading}
              expertRequestExists={expertRequestExists}
              handleOnSpeakToExpertClick={handleOnSpeakToExpertClick}
              disabled={isRequestDisabled}
            />
          </x.div>
        </>
      )}
    </x.div>
  );
};

const BrowserRelevanceStatements: FC<RelevanceStatementsOrientationProps> = ({
  selectedExpertId,
  allExpertsRequest,
  isRequestExpertLoading,
  expertRequestExists,
  bio,
  handleOnSpeakToExpertClick,
  isRequestDisabled = true,
}) => {
  const {
    spacing: { inner },
    color: {
      background: {
        action: { secondary },
      },
    },
  } = useThemeTokens();

  return (
    <x.div display="flex" flexDirection="column" gap={inner.base02} data-testid="relevant-experience-browser-section">
      <Typography color={secondary.hover} variant={"body-small-em"}>
        {relevantExperienceLabel}
      </Typography>
      <BulletPoints items={bio} />
      <x.div pt={inner.base} display="flex" justifyContent="flex-end">
        <RequestSpeakExpertButton
          selectedExpertId={selectedExpertId}
          allExpertsRequest={allExpertsRequest}
          isRequestExpertLoading={isRequestExpertLoading}
          expertRequestExists={expertRequestExists}
          handleOnSpeakToExpertClick={handleOnSpeakToExpertClick}
          disabled={isRequestDisabled}
        />
      </x.div>
    </x.div>
  );
};

const RelevanceStatements: FC<RelevanceStatementsProps> = ({
  selectedExpertId,
  bio,
  handleOnSpeakToExpertClick,
  contentId,
  isRequestDisabled = true,
}) => {
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { expertRequests, expertRequestExists, isRequestExpertLoading } = useExpertRequest({
    contents: [
      {
        id: contentId,
      },
    ],
  });

  return (
    <x.div
      display="flex"
      flexDirection="column"
      gap={inner.base03}
      mt={inner.base03}
      data-testid="relevant-experience-section"
    >
      <Divider />
      {isMobile ? (
        <MobileRelevanceStatements
          selectedExpertId={selectedExpertId}
          allExpertsRequest={expertRequests}
          isRequestExpertLoading={isRequestExpertLoading}
          expertRequestExists={expertRequestExists}
          bio={bio}
          handleOnSpeakToExpertClick={handleOnSpeakToExpertClick}
          isRequestDisabled={isRequestDisabled}
        />
      ) : (
        <BrowserRelevanceStatements
          selectedExpertId={selectedExpertId}
          allExpertsRequest={expertRequests}
          isRequestExpertLoading={isRequestExpertLoading}
          expertRequestExists={expertRequestExists}
          bio={bio}
          handleOnSpeakToExpertClick={handleOnSpeakToExpertClick}
          isRequestDisabled={isRequestDisabled}
        />
      )}
    </x.div>
  );
};

export default RelevanceStatements;
