export const PURCHASE_ERROR = Object.freeze({
  PricingError: "PricingError",
  AlreadyPurchased: "ContentAlreadyPurchasedException",
  InsufficientCredits: "NotEnoughCreditsLeftException",
  ClientLeversRestrictContentView: "ClientLeversRestrictContentViewException",
});

export const PURCHASE_INFO = Object.freeze({
  AccessRequested: "AccessRequested",
});

export const ERROR = Object.freeze({
  AccessDenied: "AccessDenied",
  DownloadFailed: "DownloadFailed",
  TechnicalError: "TechnicalError",
});

export const BANNER_TYPE = Object.freeze({
  Info: "info",
  Success: "success",
  Warning: "warning",
  Danger: "danger",
});

export const REQUEST_PRIMER = "request-primer";
