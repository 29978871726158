import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { sharedProjectsService } from "services/sharedProjectsService";
import { SharedProject } from "models/SharedProjects";
import { toastError } from "@alphasights/client-portal-shared";
import { BillingAgreementForm } from "./BillingAgreementForm";
import { BillingAgreementSuccess } from "./BillingAgreementSuccess";

export const BillingAgreementPage = () => {
  const { sharedProject } = useFetchSharedProject();

  if (!sharedProject) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<BillingAgreementForm sharedProject={sharedProject} />} />
      <Route path="/success" element={<BillingAgreementSuccess sharedProject={sharedProject} />} />
    </Routes>
  );
};

const useFetchSharedProject = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [sharedProject, setSharedProject] = useState<SharedProject | undefined>();

  useEffect(
    function fetchSharedProject() {
      const redirectToHome = () => navigate("/", { replace: true });

      if (!token) {
        redirectToHome();
        return;
      }

      sharedProjectsService
        .findSharedProject(token)
        .then((result) => {
          if (result) {
            setSharedProject(result);
          } else {
            redirectToHome();
          }
        })
        .catch(() => toastError("An error has occurred: please try refreshing."));
    },
    [navigate, token]
  );

  return { sharedProject };
};
