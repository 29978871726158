import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

enum SearchError {
  InvalidCharacterNumber = "INVALID_CHARACTER_NUMBER",
}

enum Keys {
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Enter = "Enter",
  Backspace = "Backspace",
  Escape = "Escape",
}

enum SearchVariant {
  Simple = "simple",
  Complex = "complex",
}

enum SearchSizeVariant {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

enum SearchStyleVariant {
  V1,
  V2,
}
const STYLE_CONFIG = {
  [SearchSizeVariant.Small]: {
    height: tokens.spacing.inner.base08,
    typographyVariant: "body-small",
    typography: {
      fontFamily: tokens.typography.body.small.fontFamily,
      fontSize: tokens.typography.body.small.fontSize,
      fontWeight: tokens.typography.body.small.fontWeight,
      lineHeight: tokens.typography.body.small.lineHeight,
    },
    icon: {
      size: SearchSizeVariant.Small,
      xStyledProps: {
        pl: tokens.spacing.inner.base03,
      },
    },
    loadingSpinner: {
      xStyledProps: {
        w: tokens.spacing.inner.base04,
        h: tokens.spacing.inner.base04,
      },
    },
    avatar: {
      size: tokens.spacing.inner.base04,
    },
    pill: {
      size: "x-small",
      xStyledProps: {
        mx: 0,
        py: "10px !important",
      },
    },
    input: {
      height: tokens.spacing.inner.base06,
    },
    searchItem: {
      xStyledProps: {
        gap: tokens.spacing.inner.base,
      },
    },
    searchOption: {
      xStyledProps: {
        pl: tokens.spacing.inner.base03,
      },
    },
    footer: {
      [SearchStyleVariant.V1]: {
        xStyledProps: {
          pt: tokens.spacing.inner.base03,
          pb: tokens.spacing.inner.base02,
        },
        typographyVariant: "body-small",
        divider: {
          xStyledProps: {
            my: tokens.spacing.inner.base02,
          },
        },
        container: {
          xStyledProps: {
            px: tokens.spacing.inner.base04,
            bg: "none",
          },
        },
        content: {
          xStyledProps: {
            justifyContent: "none",
            pr: 0,
            py: tokens.spacing.inner.base,
          },
        },
      },
      [SearchStyleVariant.V2]: {
        xStyledProps: {
          pt: tokens.spacing.inner.base02,
          pb: tokens.spacing.inner.base02,
        },
        typographyVariant: "body-small",
        divider: {
          xStyledProps: {
            my: 0,
          },
        },
        container: {
          xStyledProps: {
            px: 0,
            bg: tokens.color.background.neutral.subtle,
          },
        },
        content: {
          xStyledProps: {
            justifyContent: "flex-end",
            pr: tokens.spacing.inner.base04,
            py: 0,
          },
        },
      },
    },
    innerWrapper: {
      [SearchStyleVariant.V1]: {
        borderRadius: tokens.shape.border.radius.medium,
        boxShadow: "none",
        position: "relative",
        zIndex: "50",
      },
      [SearchStyleVariant.V2]: {
        borderRadius: tokens.shape.border.radius.mediumLarge,
        boxShadow: tokens.shape.shadow.tertiary,
        position: "static",
        zIndex: undefined,
      },
    },
    itemsContainer: {
      [SearchStyleVariant.V1]: {
        pt: tokens.spacing.inner.base,
        pb: tokens.spacing.inner.base,
      },
      [SearchStyleVariant.V2]: {
        pt: tokens.spacing.inner.base02,
        pb: tokens.spacing.inner.base02,
      },
    },
    sectionHeader: {
      height: tokens.spacing.inner.base05,
    },
  },
  [SearchSizeVariant.Medium]: {
    height: tokens.spacing.inner.base10,
    typographyVariant: "body",
    typography: {
      fontFamily: tokens.typography.body.regular.fontFamily,
      fontSize: tokens.typography.body.regular.fontSize,
      fontWeight: tokens.typography.body.regular.fontWeight,
      lineHeight: tokens.typography.body.regular.lineHeight,
    },
    icon: {
      size: SearchSizeVariant.Medium,
      xStyledProps: {
        pl: tokens.spacing.inner.base04,
      },
    },
    loadingSpinner: {
      xStyledProps: {
        w: tokens.spacing.inner.base05,
        h: tokens.spacing.inner.base05,
      },
    },
    avatar: {
      size: tokens.spacing.inner.base05,
    },
    pill: {
      size: "medium",
      xStyledProps: {
        mx: "2px",
      },
    },
    input: {
      height: tokens.spacing.inner.base08,
    },
    searchItem: {
      xStyledProps: {
        gap: tokens.spacing.inner.base,
      },
    },
    searchOption: {
      xStyledProps: {
        pl: tokens.spacing.inner.base04,
      },
    },
    footer: {
      [SearchStyleVariant.V1]: {
        xStyledProps: {
          pt: tokens.spacing.inner.base03,
          pb: tokens.spacing.inner.base02,
        },
        typographyVariant: "body-small",
        divider: {
          xStyledProps: {
            my: tokens.spacing.inner.base02,
          },
        },
        container: {
          xStyledProps: {
            px: tokens.spacing.inner.base04,
            bg: "none",
          },
        },
        content: {
          xStyledProps: {
            justifyContent: "none",
            pr: 0,
            py: tokens.spacing.inner.base,
          },
        },
      },
      [SearchStyleVariant.V2]: {
        xStyledProps: {
          pt: tokens.spacing.inner.base03,
          pb: tokens.spacing.inner.base03,
        },
        typographyVariant: "body-small",
        divider: {
          xStyledProps: {
            my: 0,
          },
        },
        container: {
          xStyledProps: {
            px: 0,
            bg: tokens.color.background.neutral.subtle,
          },
        },
        content: {
          xStyledProps: {
            justifyContent: "flex-end",
            pr: tokens.spacing.inner.base04,
            py: 0,
          },
        },
      },
    },
    innerWrapper: {
      [SearchStyleVariant.V1]: {
        borderRadius: tokens.shape.border.radius.medium,
        boxShadow: "none",
        position: "relative",
        zIndex: "50",
      },
      [SearchStyleVariant.V2]: {
        borderRadius: tokens.shape.border.radius.mediumLarge,
        boxShadow: tokens.shape.shadow.secondary,
        position: "static",
        zIndex: undefined,
      },
    },
    itemsContainer: {
      [SearchStyleVariant.V1]: {
        pt: tokens.spacing.inner.base,
        pb: tokens.spacing.inner.base,
      },
      [SearchStyleVariant.V2]: {
        pt: tokens.spacing.inner.base04,
        pb: tokens.spacing.inner.base04,
      },
    },
    sectionHeader: {
      height: tokens.spacing.inner.base06,
    },
  },
  [SearchSizeVariant.Large]: {
    height: tokens.spacing.inner.base12,
    typographyVariant: "body-large",
    typography: {
      fontFamily: tokens.typography.body.large.fontFamily,
      fontSize: tokens.typography.body.large.fontSize,
      fontWeight: tokens.typography.body.large.fontWeight,
      lineHeight: tokens.typography.body.large.lineHeight,
    },
    icon: {
      size: SearchSizeVariant.Large,
      xStyledProps: {
        pl: tokens.spacing.inner.base05,
      },
    },
    loadingSpinner: {
      xStyledProps: {
        w: tokens.spacing.inner.base06,
        h: tokens.spacing.inner.base06,
      },
    },
    avatar: {
      size: tokens.spacing.inner.base06,
    },
    pill: {
      size: "large",
      xStyledProps: {
        mx: "2px",
      },
    },
    input: {
      height: tokens.spacing.inner.base10,
    },
    searchItem: {
      xStyledProps: {
        gap: tokens.spacing.inner.base,
      },
    },
    searchOption: {
      xStyledProps: {
        pl: tokens.spacing.inner.base05,
      },
    },
    footer: {
      [SearchStyleVariant.V1]: {
        xStyledProps: {
          pt: tokens.spacing.inner.base05,
          pb: tokens.spacing.inner.base04,
        },
        typographyVariant: "body",
        divider: {
          xStyledProps: {
            my: tokens.spacing.inner.base02,
          },
        },
        container: {
          xStyledProps: {
            px: tokens.spacing.inner.base04,
            bg: "none",
          },
        },
        content: {
          xStyledProps: {
            justifyContent: "none",
            pr: 0,
            py: tokens.spacing.inner.base,
          },
        },
      },
      [SearchStyleVariant.V2]: {
        xStyledProps: {
          pt: tokens.spacing.inner.base04,
          pb: tokens.spacing.inner.base04,
        },
        typographyVariant: "body",
        divider: {
          xStyledProps: {
            my: 0,
          },
        },
        container: {
          xStyledProps: {
            px: 0,
            bg: tokens.color.background.neutral.subtle,
          },
        },
        content: {
          xStyledProps: {
            justifyContent: "flex-end",
            pr: tokens.spacing.inner.base04,
            py: 0,
          },
        },
      },
    },
    innerWrapper: {
      [SearchStyleVariant.V1]: {
        borderRadius: tokens.shape.border.radius.medium,
        boxShadow: "none",
        position: "relative",
        zIndex: "50",
      },
      [SearchStyleVariant.V2]: {
        borderRadius: tokens.shape.border.radius.mediumLarge,
        boxShadow: tokens.shape.shadow.strong,
        position: "static",
        zIndex: undefined,
      },
    },
    itemsContainer: {
      [SearchStyleVariant.V1]: {
        pt: tokens.spacing.inner.base,
        pb: tokens.spacing.inner.base,
      },
      [SearchStyleVariant.V2]: {
        pt: tokens.spacing.inner.base06,
        pb: tokens.spacing.inner.base06,
      },
    },
    sectionHeader: {
      height: tokens.spacing.inner.base08,
    },
  },
};

export { STYLE_CONFIG, Keys, SearchVariant, SearchSizeVariant, SearchError, SearchStyleVariant };
export * from "./boolean-consts";
