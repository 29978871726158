import { Tile, Typography } from "@alphasights/alphadesign-components";
import { BulletPoints } from "@alphasights/client-portal-shared";
import { Company } from "@alphasights/alphadesign-icons";
import Spacer from "pages/AlphaNowPage/components/Spacer";
import {
  AccordionItemContentContainer,
  FeaturedExpertTitleContainer,
  JobDetailsContainer,
  JobTitleContainer,
  RequestExpertContainer,
  StyledAccordion,
  StyledAccordionItem,
  StyledSectionHeader,
} from "./FeaturedExpertCard.styled";
import { FeaturedExpertProps } from "./types";
import RequestExpertModal from "pages/AlphaNowPage/components/RequestExpertModal";
import { SPEAKER_ANGLE_TYPE_NAME } from "constants/AlphaNow";

export const RELEVANT_EXPERIENCE_HEADER = "Relevant Experience";

export const DataTestIds = {
  FeaturedExpertCard: "featured-expert-card",
  FeaturedExpertIcon: "featured-expert-icon",
};

enum TileVariant {
  Image = "image",
  Icon = "icon",
}

const TitleContent = ({ speaker }: { speaker: FeaturedExpertProps }) => {
  const { companyName, jobTitle, jobDuration, angleType, logo } = speaker;
  return (
    <FeaturedExpertTitleContainer>
      <Tile
        variant={logo ? TileVariant.Image : TileVariant.Icon}
        icon={!logo && <Company data-testid={DataTestIds.FeaturedExpertIcon} />}
        image={logo}
        customSize={32}
      />
      <div>
        <JobTitleContainer>
          <Typography component="span" variant="body-em" pr="2px">
            {companyName}
          </Typography>
          <Typography component="span" variant="body">
            - {jobTitle}
          </Typography>
        </JobTitleContainer>
        <JobDetailsContainer>
          {jobDuration && (
            <>
              <Typography variant="body-small">{jobDuration}</Typography>
              <Spacer variant="body-small" />
            </>
          )}
          {
            <Typography variant="body-small">
              {SPEAKER_ANGLE_TYPE_NAME[angleType as keyof typeof SPEAKER_ANGLE_TYPE_NAME]}
            </Typography>
          }
        </JobDetailsContainer>
      </div>
    </FeaturedExpertTitleContainer>
  );
};

const FeaturedExpertCard = ({
  speaker,
  open = true,
  requestExpertEnabled = true,
  contentId,
}: {
  speaker: FeaturedExpertProps;
  open?: boolean;
  requestExpertEnabled?: boolean;
  contentId?: string;
}) => {
  const { bio } = speaker;

  return (
    <StyledAccordion dataAttributes={{ "data-testid": DataTestIds.FeaturedExpertCard }}>
      <StyledAccordionItem titleContent={<TitleContent speaker={speaker} />} open={open}>
        <AccordionItemContentContainer>
          <StyledSectionHeader variant="body-large-em">{RELEVANT_EXPERIENCE_HEADER}</StyledSectionHeader>
          <BulletPoints items={bio} />
          {requestExpertEnabled && (
            <RequestExpertContainer>
              <RequestExpertModal selectedSpeakers={[speaker]} contentId={contentId} />
            </RequestExpertContainer>
          )}
        </AccordionItemContentContainer>
      </StyledAccordionItem>
    </StyledAccordion>
  );
};

export default FeaturedExpertCard;
