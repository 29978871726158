import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Button as AdsButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const Wrapper = styled.div(() => {
  return `
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

export const ContentWrapper = styled.div(() => {
  const { color } = useThemeTokens();
  return `
    background: ${color.background.decorative.base02};
    flex-grow: 1;
    display: flex;
  `;
});

export const FormWrapper = styled.div(() => {
  const { color } = useThemeTokens();
  return `
    background: ${color.background.inverse};
    flex-grow: 1;
    max-width: 850px;
    margin: 0 auto;
  `;
});

export const FormContainer = styled.div(() => {
  const { isMobile } = useCheckScreen();
  const { spacing } = useThemeTokens();
  return `
    padding: 40px 100px;
    ${
      isMobile &&
      `
      padding: ${spacing.layout.base04};
    `
    }
  `;
});

export const FormTitle = styled(Typography).attrs({ variant: "body-em" })(() => {
  const { spacing } = useThemeTokens();
  return `
    padding-bottom: ${spacing.inner.base06}
  `;
});

export const FormDisclamer = styled(Typography).attrs({ variant: "body" })(() => {
  const { spacing } = useThemeTokens();
  return `
    padding-bottom: ${spacing.inner.base03}
  `;
});

export const CheckboxWrapper = styled.div`
  .checkbox-icon-label {
    align-items: center;
  }
`;

export const ClientNameWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base03};
  `;
});

export const FieldsWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base03};
    padding: ${spacing.inner.base06} 0;
  `;
});

export const Button = styled(AdsButton).attrs({ variant: "primary" })(() => {
  const { isMobile } = useCheckScreen();
  return `
    ${isMobile ? "width: 100%;" : ""}
  `;
});
