import { useCallback } from "react";
import * as React from "react";
import {
  Button,
  ButtonProps,
  ComponentSize,
  DatePicker,
  DatePickerEvent,
  Divider,
  HTMLDataAttributes,
  IconButton,
  Popover,
  PopoverProps,
  TextField,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { Close } from "@alphasights/alphadesign-icons";
import dayjs from "dayjs";
import { ChangeEvent, forwardRef, useEffect, useRef, useState } from "react";

import { DateTimePickerButtonsWrapper, DateTimePickerWrapper, MobileModal } from "./styled";

export interface TextInputWithDatePickerProps {
  value?: Date;
  format?: string;
  onChange: (date: Date) => void;
  includeTimePicker?: boolean;
  alwaysDisplayStartAdornment?: boolean;
  startAdornment?: React.ReactElement<any, any>;
  endAdornment?: React.ReactElement<any, any>;
  isClearButtonEnabled?: boolean;
  placeholder?: string;
  size?: ComponentSize;
  portal?: boolean;
  ariaLabel?: string;
  label?: React.ReactNode;
  error?: { message?: string };
  disablePastDays?: boolean;
  dataAttributes?: HTMLDataAttributes;
  popoverProps?: PopoverProps;
  applyOnClick?: boolean;
  zIndex?: number;
  disabled?: boolean;
  events?: DatePickerEvent[];
  isMobile?: boolean;
}

export const DATE_PICKER_TESTID = "textinput-datepicker-input";

export const TextInputWithDatePicker = forwardRef<HTMLInputElement, TextInputWithDatePickerProps>(
  (
    {
      value,
      format,
      onChange,
      alwaysDisplayStartAdornment = false,
      startAdornment,
      endAdornment,
      isClearButtonEnabled,
      placeholder,
      size = "medium",
      ariaLabel,
      label,
      error,
      portal = true,
      disablePastDays = false,
      applyOnClick = false,
      dataAttributes,
      zIndex = 1,
      popoverProps,
      disabled,
      events,
      isMobile = false,
    }: TextInputWithDatePickerProps,
    ref
  ) => {
    const { color } = useThemeTokens();
    const [anchorEl, setAnchorEl] = useState(undefined as Element | undefined);

    const [day, setDay] = useState(undefined as Date | undefined);

    const resetValues = useCallback(() => {
      setDay(value);
    }, [value]);

    const setValues = (date?: Date) => {
      if (!date) return;

      onChange(date);
    };

    useEffect(() => {
      resetValues();
    }, [resetValues]);

    const popoverRef = useRef(null);

    const handlePopoverOpen = (event: React.MouseEvent<Element>) => {
      const anchor = event.currentTarget.parentElement || event.currentTarget;
      setAnchorEl(anchor);

      // If is mobile we lose focus on the input because the keyboard is opened
      if (isMobile) {
        const inputElement = anchor.querySelector("input");
        if (inputElement) {
          (inputElement as HTMLElement).blur();
        }
      }
    };

    const handlePopoverClose = () => {
      setAnchorEl(undefined);

      resetValues();
    };

    const handleDayChange = (newDay: Date) => {
      setDay(newDay);

      if (applyOnClick) {
        setValues(newDay);

        handlePopoverClose();
      }
    };

    const primaryButtonProps = {
      variant: "secondary",
      size: isMobile ? "medium" : "small",
      children: "Apply",
      disabled: day === undefined,
      dataAttributes: { "data-testid": "apply-button" },
      style: { width: "50%" },
      onClick: () => {
        setValues(day);
        handlePopoverClose();
      },
    } as ButtonProps;

    const secondaryButtonProps = {
      variant: "ghost",
      size: isMobile ? "medium" : "small",
      children: "Cancel",
      style: { width: "50%" },
      onClick: handlePopoverClose,
    } as ButtonProps;

    const formatDatePickerValue = (date: Date) => dayjs(date).format(format ?? "Do MMM YYYY");

    const onTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Trigger onChange with null if the user clears the input
      if (!e.target.value) onChange(null as any);
    };

    const clearButtonAdornment =
      isClearButtonEnabled && value ? (
        <IconButton onClick={() => onChange(null as any)} variant="basic" size={size} color={color.icon.secondary}>
          <Close />
        </IconButton>
      ) : undefined;

    return (
      <>
        <TextField
          aria-label={ariaLabel}
          label={label}
          ref={ref}
          onClick={handlePopoverOpen}
          data-testid={DATE_PICKER_TESTID}
          value={value ? formatDatePickerValue(value) : ""}
          startAdornment={alwaysDisplayStartAdornment || (!value && startAdornment) ? startAdornment : undefined}
          endAdornment={endAdornment || clearButtonAdornment}
          size={size}
          placeholder={placeholder}
          error={error !== undefined}
          errorText={error?.message}
          dataAttributes={dataAttributes}
          onChange={onTextFieldChange}
          disabled={disabled}
        />

        {!isMobile && (
          <Popover
            size="small"
            ref={popoverRef}
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && !isMobile}
            leaveDelay={500}
            onClose={handlePopoverClose}
            buttonPrimaryProps={applyOnClick ? undefined : primaryButtonProps}
            buttonSecondaryProps={applyOnClick ? undefined : secondaryButtonProps}
            portal={portal}
            zIndex={zIndex}
            {...popoverProps}
          >
            <DateTimePickerWrapper>
              <DatePicker
                size="small"
                onChange={handleDayChange}
                firstDate={day}
                disablePastDays={disablePastDays}
                events={events}
              />
            </DateTimePickerWrapper>
          </Popover>
        )}

        {isMobile && (
          <MobileModal showCloseButton={false} open={Boolean(anchorEl) && isMobile} onClose={handlePopoverClose}>
            <DateTimePickerWrapper className="datepicker-body-content">
              <DatePicker
                size="small"
                onChange={handleDayChange}
                firstDate={day}
                disablePastDays={disablePastDays}
                events={events}
              />
            </DateTimePickerWrapper>
            <Divider />
            <DateTimePickerButtonsWrapper>
              <Button {...secondaryButtonProps}>Cancel</Button>
              <Button {...primaryButtonProps}>Apply</Button>
            </DateTimePickerButtonsWrapper>
          </MobileModal>
        )}
      </>
    );
  }
);

TextInputWithDatePicker.displayName = "TextInputWithDatePicker";
