import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useUserInitialsMenu = () => {
  const {
    flex: { gap },
  } = useThemeTokens();

  const loggedInAsWrapper = {
    display: "flex",
    gap: gap.sm,
    alignItems: "center",
  };

  return {
    loggedInAsWrapper,
  };
};
