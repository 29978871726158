enum Angle {
  competitors = "Competitors",
  customers = "End Customers",
  targetCompanies = "Target Company",
  industryAuthorities = "Industry Authorities",
  intermediaries = "Intermediaries",
  suppliers = "Suppliers",
  other = "Other",
}

enum FileStatus {
  processing = "PROCESSING",
  failed = "FAILED",
  uploaded = "UPLOADED",
  informationRequired = "INFORMATION_REQUIRED",
}

enum FileProcessingStatus {
  pending = "pending",
  completed = "completed",
  failed = "failed",
}

const TITLE = "Document Upload Details";
const ADD_TO_PROJECT = "Add to Project";
const UPLOAD_MORE = "Upload More";
const CANCEL = "Cancel";

const MODAL_WIDTH = "912px";

const ANGLE_DISPLAY = {
  [Angle.competitors]: "Competitors",
  [Angle.customers]: "Customers",
  [Angle.targetCompanies]: "Target Companies",
  [Angle.industryAuthorities]: "Industry Authorities",
  [Angle.intermediaries]: "Intermediaries",
  [Angle.suppliers]: "Suppliers",
  [Angle.other]: "Other",
};

const FILE_STATUS_DISPLAY = {
  [FileStatus.processing]: "",
  [FileStatus.failed]: "Failed",
  [FileStatus.uploaded]: "Uploaded",
  [FileStatus.informationRequired]: "Information Required",
};

const FILE_STATUS_TOOLTIP = {
  [FileStatus.processing]: "",
  [FileStatus.failed]: "File cannot be read. Please try again.",
  [FileStatus.uploaded]: "",
  [FileStatus.informationRequired]: "Add expert information to enable AI synthesis",
};

const ANGLE_INFO_TOOLTIP_TEXT = "Required for AI synthesis";

export {
  Angle,
  FileStatus,
  FileProcessingStatus,
  TITLE,
  MODAL_WIDTH,
  ADD_TO_PROJECT,
  CANCEL,
  UPLOAD_MORE,
  ANGLE_DISPLAY,
  FILE_STATUS_DISPLAY,
  FILE_STATUS_TOOLTIP,
  ANGLE_INFO_TOOLTIP_TEXT,
};
