import { TextField, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CASE_CODE, CASE_CODE_PLACEHOLDER, COMMISSION_MP_INFO } from "../../constants";
import { RequestForm } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal/types";

const Item = ({ value }: { value: string }) => {
  const {
    color: { text },
  } = useThemeTokens();

  return (
    <li>
      <Typography color={text.secondary} variant="body-small">
        {value}
      </Typography>
    </li>
  );
};

const CaseCodeInfoContent = ({
  onChange,
  requestForm,
}: {
  onChange: (property: "caseCode" | "information", value: string) => void;
  requestForm: RequestForm;
}) => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();

  return (
    <>
      <x.div
        display="flex"
        flexDirection="column"
        gap={inner.base02}
        data-testid="section-case-code-info-content"
        mt={inner.base04}
      >
        <Typography variant="body-small" color={text.secondary}>
          {CASE_CODE}
        </Typography>
        <TextField
          size="small"
          onChange={({ target: { value } }) => onChange("caseCode", value)}
          style={{ fontSize: size["02"] }}
          placeholder={CASE_CODE_PLACEHOLDER}
          value={requestForm?.caseCode}
        />
      </x.div>
      <x.div data-testid="section-information" className="list" marginTop={inner.base04}>
        <Typography color={text.secondary} paddingBottom={inner.base02} variant="body-small-em">
          {COMMISSION_MP_INFO.title}
        </Typography>
        {COMMISSION_MP_INFO.listItems.map(({ label, description, bullets }, index) => {
          const isLastItem = index === COMMISSION_MP_INFO.listItems.length - 1;

          return (
            <x.div paddingBottom={isLastItem ? 0 : inner.base02}>
              <x.div display="flex" flexDirection="column" key={label}>
                <x.div lineHeight={inner.base04}>
                  <Typography component="span" color={text.secondary} variant="body-small-em">
                    {label}
                  </Typography>
                  <Typography component="span" color={text.secondary} variant="body-small">
                    {description}
                  </Typography>
                </x.div>
                <x.ul listStyleType="initial" pl={inner.base06} color={text.secondary} lineHeight={inner.base04}>
                  {bullets.map((bullet) => (
                    <Item key={bullet} value={bullet} />
                  ))}
                </x.ul>
              </x.div>
            </x.div>
          );
        })}
      </x.div>
    </>
  );
};

export default CaseCodeInfoContent;
