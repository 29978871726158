import { compact, first } from "lodash";
import { Project } from "@alphasights/portal-api-client";

export const fromWorkstream = (objsWithWorkstreamId: Interaction[], workstreamId: string) => {
  return workstreamId ? objsWithWorkstreamId.filter((i) => i.workstreamId === workstreamId) : objsWithWorkstreamId;
};

export const getWorkstream = (project?: Project, workstreamId?: string) => {
  return project?.workstreams?.find((w) => w.id === workstreamId);
};

export const filterSurveysWithInteractions = (
  allInteractionsForProject: Interaction[],
  clientSurveys: ClientSurvey[]
) => {
  const allInteractions = allInteractionsForProject;
  const referencedSurveyIds = allInteractions.map((int) => int.surveyResponse?.clientSurveyId);
  const referencedSurveys = clientSurveys.filter((cs) => referencedSurveyIds.includes(cs.id));
  return referencedSurveys.length ? referencedSurveys : compact([first(clientSurveys)]);
};

export const hasOnlySurveyWorkstreams = (project: Project) => {
  return project?.workstreams?.length > 0 && project.workstreams.every((w) => w.workstreamType === "survey");
};
