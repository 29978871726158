import { memo } from "react";
import { FC } from "react";
import ReactMarkdown from "react-markdown";

import "./CitedMarkdown.css";
import { Citation, MarkdownReplacements } from "components/CitationContext/Citation";
import { reduce } from "lodash";

interface TextRendererProps {
  nodeKey: string;
  value: string;
  key?: string;
}

interface HighlightableMarkdown {
  value: CitableValue<string>;
  style?: any;
}

const CitedMarkdown: FC<HighlightableMarkdown> = memo(({ value, style = {} }) => {
  const markdown = value?.value ?? "";

  return (
    <ReactMarkdown
      className="cited-markdown mentions-container"
      renderers={{
        text: ({ value: text }: TextRendererProps) => (
          <Citation
            style={{ fontSize: "14px", ...style }}
            value={{
              value: text,
              citedBy: /{{.*}}/.test(markdown) ? [] : value.citedBy,
            }}
          />
        ),
      }}
      source={replaceAll(markdown, MarkdownReplacements)}
    />
  );
});

const replaceAll = (text: string, replacements: Record<string, string>) =>
  reduce(
    Object.entries(replacements),
    (accumulator, [toReplace, replacement]) => accumulator.replace(new RegExp(toReplace, "g"), replacement),
    text
  );

export default CitedMarkdown;
