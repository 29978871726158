import { Typography } from "@alphasights/alphadesign-components";
import { HitOrigin } from "@alphasights/portal-api-client";
import { x } from "@xstyled/styled-components";
import classNames from "classnames";
import { useCallback, useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickHooks";
import { FormattedTimeRange } from "../../../providers/TimezoneProvider";
import { CancelRequestPopup } from "../RequestModal";
import { ScheduledInteractionPopup } from "../ScheduledInteractionPopup";
import { CalendarPopover } from "./CalendarPopover";
import { useInteractionWrapperStyles } from "./Interaction.styles";

export const InteractionWrapper = ({
  event,
  children,
  isEventPopupOpen,
  referenceElement,
  onPopupOpen,
  onPopupClose: onPopupCloseP,
  popupState,
}) => {
  const { scheduledInteractionPopup } = useInteractionWrapperStyles();
  const onPopupClose = (args) => {
    onPopupCloseP(args);
    event.emit && event.emit("closePopup", args);
  };

  const [isCancelPopupOpen, setCancelPopupOpen] = useState(false);
  const ref = useRef();
  const { id, expectedDuration } = event.interaction;

  useOnClickOutside(ref, (e) => {
    const modals = [
      ...document.getElementsByClassName("ReactModal__Overlay"),
      ...document.getElementsByClassName("responsive-modal"),
      ...document.getElementsByClassName("dropdown-button"),
      document.getElementById("popup-menu"),
      document.getElementById("call-me-modal"),
    ];

    if (document.querySelector) {
      let ariaLabelOverlay = document.querySelectorAll('[aria-label="Overlay"]');
      if (ariaLabelOverlay) {
        modals.push(...ariaLabelOverlay);
      }
      let selectPopover = document.querySelectorAll('[data-testid="select-popover"]');
      if (selectPopover) {
        modals.push(...selectPopover);
      }
    }

    const isModalChild = modals.find((modal) => modal && modal.contains(e.target));
    if (!isModalChild) {
      onPopupClose();
    }
  });

  const isThisSlotPopped =
    isEventPopupOpen && event.highlight && popupState.selectedStart.getTime() === event.start.getTime();

  const onSubmitCancelPopup = useCallback(() => {
    if (event.deps.onRequestChangeInteraction) {
      return (args) =>
        event.deps.onRequestChangeInteraction(args).then((result) => {
          event.deps.reloadInteractions && event.deps.reloadInteractions();
          return result;
        });
    }
    return event.emit && (() => event.emit("submitCancelRequest"));
  }, [event]);
  return (
    <>
      <x.div overflow="visible">
        <div
          onClick={() =>
            !event.isProvideAvailability &&
            onPopupOpen &&
            onPopupOpen({
              selectedStart: event.start,
              duration: expectedDuration || 3600,
            })
          }
        >
          {children}
        </div>
        {isThisSlotPopped && (
          <x.div {...scheduledInteractionPopup}>
            <CalendarPopover ref={ref} referenceElement={referenceElement}>
              <ScheduledInteractionPopup
                event={event}
                onClose={onPopupClose}
                onCancel={() => setCancelPopupOpen(true)}
                onPopupClose={onPopupClose}
              />
            </CalendarPopover>
          </x.div>
        )}
        {isCancelPopupOpen && (
          <CancelRequestPopup
            id={id}
            onClose={() => setCancelPopupOpen(false)}
            onSubmit={onSubmitCancelPopup()}
            origin={HitOrigin.calendarViewPopup}
            interaction={event.interaction}
          />
        )}
      </x.div>
    </>
  );
};

export const interactionEventProps = ({ highlight, requestPending, ...others }) => {
  return {
    className: classNames(
      highlight // had to use custom classes due to css import order
        ? "event-blue"
        : "event-grey",
      { "striped-event": requestPending },
      {
        "opacity-50 hover-opacity-100": others.projectToken !== others.interaction.projectToken,
      }
    ),
  };
};

export const InteractionEvent = ({
  event: {
    interaction: { id, advisorName, companyName, role, angles },
    start,
    end,
    source,
    projectTitle,
  },
}) => {
  const angle = angles[0];
  return (
    <x.div data-testid={`${source}-slot-${id}`} display="flex" flexDirection="column" lineHeight="1.25">
      <Typography variant="body-small-em" component="div" style={{ whiteSpace: "nowrap" }}>
        <div>{projectTitle}</div>
        {angle && angle.title + " | "}
        {advisorName}
        <br />
        {companyName} - {role}
        <br />
      </Typography>
      <Typography variant="body-small" component="div" data-testid={`event-period-slot-${id}`}>
        <FormattedTimeRange start={start} end={end} />
      </Typography>
    </x.div>
  );
};
