import React, { FC, ReactNode, RefObject, useState } from "react";

import { SelectOption, SelectOptionProps } from "@alphasights/alphadesign-components";
import { Close, Edit, InsertLink } from "@alphasights/alphadesign-icons";
import { OPTIONS } from "./constants";

import * as S from "./DocumentOptionsPopover.styled";

type CustomSelectOptionProps = {
  children: ReactNode;
  icon?: ReactNode;
  onClick: () => void;
} & SelectOptionProps;

const CustomSelectOption: FC<CustomSelectOptionProps> = ({ children, icon, onClick, ...props }) => (
  <SelectOption size="small" listOptionAccessories={{ leftIcon: icon }} {...props} onClick={onClick}>
    {children}
  </SelectOption>
);

type DocumentOptionsPopoverProps = {
  anchorRef: RefObject<HTMLElement>;
  onClose: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

const DocumentOptionsPopover: FC<DocumentOptionsPopoverProps> = ({
  anchorRef,
  onClose,
  onClickEdit,
  onClickDelete,
}) => {
  const [shareText, setShareText] = useState(OPTIONS.share);

  const anchorEl = anchorRef?.current ?? undefined;

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.toString()).then(() => {
      setShareText(OPTIONS.copied);
    });
  };

  return (
    <S.StyledPopover
      placement="bottom-end"
      anchorEl={anchorEl}
      open
      data-testid="more-options-popover"
      onClose={onClose}
    >
      <S.OptionsList>
        <CustomSelectOption icon={<InsertLink />} onClick={handleShare}>
          {shareText}
        </CustomSelectOption>
        <CustomSelectOption icon={<Edit />} onClick={onClickEdit}>
          {OPTIONS.edit}
        </CustomSelectOption>
        <CustomSelectOption icon={<Close />} onClick={onClickDelete}>
          {OPTIONS.remove}
        </CustomSelectOption>
      </S.OptionsList>
    </S.StyledPopover>
  );
};

export default DocumentOptionsPopover;
