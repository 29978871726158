import { Icon, Pill, useThemeTokens } from "@alphasights/alphadesign-components";
import { Credit } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import pluralize from "pluralize";
import { useRealTimeCreditBalanceContext } from "providers/RealTimeCreditBalanceProvider";

export const RealTimeCreditBalance = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { creditBalance } = useRealTimeCreditBalanceContext();

  if (creditBalance === undefined) return null;

  return (
    <x.div mr={inner.base04}>
      <Pill
        size="small"
        leftAccessories={
          <Icon color="secondary">
            <Credit />
          </Icon>
        }
        isInteractive={false}
        variant="outline"
      >
        {creditBalance} available {pluralize("credit", creditBalance)}
      </Pill>
    </x.div>
  );
};
