import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useEmptyResultsStyle = () => {
  const { spacing, color } = useThemeTokens();

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      mt: spacing.layout.base10,
    },
    subtitle: {
      mt: spacing.inner.base,
      textAlign: "center",
    },
    buttonsContainer: {},
    button: {
      mx: "auto",
      mt: spacing.inner.base05,
    },
    contentWrapper: {
      borderRadius: spacing.layout.base,
      mt: spacing.layout.base10,
      background: color.background.action.outline.default,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: color.border.disabled,
    },
  };
};
