import { addSeconds } from "date-fns";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import isAfter from "date-fns/isAfter";
import { parseISO } from "../../providers/TimezoneProvider";

const buildPairs = (dates) => {
  const [first, ...rest] = dates;

  const [slots] = rest.reduce(
    ([acc, start], val) => {
      return [[...acc, { start: start, end: val }], val];
    },
    [[], first]
  );

  return slots;
};

export const splitWithCallForAction = (expertSlot, clientSlots) => {
  const ordered = [
    ...new Set([
      expertSlot.start.getTime(),
      expertSlot.end.getTime(),
      ...clientSlots.map(({ start, end }) => [start.getTime(), end.getTime()]).flat(),
    ]),
  ]
    .map((time) => new Date(time))
    .filter((date) => date.getTime() >= expertSlot.start && date.getTime() <= expertSlot.end)
    .sort((x, y) => x.getTime() - y.getTime());

  return buildPairs(ordered).map((slot) => ({
    ...slot,
    callForAction: clientSlots.some((clientSlot) => areIntervalsOverlapping(slot, clientSlot)),
  }));
};

const buildEventInteraction = ({
  interaction,
  selectedInteractions,
  requestPending,
  projectToken,
  projectTitle,
  deps,
  forceHighlight,
}) => {
  const start = parseISO(interaction.scheduledCallTime);
  const highlight =
    forceHighlight !== undefined
      ? forceHighlight
      : selectedInteractions.length === 0 || selectedInteractions.includes(interaction);

  return {
    start: start,
    end: addSeconds(start, interaction.expectedDuration || 3600),
    projectToken,
    projectTitle,
    interaction,
    source: "interaction",
    highlight,
    requestPending,
    deps,
  };
};

const buildEventAvailabilities = ({
  interaction,
  projectToken,
  projectTitle,
  deps,
  selectedInteractions,
  multipleInteractions,
  detailedDescription,
}) => {
  if (interaction.projectToken !== projectToken) return [];

  const clientAvailabilities = (interaction.clientTimeslots || []).map((clientSlot) => ({
    start: parseISO(clientSlot.startsAt),
    end: parseISO(clientSlot.endsAt),
  }));

  return (interaction.advisorAvailability || [])
    .filter((a) => isAfter(parseISO(a.endsAt), new Date()))
    .flatMap((availability) => {
      const expertSlot = {
        start: parseISO(availability.startsAt),
        end: parseISO(availability.endsAt),
      };

      const overlappingSlots = interaction.autoBookEnabled
        ? clientAvailabilities.filter((clientSlot) => {
            return areIntervalsOverlapping(expertSlot, clientSlot);
          })
        : [];

      const slots = splitWithCallForAction(expertSlot, overlappingSlots);

      return slots.map((slot) => ({
        projectToken,
        projectTitle,
        interaction,
        availability,
        detailedDescription,
        source: "availability",
        deps,
        ...slot,
      }));
    })
    .filter((e) =>
      interaction.state === "completed" && multipleInteractions
        ? selectedInteractions.some((int) => int.id === e.interaction.id && !int.followUpId)
        : true
    );
};

export const interactionAsEvents = ({
  interaction,
  selectedInteractions,
  multipleInteractions = true,
  detailedDescription = true,
  projectToken,
  projectTitle,
  requestPending,
  deps,
  forceHighlight,
}) => {
  if (["scheduled", "completed"].includes(interaction.state)) {
    const availabilities =
      interaction.state === "completed" && !interaction.followUpId
        ? buildEventAvailabilities({
            interaction,
            projectToken,
            projectTitle,
            deps,
            selectedInteractions,
            multipleInteractions,
            detailedDescription,
          })
        : [];
    return [
      buildEventInteraction({
        interaction,
        selectedInteractions,
        requestPending,
        projectToken,
        projectTitle,
        deps,
        forceHighlight,
      }),
      ...availabilities,
    ];
  } else if (
    // autobook off, after requesting in a specific timeslot
    interaction.state === "requested" &&
    requestPending?.type === "SCHEDULE_REQUEST" &&
    (interaction.clientTimeslots || []).length > 0
  ) {
    const start = parseISO(interaction.clientTimeslots[0].startsAt);
    const highlight =
      forceHighlight !== undefined
        ? forceHighlight
        : selectedInteractions.length === 0 || selectedInteractions.includes(interaction);
    return [
      {
        start,
        end: addSeconds(start, interaction.expectedDuration || 3600),
        projectToken,
        projectTitle,
        interaction,
        source: "pending",
        highlight,
        requestPending,
        deps,
      },
    ];
  } else {
    return buildEventAvailabilities({
      interaction,
      projectToken,
      projectTitle,
      deps,
      selectedInteractions,
      multipleInteractions,
      detailedDescription,
    });
  }
};
