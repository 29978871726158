import { useState } from "react";
import * as React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { isEmpty } from "lodash";
import { useQueryClient } from "query-utils";
import { contentService } from "services/content";
import Search, { SearchSizeVariant, SearchVariant } from "components/Search";
import { getDefaultComponents } from "components/Search/components";
import { MatchedOnField, MatchedOnType } from "models/companies/search/constants";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { COMMISSION_MODAL_INPUT_MIN, CommissionPrimerProperties } from "../../constants";

type SearchOptions = {
  cdsAlphaCompanyId: string;
  primaryName: string;
  logo: string;
  matchedOn: {
    id: string;
    field: string;
    type: string;
    value: string;
  };
  weight: number;
}[];

interface CommissionModalSearchProps {
  onChangeProperties: (e: string, property: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  errorMessage: string;
  query: any[];
  setQuery: (query: any[]) => void;
}

export const searchInputTruncateLabel = (label: string, maxLength: number) =>
  label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;

const CommissionModalSearch: React.FC<CommissionModalSearchProps> = ({
  onChangeProperties,
  errorMessage,
  query,
  setQuery,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const [options, setOptions] = useState<SearchOptions>([]);
  const queryClient = useQueryClient();

  const loadSearchOptions = async (query: string) => {
    if (query.length <= 1) return [];

    const companies = await queryClient.fetchQuery(["search-companies", query], () =>
      contentService.searchCompanies(query)
    );
    setOptions(companies);

    let options: any = [];

    if (query?.length > 0) {
      const primaryNameExactMatches = companies.filter(
        ({ matchedOn: { field, type } }: { matchedOn: { field: string; type: string } }) =>
          field === MatchedOnField.PrimaryName && type === MatchedOnType.Exact
      );

      if (isEmpty(primaryNameExactMatches)) {
        options.push({
          label: query[0].toUpperCase() + query.substring(1),
          value: query,
          type: SEARCH_SUGGESTION_TYPES.Keyword,
        });
      }
    }
    companies.forEach((company: any) =>
      options.push({
        label: company.primaryName,
        value: company.primaryName,
        StartIcon: () => <PrimerCompanyLogo size={inner.base06} src={company.logo} />,
      })
    );

    return options;
  };

  const SearchItem = ({ data, ...props }: { data: any }) => {
    const { SearchItem: BaseSearchItem } = getDefaultComponents();
    const { label } = data;

    const newData = {
      ...data,
      label: searchInputTruncateLabel(label, COMMISSION_MODAL_INPUT_MIN),
    };

    return <BaseSearchItem data={newData} {...props} asPlainText />;
  };

  const onHandleSelectedValueChange = (values: any[]) => {
    setQuery(values);

    if (values.length === 0) {
      onChangeProperties("", CommissionPrimerProperties.REQUESTED_COMPANY_ID);
      onChangeProperties("", CommissionPrimerProperties.REQUESTED_COMPANY_NAME);
      onChangeProperties("", CommissionPrimerProperties.REQUESTED_COMPANY_LOGO);
      return;
    }

    const [selectedValue] = values;
    const selectedCompany = options.find((option) => option.primaryName === selectedValue.label);

    const companyName = selectedCompany?.primaryName ?? selectedValue.label;
    const companyLogo = selectedCompany?.logo ?? "";
    const companyId = selectedCompany?.cdsAlphaCompanyId ? selectedCompany?.cdsAlphaCompanyId.toString() ?? "" : "";

    onChangeProperties(companyId, CommissionPrimerProperties.REQUESTED_COMPANY_ID);
    onChangeProperties(companyName, CommissionPrimerProperties.REQUESTED_COMPANY_NAME);
    onChangeProperties(companyLogo, CommissionPrimerProperties.REQUESTED_COMPANY_LOGO);
  };

  return (
    <x.div w="140px">
      <Search
        isError={!!errorMessage}
        errorMessage={errorMessage}
        isSingleSelect
        height={inner.base08}
        debounceSearch={true}
        variant={SearchVariant.Complex}
        placeholder="Add company"
        query={query}
        components={{ SearchItem }}
        loadOptions={loadSearchOptions}
        onChange={onHandleSelectedValueChange}
        size={SearchSizeVariant.Small}
        autoHideSearchIcon
        isMultiLine
      />
    </x.div>
  );
};

export default CommissionModalSearch;
