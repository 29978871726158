import { Pill } from "@alphasights/alphadesign-components";

export const TopicPill = ({ title }: { title?: string }) => (
  <>
    {title && (
      <Pill data-testid="overview-topic" size="x-small" mr="4px" isInteractive={false}>
        {title}
      </Pill>
    )}
  </>
);
