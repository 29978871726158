import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../../customTypes";
import { SurveyStatusPill } from "./SurveyStatusPill";
import { usePillStateConfig, surveyStateSortOrderKeys } from "./pillStateConfig";
import { Row } from "@tanstack/react-table";

const SurveyStatusCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;
  const pillConfig = usePillStateConfig();
  const omitPill = interaction.state === "requested" && interaction.surveyResponse.state === "pending";
  if (omitPill) return null;
  return <SurveyStatusPill interactionId={interaction.id} stateConfig={pillConfig[interaction.surveyResponse.state]} />;
};

export const SurveyStatusColumn = {
  id: "SurveyStatusColumn",
  accessorKey: "interaction.surveyResponse.state",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <SurveyStatusCell props={props} />,
  header: "Survey Status",
  minSize: 200,
  sortingFn: (rowA: Row<any>, rowB: Row<any>) => {
    const rowAOrder = surveyStateSortOrderKeys[rowA.original.interaction.surveyResponse.state];
    const rowBOrder = surveyStateSortOrderKeys[rowB.original.interaction.surveyResponse.state];
    return (rowAOrder.sortOrder || 0) - (rowBOrder.sortOrder || 0);
  },
};
