import { FC } from "react";
import * as React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { cloneDeep } from "lodash";
import Card from "components/Card";
import { useCitationContext } from "components/CitationContext/CitationContext";
import { ANGLE_TYPE_NAME_TO_FORMATTED_NAME } from "constants/CompanyPrimers";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { replaceEachByMap } from "utils/maps";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import useModal from "hooks/useModal";
import TitleAndCompanyDetails from "components/Experts/ExpertCard/TitleAndCompanyDetails/TitleAndCompanyDetails";
import RelevanceStatements from "components/Experts/ExpertCard/RelevanceStatements/RelevanceStatements";
import RelevantDurationDetails from "components/Experts/ExpertCard/RelevantDurationDetails/RelevantDurationDetails";
import SecondaryExpertDetails from "components/Experts/ExpertCard/SecondaryExpertDetails/SecondaryExpertDetails";
import RequestSpeakExpertModal from "components/Experts/RequestSpeakExpertModal/RequestSpeakExpertModal";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import useContentClientSettings from "hooks/useContentClientSettings";
import { HitAction } from "@alphasights/portal-api-client";
import { currentOrigin } from "pages/AlphaNowPage/utils/logHit";

export const DataTestIds = {
  expertCard: "expert-card",
};

interface ExpertCardProps extends React.HTMLAttributes<HTMLDivElement> {
  contentId: string;
  speaker: Speaker;
  setIsExpertsView?: (value: boolean) => void;
  isSelected?: boolean;
  /** Default: [false] */
  isPaymentRequired?: boolean;
}

/**
 * Replaces the `expert.angleTypes[0].name` with the associated value in
 * {@link ANGLE_TYPE_NAME_TO_FORMATTED_NAME} if it has an associated key.
 */
export const formatAngleTypeName = (expert: Speaker): Speaker => {
  const output = cloneDeep(expert);

  const angleTypeName = (expert: Speaker) => expert.angleTypes[0].name;

  output.angleTypes[0].name = replaceEachByMap([angleTypeName(output)], ANGLE_TYPE_NAME_TO_FORMATTED_NAME)[0];

  return output;
};

const ExpertCard: FC<ExpertCardProps> = ({
  contentId,
  speaker: speakerProp,
  isSelected = false,
  isPaymentRequired = false,
  setIsExpertsView = () => {},
  ...unconsumedProps
}) => {
  const currentUser = useCurrentUser();
  const { logHit } = useTrackUserAction();
  const { onSelectSpeakers } = useCitationContext();
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
    color: { text, border: borderColor },
    shape: { border },
  } = useThemeTokens();
  const { isVisible: isSpeakExpertModalVisible, onOpen: onOpenModal, onClose: onCloseModal } = useModal();
  const { clientSettings } = useContentClientSettings();
  const isRequestDisabled = !clientSettings?.requestExperts;

  // @ts-ignore
  const userId = currentUser?.id;

  const speaker = formatAngleTypeName(speakerProp);
  const {
    id: speakerId,
    company,
    jobTitle,
    jobDuration,
    bio,
    secondaryCompany,
    secondaryJobDuration,
    secondaryJobTitle,
  } = speaker;

  const cardStateStyles = () => {
    const base = {
      marginTop: inner.base03,
      padding: inner.base04,
      borderWidth: border.width.sm,
    };

    const active = {
      ...base,
      cursor: "pointer",
      borderColor: {
        _: borderColor.neutral.default,
      },
    };

    const selected = {
      ...base,
      cursor: "pointer",
      borderColor: borderColor.neutral.default,
    };

    const disabled = {
      ...base,
      borderColor: borderColor.neutral.default,
    };

    return {
      active,
      selected,
      disabled,
    };
  };

  const selectExpert: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    onSelectSpeakers([speaker]);
    isMobile && setIsExpertsView(false);
    const origin = currentOrigin();
    logHit({
      origin,
      action: HitAction.alphaNowViewCompanyPrimerExpertCitations,
      details: {
        expertId: speakerId,
        contentId,
        userId,
      },
    });
  };

  const cardStyles = (() => {
    const { active, selected } = cardStateStyles();

    if (isSelected) return selected;

    return active;
  })();

  function ifPaymentNotRequired<T>(callback: (params: T) => void) {
    return (params: T) => {
      callback(params);
    };
  }

  const handleOnSpeakToExpertClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpenModal();
  };

  return (
    <>
      <Card
        data-testid={DataTestIds.expertCard}
        onClick={ifPaymentNotRequired(selectExpert)}
        {...cardStyles}
        {...unconsumedProps}
      >
        <x.div display="flex" alignItems="center">
          <Typography component="div" color={text.secondary} variant="body-small" w="100%">
            <TitleAndCompanyDetails company={company} jobTitle={jobTitle} />
            <RelevantDurationDetails jobDuration={jobDuration} />
            {isSelected && (
              <>
                {!isMobile && (
                  <SecondaryExpertDetails
                    secondaryCompany={secondaryCompany}
                    secondaryJobDuration={secondaryJobDuration}
                    secondaryJobTitle={secondaryJobTitle}
                  />
                )}
                <RelevanceStatements
                  selectedExpertId={speakerId}
                  contentId={contentId}
                  bio={bio}
                  handleOnSpeakToExpertClick={handleOnSpeakToExpertClick}
                  isRequestDisabled={isRequestDisabled}
                />
              </>
            )}
          </Typography>
        </x.div>
      </Card>
      {isSpeakExpertModalVisible && (
        <RequestSpeakExpertModal
          contentId={contentId}
          expertId={speakerId}
          company={company}
          jobTitle={jobTitle}
          jobDuration={jobDuration}
          bio={bio}
          secondaryCompany={secondaryCompany}
          secondaryJobDuration={secondaryJobDuration}
          secondaryJobTitle={secondaryJobTitle}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};

export default ExpertCard;
