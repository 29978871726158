import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useAccessControl } from "./useAccessControl";
import { Badge } from "models/Badge";
import { useUserBadgeContext } from "providers/BadgeProvider";

const useThirdPartyDocsPermissions = () => {
  const currentUser = useCurrentUser();
  const hasManageThirdPartyDocumentsPermission = useAccessControl(["document-uploads:manage"], false);
  const { hasUserBadge } = useUserBadgeContext();

  const isInternalUser = currentUser?.internalUser;

  const hasInternalAccess = isInternalUser ? hasManageThirdPartyDocumentsPermission : true;
  return hasInternalAccess && hasUserBadge(Badge.thirdPartyDocUpload) && currentUser?.documentUploadsEnabled;
};

export default useThirdPartyDocsPermissions;
