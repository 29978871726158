/* eslint no-control-regex: 0 */

import { useState, useEffect, useMemo, useCallback } from "react";
import { Route, Link, useLocation, Routes, useParams, Navigate } from "router-utils";
import { x } from "@xstyled/styled-components";

import { PageFooter } from "@alphasights/client-portal-shared";
import { thirdPartyAccessTerms } from "./ThirdPartyAccessTerms";
import { fetch } from "../../hooks/useApi";
import * as S from "./ThirdPartyPage.styled";
import { Button, TextField, useThemeTokens, Checkbox, Alert } from "@alphasights/alphadesign-components";

const ThirdPartyForm = ({ project }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [messages, setMessages] = useState({});
  const [touched, setTouched] = useState({ name: false, email: false, company: false });
  const location = useLocation();

  const {
    spacing: { layout },
  } = useThemeTokens();

  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value || "");
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const checkName = useCallback(() => {
    const notUnicode = /[^\u0000-\u00ff]/;
    const twoWords = /^[a-zA-Z]+(.|,)? [a-zA-Z]+/;
    setMessages((prev) => {
      const msg = { ...prev };

      if (name && !name.match(twoWords)) {
        msg.name = "Name must be composed of at least two words";
      } else if (name && name.match(notUnicode)) {
        msg.name = "Name contains invalid characters";
      } else {
        delete msg.name;
      }

      return msg;
    });
  }, [name]);

  const checkEmail = useCallback(() => {
    const validEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    setMessages((prev) => {
      const msg = { ...prev };

      if (email && !email.trim().match(validEmail)) {
        msg.email = "Email must be valid";
      } else {
        delete msg.email;
      }

      return msg;
    });
  }, [email]);

  const checkCompany = useCallback(() => {
    setMessages((prev) => {
      const msg = { ...prev };

      if (!company.trim()) {
        msg.company = "Please inform your company";
      } else {
        delete msg.company;
      }

      return msg;
    });
  }, [company]);

  const checkMandatoryFields = () => {
    setTouched({
      name: true,
      email: true,
      company: true,
    });

    checkName();
    checkEmail();
    checkCompany();
  };

  useEffect(() => {
    if (touched.name) checkName();
  }, [name, touched.name, checkName]);

  useEffect(() => {
    if (touched.email) checkEmail();
  }, [email, touched.email, checkEmail]);

  useEffect(() => {
    if (touched.company) checkCompany();
  }, [company, touched.company, checkCompany]);

  const pathname = `${location.pathname}/terms-of-service`;
  const hasMessages = () => Object.keys(messages).length > 0;

  return (
    <x.div>
      <S.StyledMain>
        <S.StyledHeading>
          Join an AlphaSights Interaction on Project&nbsp;
          <b>{project?.title ?? ""}</b>
        </S.StyledHeading>

        <S.StyledParagraph>
          All professional connections arranged through AlphaSights take place in the context of a secure,
          legally-compliant framework, with each party adhering to the highest professional, legal and ethical standards
          at all times. For further information about AlphaSights,&nbsp;
          <S.StyledLink href="https://www.alphasights.com/about">click here</S.StyledLink>.
        </S.StyledParagraph>

        <S.StyledFormWrapper>
          <TextField
            label="Name"
            required
            value={name}
            errorText={touched.name && messages.name}
            onChange={handleInputChange(setName, "name")}
          />
          {touched.name && messages.name && <S.ErrorMessage>{messages.name}</S.ErrorMessage>}

          <x.div marginTop={layout.base02}></x.div>

          <TextField
            label="Email"
            required
            value={email}
            errorText={touched.email && messages.email}
            onChange={handleInputChange(setEmail, "email")}
          />
          {touched.email && messages.email && <S.ErrorMessage>{messages.email}</S.ErrorMessage>}

          <x.div marginTop={layout.base02}></x.div>

          <TextField
            label="Company"
            required
            value={company}
            errorText={touched.company && messages.company}
            onChange={handleInputChange(setCompany, "company")}
          />
          {touched.company && messages.company && <S.ErrorMessage>{messages.company}</S.ErrorMessage>}

          <x.div marginTop={layout.base05}>
            {!hasMessages() && name.trim() && email.trim() && company.trim() ? (
              <Link
                to={pathname}
                state={{
                  name: name.trim(),
                  email: email.trim(),
                  company: company.trim(),
                }}
              >
                <Button variant="secondary" w="100%">
                  Proceed
                </Button>
              </Link>
            ) : (
              <Button variant="secondary" w="100%" onClick={checkMandatoryFields}>
                Proceed
              </Button>
            )}
          </x.div>
        </S.StyledFormWrapper>
      </S.StyledMain>
    </x.div>
  );
};

const TermsOfService = () => {
  const location = useLocation();
  const { token } = useParams();
  const [agreed, setAgreed] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [message, setMessage] = useState();
  useEffect(() => setMessage(undefined), [agreed]);
  const {
    spacing: { inner, layout },
  } = useThemeTokens();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const year = useMemo(() => new Date().getFullYear(), []);

  const formUrl = location.pathname.replace("/terms-of-service", "");
  if (!location.state) return <Navigate to={formUrl} />;
  const { name, email: emailAddress, company: companyName } = location.state;
  if (!name || !emailAddress || !companyName) return <Navigate to={formUrl} />;
  const url = `/api/third-party/${token}/accept`;
  const body = JSON.stringify({ name, emailAddress, companyName });
  const postConfirmation = () => {
    if (!agreed) return setMessage("Please confirm the agreement in the checkbox above");
    fetch({ url, method: "POST", body }).then(() => setConfirmed(true));
  };

  return (
    <div>
      <S.StyledMain>
        {confirmed ? (
          <ThankYou />
        ) : (
          <>
            <Terms />
            <x.div marginTop={layout.base05} marginBottom={layout.base02}>
              <Checkbox size="medium" checked={agreed} onChange={(event) => setAgreed(event.target.checked)}>
                <span style={{ display: "inline" }}>
                  I <strong>{name}</strong>, for myself and on behalf of <strong>{companyName}</strong>, agree to be
                  bound by these Third Party Terms of Service in relation to the use of or access to any AlphaSights
                  Services. I confirm that I am duly authorised to sign these Third Party Terms of Service for and on
                  behalf of <strong>{companyName}</strong>.
                </span>
              </Checkbox>
            </x.div>
            {message && (
              <Alert size="large" variant="warning">
                {message}
              </Alert>
            )}
            <Button size="large" variant="secondary" onClick={postConfirmation}>
              Agree
            </Button>
          </>
        )}
      </S.StyledMain>
      {!confirmed && (
        <x.div padding={inner.base08}>
          <i>
            © {year} AlphaSights Ltd. All rights reserved. No part of this document may be reproduced, stored, or
            transmitted in any form or by any means without the written permission of AlphaSights Ltd. Private &amp;
            Confidential.
          </i>
        </x.div>
      )}
    </div>
  );
};

const Page = ({ project }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <ThirdPartyForm project={project} />
            <PageFooter />
          </>
        }
      />
      <Route
        path="/terms-of-service"
        element={
          <>
            <TermsOfService />
            <PageFooter />
          </>
        }
      />
    </Routes>
  );
};

export default Page;

const Terms = () => (
  <>
    <S.StyledHeading>Third Party Access Terms</S.StyledHeading>
    <div dangerouslySetInnerHTML={{ __html: thirdPartyAccessTerms }} />
  </>
);

const ThankYou = () => (
  <S.StyledMain>
    <S.StyledHeading>Many thanks!</S.StyledHeading>
    <S.StyledParagraph>
      You have agreed to participate in the forthcoming Interaction in accordance with the AlphaSights Third Party Terms
      of Service.
    </S.StyledParagraph>
    <S.StyledParagraph>
      Further scheduling and other information to follow separately. If you have any queries in relation to the
      Interaction, please contact the Client. If you have any queries in relation to the Third Party Terms of Service,
      please contact our&nbsp;
      <S.StyledLink href="mailto:legal.compliance@alphasights.com">Compliance team</S.StyledLink>.
    </S.StyledParagraph>
  </S.StyledMain>
);
