import { createContext, useState, useCallback, useContext, useEffect } from "react";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { fetch } from "hooks/useApi";

export type RealTimeCreditBalanceContextState = {
  creditBalance?: number;
  updateCreditBalance: () => Promise<void>;
};

type RealTimeCreditBalanceContextProps = {
  fetchCreditBalanceFn?: () => Promise<number>;
  children?: JSX.Element;
};

export const RealTimeCreditBalanceContext = createContext<undefined | RealTimeCreditBalanceContextState>(undefined);
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes

export const RealTimeCreditBalanceProvider = ({
  fetchCreditBalanceFn = fetchCreditBalance,
  children,
}: RealTimeCreditBalanceContextProps) => {
  const currentUser = useCurrentUser();
  const [creditBalance, setCreditBalance] = useState<number | undefined>();
  const shouldFetchCreditBalance = currentUser?.accessOnly;

  const updateCreditBalance = useCallback(() => {
    if (!shouldFetchCreditBalance) return Promise.resolve();
    return fetchCreditBalanceFn().then(setCreditBalance);
  }, [fetchCreditBalanceFn, shouldFetchCreditBalance]);

  useEffect(
    function loadInitialCreditBalance() {
      updateCreditBalance();
    },
    [currentUser, updateCreditBalance]
  );

  useEffect(
    function updateCreditBalanceEveryRefreshInterval() {
      if (!shouldFetchCreditBalance) return;

      const interval = setInterval(() => updateCreditBalance(), REFRESH_INTERVAL);
      return () => {
        clearInterval(interval);
      };
    },
    [shouldFetchCreditBalance, updateCreditBalance]
  );

  const context = {
    creditBalance,
    updateCreditBalance,
  };

  return <RealTimeCreditBalanceContext.Provider value={context} children={children} />;
};

export const useRealTimeCreditBalanceContext = () => {
  return useContext(RealTimeCreditBalanceContext) ?? { updateCreditBalance: () => Promise.resolve() };
};

const fetchCreditBalance = async (): Promise<number> => {
  const result = fetch({
    url: "/api/auth-user/credit-balance",
    handleForbidden: true,
    skipAlert: true,
  });
  return result.then((res) => res.json());
};
