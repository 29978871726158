import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { StrictMode, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "query-utils";
import { ThemeProvider, Preflight, createGlobalStyle } from "@xstyled/styled-components";
import Honeybadger from "@honeybadger-io/js";
import App from "./components/App";
import "./index.css";
import { theme } from "@alphasights/client-portal-ads-theme";
import LogRocket from "logrocket";
//@ts-ignore
import setupLogRocketReact from "logrocket-react";
import { datadogRum } from "@datadog/browser-rum";
import userflow from "userflow.js";

import { ClientPortalWrapper } from "app/wrappers/ClientPortalWrapper";
import { TimezoneProvider, useTimezone } from "./providers/TimezoneProvider";
import {
  useCookiePolicy,
  CookiePolicyProvider,
  EnvProvider,
  useEnv,
  WindowDimensionsProvider,
} from "@alphasights/client-portal-shared";
import { useScript } from "./hooks/useScript";
import { DATA_DOG, LOG_ROCKET, USER_FLOW, HONEY_BADGER, HEAP, WISTIA } from "./constants/CookiePolicy";
import { AlphaDesignThemeContextProvider } from "@alphasights/alphadesign-components";
import { usePendo } from "./hooks/usePendo";
import { useCurrentUser, useUserAuthIsReady } from "@alphasights/portal-auth-react";
import { authUserService } from "services/authUserService";
import { useIntercom } from "hooks/useIntercom";
import { usePreference } from "hooks/usePreference";
import { Logger } from "logging/Logger";
import { UserPreferencesProvider } from "providers/UserPreferencesProvider";
import { PreferenceType } from "providers/types";
import { isWindows } from "browser";
import { ClientContactMetadata } from "models/ClientContactMetadata";
import { SWRConfig } from "swr";
import { fetch } from "./hooks/useApi";

const initTime = window.performance.now();

const Root = () => {
  const env = useEnv();
  const currentUser = useCurrentUser();
  const isAuthReady = useUserAuthIsReady();
  const [authUserDetails, setAuthUserDetails] = useState<ClientContactMetadata>();

  const tz = useTimezone();

  const { preference } = usePreference(PreferenceType.TIMEZONE);

  useEffect(() => {
    const preferenceTimezone = preference?.attributes?.timezone;

    preferenceTimezone && tz.setCurrentTimezone(preferenceTimezone);
  }, [preference]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isPolicyAccepted } = useCookiePolicy();
  useScript("//fast.wistia.com/assets/external/E-v1.js", isPolicyAccepted(WISTIA));

  useEffect(() => {
    if (isAuthReady && currentUser) {
      authUserService.getAuthUserDetails().then((data) => {
        setAuthUserDetails(data);
      });
    }
  }, [currentUser, isAuthReady]);

  usePendo({ authUserDetails });

  const enableDataDogRum = () => {
    if (env && env.enableDatadogRum && isPolicyAccepted(DATA_DOG)) {
      datadogRum.init({
        applicationId: env.datadogRumAppid!,
        clientToken: env.datadogRumToken!,
        env: env.datadogEnv,
        site: Logger.DATADOG_SITE,
        service: Logger.DATADOG_SERVICE,
        sessionSampleRate: 100,
        trackUserInteractions: true,
        trackLongTasks: false,
        allowedTracingUrls: [env.datadogRumOrigins!],
      });
      datadogRum.setGlobalContext({ init_time: initTime });
      if (document.visibilityState === "hidden") {
        datadogRum.setGlobalContext({ page_load_in_background: true });
      }
      if (currentUser) {
        datadogRum.setUserProperty("email", currentUser.email);
      }
      Logger.getInstance().init(env);
    }
  };

  const enableUserflow = () => {
    if (env && env.enableUserflow && isPolicyAccepted(USER_FLOW)) {
      userflow.init(env.userflowToken!);
    }
  };

  const enableHoneybadger = () => {
    let honeybadger;
    if (env && isPolicyAccepted(HONEY_BADGER)) {
      honeybadger = Honeybadger.configure({
        apiKey: env.honeyBadgerApiKey,
        environment: env.honeyBadgerEnvironment,
        reportData: env.enableHoneyBadger,
        revision: env.appVersion,
      });
    }
    return honeybadger;
  };

  const enableLogRocket = () => {
    if (
      env &&
      //@ts-ignore
      !LogRocket._isInitialized &&
      env.enableLogRocket &&
      isAuthReady &&
      typeof LogRocket !== "undefined" &&
      isPolicyAccepted(LOG_ROCKET) &&
      !currentUser?.internalUser
    ) {
      LogRocket.init(env.logRocketAppID!, {
        mergeIframes: true,
        childDomains: ["https://client-platform-my-alphasights.alphasights.com"],
        network: {
          requestSanitizer: (request) => {
            if (request.url.toLocaleLowerCase().includes("/api/auth")) {
              request.body = undefined;
            }
            return request;
          },
        },
      });
      setupLogRocketReact(LogRocket);

      if (env.enableLogRocket && env.enableHoneyBadger && isPolicyAccepted(HEAP)) {
        LogRocket.getSessionURL((sessionURL) => {
          Honeybadger.beforeNotify((notice) => {
            notice.context.sessionURL = sessionURL;
          });
        });
      }
    }
  };

  useIntercom({ authUserDetails });

  if (env) {
    enableDataDogRum();
    enableUserflow();
    enableLogRocket();
    const honeybadger = enableHoneybadger();
    return (
      <>
        {isWindows() && <StyledScrollbars />}
        <App honeybadger={honeybadger} />
      </>
    );
  } else {
    return null;
  }
};

const StyledScrollbars = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  :hover::-webkit-scrollbar-thumb {
    background: rgba(162, 172, 176, 0.5);
  }
`;

const swrConfig = () => {
  const fetcher = (url: string) =>
    fetch({
      url,
      handleForbidden: true,
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });

  return {
    fetcher,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  };
};

ReactDOM.render(
  <StrictMode>
    <WindowDimensionsProvider>
      <EnvProvider>
        <QueryClientProvider>
          <CookiePolicyProvider>
            <TimezoneProvider>
              <AlphaDesignThemeContextProvider>
                <ThemeProvider theme={theme}>
                  <SWRConfig value={swrConfig()}>
                    <Preflight />
                    <ClientPortalWrapper>
                      <UserPreferencesProvider>
                        <Root />
                      </UserPreferencesProvider>
                    </ClientPortalWrapper>
                  </SWRConfig>
                </ThemeProvider>
              </AlphaDesignThemeContextProvider>
            </TimezoneProvider>
          </CookiePolicyProvider>
        </QueryClientProvider>
      </EnvProvider>
    </WindowDimensionsProvider>
  </StrictMode>,
  document.getElementById("root")
);
