import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "router-utils";
import { getFiltersFromURL, toString } from "components/InteractionsFilter/serializer";
import { flatten, mapValues, pickBy, uniq, values, zipObject } from "lodash";

export const RememberSearchParamsContext = createContext({
  withSearchParams: (path: string) => path,
});

const TRACKED_PATHS = ["/experts/deliverables-view", "/experts", "/calendar-view", "/synthesis"];

const SYNC_PARAMS = {
  groups: ["/experts/deliverables-view", "/experts", "/calendar-view"],
  companies: ["/experts", "/calendar-view"],
};

type AppliedFilters = { [key: string]: string[] };

export const RememberSearchParamsProvider = ({ ...props }) => {
  const location = useLocation();

  const [filtersByPath, setFiltersByPath] = useState<{ [key: string]: AppliedFilters | undefined }>({});
  const [otherSearchParamsByPath, setOtherSearchParamsByPath] = useState<{
    [key: string]: URLSearchParams | undefined;
  }>({});

  useEffect(() => {
    const match = TRACKED_PATHS.find((path) => {
      const regex = new RegExp(`\\/.*${path}/?`);

      return location.pathname.match(regex);
    });

    if (!match) return;

    const filters: AppliedFilters = getFiltersFromURL(location) ?? {};
    const paramsToSync = pickBy(SYNC_PARAMS, (paths) => paths.includes(match));
    const pathsToSync = uniq(flatten(values(paramsToSync))).filter((p) => p !== match);

    setFiltersByPath({
      ...filtersByPath,
      [match]: filters,
      ...zipObject(
        pathsToSync,
        pathsToSync.map((path) => ({
          ...(filtersByPath[path] ?? {}),
          ...mapValues(
            pickBy(paramsToSync, (paths) => paths.includes(path)),
            (_value, key) => filters[key]
          ),
        }))
      ),
    });

    const otherSearchParams = new URLSearchParams(location.search);
    otherSearchParams.delete("f");
    setOtherSearchParamsByPath((prev) => ({
      ...prev,
      [match]: otherSearchParams,
    }));
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const withSearchParams = (path: string) => {
    const filters = filtersByPath[path];
    const otherSearchParams = otherSearchParamsByPath[path];

    const searchParams = [];

    const filtersString = filters && toString(filters);
    filtersString && searchParams.push(`f=${encodeURIComponent(filtersString)}`);
    otherSearchParams?.toString() && searchParams.push(otherSearchParams.toString());

    const searchParamsStr = searchParams.join("&");

    return searchParamsStr ? `${path}?${searchParamsStr}` : path;
  };

  const value = {
    withSearchParams,
  };

  return <RememberSearchParamsContext.Provider value={value} {...props} />;
};

export const useRememberSearchParams = () => useContext(RememberSearchParamsContext);
