import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import styled from "styled-components";

export const ComplexOptionContainer = styled(x.div)(({ highlight }: { highlight: boolean }) => {
  const { color, spacing } = useThemeTokens();
  return {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: spacing.inner.base02,
    background: highlight ? color.background.neutral.subtle : color.background.none,
    "&:hover": {
      background: color.background.neutral.subtle,
    },
  };
});

export const OptionContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    gap: spacing.inner.base02,
  };
});

export const LeftHandContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    alignItems: "center",
    gap: spacing.inner.base02,
  };
});

export const EndIconContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    position: "absolute" as "absolute",
    right: 0,
    marginRight: spacing.inner.base02,
  };
});

export const LabelContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    alignItems: "baseline",
    gap: spacing.inner.base02,
  };
});
