import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const tokens = useThemeTokens();
  const columns = ["calc(6/8)", "calc(2/8)"];

  return {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      w: "100%",
      bg: tokens.color.background.surface.page.default,
      pb: tokens.spacing.inner.base08,
    },
    contentWrapper: {
      display: "flex",
      flex: "1",
      px: tokens.spacing.inner.base06,
      py: tokens.spacing.inner.base10,
      overflowY: "auto",
      w: "100%",
      gap: tokens.spacing.inner.base12,
      justifyContent: "center",
    },
    summaryColumn: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base08,
      flex: columns[0],
      maxW: "600px",
    },
    navigationColumn: {
      flex: columns[1],
      maxW: "200px",
      position: "sticky",
      top: 0,
      mt: tokens.spacing.inner.base12,
    },
    highLevelOverview: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base03,
    },
    highLevelOverviewTitle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    highLevelOverviewIconGroup: {
      display: "flex",
      gap: tokens.spacing.inner.base03,
    },
    themesTitle: {
      mb: tokens.spacing.inner.base03,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    furtherInsightsBox: {
      borderColor: tokens.color.border.neutral.default,
      borderWidth: tokens.shape.border.width.sm,
      borderRadius: tokens.shape.border.radius.medium,
    },
    insightsTitle: {
      display: "flex",
      gap: tokens.spacing.inner.base02,
    },
    insightsTitleIcon: {
      backgroundColor: tokens.color.background.neutral.default,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: tokens.shape.border.radius.small,
      h: tokens.spacing.inner.base08,
      w: tokens.spacing.inner.base08,
      cursor: "pointer",
    },
    insightsCountAndChevron: {
      ml: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: tokens.spacing.inner.base04,
    },
    errorAlertWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: tokens.spacing.inner.base03,
      alignItems: "center",
    },
  };
};
