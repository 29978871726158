import { Icon } from "@alphasights/alphadesign-components";
import { useAccessControl } from "@alphasights/client-portal-shared";
import { useState, useCallback, useEffect } from "react";
import * as React from "react";
import { NavLink, useLocation, useNavigate } from "router-utils";
import * as S from "./SidebarNav.styled";
import { compact } from "lodash";
import { x } from "@xstyled/styled-components";

type SidebarOptionProps = {
  id: string;
  to?: string;
  permissions?: string[];
  label: string;
  icon: React.ReactNode;
  onClick?: () => void;
};

export const SidebarOption = ({ id, to, permissions, label, icon, onClick }: SidebarOptionProps) => {
  const hasPermission = useAccessControl(compact([...(permissions ? permissions : [])]));
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      onClick?.();

      if (!hasPermission) {
        e.preventDefault();
        return;
      }

      if (isActive && to) {
        navigate(to);
      }
    },
    [isActive, hasPermission, to, navigate, onClick]
  );

  return (
    <S.NavOptionWrapper data-testid={`option-${id}`}>
      <SidebarOptionWrapper id={id} to={to} onClick={handleClick} onSetActive={setIsActive}>
        <S.NavIconWrapper isSelected={isActive} disabled={!hasPermission} data-testid="option-icon">
          <Icon size="large" color="inverse">
            {icon}
          </Icon>
        </S.NavIconWrapper>
        <S.LabelWrapper data-testid="option-label">{label}</S.LabelWrapper>
      </SidebarOptionWrapper>
    </S.NavOptionWrapper>
  );
};

const SidebarOptionWrapper = ({
  id,
  children,
  to,
  onSetActive,
  onClick,
}: {
  id: string;
  children: React.ReactNode;
  to?: string;
  onSetActive?: (active: boolean) => void;
  onClick: (e: React.MouseEvent) => void;
}) => {
  const location = useLocation();

  const checkActiveState = useCallback(
    (id: string) => {
      const match = to && location.pathname.match(to?.replace(/\/$/, ""));
      const isActive =
        !!match ||
        (id === "projects" &&
          (location.pathname.includes("/experts/") || location.pathname.includes("/calendar-view")));

      return isActive;
    },
    [location.pathname, to]
  );

  useEffect(
    function setIsActiveOnMount() {
      onSetActive?.(checkActiveState(id));
    },
    [checkActiveState, id, onSetActive]
  );

  const content = (
    <x.div
      display="flex"
      flexDirection="column"
      alignItems="center"
      textDecoration="none"
      onClick={to ? undefined : onClick}
      role="button"
    >
      {children}
    </x.div>
  );

  return to ? (
    <NavLink to={to} onClick={onClick} style={{ textDecoration: "none" }}>
      {content}
    </NavLink>
  ) : (
    <>{content}</>
  );
};
