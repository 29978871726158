import { x } from "@xstyled/styled-components";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { ExpertFilters } from "components/InteractionsPage/ExpertFilters";

export const FilterBar = ({
  toggleFilterSidebar,
  surveyType,
  isCompact,
  searchQuery,
}: {
  toggleFilterSidebar: () => void;
  surveyType: SurveyType;
  isCompact: boolean;
  searchQuery?: string;
}) => {
  const {
    onFiltersChange,
    workstreamId,
    state: { project },
  } = useProjectInteractionsContext();

  return (
    <x.div>
      <ExpertFilters
        onSubmitFilters={onFiltersChange}
        onToggleFilter={toggleFilterSidebar}
        workstream={project.workstreams.find((w: any) => w.id === workstreamId)}
        isDoubleBlindedSurvey={surveyType === "double_blinded"}
        isSurveyPage
        isCompact={isCompact}
        onResetAllFilters={() => onFiltersChange({}, [])}
        searchQuery={searchQuery}
      />
    </x.div>
  );
};
