import { useEffect, useRef, useState } from "react";
import * as React from "react";
import { IconButton, TextArea, useAlphaToast } from "@alphasights/alphadesign-components";
import * as S from "./ProjectBriefEditor.styled";
import { Close, Tick } from "@alphasights/alphadesign-icons";
import { SideBySideContainer } from "./ProjectBriefEditor.styled";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";

export const ProjectBriefEditor = ({ loading }: { loading: boolean }) => {
  const { project } = useCurrentProjectContext();
  const { updateProjectBrief, readOnly } = useAngleQuestionsContext();
  const lastSavedBriefRef = useRef(project?.clientInstructions ?? "");
  const [updatedBrief, setUpdatedBrief] = useState(lastSavedBriefRef.current);
  const [isBriefChanged, setIsBriefChanged] = useState(false);
  const { toast } = useAlphaToast();
  const errorMessage = updatedBrief.trim().length < 100 ? "Minimum of 100 characters required." : undefined;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    lastSavedBriefRef.current = project?.clientInstructions ?? "";
  }, [project]);

  const handleUpdateBrief = () => {
    updateProjectBrief(updatedBrief).then(() => {
      toast.success({ message: "Project brief updated successfully" });
      lastSavedBriefRef.current = updatedBrief;
      setIsBriefChanged(false);
      if (textAreaRef.current) {
        textAreaRef.current.blur();
        requestAnimationFrame(() => {
          textAreaRef.current?.focus();
          textAreaRef.current!.scrollTop = 0;
          textAreaRef.current?.blur();
        });
      }
    });
  };

  const handleCancelUpdateBrief = () => {
    setUpdatedBrief(lastSavedBriefRef.current);
    setIsBriefChanged(false);
  };

  const onChangeBrief = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUpdatedBrief(e.target.value);
    setIsBriefChanged(true);
  };

  return (
    <S.Wrapper>
      <TextArea
        disabled={loading || readOnly}
        ref={textAreaRef}
        value={updatedBrief}
        label="Project Brief"
        width="100%"
        dataAttributes={{ "data-testid": "project-brief-textbox" }}
        onChange={onChangeBrief}
        error={errorMessage !== undefined && isBriefChanged}
        helperText={!isBriefChanged ? undefined : errorMessage}
      />
      {isBriefChanged && (
        <SideBySideContainer>
          <IconButton
            size="small"
            variant="outline"
            children={<Tick />}
            testId={"save-brief-button"}
            onClick={handleUpdateBrief}
            disabled={errorMessage !== undefined}
          />
          <IconButton
            size="small"
            variant="outline"
            children={<Close />}
            testId={"cancel-changes-button"}
            onClick={handleCancelUpdateBrief}
          />
        </SideBySideContainer>
      )}
    </S.Wrapper>
  );
};
