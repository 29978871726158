import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Lead } from "@alphasights/portal-api-client";
import { useApi } from "hooks/useApi";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { isCallAboutToStart } from "components/InteractionsPage/helpers/Interaction";
import { InteractionsService } from "services/interactionsService";
import { isDuringInteraction, isPostInteraction } from "./helpers";

interface ForwardedProps {
  projectToken: string;
  interaction: Interaction;
  aiTranscriptEnabled: boolean;
  recordedEnabled: boolean;
  humanTranscriptEnabled: boolean;
  deliverablesViewEnabled: boolean;
  requiresComplianceApproval: boolean;
  showMobileView: boolean;
  videoCallAvailable: boolean;
  blindedUpgradeAvailable: boolean;
  upgrades: InteractionUpgrade[];
  projectLead: Lead;
}
interface InteractionUpgradesContextProps extends ForwardedProps {
  accessPlan?: AccessPlan;
  terms?: Terms;
  duringInteraction: boolean;
  postInteraction: boolean;
  selectedUpgrades: string[];
  onRequestAddUpgrade: (type: string, locale?: string) => void;
  onRequestRemoveUpgrade: (type: string) => void;
  loading: boolean;
  recording?: Recording;
}
interface InteractionUpgradesProviderProps extends ForwardedProps {
  children: JSX.Element;
  loadingUpgrades: boolean;
}

const InteractionUpgradesContext = createContext<InteractionUpgradesContextProps | undefined>(undefined);

export const useInteractionUpgradesContext = () => {
  const context = useContext(InteractionUpgradesContext);
  if (!context) {
    throw new Error("useInteractionUpgradesContext must be used within a InteractionUpgradesProvider");
  }
  return context;
};

export const InteractionUpgradesProvider = ({
  children,
  upgrades,
  interaction,
  projectToken,
  loadingUpgrades,
  aiTranscriptEnabled,
  recordedEnabled,
  humanTranscriptEnabled,
  deliverablesViewEnabled,
  showMobileView,
  projectLead,
  requiresComplianceApproval,
  videoCallAvailable,
  blindedUpgradeAvailable,
}: InteractionUpgradesProviderProps) => {
  const [accessPlan, setAccessPlan] = useState<AccessPlan>();
  const selectedUpgrades = useMemo(() => upgrades?.map((u) => u.value) ?? [], [upgrades, interaction]); // eslint-disable-line react-hooks/exhaustive-deps

  const { onRequestAddUpgrade, onRequestRemoveUpgrade } = useBuildAddRemoveUpgradeFunctions({
    projectToken,
    interactionId: interaction.id,
  });

  const [, terms] = useApi(
    {
      url: `/api/projects/${projectToken}/interactions/${interaction.id}/terms`,
      method: "GET",
    },
    [projectToken, interaction.id]
  );

  useEffect(
    function fetchAccesPlan() {
      InteractionsService.getAccessPlan(projectToken, interaction.id).then(setAccessPlan);
    },
    [interaction.id, projectToken]
  );

  const duringInteraction = isDuringInteraction(interaction) || isCallAboutToStart(interaction);
  const postInteraction = isPostInteraction(interaction);
  const recording = useMemo(
    () =>
      interaction.recordings
        ?.sort((a, b) => {
          const aCreationDate = a.createdAt ?? "";
          const bCreationDate = b.createdAt ?? "";
          return aCreationDate < bCreationDate ? -1 : aCreationDate > bCreationDate ? 1 : 0;
        })
        .at(0),
    [interaction.recordings]
  );

  const context = {
    accessPlan,
    terms,
    duringInteraction,
    postInteraction,
    upgrades,
    selectedUpgrades,
    onRequestAddUpgrade,
    onRequestRemoveUpgrade,
    loading: loadingUpgrades || !accessPlan,
    recording,
    projectToken,
    interaction,
    aiTranscriptEnabled,
    recordedEnabled,
    humanTranscriptEnabled,
    deliverablesViewEnabled,
    showMobileView,
    requiresComplianceApproval,
    videoCallAvailable,
    blindedUpgradeAvailable,
    projectLead,
  };

  return <InteractionUpgradesContext.Provider value={context}>{children}</InteractionUpgradesContext.Provider>;
};

const useBuildAddRemoveUpgradeFunctions = ({
  projectToken,
  interactionId,
}: {
  projectToken: string;
  interactionId: string;
}) => {
  const { onAddUpgrade: onAddUpgradeFn, onRemoveUpgrade: onRemoveUpgradeFn } = useProjectInteractionsContext();
  const onAddUpgrade = useCallback(
    (type: string, locale?: string) =>
      onAddUpgradeFn({
        projectToken,
        interactionId: interactionId,
        type,
        locale,
      }),
    [interactionId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onRemoveUpgrade = useCallback(
    (type: string) => onRemoveUpgradeFn({ projectToken, interactionId: interactionId, type }),
    [interactionId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // We are processing upgrade events sequentially to avoid
  // race conditions that can result in duplicate or inconsistent upgrades
  const [upgradeEventQueue, setUpgradeEventQueue] = useState<{ eventType: string; args: (string | undefined)[] }[]>([]);
  const [isProcessingEvent, setIsProcessingEvent] = useState(false);

  useEffect(
    () => {
      const processUpgradeEvents = () => {
        const event = upgradeEventQueue[0];
        if (!event) {
          setIsProcessingEvent(false);
          return;
        }

        setIsProcessingEvent(true);
        const { eventType, args } = event;
        const handleEvent = eventType === "add" ? onAddUpgrade : onRemoveUpgrade;
        // @ts-ignore
        handleEvent(...args).then(() => {
          setIsProcessingEvent(false);
          setUpgradeEventQueue((prev) => prev.slice(1));
        });
      };

      !isProcessingEvent && processUpgradeEvents();
    },
    [upgradeEventQueue] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const requestUpgradeEvent = useCallback((eventType: string, args: (string | undefined)[]) => {
    setUpgradeEventQueue((prev) => [...prev, { eventType, args }]);
  }, []);

  const onRequestAddUpgrade = (type: string, locale?: string) => {
    requestUpgradeEvent("add", [type, locale]);
  };

  const onRequestRemoveUpgrade = (type: string) => {
    requestUpgradeEvent("remove", [type]);
  };

  return {
    onRequestAddUpgrade,
    onRequestRemoveUpgrade,
  };
};
