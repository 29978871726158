import { useThemeTokens } from "@alphasights/alphadesign-components";
import Bowser from "bowser";

export const useMobileDeliverablesStyles = () => {
  const { spacing, color } = useThemeTokens();
  const isElectron = Bowser.getParser(window.navigator.userAgent).getBrowser().name === "Electron";

  return {
    noDeliverablesWrapper: {
      w: "100%",
      h: `calc(100dvh - ${spacing.layout.base08} - ${spacing.layout.base08} - 4px)`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: spacing.layout.base02,
      color: color.text.secondary,
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    mentionsNav: {
      w: "fit-content",
      position: "fixed",
      alignItems: "center",
      bottom: spacing.inner.base10,
      left: "50%",
      transform: "translate(-50%)",
      bg: color.base.white,
      display: "flex",
      gap: spacing.inner.base05,
      border: "1px",
      borderColor: color.border.divider,
      borderRadius: "8px",
      boxShadow: "0px 0px 5px 0px #001D3A1F",
    },
    moderatedContentSection: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base04,
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base04,
      padding: spacing.inner.base04,
      flexGrow: 1,
    },
    renderedWrapper: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "space-between",
      overflowY: "auto",
    },
    expertGroup: {
      display: "flex",
      flexDirection: "column",
      rowGap: spacing.inner.base03,
    },
    expertGroups: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base06,
      paddingTop: spacing.inner.base06,
    },
    fullScreenFixedWrapper: {
      w: "100vw",
      minH: isElectron ? "100vh" : "100dvh",
      maxH: isElectron ? "100vh" : "100dvh",
      background: color.base.white,
      zIndex: 5,
      position: "fixed",
      left: 0,
      top: 0,
      overflowY: "auto",
    },
    contentPurchaseCTA: {
      display: "flex",
      padding: spacing.inner.base04,
      alignItems: "center",
      background: color.base.white,
      gap: spacing.inner.base04,
    },
    stickyAtTheTop: {
      position: "sticky",
      left: 0,
      top: 0,
      w: "100%",
      bg: "white",
    },
    stickyAtTheBottom: {
      position: "sticky",
      left: 0,
      bottom: 0,
      w: "100%",
      bg: "white",
      borderTop: `1px solid ${color.border.divider}`,
      display: "flex",
    },
    filterHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: spacing.inner.base04,
      paddingBottom: 0,
    },
    transcriptReaderWrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: 0,
      flexGrow: 1,
      gap: spacing.layout.base04,
      padding: spacing.layout.base02,
    },
    aiSummaryReaderWrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: 0,
      flexGrow: 1,
      padding: spacing.layout.base02,
    },
    fullScreenFlexColWrapper: {
      maxH: "inherit",
      minH: "inherit",
      display: "flex",
      flexDirection: "column",
    },
    audioBar: {
      position: "sticky",
      left: 0,
      bottom: 0,
      padding: `${spacing.inner.base03} ${spacing.inner.base04}`,
      w: "100%",
      bg: color.base.white,
      borderTop: `1px solid ${color.border.divider}`,
    },
    topbarFixedWrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      w: "100vw",
      bg: color.base.white,
    },
    filterCtaBarWrapper: {
      w: "100%",
      h: spacing.layout.base08,
      borderTop: `1px solid ${color.border.divider}`,
      backgroundColor: color.base.white,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      px: spacing.inner.base04,
      gap: spacing.inner.base04,
    },
    filterCategoryTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: spacing.inner.base03,
      marginTop: spacing.inner.base04,
      marginRight: spacing.inner.base05,
      marginBottom: spacing.inner.base06,
    },
    filterOptionsWrapper: {
      margin: 0,
      padding: 0,
    },
  };
};
