import { Divider, IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCallback, useEffect, useMemo } from "react";
import { useMobileDeliverablesStyles } from "../MobileDeliverablesView.styles";

import { ArrowLeft, ArrowRight, ChevronDown, ChevronUp, Close } from "@alphasights/alphadesign-icons";
import { useHighlightHtmlContext } from "providers/HighlightHtmlProvider";
import { findParentWithDataAttribute, traverseArray } from "utils/htmlElement";

export const InsightsNav = ({
  speakers,
  onClose,
  showTab,
  currentContributor,
  setCurrentContributor,
}: {
  speakers: Speaker[];
  onClose: () => void;
  showTab: (v: number) => void;
  currentContributor?: Speaker;
  setCurrentContributor: (v?: Speaker) => void;
}) => {
  const { stickyAtTheBottom } = useMobileDeliverablesStyles();
  const { color, spacing } = useThemeTokens();
  const {
    setCriteriaValue,
    currentHighlight,
    highlights,
    selectNextHighlight,
    selectPreviousHighlight,
    setOnSelectCallback,
    getHighlightDataValues,
    focus,
  } = useHighlightHtmlContext();

  // just in case the highlights are tied to unknown speaker ids
  const speakerIds = useMemo(() => speakers.filter((sp) => sp.speakerId).map((sp) => sp.speakerId.toString()), [
    speakers,
  ]);
  const validHighlightValues = useMemo(
    () =>
      currentHighlight ? getHighlightDataValues(currentHighlight).filter((v: string) => speakerIds.includes(v)) : [],
    [speakerIds, currentHighlight, getHighlightDataValues]
  );

  useEffect(
    function configureContextProviderToShowContributorRelatedToSelectedHighlight() {
      setOnSelectCallback(() => (el: HTMLElement | null) => {
        const currentSpeakerId = currentContributor?.speakerId.toString();
        const highlightSpeakerIds = el ? getHighlightDataValues(el) : [];
        if (!currentSpeakerId || !highlightSpeakerIds.includes(currentSpeakerId)) {
          const speaker =
            speakers.find((sp) => sp.speakerId.toString() === highlightSpeakerIds[0]) ??
            speakers.find((sp) => highlightSpeakerIds.includes(sp.speakerId.toString()));
          setCurrentContributor(speaker);
        }
      });
    },
    [speakers, currentContributor, setOnSelectCallback, getHighlightDataValues, setCurrentContributor]
  );

  useEffect(
    function autoChangeTabWhenNavigateHighlight() {
      const visible = currentHighlight?.offsetParent;
      if (currentHighlight && !visible) {
        const parent = findParentWithDataAttribute(currentHighlight, "tabIndex");
        const highlightTab = Number(parent?.dataset.tabIndex);
        if (!isNaN(highlightTab)) {
          showTab(highlightTab);
          setTimeout(() => focus(currentHighlight), 200);
        }
      }
    },
    [currentHighlight, showTab, focus]
  );

  useEffect(
    function updateContextProviderWithSelectedContributor() {
      currentContributor ? setCriteriaValue(currentContributor.speakerId.toString()) : setCriteriaValue(null);
    },
    [currentContributor, setCriteriaValue]
  );

  const navigateContributor = useCallback(
    (offset: number) => {
      const newSpeakerId = traverseArray(validHighlightValues, currentContributor?.speakerId.toString(), offset);
      const newSpeaker = speakers.find((sp) => sp.speakerId.toString() === newSpeakerId)!;
      newSpeaker && setCurrentContributor(newSpeaker);
    },
    [currentContributor, setCurrentContributor, validHighlightValues, speakers]
  );

  const selectPreviousContributor = useCallback(() => navigateContributor(-1), [navigateContributor]);
  const selectNextContributor = useCallback(() => navigateContributor(1), [navigateContributor]);

  return currentContributor ? (
    <x.div
      {...stickyAtTheBottom}
      padding={spacing.inner.base04}
      display={"flex"}
      flexDirection={"column"}
      gap={spacing.inner.base04}
      data-testid="insights-nav"
    >
      <x.div display={"flex"} justifyContent={"space-between"}>
        <Typography color={color.text.secondary}>
          {validHighlightValues.length ? `${validHighlightValues.length} CONTRIBUTING EXPERT(S)` : ""}
        </Typography>
        <IconButton
          size="large"
          color={color.icon.secondary}
          variant="basic"
          onClick={onClose}
          testId="close-insights-nav-btn"
        >
          <Close />
        </IconButton>
      </x.div>
      <x.div display={"flex"} justifyContent={"space-between"} gap={spacing.inner.base04}>
        <IconButton
          size="small"
          variant="outline"
          onClick={selectPreviousContributor}
          disabled={validHighlightValues.indexOf(currentContributor?.speakerId.toString()) < 1}
          testId="previous-contributor-btn"
        >
          <ArrowLeft />
        </IconButton>
        <x.div flexGrow={1} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
          <Typography variant="body-small" color={color.text.secondary}>
            Contributed by
          </Typography>
          <Typography variant="body-small-em">
            {currentContributor.company} - {currentContributor.jobTitle}
          </Typography>
        </x.div>
        <IconButton
          size="small"
          variant="outline"
          onClick={selectNextContributor}
          disabled={
            validHighlightValues.indexOf(currentContributor.speakerId.toString()) >= validHighlightValues.length - 1
          }
          testId="next-contributor-btn"
        >
          <ArrowRight />
        </IconButton>
      </x.div>
      <Divider />
      <x.div display={"flex"} justifyContent={"center"} gap={spacing.inner.base04} alignItems={"center"}>
        <IconButton variant="basic" onClick={selectPreviousHighlight} testId="previous-contribution-btn">
          <ChevronUp />
        </IconButton>
        <Typography variant="body-small" color={color.text.secondary}>
          {currentHighlight ? highlights.indexOf(currentHighlight) + 1 : 0} / {highlights.length} contributions
        </Typography>
        <IconButton variant="basic" onClick={selectNextHighlight} testId="next-contribution-btn">
          <ChevronDown />
        </IconButton>
      </x.div>
    </x.div>
  ) : null;
};
