import { useState, useEffect, useRef } from "react";
import { x, useSize } from "@xstyled/styled-components";
import { useDropzone } from "react-dropzone";
import prettyBytes from "pretty-bytes";
import { Icon, Button, Typography, useThemeTokens, IconButton } from "@alphasights/alphadesign-components";
import { Attachment as AttachmentIcon, Close, DocumentUploaded } from "@alphasights/alphadesign-icons";
import { Label } from "components/Label";
import { Attachment } from "types";
import { RichTextEditorLexical } from "@alphasights/alphadesign-rte";

export const Counter = ({ length, maxLength }: { length: number; maxLength: number }) => {
  const { spacing } = useThemeTokens();
  const lengthColor = length > maxLength ? "danger" : "secondary";
  return (
    <x.div display="inline-flex" gap={spacing.inner.base}>
      <Typography color={lengthColor} variant="body-small">
        {length}
      </Typography>
      <Typography color="secondary" variant="body-small">
        /
      </Typography>
      <Typography color="secondary" variant="body-small">
        {maxLength}
      </Typography>
    </x.div>
  );
};

export const AttachmentDisplay = ({
  attachment,
  disabled,
  onClickRemove,
}: {
  attachment: Attachment;
  disabled: boolean;
  onClickRemove: (attachment: Attachment) => void;
}) => {
  const { color, shape, spacing } = useThemeTokens();

  const iconDivStyle = {
    padding: spacing.inner.base02,
    backgroundColor: color.background.neutral.default,
    borderRadius: shape.border.radius.small,
  };

  const notOverflowStyle: any = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const onClick = () => {
    onClickRemove(attachment);
  };

  const extractFileType = (attachment: Attachment) => {
    try {
      return attachment.name.split(".").pop()?.toUpperCase();
    } catch (error) {
      return attachment.type;
    }
  };

  const extractFileSize = (attachment: Attachment) => {
    return prettyBytes(attachment.size, { maximumFractionDigits: 1 });
  };

  return (
    <x.div display="inline-flex" gap={spacing.layout.base} w={useSize("full")}>
      <x.div style={iconDivStyle}>
        <Icon color={attachment.valid ? "secondary" : "danger"}>
          <DocumentUploaded />
        </Icon>
      </x.div>
      <x.div display="inline-grid">
        <Typography style={notOverflowStyle} color={attachment.valid ? "secondary" : "danger"}>
          {attachment.name}
        </Typography>
        <Typography
          component="div"
          variant="body-small"
          color={attachment.valid ? "secondary" : "danger"}
          display="inline-flex"
          gap={spacing.inner.base}
        >
          {extractFileType(attachment)}
          <x.span>•</x.span>
          {extractFileSize(attachment)}
        </Typography>
      </x.div>
      <x.div display="inline-flex" alignItems="center" marginLeft="auto" visibility={disabled ? "hidden" : undefined}>
        <Button variant="ghost" size="small" onClick={onClick}>
          <Icon color={attachment.valid ? "secondary" : "danger"}>
            <Close />
          </Icon>
        </Button>
      </x.div>
    </x.div>
  );
};

export type TextBoxProps = {
  label?: string;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  withAttachments?: boolean;
  error?: string;
  defaultValue?: string;
  attachments?: Attachment[];
  fileSizeLimit?: number;
  fileLimit?: number;
  autoFocus?: boolean;
  maxLength?: number;
  onChange: (value: string | undefined) => void;
  onAttachmentChange?: (value: Attachment[]) => void;
};

export const TextBox = ({
  label,
  placeholder = "Placeholder",
  disabled = false,
  required = false,
  withAttachments = false,
  error,
  defaultValue,
  attachments = [],
  fileSizeLimit = 10,
  fileLimit = 5,
  autoFocus,
  maxLength,
  onChange = () => {},
  onAttachmentChange = () => {},
}: TextBoxProps) => {
  const { spacing, shape, color } = useThemeTokens();
  const initialValueRef = useRef(defaultValue);

  const wrapperDivStyle = {
    display: "inline-grid",
    borderRadius: shape.border.radius.small,
    borderWidth: shape.border.width.small,
    borderColor: error ? color.border.danger : color.border.neutral.default,
    marginTop: spacing.inner.base02,
  };

  const attachDivStyle = {
    borderTopWidth: shape.border.width.small,
    borderTopColor: color.border.neutral.default,
  };

  return (
    <x.div w={useSize("full")} display="inline-grid">
      {label && <Label text={label} required={required} />}
      <x.div style={wrapperDivStyle}>
        <RichTextEditorLexical
          borderless
          autoFocus={autoFocus}
          value={initialValueRef.current}
          disabled={disabled}
          placeholder={placeholder}
          onChangeMarkdownSerialized={onChange}
          dataAttributes={{ "data-testid": "textbox" }}
          shouldDisplayAttachFile={false}
          required={required}
          maxLength={maxLength}
          heightMode="stretch"
        />
        {withAttachments && (
          <x.div {...attachDivStyle}>
            <Attachments
              disabled={disabled}
              withAttachments={withAttachments}
              attachments={attachments}
              fileSizeLimit={fileSizeLimit}
              fileLimit={fileLimit}
              onAttachmentChange={onAttachmentChange}
            />
          </x.div>
        )}
      </x.div>
      {error && (
        <Typography data-testid="text-box-error-message" variant="body-small" color="danger">
          {error}
        </Typography>
      )}
    </x.div>
  );
};

export const AttachmentBox = ({
  label = "Text",
  required = false,
  disabled = false,
  withAttachments = false,
  error = null,
  attachments = [],
  fileSizeLimit = 10,
  fileLimit = 5,
  onAttachmentChange = () => {},
}) => {
  const { spacing, shape, color } = useThemeTokens();

  const wrapperDivStyle = {
    display: "inline-grid",
    borderRadius: shape.border.radius.small,
    borderWidth: shape.border.width.small,
    borderColor: error ? color.border.danger : color.border.neutral.default,
    marginTop: spacing.inner.base02,
  };

  return (
    <x.div w={useSize("full")} display="inline-grid" data-testid="attachment-box">
      <Label text={label} required={required} />
      <x.div style={wrapperDivStyle}>
        <Attachments
          disabled={disabled}
          withAttachments={withAttachments}
          attachments={attachments}
          fileSizeLimit={fileSizeLimit}
          fileLimit={fileLimit}
          onAttachmentChange={onAttachmentChange}
        />
      </x.div>
      {error && (
        <Typography
          data-testid="text-box-error-message"
          variant="body-small"
          color="danger"
          marginTop={spacing.inner.base02}
        >
          {error}
        </Typography>
      )}
    </x.div>
  );
};

export const Attachments = ({
  disabled = false,
  withAttachments = false,
  attachments = [],
  fileSizeLimit = 10,
  fileLimit = 5,
  onAttachmentChange = () => {},
  mobileVariant = false,
  setExternalError,
}: {
  disabled: boolean;
  withAttachments: boolean;
  attachments: Attachment[];
  fileSizeLimit: number;
  fileLimit: number;
  onAttachmentChange: (value: Attachment[]) => void;
  mobileVariant?: boolean;
  setExternalError?: (value: string) => void;
}) => {
  const { spacing, shape, color } = useThemeTokens();

  const [files, setFiles] = useState<Attachment[]>(attachments);
  const [attachmentError, setAttachmentError] = useState<string | undefined>();
  const fullSize = useSize("full");

  const containerDivStyle = {
    ...(!mobileVariant && {
      w: fullSize,
      display: "inline-grid",
    }),
  };

  const wrapperDivStyle = {
    display: "flex",
    ...(!mobileVariant && {
      display: "inline-grid",
    }),
  };

  const attachDivStyle = {
    display: "inline-flex",
    ...(!mobileVariant && {
      alignItems: "center",
      gap: spacing.inner.base,
      padding: spacing.inner.base03,
      paddingTop: spacing.inner.base02,
      paddingBottom: spacing.inner.base02,
    }),
  };

  const fileDivStyle = {
    ...(!mobileVariant && {
      borderBottomWidth: shape.border.width.small,
      borderBottomColor: color.border.neutral.default,
    }),
  };

  const onClickRemoveAttach = (attachment: Attachment) => {
    const newFiles = files.filter((file) => file !== attachment);
    setFiles(newFiles);
    onAttachmentChange(newFiles);
  };

  useEffect(() => {
    setFiles(attachments);
  }, [attachments]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      "application/pdf, application/msword, text/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation",
    maxSize: fileSizeLimit * 1024 * 1024,
    maxFiles: fileLimit,
    onDrop: (acceptedFiles, fileRejections) => {
      setAttachmentError(undefined);

      if (acceptedFiles.length + files.length > fileLimit) {
        setAttachmentError(`You may only attach a maximum of ${fileLimit} files`);
      } else {
        const newFiles: Attachment[] = [...files];
        acceptedFiles.forEach((file: File) => {
          const attachment = { name: file.name, size: file.size, type: file.type, valid: true, file };
          const duplicate = files.find((f: Attachment) => f.name === attachment.name);
          if (duplicate) {
            setAttachmentError("One or more files were rejected because they are duplicates");
          } else {
            newFiles.push(attachment);
          }
        });
        setFiles(newFiles);
        onAttachmentChange(newFiles);
      }

      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setAttachmentError(`File size limit of ${fileSizeLimit}MB exceeded`);
          } else if (err.code === "too-many-files") {
            setAttachmentError(`You may only select up to ${fileLimit} files at a time`);
          } else if (err.code === "file-invalid-type") {
            setAttachmentError("Unsupported file type");
          }
        });
      });
    },
  });

  useEffect(() => {
    setAttachmentError(undefined);
  }, [isDragActive]);

  useEffect(() => {
    if (setExternalError) {
      setExternalError(attachmentError || "");
    }
  }, [attachmentError, setExternalError]);

  return (
    <x.div {...containerDivStyle}>
      <x.div style={wrapperDivStyle}>
        {!mobileVariant && (
          <>
            {files.map((attachment, index) => (
              <x.div key={`${attachment.name}-${index}`} {...fileDivStyle} {...attachDivStyle}>
                <AttachmentDisplay attachment={attachment} disabled={disabled} onClickRemove={onClickRemoveAttach} />
              </x.div>
            ))}
          </>
        )}
        {withAttachments && !disabled && (
          <x.div {...getRootProps()} style={attachDivStyle} cursor={isDragActive ? "default" : "pointer"}>
            <input {...getInputProps()} data-testid="attachment-input" accept=".txt,.csv,.pdf,.docx,.doc,.xlsx,.pptx" />
            {mobileVariant ? (
              <IconButton size="small" variant="outline" key="rounded-button">
                <AttachmentIcon />
              </IconButton>
            ) : (
              <>
                <Icon color="secondary">
                  <AttachmentIcon />
                </Icon>
                <Typography variant="body-em" color="secondary">
                  {isDragActive ? "Drop your file here" : "Attach"}
                </Typography>
              </>
            )}
            {attachmentError && !setExternalError && (
              <Typography variant="body-small" color="danger" margin="auto">
                {attachmentError}
              </Typography>
            )}
          </x.div>
        )}
      </x.div>
    </x.div>
  );
};
