import { SEARCH_SECTIONS } from "constants/AlphaNow";

const PLACEHOLDER = "Search";

const MIN_SEARCH_WIDTH = "252px";
const MAX_SEARCH_WIDTH = "715px";

const NUMBER_OPTIONS_PER_SECTION = 8;

const INACTIVE_SEARCHBAR_COLOR = "rgba(255, 255, 255, 0.35)";

const SEARCH_ERROR_MESSAGE_PER_SECTION = {
  [SEARCH_SECTIONS.ResearchLibrary]: "Failed to load more suggestions. Please try again later.",
  [SEARCH_SECTIONS.Project]: "Failed to return project suggestions. Please try again later.",
  [SEARCH_SECTIONS.RecentSearch]: "",
};

const SEARCH_FAILURE_MESSAGE = "Failed to return suggestions. Please try again later.";

export {
  PLACEHOLDER,
  NUMBER_OPTIONS_PER_SECTION,
  SEARCH_ERROR_MESSAGE_PER_SECTION,
  SEARCH_FAILURE_MESSAGE,
  INACTIVE_SEARCHBAR_COLOR,
  MIN_SEARCH_WIDTH,
  MAX_SEARCH_WIDTH,
};
