import { useMemo, useState } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Globe, Industry, Info, Products } from "@alphasights/alphadesign-icons";
import { LabeledIcon } from "pages/AlphaNowPage/primers/components";
import { useMarketPrimersStore } from "../../state/store";
import CompetitorsList from "./CompetitorsList";
import FiltersList from "./FiltersList";
import { CompetitorsData, CompetitorRelatedData, Filter } from "./types";
import useGetCompanyProperties from "pages/AlphaNowPage/primers/hooks/useGetCompanyProperties";

const OverviewCompetitorsDiagram = () => {
  const { color, spacing } = useThemeTokens();
  const { companies, companiesV2, competitorDynamics, segmentation } = useMarketPrimersStore();
  const { getCompanyProperties } = useGetCompanyProperties(companies, companiesV2);
  // when select a competitor > highlight filters
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [matchingFilters, setMatchingFilters] = useState<Filter[]>([]);
  // when select a filter > highlight competitors
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>();
  const [matchingCompanies, setMatchingCompanies] = useState<string[]>([]);

  const segmentToCompetitorData = ({ name, notableCompetitors }: Segment) => {
    return {
      name: name.value ?? "",
      companies: notableCompetitors.map((competitor) => ({
        name: getCompanyProperties(
          competitor.companyNameId && competitor.companyNameId.value
            ? competitor.companyNameId.value
            : competitor.companyName.value
        ).name,
        logoLink: getCompanyProperties(
          competitor.companyNameId && competitor.companyNameId.value
            ? competitor.companyNameId.value
            : competitor.companyName.value
        ).logoLink,
      })),
    };
  };

  const addToCompetitorList = (
    currentList: CompetitorRelatedData[],
    { name }: Segment,
    type: string,
    companyName: string | undefined
  ) => {
    const competitor = currentList.find((competitor) => competitor.name === companyName);

    if (competitor && name.value) {
      switch (type) {
        case "customers":
          competitor.customers.push(name.value);
          break;
        case "products":
          competitor.products.push(name.value);
          break;
        case "regions":
          competitor.regions.push(name.value);
          break;
        default:
          break;
      }
    }
  };

  const competitorsData: CompetitorsData = useMemo(() => {
    const { customers, regions, products } = segmentation;

    return {
      customers: customers.map((customer) => segmentToCompetitorData(customer)),
      regions: regions.map((region) => segmentToCompetitorData(region)),
      products: products.map((product) => segmentToCompetitorData(product)),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentation]);

  const competitors: CompetitorRelatedData[] = useMemo(() => {
    const data = competitorDynamics.map(
      ({ companyId, companyName, otherNotableCompetitor }) =>
        ({
          id: companyId,
          name: companyName,
          logoLink: getCompanyProperties(companyName).logoLink,
          isLeader: !otherNotableCompetitor,
          customers: [],
          products: [],
          regions: [],
        } as CompetitorRelatedData)
    );

    segmentation.customers.forEach((customer) => {
      customer.notableCompetitors.forEach(({ companyName }) => {
        addToCompetitorList(data, customer, "customers", companyName.value);
      });
    });

    segmentation.regions.forEach((region) => {
      region.notableCompetitors.forEach(({ companyName }) => {
        addToCompetitorList(data, region, "regions", companyName.value);
      });
    });

    segmentation.products.forEach((product) => {
      product.notableCompetitors.forEach(({ companyName }) => {
        addToCompetitorList(data, product, "products", companyName.value);
      });
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentation]);

  const competitorSelected = (companyId: string) => {
    const isUnselection = selectedCompany === companyId;
    const competitor = competitors.find(({ name }) => name === companyId);

    setSelectedFilter(null);
    setMatchingCompanies([]);
    setSelectedCompany(isUnselection ? null : companyId);
    setMatchingFilters(
      !competitor || isUnselection
        ? []
        : [
            ...competitor.customers.map((customer) => ({
              type: "customer",
              value: customer,
            })),
            ...competitor.products.map((product) => ({
              type: "product",
              value: product,
            })),
            ...competitor.regions.map((region) => ({
              type: "region",
              value: region,
            })),
          ]
    );
  };

  const filterSelected = (filter: Filter): void => {
    const isUnselection =
      selectedFilter && selectedFilter.type === filter.type && selectedFilter.value === filter.value;

    setSelectedCompany(null);
    setMatchingFilters([]);
    setSelectedFilter(isUnselection ? null : filter);

    if (isUnselection) {
      setMatchingCompanies([]);
      return;
    }

    switch (filter.type) {
      case "customer":
        const customer = competitorsData.customers.find(({ name }) => name === filter.value);

        setMatchingCompanies(customer ? customer.companies.map((company) => company.name) : []);
        break;
      case "product":
        const product = competitorsData.products.find(({ name }) => name === filter.value);

        setMatchingCompanies(product ? product.companies.map((company) => company.name) : []);
        break;
      case "region":
        const region = competitorsData.regions.find(({ name }) => name === filter.value);

        setMatchingCompanies(region ? region.companies.map((company) => company.name) : []);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <LabeledIcon labelText="Click on a pill to see companies' relevant market segments" iconItem={<Info />} />
      <x.div display="grid" gridTemplateColumns="60% 40%">
        <x.div paddingRight={spacing.layout.base06}>
          <CompetitorsList
            competitors={competitors.filter(({ isLeader }) => isLeader)}
            filterMatchingCompanies={matchingCompanies}
            selectedCompany={selectedCompany}
            selectedFilter={selectedFilter}
            title="Leading Competitors"
            isLeading
            onClick={competitorSelected}
          />
          <CompetitorsList
            competitors={competitors.filter(({ isLeader }) => !isLeader)}
            filterMatchingCompanies={matchingCompanies}
            mt={spacing.layout.base02}
            selectedCompany={selectedCompany}
            selectedFilter={selectedFilter}
            title="Other Notable Competitors"
            onClick={competitorSelected}
          />
        </x.div>
        <x.div paddingLeft={spacing.layout.base06} borderLeft={`1px solid ${color.border.divider}`}>
          {competitorsData.customers.length > 0 && (
            <FiltersList
              competitors={competitorsData.customers}
              filterType="customer"
              iconItem={<Industry />}
              matchingFilters={matchingFilters}
              selectedCompany={selectedCompany}
              selectedFilter={selectedFilter}
              title="Customers"
              onClick={filterSelected}
            />
          )}
          {competitorsData.products.length > 0 && (
            <FiltersList
              competitors={competitorsData.products}
              filterType="product"
              iconItem={<Products />}
              matchingFilters={matchingFilters}
              mt={competitorsData.customers.length > 0 ? spacing.layout.base04 : "0"}
              selectedCompany={selectedCompany}
              selectedFilter={selectedFilter}
              title="Products"
              onClick={filterSelected}
            />
          )}
          {competitorsData.regions.length > 0 && (
            <FiltersList
              competitors={competitorsData.regions}
              filterType="region"
              iconItem={<Globe />}
              matchingFilters={matchingFilters}
              mt={
                competitorsData.customers.length > 0 || competitorsData.products.length > 0
                  ? spacing.layout.base04
                  : "0"
              }
              selectedCompany={selectedCompany}
              selectedFilter={selectedFilter}
              title="Regions"
              onClick={filterSelected}
            />
          )}
        </x.div>
      </x.div>
    </>
  );
};

export default OverviewCompetitorsDiagram;
