import { FC } from "react";
import { ButtonProps, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Upload } from "@alphasights/alphadesign-icons";

import { ACCEPTED_FILE_FORMATS_TEXT, UPLOAD_DOCUMENTS_TEXT } from "./consts";

import * as S from "./UploadDocsButton.styled";

type UploadDocsButtonProps = {
  onClick: () => void;
} & ButtonProps;

const UploadDocsButton: FC<UploadDocsButtonProps> = ({ onClick, ...props }) => {
  const { color } = useThemeTokens();

  return (
    <S.StyledUploadButton onClick={onClick} {...props}>
      <S.ContentsWrapper>
        <S.StyledIconButton onClick={onClick}>
          <Upload />
        </S.StyledIconButton>
        <S.TextWrapper>
          <Typography variant="body-small-em" component="span" color={color.text.strong._}>
            {UPLOAD_DOCUMENTS_TEXT}
          </Typography>
          <Typography variant="body-small" component="span" color={color.text.secondary}>
            {ACCEPTED_FILE_FORMATS_TEXT}
          </Typography>
        </S.TextWrapper>
      </S.ContentsWrapper>
    </S.StyledUploadButton>
  );
};

export default UploadDocsButton;
