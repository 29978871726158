import { PageFooter } from "@alphasights/client-portal-shared";
import styled, { th, x } from "@xstyled/styled-components";

export const CalendarWrapper = styled(x.div)`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  background: ${th.color("background-action-outline-default")};
`;

export const FlyoutPanel = styled(x.div)<{ isFlyoutOpen: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  border: 0;
  border-right: 1px solid ${th.color("border-divider")};
  background-color: ${th.color("background-decorative-base02")};
  display: flex;
  width: 525px;
  margin-left: ${({ isFlyoutOpen }) => (isFlyoutOpen ? "-525px" : "0")};
  opacity: ${({ isFlyoutOpen }) => (isFlyoutOpen ? 0 : 1)};
  pointer-events: ${({ isFlyoutOpen }) => (isFlyoutOpen ? "none" : "auto")};
  transition: margin-left 0.1s, opacity 0.1s;
`;

export const ContainerWrapper = styled(x.div)`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
`;

export const CalendarPageFooter = styled(PageFooter)`
  margin-top: auto;
`;
