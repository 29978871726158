import { TranscriptMention, VendorItem } from "@alphasights/portal-api-client";
import { ThirdPartyInteraction } from "types";
import { getDefaultExpert } from "utils/thirdPartyDocumentsUtils";

export function sortByTargetAndAnchor(
  companies: VendorItem[],
  anchorCompanies: AlphaCompany[],
  targetCompanies: AlphaCompany[]
): VendorItem[] {
  const targetSet = new Set(targetCompanies.map((c) => c.cdsAlphaCompanyId));
  const anchorSet = new Set(anchorCompanies.map((c) => c.cdsAlphaCompanyId));

  return companies.sort((a, b) => {
    const inTargetA = targetSet.has(a.cdsAlphaCompanyId || "");
    const inTargetB = targetSet.has(b.cdsAlphaCompanyId || "");
    const inAnchorA = anchorSet.has(a.cdsAlphaCompanyId || "");
    const inAnchorB = anchorSet.has(b.cdsAlphaCompanyId || "");

    if (inAnchorA && !inAnchorB) return -1;
    if (!inAnchorA && inAnchorB) return 1;
    if (inTargetA && !inTargetB) return -1;
    if (!inTargetA && inTargetB) return 1;
    return 0;
  });
}

export const getAdvisorCompanyForTranscriptMention = (mention: TranscriptMention, document?: ThirdPartyInteraction) => {
  if (mention.thirdPartyDocumentId && document) {
    return getDefaultExpert(document.attribute.experts).companyName;
  }
  return mention.advisorCompany || "N/A";
};

export const getRoleForTranscriptMention = (mention: TranscriptMention, document?: ThirdPartyInteraction) => {
  if (mention.thirdPartyDocumentId && document) {
    return getDefaultExpert(document.attribute.experts).position;
  }
  return mention.role || "N/A";
};

export const getTitleForTranscriptMention = (mention: TranscriptMention, document?: ThirdPartyInteraction) => {
  const role = getRoleForTranscriptMention(mention, document);
  const company = getAdvisorCompanyForTranscriptMention(mention, document);

  if (document && (!role || role === "N/A") && (!company || company === "N/A"))
    return document.redactedFilename || document.originalFilename || "Uploaded Document";

  return `${company} - ${role}`;
};
