import React, { FC, ForwardedRef, forwardRef, useCallback, useMemo } from "react";
import { uniq, map } from "lodash";
import { Avatar } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";

import { TranscriptFragment } from "services/thirdPartyDocumentsService";
import { ThirdPartyDocumentExpert } from "types";
import { getSpeakerName } from "./utils";
import { AVATAR_COLORS } from "./constants";

import * as S from "./TranscriptViewer.styled";

export const DataTestIds = {
  TRANSCRIPT_VIEWER: "transcript-viewer",
};

type TranscriptViewerProps = {
  onError: () => void;
  ref: ForwardedRef<HTMLDivElement>;

  fragments?: TranscriptFragment[];
  experts?: ThirdPartyDocumentExpert[];
};

const TranscriptViewer: FC<TranscriptViewerProps> = forwardRef(
  ({ fragments, onError, experts = [] }, ref: ForwardedRef<HTMLDivElement>) => {
    const orderedUniqueSpeakers = useMemo(() => uniq(map(fragments, "sanitized_source")), [fragments]);

    const _getSpeakerName = useCallback(
      (speaker: string) => {
        const uniqueSpeakerIndex = orderedUniqueSpeakers.indexOf(speaker);
        const expert = uniqueSpeakerIndex > 0 ? experts[uniqueSpeakerIndex - 1] : {};
        return getSpeakerName(speaker, expert?.companyName, expert?.position);
      },
      [orderedUniqueSpeakers, experts]
    );

    const render = () => {
      if (fragments?.length) {
        try {
          return (
            <S.TranscriptWrapper data-testid={DataTestIds.TRANSCRIPT_VIEWER} ref={ref}>
              {fragments.map((fragment) => {
                const { sanitized_source: speaker, content, index } = fragment;
                const speakerName = _getSpeakerName(speaker);
                const avatarColor = AVATAR_COLORS[speaker as keyof typeof AVATAR_COLORS];
                return (
                  <Fragment key={`fragment-${index}`} color={avatarColor} speaker={speakerName} content={content} />
                );
              })}
            </S.TranscriptWrapper>
          );
        } catch (error) {
          console.error("Error rendering transcript", error);
          onError();
        }
      }
      return null;
    };

    return render();
  }
);

const Fragment = ({ color, speaker, content }: { color: any; speaker: string; content: string }) => (
  <S.FragmentWrapper>
    <S.SpeakerWrapper>
      <Avatar size="small" color={color}>
        <Expert />
      </Avatar>
      <S.SpeakerTypography>{speaker}</S.SpeakerTypography>
    </S.SpeakerWrapper>
    <S.FragmentTypography>{content}</S.FragmentTypography>
  </S.FragmentWrapper>
);

export default TranscriptViewer;
