import { useEffect, useState } from "react";
import { Cookie } from "../Cookie";
import { toastError } from "@alphasights/alphadesign-components";

const responseCheck = (response: Response) => {
  if ((response as any).error || response.status < 200 || response.status > 399) {
    throw response;
  }
  return response;
};

export interface FetchProps {
  url: string;
  skipAlert?: boolean;
  headers?: Object;
  customHeaders?: Object;
  handleForbidden?: boolean;
  method?: string;
  body?: any;
}

export const fetch = ({
  url: resource,
  skipAlert = false,
  headers = {
    "content-type": "application/json",
    "X-COOKIE-POLICY-STATE": Cookie.findByName("_iub_cs-"),
  },
  customHeaders = {},
  handleForbidden = false,
  ...init
}: FetchProps) => {
  const props = {
    ...init,
    headers: { ...headers, ...customHeaders },
  };

  return window
    .fetch(resource, props as RequestInit)
    .then((res) => responseCheck(res))
    .catch((err) => {
      if (err.status === 401) {
        window.location.href = `/sign-in`;
        return Promise.reject(err);
      }
      if (err.status === 403 && handleForbidden) {
        toastError({ message: "Action failed: Forbidden. Please refresh your page." });
      } else if (!skipAlert) {
        toastError({ message: "An error has occurred: please try refreshing." });
      }
      return Promise.reject(err);
    });
};

export const useApi = (props: FetchProps, dependencies: any[]) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    async function run() {
      try {
        setIsLoading(true);

        const res = await fetch(props);
        const body = await res.json();

        if (res.ok) {
          setError(null);
          setData(body);
        } else {
          setData(null);
          setError({
            status: res.status,
            ...error,
          });
        }
      } catch (error) {
        setData(null);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    run();
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return [isLoading, data, error, setData, setIsLoading];
};
