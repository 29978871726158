import { useTextAreaStyles } from "./TextArea.styles";
import { x } from "@xstyled/styled-components";
import { Typography } from "@alphasights/alphadesign-components";

export const TextArea = ({ label, ...props }) => {
  const { textArea, textAreaLabel, textAreaContainer } = useTextAreaStyles();

  return (
    <x.div {...textAreaContainer}>
      {label && <Typography {...textAreaLabel}>{label}</Typography>}
      <x.textarea {...textArea} {...props} />
    </x.div>
  );
};
