import styled, { x, th } from "@xstyled/styled-components";
import {
  IconButton,
  SelectOption,
  SelectSearch,
  SelectValue,
  Tooltip,
  Typography,
} from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";
import { useState } from "react";
import { isEmpty } from "lodash";
import { ClientContactsService } from "services/clientContactsService";
import { ClientContact } from "models/ClientContact";
import { useAsyncDebounce } from "hooks/useDebounce";
import { useProjectLauncherContext } from "providers/ProjectLauncherProvider";
import { ContentContainer } from "@alphasights/client-portal-shared";

export const Colleagues = () => {
  const { clientContacts: selection, onClientContactsChange: setSelection } = useProjectLauncherContext();
  const [options, setOptions] = useState<ClientContact[]>([]);

  const search = async (value: string): Promise<ClientContact[]> => {
    return ClientContactsService.getUserContacts(value);
  };

  const handleSearch = useAsyncDebounce({
    promise: async (value: string) => {
      if (isEmpty(value)) return;
      search(value).then((data) => {
        setOptions(data);
      });
    },
    delay: 200,
  });

  const optionItems = [
    ...options.map(({ id, name }) => (
      <SelectOption key={id} value={id}>
        <span>{name}</span>
      </SelectOption>
    )),
  ];

  const handleChange = (selectedIds: SelectValue | SelectValue[]) => {
    const selectedIdsSet = new Set(selectedIds as number[]);

    const selectedOptions = selectedIdsSet.size
      ? [...options.filter(({ id }) => selectedIdsSet.has(id)), ...selection.filter(({ id }) => selectedIdsSet.has(id))]
      : [];

    const uniqueSelectedOptions = [...new Map(selectedOptions.map((item) => [item.id, item])).values()];
    setSelection(uniqueSelectedOptions);
  };

  const removeContact = (id: number) => {
    const selected = selection.filter((company) => company.id !== id);
    setSelection(selected);
  };

  const renderSelectionItems = () => {
    const selectionItems = selection.map((contact) => (
      <x.div display="flex" justifyContent="space-between" paddingTop="8px" paddingBottom="8px">
        <x.div>
          <Typography variant="body-em">{contact.name}</Typography>
          <Typography variant="body-small" color="secondary">
            {contact.email}
          </Typography>
        </x.div>
        <Tooltip title="Remove colleague from project">
          <IconButton
            variant="outline"
            size="small"
            onClick={() => removeContact(contact.id)}
            dataAttributes={{ "data-testid": "remove-contact-button" }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </x.div>
    ));

    return selectionItems;
  };

  return (
    <ContentContainerStyled>
      <x.div display="flex" flexDirection="column" gap="16px" data-testid="colleagues-section">
        <SelectSearch
          size="small"
          onInputValueChange={handleSearch}
          label={
            <Typography variant="body-em" component="span">
              Colleagues
            </Typography>
          }
          allowMultiple
          isClearButtonEnabled={false}
          placeholder="Search for colleagues"
          allowAddNewItems={true}
          shouldDisplayDropdownPopover={true}
          hideResultsIfSearchEmpty={true}
          keepInputFocusedAfterSelect={false}
          matchFunction={() => true}
          resetInputValueAfterSelect={true}
          onChange={handleChange}
          value={selection.map(({ id }) => id)}
          dataAttributes={{ "data-testid": "colleagues-select" }}
        >
          {optionItems}
        </SelectSearch>
        <x.div display="flex" gap="8px" flexDirection="column">
          {renderSelectionItems()}
        </x.div>
      </x.div>
    </ContentContainerStyled>
  );
};

const ContentContainerStyled = styled(ContentContainer)`
  border-top: transparent;
  border-radius: 0 0 ${th.space("inner-base03")} ${th.space("inner-base03")};
  padding: ${th.space("inner-base06")} ${th.space("inner-base06")} ${th.space("inner-base")};
`;
