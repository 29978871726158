import { useRef } from "react";
import { x } from "@xstyled/styled-components";
import { Expert } from "@alphasights/alphadesign-icons";
import { Avatar, Button, Loading, Typography } from "@alphasights/alphadesign-components";
import { useProjectMembersContext } from "providers/ProjectMembersProvider";
import useOnClickOutside from "hooks/useOnClickHooks";
import { Popover } from "components/Popover";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

const { color } = tokens;

export const ProjectMembersPopover = ({ open, onClose, filter, onUserSelect, anchorRef, children }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => {
    onClose && onClose();
  });

  return (
    <Popover
      disableArrow
      disableHover
      borderless
      placement="top"
      isActive={open}
      body={<LoadingOrList onUserSelect={onUserSelect} filter={filter} w={anchorRef.current?.offsetWidth} />}
    >
      {children}
    </Popover>
  );
};

const LoadingOrList = ({ onUserSelect, filter, w }) => {
  const { loading } = useProjectMembersContext();
  return <x.div w={w}> {loading ? <LoadingUsers /> : <UserList onUserSelect={onUserSelect} filter={filter} />}</x.div>;
};

const LoadingUsers = () => <Loading size="sm" style={{ margin: "auto" }} data-testid="members-loading" />;

const UserList = ({ filter, onUserSelect }) => {
  const { itemStyle, itemTextStyle, dividerStyle, avatarStyle, userListStyles } = useProjectMembersStyle();

  const { getFilteredClients, getFilteredCsts } = useProjectMembersContext();

  const filteredClients = getFilteredClients(filter);
  const filteredCsts = getFilteredCsts(filter);

  return (
    <x.div data-testid="members-popover">
      <x.div {...userListStyles}>
        <Typography {...dividerStyle}>Colleagues</Typography>
        {filteredClients.length > 0 ? (
          filteredClients.map((u) => (
            <Button key={`user-tag-option-${u.displayName}`} onClick={() => onUserSelect(u)} {...itemStyle}>
              <Typography {...itemTextStyle} component="div">
                <Avatar {...avatarStyle}>
                  <Expert />
                </Avatar>
                <x.div>{u.displayName}</x.div>
              </Typography>
            </Button>
          ))
        ) : (
          <Typography p={1}>No Matches</Typography>
        )}
      </x.div>
      <x.div {...userListStyles}>
        <Typography {...dividerStyle}>AlphaSights Team</Typography>
        {filteredCsts.length > 0 ? (
          filteredCsts.map((u) => (
            <Button key={`user-tag-option-${u.displayName}`} onClick={() => onUserSelect(u)} {...itemStyle}>
              <Typography {...itemTextStyle} component="div">
                <Avatar {...avatarStyle} src={u.avatarUrl} text={u.displayName} />
                <x.div>{u.displayName}</x.div>
              </Typography>
            </Button>
          ))
        ) : (
          <Typography p={1}>No Matches</Typography>
        )}
      </x.div>
    </x.div>
  );
};

const useProjectMembersStyle = () => {
  const itemStyle = {
    justifyContent: "flex-start",
    textAlign: "left",
    ml: 2,
    variant: "ghost",
    p: 1,
    w: "full",
    bg: { hover: color.background.surface.page.hover },
  };
  const itemTextStyle = {
    alignItems: "center",
    variant: "body",
    color: color.text.strong._,
    display: "inline-flex",
  };
  const dividerStyle = {
    p: 1,
    ml: 3,
    variant: "body-small-em",
    color: color.text.secondary,
  };
  const avatarStyle = {
    size: "small",
    backgroundColor: color.background.neutral.default,
    mr: 2,
    style: { color: color.text.secondary },
  };
  const userListStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  };

  return {
    itemStyle,
    itemTextStyle,
    dividerStyle,
    avatarStyle,
    userListStyles,
  };
};
