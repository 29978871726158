import { useEffect, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import { MessengerProvider, useMessengerContext } from "providers/MessengerProvider";
import { MobileMessageList } from "./MobileMessageList";
import { IconButton, Portal } from "@alphasights/alphadesign-components";
import { Mail } from "@alphasights/alphadesign-icons";
import { MobileNewMessage } from "./MobileNewMessage";
import { MobileMessengerContent } from "./MobileMessengerContent/MobileMessengerContent.component";
import { MessageType } from "types";
import { useFixedWhileInBackground } from "utils/mobileHacks";
import { useLocation } from "router-utils";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ProjectFeature } from "@alphasights/portal-api-client";

export const MobileMessengerPage = ({ project, interactions, onSendNewMessageFromTableView, loading }) => {
  return (
    <MessengerProvider
      projectToken={project.token}
      interactions={interactions}
      onSendNewMessageFromTableView={onSendNewMessageFromTableView}
      interactionsLoading={loading}
    >
      <MobileMessengerPageContent project={project} interactions={interactions} />
    </MessengerProvider>
  );
};

const MobileMessengerPageContent = ({ project, interactions }) => {
  const [openMessageDetails, setOpenMessageDetails] = useState(false);
  const { newMessageType, onSendNewMessage, inboxes, inboxLoading, onSelectThread } = useMessengerContext();
  const location = useLocation();
  const pageRef = useRef();
  const contentRef = useRef();
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isSendMessagesDisabled = isFeatureDisabled(ProjectFeature.SendMessages);

  useFixedWhileInBackground({
    refToFix: pageRef,
    isInBackground: (newMessageType && inboxes.length > 0) || openMessageDetails,
  });

  useEffect(() => {
    const selectedThread = new URLSearchParams(location.search).get("selectedThread");
    if (selectedThread) {
      onSelectThread(selectedThread);
      setOpenMessageDetails(true);
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <x.div data-testid="mobile-messenger-page" ref={pageRef}>
        <PortalMobileTopBar
          title={project.title}
          regularButtons={
            inboxes.length === 0 || isSendMessagesDisabled ? undefined : (
              <IconButton
                size="large"
                variant="ghost"
                onClick={() => onSendNewMessage(MessageType.WorkRequest)}
                testId="new-message-button"
              >
                <Mail />
              </IconButton>
            )
          }
        />
        <x.div display="flex" flexGrow={1} flexDirection="row" minH="0" ref={contentRef}>
          {!inboxLoading && <MobileMessageList onCardClick={() => setOpenMessageDetails(true)} />}
        </x.div>
      </x.div>
      {newMessageType && !inboxLoading && (
        <Portal {...(inboxes.length === 0 && { containerEl: contentRef.current })}>
          <MobileNewMessage
            onClose={() => onSendNewMessage(undefined)}
            attachTextBoxToNavbar={inboxes.length === 0}
            onSent={() => setOpenMessageDetails(true)}
          />
        </Portal>
      )}
      {openMessageDetails && !inboxLoading && (
        <Portal>
          <MobileMessengerContent onClose={() => setOpenMessageDetails(false)} />
        </Portal>
      )}
    </>
  );
};
