import React from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { StyledTypography, TranscriptHeaderContainer } from "./TranscriptHeader.styled";
import TranscriptTools from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/TranscriptTools";
import {
  CopyButtonProps,
  WatchlistButtonProps,
} from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/TranscriptTools/types";
import { BookmarkButtonProps } from "components/BookmarkButton";

const DataTestIds = {
  transcriptHeader: "transcript-header",
};

type TranscriptHeaderProps = {
  title: string;
  transcriptHeaderToolsProps: {
    bookmarkButtonProps?: BookmarkButtonProps;
    copyLinkButtonProps?: CopyButtonProps;
    watchlistButtonProps: WatchlistButtonProps;
  };
};

const TranscriptHeader = ({ title, transcriptHeaderToolsProps }: TranscriptHeaderProps) => {
  const { isMobile } = useCheckScreen();
  return (
    <TranscriptHeaderContainer data-testid={DataTestIds.transcriptHeader}>
      <StyledTypography>{title}</StyledTypography>
      {!isMobile && <TranscriptTools {...transcriptHeaderToolsProps} />}
    </TranscriptHeaderContainer>
  );
};

export { TranscriptHeader as default, DataTestIds };
