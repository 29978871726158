import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { RoundedButton as AdsRoundedButton } from "@alphasights/alphadesign-components";

const {
  spacing: { inner },
  color: { background },
} = tokens;

export const RoundedButton = styled(AdsRoundedButton)(
  ({ open }: { open: boolean }) => `
    height: ${inner.base08};
    ${open ? `background-color: ${background.action.outline.pressed};` : ""}
  `
);
