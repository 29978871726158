import { useEffect } from "react";
import * as S from "./BillingAgreementSuccess.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { SharedProject } from "models/SharedProjects";
import { Header } from "../Header";
import { Button, Tile, Typography } from "@alphasights/alphadesign-components";
import { Tick } from "@alphasights/alphadesign-icons";
import { sharedProjectsService } from "services/sharedProjectsService";

export const BillingAgreementSuccess = ({ sharedProject }: { sharedProject: SharedProject }) => {
  const navigate = useNavigate();
  const { email } = useLocation().state as { email?: string };

  useEffect(
    function redirectToFormIfNotSignedAndNoState() {
      if (!sharedProject.signed && !email) {
        navigate(`/project-sharing/${sharedProject.token}`, { replace: true });
      }
    },
    [email, navigate, sharedProject.signed, sharedProject.token]
  );

  return (
    <S.Wrapper>
      <Header percentage={100} />
      <S.ContentWrapper>
        <Tile variant="icon" color="success" icon={<Tick />} size="large" />
        <Typography variant="body-em">Billing confirmation successfully signed.</Typography>
        <Typography>{email ?? sharedProject.clientEmail}</Typography>
        <Button
          variant="outline"
          size="small"
          data-testid="billing-confirmation-pdf-button"
          onClick={() => sharedProjectsService.openPDFConfirmation(sharedProject.token)}
        >
          View billing confirmation
        </Button>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
