import { createContext, useContext, useRef, ReactNode, FC, useCallback } from "react";
import * as React from "react";

interface ScrollProviderProps {
  children: ReactNode;
}

interface ScrollContextType {
  targetRef: React.RefObject<HTMLDivElement> | null;
  containerRef: React.RefObject<HTMLDivElement> | null;
  scrollToElement: () => void;
}

const initialContextState = {
  scrollToElement: () => {},
  targetRef: null,
  containerRef: null,
};

const ScrollContext = createContext<ScrollContextType>(initialContextState);

const cardGap = 12;

const ScrollProvider: FC<ScrollProviderProps> = ({ children }) => {
  const targetRef = useRef(null);
  const containerRef = useRef(null);

  const scrollToElement = useCallback(() => {
    setTimeout(() => {
      if (targetRef.current && containerRef.current) {
        // distance between selected card and window top
        const cardTop = (targetRef.current as any).offsetTop;
        // distance between search results container and window top
        const containerTop = (containerRef.current as any).offsetTop;
        // distance between selected card and top of search results container
        const offset = cardTop - containerTop - cardGap;

        (containerRef.current as any).scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }, 0);
  }, []);

  return (
    <ScrollContext.Provider value={{ targetRef, containerRef, scrollToElement }}>{children}</ScrollContext.Provider>
  );
};

const useScroll = () => useContext(ScrollContext);

export { ScrollProvider, useScroll };
