import { createContext, useContext, useMemo } from "react";
import { useReducerCallbacks } from "components/InteractionsPage/reducer";
export const ProjectInteractionsContext = createContext();

export const ProjectInteractionsProvider = (props) => {
  const { workstreamId, state, dispatch, originalDispatch, viewProps } = props;

  const allInteractionsForProject = useMemo(() => state.projectWithInteractions?.interactions || [], [
    state.projectWithInteractions.interactions,
  ]);

  const allInteractionsForProjectIsNull = useMemo(() => !state.projectWithInteractions?.interactions, [
    state.projectWithInteractions.interactions,
  ]);

  const callbacks = useReducerCallbacks({ dispatch, state, originalDispatch });

  const context = {
    ...callbacks,
    ...viewProps,
    dispatch,
    state,
    workstreamId,
    allInteractionsForProject,
    allInteractionsForProjectIsNull,
  };

  return <ProjectInteractionsContext.Provider value={context} {...props} />;
};

export const useProjectInteractionsContext = () => useContext(ProjectInteractionsContext);
