import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";
const Audio = forwardRef(({ src, children, onEnded, onTimeUpdate, onLoadedMetadata, onError }, ref) => {
  const audioRef = useRef(null);

  const withAudio = (fn) => {
    if (audioRef.current) {
      fn(audioRef.current);
    }
  };

  useEffect(() => {
    withAudio((audio) => {
      audio.addEventListener("ended", onEnded);
      audio.addEventListener("timeupdate", onTimeUpdate);
      audio.addEventListener("loadedmetadata", onLoadedMetadata);
      audio.addEventListener("error", onError);
    });

    return () => {
      withAudio((audio) => {
        audio.removeEventListener("ended", onEnded);
        audio.removeEventListener("timeupdate", onTimeUpdate);
        audio.removeEventListener("loadedmetadata", onLoadedMetadata);
        audio.removeEventListener("error", onError);
      });
    };
  }, [onTimeUpdate, onEnded, onLoadedMetadata, onError]);

  // Exposed functions
  const seek = (val) => {
    withAudio((audio) => (audio.currentTime = val));
  };

  const changeSpeed = (val) => {
    withAudio((audio) => (audio.playbackRate = val));
  };

  const play = () => {
    withAudio((audio) => audio.play());
  };

  const pause = () => {
    withAudio((audio) => audio.pause());
  };

  const onDragEnded = () => {
    withAudio((audio) => {
      audio.addEventListener("timeupdate", onTimeUpdate);
    });
  };

  const onDragWillStart = () => {
    withAudio((audio) => {
      audio.removeEventListener("timeupdate", onTimeUpdate);
    });
  };

  useImperativeHandle(ref, () => {
    return {
      seek: seek,
      changeSpeed: changeSpeed,
      play: play,
      pause: pause,
      onDragEnded: onDragEnded,
      onDragWillStart: onDragWillStart,
    };
  });

  return (
    <audio nocontrols="true" ref={audioRef} src={src}>
      {children}
    </audio>
  );
});

const Track = ({ onCueChange, onLoad, ...props }) => {
  const trackRef = useRef(null);
  useEffect(() => {
    const track = trackRef.current;

    if (track) {
      track.addEventListener("cuechange", onCueChange);
      track.addEventListener("load", onLoad);
    }

    return () => {
      if (track) {
        track.removeEventListener("cuechange", onCueChange);
        track.removeEventListener("load", onLoad);
      }
    };
  });

  return <track ref={trackRef} {...props} />;
};

export const parseCue = (cue) => {
  const cueParts = cue.text.match(/<v(.+?)>([\S\s]+)<\/v>/);

  const speaker = cueParts ? cueParts[1].trim() : null;
  const text = cueParts ? cueParts[2] : cue.text;

  return {
    id: cue.id,
    startTime: cue.startTime,
    endTime: cue.endTime,
    text: text,
    speaker: speaker,
  };
};

export const useAudioPlayer = ({ audioUrl, vttUrl, disabled }) => {
  const [activeCues, setActiveCues] = useState(null);
  const [transcriptSections, setTranscriptSections] = useState(null);
  const [isDraggingProgressBar, setIsDraggingProgressBar] = useState(false);
  const [draggingValue, setDraggingValue] = useState(null);

  const onCueChange = (event) => {
    setActiveCues(Object.values(event.target.track.activeCues).map(parseCue));
  };

  const onTrackLoad = (event) => {
    const parsedCues = Object.values(event.target.track.cues).map(parseCue);

    let transcriptSections = [];

    let speakerChange = null;
    let cues = [];

    parsedCues.forEach((cue) => {
      if (cue.speaker) {
        if (speakerChange && cues.length > 0) {
          transcriptSections.push({ speakerChange: speakerChange, cues: cues });
        }

        speakerChange = { speaker: cue.speaker, timestamp: cue.startTime };
        cues = [];
      }

      cues.push(cue);
    });

    transcriptSections.push({ speakerChange: speakerChange, cues: cues });
    setTranscriptSections(transcriptSections);
  };

  const audioRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggle = () => {
    if (!disabled) {
      const value = !playing;

      if (value) audioRef.current.play();
      else audioRef.current.pause();

      setPlaying(value);
    }
  };

  const play = () => {
    audioRef.current.play();
    setPlaying(true);
  };

  const onTimeUpdate = useCallback(
    (event) => {
      const { currentTime } = event.target;

      setCurrentTime(currentTime);
    },
    [setCurrentTime]
  );

  const onEnded = useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  const onLoadedMetadata = useCallback(
    (event) => {
      setDuration(event.target.duration);
      setIsLoading(false);
    },
    [setDuration, setIsLoading]
  );

  const onError = useCallback(() => {
    setIsLoading(false);
    if (!disabled) setIsError(true);
  }, [setIsLoading, disabled, setIsError]);

  const withAudio = (fn) => {
    if (audioRef.current) {
      fn(audioRef.current);
    }
  };

  const seek = (val) => {
    if (!disabled) {
      withAudio((audio) => audio.seek(val));
    }
  };

  const transcriptProps = {
    setActiveCues, // use only if you are controlling readalong without the vtt file
    activeCues,
    currentTime,
    duration,
    transcriptSections: transcriptSections,
    movePlayerTo: (timestamp) => {
      withAudio((audio) => {
        audio.seek(timestamp);
      });
    },
  };

  const controlsProps = {
    playing,
    onChangeSpeed: (val) => {
      withAudio((audio) => audio.changeSpeed(val));
    },
    onClickForward: (amount) => () => seek(currentTime + amount),
    onClickRewind: (amount) => () => seek(currentTime - amount),
    onTogglePlay: toggle,
    onPlay: play,
  };

  const eventPercentageInProgressBar = (event) => {
    const eventOffset = event.clientX - event.currentTarget.getBoundingClientRect().left;
    const percent = eventOffset / event.currentTarget.offsetWidth;

    return Math.max(0.01, Math.min(percent, 1)) * 100;
  };

  useEffect(() => {
    setIsLoading(true);
    setDuration(0);
    setPlaying(false);
    setDraggingValue(0);
    setCurrentTime(0);
  }, [audioUrl]);

  const progressBarProps = {
    duration,
    draggingValue,
    value: currentTime,
    onChange: seek,
    onMouseDown: () => {
      setIsDraggingProgressBar(true);

      withAudio((audio) => {
        audio.onDragWillStart();
      });
    },
    onMouseUp: (event) => {
      const newValue = (eventPercentageInProgressBar(event) * duration) / 100;
      seek(newValue);
      setDraggingValue(null);
      setIsDraggingProgressBar(false);

      withAudio((audio) => {
        audio.onDragEnded();
      });
    },
    onMouseMove: (event) => {
      if (disabled || !isDraggingProgressBar) return;

      setDraggingValue((eventPercentageInProgressBar(event) * duration) / 100);
    },
  };

  const playbackProps = {
    duration,
    currentTime,
  };

  return {
    isLoading,
    isError,
    transcriptProps,
    controlsProps,
    progressBarProps,
    playbackProps,
    renderElements: (
      <>
        <Audio
          key={audioUrl}
          ref={audioRef}
          src={disabled ? "" : audioUrl}
          onEnded={onEnded}
          onTimeUpdate={onTimeUpdate}
          onLoadedMetadata={onLoadedMetadata}
          onError={onError}
        >
          {vttUrl ? (
            <Track src={vttUrl} default onCueChange={onCueChange} onLoad={onTrackLoad} kind="subtitles" />
          ) : null}
        </Audio>
      </>
    ),
  };
};
