import { useThemeTokens } from "@alphasights/alphadesign-components";

const useCkFiltersStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    filtersList: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: inner.base02,
      pl: inner.base02,
    },
  };
};

export default useCkFiltersStyles;
