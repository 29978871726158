import React, { FC } from "react";
import { AlphaNowProductType } from "@alphasights/client-portal-shared";
import DownloadButton from "pages/AlphaNowPage/components/AlphaNowTranscript/DownloadButton";
import BookmarkButton from "components/BookmarkButton";
import AudioButton from "components/AudioButton/AudioButton";
import CopyLinkButton from "components/CopyLinkButton";
import SettingsButton from "components/SettingsButton";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { TranscriptToolsProps } from "./types";
import { ToolbarActionsContainer } from "./TranscriptTools.styled";
import WatchlistQuickAddButton from "pages/AlphaNowPage/components/WatchlistQuickAddButton";

const TranscriptTools: FC<TranscriptToolsProps> = ({
  copyLinkButtonProps,
  downloadButtonProps,
  bookmarkButtonProps,
  audioButtonProps,
  settingsButtonProps,
  watchlistButtonProps,
}) => {
  const { contentId: copyLinkContentId, productType: copyLinkProductType } = copyLinkButtonProps || {};
  const { audioDownloadStatus, onAudioSelect } = audioButtonProps || {};
  const {
    hasDownloadButton,
    projectToken,
    downloadFileName,
    productType: downloadProductType,
    contentId: downloadContentId,
  } = downloadButtonProps || {};
  const { showSettingsButton, onSettingsClick } = settingsButtonProps || {};
  const { hasWatchlistButton, contentId: watchlistContentId, cdsAlphaCompanyIds = [] } = watchlistButtonProps || {};

  const displayAudioButton = !!(audioDownloadStatus && onAudioSelect);
  const displaySettingsButton = !!(showSettingsButton && onSettingsClick);
  const displayWatchlistButton = !!(watchlistButtonProps && hasWatchlistButton);

  return (
    <ToolbarActionsContainer>
      {displayAudioButton && <AudioButton audioDownloadStatus={audioDownloadStatus} onAudioClick={onAudioSelect} />}
      {copyLinkButtonProps && (
        <CopyLinkButton
          contentId={copyLinkContentId as string}
          contentType={copyLinkProductType as AlphaNowProductType}
        />
      )}
      {bookmarkButtonProps && <BookmarkButton permissions={[MANAGE_CONTENT_PERMISSION]} {...bookmarkButtonProps} />}
      {hasDownloadButton && (
        <DownloadButton
          contentType={downloadProductType}
          contentId={downloadContentId}
          downloadFileName={downloadFileName || ""}
          projectToken={projectToken}
        />
      )}
      {displaySettingsButton && <SettingsButton onClick={onSettingsClick} />}
      {displayWatchlistButton && (
        <WatchlistQuickAddButton size="small" cdsAlphaCompanyIds={cdsAlphaCompanyIds} contentId={watchlistContentId} />
      )}
    </ToolbarActionsContainer>
  );
};

export default TranscriptTools;
