import { useEffect } from "react";
import { useEnv } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { authUserService } from "services/authUserService";
import { ClientContactMetadata } from "models/ClientContactMetadata";

const buildUserMetadata = ({
  authUserDetails,
  currentUser,
}: {
  authUserDetails: ClientContactMetadata;
  currentUser: User;
}) => ({
  user_id: currentUser.id,
  client_contact_id: authUserDetails.id,
  name: currentUser.name,
  client_email: currentUser.email,
  client_account: authUserDetails.account.name,
  client_target: authUserDetails.target.name,
  client_target_id: authUserDetails.target.id,
  client_business_unit: authUserDetails.account.businessUnit,
  office: authUserDetails.account.office.name,
  badges: currentUser.badges,
  internal: false,
  alpha_now_enabled: currentUser.alphaNowEnabled,
  cid_enabled: currentUser.cidEnabled,
  alpha_Now_Enabled: currentUser.alphaNowEnabled,
  past_projects_enabled: currentUser.pastProjectsEnabled,
  portal_comments_enabled: currentUser.portalCommentsEnabled,
  portal_workstreams_enabled: currentUser.portalWorkstreamsEnabled,
  portal_customer_knowledge_enabled: currentUser.portalCustomerKnowledgeEnabled,
  alpha_gpt_enabled: currentUser.alphaGPTEnabled,
  private_transcript_library_enabled: currentUser.privateTranscriptLibraryEnabled,
  private_transcript_library_setting_in_alpha_gpt_enabled: currentUser.privateTranscriptLibrarySettingInAlphaGptEnabled,
  private_transcript_library_enabled_in_alpha_gpt: currentUser.privateTranscriptLibraryEnabledInAlphaGpt,
  company_primers_enabled: currentUser.companyPrimersEnabled,
  access_only: currentUser.accessOnly,
});

export const useIntercom = ({ authUserDetails }: { authUserDetails?: ClientContactMetadata }) => {
  const currentUser = useCurrentUser();
  const env = useEnv();

  useEffect(() => {
    const w = window as any;
    const intercomAppId = w.INTERCOM_APP_ID;
    if (currentUser && env?.enableIntercom && authUserDetails && intercomAppId) {
      w.intercomSettings = {
        app_id: intercomAppId,
        user_id: currentUser.id,
        hide_default_launcher: true,
        alignment: "left",
        horizontal_padding: 66,
        vertical_padding: 20,
      };

      authUserService.getIntercomHash().then((intercomUserHash) => {
        w.Intercom("boot", {
          user_hash: intercomUserHash,
          app_id: intercomAppId,
          ...buildUserMetadata({ authUserDetails, currentUser }),
        });
      });
    }
  }, [currentUser, env, authUserDetails]);
};
