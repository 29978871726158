import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const Wrapper = styled.div(() => {
  const { color } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    background: ${color.background.surface.page.default};
    overflow: auto;
    flex-grow: 1;
    width: full;
    height: 100%;
    padding-top: 0;
    position: relative;
    overflow-x: hidden;
  `;
});

const TabContainer = styled.div`
  height: "100%";
  overflow: "scroll";
`;

const MainContentContainer = styled.div`
  height: "100%";
  overflow-x: clip;
  overflow-y: auto;
`;

export { Wrapper, TabContainer, MainContentContainer };
