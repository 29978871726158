import { useRef } from "react";
import {
  RadioButton,
  RadioGroup,
  Tooltip,
  Typography,
  useThemeTokens,
  Skeleton,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { DAY_FORMAT, TIME_FORMAT } from "helpers/interactionHelpers";
import { getStateColorFlyout, getStateName } from "components/AdvisorCard/stateFunctions";
import { FlyoutConfiguration } from "pages/InteractionPage/enums";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export const InteractionChainList = ({
  loading,
  chainInteractions,
  chainIdSelected,
  onChangeChainIdSelected,
  clientRequests,
  showCalendarView,
  isExpertHidden,
  flyoutConfiguration,
  ...props
}) => {
  if (chainInteractions == null || (!loading && chainInteractions.length === 0)) return null;

  return (
    <>
      {loading ? (
        <>
          <LinkLoader />
        </>
      ) : (
        <RadioGroup value={chainIdSelected} {...props}>
          {chainInteractions.map((interaction) => {
            return (
              <RadioButton
                key={interaction.id}
                value={interaction.id}
                onClick={() => onChangeChainIdSelected(interaction.id)}
                data-testid={`chain-link-${interaction.id}`}
              >
                <InteractionInfo
                  interaction={interaction}
                  clientRequests={clientRequests}
                  showCalendarView={showCalendarView}
                  flyoutConfiguration={flyoutConfiguration}
                  isExpertHidden={isExpertHidden}
                />
              </RadioButton>
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};

const InteractionInfo = ({ interaction, clientRequests, showCalendarView, flyoutConfiguration, isExpertHidden }) => {
  const { color } = useThemeTokens();
  const ref = useRef(null);
  const isOverflow = useIsOverflow(ref, flyoutConfiguration);
  const interactionTypeDisplayName = (() => {
    switch (interaction.interactionType) {
      case "Miscellaneous Payment":
        return "Non-Standard Situations";
      case "Industry Survey":
        return "Survey";
      default:
        return interaction.interactionType;
    }
  })();

  function changeTextColor() {
    return isExpertHidden ? color.text.secondary : getStateColorFlyout(interaction.state, color);
  }

  function renderContent(isTooltip) {
    return (
      <>
        {capitalize(interactionTypeDisplayName)}
        {" • "}
        <Typography component="span" color={!isTooltip ? changeTextColor() : "inherit"}>
          {capitalize(
            getStateName({
              pendingRequest: clientRequests?.find((r) => r.interactionId === interaction.id),
              showCalendarView,
              ...interaction,
            })
          )}
        </Typography>
        {interaction.interactionType !== "Industry Survey" && !["proposed", "requested"].includes(interaction.state) && (
          <>
            {" • "}
            <FormattedDateTime date={interaction.scheduledCallTime} format={`${DAY_FORMAT}, ${TIME_FORMAT}`} />
          </>
        )}
      </>
    );
  }

  return (
    <Tooltip title={<Typography>{renderContent(true)}</Typography>} disabled={!isOverflow} maxWidth="45ch">
      <Typography
        ref={ref}
        maxWidth={flyoutConfiguration === FlyoutConfiguration.Standard ? "45ch" : undefined}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        data-testid={`other-interaction-${interaction.id}`}
      >
        {renderContent(false)}
      </Typography>
    </Tooltip>
  );
};

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const LinkLoader = () => (
  <x.div w="300px">
    <Skeleton count={2} />
  </x.div>
);
