import { Tooltip, TooltipChild, TooltipContent, Typography } from "@alphasights/alphadesign-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { pendingRequestTooltip } from "./helpers/Interaction";

export const TooltipStateDetail = ({
  interaction,
  pendingRequest,
  children,
}: {
  interaction: Interaction;
  pendingRequest?: InteractionRequest;
  children: TooltipChild;
}) => {
  const { state, completedDate, scheduledCallTime, scheduledAt, scheduledBy } = interaction;

  const isCompleted = state === "completed";
  const scheduledByData = scheduledCallTime && scheduledBy && scheduledAt;

  return (
    <Tooltip disabled={!scheduledByData} hasFloatingVisibility={true}>
      {scheduledByData && (
        <TooltipContent>
          <Typography variant="body-small">
            {pendingRequest && (
              <>
                Request to {pendingRequestTooltip[pendingRequest.type]} by: {pendingRequest.userName} •{" "}
                <FormattedDateTime date={pendingRequest.createdAt} format="dd MMM yyyy, h:mmaaa" />
                <br />
              </>
            )}
            {isCompleted ? (
              <>
                Completed • <FormattedDateTime date={completedDate} format="dd MMM yyyy, h:mmaaa" />
              </>
            ) : (
              <>
                Scheduled by: {scheduledBy} • <FormattedDateTime date={scheduledAt} format="dd MMM yyyy, h:mmaaa" />
              </>
            )}
          </Typography>
        </TooltipContent>
      )}
      {children}
    </Tooltip>
  );
};
