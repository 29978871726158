import styled, { th } from "@xstyled/styled-components";
import { default as Content } from "react-content-loader";
import { Button } from "@alphasights/alphadesign-components";

export const LoadingWrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const StyledContent = styled(Content)`
  border-radius: ${th.radius("medium")};
  border-width: 2px;
  border-color: ${th.color("border-divider")};
  display: flex;
  box-sizing: border-box;
  background-color: ${th.color("background-surface-page-default")};
  margin-bottom: ${th.space("layout-base02")};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${th.space("inner-base04")};
`;

export const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmptyListButton = styled(Button).attrs({ variant: "outline", size: "medium" })`
  margin: 0 150px;
  margin-top: ${th.space("layout-base04")};
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledBetweenDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuAction = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: #f5f5f5;
  }
`;
