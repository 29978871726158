import { differenceInSeconds } from "date-fns";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { RegularReadAlongTranscript } from "./transcripts/RegularReadAlongTranscript.js";
import { SpeechTranscript } from "./transcripts/SpeechTranscript";

export const DeliverableTranscript = ({
  interaction,
  recording,
  transcriptRequest,
  transcriptProps,
  canPlayAudio,
  sentences,
  fragmentIds,
  isMobile,
  showPlayer,
}) => {
  const { logHit } = useTrackUserAction();
  const [openedAt] = useState(new Date());
  const maxScrollReached = useRef(0);

  const logHitDuration = useCallback(() => {
    const closedAt = new Date();
    const durationInSeconds = differenceInSeconds(closedAt, openedAt);

    if (durationInSeconds < 15) return;

    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.transcriptRead,
      advisorshipId: interaction.id,
      projectToken: interaction.projectToken,
      details: {
        transcriptType: transcriptRequest.transcriptType,
        durationInSeconds,
        maxScrollReached: _.round(maxScrollReached.current, 2),
      },
      references: { transcriptId: transcriptRequest.id },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.transcriptOpened,
      advisorshipId: interaction.id,
      projectToken: interaction.projectToken,
      details: {
        transcriptType: transcriptRequest.transcriptType,
      },
      references: { transcriptId: transcriptRequest.id },
    });

    const scrollableArea = document.querySelector("[data-transcript-scrollable-area]");

    const computeMaxScrollReached = () => {
      const maxBottomVisible = scrollableArea.getBoundingClientRect().height + scrollableArea.scrollTop;

      maxScrollReached.current = _.max([maxScrollReached.current, maxBottomVisible / scrollableArea.scrollHeight]);
    };

    scrollableArea.addEventListener("scroll", computeMaxScrollReached);

    window.addEventListener("beforeunload", logHitDuration);

    return () => {
      window.removeEventListener("beforeunload", logHitDuration);
      scrollableArea.removeEventListener("scroll", computeMaxScrollReached);
      logHitDuration();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onCueClick = ({ startTime }) => {
    logHit({
      origin: "deliverables-view",
      action: "TRANSCRIPT_READALONG_CLICK",
      advisorshipId: interaction.id,
      projectToken: interaction.projectToken,
      details: {
        startTime,
        transcriptType: transcriptRequest.transcriptType,
      },
      references: { transcriptId: transcriptRequest.id },
    });
    transcriptProps.movePlayerTo(startTime);
  };

  return (
    <>
      {transcriptRequest.transcriptType === "regular" && (
        <RegularReadAlongTranscript
          interaction={interaction}
          transcriptRequest={transcriptRequest}
          recording={recording}
          regularSentences={sentences}
          canPlayAudio={canPlayAudio}
          currentTime={canPlayAudio && transcriptProps.currentTime}
          onCueClick={canPlayAudio && onCueClick}
          data-testid={`transcript-${transcriptRequest.transcriptType}`}
          setActiveCues={transcriptProps.setActiveCues}
          duration={transcriptProps.duration}
          fragmentIds={fragmentIds}
          isMobile={isMobile}
          showPlayer={showPlayer}
        />
      )}

      {transcriptRequest.transcriptType === "ai" && (
        <SpeechTranscript
          sentences={sentences}
          canPlayAudio={canPlayAudio}
          currentTime={canPlayAudio && transcriptProps.currentTime}
          onCueClick={canPlayAudio && onCueClick}
          data-testid={`transcript-${transcriptRequest.transcriptType}`}
          setActiveCues={transcriptProps.setActiveCues}
          fragmentIds={fragmentIds}
          isMobile={isMobile}
          showPlayer={showPlayer}
        />
      )}
    </>
  );
};
