import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormattedDateTime, useTimezone, parseISO, formatStartEndTime } from "providers/TimezoneProvider";
import {
  Alert,
  Button,
  Link,
  RoundedButton,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CalendarAvailable, CalendarToday, Request, Tick } from "@alphasights/alphadesign-icons";
import { mapAdvisorAvailability } from "components/ProjectsCalendar/eventSupport";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import {
  requestChangeInteraction,
  requestMoreAvailability as requestMoreAvailabilityInteraction,
  setFlyoutMode,
  requestAdvisor,
  loadAdvisorInteractions,
} from "components/InteractionsPage/reducer";
import {
  addSeconds,
  areIntervalsOverlapping,
  differenceInHours,
  isEqual,
  isBefore,
  differenceInSeconds,
} from "date-fns";
import { useFlyoutConfiguration } from "pages/InteractionPage";
import ReactTooltip from "react-tooltip";
import { CancelRequestPopup } from "views/ProjectCalendarView/RequestModal";
import { DropdownButton } from "components/DropdownButton";
import { getFirstName, onGracePeriod } from "pages/InteractionPage/utils";
import {
  cancelProvideAvailability,
  cancelRescheduleInteraction,
  clientAvailabilitySaved,
  provideAvailability,
  requestMoreAvailability,
  requestRescheduleInteraction,
  reschedulingRequested,
  retractCancelRequestInteraction,
  retractRequestRescheduleInteraction,
  showAllAvailability,
  setAvailabilityState,
} from "../../AdvisorFlyOutReducer";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { interactionMinDuration } from "views/ProjectCalendarView/AvailabilityPopup";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { ButtonWithMargin, withMargin } from "@alphasights/client-portal-shared";
import { FlyoutConfiguration, FlyoutMode } from "pages/InteractionPage/enums";
import { HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";
import useUserInteractions from "hooks/useUserInteractions";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

const AvailabilitySlot = ({
  startsAt,
  endsAt,
  onClick,
  showDay,
  used,
  selected,
  disabledByShortNotice,
  disabledByTimeframeShort,
  customContent,
  ...props
}) => {
  const {
    color: { border, background },
    spacing,
  } = useThemeTokens();
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isInteractionActionsDisabled = isFeatureDisabled(ProjectFeature.InteractionActions);

  const disabled = disabledByShortNotice || disabledByTimeframeShort || isInteractionActionsDisabled;
  const tooltip = disabledByShortNotice
    ? "Please reach out to your AlphaSights project lead to schedule a call on short notice."
    : disabledByTimeframeShort
    ? "Please reach out to your AlphaSights project lead as this availability timeframe is too short to schedule a call in the Platform."
    : used
    ? "Another interaction is scheduled during this time"
    : "";

  return (
    <Tooltip title={tooltip}>
      <RoundedButtonWithLoginWall
        onClick={onClick}
        borderColor={{
          hover: !disabled ? border.selected : undefined,
          _: selected ? border.selected : undefined,
        }}
        backgroundColor={used || disabled ? background.neutral.default : ""}
        px={spacing.inner.base02}
        disabled={disabled}
        cursor={disabled ? "not-allowed" : undefined}
        {...props}
      >
        <Typography variant="body-em">
          {customContent ? (
            customContent(startsAt, endsAt)
          ) : (
            <>
              {showDay && (
                <>
                  <FormattedDateTime date={startsAt} format="ccc d MMM" />
                  {", "}
                </>
              )}
              {formatStartEndTime({ startsAt, endsAt })}
            </>
          )}
        </Typography>
      </RoundedButtonWithLoginWall>
    </Tooltip>
  );
};

export const GroupedAvailabilitySlots = ({
  title,
  slots,
  onClickTimespan,
  showDay,
  timespanProps,
  minExpectedDuration,
  customContent,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const isCloseToCurrentTime = (time) => differenceInHours(time, parseISO(new Date())) < 1;

  const isSlotLongEnough = (startsAt, endsAt) => {
    return differenceInSeconds(endsAt, startsAt) >= minExpectedDuration;
  };

  const [viewMore, setViewMore] = useState(false);
  const showViewMore = slots.length > 7;

  return (
    <x.div>
      <Typography>{title}</Typography>
      <x.div display="flex" flexWrap="wrap" gap={inner.base02} marginTop={inner.base02}>
        {slots.slice(0, viewMore ? slots.length : 7).map(({ startsAt, endsAt, ...otherProps }, idx) => (
          <AvailabilitySlot
            startsAt={startsAt}
            endsAt={endsAt}
            disabledByShortNotice={isCloseToCurrentTime(startsAt)}
            disabledByTimeframeShort={!isSlotLongEnough(startsAt, endsAt)}
            onClick={() => {
              onClickTimespan({ startsAt, endsAt, ...otherProps });
            }}
            customContent={customContent}
            showDay={showDay}
            key={`availability-slot-${idx}`}
            data-testid={`timeslot-${idx}`}
            {...otherProps}
            {...timespanProps}
          />
        ))}
        {showViewMore && (
          <Link onClick={() => setViewMore(!viewMore)} data-testid="toggle-view-more-availability">
            {viewMore ? "View less" : "View more"}
          </Link>
        )}
      </x.div>
    </x.div>
  );
};

const removeMutualAvailability = (advisorAvailabilities, mutualAvailabilities) => {
  if (advisorAvailabilities.length === 0 || mutualAvailabilities.length === 0) {
    return [];
  }

  const availabilities = [...advisorAvailabilities];
  const result = [];

  while (availabilities.length > 0) {
    const availability = availabilities.shift();
    var discardAvailability = false;
    for (const mutualAvailability of mutualAvailabilities) {
      if (areIntervalsOverlapping(availability, mutualAvailability)) {
        if (isBefore(availability.start, mutualAvailability.start)) {
          if (isBefore(availability.end, mutualAvailability.end)) {
            availability.end = mutualAvailability.start;
          } else {
            discardAvailability = true;
            availabilities.push({
              start: availability.start,
              end: mutualAvailability.start,
            });
            if (!isEqual(availability.end, mutualAvailability.end)) {
              availabilities.push({
                start: mutualAvailability.end,
                end: availability.end,
              });
            }
            break;
          }
        } else {
          if (isBefore(availability.end, mutualAvailability.end) || isEqual(availability.end, mutualAvailability.end)) {
            discardAvailability = true;
            break;
          } else {
            availability.start = mutualAvailability.end;
          }
        }
      }
    }
    if (!discardAvailability) {
      result.push(availability);
    }
  }

  return result;
};

const checkInteractionOverlaps = (advisorAvailabilities, clientInteractions) => {
  const result = [];
  advisorAvailabilities.forEach((availability) => {
    var hasOverlap = false;
    clientInteractions.forEach((clientInteraction) => {
      if (areIntervalsOverlapping(availability, clientInteraction)) {
        if (isBefore(availability.start, clientInteraction.start)) {
          result.push({
            start: availability.start,
            end: clientInteraction.start,
            used: false,
          });
          if (isBefore(availability.end, clientInteraction.end)) {
            result.push({
              start: clientInteraction.start,
              end: availability.end,
              used: true,
            });
          } else {
            result.push({
              start: clientInteraction.start,
              end: clientInteraction.end,
              used: true,
            });
            if (isBefore(clientInteraction.end, availability.end)) {
              result.push({
                start: clientInteraction.end,
                end: availability.end,
                used: false,
              });
            }
          }
        } else {
          if (isBefore(availability.end, clientInteraction.end)) {
            result.push({
              start: availability.start,
              end: availability.end,
              used: true,
            });
          } else {
            result.push({
              start: availability.start,
              end: clientInteraction.end,
              used: true,
            });
            if (isBefore(clientInteraction.end, availability.end)) {
              result.push({
                start: clientInteraction.end,
                end: availability.end,
                used: false,
              });
            }
          }
        }
        hasOverlap = true;
      }
    });
    if (!hasOverlap)
      result.push({
        start: availability.start,
        end: availability.end,
        used: false,
        source: availability.source,
      });
  });
  return result;
};

export const AvailabilitySelector = ({
  advisorAvailability,
  expectedDuration,
  clientAvailability,
  onSchedule,
  flyoutMode,
  interaction,
  pendingRequest,
  pendingAvailabilityRequest,
  onCancelPendingRequest,
  setReschedulingRequested,
  setNumSlots,
  onRequestRescheduleInteractionWithoutDate,
  originalInteractionId,
  onSaveAvailability: onSaveAvailabilityInput,
  ...props
}) => {
  const {
    state: { availabilityState: state },
    dispatch,
  } = useFlyoutContext();

  const rescheduleRequest = pendingRequest?.type === "RESCHEDULE_REQUEST" ? pendingRequest : null;
  const cancelRequest = pendingRequest?.type === "CANCEL_REQUEST" ? pendingRequest : null;
  const projectLead = getFirstName(props.projectWithInteractions ? props.projectWithInteractions.lead.name : "");
  const [availabilityProvidedMessage, setAvailabilityProvidedMessage] = useState("");
  const [cancellingId, setCancellingId] = useState(null);
  const tz = useTimezone();
  const dispatchGlobal = useContext(DispatchContext);
  const isFullScreen = useFlyoutConfiguration(flyoutMode) === FlyoutConfiguration.Fullscreen;
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isInteractionActionsDisabled = isFeatureDisabled(ProjectFeature.InteractionActions);

  const { regularAvailabilities, mutualAvailabilites, recurringAvailabilities, availabilities } = useAvailabilitySlots({
    allInteractions: props.allInteractions,
    clientAvailability,
    advisorAvailability,
    interactionId: interaction.id,
    timezone: tz,
  });

  const getApplicableAvailability = (clientAvailability || []).filter(
    ({ angleId, advisorshipId, startsAt }) =>
      (!angleId || angleId === interaction.group?.id || angleId === interaction.group?.parent?.id) &&
      (!advisorshipId || advisorshipId === interaction.id) &&
      isBefore(parseISO(new Date()), parseISO(startsAt))
  );

  useEffect(() => {
    setNumSlots(mutualAvailabilites.length + availabilities.length);
  }, [mutualAvailabilites, availabilities, setNumSlots]);
  const hasMutualAvailability = mutualAvailabilites.length > 0;
  const dailyAvailabilities = Object.entries(tz.timespansByDay(regularAvailabilities, "eeee d MMM"));
  const recurringHourlyAvailabilities = Object.entries(tz.timespansByHour(recurringAvailabilities));

  const setInitialState = useCallback(
    () => {
      dispatch(
        setAvailabilityState({
          currentAvailabilityState: state,
          hasMutualAvailability,
          interactionState: interaction.state,
          advisorAvailability,
          flyoutMode,
          pendingAvailabilityRequest,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMutualAvailability, interaction.state, advisorAvailability, flyoutMode, pendingAvailabilityRequest]
  );

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      window.onpopstate = () => {
        setInitialState();
      };
    }
  });

  useEffect(() => {
    setInitialState();
  }, [setInitialState]);

  const onSaveAvailability = useCallback(() => {
    return onSaveAvailabilityInput().then(([success, addedAvailabilitiesCount, removedAvailabilitiesCount]) => {
      if (success) {
        if (addedAvailabilitiesCount > 0) {
          setAvailabilityProvidedMessage("Thank you for providing your team's availability.");
        } else if (removedAvailabilitiesCount > 0) {
          setAvailabilityProvidedMessage("Thank you for updating your team's availability.");
        } else {
          setAvailabilityProvidedMessage("");
        }
        dispatch(clientAvailabilitySaved());
      }
    });
  }, [dispatch, onSaveAvailabilityInput]);

  useEffect(() => {
    if (props.reschedulingRequested) {
      dispatchGlobal(setFlyoutMode(FlyoutMode.Interaction));
      dispatch(reschedulingRequested());
      setReschedulingRequested(false);
    }
  }, [props.reschedulingRequested, setReschedulingRequested, dispatch, dispatchGlobal]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [state]);

  const onShowAllAvailability = () => {
    dispatch(showAllAvailability());
  };

  const onRequestMoreAvailability = useCallback(
    (interactionId, advisorId) => {
      dispatchGlobal(requestMoreAvailabilityInteraction({ interactionId, advisorId }))
        .then(() => {
          dispatch(requestMoreAvailability());
        })
        .then(() => {
          if (interaction.state === "proposed") {
            dispatchGlobal(
              requestAdvisor({
                id: interactionId,
                origin: HitOrigin.flyout,
                skipPopup: true,
              })
            );
          }
        });
    },
    [interaction] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const onProvideAvailability = () => {
    setAvailabilityProvidedMessage("");
    dispatch(provideAvailability());
    props.onLeaveAvailability(interaction);
  };

  const onCancelProvideAvailability = () => {
    dispatchGlobal(setFlyoutMode(FlyoutMode.Interaction));
    dispatch(cancelProvideAvailability());
  };

  const onToggleCalendarView = () => {
    if (isFullScreen) {
      dispatchGlobal(setFlyoutMode(FlyoutMode.Interaction));
    } else {
      dispatchGlobal(setFlyoutMode(FlyoutMode.Calendar));
    }
  };

  const onRequestCancelInteraction = useCallback(
    (state) =>
      dispatchGlobal(requestChangeInteraction(state)).then((cancelRequest) => {
        if (!cancelRequest) {
          dispatchGlobal(
            loadAdvisorInteractions({
              interactionId: interaction.id,
              advisorId: interaction.advisorId,
              projectToken: interaction.projectToken,
            })
          );
        }

        dispatchGlobal(setFlyoutMode(FlyoutMode.Interaction));

        return cancelRequest;
      }),
    [dispatchGlobal, interaction.advisorId, interaction.id, interaction.projectToken]
  );

  const onRequestRescheduleInteraction = (interactionId) => {
    props.onRequestRescheduleInteraction(interactionId);
    dispatch(requestRescheduleInteraction());
  };

  const onRetractRequestRescheduleInteraction = (interactionId, requestId) => {
    onCancelPendingRequest({ interactionId, requestId });
    dispatch(retractRequestRescheduleInteraction());
  };

  const onRetractCancelRequestInteraction = (interactionId, requestId) => {
    onCancelPendingRequest({ interactionId, requestId });
    dispatch(retractCancelRequestInteraction());
  };

  const onCancelRescheduleInteraction = () => {
    dispatchGlobal(setFlyoutMode(FlyoutMode.Interaction));
    dispatch(cancelRescheduleInteraction());
  };

  return (
    <>
      {state === "provide_availability" && <ProvideAvailabilityMessage />}
      {state === "reschedule_interaction" && <RescheduleInteractionMessage />}
      {state !== "provide_availability" &&
        state !== "reschedule_interaction" &&
        state !== "scheduled_interaction" &&
        advisorAvailability?.length === 0 && <NoAvailabilityMessage projectLead={projectLead} />}

      {state !== "provide_availability" &&
        state !== "reschedule_interaction" &&
        state !== "scheduled_interaction" &&
        advisorAvailability?.length > 0 && (
          <AvailabilitiesSection
            interaction={interaction}
            hasMutualAvailability={hasMutualAvailability}
            mutualAvailabilites={mutualAvailabilites}
            onSchedule={onSchedule}
            originalInteractionId={originalInteractionId}
            state={state}
            dailyAvailabilities={dailyAvailabilities}
            recurringHourlyAvailabilities={recurringHourlyAvailabilities}
          />
        )}
      {pendingAvailabilityRequest && <AvailabilityRequestedMessage projectLead={projectLead} />}
      {availabilityProvidedMessage && <AvailabilityProvidedMessage message={availabilityProvidedMessage} />}
      {rescheduleRequest && rescheduleRequest.payload.requestedTime && (
        <RescheduleRequestTimeMessage projectLead={projectLead} rescheduleRequest={rescheduleRequest} />
      )}
      {rescheduleRequest && !rescheduleRequest.payload.requestedTime && (
        <ReschedulRequestMessage projectLead={projectLead} />
      )}
      {cancelRequest && <CancelRequestMessage projectLead={projectLead} />}
      <x.div display="flex">
        {state === "show_mutual_availability" && (
          <ShowMutualAvailabilitySection
            onShowAllAvailability={onShowAllAvailability}
            isFullScreen={isFullScreen}
            onToggleCalendarView={onToggleCalendarView}
          />
        )}
        {state === "show_all_availability" && interaction.state !== "completed" && (
          <ShowAllAvailabilitySection
            interaction={interaction}
            onRequestMoreAvailability={onRequestMoreAvailability}
            isFullScreen={isFullScreen}
            onToggleCalendarView={onToggleCalendarView}
          />
        )}
        {state === "request_more_availability" && !isInteractionActionsDisabled && (
          <RequestMoreAvailabilitySection
            onProvideAvailability={onProvideAvailability}
            advisorAvailability={advisorAvailability}
            isFullScreen={isFullScreen}
            onToggleCalendarView={onToggleCalendarView}
            clientAvailability={getApplicableAvailability}
          />
        )}
        {state === "provide_availability" && !isInteractionActionsDisabled && (
          <ProvideAvailabilitySection
            onSaveAvailability={onSaveAvailability}
            onCancelProvideAvailability={onCancelProvideAvailability}
          />
        )}
        {state === "scheduled_interaction" && !isInteractionActionsDisabled && (
          <ScheduledInteractionSection
            interaction={interaction}
            cancelRequest={cancelRequest}
            onRequestFollowUp={props.onRequestFollowUp}
            onRetractCancelRequestInteraction={onRetractCancelRequestInteraction}
            rescheduleRequest={rescheduleRequest}
            onRetractRequestRescheduleInteraction={onRetractRequestRescheduleInteraction}
            onRequestRescheduleInteraction={onRequestRescheduleInteraction}
            setCancellingId={setCancellingId}
          />
        )}
        {state === "reschedule_interaction" && !isInteractionActionsDisabled && (
          <RescheduleInteractionSection
            interaction={interaction}
            onRequestRescheduleInteractionWithoutDate={onRequestRescheduleInteractionWithoutDate}
            onCancelRescheduleInteraction={onCancelRescheduleInteraction}
          />
        )}
      </x.div>
      {cancellingId && (
        <CancelRequestPopup
          id={cancellingId}
          onClose={() => setCancellingId(null)}
          onSubmit={onRequestCancelInteraction}
          origin={HitOrigin.flyout}
          interaction={interaction}
        />
      )}
    </>
  );
};

const AvailabilityRequestedMessage = ({ projectLead }) => {
  const { spacing } = useThemeTokens();

  return (
    <Alert variant="info" marginBottom={spacing.inner.base06} w="100% !important">
      Your project lead {projectLead} will notify you once the expert provides more availability.
    </Alert>
  );
};

const AvailabilityProvidedMessage = ({ message }) => {
  const { spacing } = useThemeTokens();

  return (
    <Alert variant="success" icon={<Tick />} marginBottom={spacing.inner.base06} w="100% !important">
      {message}
    </Alert>
  );
};

const RescheduleRequestTimeMessage = ({ projectLead, rescheduleRequest }) => {
  const { spacing } = useThemeTokens();

  return (
    <Alert icon={<CalendarAvailable />} marginBottom={spacing.inner.base06} w="100% !important">
      You have requested to reschedule this interaction for{" "}
      {
        <>
          <FormattedDateTime date={rescheduleRequest.payload.requestedTime} format="EEEE d MMMM, h:mm" />
          {" - "}
          <FormattedDateTime
            date={addSeconds(parseISO(rescheduleRequest.payload.requestedTime), rescheduleRequest.payload.duration)}
            format="h:mmaaa"
          />
        </>
      }
      . Your project lead {projectLead} will be in touch.
    </Alert>
  );
};

const ReschedulRequestMessage = ({ projectLead }) => {
  const { spacing } = useThemeTokens();

  return (
    <Alert icon={<CalendarAvailable />} marginBottom={spacing.inner.base06} w="100% !important">
      You have requested to reschedule this interaction. Your project lead {projectLead} will be in touch.
    </Alert>
  );
};

const CancelRequestMessage = ({ projectLead }) => {
  const { spacing } = useThemeTokens();

  return (
    <Alert marginBottom={spacing.inner.base06} w="100% !important">
      You have requested to cancel this interaction. Your project lead {projectLead} will be in touch.
    </Alert>
  );
};

const ShowMutualAvailabilitySection = ({ onShowAllAvailability, isFullScreen, onToggleCalendarView }) => {
  return (
    <>
      <Button variant={"outline"} onClick={onShowAllAvailability} data-testid="show-all-availability-button">
        <Typography variant="body-em">Show All Availability</Typography>
      </Button>
      <ToggleCalendarViewButton isFullScreen={isFullScreen} toggleCalendarView={onToggleCalendarView} />
    </>
  );
};
const AvailabilitiesSection = ({
  interaction,
  hasMutualAvailability,
  mutualAvailabilites,
  onSchedule,
  originalInteractionId,
  state,
  dailyAvailabilities,
  recurringHourlyAvailabilities,
}) => {
  const tz = useTimezone();
  const {
    spacing: { inner },
  } = useThemeTokens();
  const expectedDuration = interactionMinDuration(interaction);

  return (
    <x.div display="flex" flexDirection="column" gap={inner.base06} marginBottom={inner.base06}>
      {hasMutualAvailability && (
        <GroupedAvailabilitySlots
          title="Mutual Availability"
          slots={mutualAvailabilites}
          onClickTimespan={({ startsAt }) => {
            onSchedule({
              id: interaction.id,
              requestedScheduleDate: startsAt,
              originalInteractionId: originalInteractionId,
            });
          }}
          timespanProps={{ "data-testid": "mutual-availability-slot" }}
          showDay
          minExpectedDuration={expectedDuration}
        />
      )}
      {(state !== "show_mutual_availability" || !hasMutualAvailability) && (
        <>
          {recurringHourlyAvailabilities.map(([day, slots = []]) => (
            <GroupedAvailabilitySlots
              key={day}
              title={"Recurring " + day}
              slots={slots}
              customContent={(startsAt) => {
                return tz.format(startsAt, "d MMM");
              }}
              onClickTimespan={({ startsAt }) => {
                onSchedule({
                  id: interaction.id,
                  requestedScheduleDate: startsAt,
                  originalInteractionId: originalInteractionId,
                });
              }}
              timespanProps={{ "data-testid": "availability-slot" }}
              minExpectedDuration={expectedDuration}
            />
          ))}
          {dailyAvailabilities.map(([day, slots = []]) => (
            <GroupedAvailabilitySlots
              key={day}
              title={day}
              slots={slots}
              onClickTimespan={({ startsAt }) => {
                onSchedule({
                  id: interaction.id,
                  requestedScheduleDate: startsAt,
                  originalInteractionId: originalInteractionId,
                });
              }}
              timespanProps={{ "data-testid": "availability-slot" }}
              minExpectedDuration={expectedDuration}
            />
          ))}
        </>
      )}
    </x.div>
  );
};

const ShowAllAvailabilitySection = ({ interaction, onRequestMoreAvailability, isFullScreen, onToggleCalendarView }) => {
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isInteractionActionsDisabled = isFeatureDisabled(ProjectFeature.InteractionActions);

  return (
    <>
      {!isInteractionActionsDisabled && (
        <ButtonWithLoginWall
          variant={"outline"}
          onClick={() => onRequestMoreAvailability(interaction.id, interaction.advisorId)}
          data-testid="request-more-availability-button"
          loading={interaction.runningAction === "requestMoreAvailability"}
          disabled={isInteractionActionsDisabled}
        >
          <Typography variant="body-em">Request More Availability</Typography>
        </ButtonWithLoginWall>
      )}
      <ToggleCalendarViewButton isFullScreen={isFullScreen} toggleCalendarView={onToggleCalendarView} />
    </>
  );
};

const RequestMoreAvailabilitySection = ({
  onProvideAvailability,
  advisorAvailability,
  isFullScreen,
  onToggleCalendarView,
  clientAvailability,
}) => {
  const { isMobile } = useCheckScreen();
  if (isMobile) return null;

  return (
    <>
      <ButtonWithLoginWall
        variant={"outline"}
        onClick={onProvideAvailability}
        data-testid="provide-availability-button"
      >
        <Typography variant="body-em">
          {clientAvailability?.length > 0 ? "Provide More Availability" : "Provide Availability"}
        </Typography>
      </ButtonWithLoginWall>
      {advisorAvailability?.length > 0 && (
        <ToggleCalendarViewButton isFullScreen={isFullScreen} toggleCalendarView={onToggleCalendarView} />
      )}
    </>
  );
};

const ProvideAvailabilitySection = ({ onSaveAvailability, onCancelProvideAvailability }) => {
  return (
    <>
      <Button variant="primary" onClick={() => onSaveAvailability()} data-testid="save-availability">
        <Typography variant="body-em">Save Availability</Typography>
      </Button>
      <ButtonWithMargin variant="ghost" onClick={onCancelProvideAvailability}>
        <Typography variant="body-em">Cancel</Typography>
      </ButtonWithMargin>
    </>
  );
};

const ScheduledInteractionSection = ({
  interaction,
  cancelRequest,
  onRequestFollowUp,
  onRetractCancelRequestInteraction,
  rescheduleRequest,
  onRetractRequestRescheduleInteraction,
  onRequestRescheduleInteraction,
  setCancellingId,
}) => {
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
  } = useThemeTokens();

  const isOnGracePeriod = onGracePeriod(interaction);

  const dropdownOptions = [
    rescheduleRequest && {
      label: "Retract rescheduling request",
      action: () => onRetractRequestRescheduleInteraction(interaction.id, rescheduleRequest.requestId),
    },
    !rescheduleRequest &&
      !isMobile && {
        label: isOnGracePeriod ? "Reschedule" : "Request to reschedule",
        action: () => onRequestRescheduleInteraction(interaction.id),
      },
    {
      label: isOnGracePeriod ? "Cancel" : "Request to cancel",
      action: () => setCancellingId(interaction.id),
    },
  ].filter(Boolean);

  return (
    <>
      {!interaction.followUpId && !cancelRequest && (
        <Button
          variant="secondary"
          startIcon={<Request />}
          onClick={() => onRequestFollowUp({ id: interaction.id, origin: HitOrigin.flyout })}
          marginRight={inner.base04}
          data-testid="request-follow-up-button"
          loading={interaction.runningAction === "followUp"}
        >
          <Typography variant="body-em">Request Follow-up</Typography>
        </Button>
      )}
      {cancelRequest ? (
        <ButtonWithLoginWall
          variant="outline"
          onClick={() => onRetractCancelRequestInteraction(interaction.id, cancelRequest.requestId)}
          marginRight={inner.base04}
          data-testid="retract-cancellation-request-button"
          loading={interaction.runningAction === "requestChangeInteraction"}
        >
          <Typography variant="body-em">Retract Cancellation Request</Typography>
        </ButtonWithLoginWall>
      ) : (
        <>
          {dropdownOptions.length === 1 ? (
            <ButtonWithLoginWall
              variant="outline"
              onClick={() => setCancellingId(interaction.id)}
              data-testid="request-cancellation-button"
            >
              <Typography variant="body-em">Request To Cancel</Typography>
            </ButtonWithLoginWall>
          ) : (
            <DropdownButtonWithLoginWall
              variant="outline"
              data-testid="reschedule-cancel-button"
              options={dropdownOptions}
              label="Reschedule / Cancel"
            />
          )}
        </>
      )}
    </>
  );
};

const RescheduleInteractionSection = ({
  interaction,
  onRequestRescheduleInteractionWithoutDate,
  onCancelRescheduleInteraction,
}) => {
  return (
    <>
      <Button variant="outline" onClick={() => onRequestRescheduleInteractionWithoutDate(interaction.id)}>
        <Typography variant="body-em">Propose New Time Later</Typography>
      </Button>
      <ButtonWithMargin variant="ghost" onClick={onCancelRescheduleInteraction}>
        <Typography variant="body-em">Cancel</Typography>
      </ButtonWithMargin>
    </>
  );
};

const ProvideAvailabilityMessage = () => {
  const { color, spacing } = useThemeTokens();

  return (
    <Typography color={color.text.strong._} marginBottom={spacing.inner.base06}>
      Drag and drop your team's availability in the calendar and click 'Save Availability'.
    </Typography>
  );
};

const RescheduleInteractionMessage = ({ projectLead }) => {
  const { color, spacing } = useThemeTokens();

  return (
    <Typography color={color.text.strong._} marginBottom={spacing.inner.base06}>
      Select a time on the calendar for the rescheduled interaction. Alternatively, select 'Propose New Time Later' to
      request to reschedule without providing a specific time, and your project lead {projectLead} will be in touch.
    </Typography>
  );
};

const NoAvailabilityMessage = ({ projectLead }) => {
  const { color, spacing } = useThemeTokens();

  return (
    <Typography color={color.text.strong._} marginBottom={spacing.inner.base06}>
      This expert currently has no upcoming availability. Your project lead {projectLead} will notify you once the
      expert has provided availability.
    </Typography>
  );
};

const ToggleCalendarViewButton = ({ isFullScreen, toggleCalendarView }) => {
  const { isMobile } = useCheckScreen();
  if (isMobile) return null;

  const ButtonWithLoginWallWithMargin = withMargin(ButtonWithLoginWall);

  return (
    <ButtonWithLoginWallWithMargin
      variant="ghost"
      startIcon={<CalendarToday />}
      onClick={toggleCalendarView}
      outline={{ focus: "none" }}
      data-testid="view-in-calendar-button"
    >
      <Typography variant="body-em">{isFullScreen ? "Close Calendar" : "View In Calendar"}</Typography>
    </ButtonWithLoginWallWithMargin>
  );
};

export const useAvailabilitySlots = ({
  advisorAvailability,
  allInteractions,
  clientAvailability,
  interactionId,
  timezone,
}) => {
  const { interactionsByProject, refetch } = useUserInteractions({ status: "scheduled", autoFetch: false });

  var mutualAvailability = mapAdvisorAvailability({
    projects: [
      {
        interactions: allInteractions,
        clientAvailability,
      },
    ],
  })
    .filter((obj) => obj?.interaction?.id === interactionId)
    .flatMap((interaction) => interaction?.overlaps || []);

  const hasMutualAvailability = mutualAvailability.length !== 0;

  const clientInteractions = useMemo(() => {
    return (interactionsByProject || [])
      .flatMap((p) => p.interactions)
      .filter((i) => i.scheduledCallTime)
      .map((interaction) => {
        const start = parseISO(interaction.scheduledCallTime);
        return {
          start,
          end: addSeconds(start, interaction.expectedDuration || 3600),
        };
      });
  }, [interactionsByProject]);

  const advisorAvailabilities = useMemo(() => {
    return (advisorAvailability || [])
      .map((slot) => ({
        start: parseISO(slot.startsAt),
        end: parseISO(slot.endsAt),
        source: slot.source,
      }))
      .flatMap((slot) => timezone.breakPeriodByDay(slot, true));
  }, [advisorAvailability, timezone]);

  const availabilities = useMemo(() => {
    return (
      checkInteractionOverlaps(
        hasMutualAvailability
          ? removeMutualAvailability(advisorAvailabilities, mutualAvailability)
          : advisorAvailabilities,
        clientInteractions
      ) || []
    )
      .map((slot) => ({
        startsAt: parseISO(slot.start),
        endsAt: parseISO(slot.end),
        used: slot.used,
        source: slot.source,
      }))
      .sort(({ startsAt: start1 }, { startsAt: start2 }) => differenceInHours(start1, start2));
  }, [hasMutualAvailability, advisorAvailabilities, mutualAvailability, clientInteractions]);

  const regularAvailabilities = useMemo(() => {
    return availabilities.filter((a) => a.source !== "recurring");
  }, [availabilities]);

  const recurringAvailabilities = useMemo(() => {
    return availabilities.filter((a) => a.source === "recurring");
  }, [availabilities]);

  const overlaps = useMemo(() => {
    return checkInteractionOverlaps(mutualAvailability, clientInteractions);
  }, [mutualAvailability, clientInteractions]);

  const mutualAvailabilites = useMemo(() => {
    return (overlaps || [])
      .map((slot) => ({
        startsAt: slot.start,
        endsAt: slot.end,
        used: slot.used,
        source: null,
      }))
      .sort(({ startsAt: start1 }, { startsAt: start2 }) => differenceInHours(start1, start2));
  }, [overlaps]);

  return useMemo(
    () => ({
      mutualAvailabilites,
      availabilities,
      regularAvailabilities,
      recurringAvailabilities,
      refetch,
    }),
    [mutualAvailabilites, regularAvailabilities, availabilities, recurringAvailabilities, refetch]
  );
};

const ButtonWithLoginWall = withLoginWall(Button);
const DropdownButtonWithLoginWall = withLoginWall(DropdownButton);
const RoundedButtonWithLoginWall = withLoginWall(RoundedButton);
