import styled, { x } from "@xstyled/styled-components";

export const CommentIconWrapper = styled(x.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: max-content;
`;

export const NewCommentIconWrapper = styled(x.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
