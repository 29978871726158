import { HighlightTextTypography } from "components/AdvisorCard/HighlightText";
import { ExpertTableRow } from "models/ExpertTable";
import { HideableCell } from "./HideableCell";

export const RelevantCompanyColumn = ({ row: { original } }: { row: ExpertTableRow }) => {
  const { id, highlights, advisorCompany, hidden, doubleBlinded } = original;

  return (
    <HideableCell hidden={hidden}>
      <HighlightTextTypography
        data-testid={`advisor-row-company-${id}`}
        variant="body-small"
        component="span"
        highlights={highlights}
        fieldNames={[
          "advisorships.relevant_company_name",
          "advisorships.relevant_company_name.concat",
          "relevantPrimaryJob.companyName",
          "relevantPrimaryJob.companyName.concat",
        ]}
        text={doubleBlinded ? "Company name withheld" : advisorCompany}
        preventLineBreak
      />
    </HideableCell>
  );
};
