import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useMessengerHeaderStyles = () => {
  const {
    color: { border },
    spacing: { inner },
  } = useThemeTokens();

  const container = {
    display: "flex",
    background: "white",
    px: inner.base06,
    paddingTop: inner.base06,
    paddingBottom: inner.base06,
    justifyContent: "space-between",
    borderColor: border.divider,
    borderBottomWidth: "1px",
  };

  const headerInfo = {
    display: "flex",
    alignItems: "center",
    gap: inner.base06,
  };

  const headerActions = {
    display: "flex",
    gap: inner.base02,
    alignItems: "center",
  };

  return {
    container,
    headerInfo,
    headerActions,
  };
};

export const useThreadStatusesStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const container = {
    display: "flex",
    gap: inner.base,
  };

  return {
    container,
  };
};

export const useAttachmentsButtonStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const popoverContainer = {
    display: "flex",
    flexDirection: "column",
  };

  const senderGroup = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base,
  };

  const senderName = {
    padding: inner.base02,
  };

  const fileContainer = {
    display: "flex",
    alignItems: "center",
    padding: inner.base02,
    gap: inner.base02,
  };

  const fileName = {
    maxWidth: "15ch",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  return {
    popoverContainer,
    senderGroup,
    senderName,
    fileContainer,
    fileName,
  };
};

export const useSeparatorStyles = ({ usePadding = false }: { usePadding: boolean }) => {
  const {
    color: { border },
    spacing: { inner },
  } = useThemeTokens();

  const separator = {
    w: "100%",
    borderTop: "1px solid",
    borderTopColor: border.neutral.default,
  };

  const padding = {
    padding: usePadding ? inner.base02 : "",
  };

  return {
    padding,
    separator,
  };
};
