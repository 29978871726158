import { x } from "@xstyled/styled-components";
import Chart from "react-apexcharts";
import { useChartConfig } from "./useChartConfig";
import { OneDimensionalFloatingBarChartResults } from "views/SurveysResultsView/api/types";
import { Pill, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import DotLabel from "views/SurveysResultsView/components/DotLabel/DotLabel";

type OneDimensionalFloatingBarChartProps = {
  results: OneDimensionalFloatingBarChartResults;
};

const OneDimensionalFloatingBarChart = ({ results }: OneDimensionalFloatingBarChartProps) => {
  const { color, spacing } = useThemeTokens();
  const { chartHeight, config, values, colors: graphColors } = useChartConfig({ results });
  const medianResponse = results.average?.category || "-";
  const medianResponseColorIndex = results.aggregated.findIndex((result) => result.answerText === medianResponse);

  return (
    <x.div w="100%">
      <x.div display="flex" alignItems="center" gap={spacing.inner.base02} mb={spacing.inner.base04}>
        <Typography variant="body-small-em" color={color.text.strong._}>
          Median response
        </Typography>
        <Pill>
          <DotLabel dotColor={graphColors[medianResponseColorIndex]} label={medianResponse} maxCharsNumber={30} />
        </Pill>
      </x.div>
      <Typography variant="body-small" color={color.text.strong._}>
        % Customers
      </Typography>
      <Chart options={config} series={values} type="rangeBar" height={chartHeight} />
    </x.div>
  );
};

export default OneDimensionalFloatingBarChart;
