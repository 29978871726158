import { useStyles } from "./JoinCall.styles";
import { useCallback, useMemo, useState } from "react";
import { Alert, Button, Icon, Link, Portal, Typography } from "@alphasights/alphadesign-components";
import { Call } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { interactionAboutToStart } from "./utils";
import { JoinMethodPage } from "./JoinMethodPage";
import { getDefaultJoinMethod, useCallMe, useDialIn, useTwilioContext, useConnection } from "providers/TwilioProvider";
import { useClientDialInNumbers } from "hooks/useClientDialInNumbers";
import { TwilioClientConnection } from "components/CallMePanel/TwilioClientConnection";
import { COUNTRY_CODES } from "constants/Country";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { DialInNumber, HitAction } from "@alphasights/portal-api-client";
import { CallAvailability } from "providers/types";
import { JoinCallInteraction } from "./types";

type ContentProps = {
  interaction: JoinCallInteraction;
  thick: boolean;
  origin: string;
  callAvailability?: CallAvailability;
  onChangeMethod: () => void;
  dialInNumbers: DialInNumber[];
};

const Teams = ({ interaction, thick, callAvailability, origin }: ContentProps) => {
  const styles = useStyles({ thick });
  const { logHit } = useTrackUserAction();

  const { number, accessCode } = useConnection({ interaction, callAvailability });

  const onClick = useCallback(() => {
    logHit({
      origin,
      action: HitAction.callJoin,
      projectToken: interaction.projectToken,
      advisorshipId: interaction.id,
      references: { method: "TEAMS" },
    });
    window.open(interaction.callMeUrl!);
  }, [interaction, logHit, origin]);

  return (
    <>
      <x.div {...styles.joinMethodWrapper}>
        <Icon color="secondary">
          <Call />
        </Icon>
        <Typography variant="body-small" {...styles.joinMethodLabel}>
          Join via Teams | Dial in: {number} {accessCode}
        </Typography>
      </x.div>
      <x.div {...styles.buttonsWrapper}>
        <Button
          variant="primary"
          data-testid="join-call-teams"
          onClick={onClick}
          {...styles.halfBtn}
          {...styles.rightBtn}
        >
          Join Call
        </Button>
      </x.div>
    </>
  );
};

const Zoom = ({ interaction, thick, callAvailability, origin }: ContentProps) => {
  const styles = useStyles({ thick });
  const { logHit } = useTrackUserAction();

  const { number, accessCode } = useConnection({ interaction, callAvailability });

  const onClick = useCallback(() => {
    logHit({
      origin,
      action: HitAction.callJoin,
      projectToken: interaction.projectToken,
      advisorshipId: interaction.id,
      details: { method: "ZOOM" },
    });
    window.open(interaction.callMeUrl!);
  }, [interaction, logHit, origin]);

  return (
    <>
      <x.div {...styles.joinMethodWrapper}>
        <Icon color="secondary">
          <Call />
        </Icon>
        <Typography variant="body-small" {...styles.joinMethodLabel}>
          Join via Zoom | Dial in: {number} {accessCode}
        </Typography>
      </x.div>
      <x.div {...styles.buttonsWrapper}>
        <Typography variant="body-small" color="secondary" {...styles.halfDiv}>
          By joining this meeting, you agree to{" "}
          <Link size="small" href="https://explore.zoom.us/en/eula-terms-of-service/" target="_blank">
            Zoom’s Terms of Service
          </Link>
          .
        </Typography>
        <Button
          variant="primary"
          data-testid="join-call-zoom"
          onClick={onClick}
          {...styles.halfBtn}
          {...styles.rightBtn}
        >
          Join Call
        </Button>
      </x.div>
    </>
  );
};

const WebConnect = ({ interaction, onChangeMethod, thick, callAvailability, origin }: ContentProps) => {
  const styles = useStyles({ thick });

  const { accessCode } = useConnection({ interaction, callAvailability });

  const { twilioClient } = useTwilioContext();

  return (
    <>
      <x.div {...styles.joinMethodWrapper}>
        <Icon color="secondary">
          <Call />
        </Icon>
        <Typography variant="body-small" {...styles.joinMethodLabel}>
          Joining Via WebConnect
        </Typography>
      </x.div>

      <x.div {...styles.buttonsWrapper} data-testid="join-call-web-connect">
        {/* @ts-ignore */}
        <TwilioClientConnection
          variant="mobile"
          thick={thick}
          projectToken={interaction.projectToken}
          interactionId={interaction.id}
          accessCode={accessCode}
          globalTwilioClient={twilioClient}
          origin={origin}
        >
          <Button variant="outline" data-testid="join-call-other-options" onClick={onChangeMethod} {...styles.halfBtn}>
            Other Call Options
          </Button>
        </TwilioClientConnection>
      </x.div>
    </>
  );
};

const DialIn = ({ interaction, dialInNumbers, onChangeMethod, thick, callAvailability, origin }: ContentProps) => {
  const styles = useStyles({ thick });
  const { logHit } = useTrackUserAction();

  const {
    preference: { countryIsoCode },
  } = useTwilioContext();

  const { number, accessCode } = useDialIn({ interaction, dialInNumbers, countryIsoCode, callAvailability });

  const onClick = () => {
    logHit({
      origin,
      action: HitAction.callJoin,
      projectToken: interaction.projectToken,
      advisorshipId: interaction.id,
      details: { method: "DIAL_IN" },
    });
  };

  if (!number || !accessCode) return null;

  return (
    <>
      <x.div {...styles.joinMethodWrapper}>
        <Icon color="secondary">
          <Call />
        </Icon>
        <Typography variant="body-small" {...styles.joinMethodLabel}>
          Dial in: {number} |{"  "}
          Code: {accessCode}
        </Typography>
      </x.div>
      <x.div {...styles.buttonsWrapper}>
        <Button variant="outline" data-testid="join-call-other-options" onClick={onChangeMethod} {...styles.halfBtn}>
          Other Call Options
        </Button>
        <Button
          variant="primary"
          data-testid="join-call-dial-in"
          {...styles.halfBtn}
          href={`tel:${number},,${accessCode.replace(/[^\d]/g, "")}`}
          textDecoration="none"
          onClick={onClick}
        >
          Dial In
        </Button>
      </x.div>
    </>
  );
};

const CallMe = ({ interaction, onChangeMethod, thick, origin }: ContentProps) => {
  const styles = useStyles({ thick });
  const { logHit } = useTrackUserAction();

  const {
    preference: { phoneNumber, ext, countryIsoCode },
  } = useTwilioContext();

  const { isLoading, dispatch, error } = useCallMe({
    projectToken: interaction.projectToken,
    interactionId: interaction.id,
  });

  const { code } = COUNTRY_CODES.find(({ isoCode }) => isoCode === countryIsoCode)!;

  const onClick = () => {
    if (!isLoading) {
      logHit({
        origin,
        action: HitAction.callJoin,
        projectToken: interaction.projectToken,
        advisorshipId: interaction.id,
        details: { method: "CALL_ME" },
      });

      dispatch({ phone: `+${code} ${phoneNumber}`, extension: ext });
    }
  };

  return (
    <>
      <x.div {...styles.joinMethodWrapper}>
        <Icon color="secondary">
          <Call />
        </Icon>
        <Typography variant="body-small" {...styles.joinMethodLabel}>
          Our system will call you on {interaction.clientAccessNumber}
        </Typography>
      </x.div>
      {error && (
        <x.div {...styles.joinMethodWrapper}>
          <Alert variant="danger" data-testid="error-call-me" flex={1}>
            {/* @ts-ignore */}
            {error.message} {error.type === "phone" && <Link onClick={onChangeMethod}>Edit phone number</Link>}
          </Alert>
        </x.div>
      )}
      <x.div {...styles.buttonsWrapper}>
        <Button variant="outline" data-testid="join-call-other-options" onClick={onChangeMethod} {...styles.halfBtn}>
          Other Call Options
        </Button>
        <Button
          variant="primary"
          data-testid="join-call-call-me"
          {...styles.halfBtn}
          onClick={onClick}
          loading={isLoading}
        >
          Call Me
        </Button>
      </x.div>
    </>
  );
};

const content = ({ interaction, joinMethod }: { interaction: JoinCallInteraction; joinMethod: string }) => {
  if (interaction.newspeakProvider === "zoom") return Zoom;

  if (interaction.newspeakProvider === "teams") return Teams;

  if (joinMethod === "DIAL_IN") return DialIn;

  if (joinMethod === "CALL_ME") return CallMe;

  if (joinMethod === "WEB_CONNECT") return WebConnect;

  return null;
};

export type JoinCallProps = {
  interaction: JoinCallInteraction;
  thick?: boolean;
  callAvailability?: CallAvailability;
  origin: string;
};

export const JoinCall = ({ interaction, thick = false, callAvailability, origin }: JoinCallProps) => {
  const isInteractionAboutToStart = useMemo(() => {
    return interactionAboutToStart({ scheduledCallTime: interaction.scheduledCallTime });
  }, [interaction]);

  if (!isInteractionAboutToStart) return null;

  return (
    <JoinCallSection interaction={interaction} thick={thick} callAvailability={callAvailability} origin={origin} />
  );
};

type JoinCallSectionProps = {
  interaction: JoinCallInteraction;
  thick?: boolean;
  callAvailability?: CallAvailability;
  origin: string;
};

const JoinCallSection = ({ interaction, thick = false, callAvailability, origin }: JoinCallSectionProps) => {
  const [isChangeMethodOpened, setIsChangeMethodOpened] = useState(false);

  const { dialInNumbers }: { dialInNumbers: DialInNumber[] } = useClientDialInNumbers({ interaction });

  const { preference } = useTwilioContext();

  const Content = useMemo(() => {
    return content({ interaction, joinMethod: getDefaultJoinMethod({ interaction, joinMethod: preference.method }) });
  }, [interaction, preference.method]);

  const onChangeMethod = useCallback(() => {
    setIsChangeMethodOpened(true);
  }, []);

  const onCloseChangeMethod = useCallback(() => {
    setIsChangeMethodOpened(false);
  }, []);

  return (
    <x.div data-testid="join-call" w="100%">
      {Content && (
        <Content
          interaction={interaction}
          onChangeMethod={onChangeMethod}
          dialInNumbers={dialInNumbers}
          callAvailability={callAvailability}
          thick={thick}
          origin={origin}
        />
      )}
      {isChangeMethodOpened && (
        <Portal>
          <JoinMethodPage
            interaction={interaction}
            dialInNumbers={dialInNumbers}
            onClose={onCloseChangeMethod}
            callAvailability={callAvailability}
            origin={origin}
          />
        </Portal>
      )}
    </x.div>
  );
};
