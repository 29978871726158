import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";

const DotLabel = ({
  dotColor,
  label,
  dataTestId,
  maxCharsNumber = 15,
}: {
  dotColor: string;
  label: string;
  dataTestId?: string;
  maxCharsNumber?: number;
}) => {
  const { color, spacing } = useThemeTokens();
  // We decided not to use the ADS EllipsisText component here because it has some
  // issues with performance when used with ApexCharts.
  const [truncatedValue, tooltip] = getTruncatedTextAndTooltip(label, maxCharsNumber);

  return (
    <x.div
      cursor="pointer"
      display="flex"
      gap={spacing.inner.base}
      alignItems="center"
      data-testid={dataTestId}
      maxW="360px"
      whiteSpace="nowrap"
    >
      <span
        style={{
          borderRadius: "50%",
          width: spacing.inner.base02,
          height: spacing.inner.base02,
          backgroundColor: dotColor,
        }}
      ></span>
      {label && (
        <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
          <Typography variant="body-small" color={color.text.strong._}>
            {(truncatedValue as string) ?? ""}
          </Typography>
        </ConditionalTooltip>
      )}
    </x.div>
  );
};

export default DotLabel;
