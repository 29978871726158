import { Divider, Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { isEmpty, omit, sortBy, values } from "lodash";
import { useRef } from "react";
import { ContributedMarkdown, ContributedSpan } from "../MobileContentCommons.component";

import { Add, ArrowLeft, Remove } from "@alphasights/alphadesign-icons";
import {
  NumberRenderer,
  PercentRenderer,
  currencyRendererWithSymbol,
  dateOf,
  rangeOf,
  renderRange,
} from "components/CitationContext/PropertyRenderers";
import { OutlookType } from "models/primers/constants";
import { CompetitorGrowth } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import {
  PROPERTIES,
  PROPERTY_ORDER,
} from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/constants";
import { OVERVIEW_PROPERTIES } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/overview/constants";
import { CURRENCY_SYMBOL } from "pages/AlphaNowPage/primers/utils/constants";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import styled from "styled-components";

/// ====================== Customer Dynamics
export const PrimerCustomerDynamics = ({ primer }: { primer: CompanyPrimerContentFlexible }) => {
  const { customerDynamics } = primer;
  if (!customerDynamics) return null;

  return (
    <x.div display={"flex"} flexDirection={"column"}>
      {customerDynamics.segments?.map((segment) => (
        <>
          <PrimerCustomerDynamicsHeaderFields segment={segment} />
          <Divider spacing="lg" />
        </>
      ))}
      <PrimerCustomerDynamicsDescription primer={primer} />
    </x.div>
  );
};

export const PrimerCustomerDynamicsDescription = ({ primer }: { primer: CompanyPrimerContentFlexible }) => {
  const { color, spacing } = useThemeTokens();
  const customerDynamics = primer.customerDynamics as CitableCustomerDynamics;
  const fields = PROPERTY_ORDER;
  const map = {
    [PROPERTIES.description]: { label: "Description", fieldName: "customersDescription" },
    [PROPERTIES.goToMarket]: { label: "Go-to-market", fieldName: "goToMarket" },
    [PROPERTIES.monetization]: { label: "Monetization", fieldName: "monetization" },
  };
  return (
    <x.div display={"flex"} flexDirection={"column"} gap={spacing.layout.base02}>
      {fields.map((field) => {
        const value = customerDynamics[map[field].fieldName as keyof CitableCustomerDynamics] as CitableValue<string>;
        return value ? (
          <>
            <Typography variant="body-small-em" color={color.text.secondary}>
              {map[field].label}
            </Typography>
            <ContributedMarkdown value={value} />
            <Divider />
          </>
        ) : null;
      })}
    </x.div>
  );
};

export const PrimerCustomerDynamicsHeaderFields = ({ segment }: { segment: any }) => {
  const { color } = useThemeTokens();
  const { segment: segmentName, revenueSplit, penetration, trend, keyCustomers } = segment;
  return (
    <x.div display={"grid"} gridTemplateColumns={"1fr 3fr"} alignItems={"center"}>
      <Typography variant="body-small-em" color={color.text.secondary}>
        Segment
      </Typography>
      <ContributedSpan citation={segmentName} />

      {keyCustomers?.value && (
        <>
          <Typography variant="body-small-em" color={color.text.secondary}>
            Key Customers
          </Typography>
          <ContributedSpan citation={keyCustomers} />
        </>
      )}

      <Typography variant="body-small-em" color={color.text.secondary}>
        Penetration
      </Typography>
      <ContributedSpan citation={penetration} />

      <Typography variant="body-small-em" color={color.text.secondary}>
        Trend
      </Typography>
      {/* @ts-ignore */}
      <ContributedSpan citation={trend} renderer={GrowthTrend} display="flex" />

      {revenueSplit?.value && (
        <>
          <Typography variant="body-small-em" color={color.text.secondary}>
            Revenue Split
          </Typography>
          <ContributedSpan
            citation={revenueSplit}
            renderer={({ value }: { value: string }) => <Typography>{value}%</Typography>}
          />
        </>
      )}
    </x.div>
  );
};
/// ----------------------
/// ====================== Strategic Outlook
export const PrimerStrategicOutlook = ({ primer }: { primer: CompanyPrimerContentFlexible }) => {
  const { spacing } = useThemeTokens();
  const outlooks = sortBy(primer.outlooks, "outlookOrder.value");
  const opportunities = outlooks.filter((o) => o.impact.value === OutlookType.opportunity);
  const risks = outlooks.filter((o) => o.impact.value === OutlookType.risk);

  return (
    <x.div display={"flex"} flexDirection={"column"} gap={spacing.inner.base04}>
      {opportunities.map((o) => (
        <PrimerOutlook outlook={o} />
      ))}
      {opportunities.length && risks.length && <Divider />}
      {risks.map((o) => (
        <PrimerOutlook outlook={o} />
      ))}
    </x.div>
  );
};

export const PrimerOutlook = ({ outlook }: { outlook: CitableOutlook }) => {
  const { color, spacing } = useThemeTokens();
  const { theme, description } = outlook;
  const isRisk = outlook.impact.value === OutlookType.risk;
  const isSensitive = hasSensitiveData(outlook);

  return (
    <x.div display={"flex"} flexDirection={"column"} gap={spacing.inner.base}>
      <x.div display={"inline-flex"} alignItems={"center"} gap={spacing.inner.base}>
        <Icon color={isRisk ? color.icon.danger : color.icon.success}>{isRisk ? <Remove /> : <Add />}</Icon>
        <Typography variant="body-small-em" color={color.text.secondary}>
          <SensitiveDataContainer isSensitive={isSensitive}>
            <ContributedSpan citation={theme as CitableValue<string>} />
          </SensitiveDataContainer>
        </Typography>
      </x.div>
      <SensitiveDataContainer isSensitive={isSensitive}>
        <ContributedMarkdown value={description as CitableValue<string>} />
      </SensitiveDataContainer>
    </x.div>
  );
};
//--------
/// ====================== Competitors Dynamics
export const PrimerCompetitorsDynamics = ({ primer }: { primer: CompanyPrimerContentFlexible }) => {
  return (
    <x.div display={"flex"} flexDirection={"column"}>
      {sortBy(primer.competitors, "rank.value").map((com, idx) => (
        <>
          <PrimerCompetitor primer={primer} competitor={com} />
          {idx < primer.competitors.length - 1 && <Divider spacing="lg" />}
        </>
      ))}
    </x.div>
  );
};

const GrowthTrend = (growth: any) => {
  const { color, spacing } = useThemeTokens();
  const map = {
    [CompetitorGrowth.Constant]: {
      label: "Stable",
      icon: (
        <Icon size="large">
          <Remove color={color.text.secondary} />
        </Icon>
      ),
    },
    [CompetitorGrowth.Increasing]: {
      label: "Gaining",
      icon: <RotatedArrow up color={color.text.success} fontSize={"large"} />,
    },
    [CompetitorGrowth.Decreasing]: {
      label: "Declining",
      icon: <RotatedArrow color={color.icon.danger} fontSize={"large"} />,
    },
  };
  const item = map[growth.value as CompetitorGrowth];
  return item ? (
    <x.div display={"inline-flex"} alignItems={"center"} gap={spacing.inner.base}>
      {item.icon}
      <Typography>{item.label}</Typography>
    </x.div>
  ) : null;
};

export const PrimerCompetitorHeaderFields = ({
  primer,
  competitor,
}: {
  primer: CompanyPrimerContentFlexible;
  competitor: CitableCompetitor;
}) => {
  const { color } = useThemeTokens();
  const { companyNameId, companyName, rank, growth } = competitor;
  const { companyNameIdToCDSCompanyNamesMap } = primer;

  return (
    <x.div display={"grid"} gridTemplateColumns={"1fr 3fr"} alignItems={"center"}>
      <Typography variant="body-small-em" color={color.text.secondary}>
        Rank
      </Typography>
      <ContributedSpan citation={rank} />
      <Typography variant="body-small-em" color={color.text.secondary}>
        Market Share
      </Typography>
      {/* @ts-ignore */}
      <ContributedSpan citation={growth} renderer={GrowthTrend} display="flex" />
      {companyNameId?.value && !companyName?.value && (
        <>
          <Typography variant="body-small-em" color={color.text.secondary}>
            Competitor
          </Typography>
          <ContributedSpan
            citation={companyNameId}
            renderer={({ value }) => companyNameIdToCDSCompanyNamesMap[value].displayName}
          />
        </>
      )}
      {companyName?.value && (
        <>
          <Typography variant="body-small-em" color={color.text.secondary}>
            Competitor
          </Typography>
          <ContributedSpan citation={companyName} />
        </>
      )}
    </x.div>
  );
};

export const PrimerCompetitor = ({
  primer,
  competitor,
}: {
  primer: CompanyPrimerContentFlexible;
  competitor: CitableCompetitor;
}) => {
  const { competitiveDynamicDescription } = competitor;
  const { spacing } = useThemeTokens();
  return (
    <x.div display={"flex"} flexDirection={"column"} gap={spacing.inner.base04}>
      <PrimerCompetitorHeaderFields primer={primer} competitor={competitor} />
      <ContributedMarkdown value={competitiveDynamicDescription as CitableValue<string>} />
    </x.div>
  );
};

/// ======================  OVERVIEW
export const PrimerOverview = ({ primer }: { primer: CompanyPrimerContentFlexible }) => {
  const { spacing } = useThemeTokens();
  const ref = useRef(null);

  return (
    <x.div display={"flex"} flexDirection={"column"} gap={spacing.inner.base02} ref={ref}>
      {/* @ts-ignore  */}
      {values(partFactory(primer)).map((fac) => fac())}
    </x.div>
  );
};

const LineWrapper = ({ children, title }: { title: string; children: any }) => {
  const { color, spacing } = useThemeTokens();
  return (
    <x.div display={"flex"} flexDirection={"column"} gap={spacing.inner.base02}>
      <Typography variant="body-small-em" color={color.text.secondary}>
        {title}
      </Typography>
      {children}
    </x.div>
  );
};

const LocationLine = ({ location }: { location: CitableLocation }) => {
  const parts = [location.city, location.state, location.country].filter((it) => it.value);
  return (
    <LineWrapper title="HQ">
      <x.div display={"flex"} alignItems={"center"}>
        {parts.map((property, index) => (
          <>
            <ContributedSpan key={index} citation={property} />
            {index < parts.length - 1 ? <span>,&nbsp;</span> : null}
          </>
        ))}
      </x.div>
    </LineWrapper>
  );
};

const RotatedArrow = styled(ArrowLeft)`
   {
    rotate: ${({ up }) => (up ? "90deg" : "-90deg")};
  }
`;

const RevenueLine = ({ revenue, currency }: { revenue: any; currency: any }) => {
  const { growthMax, growthMin, expertRevenueYear, min, max, expertRevenueGrowthYear } = revenue;
  const { color } = useThemeTokens();
  const growthSign = (() => {
    const growthDifference = (growthMax.value ?? 0) - (growthMin.value ?? 0);

    const growthMidpoint = growthDifference / 2 + (growthMin.value ?? 0);

    return Math.sign(growthMidpoint);
  })();

  const currencySymbol = currency.value ? CURRENCY_SYMBOL[currency.value] : "";
  const growthColor = {
    [-1]: color.text.danger,
    0: color.text.secondary,
    1: color.text.success,
  }[growthSign];

  const CurrencyRenderer = currencyRendererWithSymbol(currencySymbol);

  return (
    <LineWrapper title="Revenue">
      <x.div display={"flex"} alignItems={"center"}>
        <ContributedSpan
          citation={rangeOf(min, max)}
          // @ts-ignore
          value={rangeOf(min, max)}
          renderer={renderRange({
            renderer: CurrencyRenderer,
            desc: expertRevenueYear.value ?? undefined,
          })}
        />
        {growthSign !== 0 && (
          <Icon color={growthColor}>
            <RotatedArrow up={growthSign > 0} />
          </Icon>
        )}
        <ContributedSpan
          color={growthColor}
          citation={rangeOf(growthMin, growthMax)}
          // @ts-ignore
          value={rangeOf(growthMin, growthMax)}
          renderer={renderRange({
            renderer: PercentRenderer,
            after: " p.a.",
            shouldRenderFullRangeWhenNoChange: false,
            desc: expertRevenueGrowthYear.value ?? undefined,
          })}
        />
      </x.div>
    </LineWrapper>
  );
};

const FullTimeEquivalentLine = ({ fte, createdAt }: { fte: any; createdAt: any }) => {
  const { min, max } = fte;
  return (
    <LineWrapper title="FTEs">
      <x.div display={"flex"} alignItems={"center"}>
        {/* @ts-ignore */}
        <ContributedSpan
          citation={rangeOf(min, max)}
          renderer={renderRange({ renderer: NumberRenderer, desc: new Date(createdAt).getFullYear() })}
        />
      </x.div>
    </LineWrapper>
  );
};

const MarketsLine = ({ markets }: { markets: any[] }) => {
  return (
    <LineWrapper title="Markets">
      {markets.map((market) => {
        const location = market.country.value ? market.country : market.region;
        const hasMultipleMarkets = markets.length > 1;

        return (
          <Typography key={market.id.value}>
            <ContributedSpan citation={market.industryName} />
            {location && (
              <>
                &nbsp;(
                <ContributedSpan citation={location} />)
              </>
            )}
            {market.isPrimary.value && hasMultipleMarkets && (
              <>
                <Typography> • </Typography>
                <ContributedSpan citation={market.isPrimary} renderer={() => <Typography>Primary</Typography>} />
              </>
            )}
          </Typography>
        );
      })}
    </LineWrapper>
  );
};

const OwnershipTypeLine = ({
  primer,
  companyNameIdToCDSCompanyNamesMap,
}: {
  primer: any;
  companyNameIdToCDSCompanyNamesMap: any;
}) => {
  const { ownershipLastChangeAtQuarter, ownershipLastChangeAtYear, ownerId, ownerName, ownershipType } = primer;
  const ownerNameCited = ownerName
    ? ownerName
    : {
        value: ownerId.value ? companyNameIdToCDSCompanyNamesMap[ownerId.value!]?.displayName : "",
        citedBy: ownerId.citedBy,
      };
  const date = dateOf(ownershipLastChangeAtQuarter, ownershipLastChangeAtYear);
  const hasOwner = !!ownerNameCited.value && !isEmpty(ownerNameCited.value.trim());
  const hasDate = !!date.value && !isEmpty(date.value.trim());

  return (
    <LineWrapper title="Ownership">
      <x.div display={"flex"} alignItems={"center"}>
        <ContributedSpan citation={ownershipType} />
        {hasOwner && (
          <>
            &nbsp;(
            <ContributedSpan citation={ownerNameCited} />
          </>
        )}
        {hasOwner && hasDate && (
          <>
            ,&nbsp;
            <ContributedSpan citation={date} />
          </>
        )}
        {hasOwner && <>)</>}
      </x.div>
    </LineWrapper>
  );
};

const GenericLine = ({ citableValue, title }: { title: string; citableValue: CitableValue<string> }) => {
  return (
    <LineWrapper title={title}>
      <ContributedMarkdown value={citableValue} />
    </LineWrapper>
  );
};

const citableWithMissingInfo = (obj: any) =>
  values(obj).find((val) => "value" in val && "citedBy" in val && [undefined, null].includes(val.value));

const partFactory = (primer: CompanyPrimerContentFlexible) => {
  const allProperties = {
    [OVERVIEW_PROPERTIES.location]: () => <LocationLine location={primer.location} />,
    [OVERVIEW_PROPERTIES.revenue]: () =>
      citableWithMissingInfo(primer.revenue[0]) ? null : (
        <RevenueLine revenue={primer.revenue[0]} currency={primer.primer.currency} />
      ),
    [OVERVIEW_PROPERTIES.fullTimeEquivalent]: () =>
      !primer.fullTimeEquivalents[0].min ? null : (
        <FullTimeEquivalentLine fte={primer.fullTimeEquivalents[0]} createdAt={primer.createdAt} />
      ),
    [OVERVIEW_PROPERTIES.markets]: () => <MarketsLine markets={primer.markets} />,
    [OVERVIEW_PROPERTIES.ownershipType]: () =>
      isEmpty(primer.primer.ownershipType) ? null : (
        <OwnershipTypeLine
          primer={primer.primer}
          companyNameIdToCDSCompanyNamesMap={primer.companyNameIdToCDSCompanyNamesMap}
        />
      ),
    [OVERVIEW_PROPERTIES.description]: () => (
      <GenericLine title="Description" citableValue={primer.primer.description as CitableValue<string>} />
    ),
    [OVERVIEW_PROPERTIES.history]: () => (
      <GenericLine title="History" citableValue={primer.primer.history as CitableValue<string>} />
    ),
    [OVERVIEW_PROPERTIES.exampleUseCase]: () => (
      <GenericLine title="Example use case" citableValue={primer.primer.exampleUseCase as CitableValue<string>} />
    ),
    [OVERVIEW_PROPERTIES.customerDescription]: () =>
      !primer.primer.customerDescription ? null : (
        <GenericLine
          title="Customers description"
          citableValue={primer.primer.customerDescription as CitableValue<string>}
        />
      ),
    [OVERVIEW_PROPERTIES.monetization]: () =>
      !primer.primer.monetization ? null : (
        <GenericLine title="Monetization" citableValue={primer.primer.monetization as CitableValue<string>} />
      ),
    [OVERVIEW_PROPERTIES.differentiation]: () =>
      !primer.primer.differentiation ? null : (
        <GenericLine title="Differentiation" citableValue={primer.primer.differentiation as CitableValue<string>} />
      ),
  };

  const skipProperties =
    primer.srmTypeVersion !== "PRIMER_V1"
      ? [OVERVIEW_PROPERTIES.customerDescription, OVERVIEW_PROPERTIES.monetization, OVERVIEW_PROPERTIES.differentiation]
      : [];
  return omit(allProperties, skipProperties);
};

// This type represents both V1 and V2, since they are mostly identical
export interface CompanyPrimerContentFlexible extends Content {
  srmTypeVersion: string;
  companyPrimerId: string;
  additionalCompanyNames: CitableAdditionalCompanyName[];
  markets: CitableMarket[];
  competitors: CitableCompetitor[];
  fullTimeEquivalents: CitableFullTimeEquivalent[];
  location: CitableLocation;
  outlooks: CitableOutlook[];
  primer: CitablePrimer;
  revenue: CitableRevenue[];
  companyNameIdToCDSCompanyNamesMap: Record<number, CDSCompanyNames>;
  customerDynamics?: CitableCustomerDynamics;
}
