import { useState } from "react";
import * as React from "react";
import {
  Button,
  Divider,
  EllipsisText,
  Modal,
  TextArea,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { SurveySpeakerData } from "views/SurveysResultsView/api/types";
import VendorsLogos from "../VendorsLogos/VendorsLogos";

type RequestExpertModalProps = {
  element: SurveySpeakerData;
  setIsModalOpen: (isOpen: boolean) => void;
};

const MAX_VISIBLE_VENDORS_LOGOS = 13;

const RequestExpertModal = ({ element, setIsModalOpen }: RequestExpertModalProps) => {
  const { color, spacing, shape } = useThemeTokens();
  const { advisorData, summary, results } = element;
  const [note, setNote] = useState("");

  const handleOnRequestExpert = () => {
    // TODO: Implement request expert
    console.log("request expert callback", element.advisorData.id);
    setIsModalOpen(false);
  };

  const handleOnChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value;
    setNote(newNote);
  };

  return (
    <Modal
      open
      shouldShowFooter
      title="Request expert"
      slotWidth="442px"
      slotHeight="316px"
      variant="complex"
      onClose={() => setIsModalOpen(false)}
      primaryButton={
        <Button variant="secondary" onClick={handleOnRequestExpert}>
          Confirm Request
        </Button>
      }
      secondaryButton={
        <Button marginRight="16px" variant="ghost" onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
      }
    >
      <x.div display="flex" flexDirection="column" data-testid="request-modal-container" my={spacing.inner.base02}>
        <x.div display="flex" flexDirection="column" gap={spacing.inner.base} flex="1 1 auto">
          <x.div data-testid="customer-line" display="flex" alignItems="center" w="100%" gap={spacing.inner.base}>
            <x.div display="flex" w="82px" alignItems="center">
              <EllipsisText
                tooltipProps={{ maxWidth: 1125 }}
                typographyProps={{
                  variant: "body-small-em",
                  component: "p",
                }}
              >
                {advisorData.name}
              </EllipsisText>
            </x.div>

            <Divider
              vertical
              h={spacing.inner.base04}
              borderLeft={`${shape.border.width.sm} solid ${color.text.secondary}`}
            />
            <Typography variant="body-small" color={color.text.strong._}>
              Uses:
            </Typography>
            <x.div display="flex" gap={spacing.inner.base} alignItems="center" justifyContent="center">
              <VendorsLogos vendors={summary.vendors} maxVisibleVendorsLogos={MAX_VISIBLE_VENDORS_LOGOS} />
            </x.div>
          </x.div>

          <x.div data-testid="market-spend-line" display="flex" alignItems="center" w="100%">
            <Typography variant="body-small">{summary.spend}</Typography>
          </x.div>
        </x.div>

        <x.div
          w="100%"
          display="flex"
          flexDirection="column"
          gap={spacing.inner.base04}
          my={spacing.inner.base04}
          data-testid="insights-results-container"
        >
          {results.map(({ tab, insights }) => (
            <x.div key={tab} display="flex" flexDirection="column" gap={spacing.inner.base}>
              <Typography variant="body-small" color={color.text.secondary}>
                {tab}
              </Typography>
              <x.ul listStyleType="initial" paddingLeft={spacing.inner.base05}>
                {insights.map(({ id, text }) => (
                  <li key={id}>
                    <Typography variant="body-small" color={color.text.strong._}>
                      {text}
                    </Typography>
                  </li>
                ))}
              </x.ul>
            </x.div>
          ))}
        </x.div>
        <x.div display="flex" flexDirection="column" gap={spacing.inner.base} pb={spacing.inner.base04}>
          <Typography variant="body-small" color={color.text.secondary}>
            TransID
          </Typography>
          <Typography variant="body-small" color={color.text.strong._}>
            {advisorData.transId}
          </Typography>
        </x.div>
        <TextArea
          data-testid="note-textarea"
          value={note}
          label="Add a note (optional)"
          spacing="small"
          height="134px"
          width="100%"
          onChange={handleOnChangeNote}
          placeholder="Share your availability or leave a note for your account manager"
        />
      </x.div>
    </Modal>
  );
};

export default RequestExpertModal;
