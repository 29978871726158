import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { CompetitorGrowth } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import { CitationWrapper, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { Segment } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/segmentation";
import TrendPill from "pages/AlphaNowPage/primers/components/TrendPill/TrendPill";
interface SegmentationTabContentProps {
  segment: Segment;
}

const SegmentationTabContent = ({ segment }: SegmentationTabContentProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { trend, exampleCustomers, commentary } = segment;

  return (
    <>
      <x.div py={inner.base04} display="flex" gap={inner.base05}>
        <x.div display="flex" gap={inner.base06} flexDirection="column">
          <x.div display="flex" gap={inner.base06} alignItems="center">
            <PrimerFieldTitle text="trend" />
            <TrendPill trend={trend.value as CompetitorGrowth} fillBackgroundColor />
          </x.div>
          {exampleCustomers.length > 0 && (
            <x.div display="flex" gap={inner.base06} alignItems="center">
              <PrimerFieldTitle text="example customers" />
              {exampleCustomers.map((customer, customerIndex) => (
                <PrimerCompanyLogo
                  key={customerIndex}
                  size={inner.base08}
                  src={customer.logoLink}
                  padding="0"
                  tooltip={customer.companyName.value}
                />
              ))}
            </x.div>
          )}
        </x.div>
      </x.div>
      <CitationWrapper value={commentary.content} label="commentary" verticalLabel />
    </>
  );
};

export default SegmentationTabContent;
