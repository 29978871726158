import { useState, useCallback } from "react";
import { each } from "lodash";

export const useObserveOverflow = () => {
  const [isOverflow, setIsOverflow] = useState(true);

  const observeChildrenVisibility = useCallback((node: Element) => {
    const observer = new IntersectionObserver(
      (intersections) => {
        const someChildrenPartiallyVisible = intersections.some(
          ({ isIntersecting, intersectionRatio }) => !isIntersecting && intersectionRatio > 0 && intersectionRatio < 1
        );
        setIsOverflow(someChildrenPartiallyVisible);
        someChildrenPartiallyVisible && observer.disconnect();
      },
      { root: node, threshold: 1 }
    );

    each(node.children, (child) => observer.observe(child));
  }, []);

  const elementRef = useCallback(
    (node: HTMLElement | null) => {
      if (!node) return;
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) =>
        entries.forEach(({ target }) => observeChildrenVisibility(target))
      );
      resizeObserver.observe(node);
    },
    [observeChildrenVisibility]
  );

  return {
    elementRef,
    isOverflow,
  };
};
