import {
  Accordion,
  AccordionItem,
  Button,
  Divider,
  IconButton,
  Link,
  Modal,
  Popover,
  TextArea,
  TextField,
  Typography,
} from "@alphasights/alphadesign-components";
import { Add, Close, Delete } from "@alphasights/alphadesign-icons";
import styled, { x } from "@xstyled/styled-components";
import { FormEvent, useCallback, useMemo, useRef, useState } from "react";
import { offset } from "@floating-ui/react-dom";
import { IconButtonRegularPosition } from "components/CopyButton/CopyButton";
import { ProjectSummaryContent, ProjectSummaryTheme, ThemeType } from "./ProjectSummaries.types";
import { CustomThemePayload } from "providers/ProjectSummariesProvider";
import { useStyles } from "./CustomThemeHandle.styles";
import { useCurrentUser } from "@alphasights/portal-auth-react";

interface ThemeForm {
  ix: number;
  title: string;
  description: string;
  errors: {
    title?: string;
    description?: string;
  };
}

export interface CustomThemeHandleProps {
  themes: ProjectSummaryTheme[];
  onSubmit: (val: CustomThemePayload) => Promise<ProjectSummaryContent>;
}

export const CustomThemeHandle = ({ themes, onSubmit }: CustomThemeHandleProps) => {
  const user = useCurrentUser();
  const styles = useStyles();
  const [showCustomThemeModal, setShowCustomThemeModal] = useState(false);

  const onAddThemeClick = useCallback(() => {
    setShowCustomThemeModal(true);
  }, []);

  const onAddThemeClose = useCallback(() => {
    setShowCustomThemeModal(false);
  }, []);

  if (!user?.enableAiInteractivity) return null;

  return (
    <>
      <Link size="small" display="flex" onClick={onAddThemeClick} data-testid="add-theme-handle">
        <x.div {...styles.handle}>
          {" "}
          <Add /> Add new theme
        </x.div>
      </Link>

      <CustomThemeModal
        key={String(showCustomThemeModal)}
        open={showCustomThemeModal}
        themes={themes}
        onClose={onAddThemeClose}
        onSubmit={onSubmit}
      />
    </>
  );
};

const CustomThemeModal = ({
  themes,
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  themes: ProjectSummaryTheme[];
  onClose: () => void;
  onSubmit: (val: CustomThemePayload) => Promise<ProjectSummaryContent>;
}) => {
  const styles = useStyles();
  const [newThemes, setNewThemes] = useState([{ ix: 0, title: "", description: "", errors: {} }]);
  const [themesToRemove, setThemesToRemove] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentThemes = useMemo(() => {
    return themes.filter((t) => themesToRemove.indexOf(t.title) === -1);
  }, [themes, themesToRemove]);

  const validateNewThemes = useCallback(() => {
    let anyError = false;

    const notTouchedForm =
      newThemes.length === 1 && newThemes[0].title.length === 0 && newThemes[0].description.length === 0;

    if (notTouchedForm) return { error: false, themes: [] };

    const validatedThemes = newThemes.map((t) => {
      let validated = t;
      if (t.title.trim().length < 8) {
        anyError = true;
        validated = {
          ...t,
          errors: { title: "Please enter a more specific theme name (8 characters minimum)" },
        };
      }

      return validated;
    });

    return { error: anyError, themes: validatedThemes };
  }, [newThemes]);

  const onSaveChanges = useCallback(async () => {
    const { error, themes } = validateNewThemes();

    if (error) {
      setNewThemes(themes);
      return;
    }

    try {
      setIsSubmitting(true);

      await onSubmit({
        newThemes: themes.map((t) => ({ title: t.title, description: t.description })),
        themesToRemove,
      });

      onClose();
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit, onClose, themesToRemove, validateNewThemes]);

  const onDeleteTheme = useCallback((t: any) => setThemesToRemove([...themesToRemove, t.title]), [themesToRemove]);

  const onAddNewTheme = () => {
    setNewThemes([
      ...newThemes,
      { ix: newThemes[newThemes.length - 1].ix + 1, title: "", description: "", errors: {} },
    ]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      variant="dialog"
      zIndex={30}
      showCloseButton={false}
      slotHeight="max-content"
      slotWidth="max-content"
    >
      {/* no ads modal variants were fitting the designs */}
      <x.div {...styles.modal} data-testid="custom-theme-modal">
        <x.div {...styles.modalTitleWrapper}>
          <Typography variant="body-large-em">Add New Theme</Typography>
          <IconButton variant="basic" size="medium" color="#666B7A" onClick={onClose}>
            <Close />
          </IconButton>
        </x.div>
        <Divider />
        <x.div {...styles.modalBodyWrapper} id="add-theme-scroll">
          {newThemes.map((theme) => {
            return (
              <NewTheme
                key={theme.ix}
                theme={theme}
                onChange={(updated) => {
                  setNewThemes((themes) => themes.map((t) => (t.ix === theme.ix ? updated : t)));
                }}
                onDeleteNewTheme={() => setNewThemes(newThemes.filter((t) => t.ix !== theme.ix))}
                canDelete={newThemes.length > 1}
              />
            );
          })}

          <Button variant="outline" size="small" startIcon={<Add />} onClick={onAddNewTheme} data-testid="add-theme">
            Add Another Theme
          </Button>

          {themes.length > 0 && (
            <Accordion data-testid="modal-themes" {...styles.accordion}>
              <AccordionItem
                // @ts-ignore
                title={<Typography variant="body-small-em">Current Themes</Typography>}
                size="small"
                version="next"
              >
                {currentThemes.map((theme: ProjectSummaryTheme) => {
                  return <Theme key={theme.title} theme={theme} onDeleteTheme={() => onDeleteTheme(theme)} />;
                })}
              </AccordionItem>
            </Accordion>
          )}
        </x.div>
        <Divider />

        <x.div {...styles.modalButtonsWrapper}>
          <Button variant="ghost" size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={onSaveChanges}
            loading={isSubmitting}
            data-testid="custom-theme-submit"
          >
            Save
          </Button>
        </x.div>
      </x.div>
    </Modal>
  );
};

const Theme = ({ theme, onDeleteTheme }: { theme: ProjectSummaryTheme; onDeleteTheme: () => void }) => {
  const styles = useStyles();
  const [showDelete, setShowDelete] = useState<any>();

  const cleanContent = (text: string) => text.replaceAll("<em>", "").replaceAll("</em>", "");

  return (
    <x.div {...styles.themesWrapper} onMouseOver={(e) => setShowDelete(e.currentTarget)}>
      <x.div {...styles.themesTitle}>
        <Typography variant="body-small" color="strong">
          {cleanContent(theme.title)}
        </Typography>
        {theme.type === ThemeType.Custom && (
          <Typography variant="body-small" color="assistive">
            (Custom)
          </Typography>
        )}
      </x.div>
      <Typography variant="body-small" color="secondary">
        {cleanContent(theme.description)}
      </Typography>
      {theme.type === ThemeType.Custom && (
        <HoverDelete anchorEl={showDelete} onClose={() => setShowDelete(null)} onClick={onDeleteTheme} />
      )}
    </x.div>
  );
};

const NewTheme = ({
  theme,
  onChange,
  canDelete,
  onDeleteNewTheme,
}: {
  theme: ThemeForm;
  onChange: (theme: any) => void;
  canDelete: boolean;
  onDeleteNewTheme: () => void;
}) => {
  const [showDelete, setShowDelete] = useState<any>();

  const onFormChange = useCallback(
    (e: FormEvent) => {
      // @ts-ignore
      const value = Object.fromEntries(new FormData(e.currentTarget).entries());

      // @ts-ignore
      onChange({ ...theme, ...value, errors: { ...theme.errors, [e.target.name]: null } });
    },
    [theme, onChange]
  );

  return (
    <x.div
      display="flex"
      flexDirection="column"
      onMouseOver={(e) => canDelete && setShowDelete(e.currentTarget)}
      data-testid={`theme-form-${theme.ix}`}
    >
      <form onChange={onFormChange}>
        <x.div pb="12px">
          <TextFieldItalicPlaceholder
            name="title"
            error={Boolean(theme.errors["title"])}
            errorText={theme.errors["title"]}
            label="Theme Name"
            required
            placeholder="Enter your theme, e.g. Challenges in the Production Process"
            size="small"
            value={theme.title}
          />
        </x.div>
        <x.div pb="12px">
          <TextAreaItalicPlaceholder
            htmlAttributes={{ name: "description" }}
            error={Boolean(theme.errors["description"] ?? false)}
            label="Theme Description"
            width="100%"
            placeholder="Add a theme description to better tailor the response, e.g. This theme explores the technical and practical challenges in the production process, such as supply chain breakdowns, labor shortages, prohibitive costs of raw material, and weather-related factors."
            height="100px"
            spacing="small"
            value={theme.description}
          />
        </x.div>
      </form>
      {canDelete && (
        <HoverDelete anchorEl={showDelete} onClose={() => setShowDelete(null)} onClick={onDeleteNewTheme} />
      )}
    </x.div>
  );
};

const italicPlaceholder = `
  input::-webkit-input-placeholder {
     font-style: italic;
  }
  input:-moz-placeholder {
     font-style: italic;
  }
  input::-moz-placeholder {
     font-style: italic;
  }
  input:-ms-input-placeholder {
     font-style: italic;
  }
  ::-webkit-input-placeholder {
     font-style: italic;
  }
  :-moz-placeholder {
     font-style: italic;
  }
  ::-moz-placeholder {
     font-style: italic;
  }
  :-ms-input-placeholder {
     font-style: italic;
  }
`;

const TextFieldItalicPlaceholder = styled(TextField)`
  ${italicPlaceholder}
`;
const TextAreaItalicPlaceholder = styled(TextArea)`
  ${italicPlaceholder}
`;

const HoverDelete = ({ anchorEl, onClose, onClick }: { anchorEl: any; onClose: () => void; onClick: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);

  const hideIfOutOfScrollBounds = useCallback(
    (data: any) => {
      const scrollBounds = document.querySelector("#add-theme-scroll")?.getBoundingClientRect();

      if (data.y < (scrollBounds?.y || 0)) onClose();
      if (data.y + (ref.current?.clientHeight || 0) > (scrollBounds?.bottom || 0)) onClose();

      return {};
    },
    [onClose]
  );

  return (
    <Popover
      ref={ref}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="top-end"
      onClose={onClose}
      middleware={[offset({ mainAxis: -20 })]}
      onFloatingData={hideIfOutOfScrollBounds}
      size="small"
      lineHeight={0}
      closeOnMouseLeave
      leaveDelay={300}
    >
      <IconButtonRegularPosition size="x-small" variant="ghost" onClick={onClick}>
        <Delete data-testid="theme-delete" />
      </IconButtonRegularPosition>
    </Popover>
  );
};
