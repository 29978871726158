import { SynthesisAnswer, SynthesisModule, SynthesisOverview, SynthesisQuote } from "@alphasights/portal-api-client";
import { useCallback, useEffect, useRef, useState } from "react";
import { question } from "../synthesisTypeGuards";
import { produce } from "immer";

export interface EditSynthesisModuleOperations {
  updateQuestion: (newQuestion: string, revisionIdx: number) => void;
  updateOverview: (newOverview: SynthesisOverview, revisionIdx: number) => void;
  deleteOverview: (deletedOverview: SynthesisOverview, revisionIdx: number) => void;
  updateQuote: (newQuote: SynthesisQuote, revisionIdx: number) => void;
  deleteQuote: (deletedQuote: SynthesisQuote, revisionIdx: number) => void;
  updateAnswer: (newAnswer: SynthesisAnswer, revisionIdx: number) => void;
  deleteAnswer: (deletedAnswer: SynthesisAnswer, revisionIdx: number) => void;
}

export const useEditSynthesisModule = (
  selectedRevisionIdx: number,
  module?: SynthesisModule
): {
  editedModule: SynthesisModule | undefined;
  editOperations: EditSynthesisModuleOperations;
  cancelChanges: () => void;
  undo: () => void;
  redo: () => void;
  clearUndoRedo: () => void;
} => {
  const [editedModule, setEditedModule] = useState(module);
  const undoStack = useRef<SynthesisModule[]>([]);
  const redoStack = useRef<SynthesisModule[]>([]);

  useEffect(() => {
    setEditedModule(module);
  }, [module]);

  const updateStacksAndReturn = useCallback((prevModule: SynthesisModule, newModule: SynthesisModule) => {
    undoStack.current.push(prevModule);
    redoStack.current = [];
    return newModule;
  }, []);

  const updateQuestion = useCallback(
    (newQuestion: string, revisionIdx: number) => {
      setEditedModule((prevModule) => {
        if (!prevModule) return undefined;

        return updateStacksAndReturn(
          prevModule,
          produce(prevModule, (draft) => {
            const content = question(draft.revisions[revisionIdx].contents);
            content.question = newQuestion;
          })
        );
      });
    },
    [updateStacksAndReturn]
  );

  const updateOverview = useCallback(
    (newOverview: SynthesisOverview, revisionIdx: number) => {
      setEditedModule((prevModule) => {
        if (!prevModule) return undefined;

        return updateStacksAndReturn(
          prevModule,
          produce(prevModule, (draft) => {
            const content = question(draft.revisions[revisionIdx].contents);

            for (const o of content.overviews) {
              if (o.id === newOverview.id) Object.assign(o, newOverview);
            }
          })
        );
      });
    },
    [updateStacksAndReturn]
  );

  const deleteOverview = useCallback(
    (deletedOverview: SynthesisOverview, revisionIdx: number) => {
      setEditedModule((prevModule) => {
        if (!prevModule) return undefined;

        return updateStacksAndReturn(
          prevModule,
          produce(prevModule, (draft) => {
            const content = question(draft.revisions[revisionIdx].contents);

            content.overviews = content.overviews.filter((o) => o.id !== deletedOverview.id);
            content.answers = content.answers.map((a) => ({
              ...a,
              quotes: a.quotes.filter((q) => q.topic !== deletedOverview.title),
            }));
          })
        );
      });
    },
    [updateStacksAndReturn]
  );

  const updateQuote = useCallback(
    (newQuote: SynthesisQuote, revisionIdx: number) => {
      setEditedModule((prevModule) => {
        if (!prevModule) return undefined;

        return updateStacksAndReturn(
          prevModule,
          produce(prevModule, (draft) => {
            const content = question(draft.revisions[revisionIdx].contents);

            for (const a of content.answers) {
              for (const q of a.quotes) {
                if (q.id === newQuote.id) Object.assign(q, newQuote);
              }
            }
          })
        );
      });
    },
    [updateStacksAndReturn]
  );

  const deleteQuote = useCallback(
    (deletedQuote: SynthesisQuote, revisionIdx: number) => {
      setEditedModule((prevModule) => {
        if (!prevModule) return undefined;

        return updateStacksAndReturn(
          prevModule,
          produce(prevModule, (draft) => {
            const content = question(draft.revisions[revisionIdx].contents);

            content.answers = content.answers.map((a) => ({
              ...a,
              quotes: a.quotes.filter((q) => q.id !== deletedQuote.id),
            }));

            for (const o of content.overviews) {
              if (o.title === deletedQuote.topic) {
                o.expertCount -= 1;
              }
            }
          })
        );
      });
    },
    [updateStacksAndReturn]
  );

  const updateAnswer = useCallback(
    (newAnswer: SynthesisAnswer, revisionIdx: number) => {
      setEditedModule((prevModule) => {
        if (!prevModule) return undefined;

        return updateStacksAndReturn(
          prevModule,
          produce(prevModule, (draft) => {
            const content = question(draft.revisions[revisionIdx].contents);

            for (const a of content.answers) {
              if (a.id === newAnswer.id) Object.assign(a, newAnswer);
            }
          })
        );
      });
    },
    [updateStacksAndReturn]
  );

  const deleteAnswer = useCallback(
    (deletedAnswer: SynthesisAnswer, revisionIdx: number) => {
      setEditedModule((prevModule) => {
        if (!prevModule) return undefined;

        const topics = deletedAnswer.quotes.map((q) => q.topic);

        return updateStacksAndReturn(
          prevModule,
          produce(prevModule, (draft) => {
            const revision = draft.revisions[revisionIdx];

            if (revision.expertCount) revision.expertCount -= 1;

            const content = question(revision.contents);

            content.answers = content.answers.filter((a) => a.id !== deletedAnswer.id)!;

            for (const o of content.overviews) {
              if (topics.includes(o.title)) {
                o.expertCount -= 1;
              }
            }
          })
        );
      });
    },
    [updateStacksAndReturn]
  );

  const clearUndoRedo = useCallback(() => {
    undoStack.current = [];
    redoStack.current = [];
  }, []);

  const cancelChanges = useCallback(() => {
    setEditedModule(module);
    undoStack.current = [];
    redoStack.current = [];
  }, [module]);

  const undo = useCallback(() => {
    if (undoStack.current.length === 0) return;
    editedModule && redoStack.current.push(editedModule);
    setEditedModule(undoStack.current.pop());
  }, [editedModule]);

  const redo = useCallback(() => {
    if (redoStack.current.length === 0) return;
    editedModule && undoStack.current.push(editedModule);
    setEditedModule(redoStack.current.pop());
  }, [editedModule]);

  const handleUndoRedoShortcut = useCallback(
    (event: KeyboardEvent) => {
      const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform;
      const isMac = platform.toLowerCase().indexOf("mac") >= 0;
      const ctrlKey = isMac ? event.metaKey : event.ctrlKey;

      if (ctrlKey === true && event.shiftKey === false && event.key === "z") {
        event.preventDefault();
        undo();
      }
      if (
        (ctrlKey === true && event.shiftKey === true && event.key === "z") ||
        (ctrlKey === true && event.key === "y")
      ) {
        event.preventDefault();
        redo();
      }
    },
    [redo, undo]
  );

  useEffect(() => {
    const targetNode = document;

    targetNode && targetNode.addEventListener("keydown", handleUndoRedoShortcut as EventListener);

    return () => targetNode && targetNode.removeEventListener("keydown", handleUndoRedoShortcut as EventListener);
  }, [handleUndoRedoShortcut]);

  const editOperations = {
    updateQuestion,
    updateOverview,
    deleteOverview,
    updateQuote,
    deleteQuote,
    updateAnswer,
    deleteAnswer,
  };

  return {
    editedModule,
    editOperations,
    cancelChanges,
    undo,
    redo,
    clearUndoRedo,
  };
};
