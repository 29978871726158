import { IconButton, IconButtonProps } from "@alphasights/alphadesign-components";
import { ArrowLeft, ArrowRight } from "@alphasights/alphadesign-icons";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { useAppSearchContext } from "providers/AppSearchProvider";

import * as S from "./Navigation.styled";

const DataTestIds = {
  backButton: "go-back",
  forwardButton: "go-forward",
};

enum NavigationButtonVariant {
  left,
  right,
}

const NAV_BUTTON_CONFIG = {
  [NavigationButtonVariant.left]: {
    testId: DataTestIds.backButton,
    ArrowIcon: ArrowLeft,
  },
  [NavigationButtonVariant.right]: {
    testId: DataTestIds.forwardButton,
    ArrowIcon: ArrowRight,
  },
};

type NavigationButtonProps = {
  variant: NavigationButtonVariant;
  onClick: () => void;

  disabled?: boolean;
};

const Navigation = () => {
  const { isMobile } = useCheckScreen();
  const { goBack, goForward, isBackDisabled, isForwardDisabled } = useAppSearchContext();
  return (
    <S.NavigationButtonWrapper shouldHide={isMobile}>
      <NavigationButton variant={NavigationButtonVariant.left} onClick={goBack} disabled={isBackDisabled} />
      <NavigationButton variant={NavigationButtonVariant.right} onClick={goForward} disabled={isForwardDisabled} />
    </S.NavigationButtonWrapper>
  );
};

const NavigationButton = ({ variant, disabled = false, onClick }: NavigationButtonProps) => {
  const { testId, ArrowIcon } = NAV_BUTTON_CONFIG[variant];

  const buttonProps: Partial<IconButtonProps> = {
    variant: "basic",
    color: "inverse",
  };

  return (
    <IconButton
      dataAttributes={{
        "data-testid": testId,
      }}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <ArrowIcon />
    </IconButton>
  );
};

export { Navigation as default, DataTestIds };
