import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const ArrowDirection = {
  left: "left",
  right: "right",
};

const Arrow = ({ direction }: { direction: string }) => {
  const { color } = useThemeTokens();

  return (
    <>
      <x.div
        w="0"
        h="0"
        borderTop="5px solid transparent"
        borderBottom="5px solid transparent"
        borderRight={direction === ArrowDirection.left ? `5px solid ${color.border.neutral.default}` : ""}
        borderLeft={direction === ArrowDirection.right ? `5px solid ${color.border.neutral.default}` : ""}
        top="50%"
        transform="translateY(-50%)"
        position="absolute"
        left={direction === ArrowDirection.left ? "0" : ""}
        right={direction === ArrowDirection.right ? "0" : ""}
      />
      <x.div
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        left="0"
        right="0"
        border={`1px solid ${color.border.neutral.default}`}
      />
    </>
  );
};

const ChartKPCGradeExplanation = () => {
  const { color, spacing, typography } = useThemeTokens();

  return (
    <x.div
      display="grid"
      gridTemplateColumns="min-content 1fr min-content 1fr min-content"
      marginLeft="100px"
      color={color.text.strong._}
      columnGap={spacing.inner.base02}
      {...typography.body.small}
    >
      <x.div justifySelf="left" whiteSpace="nowrap">
        Most Important
      </x.div>
      <x.div position="relative">
        <Arrow direction={ArrowDirection.left} />
      </x.div>
      <x.div justifySelf="center">KPCs</x.div>
      <x.div position="relative">
        <Arrow direction={ArrowDirection.right} />
      </x.div>
      <x.div justifySelf="right" whiteSpace="nowrap">
        Least Important
      </x.div>
    </x.div>
  );
};

export default ChartKPCGradeExplanation;
