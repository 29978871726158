import { x } from "@xstyled/styled-components";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { InfoBanner } from "components/Banner";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Button, Divider, Modal, Switch, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  cancel,
  contributorProgram,
  info,
  interactionOptOutAfter72h,
  interactionOptOutBeforeCompletion,
  interactionOptOutWithin72h,
  interactionSureToOptOut,
  optInInfo,
  projectOptOut,
  projectSureToOptOut,
  yesOptIn,
  yesOptOut,
} from "content/ProjectsPage";

const CHANGE_PCC_OPT_PERMISSION = "change_pcc_opt";

const PccSettingsModal = ({
  header,
  isOpen,
  isPccOptIn,
  isProject,
  onClose,
  pccOptOutWindow,
  scheduledCallTime,
  updatePccStatus,
}) => {
  const { isMobile } = useCheckScreen();
  const {
    color: { text },
    spacing: { inner, layout },
  } = useThemeTokens();

  const [isToggleOn, setIsToggleOn] = useState(isPccOptIn);

  useEffect(() => {
    setIsToggleOn(isPccOptIn);
  }, [isPccOptIn]);

  const areWeOptingIn = !isPccOptIn && isToggleOn;
  const areWeOptingOut = isPccOptIn && !isToggleOn;
  const shouldShowButton = areWeOptingIn || areWeOptingOut;
  const shouldShowOptInBanner = isProject && areWeOptingIn;
  const buttonMessage = areWeOptingOut ? yesOptOut : yesOptIn;

  const isInteractionEditable = () => {
    const now = new Date();
    const scheduledCallDate = new Date(scheduledCallTime);
    const completionDateLimit = new Date(scheduledCallTime);
    completionDateLimit.setDate(scheduledCallDate.getDate() + pccOptOutWindow);
    return completionDateLimit > now;
  };

  const hasInteractionHappened = () => {
    if (!scheduledCallTime) return false;
    const now = new Date();
    const scheduledCallDate = new Date(scheduledCallTime);
    return scheduledCallDate < now;
  };

  const clearAndClose = () => {
    setIsToggleOn(isPccOptIn);
    onClose();
  };

  return (
    <Modal
      variant="complex"
      open={isOpen}
      onClose={clearAndClose}
      title={header}
      slotWidth={isMobile ? "100vw" : "35vw"}
      slotHeight="auto"
      zIndex={100}
    >
      <x.div pt={inner.base05}>
        <x.div display="flex" flexDirection="row" alignItems="center" mb={inner.base06}>
          <Typography component="span" color={text.strong._}>
            {contributorProgram}
          </Typography>
          <x.div marginLeft="auto" display="flex" alignItems="center">
            <Switch
              isSelected={isToggleOn}
              disabled={!isProject && scheduledCallTime && !isInteractionEditable()}
              size="large"
              onClick={() => setIsToggleOn((prev) => !prev)}
              rightText={isToggleOn ? "On" : "Off"}
            />
          </x.div>
        </x.div>
        {shouldShowOptInBanner && (
          <InfoBanner mb={inner.base06}>
            <x.div>
              <x.span fontWeight="semibold">{info}</x.span>
              {` ${optInInfo}`}
            </x.div>
          </InfoBanner>
        )}
        <Divider />
        <Typography variant="body-small" component="div" color={text.secondary}>
          {isProject && !isToggleOn && (
            <x.div mt={inner.base06}>
              {projectOptOut} {areWeOptingOut && projectSureToOptOut}
            </x.div>
          )}
          {!isProject && !hasInteractionHappened() && !isToggleOn && (
            <x.div mt={inner.base06}>
              {interactionOptOutBeforeCompletion} {areWeOptingOut && interactionSureToOptOut}
            </x.div>
          )}
          {!isProject &&
            hasInteractionHappened() &&
            (isInteractionEditable() ? (
              !isToggleOn && (
                <x.div mt={inner.base06}>
                  {interactionOptOutWithin72h} {areWeOptingOut && interactionSureToOptOut}
                </x.div>
              )
            ) : (
              <x.div mt={inner.base06}>{interactionOptOutAfter72h}</x.div>
            ))}
        </Typography>
      </x.div>
      <x.div display="flex" flexDirection={isMobile ? "column" : "row"} mt={inner.base06}>
        {shouldShowButton && (
          <ButtonWithAccess
            marginBottom={layout.base03}
            accessControl={{
              allowedPermissions: [CHANGE_PCC_OPT_PERMISSION],
            }}
            onClick={() => updatePccStatus(isToggleOn)}
            variant="secondary"
            mr={!isMobile && inner.base06}
          >
            <Typography variant="body-em" component="span">
              {buttonMessage}
            </Typography>
          </ButtonWithAccess>
        )}
        <ButtonWithAccess
          marginBottom={layout.base04}
          accessControl={{
            allowedPermissions: [CHANGE_PCC_OPT_PERMISSION],
          }}
          onClick={clearAndClose}
          variant="link"
        >
          <Typography variant="body-em" component="span">
            {cancel}
          </Typography>
        </ButtonWithAccess>
      </x.div>
    </Modal>
  );
};

const ButtonWithAccess = withAccessControl(Button);

PccSettingsModal.propTypes = {
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  isPccOptIn: PropTypes.bool,
  isProject: PropTypes.bool,
  onClose: PropTypes.func,
  pccOptOutWindow: PropTypes.number,
  scheduledCallTime: PropTypes.string,
};

export default PccSettingsModal;
