import { Icon, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Warning } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

export const DoNotUseIe = () => {
  const { color, spacing } = useThemeTokens();
  if (!navigator.userAgent.includes("MSIE") && !navigator.userAgent.includes("Trident")) {
    return null;
  }

  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={spacing.inner.base10}
      background={color.background.highlightBase}
      borderBottomWidth={1}
      textAlign="center"
    >
      <Icon size="large">
        <Warning />
      </Icon>
      <Typography variant="body-large" ml={spacing.layout.base02}>
        Browser not supported. Please use{" "}
        <Link size="large" href="https://www.microsoft.com/en-us/edge">
          Microsoft Edge
        </Link>
        ,{" "}
        <Link href="https://www.google.com/chrome/" size="large">
          {" "}
          Google Chrome
        </Link>
        , or{" "}
        <Link href="https://www.mozilla.org/en-US/firefox/new/" size="large">
          Mozilla Firefox
        </Link>{" "}
        to access the AlphaSights Client Platform.
      </Typography>
    </x.div>
  );
};
