import { Icon, Pill, PillVariants, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { CalendarAvailable } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { differenceInMinutes } from "date-fns";
import { DATE_FORMAT } from "helpers/interactionHelpers";
import { HitOrigin } from "@alphasights/portal-api-client";
import { FormattedDateTime, parseISO } from "providers/TimezoneProvider";
import { useCallAvailability } from "providers/TwilioProvider";
import { useMemo } from "react";
import { JoinCall } from "./JoinCall";
import { useStyles } from "./UpcomingCallCard.styles";
import { UpcomingInteraction } from "@alphasights/portal-api-client";
import { CallAvailability, UseCallAvailabilityResult } from "providers/types";

export type UpcomingCallCardProps = {
  upcomingInteraction: UpcomingInteraction;
  onOpenFlyout: (upcomingInteraction: UpcomingInteraction) => void;
};

export const UpcomingCallCard = ({ upcomingInteraction, onOpenFlyout }: UpcomingCallCardProps) => {
  const styles = useStyles();

  const { callAvailability }: UseCallAvailabilityResult = useCallAvailability({
    scheduledCallTime: upcomingInteraction.scheduledCallTime,
    projectToken: upcomingInteraction.projectToken,
    interactionId: upcomingInteraction.id,
  });

  return (
    <x.div {...styles.card} data-testid={"interaction-" + upcomingInteraction.id}>
      <x.div onClick={() => onOpenFlyout(upcomingInteraction)} {...styles.content}>
        <Typography variant="body-em" {...styles.title}>
          {upcomingInteraction.projectTitle}
        </Typography>
        <Typography variant="body" {...styles.title}>
          {upcomingInteraction.role} - {upcomingInteraction.advisorCompany}
        </Typography>
        <x.div {...styles.scheduledCallTimeWrapper}>
          <Icon color="secondary">
            <CalendarAvailable />
          </Icon>
          <Typography variant="body-small" {...styles.scheduledCallTimeLabel}>
            <FormattedDateTime date={upcomingInteraction.scheduledCallTime} format={DATE_FORMAT} />
          </Typography>
          <UpcomingCallPill
            scheduledCallTime={upcomingInteraction.scheduledCallTime}
            callAvailability={callAvailability}
          />
        </x.div>
      </x.div>
      <JoinCall
        interaction={upcomingInteraction}
        callAvailability={callAvailability}
        origin={HitOrigin.upcomingCalls}
      />
    </x.div>
  );
};

const useInteractionCallState = ({
  scheduledCallTime,
  callAvailability,
}: {
  scheduledCallTime: string | Date;
  callAvailability?: CallAvailability;
}): {
  variant: PillVariants;
  text: string;
} | null => {
  const difference = differenceInMinutes(new Date(), parseISO(scheduledCallTime));

  if (!callAvailability || callAvailability.status === "fetch_error") return null;

  if (callAvailability.status === "ongoing" || (difference >= 0 && difference < 5))
    return { variant: "red", text: "Join now" };

  if (
    callAvailability.status === "finished" ||
    callAvailability.status === "finished_assisted" ||
    callAvailability.status === "finished_client_online" ||
    callAvailability.status === "finished_advisor_online"
  )
    return { variant: "green", text: "Call finished" };

  if (difference >= -10 && difference <= 0) return { variant: "yellow", text: "Starting soon" };

  if (difference >= 5 && callAvailability.status === "overdue") return { variant: "light", text: "Not started" };

  return null;
};

export const UpcomingCallPill = ({
  scheduledCallTime,
  callAvailability,
}: {
  scheduledCallTime: string | Date;
  callAvailability?: CallAvailability;
}) => {
  const state = useInteractionCallState({ scheduledCallTime, callAvailability });

  if (!state) return null;

  return (
    <Pill variant={state.variant} size="x-small" isInteractive={false} data-testid="call-state-pill">
      {state.text}
    </Pill>
  );
};

export const UpcomingCallsLoading = ({ withButtons = false }) => {
  const styles = useStyles();

  const [projectWidth, advisorCompanyWidth] = useMemo(() => {
    return [Math.max(Math.floor(Math.random() * 100), 40), Math.max(Math.floor(Math.random() * 100), 60)];
  }, []);

  return (
    <x.div {...styles.card} data-testid="upcoming-call-loading">
      <x.div {...styles.title}>
        <Skeleton variant="noMargin" width={projectWidth + "%"} height="20px" />
      </x.div>
      <x.div {...styles.title}>
        <Skeleton variant="noMargin" width={advisorCompanyWidth + "%"} height="20px" />
      </x.div>
      <x.div {...styles.scheduledCallTimeWrapper}>
        <x.span>
          <Skeleton variant="noMargin" width="20px" height="20px" />
        </x.span>
        <Skeleton variant="noMargin" width="40%" height="20px" />
      </x.div>

      {withButtons && (
        <>
          <x.div {...styles.scheduledCallTimeWrapper} marginBottom="16px">
            <x.span>
              <Skeleton variant="noMargin" width="20px" height="20px" />
            </x.span>
            <Skeleton variant="noMargin" width="30%" height="20px" />
          </x.div>

          <x.div display="flex" gap="16px">
            <Skeleton variant="noMargin" height="32px" />
            <Skeleton variant="noMargin" height="32px" />
          </x.div>
        </>
      )}
    </x.div>
  );
};
