import { createContext, useContext, useState } from "react";
import * as React from "react";

type ThirdPartyDocumentsProviderState = {
  isViewLoading: boolean;
  setIsViewLoading: (loading: boolean) => void;
  isTranscriptSearchEnabled: boolean;
  setIsTranscriptSearchEnabled: (enabled: boolean) => void;
};

const ThirdPartyDocumentsContext = createContext<ThirdPartyDocumentsProviderState | undefined>(undefined);

const ThirdPartyDocumentsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [isTranscriptSearchEnabled, setIsTranscriptSearchEnabled] = useState(false);
  const context = {
    isViewLoading,
    setIsViewLoading,
    isTranscriptSearchEnabled,
    setIsTranscriptSearchEnabled,
  };

  return <ThirdPartyDocumentsContext.Provider value={context}>{children}</ThirdPartyDocumentsContext.Provider>;
};

const useThirdPartyDocumentsContext = () => {
  const context = useContext(ThirdPartyDocumentsContext);
  if (!context) {
    throw new Error("useThirdPartyDocumentsContext must be used within a ThirdPartyDocumentsProvider");
  }
  return context;
};

export { ThirdPartyDocumentsProvider as default, useThirdPartyDocumentsContext };
