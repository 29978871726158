import { useState } from "react";
import { x } from "@xstyled/styled-components";
import { CHART_TYPES, ThreeDimensionalScatterChartKpcAggregatedResults } from "views/SurveysResultsView/api/types";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import TwoDimensionalStackedBarChart from "../TwoDimensionalStackedBarChart/TwoDimensionalStackedBarChart";
import ThreeDimensionalKpcChart from "./ThreeDimensionalKpcChart";
import ThreeDimensionalKpcTabs from "./ThreeDimensionalKpcTabs";
import mapThreeDimensionalKpcChartResultsInto2DResults from "./mapper";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import ChartKPCGradeExplanation from "pages/AlphaNowPage/primers/CustomerPrimer/sections/key-purchasing-criteria/ChartKPCGradeExplanation";

export type ThreeDimensionalKpcChartProps = {
  results: ThreeDimensionalScatterChartKpcAggregatedResults;
  chartType:
    | CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC
    | CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT
    | CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT
    | CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL
    | CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL;
};

const ThreeDimensionalKpcContainer = ({ results, chartType }: ThreeDimensionalKpcChartProps) => {
  const { spacing } = useThemeTokens();

  const { getChartColors } = useGetChartColors({
    numberOfBars: results.aggregated.length,
  });
  const [selectedChartSegment, setSelectedChartSegment] = useState<number>(0);

  const companies = results.aggregated.map((item) => item.x);

  const onHighlightChartSegment = (index: number): void => {
    setSelectedChartSegment(index);
    ApexCharts.exec("KPCCompetitiveSurveyGeneric", "toggleDataPointSelection", index);
  };

  const isKpcChartData =
    chartType === CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC ||
    chartType === CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT;

  return (
    <x.div
      display="flex"
      w="100%"
      data-testid="three-dimensional-chart-container"
      flexDirection="column"
      gap={spacing.inner.base05}
    >
      {isKpcChartData && (
        <ThreeDimensionalKpcChart
          chartType={chartType}
          results={results.aggregated}
          selectedChartSegment={selectedChartSegment}
          setSelectedChartSegment={setSelectedChartSegment}
        />
      )}
      {chartType === CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC && <ChartKPCGradeExplanation />}
      <ThreeDimensionalKpcTabs
        chartColors={getChartColors(null)}
        onClick={onHighlightChartSegment}
        companies={companies}
        selectedTab={selectedChartSegment}
        setSelectedTab={setSelectedChartSegment}
      />
      <TwoDimensionalStackedBarChart
        chartType={chartType}
        results={mapThreeDimensionalKpcChartResultsInto2DResults(results.aggregated[selectedChartSegment].yElements)}
      />
    </x.div>
  );
};
export default ThreeDimensionalKpcContainer;
