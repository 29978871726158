import { Advisor, Help } from "@alphasights/alphadesign-icons";

const simpleOptions = [
  { label: "One", value: 1 },
  { label: "Two", value: 2 },
  { label: "Three", value: 3 },
  { label: "Four", value: 4 },
];

const complexOptions = [
  { label: "One", value: 1, secondaryLabel: "Sec1", StartIcon: Advisor, EndIcon: Help },
  { label: "Two", value: 2, secondaryLabel: "Sec2", StartIcon: Advisor, EndIcon: Help },
  { label: "Three", value: 3, secondaryLabel: "Sec3", StartIcon: Advisor, EndIcon: Help },
  { label: "Four", value: 4, secondaryLabel: "Sec4", StartIcon: Advisor, EndIcon: Help },
];

const loadComplexOptions = (inputValue: string) =>
  inputValue ? complexOptions.filter((option) => option.label.toLowerCase().startsWith(inputValue.toLowerCase())) : [];

export { simpleOptions, complexOptions, loadComplexOptions };
