import { useThemeTokens } from "@alphasights/alphadesign-components";

const useCustomerExpertViewStyles = () => {
  const {
    shape,
    spacing: { inner },
    color: { background, border },
  } = useThemeTokens();

  return {
    headerWrapper: {
      display: "flex",
      mt: inner.base05,
      mb: inner.base04,
      px: inner.base08,
      justifyContent: "space-between",
      alignItems: "center",
    },
    contentWrapper: {
      minH: 0,
      display: "flex",
      flexGrow: 1,
    },
    barChartWrapper: {
      borderRight: `${shape.border.width.sm} solid ${border.divider}`,
      flexShrink: 0,
      overflow: "auto",
    },
    tableWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      background: background.surface.page.default,
      flexGrow: 1,
    },
    outerWrapper: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      minHeight: 0,
      mr: 0,
    },
    innerWrapper: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      position: "relative",
    },
  };
};

export default useCustomerExpertViewStyles;
