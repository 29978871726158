import { x } from "@xstyled/styled-components";
import { IconButton, TextArea, TextField, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Add, Remove } from "@alphasights/alphadesign-icons";
import { SURVEY_MP_INFO, SURVEY_NOTES } from "pages/AlphaNowPage/primers/request-survey/constants";

const SurveyCustomers = ({
  customerCount,
  customerNote,
  onChange,
}: {
  customerCount: string;
  customerNote: string;
  onChange: (value: string, property: string) => void;
}) => {
  const {
    spacing: { inner },
    color: { text },
    font: { size },
  } = useThemeTokens();

  const Item = ({ value }: { value: string }) => (
    <x.ul listStyleType="initial" pl={inner.base05}>
      <li>
        <Typography color={text.secondary} variant="body-small">
          {value}
        </Typography>
      </li>
    </x.ul>
  );

  return (
    <x.div display="flex" paddingTop={inner.base06} flexDirection="column" px={inner.base06}>
      <x.div display="flex" gap={inner.base02} flexDirection="column">
        <Typography variant="body-em" color={text.strong._}>
          Customers
        </Typography>
        <x.div display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body-small" color={text.strong._}>
            Expected # customer respondents
          </Typography>
          <x.div display="flex" gap={inner.base02} alignItems="center">
            <IconButton
              color={text.strong._}
              disabled={parseInt(customerCount) <= 0}
              onClick={() => {
                let newValue = parseInt(customerCount) - 10;
                if (newValue < 0) newValue = 0;

                onChange("count", String(newValue));
              }}
              size="small"
              variant="basic"
            >
              <Remove />
            </IconButton>
            <TextField
              size="small"
              style={{ height: 32, width: 48 }}
              value={customerCount}
              inputMode="numeric"
              pattern={/^[0-9]*$/}
              onChange={(e) => {
                const value = e.target.value;
                if (!value) onChange("count", "0");

                if (/^[0-9]*$/.test(value)) {
                  onChange("count", value);
                }
              }}
            />
            <IconButton
              color={text.strong._}
              onClick={() => onChange("count", String(parseInt(customerCount) + 10))}
              size="small"
              variant="basic"
            >
              <Add />
            </IconButton>
          </x.div>
        </x.div>
        <TextArea
          width="100%"
          onChange={({ target: { value } }) => {
            onChange("notes", value);
          }}
          placeholder={SURVEY_NOTES}
          value={customerNote}
          isDynamic
          height="60px"
          fontSize={size["02"]}
        />
      </x.div>
      <x.div data-testid="survey-next-steps-info" py={inner.base06} className="list">
        <Typography color={text.strong._} paddingBottom={inner.base02} variant="body-em">
          {SURVEY_MP_INFO.title}
        </Typography>
        {SURVEY_MP_INFO.listItems.map(({ value }) => (
          <Item key={`root-${value}`} value={value} />
        ))}
      </x.div>
    </x.div>
  );
};

export default SurveyCustomers;
