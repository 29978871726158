import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useMutation } from "query-utils";

import { useDeliverableContext } from "providers/DeliverableProvider";
import { RequestUploadUrlResponse } from "services/thirdPartyDocumentsService";
import { ThirdPartyDocument, ThirdPartyDocumentType, ThirdPartyDocumentAttributes } from "types";

import { FileData, SelectedThirdPartyFileToUpload } from "./types";
import { FileProcessingStatus, FileStatus } from "./constants";
import { isSuccessStatus } from "./utils";
import { getDefaultExpert } from "utils/thirdPartyDocumentsUtils";
type UseDocumentRequestsProps = {
  files: SelectedThirdPartyFileToUpload[];
  setFiles: Dispatch<SetStateAction<SelectedThirdPartyFileToUpload[]>>;
};

const useDocumentRequests = ({ files, setFiles }: UseDocumentRequestsProps) => {
  const isMounted = useRef(false);
  const pollingTimeoutIds = useRef<(number | NodeJS.Timeout)[]>([]);

  const {
    fetchDocumentUploadUrl,
    uploadDocumentToS3,
    createDocument,
    fetchDocument,
    deleteDocument,
    updateDocumentAttributes,
    addProjectIdToDocument,
  } = useDeliverableContext();

  const handleFileUploadFailure = (id?: number, documentId?: string) => {
    if (!id && !documentId) return;

    const hasMatchingId = (file: SelectedThirdPartyFileToUpload) =>
      id ? file.id === id : file.documentId === documentId;

    setFiles((prevFiles: SelectedThirdPartyFileToUpload[]) =>
      prevFiles.map((file) => (hasMatchingId(file) ? { ...file, status: FileStatus.failed } : file))
    );
  };

  const checkDocTypeProcessed = (documentId: string) => {
    if (isMounted.current) {
      const handler = setTimeout(() => fetchDocumentMutation.mutateAsync(documentId), 5000);
      pollingTimeoutIds.current = [...pollingTimeoutIds.current, handler];
    }
  };

  const fetchDocumentMutation = useMutation((documentId: string) => fetchDocument(documentId), {
    onSuccess: (res: ThirdPartyDocument, documentId: string) => {
      if (res.documentType) {
        setFiles((prevFiles: SelectedThirdPartyFileToUpload[]) =>
          prevFiles.map((file) => {
            if (file.documentId === res.id) {
              const isTranscript = res.documentType === ThirdPartyDocumentType.transcript;
              const status = isTranscript ? FileStatus.informationRequired : FileStatus.uploaded;
              return {
                ...file,
                status,
                isTranscript,
              };
            }
            return file;
          })
        );
      } else {
        checkDocTypeProcessed(documentId);
      }
    },
    onError: (_, documentId) => handleFileUploadFailure(undefined, documentId),
  });

  const createDocumentMutation = useMutation((documentId: string) => createDocument(documentId), {
    onSuccess: (res: ThirdPartyDocument, documentId: string) => {
      if (res.processingStatus === FileProcessingStatus.failed) {
        handleFileUploadFailure(undefined, documentId);
      } else {
        checkDocTypeProcessed(documentId);
      }
    },
    onError: (_, documentId) => handleFileUploadFailure(undefined, documentId),
  });

  const uploadToS3Mutation = useMutation(
    ({ presignedUrl, file }: { presignedUrl: string; file: FileData; documentId: string }) =>
      uploadDocumentToS3(presignedUrl, file),
    {
      onSuccess: (_, { documentId }) => createDocumentMutation.mutate(documentId),
      onError: (_, { documentId }) => {
        const fileId = files.find((file) => file.documentId === documentId)?.id!;
        handleFileUploadFailure(fileId);
      },
    }
  );

  const fetchUploadUrlMutation = useMutation(({ name }: { id: number; name: string }) => fetchDocumentUploadUrl(name), {
    onSuccess: (res: RequestUploadUrlResponse, { id: fileId }) => {
      const { presignedUrl, id: documentId } = res;
      let fileData;
      setFiles((prevFiles: SelectedThirdPartyFileToUpload[]) =>
        prevFiles.map((file) => {
          if (file.id === fileId) {
            fileData = file.data;
            return { ...file, documentId };
          }
          return file;
        })
      );
      uploadToS3Mutation.mutate({ presignedUrl, file: fileData, documentId });
    },
    onError: (_, { id }) => handleFileUploadFailure(id),
  });

  const deleteDocumentMutation = useMutation((documentId: string) => deleteDocument(documentId), {
    onSuccess: (_, documentId) => setFiles((prevFiles) => prevFiles.filter((file) => file.documentId !== documentId)),
  });

  const assignToProjectMutation = useMutation((documentId: string) => addProjectIdToDocument(documentId));

  const updateDocumentAttributesMutation = useMutation(
    ({
      id,
      documentId,
      attributes,
    }: {
      id: number;
      documentId: string;
      attributes: Partial<ThirdPartyDocumentAttributes>;
    }) => {
      const file = files.find((file) => file.id === id)!;

      const updatedAttributes = {
        title: file.name,
        documentDate: file?.date.toISOString(),
        ...attributes,
      };
      return updateDocumentAttributes(documentId, updatedAttributes);
    },
    {
      onSuccess: (res: ThirdPartyDocument) => {
        const {
          id: documentId,
          attribute: { documentDate, title, experts },
        } = res;
        setFiles((prevFiles: SelectedThirdPartyFileToUpload[]) =>
          prevFiles.map((file) => {
            if (file.documentId === documentId) {
              let status = file.status;
              if (file.isTranscript && isSuccessStatus(file.status)) {
                const angle = getDefaultExpert(experts).angle;
                status = !!angle ? FileStatus.uploaded : FileStatus.informationRequired;
              }
              const updatedFile = {
                ...file,
                status,
                experts,
                ...(title && { title }),
                ...(documentDate && { date: new Date(documentDate) }),
              } as SelectedThirdPartyFileToUpload;
              return updatedFile;
            }
            return file;
          })
        );
      },
    }
  );

  const uploadDocument = ({ id, name }: { id: number; name: string }) =>
    fetchUploadUrlMutation.mutateAsync({ id, name });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      pollingTimeoutIds.current.forEach((timeoutId) => clearTimeout(timeoutId));
      pollingTimeoutIds.current = [];
      isMounted.current = false;
    };
  }, []);

  return {
    uploadDocument,
    fetchDocument: fetchDocumentMutation.mutateAsync,
    deleteDocument: deleteDocumentMutation.mutateAsync,
    assignToProject: assignToProjectMutation.mutateAsync,
    updateDocumentAttributes: updateDocumentAttributesMutation.mutateAsync,
  };
};

export default useDocumentRequests;
