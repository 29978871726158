import { useCallback } from "react";

import { ThirdPartyInteraction, ThirdPartyDocumentAttributes } from "types";
import { useMutation } from "query-utils";
import thirdPartyDocumentsService from "services/thirdPartyDocumentsService";

interface UpdateDocumentAttributesProps {
  documentId: string;
  attributes: Partial<ThirdPartyDocumentAttributes>;
}

export const useUpdateDocumentAttributes = ({
  interaction,
  projectToken,
  service = thirdPartyDocumentsService,
  onSuccess = () => {},
}: {
  interaction?: ThirdPartyInteraction;
  projectToken: string;
  service?: typeof thirdPartyDocumentsService;
  onSuccess?: () => void;
}) => {
  const updateDocumentAttributes = useCallback(
    (documentId: string, attributes: Partial<ThirdPartyDocumentAttributes>) => {
      return service.updateDocumentAttributes(projectToken, documentId, attributes);
    },
    [service, projectToken]
  );

  const updateDocumentAttributesMutation = useMutation(
    ({ documentId, attributes }: UpdateDocumentAttributesProps) => {
      const { documentDate, ...rest } = attributes;
      const {
        createdAt: interactionCreatedAt,
        attribute: { documentDate: interactionDocumentDate },
      } = interaction ?? { attribute: {} };
      const isDateUpdated =
        documentDate && documentDate !== interactionCreatedAt && documentDate !== interactionDocumentDate;
      return updateDocumentAttributes(documentId, {
        ...rest,
        ...(isDateUpdated && { documentDate }),
      });
    },
    {
      onSuccess,
    }
  );

  return {
    onSaveEdits: updateDocumentAttributesMutation.mutateAsync,
  };
};
