import { forwardRef } from "react";
import { AlphaNowCompanyPrimerProps } from "../CompanyPrimerContentPageVars";
import CompanyPrimerContentV1 from "./v1/CompanyPrimerContent";
import CompanyPrimerContentV2 from "./v2/CompanyPrimerContent";
import CompanyPrimerContentV3 from "./v3/CompanyPrimerContent";
import { PrimerVersion } from "models/primers/constants";
import { HitOrigin } from "@alphasights/portal-api-client";

interface CompanyPrimerContentFactoryProps extends Pick<AlphaNowCompanyPrimerProps, "content"> {
  primerStatus: string;
  isHidden?: boolean;
  isFetchingContent?: boolean;
  isDeliverables?: boolean;
  origin?: HitOrigin;
  onContentErrorChanged: (error: string) => void;
  onPurchasedContentChanged: () => void;
  price?: number;

  showShrinkedPaywallHeader: (value: boolean) => void;
}

const CompanyPrimerContentFactory = forwardRef<HTMLDivElement, CompanyPrimerContentFactoryProps>(
  (
    {
      content,
      primerStatus,
      isHidden = false,
      isFetchingContent = false,
      isDeliverables = false,
      onContentErrorChanged,
      onPurchasedContentChanged,
      showShrinkedPaywallHeader,
      price = 0,
      origin = HitOrigin.alphaNow,
    },
    ref
  ) => {
    switch (content.srmTypeVersion) {
      case PrimerVersion.PRIMER_VERSION_1:
        return (
          <CompanyPrimerContentV1
            ref={ref}
            isHidden={isHidden}
            primerStatus={primerStatus}
            isFetchingContent={isFetchingContent}
            content={content as CompanyPrimerContentV1}
            showShrinkedPaywallHeader={showShrinkedPaywallHeader}
            origin={origin}
          />
        );
      case PrimerVersion.PRIMER_VERSION_2:
        return (
          <CompanyPrimerContentV2
            ref={ref}
            isHidden={isHidden}
            primerStatus={primerStatus}
            isFetchingContent={isFetchingContent}
            content={content as CompanyPrimerContentV2}
            showShrinkedPaywallHeader={showShrinkedPaywallHeader}
            origin={origin}
          />
        );
      case PrimerVersion.PRIMER_VERSION_3:
        return (
          <CompanyPrimerContentV3
            ref={ref}
            price={price}
            isHidden={isHidden}
            primerStatus={primerStatus}
            isDeliverables={isDeliverables}
            isFetchingContent={isFetchingContent}
            content={content as CompanyPrimerContentV3}
            origin={origin}
            onContentErrorChanged={onContentErrorChanged}
            onPurchasedContentChanged={onPurchasedContentChanged}
          />
        );
      default:
        break;
    }

    return null;
  }
);

export default CompanyPrimerContentFactory;
