import { x } from "@xstyled/styled-components";
import { IconButton, IconButtonVariant, useThemeTokens } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";

interface ExpertsViewButtonProps {
  onClick: () => void;
  isActive: boolean;
  variant: IconButtonVariant;
}
const ExpertsViewButton = ({ onClick, isActive, variant }: ExpertsViewButtonProps) => {
  const {
    color: { icon },
  } = useThemeTokens();
  return (
    <x.div data-testid="experts-button">
      <IconButton
        onClick={onClick}
        variant={variant}
        isSelectable
        isSelected={isActive}
        {...(isActive ? {} : { color: icon.secondary })}
      >
        <Expert />
      </IconButton>
    </x.div>
  );
};

export default ExpertsViewButton;
