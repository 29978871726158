import {
  ForwardedRef,
  RefObject,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as React from "react";
import {
  Accordion,
  Divider,
  VerticalTabItem,
  VerticalTabs,
  Typography,
  useThemeTokens,
  Icon,
  PillAccessoryIconButton,
  Pill,
  Alert,
  Skeleton,
} from "@alphasights/alphadesign-components";
import styled, { th, x } from "@xstyled/styled-components";
import { ChevronDown, ChevronUp, Expert, Quote, Refresh } from "@alphasights/alphadesign-icons";
import { useStyles } from "./ProjectSummary.styles";
import { ProjectSummaryPageSkeleton, ProjectSummarySkeleton } from "./ProjectSummarySkeletons";
import { CustomThemePayload, useProjectSummariesContext } from "providers/ProjectSummariesProvider";
import {
  ExpertInsights,
  ProjectSummaryContent,
  ProjectSummaryOverview,
  ProjectSummaryPart,
  ProjectSummaryStatus,
  ProjectSummaryTheme,
} from "./ProjectSummaries.types";
import { ProjectSummaryRender } from "./ProjectSummaryRender";
import _ from "lodash";
import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { useCallbackContext } from "providers/CallbackProvider";
import { FLYOUT_SECTIONS } from "providers/FlyoutProvider";
import { CopyButton, IconButtonRegularPosition } from "components/CopyButton/CopyButton";
import { EmphasisTypography } from "../transcripts/EmphasisTypography";
import { tagHighlight } from "../transcripts/support";
import { ProjectSummaryHeader } from "./ProjectSummaryHeader";
import { CustomThemeHandle } from "./CustomThemeHandle";
import { useProjectSummarySearch } from "./useProjectSummarySearch";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ProjectSummaryError } from "./ProjectSummaryError";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { css } from "styled-components";
import useQueryParams from "hooks/useQueryParams";
import { GenerativeAiDisclaimer } from "components/GenerativeAiDisclaimer";

export const ProjectSummary = () => {
  const styles = useStyles();

  const {
    selectedSummary,
    isSelectedSummaryLoading,
    angles,
    onChangeSelectedAngle,
    uniqSummariesStatusByAngle,
    generateProjectSummaries,
    onDeleteCurrentSummary,
    onSubmitCustomTheme,
    projectSummaryLogHit,
    isAnySummaryGenerating,
  } = useProjectSummariesContext();

  const {
    searchTerms,
    onSearchTerms,
    onSearchNavPrevious,
    onSearchNavNext,
    searchIx,
    searchCounts,
    searchedSummary,
    searchElHighlight,
  } = useProjectSummarySearch({ summary: selectedSummary });

  const containerRef = useRef(null);

  const onRefreshAllSummaries = () => {
    generateProjectSummaries();
  };

  const submitCustomThemeAndLogHit = (payload: CustomThemePayload) => {
    return onSubmitCustomTheme(payload).then((content) => {
      if (payload.themesToRemove.length > 0) {
        projectSummaryLogHit(
          payload.themesToRemove.map((t) => {
            return {
              action: HitAction.projectSummaryDeleteThemes,
              summary: selectedSummary,
              extraDetails: { themeTitle: t },
            };
          })
        );
      }
      if (payload.newThemes.length > 0) {
        projectSummaryLogHit(
          payload.newThemes.map((t) => {
            return {
              action: HitAction.projectSummaryAddThemes,
              summary: selectedSummary,
              extraDetails: { themeTitle: t.title, description: t.description },
            };
          })
        );
      }
      return content;
    });
  };

  return (
    <x.div {...styles.wrapper} data-testid={`project-summary-${selectedSummary?.id ?? ""}`}>
      <ProjectSummaryHeader
        searchTerms={searchTerms}
        onSearchTerms={onSearchTerms}
        angles={angles}
        onSelectAngle={onChangeSelectedAngle}
        onUpdate={onRefreshAllSummaries}
        onDelete={onDeleteCurrentSummary}
        onSearchNavPrevious={onSearchNavPrevious}
        onSearchNavNext={onSearchNavNext}
        selectedSummary={selectedSummary}
        searchCurrentIx={searchIx}
        searchTotalResults={searchCounts?.summary}
        uniqSummariesStatusByAngle={uniqSummariesStatusByAngle}
        isSelectSummaryLoading={isSelectedSummaryLoading}
        projectSummaryLogHit={projectSummaryLogHit}
        isAnySummaryGenerating={isAnySummaryGenerating}
      />
      <x.div {...styles.contentWrapper} ref={containerRef} data-testid="project-summary-content">
        <ProjectSummaryMainContent
          containerRef={containerRef}
          selectedSummary={searchedSummary}
          searchElHighlight={searchElHighlight}
          searchCounts={searchCounts}
          onSubmitCustomTheme={submitCustomThemeAndLogHit}
        />
      </x.div>
    </x.div>
  );
};

const ProjectSummaryMainContent = ({
  containerRef,
  selectedSummary,
  searchElHighlight,
  searchCounts,
  onSubmitCustomTheme,
}: {
  containerRef: RefObject<HTMLDivElement>;
  selectedSummary: ProjectSummaryContent;
  searchElHighlight: Element | null;
  searchCounts: { overview: number; themes: number[]; insights: number[][] } | null;
  onSubmitCustomTheme: (payload: CustomThemePayload) => Promise<ProjectSummaryContent>;
}) => {
  const styles = useStyles();
  const { isSelectedSummaryLoading, projectSummaryLogHit } = useProjectSummariesContext();
  const sectionRefs = useMemo(
    () => [createRef<HTMLDivElement>(), ...(selectedSummary?.themes.map(() => createRef<HTMLDivElement>()) ?? [])],
    [selectedSummary]
  );

  const queryParams = useQueryParams();
  useEffect(() => {
    if (!selectedSummary) return;

    const source = queryParams.get("source");
    if (source !== null) {
      queryParams.delete("source");
    }

    if (selectedSummary.status === ProjectSummaryStatus.Error) {
      projectSummaryLogHit({
        action: HitAction.projectSummaryGenerateError,
        extraDetails: { section: "whole-summary", themeTitle: null },
      });
    } else {
      projectSummaryLogHit({
        action: HitAction.projectSummaryView,
        origin: source ? HitOrigin.email : HitOrigin.deliverablesView,
      });
    }
  }, [selectedSummary?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (selectedSummary?.status === ProjectSummaryStatus.Error) {
    return <ProjectSummaryError />;
  }

  if (
    isSelectedSummaryLoading ||
    selectedSummary?.status === ProjectSummaryStatus.Pending ||
    selectedSummary?.status === ProjectSummaryStatus.Processing
  ) {
    return <ProjectSummaryPageSkeleton />;
  }

  return (
    <>
      {selectedSummary && (
        <>
          <x.div {...styles.summaryColumn}>
            <GenerativeAiDisclaimer />
            <HighLevelOverview overview={selectedSummary.overview} ref={sectionRefs[0]} />
            <Divider spacing="lg" />
            {selectedSummary.themes.map((theme, idx) => (
              <React.Fragment key={theme.title}>
                <ThemeSection
                  theme={theme}
                  ref={sectionRefs[idx + 1]}
                  index={idx + 1}
                  searchElHighlight={searchElHighlight}
                  insightsSearchCounts={searchCounts?.insights[idx]}
                />
                {idx + 1 < selectedSummary.themes.length && <Divider spacing="lg"></Divider>}
              </React.Fragment>
            ))}
          </x.div>
          <x.div {...styles.navigationColumn}>
            <SummaryNavigation
              sectionRefs={sectionRefs}
              containerRef={containerRef}
              selectedSummary={selectedSummary}
              searchCounts={searchCounts}
              onSubmitCustomTheme={onSubmitCustomTheme}
            />
          </x.div>
        </>
      )}
    </>
  );
};

const SummaryNavigation = ({
  sectionRefs,
  containerRef,
  selectedSummary,
  searchCounts,
  onSubmitCustomTheme,
}: {
  sectionRefs: RefObject<HTMLDivElement>[];
  containerRef: RefObject<HTMLDivElement>;
  selectedSummary: ProjectSummaryContent;
  searchCounts: { overview: number; themes: number[]; insights: number[][] } | null;
  onSubmitCustomTheme: (payload: CustomThemePayload) => Promise<ProjectSummaryContent>;
}) => {
  const [sectionsVisibility, setSectionsVisibility] = useState<boolean[]>([]);
  const { readOnly } = useProjectSummariesContext();

  const navigationThemes = useMemo(() => {
    return [
      {
        label: "Overview",
        count: searchCounts?.overview,
      },
      ...(selectedSummary?.themes.map((theme, ix) => ({
        label: theme.title.replaceAll("<em>", "").replaceAll("</em>", ""),
        count: searchCounts?.themes[ix],
      })) || []),
    ];
  }, [selectedSummary, searchCounts]);

  const selectedIndex = useMemo(() => {
    const index = sectionsVisibility.findIndex((isVisible) => isVisible);
    return index >= 0 ? index : 0;
  }, [sectionsVisibility]);

  const onClickTheme = useCallback(
    (index: number) => {
      sectionRefs[index].current?.scrollIntoView({ behavior: "smooth" });
    },
    [sectionRefs]
  );

  const isVisible = useCallback((entry: IntersectionObserverEntry) => {
    const ratio = entry.intersectionRatio;
    if (ratio === 0) {
      return false;
    } else {
      return true;
    }
  }, []);

  useEffect(() => {
    if (sectionRefs.length <= 1) return;
    const observer = new IntersectionObserver(
      (entries) => {
        const changedEntries = _.compact(
          entries.map((entry) => {
            const index = sectionRefs.findIndex((ref) => ref.current === entry.target);
            if (index >= 0) {
              return { position: index, visibility: isVisible(entry) };
            } else {
              return undefined;
            }
          })
        );

        setSectionsVisibility((prev) => {
          changedEntries.forEach(({ position, visibility }) => {
            prev[position] = visibility;
          });
          return [...prev];
        });
      },
      { root: containerRef.current, rootMargin: "0px 0px", threshold: 0 }
    );
    sectionRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, [sectionRefs, isVisible, containerRef]);

  return (
    <>
      <VerticalTabs
        key={JSON.stringify(navigationThemes)}
        index={selectedIndex}
        onChange={onClickTheme}
        data-testid="summary-navigator"
      >
        {navigationThemes.map((theme) => (
          <VerticalTabItem
            key={theme.label}
            label={theme.label}
            maxLines={2}
            rightAccessory={
              Boolean(searchCounts) && (theme.count || 0) > 0 ? (
                <Pill
                  data-testid="mentions-count"
                  variant="blue"
                  size="x-small"
                  leftAccessories={
                    <PillAccessoryIconButton>
                      <Quote />
                    </PillAccessoryIconButton>
                  }
                >
                  {theme.count}
                </Pill>
              ) : undefined
            }
          />
        ))}
      </VerticalTabs>
      {!readOnly && <CustomThemeHandle themes={selectedSummary.themes} onSubmit={onSubmitCustomTheme} />}
    </>
  );
};

export const HighLevelOverview = forwardRef(
  ({ overview }: { overview: ProjectSummaryOverview }, ref: ForwardedRef<HTMLDivElement>) => {
    const styles = useStyles();
    const highOverviewRef = useRef(null);
    const { onRegenerateOverview, projectSummaryLogHit, readOnly } = useProjectSummariesContext();
    const { isMobile } = useCheckScreen();
    const canRegenerate =
      overview.status !== ProjectSummaryStatus.Processing && overview.status !== ProjectSummaryStatus.Pending;

    const onClickRefresh = () => {
      onRegenerateOverview();
      projectSummaryLogHit({
        action: HitAction.projectSummaryRefreshOverview,
        extraDetails: {
          section: "high-level-overview",
          themeTitle: "Overview",
        },
      });
    };

    useEffect(() => {
      if (overview.status === ProjectSummaryStatus.Error) {
        projectSummaryLogHit({
          action: HitAction.projectSummaryGenerateError,
          extraDetails: { section: "high-level-overview", themeTitle: "Overview" },
        });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <x.div {...styles.highLevelOverview} ref={ref} data-testid="high-level-overview">
        <x.div {...styles.highLevelOverviewTitle}>
          <Typography variant="h3">Overview</Typography>
          {!isMobile && !readOnly && (
            <x.div {...styles.highLevelOverviewIconGroup}>
              <IconButtonRegularPosition
                onClick={onClickRefresh}
                size="medium"
                variant="basic"
                testId="regenerate-button"
                disabled={!canRegenerate}
              >
                <Refresh />
              </IconButtonRegularPosition>
              {overview.status === ProjectSummaryStatus.Complete && (
                <CopyButton
                  messageRef={highOverviewRef}
                  testId="high-level-copy-button"
                  variant="basic"
                  size="medium"
                  onClick={() =>
                    projectSummaryLogHit({
                      action: HitAction.projectSummaryCopy,
                      extraDetails: {
                        section: "high-level-overview",
                        themeTitle: "Overview",
                      },
                    })
                  }
                ></CopyButton>
              )}
            </x.div>
          )}
        </x.div>
        {overview.status !== ProjectSummaryStatus.Complete && overview.status !== ProjectSummaryStatus.Error && (
          <>
            <Skeleton variant="noMargin" />
            <Skeleton variant="noMargin" />
            <Skeleton variant="noMargin" />
            <Skeleton variant="noMargin" />
          </>
        )}
        {overview.status === ProjectSummaryStatus.Error && (
          <Alert variant="danger" w="100%">
            <b>Error occurred:</b> unable to generate overview. Please refresh.
          </Alert>
        )}
        <BorderlessContainer>
          <x.div
            {...styles.highLevelOverview}
            ref={highOverviewRef}
            userSelect={readOnly ? "none" : "auto"}
            onContextMenu={readOnly ? (e) => e.preventDefault() : undefined}
          >
            {" "}
            {overview.status === ProjectSummaryStatus.Complete &&
              overview.parts.map((part) => (
                <EmphasisTypography key={part.text}>
                  <>{tagHighlight(part.text)}</>
                </EmphasisTypography>
              ))}
          </x.div>
        </BorderlessContainer>
      </x.div>
    );
  }
);

export const ThemeSection = forwardRef(
  (
    {
      theme,
      index,
      searchElHighlight,
      insightsSearchCounts,
    }: {
      theme: ProjectSummaryTheme;
      index: number;
      searchElHighlight: Element | null;
      insightsSearchCounts: number[] | undefined;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const tokens = useThemeTokens();
    const styles = useStyles();
    const {
      getInteractionById,
      onRegenerateOverview,
      projectSummaryLogHit,
      onSelectInsight,
      readOnly,
    } = useProjectSummariesContext();
    const canRegenerate =
      theme.overview.status !== ProjectSummaryStatus.Processing &&
      theme.overview.status !== ProjectSummaryStatus.Pending;
    const overviewRef = useRef(null);
    const quotesRef = useRef(null);
    const { isMobile } = useCheckScreen();

    const quotes = useMemo(() => theme.overview.parts.filter((part) => part.type === "quote"), [theme.overview.parts]);
    const points = useMemo(() => theme.overview.parts.filter((part) => part.type === "point"), [theme.overview.parts]);

    const interactions = useMemo(() => {
      const map = new Map<string, Interaction>();
      theme.overview.parts
        .flatMap((part) => part.interactionIds.map((id) => getInteractionById(id)))
        .forEach((interaction) => interaction && map.set(interaction.id, interaction));
      return map;
    }, [theme.overview.parts, getInteractionById]);

    const sentences = useMemo(() => {
      return points.map((part: ProjectSummaryPart, ix: Number) => {
        return {
          speaker: part.type === "point" ? "copyablePoint" : part.type,
          position: ix,
          value: part.text,
          hasHighlight: (part as any).hasHighlight,
          highlightCounts: (part as any).highlightCounts,
          part: part,
          interaction: interactions.get(part.interactionIds.find((a) => a) || ""),
          onSelectInsight,
        };
      });
    }, [points, interactions, onSelectInsight]);

    const quotesSentences = useMemo(() => {
      return quotes.map((part: ProjectSummaryPart, ix: Number) => {
        return {
          speaker: "citation",
          position: ix,
          value: part.text,
          expertName: interactions.get(part.interactionIds.find((a) => a) || "")?.advisorName,
          hasHighlight: (part as any).hasHighlight,
          highlightCounts: (part as any).highlightCounts,
          part: part,
          interaction: interactions.get(part.interactionIds.find((a) => a) || ""),
          onSelectInsight,
        };
      });
    }, [quotes, interactions, onSelectInsight]);

    const onClickRefresh = () => {
      onRegenerateOverview(theme.title);
      projectSummaryLogHit({
        action: HitAction.projectSummaryRefreshOverview,
        extraDetails: {
          section: "theme-overview",
          themeTitle: theme.title,
        },
      });
    };

    useEffect(() => {
      if (theme.overview.status === ProjectSummaryStatus.Error) {
        projectSummaryLogHit({
          action: HitAction.projectSummaryGenerateError,
          extraDetails: { section: "theme-overview", themeTitle: theme.title },
        });
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <x.div
        ref={ref}
        data-testid={`theme-section-${index}`}
        userSelect={readOnly ? "none" : "auto"}
        onContextMenu={readOnly ? (e) => e.preventDefault() : undefined}
      >
        <x.div {...styles.themesTitle}>
          <EmphasisTypography variant="h3" shouldCapitalizeHeadline={false}>
            <>{tagHighlight(theme.title)}</>
          </EmphasisTypography>
          {!isMobile && !readOnly && (
            <IconButtonRegularPosition
              onClick={onClickRefresh}
              size="medium"
              variant="basic"
              disabled={!canRegenerate}
              testId="theme-overview-regenerate-button"
            >
              <Refresh />
            </IconButtonRegularPosition>
          )}
        </x.div>
        {theme.overview.status !== ProjectSummaryStatus.Complete &&
          theme.overview.status !== ProjectSummaryStatus.Error && (
            <x.div mb={tokens.spacing.inner.base08} data-testid="theme-overview-loading">
              <ProjectSummarySkeleton />
            </x.div>
          )}
        {theme.overview.status === ProjectSummaryStatus.Error && (
          <Alert variant="danger" mb={tokens.spacing.inner.base08} w="100%">
            <b>Error occurred:</b> unable to generate overview. Please refresh.
          </Alert>
        )}
        {theme.overview.status === ProjectSummaryStatus.Complete && (
          <>
            <EmphasisTypography mb={tokens.spacing.inner.base08}>
              <>{tagHighlight(theme.description)}</>
            </EmphasisTypography>
            {points.length > 0 && (
              <x.div mb={tokens.spacing.inner.base08}>
                <x.div {...styles.themesTitle}>
                  <Typography variant="navigation">Overview</Typography>
                  {!isMobile && !readOnly && (
                    <CopyButton
                      messageRef={overviewRef}
                      testId="overview-copy-button"
                      variant="basic"
                      size="medium"
                      onClick={() =>
                        projectSummaryLogHit({
                          action: HitAction.projectSummaryCopy,
                          extraDetails: {
                            section: "theme-overview",
                            themeTitle: theme.title,
                          },
                        })
                      }
                    ></CopyButton>
                  )}
                </x.div>
                <BorderlessContainer>
                  <ProjectSummaryRender sentences={sentences} summaryRef={overviewRef} />
                </BorderlessContainer>
              </x.div>
            )}

            {quotes.length > 0 && (
              <x.div mb={tokens.spacing.inner.base08}>
                <x.div {...styles.themesTitle}>
                  <Typography variant="navigation">Key Quotes</Typography>
                  {!isMobile && !readOnly && (
                    <CopyButton
                      messageRef={quotesRef}
                      testId="key-quotes-copy-button"
                      variant="basic"
                      size="medium"
                      onClick={() =>
                        projectSummaryLogHit({
                          action: HitAction.projectSummaryCopy,
                          extraDetails: {
                            section: "key-quotes",
                            themeTitle: theme.title,
                          },
                        })
                      }
                    ></CopyButton>
                  )}
                </x.div>
                <BorderlessContainer>
                  <ProjectSummaryRender sentences={quotesSentences} summaryRef={quotesRef} />
                </BorderlessContainer>
              </x.div>
            )}
          </>
        )}

        {theme.expertInsights.length > 0 && (
          <x.div {...styles.furtherInsightsBox} data-testid="insights-box">
            <x.div p={tokens.spacing.inner.base06}>
              <Typography variant="navigation">Further Expert Insights</Typography>
            </x.div>
            <Divider />
            <Accordion>
              {theme.expertInsights.map((insight, ix) => (
                <>
                  <Insights
                    insights={insight}
                    searchElHighlight={searchElHighlight}
                    insightSearchCount={insightsSearchCounts && insightsSearchCounts[ix]}
                  />
                  {ix < theme.expertInsights.length - 1 && <Divider />}
                </>
              ))}
            </Accordion>
          </x.div>
        )}
      </x.div>
    );
  }
);

const SentenceHighlightHover = styled(x.div)`
  ${(props) => {
    return props.onClick
      ? css`
          cursor: pointer;

          &:hover i,
          &:hover ul {
            background-color: ${th.color("background-highlightBase")};
          }
        `
      : null;
  }}
`;

const Insights = ({
  insights,
  searchElHighlight,
  insightSearchCount,
}: {
  insights: ExpertInsights;
  searchElHighlight: Element | null;
  insightSearchCount: number | undefined;
}) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const insightsRef = useRef<HTMLElement>(null);
  const { getInteractionById, onSelectInsight } = useProjectSummariesContext();
  const {
    callbacks: { onSelectCard },
  } = useCallbackContext();

  const interaction = useMemo(() => insights.interactionIds.map((id) => getInteractionById(id)).find((a) => a), [
    getInteractionById,
    insights.interactionIds,
  ]);

  const advisorInfo = useMemo(() => {
    if (!interaction) return null;
    const angle = interaction.angles[0];

    return {
      company: interaction.advisorCompany,
      role: interaction.role,
      angle: angle?.title ?? "",
      name: interaction.advisorName,
      interactionDate: interaction.scheduledCallTime,
    };
  }, [interaction]);

  const sentences = useMemo(() => {
    return insights.parts.map((part: ProjectSummaryPart, ix: Number) => {
      return {
        speaker: part.type === "point" ? "copyablePoint" : part.type,
        position: ix,
        value: part.text,
        expertName: advisorInfo?.name,
        hasHighlight: (part as any).hasHighlight,
        highlightCount: (part as any).highlightCount,
        part: part,
      };
    });
  }, [advisorInfo, insights.parts]);

  const onClickExpertName = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onSelectCard({
        ...interaction,
        flyoutAction: FLYOUT_SECTIONS.expandExpert,
      });
      e.stopPropagation();
    },
    [interaction, onSelectCard]
  );

  useEffect(() => {
    if (!insightsRef.current) return;
    if (!searchElHighlight) return;

    if (insightsRef.current!!.contains(searchElHighlight)) {
      setIsOpen(true);
    }
  }, [searchElHighlight]);

  if (!advisorInfo) {
    return null;
  }

  return (
    <CustomPaddedAccordionItem data-testid="insights-accordion-item">
      <x.div {...styles.insightsTitle}>
        <x.div
          {...styles.insightsTitleIcon}
          data-testid="insights-accordion-item-icon"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon>
            <Expert />
          </Icon>
        </x.div>
        <x.div flex={1}>
          <Typography
            variant="body-small-em"
            onClick={onClickExpertName}
            data-testid="insights-accordion-item-title"
          >{`${advisorInfo.company} | ${advisorInfo.role}`}</Typography>
          <Typography variant="body-small" color="secondary">{`${advisorInfo.angle} | ${advisorInfo.name} | ${
            advisorInfo.interactionDate && format(parseISO(advisorInfo.interactionDate), "d MMM yyyy")
          }`}</Typography>
        </x.div>
        <x.div {...styles.insightsCountAndChevron}>
          {insightSearchCount ? (
            <span>
              <Pill
                data-testid="expert-insight-search-count"
                variant="blue"
                size="x-small"
                isInteractive={false}
                leftAccessories={
                  <PillAccessoryIconButton>
                    <Quote />
                  </PillAccessoryIconButton>
                }
              >
                {insightSearchCount}
              </Pill>
            </span>
          ) : null}
          <IconButtonRegularPosition size="small" variant="ghost" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </IconButtonRegularPosition>
        </x.div>
      </x.div>
      <x.div role="region" display={isOpen ? undefined : "none"} pt="16px">
        {sentences.map((sentence) => {
          return (
            <SentenceHighlightHover
              data-testid={`insight-${advisorInfo?.name}-${sentence.position}`}
              key={sentence.value}
              onClick={
                sentence.part.sources?.length > 0
                  ? () => interaction && onSelectInsight({ insight: sentence.part, interaction })
                  : undefined
              }
            >
              <ProjectSummaryRender sentences={[sentence]} summaryRef={insightsRef} />
            </SentenceHighlightHover>
          );
        })}
      </x.div>
    </CustomPaddedAccordionItem>
  );
};

const CustomPaddedAccordionItem = styled(x.div)`
  padding-top: ${th.space("inner-base04")};
  padding-bottom: ${th.space("inner-base04")};
  padding-left: ${th.space("inner-base06")};
  padding-right: ${th.space("inner-base06")};
  div[role="button"] {
    padding-top: ${th.space("inner-base04")};
    padding-bottom: ${th.space("inner-base04")};
  }
`;

export const BorderlessContainer = styled.div`
  * {
    border-style: none;
  }
`;
