import { useQueries } from "query-utils";
import { clientControlsService } from "services/clientControlsService";
import { useMemo } from "react";

export const Product = {
  ALPHAVIEW_DIAL_IN: "ALPHAVIEW_DIAL_IN",
  ALPHAVIEW_TRANSCRIPT: "ALPHAVIEW_TRANSCRIPT",
  PEER_CONTRIBUTED_CONTENT: "PEER_CONTRIBUTED_CONTENT",
  PRIVATE_TRANSCRIPT_LIBRARY: "PRIVATE_TRANSCRIPT_LIBRARY",
};

export const useClientLevers = () => {
  const clientLevers = useQueries({
    queries: Object.values(Product).map((product) => ({
      queryKey: ["client-levers", product],
      queryFn: () => clientControlsService.fetchClientLevers(product),
    })),
  });

  const allQueriesFetched = useMemo(() => {
    return clientLevers.every((query) => query.isFetched);
  }, [clientLevers]);

  const processedLevers = useMemo(
    () =>
      allQueriesFetched
        ? clientLevers
            .map((query) => query.data)
            .filter(Boolean)
            .flat()
        : [],
    [allQueriesFetched, clientLevers]
  );

  const showInsights = useMemo(() => {
    return processedLevers.length
      ? !processedLevers.some((lever) =>
          [
            "anchorCompanyEmployedBehaviour",
            "publicCompanyEmployedBehaviour",
            "doNotCallListEmployedBehaviour",
            "governmentOrMilitaryEmployedBehaviour",
          ].some((field) => lever[field]?.toLowerCase() === "hide")
        )
      : true;
  }, [processedLevers]);

  return { showInsights };
};

export default useClientLevers;
