import { isPast, isBefore, subHours, isWithinInterval, addSeconds } from "date-fns";
import { parseISO } from "providers/TimezoneProvider";

export const isDuringInteraction = ({
  scheduledCallTime,
  expectedDuration,
}: {
  scheduledCallTime: string | Date;
  expectedDuration?: number;
}) =>
  scheduledCallTime &&
  expectedDuration &&
  isWithinInterval(new Date(), {
    start: parseISO(scheduledCallTime),
    end: addSeconds(parseISO(scheduledCallTime), expectedDuration),
  });

export const isPostInteraction = ({
  state,
  scheduledCallTime,
  expectedDuration,
}: {
  state: string;
  scheduledCallTime: string | Date;
  expectedDuration?: number;
}) =>
  state === "completed" ||
  !!(scheduledCallTime && expectedDuration && isPast(addSeconds(parseISO(scheduledCallTime), expectedDuration)));

export const isPostScheduledCallTime = ({ scheduledCallTime }: { scheduledCallTime: string | Date }) =>
  scheduledCallTime && isPast(parseISO(scheduledCallTime));

export const isAtLeastHoursBeforeInteraction = ({
  scheduledCallTime,
  hoursBefore,
}: {
  scheduledCallTime: string | Date;
  hoursBefore: number;
}) => !scheduledCallTime || isBefore(new Date(), subHours(parseISO(scheduledCallTime), hoursBefore));

export const isRecordingExpired = (recording?: Recording) =>
  !!(recording?.purgedAt || (recording?.expiresAt && isPast(parseISO(recording.expiresAt))));

export const isRecordingAvaiable = (recording?: Recording) =>
  !isRecordingExpired(recording) && recording?.visibleToClient;
