import _ from "lodash";
import { Expert, ThreadResponse, ParticipantRole, ParticipantResponse } from "types";

export const setExpertsNamesThread = (originalThread: ThreadResponse, experts: Expert[], avatarColor?: string) => {
  const thread = _.cloneDeep(originalThread);
  thread.advisor.name = getParticipantName(thread.advisor, experts);
  thread.messages?.forEach((message) => {
    message.sender.name = getParticipantName(message.sender, experts);
    if (message.sender.role === ParticipantRole.Advisor) {
      message.sender.avatarColor = avatarColor;
    } else {
      message.sender.avatarColor = "base02";
    }
  });
  return thread;
};

export const getParticipantName = (participant: ParticipantResponse, experts: Expert[]) => {
  return participant.role === ParticipantRole.Advisor
    ? experts.find((expert) => expert.id === participant.id)?.name ?? participant.name
    : participant.name;
};
