import { Button, Typography } from "@alphasights/alphadesign-components";
import { useEffect, useMemo } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { useWorkRequestMaxiumChargeStyles } from "./WorkRequestMaximumCharge.styles";
import { Expert, ExpertCharge, MessageType } from "types";
import { useWorkRequestCredits } from "hooks/messenger/useWorkRequestCredits";

interface WorkRequestMaximumChargeProps {
  projectToken: string;
  maximumTaskDuration: number | undefined;
  selectedExperts: Expert[];
  isExpertsListOpen: boolean;
  setIsExpertsListOpen: Function;
  setExpertsCharge: (experts: ExpertCharge[]) => void;
  messageType: MessageType;
}

export const WorkRequestMaximumCharge: React.FC<WorkRequestMaximumChargeProps> = ({
  projectToken,
  maximumTaskDuration: maximumTaskDurationInput,
  selectedExperts = [],
  isExpertsListOpen = false,
  setIsExpertsListOpen,
  messageType,
}) => {
  const maximumTaskDuration = useMemo(() => maximumTaskDurationInput ?? 0, [maximumTaskDurationInput]);
  const [credits, setCredits] = React.useState<string>("No charge");

  const { isCreditsLoading, calculatePerExperts, convertCreditsToString } = useWorkRequestCredits({
    projectToken,
    duration: maximumTaskDuration,
  });

  useEffect(() => {
    if (messageType !== MessageType.WorkRequest) {
      setIsExpertsListOpen(false);
    }
  }, [messageType, setIsExpertsListOpen]);

  useEffect(() => {
    if (!isCreditsLoading) {
      if (selectedExperts.length === 0) {
        setCredits(convertCreditsToString(0));
      } else {
        setCredits(convertCreditsToString(calculatePerExperts(selectedExperts)));
      }
    }
  }, [isCreditsLoading, selectedExperts, calculatePerExperts, convertCreditsToString]);

  return (
    <>
      <Typography component="div" color="secondary">
        Maximum charge:{" "}
        {messageType === MessageType.WorkRequest ? (
          selectedExperts.length > 0 && maximumTaskDuration ? (
            <MaximumChargeLink
              expertCount={selectedExperts.length}
              totalCharge={credits}
              opened={isExpertsListOpen}
              setOpened={setIsExpertsListOpen}
            />
          ) : (
            credits
          )
        ) : (
          "Free"
        )}
      </Typography>
    </>
  );
};

interface MaximumChargeLinkProps {
  expertCount: number;
  totalCharge: string;
  opened: boolean;
  setOpened: Function;
  fontColor?: string;
}

export const MaximumChargeLink: React.FC<MaximumChargeLinkProps> = ({
  expertCount,
  totalCharge,
  opened,
  setOpened,
  fontColor,
}) => {
  const { link, linkIcon } = useWorkRequestMaxiumChargeStyles({ fontColor });

  const endIcon = (
    <>
      <x.div {...linkIcon}>{opened ? <ChevronUp /> : <ChevronDown />}</x.div>
    </>
  );

  const WrapperComponent = useMemo(
    () => ({ children, ...props }: React.PropsWithChildren<any>) =>
      expertCount > 1 ? (
        <Button data-testid="experts-link" {...props}>
          {children}
        </Button>
      ) : (
        <x.span data-testid="experts-link">{children}</x.span>
      ),
    [expertCount]
  );

  return (
    <>
      <WrapperComponent variant="ghost" endIcon={endIcon} onClick={() => setOpened(!opened)} {...link}>
        <Typography variant="body" component="span">
          {totalCharge}
        </Typography>
      </WrapperComponent>
    </>
  );
};
