import { useEnv } from "@alphasights/client-portal-shared";
import { useIsInternalUser } from "@alphasights/portal-auth-react";
import { useMemo } from "react";
import { ENABLE_PROJECT_MESSENGER_APPROVAL_FLOW, useProjectBadgeContext } from "providers/BadgeProvider";

export const useImpersonatingMessenger = () => {
  const env = useEnv();
  const { hasProjectBadge } = useProjectBadgeContext();
  const isInternalUser = useIsInternalUser();

  const canSendImpersonatingMessage: boolean = useMemo(
    () =>
      ((env?.enableMessengerApprovalFlow || hasProjectBadge(ENABLE_PROJECT_MESSENGER_APPROVAL_FLOW)) &&
        !!isInternalUser) ||
      false,
    [env, isInternalUser, hasProjectBadge]
  );

  return {
    canSendImpersonatingMessage,
  };
};
