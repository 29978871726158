import { x } from "@xstyled/styled-components";
import ConditionalTooltip from "components/ConditionalTooltip";
import { DotLabel } from "pages/AlphaNowPage/primers/components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styles from "./styles/chartLegend.module.css";

type SegmentTableProps = {
  segmentInfo?: {
    leader?: string | CitableValue<string>;
    tooltip?: string | null;
    share?: string | number;
    color: string;
  }[];
  firstColumnLabel?: string;
  secondColumnLabel?: string;
  onClick: (index: number) => void;
};

const ChartLegend = ({ segmentInfo, firstColumnLabel, secondColumnLabel, onClick }: SegmentTableProps) => {
  const { color: adsColor } = useThemeTokens();

  return (
    <x.div className={styles["segment-table-container"]}>
      <x.table className={styles["segment-table"]}>
        <thead>
          <x.tr>
            <x.th data-testid="leadersColumn">
              <Typography variant="pre-title" textTransform="uppercase" color={adsColor.text.secondary}>
                {firstColumnLabel ? firstColumnLabel : "leaders"}
              </Typography>
            </x.th>
            <x.th className={styles.share} data-testid="sharesColumn">
              <Typography variant="pre-title" textTransform="uppercase" color={adsColor.text.secondary}>
                {secondColumnLabel ? secondColumnLabel : "share"}
              </Typography>
            </x.th>
          </x.tr>
        </thead>
        <tbody data-testid="tableBody">
          {segmentInfo &&
            segmentInfo.map(({ color, leader, tooltip, share }, index) => (
              <tr key={`${leader}-${index}`} onClick={() => onClick(index)}>
                <x.td>
                  <ConditionalTooltip tooltip={tooltip ?? ""}>
                    <DotLabel dotColor={color} label={leader} labelColor={adsColor.text.strong._} />
                  </ConditionalTooltip>
                </x.td>
                <x.td className={`${styles["segment-table"]} ${styles.share}`}>
                  <Typography variant="body-em" color={adsColor.text.secondary}>
                    {share}%
                  </Typography>
                </x.td>
              </tr>
            ))}
        </tbody>
      </x.table>
    </x.div>
  );
};

export default ChartLegend;
