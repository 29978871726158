import { useEffect, useState } from "react";
import { Alert, Button, Checkbox, Dialog, Loading, Typography } from "@alphasights/alphadesign-components";
import { CidStatus } from "components/CidBadge/cid-status";
import { x } from "@xstyled/styled-components";
import { useCidOneOffApprovalModalStyles } from "./CidOneOffApprovalModal.styles";

export const CidOneOffApprovalModal = ({
  isOpen,
  onClose,
  persistOneOffApprovals,
  currentJobs = [],
  jobCidStatuses = [],
}) => {
  const [blockedApprovedCompanies, setBlockedApprovedCompanies] = useState({});
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const { errorAlert, companiesDiv, footer, dialog } = useCidOneOffApprovalModalStyles();

  useEffect(() => {
    if (isOpen) {
      const companies = currentJobs.reduce((acc, job) => {
        const jobCid = jobCidStatuses.find((jobCidStatus) => jobCidStatus.id === job.id);
        const cidStatus = jobCid && jobCid.cidStatus;
        if ([CidStatus.RESTRICTED, CidStatus.APPROVED].includes(cidStatus)) {
          return {
            ...acc,
            [job.company]: cidStatus === CidStatus.APPROVED,
          };
        }
        return acc;
      }, {});
      setBlockedApprovedCompanies(companies);
    }
  }, [isOpen, currentJobs, jobCidStatuses]);

  if (!isOpen) return null;

  const persistApprovals = async () => {
    try {
      setProcessing(true);
      const oneOffApprovals = getOneOffApprovalRequestBody();
      await persistOneOffApprovals(oneOffApprovals);
      setError("");
      closeModal();
    } catch (error) {
      setError("System Error. Failed to update CID status. Please, contact your project lead for support.");
    } finally {
      setProcessing(false);
    }
  };

  const getOneOffApprovalRequestBody = () =>
    Object.entries(blockedApprovedCompanies).reduce((acc, [company, approved]) => {
      const oneOffApprovalJobs = currentJobs
        .filter((job) => job.company === company)
        .map((job) => ({ jobId: job.id, approved }));
      return [...acc, ...oneOffApprovalJobs];
    }, []);

  const updateApprovalForCompany = (company, approved) => {
    setBlockedApprovedCompanies({
      ...blockedApprovedCompanies,
      [company]: approved,
    });
  };

  const closeModal = () => {
    setError("");
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      title="One-off Approval"
      onClose={closeModal}
      data-testid="one-off-approval-modal"
      slotWidth="480"
      {...dialog}
    >
      <div>
        {error && (
          <Alert variant="danger" {...errorAlert}>
            {error}
          </Alert>
        )}
      </div>
      <main>
        <Typography>
          Please confirm which company you are one-off approving for this expert? Please note that their CID status will
          show to <strong>Approved</strong> once all companies have been confirmed.
        </Typography>
        <x.div {...companiesDiv}>
          {Object.entries(blockedApprovedCompanies).map(([company, approved]) => (
            <Checkbox
              key={company}
              checked={approved}
              onChange={(event) => updateApprovalForCompany(company, event.target.checked)}
            >
              {company}
            </Checkbox>
          ))}
        </x.div>
      </main>
      <x.footer {...footer}>
        {processing ? (
          <Loading size="sm" />
        ) : (
          <>
            <Button variant="secondary" onClick={persistApprovals}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={closeModal}>
              Cancel
            </Button>
          </>
        )}
      </x.footer>
    </Dialog>
  );
};
