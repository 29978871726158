import { useState } from "react";
import { x } from "@xstyled/styled-components";
import { Button, Icon, IconButton, ListOption, Modal, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";

export const DeleteItemWithConfirmationBtn = ({
  onDeleteItem,
  saveInProgress = false,
  tooltipText = "",
  variant = "iconButton",
  testId = "",
}: {
  onDeleteItem: () => void;
  saveInProgress?: boolean;
  variant?: "iconButton" | "listOption";
  tooltipText?: string;
  testId?: string;
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  return (
    <>
      {variant === "listOption" ? (
        <ListOption
          type="text"
          label="Delete"
          onChange={() => setIsConfirmationOpen(true)}
          leftIcon={
            <Icon color="danger">
              <Delete />
            </Icon>
          }
        />
      ) : (
        <Tooltip variant="dark" title={tooltipText}>
          <IconButton
            size="small"
            variant="ghost"
            onClick={() => setIsConfirmationOpen(true)}
            testId={testId}
            disabled={saveInProgress}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      )}
      <ConfirmationModal isOpen={isConfirmationOpen} setIsOpen={setIsConfirmationOpen} onDeleteItem={onDeleteItem} />
    </>
  );
};

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  onDeleteItem,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onDeleteItem: () => void;
}) => {
  const handleDeleteModule = () => {
    setIsOpen(false);
    onDeleteItem();
  };

  return (
    <Modal
      title="Delete section?"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      slotHeight="112px"
      slotWidth="534px"
      zIndex={1800}
    >
      <Typography variant="body-small">
        Are you sure you want to delete this module? Deleting will permanently delete this section from view.
      </Typography>
      <x.div display="flex" alignItems="center" justifyContent="flex-end" py="16px" gap="16px">
        <Button
          onClick={() => setIsOpen(false)}
          variant="outline"
          size="small"
          dataAttributes={{ "data-testid": "delete-item-modal-cancel" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteModule}
          variant="secondary"
          size="small"
          dataAttributes={{ "data-testid": "delete-item-modal-confirm" }}
        >
          Delete
        </Button>
      </x.div>
    </Modal>
  );
};
