import { QueryStatus as QueryStatusType } from "query-utils";
import { ProcessingStatus, QueryStatus } from "./consts";

export const getSequentialFetchStatus = (
  initialFetchStatus: QueryStatusType,
  initialDataProcessingStatus: ProcessingStatus,
  contingentFetchStatus: QueryStatusType
) => {
  // If the initial fetch resulted in an error, mark the overall process as failed
  if (initialFetchStatus === QueryStatus.Error) {
    return ProcessingStatus.failed;
  }

  // If data processing is incomplete, return its current data status
  if (initialDataProcessingStatus !== ProcessingStatus.completed) {
    return initialDataProcessingStatus;
  }

  // If the initial data is processed successfully, assess the status of the second (contingent) fetch
  switch (contingentFetchStatus) {
    case QueryStatus.Success:
      return ProcessingStatus.completed;
    case QueryStatus.Error:
      return ProcessingStatus.failed;
    default:
      return ProcessingStatus.pending;
  }
};
