import { TextField } from "@alphasights/alphadesign-components";
import { Field } from "formik";

export const FormField = ({
  className = "",
  type,
  name,
  placeholder,
  size = "medium",
  disabled = false,
  readOnly,
  dataTestId,
  autoFocus,
  endAdornment,
  showErrorText = true,
  ...props
}) => {
  return (
    <Field name={name} {...props}>
      {({ field, meta: { touched, error } }) => {
        return (
          <TextField
            width="100%"
            placeholder={placeholder}
            type={type}
            size={size}
            {...field}
            error={touched && error}
            helperText={touched && showErrorText ? error : ""}
            disabled={disabled}
            data-testid={dataTestId}
            autoFocus={autoFocus}
            readOnly={readOnly}
            endAdornment={endAdornment}
          />
        );
      }}
    </Field>
  );
};
