import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

type ChartLegendProps = {
  elements: {
    color: string;
    label: string;
  }[];
};

const ChartLegend = ({ elements }: ChartLegendProps) => {
  const { color: auiColor, shape, spacing } = useThemeTokens();

  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      pt={spacing.inner.base04}
      data-testid="chart-legend-container"
    >
      <x.div
        display="flex"
        alignItems="center"
        justifyContent="center"
        columnGap={spacing.inner.base04}
        data-testid="chart-legend"
        flexWrap="wrap"
        border={`1px solid ${auiColor.border.neutral.default}`}
        p={`${spacing.inner.base02} ${spacing.inner.base03}`}
        borderRadius={shape.border.radius[8]}
      >
        {elements.map(({ color, label }) => (
          <x.div
            key={label}
            display="flex"
            alignItems="center"
            justifyContent="center"
            columnGap={spacing.inner.base02}
            padding={spacing.inner.base}
            data-testid="chart-legend-entry"
          >
            <x.div borderRadius="50%" w={spacing.inner.base02} h={spacing.inner.base02} backgroundColor={color}></x.div>
            <Typography component="div" color={auiColor.text.strong._} variant="body-small">
              {label}
            </Typography>
          </x.div>
        ))}
      </x.div>
    </x.div>
  );
};

export default ChartLegend;
