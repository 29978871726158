import { ReactElement } from "react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Add, Remove } from "@alphasights/alphadesign-icons";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import { x } from "@xstyled/styled-components";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { Citation } from "components/CitationContext/Citation";
import CitedMarkdown from "components/CitedMarkdown";
import { TestIds } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/strategic-outlook/StrategicOutlook";
import { OutlookType } from "models/primers/constants";

type PrimerOutlookSymbolProps = {
  outlookType: string;
};

const PrimerOutlookSymbol = ({ outlookType }: PrimerOutlookSymbolProps): ReactElement | null => {
  const { isMobile } = useCheckScreen();

  switch (outlookType) {
    case OutlookType.opportunity:
      return (
        <Icon color="success" size={isMobile ? "large" : "medium"}>
          <Add />
        </Icon>
      );
    case OutlookType.risk:
      return (
        <Icon color="danger" size={isMobile ? "large" : "medium"}>
          <Remove />
        </Icon>
      );
    default:
      return null;
  }
};

type PrimerOutlookProps = {
  outlook: CitableOutlook;
  outlookType: OutlookType;
};

const PrimerOutlook = ({ outlook, outlookType }: PrimerOutlookProps) => {
  const { id, theme: themeValue, description } = outlook;
  const isSensitive = hasSensitiveData(outlook);
  const {
    color: { text },
    spacing: { inner },
    font,
    shape: { border },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <x.div
      key={id.value}
      mb={{
        _: inner.base04,
        last: 0,
      }}
    >
      <Typography color={text.strong._} variant={isMobile ? "body-large" : "body"}>
        <x.div
          alignItems="center"
          display="flex"
          fontWeight={font.weight.bold}
          mb={inner.base}
          gap={border.width.large}
          data-testid={TestIds.strategicOutlookTheme}
        >
          <PrimerOutlookSymbol outlookType={outlookType} />
          <SensitiveDataContainer isSensitive={isSensitive}>
            <Citation value={themeValue as CitableValue<string>} />
          </SensitiveDataContainer>
        </x.div>
        <x.div marginLeft="22px">
          <SensitiveDataContainer isSensitive={isSensitive}>
            <CitedMarkdown value={description as CitableValue<string>} />
          </SensitiveDataContainer>
        </x.div>
      </Typography>
    </x.div>
  );
};

export default PrimerOutlook;
