import { Typography, Link, Button } from "@alphasights/alphadesign-components";
import { useLoginStyles } from "../index.styles";
import { FormContainer } from "./FormContainer";
import { useFormStyles } from "../Form.styles";
import { x } from "@xstyled/styled-components";

export const Help = ({ onClickReturn }) => {
  const { modalTitle, modalDescription, backButton } = useLoginStyles();

  return (
    <FormContainer>
      <FormHeader
        title={
          <Typography variant="h3" {...modalTitle}>
            Help
          </Typography>
        }
        description={
          <Typography {...modalDescription}>
            {"Need help? Contact our team by sending an email to "}
            <Link
              href={`mailto:clientsupport@alphasights.com?subject=Help with AlphaSights Platform`}
              target="_blank"
              rel="noopener noreferrer"
            >
              clientsupport@alphasights.com
            </Link>
          </Typography>
        }
      />
      <FormBody>
        <Button variant="outline" onClick={onClickReturn} {...backButton}>
          <Typography variant="body-em">Back</Typography>
        </Button>
      </FormBody>
    </FormContainer>
  );
};

const FormBody = ({ ...props }) => {
  const { formBodyDiv } = useFormStyles();

  return <x.div {...formBodyDiv} {...props}></x.div>;
};

const FormHeader = ({ title, description, error }) => {
  const { formHeader } = useFormStyles();

  return (
    <x.header {...formHeader}>
      <div>{title}</div>
      <div>
        <span>{description}</span>
        {error && (
          <>
            <br />
            <Typography>{error}</Typography>
          </>
        )}
      </div>
    </x.header>
  );
};
