import { TextArea as AdsTextArea, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const Wrapper = styled(x.div)(() => {
  return `
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  `;
});

export const TextArea = styled(AdsTextArea)(() => {
  const { spacing, font } = useThemeTokens();

  return `
    width: 100%;
    font-size: ${font.size["03"]};
    padding: ${spacing.inner.base02} ${spacing.inner.base03};
    padding-right: ${spacing.inner.base12};
    line-height: ${font.lineHeight["02"]};
    min-height: ${spacing.inner.base08};
    z-index: 1;
  `;
});

export const ButtonsWrapper = styled(x.div)(({ hasPaddingBottom }: { hasPaddingBottom?: boolean }) => {
  const { spacing } = useThemeTokens();

  return `
    display: flex;
    align-items: center;
    gap: ${spacing.inner.base02};
    position: absolute;
    right: ${spacing.inner.base02};
    height: 100%;
    top: 0;
    z-index: 2;
    padding-bottom: ${hasPaddingBottom ? spacing.layout.base03 : 0};
  `;
});
