import * as React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { enrichInteraction } from "pages/InteractionPage";
import { CtaButton, FollowUpButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { x } from "@xstyled/styled-components";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";

interface SurveyCTAProps {
  interaction: Interaction;
  followUps: Interaction[];
  doubleBlinded: boolean;
}

const SurveyCTA = ({ interaction, followUps, doubleBlinded }: SurveyCTAProps) => {
  const { isMobile } = useCheckScreen();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { project, isFeatureDisabled } = useCurrentProjectContext();

  const isSendMessagesDisabled = isFeatureDisabled(ProjectFeature.SendMessages);
  const isInteractionActionsDisabled = isFeatureDisabled(ProjectFeature.InteractionActions);

  const { state, onRequestFollowUp, onStartSchedule, onLeaveAvailability, onRequest } = useProjectInteractionsContext();

  if (!interaction.surveyResponse) return null;

  if (interaction.surveyResponse.state === "available" && followUps.length === 0 && !doubleBlinded)
    return (
      <CtaButton
        data-testid="survey-cta-request"
        variant="primary"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onRequest(interaction.id, HitOrigin.workstreamView);
        }}
      >
        Request for Survey
      </CtaButton>
    );

  if (interaction.state !== "completed") return null;

  if (followUps.length === 0)
    return (
      // @ts-ignore
      <FollowUpButton
        w="100%"
        interaction={{ ...interaction, showFollowUp: true }}
        hasClientPortalMessages={hasProjectBadge(ENABLE_PORTAL_MESSAGES)}
        token={interaction.projectToken}
        onRequestFollowUp={() => {
          onRequestFollowUp({
            id: interaction.id,
            origin: HitOrigin.workstreamView,
          });
        }}
        backgroundSyncRunning={state.backgroundSyncRunning}
      />
    );

  const followUp = enrichInteraction({
    interaction: followUps[followUps.length - 1],
    isMobile,
    isActiveProject: interaction.isActiveProject,
    pendingRequest: null,
    clientPccFlag: project?.peerContentContributor,
    hasClientPortalMessages: hasProjectBadge(ENABLE_PORTAL_MESSAGES),
    isSendMessagesDisabled: isSendMessagesDisabled,
    isInteractionActionsDisabled,
  });

  if (followUp.state === "requested") {
    if (followUp.showSchedule) {
      return (
        <CtaButton
          variant="primary"
          data-testid="survey-cta-schedule"
          w="100%"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onStartSchedule(followUp);
          }}
        >
          Schedule
        </CtaButton>
      );
    }

    if (followUp.showLeaveAvailability) {
      return (
        <CtaButton
          variant="outline"
          data-testid="survey-cta-leave-availability"
          w="100%"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onLeaveAvailability(followUp);
          }}
        >
          Provide Availability
        </CtaButton>
      );
    }
  }

  if (followUp.state === "completed") {
    return (
      // @ts-ignore
      <FollowUpButton
        interaction={{
          ...followUp,
          showFollowUpMessageButton: followUp.showFollowUpMessageButton && !doubleBlinded,
        }}
        hasClientPortalMessages={hasProjectBadge(ENABLE_PORTAL_MESSAGES)}
        token={interaction.projectToken}
        onRequestFollowUp={() => {
          onRequestFollowUp({
            id: followUp.id,
            origin: HitOrigin.workstreamView,
          });
        }}
        backgroundSyncRunning={state.backgroundSyncRunning}
        w="100%"
      />
    );
  }

  return null;
};

export const CallToActionCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;
  const followUps: Interaction[] = props.row.original.followUps;
  const doubleBlinded: boolean = props.row.original.doubleBlinded;

  const styles = useStyles();

  return (
    <x.div data-testid={`survey-call-to-action-column-${interaction.id}`} {...styles.wrapper}>
      <SurveyCTA interaction={interaction} followUps={followUps} doubleBlinded={doubleBlinded} />
    </x.div>
  );
};

const useStyles = () => {
  const tokens = useThemeTokens();

  return {
    wrapper: {
      w: "100%",
      display: "flex",
      justifyContent: "center",
      mr: tokens.spacing.inner.base04,
    },
  };
};

export const CallToActionColumn = {
  id: "CallToActionColumn",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <CallToActionCell props={props} />,
  header: "",
  minSize: 170,
  style: "justify-content: center",
  meta: {
    enableColumnActions: false,
    enableDragDrop: false,
  },
};
