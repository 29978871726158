import { Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { FormattedTimeRange } from "providers/TimezoneProvider";

export const UpcomingCallWrapper = ({ children }) => {
  return children;
};

export const upcomingCallEventProps = () => {
  return {
    className: "event-grey opacity-50 hover-opacity-100",
  };
};

export const UpcomingCallEvent = ({ event: { start, end, source, raw } }) => {
  return (
    <x.div data-testid={`${source}-slot-${raw.id}`} display="flex" flexDirection="column" lineHeight="1.25">
      <Typography variant="body-small-em" component="div" style={{ whiteSpace: "nowrap" }}>
        <div>{raw.projectTitle}</div>
        {raw.advisorName}
        <br />
        {raw.advisorCompany} - {raw.role}
        <br />
      </Typography>
      <Typography variant="body-small" component="div" data-testid={`event-period-slot-${raw.id}`}>
        <FormattedTimeRange start={start} end={end} />
      </Typography>
    </x.div>
  );
};
