import { memo } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

const Title = memo(() => {
  const { spacing, color } = useThemeTokens();

  return (
    <x.div
      data-testid="title-container"
      py={spacing.inner.base04}
      px={spacing.inner.base06}
      borderBottom={`1px solid ${color.border.neutral.default}`}
    >
      <x.div display="flex" h={spacing.inner.base08} alignItems="center">
        <Typography variant="body-large-em">Customers</Typography>
      </x.div>
    </x.div>
  );
});

export default Title;
