import * as React from "react";
import { ArrowRight, Copy } from "@alphasights/alphadesign-icons";
import { FloatingAction } from "./FloatingActions";
import { useNavigate } from "router-utils";

export const useHelperActions = () => {
  const navigate = useNavigate();

  const copyRefAction = (
    elementRef: React.RefObject<HTMLElement | null>,
    tooltip: string = "Copy section",
    onCopy: () => void = () => {}
  ): FloatingAction => {
    return {
      icon: <Copy />,
      testid: "copy-ref",
      tooltip: tooltip,
      onClick: () => {
        copyElementToClipboard(elementRef).then(() => {
          onCopy && onCopy();
        });
      },
    };
  };

  const copyQuoteAction = (
    quote: string,
    author: string,
    tooltip: string = "Copy quote",
    onCopy: () => void = () => {}
  ) => {
    const text = `<i>"${quote}"</i> - ${author}`;
    return {
      icon: <Copy />,
      testid: "copy-quote",
      tooltip: tooltip,
      onClick: () => {
        copyTextToClipboard(text).then(() => {
          onCopy && onCopy();
        });
      },
    };
  };

  const copyNoFormatTextAction = (text: string, tooltip: string = "Copy bullet", onCopy: () => void = () => {}) => {
    return {
      icon: <Copy />,
      tooltip: tooltip,
      testid: "copy-plain",
      onClick: () => {
        copyTextToClipboard(text).then(() => {
          onCopy && onCopy();
        });
      },
    };
  };

  const navigateToAction = (
    url: string | null,
    tooltip: string = "View in transcript",
    onNavigate: () => void = () => {}
  ) => {
    const onClick = () => {
      url && navigate(url);
      onNavigate && onNavigate();
    };

    return {
      icon: <ArrowRight />,
      testid: "nav",
      tooltip: tooltip,
      onClick: onClick,
    };
  };

  return {
    copyRefAction,
    copyQuoteAction,
    copyNoFormatTextAction,
    navigateToAction,
  };
};

export const copyTextToClipboard = (text: string) => {
  return new Promise<void>((resolve, reject) => {
    try {
      const container = document.createElement("div");
      container.innerHTML = `<style type="text/css">* { border-style: none; }</style>${text}`;
      container.style.position = "fixed";
      container.style.pointerEvents = "none";
      container.style.opacity = "0";
      document.body.appendChild(container);
      copyToClipboard(container);
      document.body.removeChild(container);
      resolve();
    } catch (e) {
      reject();
    }
  });
};

export const copyElementToClipboard = (elementRef: React.MutableRefObject<HTMLElement | null>) => {
  return new Promise<void>((resolve, reject) => {
    try {
      if (!elementRef?.current) {
        reject();
      } else {
        copyToClipboard(elementRef.current);
        resolve();
      }
    } catch (e) {
      reject();
    }
  });
};

const copyToClipboard = (node: HTMLElement) => {
  let toCopy = node.cloneNode(true);
  let element = document.createElement("div");
  element.style.position = "fixed";
  element.style.pointerEvents = "none";
  element.style.opacity = "0";

  document.body.appendChild(element);
  element.setAttribute("contentEditable", "true");
  element.appendChild(toCopy);
  element.focus();
  document.execCommand("selectAll");
  document.execCommand("copy");
  document.body.removeChild(element);
};
