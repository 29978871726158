import { FC, useRef, useEffect, useCallback } from "react";
import ReactHtmlParser from "react-html-parser";
import { Typography } from "@alphasights/alphadesign-components";
import { ChevronRight } from "@alphasights/alphadesign-icons";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

import useKeywordSearch from "hooks/useKeywordSearch";
import { logTimeSpent, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import {
  errorLoadingComprehensiveSummaryView,
  comprehensiveSummaryViewUnavailableTryAgainContent,
  generatedByAITag,
  viewInTranscriptText,
} from "content/AlphaNow";
import { View } from "models/Header";
import { useAppSearchContext } from "providers/AppSearchProvider";
import ResearchContainer from "components/ResearchContainer";
import { HEADER } from "./constants";
import {
  ButtonContainer,
  StyledBulletPoint,
  StyledButton,
  StyledPoint,
  StyledQuote,
  SummaryContainer,
} from "./ComprehensiveSummaryView.styled";

interface ComprehensiveSummaryViewProps {
  contentId: string;
  comprehensiveSummaryFetched: boolean;
  comprehensiveSummaryViewData: any[];
  selectedIndex: any;
  setViewMode: (value: string) => void;
}

const ComprehensiveSummaryView: FC<ComprehensiveSummaryViewProps> = ({
  contentId,
  comprehensiveSummaryFetched,
  comprehensiveSummaryViewData,
  selectedIndex,
  setViewMode,
}) => {
  const { updateQuery } = useAppSearchContext();
  const { logHit } = useTrackUserAction();
  const maxScrollPercentage = useRef(0);
  const { addRefToKeyword } = useKeywordSearch(selectedIndex);

  const handleClick = useCallback(
    (fragmentId: any) => {
      updateQuery({ fragmentIds: [fragmentId] });
      setViewMode(View.Transcript);

      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowViewQuote,
        details: { contentId, fragmentPosition: fragmentId },
      });
    },
    [updateQuery, setViewMode, contentId, logHit]
  );

  useEffect(() => {
    const startTime = Date.now();
    const scrollPercentage = maxScrollPercentage.current;

    const timeSpentAction = HitAction.alphaNowTimeSpentOnComprehensiveSummary;
    const scrollAction = HitAction.alphaNowComprehensiveSummaryScrollPercentage;

    return () => {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenComprehensiveSummaryView,
        details: { contentId },
      });

      logTimeSpent(startTime, { contentId, userId: "" }, logHit, timeSpentAction);

      logScrollPercentage(
        logHit,
        {
          contentId,
          scrollPercentage,
          userId: "",
        },
        scrollAction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!comprehensiveSummaryFetched) {
    return (
      <ErrorMessage
        header={errorLoadingComprehensiveSummaryView}
        body={comprehensiveSummaryViewUnavailableTryAgainContent}
      />
    );
  }
  return (
    <ResearchContainer header={HEADER} secondaryLabel={generatedByAITag}>
      <SummaryContainer className="mentions-container">
        {comprehensiveSummaryViewData.map((data) => {
          const { theme, points, quotes } = data;
          return (
            <>
              <Typography variant="body-em">
                {theme && ReactHtmlParser(theme, { transform: addRefToKeyword })}
              </Typography>
              <StyledBulletPoint>
                {points?.map((point: string) => (
                  <li key={point.toString()}>
                    <StyledPoint variant="body">
                      {points &&
                        ReactHtmlParser(point, {
                          transform: addRefToKeyword,
                        })}
                    </StyledPoint>
                  </li>
                ))}
              </StyledBulletPoint>
              <ul>
                {quotes?.map((data: { quote: any[]; fragmentId: number }) => (
                  <li key={data.quote.toString()}>
                    <StyledQuote>
                      {quotes &&
                        ReactHtmlParser(`"${data.quote}"`, {
                          transform: addRefToKeyword,
                        })}
                    </StyledQuote>
                    <ButtonContainer>
                      <StyledButton
                        variant="link"
                        size="small"
                        endIcon={<ChevronRight />}
                        onClick={() => handleClick(data.fragmentId)}
                      >
                        {viewInTranscriptText}
                      </StyledButton>
                    </ButtonContainer>
                  </li>
                ))}
              </ul>
            </>
          );
        })}
      </SummaryContainer>
    </ResearchContainer>
  );
};

export default ComprehensiveSummaryView;
export type { ComprehensiveSummaryViewProps };
