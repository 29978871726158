import { memo } from "react";
import { x } from "@xstyled/styled-components";
import ReactHtmlParser from "react-html-parser";
import { useInView } from "react-intersection-observer";
import { Skeleton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { TransformedQuestion } from "views/SurveysResultsView/api/types";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import VisualisationTypeFactory from "./VisualisationTypeFactory";
import { useQueryClient } from "query-utils";

type QuestionProps = { question: TransformedQuestion };

const Question = memo(({ question }: QuestionProps) => {
  const { color, spacing } = useThemeTokens();
  const queryClient = useQueryClient();
  const { id, questionIndex, text, subtitle } = question;

  // Check if a specific key is in the cache
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const cachedSurveyMetadata = queryClient.getQueryData(["SURVEY_RESULTS_KEY", surveyId, [id]]);

  const isScrollOnClickedTab = useSurveysResultsStore(({ isScrollOnClickedTab }) => isScrollOnClickedTab);
  const { ref, inView } = useInView({ triggerOnce: true, skip: isScrollOnClickedTab });

  return (
    <x.div ref={ref} display="flex" flexDirection="column" data-testid="question-section">
      <x.div
        display="flex"
        flexDirection="column"
        padding={`${spacing.layout.base03} ${spacing.layout.base05}`}
        borderTop={`1px solid ${color.border.neutral.default}`}
        gap={spacing.inner.base04}
      >
        <Typography variant="body-small-em" color={color.text.secondary} data-testid={`question-subtitle-${id}`}>
          {subtitle}
        </Typography>
        <Typography variant="body-em" color={color.text.strong._} data-testid={`question-${id}`}>
          {questionIndex + 1}. {ReactHtmlParser(text)}
        </Typography>
        {(inView && !isScrollOnClickedTab) || !!cachedSurveyMetadata ? (
          <VisualisationTypeFactory questionId={id} />
        ) : (
          <Skeleton count={7} />
        )}
      </x.div>
    </x.div>
  );
});

export default Question;
