import { useEffect } from "react";
import { Sidebar } from "./Sidebar";
import { Topbar } from "./Topbar";
import { MainContent } from "./MainContent";
import * as S from "./AngleQuestionsView.styled";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

const AngleQuestionsView = ({ onSendNewMessage }: { onSendNewMessage: any }) => {
  const { getAngleQuestionThemes, setSelectedAngleId } = useAngleQuestionsContext();
  const angles = useAnglesFromFilters();
  const anglesLoading = angles.length === 0;

  useEffect(
    function getFirstAngleQuestionThemes() {
      if (!anglesLoading) {
        setSelectedAngleId(angles.at(0)!.value);
      }
    },
    [angles, anglesLoading, getAngleQuestionThemes, setSelectedAngleId]
  );

  return (
    <S.Wrapper>
      <Sidebar loading={anglesLoading} />
      <S.RightSide>
        <Topbar loading={anglesLoading} onSendNewMessage={onSendNewMessage} />
        <MainContent />
      </S.RightSide>
    </S.Wrapper>
  );
};

const useAnglesFromFilters = () => {
  const {
    state: { filterOptions },
  } = useProjectInteractionsContext();

  return (filterOptions?.groups ?? []) as FilterOption[];
};

export { AngleQuestionsView };
