import { Popover, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const StyledPopover = styled(Popover)(() => {
  const { shape } = useThemeTokens();
  return `
      box-shadow: ${shape.shadow.default};
  `;
});

const StyledList = styled.ul(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base02};
  `;
});

const StyledListItem = styled.li(({ isSelected }: { isSelected: boolean }) => {
  const { color, spacing } = useThemeTokens();
  const bgColor = isSelected ? color.background.selected.subtle : color.background.surface.page.default;
  const hoverBgColor = isSelected ? color.background.selected.subtle : color.background.surface.page.hover;
  return `
    padding: ${spacing.inner.base02};
    display: flex;
    justify-content: space-between;
    background-color: ${bgColor};

    &:hover {
      cursor: pointer;
      background-color: ${hoverBgColor};
    }
  `;
});

const StyledAngleCell = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base02};
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  `;
});
export { StyledPopover, StyledListItem, StyledAngleCell, StyledList };
