import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ResponsiveModal } from "components/ResponsiveModal";
import { useEffect, useState } from "react";
import { CurrentTimezone, useTimezone } from "../../providers/TimezoneProvider";
import { TimezoneSearchInput } from "../../components/TimezoneSearchInput";
import styled, { x } from "@xstyled/styled-components";

const OverflowYUnsetResponsiveModal = styled(ResponsiveModal)`
  div > div {
    overflow-y: unset;
  }
`;

export const TimezoneSearchInputModal = ({ onClose, onTimezoneChanged, ...props }) => {
  const tz = useTimezone();
  const {
    spacing: { inner },
  } = useThemeTokens();

  const [selectedTimezone, setSelectedTimezone] = useState(tz.currentTimezone);

  useEffect(() => {
    setSelectedTimezone(tz.currentTimezone);
  }, [tz.currentTimezone]);

  const onSelectTimezone = () => {
    onTimezoneChanged && onTimezoneChanged(selectedTimezone);
    tz.setCurrentTimezone(selectedTimezone);
    onClose();
  };

  return (
    <OverflowYUnsetResponsiveModal
      {...props}
      onClose={onClose}
      title="Select Time Zone"
      data-testid="modal-tz-selector"
      slotWidth="35vw"
      zIndex={160}
      primaryButton={
        <Button variant="secondary" onClick={onSelectTimezone}>
          <Typography variant="body-em">Confirm Time Zone</Typography>
        </Button>
      }
    >
      <x.div mt={inner.base} mb={inner.base06}>
        <Typography>Change your default time zone for the platform.</Typography>
      </x.div>
      <TimezoneSearchInput onTimezoneChange={setSelectedTimezone} timezone={selectedTimezone} />
    </OverflowYUnsetResponsiveModal>
  );
};

export const TimeSearchInputModalActivator = ({ format, onTimezoneChanged, ...props }) => {
  const [isTimezoneModalOpen, toggleTimezoneModalOpen] = useState(false);

  return (
    <>
      <x.div onClick={() => toggleTimezoneModalOpen(true)} cursor="pointer" {...props}>
        <CurrentTimezone formatOnly format={format} />
      </x.div>
      <TimezoneSearchInputModal
        open={isTimezoneModalOpen}
        onTimezoneChanged={onTimezoneChanged}
        onClose={() => toggleTimezoneModalOpen(false)}
      />
    </>
  );
};
