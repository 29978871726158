const stripMarkdown = (text: string) => {
  // If text starts with a list, remove the leading space
  if (/^\s*[-*]\s/.test(text) || /^\s*\d+\.\s/.test(text)) {
    text = text.replace(/^\s*([-*]|\d+\.)\s/, "");
  }

  return text
    .replace(/\*\*(.*?)\*\*/g, "$1")
    .replace(/__(.*?)__/g, "$1") // Bold
    .replace(/\*(.*?)\*/g, "$1")
    .replace(/_(.*?)_/g, "$1") // Italic
    .replace(/~~(.*?)~~/g, "$1") // Strikethrough
    .replace(/`(.*?)`/g, "$1") // Inline code
    .replace(/<u>(.*?)<\/u>/g, "$1") // Underline (if applicable)
    .replace(/^\s*[-*]\s/gm, " ") // Bullet points (keep one space)
    .replace(/^\s*\d+\.\s/gm, " "); // Numbered lists (keep one space)
};

export const getCharacterCount = (editorText: string) => stripMarkdown(editorText).length;
