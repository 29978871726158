import { compact } from "lodash";
import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { PLACEHOLDER } from "./constants";

const getExpertInfo = (
  interaction: ThirdPartyInteraction,
  format: (position: string, company: string) => string,
  defaultValue: string = ""
) => {
  const {
    documentType,
    attribute: { experts = [] },
  } = interaction;

  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const hasMultipleExperts = experts.length > 1;

  if (hasMultipleExperts) {
    if (isTranscript) {
      const companyInTitle =
        experts.find((expert) => !!expert.companyName)?.companyName ?? PLACEHOLDER.multiExpertCompanyName;
      return `Panel of ${experts.length} experts, from ${companyInTitle} and more`;
    } else {
      return defaultValue;
    }
  }

  const { companyName, position } = experts[0] ?? {};

  const expertCompanyNameDisplay = companyName || PLACEHOLDER.expertCompanyName;
  const expertPositionDisplay = position || PLACEHOLDER.expertPosition;

  if (isTranscript) {
    return format(expertPositionDisplay, expertCompanyNameDisplay);
  }

  return defaultValue;
};

const getTitle = (interaction: ThirdPartyInteraction) => {
  const title = interaction.attribute.title;
  return getExpertInfo(interaction, (position, company) => `${company} - ${position}`, title ?? PLACEHOLDER.title);
};

const getAuthor = (interaction: ThirdPartyInteraction) => {
  return getExpertInfo(interaction, (position, company) => `${position}, ${company}`);
};

const getSubtitle = (interaction: ThirdPartyInteraction) => {
  const {
    documentType,
    redactedFilename,
    attribute: { title, experts = [] },
    fileExtension,
  } = interaction;
  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const hasMultipleExperts = experts.length > 1;

  const showFileName = isTranscript
    ? !(hasMultipleExperts || (experts?.[0]?.companyName && experts?.[0]?.position))
    : !title;
  const angleInSubtitle = experts[0]?.angle;
  const subtitleParts = compact([
    !!angleInSubtitle && [angleInSubtitle],
    fileExtension,
    showFileName && redactedFilename,
  ]);
  return subtitleParts.join(" | ");
};

export { getTitle, getSubtitle, getAuthor };
