import { fetch } from "hooks/useApi";

export const clientControlsService = {
  async fetchClientLevers(product) {
    return fetch({
      url: `/api/client-controls/client-levers/${product}`,
      handleForbidden: true,
      skipAlert: true,
      method: "GET",
    }).then((res) => res.json());
  },
};
