import { forwardRef, useEffect, useState } from "react";
import { Button, Icon, SelectMenu, SelectOption, TextField, Typography } from "@alphasights/alphadesign-components";
import { Close, Edit } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useTimezone } from "../../providers/TimezoneProvider";
import { fetch } from "../../hooks/useApi";
import { useMyProfilePageStyles } from "./index.styles";

export const MobileTimezoneSelect = forwardRef(({ size = "small", value, onEditing, onTimezoneChanged }, ref) => {
  const tz = useTimezone();
  const { formLabel, formLabelDisabled } = useMyProfilePageStyles();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(tz.currentTimezone);
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    fetch({
      url: "/api/cities/",
      method: "GET",
      skipAlert: true,
    })
      .then((res) => res.json())
      .then((body) => {
        setTimezones(body.map(({ timeZone }) => ({ value: timeZone, content: timeZone })));
      });
  }, []);

  useEffect(() => {
    setSelectedTimezone(tz.currentTimezone);
  }, [tz.currentTimezone]);

  const changeEditing = (editing) => {
    setIsEditing(editing);
    onEditing && onEditing(editing);
  };

  const handleTimezoneChange = (timeZone) => {
    setSelectedTimezone(timeZone);
    onTimezoneChanged && onTimezoneChanged(timeZone);
    changeEditing(false);
  };

  return (
    <x.div display="flex" flexDirection="column" gap="8px">
      <Typography {...(isEditing ? formLabel : formLabelDisabled)}>Timezone</Typography>
      {!isEditing ? (
        <TextField
          ref={ref}
          value={value}
          size={size}
          disabled={true}
          endAdornment={
            <Icon
              data-testid="tz-form-field-change-icon"
              size="small"
              color="secondary"
              onClick={() => changeEditing(true)}
            >
              <Edit />
            </Icon>
          }
        />
      ) : (
        <x.div display="flex" w="100%" gap="9px">
          <SelectMenu
            dataAttributes={{ "data-testid": "tz-selector" }}
            size={size}
            value={selectedTimezone}
            isClearButtonEnabled={false}
            onChange={handleTimezoneChange}
          >
            {timezones.map((option) => (
              <SelectOption key={option.value} value={option.value}>
                {option.content}
              </SelectOption>
            ))}
          </SelectMenu>
          <Button
            data-testid="tz-form-field-close-icon"
            size="small"
            variant="icon"
            type="button"
            pr="12px"
            onClick={() => changeEditing(false)}
          >
            <Close />
          </Button>
        </x.div>
      )}
    </x.div>
  );
});
