import { FC } from "react";
import pluralize from "pluralize";
import { noop } from "lodash";

import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";
import { CheckboxFilter } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { AngleValues } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/AngleFilter/types";
import { ANGLE } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/AngleFilter/constants";
import { SPEAKER_ANGLE_TYPE_NAME } from "constants/AlphaNow";
import Filter from "components/Filter";

interface AngleFilterProps extends Omit<FilterProps, "value"> {
  value: AngleValues;
}

const AngleFilter: FC<AngleFilterProps> = ({ value, onChange, onClear = noop }) => {
  const angleOptions = Object.entries(SPEAKER_ANGLE_TYPE_NAME).map(([angleType, displayName]) => {
    const value = angleType === "Other" ? "Other" : pluralize(displayName);
    return {
      label: value,
      value,
    };
  });

  const handleClear = () => onClear(SearchFilters.angle, []);

  return (
    <Filter onClear={handleClear} title={ANGLE}>
      <CheckboxFilter filterKey={SearchFilters.angle} value={value} options={angleOptions} onChange={onChange} />
    </Filter>
  );
};

export default AngleFilter;
