import { MobileTopBar, MobileTopBarProps, useThemeTokens } from "@alphasights/alphadesign-components";
import { IconButton } from "@alphasights/alphadesign-components";
import { Menu } from "@alphasights/alphadesign-icons";
import { useMobileSidebarContext } from "providers/MobileSidebarProvider";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

type PortalMobileTopBarProps = {
  showFilter?: boolean;
  zIndex?: number;
} & MobileTopBarProps;

export const PortalMobileTopBar = ({ ...props }: PortalMobileTopBarProps) => {
  const { toggleSidebar } = useMobileSidebarContext();
  const { isMobile } = useCheckScreen();
  const {
    color: { background },
  } = useThemeTokens();

  if (!isMobile) {
    return null;
  }

  const mainButton = (
    <IconButton size="large" variant="ghost" onClick={toggleSidebar} testId="mobile-top-bar-menu-button">
      <Menu />
    </IconButton>
  );

  const localProps = { ...props, ...(props.showFilter ? { title: "Filter", mainButton: undefined } : {}) };

  return (
    <x.div bg={background.inverse} position="sticky" top="0" zIndex={props.zIndex}>
      <MobileTopBar mainButton={mainButton} {...localProps} />
    </x.div>
  );
};
