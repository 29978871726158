import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

const FooterContentWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inline-flex",
    alignItems: "center",
    gap: spacing.inner.base02,
  };
});

const FooterContainer = styled(x.div)(() => {
  const { spacing } = useThemeTokens();
  return {
    borderBottomLeftRadius: spacing.inner.base03,
    borderBottomRightRadius: spacing.inner.base03,
  };
});

export { FooterContentWrapper, FooterContainer };
