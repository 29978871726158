import { useEffect, useRef, useState } from "react";
import { fetch } from "../useApi";
import { Device } from "@twilio/voice-sdk";
import _ from "lodash";
import { toMinutesAndSeconds } from "helpers/displayHelpers";
import { Typography, Link } from "@alphasights/alphadesign-components";
import { CALL_ME_TAB } from "components/CallMePanel/constants";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { findCookieByName } from "@alphasights/ads-community-utils";

const preflightTestEnabled = false;
const callQualityThreshold = 4.1; // https://www.twilio.com/docs/voice/sdks/javascript/twiliopreflighttest#report
const codecPreferences = ["opus", "pcmu"];
const edges = ["sydney", "sao-paulo", "dublin", "frankfurt", "tokyo", "singapore", "ashburn", "umatilla", "roaming"];

const audioWarning = "Are you talking? Please ensure that your headset or computer mic is turned on.";
const networkWarning =
  "We have detected poor call quality. If you have difficulty connecting, please move closer to your wifi router and ensure you are not connected via cellular network.";

export function useTwilioClient({ mainNumber, setSelectedTabIndex }) {
  const currentUser = useCurrentUser();

  const [isMute, setIsMute] = useState(false);
  const [call, setCall] = useState(undefined);
  const [isConnected, setIsConnected] = useState(false);
  const [dialStatus, setDialStatus] = useState("none");
  const [isConnectionDegraded, setIsConnectionDegraded] = useState(false);
  const [error, setError] = useState(undefined);
  const [warning, setWarning] = useState(undefined);
  const [warningList, setWarningList] = useState([]);
  const [info, setInfo] = useState(undefined);
  const [activeAccessCode, setActiveAccessCode] = useState(undefined);
  const [elapsedTime, setElapsedTime] = useState();
  const [subjectAccessCode, setSubjectAccessCode] = useState();

  const deviceRef = useRef();
  const isMountedRef = useRef(true);

  const clearMessages = (clearError = true) => {
    setInfo(undefined);
    setWarning(undefined);
    setWarningList([]);
    if (clearError) {
      setError(undefined);
    }
  };

  const generateErrorMessage = (accessCode, disconnectedDueToSignalLost = false) => {
    const showOtherOptions = () =>
      setSelectedTabIndex &&
      mainNumber && (
        <Typography component="span">
          If you have better cell service, go to the{" "}
          <Link onClick={() => setSelectedTabIndex(CALL_ME_TAB)}>Call Me</Link> tab to be dialed into the bridge or
          dial-in yourself using this number: {mainNumber ?? ""}, code: {accessCode ?? "#"}.
        </Typography>
      );

    return (
      <Typography>
        We were unable to connect via web{disconnectedDueToSignalLost ? " due to poor signal" : ""}.{" "}
        {showOtherOptions()}
      </Typography>
    );
  };

  const accessCodeAsNumber = (accessCode) => {
    return accessCode.replaceAll(" ", "").replaceAll("#", "");
  };

  const generateTwilioCapabilityToken = async (accessCode, projectToken, device = null) => {
    try {
      return await fetch({
        url: `/api/internal/projects/${projectToken}/calls/access-code/${accessCode}/twilio-token`,
        method: "GET",
      }).then((res) => res.json());
    } catch (error) {
      console.log(`An error occurred while obtaining token: ${error}`);
      setError(generateErrorMessage(accessCode));
      setDialStatus("none");
      if (device) {
        device.destroy();
      }
    }
  };

  const registerPreflightTest = async (projectToken, interactionId, preflightResult) => {
    try {
      return await fetch({
        skipAlert: true,
        url: `/api/internal/projects/${projectToken}/calls/${interactionId}/preflight`,
        method: "POST",
        body: JSON.stringify(preflightResult),
      });
    } catch (error) {
      console.log(`Error to register preflight result: ${error}`);
    }
  };

  const disconnect = () => {
    if (call && isConnected) {
      call.disconnect();
    }

    setIsConnected(false);
    setIsConnectionDegraded(false);
    clearMessages();
    setSubjectAccessCode(null);
  };

  // destroy and clean up the device
  const destroyDevice = () => {
    if (deviceRef.current) {
      deviceRef.current.destroy();
      deviceRef.current = null;
    }
  };

  const makeOutgoingCall = async (accessCode, createdDevice) => {
    createdDevice
      .connect({
        params: {
          AccessCode: accessCodeAsNumber(accessCode),
          IdentificationCode: currentUser
            ? currentUser.name
            : findCookieByName("limitations_of_use")?.substring(0, 128),
        },
      })
      .then((createdCall) => {
        try {
          createdCall.on("disconnect", (call) => {
            destroyDevice();
          });
          createdCall.on("cancel", (call) => {
            destroyDevice();
          });

          createdCall.on("accept", (call) => {
            setCall(call);
            setActiveAccessCode(accessCode);
            setIsConnected(true);
            setDialStatus("none");
            setIsMute(false);
          });

          createdCall.on("reconnecting", (twilioError) => {
            console.log("Recconecting due to: ", twilioError);
            setDialStatus("reconnecting");
          });
          createdCall.on("reconnected", () => {
            console.log("The call was reconnected");
            setDialStatus("connected");
          });

          createdCall.on("error", (error) => {
            console.log(`An error occurred on call: ${error.message}`);
            if (error.code === 31401) {
              setError("Microphone permissions not enabled, please check your settings or try a different browser.");
              call.disconnect();
            } else if (error.code === 31402) {
              setError("Could not acquire microphone device, please check your settings or try a different browser.");
              call.disconnect();
            } else {
              setError(generateErrorMessage(accessCode, true));
            }
          });

          createdCall.on("warning", function (warningName, warningData) {
            console.log(`Received a warning from call: ${warningName}: ${warningData}`);
            setWarningList((prevList) => [...new Set(prevList.concat(warningName))]);
          });
          createdCall.on("warning-cleared", function (warningName) {
            console.log(`Warning cleared: ${warningName}`);
            setWarningList((prevList) => prevList.filter((warning) => warning !== warningName));
          });
        } catch (error) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    const { networkWarnings, audioWarnings } = warningList.reduce(
      (acc, warning) => {
        if (warning.includes("constant-audio-input-level")) {
          acc.audioWarnings.push(warning);
        } else if (!warning.includes("constant-audio-output-level")) {
          acc.networkWarnings.push(warning);
        }
        return acc;
      },
      { networkWarnings: [], audioWarnings: [] }
    );

    setWarning(networkWarnings.length === 0 ? undefined : networkWarning);
    setInfo(audioWarnings.length === 0 ? undefined : audioWarning);
  }, [warningList, info]);

  const preflightTest = (token, edge) => {
    const timeoutPromise = new Promise((resolve) =>
      setTimeout(() => resolve({ edge: edge, selectedEdge: edge, mos: -3 }), 30000)
    );

    const preflightPromise = new Promise((resolve) => {
      const preflight = Device.runPreflight(token, {
        codecPreferences: codecPreferences,
        fakeMicInput: true,
        edge: edge,
      });

      preflight.on("completed", (report) => {
        resolve({
          edge: _.get(report, "edge", edge),
          selectedEdge: edge,
          mos: _.get(report, "stats.mos.average", -1),
        });
      });

      preflight.on("failed", (error) => {
        resolve({ edge: edge, selectedEdge: edge, mos: -2 });
      });
    });

    return Promise.race([timeoutPromise, preflightPromise]);
  };

  const dial = (accessCode, projectToken, token, edge) => {
    // avoid making a call when the component is no longer mounted
    if (isMountedRef.current) {
      setDialStatus("connecting");

      const createdDevice = new Device(token, {
        codecPreferences: codecPreferences,
        edge: edge,
        maxCallSignalingTimeoutMs: 10000,
        enableImprovedSignalingErrorPrecision: true,
      });

      destroyDevice();
      deviceRef.current = createdDevice;

      setupDeviceListeners(createdDevice, accessCode, projectToken);
      createdDevice.register();

      makeOutgoingCall(accessCode, createdDevice);
    }
  };

  const setupDeviceListeners = (createdDevice, accessCode, projectToken) => {
    createdDevice.on("registered", () => {
      console.log("Device Ready to make and receive calls");
    });

    createdDevice.on("error", (twilioError, call) => {
      console.log(`An error occurred on device: ${twilioError.message}`);
      setError(generateErrorMessage(accessCode, true));
    });

    createdDevice.on("unregistered", () => {
      console.log("Device ungeristered");
      createdDevice.destroy();
    });

    createdDevice.on("destroyed", () => {
      setIsConnected(false);
      setDialStatus("none");
      setIsConnectionDegraded(false);
      setCall(undefined);
      setActiveAccessCode(undefined);
      clearMessages(false);
    });

    createdDevice.on("tokenWillExpire", () => {
      console.log("Refreshing token");
      return generateTwilioCapabilityToken(
        accessCodeAsNumber(accessCode),
        projectToken,
        createdDevice
      ).then((tokenJson) => createdDevice.updateToken(tokenJson.token));
    });
  };

  // the generation of the token and creation of the device only at the moment
  // of connection request, where a new token is always generated at each connection
  const connect = (accessCode, projectToken, interactionId) => {
    if (isConnected) return;

    clearMessages();
    setSubjectAccessCode(accessCode);

    setDialStatus("connecting");
    generateTwilioCapabilityToken(accessCodeAsNumber(accessCode), projectToken).then((tokenJson) => {
      if (!tokenJson || !tokenJson.token) {
        setError(generateErrorMessage(accessCode));
        return;
      }

      if (preflightTestEnabled) {
        setDialStatus("testing");

        Promise.all(edges.map((edge) => preflightTest(tokenJson.token, edge))).then((result) => {
          result.sort((a, b) => b.mos - a.mos);
          console.log(result);

          let edge = result[0];
          const defaultEdge = result.find((edge) => edge.selectedEdge === "roaming");

          if (edge.mos <= 0) {
            edge = defaultEdge;
          }

          setIsConnectionDegraded(edge.mos < callQualityThreshold);
          console.log(`Selected edge: ${edge.edge}`);

          registerPreflightTest(projectToken, interactionId, {
            browserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            edge: edge.edge,
            qualityScore: edge.mos,
            defaultEdge: defaultEdge.edge,
            defaultEdgeQualityScore: defaultEdge.mos,
          });

          dial(accessCode, projectToken, tokenJson.token, edge.edge);
        });
      } else {
        dial(accessCode, projectToken, tokenJson.token, "roaming");
      }
    });
  };

  useEffect(() => {
    if (call) {
      call.mute(isMute);
    }
  }, [isMute, call]);

  // Use effect with empty dependency array will run when component is unmounted.
  // To ensure that on unmount the call will be destroyed / not started
  useEffect(() => {
    return () => {
      destroyDevice();
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isConnected) {
      setElapsedTime(null);
      return;
    }

    let seconds = 0;

    const interval = setInterval(() => {
      seconds += 1;

      setElapsedTime(toMinutesAndSeconds(seconds));
    }, 1000);

    return () => clearInterval(interval);
  }, [isConnected]);

  return {
    isMute,
    setIsMute,
    isConnected,
    dialStatus,
    isConnectionDegraded,
    disconnect,
    connect,
    error,
    warning,
    info,
    activeAccessCode,
    elapsedTime,
    subjectAccessCode,
  };
}

export default useTwilioClient;
