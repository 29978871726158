import { useCallback, useMemo } from "react";
import * as React from "react";
import { HideAlt, ShowAlt } from "@alphasights/alphadesign-icons";
import { useHideExpertContext } from "providers/HideExpertProvider";
import { useLockedExpert } from "hooks/useLockedExpert";
import { HitOrigin } from "@alphasights/portal-api-client";
import { Loading, toastSuccess } from "@alphasights/alphadesign-components";

export const useHideExpert = ({ interaction, origin }: { interaction: Interaction; origin: HitOrigin }) => {
  const { locked } = useLockedExpert(interaction);
  const { setHideRef, onToggleHiddenWithoutMessage, isLoading: isLoadingInteraction } = useHideExpertContext();

  const isLoading = useMemo(() => (isLoadingInteraction ? isLoadingInteraction(interaction.id) : false), [
    interaction.id,
    isLoadingInteraction,
  ]);

  const handleToggleHidden = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      return locked
        ? Promise.resolve(false)
        : onToggleHiddenWithoutMessage(interaction, origin).then(() => {
            !interaction.hidden && toastSuccess({ message: "Expert hidden." });
            return true;
          });
    },
    [interaction, locked, onToggleHiddenWithoutMessage, origin]
  );

  const hideIcon = useMemo(() => {
    if (isLoading) {
      return (
        <div>
          <Loading size="xs" display="flex" alignItems="center" />
        </div>
      );
    }
    return interaction.hidden ? <ShowAlt data-testid="show-button" /> : <HideAlt data-testid="hide-button" />;
  }, [interaction.hidden, isLoading]);

  const hideTooltip = useMemo(() => (interaction.hidden ? "Show expert" : "Hide expert"), [interaction.hidden]);

  return { hideIcon, hideTooltip, handleToggleHidden, locked, setHideRef };
};
