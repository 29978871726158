const readyWithinMap = new Map<string, Map<string, number>>([
  [
    "regular",
    new Map<string, number>([
      ["en", 10],
      ["zh", 8],
      ["es", 24],
      ["de", 24],
      ["fr", 24],
      ["jp", 24],
      ["ko", 24],
      ["nl", 48],
      ["it", 48],
      ["pt", 48],
      ["sv", 48],
      ["ru", 48],
      ["zh-HK", 48],
      ["id", 48],
      ["vi", 48],
    ]),
  ],
]);

export const getTranscriptReadyWithinHours = (transcriptType: TranscriptType, language: string) => {
  return readyWithinMap.get(transcriptType) ? readyWithinMap.get(transcriptType)!.get(language) : null;
};

export const getTranscriptReadyWithinText = (transcriptType: TranscriptType, language: string) => {
  return getTranscriptReadyWithinHours(transcriptType, language)
    ? `Ready within ${getTranscriptReadyWithinHours(transcriptType, language)} hours`
    : "";
};
