import { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { x } from "@xstyled/styled-components";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import Chart from "react-apexcharts";
import { mean } from "lodash";
import { ApexOptions } from "apexcharts";
import { ChartTooltip } from "pages/AlphaNowPage/primers/components";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";
import { XAxis, YAxis } from "./chart/axis";

type AllocationChartProps = {
  ranks: {
    companyName: string;
    values: CitableValue<number>[];
  }[];
};

const legendsNames = [
  "Significantly lose spend",
  "Somewhat lose spend",
  "Neither win nor lose spend",
  "Somewhat gain spend",
  "Significantly gain spend",
];

const AllocationChart = ({ ranks }: AllocationChartProps) => {
  const sectionName = "ALLOCATION_CHART";
  const [selectedSerie, setSelectedSerie] = useState<number | null>(null);
  const [chartKey, setChartKey] = useState<number>(0);
  const { color, font, spacing } = useThemeTokens();
  const expertsMentionedSection = useMentionedExpertsStore(({ expertsMentionedSection }) => expertsMentionedSection);
  const isSidebarExpanded = useCustomerPrimersStore(({ isSidebarExpanded }) => isSidebarExpanded);
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );

  const allocationChartColors = {
    negative: color.base.red[500],
    neutral: color.chart.categorical.base07,
    positive: color.chart.categorical.base03,
  };

  const hexToRgba = (hex: string, alphaPercentage: number): string => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const clearHex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(clearHex);

    return result
      ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${
          alphaPercentage / 100
        })`
      : "";
  };

  useEffect(() => {
    // we need this because, due to component memoization, the chart was
    // not resizing when the search bar collapsed at first load.
    //
    // to solve this we get the expanded status of the search bar and
    // when it changes we enforce a new key to the chart. We use a setTimeout
    // to defer this change to when the animation finishes + half a second,
    // to ensure the size is correctly calculated
    let timer = setTimeout(
      () => setChartKey((currentState) => currentState + 1),
      parseFloat(SIDEBAR_ANIMATION_SPEED.substring(0, SIDEBAR_ANIMATION_SPEED.length - 1)) * 1000 + 500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [isSidebarExpanded]);

  useEffect(() => {
    if (expertsMentionedSection !== sectionName && selectedSerie !== null) {
      setSelectedSerie(null);
    }
  }, [expertsMentionedSection, selectedSerie]);

  const getEntryColor = (index: number, value: number): string => {
    const { negative, neutral, positive } = allocationChartColors;
    const isFaded = !(selectedSerie === null || selectedSerie === undefined || index === selectedSerie);

    let color = positive;

    if (value < 0) {
      color = negative;
    } else if (value === 1) {
      color = neutral;
    }

    return isFaded ? hexToRgba(color, 20) : color;
  };

  const getSeries = () => {
    return ranks.map(({ companyName, values }, index) => {
      const valueToCompare = values && values.length > 0 ? mean(values.map(({ value }) => value!)) : 0;

      return {
        x: companyName,
        y: valueToCompare === 0 ? [0, 1] : [0, valueToCompare],
        fillColor: getEntryColor(index, valueToCompare),
      };
    });
  };

  const getSeriesTooltips = (seriesIndex: number): string[] => {
    const { values } = ranks[seriesIndex];

    const valuesToCompare = values && values.length > 0 ? values.map(({ value }) => value!) : [];
    const finalValues = [0, 0, 0, 0, 0];
    const tooltipText: string[] = [];

    valuesToCompare.forEach((value) => {
      if (value < -50) finalValues[0] += 1;
      if (value >= -50 && value < 0) finalValues[1] += 1;
      if (value >= 0 && value < 50) finalValues[2] += 1;
      if (value >= 50 && value < 100) finalValues[3] += 1;
      if (value >= 100) finalValues[4] += 1;
    });

    finalValues.forEach((value, index) => {
      if (value > 0) {
        tooltipText.push(`${legendsNames[index]} - ${value} expert${value === 1 ? "" : "s"}`);
      }
    });

    return tooltipText;
  };

  const series = [
    {
      data: getSeries(),
    },
  ];
  const options = {
    chart: {
      height: 390,
      type: "rangeBar",
      fontFamily: font.family.text.regular,
      zoom: {
        enabled: false,
      },
      toolbar: { show: false },
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          const newSelectedSerie = config.dataPointIndex;

          if (newSelectedSerie === selectedSerie) {
            resetExpertsMentioned();
            setSelectedSerie(null);
          } else {
            const speakersIds = ranks[config.dataPointIndex].values.map(({ citedBy }) => citedBy).flat();

            setExpertsMentionedSectionAndExpertsIds(sectionName, speakersIds);
            setSelectedSerie(newSelectedSerie);
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "24px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      custom: function ({
        series,
        seriesIndex,
        dataPointIndex,
      }: {
        series: any;
        seriesIndex: number;
        dataPointIndex: number;
      }) {
        return ReactDOMServer.renderToString(
          <ChartTooltip>
            <ul>
              {getSeriesTooltips(dataPointIndex).map((value, index) => (
                <li key={`${index}_${value}`}>{value}</li>
              ))}
            </ul>
          </ChartTooltip>
        );
      },
    },
    xaxis: {
      tickAmount: 4,
      min: -100,
      max: 100,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      show: true,
    },
    // this avoids the color to be darker when clicking on an entry
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
  };

  return (
    <>
      <x.div id="chart">
        <x.div display="flex">
          <YAxis vendors={getSeries().map(({ x }) => x)} />
          <x.div flex="1 1 auto" marginRight="40px">
            <Chart
              key={chartKey}
              options={(options as unknown) as ApexOptions}
              series={series}
              type="bar"
              height={50 * getSeries().length + 35}
            />
          </x.div>
        </x.div>
        <XAxis legendsNames={legendsNames} />
      </x.div>
      <Divider m={`${spacing.layout.base05} 0`} />
    </>
  );
};

export default AllocationChart;
