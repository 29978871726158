import { forwardRef, ReactNode, ForwardedRef } from "react";
import { RoundedButton, useThemeTokens, RoundedButtonProps, Typography } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

type FilterButtonProps = {
  children: ReactNode;

  selected?: boolean;
} & RoundedButtonProps;

const FilterButton = forwardRef(
  ({ children, onClick, selected = false, ...props }: FilterButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { color, spacing } = useThemeTokens();
    const { isMobile } = useCheckScreen();
    return (
      <RoundedButton
        // TODO (Fran): temp fix - look into why this is not being rerendered on props change unless key is specified
        key={isMobile ? selected.toString() : ""}
        ref={ref}
        onClick={onClick}
        backgroundColor={color.background.action.outline[selected ? "pressed" : "default"]}
        {...(isMobile && {
          px: spacing.inner.base04,
          py: spacing.inner.base03,
        })}
        {...props}
      >
        <Typography component="span" color={color.text.secondary}>
          {children}
        </Typography>
      </RoundedButton>
    );
  }
);

export default FilterButton;
