import { useEffect, useState } from "react";
import { IconButton } from "@alphasights/alphadesign-components";
import { ThumbDown, ThumbDownFilled, ThumbUp, ThumbUpFilled } from "@alphasights/alphadesign-icons";
import { useDeliverableContext } from "providers/DeliverableProvider";

const FeedbackButtons = ({
  interactionId,
  recordingId,
  transcriptId,
  summaryType,
  feedbackType,
  onFeedbackUpdated,
}) => {
  const { submitAiSummaryFeedback, removeAiSummaryFeedback } = useDeliverableContext();

  const onSubmitFeedback = (feedbackType) => {
    submitAiSummaryFeedback(interactionId, recordingId, transcriptId, summaryType, feedbackType).then(() =>
      onFeedbackUpdated(feedbackType)
    );
  };

  const onRemoveFeedback = () => {
    removeAiSummaryFeedback(interactionId, recordingId, transcriptId, summaryType).then(() => onFeedbackUpdated(""));
  };

  const [thumbUpSelected, setThumbUpSelected] = useState(feedbackType === "positive");
  const [thumbDownSelected, setThumbDownSelected] = useState(feedbackType === "negative");

  useEffect(() => {
    setThumbUpSelected(feedbackType === "positive");
    setThumbDownSelected(feedbackType === "negative");
  }, [feedbackType]);

  const feedbackCallback = async ({ feedbackType, isSelected }) => {
    if (feedbackType === "positive") {
      setThumbUpSelected(!isSelected);
      if (thumbDownSelected) {
        setThumbDownSelected(false);
      }
    } else if (feedbackType === "negative") {
      setThumbDownSelected(!isSelected);
      if (thumbUpSelected) {
        setThumbUpSelected(false);
      }
    }

    if (isSelected) {
      onRemoveFeedback();
    } else {
      onSubmitFeedback(feedbackType);
    }
  };

  return (
    <>
      <FeedbackButton
        feedbackType="positive"
        isSelected={thumbUpSelected}
        feedbackCallback={feedbackCallback}
        testId="feedback-positive-button"
      />
      <FeedbackButton
        feedbackType="negative"
        isSelected={thumbDownSelected}
        feedbackCallback={feedbackCallback}
        testId="feedback-negative-button"
      />
    </>
  );
};

const FeedbackButton = ({ feedbackType, isSelected, feedbackCallback, testId }) => {
  const handleClick = () => {
    feedbackCallback({ feedbackType, isSelected });
  };

  const renderIcon = () => {
    if (feedbackType === "positive") {
      return isSelected ? <ThumbUpFilled /> : <ThumbUp />;
    } else if (feedbackType === "negative") {
      return isSelected ? <ThumbDownFilled /> : <ThumbDown />;
    }
  };

  return (
    <IconButton
      variant="outline"
      size="small"
      onClick={() => handleClick()}
      isSelected={isSelected}
      testId={testId}
      isSelectable
    >
      {renderIcon()}
    </IconButton>
  );
};

export default FeedbackButtons;
