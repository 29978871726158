import { useRef, useState } from "react";
import * as React from "react";
import {
  IconButton,
  IconButtonSizes,
  IconButtonVariant,
  Popover,
  Typography,
} from "@alphasights/alphadesign-components";
import { Copy } from "@alphasights/alphadesign-icons";
import ExpiringComponent from "components/ExpiringComponent";
import styled from "styled-components";
import { copyElementToClipboard } from "components/FloatingActions";
import { th } from "@xstyled/styled-components";

export const CopyButton = ({
  messageRef,
  onClick,
  testId,
  size,
  variant,
}: {
  messageRef: React.MutableRefObject<HTMLElement | null>;
  onClick?: () => void;
  testId?: string;
  size?: IconButtonSizes;
  variant?: IconButtonVariant;
}) => {
  const [anchorEl, setAnchorEl] = useState<Element>();
  const ref = useRef(null);

  const handleClick = ({ currentTarget }: { currentTarget: Element }) =>
    copyElementToClipboard(messageRef)?.then(() => {
      setAnchorEl(currentTarget);
      onClick && onClick();
    });

  const handleClose = () => setAnchorEl(undefined);
  const open = Boolean(anchorEl);

  const dataTestId = testId ? testId : "copy-button";

  return (
    <>
      <IconButtonRegularPosition
        variant={variant ? variant : "outline"}
        size={size ? size : "small"}
        onClick={handleClick}
        testId={dataTestId}
      >
        <Copy />
      </IconButtonRegularPosition>
      {open && (
        <ExpiringComponent delay={3000} onExpire={handleClose}>
          <CopiedPopover ref={ref} anchorEl={anchorEl} open={open} onClose={handleClose} placement="right">
            <Typography variant="body-small">Copied</Typography>
          </CopiedPopover>
        </ExpiringComponent>
      )}
    </>
  );
};

export const IconButtonRegularPosition = styled(IconButton)`
  position: static;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: static;
    top: initial;
    left: initial;
    transform: initial;
  }
`;

export const CopiedPopover = styled(Popover)`
  background-color: ${th.color("background-dark")};
  color: ${th.color("text-inverse")};
  padding: ${th.space("inner-base")};
  border: none;
`;
