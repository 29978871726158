import { Popover, Typography, useThemeTokens, TextField, DatePicker, Icon } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

const FormContainer = styled(x.div)(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base04};
    padding: ${spacing.inner.base06} ${spacing.inner.base};
  `;
});

const FormRow = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base04};
    width: -webkit-fill-available;
  `;
});

const FormItem = styled.div(({ w = "inherit" }: { w?: string }) => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: ${spacing.inner.base02};
    width: ${w};
  `;
});

const Label = styled(Typography).attrs({ variant: "body-small", component: "label" })(
  ({ disabled = false }: { disabled?: boolean }) => {
    const { color } = useThemeTokens();
    return `
      color: ${disabled ? color.text.disabled : color.text.secondary};
    `;
  }
);

const StyledTextField = styled(TextField).attrs({ size: "small" })(() => {
  const { color } = useThemeTokens();
  return `
    color: ${color.text.strong._};
  `;
});

const DateField = styled(StyledTextField)(() => {
  const { color } = useThemeTokens();
  return `
    cursor: pointer !important;
    &:hover {
      background-color: ${color.background.surface.page.default};
    }
    input {
      cursor: pointer;
    }
  `;
});

const DatePopover = styled(Popover)(() => {
  const { shape, spacing } = useThemeTokens();
  return `
    box-shadow: ${shape.shadow.default};
    padding: ${spacing.inner.base05} 10px;
  `;
});

const StyledDatePicker = styled(DatePicker).attrs({ size: "small" })(() => {
  const { color } = useThemeTokens();
  return `
    background-color: ${color.background.surface.page.default};
  `;
});

const StyledCalendarIcon = styled(Icon).attrs({ size: "small" })(() => {
  const { color } = useThemeTokens();
  return `
    color: ${color.icon.secondary};
    &:hover {
      background-color: ${color.background.surface.page.default} !important;
    }
  `;
});

export {
  FormContainer,
  FormRow,
  FormItem,
  Label,
  StyledTextField,
  DateField,
  DatePopover,
  StyledDatePicker,
  StyledCalendarIcon,
};
