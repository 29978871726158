import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { Popover as AdsPopover } from "@alphasights/alphadesign-components";

const {
  spacing: { inner },
  color,
  shape,
} = tokens;

export const Container = styled.div`
  display: flex;
  gap: ${inner.base02};
`;

export const ViewButton = styled.button(({ selected }: { selected?: boolean }) => {
  return `
    width: ${inner.base06};
    height: ${inner.base06};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${selected ? color.background.inverse : "transparent"};
    border-radius: ${shape.border.radius.xsmall};
    cursor: pointer;
    outline: none !important;
    position: relative;
    &:hover {
      background: ${selected ? color.background.inverse : color.background.neutral.hover};
    }
  `;
});

export const ViewsContainer = styled.div`
  display: flex;
  padding: ${inner.base};
  gap: ${inner.base};
  background: ${color.background.neutral.default};
  outline: ${shape.border.width.small} solid ${color.border.neutral.default};
  border-radius: ${shape.border.radius.medium};
`;

export const NewDot = styled.div`
  position: absolute;
  top: ${inner.base};
  right: ${inner.base};
  width: ${inner.base};
  height: ${inner.base};
  background: ${color.icon.danger};
  outline: ${shape.border.width.small} solid ${color.background.neutral.default};
  border-radius: ${shape.border.radius.xlarge};
  margin-top: ${shape.border.width.small};
`;

export const ComparisonViewPreviewHeader = styled.div`
  display: flex;
  gap: ${inner.base02};
  align-items: center;
`;

export const ComparisonViewPreviewWrapper = styled.div`
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: ${inner.base03};
  padding: ${inner.base04};
  img {
    border: ${shape.border.width.small} solid ${color.border.neutral.default};
    border-radius: ${shape.border.radius.small};
  }
`;

export const NotEnoughExperts = styled.div`
  background: ${color.background.dark};
  padding: 0 ${inner.base};
`;

export const Popover = styled(AdsPopover)(({ dark }: { dark?: boolean }) => {
  return `
    background: ${dark ? color.background.dark : color.background.inverse};
  `;
});
