import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = ({
  isEditing = false,
}: {
  isEditing?: boolean;
} = {}) => {
  const { color, spacing, shape } = useThemeTokens();
  return {
    moduleWrapper: {
      overflow: "auto",
      flex: "1",
      bg: color.background.surface.raised,
      p: spacing.inner.base06,
    },
    overviewWrapper: {
      py: spacing.inner.base06,
      ...(!isEditing && { px: spacing.inner.base06 }),
    },
    overviewTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: spacing.inner.base02,
      mb: spacing.inner.base03,
    },
    overviewContent: {
      display: "flex",
      gap: spacing.inner.base04,
      flexDirection: "column",
    },
    overviewItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    answersWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
    },
    borderedBox: {
      p: spacing.inner.base06,
      borderRadius: shape.border.radius.medium,
      border: `1px solid ${color.border.divider}`,
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
    },
    answerTitleWrapper: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base02,
      justifyContent: "space-between",
    },
    answerTitle: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base02,
    },
    companyLogoWrapper: {
      border: `1px solid ${color.border.divider}`,
      borderRadius: shape.border.radius.small,
      h: spacing.inner.base05,
      minW: spacing.inner.base05,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    quoteWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    editHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      h: spacing.inner.base04,
    },
    emptyAnswersWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: spacing.inner.base04,
      border: `1px dashed ${color.border.divider}`,
      h: "448px",
      borderRadius: shape.border.radius.small,
    },
    emptyIconWrapper: {
      background: color.background.neutral.default,
      borderRadius: shape.border.radius.small,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      h: spacing.inner.base08,
      w: spacing.inner.base08,
    },
    kpcContentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base03,
      mt: spacing.inner.base06,
    },
    kpcRevisionTogggler: {
      display: "flex",
      justifyContent: "flex-end",
      position: "absolute",
      right: spacing.inner.base08,
      zIndex: "1",
    },
  };
};
