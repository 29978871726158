import { x } from "@xstyled/styled-components";
import { useNavigate } from "router-utils";
import { TickCircleFilled } from "@alphasights/alphadesign-icons";
import { useContentFlyoutStyles } from "../ContentFlyout/ContentFlyout.styles";
import { Button, Icon, Typography } from "@alphasights/alphadesign-components";
import { ContentPurchaseStatus } from "@alphasights/client-portal-shared";

const statusMap = {
  [ContentPurchaseStatus.AllAvailable]: "Available",
  [ContentPurchaseStatus.Available]: "Available",
  [ContentPurchaseStatus.FreePilotAccess]: "Available",
  [ContentPurchaseStatus.Purchased]: "Purchased",
} as { [key in ContentPurchaseStatus]: ContentPurchaseStatus };

export const ContentFlyoutFooter = ({ content, fragmentIds }: { content: ContentResults; fragmentIds?: string[] }) => {
  const navigate = useNavigate();

  const onClick = () => {
    let url = `deliverables-view?selectedContentId=${content.id}`;
    if (fragmentIds) {
      url += `&fragmentIds=${fragmentIds.join(",")}`;
    }
    navigate(url);
  };

  return <FlyoutFooter onClick={onClick} status={statusMap[content.purchaseStatus!]} />;
};

export const FlyoutFooter = ({ onClick, status }: { onClick: () => void; status: string }) => {
  const { footerContainer, footerContentStatus } = useContentFlyoutStyles();

  return (
    <x.div {...footerContainer}>
      <x.div {...footerContentStatus}>
        <Icon color="success">
          <TickCircleFilled />
        </Icon>
        <Typography variant="body-small-em">{status}</Typography>
      </x.div>
      <Button size="small" onClick={onClick}>
        View in Deliverables
      </Button>
    </x.div>
  );
};
