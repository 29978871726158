import { useEffect, useRef } from "react";
import { Icon, Typography, TypographyVariation, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import { STYLE_CONFIG, SearchSizeVariant } from "components/Search/consts";
import { OptionalOptionProps, SearchOption } from "components/Search/types";
import { ElementUtil } from "utils/element";
import {
  ComplexOptionContainer,
  EndIconContainer,
  LabelContainer,
  LeftHandContainer,
  OptionContainer,
} from "./ComplexOption.styled";
import { isBooleanSearchTerm } from "components/Search/utils";

const DataTestIds = {
  SearchOption: "complex-search-option",
  StartIcon: "complex-option-start-icon",
  EndIcon: "complex-option-end-icon",
  Avatar: "complex-option-avatar",
};

export type ComplexOptionProps = {
  data: SearchOption[];
  onSelect: () => void;
  onHighlight: () => void;
  highlight: boolean;

  size?: SearchSizeVariant;
} & OptionalOptionProps;

const ComplexOption = ({
  data,
  onSelect,
  onHighlight,
  size = SearchSizeVariant.Medium,
  highlight = false,
}: ComplexOptionProps) => {
  const { color } = useThemeTokens();

  const ref = useRef<HTMLDivElement>(null);
  const { current: styles } = useRef(STYLE_CONFIG[size]);
  const {
    height: minHeight,
    typographyVariant,
    icon: { size: iconSize },
    avatar: { size: avatarSize },
    searchOption: { xStyledProps: searchOptionStyles },
  } = styles;

  useEffect(() => {
    if (ref?.current && highlight && ElementUtil.isElementXPercentInViewport(ref.current, 100)) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [highlight]);

  return (
    <ComplexOptionContainer
      data-testid={DataTestIds.SearchOption}
      ref={ref}
      minH={minHeight}
      pl={searchOptionStyles.pl}
      highlight={highlight}
      onClick={onSelect}
      onMouseEnter={onHighlight}
    >
      <OptionContainer>
        {data.map(({ label, secondaryLabel, StartIcon, EndIcon, avatar, type }: SearchOption) => {
          const displaySecondaryLabel = data.length === 1 && secondaryLabel;
          const labelVariant = (isBooleanSearchTerm(type as string)
            ? typographyVariant
            : `${typographyVariant}-em`) as TypographyVariation;
          return (
            <>
              <LeftHandContainer>
                {StartIcon && (
                  <Icon color={color.icon.secondary} data-testid={DataTestIds.StartIcon} size={iconSize}>
                    <StartIcon />
                  </Icon>
                )}
                {avatar?.src && (
                  <x.img
                    w={avatarSize}
                    h={avatarSize}
                    src={avatar.src}
                    objectFit="contain"
                    data-testId={DataTestIds.Avatar}
                  />
                )}
                <LabelContainer>
                  <Typography component="span" variant={labelVariant}>
                    {label}
                  </Typography>
                  {displaySecondaryLabel && (
                    <Typography
                      component="span"
                      variant={typographyVariant as TypographyVariation}
                      color={color.text.secondary}
                    >
                      {secondaryLabel}
                    </Typography>
                  )}
                </LabelContainer>
              </LeftHandContainer>
              {EndIcon && (
                <EndIconContainer data-testid={DataTestIds.EndIcon}>
                  <Icon color={color.icon.secondary}>
                    <EndIcon />
                  </Icon>
                </EndIconContainer>
              )}
            </>
          );
        })}
      </OptionContainer>
    </ComplexOptionContainer>
  );
};

export { ComplexOption as default, DataTestIds };
