import { useCallback, useEffect, useState } from "react";
import styled from "@xstyled/styled-components";
import { ToggleButton, ToggleButtonGroup, useThemeTokens } from "@alphasights/alphadesign-components";

import { ViewType } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";
import { TranscriptSearchBar } from "views/DeliverablesView/TranscriptSearchBar";
import { selectIndexHighlight } from "views/DeliverablesView/helpers";
import { highlightWordsInElement } from "utils/frontendElementHighlight";
import { useThirdPartyDocumentsContext } from "providers/ThirdPartyDocumentsProvider";
import { QUESTIONS_VIEW_TITLE, SUMMARY_VIEW_TITLE, TRANSCRIPT_VIEW_TITLE } from "./consts";

const DataTestIds = {
  summaryToggleButton: "summary-view-button",
  questionsToggleButton: "questions-view-button",
  transcriptToggleButton: "transcript-view-button",
  tabContent: "tab-content-wrapper",
};

const tabs = [
  { label: SUMMARY_VIEW_TITLE, value: ViewType.Summary, testId: DataTestIds.summaryToggleButton },
  { label: QUESTIONS_VIEW_TITLE, value: ViewType.Questions, testId: DataTestIds.questionsToggleButton },
  {
    label: TRANSCRIPT_VIEW_TITLE,
    value: ViewType.Transcript,
    testId: DataTestIds.transcriptToggleButton,
  },
];

const DocumentActionsPanel = ({
  searchTargetEl,
  selectedView,
  onChangeView,
  searchQuery,
}: {
  selectedView: ViewType;
  onChangeView: (view: ViewType) => void;
  searchTargetEl: HTMLDivElement | null;
  searchQuery: string[];
}) => {
  const [searchCriteria, setSearchCriteria] = useState<string[]>(searchQuery);
  const [totalMentions, setTotalMentions] = useState<number | undefined>();

  const { isViewLoading, setIsViewLoading, isTranscriptSearchEnabled } = useThirdPartyDocumentsContext();

  const isSearchDisabled = selectedView === ViewType.Transcript && !isTranscriptSearchEnabled;

  const onSearchCallback = useCallback(
    (keywords: string[]) => {
      setSearchCriteria(keywords);
    },
    [setSearchCriteria]
  );

  useEffect(() => {
    setSearchCriteria(searchQuery);
  }, [searchQuery]);

  useEffect(
    function highlightKeywordsFe() {
      if (searchTargetEl) {
        const matchCount = highlightWordsInElement(searchTargetEl, searchCriteria);
        setTotalMentions(searchCriteria.length ? matchCount : undefined);
        selectIndexHighlight(0, matchCount, { noScroll: true });
      } else {
        setTotalMentions(undefined);
      }
    },
    [searchCriteria, searchTargetEl, selectedView]
  );

  const onClickTab = useCallback(
    function selectView(view: ViewType) {
      onChangeView(view);
      setIsViewLoading(true);
    },
    [onChangeView, setIsViewLoading]
  );

  return (
    <>
      <TranscriptSearchContainer>
        <TranscriptSearchBar
          variant="popover"
          placeholder={""}
          onKeywordsUpdated={onSearchCallback}
          onNav={(idx) => selectIndexHighlight(idx, totalMentions)}
          totalResults={totalMentions ?? 0}
          queryKeywords={searchCriteria}
          loading={isViewLoading}
          isDisabled={isSearchDisabled}
        />
        {/* @ts-ignore */}
        <ToggleButtonGroup
          value={selectedView}
          allowUnselection={false}
          wrapperProps={{
            flex: 1,
            minWidth: "fit-content",
          }}
        >
          {tabs.map((tab) => (
            <ToggleButton
              value={tab.value}
              onClick={() => onClickTab(tab.value)}
              dataAttributes={{ "data-testid": tab.testId }}
            >
              {tab.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </TranscriptSearchContainer>
    </>
  );
};

const TranscriptSearchContainer = styled.div(() => {
  const { spacing } = useThemeTokens();

  return {
    display: "inline-flex",
    width: "100%",
    alignContent: "center",
    justifyContent: "space-between",
    gap: spacing.inner.base03,
  };
});

export { DocumentActionsPanel as default };
