import { FC, useState } from "react";
import { ListOption, useAlphaToast } from "@alphasights/alphadesign-components";
import {
  createCompanyTriggers,
  hasCompanyTriggers,
  getToastMessage,
} from "pages/AlphaNowPage/components/WatchlistQuickAddButton/utils";
import { RequestType } from "pages/AlphaNowPage/components/WatchlistQuickAddButton/consts";
import { WatchlistTriggerProps } from "hooks/useWatchlist/types";
import useWatchlist, { TRIGGER_TYPE } from "hooks/useWatchlist";
import { AlphaNowProductType, AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

const DataTestIds = {
  watchlistOption: "watchlist-option",
};

type WatchlistOptionProps = {
  name: string;
  id: string;
  cdsAlphaCompanyIds: number[];
  triggers: WatchlistTriggerProps[];

  productType?: AlphaNowProductType | AlphaNowSRMProductType;
  contentId?: string;
};

const WatchlistOption: FC<WatchlistOptionProps> = ({
  name,
  id,
  cdsAlphaCompanyIds,
  triggers,
  productType,
  contentId,
}) => {
  const [isSelected, setIsSelected] = useState(hasCompanyTriggers(triggers, cdsAlphaCompanyIds));
  const { updateWatchlistTriggers, deleteWatchlistTriggers } = useWatchlist();
  const { toast } = useAlphaToast();
  const { logHit } = useTrackUserAction();

  const handleWatchlistTriggers = async (isSelecting: boolean) => {
    const origin = contentId ? HitOrigin.alphaNow : HitOrigin.companyPage;
    const requestType = isSelecting ? RequestType.add : RequestType.delete;
    try {
      let changedTriggers = [];
      if (requestType === RequestType.add) {
        const currentTriggerIds = triggers.map((trigger) => trigger.cdsAlphaCompanyId).filter(Boolean);
        const updatedWatchlist = await updateWatchlistTriggers({
          watchlistId: id,
          watchlistTriggers: createCompanyTriggers(cdsAlphaCompanyIds),
        });
        const updatedTriggers = updatedWatchlist.triggers;

        changedTriggers = updatedTriggers.filter(
          ({ cdsAlphaCompanyId, type }: WatchlistTriggerProps) =>
            type === TRIGGER_TYPE.company && !currentTriggerIds.includes(cdsAlphaCompanyId)
        );
        logHit({
          origin,
          action: HitAction.addWatchlistTriggers,
          details: {
            contentId,
            watchlistId: id,
            triggers: { companyIds: cdsAlphaCompanyIds },
          },
        });
      } else {
        changedTriggers = cdsAlphaCompanyIds.map((cdsAlphaCompanyId) =>
          triggers.find((trigger) => trigger.cdsAlphaCompanyId === cdsAlphaCompanyId)
        ) as WatchlistTriggerProps[];
        await deleteWatchlistTriggers({
          watchlistId: id,
          triggerIds: changedTriggers.map((trigger) => trigger.id),
        });
        logHit({
          origin,
          action: HitAction.deleteWatchlistTriggers,
          details: {
            contentId,
            watchlistId: id,
            triggers: { companyIds: cdsAlphaCompanyIds },
          },
        });
      }
      toast.success({ message: getToastMessage(name, changedTriggers, requestType, true, productType) });
    } catch {
      setIsSelected(!isSelecting);
      toast.error({ message: getToastMessage(name, [], requestType, false, productType, cdsAlphaCompanyIds) });
    }
  };

  const handleOnChange = () => {
    const newValue = !isSelected;
    setIsSelected(newValue);
    handleWatchlistTriggers(newValue);
  };

  return (
    <ListOption
      dataAttributes={{ "data-testid": DataTestIds.watchlistOption }}
      size="small"
      type="text"
      inputType="checkbox"
      label={name}
      selected={isSelected}
      onChange={handleOnChange}
    >
      {name}
    </ListOption>
  );
};

export { WatchlistOption as default, DataTestIds };
