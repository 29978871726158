import { FC } from "react";
import { IconButton } from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";

import { Cell } from "views/DeliverablesView/ThirdPartyAssignDocsModal/DocumentsTable/types";

const DataTestIds = {
  DELETE_BUTTON: "delete-cell-button",
};

type DeleteCellProps = Cell & {
  onClick: (id: string) => void;
};

const DeleteCell: FC<DeleteCellProps> = ({
  row: {
    original: { id },
    index,
  },
  onClick,
}) => (
  <IconButton
    variant="outline"
    size="small"
    onClick={() => onClick(id)}
    dataAttributes={{ "data-testid": `${DataTestIds.DELETE_BUTTON}-${index}` }}
  >
    <Delete />
  </IconButton>
);

export { DeleteCell as default, DataTestIds };
export type { DeleteCellProps };
