import styled, { x } from "@xstyled/styled-components";

export const Wrapper = styled(x.div)(() => {
  return `
    width: 100%;
    height: 100%;
    display: flex;
  `;
});

export const RightSide = styled(x.div)(() => {
  return `
    display: flex;
    flex-basis: 500px;
    flex-direction: column;
    flex-grow: 1;
  `;
});
