import { memo } from "react";
import { Avatar, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useSurveyInsights } from "views/SurveysResultsView/api";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";

const Filters = memo(() => {
  const { color, spacing } = useThemeTokens();

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyInsights } = useSurveyInsights(surveyId);

  return (
    <x.div py={spacing.inner.base04} px={spacing.inner.base06}>
      <x.div data-testid="expert-avatar" display="flex" alignItems="center" gap={spacing.inner.base02}>
        <Avatar color="base02">
          <Expert />
        </Avatar>
        <Typography data-testid="expert-count" variant="body-large" color={color.text.strong._}>
          {surveyInsights?.pages[0].total || "-"}
        </Typography>
      </x.div>
    </x.div>
  );
});

export default Filters;
