import { ThirdPartyDocument, ThirdPartyDocumentType } from "types/common-types";
import { getDefaultExpert } from "utils/thirdPartyDocumentsUtils";
import { FileProcessingStatus, FileStatus } from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";

const isEllipsisActive = (element: HTMLElement) => element.offsetWidth < element.scrollWidth;

const getStatus = (document: ThirdPartyDocument) => {
  const {
    processingStatus,
    documentType,
    attribute: { experts },
  } = document;
  const angle = getDefaultExpert(experts).angle;
  if (processingStatus === FileProcessingStatus.failed) {
    return FileStatus.failed;
  }
  if (documentType === ThirdPartyDocumentType.transcript && !angle) {
    return FileStatus.informationRequired;
  }
  return FileStatus.uploaded;
};

const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export { getStatus, isEllipsisActive, formatDate };
