import styled, { x } from "@xstyled/styled-components";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";

export const AlphaTableWrapper = styled(x.div)((props: { hasVirtualization: boolean; areaHeight: number }) => {
  const { hasVirtualization, areaHeight } = props;
  const {
    color: { background, border },
    spacing: { inner },
  } = useThemeTokens();

  return `
    background: ${background.surface.page.default};
    display: grid;
    height: ${hasVirtualization ? "100%" : "auto"};
    min-height: ${hasVirtualization ? 0 : "auto"};

    .row-selected {
      background-color: ${background.selected.subtle};
    }
    .row-hidden {
      background-color: ${background.neutral.subtle};
    }
    .action-column {
      width: 100%;
      padding-right: ${inner.base04};
      z-index: 1000;
    }
    .action-column > button {
      width: 100%;
    }
    .action-column > div > button:first-child {
      flex-grow: 1;
    }

  [data-testid="table-pinned-left"] {
    z-index: 5;
  }

  [data-element-type="ads-row-overlay"] {
    z-index: 5 !important;
    &#actions-right {
      left: auto;
      right: 0;
      transform: none;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

   [data-testid="table-pinned-left"] {
    border-right: 1px solid ${border.divider};
  }

  [data-testid="alpha-table"] {
    flex-grow: 1;
    height: ${hasVirtualization ? `${areaHeight}px` : "auto"};
    overflow: hidden;
  }
  `;
});

export const ShowHideExpertsButtonWrapper = styled(x.div)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return `
    display: flex;
    justify-content: center;
    padding-top: ${inner.base06};
  `;
});

export const SkeletonWithMarginBottom = styled(Skeleton)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return `
    .skeleton-loader-wrapper {
      margin-bottom: ${inner.base06};
    }
  `;
});
