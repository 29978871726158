import { Skeleton } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./ProjectSummary.styles";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const ProjectSummaryPageSkeleton = () => {
  const styles = useStyles();
  const { isMobile } = useCheckScreen();

  return (
    <>
      <x.div {...styles.summaryColumn} data-testid="project-summary-skeleton">
        <ProjectSummarySkeleton />
        <ProjectSummarySkeleton />
        <ProjectSummarySkeleton />
      </x.div>
      {!isMobile && (
        <x.div {...styles.navigationColumn}>
          <VerticalTabSkeleton />
        </x.div>
      )}
    </>
  );
};

export const ProjectSummarySkeleton = () => {
  return (
    <x.div w="100%" display="flex" gap="8px" flexDirection="column">
      <x.div mb="16px">
        <Skeleton variant="noMargin" width="50%" />
      </x.div>
      <Skeleton variant="noMargin" width="30%" />
      <Skeleton variant="noMargin" />
      <Skeleton variant="noMargin" />
      <Skeleton variant="noMargin" />
      <Skeleton variant="noMargin" />
    </x.div>
  );
};

const VerticalTabSkeleton = () => {
  return (
    <x.div display="flex" flexDirection="column" gap="12px">
      <Skeleton variant="noMargin" height="20px" />
      <Skeleton variant="noMargin" height="20px" />
      <Skeleton variant="noMargin" height="20px" />
      <Skeleton variant="noMargin" height="20px" />
      <Skeleton variant="noMargin" height="20px" />
    </x.div>
  );
};
