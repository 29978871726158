import { pendingRequestLabels } from "../InteractionsPage/helpers/Interaction";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

const { color: colorTokens } = tokens;

export const getStateColorFlyout = (state: string) =>
  ({
    proposed: colorTokens.text.secondary,
    requested: colorTokens.text.warning,
    completed: colorTokens.text.success,
    scheduled: colorTokens.text.processing,
  }[state]);

export const getSurveyStateColorFlyout = (state: string) =>
  ({
    available: colorTokens.text.secondary,
    requested: colorTokens.text.danger,
    sent: colorTokens.text.info,
    partially_completed: colorTokens.text.info,
    answered: colorTokens.text.processing,
    completed: colorTokens.text.success,
  }[state] || colorTokens.base.yellow[500]);

export const getStateColor = (state: string) =>
  ({
    proposed: colorTokens.text.secondary,
    requested: colorTokens.text.danger,
    completed: colorTokens.text.success,
    scheduled: colorTokens.text.processing,
  }[state]);

export const getStateName = ({
  state,
  previousAdvisorshipId,
  isExpertHidden,
  followUpId,
  showCalendarView,
  pendingRequest,
}: {
  state: string;
  previousAdvisorshipId?: string;
  isExpertHidden: boolean;
  followUpId?: string;
  showCalendarView: boolean;
  pendingRequest?: { type: string };
}) => {
  if (isExpertHidden) return "Hidden";

  if (showCalendarView) {
    if (previousAdvisorshipId) return appendPendingInformation(`Follow-up ${state}`, pendingRequest);
  } else {
    if (followUpId) return appendPendingInformation("Follow-up Requested", pendingRequest);
  }

  if (state === "proposed") return "Available";

  return appendPendingInformation(state, pendingRequest);
};

const appendPendingInformation = (state: string, pendingRequest?: { type: string }) => {
  if (pendingRequest && pendingRequestLabels[pendingRequest.type]) {
    return `${state}, Pending ${pendingRequestLabels[pendingRequest.type]}`;
  }

  return state;
};
