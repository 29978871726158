import { Modal, useThemeTokens, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { SecondaryButton } from "@alphasights/client-portal-shared";

export const MessageCantBeSent = ({ isOpen, setIsOpen, selectedExperts, setSelectedExperts }) => {
  const incompletedExperts = selectedExperts.filter((expert) => !expert.hasCompletedInteractions);
  const thereIsValidExperts = incompletedExperts.length < selectedExperts.length;
  const { color, spacing } = useThemeTokens();

  const onRemoveExperts = () => {
    if (setSelectedExperts) {
      setSelectedExperts(selectedExperts.filter((expert) => expert.hasCompletedInteractions));
    }
    setIsOpen(false);
  };

  return (
    <Modal
      variant="complex"
      slotHeight="150px"
      slotWidth="700px"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={"Message cannot be sent"}
      closeOnOutsideClick
      shouldShowFooter
      primaryButton={
        <SecondaryButton variant="outline" onClick={onRemoveExperts}>
          {`Remove expert${incompletedExperts.length > 1 ? "s" : ""}`}
        </SecondaryButton>
      }
    >
      <x.div
        display="flex"
        flexDirection="column"
        alignItems="left"
        justifyContent="center"
        textAlign="left"
        height="100%"
        marginTop={spacing.layout.base02}
      >
        <Typography variant="body-small" color={color.text.assistive}>
          You cannot send follow-up messages to experts who have not yet completed an interaction.
        </Typography>
        {thereIsValidExperts && (
          <>
            <Typography
              component="span"
              marginTop={spacing.layout.base02}
              variant="body-small"
              color={color.text.assistive}
            >
              {`To proceed with sending this message, please remove the expert${
                incompletedExperts.length > 1 ? "s" : ""
              } without complete interactions from the selection:`}
              <Typography component="span" variant="body-small" color={color.text.strong._}>
                {" "}
                {incompletedExperts.map((expert) => expert.label).join(", ")}
              </Typography>
            </Typography>
          </>
        )}
      </x.div>
    </Modal>
  );
};
