import { SynthesisAnswer } from "@alphasights/portal-api-client";
import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { getDefaultExpert } from "utils/thirdPartyDocumentsUtils";
import { FileStatus, SelectedThirdPartyFileToUpload } from "views/DeliverablesView/ThirdPartyDocUploadModal";

const getExpert = (document: ThirdPartyInteraction) => {
  return getDefaultExpert(document.attribute.experts, true);
};

export const getCompanyName = (answer: SynthesisAnswer, document?: ThirdPartyInteraction) => {
  if (answer.expert.thirdPartyDocumentId && document) {
    return getExpert(document).companyName;
  }
  return answer.expert.companyName || "N/A";
};

export const getCompanyNameForQuote = (
  quote: { thirdPartyDocumentId?: string; companyName?: string },
  document?: ThirdPartyInteraction
) => {
  if (quote.thirdPartyDocumentId && document) {
    return getExpert(document).companyName;
  }
  return quote.companyName || "N/A";
};

export const getExpertRole = (answer: SynthesisAnswer, document?: ThirdPartyInteraction) => {
  if (answer.expert.thirdPartyDocumentId && document) {
    return getExpert(document).position;
  }
  return answer.expert.role || "N/A";
};

export const getExpertRoleForQuote = (
  quote: { thirdPartyDocumentId?: string; role?: string },
  document?: ThirdPartyInteraction
) => {
  if (quote.thirdPartyDocumentId && document) {
    return getExpert(document).position;
  }
  return quote.role || "N/A";
};

export const isFromThirdPartyDocument = (answer: SynthesisAnswer) => {
  return !!answer.expert.thirdPartyDocumentId;
};

export const mapThirdPartyInteractionToSelectedThirdPartyFileToUpload = (
  thirdPartyDocument: ThirdPartyInteraction,
  id: number
): SelectedThirdPartyFileToUpload => ({
  id,
  documentId: thirdPartyDocument.id,
  title: thirdPartyDocument.originalFilename,
  name: thirdPartyDocument.originalFilename,
  status: FileStatus.informationRequired,
  date: new Date(thirdPartyDocument.createdAt),
  isTranscript: thirdPartyDocument.documentType === ThirdPartyDocumentType.transcript,
  experts: thirdPartyDocument.attribute.experts,
});
