import { createContext, useContext } from "react";

export const CallbackContext = createContext();

export const CallbackProvider = ({ callbacks, ...props }) => {
  const context = { callbacks };

  return <CallbackContext.Provider value={context} {...props} />;
};

export const useCallbackContext = () => useContext(CallbackContext);
