import { useState } from "react";

type ModalProperties = {
  isVisible: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const useModal = (): ModalProperties => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return {
    onOpen: () => setIsVisible(true),
    onClose: () => setIsVisible(false),
    isVisible,
  };
};

export default useModal;
