import { useState, useLayoutEffect, Fragment } from "react";
import { Portal } from "@alphasights/alphadesign-components";

import SearchBar from "components/NavigationContainer/SearchBar";
import Navigation from "components/NavigationContainer/Navigation";

import * as S from "./TopBar.styled";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useIsTabletNavigationLayout, useRenderSearchBarInsideOverlay } from "../utils";
import { RealTimeCreditBalance } from "./RealTimeCreditBalance";

const TopBar = () => {
  const renderSearchBarInsideOverlay = useRenderSearchBarInsideOverlay();
  const isTabletNavigationLayout = useIsTabletNavigationLayout();
  return (
    <S.TopBarWrapper id="navigation-topbar">
      <S.LeftPanel>
        {!isTabletNavigationLayout && <Navigation />}
        {!renderSearchBarInsideOverlay && <SearchBar />}
      </S.LeftPanel>
      <RightPanel />
    </S.TopBarWrapper>
  );
};

const RightPanel = () => {
  const currentUser = useCurrentUser();
  return (
    <S.RightPanel>
      {currentUser?.accessOnly && <RealTimeCreditBalance />}
      <div
        id="top-bar-portal-container"
        data-testid="top-bar-portal-container"
        style={{ height: "100%", maxWidth: "100%", flexGrow: 1 }}
      />
    </S.RightPanel>
  );
};

const TopBarPortal = ({ children }: { children: JSX.Element }) => {
  const [containerEl, setContainerEl] = useState<HTMLElement | undefined>(undefined);
  useLayoutEffect(() => {
    setContainerEl(document.querySelector("#top-bar-portal-container") as HTMLElement);
  }, []);

  const Wrapper = containerEl ? Portal : Fragment;
  const props = containerEl ? { containerEl } : {};
  return <Wrapper {...props}>{children}</Wrapper>;
};

const useNavigationTopbarHeight = (isInternalUser: boolean) => {
  const [element, setElement] = useState<HTMLElement | undefined>(undefined);
  const [impersonateElement, setImpersonateElement] = useState<HTMLElement | undefined>(undefined);

  useLayoutEffect(() => {
    setElement(document.querySelector("#navigation-topbar") as HTMLElement);
    if (isInternalUser) {
      setImpersonateElement(document.querySelector("#impersonate-topbar") as HTMLElement);
    }
  }, [isInternalUser]);

  const topBarHeight = element ? element.getBoundingClientRect().height : 0;
  const impersonateHeight = impersonateElement ? impersonateElement.getBoundingClientRect().height : 0;

  return topBarHeight + impersonateHeight;
};

export { TopBar, TopBarPortal, useNavigationTopbarHeight };
