import { Typography, Divider, Pill, Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import { Industry } from "@alphasights/alphadesign-icons";
import { PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { MarketCompetitor } from "../../../types/competitors";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";

const SubMarkets = ({ markets }: { markets: MarketCompetitor[] }) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  const { isAccessible } = usePrimersStore();

  return (
    <x.div>
      <x.div display="flex" flexDirection="column" gap={inner.base06} px={inner.base10} pb={inner.base06}>
        <PrimerFieldTitle text="Markets" variant="body-large-em" />
        <PrimerFieldTitle variant="body" text="Sub-Markets" />
      </x.div>
      <x.div display="flex" flexDirection="column" gap={inner.base03} px={inner.base10}>
        {markets.map((subMarket, index) => {
          const [subMarketName, tooltip] = getTruncatedTextAndTooltip(subMarket.name, 20);

          return (
            <>
              <x.div
                display="grid"
                gridTemplateColumns={"205px auto"}
                alignItems="start"
                gap={inner.base04}
                key={index}
              >
                <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                  <SensitiveDataContainer isSensitive={!isAccessible}>
                    <Pill
                      border={`1px solid ${color.base.grey["300"]}`}
                      w="fit-content"
                      leftAccessories={
                        <Icon size="x-small" color={color.icon.secondary}>
                          <Industry />
                        </Icon>
                      }
                    >
                      <Typography variant="body-em" color={color.text.strong._}>
                        <CitationTextWrapper value={subMarketName} />
                      </Typography>
                    </Pill>
                  </SensitiveDataContainer>
                </ConditionalTooltip>

                <CitationTextWrapper value={subMarket.description.content} />
              </x.div>
              {index < markets.length - 1 && <Divider />}
            </>
          );
        })}
      </x.div>
    </x.div>
  );
};

export default SubMarkets;
