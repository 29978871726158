import React, { FC, useCallback, useMemo } from "react";
import { Button, Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Plus } from "@alphasights/alphadesign-icons";

import ExpertInfo, {
  DataTestIds as ExpertInfoDataTestIds,
} from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/ExpertInfo";
import { ThirdPartyDocumentExpertWithId } from "types/common-types";
import { ADD_EXPERT, MAX_EXPERT_COUNT } from "./constants";
import { firstExpertHasInfo } from "./utils";

import * as S from "./Experts.styled";

type ExpertsProps = {
  experts: ThirdPartyDocumentExpertWithId[];
  onUpdate: (value: ThirdPartyDocumentExpertWithId, isNewExpert?: boolean) => void;
  onDelete: (expertId: number) => void;
  onAddNewExpert: () => void;
  isTranscript: boolean;
  expertIdsWithErrors: number[];
};

export const Experts: FC<ExpertsProps> = ({
  experts = [],
  onUpdate,
  onDelete,
  onAddNewExpert,
  expertIdsWithErrors,
  isTranscript,
}) => {
  const { color } = useThemeTokens();

  const hasExperts = experts.length > 0;
  const showAddExpertButton = isTranscript && experts.length < MAX_EXPERT_COUNT && firstExpertHasInfo(experts[0]);

  const handleUpdateExpert = useCallback(
    (expert: ThirdPartyDocumentExpertWithId) => {
      const isNewExpert = !experts.find((e) => e.expertId === expert.expertId) && hasExperts;
      onUpdate(expert, isNewExpert);
    },
    [hasExperts, onUpdate, experts]
  );

  const expertRows = useMemo(() => {
    const areExpertsRemovable = experts.length > 1;
    return (
      <>
        {experts.map((expert, index) => {
          const expertId = expert.expertId;
          const expertHasErrors = expertIdsWithErrors.includes(expertId);
          return (
            <ExpertInfo
              key={expertId}
              expert={expert}
              onUpdate={handleUpdateExpert}
              onDelete={() => onDelete(expertId)}
              isRemovable={areExpertsRemovable}
              showErrors={expertHasErrors}
              dataTestId={`${ExpertInfoDataTestIds.EXPERT_INFO}-${index}`}
            />
          );
        })}
      </>
    );
  }, [experts, handleUpdateExpert, expertIdsWithErrors, onDelete]);

  return (
    <S.Wrapper>
      {expertRows}
      {showAddExpertButton && (
        <S.ButtonContainer>
          <Button
            variant="outline"
            size="small"
            w="fit-content"
            onClick={onAddNewExpert}
            startIcon={
              <Icon size="x-small">
                <Plus />
              </Icon>
            }
          >
            <Typography variant="body-small-em" color={color.text.strong._}>
              {ADD_EXPERT}
            </Typography>
          </Button>
        </S.ButtonContainer>
      )}
    </S.Wrapper>
  );
};

export default Experts;
