import { useCallback } from "react";
import * as React from "react";
import { QuestionThemeCard, QuestionThemeCardLoading } from "./QuestionThemeCard";
import * as S from "./MainContent.styled";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import { DragDropContext, Droppable, DroppableProvided, DropResult } from "react-beautiful-dnd";
import { x } from "@xstyled/styled-components";

const DragDropContextWrapper = DragDropContext as React.ComponentType<any>;
const DroppableWrapper = Droppable as React.ComponentType<any>;

export const MainContent = () => {
  const {
    selectedAngleQuestionThemes,
    angleQuestionThemesLoading,
    reorderAngleQuestionThemes,
  } = useAngleQuestionsContext();

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const movedTheme = selectedAngleQuestionThemes[result.source.index];
      reorderAngleQuestionThemes(movedTheme.id, result.destination.index);
    },
    [reorderAngleQuestionThemes, selectedAngleQuestionThemes]
  );

  return (
    <S.Wrapper>
      {angleQuestionThemesLoading ? (
        <>
          <QuestionThemeCardLoading />
          <QuestionThemeCardLoading />
          <QuestionThemeCardLoading />
        </>
      ) : (
        <DragDropContextWrapper onDragEnd={handleDragEnd}>
          <DroppableWrapper droppableId="question-theme-droppable">
            {(provided: DroppableProvided) => (
              <x.div ref={provided.innerRef} {...provided.droppableProps}>
                {selectedAngleQuestionThemes.map((theme, index) => (
                  <QuestionThemeCard key={theme.id} theme={theme} index={index} />
                ))}
                {provided.placeholder}
              </x.div>
            )}
          </DroppableWrapper>
        </DragDropContextWrapper>
      )}
    </S.Wrapper>
  );
};
