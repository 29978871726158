import { FC, useEffect, useRef, useState } from "react";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import styled, { x } from "@xstyled/styled-components";
import { isEmpty } from "lodash";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { logTimeSpent } from "pages/AlphaNowPage/utils/logHit";
import { AlphaNowBanner, AlphaNowPurchasableHeader } from "pages/AlphaNowPage/components";
import { useCitationContext } from "components/CitationContext/CitationContext";
import { CitationVisibility, useCitationTrackingContext } from "components/CitationContext/CitationTrackingContext";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { PrimerVersion, useTrackUserAction } from "@alphasights/client-portal-shared";
import { contentService } from "services/content";
import { useMutation } from "query-utils";
import { getKeywordQuery } from "../../utils";
import { ExpertsInfoView } from "components/Experts/ExpertsInfoView";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import OverlayAlert, { AlertContainer } from "pages/AlphaNowPage/components/OverlayAlert/OverlayAlert";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { getCompanyPrimerTitle } from "pages/AlphaNowPage/primers/utils/utils";
import CompanyPrimerContentFactory from "./versions/CompanyPrimerContentFactory";
import { AlphaNowCompanyPrimerProps, DataTestIds, PrimerStatus } from "./CompanyPrimerContentPageVars";
import { useCompanyPrimersStore } from "pages/AlphaNowPage/primers/CompanyPrimer/state/store";
import CompanyPrimerHeaderFactory from "./versions/CompanyPrimerHeaderFactory";
import { mergeMentionsPrimerWithActualPrimer } from "./utils/utils";

import styles from "pages/AlphaNowPage/primers/styles/primers.module.css";

const AlphaNowCompanyPrimer: FC<AlphaNowCompanyPrimerProps> = ({
  content,
  contentError,
  onContentErrorChanged,
  onBookmarkChanged,
  onPurchasedContentChanged,
  isSidebarExpanded,
  price,
  alphaNowSearchQuery = [],
  origin = HitOrigin.alphaNow,
  onReturnToMobileSearch,
  isFetchingContent,
  isDeliverables = false,
}) => {
  const currentUser = useCurrentUser();
  // @ts-ignore
  const userId = currentUser?.id;
  const { logHit } = useTrackUserAction();
  const { selectedSpeakerIds } = useCitationContext();
  const isSpeakerSelected = !isEmpty(selectedSpeakerIds);
  const { isMobile } = useCheckScreen();
  const { shape, spacing } = useThemeTokens();

  const setCompanyName = useCompanyPrimersStore(({ setCompanyName }) => setCompanyName);
  const setCompanyLogo = useCompanyPrimersStore(({ setCompanyLogo }) => setCompanyLogo);
  const setCompanyId = useCompanyPrimersStore(({ setCompanyId }) => setCompanyId);

  const [primer, setPrimer] = useState(content);
  const [successMessage, setSuccessMessage] = useState("");
  const [primerStatus, setPrimerStatus] = useState(PrimerStatus.idle);
  const [isExpertsView, setIsExpertsView] = useState(false);
  const [isShrinkedPaywallHeader, showShrinkedPaywallHeader] = useState(false);

  const {
    id: contentId,
    contentType,
    productType,
    speakers,
    purchaseStatus: primerPurchaseStatus,
    approvalStatus: primerApprovalStatus,
    companyNameIdToCDSCompanyNamesMap,
    srmTypeVersion,
    companies,
  } = primer;

  let primerCompanyName: CitableValue<string>;
  let primerCompanyNameId: CitableValue<number>;
  let companyId: number;
  let primerCompanyLogo: CitableValue<string>;
  const anchor = companies.find((company) => company.companyType === "ANCHOR");

  if (srmTypeVersion === PrimerVersion.PRIMER_VERSION_3) {
    const { companyName, cdsAlphaCompanyId, logoLink } = (primer as CompanyPrimerContentV3).overview;

    primerCompanyName = { value: companyName, citedBy: [] };
    primerCompanyNameId = { value: cdsAlphaCompanyId, citedBy: [] };
    primerCompanyLogo = { value: logoLink, citedBy: [] };
    companyId = cdsAlphaCompanyId;
  } else if (srmTypeVersion === PrimerVersion.PRIMER_VERSION_2) {
    const { companyName, companyNameId, logoLink } = (primer as CompanyPrimerContentV2).primer;

    primerCompanyName = companyName;
    primerCompanyNameId = companyNameId;
    primerCompanyLogo = logoLink;
    //@ts-ignore
    companyId = anchor?.cdsAlphaCompanyId;
  } else {
    const { companyName, companyNameId, logoLink } = (primer as CompanyPrimerContentV1).primer;

    primerCompanyName = companyName;
    primerCompanyNameId = companyNameId;
    primerCompanyLogo = logoLink;
    //@ts-ignore
    companyId = anchor?.cdsAlphaCompanyId;
  }

  const mainPrimerCompanyName = getCompanyPrimerTitle(
    primerCompanyName,
    primerCompanyNameId,
    companyNameIdToCDSCompanyNamesMap
  );

  const isAccessible = isContentAccessible(primerPurchaseStatus, primerApprovalStatus);

  const searchInPrimerMutation = useMutation(
    ({ contentId, keywords, srmTypeVersion }: { contentId: string; keywords: string[]; srmTypeVersion: string }) =>
      contentService.fetchCompanyPrimerWithMentions(contentId, keywords, srmTypeVersion),
    {
      onSuccess: (res) => {
        setPrimer(
          mergeMentionsPrimerWithActualPrimer(
            primer,
            primer.srmTypeVersion === PrimerVersion.PRIMER_VERSION_3 ? res.companyPrimerV3 : res.companyPrimer
          )
        );
      },
    }
  );

  const primerContentVisible = !isMobile || isAccessible;
  const primerPurchasableHeaderVisible = !isAccessible && !(isMobile && isExpertsView);

  useEffect(() => {
    if (mainPrimerCompanyName) {
      setCompanyName(mainPrimerCompanyName);
      setCompanyLogo(primerCompanyLogo.value as string);
      setCompanyId(companyId);
    }
  }, [mainPrimerCompanyName, primerCompanyLogo.value, setCompanyLogo, setCompanyName, setCompanyId, companyId]);

  useEffect(() => {
    logHit({
      origin: HitOrigin.alphaNow,
      action: HitAction.alphaNowOpenCompanyPrimer,
      details: { contentId: contentId, userId },
    });

    const startTime = Date.now();
    const action = HitAction.alphaNowTimeSpentOnCompanyPrimer;
    return () => logTimeSpent(startTime, { contentId, userId: String(userId) }, logHit, action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPrimer({ ...primer, ...content });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    const keywords = getKeywordQuery(alphaNowSearchQuery, content.companies).map(
      (keyword: { value: any }) => keyword.value
    );
    if (keywords.length > 0 && isAccessible) {
      searchInPrimerMutation.mutate({ contentId, keywords, srmTypeVersion });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, alphaNowSearchQuery, isAccessible]);

  const {
    isAnyCitationVisible,
    closestCitationState: { element: closestCitationElement, visibility: closestCitationVisibility },
  } = useCitationTrackingContext();

  const primerContentRef = useRef<HTMLDivElement | null>(null);

  const shouldShowCitationTrackingAlert =
    !isExpertsView && !isAnyCitationVisible && isSpeakerSelected && Boolean(closestCitationElement);

  return (
    <x.div
      className={styles.primer}
      data-testid={DataTestIds.companyPrimer}
      h="100%"
      position="relative"
      display="flex"
      flexDirection="column"
      overflowY="hidden"
    >
      <HeaderContainer
        {...{
          renderBoxShadow: !isAccessible && !isMobile,
          renderBorder: !isMobile,
        }}
      >
        {!isDeliverables && (
          <CompanyPrimerHeaderFactory
            content={primer}
            {...{
              isShrinkedPaywallHeader,
              mainPrimerCompanyName,
              isSidebarExpanded,
              onBookmarkChanged,
              successMessage,
              setSuccessMessage,
              isExpertsView,
              setIsExpertsView,
              onReturnToMobileSearch,
            }}
          />
        )}
        {primerPurchasableHeaderVisible && primer.srmTypeVersion !== PrimerVersion.PRIMER_VERSION_3 && (
          <>
            <x.div w="100%" px={spacing.inner.base08} h={shape.border.radius[2]}>
              <Divider />
            </x.div>
            <AlphaNowPurchasableHeader
              content={primer}
              contentTitle={mainPrimerCompanyName}
              price={price}
              contentApprovalStatus={primerApprovalStatus}
              contentPurchaseStatus={primerPurchaseStatus}
              onError={onContentErrorChanged}
              onPurchaseSuccess={onPurchasedContentChanged}
              setPrimerStatus={setPrimerStatus}
            />
          </>
        )}
      </HeaderContainer>
      {isMobile && (
        <ExpertsView
          contentId={contentId}
          productType={productType}
          speakers={speakers}
          isAccessible={isAccessible}
          isHidden={!isExpertsView}
          toggle={setIsExpertsView}
        />
      )}
      {primerContentVisible && (
        <CompanyPrimerContentFactory
          price={price}
          content={primer}
          ref={primerContentRef}
          primerStatus={primerStatus}
          isHidden={isExpertsView}
          isFetchingContent={isFetchingContent}
          isDeliverables={isDeliverables}
          origin={origin}
          onContentErrorChanged={onContentErrorChanged}
          onPurchasedContentChanged={onPurchasedContentChanged}
          showShrinkedPaywallHeader={showShrinkedPaywallHeader}
        />
      )}
      <AlertContainer
        isVisible={shouldShowCitationTrackingAlert}
        position="absolute"
        // Pass through scroll events
        onWheel={(event) => primerContentRef.current?.scrollBy({ top: event.deltaY })}
        onMouseDown={(event) => {
          event.preventDefault();

          closestCitationElement?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }}
      >
        <OverlayAlert
          // key to remount when it is becoming hidden/visible to avoid
          // seeing the rotation animation for the arrow
          keyInfo={shouldShowCitationTrackingAlert.toString()}
          isVisible={shouldShowCitationTrackingAlert}
          iconStyles={{
            transform: `rotate(${closestCitationVisibility === CitationVisibility.aboveViewport ? "90" : "270"}deg)`,
          }}
          icon={<ArrowLeft />}
          text="View expert’s insights"
        />
      </AlertContainer>
      {contentError && (
        <AlphaNowBanner
          contentType={contentType}
          useCase={contentError}
          onClose={() => onContentErrorChanged("")}
          styles={{ position: "fixed" }}
        />
      )}
    </x.div>
  );
};

const HeaderContainer = styled.div<{
  renderBoxShadow: boolean;
  renderBorder: boolean;
}>(({ renderBoxShadow, renderBorder }) => {
  const {
    color,
    shape: { border },
  } = useThemeTokens();

  return {
    boxShadow: renderBoxShadow ? `0 ${border.width.small} 5px 0 rgba(34, 34, 34, 0.2)` : "",
    borderBottomWidth: renderBorder ? border.width.small : 0,
    borderBottomColor: color.border.neutral.default,
  };
});

interface ExpertsViewProps {
  contentId: string;
  productType: string;
  speakers: Speaker[];
  isAccessible: boolean;
  isHidden: boolean;
  toggle: (value: boolean) => void;
}

const ExpertsView = ({ contentId, productType, speakers, isAccessible, isHidden, toggle }: ExpertsViewProps) => {
  const {
    color: { background },
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div
      p={isHidden ? 0 : inner.base06}
      backgroundColor={background.surface.recessed}
      overflow="scroll"
      h={isHidden ? 0 : "100%"}
    >
      <ExpertsInfoView setIsExpertsView={toggle} {...{ contentId, productType, speakers, isAccessible }} />
    </x.div>
  );
};

export default AlphaNowCompanyPrimer;
