import { useRefEvaluated } from "hooks/useRefEvaluated";
import { isEmpty, minBy, some } from "lodash";

import {
  Dispatch,
  FC,
  memo,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as React from "react";

export const ErrorMessages = {
  missingProvider: "useCitationTrackingContext must be used within a CitationTrackingProvider",
  missingScrollArea: "Failed to find the Primer Scroll Area ref",
};

export type CitationTrackingState = {
  closestCitationState: ClosestCitationState;
  isAnyCitationVisible: boolean;
  registerCitation: (citation: CitableValue<any>, ref: RefObject<Element>) => void;
  registerScrollContainer: (element: RefObject<Element>) => void;
};

const CitationTrackingContext = React.createContext<CitationTrackingState | undefined>(undefined);

export enum CitationVisibility {
  visible = "visible",
  aboveViewport = "aboveViewport",
  belowViewport = "belowViewport",
}

export interface ClosestCitationState {
  element?: Element;
  visibility?: CitationVisibility;
}

interface CitationTrackingProviderProps extends Pick<React.HTMLAttributes<HTMLDivElement>, "children"> {
  selectedSpeakerIds: number[];

  selectedContentId?: number | string;
}

export const CitationTrackingProvider: FC<CitationTrackingProviderProps> = memo(
  ({ selectedContentId, selectedSpeakerIds, children }) => {
    const {
      isAnyCitationVisible,
      closestCitationState,
      registerScrollContainer,
      registerCitation,
    } = useCitationTracking({ selectedContentId, selectedSpeakerIds });

    const value: CitationTrackingState = useMemo(
      () => ({
        isAnyCitationVisible,
        closestCitationState,
        registerScrollContainer,
        registerCitation,
      }),
      [isAnyCitationVisible, closestCitationState, registerScrollContainer, registerCitation]
    );

    return <CitationTrackingContext.Provider value={value}>{children}</CitationTrackingContext.Provider>;
  }
);

/** Visible for testing */
export const _findSelectedCitations = (
  visibilityByElement: Map<Element, CitationVisibility>,
  selectedSpeakerIds: number[],
  citationByElement: Map<Element, CitableValue<any>>
): ClosestCitationState[] =>
  [...visibilityByElement.entries()]
    .map(([element, visibility]) => ({ element, visibility }))
    .filter(({ element }) => some(citationByElement.get(element)?.citedBy, (id) => selectedSpeakerIds.includes(id)));

/** Visible for testing */
export const _calculateCitationVisibility = (entry: IntersectionObserverEntry, scrollTop: number) => {
  if (entry.isIntersecting) return CitationVisibility.visible;

  const isBelowViewport = entry.boundingClientRect.top - scrollTop > 0;

  return isBelowViewport ? CitationVisibility.belowViewport : CitationVisibility.aboveViewport;
};

/** Visible for testing */
export const _onVisibilityByElementChangedFactory = (
  scrollAreaRef: RefObject<Element | null>,
  onVisibilityByElementChanged: Dispatch<SetStateAction<Map<Element, CitationVisibility>>>
) => (entries: IntersectionObserverEntry[]) => {
  const scrollAreaCurrent = scrollAreaRef.current;

  //TODO check citation scroll
  if (!scrollAreaCurrent) {
    throw new Error(ErrorMessages.missingScrollArea);
  }

  onVisibilityByElementChanged((prev) => {
    const next = new Map(prev);

    const scrollTop = scrollAreaCurrent.getBoundingClientRect().top;

    entries.forEach((entry: IntersectionObserverEntry) => {
      next.set(entry.target, _calculateCitationVisibility(entry, scrollTop));
    });

    return next;
  });
};

const waitForObserver = (observerRef: RefObject<IntersectionObserver | null>): Promise<IntersectionObserver> =>
  new Promise((resolve) => {
    (function pollForObserver() {
      if (observerRef.current) return resolve(observerRef.current);

      setTimeout(pollForObserver);
    })();
  });

/** Visible for testing */
export const _findClosestSelectedCitation = (
  scrollAreaRef: RefObject<Element>,
  selectedCitations: ClosestCitationState[]
): ClosestCitationState => {
  const findVerticalCenter = (element: Element) => {
    const rect = element.getBoundingClientRect();

    return rect.top + rect.height / 2;
  };

  const scrollVerticalCenter = findVerticalCenter(scrollAreaRef.current!);

  return minBy(selectedCitations, ({ element }) => Math.abs(findVerticalCenter(element!) - scrollVerticalCenter))!;
};

const trackCitations = (params: {
  selectedSpeakerIds: number[];
  citationByElement: Map<Element, CitableValue<any>>;
  visibilityByElement: Map<Element, CitationVisibility>;
  isAnyCitationVisible: boolean;
  closestSelectedCitation: ClosestCitationState;
  onCitationVisibilityChanged: Dispatch<SetStateAction<boolean>>;
  onClosestCitationChanged: Dispatch<SetStateAction<ClosestCitationState>>;
  scrollAreaRef: RefObject<Element>;
}) => {
  if (isEmpty(params.selectedSpeakerIds)) return;

  const selectedCitations = _findSelectedCitations(
    params.visibilityByElement,
    params.selectedSpeakerIds,
    params.citationByElement
  );

  const hasVisibleCitation = selectedCitations.some(({ visibility }) => visibility === CitationVisibility.visible);

  if (hasVisibleCitation !== params.isAnyCitationVisible) {
    params.onCitationVisibilityChanged(hasVisibleCitation);
  }

  if (isEmpty(selectedCitations)) return;

  if (hasVisibleCitation) {
    const hasClosestCitation = Boolean(params.closestSelectedCitation.element);

    // Reset the closest citation if one is visible currently
    if (hasClosestCitation) params.onClosestCitationChanged({});

    return;
  }

  const closestCitation = _findClosestSelectedCitation(params.scrollAreaRef, selectedCitations);

  if (params.closestSelectedCitation.element === closestCitation.element) {
    return;
  }

  params.onClosestCitationChanged({
    element: closestCitation.element,
    visibility: closestCitation.visibility,
  });
};

const useCitationTracking = (params: {
  selectedSpeakerIds: number[];

  selectedContentId?: string | number;
}) => {
  const [visibilityByElement, setVisibilityByElement] = useState<Map<Element, CitationVisibility>>(new Map());

  const [citationByElement, setCitationByElement] = useState<Map<Element, CitableValue<any>>>(new Map());

  const [isAnyCitationVisible, setCitationVisible] = useState(false);

  const [closestSelectedCitation, setClosestSelectedCitation] = useState<ClosestCitationState>({});

  useEffect(() => {
    setVisibilityByElement(new Map());
    setCitationByElement(new Map());
    setCitationVisible(false);
    setClosestSelectedCitation({});
  }, [params.selectedContentId]);

  useEffect(() => {
    setClosestSelectedCitation({});
  }, [params.selectedSpeakerIds]);

  const scrollAreaRef = useRef<Element | null>(null);

  const observerRef = useRefEvaluated(
    () => new IntersectionObserver(_onVisibilityByElementChangedFactory(scrollAreaRef, setVisibilityByElement))
  );

  useEffect(() => {
    trackCitations({
      selectedSpeakerIds: params.selectedSpeakerIds,
      citationByElement,
      visibilityByElement,
      isAnyCitationVisible,
      closestSelectedCitation,
      onCitationVisibilityChanged: setCitationVisible,
      onClosestCitationChanged: setClosestSelectedCitation,
      scrollAreaRef,
    });
  }, [
    params.selectedSpeakerIds,
    citationByElement,
    visibilityByElement,
    isAnyCitationVisible,
    closestSelectedCitation,
  ]);

  const registerCitation = useCallback(
    async (citation: CitableValue<any>, citationRef: RefObject<Element>) => {
      const observer = await waitForObserver(observerRef);

      const currentCitation = citationRef.current;

      if (!currentCitation) return;

      observer.observe(currentCitation);

      setCitationByElement((prevCitationByElement) => {
        const nextCitationByElement = new Map(prevCitationByElement);

        nextCitationByElement.set(currentCitation, citation);

        return nextCitationByElement;
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const registerScrollContainer = useCallback(
    (scrollArea: RefObject<Element>) => (scrollAreaRef.current = scrollArea.current),
    []
  );

  return {
    isAnyCitationVisible,
    closestCitationState: closestSelectedCitation,
    registerScrollContainer,
    registerCitation,
  };
};

export const useCitationTrackingContext = (): CitationTrackingState => {
  const citationTrackingContext = useContext(CitationTrackingContext);

  if (citationTrackingContext === undefined) {
    throw new Error(ErrorMessages.missingProvider);
  }

  return citationTrackingContext;
};
