import { useCallback, useEffect, useMemo, useState } from "react";
import { useStarExpertContext } from "providers/StarExpertProvider";
import { HitOrigin } from "@alphasights/portal-api-client";
import { StarSelected, StarUnselected } from "@alphasights/alphadesign-icons";
import { useLockedExpert } from "hooks/useLockedExpert";
import { Loading, toastSuccess } from "@alphasights/alphadesign-components";

export const useStarExpert = ({ interaction, origin }: { interaction: Interaction; origin: HitOrigin }) => {
  const [interactionStarredStatus, setInteractionStarredStatus] = useState<Record<string, boolean>>({});
  const { onToggleStar, isLoading } = useStarExpertContext();
  const { locked } = useLockedExpert(interaction);

  const isStarred = useMemo(() => interactionStarredStatus[interaction.id] ?? false, [
    interaction.id,
    interactionStarredStatus,
  ]);
  const setIsStarred = useCallback(
    (starred: boolean) => setInteractionStarredStatus((prev) => ({ ...prev, [interaction.id]: starred })),
    [interaction.id]
  );

  useEffect(
    function syncStarredWhenInteractionChanges() {
      setIsStarred(interaction.starred);
    },
    [interaction, setIsStarred]
  );

  const handleToggleClick = useCallback(
    async (interactionId: string) => {
      if (isLoading(interactionId) || locked) return;
      onToggleStar(interaction, origin, !isStarred).then((starred) => {
        setIsStarred(starred);
        starred && toastSuccess({ message: "Expert starred." });
      });
    },
    [isLoading, locked, isStarred, onToggleStar, interaction, setIsStarred, origin]
  );

  const loading = useMemo(() => isLoading(interaction.id), [isLoading, interaction.id]);

  const starIcon = useMemo(() => {
    if (loading) {
      return (
        <div>
          <Loading size="xs" display="flex" alignItems="center" />
        </div>
      );
    }
    return isStarred ? (
      <StarSelected
        data-testid="starred-icon"
        style={{ opacity: locked ? 0.5 : 1, cursor: locked ? "not-allowed" : "pointer" }}
      />
    ) : (
      <StarUnselected
        data-testid="star-icon"
        style={{ opacity: locked ? 0.5 : 1, cursor: locked ? "not-allowed" : "pointer" }}
      />
    );
  }, [loading, isStarred, locked]);

  const starTooltip = useMemo(
    () => (locked ? "You cannot star this expert" : isStarred ? "Un-star expert" : "Star expert"),
    [isStarred, locked]
  );

  return { isStarred, starIcon, starTooltip, handleToggleClick, locked, loading };
};
