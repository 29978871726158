import * as React from "react";

import { useAccessControl } from "hooks/useAccessControl";

export enum Variant {
  Disabled = "DISABLED",
  NotAllowed = "NOT_ALLOWED",
  Opaque = "OPAQUE",
}

const renderDisabled = (children: React.ReactElement, variant: Variant) => {
  switch (variant) {
    case Variant.NotAllowed:
      return React.cloneElement(children, {
        cursor: "not-allowed !important",
        outline: { focus: "none" },
        onClick: () => {},
      });
    case Variant.Opaque:
      return React.cloneElement(children, {
        pointerEvents: "none",
        opacity: "0.6",
      });
    default:
      return React.cloneElement(children, { disabled: true, isDisabled: true });
  }
};

interface AccessControlProps {
  allowedPermissions: string[];
  allowClientRoleAccess?: boolean;
  variant?: Variant;
  children?: React.ReactElement;
  renderNoAccess?: (() => React.ReactElement) | null;
}

export const AccessControl = ({
  allowedPermissions = [],
  allowClientRoleAccess = true,
  variant = Variant.Disabled,
  renderNoAccess = null,
  children,
}: AccessControlProps) => {
  const hasPermission = useAccessControl(allowedPermissions, allowClientRoleAccess);

  if (!children) return null;

  if (hasPermission) return children;

  if (renderNoAccess) return renderNoAccess();

  return renderDisabled(children, variant);
};

export const withAccessControl = <P extends object>(WrappedComponent: React.ComponentType<P>) => ({
  accessControl,
  ...props
}: P & { accessControl: AccessControlProps }) =>
  accessControl ? (
    <AccessControl {...accessControl}>
      <WrappedComponent {...(props as P)} />
    </AccessControl>
  ) : (
    <WrappedComponent {...(props as P)} />
  );
