import { FC, ReactNode } from "react";
import { HighlightablePill } from "@alphasights/client-portal-shared";
import { PillSectionWrapper, TitleWrapper } from "./PillSection.styled";

const DataTestIds = {
  Info: "pill-section-info",
  Title: "pill-section-title",
};

const buildTestIds = (title?: string) => {
  const normalisedTitle = title?.toLowerCase().replace(/ /g, "-");
  const suffix = normalisedTitle ? "-" + normalisedTitle : "";
  return {
    infoTestId: `${DataTestIds.Info}${suffix}`,
    titleTestId: `${DataTestIds.Title}${suffix}`,
  };
};

type PillItem = {
  name: string;
  id: number;

  icon?: ReactNode;
  highlight?: boolean;
};

export type PillSectionProps = {
  items: PillItem[];

  title?: string;
  onClick?: (id: number, name: string) => void;
  isInteractive?: boolean;
};

const PillSection: FC<PillSectionProps> = ({ title, items, onClick = () => {}, isInteractive = false }) => {
  const { infoTestId, titleTestId } = buildTestIds(title);

  return (
    <PillSectionWrapper data-testid={infoTestId}>
      {title && <TitleWrapper data-testid={titleTestId}>{title}</TitleWrapper>}
      {items.map(({ name, icon, highlight, id }) => (
        <HighlightablePill {...{ name, icon, highlight, isInteractive }} onClick={() => onClick(id, name)} />
      ))}
    </PillSectionWrapper>
  );
};

export { PillSection as default, DataTestIds };
