import { ThirdPartyDocumentExpertWithId } from "types/common-types";

const MODAL_TITLE = "Edit Document Details";
const MODAL_WIDTH = "676px";

const SAVE = "Save";
const BACK = "Back";

enum FormFieldLabel {
  title = "Uploaded Document Title",
  expertCompany = "Expert Company",
  expertPosition = "Expert Position",
  relevantDate = "Relevant Date",
  angle = "Angle",
}

const DUMMY_EXPERT: ThirdPartyDocumentExpertWithId = {
  companyName: undefined,
  cdsAlphaCompanyId: undefined,
  position: undefined,
  angle: undefined,
  expertId: 0,
};

export { MODAL_TITLE, MODAL_WIDTH, SAVE, BACK, DUMMY_EXPERT, FormFieldLabel };
