import { x } from "@xstyled/styled-components";
import { Divider, Icon, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { InfoFilled } from "@alphasights/alphadesign-icons";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import { useSurveysStore } from "pages/AlphaNowPage/primers/request-survey/state/store";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

const RequestSurveyCustomerPrimer = () => {
  const { spacing, color } = useThemeTokens();
  const { logHit } = useTrackUserAction();
  const setIsModalOpen = useSurveysStore(({ setIsModalOpen }) => setIsModalOpen);
  const primerTitle = usePrimersStore(({ primerTitle }) => primerTitle);

  return (
    <x.div display="flex" flexDirection="column" rowGap={spacing.layout.base03} marginBottom={spacing.layout.base03}>
      <x.div display="flex" columnGap={spacing.layout.base}>
        <x.div paddingTop="5px">
          <Icon size="small" color={color.icon.info}>
            <InfoFilled />
          </Icon>
        </x.div>
        <x.div>
          <Typography variant="body-small" display="inline">
            <>
              Increase the number of customer respondents with a survey on the {primerTitle} market.&nbsp;
              <Link
                onClick={() => {
                  logHit({
                    origin: HitOrigin.alphaNow,
                    action: HitAction.alphaNowRequestSurveyAttempt,
                  });

                  setIsModalOpen(true);
                }}
                size="small"
                fontWeight="600"
              >
                Launch a Customer Survey
              </Link>
            </>
          </Typography>
        </x.div>
      </x.div>
      <Divider />
    </x.div>
  );
};

export default RequestSurveyCustomerPrimer;
