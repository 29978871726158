import { useEffect } from "react";
import "./index.css";
import { useEnv } from "@alphasights/client-portal-shared";
import { LoginModal as AuthLoginModal } from "@alphasights/auth-components";
import { useClientPortalActions } from "app/wrappers/ClientPortalWrapper";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useLogger } from "logging/Logger";
import useQuery from "hooks/useQuery";

export const LoginModal = ({
  isOpen,
  onClose,
  message = undefined,
  allowAnonymousContinue = true,
  projectToken,
}: {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
  allowAnonymousContinue?: boolean;
  projectToken?: string;
}) => {
  const { setIsModalOpen } = useClientPortalActions();
  const user = useCurrentUser();
  const shouldOpen = isOpen && !user;
  const logger = useLogger();
  const query = useQuery();

  const env = useEnv();

  useEffect(
    function trackOpenModal() {
      if (shouldOpen) {
        logger.log("login modal launched");
      }
    },
    [shouldOpen, logger]
  );

  return (
    <>
      {shouldOpen && (
        <AuthLoginModal
          onClose={onClose}
          onSuccess={(user) => {
            logger.log("login success", user);
            setIsModalOpen(false);
          }}
          onFail={() => logger.log("login failed")}
          loginUrl={env!.login!.loginModalUrl!}
          loginProps={{
            allowAnonymousContinue,
            emailStepMessage: message,
            projectToken,
            redirectUrl: query.get("next") ?? undefined,
          }}
        />
      )}
    </>
  );
};
