import { Dispatch, ForwardedRef, SetStateAction, forwardRef, useCallback, useEffect, useState } from "react";

import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Button, Skeleton, Status, Typography } from "@alphasights/alphadesign-components";
import { useAlphaGPTExpertRecommendationStyles } from "./AlphaGPTExpertRecommendation.styles";
import { Reply, TickOutline } from "@alphasights/alphadesign-icons";
import { AlphaGPTMessageContent } from "../AlphaGPTMessageContent/AlphaGPTMessageContent";
import Card from "components/Card";
import { ReactMarkdownStyled } from "views/DeliverablesView/Sidebar/ContentFlyout/ContentFlyout";
import useModal from "hooks/useModal";
import { RequestExpertOverlay } from "pages/AlphaNowPage/components";
import { AlphaNowContentType } from "@alphasights/client-portal-shared";
import { AlphaGPTMessageProvider, useAlphaGPTMessageContext } from "providers/AlphaGPTMessageProvider";
import { AlphaGptCollapsibleList } from "../AlphaGPTCollapsibleList/AlphaGPTCollapsibleList";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { compact } from "lodash";
import useExpertRequest from "pages/AlphaNowPage/hooks/useExpertRequest";
import useRequestForProject from "views/DeliverablesView/useRequestForProject";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useCurrentUser } from "@alphasights/portal-auth-react";

interface AlphaGPTExpertRecommendationProps {
  collapse?: boolean;
}

type handleSubmitProps = (selectedExperts: number[], reason: string) => void;

export const AlphaGPTExpertRecommendation = React.forwardRef<HTMLDivElement, AlphaGPTExpertRecommendationProps>(
  ({ collapse = false }, ref) => {
    const { projectToken, searchContent, name, conversation, requestedSpeakersIds } = useAlphaGPTContext();
    const { query, id: messageId } = useAlphaGPTMessageContext();
    const { contentWrapper, expertRecomendation } = useAlphaGPTExpertRecommendationStyles();
    const [searchResults, setSearchResults] = React.useState<ContentResults[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const { logHit } = useTrackUserAction();
    const [expertRequests, setExpertRequests] = useState<ClientExpertInquiryDto[]>([]);

    const trackSuggestedExpertsResponse = useCallback(
      (results: ContentResults[]) => {
        const recommendedExpertIds = results.flatMap((result) =>
          result.speakers.filter((speaker) => !speaker.isModerator).map((speaker) => speaker.speakerId)
        );
        logHit({
          origin: projectToken ? HitOrigin.projectAlphaGptPage : HitOrigin.alphaGptPage,
          action: HitAction.alphaGptExpertsRecommended,
          projectToken,
          details: {
            conversationId: conversation?.id,
            messageId: messageId,
            returnedResults: recommendedExpertIds.length > 0,
            recommendedExpertIds,
          },
        });
      },
      [logHit, projectToken, messageId, conversation]
    );

    useEffect(
      function loadSuggestedExperts() {
        if (query) {
          setIsLoading(true);
          searchContent([query], 20, [AlphaNowContentType.srm, AlphaNowContentType.pcc])
            .then((response) => {
              setSearchResults(response);
              trackSuggestedExpertsResponse(response);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          trackSuggestedExpertsResponse([]);
        }
      },
      [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const { expertRequests: existingExpertRequests, isRequestExpertLoading } = useExpertRequest({
      contents: searchResults,
      origin: HitOrigin.alphaGptPage,
    });

    const { isExpertRequestForProjectLoading } = useRequestForProject({
      contents: searchResults,
    });

    useEffect(() => {
      existingExpertRequests && setExpertRequests(existingExpertRequests);
    }, [existingExpertRequests]);

    const allExpertsIds = searchResults
      .flatMap((request) => request.speakers)
      .filter((s) => !projectToken || !s.isModerator)
      .map((speaker) => (projectToken ? speaker.speakerId : speaker.id));
    const requestedExpertsIds = projectToken
      ? requestedSpeakersIds
      : expertRequests.flatMap((request) => request.speakerIds);
    const nonRequestedExperts = allExpertsIds.filter((expertId) => !requestedExpertsIds.includes(expertId));

    const allSpeakers = searchResults.flatMap((content) =>
      content.speakers.map((speaker) => {
        return { ...speaker, contentId: content.id };
      })
    );

    const nonModerators = allSpeakers.filter((speaker: Speaker) => !speaker.isModerator);

    const moderators = allSpeakers.filter((speaker: Speaker) => speaker.isModerator);

    const speakers = [
      ...nonModerators.filter((speaker) => !speaker.company.localeCompare(query!, undefined, { sensitivity: "base" })),
      ...nonModerators.filter((speaker) => speaker.company.localeCompare(query!, undefined, { sensitivity: "base" })),
    ].slice(0, 10);

    return (
      <x.div data-testid="alpha-gpt-recommended-expert">
        {isLoading && (
          <x.div {...expertRecomendation}>
            <Skeleton variant="noMargin" height="110px" />
          </x.div>
        )}
        {!isLoading &&
          (searchResults?.length > 0 ? (
            <x.div ref={ref}>
              <AlphaGPTMessageProvider
                message={{
                  id: "",
                  sender: "GPT",
                  text: `Here are some experts who have provided insights on ${query}. ${name} can only recommend specific experts that have contributed to our research library. Reach out to your AlphaSights team should you wish for a validated list of experts on your topic.`,
                }}
              >
                <AlphaGPTMessageContent />
              </AlphaGPTMessageProvider>
              <x.div {...contentWrapper}>
                <x.div {...expertRecomendation} data-testid="expert-recomendations">
                  <AlphaGptCollapsibleList
                    collapse={collapse}
                    items={speakers.map((speaker: Speaker) => (
                      <ExpertCard
                        expert={speaker}
                        allContents={searchResults}
                        onExpertRequested={(param) =>
                          setExpertRequests((oldValue) => [...oldValue, { ...param } as ClientExpertInquiryDto])
                        }
                        expertRequest={expertRequests.find((request) => request.speakerIds?.includes(speaker.id))}
                        isRequestExpertLoading={isRequestExpertLoading || isExpertRequestForProjectLoading}
                        requestedSpeakersIds={requestedSpeakersIds}
                        allSpeakers={[...speakers, ...moderators]}
                      />
                    ))}
                  />
                </x.div>
                {nonRequestedExperts.length > 0 && (
                  <RequestAllExpertsButton
                    contents={searchResults}
                    speakers={[...speakers, ...moderators]}
                    onExpertRequested={(param) =>
                      setExpertRequests((oldValue) => [...oldValue, { ...param } as ClientExpertInquiryDto])
                    }
                  />
                )}
              </x.div>
            </x.div>
          ) : (
            <NoRecommendedExpert />
          ))}
      </x.div>
    );
  }
);

const NoRecommendedExpert = () => {
  const { name } = useAlphaGPTContext();
  return (
    <x.div data-testid="alpha-gpt-no-recommended-expert">
      <AlphaGPTMessageProvider
        message={{
          id: "",
          sender: "GPT",
          text: `${name} can only recommend specific experts that have contributed to our research library. Reach out to your AlphaSights team should you wish for a validated list of experts on your topic.`,
        }}
      >
        <AlphaGPTMessageContent />
      </AlphaGPTMessageProvider>
    </x.div>
  );
};

interface RequestExpertsButtonProps {
  contents: ContentResults[];
  speakers: Speaker[];
  onExpertRequested: Dispatch<SetStateAction<Partial<ClientExpertInquiryDto>>>;
}

const RequestAllExpertsButton = ({ contents, speakers, onExpertRequested }: RequestExpertsButtonProps) => {
  const { viewAllExpertsButton } = useAlphaGPTExpertRecommendationStyles();
  const { isVisible, onOpen, onClose } = useModal();
  const currentUser = useCurrentUser();
  return (
    <>
      <Button
        variant="outline"
        size="small"
        startIcon={<Reply />}
        onClick={onOpen}
        data-testid="request-all-experts-button"
        disabled={currentUser?.accessOnly}
        {...viewAllExpertsButton}
      >
        Request All Experts
      </Button>
      {isVisible && (
        <RequestExpertModal
          contents={contents}
          onClose={onClose}
          speakers={speakers}
          onExpertRequested={onExpertRequested}
          selectedSpeakers={speakers}
        />
      )}
    </>
  );
};

interface RequestExpertModalProps {
  contents: ContentResults[];
  onExpertRequested?: Dispatch<SetStateAction<Partial<ClientExpertInquiryDto>>>;
  onClose: () => void;
  expertRequest?: Partial<ClientExpertInquiryDto>;
  speakers: Speaker[];
  selectedSpeakers: Speaker[];
}

const RequestExpertModal = ({
  contents,
  onExpertRequested,
  onClose,
  expertRequest: initialExpertRequest,
  speakers,
  selectedSpeakers,
}: RequestExpertModalProps) => {
  const { projectToken, setRequestedSpeakersIds } = useAlphaGPTContext();

  const {
    handleSubmitRequest: handleExpertRequestSubmit,
    handleCancelRequest: handleExpertRequestCancel,
    expertRequests,
  } = useExpertRequest({
    onClose,
    contents,
    onExpertRequested,
    origin: HitOrigin.alphaGptPage,
  });

  const { submit } = useRequestForProject({ speakers });
  const handleProjectExpertRequestSubmit: handleSubmitProps = async (selectedExperts, reason) => {
    await submit(selectedExperts, reason);
    setRequestedSpeakersIds(selectedExperts);
    onClose();
  };

  const overlayProps = projectToken
    ? {
        onSubmit: handleProjectExpertRequestSubmit,
        onCancel: onClose,
        selectedSpeakers: (selectedSpeakers ?? speakers).map((sp) => sp.speakerId),
      }
    : {
        expertRequests: (initialExpertRequest ? [initialExpertRequest] : expertRequests) as ClientExpertInquiryDto[],
        onSubmit: handleExpertRequestSubmit,
        onCancel: handleExpertRequestCancel,
        selectedSpeakers: (selectedSpeakers ?? speakers).map((sp) => sp.id),
      };

  return <RequestExpertOverlay projectToken={projectToken} onClose={onClose} speakers={speakers} {...overlayProps} />;
};

interface ExpertCardProps {
  expert: Speaker;
  expertRequest?: Partial<ClientExpertInquiryDto>;
  onExpertRequested?: Dispatch<SetStateAction<Partial<ClientExpertInquiryDto>>>;
  isRequestExpertLoading: boolean;
  requestedSpeakersIds: number[];
  allContents: ContentResults[];
  allSpeakers: Speaker[];
}

const ExpertCard = forwardRef(
  (
    {
      expert,
      expertRequest,
      onExpertRequested,
      isRequestExpertLoading,
      requestedSpeakersIds,
      allContents,
      allSpeakers,
    }: ExpertCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      expertCard,
      expertCardHeader,
      expertCardInfo,
      expertCardSeparator,
      requestedExpertStatus,
      requestedExpertButton,
    } = useAlphaGPTExpertRecommendationStyles();
    const { projectToken } = useAlphaGPTContext();
    const currentUser = useCurrentUser();

    const isRequested = projectToken
      ? requestedSpeakersIds.includes(expert.speakerId)
      : expertRequest?.speakerIds?.includes(expert.id);

    const { isVisible, onOpen, onClose } = useModal();

    const content = allContents.find((content) => content.id === expert.contentId)!;
    const moderator = content.speakers.find((speaker: Speaker) => speaker.isModerator);

    return (
      <Card {...expertCard} data-testid={`expert-card-${expert.id}`} ref={ref}>
        <x.div {...expertCardHeader}>
          <x.div {...expertCardInfo}>
            <div>
              <Typography variant="body-em" color="strong" component="span">
                {expert.company}
                {" - "}
              </Typography>
              <Typography variant="body" color="strong" component="span">
                {expert.jobTitle}
              </Typography>
            </div>
            <Typography variant="body-small" color="secondary">
              {expert.jobDuration}
            </Typography>
          </x.div>
          <>
            <>
              {isRequestExpertLoading ? (
                <x.div w="146px">
                  <Skeleton variant="noMargin" width="146px" height="32px"></Skeleton>
                </x.div>
              ) : isRequested ? (
                <Status
                  size="small"
                  text="Requested"
                  leftIcon={<TickOutline />}
                  {...requestedExpertStatus}
                  testId="requested-expert-status"
                />
              ) : (
                <Button
                  variant="outline"
                  size="small"
                  onClick={onOpen}
                  data-testid="request-expert-button"
                  disabled={currentUser?.accessOnly}
                  {...requestedExpertButton}
                >
                  Request Expert
                </Button>
              )}
            </>
            {isVisible && (
              <RequestExpertModal
                contents={[content]}
                onClose={onClose}
                expertRequest={expertRequest || {}}
                speakers={projectToken ? allSpeakers : compact([moderator, expert])}
                onExpertRequested={onExpertRequested}
                selectedSpeakers={[expert]}
              />
            )}
          </>
        </x.div>
        <x.div {...expertCardSeparator}></x.div>
        <Typography variant="body" color="strong">
          <ReactMarkdownStyled source={expert.bio} />
        </Typography>
      </Card>
    );
  }
);
