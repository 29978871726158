import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";

const MAX_VISIBLE_VENDORS_LOGOS = 4;

const VendorsLogos = ({
  vendors,
  maxVisibleVendorsLogos = MAX_VISIBLE_VENDORS_LOGOS,
}: {
  vendors: string[];
  maxVisibleVendorsLogos?: number;
}) => {
  const { color, icons } = useThemeTokens();

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const companyDetails = surveyMetadata?.companyDetails ?? [];

  const vendorsLength = vendors.length;
  const hasVendors = vendorsLength > 0;
  const hasVendorsOverflow = vendorsLength > maxVisibleVendorsLogos;

  if (!hasVendors) {
    return (
      <Typography data-testid="no-vendors" variant="body-small" color={color.text.strong._}>
        -
      </Typography>
    );
  }

  const displayedVendors = hasVendorsOverflow ? vendors.slice(0, maxVisibleVendorsLogos - 1) : vendors;
  return (
    <>
      {displayedVendors.map((vendor, index) => {
        const logoUrl = companyDetails.find((company) => company.name === vendor)?.logoUrl;

        return <PrimerCompanyLogo key={index} size={icons.size.sm} src={logoUrl} />;
      })}
      {hasVendorsOverflow && (
        <Typography data-testid="overflow-vendors" variant="body-small" color={color.text.strong._}>
          +{vendorsLength - maxVisibleVendorsLogos + 1}
        </Typography>
      )}
    </>
  );
};

export default VendorsLogos;
