import { useRef, useState, useCallback, useEffect } from "react";
import * as React from "react";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useLabelExpert = (
  interaction: Interaction,
  setCurrentOpenId: (id?: string) => void,
  currentOpenId?: string
) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const user = useCurrentUser();
  const { openLoginModal } = useClientPortalOperations();
  const { color } = useThemeTokens();

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      if (!user) {
        openLoginModal();
        return;
      }

      if (currentOpenId === interaction.id) {
        setOpen(false);
        setCurrentOpenId();
      } else {
        setOpen(true);
        setCurrentOpenId(interaction.id);
      }
    },
    [user, openLoginModal, interaction.id, currentOpenId, setCurrentOpenId]
  );

  useEffect(
    function closePopoverOnInteractionChanged() {
      if (currentOpenId !== interaction.id) {
        setOpen(false);
      }
    },
    [currentOpenId, interaction.id]
  );

  const labelColor = open ? color.icon.info : "inherit";

  const handlePopoverClose = useCallback(() => {
    setOpen(false);
    if (currentOpenId === interaction.id) {
      setCurrentOpenId(undefined);
    }
  }, [interaction.id, currentOpenId, setCurrentOpenId]);

  return { handleClick, open, ref, handlePopoverClose, labelColor };
};
