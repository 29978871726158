import { useCallback, useMemo } from "react";
import { ComplianceReviewStatus, MessageResponse } from "types/common-types";

type SuggestionCallback = (payload: { contentId: number; versionId: number }) => Promise<void>;

export const useComplianceReviewContent = ({
  message,
  onAcceptSuggestion,
  onDeclineSuggestion,
}: {
  message: MessageResponse;
  onAcceptSuggestion?: SuggestionCallback;
  onDeclineSuggestion?: SuggestionCallback;
}) => {
  const { originalMessage, suggestionMessage } = useMemo(() => {
    const withoutSuggestionResponse = {
      originalMessage: { content: message.content, sanitizedContent: message.sanitizedContent },
      suggestionMessage: undefined,
    };

    if (!message.contentUpdateHistory) return withoutSuggestionResponse;

    if (
      message.complianceReviewStatus !== ComplianceReviewStatus.SUGGESTION_PENDING &&
      message.complianceReviewStatus !== ComplianceReviewStatus.SUGGESTION_DECLINED
    )
      return withoutSuggestionResponse;

    const version = message.contentUpdateHistory.find(
      (history) => history.complianceReviewStatus === ComplianceReviewStatus.REJECTED_WITH_SUGGESTION
    );

    if (version)
      return {
        originalMessage: { content: version.content, sanitizedContent: version.sanitizedContent },
        suggestionMessage: { content: message.content, sanitizedContent: message.sanitizedContent },
      };

    return withoutSuggestionResponse;
  }, [message]);

  const acceptSuggestion = useCallback(
    ({ contentId, versionId }: { contentId?: number; versionId?: number }) => {
      if (!contentId || !versionId || !onAcceptSuggestion) return;

      return () => onAcceptSuggestion({ contentId: contentId, versionId: versionId });
    },
    [onAcceptSuggestion]
  );

  const declineSuggestion = useCallback(
    ({ contentId, versionId }: { contentId?: number; versionId?: number }) => {
      if (!contentId || !versionId || !onDeclineSuggestion) return;

      return () => onDeclineSuggestion({ contentId: contentId, versionId: versionId });
    },
    [onDeclineSuggestion]
  );

  return {
    originalMessage,
    suggestionMessage,
    onAcceptSuggestion: acceptSuggestion(message),
    onDeclineSuggestion: declineSuggestion(message),
  };
};
