import { useState } from "react";
import * as React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { isEmpty } from "lodash";
import { useQueryClient } from "query-utils";
import { contentService } from "services/content";
import Search, { SearchSizeVariant, SearchVariant } from "components/Search";
import { getDefaultComponents } from "components/Search/components";
import { MatchedOnField, MatchedOnType } from "models/companies/search/constants";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { SURVEY_MODAL_INPUT_MIN } from "pages/AlphaNowPage/primers/request-survey/constants";

type SearchOptions = {
  cdsAlphaCompanyId: string;
  primaryName: string;
  logo: string;
  matchedOn: {
    id: string;
    field: string;
    type: string;
    value: string;
  };
  weight: number;
}[];

type SurveyCompany = {
  cdsAlphaCompanyId: string;
  companyName: string;
};

interface SurveyCompanySearchProps {
  onChangeProperties: (companies: SurveyCompany[]) => void;
  errorMessage: string;
  query: any[];
  setQuery: (query: any[]) => void;
  defaultCompanies?: SurveyCompany[];
  dataTestId: string;
}

export const searchInputTruncateLabel = (label: string, maxLength: number) =>
  label?.length > maxLength ? `${label?.substring(0, maxLength)}...` : label;

const SurveyCompanySearch: React.FC<SurveyCompanySearchProps> = ({
  onChangeProperties,
  errorMessage,
  query,
  setQuery,
  defaultCompanies,
  dataTestId,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const [options, setOptions] = useState<SearchOptions>([]);
  const queryClient = useQueryClient();

  const loadSearchOptions = async (query: string) => {
    if (query.length <= 1) return [];

    const companies = await queryClient.fetchQuery(["search-companies", query], () =>
      contentService.searchCompanies(query)
    );
    setOptions(companies);

    let options: any = [];

    if (query?.length > 0) {
      const primaryNameExactMatches = companies.filter(
        ({ matchedOn: { field, type } }: { matchedOn: { field: string; type: string } }) =>
          field === MatchedOnField.PrimaryName && type === MatchedOnType.Exact
      );

      if (isEmpty(primaryNameExactMatches)) {
        options.push({
          label: query[0].toUpperCase() + query.substring(1),
          value: query,
          type: SEARCH_SUGGESTION_TYPES.Keyword,
        });
      }
    }
    companies.forEach((company: any) =>
      options.push({
        label: company.primaryName,
        value: company.primaryName,
        StartIcon: () => <PrimerCompanyLogo size={inner.base06} src={company.logo} whiteBackground />,
      })
    );

    return options;
  };

  const SearchItem = ({ data, ...props }: { data: any }) => {
    const { SearchItem: BaseSearchItem } = getDefaultComponents();
    const { label } = data;

    const newData = {
      ...data,
      label: searchInputTruncateLabel(label, SURVEY_MODAL_INPUT_MIN),
      startIcon: () => <PrimerCompanyLogo size={inner.base06} src={data.logo} whiteBackground />,
    };

    return (
      <BaseSearchItem
        data={newData}
        {...{
          ...props,
          size: SearchSizeVariant.Medium,
          variant: "light",
        }}
      />
    );
  };

  const onHandleSelectedValueChange = (values: { label: string; value: string }[]) => {
    setQuery(values);

    if (options) {
      const searchedCompanies = values.map((value) => {
        return {
          cdsAlphaCompanyId:
            options.find((option) => option.primaryName === value.label)?.cdsAlphaCompanyId ||
            defaultCompanies?.find((company) => company.companyName === value.label)?.cdsAlphaCompanyId ||
            "",
          companyName: value.label,
        };
      });

      onChangeProperties(searchedCompanies);
    }
  };

  return (
    <Search
      dataTestId={dataTestId}
      isError={!!errorMessage}
      errorMessage={errorMessage}
      height={inner.base08}
      debounceSearch={true}
      variant={SearchVariant.Complex}
      placeholder="Add companies..."
      query={query}
      components={{ SearchItem }}
      loadOptions={loadSearchOptions}
      onChange={onHandleSelectedValueChange}
      size={SearchSizeVariant.Medium}
      autoHideSearchIcon
      isMultiLine
    />
  );
};

export default SurveyCompanySearch;
