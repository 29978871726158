import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCommentExpert } from "./hooks";
import { OverlayContainer } from "./Overlays.styled";

export const MessagesOverlay = ({ interaction }: { interaction: Interaction }) => {
  const { hasNewMessages, onClick } = useCommentExpert(interaction);
  const { spacing } = useThemeTokens();

  if (hasNewMessages) return null;

  return (
    <OverlayContainer data-testid="messages-overlay-container" data-advisor={interaction.advisorName}>
      <x.div>
        <Button
          marginLeft={spacing.layout.base03}
          padding={spacing.inner.base}
          variant="ghost"
          size="medium"
          onClick={onClick}
        >
          Add comment
        </Button>
      </x.div>
    </OverlayContainer>
  );
};
