import { useDeliverableContext } from "providers/DeliverableProvider";
import { useEffect, useMemo, useState } from "react";
import { getAvailableTranscripts } from "../helpers";
import interpolateMatchingSentences from "./interpolateMatchingSentences";
import { SpeechTranscript } from "./SpeechTranscript";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitOrigin } from "@alphasights/portal-api-client";

export const RegularReadAlongTranscript = ({
  interaction,
  transcriptRequest,
  recording,
  regularSentences,
  canPlayAudio,
  currentTime,
  onCueClick,
  setActiveCues,
  duration,
  fragmentIds,
  showPlayer,
  ...props
}) => {
  const { logHit } = useTrackUserAction();

  const [interpolatedSentences, setInterpolatedSentences] = useState(regularSentences);

  const [interpolatedMatches, setInterpolatedMatches] = useState(false);

  const { fetchDeliverable } = useDeliverableContext();

  const aiTranscript = useMemo(() => {
    return getAvailableTranscripts(recording).find(({ transcriptType }) => transcriptType === "ai");
  }, [recording]);

  useEffect(() => {
    if (!aiTranscript) {
      setInterpolatedSentences(regularSentences);
      return;
    }

    fetchDeliverable(aiTranscript, []).then(({ sentences: aiSentences }) => {
      const [interpolatedSentences, match] = interpolateMatchingSentences(regularSentences, aiSentences, duration);

      logHit({
        origin: HitOrigin.DeliverablesView,
        action: "REGULAR_TRANSCRIPT_READALONG",
        advisorshipId: interaction.id,
        projectToken: interaction.projectToken,
        details: {
          match,
        },
        references: {
          transcriptId: transcriptRequest.id,
          recordingId: recording.id,
        },
      });

      match && setInterpolatedSentences(interpolatedSentences);
      setInterpolatedMatches(match);
    });
  }, [aiTranscript, regularSentences]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SpeechTranscript
      sentences={interpolatedSentences}
      canPlayAudio={canPlayAudio}
      currentTime={currentTime}
      onCueClick={interpolatedMatches && !!aiTranscript && onCueClick}
      setActiveCues={setActiveCues}
      fragmentIds={fragmentIds}
      showPlayer={showPlayer}
      {...props}
    />
  );
};
