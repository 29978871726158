import { FC } from "react";
import { PrimerCardsProps, PrimerDataProps } from "./types";
import { AlphaNowSRMProductType } from "@alphasights/client-portal-shared";
import { primerDescription } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/PrimerSection/consts";
import BasePrimerCard from "components/PrimerCard";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { getContentTitle } from "content/AlphaNow/utils";
import { disabledPrimerCardTitle } from "../../../consts";

const getPrimerDescription = (productType: AlphaNowSRMProductType, numExpertContributors?: number) =>
  primerDescription(productType, numExpertContributors);

const getPrimerLogos = (primerData: PrimerDataProps) => {
  const { productType, companyLogos, primer } = primerData;

  switch (productType) {
    case AlphaNowSRMProductType.companyPrimer:
      const companyPrimerLogo = primer?.logoLink.value;

      return companyPrimerLogo ? [companyPrimerLogo] : [];
    case AlphaNowSRMProductType.marketPrimer:
    case AlphaNowSRMProductType.customerPrimer:
      return companyLogos ?? [];
    default:
      return [];
  }
};

const PrimerCard: FC<PrimerCardsProps> = ({ primerData, primerProductType, onClick }) => {
  const { hasOnlyPremiumContent } = useAlphaNowContentAccessLevelContext();

  if (!primerData) {
    return (
      <BasePrimerCard
        primerType={primerProductType}
        description={getPrimerDescription(primerProductType)}
        title={disabledPrimerCardTitle}
        handleOnClick={() => onClick(undefined, primerProductType)}
        disabled
      />
    );
  }

  const {
    id,
    productType,
    speakers,
    purchaseStatus,
    approvalStatus,
    clientBookmark,
    companies,
    externalTitle,
  } = primerData;
  const handleClick = () => onClick(id);

  return (
    <BasePrimerCard
      primerType={productType}
      title={getContentTitle(productType, companies, externalTitle)}
      description={getPrimerDescription(productType, speakers.length)}
      logos={getPrimerLogos(primerData)}
      handleOnClick={handleClick}
      showPurchasedFlag={!hasOnlyPremiumContent && isContentAccessible(purchaseStatus, approvalStatus)}
      isBookmarked={clientBookmark !== null}
    />
  );
};

export { PrimerCard as default };
