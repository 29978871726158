import { Location } from "history";
import { NavigateFunction } from "router-utils";

type AppliedFilters = { [key: string]: string[] };

export const fromString = (string: string) => {
  return string
    .split("|")
    .map((pair) => pair.split("="))
    .filter(([key, value]) => !!value)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value.split(",").map(decodeURIComponent),
      }),
      {}
    );
};

export const toString = (filters: AppliedFilters) => {
  return Object.entries(filters)
    .filter(([key, value]) => (Array.isArray(value) ? value.length > 0 : !!value))
    .map(([key, value]) =>
      Array.isArray(value) ? `${key}=${value.map(encodeURIComponent).join(",")}` : `${key}=${encodeURIComponent(value)}`
    )
    .join("|");
};

export const getFiltersFromURL = (url: Location) => {
  const filterString = new URLSearchParams(url.search).get("f");
  const filterHash = fromString(filterString || "");
  return Object.keys(filterHash).length === 0 ? null : filterHash;
};

export const pushFiltersToURL = (
  navigate: NavigateFunction,
  url: Location,
  replace: boolean | undefined,
  filters: AppliedFilters
) => {
  const searchParams = new URLSearchParams(url.search);
  const filtersString = toString(filters);
  filtersString ? searchParams.set("f", filtersString) : searchParams.delete("f");
  if (url.search !== searchParams.toString()) {
    navigate(
      {
        search: searchParams.toString(),
      },
      { replace }
    );
  }
};

export const getInteractionIdFromURL = (url: Location) => {
  if (/\w+\/table-view\/new-message$/.test(url.pathname)) {
    return undefined;
  }

  return [
    /\w+\/experts\/(?<route>list-view|table-view|calendar-view|surveys-results-view|messages-view|deliverables-view|survey-view|customer-view|comparison-view)\/(?<id>\w+)/,
    /\w+\/(?<route>calendar-view)\/(?<id>\w+)/,
    /\w+\/workstream\/\w+\/experts\/\w+\/(?<route>list-view|table-view|calendar-view|surveys-results-view|messages-view|deliverables-view|survey-view|customer-view|comparison-view)\/(?<id>\w+)/,
  ]
    .map((regex) => regex.exec(url.pathname)?.groups?.id)
    .find(Boolean);
};

export const buildUrlWithFilters = (url: string, filters: AppliedFilters) => {
  const filtersString = toString(filters);
  return `${url}${filtersString ? `?f=${encodeURIComponent(filtersString)}` : ""}`;
};
