import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const QuestionContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: spacing.inner.base06,
  };
});

export const StyledTypography = styled(Typography)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.strong._,
  };
});

export const StyledDivider = styled(Divider)(() => {
  const { spacing } = useThemeTokens();
  return {
    marginBlock: spacing.inner.base04,
  };
});
