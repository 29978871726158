import Chart from "react-apexcharts";
import { useChartConfig } from "./useChartConfig";
import { CHART_TYPES, ThreeDimensionalScatterChartKpcAggregated } from "views/SurveysResultsView/api/types";

export type ThreeDimensionalKpcChartProps = {
  results: ThreeDimensionalScatterChartKpcAggregated[];
  selectedChartSegment: number | null;
  setSelectedChartSegment: (index: number) => void;
  chartType: CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC | CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT;
};

const ThreeDimensionalKpcChart = ({
  results,
  selectedChartSegment,
  setSelectedChartSegment,
  chartType,
}: ThreeDimensionalKpcChartProps) => {
  const { values, config } = useChartConfig({ results, selectedChartSegment, setSelectedChartSegment, chartType });

  return (
    <Chart
      options={config as ApexCharts.ApexOptions}
      series={values as ApexAxisChartSeries}
      type="line"
      height={300}
      width="100%"
    />
  );
};
export default ThreeDimensionalKpcChart;
