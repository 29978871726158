import styled from "styled-components";
import tokens from "@alphasights/alphadesign-tokens";

export const TopbarContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
  label {
    padding: ${tokens.spacing.inner.base} ${tokens.spacing.inner.base02};
  }
`;
