import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const SummaryViewContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base02,
    alignItems: "center",
  };
});

export const SummaryContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: spacing.inner.base10,
  };
});

export const ErrorContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: spacing.inner.base10,
    margin: `${spacing.inner.base10} auto`,
  };
});
