import { Location } from "history";
import { isEqual, keys, pick } from "lodash";

const isLocationEqual = (locationOne: Location, locationTwo: Location) => {
  return (
    locationOne.pathname === locationTwo.pathname &&
    locationOne.search === locationTwo.search &&
    isEqual(removeReplaceState(locationOne), removeReplaceState(locationTwo))
  );
};

const removeReplaceState = (location: Location) =>
  pick(
    location.state ?? {},
    keys(location.state).filter((key) => key !== "replace")
  );

export { isLocationEqual };
