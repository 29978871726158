import { projectSynthesis as API, SynthesisModule } from "@alphasights/portal-api-client";
import { addDays, differenceInMinutes, parseISO } from "date-fns";
import { noop } from "lodash";
import { useEffect, useState } from "react";

export const REFRESH_VENDOR_SESSION_KEY = "vendor-synthesis-refresh";

export const useRefreshVendorMentions = ({
  project,
  selectedModule,
  updateSynthesis,
}: {
  project: Project;
  selectedModule?: SynthesisModule;
  updateSynthesis: (updatedModule: SynthesisModule) => void;
}) => {
  const [vendorRefreshing, setVendorRefreshing] = useState(false);

  useEffect(() => {
    if (!selectedModule) return;

    if (selectedModule.contentType !== "VENDORS") return;

    const session = JSON.parse(sessionStorage.getItem(REFRESH_VENDOR_SESSION_KEY) || "{}");

    const lastRefreshStr = session[selectedModule.id] as string;

    const lastRefresh = lastRefreshStr ? parseISO(lastRefreshStr) : addDays(new Date(), -1);

    const difference = differenceInMinutes(new Date(), lastRefresh);

    if (difference < 15) return;

    setVendorRefreshing(true);

    API.refreshMentions(project.token, selectedModule.id)
      .then((updated) => {
        updateSynthesis(updated);
        sessionStorage.setItem(REFRESH_VENDOR_SESSION_KEY, JSON.stringify({ ...session, [updated.id]: new Date() }));
      })
      .catch(noop)
      .finally(() => setVendorRefreshing(false));
  }, [selectedModule?.id, project.token, updateSynthesis]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    vendorRefreshing,
  };
};
