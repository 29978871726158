import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

export const useLoggableAudioPlayer = ({ audioPlayer, interaction, currentTranscript }: any) => {
  const { logHit } = useTrackUserAction();

  return {
    ...audioPlayer,
    controlsProps: {
      ...audioPlayer.controlsProps,
      onTogglePlay() {
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptPlayer,
          advisorshipId: interaction.id,
          projectToken: interaction.projectToken,
          details: {
            playing: !audioPlayer.controlsProps.playing,
            readalong: audioPlayer.transcriptProps.autoScroll,
            seconds: Math.floor(audioPlayer.progressBarProps.value),
            duration: Math.floor(audioPlayer.progressBarProps.duration),
            transcriptType: currentTranscript?.transcriptType,
          },
          references: { transcriptId: currentTranscript?.id },
        });

        audioPlayer.controlsProps.onTogglePlay();
      },
      onClickRefocus: () => {
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptRefocus,
          advisorshipId: interaction.id,
          projectToken: interaction.projectToken,
          details: {
            transcriptType: currentTranscript?.transcriptType,
          },
          references: { transcriptId: currentTranscript?.id },
        });

        audioPlayer.controlsProps.onClickRefocus();
      },
    },
  };
};
