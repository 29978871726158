import { useMemo } from "react";
import { trySingularAngleName } from "../helpers";
import { x } from "@xstyled/styled-components";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { BookmarkSelected } from "@alphasights/alphadesign-icons";
import { CustomHoverContentCard } from "components/CustomHoverContentCard";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";
import { useTimezone } from "providers/TimezoneProvider";
import { MentionsFlag } from ".";

interface TranscriptCardProps {
  interaction: Interaction;
  onSelect: () => void;
  selected: boolean;
}

export const TranscriptCard = ({ interaction, onSelect, selected }: TranscriptCardProps) => {
  const styles = useTranscriptCardStyles();
  const timezone: any = useTimezone();

  const { score: hitCount, bookmarked } = interaction;

  const primaryLineText = `${interaction.companyName} - ${interaction.role}`;
  const secondaryLineText = useMemo(() => {
    const interactionAndAdvisor = `${trySingularAngleName(interaction)} | ${interaction.advisorName}`;
    if (interaction.scheduledCallTime) {
      return `${interactionAndAdvisor} | ${timezone.format(interaction.scheduledCallTime, "d LLL yyyy")}`;
    }
    return interactionAndAdvisor;
  }, [interaction, timezone]);

  return (
    <CustomHoverContentCard selected={selected} onClick={onSelect} data-testid={`transcript-card-${interaction.id}`}>
      <LimitedLinesTypography tooltip={primaryLineText} component={"span"}>
        <Typography variant="body-em" component="span">
          {interaction.companyName}
        </Typography>{" "}
        - {interaction.role}
      </LimitedLinesTypography>

      <x.div {...styles.cardRow}>
        <LimitedLinesTypography variant="body-small" color="secondary" component="span" tooltip={secondaryLineText}>
          {secondaryLineText}
        </LimitedLinesTypography>

        <x.div {...styles.iconsWrapper}>
          {bookmarked && (
            <Icon color="secondary" size="medium" data-testid="transc-card-bookmark">
              <BookmarkSelected />
            </Icon>
          )}
          <MentionsFlag hitCount={hitCount} />
        </x.div>
      </x.div>
    </CustomHoverContentCard>
  );
};

const useTranscriptCardStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return {
    cardRow: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      columnGap: inner.base,
      rowGap: inner.base,
      justifyContent: "space-between",
    },
    iconsWrapper: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
    },
  };
};
