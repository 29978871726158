import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import showdown from "showdown";

import { ChevronRight } from "@alphasights/alphadesign-icons";
import { IconButton, Typography } from "@alphasights/alphadesign-components";

import { logTimeSpent, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

import {
  addKeywordToSearch,
  errorLoadingTranscript,
  noResultsFound,
  searchForMentions,
  keywordNotMentioned,
} from "content/AlphaNow";
import { SPEAKER_ENGLISH_TRANSLATIONS } from "constants/AlphaNow";
import { removeTabs } from "content/AlphaNow/utils";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import SpeakerIcon from "pages/AlphaNowPage/components/AlphaNowTranscript/SpeakerIcon";
import "../../index.css";
import ResearchContainer from "components/ResearchContainer";
import { MentionContainer, MentionTextContainer, StyledDivider } from "./MentionsView.styled";

showdown.extension("remove-encapsulator-paragraphs", function () {
  return [
    {
      type: "output",
      filter: (text) => text.replace(/<\/?p[^>]*>/g, ""),
    },
  ];
});

const MentionsView = ({
  contentId,
  data,
  isSuccess,
  keywords,
  viewInTranscript,
  isConversation,
  speakers,
  containerWidth,
  contentType,
}) => {
  const { logHit } = useTrackUserAction();
  const maxScrollPercentage = useRef(0);

  const [mentions, setMentions] = useState([]);

  const converter = new showdown.Converter({
    extensions: ["remove-encapsulator-paragraphs"],
  });
  const { results, total } = data;
  const hasKeywords = keywords.length > 0;
  const hasMultipleKeywords = keywords.length > 1;
  const keywordLabels = keywords.map((word) => word.label).join(", ");
  let mentionIndex = -1;

  const mentionsNotFound = `The word${hasMultipleKeywords ? `s` : ``} ${keywordLabels} ${
    hasMultipleKeywords ? `have` : `has`
  } ${keywordNotMentioned}`;

  const countMentionIndex = (node) => {
    // mentions come from the search api with an <em> tag
    node.type === "tag" && node.name === "em" && mentionIndex++;
  };

  const handleMentionSelect = (currentTarget) => {
    viewInTranscript(currentTarget);

    logHit({
      origin: HitOrigin.alphaNow,
      action: HitAction.alphaNowViewMention,
      details: { contentId },
    });
  };

  useEffect(() => {
    const mentionsData = isConversation
      ? results
      : results && results.reduce((acc, result) => Object.assign(acc, result.highlight.transcript_content), []);
    if (mentionsData) {
      mentionsData.sort((mentionOne, mentionTwo) => mentionOne.position - mentionTwo.position);
      setMentions(mentionsData);
    }
  }, [data, isConversation, results]);

  useEffect(() => {
    const startTime = Date.now();
    const scrollPercentage = maxScrollPercentage.current;
    const timeSpentAction = HitAction.alphaNowTimeSpentOnMentionsView;
    const scrollAction = HitAction.alphaNowMentionsViewScrollPercentage;

    return () => {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenMentionsView,
        details: { contentId },
      });

      logTimeSpent(startTime, { contentId, userId: "" }, logHit, timeSpentAction);

      logScrollPercentage(
        logHit,
        {
          contentId,
          scrollPercentage,
          userId: "",
        },
        scrollAction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSuccess) {
    return <ErrorMessage header={errorLoadingTranscript} />;
  }

  if (!hasKeywords) {
    return <ErrorMessage header={searchForMentions} body={addKeywordToSearch} />;
  }

  if (!total) {
    return <ErrorMessage header={noResultsFound} body={mentionsNotFound} />;
  }

  return (
    <ResearchContainer>
      {mentions.map((mention, index) => {
        const isLastMention = index === mentions.length - 1;
        const str = `“${removeTabs(isConversation ? mention.speechContent : mention)}”`;
        const speakerInfo =
          isConversation &&
          speakers.find(
            (s) =>
              s.transcriptParticipantRole === mention.participant ||
              s.transcriptParticipantRole === SPEAKER_ENGLISH_TRANSLATIONS[mention.participant]
          );
        return (
          <div key={index}>
            <MentionContainer>
              <MentionTextContainer>
                {isConversation && (
                  <SpeakerIcon
                    speaker={speakerInfo}
                    tooltip
                    containerWidth={containerWidth}
                    contentType={contentType}
                    isMentionsView={true}
                  />
                )}
                <Typography
                  className="mentions-container"
                  data-testid={`mention-text-${index}`}
                  maxW={{ xs: "none", sm: "550px" }}
                >
                  {ReactHtmlParser(converter.makeHtml(str), {
                    transform: countMentionIndex,
                  })}
                </Typography>
              </MentionTextContainer>
              <IconButton
                dataAttributes={{
                  "data-testid": `select-mention-${index}-button`,
                  "data-mention-index": mentionIndex,
                }}
                onClick={({ currentTarget }) => {
                  handleMentionSelect(currentTarget);
                }}
                size="small"
                variant="outline"
                data-mention-index={mentionIndex}
              >
                <ChevronRight />
              </IconButton>
            </MentionContainer>
            {!isLastMention && <StyledDivider />}
          </div>
        );
      })}
    </ResearchContainer>
  );
};

MentionsView.defaultProps = {
  data: {},
  isSuccess: false,
  keywords: [],
  viewInTranscript: () => {},
};

MentionsView.propTypes = {
  data: PropTypes.shape({
    total: PropTypes.number,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        highlight: PropTypes.shape({
          transcript_content: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
  }),
  isSuccess: PropTypes.bool,
  keywords: PropTypes.arrayOf(PropTypes.shape()),
  viewInTranscript: PropTypes.func,
  isConversation: PropTypes.bool,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      jobTitle: PropTypes.string,
      company: PropTypes.string,
      transcriptParticipantRole: PropTypes.string,
    })
  ),
};

export { MentionsView as default };
