import { FC, useEffect, useRef, useState } from "react";
import { Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

import { isEllipsisActive } from "../utils";

type WrapperCellProps = {
  text: string;
  testId: string;
  textColor?: string;
};

const TruncatedTextCell: FC<WrapperCellProps> = ({ text, textColor, testId }) => {
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);

  const { color } = useThemeTokens();

  const ref = useRef(null);

  useEffect(() => {
    setIsTooltipDisabled(ref.current ? !isEllipsisActive((ref.current as unknown) as HTMLElement) : false);
  }, [ref]);

  return (
    <Tooltip title={text} position="bottom" variant="dark" disabled={isTooltipDisabled} size="small">
      <Typography
        data-testid={testId}
        ref={ref}
        variant="body-small"
        cursor={isTooltipDisabled ? "default" : "pointer"}
        color={textColor || color.text.strong._}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTextCell;
