const PLACEHOLDER = {
  expertCompanyName: "[Expert Company]",
  expertPosition: "[Expert Position]",
  title: "[Document Title]",
  multiExpertCompanyName: "[Company Name]",
};

const UPLOADED = "Uploaded";

export { PLACEHOLDER, UPLOADED };
