import { IconButton } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import { useExpertFiltersStyles } from "./ExpertFilters.styles";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { Close } from "@alphasights/alphadesign-icons";
import { FilterChip } from "./FilterChip";
import { FilterItem } from "./FilterItem";
import { Project } from "@alphasights/portal-api-client";

type AppliedFilters = { [key: string]: string[] };

type ExpertFiltersProps = {
  onSubmitFilters: (filters: AppliedFilters) => void;
  onToggleFilter: () => void;
  workstream?: Workstream;
  isDoubleBlindedSurvey?: boolean;
  onResetAllFilters?: () => void;
  isSurveyPage?: boolean;
  currentView?: string;
  searchQuery?: string;
  isCompact?: boolean;
};

type ActualFilterOptions = {
  groups?: FilterOption[];
  recency?: FilterOption[];
  companies?: FilterOption[];
  date_added?: FilterOption[];
  profile_activity?: FilterOption[];
  availability?: FilterOption[];
  geographies_hierarchical?: FilterOption[];
  geographies?: FilterOption[];
};

export const ExpertFilters = ({
  onSubmitFilters,
  onToggleFilter,
  workstream = undefined,
  isDoubleBlindedSurvey = false,
  onResetAllFilters = () => {},
  isSurveyPage = false,
  currentView = "",
  searchQuery = "",
  isCompact = false,
}: ExpertFiltersProps) => {
  const {
    state: { appliedFilters, filterOptions: options },
  } = useProjectInteractionsContext() as {
    state: { appliedFilters: AppliedFilters; filterOptions: ActualFilterOptions };
  };
  const { project } = useCurrentProjectContext();
  const { containerStyles, listStyles } = useExpertFiltersStyles();

  const isComparisonView = currentView === "comparison-view";
  const filterOptions = filterDefinitions({
    options,
    appliedFilters,
    project: project!,
    workstream,
    isDoubleBlindedSurvey,
    isSurveyPage,
    isComparisonView,
  });
  const surveyAndCallFilters =
    filterOptions.find((o) => o.name === "survey_response_status") && filterOptions.find((o) => o.name === "status");
  const genericFilters = !surveyAndCallFilters
    ? filterOptions
    : filterOptions.filter((o) => !["status", "survey_response_status"].includes(o.name));
  const onSubmitFiltersCallback = (newFilters: AppliedFilters) =>
    onSubmitFilters({
      ...appliedFilters,
      ...newFilters,
    });
  const hasAppliedFilter =
    Object.keys(appliedFilters)
      .filter((key) => !isComparisonView || key !== "groups")
      .map((key) => appliedFilters[key])
      .some((filter) => filter?.length > 0) || searchQuery !== "";

  return (
    <x.div {...containerStyles}>
      <x.ul {...listStyles}>
        {!isCompact ? (
          <>
            {surveyAndCallFilters && (
              <SurveyAndCallStatusItem
                appliedFilters={appliedFilters}
                onSubmitFiltersCallback={onSubmitFiltersCallback}
                filterOptions={filterOptions}
              />
            )}
            {genericFilters.map((option, index) => (
              <FilterItem
                key={`item-${index}`}
                filterDefinition={option}
                onApplyFilter={(values) =>
                  onSubmitFiltersCallback({
                    [option.name]: values,
                  })
                }
              />
            ))}
            {!isDoubleBlindedSurvey && (
              <FilterChip
                title="More Filters"
                onClick={onToggleFilter}
                appliedFilters={getActualAppliedFilters({
                  appliedFilters,
                  includeAllFilters: isComparisonView,
                  isComparisonView,
                })}
                testId="toggle-more-filters"
              />
            )}
          </>
        ) : (
          <FilterChip
            title="Filters"
            onClick={onToggleFilter}
            appliedFilters={getActualAppliedFilters({ appliedFilters, includeAllFilters: true, isComparisonView })}
            testId="toggle-more-filters"
          />
        )}
        {hasAppliedFilter && (
          <x.li display="flex" alignItems="center">
            <ClearAllButton onClick={onResetAllFilters} />
          </x.li>
        )}
      </x.ul>
    </x.div>
  );
};

const ClearAllButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton testId="clear-all-button" variant="ghost" color="strong" onClick={onClick} size="small">
    <Close />
  </IconButton>
);

export const mergeStatusOptions = (
  filterOptions: FilterDefinition[],
  appliedFilters: AppliedFilters
): FilterDefinition => {
  const surveyValues = appliedFilters.survey_response_status?.map((v) => `survey+${v}`) || [];
  const callValues = appliedFilters.status?.map((v) => `call+${v}`) || [];
  return {
    title: "Status",
    type: "checkbox",
    name: "custom_status",
    options: [
      { label: "Survey Status", elementType: "textOnly", value: "" },
      ...(filterOptions
        .find((o) => o.name === "survey_response_status")
        ?.options?.map((v) => ({ ...v, value: `survey+${v.value}` })) ?? []),
      { label: "", elementType: "separator", value: "" },
      { label: "Follow up Call Status", elementType: "textOnly", value: "" },
      ...(filterOptions
        .find((o) => o.name === "status")
        ?.options?.map((v) => ({
          ...v,
          value: `call+${v.value === "proposed" ? "follow_up_available" : v.value}`,
        })) ?? []),
    ],
    value: [...surveyValues, ...callValues],
  };
};

const SurveyAndCallStatusItem = ({
  onSubmitFiltersCallback,
  appliedFilters,
  filterOptions,
}: {
  appliedFilters: AppliedFilters;
  onSubmitFiltersCallback: (newFilters: AppliedFilters) => void;
  filterOptions: FilterDefinition[];
}) => {
  const { allInteractionsForProject } = useProjectInteractionsContext();

  const hasCompletedSurvey = allInteractionsForProject.find(
    (i: Interaction) => i.surveyResponse?.state === "completed"
  );
  const surveyResponseOption = filterOptions.find((o) => o.name === "survey_response_status");
  if (!hasCompletedSurvey && surveyResponseOption) {
    return (
      <FilterItem
        filterDefinition={{ ...surveyResponseOption, title: "Status" }}
        onApplyFilter={(values) =>
          onSubmitFiltersCallback({
            [surveyResponseOption.name]: values,
          })
        }
      />
    );
  }

  const mergedStatusOption = mergeStatusOptions(filterOptions, appliedFilters);

  return (
    <FilterItem
      filterDefinition={mergedStatusOption}
      onApplyFilter={(values) =>
        onSubmitFiltersCallback({
          status: values.filter((v) => v.startsWith("call+")).map((v) => v.replace("call+", "")),
          survey_response_status: values.filter((v) => v.startsWith("survey+")).map((v) => v.replace("survey+", "")),
        })
      }
    />
  );
};

const moreFilters = ["geographies", "availability", "profile_activity"];

type filterDefinitionProps = {
  options: ActualFilterOptions;
  appliedFilters: AppliedFilters;
  project: Project;
  moreFilters?: boolean;
  workstream?: Workstream;
  isDoubleBlindedSurvey?: boolean;
  isSurveyPage?: boolean;
  isComparisonView?: boolean;
};
export const filterDefinitions = ({
  options = {},
  appliedFilters,
  project,
  moreFilters = false,
  workstream,
  isDoubleBlindedSurvey = false,
  isSurveyPage = false,
  isComparisonView = false,
}: filterDefinitionProps) => {
  const filterOptions: FilterDefinition[] = [];

  if (project.active && workstream?.workstreamType === "survey") {
    const surveyResponseStatusOptions: FilterDefinition = {
      title: "Survey Status",
      type: "checkbox",
      name: "survey_response_status",
      options: [
        { value: "available", label: "Available" },
        { value: "requested", label: "Requested" },
        { value: "sent", label: "Sent" },
        { value: "partially_completed", label: "Partially Completed" },
        { value: "answered", label: "Answered" },
        { value: "quota_met", label: "Quota Met" },
        { value: "completed", label: "Completed" },
      ],
      value: appliedFilters?.survey_response_status || [],
    };
    filterOptions.push(surveyResponseStatusOptions);
  }

  if (project.active) {
    const statusOptions: FilterDefinition = {
      title: "Status",
      type: "checkbox",
      name: "status",
      options: [
        { value: "proposed", label: "Available" },
        { value: "requested", label: "Requested" },
        { value: "scheduled", label: "Scheduled" },
        { value: "completed", label: "Completed" },
      ],
      value: appliedFilters?.status || [],
    };
    filterOptions.push(statusOptions);
  }

  if (!isComparisonView) {
    filterOptions.push({
      title: "Angle",
      type: "checkbox",
      name: "groups",
      value: appliedFilters.groups ?? [],
      options: options.groups ?? [{ label: "Loading...", value: "loading" }],
    });
  }

  if (!isDoubleBlindedSurvey) {
    const recencyOptions: FilterDefinition = {
      title: "Recency",
      type: "checkbox",
      name: "recency",
      value: appliedFilters.recency || [],
      options: options.recency
        ? [
            { label: "Current", value: "Current" },
            { label: "Former:", elementType: "textOnly", value: "" },
            ...options.recency,
          ]
        : [],
    };
    filterOptions.push(recencyOptions);
  }

  if (!isDoubleBlindedSurvey) {
    filterOptions.push({
      title: "Company",
      type: "checkbox",
      name: "companies",
      value: appliedFilters.companies ?? [],
      options: options.companies ?? [{ label: "Loading...", value: "loading" }],
    });
  }

  if (project.active) {
    const dateAddedOptions: FilterDefinition = {
      title: "Date Added",
      type: "checkbox",
      name: "date_added",
      value: appliedFilters.date_added || [],
      options: options.date_added || [],
    };
    filterOptions.push(dateAddedOptions);
  }

  if (project.enablePortalComments && !isComparisonView && project.active) {
    const commentsOption: FilterDefinition = {
      title: "Comments",
      type: "checkbox",
      name: "comments",
      value: appliedFilters.comments || [],
      options: [
        {
          label: "Comments",
          value: "commented",
          autoSelect: ["unread"],
        },
        { label: "Unread Comments", value: "unread" },
      ],
    };

    if (moreFilters || isSurveyPage) filterOptions.push(commentsOption);
  }

  if (moreFilters) {
    if (project.active) {
      const profileActivityOptions: FilterDefinition = {
        title: isComparisonView ? "Starred" : "Profile Activity",
        type: "checkbox",
        name: "profile_activity",
        value: appliedFilters.profile_activity || [],
        options: (options.profile_activity ?? []).filter((o) => !isComparisonView || o.value === "Starred"),
      };
      filterOptions.push(profileActivityOptions);
    }

    if (project.active && options.availability) {
      const availabilityOption: FilterDefinition = {
        title: "Available for Instant Schedule",
        type: "checkbox",
        name: "availability",
        value: appliedFilters.availability || [],
        options: options.availability,
      };

      filterOptions.push(availabilityOption);
    }

    const renderNestedGeographies = (options.geographies_hierarchical?.length ?? 0) > 0 && !isComparisonView;
    const renderGeographies =
      !renderNestedGeographies && options.geographies && options.geographies.length > 0 && !isComparisonView;

    if (project.active && renderNestedGeographies && options.geographies_hierarchical) {
      const geographyNestedOption: FilterDefinition = {
        title: "Geographic Knowledge",
        type: "checkbox",
        name: "geographies",
        value: appliedFilters.geographies || [],
        options: options.geographies_hierarchical,
      };

      filterOptions.push(geographyNestedOption);
    }

    if (project.active && renderGeographies && options.geographies) {
      const geographyOption: FilterDefinition = {
        title: "Geographic Knowledge",
        type: "checkbox",
        name: "geographies",
        value: appliedFilters.geographies || [],
        options: options.geographies,
      };

      filterOptions.push(geographyOption);
    }
  }

  const filtersOrder = isComparisonView
    ? ["availability", "status", "recency", "date_added", "profile_activity", "companies"]
    : [];
  const sortedFilters = filterOptions.sort((a, b) => filtersOrder.indexOf(a.name) - filtersOrder.indexOf(b.name));

  return isComparisonView && !moreFilters ? [] : sortedFilters;
};

export const getActualAppliedFilters = ({
  appliedFilters,
  includeAllFilters,
  isComparisonView = false,
}: {
  appliedFilters: AppliedFilters;
  includeAllFilters: boolean;
  isComparisonView: boolean;
}) => {
  const appliedMoreFilters = Object.keys(appliedFilters)
    .filter((filterKey) => !isComparisonView || filterKey !== "groups")
    .flatMap((filterKey) => {
      if (includeAllFilters || moreFilters.includes(filterKey)) {
        return appliedFilters[filterKey];
      }
      return [];
    });

  return appliedMoreFilters;
};
