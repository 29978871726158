export const getTruncatedTextAndTooltip = (text: CitableValue<string> | string, maxLength: number) => {
  if (!text) return [null, null];

  if (typeof text === "string") {
    let name = text;
    let tooltip = null;

    if (text && text.length > maxLength) {
      tooltip = text;
      name = `${text.substring(0, maxLength)}...`;
    }

    return [name, tooltip];
  } else {
    const name = { ...text };
    let tooltip = null;

    if (text.value && text.value.length > maxLength) {
      tooltip = text.value;
      name.value = `${text.value.substring(0, maxLength)}...`;
    }

    return [name, tooltip];
  }
};
