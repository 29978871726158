import { Avatar } from "@alphasights/alphadesign-components";
import { PreferencesModal } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useTimezone } from "providers/TimezoneProvider";
import { useCallback, useRef, useState } from "react";
import { AvatarContainer, AvatarOptionWrapper } from "components/SidebarNav/SidebarNav.styled";
import { CustomBadge } from "components/CustomBadge";
import { Expert } from "@alphasights/alphadesign-icons";
import { AvatarMenu, useAvatarMenuOptions } from "./AvatarMenu";
import WatchlistModal from "components/WatchlistModal";
import useWatchlist from "hooks/useWatchlist";
import { usePreference } from "hooks/usePreference";
import { PreferenceType } from "providers/types";
import { first } from "lodash";
import { useUserInitialsMenu } from "./AvatarOptions.styles";

type AvatarOptionProps = {
  closeIntercom?: () => void;
};

export const AvatarOption = ({ closeIntercom }: AvatarOptionProps) => {
  const user = useCurrentUser() as
    | (User & {
        internalUser?: { clientProfileId: number };
      })
    | undefined;
  const [isPreferenceModalOpen, setIsPreferenceModalOpen] = useState(false);
  const [isWatchListModalOpen, setIsWatchlistModalOpen] = useState(false);
  const { logOutCurrentUser, openLoginModal } = useClientPortalOperations();
  const { loggedInAsWrapper } = useUserInitialsMenu();
  const isInternalUser = !!user?.internalUser?.clientProfileId;
  const timezoneCtx = useTimezone();
  const { updatePreference } = usePreference(PreferenceType.TIMEZONE);
  const ref = useRef(null);
  const [open, setIsOpen] = useState(false);
  const { availableWatchlists, selectedWatchlistId, setSelectedWatchlistId } = useWatchlist();

  const onPreferencesClick = () => setIsPreferenceModalOpen(true);
  const onWatchlistClick = () => setIsWatchlistModalOpen(true);
  const closeMenu = () => setIsOpen(false);

  const options = useAvatarMenuOptions({ onWatchlistClick, onPreferencesClick });
  const onUpdateTimezone = useCallback(
    (timezone: string) => {
      timezoneCtx.setCurrentTimezone(timezone);
      return updatePreference({ timezone });
    },
    [timezoneCtx, updatePreference]
  );
  const handleAvatarClick = useCallback(() => {
    if (user) {
      closeIntercom?.();
      options.length === 1 ? first(options)!.onChange() : setIsOpen((prev) => !prev);
    } else {
      openLoginModal();
    }
  }, [closeIntercom, openLoginModal, options, user]);

  return (
    <>
      <AvatarOptionWrapper showBackground={isInternalUser} ref={ref}>
        {isInternalUser && <CustomBadge tooltipText={`Viewing as ${user?.name}`} {...loggedInAsWrapper} />}
        <AvatarContainer onClick={handleAvatarClick}>
          <Avatar size="medium" text={user?.name} children={user?.name ? undefined : <Expert />} data-testid="avatar" />
        </AvatarContainer>
      </AvatarOptionWrapper>
      {ref.current && open && (
        <AvatarMenu
          onPreferencesClick={onPreferencesClick}
          onWatchlistClick={onWatchlistClick}
          onClick={closeIntercom}
          anchorElement={ref.current}
          onClose={closeMenu}
          data-testid="avatar-menu"
        />
      )}
      {isPreferenceModalOpen && (
        <PreferencesModal
          isOpen
          onClose={() => setIsPreferenceModalOpen(false)}
          onLogout={logOutCurrentUser}
          timezone={timezoneCtx.currentTimezone}
          onUpdateUser={(field: string, value: string) =>
            field === "timezone" ? onUpdateTimezone(value) : Promise.resolve()
          }
        />
      )}
      {isWatchListModalOpen && (
        <WatchlistModal
          onClose={() => setIsWatchlistModalOpen(false)}
          availableWatchlists={availableWatchlists}
          selectedWatchlistId={selectedWatchlistId}
          setSelectedWatchlistId={setSelectedWatchlistId}
        />
      )}
    </>
  );
};
