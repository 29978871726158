import styled from "styled-components";
import { th } from "@xstyled/styled-components";

export const PhoneInputWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid;
  width: 100%;
  border-color: ${({ hasError }) => (hasError ? th.color("border-danger") : th.color("border-neutral-default"))};
`;

export const CountrySelector = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding: ${th.space("inner-base02")};
`;

export const CountrySearchIconWrapper = styled.div`
  margin-left: ${th.space("layout-base02")};
  cursor: pointer;
`;

export const PhoneNumberInput = styled.input`
  width: 100%;
  margin-left: ${th.space("layout-base")};
  padding: ${th.space("inner-base02")};
  height: 100%;
  color: ${th.color("text-assistive")};
  font-size: ${th.fontSize("02")};
  font-weight: ${th.fontWeight("semiBold")};
`;

export const ExtensionFieldWrapper = styled.div`
  width: 25%;
  margin-left: ${th.space("layout-base02")};
`;

export const ExtensionInput = styled.input`
  width: 100%;
  border-radius: ${th.radius("2")};
  border: 1px solid;
  border-color: ${th.color("border-neutral-default")};
  padding: ${th.space("inner-base04")};
  height: 100%;
  font-size: ${th.fontSize("02")};
`;

export const ErrorMessage = styled.div`
  display: flex;
  color: ${th.color("text-danger")};
  font-size: ${th.fontSize("02")};
  margin-top: ${th.space("layout-base05")};
`;

export const SearchModeWrapper = styled.div`
  position: absolute;
  z-index: 50;
  ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? "width: 100%; padding-right: th.space(inner-base04);" : "width: 33%;"}
`;
