import { createContext, useEffect, useCallback, FC, PropsWithChildren } from "react";
import { PortalStoreProvider, PortalStoreProviderProps } from "@alphasights/portal-auth-react";
import { initialState, useClientPortalReducer } from "app/reducers/ClientPortal.reducer";
import { noop } from "@alphasights/ads-community-utils";
import { isBefore, parseISO } from "date-fns";
import userflow from "userflow.js";
import LogRocket from "logrocket";
import { redirectToSignIn } from "utils/redirect-utils";
import { RequestError, User } from "@alphasights/portal-api-client";
import { useNotifications, useEnv } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { FeatureFlagsWrapper } from "app/wrappers/FeatureFlagsWrapper";
import { useLogger } from "logging/Logger";

export type ClientPortalContextType = ReturnType<typeof useClientPortalReducer>;

export const ClientPortalContext = createContext<ClientPortalContextType>({
  state: initialState,
  actions: {
    setIsModalOpen: noop,
    setModalExtraProps: noop,
    setIsReady: noop,
    setSignInConfiguration: noop,
  },
});
const ClientPortalProvider = ClientPortalContext.Provider;

const ClientPortalWrapperContent: FC<PropsWithChildren<{
  contextValueOverrides?: Partial<ClientPortalContextType>;
}>> = ({ children, contextValueOverrides }) => {
  const contextValue = useClientPortalReducer();

  const { state, actions } = contextValue;
  const { signInConfiguration } = state;
  const { setSignInConfiguration } = actions;

  useEffect(() => {
    if (
      signInConfiguration &&
      signInConfiguration.expiration &&
      signInConfiguration.skipSignIn &&
      isBefore(parseISO(signInConfiguration.expiration), new Date())
    ) {
      setSignInConfiguration(null);
    }
  }, [signInConfiguration, setSignInConfiguration]);

  return <ClientPortalProvider value={{ ...contextValue, ...contextValueOverrides }}>{children}</ClientPortalProvider>;
};

export type ClientPortalWrapperProps = PropsWithChildren<{
  portalStoreInitialState?: PortalStoreProviderProps["initialState"];
  contextValueOverrides?: Partial<ClientPortalContextType>;
}>;

export const ClientPortalWrapper: FC<ClientPortalWrapperProps> = ({
  children,
  portalStoreInitialState,
  contextValueOverrides,
}) => {
  const env = useEnv();
  const { isMobile } = useCheckScreen();
  const { showErrorBanner } = useNotifications();
  const logger = useLogger();

  const onRequestError = useCallback(
    (error: Error | RequestError) => {
      if (error instanceof RequestError) {
        switch (error.status) {
          case 401: {
            redirectToSignIn(false);
            break;
          }
          case 403: {
            showErrorBanner("An error has occurred: please try refreshing.");
            break;
          }
        }
      }
    },
    [showErrorBanner]
  );

  const onLoginSuccess = useCallback(
    (currentUser: User) => {
      logger.log("load user success", currentUser);

      if (env?.enableLogRocket && typeof LogRocket !== "undefined") {
        LogRocket.identify(String(currentUser.id), {
          name: currentUser.name,
          email: currentUser.email,
        });
      }

      if (env?.enableUserflow && typeof userflow !== "undefined") {
        const user = currentUser.internalUser ?? currentUser;
        const device = isMobile ? "mobile" : "desktop";
        userflow.identify(String(currentUser.id), {
          name: user.name,
          email: user.email,
          signed_up_at: new Date().toISOString(),
          device_type: device,
        });
      }
    },
    [env, isMobile, logger]
  );

  if (!env) return null;

  return (
    <PortalStoreProvider
      initialState={portalStoreInitialState}
      onLoginSuccess={onLoginSuccess}
      onErrorFetching={onRequestError}
      onErrorDeleting={onRequestError}
      onErrorPosting={onRequestError}
      onErrorPutting={onRequestError}
    >
      <FeatureFlagsWrapper>
        <ClientPortalWrapperContent>{children}</ClientPortalWrapperContent>
      </FeatureFlagsWrapper>
    </PortalStoreProvider>
  );
};
