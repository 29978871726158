import React, { useState } from "react";
import { Close, Search } from "@alphasights/alphadesign-icons";
import { Button } from "@alphasights/alphadesign-components";
import {
  TranscriptActionsContainer,
  TranscriptMobileActionBarContainer,
  TranscriptSearchBarContainer,
} from "./TranscriptMobileActionBar.styled";
import TranscriptSearch from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/TranscriptSearch";
import { TranscriptMobileActionBarProps } from "./types";
import TranscriptTools from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/TranscriptTools";
import ReturnButton from "pages/AlphaNowPage/components/ReturnButton/ReturnButton";

const DataTestIds = {
  searchButton: "search-button",
};

const TranscriptMobileActionBar = ({
  transcriptSearchProps,
  transcriptToolsProps,
  onReturnToMobileSearch,
}: TranscriptMobileActionBarProps) => {
  const [isMobileSearchBarActive, setIsMobileSearchBarActive] = useState(false);

  const handleOpenSearchBar = () => {
    setIsMobileSearchBarActive(true);
  };

  const handleCloseSearchBar = () => {
    setIsMobileSearchBarActive(false);
  };

  if (isMobileSearchBarActive) {
    return (
      <TranscriptSearchBarContainer>
        <TranscriptSearch {...transcriptSearchProps} />
        <Button variant="icon" onClick={handleCloseSearchBar}>
          <Close />
        </Button>
      </TranscriptSearchBarContainer>
    );
  }

  return (
    <TranscriptMobileActionBarContainer>
      <ReturnButton onReturn={onReturnToMobileSearch} variant="ghost" />
      <TranscriptActionsContainer>
        <Button
          variant="icon"
          onClick={handleOpenSearchBar}
          dataAttributes={{ "data-testid": DataTestIds.searchButton }}
        >
          <Search />
        </Button>
        <TranscriptTools {...transcriptToolsProps} />
      </TranscriptActionsContainer>
    </TranscriptMobileActionBarContainer>
  );
};

export { TranscriptMobileActionBar as default, DataTestIds };
