import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const ErrorAlertContainer = styled.div(() => {
  const { spacing, color } = useThemeTokens();

  return {
    display: "inline-flex",
    gap: spacing.inner.base02,
    color: color.text.assistive,
    alignItems: "center",
    paddingBlock: spacing.inner.base02,
    paddingInline: spacing.inner.base04,
  };
});

const StyledIcon = styled(Icon)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.icon.assistive,
  };
});
export { ErrorAlertContainer, StyledIcon };
