import { useEffect, useState } from "react";
import { ActionGroup, IconGroup } from "@alphasights/alphadesign-components";
import { Label } from "@alphasights/alphadesign-icons";
import { ManageLabelsPopover } from "components/InteractionLabel/ManageLabelsPopover";
import { CommentIcon } from "views/AdvisorsTableView/Overlays/CommentIcon";
import { useCommentExpert, useHideExpert, useLabelExpert, useStarExpert } from "./hooks";
import { OverlayContainer } from "./Overlays.styled";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { useLabelsContext } from "providers/LabelsProvider";
import { HitOrigin } from "@alphasights/portal-api-client";

export const InteractionActionsOverlay = ({
  interaction,
  enableLabelling,
  origin,
}: {
  interaction: Interaction;
  enableLabelling?: boolean;
  origin: HitOrigin;
}) => {
  const { locked, starIcon, starTooltip, handleToggleClick } = useStarExpert({ interaction, origin });
  const { hideIcon, hideTooltip, handleToggleHidden } = useHideExpert({ interaction, origin });
  const { labelsDisabled } = useLabelsContext();

  const [currentOpenId, setCurrentOpenId] = useState<string>();
  const { handleClick, open, ref, handlePopoverClose, labelColor } = useLabelExpert(
    interaction,
    setCurrentOpenId,
    currentOpenId
  );
  const { showComments } = useCommentExpert(interaction);
  const isExpertHidden = interaction.hidden;

  const [justToggledHidden, setJustToggledHidden] = useState(false);
  const showLabelsButton = enableLabelling && !labelsDisabled;
  const showStarButton = !isExpertHidden && interaction.isActiveProject;

  useEffect(
    function reset() {
      setJustToggledHidden(false);
    },
    [interaction]
  );

  const actions: IconGroup[] = [
    ...(showLabelsButton
      ? [
          {
            tooltipTitle: "Label",
            icon: <Label data-testid="label-icon" style={{ color: labelColor }} />,
            onClick: handleClick,
          },
        ]
      : []),
    ...(showComments
      ? [
          {
            tooltipTitle: "Add comment",
            icon: <CommentIcon interaction={interaction} />,
          },
        ]
      : []),
    ...(showStarButton
      ? [
          {
            tooltipTitle: starTooltip,
            icon: starIcon,
            onClick: () => !locked && handleToggleClick(interaction.id),
          },
        ]
      : []),
    ...(interaction.isActiveProject
      ? [
          {
            tooltipTitle: hideTooltip,
            icon: hideIcon,
            onClick: (e: React.MouseEvent) => handleToggleHidden(e).then(setJustToggledHidden),
          },
        ]
      : []),
  ];

  if (justToggledHidden || actions.length === 0) return null;

  return (
    <>
      <OverlayContainer interaction-advisor={interaction.advisorName} onMouseEnter={() => setCurrentOpenId(undefined)}>
        <div ref={ref}>
          <ActionGroup iconGroup={actions} />
        </div>
      </OverlayContainer>

      {open && (
        <ManageLabelsPopoverWithLoginWall
          anchorRef={ref}
          open={open}
          onClose={handlePopoverClose}
          interactions={[interaction]}
        />
      )}
    </>
  );
};

const ManageLabelsPopoverWithLoginWall = withLoginWall(ManageLabelsPopover);
