import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Pill, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { DotLabel } from "views/SurveysResultsView/components";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";
import { CompanyDetails, TransformedSurveyMetadata } from "views/SurveysResultsView/api/types";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";

export enum YAxisValueType {
  Text,
  Logo,
  PillColoredCircleAndText,
}
type YAxisProps = {
  labels: {
    values: string[];
    valueType: YAxisValueType;
    align: string;
  }[];
  titles?: React.ReactNode[];
};

type YAxisValueProps = {
  value: string;
  valueType: YAxisValueType;
  companyDetails: CompanyDetails[];
};

const YAxisValue = ({ companyDetails, value, valueType }: YAxisValueProps) => {
  const { color, icons } = useThemeTokens();

  if (valueType === YAxisValueType.Logo) {
    const vendor = companyDetails.find((company) => company.name === value);

    return <PrimerCompanyLogo size={icons.size.lg} src={vendor?.logoUrl} />;
  }

  if (valueType === YAxisValueType.PillColoredCircleAndText) {
    const [dotColor, label] = value.split(",");

    return (
      <Pill>
        <DotLabel dotColor={dotColor} label={label} />
      </Pill>
    );
  }

  // We decided not to use the ADS EllipsisText component here because it has some
  // issues with performance when used with ApexCharts.
  const [truncatedValue, tooltip] = getTruncatedTextAndTooltip(value, 15);

  return (
    <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
      <Typography variant="body-small" color={color.text.strong._}>
        {(truncatedValue as string) ?? ""}
      </Typography>
    </ConditionalTooltip>
  );
};

const YAxis = ({ labels, titles }: YAxisProps) => {
  const { spacing, typography } = useThemeTokens();
  const hasTitles = !!titles;

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { companyDetails } = surveyMetadata as TransformedSurveyMetadata;

  return (
    <x.div position="relative" display="flex" gap={spacing.layout.base02} data-testid="y-axis-container">
      {labels.map(({ values, valueType, align }, index) => (
        <x.div
          key={index}
          flex="0 0 auto"
          display="flex"
          whiteSpace="nowrap"
          flexDirection="column"
          maxWidth="128px"
          data-testid={`y-axis-${index}`}
        >
          {hasTitles && (
            <x.div h={spacing.inner.base05} w="100%">
              {titles?.[index] ?? <>&nbsp;</>}
            </x.div>
          )}
          {values.map((value, i) => (
            <x.div
              key={`${value}-${i}`}
              h={spacing.inner.base10}
              w="100%"
              alignItems="center"
              display="flex"
              justifyContent={align}
              columnGap={spacing.inner.base02}
              {...typography.body.small}
            >
              <YAxisValue value={value} valueType={valueType} companyDetails={companyDetails} />
            </x.div>
          ))}
        </x.div>
      ))}
    </x.div>
  );
};

export default YAxis;
