import React from "react";
import { SelectMenu, SelectOption, SelectValue, Tooltip, useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";

import { Angle, ANGLE_DISPLAY, ANGLE_INFO_TOOLTIP_TEXT } from "views/DeliverablesView/ThirdPartyDocUploadModal";
import { FormFieldLabel } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/constants";

import * as EditDocumentModalStyles from "../EditDocumentModal.styled";
import * as AngleFieldStyles from "./AngleField.styled";

const S = {
  Label: EditDocumentModalStyles.Label,
  ...AngleFieldStyles,
};

const DataTestIds = {
  thirdPartyDocumentAngleField: "third-party-document-angle-field",
};

type AngleFieldProps = {
  onChange: (value: SelectValue) => void;

  value?: Angle;
  dataTestId?: string;
  errorMessage?: string;
};

const AngleField = ({
  value,
  onChange,
  dataTestId = DataTestIds.thirdPartyDocumentAngleField,
  errorMessage,
}: AngleFieldProps) => {
  const { color } = useThemeTokens();

  return (
    <>
      <S.Label>
        <S.TextAndIconWrapper>
          {FormFieldLabel.angle}
          <Tooltip variant="dark" size="small" title={ANGLE_INFO_TOOLTIP_TEXT}>
            <S.StyledInteractiveIcon color={color.icon.strong._} size="x-small">
              <Info />
            </S.StyledInteractiveIcon>
          </Tooltip>
        </S.TextAndIconWrapper>
      </S.Label>
      <SelectMenu
        size="small"
        value={value}
        onChange={(val: SelectValue | SelectValue[]) => onChange(val as SelectValue)}
        error={errorMessage}
        dataAttributes={{ "data-testid": dataTestId }}
      >
        {Object.values(Angle).map((angleOption) => (
          <SelectOption key={angleOption} value={angleOption}>
            {ANGLE_DISPLAY[angleOption]}
          </SelectOption>
        ))}
      </SelectMenu>
    </>
  );
};

export default AngleField;
