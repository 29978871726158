import { useEffect, useMemo, useRef } from "react";
import { default as Content } from "react-content-loader";
import { noop } from "lodash";
import { x } from "@xstyled/styled-components";
import {
  Typography,
  Icon as IconWrapper,
  Button,
  Icon,
  Tooltip,
  useThemeTokens,
  TooltipContent,
  ContentCard,
} from "@alphasights/alphadesign-components";
import { BookmarkSelected, Company, Lock, TickCircleFilled } from "@alphasights/alphadesign-icons";
import { HitAction, HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import {
  CONTENT_TYPE_DISPLAY_NAME,
  AlphaNowProductType,
  useTrackUserAction,
  PageFooter,
} from "@alphasights/client-portal-shared";
import Tag from "components/Tag";
import { FormattedDateTime } from "providers/TimezoneProvider";
import useStyles from "./styles";
import { useEmptyResultsStyle } from "./Cards.style";
import { industryPerspective } from "content/AlphaNow";
import { usePlusMore } from "hooks/usePlusMore";
import { sorterByCriteria } from "./helpers";
import { ProjectSummaryCard } from "./ProjectSummaries/ProjectSummaryCard";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { RecommendedContentCard } from "./RecommendedContentCard/RecommendedContentCard";
import PitchedDetails from "components/PitchedDetails";
import { CardsSidebarHeader } from "./CardsSidebarHeader";
import { TranscriptCard, MentionsFlag } from "./TranscriptCard";
import ThirdPartyDocumentCard from "./ThirdPartyDocumentCard";
import { InteractionOrigin } from "./constants";
import AddDocumentsCard from "./AddDocumentsCard";
import useThirdPartyDocsPermissions from "hooks/useThirdPartyDocsPermissions";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { ENABLE_PAST_PROJECT_DELIVERABLES, useProjectBadgeContext } from "../../providers/BadgeProvider";

export const RECOMMENDED_CONTENT_ID = "recommended-content";
export const PROJECT_SUMMARY_ID = "project-summary";

const SuggestionCard = ({ suggestion, onSelect, selected, appliedKeywords }) => {
  const styles = useStyles();

  const {
    id: contentId,
    isAccessible,
    isPrimer,
    anchorCompanies,
    primerCompanyLogoSrc,
    title,
    externalTitle,
    scheduledTimeUTC,
    bookmarked,
    description,
    contentType,
    productType,
    privateMode,
    pitchedAt,
    keywordHits,
  } = suggestion;

  const isMarketOrCustomerPrimer = [AlphaNowProductType.marketPrimer, AlphaNowProductType.customerPrimer].includes(
    productType
  );

  const Logo = () => {
    if (productType === AlphaNowProductType.companyPrimer && primerCompanyLogoSrc) {
      return <x.img src={primerCompanyLogoSrc} alt={`${title ?? "company"} logo`} {...styles.primerImg} />;
    } else if (isMarketOrCustomerPrimer && suggestion.companyLogos && suggestion.companyLogos.length > 0) {
      return (
        <x.div
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap="2px"
          padding="2px"
          w="100%"
          h="100%"
          gridAutoRows="1fr"
          textAlign="center"
          alignItems="center"
        >
          {suggestion.companyLogos.map((companyLogo) => (
            <x.div
              w="100%"
              h="100%"
              backgroundImage={`url(${companyLogo})`}
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              title={`${title ?? "company"} logo`}
            />
          ))}
        </x.div>
      );
    }

    return (
      <div data-testid="placeholder-icon">
        <Icon size="large" {...styles.primerNoLogo}>
          <Company />
        </Icon>
      </div>
    );
  };

  const defaultProps = {
    anchorCompanies,
    scheduledTimeUTC,
    contentType,
    productType,
    description,
    externalTitle,
    isAccessible,
    bookmarked,
    pitchedAt,
    appliedKeywords,
    title: isMarketOrCustomerPrimer ? externalTitle : title,
    hitCount: keywordHits,
  };

  return (
    <ContentCard {...styles.card} selected={selected} onClick={onSelect} data-testid={`suggested-card-${contentId}`}>
      {isPrimer ? (
        <x.div display="flex">
          <x.div
            data-testid="primer-logo"
            {...(primerCompanyLogoSrc
              ? { backgroundColor: "transparent", ...styles.primerWrapper }
              : styles.primerWrapper)}
          >
            <Logo />
          </x.div>
          <x.div {...styles.primerContent}>
            <SuggestionCardMainContent {...defaultProps} privateMode={privateMode} />
          </x.div>
        </x.div>
      ) : (
        <SuggestionCardMainContent {...defaultProps} />
      )}
    </ContentCard>
  );
};

const SuggestionCardMainContent = ({
  anchorCompanies,
  scheduledTimeUTC,
  title,
  productType,
  isAccessible,
  bookmarked,
  privateMode,
  pitchedAt,
  hitCount,
  appliedKeywords,
}) => {
  const labels = useMemo(
    () => (anchorCompanies.length > 0 ? anchorCompanies.map((company) => company.companyName) : [industryPerspective]),
    [anchorCompanies]
  );

  const { setContainerEl, regularPills, morePill, steady } = usePlusMore({
    labels,
  });

  const containKeywords = useMemo(() => appliedKeywords?.length > 0, [appliedKeywords]);

  const { spacing } = useThemeTokens();
  const styles = useStyles();
  const { project } = useCurrentProjectContext();

  const displayProductType = CONTENT_TYPE_DISPLAY_NAME[productType];
  const productTypeDescription = privateMode ? `Private ${displayProductType}` : displayProductType;

  return (
    <>
      <x.div display="flex" justifyContent="space-between" gap="4px" alignItems="center">
        <x.div ref={setContainerEl} {...styles.cardRow} overflow="hidden" zIndex={steady ? null : -1}>
          {regularPills.map(({ original, text }) => (
            <Tooltip key={original} disabled={original === text} dark>
              <TooltipContent>
                <Typography variant="body-small">{original}</Typography>
              </TooltipContent>
              <Tag name={text} whiteSpace="nowrap" icon={<Company />} />
            </Tooltip>
          ))}
          {morePill.length > 0 && (
            <Tooltip dark>
              <TooltipContent>
                <Typography variant="body-small">{morePill.map(({ original }) => original).join(", ")}</Typography>
              </TooltipContent>
              <Tag name={"+" + morePill.length + " more"} />
            </Tooltip>
          )}
        </x.div>
        <x.div {...styles.cardRowDate}>
          <Typography variant="body-small" color="secondary">
            <FormattedDateTime date={scheduledTimeUTC} format="d LLL yyyy" />
          </Typography>
        </x.div>
      </x.div>
      <x.div {...styles.suggestionCardRow}>
        <Typography variant="body-em">{title}</Typography>
      </x.div>
      <x.div {...styles.cardRow} justifyContent="space-between">
        <Typography variant="body-small" color="secondary">
          {productTypeDescription}
        </Typography>
        <x.div {...styles.cardRow}>
          {privateMode && (
            <Tooltip title="Private research created exclusively for your research">
              <IconWrapper color="secondary" size="medium" data-testid="private-primer">
                <Lock />
              </IconWrapper>
            </Tooltip>
          )}
          {isAccessible && (
            <IconWrapper color="success" size="medium" data-testid="suggestion-purchased">
              <TickCircleFilled />
            </IconWrapper>
          )}
          {bookmarked && (
            <IconWrapper color="secondary" size="medium" data-testid="bookmarked-flag">
              <BookmarkSelected />
            </IconWrapper>
          )}
          {pitchedAt && (
            <PitchedDetails
              pitchedAt={pitchedAt}
              pitchedBy={project.lead}
              stylingProps={{ marginLeft: spacing.inner.base }}
              hasDescription
            />
          )}
          {containKeywords && hitCount > 0 ? <MentionsFlag hitCount={hitCount} /> : null}
        </x.div>
      </x.div>
    </>
  );
};

const DeliverablesCardsList = ({ interactions, currentSelection, onSelect }) => {
  return interactions.map((interaction) => {
    const DeliverableCard =
      interaction.origin === InteractionOrigin.thirdParty ? ThirdPartyDocumentCard : TranscriptCard;
    return (
      <DeliverableCard
        key={`deliverable-card-${interaction.id}`}
        interaction={interaction}
        onSelect={() => onSelect(interaction)}
        selected={currentSelection?.id === interaction.id}
      />
    );
  });
};

export const deliverablesNames = {
  regular: {
    name: "Human Transcript",
  },
  translated: {
    name: "Translated Transcript",
  },
  ai: {
    name: "AI Transcript",
  },
  recording: {
    name: "Recording",
  },
};

const EmptyResult = ({ variant = "deliverables", onClearFilters, hasAppliedFilterOrKeyword }) => {
  const styles = useEmptyResultsStyle();

  const texts = {
    deliverables: {
      title: "No project deliverables have been uploaded",
      subtitle: "Your deliverable will be uploaded as soon as it's ready.",
    },
    research: {
      title: "No research content found for your project",
      subtitle: "Check again later.",
    },
  };

  const textsOnSearch = {
    deliverables: {
      title: "No Results Found",
      subtitle: "Try removing the applied filters or rephrasing your search",
      cta: "Remove All Filters",
    },
    research: {
      title: "No Results Found",
      subtitle: "Try removing the applied filters or rephrasing your search",
      cta: "Remove All Filters",
    },
  };

  const { title, subtitle, cta } = (hasAppliedFilterOrKeyword ? textsOnSearch : texts)[variant];

  return (
    <x.div {...styles.container} data-testid="deliverables-empty-results">
      <Typography variant="body-large-em" color="secondary">
        {title}
      </Typography>
      <Typography variant="body-small" color="secondary" {...styles.subtitle}>
        {subtitle}
      </Typography>
      {cta && (
        <Button variant="outline" size="small" onClick={onClearFilters} {...styles.button}>
          {cta}
        </Button>
      )}
    </x.div>
  );
};

export const CardSkeleton = ({ ...props }) => {
  const styles = useEmptyResultsStyle();

  return (
    <Content data-testid="card-loading" viewBox="0 0 400 100" style={styles.contentWrapper} {...props}>
      <rect x="17" y="17" width="100" height="18" />
      <rect x="127" y="17" width="50" height="18" />
      <rect x="310" y="17" width="77" height="18" />

      <rect x="17" y="42" width="370" height="18" />

      <rect x="17" y="68" width="55" height="15" />
      <rect x="77" y="68" width="160" height="15" />
    </Content>
  );
};

export const CardsSidebar = (props) => {
  const {
    appliedFilters,
    appliedKeywords,
    filterOptions,
    interactions,
    thirdPartyDocuments,
    alphaNowContent,
    currentSelection,
    sortCriteria,
    onSelect,
    onFilter,
    onSort,
    onTextSearch,
    onClearFilters,
    loading,
    isCardsSidebarCollapsed,
    onRefetchThirdPartyDocuments,
  } = props;

  const newlyUploadedDocumentIdRef = useRef(null);

  const { project } = useCurrentProjectContext();

  const styles = useStyles({
    isCardsSidebarCollapsed,
    currentSelection,
  });

  const { logHit } = useTrackUserAction();

  useEffect(() => {
    if (Object.keys(alphaNowContent).length === 0) return;
    if (loading) return;

    const suggestedContent = alphaNowContent?.suggestedContent || [];

    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.suggestionsPopulated,
      projectToken: project.token,
      details: {
        suggestedContent: suggestedContent.map((e) => e.id),
        numSuggestions: suggestedContent.length,
      },
    });
  }, [alphaNowContent, loading, project.token, logHit]);

  useDeepCompareEffect(() => {
    const id = newlyUploadedDocumentIdRef.current;
    if (id) {
      const document = thirdPartyDocuments.find((doc) => doc.id === id);
      if (document) {
        onSelect(document);
      }
      newlyUploadedDocumentIdRef.current = null;
    }
  }, [thirdPartyDocuments]); // eslint-disable-line react-hooks/exhaustive-deps

  const { suggestedContent = [], privateContent = [] } = alphaNowContent;

  const numInteractions = useMemo(() => {
    const accessible = suggestedContent.filter((it) => it.isAccessible);
    return interactions.length + privateContent.length + accessible.length;
  }, [interactions, privateContent, suggestedContent]);

  const hasThirdPartyDocuments = thirdPartyDocuments.length > 0;
  const allInteractions = [...interactions, ...thirdPartyDocuments].sort(sorterByCriteria(sortCriteria));

  const handleRefetchThirdPartyDocuments = (uploadedDocuments) => {
    newlyUploadedDocumentIdRef.current = uploadedDocuments[0].id;
    onRefetchThirdPartyDocuments();
  };

  return (
    <>
      <x.div data-testid="sidebar-expanded-content" {...styles.sidebarExpandedContent}>
        <x.div {...styles.sideSearchWrapper}>
          <CardsSidebarHeader
            appliedFilters={appliedFilters}
            appliedKeywords={appliedKeywords}
            filterOptions={filterOptions}
            interactions={interactions}
            alphaNowContent={alphaNowContent}
            sortCriteria={sortCriteria}
            onFilter={onFilter}
            onSort={onSort}
            onTextSearch={onTextSearch}
            loading={loading}
            onClearFilters={onClearFilters}
            hasThirdPartyDocuments={hasThirdPartyDocuments}
          />

          <x.div data-testid="deliverables-cards" {...styles.cardsList}>
            {loading ? (
              <>
                <CardSkeleton width="100%" height="105px" />
                <CardSkeleton width="100%" height="105px" />
                <CardSkeleton width="100%" height="105px" />
              </>
            ) : (
              <DeliverablesCards
                order={[
                  "addDocuments",
                  "recommendedTable",
                  "projectSummary",
                  "privateContent",
                  "accessibleContent",
                  "deliverables",
                  "empty",
                ]}
                alphaNowContent={alphaNowContent}
                interactions={allInteractions}
                onSelect={onSelect}
                currentSelection={currentSelection}
                appliedFilters={appliedFilters}
                appliedKeywords={appliedKeywords}
                loading={loading}
                numInteractions={numInteractions}
                onClearFilters={onClearFilters}
                onRefetchThirdPartyDocuments={handleRefetchThirdPartyDocuments}
              />
            )}
            <PageFooter style={{ marginTop: "auto" }} />
          </x.div>
        </x.div>
      </x.div>
    </>
  );
};

export const DeliverablesCards = ({
  order = [],
  alphaNowContent = {},
  interactions = [],
  onSelect,
  currentSelection = null,
  appliedFilters = {},
  appliedKeywords = [],
  loading = false,
  numInteractions = 0,
  onClearFilters = noop,
  onRefetchThirdPartyDocuments = noop,
}) => {
  const { isMobile } = useCheckScreen();
  const { project, isFeatureDisabled } = useCurrentProjectContext();
  const { hasProjectBadge } = useProjectBadgeContext();

  const hasThirdPartyDocumentsPermissions = useThirdPartyDocsPermissions();
  const showAddDocumentsCard =
    !isFeatureDisabled(ProjectFeature.ProjectSynthesis) && !isMobile && hasThirdPartyDocumentsPermissions;

  const { suggestedContent = [], privateContent = [], pitchedContent = [] } = alphaNowContent;

  const notAccessibleSuggestedContentCount = useMemo(
    () => (suggestedContent ?? []).filter((suggestion) => !suggestion.isAccessible).length,
    [suggestedContent]
  );

  const pitchedContentCount = useMemo(() => (pitchedContent ?? []).length, [pitchedContent]);

  const accessibleContent = useMemo(() => {
    return suggestedContent.filter((suggestion) => suggestion.isAccessible);
  }, [suggestedContent]);

  const hasAppliedFilterOrKeyword =
    appliedKeywords.length > 0 || Object.values(appliedFilters).some((filter) => filter?.length > 0);

  const hasOnlyAngleFilterApplied =
    Object.keys(appliedFilters || {})
      .filter((key) => key !== "groups")
      .every((key) => appliedFilters[key]?.length === 0) && (appliedFilters?.groups?.length || 0) > 0;

  const maybeAngleFilterApplied = !hasAppliedFilterOrKeyword || hasOnlyAngleFilterApplied;

  const suggestionRender = (source, appliedKeywords, key) => {
    return source.map((suggestion) => (
      <SuggestionCard
        key={key}
        suggestion={suggestion}
        appliedKeywords={appliedKeywords}
        onSelect={() => onSelect(suggestion)}
        selected={currentSelection?.id === suggestion.id}
      />
    ));
  };

  const isPastProjectDeliverablesEnabled = hasProjectBadge(ENABLE_PAST_PROJECT_DELIVERABLES);
  const showExtraCards = project.active || !isPastProjectDeliverablesEnabled;

  const showRecommendedContentCard =
    showExtraCards && (pitchedContentCount > 0 || notAccessibleSuggestedContentCount > 0);
  const recommendedContentCardCount = pitchedContentCount + notAccessibleSuggestedContentCount;

  const recommendedTableRender = (key) =>
    showRecommendedContentCard ? (
      <RecommendedContentCard
        key={key}
        count={recommendedContentCardCount}
        onClick={() => onSelect({ id: RECOMMENDED_CONTENT_ID })}
        selected={currentSelection?.id === RECOMMENDED_CONTENT_ID}
      />
    ) : null;

  const projectSummaryRender = () =>
    showExtraCards &&
    maybeAngleFilterApplied &&
    project.canGenerateProjectSummary && <ProjectSummaryCard onSelect={onSelect} currentSelection={currentSelection} />;

  const privateContentRender = (key) => suggestionRender(privateContent, appliedKeywords, key);
  const pitchedContentRender = (key) => suggestionRender(pitchedContent, appliedKeywords, key);
  const accessibleContentRender = (key) => suggestionRender(accessibleContent, appliedKeywords, key);
  const suggestionsRender = (key) => suggestionRender(suggestedContent, appliedKeywords, key);

  const deliverablesRender = (key) => (
    <DeliverablesCardsList
      key={key}
      interactions={interactions}
      currentSelection={currentSelection}
      onSelect={onSelect}
    />
  );

  const addDocumentsRender = (key) =>
    showAddDocumentsCard ? <AddDocumentsCard key={key} refetchDocuments={onRefetchThirdPartyDocuments} /> : null;

  const emptyRenderer = (key) =>
    !loading &&
    numInteractions === 0 && (
      <EmptyResult
        key={key}
        variant="deliverables"
        onClearFilters={onClearFilters}
        hasAppliedFilterOrKeyword={hasAppliedFilterOrKeyword}
      />
    );

  const renderers = {
    addDocuments: addDocumentsRender,
    recommendedTable: recommendedTableRender,
    projectSummary: projectSummaryRender,
    privateContent: privateContentRender,
    accessibleContent: accessibleContentRender,
    deliverables: deliverablesRender,
    pitchedContent: pitchedContentRender,
    suggestions: suggestionsRender,
    empty: emptyRenderer,
  };

  return (
    <>
      {order.map((section, index) => {
        return renderers[section](`card-${index}`);
      })}
    </>
  );
};
