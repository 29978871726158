import { FC, useEffect, useState } from "react";
import { useQueryClient } from "query-utils";
import { Company as CompanyIcon } from "@alphasights/alphadesign-icons";

import { contentService } from "services/content";
import Search, {
  getDefaultComponents,
  OptionProps,
  SearchItemProps,
  SearchSizeVariant,
  SearchVariant,
} from "components/Search";
import { Company, CompanyOption } from "./types";
import { CompanySearchOption } from "./constants";
import { useAlphaNowPageStore } from "pages/AlphaNowPage/store/useAlphaNowPageStore";
import withErrorMessage from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/withErrorMessage";

const enhanceData = (data: any) => ({
  ...data,
  ...(data.logo ? { avatar: { src: data.logo } } : { StartIcon: CompanyIcon }),
});

const Option = ({ data, ...props }: OptionProps) => {
  const { Option: BaseOption } = getDefaultComponents();
  const enhancedOption = enhanceData(data);
  return <BaseOption data={enhancedOption} {...props} />;
};

const SearchItem = ({ data, ...props }: SearchItemProps) => {
  const { SearchItem: BaseSearchItem } = getDefaultComponents();
  const enhancedData = enhanceData(data);
  return <BaseSearchItem data={enhancedData} variant="light" {...props} />;
};

type CompanySearchFieldProps = {
  onChange: (value: Company) => void;

  value?: { name?: string; id?: number };
  errorMessage?: string;
  dataTestId?: string;
};

const CompanySearchField: FC<CompanySearchFieldProps> = ({ value, onChange, errorMessage, dataTestId }) => {
  const [query, setQuery] = useState<CompanyOption[]>([]);

  const queryClient = useQueryClient();
  const { setUnfocusSearch } = useAlphaNowPageStore((state) => state);

  useEffect(() => {
    const { id, name } = value ?? {};
    if (id || name) {
      setQuery([{ label: name, value }] as CompanyOption[]);
    }
  }, [value]);

  const loadSuggestions = async (inputValue: string) => {
    const suggestions = [];
    if (inputValue) {
      const res = await queryClient.fetchQuery(["search-companies", inputValue], () =>
        contentService.searchCompanies(inputValue)
      );

      const options = res.map(({ primaryName, cdsAlphaCompanyId, logo }: any) => ({
        label: primaryName,
        value: { id: cdsAlphaCompanyId, name: primaryName },
        logo,
        type: CompanySearchOption.company,
      }));

      if (!options.length || !options.some((option: CompanyOption) => option.label.toLowerCase() === inputValue)) {
        suggestions.push({
          label: inputValue[0].toUpperCase() + inputValue.slice(1),
          value: { name: inputValue },
          type: CompanySearchOption.string,
        });
      }

      suggestions.push(...options);
      return suggestions;
    }
    return [];
  };

  const handleSearchChange = (values: any[]) => {
    const [companyValue] = values;
    onChange(companyValue?.value);
    setUnfocusSearch(true);
  };

  return (
    <Search
      dataTestId={dataTestId}
      variant={SearchVariant.Complex}
      size={SearchSizeVariant.Small}
      components={{ SearchItem, Option }}
      query={query}
      loadOptions={loadSuggestions}
      onChange={handleSearchChange}
      isError={!!errorMessage}
      isSingleSelect
      debounceSearch
      autoHideSearchIcon
    />
  );
};

export default withErrorMessage(CompanySearchField);
