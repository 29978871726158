import { forwardRef } from "react";
import { components as selectComponents } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Typography } from "@alphasights/alphadesign-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

const { color, shape } = tokens;

export const theme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: color.background.input.default,
      neutral80: color.text.strong._,
      primary: color.border.neutral.default,
      neutral20: color.border.neutral.default,
      neutral30: color.border.neutral.default,
    },
  };
};

export const styles = ({ bordered, indicator, hasError }) => ({
  menu: (base) => {
    return {
      ...base,
      backgroundColor: color.background.input.default,
      borderRadius: shape.border.radius.small,
      boxShadow: shape.shadow.medium,
      marginTop: "0px !important",
      borderBottom: `${color.border.selected} ${shape.border.width.lg} solid`,
      borderRight: `${color.border.selected} ${shape.border.width.lg} solid`,
      borderLeft: `${color.border.selected} ${shape.border.width.lg} solid`,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      cursor: "pointer",
    };
  },
  dropdownIndicator: (state) => {
    return {
      padding: 5,
      color: color.icon.selected,
      ...(state.isFocused
        ? {
            transform: "rotate(180deg)",
          }
        : {
            display: indicator ? "block" : "none",
          }),
    };
  },
  option: (base, state) => {
    return {
      ...base,
      cursor: "pointer",
      backgroundColor: state.isFocused ? color.background.info : color.background.input.default,
    };
  },
  control: (base, state) => {
    let extras;

    if (state.menuIsOpen) {
      extras = {
        borderTop: `${color.border.selected} ${shape.border.width.lg} solid !important`,
        borderRight: `${color.border.selected} ${shape.border.width.lg} solid !important`,
        borderLeft: `${color.border.selected} ${shape.border.width.lg} solid !important`,
        borderBottom: "none",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: color.background.input.default,
      };

      if (bordered) {
        extras = {
          ...extras,
          marginTop: "-6px",
        };
      }
    } else {
      if (bordered) {
        extras = {
          border: `${hasError ? color.border.danger : color.border.neutral.default} ${
            shape.border.width.lg
          } solid !important`,
        };
      } else {
        extras = {
          border: "none",
          paddingTop: "2px",
        };
      }
    }

    return {
      ...base,
      backgroundColor: color.background.surface.page.hover,
      ...extras,
      transition: "none",
      outline: "0 !important",
      cursor: "pointer",
    };
  },
  menuList: (base) => {
    return {
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    };
  },
  container: (base) => {
    return {
      ...base,
      marginTop: -4,
    };
  },
  valueContainer: (base) => {
    return {
      ...base,
      paddingBottom: 12,
      paddingTop: 12,
      paddingLeft: 16,
      paddingRight: 16,
      color: "#222222",
    };
  },
  multiValue: (base) => {
    return {
      ...base,
      maxWidth: 180,
    };
  },
  input: (base) => {
    return {
      ...base,
      margin: 0,
      padding: 0,
    };
  },
  singleValue: (base) => {
    return {
      ...base,
      margin: 0,
    };
  },
  placeholder: (base) => {
    return {
      ...base,
      marginLeft: 0,
    };
  },
});

const Option = ({ children, ...props }) => {
  return (
    <selectComponents.Option {...props} isSelected={false}>
      <Typography component="div" color="assistive" display="flex" alignItems="center">
        {children}
      </Typography>
    </selectComponents.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <selectComponents.SingleValue {...props}>
      <Typography component="div">{children}</Typography>
    </selectComponents.SingleValue>
  );
};

const ValueContainer = ({ children, ...props }) => {
  return (
    <selectComponents.ValueContainer {...props}>
      <Typography component="div">{children}</Typography>
    </selectComponents.ValueContainer>
  );
};

const MenuList = ({ children, ...props }) => {
  return (
    <selectComponents.MenuList {...props}>
      <Typography component="div">{children}</Typography>
    </selectComponents.MenuList>
  );
};

const components = {
  Option,
  IndicatorSeparator: () => null,
  SingleValue,
  ValueContainer,
  MenuList,
};

export const CreatableSelect = forwardRef(
  (
    {
      getOptionValue,
      getOptionLabel,
      defaultOptions,
      noOptionsMessage,
      getNewOptionData,
      isValidNewOption,
      placeholderMenuClosed,
      placeholderMenuOpen,
      onChange,
      loadOptions,
      onCreateOption,
      ...props
    },
    ref
  ) => {
    return (
      <AsyncCreatableSelect
        ref={ref}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        defaultOptions={defaultOptions}
        blurInputOnSelect={true}
        controlShouldRenderValue={false}
        loadOptions={loadOptions}
        noOptionsMessage={noOptionsMessage}
        isValidNewOption={isValidNewOption}
        getNewOptionData={getNewOptionData}
        onCreateOption={onCreateOption}
        onChange={onChange}
        components={{
          ...components,
          Placeholder: (props) => (
            <selectComponents.Placeholder {...props}>
              {props.selectProps.menuIsOpen ? placeholderMenuOpen : placeholderMenuClosed}
            </selectComponents.Placeholder>
          ),
        }}
        styles={styles({ bordered: false })}
        theme={theme}
        {...props}
      />
    );
  }
);
