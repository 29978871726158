import { Alert, Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";

export const CompleteQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnswerContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    gap: spacing.inner.base04,
    paddingTop: spacing.inner.base03,
    paddingBottom: spacing.inner.base06,
  };
});

export const ErrorContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    width: "fit-content",
    margin: `${spacing.inner.base10} auto`,
  };
});

export const StyledQuestion = styled(EmphasisTypography)(() => {
  const { spacing } = useThemeTokens();
  return {
    paddingBottom: spacing.inner.base03,
  };
});

export const StyledQuote = styled(EmphasisTypography)(() => {
  const { spacing } = useThemeTokens();
  return {
    borderLeft: "2px solid rgba(8, 135, 95, 0.08)",
    fontStyle: "italic",
    paddingLeft: spacing.inner.base04,
    marginLeft: spacing.inner.base10,
  };
});

export const StyledIcon = styled(Icon)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.icon.ai,
  };
});

export const StyledErrorAlert = styled(Alert)(() => {
  const { spacing } = useThemeTokens();
  return {
    width: "100%",
    marginTop: spacing.inner.base03,
  };
});
