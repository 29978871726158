import { OptionSections, SearchOption } from "components/Search/types";

export const getOptionsPerSection = (options: SearchOption[] = [], sections: OptionSections[] = []) => {
  const categorizedOptions = [];

  sections.forEach(({ title, numOptionsPerSection, errorMessage }) => {
    const matchingOptions = options.filter((option) => option.section === title).slice(0, numOptionsPerSection);

    // Add section if section has options associated with it
    //  or if the section fails and an error message exists
    if (matchingOptions.length > 0 || !!errorMessage) {
      categorizedOptions.push({
        title,
        options: matchingOptions,
        errorMessage,
      });
    }
  });

  // Filter options that do not fit into any defined sections into "Other" section
  const uncategorizedOptions = options.filter((option) => !sections.some(({ title }) => title === option.section));

  if (uncategorizedOptions.length > 0) {
    categorizedOptions.push({
      title: sections.length > 0 ? "Other" : "",
      options: uncategorizedOptions,
      errorMessage: "",
    });
  }

  return categorizedOptions;
};
