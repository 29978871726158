import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { useStyles } from "./VendorSwitchingModuleContent.styles";
import { Skeleton, TabItem, Tabs, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import {
  DeleteItemWithConfirmationBtn,
  EditableInput,
  EditStructuredModuleItemCTAs,
  ExpertQuote,
  MoreActions,
  RegenerationFailedToast,
  RevisionToggler,
} from "../components";
import { vendorSwitching } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { ScheduleCustomersBanner } from "../components/ScheduleCustomersBanner";
import { VendorSwitchingChart } from "./VendorSwitchingChart";
import { HitAction, SynthesisQuote, VendorSwitchingItem } from "@alphasights/portal-api-client";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useEditItem } from "views/ProjectSynthesisView/hooks/useEditItem";
import { useEditVendorSwitchingOperations } from "views/ProjectSynthesisView/hooks/useEditVendorSwitchingOperations";
import { copyTextToClipboard } from "components/FloatingActions";
import { withNumberParam } from "helpers/tabHelper";

export const VendorSwitchingModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <Render />;
};

const Render = () => {
  const styles = useStyles();
  const [tab, setTab] = useState(0);
  const [editItemsCount, setEditItemsCount] = useState(0);
  const { spacing } = useThemeTokens();
  const { revision, selectedModule, thirdPartyDocuments } = useProjectSynthesisContext();

  const content = vendorSwitching(revision?.contents);
  const tabContent = useMemo(() => (tab === 0 && content.barriers.length > 0 ? content.barriers : content.drivers), [
    content.barriers,
    content.drivers,
    tab,
  ]);

  if (revision?.status === "PROCESSING") {
    return <ProcessingModuleContent />;
  }

  if (revision?.status === "FAILED") {
    return <RegenerationFailedToast module={selectedModule!} />;
  }

  return (
    <x.div mt={spacing.inner.base06} data-testid="vendor-switching-module-content">
      <ScheduleCustomersBanner />
      <x.div {...styles.container}>
        {editItemsCount === 0 && (
          <RevisionToggler display="flex" justifyContent="flex-end" position="absolute" right="32px" zIndex="1" />
        )}
        <VendorSwitchingChart
          transcriptAnalyses={content.transcriptAnalyses}
          thirdPartyDocuments={thirdPartyDocuments}
        />
        <Tabs variant="default" onChange={withNumberParam(setTab)} hasInitialSelection>
          {content.barriers.length > 0 && <TabItem label="Barriers to Switching" />}
          {content.drivers.length > 0 && <TabItem label="Drivers to Switching" />}
        </Tabs>
        {tabContent.map((item) => (
          <VendorSwitchingItemRender
            key={item.id}
            item={item}
            onEnterEditMode={() => setEditItemsCount((prev) => prev + 1)}
            onLeaveEditMode={() => setEditItemsCount((prev) => Math.max(0, prev - 1))}
          />
        ))}
      </x.div>
    </x.div>
  );
};

const VendorSwitchingItemRender = ({
  item,
  onEnterEditMode,
  onLeaveEditMode,
}: {
  item: VendorSwitchingItem;
  onEnterEditMode?: () => void;
  onLeaveEditMode?: () => void;
}) => {
  const styles = useStyles();
  const {
    synthesisLogHit,
    revision,
    selectedModule,
    saveInProgress,
    thirdPartyDocuments,
    saveModuleChanges,
    selectedRevisionIdx,
  } = useProjectSynthesisContext();
  const { updateVendorSwitchingItem } = useEditVendorSwitchingOperations({ originalModule: selectedModule });

  const onSaveChanges = useCallback(
    (itemToSave: VendorSwitchingItem) => {
      const updatedModule = updateVendorSwitchingItem(itemToSave, selectedRevisionIdx);
      return updatedModule
        ? saveModuleChanges(updatedModule, { editedItem: `${itemToSave.title}` })
        : Promise.resolve();
    },
    [saveModuleChanges, selectedRevisionIdx, updateVendorSwitchingItem]
  );

  const { mode, actualItem: vendorSwitchingItem, enterEditMode, onEditItem, onSaveEdit, onCancelEdit } = useEditItem({
    item,
    onSaveChanges,
    onLeaveEditMode,
    onEnterEditMode,
  });

  const onNavigateToTranscript = (quote: SynthesisQuote) => {
    synthesisLogHit({
      action: HitAction.projectSynthesisViewInTranscript,
      details: {
        revision: revision?.revision,
        quote: quote.text,
        moduleType: selectedModule?.contentType,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
  };

  const onEditQuote = useCallback(
    (updatedQuote: SynthesisQuote) => {
      onEditItem({
        ...vendorSwitchingItem,
        quotes: vendorSwitchingItem.quotes.map((q) => (q.id === updatedQuote.id ? { ...updatedQuote } : q)),
      });
    },
    [onEditItem, vendorSwitchingItem]
  );

  const onDeleteQuote = useCallback(
    (deletedQuote: SynthesisQuote) => {
      onEditItem({
        ...vendorSwitchingItem,
        quotes: vendorSwitchingItem.quotes.filter((q) => q.id !== deletedQuote.id),
      });
    },
    [onEditItem, vendorSwitchingItem]
  );

  return (
    <x.div {...styles.card} data-testid={`vendor-switching-item-${vendorSwitchingItem.id}`}>
      <Title
        vendorSwitchingItem={vendorSwitchingItem}
        mode={mode}
        enterEditMode={enterEditMode}
        onEditItem={onEditItem}
      />
      <Summary vendorSwitchingItem={vendorSwitchingItem} mode={mode} onEditItem={onEditItem} />

      {vendorSwitchingItem.quotes.map((q) => {
        return (
          <ExpertQuote
            key={q.id}
            quote={q}
            mode={mode}
            onEditQuote={onEditQuote}
            onDeleteQuote={() => onDeleteQuote(q)}
            onNavigateToTranscript={() => onNavigateToTranscript(q)}
            saveInProgress={saveInProgress}
            thirdPartyDocuments={thirdPartyDocuments}
            showCompanyInfo
          />
        );
      })}
      {mode === Mode.EDIT && (
        <EditStructuredModuleItemCTAs
          onCancelEdit={onCancelEdit}
          onSaveEdit={onSaveEdit}
          saveDisabled={saveInProgress}
        />
      )}
    </x.div>
  );
};

const Title = ({
  vendorSwitchingItem,
  mode,
  enterEditMode,
  onEditItem,
}: {
  vendorSwitchingItem: VendorSwitchingItem;
  mode: Mode;
  enterEditMode: () => void;
  onEditItem: (updatedItem: VendorSwitchingItem) => void;
}) => {
  const styles = useStyles();
  const { saveInProgress, saveModuleChanges, selectedModule, selectedRevisionIdx } = useProjectSynthesisContext();
  const { deleteVendorSwitchingItem } = useEditVendorSwitchingOperations({ originalModule: selectedModule });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onEditItem({ ...vendorSwitchingItem, title: e.target.value });
    },
    [onEditItem, vendorSwitchingItem]
  );

  const onDeleteItem = useCallback(() => {
    const updatedModule = deleteVendorSwitchingItem(vendorSwitchingItem, selectedRevisionIdx);
    updatedModule &&
      saveModuleChanges(
        updatedModule,
        { deletedItem: `${vendorSwitchingItem.title}` },
        true,
        `${vendorSwitchingItem.title} deleted.`
      );
  }, [deleteVendorSwitchingItem, saveModuleChanges, selectedRevisionIdx, vendorSwitchingItem]);

  const onCopyItem = useCallback(() => {
    return copyTextToClipboard(formatSynthesisVendorSwitching(vendorSwitchingItem));
  }, [vendorSwitchingItem]);

  if (mode === Mode.EDIT) {
    return (
      <x.div
        display="flex"
        gap="8px"
        data-testid={`vendor-switching-title-edit-${vendorSwitchingItem.id}`}
        alignItems={"center"}
      >
        <EditableInput input={vendorSwitchingItem.title} onChange={onChange} />
        <x.div mt="8px">
          <DeleteItemWithConfirmationBtn
            onDeleteItem={onDeleteItem}
            saveInProgress={saveInProgress}
            tooltipText="Delete item"
            testId={`delete-item-${vendorSwitchingItem.id}`}
          />
        </x.div>
      </x.div>
    );
  }

  return (
    <x.div {...styles.titleRow}>
      <Typography variant="body-em" data-testid="item-title">
        {vendorSwitchingItem.title}
      </Typography>
      <x.div {...styles.alignRight}>
        <MoreActions enterEditMode={enterEditMode} onDelete={onDeleteItem} onCopy={onCopyItem} />
      </x.div>
    </x.div>
  );
};

const Summary = ({
  vendorSwitchingItem,
  mode,
  onEditItem,
}: {
  vendorSwitchingItem: VendorSwitchingItem;
  mode: Mode;
  onEditItem: (updatedItem: VendorSwitchingItem) => void;
}) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onEditItem({ ...vendorSwitchingItem, summary: e.target.value });
    },
    [onEditItem, vendorSwitchingItem]
  );

  if (mode === Mode.EDIT) {
    return <EditableInput input={vendorSwitchingItem.summary} onChange={onChange} />;
  }

  return <Typography>{vendorSwitchingItem.summary}</Typography>;
};

const ProcessingModuleContent = () => {
  return (
    <>
      <x.div display="flex" flexDirection="column" gap="16px" p="24px" data-testid="processing-module">
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </>
  );
};

export const formatSynthesisVendorSwitching = (item: VendorSwitchingItem): string => {
  return `
  <div>
    <p style="margin-bottom: 12px; font-weight: bold;">${item.title}</p>
    <p style="margin-bottom: 12px;">${item.summary}</p>
    ${item.quotes
      .map((quote) => {
        const q = "<span style='font-style: italic'>\"" + quote.text + '"</span>';
        const author =
          "<span style='font-style: italic; color: #666b7a'>" + quote.companyName + " - " + quote.role + "</span>";
        return "<p>• " + q + " " + author + "</p>";
      })
      .join("\n")}
  </div>
  <br>
  `;
};
