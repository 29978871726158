import { useMemo } from "react";
import { SelectMenu, SelectOption, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { CurrentTimezone, FormattedDateTime, useTimezone } from "providers/TimezoneProvider";
import { PopupDataArea, ScheduledCallTime } from "./common";
import { x } from "@xstyled/styled-components";
import { CalendarAvailable } from "@alphasights/alphadesign-icons";
import { startOfDay } from "date-fns";

export const SelectDateTime = ({
  optionsStart,
  optionsEnd,
  selectedStart,
  setSelectedStart,
  duration,
  setDuration,
  icon,
  addMarginTop = true,
  label = "",
  selectableDate = false,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const timezone = useTimezone();

  const daysList = useMemo(() => {
    const today = new Date();
    const nextDays = Array.from({ length: Math.ceil((selectedStart - today) / (1000 * 60 * 60 * 24)) + 10 }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      return date;
    });

    return [
      ...nextDays.map((day) => ({
        label: timezone.format(day, "iiii d MMM"),
        value: startOfDay(day).toISOString(),
      })),
    ];
  }, [selectedStart, timezone]);

  const selectedDay = startOfDay(selectedStart).toISOString();

  if (optionsStart.length === 1 && optionsEnd.length === 1) {
    return <ScheduledCallTime date={selectedStart} duration={duration} label={label} />;
  }
  return (
    <PopupDataArea
      icon={icon ? icon : <CalendarAvailable />}
      firstLine={
        <>
          {label && <Typography color="secondary">{label}</Typography>}
          {selectableDate ? (
            <SelectMenu
              value={selectedDay}
              onChange={(value) => {
                const selectedDateWithHour = new Date(value);
                const today = new Date();
                selectedDateWithHour.setHours(today.getHours() + Math.round(today.getMinutes() / 60 + 1));
                selectedDateWithHour.setMinutes(0, 0, 0);
                setSelectedStart(selectedDateWithHour);
              }}
              isClearButtonEnabled={false}
              dataAttributes={{ "data-testid": "schedule-date-dropdown" }}
            >
              {daysList.map((dayOption) => (
                <SelectOption value={dayOption.value} label={dayOption.label} />
              ))}
            </SelectMenu>
          ) : (
            <Typography variant="body" color="strong">
              <FormattedDateTime date={selectedStart} format="iiii d MMM" />
              <CurrentTimezone formatOnly format=" (O)" />
            </Typography>
          )}
        </>
      }
      addMarginTop={addMarginTop}
    >
      {(optionsStart.length > 1 || optionsEnd.length > 1) && (
        <x.div display="flex" alignItems="center" pt={inner.base02}>
          <x.div flex="1">
            <SelectMenu
              value={selectedStart.getTime()}
              onChange={(value) => setSelectedStart(new Date(value))}
              dataAttributes={{ "data-testid": "schedule-start-dropdown" }}
              isClearButtonEnabled={false}
            >
              {optionsStart.map((option) => (
                <div key={option.value.getTime()} data-testid="start-option">
                  <SelectOption
                    value={option.value.getTime()}
                    label={option.label}
                    dataAttributes={{ "data-testid": "start-option" }}
                  />
                </div>
              ))}
            </SelectMenu>
          </x.div>
          <x.div mx={inner.base04}>-</x.div>
          {optionsEnd.length > 1 ? (
            <x.div flex="1">
              <SelectMenu
                key={optionsEnd.map((option) => option.label).join()}
                value={duration}
                onChange={setDuration}
                dataAttributes={{ "data-testid": "schedule-end-dropdown" }}
                isClearButtonEnabled={false}
              >
                {optionsEnd.map((option) => (
                  <div key={option.value} data-testid="end-option">
                    <SelectOption value={option.value} label={option.label} />
                  </div>
                ))}
              </SelectMenu>
            </x.div>
          ) : (
            <Typography flex="1" data-testid="schedule-end-dropdown-read-only">
              {optionsEnd[0].label}
            </Typography>
          )}
        </x.div>
      )}
    </PopupDataArea>
  );
};
