enum ColumnIds {
  title = "title",
  angle = "angle",
  status = "status",
  edit = "edit",
  delete = "delete",
}

const COLUMN_NAMES = {
  [ColumnIds.title]: "Uploaded Document Title",
  [ColumnIds.angle]: "Angle",
  [ColumnIds.status]: "Status",
};

const COLLAPSED_ANGLE_COLUMN_SIZE = 120;

const COLUMN_METADATA = {
  enableDragDrop: false,
  enableResizing: false,
  enableSorting: false,
};

const TABLE_OPTIONS = {
  meta: {
    hideSelectColumn: true,
    fullWidth: true,
  },
};

export { ColumnIds, COLUMN_NAMES, COLLAPSED_ANGLE_COLUMN_SIZE, COLUMN_METADATA, TABLE_OPTIONS };
