import { useMemo } from "react";
import { ContentCard, Pill, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { isSameDay } from "date-fns";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { useMessengerThreadCardStyles } from "./MessengerThreadCard.styles";
import { getRequestTypeName } from "pages/MessengerPage/utils";
import { ComplianceReviewStatus, InboxResponse } from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { MarkdownDisplay } from "@alphasights/alphadesign-rte";

export const MessengerThreadCard = ({
  inbox,
  onClick,
  isSelected,
}: {
  inbox: InboxResponse;
  onClick: () => void;
  isSelected: boolean;
}) => {
  const { experts } = useMessengerContext();

  const {
    dateLabel,
    expertCompanyLabel,
    expertPositionLabel,
    sendersLabel,
    messagePreviewLabel,
    topContainer,
    contentContainer,
    expertInfoContainer,
    markdownStyles,
  } = useMessengerThreadCardStyles();

  const isLastMessageFromToday = useMemo(() => isSameDay(new Date(), new Date(inbox.mostRecentMessage.sentAt)), [
    inbox.mostRecentMessage.sentAt,
  ]);

  const numParticipants = useMemo(() => inbox.threads.length, [inbox.threads.length]);

  const mostRecentExpert = useMemo(() => experts.find((e) => e.id === inbox.mostRecentAdvisor.id), [
    experts,
    inbox.mostRecentAdvisor.id,
  ]);

  const requestType = useMemo(() => getRequestTypeName(inbox.requestType), [inbox.requestType]);

  const isClient = inbox.mostRecentMessage.sender.role === "client";

  return (
    <ContentCard data-testid="thread-card" onClick={onClick} selected={isSelected}>
      <x.div {...topContainer}>
        <div>
          {((!inbox.mostRecentMessage.isRead && !isClient) ||
            inbox.mostRecentMessage.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_PENDING) && (
            <UnreadDot />
          )}
          <Pill size="small" isInteractive={false}>
            {requestType}
          </Pill>
        </div>
        <Typography variant="body-small" {...dateLabel}>
          <FormattedDateTime
            date={inbox.mostRecentMessage.sentAt}
            format={isLastMessageFromToday ? "hh:mmaaa" : "d LLL"}
          />
        </Typography>
      </x.div>
      <x.div {...contentContainer}>
        <x.div {...expertInfoContainer}>
          <Typography component="span" variant="body-em" {...expertCompanyLabel}>
            {mostRecentExpert?.relevantCompany}
          </Typography>
          <Typography component="span" variant="body" {...expertPositionLabel}>
            {" "}
            - {mostRecentExpert?.relevantPosition}
          </Typography>
        </x.div>
        {numParticipants > 1 && (
          <Typography component="span" variant="body" {...sendersLabel}>
            &nbsp;{`+ ${numParticipants - 1} more`}
          </Typography>
        )}
      </x.div>
      <x.div
        style={
          {
            filter: inbox.mostRecentMessage.obfuscated ? "blur(2.5px)" : "none",
            userSelect: inbox.mostRecentMessage.obfuscated ? "none" : "auto",
            ...messagePreviewLabel,
          } as any
        }
      >
        {inbox.mostRecentMessage.sanitizedContent && (
          <MarkdownDisplay
            dataTestid="message-preview"
            size="small"
            markdown={inbox.mostRecentMessage.sanitizedContent}
            customStyles={markdownStyles}
          />
        )}
      </x.div>
    </ContentCard>
  );
};

export const UnreadDot = ({ ...props }) => {
  const { unreadMark } = useMessengerThreadCardStyles();
  return <x.div {...unreadMark} {...props} data-testid="unread-dot" />;
};
