import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { x } from "@xstyled/styled-components";

const STYLE_CONFIG = {
  small: {
    width: tokens.spacing.inner.base04,
    height: tokens.spacing.inner.base04,
    variant: tokens.typography.body.smallEm,
  },
  medium: {
    width: tokens.spacing.inner.base06,
    height: tokens.spacing.inner.base06,
    variant: tokens.typography.body.em,
  },
  large: {
    width: tokens.spacing.inner.base08,
    height: tokens.spacing.inner.base08,
    variant: tokens.typography.body.largeEm,
  },
} as any;

export const CompanyPillContainer = styled(x.div)(
  ({
    isHighlighted,
    isInteractive,
    isSelected,
    ...props
  }: {
    isHighlighted: boolean;
    isInteractive: boolean;
    isSelected: boolean;
  }) => {
    const { color, shape, spacing } = useThemeTokens();
    return {
      display: "inline-flex",
      borderRadius: shape.border.radius[16],
      padding: spacing.inner.base02,
      backgroundColor: isHighlighted ? color.background.info : color.background.surface.page.default,
      alignItems: "center",
      cursor: isInteractive ? "pointer" : "default",
      border: `${shape.border.width.sm} solid ${isSelected ? color.border.selected : color.border.divider}`,
      "&:hover": {
        border: `${shape.border.width.sm} solid ${color.border.neutral.hover}`,
      },
      ...props,
    };
  }
);

export const StartAdornmentContainer = styled.div(({ size }: { size: string }) => {
  const { color, spacing, shape } = useThemeTokens();
  const styleConfig = STYLE_CONFIG[size];
  return {
    borderRadius: spacing.inner.base02,
    border: `${shape.border.width.sm} solid ${color.border.divider}`,
    width: styleConfig.width,
    height: styleConfig.width,
    display: "flex",
    backgroundColor: color.background.surface.page.default,
    overflow: "hidden",
  };
});

export const StyledStartAdornment = styled(x.div)(({ isGrayscale }: { isGrayscale: boolean }) => {
  return {
    flex: "1 1 auto",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    filter: isGrayscale ? "grayscale(100%)" : "none",
    opacity: isGrayscale ? "0.3" : "1",
  };
});

export const StyledTypography = styled(Typography)(({ size, isGrayscale }: { size: string; isGrayscale: boolean }) => {
  const { color, spacing } = useThemeTokens();
  const styleConfig = STYLE_CONFIG[size];

  return {
    ...styleConfig.variant,
    marginLeft: spacing.inner.base,
    color: isGrayscale ? color.text.disabled : color.icon.strong._,
    display: "inline-flex",
  };
});
