import { useNavigate } from "router-utils";
import useQuery from "./useQuery";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { LeanProject } from "@alphasights/portal-api-client";

function isVisitingPage() {
  const { pathname, search } = window.location;
  if (pathname === "/" || pathname === "/login-success") return false;
  if ((pathname === "/sign-in" || pathname.startsWith("/invitations/")) && !search.match(/next/)) return false;
  return true;
}

function getRedirectRoute({
  user,
  allProjects,
  nextFromQuery,
  mobileRedirect,
}: {
  user?: User;
  allProjects?: LeanProject[];
  nextFromQuery?: string;
  mobileRedirect: boolean;
}) {
  const { accessOnly, alphaNowEnabled } = user ?? {};

  switch (true) {
    case !user:
      return "/sign-in";
    case accessOnly && alphaNowEnabled:
      return "/alphanow";
    case !!nextFromQuery:
      return nextFromQuery;
    case isVisitingPage():
      return null;
    case mobileRedirect:
      return "/upcoming-calls?openSidebar=true";
    case (allProjects?.length ?? 0) > 0:
      return "/my-projects";
    case alphaNowEnabled:
      return "/alphanow";
    default:
      return "/my-projects";
  }
}

function useRedirect({
  onRedirect,
  onContinue,
}: {
  onRedirect?: () => void;
  onContinue?: () => void;
} = {}) {
  const { isMobile: mobileRedirect } = useCheckScreen();
  const allProjects = useMyProjects();
  const currentUser = useCurrentUser();
  const query = useQuery();
  const nextFromQuery = query.get("next") ?? undefined;
  const navigate = useNavigate();

  const redirectTo = (url: string) => {
    onRedirect && onRedirect();
    navigate(url);
  };

  const continueInPage = () => onContinue && onContinue();

  const performRedirect = async () => {
    const nextRoute = getRedirectRoute({ user: currentUser, allProjects, nextFromQuery, mobileRedirect });

    if (!nextRoute) return continueInPage();
    return redirectTo(nextRoute);
  };

  return performRedirect;
}

export default useRedirect;
