import { useRef } from "react";
import { Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { x } from "@xstyled/styled-components";
import { TotalHighlights } from "components/TotalHighlights";
import { SurveyCellProps } from "../customTypes";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export const ExpertCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;
  const tokens = useThemeTokens();
  const valueRef = useRef<HTMLDivElement>(null);
  const isValueOverflow = useIsOverflow(valueRef);

  const totalHighlights = interaction.highlights?.flatMap(({ terms }) => terms).length || 0;

  return (
    <x.div
      data-testid={`survey-expert-column-${interaction.id}`}
      color={tokens.color.text.info}
      display="flex"
      alignItems="center"
    >
      <Tooltip disabled={!isValueOverflow} title={props.getValue()}>
        <Typography
          ref={valueRef}
          maxWidth="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          display="inline-block"
          cursor={"pointer"}
        >
          {props.getValue()}
        </Typography>
      </Tooltip>
      {totalHighlights > 0 && (
        <TotalHighlights totalHighlights={totalHighlights} data-testid={`expert-total-highlights-${interaction.id}`} />
      )}
    </x.div>
  );
};

export const ExpertColumn = {
  id: "ExpertColumn",
  accessorKey: "interaction.advisorName",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <ExpertCell props={props} />,
  header: "Expert",
  minSize: 50,
};
