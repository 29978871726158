import { ThirdPartyDocumentExpert } from "types/common-types";

export const PLACEHOLDER_COMPANY_NAME = "[Expert Company]";
export const PLACEHOLDER_POSITION = "[Expert Position]";

export const getDefaultExpert = (experts?: ThirdPartyDocumentExpert[], placeholder = false) => {
  // always use first expert's angle if available
  const expert = experts?.[0] ?? {};
  let { companyName, position, ...rest } = expert;
  if (placeholder) {
    companyName = companyName ?? PLACEHOLDER_COMPANY_NAME;
    position = position ?? PLACEHOLDER_POSITION;
  }
  return { companyName, position, ...rest };
};
