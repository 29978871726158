import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { ManageLabelsPopover } from "components/InteractionLabel/ManageLabelsPopover";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { useLabelsContext } from "providers/LabelsProvider";
import { useState } from "react";
import styled from "styled-components";
import { OverlayContainer } from "./Overlays.styled";
import { useLabelExpert } from "./hooks";

export const AddLabelOverlay = ({ interaction }: { interaction: Interaction }) => {
  const { getLabelledExpert, labelsDisabled } = useLabelsContext();
  const [currentOpenId, setCurrentOpenId] = useState<string>();

  const { handleClick, open, ref, handlePopoverClose } = useLabelExpert(interaction, setCurrentOpenId, currentOpenId);

  const expertId = interaction.advisorId;
  const angleId = interaction.angles[0].id;
  const labelledExpert = getLabelledExpert(expertId, angleId);
  const label = labelledExpert?.label?.text;

  if (label || labelsDisabled) return null;

  return (
    <>
      <OverlayContainer
        data-testid="label-overlay-container"
        data-advisor={interaction.advisorName}
        onMouseEnter={() => setCurrentOpenId(undefined)}
      >
        <ButtonWrapper>
          <ButtonWithLoginWall variant="ghost" size="medium" onClick={handleClick} ref={ref}>
            Add label
          </ButtonWithLoginWall>
        </ButtonWrapper>
      </OverlayContainer>

      {open && (
        <ManageLabelsPopover anchorRef={ref} open={open} onClose={handlePopoverClose} interactions={[interaction]} />
      )}
    </>
  );
};

const ButtonWithLoginWall = withLoginWall(Button);
export const ButtonWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    margin-left: ${spacing.layout.base03};
  `;
});
