import styled, { x } from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { Tabs as AdsTabs, useThemeTokens } from "@alphasights/alphadesign-components";

export const ToolbarWrapper = styled(x.div)`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing.inner.base08};
  height: 100%;
`;

export const Tabs = styled(AdsTabs)<{
  isInactive?: boolean;
}>(({ isInactive }) => {
  const { color } = useThemeTokens();

  return `
    max-width: 100%;
    flex-grow: 1;
    align-items: center;
    background: transparent !important;
    button {
      background: transparent;
      &[direction] {
        display: none !important;
      }
    }
    & > div {
      height: 100%;
      flex-grow: 1;
      overflow: hidden;
    }

    [data-testid="hover-indicator"] {
      background: ${color.border.neutral.default};
    }

    button:has([data-testid="project-title-wrapper"]) {
      pointer-events: ${isInactive ? "none" : "auto"};
      flex-shrink: 1;
      min-width: 0;
      flex-grow: 2;
      justify-content: start;
      & > div {
        max-width: 100%;
        & > div {
          max-width: 100%;
          & > p {
            max-width: 100%;
          }
        }
      }
    }
  `;
});
