import { FC } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Typography, Accordion, AccordionItem } from "@alphasights/alphadesign-components";

import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { purchasableTranscriptQuestionsHeader, generatedByAITag } from "content/AlphaNow";

interface TranscriptQuestionsProps {
  questionsData: any[];
  transcriptQuestionsHeight: number;
}

const TranscriptQuestions: FC<TranscriptQuestionsProps> = ({ questionsData = [], transcriptQuestionsHeight }) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();

  return (
    <>
      <Accordion maxHeight={transcriptQuestionsHeight}>
        <AccordionItem
          open
          titleContent={
            <x.div row mx={-2} alignItems="center">
              <x.div pl={spacing.inner.base02} col={isMobile ? 8 / 10 : undefined}>
                <Typography variant="body-large-em" color={text.strong._}>
                  {purchasableTranscriptQuestionsHeader}
                </Typography>
              </x.div>
              <Typography
                px={spacing.inner.base02}
                color={text.secondary}
                variant="body-small"
                position={isMobile ? "static" : "sticky"}
                right={spacing.layout.base06}
              >
                {generatedByAITag}
              </Typography>
            </x.div>
          }
          borderBottom={isMobile ? 0 : "undefined"}
        >
          <x.div color={text.strong._}>
            {questionsData.map((entry) => {
              return (
                <x.ul listStyleType={"disc"} marginLeft={spacing.inner.base05} pb={spacing.inner.base} fontSize="10px">
                  <li>
                    <Typography variant="body" pl={spacing.inner.base}>
                      {entry.question}
                    </Typography>
                  </li>
                </x.ul>
              );
            })}
          </x.div>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default TranscriptQuestions;
