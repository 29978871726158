import { FC } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import DownloadButton from "pages/AlphaNowPage/components/AlphaNowTranscript/DownloadButton";
import { BookmarkButtonProps } from "components/BookmarkButton";
import BookmarkButton from "components/BookmarkButton";
import AudioButton from "components/AudioButton/AudioButton";
import CopyLinkButton from "components/CopyLinkButton";
import SettingsButton from "components/SettingsButton";
import { RequestExpertButton, WatchlistQuickAddButton } from "pages/AlphaNowPage/components";
import { RequestExpertButtonType } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions/constants";
import useExpertRequest from "pages/AlphaNowPage/hooks/useExpertRequest";
import { DataTestIds } from "pages/AlphaNowPage/components/AlphaNowContent/RequestExpertOverlay";
import useContentClientSettings from "hooks/useContentClientSettings";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { getAnchorCompanyIds } from "content/AlphaNow/utils";

type DownloadButtonProps = {
  hasDownloadButton: boolean;

  projectToken?: string;
};

type WatchlistButtonProps = {
  hasWatchlistButton: boolean;
};

type AudioButtonProps = {
  audioDownloadStatus?: string;
  onAudioSelect?: () => void;
};

type SettingsButtonProps = {
  showSettingsButton: boolean;
  onSettingsClick: () => void;
};

type RequestExpertProps = {
  isRequestExpertButtonDisabled?: boolean;
  openRequestExpertOverlay: () => void;
  requestExpertButtonText: string;
};

interface TranscriptActionsProps {
  content: Content;
  downloadButtonProps: DownloadButtonProps;
  bookmarkButtonProps: BookmarkButtonProps;
  detailsCollapsed: boolean;
  requestExpertProps: RequestExpertProps;

  watchlistButtonProps?: WatchlistButtonProps;
  audioButtonProps?: AudioButtonProps;
  settingsButtonProps?: SettingsButtonProps;
}

const TranscriptActions: FC<TranscriptActionsProps> = ({
  content,
  downloadButtonProps,
  bookmarkButtonProps,
  audioButtonProps,
  settingsButtonProps,
  requestExpertProps,
  detailsCollapsed,
  watchlistButtonProps = { hasWatchlistButton: true },
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();

  const { isRequestExpertLoading } = useExpertRequest({
    contents: [content],
  });
  const { isLoading: isClientSettingsLoading } = useContentClientSettings();
  const { productType, id, downloadFileName, companies } = content;

  const isRequestExpertButtonLoading = isRequestExpertLoading || isClientSettingsLoading;
  const { audioDownloadStatus, onAudioSelect } = audioButtonProps || {};
  const { hasDownloadButton, projectToken } = downloadButtonProps;
  const { hasWatchlistButton } = watchlistButtonProps;
  const { showSettingsButton, onSettingsClick } = settingsButtonProps || {};
  const { openRequestExpertOverlay, isRequestExpertButtonDisabled, requestExpertButtonText } = requestExpertProps;
  const showWatchlistAddButton = !detailsCollapsed && !isMobile && hasWatchlistButton;

  return (
    <>
      <x.div flex="0 1 auto" display="inline-flex">
        <x.div display="inline-flex" pt="2px" gap={inner.base04}>
          <x.div display="inline-flex" gap={inner.base04} alignItems="center">
            {audioDownloadStatus && onAudioSelect && (
              <AudioButton audioDownloadStatus={audioDownloadStatus} onAudioClick={onAudioSelect} />
            )}
            <CopyLinkButton contentId={id} contentType={productType} />
            <BookmarkButton permissions={[MANAGE_CONTENT_PERMISSION]} {...bookmarkButtonProps} />
            {hasDownloadButton && (
              <DownloadButton
                contentType={productType}
                contentId={id}
                downloadFileName={downloadFileName}
                projectToken={projectToken}
              />
            )}
            {showSettingsButton && onSettingsClick && <SettingsButton onClick={onSettingsClick} />}
          </x.div>
          <RequestExpertButton
            data-testid={DataTestIds.requestExpert}
            buttonActionVariant={RequestExpertButtonType.openModal}
            text={requestExpertButtonText}
            onClick={openRequestExpertOverlay}
            loading={isRequestExpertButtonLoading}
            disabled={isRequestExpertButtonDisabled}
          />
          {showWatchlistAddButton && (
            <WatchlistQuickAddButton
              size="medium"
              cdsAlphaCompanyIds={getAnchorCompanyIds(companies ?? [])}
              contentId={id}
            />
          )}
        </x.div>
      </x.div>
    </>
  );
};

export default TranscriptActions;
