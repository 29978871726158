import { useMemo } from "react";
import { Icon, Link, Popover, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import usePopover from "hooks/usePopover";
import { PRIMER_INFO, PRIMER_WORKING_DAYS, PRIMER_WORKING_DAYS_RUSH } from "../../constants";
import { camelCase } from "lodash";

const CommissionModalPrimerFormat = ({
  format,
  availableLink,
  disabled = false,
  isRush,
}: {
  format: string;
  availableLink: string | null;
  disabled?: boolean;
  isRush: boolean;
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const { anchor, onClose: onClosePopover, onClick: handlePopoverClick, ref } = usePopover();

  const workingDays: string = useMemo(() => {
    const workingDaysKey = isRush ? PRIMER_WORKING_DAYS_RUSH : PRIMER_WORKING_DAYS;
    const workingDays = workingDaysKey[camelCase(format) as keyof typeof PRIMER_INFO];

    return workingDays;
  }, [format, isRush]);

  const primerInfo: string = useMemo(() => PRIMER_INFO[camelCase(format) as keyof typeof PRIMER_INFO], [format]);

  return (
    <x.div display="flex" flexDirection="column" gap={inner.base}>
      <x.div display="flex" gap={inner.base}>
        <Typography variant="body-small-em" color={disabled ? text.disabled : text.strong._}>
          {format}
        </Typography>
        <x.div onMouseEnter={handlePopoverClick}>
          <Icon color={text.secondary} size="small">
            <Info />
          </Icon>
        </x.div>
      </x.div>
      {disabled ? (
        <Typography variant="body-small" color={text.secondary}>
          Available on{" "}
          <Link fontSize="13px" href={availableLink as string} target="_blank">
            AlphaNow
          </Link>
        </Typography>
      ) : (
        <Typography variant="body-small" color={text.secondary}>
          {workingDays}
        </Typography>
      )}
      <Popover
        closeOnMouseLeave={true}
        ref={ref}
        anchorEl={anchor}
        open={!!anchor}
        onClose={onClosePopover}
        leaveDelay={500}
      >
        <x.div w="200px">
          <Typography padding={inner.base02} variant="body-small">
            {primerInfo}
          </Typography>
        </x.div>
      </Popover>
    </x.div>
  );
};

export default CommissionModalPrimerFormat;
