import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { sortBy } from "lodash";
import { OutlookType } from "models/primers/constants";
import { PrimerOutlook } from "pages/AlphaNowPage/primers/components";

const OutlookEntries = ({ entries }: { entries: CitableOutlook[] }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const getOutlooksOfType = (type: string): CitableOutlook[] =>
    sortBy(
      entries.filter((output) => output.impact.value === type),
      (outlook: CitableOutlook) => outlook.outlookOrder.value || 0
    );

  const opportunities = getOutlooksOfType(OutlookType.opportunity);
  const risks = getOutlooksOfType(OutlookType.risk);

  if (!Boolean(risks.length) && !Boolean(opportunities.length)) return null;

  return (
    <x.div my={{ xs: inner.base05, sm: 0 }}>
      {!!opportunities.length && (
        <x.div mb={Boolean(risks.length) ? inner.base06 : 0}>
          {opportunities.map((opportunity) => (
            <PrimerOutlook key={opportunity.id.value} outlook={opportunity} outlookType={OutlookType.opportunity} />
          ))}
        </x.div>
      )}
      {!!risks.length && (
        <>
          {risks.map((risk) => (
            <PrimerOutlook key={risk.id.value} outlook={risk} outlookType={OutlookType.risk} />
          ))}
        </>
      )}
    </x.div>
  );
};

export default OutlookEntries;
