import { x } from "@xstyled/styled-components";
import ReactHtmlParser from "react-html-parser";
import { Pill, useThemeTokens } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";
import { LabeledIcon } from "pages/AlphaNowPage/primers/components";
import { Competitor, Filter } from "./types";

type FiltersListProps = {
  competitors: {
    name: string;
    companies: Competitor[];
  }[];
  filterType: string;
  iconItem: JSX.Element;
  matchingFilters: Filter[];
  mt?: string;
  selectedCompany: string | null;
  selectedFilter?: Filter | null;
  title: string;
  onClick: (filter: Filter) => void;
};

const FiltersList = ({
  competitors,
  filterType,
  iconItem,
  matchingFilters,
  mt = "",
  selectedCompany,
  selectedFilter,
  title,
  onClick,
}: FiltersListProps) => {
  const { color, spacing, typography } = useThemeTokens();

  return (
    <>
      <x.div display="flex" alignItems="center" columnGap={spacing.inner.base} mb={spacing.layout.base} mt={mt}>
        <LabeledIcon
          fontFormat={typography.preTitle}
          iconColor={color.icon.disabled}
          labelColor={color.text.assistive}
          labelText={`${title}${!!selectedCompany ? " -" : ""}`}
          iconItem={iconItem}
          mt={spacing.inner.base}
          mb={spacing.inner.base}
        />
        {!!selectedCompany && (
          <Pill size="small" display="flex">
            <x.div display="flex" alignItems="center" columnGap={spacing.inner.base}>
              <Company />
              {selectedCompany}
            </x.div>
          </Pill>
        )}
      </x.div>
      <x.div display="flex" columnGap="10px" rowGap="10px" flexWrap="wrap">
        {competitors.map(({ name }, index) => (
          <Pill
            data-testid={`${name}-${index}`}
            selected={!!selectedFilter && selectedFilter.type === filterType && selectedFilter.value === name}
            border={`1px solid ${color.border.divider}`}
            backgroundColor={
              !matchingFilters?.some(({ type, value }) => type === filterType && value === name) && "#fff !important"
            }
            onClick={() => onClick({ type: filterType, value: name })}
            cursor="pointer"
            variant={
              matchingFilters?.some(({ type, value }) => type === filterType && value === name) ? "blue" : undefined
            }
          >
            <x.span className="mentions-container" color={color.base.grey[900]}>
              {ReactHtmlParser(name)}
            </x.span>
          </Pill>
        ))}
      </x.div>
    </>
  );
};

export default FiltersList;
