import { Typography } from "@alphasights/alphadesign-components";

type SectionTitleProps = {
  text: string;
  dataTestId?: string;
  heading?: boolean;
  xtraProps?: any;
};

const SectionTitle = ({ text, dataTestId, heading = false, xtraProps }: SectionTitleProps) => (
  <Typography
    color="strong"
    data-testid={dataTestId}
    variant={heading ? "h3" : "body-large-em"}
    style={{ ...xtraProps }}
  >
    {text}
  </Typography>
);

export default SectionTitle;
