import { Button, Divider, IconButton, Popover, Icon, Typography, Tooltip } from "@alphasights/alphadesign-components";
import { Tick, VolumeUp } from "@alphasights/alphadesign-icons";
import { useState, useMemo } from "react";
import { useStyles } from "./RecordingToggler.styles";
import { x } from "@xstyled/styled-components";
import { MiniPlayer } from "./AudioPlayer";
import { FormattedDateTime, FormattedDuration } from "providers/TimezoneProvider";
import { parseISO } from "date-fns";
import { DATE_FORMAT } from "helpers/interactionHelpers";
import { useIsInternalUser } from "@alphasights/portal-auth-react";

export const RecordingToggler = ({
  togglePlayer,
  showingPlayer,
  currentRecording,
  setCurrentRecording,
  availableRecordings,
  selectedTranscriptView,
  controlsProps,
  progressBarProps,
}) => {
  const isInternal = useIsInternalUser();
  const [anchorEl, setAnchorEl] = useState();

  const { popoverStyles, popoverOptionStyles, popoverWrapperStyles, popoverOptionSelectedStyles } = useStyles();

  const renderPopover = useMemo(() => showingPlayer && selectedTranscriptView.toLowerCase() !== "transcript", [
    selectedTranscriptView,
    showingPlayer,
  ]);

  return (
    <>
      {!currentRecording.purgedAt && currentRecording.visibleToClient && (
        <Tooltip title="Recording" disabled={availableRecordings.length > 1 || renderPopover}>
          <IconButton
            ref={setAnchorEl}
            variant="outline"
            onClick={togglePlayer}
            isSelectable
            size="small"
            isSelected={showingPlayer}
            buttonAttributes={{
              "data-testid": "recording-player-toggler",
            }}
          >
            <VolumeUp />
          </IconButton>
        </Tooltip>
      )}

      <Popover anchorEl={anchorEl} open={renderPopover} placement="bottom-end" leaveDelay={500} {...popoverStyles}>
        <x.div {...popoverWrapperStyles}>
          {controlsProps && progressBarProps && (
            <>
              <MiniPlayer controlsProps={controlsProps} progressBarProps={progressBarProps} onClose={togglePlayer} />
              {availableRecordings.length > 1 && isInternal && <Divider ml="-4px" mr="-4px" />}
            </>
          )}
          {availableRecordings.length > 1 &&
            isInternal &&
            availableRecordings.map((recording, ix) => (
              <Button
                key={recording.id}
                variant="ghost"
                data-testid={`recording-toggle-${recording.id}`}
                onClick={() => setCurrentRecording(recording)}
                endIcon={currentRecording?.id === recording.id ? <Icon color="secondary" children={<Tick />} /> : null}
                {...popoverOptionStyles}
                {...(currentRecording === recording && popoverOptionSelectedStyles)}
                aria-selected={currentRecording === recording}
              >
                <Typography variant="body">
                  Recording {ix + 1}
                  {" • "}
                  <FormattedDateTime date={parseISO(recording.createdAt)} format={DATE_FORMAT} />
                  {" • "}
                  <FormattedDuration duration={recording.length} /> min
                </Typography>
              </Button>
            ))}
        </x.div>
      </Popover>
    </>
  );
};
