import React, { useEffect, useMemo } from "react";
import { Icon } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { TranscriptSearchProps } from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/types";
import { View } from "models/Header";
import {
  TranscriptSearchContainer,
  TranscriptSearchBarContainer,
  KeywordFilterContainer,
  SearchKeywordContainer,
  StyledResultsContainer,
  StyledNumResultsContainer,
  StyledNoResultsContainer,
} from "./TranscriptSearchBar.styled";
import { AlphaNowSearchQuery } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { TranscriptSearchBarWithAccess } from "components/SearchBar";
import { loadKeywords } from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/TranscriptSearch/utils";
import { noResultsContent, searchWithinTranscript } from "content/AlphaNow";

const DataTestIds = {
  transcriptSearchBar: "transcriptSearchBar",
  nextMatch: "nextMatch",
  previousMatch: "previousMatch",
};

const TranscriptSearchBar = ({
  searchQuery,
  setSearchQuery,
  viewMode,
  isTranscriptDataReady,
  keywordSearchProps,
  contentTypePermission,
}: TranscriptSearchProps) => {
  const {
    totalKeywordMatches,
    selectedMentionIndex,
    setSelectedMentionIndex,
    selectedComprehensiveSummaryIndex,
    setSelectedComprehensiveSummaryIndex,
  } = keywordSearchProps;

  const isTranscriptView = viewMode === View.Transcript;
  const isSummaryView = viewMode === View.Summary;

  const hasKeywords = searchQuery.length > 0;
  const hasKeywordMatch = totalKeywordMatches > 0;
  const hasMultipleKeywordMatches = totalKeywordMatches > 1;

  const displayKeywordFilter =
    (isTranscriptView || isSummaryView) && isTranscriptDataReady && hasKeywords && totalKeywordMatches !== null;

  const [selectedSearchTermIndex, setSelectedSearchTermIndex] = useMemo(
    () =>
      isSummaryView
        ? [selectedComprehensiveSummaryIndex, setSelectedComprehensiveSummaryIndex]
        : [selectedMentionIndex, setSelectedMentionIndex],
    [
      isSummaryView,
      selectedComprehensiveSummaryIndex,
      selectedMentionIndex,
      setSelectedComprehensiveSummaryIndex,
      setSelectedMentionIndex,
    ]
  );

  const selectedKeywordIndex = selectedSearchTermIndex + 1;

  const findNextMatch = () => {
    const isLastKeywordSelected = selectedSearchTermIndex === totalKeywordMatches - 1;
    const nextIndex = isLastKeywordSelected ? 0 : selectedSearchTermIndex + 1;
    setSelectedSearchTermIndex(nextIndex);
  };

  const findPreviousMatch = () => {
    const isFirstKeywordSelected = selectedSearchTermIndex === 0;
    const previousIndex = isFirstKeywordSelected ? totalKeywordMatches - 1 : selectedSearchTermIndex - 1;
    setSelectedSearchTermIndex(previousIndex);
  };

  const onOptionSelect = (searchQuery: AlphaNowSearchQuery[]) => {
    const query = searchQuery ?? [];
    setSearchQuery(query);
  };

  useEffect(() => {
    if (totalKeywordMatches !== null && totalKeywordMatches < selectedSearchTermIndex) {
      setSelectedSearchTermIndex(0);
    }
  }, [totalKeywordMatches]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TranscriptSearchContainer>
      <TranscriptSearchBarContainer data-testid={DataTestIds.transcriptSearchBar}>
        <TranscriptSearchBarWithAccess
          accessControl={{ allowedPermissions: [contentTypePermission] }}
          loadOptions={loadKeywords}
          onChange={onOptionSelect}
          placeholder={searchWithinTranscript}
          showSeparator={isTranscriptView && hasKeywords}
          value={searchQuery}
        />
      </TranscriptSearchBarContainer>
      {displayKeywordFilter && (
        <KeywordFilterContainer>
          {hasMultipleKeywordMatches && (
            <SearchKeywordContainer>
              <Icon size="medium" onClick={findNextMatch} dataAttributes={{ "data-testid": DataTestIds.nextMatch }}>
                <ChevronDown />
              </Icon>
              <Icon
                size="medium"
                onClick={findPreviousMatch}
                dataAttributes={{ "data-testid": DataTestIds.previousMatch }}
              >
                <ChevronUp />
              </Icon>
            </SearchKeywordContainer>
          )}

          {hasKeywordMatch ? (
            <StyledResultsContainer>
              <StyledNumResultsContainer>{selectedKeywordIndex}</StyledNumResultsContainer>
              <span>/{totalKeywordMatches}</span>
            </StyledResultsContainer>
          ) : (
            <StyledNoResultsContainer>{noResultsContent}</StyledNoResultsContainer>
          )}
        </KeywordFilterContainer>
      )}
    </TranscriptSearchContainer>
  );
};

export { TranscriptSearchBar as default, DataTestIds };
