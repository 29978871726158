import { useQuery } from "query-utils";
import { AlphaNowContentType, AlphaNowProductType, CONTENT_TYPE_DISPLAY_NAME } from "@alphasights/client-portal-shared";

import { AlphaNowAccessLevel } from "models/content/constants";
import { contentService } from "services/content";

type ContentTypeDisplayName = typeof CONTENT_TYPE_DISPLAY_NAME;

type ContentTypeToAccessLevel = {
  contentType: AlphaNowProductType | AlphaNowContentType.alphaviewDialIn;
  contentTypeDisplayName: ContentTypeDisplayName[keyof ContentTypeDisplayName];
  accessLevel: AlphaNowAccessLevel;
};

type ContentAccessLevels = {
  hasOnlyPremiumContent: boolean;
  hasUpcomingContent: boolean;
  contentTypeMapping: ContentTypeToAccessLevel[];
};

export const useContentAccessLevel = () => {
  let hasOnlyPremiumContent = false;
  let hasUpcomingContent = false;
  let contentTypeMapping = [];

  const { data: contentTypeToAccessLevelMap } = useQuery(["content-types-to-access-levels"], () =>
    contentService.fetchContentTypesToAccessLevelsMap()
  );

  if (contentTypeToAccessLevelMap) {
    contentTypeMapping = contentTypeToAccessLevelMap.contentTypeMapping;

    hasOnlyPremiumContent = !contentTypeMapping.find(
      (content: any) => content.accessLevel === AlphaNowAccessLevel.payg
    );

    hasUpcomingContent = !!contentTypeMapping.find(
      (content: any) => content.contentType === AlphaNowProductType.alphaview
    );
  }

  return {
    hasOnlyPremiumContent,
    hasUpcomingContent,
    contentTypeMapping,
  };
};

export default useContentAccessLevel;
export type { ContentAccessLevels, ContentTypeToAccessLevel };
