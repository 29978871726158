import { useCallback, useReducer, useState } from "react";
import { differenceInSeconds } from "date-fns";
import { datadogRum } from "@datadog/browser-rum";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import {
  fetchAndMap,
  HitOrigin,
  interactions as interactionsApi,
  makeEnrichedInteraction,
} from "@alphasights/portal-api-client";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { getInteractionIdFromURL, pushFiltersToURL } from "components/InteractionsFilter/serializer";
import { isPotentialSurveyResponse } from "components/SurveysPage/helpers";
import { currentProjectView } from "helpers/currentView";
import { fetch } from "hooks/useApi";
import { FlyoutMode, NewMessageOrigin } from "pages/InteractionPage/enums";
import { FLYOUT_SECTIONS } from "providers/FlyoutProvider";
import { useLocation, useNavigate } from "router-utils";
import thirdPartyDocumentsApi from "services/thirdPartyDocumentsService";
import { areFiltersEquals } from "utils/compareFilter";
import { createThirdPartyInteraction } from "views/DeliverablesView/helpers";
import { withThunk } from "withThunk";
import { mapExperts, sortInteractionChain } from "./helpers/Interaction";
import useThirdPartyDocsPermissions from "hooks/useThirdPartyDocsPermissions";

export const useInteractionsPageReducer = (initialState) => withThunk(useReducer(reducer, initialState, init));

const emptyFollowUpModal = {
  id: null,
  followUpId: null,
  advisorName: null,
  advisorCompany: null,
  alphaCircleMultiplier: null,
  role: null,
  isModalOpen: false,
};

export const init = ({
  token,
  env,
  appliedFilters,
  currentView,
  showMobileView,
  selectedExpertsIds,
  newMessageType,
  newMessageOrigin,
  hasThirdPartyDocumentsPermissions,
  ...props
}) => {
  const project = Object.assign(
    {},
    {
      exportFileAccess: "INTERNAL",
      badges: [],
    },
    props.project
  );

  const isThirdPartyDocumentsLoading = Boolean(
    !showMobileView && currentView === "deliverables-view" && hasThirdPartyDocumentsPermissions
  );

  return {
    appliedFilters: appliedFilters || {
      status: [],
    },
    searchQuery: [],
    interactions: [],
    thirdPartyDocuments: [],
    experts: [],
    summary: null,
    totalResults: 0,
    isInteractionsLoading: true,
    isThirdPartyDocumentsLoading,
    isBackgroundSyncRunning: true,
    interactionsError: null,
    isProjectSettingsOpen: false,
    isUpdatingProjectLevelSettings: false,
    project,
    token,
    schedulingAdvisor: null,
    selectedCardId: null,
    isFlyoutOpen: false,
    env,
    relevantStatementType: "MARKDOWN",
    showCalendarView: project.active,
    showMessagesView: project.enablePortalMessages && project.active,
    showMobileView,
    followUpModal: { ...emptyFollowUpModal },
    clientRequests: [],
    clientAvailability: [],
    projects: props.project ? [props.project] : [],
    chainInteractions: [],
    outOfFilterInteractions: [],
    scheduledInteractions: [],
    researchRequest: null,
    trackUserAction: props.trackUserAction,
    upgrades: [],
    loadingUpgrades: false,
    flyoutAction: null,
    workstreamId: props.workstreamId,
    runningAction: null,
    projectWithInteractions: props.project,
    selectedExpertsIds,
    newMessageOrigin,
    newMessageType,
  };
};

export const reducer = (state, action) => {
  const newState = reducerCompute(state, action);
  const interactionsChanged = newState.interactions !== state.interactions;
  const experts = interactionsChanged ? mapExperts(newState.interactions) : newState.experts;
  return { ...newState, experts };
};

export const INTERACTIONS_FIELDS = [
  "interactions",
  "chainInteractions",
  "outOfFilterInteractions",
  "scheduledInteractions",
];

export const reducerCompute = (state, action) => {
  const updateInteraction = ({ id, ...attrs }, interactions) => {
    return interactions.map((interaction) => {
      if (interaction.id !== id) {
        return interaction;
      } else {
        const prevPriorityHit = interaction.priorityHit;
        return {
          ...interaction,
          ...attrs,
          priorityHit: attrs.priorityHit ?? prevPriorityHit,
        };
      }
    });
  };

  const interactionsByUpdating = (attrs) => {
    return INTERACTIONS_FIELDS.reduce((acc, field) => {
      return {
        ...acc,
        [field]: updateInteraction(attrs, state[field] || []),
      };
    }, {});
  };

  const interactionsByUpdatingMultiple = (items, attrs) => {
    const apply = (items, attrs, interactions) =>
      items.reduce((interactions, data) => updateInteraction({ ...data, ...attrs }, interactions), interactions);

    return INTERACTIONS_FIELDS.reduce((acc, field) => {
      return {
        ...acc,
        [field]: apply(items, attrs, state[field]),
      };
    }, {});
  };

  const interactionsMap = (transformFunc, interactions = state) => {
    return INTERACTIONS_FIELDS.reduce((acc, field) => {
      return {
        ...acc,
        [field]: transformFunc(interactions[field]),
      };
    }, {});
  };

  switch (action.type) {
    case "reset": {
      return init(action.data);
    }
    case "onSearchSyncStart": {
      return { ...state, ...action.data, isBackgroundSyncRunning: true };
    }
    case "onSearchStart": {
      return { ...state, ...action.data, isInteractionsLoading: true };
    }
    case "onSearchSyncEnd": {
      const { interactions, currentFilters } = action.data;

      const newInteractions = areFiltersEquals(state.appliedFilters, currentFilters)
        ? // if the current filters are the same as the ones on sync-find, just update the interactions
          { interactions: interactions.map((i) => maskTargetCompany(i)) }
        : // if not, keep the current interactions and add a `postSyncSearch` flag.
          // This will fire a new "find" request with current filters.
          { postSyncSearch: true };

      return {
        ...state,
        isBackgroundSyncRunning: false,
        ...newInteractions,
      };
    }
    case "onSearchSyncError": {
      return {
        ...state,
        isBackgroundSyncRunning: false,
      };
    }
    case "onSearchEnd": {
      const { interactions } = action.data;
      return {
        ...state,
        isInteractionsLoading: false,
        postSyncSearch: false,
        interactions: interactions.map((i) => maskTargetCompany(i)),
      };
    }
    case "onProjectSettingsModeChange": {
      return {
        ...state,
        isProjectSettingsOpen: action.data,
      };
    }
    case "onUpdateProjectLevelFlagStart":
      return {
        ...state,
        isUpdatingProjectLevelSettings: true,
      };
    case "onUpdateProjectLevelFlagEnd": {
      const { key, newValue, impactedInteractions } = action.data;

      const newInteractions = interactionsByUpdatingMultiple(impactedInteractions, {
        [key]: newValue,
      });

      return {
        ...state,
        ...newInteractions,
        isUpdatingProjectLevelSettings: false,
        isProjectSettingsOpen: false,
      };
    }
    case "onUpdateInteractionPCCFlag": {
      const { id, peerContentContributor } = action.data;

      return {
        ...state,
        ...interactionsByUpdating({ id, peerContentContributor }),
      };
    }
    case "onUpdateInteractionPTLFlag": {
      const { id, privateTranscriptContributor } = action.data;

      return {
        ...state,
        ...interactionsByUpdating({ id, privateTranscriptContributor }),
      };
    }
    case "onUpdateInteractionTranscriptTargetLanguage": {
      const { id, transcriptTargetLanguage } = action.data;

      return {
        ...state,
        ...interactionsByUpdating({ id, transcriptTargetLanguage }),
      };
    }
    case "onSearchError": {
      return {
        ...state,
        isInteractionsLoading: false,
        interactions: [],
        interactionsError: action.error,
      };
    }
    case "onUpdateInteractionFlagError": {
      return { ...state };
    }
    case "onLoadFilterOptionsStart": {
      return {
        ...state,
        isFilterOptionsLoading: true,
      };
    }
    case "onLoadFilterOptionsEnd": {
      const { filterOptions, totalSize } = action.data;

      return {
        ...state,
        isFilterOptionsLoading: false,
        filterOptions: filterOptions,
        totalResults: totalSize,
      };
    }
    case "onLoadFilterOptionsError": {
      return {
        ...state,
        isFilterOptionsLoading: false,
      };
    }
    case "onSelectCard": {
      const selectedId = action.data?.id;
      const scrollToCardId = action.data?.scrollToCardId;
      const originalInteractionId = action.data?.originalInteractionId;
      const isFlyoutOpen = selectedId != null;

      return {
        ...state,
        isFlyoutOpen,
        selectedCardId: originalInteractionId || selectedId,
        scrollToCardId,
        flyoutMode: action.mode,
        flyoutAction: action.data?.flyoutAction,
        chainInteractions: !state.isFlyoutOpen && isFlyoutOpen ? [] : state.chainInteractions,
      };
    }
    case "onMarkAsViewed": {
      return {
        ...state,
        ...interactionsByUpdating({ id: action.data.id, newlyAdded: false }),
      };
    }
    case "onPreSelectInteractionCalendarView": {
      return {
        ...state,
        isFlyoutOpen: false,
      };
    }
    case "onPreSelectExpertMessagesView": {
      const { advisorId } = action.data;
      return {
        ...state,
        preselectedExpertId: advisorId,
      };
    }
    case "onRequestAdvisorStart": {
      const { id, runningAction } = action.data;
      return {
        ...state,
        ...interactionsByUpdating({ id, runningAction }),
      };
    }
    case "onHideAdvisorStart":
    case "onStarAdvisorStart":
    case "onRequestTranscriptStart":
    case "onRequestMoreAvailabilityStart":
    case "onCancelTranscriptStart":
    case "onRequestFollowUpStart":
    case "onRequestTranscriptPostCallStart":
    case "onUpdateFollowUpStart":
    case "onSubmitSchedulingStart":
    case "onRequestChangeInteractionStart":
    case "onCancelRequestChangeInteractionStart":
    case "onSubmitInvitationStart": {
      const { id, runningAction } = action.data;
      return {
        ...state,
        ...interactionsByUpdating({ id, runningAction }),
      };
    }
    case "onHideAdvisorEnd":
    case "onStarAdvisorEnd":
    case "onRequestTranscriptEnd":
    case "onCancelTranscriptEnd": {
      const { data } = action;
      const interactions = Array.isArray(data)
        ? interactionsByUpdatingMultiple(data, { runningAction: null })
        : interactionsByUpdating({ ...data, runningAction: null });

      return {
        ...state,
        ...interactions,
      };
    }
    case "onRequestTranscriptPostCallEnd": {
      const { id, recordings } = action.data;

      return {
        ...state,
        ...interactionsByUpdating({ id, runningAction: null, recordings }),
      };
    }
    case "onUpdateFollowUpEnd": {
      const { data } = action;

      const interactions = Array.isArray(data)
        ? interactionsByUpdatingMultiple(data, { runningAction: null })
        : interactionsByUpdating({ ...data, runningAction: null });

      return {
        ...state,
        ...interactions,
        followUpModal: { ...state.followUpModal, submitted: true },
      };
    }
    case "onCloseFollowUpModal": {
      return { ...state, followUpModal: { ...emptyFollowUpModal } };
    }
    case "onCancelRequestChangeInteractionEnd": {
      const {
        data: { id, requestId },
      } = action;

      const interactions = interactionsByUpdating({
        id,
        runningAction: null,
      });

      const clientRequests = state.clientRequests.filter((old) => old.requestId !== requestId);

      return {
        ...state,
        ...interactions,
        clientRequests,
      };
    }
    case "onSubmitClientAvailabilityEnd": {
      const { data: updatedInteractions } = action;

      const interactions = interactionsByUpdatingMultiple(updatedInteractions, {});

      return {
        ...state,
        ...interactions,
      };
    }
    case "onSubmitSchedulingEnd": {
      const { data } = action;

      const interactions = interactionsByUpdating({
        ...data,
        runningAction: null,
      });

      const clientRequests = data.scheduled
        ? state.clientRequests.filter((old) => old.interactionId !== data.id || old.type !== "AVAILABILITY_REQUEST")
        : state.clientRequests;

      return {
        ...state,
        ...interactions,
        schedulingAdvisor: null,
        clientRequests,
      };
    }
    case "onSubmitSchedulingError": {
      const { data } = action;

      const interactions = interactionsByUpdating({
        ...data,
        runningAction: null,
      });

      return {
        ...state,
        ...interactions,
      };
    }
    case "onJustRequestAdvisorEnd": {
      const { data } = action;
      const interactions = interactionsByUpdating({
        ...data,
        runningAction: null,
      });
      return {
        ...state,
        ...interactions,
      };
    }
    case "onRequestAdvisorEnd": {
      const { data, skipPopup } = action;
      const interactions = interactionsByUpdating({
        ...data,
        runningAction: null,
      });
      return {
        ...state,
        ...interactions,
        schedulingAdvisor: skipPopup ? null : findInteraction(data.id, interactions),
      };
    }
    case "onRequestAdvisorError": {
      const { data } = action;
      const interactions = interactionsByUpdating({
        ...data,
        runningAction: null,
      });
      return {
        ...state,
        ...interactions,
      };
    }
    case "onCancelRequestAdvisorStart": {
      const { data } = action;
      const interactions = interactionsByUpdating({
        ...data,
        runningAction: "cancelRequest",
      });
      return {
        ...state,
        ...interactions,
        schedulingAdvisor: state.schedulingAdvisor && findInteraction(data.id, interactions),
      };
    }
    case "onCancelRequestAdvisorEnd": {
      const { data } = action;
      let interactions = interactionsByUpdating({
        ...data,
        runningAction: null,
      });

      if (data.remove) {
        interactions = interactionsMap((interactions) => {
          return interactions
            .filter(({ id }) => id !== data.id)
            .map(({ followUpId, ...props }) => ({
              ...props,
              followUpId: followUpId === data.id ? null : followUpId,
            }));
        }, interactions);
      }

      return {
        ...state,
        ...interactions,
        schedulingAdvisor: null,
        isFlyoutOpen: data.remove ? false : state.isFlyoutOpen,
      };
    }
    case "onRequestChangeInteractionEnd": {
      const { id, data } = action;
      const interactions = interactionsByUpdating({
        id,
        runningAction: null,
      });

      const clientRequests =
        data?.type === "CANCEL_REQUEST"
          ? state.clientRequests.filter((requests) => requests.interactionId !== data.interactionId)
          : state.clientRequests;

      return {
        ...state,
        ...interactions,
        clientRequests: data ? [...clientRequests, data] : clientRequests,
      };
    }
    case "onRequestFollowUpEnd": {
      const { originalId, data, skipPopup } = action;
      const interactions = interactionsByUpdating({
        id: originalId,
        followUpId: data.id,
        runningAction: null,
      });

      const interaction = findInteraction(originalId, interactions);
      interactions.interactions = [...interactions.interactions, { ...data, projectToken: interaction.projectToken }];

      const followUpModal = skipPopup
        ? null
        : {
            id: originalId,
            followUpId: data.id,
            advisorName: interaction.advisorName,
            advisorCompany: interaction.advisorCompany,
            alphaCircleMultiplier: interaction.alphaCircleMultiplier,
            role: interaction.role,
            isActiveProject: interaction.isActiveProject,
            isModalOpen: true,
          };

      return {
        ...state,
        ...interactions,
        followUpModal,
      };
    }
    case "onCancelFollowUpStart": {
      const { data } = action;
      const interactions = interactionsByUpdating({
        ...data,
        runningAction: "cancelRequest",
      });
      return {
        ...state,
        ...interactions,
        schedulingAdvisor: state.schedulingAdvisor && findInteraction(data.id, interactions),
      };
    }
    case "onCancelFollowUpEnd": {
      const { data } = action;

      let interactions = interactionsByUpdating({
        ...data,
        runningAction: null,
      });

      if (data.remove) {
        interactions = interactionsMap((interactions) => {
          return interactions
            .filter(({ id }) => id !== data.id)
            .map(({ followUpId, ...props }) => ({
              ...props,
              followUpId: followUpId === data.id ? null : followUpId,
            }));
        }, interactions);
      }

      return {
        ...state,
        ...interactions,
        schedulingAdvisor: null,
        followUpModal: { ...emptyFollowUpModal },
      };
    }
    case "onSchedulingStart": {
      const { skipPopup, data } = action;
      return {
        ...state,
        schedulingAdvisor: skipPopup ? null : findInteraction(data.id, state),
        requestedScheduleDate: data.requestedScheduleDate,
      };
    }
    case "onSchedulingClose": {
      return {
        ...state,
        schedulingAdvisor: null,
      };
    }
    case "onSelectExperts": {
      const { data } = action;
      return {
        ...state,
        selectedExpertsIds: data,
      };
    }
    case "onSubmitInvitationEnd": {
      const { data } = action;
      const interactions = interactionsByUpdating({
        ...data,
        calendarInvitationAttendees: data.attendees,
        runningAction: null,
      });

      return {
        ...state,
        ...interactions,
      };
    }
    case "onChangeLayout": {
      return {
        ...state,
        currentView: action.data,
      };
    }
    case "onChangeInteractionChainDate": {
      const { interactionChainDate } = action.data;
      return {
        ...state,
        interactionChainDate,
      };
    }
    case "onCloseFlyout": {
      return {
        ...state,
        isFlyoutOpen: false,
      };
    }
    case "onClientRequestLoadedEnd": {
      return {
        ...state,
        clientRequests: action.data,
      };
    }
    case "onSearchProjectsStart": {
      return {
        ...state,
        searchProjectsLoading: true,
      };
    }
    case "onSearchProjectsEnd": {
      return {
        ...state,
        searchProjectsLoading: false,
      };
    }
    case "onProjectsReceived": {
      return {
        ...state,
        projects: action.data,
      };
    }
    case "onLoadAdvisorInteractionsStart": {
      return {
        ...state,
        runningAction: "loadingAdvisorInteractions",
      };
    }
    case "onLoadAdvisorInteractionsEnd": {
      const updatedOriginal = action.data.interactions.find((i) => i.id === action.data.originalId) || {};
      return {
        ...state,
        ...interactionsByUpdating({
          id: action.data.originalId,
          ...updatedOriginal,
        }),
        runningAction: null,
        chainInteractions: action.data.interactions,
      };
    }
    case "onLoadAdvisorResearchRequestStart": {
      return {
        ...state,
        ...interactionsByUpdating({
          id: action.data,
          runningAction: "loadingAdvisorResearchRequest",
        }),
      };
    }
    case "onLoadAdvisorResearchRequestEnd": {
      return {
        ...state,
        ...interactionsByUpdating({
          id: action.data.interactionId,
          runningAction: null,
        }),
        researchRequest: action.data.researchRequest,
      };
    }
    case "onLoadAdvisorResearchRequestError": {
      return {
        ...state,
        ...interactionsByUpdating({
          id: action.data.interactionId,
          runningAction: null,
        }),
        researchRequest: null,
      };
    }
    case "onOutOfFilterInteractionsEnd": {
      return {
        ...state,
        outOfFilterInteractions: [...state.outOfFilterInteractions, ...action.data],
      };
    }
    case "onScheduledInteractions": {
      return {
        ...state,
        scheduledInteractions: action.data,
      };
    }
    case "onClientAvailabilityLoadedEnd": {
      state.interactions.forEach((interaction) => {
        interaction.clientTimeslots =
          action.data.filter((availability) => availability.advisorshipId === interaction.id) ?? [];
      });

      return {
        ...state,
        clientAvailability: action.data,
      };
    }
    case "onFindProjectInteractionsEnd": {
      return {
        ...state,
        projectWithInteractions: {
          ...state.projectWithInteractions,
          interactions: action.data,
        },
      };
    }
    case "onFlyoutModeChange": {
      return {
        ...state,
        flyoutMode: action.data,
      };
    }
    case "onNewMessageTypeChange": {
      return {
        ...state,
        newMessageType: action.data,
      };
    }
    case "onNewMessageContentChange": {
      return {
        ...state,
        newMessageContent: action.data,
      };
    }
    case "onNewMessageOriginChange": {
      return {
        ...state,
        newMessageOrigin: action.data,
      };
    }
    case "onRequestMoreAvailabilityEnd": {
      return {
        ...state,
        ...interactionsByUpdating({
          id: action.data.interactionId,
          runningAction: null,
        }),
        clientRequests: [...state.clientRequests, action.data],
      };
    }
    case "onLoadUpgradesStart":
      return {
        ...state,
        loadingUpgrades: true,
      };
    case "onLoadUpgradesEnd":
      return {
        ...state,
        loadingUpgrades: false,
        upgrades: action.data,
      };
    case "onEditUpgradesEnd":
      return {
        ...state,
        upgrades: action.data.upgrades,
        ...interactionsByUpdating({
          id: action.data.id,
          needsInterpreter: action.data.needsInterpreter,
        }),
      };
    case "onFetchThirdPartyDocumentsStart":
      return {
        ...state,
        isThirdPartyDocumentsLoading: true,
      };
    case "onFetchThirdPartyDocumentsEnd":
      return {
        ...state,
        thirdPartyDocuments: action.data?.map(createThirdPartyInteraction) ?? [],
        isThirdPartyDocumentsLoading: false,
      };
    default:
      throw new Error(`${action.type} not found`);
  }
};

export const findInteraction = (id, state) => {
  for (const field of INTERACTIONS_FIELDS) {
    const find = (state[field] || []).find((interaction) => interaction.id === id);
    if (find) return find;
  }
  return null;
};

function maskTargetCompany(interaction) {
  const isTargetCompanyAngle = (angle) => angle?.type?.name.match(/Target/i);

  return {
    ...interaction,
    angles: (interaction.angles || []).map((angle) =>
      isTargetCompanyAngle(angle)
        ? {
            ...angle,
            type: {
              ...angle.type,
              name: "Competitors",
              targetCompany: true,
            },
          }
        : angle
    ),
  };
}

function searchSync({ appliedFilters, searchQuery, currentView }) {
  return (dispatch, state) => {
    const { showMobileView } = state;
    const tableView = currentView === "table-view";
    const deliverablesView = currentView === "deliverables-view";
    const comparisonView = currentView === "comparison-view";
    const { project } = state;

    const currentFilters = getEffectiveFilters({
      appliedFilters,
      deliverablesView,
      showMobileView,
      tableView,
      comparisonView,
    });

    dispatch({ type: "onSearchSyncStart" });

    return interactionsApi
      .searchSync({
        project,
        searchQuery,
        appliedFilters: currentFilters,
      })
      .then(({ interactions, data }) => {
        dispatch({
          type: "onSearchSyncEnd",
          data: { ...data, interactions, currentFilters },
        });
        return { interactions };
      })
      .catch(() => {
        dispatch({ type: "onSearchSyncError" });
        return { interactions: [] };
      });
  };
}

export function searchProjects({ projects }) {
  return async (dispatch) => {
    dispatch({ type: "onProjectsReceived", data: projects });
    dispatch({ type: "onSearchProjectsStart" });

    const interactions = await interactionsApi.searchProjects({ projects });

    dispatch({ type: "onSearchProjectsEnd" });
    dispatch({
      type: "onScheduledInteractions",
      data: interactions,
    });

    return interactions;
  };
}

const getExpertCommentData = (location) => {
  const search = new URLSearchParams(location.search);

  const expertId = search.get("commentExpertId");
  const angleId = search.get("commentAngleId");

  if (expertId && angleId) return { expertId, angleId };

  return null;
};

const checkCommentRoute = (location, navigate, token, interactions = false) => {
  const commentData = getExpertCommentData(location);

  if (commentData) {
    const { expertId, angleId } = commentData;

    const suitableCommentInteraction = interactions.find((i) => {
      const angle = i.angles[0];
      return i.advisorId === expertId && angle?.id === angleId;
    });

    if (suitableCommentInteraction) {
      navigate(`/${token}/experts/${suitableCommentInteraction.id}/comments`);

      return suitableCommentInteraction.id;
    }
  }

  return null;
};

const getEffectiveFilters = ({ appliedFilters, deliverablesView, showMobileView, tableView, comparisonView }) => {
  var showHiddenExperts = appliedFilters.profile_activity && appliedFilters.profile_activity.includes("Hidden");
  if (deliverablesView || showMobileView || tableView || comparisonView)
    return {
      ...appliedFilters,
      profile_activity: [...(appliedFilters.profile_activity || []), showHiddenExperts ? "" : "HiddenOrNot"],
    };

  return appliedFilters;
};

export function search({ appliedFilters, searchQuery, location, navigate }, { updateURL, replace } = {}) {
  return (dispatch, state) => {
    dispatch({ type: "onSearchStart", data: { appliedFilters, searchQuery } });
    const { showMobileView } = state;
    const currentPage = currentProjectView(location.pathname);
    const tableView = currentPage === "table-view";
    const comparisonView = currentPage === "comparison-view";
    const deliverablesView = currentPage === "deliverables-view";
    const urlParams = new URLSearchParams();
    urlParams.append("keywords", searchQuery.length > 0 ? searchQuery : "");
    let url = `/api/projects/${state.token}/interactions/find?${urlParams.toString()}${
      deliverablesView ? "&searchTranscripts=true" : ""
    }`;
    if (updateURL && currentPage !== "messages-view") {
      pushFiltersToURL(navigate, location, replace, appliedFilters);
    }

    const effectiveFilters = getEffectiveFilters({
      appliedFilters,
      deliverablesView,
      showMobileView,
      tableView,
      comparisonView,
    });

    return fetchAndMap(url, effectiveFilters, state.project)
      .then(({ interactions, data }) => {
        dispatch({
          type: "onSearchEnd",
          data: { ...data, interactions },
        });

        return { interactions };
      })
      .then(({ interactions }) => {
        let id = getInteractionIdFromURL(location) || checkCommentRoute(location, navigate, state.token, interactions);

        const flyoutMode = location.pathname?.endsWith("comments") ? FlyoutMode.Comments : null;

        if (id) {
          const card = interactions.find((interaction) => interaction.id.toString() === id.toString());

          const promise = card
            ? Promise.resolve(card)
            : fetchAndMap(
                `/api/projects/${state.token}/interactions/find`,
                {
                  ids: [id],
                },
                state.project
              ).then(({ interactions }) => {
                if (interactions.length === 1) {
                  dispatch({
                    type: "onOutOfFilterInteractionsEnd",
                    data: interactions,
                  });
                  return interactions[0];
                }
                return null;
              });

          promise.then((interaction) => {
            if (!interaction) return;

            if (currentPage === "messages-view") {
              dispatch({
                type: "onPreSelectExpertMessagesView",
                data: { advisorId: interaction.advisorId },
              });
              navigate(`/${state.token}/experts/messages-view`, { replace: true });
              return;
            }

            dispatch({
              type: "onSelectCard",
              data: { ...interaction, scrollToCardId: id },
              mode: flyoutMode,
            });
            if (
              interaction.state === "proposed" &&
              (location.search.includes("auto-request") || location.search.includes("auto_request"))
            ) {
              navigate(location.pathname);
              requestAdvisor({ id }, state.trackUserAction.logHit)(dispatch, {
                token: state.token,
              });
            }
          });
        }
        return { interactions };
      })
      .then(({ interactions }) => {
        const context = datadogRum.getGlobalContext();
        if (context.init_time) {
          const firstCardListRenderTime = window.performance.now() - context.init_time;
          datadogRum.addAction("initial_rendering_times", {
            time_to_render_cards: firstCardListRenderTime,
            number_of_cards: interactions.length,
            time_to_render_filter: context.time_to_render_filter_temp,
            project_size: context.project_size,
          });
          // if we don't clean up, these values will be sent in all subsequent metrics
          datadogRum.removeGlobalContextProperty("init_time");
          datadogRum.removeGlobalContextProperty("time_to_render_filter_temp");
          datadogRum.removeGlobalContextProperty("project_size");
        }
      })
      .then(() => {
        const userAppliedFilters =
          searchQuery.length > 0 ||
          (appliedFilters && Object.values(appliedFilters).some((value) => Array.isArray(value) && value.length > 0));

        if (userAppliedFilters)
          state.trackUserAction.logHit({
            origin: currentPage,
            action: "ADVISOR_SEARCH",
            projectToken: state.token,
            details: { searchQuery },
            references: { appliedFilters },
          });
      });
  };
}

export function getFilterOptions() {
  return (dispatch, { token }) => {
    let url = `/api/projects/${token}/interactions/filters`;
    dispatch({ type: "onLoadFilterOptionsStart" });

    fetch({
      url,
    })
      .then((res) => res.json())
      .then(({ summary = [], ...data }) => {
        const filterOptions = summary.reduce(
          (acc, { name, items }) => ({
            ...acc,
            [name]: items.map((item) => ({
              ...item,
              value: item.id ? item.id : item.label,
              children: item.children?.map((child) => {
                return {
                  ...child,
                  value: child.id ? child.id : child.label,
                };
              }),
            })),
          }),
          {}
        );

        dispatch({
          type: "onLoadFilterOptionsEnd",
          data: { ...data, filterOptions },
        });
        return { ...data };
      })
      .then(({ totalSize }) => {
        const context = datadogRum.getGlobalContext();
        if (context.init_time) {
          const filterRenderTimeNs = window.performance.now() - context.init_time;
          datadogRum.setGlobalContext({ time_to_render_filter_temp: filterRenderTimeNs });
          datadogRum.setGlobalContext({ project_size: totalSize });
        }
      })
      .catch(() => {
        dispatch({ type: "onLoadFilterOptionsError" });
      });
  };
}

export function requestFollowUp({ id, skipPopup = false, origin }) {
  return (dispatch, state) => {
    const { project, trackUserAction } = state;

    dispatch({
      type: "onRequestFollowUpStart",
      data: { id, runningAction: "followUp" },
    });

    return fetch({
      url: `/api/projects/${project.token}/interactions/${id}/followup`,
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        trackUserAction.logHit({
          origin: origin,
          action: "REQUEST_FOLLOW_UP",
          advisorshipId: id,
          projectToken: project.token,
        });

        dispatch({
          type: "onRequestFollowUpEnd",
          data: makeEnrichedInteraction(data, {}, project),
          originalId: id,
          skipPopup,
        });
        return {
          ...data,
          flyoutAction: FLYOUT_SECTIONS.expandAvailability,
        };
      });
  };
}

export function hideAdvisor({ id, hidden, origin }) {
  return (dispatch, state) => {
    const interaction = findInteraction(id, state);

    dispatch({
      type: "onHideAdvisorStart",
      data: { id, runningAction: "toggleHidden" },
    });

    return fetch({
      url: `/api/projects/${interaction.projectToken}/interactions/${id}/hidden`,
      method: hidden ? "POST" : "DELETE",
    })
      .then((res) => res.json())
      .then((data) => dispatch({ type: "onHideAdvisorEnd", data }))
      .then(() => {
        state.trackUserAction.logHit({
          origin: origin,
          action: hidden ? "HIDE_ADVISOR" : "UNHIDE_ADVISOR",
          advisorshipId: id,
          projectToken: interaction.projectToken,
        });
      });
  };
}

export function saveHideExpertReason({ id, reason, customReason, origin }) {
  return (dispatch, state) => {
    const interaction = findInteraction(id, state);

    return fetch({
      url: `/api/projects/${interaction.projectToken}/comments/on-expert-hide`,
      method: "POST",
      body: JSON.stringify({
        expertId: interaction.advisorId,
        angleId: interaction.angles[0].id,
        hideReason: reason,
        customHideReason: customReason,
      }),
    }).then(() => {
      state.trackUserAction.logHit({
        origin: origin,
        action: "HIDE_EXPERT_REASON",
        details: { reason: reason, customReason: customReason },
        advisorshipId: id,
        projectToken: interaction.projectToken,
      });
    });
  };
}

export function starAdvisor({ id, starred, origin }) {
  return (dispatch, state) => {
    const interaction = findInteraction(id, state);

    dispatch({
      type: "onStarAdvisorStart",
      data: { id, runningAction: "toggleStar" },
    });

    return interactionsApi
      .starAdvisor({
        starred,
        interactionId: id,
        projectToken: interaction.projectToken,
      })
      .then((data) => {
        dispatch({ type: "onStarAdvisorEnd", data });
      })
      .then(() => {
        state.trackUserAction.logHit({
          origin: origin,
          action: starred ? "STAR_ADVISOR" : "UNSTAR_ADVISOR",
          advisorshipId: id,
          projectToken: interaction.projectToken,
        });
      });
  };
}

export function requestAdvisor({ id, origin = "unknown", skipPopup = false }) {
  return (dispatch, state) => {
    const { token, isFlyoutOpen, trackUserAction } = state;

    const interaction = findInteraction(id, state);

    if (interaction?.state === "requested") {
      return Promise.resolve();
    } else {
      dispatch({
        type: "onRequestAdvisorStart",
        data: { id, runningAction: "request" },
      });

      return interactionsApi
        .requestAdvisor({ interactionId: id, projectToken: token })
        .then((data) => dispatch({ type: "onRequestAdvisorEnd", data, skipPopup }))
        .then(() =>
          trackUserAction.logHit({
            origin: isFlyoutOpen ? `${origin}-flyout` : origin,
            action: "REQUEST_ADVISOR",
            advisorshipId: id,
            projectToken: token,
          })
        )
        .catch(() => {
          dispatch({ type: "onRequestAdvisorError", data: { id } });
        });
    }
  };
}

export function justRequestAdvisor({ id }) {
  return (dispatch, { token, trackUserAction }) => {
    dispatch({
      type: "onRequestAdvisorStart",
      data: { id, runningAction: "request" },
    });

    return fetch({
      url: `/api/projects/${token}/interactions/${id}/request`,
      method: "POST",
      handleForbidden: true,
    })
      .then((res) => res.json())
      .then((data) => dispatch({ type: "onJustRequestAdvisorEnd", data }))
      .then(() =>
        trackUserAction.logHit({
          origin: "calendar-view-popup",
          action: "REQUEST_ADVISOR",
          advisorshipId: id,
          projectToken: token,
        })
      )
      .catch(() => {
        dispatch({ type: "onRequestAdvisorError", data: { id } });
      });
  };
}

export function cancelRequestAdvisor({ id, origin }) {
  return (dispatch, state) => {
    const { trackUserAction, token } = state;
    dispatch({
      type: "onCancelRequestAdvisorStart",
      data: { id, runningAction: "cancelRequest" },
    });

    return fetch({
      url: `/api/projects/${token}/interactions/${id}/request`,
      method: "DELETE",
    })
      .then((res) => res.text())
      .then((text) => (text.length ? JSON.parse(text) : { id, remove: true }))
      .then((data) => {
        trackUserAction.logHit({
          origin: origin,
          action: "CANCEL_INTERACTION_REQUEST",
          advisorshipId: id,
          projectToken: token,
        });

        dispatch({ type: "onCancelRequestAdvisorEnd", data });
        return data;
      });
  };
}

export function updateFollowUp({ id, ...props }) {
  return (dispatch, { token }) => {
    dispatch({
      type: "onUpdateFollowUpStart",
      data: { id, runningAction: "followUp" },
    });

    return fetch({
      url: `/api/projects/${token}/interactions/${id}/followup`,
      method: "PATCH",
      body: JSON.stringify(props),
    })
      .then((res) => res.json())
      .then((data) => dispatch({ type: "onUpdateFollowUpEnd", data }));
  };
}

export function cancelFollowUp({ id }) {
  return (dispatch, state) => {
    const { trackUserAction, token } = state;
    dispatch({
      type: "onCancelFollowUpStart",
      data: { id, runningAction: "cancelRequest" },
    });

    return fetch({
      url: `/api/projects/${token}/interactions/${id}/request`,
      method: "DELETE",
    })
      .then((res) => res.text())
      .then((text) => (text.length > 0 ? JSON.parse(text) : { id, remove: true }))
      .then((data) => {
        trackUserAction.logHit({
          origin: origin,
          action: "CANCEL_INTERACTION_REQUEST",
          advisorshipId: id,
          projectToken: token,
        });

        dispatch({ type: "onCancelFollowUpEnd", data });
      });
  };
}

export function closeFollowUpModal(data) {
  return {
    type: "onCloseFollowUpModal",
    data,
  };
}

export function startScheduling({ skipPopup = false, ...data }) {
  return {
    type: "onSchedulingStart",
    data,
    skipPopup,
  };
}

export function closeScheduling(data) {
  return {
    type: "onSchedulingClose",
    data,
  };
}

export function submitClientAvailability({
  interactionIds,
  slots,
  timezone,
  projectWide = false,
  skipLog = false,
  origin,
}) {
  return (dispatch, state) => {
    const { token, trackUserAction } = state;
    const body = {
      interactionIds,
      clientTimeslots: slots,
      timezone,
      projectWide,
    };

    return fetch({
      url: `/api/projects/${token}/availability`,
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!skipLog) {
          trackClientAvailability({
            trackUserAction,
            action: slots?.length > 0 ? "SUBMIT_CLIENT_AVAILABILITY" : "REMOVE_CLIENT_AVAILABILITY",
            origin: origin,
            projectToken: token,
            projectWide,
            interactionIds,
          });
        }

        return dispatch({ type: "onSubmitClientAvailabilityEnd", data });
      });
  };
}

export function requestMoreAvailability({ interactionId, advisorId }) {
  return (dispatch, state) => {
    const { token } = state;
    const body = {
      interactionId,
      advisorId,
    };

    dispatch({
      type: "onRequestMoreAvailabilityStart",
      data: { id: interactionId, runningAction: "requestMoreAvailability" },
    });

    return fetch({
      url: `/api/projects/${token}/availability/request-more`,
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        return dispatch({
          type: "onRequestMoreAvailabilityEnd",
          data,
        });
      });
  };
}

function trackClientAvailability({
  trackUserAction,
  action = "SUBMIT_CLIENT_AVAILABILITY",
  origin,
  projectToken,
  projectWide = false,
  interactionIds = [],
  angleIds = [],
}) {
  trackUserAction.logHit({
    origin: origin,
    action: action,
    projectToken: projectToken,
    details: {
      projectWide: projectWide,
    },
    references: {
      interactionIds: interactionIds,
      angleIds: angleIds,
    },
  });
}

export function submitScheduling(
  { mode, slots, timezone, interactionIds, projectWide = false },
  { isLoggedIn, origin = "unknown", skipPopup = false, matchedAvailability = false }
) {
  return async (dispatch, state) => {
    const { token, schedulingAdvisor, isFlyoutOpen, trackUserAction } = state;
    const ids = interactionIds || [schedulingAdvisor.id];

    const expectedDuration = differenceInSeconds(slots[0].endsAt, slots[0].startsAt);

    const calendarBody = { clientTimeslots: slots };
    const slotsBody = { scheduledCallTime: slots[0].startsAt };

    const body = {
      ...(mode === "calendar" ? calendarBody : {}),
      ...(mode === "slots" ? slotsBody : {}),
      timezone,
      expectedDuration,
      projectWide,
    };

    for (const id of ids) {
      try {
        dispatch({
          type: "onSubmitSchedulingStart",
          data: { id, runningAction: "schedule" },
        });

        const res = await fetch({
          url: `/api/projects/${token}/interactions/${id}/schedule`,
          method: "POST",
          body: JSON.stringify(body),
        });

        const data = await res.json();

        dispatch({ type: "onSubmitSchedulingEnd", data, skipPopup });

        if (mode === "slots") {
          trackUserAction.logHit({
            origin: isFlyoutOpen && origin !== HitOrigin.schedulingModal ? HitOrigin.flyout : origin,
            action: "SCHEDULE_ADVISOR",
            advisorshipId: id,
            projectToken: token,
            details: {
              matchedAvailability,
            },
          });
        } else {
          trackClientAvailability({
            trackUserAction,
            origin: origin,
            projectToken: token,
            interactionIds: [id],
          });
        }
      } catch (error) {
        dispatch({ type: "onSubmitSchedulingError", data: { id } });
        throw error;
      }
    }
  };
}

export const setProjectSettingsMode = (mode) => {
  return {
    type: "onProjectSettingsModeChange",
    data: mode,
  };
};

export function updateProjectPCCFlag(peerContentContributor, impactedInteractions, currentView) {
  return (dispatch, { token, trackUserAction }) => {
    dispatch({ type: "onUpdateProjectLevelFlagStart" });
    return fetch({
      url: `/api/projects/${token}/peerContentContributor`,
      skipAlert: true,
      method: "PUT",
      body: JSON.stringify({
        peerContentContributor,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        trackUserAction.logHit({
          origin: currentView,
          action: "UPDATE_PEER_CONTENT_CONTRIBUTOR",
          projectToken: token,
          details: { peerContentContributor },
        });
        dispatch({
          type: "onUpdateProjectLevelFlagEnd",
          data: {
            key: "peerContentContributor",
            newValue: data.peerContentContributor,
            impactedInteractions,
          },
        });
      });
  };
}

export function updateProjectPTLFlag(privateTranscriptContributor, impactedInteractions, currentView) {
  return (dispatch, { token, trackUserAction }) => {
    dispatch({ type: "onUpdateProjectLevelFlagStart" });
    return fetch({
      url: `/api/projects/${token}/privateTranscriptContributor`,
      skipAlert: true,
      method: "PUT",
      body: JSON.stringify({
        privateTranscriptContributor,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        trackUserAction.logHit({
          origin: currentView,
          action: "UPDATE_PRIVATE_TRANSCRIPT_CONTRIBUTOR",
          projectToken: token,
          details: { privateTranscriptContributor },
        });
        dispatch({
          type: "onUpdateProjectLevelFlagEnd",
          data: {
            key: "privateTranscriptContributor",
            newValue: data.privateTranscriptContributor,
            impactedInteractions,
          },
        });
      });
  };
}

export function updateInteractionPCCFlag(interactionId, value, currentView) {
  return (dispatch, { token, trackUserAction }) => {
    return fetch({
      url: `/api/projects/${token}/interactions/${interactionId}/peerContentContributor`,
      skipAlert: true,
      handleForbidden: true,
      method: "PUT",
      body: JSON.stringify({
        peerContentContributor: value,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        trackUserAction.logHit({
          origin: currentView,
          action: "UPDATE_PEER_CONTENT_CONTRIBUTOR",
          projectToken: token,
          advisorshipId: interactionId,
          details: { peerContentContributor: value },
        });
        dispatch({
          type: "onUpdateInteractionPCCFlag",
          data: { ...data, id: interactionId },
        });
      })
      .catch(() =>
        dispatch({
          type: "onUpdateInteractionFlagError",
        })
      );
  };
}

export function updateInteractionPTLFlag(interactionId, value, currentView) {
  return (dispatch, { token, trackUserAction }) => {
    return fetch({
      url: `/api/projects/${token}/interactions/${interactionId}/privateTranscriptContributor`,
      skipAlert: true,
      handleForbidden: true,
      method: "PUT",
      body: JSON.stringify({
        privateTranscriptContributor: value,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        trackUserAction.logHit({
          origin: currentView,
          action: "UPDATE_PRIVATE_TRANSCRIPT_CONTRIBUTOR",
          projectToken: token,
          advisorshipId: interactionId,
          details: { privateTranscriptContributor: value },
        });
        dispatch({
          type: "onUpdateInteractionPTLFlag",
          data: { ...data, id: interactionId },
        });
      })
      .catch(() =>
        dispatch({
          type: "onUpdateInteractionFlagError",
        })
      );
  };
}

function selectCard(data, route, mode, location, navigate) {
  return (dispatch, state) => {
    const { token } = state;
    const isCalendarView = location.pathname.includes("calendar-view");
    const isWorkstreamView = location.pathname.includes("workstream");

    const navigateWithParams = (url, params = new URLSearchParams(location.search)) => {
      if (!data) {
        params.delete("selected");
      }
      const paramsToString = params.toString();

      let to = url;
      if (data && mode === FlyoutMode.Comments && !to.endsWith("/comments")) {
        to = `${to}/comments`;
      } else if (mode !== FlyoutMode.Comments && to.endsWith("/comments")) {
        to = to.replace(/\/comments$/, "");
      }

      navigate(to + (paramsToString ? "?" + params.toString() : ""));
    };

    if (isWorkstreamView) {
      navigateWithParams(
        data?.id
          ? `/${token}/workstream/${state.workstreamId}/experts/${route}/${data.id}`
          : `/${token}/workstream/${state.workstreamId}/experts/${route}`
      );
    }

    if (isCalendarView) navigateWithParams(data ? `/${token}/calendar-view/${data.id}` : `/${token}/calendar-view`);

    if (!isWorkstreamView && !isCalendarView && data && !data.originalInteractionId) {
      const params = new URLSearchParams(location.search);
      if (data.showChainList) params.set("showChainList", true);
      if (data.showAvailabilities) params.set("showAvailabilities", true);
      navigateWithParams(`/${token}/experts/${route}/${data.id}`, params);
    }

    const currentView = currentProjectView(location.pathname);

    if (!isWorkstreamView && !isCalendarView && !data && currentView) {
      navigateWithParams(`/${token}/experts/${currentView}`);
    }

    dispatch({
      type: "onSelectCard",
      mode: mode || FlyoutMode.Interaction,
      data,
    });
  };
}

export function selectExperts(data) {
  return {
    type: "onSelectExperts",
    data,
  };
}

export function markAsViewed(interaction, currentUser) {
  return (dispatch, state) => {
    if (interaction.newlyAdded && !currentUser?.internalUser) {
      return fetch({
        url: `/api/projects/${state.token}/interactions/${interaction.id}/viewed`,
        method: "POST",
      }).then(() => {
        dispatch({
          type: "onMarkAsViewed",
          data: interaction,
        });
      });
    }
  };
}

export function selectInteractionCalendarView(data, view, navigate) {
  return (dispatch, { token }) => {
    navigate(`/${token}/calendar-view?interactionId=${data.id}&${view}=true`);

    dispatch({
      type: "onPreSelectInteractionCalendarView",
    });
  };
}

export function loadAdvisorResearchRequest({ projectToken, interactionId }) {
  return (dispatch) => {
    dispatch({
      type: "onLoadAdvisorResearchRequestStart",
      data: interactionId,
    });

    return fetch({
      url: `/api/projects/${projectToken}/research-requests?type=ADVISOR&advisorshipId=${interactionId}`,
      skipAlert: true,
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        return dispatch({
          type: "onLoadAdvisorResearchRequestEnd",
          data: {
            interactionId,
            researchRequest: { ...data },
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "onLoadAdvisorResearchRequestError",
          data: { interactionId },
        });
      });
  };
}

export function loadAdvisorInteractions({ interactionId, advisorId, projectToken, groupId }) {
  return (dispatch, state) => {
    dispatch({ type: "onLoadAdvisorInteractionsStart" });
    const { interactions: previousInteractions } = state;

    return fetchAndMap(
      `/api/projects/${projectToken || state.project.token}/interactions/find`,
      {
        advisors: [advisorId],
        groups: [groupId],
      },
      state.project
    ).then(({ interactions }) => {
      dispatch({
        type: "onLoadAdvisorInteractionsEnd",
        data: {
          originalId: interactionId,
          interactions: sortInteractionChain(interactions).map((i) => ({
            ...i,
            projectToken,
            highlights: previousInteractions.find((interaction) => interaction.id === i.id)?.highlights,
          })),
        },
      });
    });
  };
}

function changeLayout(data, navigate, location) {
  return (dispatch, state) => {
    const { token, selectedCardId, workstreamId } = state;

    if (workstreamId) {
      navigate(`/${token}/workstream/${workstreamId}/experts/${data}/${selectedCardId ?? ""}${location.search}`);
    } else {
      navigate(`/${token}/experts/${data}/${selectedCardId ?? ""}${location.search}`);
    }

    dispatch({
      type: "onChangeLayout",
      data,
    });
  };
}

function closeFlyout(location, navigate) {
  return (dispatch) => {
    const params = new URLSearchParams(location.search);
    if (params.get("showChainList")) params.delete("showChainList");
    if (params.get("showAvailabilities")) params.delete("showAvailabilities");
    navigate(
      {
        search: "?" + params.toString(),
      },
      { replace: true }
    );
    dispatch({ type: "onCloseFlyout" });
  };
}

export function changeInteractionChainDate(interactionChainDate) {
  return (dispatch) => {
    dispatch({
      type: "onChangeInteractionChainDate",
      data: { interactionChainDate },
    });
  };
}

export function requestTranscriptPostCall({ id, origin }) {
  return (dispatch, state) => {
    const { projectToken } = findInteraction(id, state);
    const { project, trackUserAction } = state;

    dispatch({
      type: "onRequestTranscriptPostCallStart",
      data: { id, runningAction: "requestTranscript" },
    });

    return interactionsApi.requestTranscriptPostCall({ projectToken, interactionId: id }).then((data) => {
      trackUserAction.logHit({
        origin: origin,
        action: "REQUEST_TRANSCRIPT_UPGRADE",
        advisorshipId: id,
        projectToken: project.token,
        details: { postCall: true },
      });

      return dispatch({
        type: "onRequestTranscriptPostCallEnd",
        data,
      });
    });
  };
}

export function cancelRequestChangeInteraction({ interactionId, requestId }) {
  return async (dispatch, state) => {
    const interaction = findInteraction(interactionId, state);

    dispatch({
      type: "onCancelRequestChangeInteractionStart",
      data: { id: interactionId, runningAction: "requestChangeInteraction" },
    });

    return interactionsApi
      .cancelRequestChangeInteraction({ projectToken: interaction.projectToken, interactionId, requestId })
      .then(() => {
        dispatch({
          type: "onCancelRequestChangeInteractionEnd",
          data: { id: interactionId, requestId },
        });
      });
  };
}

export function submitInvitation({ id, attendees }) {
  return (dispatch, state) => {
    const { projectToken } = findInteraction(id, state);

    dispatch({
      type: "onSubmitInvitationStart",
      data: { id, runningAction: "calendarInvitation" },
    });

    return interactionsApi.submitInvitation({ projectToken, interactionId: id, attendees }).then((data) => {
      dispatch({ type: "onSubmitInvitationEnd", data });
    });
  };
}

export async function requestChangeInteractionFn({
  id = undefined,
  reason,
  type,
  projectToken,
  payload = {},
  origin = "calendar-view-popup",
  trackUserAction,
}) {
  const logHitMap = {
    CANCEL_REQUEST: "ASK_TO_CANCEL_INTERACTION",
    RESCHEDULE_REQUEST: "REQUEST_RESCHEDULE",
    SCHEDULE_REQUEST: "REQUEST_SCHEDULE",
    ADD_EXPERTS_IN_PROJECT_REQUEST: "ADD_EXPERTS_TO_PROJECT_FROM_CONTENT",
  };

  return interactionsApi
    .requestChangeInteraction({
      interactionId: id,
      reason,
      type,
      projectToken,
      payload,
    })
    .then((data) => {
      trackUserAction.logHit({
        action: logHitMap[type],
        advisorshipId: id,
        projectToken,
        origin: origin,
        details: {
          mobile: window.innerWidth <= 768,
        },
      });
      return data;
    });
}

export function requestChangeInteraction({
  id,
  reason,
  type,
  projectToken,
  payload = {},
  origin = "calendar-view-popup",
}) {
  return (dispatch, state) => {
    dispatch({
      type: "onRequestChangeInteractionStart",
      data: { id, runningAction: "requestChangeInteraction" },
    });
    const token = projectToken || findInteraction(id, state).projectToken;

    return requestChangeInteractionFn({
      id,
      reason,
      type,
      projectToken: token,
      payload,
      origin,
      trackUserAction: state.trackUserAction,
    }).then((data) => {
      dispatch({
        type: "onRequestChangeInteractionEnd",
        data,
        id,
      });

      return data;
    });
  };
}

export function cancelTranscript(id) {
  return (dispatch, state) => {
    const interaction = findInteraction(id, state);

    dispatch({
      type: "onCancelTranscriptStart",
      data: { id, runningAction: "cancelTranscript" },
    });

    return interactionsApi
      .cancelTranscript({ projectToken: interaction.projectToken, interactionId: id })
      .then((data) => {
        dispatch({ type: "onCancelTranscriptEnd", data });
      });
  };
}

export function requestTranscript({ id, origin }) {
  return (dispatch, state) => {
    const interaction = findInteraction(id, state);
    const { project, trackUserAction } = state;

    dispatch({
      type: "onRequestTranscriptStart",
      data: { id, runningAction: "requestTranscript" },
    });

    return interactionsApi
      .requestTranscript({
        projectToken: interaction.projectToken,
        interactionId: id,
      })
      .then((data) => {
        trackUserAction.logHit({
          origin: origin,
          action: "REQUEST_TRANSCRIPT_UPGRADE",
          advisorshipId: id,
          projectToken: project.token,
        });

        return dispatch({ type: "onRequestTranscriptEnd", data });
      });
  };
}

export const fetchClientRequests = ({ projects }) => {
  return (dispatch) => {
    return interactionsApi.fetchClientRequests({ projects }).then((data) => {
      dispatch({
        type: "onClientRequestLoadedEnd",
        data,
      });
      return data;
    });
  };
};

export const saveClientAvailability = ({
  projectToken,
  before,
  after,
  origin,
  projectWide = false,
  interactionIds = [],
  angleIds = [],
}) => {
  return (dispatch, state) => {
    const { trackUserAction, isFlyoutOpen } = state;
    return fetch({
      method: "PUT",
      skipAlert: true,
      url: `/api/projects/${projectToken}/client-availability`,
      body: JSON.stringify({ before, after }),
    })
      .then(() => fetchClientAvailability({ projectToken })(dispatch))
      .then(() => {
        trackClientAvailability({
          trackUserAction,
          action: after?.length > 0 ? "SUBMIT_CLIENT_AVAILABILITY" : "REMOVE_CLIENT_AVAILABILITY",
          origin: isFlyoutOpen ? HitOrigin.flyout : origin,
          projectToken: projectToken,
          projectWide: projectWide,
          interactionIds: interactionIds,
          angleIds: angleIds,
        });
      });
  };
};

export const fetchClientAvailability = ({ projectToken }) => {
  return (dispatch) => {
    return fetch({
      url: `/api/projects/${projectToken}/client-availability`,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "onClientAvailabilityLoadedEnd",
          data,
        });
        return data;
      });
  };
};

export const fetchProjectInteractions = ({ project }) => {
  return (dispatch) => {
    interactionsApi.findProjectInteractions(project).then((interactions) => {
      dispatch({
        type: "onFindProjectInteractionsEnd",
        data: interactions,
      });
    });
  };
};

export const setFlyoutMode = (mode) => {
  return {
    type: "onFlyoutModeChange",
    data: mode,
  };
};

export const setNewMessageType = (mode) => ({
  type: "onNewMessageTypeChange",
  data: mode,
});

export const setNewMessageContent = (content) => ({
  type: "onNewMessageContentChange",
  data: content,
});

export const setNewMessageOrigin = (mode) => ({
  type: "onNewMessageOriginChange",
  data: mode,
});

export function loadUpgrades({ projectToken, interactionId }) {
  return (dispatch) => {
    dispatch({
      type: "onLoadUpgradesStart",
    });

    return fetch({
      url: `/api/projects/${projectToken}/interactions/${interactionId}/upgrades`,
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        return dispatch({ type: "onLoadUpgradesEnd", data: data.upgrades });
      });
  };
}

function addUpgrade({ projectToken, interactionId, type, locale, currentView }) {
  return (dispatch, state) => {
    return fetch({
      url: `/api/projects/${projectToken}/interactions/${interactionId}/upgrades/${type}`,
      method: "POST",
      body: JSON.stringify({ locale: locale }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { upgrades } = data;
        const addedUpgrade = upgrades.find((upg) => upg.value === type);

        if (type === "translated") {
          dispatch({
            type: "onUpdateInteractionTranscriptTargetLanguage",
            data: { transcriptTargetLanguage: locale, id: interactionId },
          });
        }

        state.trackUserAction.logHit({
          origin: currentView,
          action: "DELIVERABLE_UPGRADE_ADD",
          projectToken: state.token,
          advisorshipId: interactionId,
          details: { id: addedUpgrade?.id, type, locale },
        });

        return dispatch({
          type: "onEditUpgradesEnd",
          data: { id: interactionId, ...data },
        });
      });
  };
}

function removeUpgrade({ projectToken, interactionId, type, currentView }) {
  return (dispatch, state) => {
    return fetch({
      url: `/api/projects/${projectToken}/interactions/${interactionId}/upgrades/${type}`,
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        if (type === "transcript") {
          dispatch({
            type: "onUpdateInteractionPCCFlag",
            data: { peerContentContributor: false, id: interactionId },
          });
          dispatch({
            type: "onUpdateInteractionPTLFlag",
            data: { privateTranscriptContributor: false, id: interactionId },
          });
        }

        state.trackUserAction.logHit({
          origin: currentView,
          action: "DELIVERABLE_UPGRADE_REMOVE",
          projectToken: state.token,
          advisorshipId: interactionId,
          details: { type },
        });

        return dispatch({
          type: "onEditUpgradesEnd",
          data: { id: interactionId, ...data },
        });
      });
  };
}

export const searchThirdPartyDocuments = ({ projectToken, searchQuery }) => {
  return (dispatch) => {
    dispatch({ type: "onFetchThirdPartyDocumentsStart" });
    const fetchFn = () =>
      searchQuery?.length > 0
        ? thirdPartyDocumentsApi.searchDocuments(projectToken, searchQuery)
        : thirdPartyDocumentsApi.fetchDocuments(projectToken);
    fetchFn()
      .then((documents) => {
        dispatch({ type: "onFetchThirdPartyDocumentsEnd", data: documents });
      })
      .catch(() => dispatch({ type: "onFetchThirdPartyDocumentsEnd", data: [] }));
  };
};

export const useReducerCallbacks = ({ dispatch, state, originalDispatch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentView = currentProjectView(location.pathname);
  const isLoggedIn = useIsAuthenticated();
  const { isMobile } = useCheckScreen();

  const [requestingInteractionIds, setRequestingInteractionIds] = useState([]);

  const project = state.project;
  const appliedFilters = state.appliedFilters;
  const workstreamId = state.workstreamId;
  const isFlyoutOpen = state.isFlyoutOpen;
  const flyoutMode = state.flyoutMode;
  const flyoutShowsCalendar = state.showCalendarView && !isMobile && isLoggedIn;

  const hasThirdPartyDocumentsPermissions = useThirdPartyDocsPermissions();
  const shouldFetchThirdPartyDocuments = hasThirdPartyDocumentsPermissions && !isMobile;

  const onSelectCard = useCallback(
    (card, route = currentView, mode) => {
      const newFlyoutMode = isFlyoutOpen
        ? [FlyoutMode.Messages, FlyoutMode.NewMessage].includes(flyoutMode)
          ? FlyoutMode.Interaction
          : flyoutMode
        : undefined;
      return selectCard(
        card,
        route,
        mode ?? newFlyoutMode,
        location,
        navigate
      )(originalDispatch, {
        token: project.token,
        workstreamId,
      });
    },
    [flyoutMode, currentView, isFlyoutOpen, originalDispatch, project.token, navigate, location, workstreamId]
  );

  const onSearch = useCallback(
    ({ appliedFilters, searchQuery }, { updateURL, replace } = {}) => {
      dispatch(search({ appliedFilters, searchQuery, location, navigate }, { updateURL, replace }));
      if (shouldFetchThirdPartyDocuments) {
        dispatch(searchThirdPartyDocuments({ projectToken: project.token, searchQuery }));
      }
    },
    [dispatch, location, navigate, project.token, shouldFetchThirdPartyDocuments]
  );

  const onToggleHidden = useCallback(
    (interaction, origin) =>
      dispatch(
        hideAdvisor({
          id: interaction.id,
          hidden: !interaction.hidden,
          origin,
        })
      ),
    [dispatch]
  );

  const onSelectInteractionCalendarView = useCallback(
    (data, view) => dispatch(selectInteractionCalendarView(data, view, navigate)),
    [dispatch, navigate]
  );

  const onSearchSync = useCallback(
    ({ appliedFilters, searchQuery }) => dispatch(searchSync({ appliedFilters, searchQuery, currentView })),
    [currentView, dispatch]
  );

  const onSaveHideExpertReason = useCallback(
    (id, reason, customReason, origin) => dispatch(saveHideExpertReason({ id, reason, customReason, origin })),
    [dispatch]
  );

  const onToggleStar = useCallback((id, starred, origin) => dispatch(starAdvisor({ id, starred, origin })), [dispatch]);

  const onRequestTranscript = useCallback(({ id, origin }) => dispatch(requestTranscript({ id, origin })), [dispatch]);

  const onCancelRequestTranscript = useCallback((id) => dispatch(cancelTranscript(id)), [dispatch]);

  const onRequestTranscriptPostCall = useCallback(
    ({ id, origin }) => dispatch(requestTranscriptPostCall({ id, origin })),
    [dispatch]
  );

  const onRequestInternal = useCallback(
    (id, origin = "unknown") => {
      const interaction = findInteraction(id, state);
      const hasAdvisorAvailability = interaction?.advisorAvailability?.length > 0;
      const isSurvey =
        interaction?.interactionType === "Industry Survey" ||
        (currentView === "survey-view" && isPotentialSurveyResponse(interaction));
      return dispatch(
        requestAdvisor({
          id,
          origin,
          skipPopup: flyoutShowsCalendar || !hasAdvisorAvailability,
        })
      ).then(() => {
        if (flyoutShowsCalendar && currentView !== "calendar-view") {
          onSelectCard({ id }, currentView, hasAdvisorAvailability && !isSurvey ? FlyoutMode.Schedule : null);
        } else if (currentView === "calendar-view") {
          onSelectInteractionCalendarView({ id }, "schedule");
        }
      });
    },
    [dispatch, flyoutShowsCalendar, currentView, state, onSelectInteractionCalendarView, onSelectCard]
  );

  const onRequest = useCallback(
    (...args) => {
      const { id } = args;
      if (!requestingInteractionIds.includes(id)) {
        setRequestingInteractionIds((prev) => [...prev, id]);
        return onRequestInternal(...args).finally(() =>
          setRequestingInteractionIds((prev) => prev.filter((i) => i !== id))
        );
      }
      return Promise.resolve();
    },
    [requestingInteractionIds, onRequestInternal]
  );

  const onRequestRescheduleInteraction = useCallback(
    (id) => {
      if (flyoutShowsCalendar && currentView !== "calendar-view") {
        onSelectCard({ id }, currentView, FlyoutMode.RequestReschedule);
      } else if (currentView === "calendar-view") {
        onSelectInteractionCalendarView({ id }, "request-reschedule");
      }
    },
    [flyoutShowsCalendar, currentView, onSelectInteractionCalendarView, onSelectCard]
  );

  const onUpdateFollowUp = useCallback((props) => dispatch(updateFollowUp(props)), [dispatch]);

  const onCancelFollowUp = useCallback((id) => dispatch(cancelFollowUp({ id })), [dispatch]);

  const onCloseFollowUpModal = useCallback(() => dispatch(closeFollowUpModal()), [dispatch]);

  const onSubmitClientAvailability = useCallback((props) => dispatch(submitClientAvailability(props)), [dispatch]);

  const onSubmitSchedule = useCallback(
    (props, origin = "unknown") =>
      dispatch(
        submitScheduling(props, {
          origin,
          skipPopup: flyoutShowsCalendar,
          isLoggedIn,
        })
      ),
    [dispatch, flyoutShowsCalendar, isLoggedIn]
  );

  const onCloseSchedulingModal = useCallback(() => {
    dispatch(closeScheduling());
  }, [dispatch]);

  const onStartSchedule = useCallback(
    (props) => {
      dispatch(startScheduling({ ...props, skipPopup: flyoutShowsCalendar }));
      if (flyoutShowsCalendar && currentView !== "calendar-view") {
        onSelectCard(props, currentView, FlyoutMode.Schedule);
      } else if (currentView === "calendar-view") {
        onSelectInteractionCalendarView(props, "schedule");
      }
    },
    [currentView, dispatch, flyoutShowsCalendar, onSelectInteractionCalendarView, onSelectCard]
  );

  const onCancelRequest = useCallback(
    (id, origin) => {
      const previousInteraction = state.chainInteractions.at(
        state.chainInteractions.findIndex((el) => el.id === id) + 1
      );
      dispatch(cancelRequestAdvisor({ id, origin })).then(({ remove }) => {
        if (!isFlyoutOpen) {
          onSelectCard(null);
        } else if (remove) {
          onSelectCard(previousInteraction);
        }
      });
    },
    [dispatch, onSelectCard, isFlyoutOpen, state.chainInteractions]
  );

  const onCancelPendingRequest = useCallback(
    (request) =>
      dispatch(cancelRequestChangeInteraction(request)).then(() => {
        if (request.type === "SCHEDULE_REQUEST") {
          return dispatch(cancelRequestAdvisor({ id: request.interactionId }));
        }
      }),
    [dispatch]
  );

  const onFiltersChange = useCallback(
    (appliedFilters, searchQuery, { keepFlyoutOpen, replace } = { keepFlyoutOpen: false, replace: false }) => {
      if (isFlyoutOpen && !keepFlyoutOpen) {
        onSelectCard(null);
      }
      const options = {
        searchQuery: searchQuery ? searchQuery : state.searchQuery,
        appliedFilters,
      };
      return onSearch(options, { updateURL: true, replace });
    },
    [onSearch, state.searchQuery, isFlyoutOpen, onSelectCard]
  );

  const onLeaveAvailability = useCallback(
    (props) => {
      if (flyoutShowsCalendar && currentView !== "calendar-view") {
        onSelectCard(props, currentView, FlyoutMode.ProvideAvailability);
      } else if (currentView === "calendar-view") {
        onSelectInteractionCalendarView(props, "request-availability");
      } else {
        dispatch(startScheduling(props));
      }
    },
    [currentView, dispatch, flyoutShowsCalendar, onSelectInteractionCalendarView, onSelectCard]
  );

  const onSaveClientAvailability = useCallback((args) => dispatch(saveClientAvailability(args)), [dispatch]);

  const onOpenComments = useCallback(
    (interaction) => {
      if (isFlyoutOpen && flyoutMode === FlyoutMode.Comments && state.selectedCardId === interaction.id) {
        const pathname = location.pathname;
        if (pathname.endsWith("/comments")) {
          navigate({ ...location, ...{ pathname: pathname.replace("/comments", "") } });
        }

        dispatch(setFlyoutMode(FlyoutMode.Interaction));
      } else {
        onSelectCard(interaction, currentView, FlyoutMode.Comments);
      }
    },
    [dispatch, currentView, isFlyoutOpen, flyoutMode, location, navigate, state.selectedCardId, onSelectCard]
  );

  const onOpenMessageStatus = useCallback(
    (interaction) => {
      if (isFlyoutOpen && state.selectedCardId === interaction.id) {
        onSelectCard(null);
      } else {
        onSelectCard(interaction, currentView, FlyoutMode.Messages);
      }
    },
    [onSelectCard, currentView, isFlyoutOpen, state.selectedCardId]
  );

  const performSearchSync = useCallback(() => onSearchSync(state), [onSearchSync, state]);

  const filterOverrides = ({ currentView, location, appliedFilters }) => {
    const urlParams = new URLSearchParams(location.search);
    const hasIdInUrl = urlParams.get("interactionId") || urlParams.get("selectedInteraction");
    const noFiltersApplied = !appliedFilters || Object.values(appliedFilters).flat().length === 0;
    if (!noFiltersApplied) {
      return appliedFilters;
    }
    return currentView === "calendar-view"
      ? {
          ...appliedFilters,
          groups: project.anglesTaggedToClient,
          status: hasIdInUrl ? ["requested", "proposed", "scheduled", "completed"] : ["requested", "proposed"],
        }
      : {
          ...appliedFilters,
          groups: currentView === "messages-view" || hasIdInUrl ? [] : project.anglesTaggedToClient,
        };
  };

  const sanitizeFilters = ({ currentView, appliedFilters }) => {
    if (currentView === "comparison-view") {
      return {
        ...appliedFilters,
        groups: [],
        comments: [],
        transcript_status: [],
        profile_activity: (appliedFilters.profile_activity || []).filter((value) => value === "Starred"),
      };
    }

    return appliedFilters;
  };
  const initialInteractionsLoad = useCallback(() => {
    dispatch(getFilterOptions());
    const updatedFilters = sanitizeFilters({
      appliedFilters: filterOverrides({
        currentView,
        location,
        appliedFilters,
      }),
      currentView,
    });
    onSearch({ ...state, appliedFilters: updatedFilters }, { updateURL: true, replace: true });
    onSearchSync({ ...state, appliedFilters: updatedFilters });
    dispatch(fetchClientRequests({ projects: [{ token: project.token }] }));
    dispatch(fetchClientAvailability({ projectToken: project.token }));
    dispatch(fetchProjectInteractions({ project }));
  }, [dispatch, state, onSearch]); //eslint-disable-line react-hooks/exhaustive-deps

  const onRequestFollowUp = useCallback(
    ({ id, selectCard = true, origin }) => {
      return dispatch(requestFollowUp({ id, skipPopup: true, origin })).then((newInteraction) => {
        if (selectCard) {
          onSelectCard(newInteraction);
        }
        return newInteraction;
      });
    },
    [dispatch, onSelectCard]
  );

  const onSendNewMessage = useCallback(
    (interactions, messageType, origin = NewMessageOrigin.Standard, messageContent) => {
      dispatch(setNewMessageType(messageType));
      dispatch(setNewMessageOrigin(origin));
      if (interactions.length) {
        onSelectCard(interactions[0], currentView, FlyoutMode.NewMessage);
        dispatch(selectExperts(interactions.map((interaction) => interaction.advisorId)));
      }
      if (messageContent) {
        dispatch(setNewMessageContent(messageContent));
      }
    },
    [dispatch, currentView, onSelectCard]
  );

  const onSendNewMessageFromTableView = useCallback(
    (experts, messageType) => {
      const { token } = project;
      const routeState = {
        newMessageType: messageType,
        selectedExpertsIds: experts.map((expert) => expert.id),
        newMessageOrigin: NewMessageOrigin.TableView,
      };
      navigate(`/${token}/experts/table-view/new-message`, { state: routeState });
    },
    [project, navigate]
  );

  const onCloseFlyout = useCallback(() => {
    dispatch(closeFlyout(location, navigate));
    onSelectCard(null);
  }, [dispatch, location, navigate, onSelectCard]);

  const onUpdateProjectPCCFlag = useCallback(
    (peerContentContributor, impactedInteractions) =>
      dispatch(updateProjectPCCFlag(peerContentContributor, impactedInteractions, currentView)),
    [currentView, dispatch]
  );

  const onUpdateProjectPTLFlag = useCallback(
    (privateTranscriptContributor, impactedInteractions) =>
      dispatch(updateProjectPTLFlag(privateTranscriptContributor, impactedInteractions, currentView)),
    [currentView, dispatch]
  );

  const onUpdateInteractionPCCFlag = useCallback(
    (interactionId, value) => dispatch(updateInteractionPCCFlag(interactionId, value, currentView)),
    [currentView, dispatch]
  );

  const onUpdateInteractionPTLFlag = useCallback(
    (interactionId, value) => dispatch(updateInteractionPTLFlag(interactionId, value, currentView)),
    [currentView, dispatch]
  );

  const onChangeLayout = useCallback((layout) => dispatch(changeLayout(layout, navigate, location)), [
    dispatch,
    location,
    navigate,
  ]);

  const onAddUpgrade = useCallback(
    ({ projectToken, interactionId, type, locale }) =>
      dispatch(addUpgrade({ projectToken, interactionId, type, locale, currentView })),
    [dispatch, currentView]
  );

  const onRemoveUpgrade = useCallback(
    ({ projectToken, interactionId, type }) =>
      dispatch(removeUpgrade({ projectToken, interactionId, type, currentView })),
    [dispatch, currentView]
  );

  const onRefetchThirdPartyDocuments = useCallback(
    ({ searchQuery }) => {
      if (shouldFetchThirdPartyDocuments) {
        dispatch(searchThirdPartyDocuments({ projectToken: project.token, searchQuery }));
      }
    },
    [dispatch, shouldFetchThirdPartyDocuments, project.token]
  );

  return {
    onToggleHidden,
    onSaveHideExpertReason,
    onToggleStar,
    onRequestTranscript,
    onCancelRequestTranscript,
    onRequestTranscriptPostCall,
    onRequest,
    onRequestRescheduleInteraction,
    onUpdateFollowUp,
    onCancelFollowUp,
    onCloseFollowUpModal,
    onSubmitClientAvailability,
    onSubmitSchedule,
    onCloseSchedulingModal,
    onStartSchedule,
    onSelectCard,
    onCancelRequest,
    onFiltersChange,
    onLeaveAvailability,
    onSaveClientAvailability,
    onOpenComments,
    onOpenMessageStatus,
    onRequestFollowUp,
    performSearchSync,
    initialInteractionsLoad,
    onCancelPendingRequest,
    onSendNewMessage,
    onSendNewMessageFromTableView,
    onSearch,
    onCloseFlyout,
    onUpdateProjectPCCFlag,
    onUpdateProjectPTLFlag,
    onUpdateInteractionPCCFlag,
    onUpdateInteractionPTLFlag,
    onChangeLayout,
    onAddUpgrade,
    onRemoveUpgrade,
    onRefetchThirdPartyDocuments,
  };
};
