import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCustomerPrimersStore } from "../../state/store";
import { SectionWithTopics } from "../../components";
import RankedKPCsChart from "./RankedKPCsChart";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

const RankedKPCs = () => {
  const { spacing } = useThemeTokens();

  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const keyPurchasingCriteria = useCustomerPrimersStore(({ keyPurchasingCriteria }) => keyPurchasingCriteria);
  const { ranked } = keyPurchasingCriteria;

  return (
    <>
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <RankedKPCsChart />
      </SensitiveDataContainer>
      <SectionWithTopics
        sectionTitle="Why are these criteria most important to businesses like yours?"
        categories={ranked.map(({ kpc, summary, expertsResponses }) => ({
          title: kpc,
          summary: summary,
          expertsResponses: expertsResponses,
        }))}
        mt={spacing.layout.base03}
        useDivider
      />
    </>
  );
};

export default RankedKPCs;
