import * as React from "react";
import { LabeledIcon, PrimerFieldTitle, SectionTitle } from "pages/AlphaNowPage/primers/components";
import UsageAndAwarenessChart from "./UsageAndAwarenessChart";
import UsageAndAwarenessTable from "./UsageAndAwarenessTable";
import { Info } from "@alphasights/alphadesign-icons";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const UsageAndAwareness = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const primerTitle = usePrimersStore(({ primerTitle }) => primerTitle);
  const { spacing, color } = useThemeTokens();

  return (
    <SectionWrapper ref={ref} px={spacing.inner.base06}>
      <SectionTitle dataTestId="usageAndAwarenessSectionTitle" text="Usage and Awareness" />
      <PrimerFieldTitle text="USAGE AND AWARENESS BY VENDOR" mt={spacing.inner.base06} />
      <LabeledIcon
        labelText={`Contributing experts stated their current customer relationship with vendors in the ${primerTitle} market. Click on a bar to show associated customer experts.`}
        iconItem={<Info />}
        mt={spacing.inner.base}
        iconColor={color.text.assistive}
        labelColor={color.text.assistive}
      />
      <UsageAndAwarenessChart />
      <PrimerFieldTitle text="USAGE AND AWARENESS BY CUSTOMER" mt={spacing.inner.base06} />
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <UsageAndAwarenessTable />
      </SensitiveDataContainer>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(UsageAndAwareness));
