import { FC } from "react";
import { x } from "@xstyled/styled-components";
import { IconButton, IconButtonSizes, IconButtonVariant, useThemeTokens } from "@alphasights/alphadesign-components";
import { Download } from "@alphasights/alphadesign-icons";

export interface DownloadButtonProps {
  variant?: IconButtonVariant;
  size?: IconButtonSizes;
}

const DownloadButton: FC<DownloadButtonProps> = ({ variant = "basic", size = "medium" }) => {
  const {
    color: { icon },
  } = useThemeTokens();

  const onClickDownload = () => {
    // TODO: to be implemented
  };

  return (
    <x.div display="flex" alignItems="center">
      <IconButton onClick={onClickDownload} color={icon.secondary} size={size} variant={variant}>
        <Download data-testid="download-button" />
      </IconButton>
    </x.div>
  );
};

export default DownloadButton;
