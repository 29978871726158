import { useEffect, useMemo, useRef, useState } from "react";
import { AccordionItem, TabItem, Tabs, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCkFlyoutStyles } from "./CustomerKnowledgeShowcase.styles";
import { Experience, Role } from "models/Interaction";
import { ExperienceIcon } from "../ExperienceIcon";
import { EXPERIENCE_DESCRIPTION, IN_ORDER_EXPERIENCES } from "../helpers";
import _ from "lodash";
import { useIsOverflow } from "@alphasights/client-portal-shared";
import { withNumberParam } from "helpers/tabHelper";

const ROLE_DESCRIPTION = {
  [Role.Kdm]: "KDM",
  [Role.EvaluationTeam]: "Evaluation Team",
  [Role.User]: "User",
};

export const CustomerKnowledgeShowcase = ({ knowledges }: { knowledges: CustomerKnowledge[] }) => {
  const [rank, setRank] = useState(1);
  const styles = useCkFlyoutStyles();
  const selectedKnowledges = useMemo(
    () => (rank === 1 ? knowledges.filter((k) => k.rank === 1) : knowledges.filter((k) => k.rank !== 1)),
    [knowledges, rank]
  );
  const role = useMemo(
    () => (selectedKnowledges.find((k) => k.experience === Experience.Uses) || selectedKnowledges[0] || {}).role,
    [selectedKnowledges]
  );

  const orderedExperienceItems = useMemo(() => {
    const groupedKnowledges = _.groupBy(selectedKnowledges, "experience");
    const experienceItems = Object.entries(groupedKnowledges).flatMap(([experience, cks]) => {
      if (experience === Experience.Aware || experience === Experience.Unaware) {
        const vendorName = cks
          .map((ck) => ck.vendor.name)
          .sort()
          .join(", ");
        return [
          {
            vendorName,
            experience,
          } as CkExperienceLineProps,
        ];
      } else {
        return cks.map(
          (ck) =>
            ({
              lastEvaluatedAt: ck.lastEvaluatedAt,
              solutions: ck.solutions,
              vendorName: ck.vendor.name,
              experience,
            } as CkExperienceLineProps)
        );
      }
    });

    return IN_ORDER_EXPERIENCES.flatMap((experience) => {
      return _.orderBy(
        experienceItems.filter((k) => k.experience === experience),
        "vendor.name"
      );
    });
  }, [selectedKnowledges]);

  const hasPrimaryPosition = useMemo(() => !!knowledges.find((k) => k.rank === 1), [knowledges]);
  const hasSecondaryPosition = useMemo(() => !!knowledges.find((k) => k.rank !== 1), [knowledges]);
  useEffect(() => {
    if (hasPrimaryPosition) {
      setRank(1);
    } else if (hasSecondaryPosition) {
      setRank(2);
    }
  }, [hasPrimaryPosition, hasSecondaryPosition]);

  const onTabChange = (index: number) => {
    setRank(index + 1);
  };

  if (knowledges.length === 0) {
    return null;
  }

  return (
    <AccordionItem title="Customer Knowledge" open={true} data-testid="customer-knowledge-showcase">
      {hasSecondaryPosition && (
        <x.div display="inline-block">
          <Tabs variant="default" onChange={withNumberParam(onTabChange)} index={rank - 1}>
            <TabItem label="Primary Position" />
            {hasSecondaryPosition && <TabItem label="Secondary Position" />}
          </Tabs>
        </x.div>
      )}
      {selectedKnowledges.length > 0 ? (
        <>
          {role && (
            <x.div {...styles.roleSubtitle}>
              <Typography>Role: {ROLE_DESCRIPTION[role]}</Typography>
            </x.div>
          )}
          <x.div {...styles.knowledgesList}>
            {orderedExperienceItems.map((gk, idx) => (
              <CkExperienceLine key={idx} {...gk} />
            ))}
          </x.div>
        </>
      ) : (
        <Typography {...styles.roleSubtitle}>
          No Customer Knowledge has been entered for this Primary Position.
        </Typography>
      )}
    </AccordionItem>
  );
};

interface CkExperienceLineProps {
  vendorName: string;
  experience: Experience;
  lastEvaluatedAt?: string;
  solutions?: string[];
}

const CkExperienceLine = ({ vendorName, experience, lastEvaluatedAt, solutions }: CkExperienceLineProps) => {
  const styles = useCkFlyoutStyles();
  const productsRef = useRef(null);
  const isProductsOverflow = useIsOverflow(productsRef);
  const quarterStr = useMemo(() => {
    if (lastEvaluatedAt) {
      const date = new Date(lastEvaluatedAt);
      return `Q${Math.floor(date.getMonth() / 3 + 1)} ${date.getFullYear()}`;
    }
    return "";
  }, [lastEvaluatedAt]);

  const experienceCopy = `${EXPERIENCE_DESCRIPTION[experience]} ${vendorName}`;
  const evaluatedCopy = quarterStr ? ` | Evaluated ${quarterStr}` : "";
  const solutionsCopy = solutions?.length ? ` | ${solutions.join(", ")}` : "";
  const tooltipCopy = `${experienceCopy}${evaluatedCopy}${solutionsCopy}`;

  return (
    <x.div {...styles.knowledgeLine} role="knowledge-info">
      <ExperienceIcon experience={experience} />
      <Tooltip title={tooltipCopy} enterDelay={100} variant="dark" disabled={!isProductsOverflow}>
        <x.div ref={productsRef} {...styles.knowledgeText}>
          <Typography variant="body" color="strong" component="span">
            {experienceCopy}
          </Typography>
          <Typography variant="body" color="secondary" component="span">
            {evaluatedCopy}
            {solutionsCopy}
          </Typography>
        </x.div>
      </Tooltip>
    </x.div>
  );
};
