import * as React from "react";
import { Link, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const DisclaimerSection = ({
  showExpandedDisclaimer,
  setShowExpandedDisclaimer,
  blinded,
  complianceReviewEnabled,
  enableSixYearsMessageRetentionPolicy,
}: {
  showExpandedDisclaimer: boolean;
  setShowExpandedDisclaimer: (value: boolean) => void;
  blinded: boolean;
  complianceReviewEnabled: boolean;
  enableSixYearsMessageRetentionPolicy: boolean;
}) => {
  return (
    <Typography mt="inner-base" variant="body-small" color="secondary">
      <DisclaimerText
        complianceReviewEnabled={complianceReviewEnabled}
        enableSixYearsMessageRetentionPolicy={enableSixYearsMessageRetentionPolicy}
      />
      {showExpandedDisclaimer && (
        <Typography component="span" variant="body-small" color="secondary">
          <Typography mt="inner-base02" variant="body-small">
            <TermsOfUseText
              complianceReviewEnabled={complianceReviewEnabled}
              enableSixYearsMessageRetentionPolicy={enableSixYearsMessageRetentionPolicy}
            />
            <BlindedTransactionText blinded={blinded} />
            <StorageText
              complianceReviewEnabled={complianceReviewEnabled}
              enableSixYearsMessageRetentionPolicy={enableSixYearsMessageRetentionPolicy}
            />{" "}
            <ShowDisclaimerLink onClick={() => setShowExpandedDisclaimer(!showExpandedDisclaimer)}>
              Show Less
            </ShowDisclaimerLink>
          </Typography>
        </Typography>
      )}{" "}
      {!showExpandedDisclaimer && (
        <ShowDisclaimerLink onClick={() => setShowExpandedDisclaimer(!showExpandedDisclaimer)}>
          Show More
        </ShowDisclaimerLink>
      )}
    </Typography>
  );
};

const DisclaimerText = ({
  complianceReviewEnabled,
  enableSixYearsMessageRetentionPolicy,
}: {
  complianceReviewEnabled: boolean;
  enableSixYearsMessageRetentionPolicy: boolean;
}) => {
  return (
    <>
      <Typography component="span" variant="body-small-em">
        Disclaimer:
      </Typography>{" "}
      {(!complianceReviewEnabled || enableSixYearsMessageRetentionPolicy) &&
        "In using AlphaSights Messenger, you acknowledge and agree that each use of this service shall constitute a “Transaction” as defined under the Access Terms or other written agreement between your employer or its Affiliate and AlphaSights (each, an “Agreement”) and shall be governed by the terms of that Agreement. Accordingly, AlphaSights takes no responsibility or liability whatsoever in respect of any information exchanged between you and an Expert using AlphaSights Messenger, including in respect of the information’s: (i) accuracy or completeness; (ii) compliance with applicable Law or Intellectual Property Rights; or (iii) exclusion of any Inside Information or confidential information belonging to another party. Any information exchanged is shared directly between you and the Expert and AlphaSights will not review, monitor or modify any written correspondence or attachments. Any response you receive from an Expert shall constitute an Expert Engagement Record. Any Expert Engagement Record submitted by an Expert solely constitutes the opinion of such Expert and not the opinion of AlphaSights."}
      {complianceReviewEnabled &&
        !enableSixYearsMessageRetentionPolicy &&
        "In using AlphaSights Messenger, you acknowledge and agree that each use of this service shall constitute a “Transaction” as defined under the Access Terms or other written agreement between your employer or its Affiliate and AlphaSights (each, an “Agreement”) and shall be governed by the terms of that Agreement. Accordingly, AlphaSights takes no responsibility or liability whatsoever in respect of any information exchanged between you and an Expert using AlphaSights Messenger, including in respect of the information’s: (i) accuracy or completeness; (ii) compliance with applicable Law or Intellectual Property Rights; or (iii) exclusion of any Inside Information or confidential information belonging to another party. Any response you receive from an Expert shall constitute an Expert Engagement Record. Any Expert Engagement Record submitted by an Expert solely constitutes the opinion of such Expert and not the opinion of AlphaSights."}
      {!enableSixYearsMessageRetentionPolicy &&
        "Terms used but not defined herein shall have the meaning ascribed to them in the relevant Agreement."}
    </>
  );
};

const TermsOfUseText = ({
  complianceReviewEnabled,
  enableSixYearsMessageRetentionPolicy,
}: {
  complianceReviewEnabled: boolean;
  enableSixYearsMessageRetentionPolicy: boolean;
}) => {
  return (
    <>
      <Typography component="span" variant="body-small-em">
        Terms of Use:
      </Typography>{" "}
      {(!complianceReviewEnabled || enableSixYearsMessageRetentionPolicy) &&
        "AlphaSights Messenger facilitates direct communications between clients and experts for specific, documented purposes. In particular, you are prohibited from using AlphaSights Messenger to: (i) ask specific questions about an Expert’s current employer; (ii) solicit any confidential or Insider Information; (iii) exchange direct contact details with an Expert; or (iv) exchange legal documents (i.e. non-disclosure agreements), unless this is in preparation for a Direct Engagement."}
      {complianceReviewEnabled &&
        !enableSixYearsMessageRetentionPolicy &&
        "AlphaSights Messenger facilitates communications between clients and experts for specific, documented purposes. In particular, you are prohibited from using AlphaSights Messenger to: (i) ask specific questions about an Expert’s current employer; (ii) solicit any confidential or Insider Information; (iii) exchange direct contact details with an Expert; or (iv) exchange legal documents (i.e. non-disclosure agreements), unless this is in preparation for a Direct Engagement."}
    </>
  );
};

const BlindedTransactionText = ({ blinded }: { blinded: boolean }) => {
  if (!blinded) return <></>;

  return (
    blinded && (
      <>
        <x.div mt="inner-base02" />
        <Typography component="span" variant="body-small-em">
          Blinded Transaction:
        </Typography>{" "}
        This is a blinded Transaction so the recipient of your message should not know your or your company's identity.
        You should introduce yourself with your first name only and should not share any information that could reveal
        your or your company's identity in your message(s) or attached files. While we have implemented stringent
        protocols to reduce the risk of your identity being revealed, please be aware that we cannot guarantee
        anonymity. By sending this message, you acknowledge and accept that anonymity is not guaranteed and release
        AlphaSights of any liability in the event that a party's identity is disclosed during this Transaction and any
        related correspondence.{" "}
      </>
    )
  );
};

const StorageText = ({
  complianceReviewEnabled,
  enableSixYearsMessageRetentionPolicy,
}: {
  complianceReviewEnabled: boolean;
  enableSixYearsMessageRetentionPolicy: boolean;
}) => {
  if (complianceReviewEnabled && !enableSixYearsMessageRetentionPolicy) return <></>;

  return (
    <Typography mt="inner-base02" variant="body-small" color="secondary">
      <Typography component="span" variant="body-small-em">
        Storage:
      </Typography>{" "}
      {enableSixYearsMessageRetentionPolicy &&
        "AlphaSights remotely archives all written correspondence and attachments exchanged via AlphaSights Messenger following project closure. AlphaSights has no access to Expert responses. Expert responses are permanently deleted from our systems 48 business hours after project closure. All projects are automatically closed following 90 days of inactivity."}
      {!enableSixYearsMessageRetentionPolicy &&
        "AlphaSights remotely archives all written correspondence and attachments exchanged via AlphaSights Messenger for 48 business hours after project closure, after which they will be permanently deleted. All projects are automatically closed following 90 days of inactivity. Except for your initial request, AlphaSights has no access to any written correspondence or attachments."}
    </Typography>
  );
};

const ShowDisclaimerLink = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
  return (
    <Link component="button" onClick={onClick}>
      <Typography component="span" variant="body-small-em">
        {children}
      </Typography>
    </Link>
  );
};
