import { CallAvailabilityStatus } from "components/CallMePanel/constants";

export type CallAvailability = {
  fetch_error?: string;
  status: string;
  clientAccessCodeFormatted?: string;
  availability: CallAvailabilityStatus;
};

export type UseCallAvailabilityResult = {
  callAvailability?: CallAvailability;
};

export enum PreferenceType {
  TABLE_VIEW_COLUMNS = "TABLE_VIEW_COLUMNS",
  SURVEY_TABLE_COLUMNS = "SURVEY_TABLE_COLUMNS",
  DEFAULT_ADVISOR_VIEW = "DEFAULT_ADVISOR_VIEW",
  CALENDAR = "CALENDAR",
  TIMEZONE = "TIMEZONE",
  TWILIO = "TWILIO",
  PROJECT_SYNTHESIS = "PROJECT_SYNTHESIS",
  NEW_AI_MODEL_BANNER = "NEW_AI_MODEL_BANNER",
}

export type GenericPreference<T> = {
  id?: number;
  type: string;
  scope: PreferenceScope;
  projectToken?: string;
  attributes: { [key: string]: T };
};

export type Preference = GenericPreference<any>;

export enum PreferenceScope {
  GLOBAL = "GLOBAL",
  PROJECT = "PROJECT",
}
