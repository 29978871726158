import { useCallback, useContext, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";

import { Chat, HideAlt, ShowAlt, StarSelected, StarUnselected } from "@alphasights/alphadesign-icons";

import MoreOptions from "./MoreOptions";
import { useInteractionActionIconstyles } from "../../AdvisorFlyOut.styles";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { requestChangeInteraction } from "components/InteractionsPage/reducer";
import { ENABLE_PORTAL_COMMENTS, ProjectBadged, useProjectBadgeContext } from "providers/BadgeProvider";
import { CancelRequestPopup } from "views/ProjectCalendarView/RequestModal";
import { Icon, Tooltip } from "@alphasights/alphadesign-components";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useHideExpertContext } from "providers/HideExpertProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { getWorkstream } from "components/InteractionsPage/helpers/Workstreams";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";
import { LabelButton } from "components/InteractionLabel/InteractionLabel";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

export const InteractionActionIcons = ({
  backgroundSyncRunning,
  interaction,
  isActiveProject,
  onCancelRequest,
  onClickComments,
  isExpertHidden,
  onClickStar,
  isExpertStarred,
  onRequestRescheduleInteraction,
  origin,
  pccOptOutWindow,
  showCommentsButton,
  showMoreOptionsButton,
  showLabelButton = false,
  token,
  locationContext = undefined,
  hideStarOrigin = undefined,
  currentView = undefined,
  ...props
}) => {
  const { iconsActionsStyles, iconButtonStyles } = useInteractionActionIconstyles();
  const { isMobile } = useCheckScreen();
  const [cancellingId, setCancellingId] = useState(null);
  const dispatch = useContext(DispatchContext);
  const onRequestChangeInteraction = useCallback((state) => dispatch(requestChangeInteraction(state)), [dispatch]);
  const { locked } = useLockedExpert(interaction);
  const isDeliverablesView = currentView === HitOrigin.deliverablesView;
  const { isFeatureDisabled } = useCurrentProjectContext();

  const showActions = !isDeliverablesView && isActiveProject && !locked;
  const { project, workstreamId } = useProjectInteractionsContext() || {};
  const isSurveyWorkstream = getWorkstream(project, workstreamId)?.workstreamType === "survey";
  if (!showActions) return null;
  const isCommentsButtonVisible = !isExpertHidden && showCommentsButton && !isSurveyWorkstream;
  const isLabelsEditDisabled = isFeatureDisabled(ProjectFeature.EditLabels);
  const interactionActionsEnabled = !isFeatureDisabled(ProjectFeature.InteractionActions);

  return (
    <>
      <x.div {...iconsActionsStyles} {...props} data-testid={`action-icons-${interaction.id}`}>
        {showLabelButton && !isLabelsEditDisabled && <LabelButton interactions={[interaction]} />}

        {interactionActionsEnabled && (
          <>
            {!isExpertHidden && !isSurveyWorkstream && (
              <StarButton
                disabled={backgroundSyncRunning}
                starred={isExpertStarred}
                onClick={onClickStar}
                size={isMobile ? "large" : "medium"}
                {...iconButtonStyles}
              />
            )}

            <HideButton
              disabled={backgroundSyncRunning}
              hidden={isExpertHidden}
              interaction={interaction}
              origin={origin}
              size={isMobile ? "large" : "medium"}
              locationContext={locationContext}
              hideStarOrigin={hideStarOrigin}
              currentView={currentView}
              {...iconButtonStyles}
            />

            {isCommentsButtonVisible && (
              <ProjectBadged badge={ENABLE_PORTAL_COMMENTS}>
                <CommentsButton
                  onToggleComments={onClickComments}
                  size={isMobile ? "large" : "medium"}
                  {...iconButtonStyles}
                />
              </ProjectBadged>
            )}
          </>
        )}

        {!isExpertHidden && showMoreOptionsButton && (
          <MoreOptions
            onCancelInteraction={() => setCancellingId(interaction.id)}
            onRequestRescheduleInteraction={() => onRequestRescheduleInteraction(interaction.id)}
            onCancelRequest={onCancelRequest}
            pccOptOutWindow={pccOptOutWindow}
            interaction={interaction}
            closeOnScroll={currentView === "list-view"}
            token={token}
            size={isMobile ? "large" : "medium"}
            {...iconButtonStyles}
          />
        )}

        {cancellingId && (
          <CancelRequestPopup
            id={cancellingId}
            onClose={() => setCancellingId(null)}
            onSubmit={onRequestChangeInteraction}
            origin={origin}
          />
        )}
      </x.div>
    </>
  );
};

const StarButton = ({ starred, disabled, onClick, ...props }) => {
  const icon = useMemo(() => (starred ? <StarSelected /> : <StarUnselected />), [starred]);
  const tooltip = useMemo(() => (starred ? "Unstar expert" : "Star expert"), [starred]);

  return (
    <x.div cursor={disabled ? "not-allowed" : "auto"}>
      <Tooltip title={tooltip}>
        <Icon
          color="secondary"
          data-testid={starred ? "unstar-button" : "star-button"}
          onClick={
            disabled
              ? undefined
              : (e) => {
                  e.stopPropagation();
                  onClick();
                }
          }
          {...props}
        >
          {icon}
        </Icon>
      </Tooltip>
    </x.div>
  );
};

const HideButton = ({
  hidden,
  origin,
  interaction,
  onClick,
  locationContext,
  hideStarOrigin,
  disabled,
  currentView,
  ...props
}) => {
  const icon = useMemo(() => (hidden ? <ShowAlt /> : <HideAlt />), [hidden]);
  const currentUser = useCurrentUser();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { setHideRef, onToggleHiddenWithMessage } = useHideExpertContext();
  const enablePortalComments = hasProjectBadge(ENABLE_PORTAL_COMMENTS);

  let tooltip = useMemo(
    () => (hidden ? "Show expert" : currentUser && enablePortalComments ? "Hide expert with reason" : "Hide expert"),
    [currentUser, enablePortalComments, hidden]
  );

  const hideContext = useMemo(() => currentView + locationContext, [locationContext, currentView]);

  return (
    <x.div
      ref={(ref) => setHideRef(interaction.id, ref, hideContext)}
      style={{ display: "flex", alignItems: "center" }}
      cursor={disabled ? "not-allowed" : "auto"}
    >
      <Tooltip title={tooltip} dark>
        <Icon
          color="secondary"
          data-testid={hidden ? "unhide-button" : "hide-button"}
          onClick={(e) => {
            !disabled && onToggleHiddenWithMessage(interaction, hideStarOrigin || origin, hideContext);
            e.stopPropagation();
          }}
          {...props}
        >
          {icon}
        </Icon>
      </Tooltip>
    </x.div>
  );
};

const CommentsButton = ({ onToggleComments, ...props }) => {
  return (
    <x.div>
      <Tooltip title="Comment">
        <IconWithLoginWall
          color="secondary"
          data-testid="comments-button"
          onClick={(e) => {
            e.stopPropagation();
            onToggleComments();
          }}
          tooltip="Comment"
          dark
          {...props}
        >
          <Chat />
        </IconWithLoginWall>
      </Tooltip>
    </x.div>
  );
};

const IconWithLoginWall = withLoginWall(Icon);
