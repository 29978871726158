import { Typography, TypographyVariation } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

type PrimerFieldTitleProps = {
  text: string;
  textAlign?: string;
  variant?: TypographyVariation;
  w?: string;
  transform?: string;
  color?: string;
  mt?: string;
  xtraProps?: any;
};

const PrimerFieldTitle = ({
  text,
  variant,
  textAlign,
  w,
  transform = "uppercase",
  color = "secondary",
  mt,
  xtraProps,
}: PrimerFieldTitleProps) => {
  const { isMobile } = useCheckScreen();

  let fontVariant = variant;

  if (!fontVariant || fontVariant.length === 0) fontVariant = isMobile ? "body-large" : "body"; // default value

  return (
    <Typography
      component="div"
      color={color}
      variant={fontVariant ?? "body"}
      textTransform={transform}
      textAlign={textAlign ?? "left"}
      fontWeight="600"
      w={w ?? ""}
      mt={mt ?? ""}
      {...xtraProps}
    >
      {text}
    </Typography>
  );
};

export default PrimerFieldTitle;
