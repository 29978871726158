import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import {
  Container,
  IconButton,
  ListOption,
  MobileTopBar,
  Portal,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import {
  ArrowLeft,
  Chat,
  Hide,
  MoreVert,
  Show,
  StarSelected,
  StarUnselected,
  Tick,
} from "@alphasights/alphadesign-icons";
import { useMobileExpertsFlyoutStyles } from "./MobileExpertsFlyout.styles";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { getThreadStateColorFlyout } from "components/MessengerPage/helper";
import { getStateName } from "components/AdvisorCard/stateFunctions";
import { FlyoutMobileCTAs } from "./MobileExpertsFlyoutCTAs.component";
import { FlyoutMode } from "pages/InteractionPage/enums";
import { MobileSchedulingPanel } from "../MobileSchedulingPanel";
import { useFixedWhileInBackground } from "utils/mobileHacks";
import { MobileNewMessage } from "views/MobileMessengerPage/MobileNewMessage";
import { useMessengerContext } from "providers/MessengerProvider";
import { MobileMessengerContent } from "views/MobileMessengerPage/MobileMessengerContent/MobileMessengerContent.component";
import { useNavigate } from "router-utils";
import { useHideExpertContext } from "providers/HideExpertProvider";
import { useStarExpertContext } from "providers/StarExpertProvider";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitOrigin } from "@alphasights/portal-api-client";

export const MobileExpertsFlyoutNav = forwardRef(
  (
    {
      interaction,
      pendingRequest,
      clientRequests,
      onClose,
      onToggleComments,
      chainInteractions,
      onSelectInteraction,
      onRequest,
      onRequestFollowUp,
      hasClientPortalMessages,
      isActiveProject,
      onSchedule,
      flyoutMode,
      changeFlyoutMode,
      isScheduleView,
      requestedScheduleDate,
      allInteractions,
      clientAvailability,
      advisorAvailability,
      onCloseScheduling,
      onSubmitSchedule,
      flyoutBodyRef,
      plusXInteractionsElem,
      projectToken,
      backgroundSyncRunning,
    }: {
      interaction?: Interaction;
      pendingRequest?: any;
      clientRequests?: any[];
      onClose: () => void;
      onToggleComments: (interation: Interaction) => void;
      chainInteractions: Interaction[];
      onSelectInteraction: (interaction: Interaction) => void;
      onRequest: (id: string, origin: string) => void;
      onRequestFollowUp: ({ id, origin }: { id: string; origin: string }) => void;
      hasClientPortalMessages: boolean;
      isActiveProject: boolean;
      onSchedule: (interaction: Interaction) => void;
      flyoutMode: FlyoutMode;
      changeFlyoutMode: (mode: FlyoutMode) => void;
      requestedScheduleDate: Date;
      isScheduleView: boolean;
      onCloseScheduling: () => void;
      allInteractions: Interaction[];
      clientAvailability: any[];
      advisorAvailability: any[];
      onSubmitSchedule: (slots: any, origin: HitOrigin) => Promise<void>;
      flyoutBodyRef: React.RefObject<HTMLDivElement>;
      plusXInteractionsElem: HTMLDivElement | null;
      projectToken: string;
      backgroundSyncRunning: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const navigate = useNavigate();
    const [viewMore, setViewMore] = useState(false);
    const [newMessage, setNewMessage] = useState(false);
    const [openMessageDetails, setOpenMessageDetails] = useState(false);

    const { onSendNewMessage } = useMessengerContext();
    const { messageThreads } = useFlyoutContext();
    const { onToggleHiddenWithoutMessage: onToggleHidden } = useHideExpertContext()!;
    const { onToggleStar } = useStarExpertContext()!;
    const selectAndBack = (interaction: Interaction) => {
      onSelectInteraction(interaction);
      setViewMore(false);
    };

    const flyoutTitle = flyoutMode === FlyoutMode.Comments ? "Comments" : interaction?.advisorName;
    const onBackClick = flyoutMode === FlyoutMode.Comments ? () => changeFlyoutMode(FlyoutMode.Interaction) : onClose;
    const onMoreOptionsClick = useMemo(
      () => (flyoutMode === FlyoutMode.Comments ? undefined : () => setViewMore(true)),
      [flyoutMode]
    );
    const onCommentsClick = flyoutMode === FlyoutMode.Comments ? undefined : () => onToggleComments(interaction!);

    useFixedWhileInBackground({ isInBackground: viewMore || isScheduleView, refToFix: flyoutBodyRef });
    useEffect(() => {
      if (plusXInteractionsElem && onMoreOptionsClick) {
        plusXInteractionsElem.addEventListener("click", onMoreOptionsClick);
      }
    }, [plusXInteractionsElem, onMoreOptionsClick]);

    const onMessageClick = (message: any) => {
      navigate(`/${projectToken}/experts/messages-view?selectedThread=${message.id}`);
    };

    return (
      <>
        {!isScheduleView && !viewMore && interaction && (
          <>
            <FlyoutMobileHeader
              title={flyoutTitle}
              onMoreOptionsClick={onMoreOptionsClick}
              onCommentsClick={onCommentsClick}
              onBackClick={onBackClick}
            />
            {flyoutMode !== FlyoutMode.Comments && (
              <Portal>
                <FlyoutMobileCTAs
                  ref={ref}
                  interaction={interaction}
                  onRequest={onRequest}
                  hasClientPortalMessages={hasClientPortalMessages}
                  isActiveProject={isActiveProject}
                  onRequestFollowUp={onRequestFollowUp}
                  pendingRequest={pendingRequest}
                  onSchedule={onSchedule}
                  setNewMessage={setNewMessage}
                  backgroundSyncRunning={backgroundSyncRunning}
                />
              </Portal>
            )}
          </>
        )}
        {!isScheduleView && viewMore && interaction && (
          <x.div display={"flex"} flexDirection={"column"} w="100%" h="100vh">
            <FlyoutMobileHeader onBackClick={() => setViewMore(false)} withDivider={true} />
            <MobileMoreOptions
              interaction={interaction}
              onStarClick={() => onToggleStar(interaction, HitOrigin.flyout)}
              onHideClick={() => onToggleHidden(interaction, HitOrigin.flyout)}
              chainInteractions={chainInteractions}
              onInteractionClick={selectAndBack}
              messages={messageThreads}
              onMessageClick={onMessageClick}
              clientRequests={clientRequests}
            />
          </x.div>
        )}
        {!isScheduleView && newMessage && interaction && (
          <Portal>
            <MobileNewMessage
              onClose={() => onSendNewMessage(undefined)}
              attachTextBoxToNavbar={false}
              onSent={() => setOpenMessageDetails(true)}
              selectedExpertId={interaction.advisorId}
            />
          </Portal>
        )}
        {!isScheduleView && openMessageDetails && interaction && (
          <Portal>
            <MobileMessengerContent onClose={() => setOpenMessageDetails(false)} />
          </Portal>
        )}
        {isScheduleView && interaction && (
          <x.div display={"flex"} flexDirection={"column"} w="100%" h="100vh">
            <FlyoutMobileHeader title="Schedule Interaction" withDivider={true} onBackClick={onCloseScheduling} />
            <MobileSchedulingPanel
              interaction={interaction}
              allInteractions={allInteractions}
              clientAvailability={clientAvailability}
              advisorAvailability={advisorAvailability}
              pendingRequest={pendingRequest}
              allowNonHourAutoBook={interaction.allowNonHourAutoBook}
              previousAdvisorshipId={
                interaction.previousAdvisorshipId ? Number(interaction.previousAdvisorshipId) : null
              }
              requestedScheduleDate={requestedScheduleDate}
              onSubmit={onSubmitSchedule}
            />
          </x.div>
        )}
      </>
    );
  }
);

const MobileMoreOptions = ({
  interaction,
  onStarClick,
  onHideClick,
  chainInteractions,
  onInteractionClick,
  messages,
  onMessageClick,
  clientRequests,
}: {
  interaction: Interaction;
  onStarClick: (interaction: Interaction) => void;
  onHideClick: (interaction: Interaction) => void;
  chainInteractions: Interaction[];
  onInteractionClick: (interaction: Interaction) => void;
  messages: any[];
  onMessageClick: (message: any) => void;
  clientRequests?: any[];
}) => {
  return (
    <x.div display={"flex"} flexDirection={"column"}>
      <Container>
        {!interaction.starred && (
          <ListOption
            dataAttributes={{ "data-testid": "star-btn" }}
            onChange={() => onStarClick(interaction)}
            type="text"
            leftIcon={<StarUnselected />}
            label={"Star expert"}
          />
        )}
        {interaction.starred && (
          <ListOption
            dataAttributes={{ "data-testid": "unstar-btn" }}
            onChange={() => onStarClick(interaction)}
            type="text"
            leftIcon={<StarSelected />}
            label={"Un-star expert"}
          />
        )}
        {!interaction.hidden && (
          <ListOption
            dataAttributes={{ "data-testid": "hide-btn" }}
            onChange={() => onHideClick(interaction)}
            type="text"
            leftIcon={<Show />}
            label={"Hide expert"}
          />
        )}
        {interaction.hidden && (
          <ListOption
            dataAttributes={{ "data-testid": "unhide-btn" }}
            onChange={() => onHideClick(interaction)}
            type="text"
            leftIcon={<Hide />}
            label={"Show expert"}
          />
        )}

        {chainInteractions.length ? (
          <>
            <ListOption type="divider"></ListOption>
            <ListOption type="title" label="Interactions"></ListOption>
          </>
        ) : null}

        {chainInteractions.map((ci) => (
          <ListOptionInteraction
            onClick={() => onInteractionClick(ci)}
            selected={interaction.id === ci.id}
            interaction={ci}
            pendingRequest={clientRequests?.find((r) => r.interactionId === ci.id)}
          />
        ))}

        {messages.length ? (
          <>
            <ListOption type="divider"></ListOption>
            <ListOption type="title" label="Messages"></ListOption>
          </>
        ) : null}

        {messages.map((m) => (
          <ListOptionMessage onClick={() => onMessageClick(m)} message={m} />
        ))}
      </Container>
    </x.div>
  );
};

const ListOptionMessage = ({ onClick, message }: { onClick: () => void; message: any }) => {
  const statusColor = useMessageStateColor(message);

  return (
    <ListOption dataAttributes={{ "data-testid": `message-option-${message.id}` }} type="text" onChange={onClick}>
      <Typography component={"span"}>{message.workRequest ? "Work Request" : "Clarification"} • </Typography>
      <Typography component={"span"} color={statusColor} textTransform={"capitalize"}>
        {message.state.description}
      </Typography>
    </ListOption>
  );
};

const ListOptionInteraction = ({
  onClick,
  selected = false,
  interaction,
  pendingRequest,
}: {
  onClick: () => void;
  selected?: boolean;
  interaction: Interaction;
  pendingRequest?: any;
}) => {
  const statusColor = useInteractionStateColor(interaction);
  const statusLabel = getStateLabel(interaction, pendingRequest);
  const interactionTypeDisplayName =
    interaction.interactionType === "Miscellaneous Payment" ? "Non-Standard Situations" : interaction.interactionType;
  return (
    <ListOption
      dataAttributes={{ "data-testid": `interaction-option-${interaction.id}` }}
      type="text"
      onChange={onClick}
      rightIcon={selected && <Tick />}
    >
      <Typography component={"span"}>{interactionTypeDisplayName} • </Typography>
      <Typography component={"span"} color={statusColor} textTransform={"capitalize"}>
        {statusLabel}
      </Typography>
    </ListOption>
  );
};

const FlyoutMobileHeader = ({
  title = "",
  onMoreOptionsClick,
  onCommentsClick,
  onBackClick,
  withDivider = false,
}: {
  title?: string;
  onMoreOptionsClick?: () => void;
  onCommentsClick?: () => void;
  onBackClick: () => void;
  withDivider?: boolean;
}) => {
  const { topBar } = useMobileExpertsFlyoutStyles({ withDivider });
  const user = useCurrentUser();
  const { openLoginModal } = useClientPortalOperations();

  return (
    <x.div {...topBar}>
      <MobileTopBar
        mainButton={
          <IconButton size="large" variant="ghost" onClick={onBackClick} testId="close-flyout-btn">
            <ArrowLeft />
          </IconButton>
        }
        title={title}
        regularButtons={
          <>
            {onCommentsClick && (
              <IconButton
                size="large"
                variant="ghost"
                onClick={() => (user ? onCommentsClick() : openLoginModal({ allowAnonymousContinue: true }))}
                testId="open-comments-flyout-btn"
              >
                <Chat />
              </IconButton>
            )}
            {onMoreOptionsClick && (
              <IconButton size="large" variant="ghost" onClick={onMoreOptionsClick} testId="more-options-flyout-btn">
                <MoreVert />
              </IconButton>
            )}
          </>
        }
      />
    </x.div>
  );
};

const useMessageStateColor = (message: any): string => {
  const st = message.state as string;
  const { color } = useThemeTokens();
  return getThreadStateColorFlyout(st, color);
};

const useInteractionStateColor = (interaction: Interaction): string => {
  const { color } = useThemeTokens();
  return (
    {
      proposed: color.text.secondary,
      requested: color.text.danger,
      completed: color.text.success,
      scheduled: color.text.processing,
    }[interaction.state] ?? color.text.secondary
  );
};

const getStateLabel = (interaction: Interaction, pendingRequest?: any): string => {
  return getStateName({
    state: interaction.state,
    previousAdvisorshipId: interaction.previousAdvisorshipId,
    isExpertHidden: interaction.isExpertHidden,
    followUpId: interaction.followUpId,
    showCalendarView: true,
    pendingRequest: pendingRequest,
  });
};
