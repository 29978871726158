import * as React from "react";
import styled, { x, th } from "@xstyled/styled-components";
import { Icon, IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ChevronUp } from "@alphasights/alphadesign-icons";

export const ExpandContainer = styled(x.div)<{ expanded: number }>`
  position: ${({ expanded }) => (expanded === 1 ? "relative" : "absolute")};
  bottom: 0;
  right: 0;
  left: 0;
  height: ${({ expanded }) => (expanded === 1 ? "auto" : "100px")};
  background: ${({ expanded }) =>
    expanded === 1 ? "transparent" : "linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)"};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1px;
  padding-top: ${({ expanded }) => (expanded === 1 ? "16px" : "0")};
`;

export const ExpandButton = styled(x.button)`
  border-radius: ${th.radius("24")};
  box-shadow: ${th.shadow("300")};
  padding: ${th.space("inner-base02")} ${th.space("inner-base03")};
  outline: ${th.borderWidth("sm")} solid ${th.color("border-neutral-default")};
  background: ${th.color("background-input-default")};

  &:hover {
    background: ${th.color("background-input-hover")};
  }

  &:focus {
    outline: ${th.borderWidth("sm")} solid ${th.color("border-neutral-default")};
  }
`;

interface RotatedIconProps extends React.ComponentProps<typeof IconButton> {
  up: number;
}

export const RotatedIcon = styled(Icon)<RotatedIconProps>`
   {
    display: inline-flex;
    align-self: center;
    justify-content: center;
    transform: ${({ up }) => (up === 1 ? "rotate(0deg)" : "rotate(180deg)")};
    transform-origin: center;
    transition: transform 0.5s;
  }
`;

const ExpandToggle = ({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: (expandIt: boolean) => void;
}) => {
  const { color, spacing } = useThemeTokens();

  return (
    <ExpandContainer expanded={isExpanded ? 1 : 0}>
      <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
        <x.div display="flex" alignItems="center" columnGap={spacing.inner.base02}>
          <Typography variant="body-small" color={color.text.secondary}>
            {isExpanded ? "Show less" : "Show more"}
          </Typography>
          <RotatedIcon color={color.icon.secondary} up={isExpanded ? 1 : 0}>
            <ChevronUp />
          </RotatedIcon>
        </x.div>
      </ExpandButton>
    </ExpandContainer>
  );
};

export default ExpandToggle;
