import styled from "styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const SubtitleContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base04,
    paddingTop: spacing.inner.base03,
  };
});

const truncateTextStyle = {
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical" as "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const StyledCardDescription = styled(Typography)(() => {
  return {
    ...truncateTextStyle,
    WebkitLineClamp: 2,
  };
});

const StyledCardTitle = styled(Typography)(() => {
  const { spacing, color } = useThemeTokens();
  return {
    ...truncateTextStyle,
    color: color.base.black,
    height: spacing.layout.base05,
    maxHeight: spacing.layout.base05,
  };
});

export { SubtitleContainer, StyledCardDescription, StyledCardTitle };
