import { memo } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import Title from "./components/Title/Title";
import Filters from "./components/Filters/Filters";
import Experts from "./components/Experts/Experts";
import { Spinner } from "@alphasights/client-portal-shared";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyInsights } from "views/SurveysResultsView/api";

const sidebarStyling = {
  w: 357,
};

const Sidebar = memo(() => {
  const { color } = useThemeTokens();

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { isLoading } = useSurveyInsights(surveyId);

  return (
    <x.div
      display="flex"
      flexDirection="column"
      h="100%"
      backgroundColor={color.background.surface.page.default}
      flex={`0 0 ${sidebarStyling.w}px`}
      borderLeft={`1px solid ${color.border.neutral.default}`}
    >
      <Title />
      {isLoading ? (
        <x.div display="flex" justifyContent="center" alignItems="center" h="inherit">
          <Spinner />
        </x.div>
      ) : (
        <>
          <Filters />
          <Experts />
        </>
      )}
    </x.div>
  );
});

export default Sidebar;
