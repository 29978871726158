import * as React from "react";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { SectionTitle } from "pages/AlphaNowPage/primers/components";
import SubMarkets from "./SubMarkets/SubMarkets";
import CoreCompetitors from "./Core/CoreCompetitors";
import AdjacentCompetitors from "./Adjacent/AdjacentCompetitors";
import { useCompanyPrimerV3Store } from "../../store/store";
import SectionWrapper from "../styled";

const Competitors = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { markets, core, adjacent } = useCompanyPrimerV3Store(({ competitors }) => competitors);

  return (
    <SectionWrapper ref={ref}>
      <x.div display="flex" flexDirection="column" gap={inner.base06}>
        <SectionTitle
          dataTestId="CompetitorsSectionTitle"
          text="Competitors"
          heading
          xtraProps={{ paddingLeft: inner.base10 }}
        />
        <Divider />
        <SubMarkets markets={markets} />
        <Divider />
        <CoreCompetitors coreCompetitors={core} />
        <Divider />
        {adjacent.length > 0 && <AdjacentCompetitors adjacentCompetitors={adjacent} />}
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Competitors));
