import { useState, useMemo } from "react";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./SynthesisModuleHeader.styles";
import {
  Button,
  Icon,
  IconButton,
  Pill,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from "@alphasights/alphadesign-components";
import { Add, Command, Copy, Delete, Edit, GenerativeAi, Refresh, Reply } from "@alphasights/alphadesign-icons";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { FormattedDateRelative } from "providers/TimezoneProvider";
import styled from "styled-components";
import {
  HitAction,
  SynthesisModuleRevision,
  SynthesisModule,
  SynthesisQuestion,
  SynthesisKPC,
  SynthesisVendors,
  TranscriptMention,
  SynthesisNPS,
  SynthesisVendorSwitching,
} from "@alphasights/portal-api-client";
import { SynthesisReprocessButton } from "./SynthesisReprocessButton";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import {
  DeleteEmptyModuleModal,
  DeleteModuleModal,
  DisableAutoRegenerationModal,
  SaveAndRegenerateModal,
} from "./ConfirmationModals";
import {
  formatSynthesisAnswer,
  formatSynthesisKpc,
  formatSynthesisNps,
} from "../SynthesisModuleContent/QuestionModuleContent";
import { copyTextToClipboard } from "components/FloatingActions";
import {
  isKpcContent,
  isNPSContent,
  isQuestionContent,
  isVendorContent,
  isVendorSwitchingContent,
} from "../synthesisTypeGuards";
import { renderToString } from "react-dom/server";
import { formatSynthesisVendorSwitching } from "../SynthesisModuleContent/VendorSwitchingModuleContent";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

export const SynthesisModuleHeader = () => {
  const styles = useStyles();
  const { selectedModule, mode, revision } = useProjectSynthesisContext();

  const HeaderContent = selectVariant(selectedModule, mode, revision);

  return (
    <x.div>
      <HeaderContent />
      <x.div {...styles.separator} />
    </x.div>
  );
};

const selectVariant = (selectedModule: SynthesisModule | undefined, mode: Mode, question?: SynthesisModuleRevision) => {
  if (mode === Mode.NEW) return NewModuleVariant;
  if (!selectedModule) return EmptyVariant;
  if (question?.status === "PROCESSING") return LoadingVariant;
  if (selectedModule?.visibility === "SUGGESTED") return SuggestedModuleVariant;
  if (mode === Mode.EDIT) return EditVariant;

  return DefaultVariant;
};

const DefaultVariant = () => {
  const styles = useStyles();
  const { revision, moduleTitle, selectedModule } = useProjectSynthesisContext();
  const { project } = useCurrentProjectContext();

  if (!revision) return null;

  const hasEditMode = revision.status !== "FAILED" && selectedModule?.contentType === "QUESTION";

  const showAutoRegenerationToggle = project?.active;

  return (
    <x.div data-testid="synthesis-top-bar" {...styles.moduleTopBarWrapper}>
      <x.div {...styles.leftGroup}>
        <HeaderTitle title={moduleTitle} />
        <Typography component="span" color="disabled" {...styles.regeneratedAt}>
          <Icon size="small" color="disabled">
            <Refresh />
          </Icon>
          Regenerated <FormattedDateRelative date={revision?.createdAt} />
        </Typography>
      </x.div>

      <x.div {...styles.rightGroup}>
        {showAutoRegenerationToggle && <AutoRegenerationToggle />}
        <RefreshBtn />
        {revision.status === "COMPLETED" && <CopyBtn />}
        {hasEditMode && <EditBtn />}
        <DeleteBtn />
      </x.div>
    </x.div>
  );
};

const EditVariant = () => {
  const styles = useStyles();
  const {
    setMode,
    saveModuleChanges,
    cancelModuleChanges,
    saveInProgress,
    isQuestionEdited,
    selectedModule,
    synthesisPreferences,
    newSynthesisModule,
    moduleTitle,
    revision,
    readOnly,
  } = useProjectSynthesisContext();

  const [saveAndRegenerateModalOpen, setSaveAndRegenerateModalOpen] = useState(false);
  const [disableAutoRegenModalOpen, setDisableAutoRegenModalOpen] = useState(false);
  const [deleteModuleModalOpen, setDeleteModuleModalOpen] = useState(false);

  const hasError = useMemo(() => {
    if (!revision) return false;
    const questionHasError =
      isQuestionContent(revision.contents) && newSynthesisModule.questionHasError(revision.contents.question ?? "");
    return questionHasError;
  }, [newSynthesisModule, revision]);

  const handleSave = () => {
    const details = {
      originator: "save-btn",
      promptShown: false,
    };
    if (selectedModule?.aiRegenerationEnabled && synthesisPreferences.showSavePrompt) {
      setDeleteModuleModalOpen(false);
      setDisableAutoRegenModalOpen(true);
    } else if (
      selectedModule?.aiRegenerationEnabled &&
      !synthesisPreferences.showSavePrompt &&
      synthesisPreferences.savePromptSelectedOption === "saveAndDisable"
    ) {
      saveModuleChanges(
        { ...selectedModule!, aiRegenerationEnabled: false },
        { ...details, chosenOption: "saveAndDisable" },
        true,
        "Module locked. Edits saved."
      );
    } else {
      saveModuleChanges(selectedModule!, { ...details, chosenOption: "save" });
    }
  };

  const handleSaveAndRegenerate = () => {
    if (isQuestionEdited && synthesisPreferences.showSaveAndRegeneratePrompt) {
      setSaveAndRegenerateModalOpen(true);
    } else {
      const details = {
        originator: "save-and-regenerate-btn",
        promptShown: false,
      };
      saveModuleChanges({ ...selectedModule!, aiRegenerationEnabled: true }, details);
    }
  };

  const handleCancel = () => {
    setMode(Mode.VIEW);
    cancelModuleChanges();
  };

  const validateEmptyModule = () => {
    if (isQuestionContent(revision?.contents) && revision?.contents.answers.length === 0) {
      setDeleteModuleModalOpen(true);
      return;
    }
    handleSave();
  };

  return (
    <>
      <x.div {...styles.moduleTopBarWrapper}>
        <x.div {...styles.leftGroup}>
          <HeaderTitle title={moduleTitle} />
          <Pill size="x-small">Editing</Pill>
        </x.div>

        <x.div {...styles.rightGroup}>
          {revision?.status !== "FAILED" ? (
            <>
              <UndoBtn />
              <RedoBtn />
              <Button
                disabled={saveInProgress || readOnly}
                onClick={handleCancel}
                variant="outline"
                size="small"
                dataAttributes={{ "data-testid": "cancel-synthesis-edit" }}
              >
                Cancel
              </Button>
              <Button
                disabled={saveInProgress || hasError || readOnly}
                onClick={validateEmptyModule}
                variant={`${isQuestionEdited ? "outline" : "secondary"}`}
                size="small"
                dataAttributes={{ "data-testid": "save-synthesis" }}
              >
                Save
              </Button>
              {isQuestionEdited && (
                <Button
                  disabled={saveInProgress || hasError || readOnly}
                  startIcon={<GenerativeAi />}
                  onClick={handleSaveAndRegenerate}
                  variant="secondary"
                  size="small"
                  dataAttributes={{ "data-testid": "save-and-regenerate-synthesis" }}
                >
                  Save and Regenerate
                </Button>
              )}
              <DeleteBtn />
            </>
          ) : (
            <>
              <Button
                disabled={saveInProgress || hasError || readOnly}
                startIcon={<GenerativeAi />}
                onClick={() =>
                  saveModuleChanges(
                    { ...selectedModule!, aiRegenerationEnabled: true },
                    { originator: "save-and-regenerate-btn", promptShown: false }
                  )
                }
                variant="secondary"
                size="small"
                dataAttributes={{ "data-testid": "save-and-regenerate-synthesis" }}
              >
                Save and Regenerate
              </Button>
              <DeleteBtn />
            </>
          )}
        </x.div>
      </x.div>

      <DeleteEmptyModuleModal isOpen={deleteModuleModalOpen} setIsOpen={setDeleteModuleModalOpen} onSave={handleSave} />
      <SaveAndRegenerateModal isOpen={saveAndRegenerateModalOpen} setIsOpen={setSaveAndRegenerateModalOpen} />
      <DisableAutoRegenerationModal isOpen={disableAutoRegenModalOpen} setIsOpen={setDisableAutoRegenModalOpen} />
    </>
  );
};

const NewModuleVariant = () => {
  const styles = useStyles();

  const { setMode, newSynthesisModule } = useProjectSynthesisContext();

  const handleCancel = () => {
    setMode(Mode.VIEW);
  };

  const handleRegenerate = () => {
    newSynthesisModule.onSubmit();
  };

  return (
    <x.div {...styles.moduleTopBarWrapper}>
      <x.div {...styles.leftGroup}>
        <HeaderTitle title="New theme" />
      </x.div>

      <x.div {...styles.rightGroup}>
        <Button onClick={handleCancel} variant="outline" size="small">
          Cancel
        </Button>
        <Button
          startIcon={<GenerativeAi />}
          onClick={handleRegenerate}
          variant="secondary"
          size="small"
          dataAttributes={{ "data-testid": "generate-new-insights-btn" }}
        >
          Generate New Insights
        </Button>
      </x.div>
    </x.div>
  );
};

const SuggestedModuleVariant = () => {
  const styles = useStyles();

  const { promoteModule, selectedModule, synthesisLogHit, moduleTitle, readOnly } = useProjectSynthesisContext();

  const onPromoteModule = () => {
    if (!selectedModule) return;
    promoteModule(selectedModule);
    synthesisLogHit({
      action: HitAction.projectSynthesisModulePromoted,
      references: { moduleIds: [selectedModule.id] },
      details: {
        cta: "header",
        moduleType: selectedModule.contentType,
      },
    });
  };

  return (
    <x.div {...styles.moduleTopBarWrapper}>
      <x.div {...styles.leftGroup}>
        <HeaderTitle title={moduleTitle} />
        <Pill size="x-small" variant="blue">
          Suggested
        </Pill>
      </x.div>

      <x.div {...styles.rightGroup}>
        <Button
          disabled={readOnly}
          startIcon={<Add />}
          onClick={onPromoteModule}
          variant="outline"
          size="small"
          dataAttributes={{ "data-testid": "add-to-synthesis" }}
        >
          Add To Synthesis
        </Button>
        <DeleteBtn />
      </x.div>
    </x.div>
  );
};

const LoadingVariant = () => {
  const styles = useStyles();
  const { moduleTitle } = useProjectSynthesisContext();

  return (
    <x.div {...styles.moduleTopBarWrapper} data-testid="loading-header">
      <x.div {...styles.leftGroup}>
        {moduleTitle ? <HeaderTitle title={moduleTitle} /> : <Skeleton variant="noMargin" width="200px" />}
      </x.div>

      <x.div {...styles.rightGroup}>
        <Skeleton variant="noMargin" width="300px" />
      </x.div>
    </x.div>
  );
};

const EmptyVariant = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.moduleTopBarWrapper} data-testid="empty-module-header">
      <x.div {...styles.leftGroup}></x.div>

      <x.div {...styles.rightGroup}>
        <SynthesisReprocessButton />
      </x.div>
    </x.div>
  );
};

const AutoRegenerationToggle = () => {
  const { selectedModule, saveModuleChanges, mode, synthesisLogHit, readOnly } = useProjectSynthesisContext();

  const handleToggle = () => {
    synthesisLogHit({
      action: HitAction.projectSynthesisModuleAutoRegenChanged,
      references: { moduleId: selectedModule?.id },
      details: {
        moduleType: selectedModule?.contentType,
        autoRegeneration: !selectedModule?.aiRegenerationEnabled,
      },
    });
    saveModuleChanges(
      { ...selectedModule!, aiRegenerationEnabled: !selectedModule!.aiRegenerationEnabled },
      undefined,
      false
    );
  };

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  if (mode === Mode.EDIT) return null;

  return (
    <Switch
      leftText="Auto regeneration"
      size="small"
      isSelected={selectedModule?.aiRegenerationEnabled}
      onClick={handleToggle}
      disabled={internalUserNoAccess || readOnly}
      dataAttributes={{ "data-testid": "synthesis-header-auto-regen" }}
    />
  );
};

const RefreshBtn = () => {
  const { selectedModule, reprocess, saveInProgress, readOnly } = useProjectSynthesisContext();
  if (selectedModule?.visibility !== "ADDED") return null;

  return (
    <Tooltip variant="dark" title="Refresh Theme">
      <IconButton
        disabled={saveInProgress || readOnly}
        onClick={() => reprocess({ angleTypeName: selectedModule.angleType, moduleId: selectedModule.id })}
        variant="outline"
        size="small"
        testId="synthesis-header-refresh-module"
      >
        <Refresh />
      </IconButton>
    </Tooltip>
  );
};

const CopyBtn = () => {
  const [copyTooltip, setCopyTooltip] = useState("Copy Theme");
  const { revision, synthesisLogHit, selectedModule, readOnly } = useProjectSynthesisContext();
  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  const handleCopy = () => {
    if (!revision) return;

    let hitDetails = {};

    if (revision && isQuestionContent(revision.contents)) {
      copyTextToClipboard(generateFormattedSynthesis(revision.contents));
      hitDetails = { question: revision.contents.question };
    } else if (revision && isKpcContent(revision.contents)) {
      generateFormattedKpc(revision.contents).then(copyTextToClipboard);
    } else if (revision && isNPSContent(revision.contents)) {
      generateFormattedNPS(revision.contents).then(copyTextToClipboard);
    } else if (revision && isVendorContent(revision.contents)) {
      copyTextToClipboard(generateFormattedVendor(revision.contents));
    } else if (revision && isVendorSwitchingContent(revision.contents)) {
      generateFormattedVendorSwitching(revision.contents).then(copyTextToClipboard);
    }

    synthesisLogHit({
      action: HitAction.projectSynthesisCopy,
      details: {
        revision: revision?.revision,
        contentCopiedType: "module",
        moduleType: selectedModule?.contentType,
        ...hitDetails,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
    setCopyTooltip("Copied!");
    setTimeout(() => {
      setCopyTooltip("Copy Theme");
    }, 1000);
  };

  return (
    <Tooltip variant="dark" title={copyTooltip}>
      <IconButton
        disabled={internalUserNoAccess || readOnly}
        onClick={handleCopy}
        variant="outline"
        size="small"
        testId="synthesis-header-copy"
      >
        <Copy />
      </IconButton>
    </Tooltip>
  );
};

const EditBtn = () => {
  const { setMode, saveInProgress, readOnly } = useProjectSynthesisContext();
  const handleEdit = () => {
    setMode(Mode.EDIT);
  };

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <Tooltip variant="dark" title="Edit Theme">
      <IconButton
        disabled={internalUserNoAccess || saveInProgress || readOnly}
        onClick={handleEdit}
        variant="outline"
        size="small"
        testId="synthesis-header-edit"
      >
        <Edit />
      </IconButton>
    </Tooltip>
  );
};

const UndoBtn = () => {
  const { undo, readOnly } = useProjectSynthesisContext();

  return (
    <Tooltip variant="dark" title={<ShortcutTooltip action="Undo" shortcut="z" />}>
      <IconButton disabled={readOnly} onClick={undo} variant="outline" size="small" testId="synthesis-header-undo">
        <Reply />
      </IconButton>
    </Tooltip>
  );
};

const RedoBtn = () => {
  const { redo, readOnly } = useProjectSynthesisContext();

  return (
    <Tooltip variant="dark" title={<ShortcutTooltip action="Redo" shortcut="y" />}>
      <MirroredIconButton
        disabled={readOnly}
        onClick={redo}
        variant="outline"
        size="small"
        testId="synthesis-header-redo"
      >
        <Reply />
      </MirroredIconButton>
    </Tooltip>
  );
};

const ShortcutTooltip = ({ action, shortcut }: { action: string; shortcut: string }) => {
  const { keyWrapper, flexCentered } = useStyles();
  const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform;
  const isMac = platform.toLowerCase().indexOf("mac") >= 0;

  return (
    <x.div {...flexCentered}>
      <x.span mr="8px">{action}</x.span>
      <x.div {...flexCentered} gap="4px">
        <x.div {...keyWrapper}>
          {isMac ? (
            <Icon size="x-small" color="inverse">
              <Command />
            </Icon>
          ) : (
            <Typography component="span" variant="body-small" color="inverse">
              ctrl
            </Typography>
          )}
        </x.div>
        <x.div {...keyWrapper}>
          <Typography component="span" variant="body-small" color="inverse">
            {shortcut}
          </Typography>
        </x.div>
      </x.div>
    </x.div>
  );
};

const DeleteBtn = () => {
  const [deleteModuleModalOpen, setDeleteModuleModalOpen] = useState(false);
  const { saveInProgress, vendorRefreshing, readOnly } = useProjectSynthesisContext();
  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <>
      <Tooltip variant="dark" title="Delete Theme">
        <IconButton
          disabled={internalUserNoAccess || saveInProgress || vendorRefreshing || readOnly}
          onClick={() => setDeleteModuleModalOpen(true)}
          variant="outline"
          size="small"
          color="danger"
          testId="synthesis-header-delete"
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <DeleteModuleModal isOpen={deleteModuleModalOpen} setIsOpen={setDeleteModuleModalOpen} />
    </>
  );
};

const HeaderTitle = ({ title }: { title: string }) => {
  return (
    <Typography component="span" variant="body-large-em" color="secondary">
      {title}
    </Typography>
  );
};

const MirroredIconButton = styled(IconButton)`
  transform: scaleX(-1);
`;

const formatSynthesisQuestion = (revision: SynthesisQuestion): string => {
  let formattedString = `
  <p style="font-size: 16px;">
    <span style="color: #666B7A;">${revision?.topic || ""}</span></p><br>
  <p style="font-size: 18px; ">${revision?.question}</p><br>`;

  revision?.overviews?.forEach((overview) => {
    const title = overview.title ? `<span style="font-weight: bold; font-size: 14px">${overview.title}</span>: ` : "";
    formattedString += `• ${title}${overview.summary}<br>`;
  });

  return formattedString + `<br>`;
};

const generateFormattedSynthesis = (question: SynthesisQuestion): string => {
  const formattedQuestion = formatSynthesisQuestion(question);

  const formattedAnswers = question.answers.map((answer) => `${formatSynthesisAnswer(answer)}`).join("\n\n");

  return `${formattedQuestion}${formattedAnswers}`;
};

const generateFormattedKpc = async (kpc: SynthesisKPC) => {
  const base64Img = await ApexCharts.exec("kpc-stacked-bar-chart", "dataURI").then((it: any) => it.imgURI as string);

  const image = `
    <img alt="kpc bar chart" src="${base64Img}" />
  `;

  const content = kpc.kpcItems.map((item) => `${formatSynthesisKpc(item)}`).join("\n\n");

  return `
  <div>
    ${image}
    ${content}
  </div>
  `;
};

const generateFormattedVendor = (vendors: SynthesisVendors): string => {
  const VendorSection = (vendorName: string, vendorSummary: string, mentions: TranscriptMention[]) => {
    const mentionsText = mentions.map((mention) => `${mention.advisorCompany} - ${mention.role}`).join(", ");
    return (
      <div>
        <h3>{vendorName}</h3>
        <br />
        <div>{vendorSummary}</div>
        <br />
        <div>
          <span style={{ color: "#666B7A" }}>Mentioned by: {mentionsText}</span>
        </div>
      </div>
    );
  };

  const vendorsAsString = vendors.featuredVendors.map((vendor) =>
    renderToString(VendorSection(vendor.companyName, vendor.summary ?? "", vendor.mentions))
  );

  return vendorsAsString.join("<br/><hr/><br/><br/>");
};

const generateFormattedNPS = async (nps: SynthesisNPS) => {
  const base64Img = await ApexCharts.exec("nps-stacked-bar-chart", "dataURI").then((it: any) => it.imgURI as string);

  const image = `
    <img alt="nps bar chart" src="${base64Img}" />
  `;
  const companies = nps.companies.map(formatSynthesisNps).join("\n\n");

  return `
  <div>
    ${image}
    ${companies}
  </div>
`;
};

const generateFormattedVendorSwitching = async (vendorSwitchingModule: SynthesisVendorSwitching) => {
  const base64Img = await ApexCharts.exec("vendor-switching-stacked-bar-chart", "dataURI").then(
    (it: any) => it.imgURI as string
  );
  const image = `
    <img alt="nps bar chart" src="${base64Img}" />
  `;
  const barriers = vendorSwitchingModule.barriers.map(formatSynthesisVendorSwitching).join("\n\n");
  const drivers = vendorSwitchingModule.drivers.map(formatSynthesisVendorSwitching).join("\n\n");
  return `
  <div>
    ${image}
    <p style="margin-bottom: 12px; font-weight: bold;">Barriers to Switching</p>
    ${barriers}
    <p style="margin-bottom: 12px; font-weight: bold;">Drivers to Switching</p>
    ${drivers}
  </div>
  `;
};
