import { x } from "@xstyled/styled-components";
import { useAlphaGPTSidebarStyles } from "./AlphaGPTSidebar.styles";
import { Button, Skeleton, Typography } from "@alphasights/alphadesign-components";
import { Add } from "@alphasights/alphadesign-icons";
import { AlphaGPTConversationList, AlphaGPTConversationListSkeleton } from "../AlphaGPTConversationList";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AlphaGPTUserFilter } from "pages/ProjectAlphaGPTPage/AlphaGPTUserFilter/AlphaGPTUserFilter";

export const AlphaGPTSidebar = () => {
  const { isMobile } = useCheckScreen();
  const { conversations, startNewConversation, showUserFilter, hasValidAlphaGPTPurchase } = useAlphaGPTContext();
  const { wrapper, buttons, newChatButton, label } = useAlphaGPTSidebarStyles({
    showUserFilter,
    hasValidAlphaGPTPurchase,
  });

  if (isMobile || !conversations.length) {
    return null;
  }

  return (
    <x.div {...wrapper} data-testid="alphagpt-sidebar">
      <x.div {...buttons}>
        {showUserFilter && <AlphaGPTUserFilter disabled={!hasValidAlphaGPTPurchase} />}
        <Button
          startIcon={<Add />}
          onClick={startNewConversation}
          disabled={!hasValidAlphaGPTPurchase}
          {...newChatButton}
        >
          New Chat
        </Button>
      </x.div>
      <x.div {...label}>
        <Typography color="assistive" variant="body-small-spaced">
          Recent
        </Typography>
      </x.div>
      <AlphaGPTConversationList />
    </x.div>
  );
};

export const AlphaGPTSidebarSkeleton = () => {
  const { isMobile } = useCheckScreen();
  const { showUserFilter } = useAlphaGPTContext();
  const { wrapper, buttons } = useAlphaGPTSidebarStyles({
    showUserFilter,
  });

  if (isMobile) {
    return null;
  }

  return (
    <x.div {...wrapper}>
      <x.div {...buttons}>
        {showUserFilter && (
          <x.div w="100px">
            <Skeleton width="100px" height="40px" variant="noMargin" />
          </x.div>
        )}
        <x.div w="140px">
          <Skeleton width="140px" height="40px" variant="noMargin" />
        </x.div>
      </x.div>
      <AlphaGPTConversationListSkeleton />
    </x.div>
  );
};
