import { useMemo } from "react";
import { Expert } from "@alphasights/alphadesign-icons";
import { useProjectAlphaGPTContext } from "providers/ProjectAlphaGPTProvider";
import { useProjectMembersContext } from "providers/ProjectMembersProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { FilterItem } from "components/InteractionsPage/FilterItem";

export const AlphaGPTUserFilter = ({ disabled = false }: { disabled: boolean }) => {
  const authUser = useCurrentUser()!;
  const { selectedUsersIds, setSelectedUsersIds, allConversations } = useProjectAlphaGPTContext()!;
  const { clients } = useProjectMembersContext();

  const allUsers = useMemo(
    () => [
      {
        displayName: "Me",
        clientProfile: {
          id: authUser.clientProfile.id,
        },
      },
      ...clients.filter(
        (c) =>
          c.clientProfile &&
          allConversations.some((conversation) => conversation.clientProfile.id === c.clientProfile?.id)
      ),
    ],
    [allConversations, authUser.clientProfile.id, clients]
  );

  const onApplyFilter = (selectedUserIds: string[]) => {
    setSelectedUsersIds(
      selectedUserIds.length ? selectedUserIds.map((id) => parseInt(id)) : [authUser.clientProfile.id]
    );
  };

  const firstSelectedUser = selectedUsersIds.length
    ? selectedUsersIds.includes(authUser.clientProfile.id ?? -1)
      ? "Me"
      : allUsers.find((u) => u.clientProfile?.id === selectedUsersIds[0])?.displayName
    : "";
  const selectedUsersCount = selectedUsersIds.length > 1 ? `+${selectedUsersIds.length - 1}` : "";

  return (
    <FilterItem
      filterDefinition={{
        name: "user",
        title: "User",
        value: selectedUsersIds.map((id) => id.toString()),
        options: allUsers.map((user) => ({
          label: user.displayName,
          value: user.clientProfile?.id.toString() ?? "",
        })),
      }}
      onApplyFilter={onApplyFilter}
      getAppliedFiltersCountFormatter={() => `${firstSelectedUser} ${selectedUsersCount}`}
      startIcon={<Expert />}
      data-testid="user-filter-button"
      disabled={disabled}
    />
  );
};
