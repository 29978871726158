import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const NoUpcomingCalls = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.wrapper}>
      <Typography variant="body-em" {...styles.title}>
        No upcoming calls
      </Typography>
      <Typography variant="body">Check back here when you have scheduled a call.</Typography>
    </x.div>
  );
};

const useStyles = () => {
  const tokens = useThemeTokens();

  return {
    wrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      mt: "-65px",
      mb: tokens.spacing.inner.base03,
    },
  };
};
