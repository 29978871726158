import { noop } from "lodash";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useCallback, useMemo, useState } from "react";

export const useEditItem = <T,>({
  item,
  initialMode = Mode.VIEW,
  onSaveChanges = Promise.resolve,
  onLeaveEditMode = noop,
  onEnterEditMode = noop,
}: {
  item: T;
  initialMode?: Mode;
  onSaveChanges?: (itemToSave: T) => Promise<unknown>;
  onLeaveEditMode?: () => void;
  onEnterEditMode?: () => void;
}) => {
  const [mode, setMode] = useState<Mode>(initialMode);
  const [editedItem, setEditedItem] = useState<T>(item);

  const actualItem = useMemo(() => (mode === Mode.EDIT ? editedItem : item), [editedItem, item, mode]);

  const onEditItem = useCallback((updatedItem: T) => {
    setEditedItem(updatedItem);
  }, []);

  const enterEditMode = useCallback(() => {
    setEditedItem(item);
    setMode(Mode.EDIT);
    onEnterEditMode();
  }, [item, onEnterEditMode]);

  const onCancelEdit = useCallback(() => {
    setMode(Mode.VIEW);
    setEditedItem(item);
    onLeaveEditMode();
  }, [item, onLeaveEditMode]);

  const onSaveEdit = useCallback(() => {
    onSaveChanges(actualItem)
      .then(() => {
        setMode(Mode.VIEW);
        onLeaveEditMode();
      })
      .catch(noop);
  }, [actualItem, onLeaveEditMode, onSaveChanges]);

  return {
    mode,
    actualItem,
    enterEditMode,
    onEditItem,
    onCancelEdit,
    onSaveEdit,
  };
};
