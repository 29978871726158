import { useCallback } from "react";
import {
  useNavigate as useNavigateReactRouter,
  Navigate as NavigateReactRouter,
  NavigateProps,
} from "react-router-dom";

type To = string | Partial<Path>;
interface Path {
  pathname: string;
  search: string;
  hash: string;
}
export interface NavigateFunction {
  (toOrDelta: To | number, options?: NavigateOptions): void;
}
interface NavigateOptions {
  replace?: boolean;
  state?: unknown;
}

const addReplaceToState = ({
  state: stateInput,
  replace,
}: {
  state?: unknown;
  replace?: boolean;
}): Record<string, unknown> | undefined => {
  const state = stateInput as Record<string, unknown> | undefined;
  return replace ? { ...state, replace } : state;
};

const useNavigate = () => {
  const navigateTo = useNavigateReactRouter();
  const navigate: NavigateFunction = useCallback(
    (toOrDelta, options) => {
      const { state: stateInput, replace } = options ?? {};

      if (typeof toOrDelta === "number") {
        navigateTo(toOrDelta);
        return;
      }

      const state = addReplaceToState({ state: stateInput, replace });

      state || options ? navigateTo(toOrDelta, { ...options, state }) : navigateTo(toOrDelta);
    },
    [navigateTo]
  );

  return navigate;
};

const Navigate = (props: NavigateProps) => {
  const { state: stateInput, replace } = props ?? {};

  return <NavigateReactRouter {...props} state={addReplaceToState({ state: stateInput, replace })} />;
};

// re-export everything
export * from "react-router-dom";

// override exports
export { useNavigate, Navigate };
