import { FC, ReactNode } from "react";
import { x } from "@xstyled/styled-components";
import {
  AccordionItem,
  Typography,
  useThemeTokens,
  Button,
  RoundedButtonVariant,
  XStyledProps,
} from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { CLEAR_SELECTION } from "components/Filter/constants";

import { AccordionItemContentsWrapper, StyledAccordion } from "./Filter.styled";

const DataTestIds = {
  accordionFilter: "accordion-filter",
  mobileAccordionFilter: "mobile-accordion-filter",
  flatFilter: "flat-filter",
};

type AccordionPropsType = {
  maxContentsHeight?: XStyledProps["maxHeight"];
};

type FilterPropsType = {
  children: ReactNode;
  title: string;

  onClear?: () => void;
  variant?: RoundedButtonVariant;
  isAccordion?: boolean;
  accordionProps?: AccordionPropsType;
} & XStyledProps;

const Filter: FC<FilterPropsType> = ({
  children,
  title,
  onClear = () => {},
  variant,
  isAccordion = false,
  accordionProps = {},
  ...props
}) => {
  const { color, spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  if (isMobile) {
    return (
      <StyledAccordion {...props} dataAttributes={{ "data-testid": DataTestIds.mobileAccordionFilter }}>
        <AccordionItem title={title} open>
          <AccordionItemContentsWrapper>
            <Typography minWidth="100%" pb={spacing.inner.base04} component="div">
              {children}
            </Typography>
            <Button color={color.text.secondary} variant="ghost" size="small" onClick={onClear}>
              {CLEAR_SELECTION}
            </Button>
          </AccordionItemContentsWrapper>
        </AccordionItem>
      </StyledAccordion>
    );
  }

  if (isAccordion) {
    const { maxContentsHeight } = accordionProps;
    const titleContent = (
      <Typography variant="body-small-em" color={color.text.secondary}>
        {title}
      </Typography>
    );
    return (
      <StyledAccordion {...props} dataAttributes={{ "data-testid": DataTestIds.accordionFilter }}>
        <AccordionItem titleContent={titleContent}>
          <AccordionItemContentsWrapper>
            <Typography
              w="100%"
              component="div"
              maxHeight={maxContentsHeight}
              overflowY={maxContentsHeight ? "scroll" : "visible"}
            >
              {children}
            </Typography>
          </AccordionItemContentsWrapper>
        </AccordionItem>
      </StyledAccordion>
    );
  }

  return (
    <x.div data-testid={DataTestIds.flatFilter} my={spacing.inner.base04} {...props}>
      <Typography
        id="filter-title"
        variant="body-small-em"
        component="div"
        color={color.text.secondary}
        mb={spacing.inner.base04}
      >
        {title}
      </Typography>
      {children}
    </x.div>
  );
};

export { Filter as default, DataTestIds };
export type { FilterPropsType, AccordionPropsType };
