import { PrimerCompanyLogo, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { AdjacentCompetitor } from "../../../types/competitors";
import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import TrendPill from "pages/AlphaNowPage/primers/components/TrendPill/TrendPill";
import { CompetitorGrowth } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";

const AdjacentCompetitors = ({ adjacentCompetitors }: { adjacentCompetitors: AdjacentCompetitor[] }) => {
  const {
    spacing: { inner },
    font,
    color: { text },
  } = useThemeTokens();

  const { isAccessible } = usePrimersStore();

  return (
    <x.div px={inner.base10} display="flex" flexDirection="column" gap={inner.base03}>
      <PrimerFieldTitle text="Adjacent Competitors" xtraProps={{ paddingBottom: inner.base03 }} />
      {adjacentCompetitors.map((adj, index) => (
        <SensitiveDataContainer key={index} isSensitive={!isAccessible}>
          <>
            <x.div display="grid" gridTemplateColumns={"205px auto"} alignItems="start" gap={inner.base04}>
              <x.div display="flex" flexDirection="column" gap={inner.base02}>
                {adj.companies.map((company, indexCompanies) => {
                  const [companyName, tooltip] = getTruncatedTextAndTooltip(company.companyName, 20);

                  return (
                    <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                      <x.div display="flex" gap={inner.base02} whiteSpace="nowrap">
                        <PrimerCompanyLogo size={inner.base05} src={company.logoLink} padding="0" />
                        <Typography variant="body-em" color={text.strong._}>
                          <CitationTextWrapper value={companyName} />
                        </Typography>
                      </x.div>
                    </ConditionalTooltip>
                  );
                })}
              </x.div>
              <x.div display="flex" gap={inner.base04} flexDirection="column">
                <CitationTextWrapper value={adj.commentary.content} />

                {adj.markets.map((market, marketIndex) => {
                  const marketName = {
                    ...market.name,
                    value:
                      market.name.value && market.name.value.length > 20
                        ? `${market.name.value.substring(0, 20)}...`
                        : market.name.value,
                  };

                  return (
                    <TrendPill
                      key={marketIndex}
                      trend={market.trend.value as CompetitorGrowth}
                      label=""
                      tooltip={market.name.value && market.name.value.length > 20 ? market.name.value : null}
                    >
                      <CitationTextWrapper
                        value={marketName}
                        style={{ fontSize: font.size["03"], lineHeight: font.lineHeight["02"] }}
                      />
                    </TrendPill>
                  );
                })}
              </x.div>
            </x.div>
            {index < adjacentCompetitors.length - 1 && <Divider marginTop={inner.base03} />}
          </>
        </SensitiveDataContainer>
      ))}
    </x.div>
  );
};

export default AdjacentCompetitors;
