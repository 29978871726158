import { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { CardCompetitorWrapper, ListOptionWrapper } from "./styled";
import { PrimerCompanyLogo, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { CoreCompetitor } from "../../../types/competitors";
import CoreCompetitorDetails from "./CoreCompetitorDetails";
import { Target } from "@alphasights/alphadesign-icons";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";

const CoreCompetitors = ({ coreCompetitors }: { coreCompetitors: CoreCompetitor[] }) => {
  const {
    spacing: { inner },
    color: { icon, text },
    font,
  } = useThemeTokens();

  const { companies, isAccessible } = usePrimersStore();

  const [selectedCompetitor, setSelectedCompetitor] = useState<CoreCompetitor>(coreCompetitors[0]);

  // when mentions are applied we need to refresh the selected competitor
  useEffect(() => {
    setSelectedCompetitor(coreCompetitors[0]);
  }, [coreCompetitors]);

  return (
    <x.div px={inner.base10}>
      <PrimerFieldTitle text="Core Competitors" xtraProps={{ pb: inner.base05 }} />
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <x.div display="flex" gap={inner.base04}>
          <x.div display="flex" flexDirection="column" gap={inner.base02}>
            {coreCompetitors.map((competitor, index) => {
              const mainCompany =
                companies?.find((company: Company) => company.cdsAlphaCompanyId === competitor.cdsAlphaCompanyId)
                  ?.companyType === "ANCHOR";

              const [companyName, tooltip] = getTruncatedTextAndTooltip(competitor.companyName, 10);

              return (
                <ListOptionWrapper
                  key={index}
                  onClick={() => setSelectedCompetitor(competitor)}
                  selected={selectedCompetitor === competitor}
                >
                  <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                    <x.div display="flex" alignItems="start" gap={inner.base02}>
                      <PrimerCompanyLogo size={inner.base06} src={competitor.logoLink} padding="0" />
                      <CitationTextWrapper
                        value={companyName}
                        style={{
                          fontSize: font.size["04"],
                          lineHeight: font.lineHeight["03"],
                          fontWeight: "600",
                          color: text.strong,
                          whiteSpace: "nowrap",
                        }}
                      />
                    </x.div>
                  </ConditionalTooltip>
                  {mainCompany && (
                    <Icon color={icon.disabled}>
                      <Target />
                    </Icon>
                  )}
                </ListOptionWrapper>
              );
            })}
          </x.div>
          <CardCompetitorWrapper>
            {selectedCompetitor && <CoreCompetitorDetails competitor={selectedCompetitor} />}
          </CardCompetitorWrapper>
        </x.div>
      </SensitiveDataContainer>
    </x.div>
  );
};

export default CoreCompetitors;
