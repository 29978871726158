import { FC } from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

enum GroupingType {
  static = "STATIC",
}

interface CompetitorDynamicsTableHeaderItemProps {
  label: GroupingType | string;
  col?: number;
  minWidth?: number | string;
  maxWidth?: number | string;
}

const CompetitorDynamicsTableHeaderItem: FC<CompetitorDynamicsTableHeaderItemProps> = ({
  label,
  col,
  minWidth = "unset",
  maxWidth = "unset",
}) => {
  const { color } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <x.div alignItems="center" display="flex" justifyContent="flex-start" {...{ col, minWidth, maxWidth }}>
      <Typography component="div" color={color.text.secondary} variant={isMobile ? "body-em" : "body-small-em"}>
        {label}
      </Typography>
    </x.div>
  );
};

export { CompetitorDynamicsTableHeaderItem as default, GroupingType };
