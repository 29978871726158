import { ForwardedRef, useEffect, useRef, useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Attachment } from "types";
import { IconButton, Portal, Typography } from "@alphasights/alphadesign-components";
import { AttachmentDisplay, Attachments, Counter } from "components/TextBox";
import { calculateRows } from "components/CommentThread";
import { Label } from "components/Label";
import { useMobileTextBoxStyles } from "./MobileTextBox.styles";
import { Send } from "@alphasights/alphadesign-icons";

export type MobileTextBoxProps = {
  name: string;
  placeholder: string;
  disabled?: boolean;
  withAttachments?: boolean;
  error?: string;
  defaultValue?: string;
  maxLength?: number;
  attachments?: Attachment[];
  fileSizeLimit?: number;
  fileLimit?: number;
  resizable: boolean;
  onChange: (value: string | undefined) => void;
  onAttachmentChange?: (value: Attachment[]) => void;
  onSendClick?: () => void;
  sendMessageButtonRef?: React.MutableRefObject<any>;
  checkMessageError?: (value: boolean) => boolean;
  disableSend?: boolean;
  attachToNavbar?: boolean;
  onHeightChange?: (newHeight: number) => void;
};

export const MobileTextBox = React.forwardRef(
  (
    {
      name,
      placeholder = "Placeholder",
      disabled = false,
      withAttachments = false,
      error,
      defaultValue,
      maxLength,
      attachments = [],
      fileSizeLimit = 10,
      fileLimit = 5,
      resizable = true,
      onChange = (value: string | undefined) => {},
      onAttachmentChange = (value: Attachment[]) => {},
      onSendClick,
      sendMessageButtonRef,
      checkMessageError,
      disableSend,
      attachToNavbar: attachToNavbarInput = false,
      onHeightChange,
    }: MobileTextBoxProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [length, setLength] = useState(defaultValue ? defaultValue.length : 0);
    const [attachmentsError, setAttachmentsError] = useState<string | undefined>(undefined);
    const mobileTextBoxRef = useRef<HTMLDivElement>(null);

    const navbarCompanion = document.getElementById("navbar-companion") ?? undefined;
    const attachToNavbar = attachToNavbarInput && !!navbarCompanion;

    const styles = useMobileTextBoxStyles({
      error: !!error || !!(maxLength && length > maxLength),
      disabled,
      resizable,
      attachToNavbar,
    });

    useEffect(() => {
      onHeightChange?.(mobileTextBoxRef.current?.clientHeight ?? 0);
    }, [onHeightChange]);

    const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      event.target.rows = 1;
      const [newRows] = calculateRows(event, 4);
      event.target.rows = newRows;

      const value = event.target.value === "" ? undefined : event.target.value;
      setLength(value ? value.length : 0);
      onChange(value);
      onHeightChange?.(mobileTextBoxRef.current?.clientHeight ?? 0);
    };

    useEffect(() => {
      if (checkMessageError && !!(maxLength && length > maxLength)) {
        checkMessageError(true);
      }
    }, [checkMessageError, length, maxLength]);

    const Container = attachToNavbar ? Portal : React.Fragment;
    return (
      <Container {...(attachToNavbar && { containerEl: navbarCompanion })}>
        <x.div {...styles.wrapper} ref={mobileTextBoxRef}>
          <x.div {...styles.textBoxAndCTAsWrapper}>
            {withAttachments && (
              <Attachments
                disabled={disabled}
                withAttachments={withAttachments}
                attachments={attachments}
                fileSizeLimit={fileSizeLimit}
                fileLimit={fileLimit}
                onAttachmentChange={onAttachmentChange}
                mobileVariant={true}
                setExternalError={setAttachmentsError}
              />
            )}
            <x.div flexGrow={1}>
              <x.div {...styles.textAreaWrapper}>
                <x.textarea
                  data-testid="mobile-text-box-textarea"
                  ref={ref}
                  name={name}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={changeHandler}
                  rows={1}
                  {...styles.textAreaStyle}
                />
                {!disabled && maxLength && (
                  <x.div {...styles.counterDivStyle}>
                    <Counter length={length} maxLength={maxLength} />
                  </x.div>
                )}
              </x.div>
            </x.div>
            <IconButton
              size="small"
              variant="outline"
              key="rounded-button"
              ref={sendMessageButtonRef}
              testId="send-btn"
              disabled={disableSend || !!attachmentsError}
              {...(onSendClick ? { onClick: onSendClick } : {})}
            >
              <Send />
            </IconButton>
          </x.div>
          {error && (
            <Typography data-testid="text-box-error-message" variant="body-small" color="danger">
              {error}
            </Typography>
          )}
          {attachmentsError && (
            <Typography data-testid="text-box-error-message" variant="body-small" color="danger">
              {attachmentsError}
            </Typography>
          )}
        </x.div>
      </Container>
    );
  }
);

export const MobileAttachmentsList = ({
  attachments,
  onAttachmentChange,
}: {
  attachments: any[];
  onAttachmentChange: (value: Attachment[]) => void;
}) => {
  const onClickRemoveAttach = (attachment: Attachment) => {
    const newAttachments = attachments.filter((file) => file !== attachment);
    onAttachmentChange(newAttachments);
  };

  return (
    <>
      {attachments.length > 0 && <Label text="Attachments" />}
      {attachments.map((attachment, index) => (
        <x.div key={`${attachment.name}-${index}`}>
          <AttachmentDisplay attachment={attachment} disabled={false} onClickRemove={onClickRemoveAttach} />
        </x.div>
      ))}
    </>
  );
};
