import { x } from "@xstyled/styled-components";
import { useThemeTokens, Tabs, TabItem } from "@alphasights/alphadesign-components";
import { DotLabel } from "pages/AlphaNowPage/primers/components";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";
import { AggregatedResult } from "views/SurveysResultsView/api/types";

type ThreeDimensionalKPCTabsProps = {
  chartColors: string[];
  onClick: (index: number) => void;
  companies: AggregatedResult[];
  selectedTab: number;
  setSelectedTab: (index: number) => void;
};

const ThreeDimensionalKPCTabs = ({
  chartColors,
  onClick,
  companies,
  selectedTab,
  setSelectedTab,
}: ThreeDimensionalKPCTabsProps) => {
  const { spacing, color } = useThemeTokens();

  const handleTabChange = (index: string | number | undefined) => {
    if (typeof index === "number") {
      setSelectedTab(index);
      onClick(index);
    }
  };

  return (
    <x.div pt={spacing.inner.base04}>
      <Tabs data-testid="surveysResultsKPCsTabs" colorVariant="primary" index={selectedTab} onChange={handleTabChange}>
        {companies.map(({ answerText, answerId }, i) => {
          const [companyNameTruncated, tooltip] = getTruncatedTextAndTooltip(answerText, 30);

          return (
            <TabItem
              key={`${answerText}-${answerId}`}
              label={
                <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                  <DotLabel
                    dotColor={chartColors[i]}
                    label={companyNameTruncated as string}
                    tableEntry
                    labelColor={i === selectedTab ? color.text.strong._ : color.text.secondary}
                  />
                </ConditionalTooltip>
              }
            />
          );
        })}
      </Tabs>
    </x.div>
  );
};

export default ThreeDimensionalKPCTabs;
