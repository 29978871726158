import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const QuestionContainer = styled.div(() => {
  const { spacing, shape, color } = useThemeTokens();
  return {
    width: "636px",
    margin: "auto",
    padding: `${spacing.inner.base10} 0`,
    display: "flex",
    flexDirection: "column" as "column",
    borderBottom: `${shape.border.width.sm} solid ${color.border.divider}`,
  };
});
