import React, { useEffect, useRef, useState } from "react";
import { IconButton, Popover } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Close, Search } from "@alphasights/alphadesign-icons";
import { View } from "models/Header";
import { StyledCloseSearchButton, TranscriptSearchPopoverContainer } from "./TranscriptSearch.styled";
import { TranscriptSearchProps } from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/types";
import TranscriptSearchBar from "./TranscriptSearchBar";

const DataTestIds = {
  transcriptSearchButton: "searchButton",
  closeSearchButton: "closeSearchButton",
};

const TranscriptSearch = ({
  searchQuery,
  setSearchQuery,
  viewMode,
  isTranscriptDataReady,
  keywordSearchProps,
  contentTypePermission,
}: TranscriptSearchProps) => {
  const searchButtonRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState<Element>();
  const { isUltraWide, isMobile } = useCheckScreen();
  const hasKeywords = searchQuery.length > 0;
  const displayPopOverSearchBar = (hasKeywords && viewMode !== View.Question) || viewMode === View.Mentions;

  useEffect(() => {
    if (searchButtonRef.current && displayPopOverSearchBar) {
      setAnchorEl(searchButtonRef.current);
    } else {
      setAnchorEl(undefined);
    }
  }, [searchButtonRef, displayPopOverSearchBar, viewMode]);

  const handleOpenPopOver = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const searchBar = (
    <TranscriptSearchBar
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      viewMode={viewMode}
      isTranscriptDataReady={isTranscriptDataReady}
      keywordSearchProps={keywordSearchProps}
      contentTypePermission={contentTypePermission}
    />
  );

  if (isUltraWide || isMobile) return searchBar;

  return (
    <>
      <IconButton
        ref={searchButtonRef}
        size="small"
        onClick={handleOpenPopOver}
        variant="outline"
        dataAttributes={{
          "data-testid": DataTestIds.transcriptSearchButton,
          "data-id": DataTestIds.transcriptSearchButton,
        }}
      >
        <Search />
      </IconButton>
      <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} size="medium">
        <TranscriptSearchPopoverContainer>
          {searchBar}
          <StyledCloseSearchButton
            onClick={() => setAnchorEl(undefined)}
            dataAttributes={{
              "data-testid": DataTestIds.closeSearchButton,
            }}
          >
            <Close />
          </StyledCloseSearchButton>
        </TranscriptSearchPopoverContainer>
      </Popover>
    </>
  );
};

export { TranscriptSearch as default, DataTestIds };
