import * as React from "react";
import styles from "./PieChart.module.css";
import { useThemeTokens } from "@alphasights/alphadesign-components";

interface RevenuePieChartProps {
  value: number;
}

const RevenuePieChart = ({ value }: RevenuePieChartProps) => {
  const { color } = useThemeTokens();

  const pieStyles: React.CSSProperties = {
    background: `conic-gradient(from 0deg, ${color.chart.categorical.base09} 0% ${value}%, ${color.background.disabled} ${value}% 100%)`,
  };

  return <div className={styles["pie-chart"]} style={pieStyles}></div>;
};

export default RevenuePieChart;
