import "../index.css";
import { useEnv } from "@alphasights/client-portal-shared";
import { Button, Link, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useLoginStyles } from "../index.styles";

export const SignInFooter = ({
  showLimitationsOfUse = false,
  showSignUp = true,
  showSignIn = false,
  showExpertAccess = false,
  onClickLOU,
  onClickHelp,
  onClickSignUp,
  onClickSignIn,
}) => {
  const { signInFooterDiv, helpButton, textStyle, limitationsOfUseButton } = useLoginStyles();

  const env = useEnv();

  return (
    <x.div {...signInFooterDiv}>
      <Button variant="ghost" onClick={onClickHelp} {...helpButton}>
        <Typography variant="body-small-em" {...textStyle}>
          Help
        </Typography>
      </Button>
      {showLimitationsOfUse && !env?.enableCookiePolicy && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | "}
          </Typography>
          <Button variant="ghost" data-testid="lou-button" onClick={onClickLOU} {...limitationsOfUseButton}>
            <Typography variant="body-small-em" {...textStyle}>
              Limitations of Use
            </Typography>
          </Button>
        </>
      )}
      {showSignUp && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | No account? "}
          </Typography>
          <Link onClick={onClickSignUp} cursor="pointer" size="small">
            <Typography variant="body-small-em">Sign Up</Typography>
          </Link>
        </>
      )}
      {showSignIn && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | Have an account? "}
          </Typography>
          <Link onClick={onClickSignIn} cursor="pointer" size="small">
            <Typography variant="body-small-em">Sign in</Typography>
          </Link>
        </>
      )}
      {showExpertAccess && (
        <>
          <Typography variant="body-small" component="span" {...textStyle}>
            {" | "}
          </Typography>
          <x.div display="inline-block">
            <Typography variant="body-small" component="span" {...textStyle}>
              Are you an expert?{" "}
            </Typography>
            <Link
              href="https://www.alphasights.com/experts/#how-can-i-sign-in-to-your-expert-platform"
              cursor="pointer"
              size="small"
            >
              <Typography variant="body-small-em">Request Access</Typography>
            </Link>
          </x.div>
        </>
      )}
    </x.div>
  );
};
