import { Divider, Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

const SeparatorSection = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: ${spacing.inner.base08};
  `;
});

const StyledDivider = styled(Divider)`
  flex-grow: 1;
`;

const TableWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base03};
  `;
});

const TitleWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base02};
    align-items: center;
    margin-left: ${spacing.inner.base06};
  `;
});

const StyledIcon = styled(Icon).attrs({ size: "small" })(() => {
  const { color } = useThemeTokens();
  return `
    color: ${color.icon.secondary};
    &:hover {
      cursor: pointer;
    }
  `;
});

export { SeparatorSection, StyledDivider, TableWrapper, TitleWrapper, StyledIcon };
