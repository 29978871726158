import { ContentTypeToAccessLevel } from "./useContentAccessLevel";
import { InsightType } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/consts";

export const useInsightsData = (contentTypeMapping: ContentTypeToAccessLevel[], showInsights: boolean) => {
  if (!showInsights || !contentTypeMapping) {
    return { insightsTypes: [] };
  }

  const insightTypesArray = Object.values(InsightType) as string[];

  const insightsTypes = contentTypeMapping
    .filter(({ contentType }) => insightTypesArray.includes(contentType as any))
    .map(({ contentType }) => contentType);

  return { insightsTypes };
};

export default useInsightsData;
