import { Typography } from "@alphasights/alphadesign-components";
import { StatusError } from "@alphasights/alphadesign-icons";
import { ErrorAlertContainer, StyledIcon } from "./ErrorAlert.styled";

const ErrorAlert = ({ text }: { text: string }) => {
  return (
    <ErrorAlertContainer>
      <StyledIcon>
        <StatusError />
      </StyledIcon>
      <Typography variant="body-small">{text}</Typography>
    </ErrorAlertContainer>
  );
};

export { ErrorAlert as default };
