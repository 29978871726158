import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Calendar as ReactCalendar, dateFnsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import * as allLocales from "date-fns/locale";
import { useTimezone } from "../../providers/TimezoneProvider";

const DnDCalendar = withDragAndDrop(ReactCalendar);

export const locales = Object.entries(allLocales).map(([key, locale]) => ({
  [locale.code]: locale,
}));

export const localizer = (tzCtx) =>
  dateFnsLocalizer({
    format(date, fmt, options) {
      return format(tzCtx.parseDateZoned(date), fmt, options);
    },
    startOfWeek: (date, options) =>
      startOfWeek(date, {
        ...options,
        weekStartsOn: 1,
      }),
    getDay,
    locales,
  });

export const Calendar = ({ events = [], ...props }) => {
  const tz = useTimezone();

  return <DnDCalendar localizer={localizer(tz)} events={events} {...props} />;
};
