import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { ChangeEvent, useCallback, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./NPSModuleContent.styles";
import { nps } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { Icon, Skeleton, Tile, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { NPSCategory, NPSCompany, NPSQuote, SynthesisQuote } from "@alphasights/portal-api-client";
import { Company as CompanyIcon, ArrowDown, ArrowUp, Remove } from "@alphasights/alphadesign-icons";
import {
  DeleteItemWithConfirmationBtn,
  EditableInput,
  EditStructuredModuleItemCTAs,
  ExpertQuote,
  MoreActions,
  RegenerationFailedToast,
  RevisionToggler,
} from "../components";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import _, { noop } from "lodash";
import { NPSChart } from "./NPSChart";
import { ScheduleCustomersBanner } from "../components/ScheduleCustomersBanner";
import { useEditItem } from "views/ProjectSynthesisView/hooks/useEditItem";
import { useEditNpsOperations } from "views/ProjectSynthesisView/hooks/useEditNpsOperations";
import { ThirdPartyInteraction } from "types";

export const NPSModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <Render />;
};

const Render = () => {
  const [editItemsCount, setEditItemsCount] = useState(0);
  const styles = useStyles();
  const { spacing } = useThemeTokens();
  const { revision, selectedModule, thirdPartyDocuments } = useProjectSynthesisContext();

  const content = nps(revision?.contents);

  if (revision?.status === "PROCESSING") {
    return <ProcessingModuleContent />;
  }

  if (revision?.status === "FAILED") {
    return <RegenerationFailedToast module={selectedModule!} />;
  }

  return (
    <x.div mt={spacing.inner.base06}>
      <ScheduleCustomersBanner />
      <x.div {...styles.container}>
        {editItemsCount === 0 && (
          <RevisionToggler display="flex" justifyContent="flex-end" position="absolute" right="32px" zIndex="1" />
        )}
        <NPSChart companies={content.companies} thirdPartyDocuments={thirdPartyDocuments} />
        {content.companies.map((c) => (
          <CompanyNPSRender
            key={c.id}
            companyItem={c}
            onEnterEditMode={() => setEditItemsCount((prev) => prev + 1)}
            onLeaveEditMode={() => setEditItemsCount((prev) => Math.max(0, prev - 1))}
          />
        ))}
      </x.div>
    </x.div>
  );
};

const CompanyNPSRender = ({
  companyItem,
  onEnterEditMode,
  onLeaveEditMode,
}: {
  companyItem: NPSCompany;
  onEnterEditMode?: () => void;
  onLeaveEditMode?: () => void;
}) => {
  const styles = useStyles();
  const {
    saveModuleChanges,
    selectedRevisionIdx,
    saveInProgress,
    selectedModule,
    thirdPartyDocuments,
  } = useProjectSynthesisContext();
  const { updateNpsItem } = useEditNpsOperations({ originalModule: selectedModule });

  const onSaveChanges = useCallback(
    (companyToSave: NPSCompany) => {
      const updatedModule = updateNpsItem(companyToSave, selectedRevisionIdx);
      return updatedModule
        ? saveModuleChanges(updatedModule, { editedItem: `${companyToSave.companyName}` })
        : Promise.resolve();
    },
    [saveModuleChanges, selectedRevisionIdx, updateNpsItem]
  );

  const { mode, actualItem: company, enterEditMode, onEditItem, onSaveEdit, onCancelEdit } = useEditItem({
    item: companyItem,
    onSaveChanges,
    onLeaveEditMode,
    onEnterEditMode,
  });

  return (
    <x.div {...styles.card} data-testid={`company-${company.companyName}`}>
      <x.div {...styles.companyRow}>
        <CompanyName company={company} mode={mode} enterEditMode={enterEditMode} />
      </x.div>
      <CompanySummary company={company} mode={mode} onEditItem={onEditItem} />

      {_.sortBy(
        company.transcriptAnalyses.flatMap((ta) => ta.quotes),
        (q) => [NPSCategory.PROMOTER, NPSCategory.PASSIVE, NPSCategory.DETRACTOR].indexOf(q.category)
      ).map((q) => {
        return (
          <CompanyNPSQuote
            key={q.id}
            company={company}
            quote={q}
            mode={mode}
            onEditItem={onEditItem}
            thirdPartyDocuments={thirdPartyDocuments}
          />
        );
      })}
      {mode === Mode.EDIT && (
        <EditStructuredModuleItemCTAs
          onCancelEdit={onCancelEdit}
          onSaveEdit={onSaveEdit}
          saveDisabled={saveInProgress}
        />
      )}
    </x.div>
  );
};

const CompanyNPSQuote = ({
  company,
  quote,
  mode,
  onEditItem,
  thirdPartyDocuments,
}: {
  company: NPSCompany;
  quote: NPSQuote;
  mode: Mode;
  onEditItem: (updatedItem: NPSCompany) => void;
  thirdPartyDocuments: ThirdPartyInteraction[];
}) => {
  const styles = useStyles();

  const onEditQuote = useCallback(
    (updatedQuote: SynthesisQuote) => {
      onEditItem({
        ...company,
        transcriptAnalyses: [
          ...company.transcriptAnalyses.map((ta) => ({
            ...ta,
            quotes: ta.quotes.map((q) => (q.id === updatedQuote.id ? { ...updatedQuote, category: q.category } : q)),
          })),
        ],
      });
    },
    [company, onEditItem]
  );

  const onDeleteQuote = useCallback(() => {
    onEditItem({
      ...company,
      transcriptAnalyses: company.transcriptAnalyses.map((ta) => ({
        ...ta,
        quotes: ta.quotes.filter((q) => q.id !== quote.id),
      })),
    });
  }, [company, onEditItem, quote.id]);

  return (
    <x.div {...styles.quoteRow}>
      <NPSCategoryIcon category={quote.category} />
      <x.div display="flex" w="100%" flexDirection="column">
        <ExpertQuote
          quote={quote}
          mode={mode}
          onEditQuote={onEditQuote}
          onDeleteQuote={onDeleteQuote}
          onNavigateToTranscript={noop}
          saveInProgress={false}
          showCompanyInfo
          thirdPartyDocuments={thirdPartyDocuments}
        />
      </x.div>
    </x.div>
  );
};

const CompanyName = ({
  company,
  mode,
  enterEditMode,
}: {
  company: NPSCompany;
  mode: Mode;
  enterEditMode: () => void;
}) => {
  const styles = useStyles();
  const { saveModuleChanges, selectedModule, selectedRevisionIdx, saveInProgress } = useProjectSynthesisContext();
  const { deleteNpsItem } = useEditNpsOperations({ originalModule: selectedModule });

  const tile = company.companyLogo ? (
    <Tile variant="image" size="x-small" image={company.companyLogo} />
  ) : (
    <Tile variant="icon" size="x-small" icon={<CompanyIcon data-testid="no-logo" />} />
  );

  const onDeleteCompany = useCallback(() => {
    const updatedModule = deleteNpsItem(company, selectedRevisionIdx);
    updatedModule &&
      saveModuleChanges(
        updatedModule,
        { deletedItem: `${company.companyName}` },
        true,
        `${company.companyName} deleted.`
      );
  }, [company, deleteNpsItem, saveModuleChanges, selectedRevisionIdx]);

  return (
    <>
      {tile}
      <Typography variant="body-em" data-testid="company-name">
        {company.companyName}
      </Typography>
      <x.div {...styles.alignRight}>
        {mode === Mode.EDIT && (
          <DeleteItemWithConfirmationBtn
            onDeleteItem={onDeleteCompany}
            saveInProgress={saveInProgress}
            tooltipText="Delete NPS"
            testId={`delete-company-${company.companyName}`}
          />
        )}
        {mode === Mode.VIEW && <MoreActions enterEditMode={enterEditMode} onDelete={onDeleteCompany} />}
      </x.div>
    </>
  );
};

const CompanySummary = ({
  company,
  mode,
  onEditItem,
}: {
  company: NPSCompany;
  mode: Mode;
  onEditItem: (updatedItem: NPSCompany) => void;
}) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onEditItem({ ...company, summary: e.target.value });
    },
    [company, onEditItem]
  );

  if (mode === Mode.VIEW) {
    return (
      <Typography variant="body" data-testid="company-summary">
        {company.summary}
      </Typography>
    );
  }

  return <EditableInput input={company.summary} onChange={onChange} />;
};

const NPSCategoryIcon = ({ category }: { category: NPSCategory }) => {
  const { color, icon, text } = {
    PROMOTER: {
      text: "Promoter",
      color: "success",
      icon: <ArrowUp />,
    },
    PASSIVE: {
      text: "Passive",
      color: "secondary",
      icon: <Remove />,
    },
    DETRACTOR: {
      text: "Detractor",
      color: "danger",
      icon: <ArrowDown />,
    },
  }[category];

  return (
    <Tooltip title={text} position="bottom">
      <Icon color={color} dataAttributes={{ "data-testid": `category-icon-${category}` }}>
        {icon}
      </Icon>
    </Tooltip>
  );
};

const ProcessingModuleContent = () => {
  return (
    <>
      <x.div display="flex" flexDirection="column" gap="16px" p="24px" data-testid="processing-module">
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </>
  );
};
