import { FC, useState } from "react";
import { Divider } from "@alphasights/alphadesign-components";

import ThirdPartyDocumentsProvider from "providers/ThirdPartyDocumentsProvider";
import { ThirdPartyInteraction } from "types";
import DocumentSummaryTab from "./DocumentSummaryTab";
import DocumentQuestionTab from "./DocumentQuestionsTab";
import PageHeader from "./PageHeader";
import DocumentViewer from "./DocumentViewer";
import { ViewType } from "./consts";

import * as S from "./ThirdPartyDocumentPage.styled";

type ThirdPartyDocumentPageProps = {
  interaction: ThirdPartyInteraction;
  refetchDocuments: () => void;
  searchQuery: string[];
};

const DataTestIds = {
  tabContent: "tab-content-wrapper",
};

const ThirdPartyDocumentPage: FC<ThirdPartyDocumentPageProps> = ({ interaction, refetchDocuments, searchQuery }) => {
  const [selectedView, setSelectedView] = useState(ViewType.Transcript);
  const [searchTargetEl, setSearchTargetEl] = useState<HTMLDivElement | null>(null);

  return (
    <ThirdPartyDocumentsProvider>
      <S.Wrapper>
        <PageHeader
          interaction={interaction}
          searchTargetEl={searchTargetEl}
          selectedView={selectedView}
          onChangeView={setSelectedView}
          refetchDocuments={refetchDocuments}
          searchQuery={searchQuery}
        />
        <Divider />
        <S.MainContentContainer data-testid={DataTestIds.tabContent}>
          {selectedView === ViewType.Summary && (
            <S.TabContainer data-summary-scrollable-area>
              <DocumentSummaryTab ref={setSearchTargetEl} interaction={interaction} />
            </S.TabContainer>
          )}
          {selectedView === ViewType.Questions && (
            <S.TabContainer data-questions-scrollable-area>
              <DocumentQuestionTab ref={setSearchTargetEl} interaction={interaction} />
            </S.TabContainer>
          )}
          {selectedView === ViewType.Transcript && (
            <S.TabContainer data-transcript-scrollable-area>
              <DocumentViewer
                key={interaction.id}
                ref={setSearchTargetEl}
                interaction={interaction}
                refetchDocuments={refetchDocuments}
              />
            </S.TabContainer>
          )}
        </S.MainContentContainer>
      </S.Wrapper>
    </ThirdPartyDocumentsProvider>
  );
};

export default ThirdPartyDocumentPage;
