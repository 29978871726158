import { Divider, Pill, PillVariants, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { OutlookProperties } from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/types/outlook";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import { CompetitorGrowth } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";

const OutlookContent = ({
  content,
  headerItems,
  isGrowthDrivers,
}: {
  content: OutlookProperties[];
  headerItems: string[];
  isGrowthDrivers: boolean;
}) => {
  const {
    color: { text },
    spacing: { inner },
    typography,
  } = useThemeTokens();
  const { isAccessible } = usePrimersStore();

  const impactOrder: {
    [key: string]: {
      order: number;
      value: CompetitorGrowth.Increasing | CompetitorGrowth.Decreasing;
      color: string;
      variant: PillVariants;
    };
  } = {
    HIGHLY_POSITIVE: {
      order: 4,
      value: CompetitorGrowth.Increasing,
      color: text.success,
      variant: "green",
    },
    POSITIVE: {
      order: 3,
      value: CompetitorGrowth.Increasing,
      color: text.success,
      variant: "green",
    },
    NEGATIVE: {
      order: 2,
      value: CompetitorGrowth.Decreasing,
      color: text.danger,
      variant: "red",
    },
    HIGHLY_NEGATIVE: {
      order: 1,
      value: CompetitorGrowth.Decreasing,
      color: text.danger,
      variant: "red",
    },
  };

  const sortByImpact = (a: OutlookProperties, b: OutlookProperties) => {
    const aImpact = a.impact?.value || "";
    const bImpact = b.impact?.value || "";
    return impactOrder[bImpact]?.order - impactOrder[aImpact]?.order;
  };

  const sortedContent = [...content].sort(sortByImpact);

  const getImpactText = (impact: string): string => {
    switch (impact) {
      case "HIGHLY_POSITIVE":
        return isGrowthDrivers ? "Very positive" : "Significant opportunity";
      case "POSITIVE":
        return isGrowthDrivers ? "Positive" : "Opportunity";
      case "NEGATIVE":
        return isGrowthDrivers ? "Negative" : "Risk";
      case "HIGHLY_NEGATIVE":
        return isGrowthDrivers ? "Very negative" : "Significant risk";
    }

    return "";
  };

  return (
    <>
      <x.div display="grid" columnGap="10px" w="100%" gridTemplateColumns="205px auto" color={text.assistive}>
        {headerItems.map((item) => {
          return (
            <Typography key={item} variant="navigation">
              {item}
            </Typography>
          );
        })}
      </x.div>
      <Divider />
      {sortedContent.map((item, index) => {
        const impact = item.impact.value || "";

        return (
          <SensitiveDataContainer isSensitive={!isAccessible}>
            <x.div
              alignItems="start"
              display="grid"
              columnGap={inner.base04}
              gridTemplateColumns="205px auto"
              w="100%"
              key={index}
            >
              <x.div
                color={text.strong._}
                {...typography.body.em}
                display="flex"
                flexDirection="column"
                rowGap={inner.base04}
              >
                <CitationTextWrapper value={item.driver} />
                <div>
                  <Pill size="small" variant={impactOrder[impact]?.variant as PillVariants}>
                    <x.span
                      display="flex"
                      alignItems="center"
                      color={impactOrder[impact]?.color}
                      backgroundColor="inherit"
                    >
                      {getImpactText(impact)}
                    </x.span>
                  </Pill>
                </div>
              </x.div>
              <x.div color={text.strong._} {...typography.body.regular}>
                {<CitationTextWrapper value={item.commentary.content} />}
              </x.div>
            </x.div>
          </SensitiveDataContainer>
        );
      })}
    </>
  );
};

export default OutlookContent;
