import { FC } from "react";
import { IconButton, Tooltip } from "@alphasights/alphadesign-components";
import { Edit } from "@alphasights/alphadesign-icons";

import { Cell } from "views/DeliverablesView/ThirdPartyDocUploadModal/FileTable/types";
import { isSuccessStatus } from "views/DeliverablesView/ThirdPartyDocUploadModal/utils";
import { TOOLTIP_TEXT } from "./constants";

const DataTestIds = {
  EDIT_BUTTON: "edit-button",
};

type EditCellProps = Cell & {
  onClickEdit: (id: number) => void;
};

const EditCell: FC<EditCellProps> = ({ row: { original: selectedFile, index }, onClickEdit }) => {
  const { id, status } = selectedFile;

  const handleClick = () => onClickEdit(id);

  if (!isSuccessStatus(status)) {
    return <></>;
  }

  return (
    <Tooltip variant="dark" size="small" position="bottom" title={TOOLTIP_TEXT}>
      <IconButton
        variant="outline"
        size="small"
        onClick={handleClick}
        dataAttributes={{ "data-testid": `${DataTestIds.EDIT_BUTTON}-${index}` }}
      >
        <Edit />
      </IconButton>
    </Tooltip>
  );
};

export { EditCell as default, DataTestIds };
export type { EditCellProps };
