import styled from "styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ContentCard } from "@alphasights/client-portal-shared";

const ContentCardCarouselContainer = styled.div(() => {
  const { spacing, color, shape } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    padding: spacing.inner.base08,
    backgroundColor: color.background.surface.page.default,
    border: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
  };
});

const TitleContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    gap: spacing.inner.base02,
  };
});

const StyledContentCard = styled(ContentCard)(
  ({ isBookmarked, showPurchasedFlag }: { isBookmarked: boolean; showPurchasedFlag: boolean }) => {
    const { spacing } = useThemeTokens();

    const subtitleWidth =
      isBookmarked && showPurchasedFlag ? "86%" : isBookmarked || showPurchasedFlag ? "92%" : "100%";

    const truncateTextStyle = {
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical" as "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxHeight: spacing.inner.base05,
    };
    return {
      height: "100%",
      "#content-card-title": {
        ...truncateTextStyle,
      },
      "#card-subtitle-container": {
        ...truncateTextStyle,
        width: subtitleWidth,
      },
      "#content-card-subtitle": {
        flexWrap: "unset" as "unset",
      },
    };
  }
);

export { ContentCardCarouselContainer, TitleContainer, StyledContentCard };
