import {
  SynthesisKPC,
  SynthesisQuestion,
  SynthesisVendors,
  SynthesisNPS,
  SynthesisVendorSwitching,
} from "@alphasights/portal-api-client";

export function isKpcContent(content: any): content is SynthesisKPC {
  return content !== undefined && content.__type__ === "ProjectSynthesisKPC";
}
export function isQuestionContent(content: any): content is SynthesisQuestion {
  return content !== undefined && content.__type__ === "ProjectSynthesisQuestion";
}
export function isVendorContent(content: any): content is SynthesisVendors {
  return content !== undefined && content.__type__ === "ProjectSynthesisVendors";
}
export function isNPSContent(content: any): content is SynthesisNPS {
  return content !== undefined && content.__type__ === "ProjectSynthesisNPS";
}

export function isVendorSwitchingContent(content: any): content is SynthesisVendorSwitching {
  return content !== undefined && content.__type__ === "ProjectSynthesisVendorSwitching";
}

export function kpc(contents: any) {
  return contents as SynthesisKPC;
}

export function question(contents: any) {
  return contents as SynthesisQuestion;
}

export function vendors(contents: any) {
  return contents as SynthesisVendors;
}

export function nps(contents: any) {
  return contents as SynthesisNPS;
}

export function vendorSwitching(content: any) {
  return content as SynthesisVendorSwitching;
}
