import { useCallback, useMemo, useRef } from "react";
import { useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  Icon,
  Button,
  IconButton,
  Typography,
  Tooltip,
  List,
  useThemeTokens,
  ListOption,
  InputKeyWords,
  RoundedButton,
  Popover,
  Loading,
} from "@alphasights/alphadesign-components";
import {
  Info,
  Refresh,
  Close,
  Search,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  ChevronDown,
  Delete,
  MoreVert,
  Download,
} from "@alphasights/alphadesign-icons";
import { useStyles } from "./ProjectSummaryHeader.styles";
import {
  ProjectSummaryAnglePill,
  ProjectSummaryContent,
  ProjectSummaryStatus,
  ProjectSummaryStatusResponse,
} from "../ProjectSummaries.types";
import { FormattedDateRelative } from "providers/TimezoneProvider";
import { isNumber } from "lodash";
import { useEnv } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useIsOverflow } from "@alphasights/client-portal-shared";
import { useProjectSummariesContext } from "providers/ProjectSummariesProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ExpandSidebarButton } from "views/DeliverablesView/ExpandSidebarButton/ExpandSidebarButton";

export interface ProjectSummaryHeaderProps {
  angles: ProjectSummaryAnglePill[];
  onSelectAngle: (angle: ProjectSummaryAnglePill) => void;
  onUpdate: () => void;
  onDelete: () => void;
  onSearchNavPrevious: () => void;
  onSearchNavNext: () => void;
  searchTerms: string[];
  onSearchTerms: (terms: string[]) => void;
  selectedSummary?: ProjectSummaryContent;
  searchCurrentIx: number | null;
  searchTotalResults?: number;
  uniqSummariesStatusByAngle: ProjectSummaryStatusResponse[];
  isSelectSummaryLoading: boolean;
  isAnySummaryGenerating: boolean;
  projectSummaryLogHit: (props: { action: HitAction; extraDetails?: any; extraReferences?: any }) => void;
}

export const ProjectSummaryHeader = ({
  angles,
  onSelectAngle,
  onUpdate,
  onDelete,
  onSearchNavPrevious,
  onSearchNavNext,
  searchTerms,
  onSearchTerms,
  selectedSummary,
  searchCurrentIx,
  searchTotalResults,
  uniqSummariesStatusByAngle,
  isSelectSummaryLoading,
  isAnySummaryGenerating,
  projectSummaryLogHit,
  ...props
}: ProjectSummaryHeaderProps) => {
  const {
    color: { text },
    spacing,
  } = useThemeTokens();

  const { readOnly } = useProjectSummariesContext();

  const { headerWrapper, topRow, searchWrapper, bottomRow, centerIcon } = useStyles();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [selectedPill, setSelectedPill] = useState(() => {
    const summaryAngleId = selectedSummary?.angleId;
    return angles.find(
      (a) =>
        (a.id === "project-wide" && !summaryAngleId) ||
        summaryAngleId === a.id ||
        a.children?.find((c) => c.id === summaryAngleId)
    )?.id;
  });

  const anglesRef = useRef(null);
  const isAnglesOverflow = useIsOverflow(anglesRef);

  const isUpToDate = useMemo(() => uniqSummariesStatusByAngle.every((s) => s.isUpToDate), [uniqSummariesStatusByAngle]);

  const buttonTooltip = useMemo(() => {
    if (isSelectSummaryLoading) return "";

    if (isAnySummaryGenerating) return "New Summary is being generated";

    if (isUpToDate) return "Unable to generate as no new transcripts have been uploaded";

    return "Regenerate summary with new transcripts.";
  }, [isUpToDate, isAnySummaryGenerating, isSelectSummaryLoading]);

  const visibleAngles = angles.slice(visibleIndex, visibleIndex + 10);

  const onAnglesNavLeft = () => {
    const prevIndex = Math.max(0, visibleIndex - 5);
    setVisibleIndex(prevIndex);
  };

  const onAnglesNavRight = () => {
    const nextIndex = Math.min(angles.length - 5, visibleIndex + 5);
    setVisibleIndex(nextIndex);
  };

  const onToggleSearch = () => {
    onSearchTerms([]);
    setShowSearchBar(!showSearchBar);
  };

  const onSelectAnglePill = useCallback(
    (angle: ProjectSummaryAnglePill) => {
      const parentId = angles.find((a) => angle.id === a.id || a.children?.find((c) => c.id === angle.id))?.id;
      setSelectedPill(parentId);
      onSelectAngle(angle);
    },
    [angles, onSelectAngle]
  );

  const onClickManuallyUpdate = useCallback(() => {
    onUpdate();
    projectSummaryLogHit({ action: HitAction.projectSummaryManuallyUpdate });
  }, [onUpdate, projectSummaryLogHit]);

  //@ts-ignore
  const internalUser = useEnv().internalUser;

  const checkNumbers = (anglePills: ProjectSummaryAnglePill[]) => {
    if (anglePills.length === 2) {
      const projectWideId = "project-wide";
      const projectWide = anglePills.find(({ id }) => id === projectWideId);
      const firstAngle = anglePills.find(({ id }) => id !== projectWideId);
      const totalTranscripts = (firstAngle?.children || []).reduce((acc, v) => acc + v.transcriptCount, 0);
      return totalTranscripts !== projectWide?.transcriptCount;
    } else {
      return true;
    }
  };

  const showAllOnPills = checkNumbers(visibleAngles);

  return (
    <>
      <x.div {...headerWrapper} data-testid="ai-summary-header">
        <x.div {...topRow}>
          <ExpandSidebarButton />
          <x.div gap={spacing.inner.base04} display="flex" alignItems="center">
            <Typography variant="body-small-em">Project Transcript Summary</Typography>
            {!showSearchBar && (
              <Typography variant="body-small" color={text.secondary}>
                {selectedSummary?.lastUpdatedAt && (
                  <>
                    Last updated <FormattedDateRelative date={selectedSummary.lastUpdatedAt} />
                  </>
                )}
              </Typography>
            )}
          </x.div>
          <x.div {...searchWrapper} data-testid="search-bar">
            {showSearchBar && (
              <InputKeyWords
                keywords={searchTerms}
                size="small"
                showClearButton
                onKeywordsMatch={onSearchTerms}
                w={undefined}
                flex={1}
              ></InputKeyWords>
            )}
            <x.div marginLeft="auto" display="flex" gap={spacing.inner.base04}>
              {!showSearchBar ? (
                <x.div display="flex" gap={spacing.inner.base04}>
                  {[
                    !readOnly && (
                      <Tooltip key="update" title={buttonTooltip}>
                        <Button
                          variant="outline"
                          size="small"
                          startIcon={<Refresh />}
                          minWidth="150px"
                          disabled={isUpToDate || isAnySummaryGenerating || isSelectSummaryLoading}
                          loading={isAnySummaryGenerating || isSelectSummaryLoading}
                          onClick={onClickManuallyUpdate}
                          data-testid="update-button"
                        >
                          Manually Update
                        </Button>
                      </Tooltip>
                    ),
                    internalUser && (
                      <Button
                        key="delete"
                        variant="outline"
                        size="small"
                        startIcon={<Delete />}
                        minWidth="150px"
                        onClick={() => onDelete()}
                        data-testid="delete-button"
                      >
                        Delete Summary
                      </Button>
                    ),
                  ].filter(Boolean)}
                </x.div>
              ) : (
                isNumber(searchTotalResults) && (
                  <x.div display="flex" gap={spacing.layout.base02} {...centerIcon}>
                    {searchTotalResults === 0 ? (
                      <Typography component="span" variant="body-em" color="danger">
                        No results found
                      </Typography>
                    ) : (
                      <>
                        <IconButton
                          variant="basic"
                          size="medium"
                          color={text.secondary}
                          testId="chevron-nav-up"
                          onClick={onSearchNavPrevious}
                        >
                          <ChevronUp />
                        </IconButton>
                        <IconButton
                          variant="basic"
                          size="medium"
                          color={text.secondary}
                          testId="chevron-nav-down"
                          onClick={onSearchNavNext}
                        >
                          <ChevronDown />
                        </IconButton>

                        <span data-testid="search-results">
                          <Typography component="span" variant="body-em" color="danger">
                            {searchCurrentIx!! + 1}
                          </Typography>
                          <Typography component="span" variant="body-em" color="secondary">
                            {` / ${searchTotalResults}`}
                          </Typography>
                        </span>
                      </>
                    )}
                  </x.div>
                )
              )}
              <IconButton variant="outline" testId="toggle-search-button" size="small" onClick={onToggleSearch}>
                {showSearchBar ? <Close /> : <Search />}
              </IconButton>
              <MoreOptions />
            </x.div>
          </x.div>
        </x.div>
        <x.div {...bottomRow}>
          <x.div overflow={"hidden"} display="flex" ref={anglesRef}>
            <x.div {...centerIcon}>
              {isAnglesOverflow && visibleIndex !== 0 && (
                <Icon onClick={onAnglesNavLeft} color="secondary" size="x-small">
                  <ChevronLeft data-testid="nav-chevron-left" />
                </Icon>
              )}
            </x.div>
            <List direction="horizontal" gap={spacing.layout.base02}>
              {visibleAngles.map((angle) => (
                <FilterItem
                  key={angle.label}
                  angle={angle}
                  selectedSummary={selectedSummary}
                  onSelectAngle={onSelectAnglePill}
                  isSelected={selectedPill === angle.id}
                  uniqSummariesStatusByAngle={uniqSummariesStatusByAngle}
                  showAll={showAllOnPills}
                ></FilterItem>
              ))}
              <x.div {...centerIcon} data-testid="transcript-tooltip">
                <Tooltip title="Summaries will only appear for angles with 3 or more transcripts" position="right">
                  <Icon size="medium" color={text.secondary}>
                    <Info></Info>
                  </Icon>
                </Tooltip>
              </x.div>
            </List>
          </x.div>
          {isAnglesOverflow && visibleIndex < angles.length / 10 && (
            <x.div {...centerIcon} data-testid="test">
              <Icon onClick={onAnglesNavRight} size="x-small" color="secondary">
                <ChevronRight data-testid="nav-chevron-right" />
              </Icon>
            </x.div>
          )}
        </x.div>
      </x.div>
    </>
  );
};

interface FilterItemProps {
  angle: ProjectSummaryAnglePill;
  selectedSummary: any;
  onSelectAngle: (angle: ProjectSummaryAnglePill) => void;
  uniqSummariesStatusByAngle: ProjectSummaryStatusResponse[];
  isSelected: boolean;
  showAll: boolean;
}

const transcriptCountLabel = (transcriptCount: number) => (transcriptCount < 30 ? transcriptCount : "30+");

export const FilterItem = ({
  angle,
  selectedSummary,
  onSelectAngle,
  uniqSummariesStatusByAngle,
  isSelected,
  showAll,
}: FilterItemProps) => {
  const {
    color: { border, text, background },
  } = useThemeTokens();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | undefined>();

  const parentSelected = useMemo(() => {
    return (
      (selectedSummary?.angleId && selectedSummary?.angleId === angle.id) ||
      (selectedSummary && !selectedSummary?.angleId && angle.id === "project-wide")
    );
  }, [angle.id, selectedSummary]);

  const childrenSelected = useMemo(() => {
    return angle.children?.find(({ id }) => id === selectedSummary?.angleId);
  }, [angle.children, selectedSummary]);

  const complement =
    isSelected && childrenSelected ? (
      <Typography component="span" variant="body-small-em" color="info">
        {`${childrenSelected.label} · ${transcriptCountLabel(childrenSelected.summariesCount)}`}
      </Typography>
    ) : (
      " · " + transcriptCountLabel(angle.summariesCount)
    );

  const onTogglePopover = (event: any) => {
    if (angle.children?.length > 0) {
      if (popoverAnchorEl) setPopoverAnchorEl(undefined);
      else setPopoverAnchorEl(event.target as Element);
    } else {
      onSelectAngle(angle);
    }
  };

  const onClickPopoverAngle = (angle: ProjectSummaryAnglePill) => {
    onSelectAngle(angle);
    setPopoverAnchorEl(undefined);
  };

  const isLoading = useCallback(
    (angleId: string) => {
      const summaryStatus = uniqSummariesStatusByAngle.find(
        (s) => (angleId === "project-wide" && !s.angleId) || s.angleId === angleId
      );
      return (
        summaryStatus?.status === ProjectSummaryStatus.Processing ||
        summaryStatus?.status === ProjectSummaryStatus.Pending
      );
    },
    [uniqSummariesStatusByAngle]
  );

  return (
    <>
      <RoundedButton
        variant="filter"
        backgroundColor={isLoading(angle.id) && angle.children?.length === 0 ? background.disabled : undefined}
        borderColor={isSelected ? border.selected : undefined}
        onClick={onTogglePopover}
        disabled={isLoading(angle.id) && angle.children?.length === 0}
        data-testid={`selector-angle-${angle.id}`}
        aria-selected={Boolean(isSelected)}
      >
        {isLoading(angle.id) && angle.children?.length === 0 && <Loading size="xs" />}
        <Typography variant="body-small-em">
          {angle.label} {complement}
        </Typography>
      </RoundedButton>
      <Popover
        anchorEl={popoverAnchorEl}
        open={Boolean(popoverAnchorEl)}
        onClose={() => setPopoverAnchorEl(undefined)}
        data-testid={`selector-angle-${angle.id}-popover`}
      >
        {angle.children?.length > 0 && (
          <>
            {showAll && (
              <ListOption
                type="text"
                label="All"
                indent={0}
                selected={parentSelected}
                disabled={isLoading(angle.id)}
                leftIcon={
                  <>
                    {isLoading(angle.id) && (
                      <x.div display="flex" alignItems="center" justifyContent="center">
                        <Loading size="xs" />
                      </x.div>
                    )}
                  </>
                }
                rightIcon={
                  <Typography color="secondary" textAlign="right">
                    {transcriptCountLabel(angle.transcriptCount)}
                  </Typography>
                }
                onChange={() => onClickPopoverAngle(angle)}
              ></ListOption>
            )}
            {angle.children.map((subAngle: ProjectSummaryAnglePill) => (
              <ListOption
                type="text"
                label={subAngle.label}
                indent={showAll ? 1 : 0}
                selected={subAngle === childrenSelected}
                disabled={isLoading(subAngle.id)}
                onChange={() => onClickPopoverAngle(subAngle)}
                leftIcon={
                  <>
                    {isLoading(subAngle.id) && (
                      <x.div display="flex" alignItems="center" justifyContent="center">
                        <Loading size="xs" />
                      </x.div>
                    )}
                  </>
                }
                rightIcon={
                  <Typography color="secondary" textAlign="right">
                    {transcriptCountLabel(subAngle.transcriptCount)}
                  </Typography>
                }
              ></ListOption>
            ))}
            <ListOption type="divider" />
            <x.div display="flex" padding="8px" gap="12px">
              <x.div alignSelf="start">
                <Icon size="medium" color={text.secondary}>
                  <Info></Info>
                </Icon>
              </x.div>
              <Typography variant="body-small" color={text.secondary} alignSelf="center">
                Summaries will only appear for angles with 3 or more experts
              </Typography>
            </x.div>
          </>
        )}
      </Popover>
    </>
  );
};

const MoreOptions = () => {
  const { project } = useCurrentProjectContext();
  const { logHit } = useTrackUserAction();
  const { downloadSummaryUrl, readOnly } = useProjectSummariesContext();

  const [moreOpen, setMoreOpen] = useState(false);
  const popoverAnchor = useRef<HTMLButtonElement>(null);

  const onClickDownloadSummary = () => {
    setMoreOpen(false);
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.downloadTranscript,
      projectToken: project?.token,
      details: { transcriptType: "project-summary" },
    });

    window.open(downloadSummaryUrl, "_blank");
  };

  return (
    <>
      <IconButton
        data-testid="deliverables-topbar-more-options"
        ref={popoverAnchor}
        onClick={() => setMoreOpen((a) => !a)}
        variant="ghost"
        isSelected={moreOpen}
        size="small"
        disabled={readOnly}
      >
        <MoreVert />
      </IconButton>
      {popoverAnchor.current && (
        <Popover
          placement="bottom-end"
          anchorEl={popoverAnchor.current}
          open={moreOpen}
          data-testid="more-options-popover"
          p={0}
          onClose={() => setMoreOpen(false)}
        >
          <ListOption type="text" label="Download summary" leftIcon={<Download />} onChange={onClickDownloadSummary} />
        </Popover>
      )}
    </>
  );
};
