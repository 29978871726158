import styled from "styled-components";
import { th } from "@xstyled/styled-components";

export const PopoverArrow = styled.div`
  position: absolute;
  width: ${th.space("layout-base")};
  height: ${th.space("layout-base0")};
  z-index: -1;

  &::before {
    content: "";
    transform: rotate(45deg);
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
