import { HitOrigin } from "@alphasights/portal-api-client";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useCommentContext } from "components/CommentThread/CommentContext";
import { currentView } from "helpers/currentView";
import { useLockedExpert } from "hooks/useLockedExpert";
import { noop } from "lodash";
import { ENABLE_PORTAL_COMMENTS, useProjectBadgeContext } from "providers/BadgeProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCallback, useMemo } from "react";
import * as React from "react";

export const useCommentExpert = (interaction: Interaction) => {
  const { getCounts } = useCommentContext();
  const { locked } = useLockedExpert(interaction);
  const { onOpenComments } = useProjectInteractionsContext();
  const isExpertHidden = interaction.hidden;
  const isActiveProject = interaction.isActiveProject;
  const isDeliverablesView = currentView() === HitOrigin.deliverablesView;
  const { hasProjectBadge } = useProjectBadgeContext();
  const hasBadge = hasProjectBadge(ENABLE_PORTAL_COMMENTS);
  const user = useCurrentUser();

  const showActions = !isDeliverablesView && isActiveProject && !locked;

  const { pendingCount } = getCounts({
    angleId: interaction.angles?.[0]?.id,
    expertId: interaction.advisorId,
  });

  const hasNewMessages = useMemo(() => pendingCount > 0, [pendingCount]);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      event.stopPropagation();
      if (!locked) {
        onOpenComments(interaction);
      }
    },
    [interaction, onOpenComments, locked]
  );

  const showComments = !!user && !isExpertHidden && hasBadge && isActiveProject;

  return showActions
    ? {
        locked,
        hasNewMessages,
        pendingCount,
        onClick,
        showComments,
      }
    : {
        locked,
        hasNewMessages: false,
        pendingCount: 0,
        onClick: noop,
        showComments: false,
      };
};
