import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

type ChartLegendProps = {
  elements: {
    color: string;
    label: string;
  }[];
};

const RangeChartLegend = ({ elements }: ChartLegendProps) => {
  const { color: auiColor, shape, spacing } = useThemeTokens();

  const startLabel = elements[0].label;
  const endLabel = elements[elements.length - 1].label;
  const edgeBorderRadius = shape.border.radius[4];

  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      pt={spacing.inner.base04}
      data-testid="chart-legend-container"
    >
      <x.div
        border={`1px solid ${auiColor.border.neutral.default}`}
        p={`${spacing.inner.base02} ${spacing.inner.base03}`}
        borderRadius={shape.border.radius[8]}
        data-testid="chart-legend"
      >
        <x.div
          display="grid"
          gridAutoFlow="column"
          gridAutoColumns="minmax(5px, 39px)"
          gap={shape.border.width.sm}
          borderRadius={edgeBorderRadius}
        >
          {elements.map(({ color, label }) => (
            <x.div
              key={label}
              backgroundColor={color}
              h="10px"
              borderColor={auiColor.background.neutral.default}
              borderStyle="solid"
              borderWidth={{
                _: `2px 0`,
                first: `2px 0 2px 2px`,
                last: `2px 2px 2px 0`,
              }}
              borderRadius={{
                first: `${edgeBorderRadius} 0 0 ${edgeBorderRadius}`,
                last: `0 ${edgeBorderRadius} ${edgeBorderRadius} 0`,
              }}
              data-testid="chart-legend-entry"
            />
          ))}
        </x.div>
        <x.div
          display="flex"
          justifyContent="space-between"
          gap={spacing.inner.base02}
          mt={spacing.inner.base02}
          w="100%"
        >
          <Typography variant="body-small">{startLabel}</Typography>
          <Typography variant="body-small">{endLabel}</Typography>
        </x.div>
      </x.div>
    </x.div>
  );
};

export default RangeChartLegend;
