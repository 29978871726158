import { FC, useContext, useCallback } from "react";
import * as React from "react";
import { fetch } from "hooks/useApi";
import { NotificationEvent, useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

export type UserNotificationsState = {
  fetchNotifications: ((page: number) => Promise<NotificationEvent[]>) | undefined;
  markAsRead: (ids: (string | number)[]) => Promise<void>;
  notificationsSummary: (currentNotifications: NotificationEvent[]) => NotificationsSummary;
};

interface NotificationsSummary {
  details: {
    total: number;
    [key: string]: number;
  };
  references: {
    notificationIds: (string | number)[];
  };
}

interface UserNotificationsProviderProps {
  children: React.ReactNode;
  enabled?: boolean;
}

const UserNotificationsContext = React.createContext<UserNotificationsState | undefined>(undefined);

export const UserNotificationsProvider: FC<UserNotificationsProviderProps> = ({ children, enabled = true }) => {
  const { logHit } = useTrackUserAction();

  const notificationsSummary = useCallback((currentNotifications: NotificationEvent[]) => {
    const countByType = currentNotifications.reduce((acc: { [key: string]: number }, n) => {
      acc[n.type] = (acc[n.type] || 0) + 1;
      return acc;
    }, {});

    return {
      details: {
        total: currentNotifications.length,
        ...countByType,
      },
      references: {
        notificationIds: currentNotifications.flatMap((n) => n.ids),
      },
    };
  }, []);

  const fetchNotifications = useCallback(
    (page: number) => {
      return fetch({ url: "/api/notifications?pageNumber=" + page })
        .then((res) => res.json())
        .then((res: NotificationEvent[]) => {
          // We won't log the first page load as the first page is loaded
          // without any user interaction.
          if (page > 1) {
            const summary = notificationsSummary(res);
            logHit({
              origin: HitOrigin.globalNav,
              action: HitAction.notificationsNewPageLoaded,
              details: {
                page,
                ...summary.details,
              },
              references: {
                ...summary.references,
              },
              decodeWithUserId: true,
            });
          }
          return res.map((r) => {
            const data = new Map();
            Object.entries(r.data || {}).forEach((el) => {
              data.set(el[0], el[1]);
            });
            return {
              ...r,
              data,
            };
          });
        });
    },
    [logHit, notificationsSummary]
  );

  const markAsRead = useCallback(async (ids: (string | number)[]) => {
    await fetch({
      url: "/api/notifications/ack",
      method: "POST",
      handleForbidden: true,
      body: JSON.stringify({ ids }),
    });
  }, []);

  const ctx = {
    markAsRead,
    notificationsSummary,
    fetchNotifications: enabled ? fetchNotifications : undefined,
  };
  return <UserNotificationsContext.Provider value={ctx}>{children}</UserNotificationsContext.Provider>;
};

export const useUserNotificationsContext = (): UserNotificationsState => {
  const ctx = useContext(UserNotificationsContext);

  if (ctx === undefined) {
    throw new Error("useUserNotificationsContext must be used within a UserNotificationsProvider");
  }

  return ctx;
};
