import { FC, useState } from "react";
import { addWatchlistEntries, noWatchlistEntriesMessage, searchErrorMessage } from "components/WatchlistModal/consts";
import {
  EmptyWatchlistContainer,
  WatchlistContainer,
  LoadingContainer,
  TriggerSearchContainer,
  SearchContainer,
} from "./EditWatchlistView.styled";
import WatchlistTriggerCard from "components/WatchlistModal/WatchlistTriggerCard";
import { FilterWatchlistTriggerProps, WatchlistDataProps, WatchlistTriggerProps } from "hooks/useWatchlist";
import WatchlistFilter from "components/WatchlistModal/WatchlistFilter";
import { Alert, Loading, Typography } from "@alphasights/alphadesign-components";
import SearchBar from "../SearchBar";

type EditWatchlistProps = {
  isLoading: boolean;
  watchlistTriggers: WatchlistTriggerProps[];
  filter: FilterWatchlistTriggerProps;
  updateFilter: (value: FilterWatchlistTriggerProps) => void;
  availableWatchlists: WatchlistDataProps[];

  watchlistId?: string;
};

type EditWatchlistBodyProps = {
  isLoading: boolean;
  watchlistTriggers: WatchlistTriggerProps[];
  watchlistId: string;
};

const EditWatchlistBody: FC<EditWatchlistBodyProps> = ({ isLoading, watchlistTriggers, watchlistId }) => {
  const hasTriggers = watchlistTriggers.length > 0;

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading size="sm" />
      </LoadingContainer>
    );
  }

  if (hasTriggers) {
    return (
      <WatchlistContainer>
        {watchlistTriggers.map((trigger: WatchlistTriggerProps) => (
          <WatchlistTriggerCard trigger={trigger} watchlistId={watchlistId} />
        ))}
      </WatchlistContainer>
    );
  }

  return (
    <EmptyWatchlistContainer>
      <Typography color="secondary" variant="body-em">
        {addWatchlistEntries}
      </Typography>
      <Typography color="secondary">{noWatchlistEntriesMessage}</Typography>
    </EmptyWatchlistContainer>
  );
};

const EditWatchlistView: FC<EditWatchlistProps> = ({
  isLoading,
  watchlistId,
  watchlistTriggers,
  filter,
  updateFilter,
  availableWatchlists,
}) => {
  const [isSearchError, setIsSearchError] = useState(false);

  return (
    <>
      <SearchContainer>
        <TriggerSearchContainer>
          <SearchBar watchlistId={watchlistId as string} handleError={setIsSearchError} />
          <WatchlistFilter
            triggerFilters={filter}
            updateTriggerFilter={updateFilter}
            isDisabled={watchlistTriggers.length === 0}
            watchlistId={watchlistId}
            availableWatchlists={availableWatchlists}
          />
        </TriggerSearchContainer>
        {isSearchError && (
          <Alert variant="danger" width="100%" size="small">
            {searchErrorMessage}
          </Alert>
        )}
      </SearchContainer>
      <EditWatchlistBody
        isLoading={isLoading}
        watchlistTriggers={watchlistTriggers}
        watchlistId={watchlistId as string}
      />
    </>
  );
};

export { EditWatchlistView as default };
