import { ReactElement } from "react";
import * as React from "react";
import styled, { x } from "@xstyled/styled-components";
import { Popover, Status, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { StatusPillVariant, useMessengerStatusPillStyles } from "./MessengerStatusPill.styles";

export type MessengerStatusPillProps = {
  text?: string;
  advisorsInfo?: string[];
  variant: StatusPillVariant;
  showCount?: boolean;
  onlyCount?: number;
  tooltip?: ReactElement[] | undefined;
  tooltipOpen?: boolean;
  overrideColor?: string;
  textColor?: string;
  onClick?: Function;
};

export const MessengerStatusPill = ({
  text,
  advisorsInfo,
  variant,
  showCount = true,
  onlyCount,
  tooltip = undefined,
  tooltipOpen = undefined,
  overrideColor = undefined,
  textColor = undefined,
  onClick = undefined,
}: MessengerStatusPillProps) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
  const ref = React.useRef(null);

  const onClickTooltip = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    } else {
      handlePopoverOpen(event);
    }
  };
  const handlePopoverOpen = (event: React.MouseEvent) => {
    if (advisorsInfo) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };
  const open = Boolean(anchorEl);

  const { variantStyle, stausPopoverItem, statusPopoverText } = useMessengerStatusPillStyles({ variant: variant });

  const advisorsCount = advisorsInfo ? advisorsInfo.length : "";

  const pillText = onlyCount
    ? `${variant === "more" ? "+" : ""}${onlyCount}`
    : `${variant === "more" ? "+ " : ""}${showCount ? advisorsCount : ""} ${text}`;

  return (
    <>
      <Tooltip title={tooltip} disabled={!tooltip} open={tooltipOpen} maxWidth={"45ch" as any}>
        <x.div data-testid="div-popover" onMouseEnter={handlePopoverOpen}>
          <NoWrapStatus
            {...variantStyle.styles}
            {...(overrideColor && {
              color: `background-${overrideColor}`,
              borderColor: `background-${overrideColor} !important`,
            })}
            {...(textColor && { textColor })}
            testId={`messenger-status-${variant}`}
            size="small"
            leftIcon={variantStyle.icon}
            text={pillText}
            onClick={onClickTooltip}
          />
        </x.div>
      </Tooltip>
      <Popover
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        placement="bottom-start"
        closeOnMouseLeave
        leaveDelay={100}
      >
        {advisorsInfo?.map((advisor) => (
          <x.div key={advisor} {...stausPopoverItem}>
            <Typography {...statusPopoverText}>{advisor}</Typography>
          </x.div>
        ))}
      </Popover>
    </>
  );
};

const NoWrapStatus = styled(Status)`
  white-space: nowrap;
`;
