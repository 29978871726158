import { Button, ListOption, Skeleton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const CardWrapper = styled(x.div)(() => {
  const { color, shape, spacing } = useThemeTokens();

  return `
    background-color: ${color.background.surface.overlay};
    border: ${shape.border.width.sm} solid ${color.border.neutral.default};
    border-radius: ${shape.border.radius.medium};
    margin-bottom: ${spacing.inner.base04};
  `;
});

export const CardTitleWrapper = styled(x.div)<{ readOnly?: boolean }>`
  width: 100%;
  cursor: ${({ readOnly }) => (readOnly ? "default" : "text")};
`;

export const CardHeaderWrapper = styled(x.div)(() => {
  const { spacing, color, shape } = useThemeTokens();

  return `
    padding: ${spacing.inner.base03} ${spacing.inner.base04} ${spacing.inner.base03} ${spacing.inner.base02};
    border-bottom: ${shape.border.width.sm} solid ${color.border.neutral.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
});

export const CardEditTitleWrapper = styled(x.div)(() => {
  return `
    width: 100%;
  `;
});

export const CardTitle = styled(Typography).attrs({ component: "div" })(() => {
  const { spacing } = useThemeTokens();

  return `
    display: flex;
    align-items: center;
    gap: ${spacing.inner.base};
  `;
});

export const CardContentWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    padding: ${spacing.inner.base03} ${spacing.inner.base04} ${spacing.inner.base03} 0px;
  `;
});

export const QuestionLoadingWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    padding: ${spacing.inner.base02} ${spacing.inner.base03} ${spacing.inner.base02} ${spacing.inner.base06};
  `;
});

export const QuestionWrapper = styled(x.div)(() => {
  return `
    display: flex;
    align-items: center;
  `;
});

export const QuestionListWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base};
    margin-bottom: ${spacing.inner.base};
  `;
});

export const Question = styled(ListOption)(() => {
  const { spacing, shape } = useThemeTokens();

  return `
    position: relative;
    gap: ${spacing.inner.base02};
    border-radius: ${shape.border.radius.medium};
    display: flex;
    align-items: center;
    .list-option-label {
      white-space: normal;
      word-break: break-word;
    }
    & > div:has([data-testid="action-buttons"]) {
      position: absolute;
      right: 0;
      height: 100%;
    }
  `;
});

export const QuestionButtons = styled(x.div)(() => {
  return `
    padding-top: 2px;
  `;
});

export const AddQuestionButton = styled(Button)(() => {
  const { spacing } = useThemeTokens();

  return `
    margin-left: ${spacing.inner.base04};
  `;
});

export const AddQuestionFieldWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    margin-left: ${spacing.inner.base04};
  `;
});

export const QuestionLoading = () => {
  const { spacing } = useThemeTokens();

  return (
    <QuestionLoadingWrapper>
      <Skeleton variant="noMargin" height={spacing.layout.base02} width="100%" />
      <x.div h={spacing.inner.base} />
      <Skeleton variant="noMargin" height={spacing.layout.base02} width="200px" />
    </QuestionLoadingWrapper>
  );
};

export const CardTitleLoading = () => (
  <x.div w="50%" maxW="400px" padding="6px 12px">
    <Skeleton variant="noMargin" height="20px" width="100%" />
  </x.div>
);
