import { Typography } from "@alphasights/alphadesign-components";
import { ComplianceReviewStatus } from "types";

export const MessengerComplianceReviewApprovalStatus = ({ status }: { status?: ComplianceReviewStatus }) => {
  if (
    status &&
    [
      ComplianceReviewStatus.SUGGESTION_PENDING,
      ComplianceReviewStatus.SUGGESTION_DECLINED,
      ComplianceReviewStatus.PENDING,
    ].includes(status)
  ) {
    const statusMap = {
      [ComplianceReviewStatus.SUGGESTION_PENDING]: {
        color: "processing",
        text: "Awaiting Approval",
      },
      [ComplianceReviewStatus.PENDING]: {
        color: "processing",
        text: "Awaiting Approval",
      },
      [ComplianceReviewStatus.SUGGESTION_DECLINED]: {
        color: "danger",
        text: "Suggestion Declined",
      },
    } as Record<ComplianceReviewStatus, { color: string; text: string }>;

    return (
      <>
        •{" "}
        <Typography color={statusMap[status].color} display="inline-block">
          {statusMap[status].text}
        </Typography>
      </>
    );
  }

  return <></>;
};
