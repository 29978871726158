import { FileStatus } from "./constants";
import { SelectedThirdPartyFileToUpload } from "./types";

const isSuccessStatus = (status: FileStatus) => [FileStatus.uploaded, FileStatus.informationRequired].includes(status);

const getEditDocumentProps = (file: SelectedThirdPartyFileToUpload) => {
  const { name, date, isTranscript, title, experts } = file;
  return {
    documentDate: date.toISOString(),
    isTranscript,
    fileName: name,
    title,
    experts,
  };
};

export { isSuccessStatus, getEditDocumentProps };
