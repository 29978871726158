import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

const StateContainer = styled(x.div)`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorContainer = styled(x.div)(() => {
  const { color, spacing } = useThemeTokens();
  return `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${spacing.inner.base04};
    background: ${color.background.surface.page.default};
  `;
});

export { StateContainer, ErrorContainer };
