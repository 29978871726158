import React, { FC, useCallback, useEffect, useState } from "react";
import { SelectValue } from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";

import { Angle } from "views/DeliverablesView/ThirdPartyDocUploadModal";
import CompanySearchField, {
  Company,
} from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/CompanySearchField";
import { FormFieldLabel } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/constants";
import { ThirdPartyDocumentExpertWithId } from "types";
import AngleField from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/AngleField";
import withErrorMessage from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/withErrorMessage";
import { getFieldError, getFalsyValueFields } from "./utils";

import * as EditDocumentModalStyles from "../EditDocumentModal.styled";
import * as ExpertInfoStyles from "./ExpertInfo.styled";

const S = {
  Wrapper: EditDocumentModalStyles.FormRow,
  FieldWrapper: EditDocumentModalStyles.FormItem,
  Label: EditDocumentModalStyles.Label,
  StyledTextField: EditDocumentModalStyles.StyledTextField,
  ...ExpertInfoStyles,
};

export const DataTestIds = {
  EXPERT_COMPANY_FIELD: "expert-company-field",
  EXPERT_POSITION_FIELD: "expert-position-field",
  ANGLE_FIELD: "angle-field",
  EXPERT_INFO: "expert-info",
  DELETE_EXPERT_BUTTON: "delete-expert-button",
};

type ExpertInfoProps = {
  onUpdate: (newState: ThirdPartyDocumentExpertWithId) => void;
  expert: ThirdPartyDocumentExpertWithId;
  isRemovable?: boolean;
  onDelete?: () => void;
  showErrors?: boolean;
  dataTestId?: string;
};

const TextFieldWithError = withErrorMessage(S.StyledTextField);

export const ExpertInfo: FC<ExpertInfoProps> = ({
  expert,
  onUpdate,
  onDelete = () => {},
  isRemovable = false,
  showErrors = false,
  dataTestId = DataTestIds.EXPERT_INFO,
}) => {
  const [errorFields, setErrorFields] = useState<string[]>([]);

  const { companyName, cdsAlphaCompanyId, position, angle } = expert;

  const checkForErrors = useCallback(() => {
    if (showErrors) {
      const errorFields = getFalsyValueFields(expert);
      if (errorFields.length > 0) {
        setErrorFields(errorFields);
      } else {
        setErrorFields([]);
      }
    } else {
      setErrorFields([]);
    }
  }, [expert, showErrors]);

  useEffect(() => {
    checkForErrors();
  }, [showErrors, checkForErrors]);

  const updateExpert = (updatedValue: Partial<ThirdPartyDocumentExpertWithId>) => {
    onUpdate({ ...expert, ...updatedValue });
    checkForErrors();
  };

  const handleExpertCompanyChange = (value: Company) => {
    const { id, name } = value ?? {};
    updateExpert({ companyName: name, cdsAlphaCompanyId: id });
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateExpert({ position: e.target.value });
  };

  const handleAngleChange = (value: SelectValue) => {
    updateExpert({ angle: value as Angle });
  };

  return (
    <S.Wrapper data-testid={dataTestId}>
      <S.FieldWrapper>
        <S.Label>{FormFieldLabel.expertCompany}</S.Label>
        <CompanySearchField
          onChange={handleExpertCompanyChange}
          value={{
            name: companyName,
            id: cdsAlphaCompanyId,
          }}
          // at least a companyName is needed in order for there to be a company
          errorMessage={getFieldError("companyName", errorFields)}
          dataTestId={DataTestIds.EXPERT_COMPANY_FIELD}
        />
      </S.FieldWrapper>
      <S.FieldWrapper>
        <S.Label>{FormFieldLabel.expertPosition}</S.Label>
        <TextFieldWithError
          dataAttributes={{ "data-testid": DataTestIds.EXPERT_POSITION_FIELD }}
          value={position}
          isControlled
          onChange={handleRoleChange}
          error={errorFields.includes("position")}
          errorMessage={getFieldError("position", errorFields)}
        />
      </S.FieldWrapper>
      <S.FieldWrapper>
        <AngleField
          value={angle as Angle}
          onChange={handleAngleChange}
          dataTestId={DataTestIds.ANGLE_FIELD}
          errorMessage={getFieldError("angle", errorFields)}
        />
      </S.FieldWrapper>
      {isRemovable && (
        <S.StyledIconButton
          variant="outline"
          size="small"
          onClick={onDelete}
          rowHasError={showErrors}
          dataAttributes={{ "data-testid": DataTestIds.DELETE_EXPERT_BUTTON }}
        >
          <Delete />
        </S.StyledIconButton>
      )}
    </S.Wrapper>
  );
};

export default ExpertInfo;
