import { Quote } from "@alphasights/alphadesign-icons";
export enum SEARCH_ITEM_TYPE {
  Keyword = 1,
}

export const SEARCH_PLACEHOLDER_TEXT = "Search Deliverables...";

export const SEARCH_ITEM_CONFIG = {
  [SEARCH_ITEM_TYPE.Keyword]: {
    secondaryLabel: "Keyword",
    StartIcon: Quote,
  },
};

export const REQUEST_EXPERT_DISCLAIMER =
  "Clicking 'Request Expert' will notify your AlphaSights team, who will follow up with you shortly. Any expert requests will be checked against your project compliance requirements for bespoke interactions.";

export enum InteractionOrigin {
  native = "NATIVE",
  thirdParty = "THIRD_PARTY",
}

export const MIME_TYPE_TO_EXTENSION = {
  "application/pdf": "PDF",
  "application/msword": "DOC",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOCX",
  "application/vnd.ms-excel": "XLS",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "application/vnd.ms-powerpoint": "PPT",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "PPTX",
};
