import { PropsWithChildren } from "react";
import { FeatureFlag, LaunchFlag, SoftLaunchProvider, useSoftLaunch } from "@alphasights/ads-community-utils";
import { Env } from "@alphasights/portal-api-client";
import { Feature, FEATURES, Flag, FLAGS, MAP_FROM_FEATURES_TO_FLAGS } from "app/constants";
import { useEnv } from "@alphasights/client-portal-shared";

function getValueFromEnv(path: string, env: Env) {
  const keys = path.split(".");
  let value = env as any;
  for (const key of keys) {
    if (value === undefined) {
      return undefined;
    }
    value = value[key];
  }
  return value;
}

function mapFeaturesToFlags(features: { [key: string]: LaunchFlag }) {
  return Object.keys(features).reduce((acc, key) => {
    const featureName = key in MAP_FROM_FEATURES_TO_FLAGS ? MAP_FROM_FEATURES_TO_FLAGS[key] : key;
    acc[featureName] = features[key as Feature];
    return acc;
  }, {} as Record<string, LaunchFlag>);
}

export function getFlagsFromEnv(env: Env) {
  const flags: { [key: string]: FeatureFlag } = {};
  for (const key in FLAGS) {
    flags[key] = getValueFromEnv(FLAGS[key as Flag], env) as FeatureFlag;
  }
  return flags;
}

export function getFeaturesFromEnv(env: Env) {
  const features: { [key: string]: LaunchFlag } = {};
  for (const key in FEATURES) {
    features[key] = parseFloat(getValueFromEnv(FEATURES[key as Feature], env)) as LaunchFlag;
  }
  return mapFeaturesToFlags(features);
}

export function useFeatures(): Record<Feature, boolean> {
  const isFeatureEnabled = useSoftLaunch();

  return Object.keys(FEATURES).reduce((acc, key) => {
    const featureName = key in MAP_FROM_FEATURES_TO_FLAGS ? MAP_FROM_FEATURES_TO_FLAGS[key] : key;
    acc[key as Feature] = isFeatureEnabled(featureName);
    return acc;
  }, {} as Record<Feature, boolean>);
}

export function useFlags(): Record<Flag, boolean> {
  const isFlagEnabled = useSoftLaunch();

  return Object.keys(FLAGS).reduce((acc, key) => {
    acc[key as Flag] = isFlagEnabled(key);
    return acc;
  }, {} as Record<Flag, boolean>);
}

export const FeatureFlagsWrapper = ({ children }: PropsWithChildren) => {
  const env = useEnv();

  if (!env) {
    return null;
  }

  const initialFlags = getFlagsFromEnv(env);
  const launchFlags = getFeaturesFromEnv(env);

  return <SoftLaunchProvider {...{ initialFlags, launchFlags }}>{children}</SoftLaunchProvider>;
};
