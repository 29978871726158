import { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCallDurationSelectorStyles } from "./CallDurationSelector.styles";
import _ from "lodash";

const defaultCallDurationOptions = [
  { value: 120, label: "120 mins" },
  { value: 90, label: "90 mins" },
  { value: 60, label: "60 mins" },
];

const allowNonHourAutoBookDurationOptions = [{ value: 30, label: "30 mins" }];

const additionalFollowUpCallDurationOptions = [
  { value: 15, label: "15 mins" },
  { value: 30, label: "30 mins" },
  { value: 45, label: "45 mins" },
];

export const CallDurationSelector = ({ value, durationOptions, onChangeExpectedDuration, isMobileView = false }) => {
  const { containerStyles } = useCallDurationSelectorStyles();
  const size = isMobileView ? "small" : "medium";
  return (
    <x.div {...containerStyles}>
      {!isMobileView && <Typography>Call duration (mins)</Typography>}
      <ToggleButtonGroup value={value} allowUnselection={false} onChange={onChangeExpectedDuration}>
        {durationOptions
          .sort((d1, d2) => d1.value - d2.value)
          .map((duration, idx) => (
            <ToggleButton key={`${idx}`} sizeVariant={size} value={duration.value}>
              {duration.label}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </x.div>
  );
};

export const useCallDurationOptions = ({ allowNonHourAutoBook = false, isFollowUpCall = false }) => {
  const [durationOptions, setDurationOptions] = useState([]);

  useEffect(() => {
    const newDurationOptions = [...defaultCallDurationOptions];
    allowNonHourAutoBook && newDurationOptions.push(...allowNonHourAutoBookDurationOptions);
    isFollowUpCall && newDurationOptions.push(...additionalFollowUpCallDurationOptions);
    setDurationOptions(_.uniqBy(newDurationOptions, "value"));
  }, [allowNonHourAutoBook, isFollowUpCall]);

  return durationOptions;
};
