import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useAdvisorFlyOutStyles } from "pages/InteractionPage/AdvisorFlyOut.styles";

export const useContentFlyoutStyles = () => {
  const {
    color: { border: borderColor, background },
    shape: { border },
    spacing: { inner },
  } = useThemeTokens();

  const { header, container, body, standardContent, sidebar } = useAdvisorFlyOutStyles();

  const contentSidebar = {
    ...sidebar,
    borderLeftWidth: border.width.sm,
    borderStyle: "solid",
    borderColor: borderColor.divider,
  };

  const contentHeader = {
    ...header,
    position: "static",
  };

  const contentBody = {
    ...body,
    ...standardContent,
    pt: "0",
    mt: "0",
  };

  const topbar = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    w: "100%",
    marginLeft: inner.base02,
  };

  const topbarTitle = {
    display: "flex",
    justifyContent: "space-between",
  };

  const requestExpertsButton = {
    mt: inner.base02,
    alignSelf: "end",
  };

  const expertsContainer = {
    display: "flex",
    flexDirection: "column",
  };

  const primerExpertsAngle = {
    display: "flex",
    gap: inner.base,
    alignItems: "center",
  };

  const contentExpertsList = {
    listStyleType: "disc",
    marginLeft: inner.base05,
  };

  const viewFreePrimerButton = {
    alignSelf: "end",
    marginTop: inner.base02,
  };

  const details = {
    display: "flex",
    flexWrap: "wrap",
    gap: inner.base02,
    alignItems: "center",
  };

  const contentDetails = {
    display: "flex",
    flexWrap: "wrap",
    gap: inner.base02,
    alignItems: "center",
  };

  const primerExpertsList = {
    listStyleType: "disc",
    marginLeft: inner.base05,
    display: "flex",
    flexDirection: "column",
    gap: inner.base,
  };

  const primerExpertsListContainer = {
    display: "flex",
    flexDirection: "column",
  };

  const primerExperts = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base,
  };

  const primerDescriptionSection = {
    display: "flex",
    flexDirection: "column",
  };

  const descriptionSectionAccordion = {
    borderTop: "none",
    borderBottom: `1px solid ${borderColor.neutral.default} !important`,
  };

  const accordion = {
    w: "100%",
  };

  const footerContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: background.surface.page.default,
    px: inner.base08,
    py: inner.base04,
    borderTop: "solid 1px " + borderColor.neutral.default,
  };

  const footerContentStatus = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  };

  return {
    header: contentHeader,
    container,
    body: contentBody,
    sidebar: contentSidebar,
    topbar,
    topbarTitle,
    requestExpertsButton,
    contentExpertsList,
    viewFreePrimerButton,
    details,
    contentDetails,
    primerExpertsAngle,
    primerExpertsList,
    primerExpertsListContainer,
    primerExperts,
    primerDescriptionSection,
    descriptionSectionAccordion,
    accordion,
    expertsContainer,
    footerContainer,
    footerContentStatus,
  };
};
