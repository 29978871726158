import { useState, FC, useMemo } from "react";
import { noop } from "lodash";
import { Slider } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import {
  SliderRange,
  SliderOption,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/SliderFilter/types";
import useSaveFilterTimeout from "pages/AlphaNowPage/hooks/useSaveFilterTimeout";
import Filter, { AccordionPropsType } from "components/Filter";

import { SliderFilterContainer, SliderWrapper } from "./SliderFilter.styled";

type SliderFilterProps = {
  filterKey: string;
  title: string;
  value: SliderRange;
  options: SliderOption[];
  onChange: (value: any) => void;

  id?: string;
  onClear?: () => void;
  enableOverlap?: boolean;
  isAccordion?: boolean;
  accordionProps?: AccordionPropsType;
};

const SliderFilter: FC<SliderFilterProps> = ({
  id,
  filterKey,
  title,
  value,
  options,
  onChange,
  onClear = noop,
  enableOverlap = false,
  isAccordion = false,
  accordionProps = {},
}) => {
  const { isMobile } = useCheckScreen();
  const [values, setValues] = useState<SliderRange>({ min: value.min, max: value.max });

  const marks = useMemo(() => {
    const startLabel = options[values.min].label;
    const endLabel = options[values.max].label;
    return [
      { label: startLabel, value: options[0].value },
      ...options.slice(1, options.length - 1),
      { label: endLabel, value: options[options.length - 1].value },
    ];
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const { saveFilter } = useSaveFilterTimeout({
    onSave: onChange,
    filterState: value,
  });

  const handleChange = (event: Event) => {
    const [minValue, maxValue] = (event.target as HTMLInputElement)?.value;
    const [newMin, newMax] = [parseInt(minValue), parseInt(maxValue)];

    // check if the user attempts to overlap the slider thumbs
    const areThumbsOverlapping = Math.abs(newMin - newMax) === 0;
    // we should only actually change the values if the thumbs are not overlapping
    // or if overlap is allowed
    const shouldUpdateValues = enableOverlap || (!enableOverlap && !areThumbsOverlapping);
    if (shouldUpdateValues) {
      const newValues = { min: newMin, max: newMax };
      setValues(newValues);
      saveFilter({ [filterKey]: newValues });
    }
  };

  const handleClear = () => {
    setValues({ min: 0, max: options.length - 1 });
    onClear();
  };

  return (
    <SliderFilterContainer id={id ?? "slider-filter"} isAccordion={isAccordion}>
      <Filter onClear={handleClear} title={title} isAccordion={isAccordion} accordionProps={accordionProps}>
        <SliderWrapper isAccordion={isAccordion}>
          <Slider
            width={isMobile ? "-webkit-fill-available" : "228px"}
            step={1}
            value={[values.min, values.max]}
            marks={marks.map((mark, index) => ({
              value: mark.value,
              label: [0, marks.length - 1].includes(index) ? mark.label : "",
            }))}
            disableSwap
            valueTextBottomMargin={0}
            customValueLabel={(index) => {
              const numChars = marks[index].label.length;
              return {
                bottomMargin: 34,
                // workaround to hide tooltip
                rightShift: 1000,
                text: marks[index].label,
                width: 8 * numChars + 4,
              };
            }}
            onChange={handleChange}
          />
        </SliderWrapper>
      </Filter>
    </SliderFilterContainer>
  );
};

export default SliderFilter;
