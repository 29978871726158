import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { HitAction, KpcItem, ProjectFeature, SynthesisQuote } from "@alphasights/portal-api-client";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { useStyles } from "../SynthesisModuleContent.styles";
import { Button, Skeleton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  DeleteItemWithConfirmationBtn,
  EditableInput,
  EditStructuredModuleItemCTAs,
  ExpertQuote,
  MoreActions,
  RegenerationFailedToast,
  RevisionToggler,
} from "../components";
import { x } from "@xstyled/styled-components";
import { KpcChart } from "./KpcChart";
import { kpc } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { ScheduleCustomersBanner } from "../components/ScheduleCustomersBanner";
import { useEditKpcOperations } from "views/ProjectSynthesisView/hooks/useEditKpcOperations";
import { useEditItem } from "../../hooks/useEditItem";
import { v4 as uuid } from "uuid";
import { noop } from "lodash";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

export const KpcModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <KpcModuleContentSwitch />;
};

const KpcModuleContentSwitch = () => {
  return <KpcCompleteModuleContent />;
};

const KpcCompleteModuleContent = () => {
  const [draftKpcItems, setDraftKpcItems] = useState<KpcItem[]>([]);
  const [editItemsCount, setEditItemsCount] = useState(0);
  const { spacing } = useThemeTokens();
  const { kpcContentWrapper, kpcRevisionTogggler } = useStyles();
  const { revision, isLatestRevisionSelected, thirdPartyDocuments } = useProjectSynthesisContext();

  const onKpcItemAdded = useCallback(
    (kpcItem: KpcItem) => {
      setDraftKpcItems([...draftKpcItems, kpcItem]);
    },
    [draftKpcItems]
  );

  const kpcItems = kpc(revision?.contents).kpcItems;

  return (
    <x.div data-testid="kpc-module-content" mt={spacing.inner.base06}>
      <ScheduleCustomersBanner />
      <x.div {...kpcContentWrapper} position="relative">
        {editItemsCount + draftKpcItems.length === 0 && <RevisionToggler {...kpcRevisionTogggler} />}
        {revision?.status === "COMPLETED" && (
          <KpcChart
            kpcItems={kpcItems.filter((item) => !!item.sentiments.length) ?? []}
            thirdPartyDocuments={thirdPartyDocuments}
          />
        )}
        {isLatestRevisionSelected && revision?.status === "COMPLETED" && (
          <AddKpcButton onKpcItemAdded={onKpcItemAdded} />
        )}
        {draftKpcItems.map((addedItem) => (
          <KpcItemComponent
            key={addedItem.id}
            kpcItem={addedItem}
            initialMode={Mode.EDIT}
            onLeaveEditMode={() => {
              setDraftKpcItems((prev) => prev.filter((item) => item.id !== addedItem.id));
            }}
          />
        ))}
        {kpcItems.map((kpcItem) => (
          <KpcItemComponent
            key={kpcItem.id}
            kpcItem={kpcItem}
            onLeaveEditMode={() => setEditItemsCount((prev) => Math.max(0, prev - 1))}
            onEnterEditMode={() => setEditItemsCount((prev) => prev + 1)}
          />
        ))}
      </x.div>
    </x.div>
  );
};

const KpcItemComponent = ({
  kpcItem,
  initialMode = Mode.VIEW,
  onLeaveEditMode = noop,
  onEnterEditMode = noop,
}: {
  kpcItem: KpcItem;
  initialMode?: Mode;
  onLeaveEditMode?: () => void;
  onEnterEditMode?: () => void;
}) => {
  const [saveAttempted, setSaveAttempted] = useState(false);
  const { spacing } = useThemeTokens();
  const { borderedBox } = useStyles();
  const {
    saveModuleChanges,
    selectedModule,
    selectedRevisionIdx,
    saveInProgress,
    synthesisLogHit,
    revision,
    thirdPartyDocuments,
  } = useProjectSynthesisContext();
  const { updateKpcItem, deleteKpcItem } = useEditKpcOperations({ originalModule: selectedModule });

  const isNameInvalid = useCallback((kpc: KpcItem) => kpc.name.trim().length === 0, []);

  const onSaveChanges = useCallback(
    (kpcItemToSave: KpcItem) => {
      if (isNameInvalid(kpcItemToSave)) {
        setSaveAttempted(true);
        return Promise.reject();
      }
      const updatedModule = updateKpcItem(kpcItemToSave, selectedRevisionIdx);
      return updatedModule
        ? saveModuleChanges(updatedModule, { editedItem: `${kpcItemToSave.name}` })
        : Promise.resolve();
    },
    [isNameInvalid, saveModuleChanges, selectedRevisionIdx, updateKpcItem]
  );

  const { mode, actualItem, enterEditMode, onEditItem, onCancelEdit, onSaveEdit } = useEditItem({
    item: kpcItem,
    initialMode,
    onSaveChanges,
    onLeaveEditMode,
    onEnterEditMode,
  });

  const onDeleteKpcItem = useCallback(() => {
    const updatedModule = deleteKpcItem(kpcItem, selectedRevisionIdx);
    updatedModule &&
      saveModuleChanges(updatedModule, { deletedItem: `${kpcItem.name}` }, true, `${kpcItem.name} deleted.`);
    onLeaveEditMode();
  }, [deleteKpcItem, kpcItem, onLeaveEditMode, saveModuleChanges, selectedRevisionIdx]);

  const onEditQuote = useCallback(
    (updatedQuote: SynthesisQuote) => {
      onEditItem({
        ...actualItem,
        quotes: actualItem.quotes.map((q) => (q.id === updatedQuote.id ? updatedQuote : q)),
      });
    },
    [actualItem, onEditItem]
  );

  const onDeleteQuote = useCallback(
    (quote: SynthesisQuote) => {
      onEditItem({ ...actualItem, quotes: actualItem.quotes.filter((q) => q.id !== quote.id) });
    },
    [actualItem, onEditItem]
  );

  const onNavigateToTranscript = (quote: SynthesisQuote) => {
    synthesisLogHit({
      action: HitAction.projectSynthesisViewInTranscript,
      details: {
        revision: revision?.revision,
        quote: quote.text,
        moduleType: selectedModule?.contentType,
      },
      references: {
        moduleId: selectedModule?.id,
      },
    });
  };

  const showError = useMemo(() => saveAttempted && mode === Mode.EDIT && isNameInvalid(actualItem), [
    actualItem,
    isNameInvalid,
    mode,
    saveAttempted,
  ]);

  if (revision?.status === "PROCESSING") {
    return <ProcessingModuleContent />;
  }

  if (revision?.status === "FAILED") {
    return <RegenerationFailedToast module={selectedModule!} />;
  }

  return (
    <x.div id="kpc-item-block" key={actualItem.id} {...borderedBox} data-testid={`kpc-item-${actualItem.id}`}>
      <KpcItemTitle
        kpcItem={actualItem}
        mode={mode}
        onEditItem={onEditItem}
        enterEditMode={enterEditMode}
        showError={showError}
        saveInProgress={saveInProgress}
        onDeleteKpcItem={onDeleteKpcItem}
      />
      <KpcItemSummary kpcItem={actualItem} mode={mode} onEditItem={onEditItem} />
      {!actualItem.id && mode !== Mode.EDIT && (
        <x.div display="flex" flexDirection="column" gap={spacing.inner.base03}>
          <Skeleton variant="noMargin" />
          <Skeleton variant="noMargin" />
        </x.div>
      )}
      {!!actualItem.id &&
        actualItem.quotes.map((quote) => (
          <ExpertQuote
            key={quote.id}
            quote={quote}
            mode={mode}
            onEditQuote={(updatedQuote) => onEditQuote(updatedQuote)}
            onDeleteQuote={() => onDeleteQuote(quote)}
            onNavigateToTranscript={() => onNavigateToTranscript(quote)}
            saveInProgress={saveInProgress}
            showCompanyInfo={true}
            thirdPartyDocuments={thirdPartyDocuments}
          />
        ))}
      {mode === Mode.EDIT && (
        <EditStructuredModuleItemCTAs
          onCancelEdit={onCancelEdit}
          onSaveEdit={onSaveEdit}
          saveDisabled={saveInProgress || showError}
        />
      )}
    </x.div>
  );
};

const KpcItemTitle = ({
  kpcItem,
  mode,
  onEditItem,
  enterEditMode,
  showError,
  saveInProgress,
  onDeleteKpcItem,
}: {
  kpcItem: KpcItem;
  mode: Mode;
  onEditItem: (updatedItem: KpcItem) => void;
  enterEditMode: () => void;
  showError: boolean;
  saveInProgress: boolean;
  onDeleteKpcItem: () => void;
}) => {
  const user = useCurrentUser();
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isEnabled = !isFeatureDisabled(ProjectFeature.ProjectSynthesis);

  const onEditKpcItemName = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onEditItem({ ...kpcItem, name: e.target.value });
  };

  if (mode === Mode.EDIT && user?.enableAiInteractivity) {
    return (
      <x.div display="flex" gap="8px" data-testid={`kpc-title-edit-${kpcItem.id}`} alignItems={"center"}>
        <EditableInput
          input={kpcItem.name}
          onChange={onEditKpcItemName}
          errorMessage={showError ? "Title is required" : undefined}
          placeholder="KPC title here..."
        />
        {isEnabled && (
          <x.div mt="8px">
            <DeleteItemWithConfirmationBtn
              onDeleteItem={onDeleteKpcItem}
              saveInProgress={saveInProgress}
              tooltipText="Delete KPC"
              testId={`delete-kpc-${kpcItem.id}`}
            />
          </x.div>
        )}
      </x.div>
    );
  }

  return (
    <x.div display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography variant="body-em">{kpcItem.name}</Typography>
      <MoreActions enterEditMode={enterEditMode} onDelete={onDeleteKpcItem} />
    </x.div>
  );
};

const KpcItemSummary = ({
  kpcItem,
  mode,
  onEditItem,
}: {
  kpcItem: KpcItem;
  mode: Mode;
  onEditItem: (updatedItem: KpcItem) => void;
}) => {
  const onEditKpcItemSummary = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onEditItem({ ...kpcItem, summary: e.target.value });
  };

  if (mode === Mode.EDIT) {
    return (
      <EditableInput
        input={kpcItem.summary ?? ""}
        onChange={onEditKpcItemSummary}
        placeholder="KPC description here..."
      />
    );
  }

  return <Typography>{kpcItem.summary ?? ""}</Typography>;
};

const AddKpcButton = ({ onKpcItemAdded }: { onKpcItemAdded: (kpcItem: KpcItem) => void }) => {
  const user = useCurrentUser();
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isEnabled = !isFeatureDisabled(ProjectFeature.ProjectSynthesis);

  if (!user?.enableAiInteractivity) return null;

  const onAddKpcItem = () => {
    const newKpcItem: KpcItem = {
      id: uuid(),
      name: "",
      quotes: [],
      sentiments: [],
      isPinned: true,
      isPending: true,
    };
    onKpcItemAdded(newKpcItem);
  };

  return (
    <x.div display="flex" justifyContent="center">
      <Button
        disabled={!isEnabled}
        variant="outline"
        onClick={onAddKpcItem}
        w="100%"
        dataAttributes={{ "data-testid": "add-kpc-button" }}
      >
        + Add KPC
      </Button>
    </x.div>
  );
};

const ProcessingModuleContent = () => {
  return (
    <>
      <x.div display="flex" flexDirection="column" gap="16px" p="24px" data-testid="processing-module">
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </x.div>
    </>
  );
};
