import { ForwardedRef, forwardRef, useRef } from "react";
import { FormattedDateTime } from "providers/TimezoneProvider";
import {
  Avatar,
  AvatarProps,
  ComponentSize,
  Tooltip,
  Typography,
  TypographyProps,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { TooltipedButton } from "views/DeliverablesView/MainContentViews";
import { x } from "@xstyled/styled-components";

export type PitchedDetailsProps = {
  hasTooltip?: boolean;
  hasDescription?: boolean;
  pitchedAt: string;
  pitchedBy?: PtoUser;
  stylingProps?: Record<string, string>;
};

export const PITCHED_BY_TEXT = "Proposed by";

export const DATA_TEST_IDS = {
  mobileTooltip: "mobile-avatar-with-tooltip",
  desktopTooltip: "desktop-avatar-with-tooltip",
  avatar: "avatar",
};

const AvatarWithTooltip = ({ lead, ...props }: { lead: PtoUser }) => {
  const { isMobile } = useCheckScreen();

  const propsOverride = isMobile
    ? { size: "x-small" as ComponentSize, dataTestId: DATA_TEST_IDS.mobileTooltip }
    : { dataTestId: DATA_TEST_IDS.desktopTooltip };

  const avatar = <ProjectLeadAvatar {...props} lead={lead} {...propsOverride} />;

  return isMobile ? (
    <TooltipedButton tooltip={lead.name} icon={avatar}></TooltipedButton>
  ) : (
    <Tooltip title={lead.name}>{avatar}</Tooltip>
  );
};

const typographyProps: TypographyProps = { variant: "body-small", color: "secondary" };

const PitchedDetails = ({
  hasTooltip = false,
  hasDescription = false,
  pitchedAt,
  pitchedBy,
  stylingProps = {},
}: PitchedDetailsProps) => {
  const { spacing } = useThemeTokens();
  const ref = useRef(null);

  const Avatar = hasTooltip ? AvatarWithTooltip : ProjectLeadAvatar;

  return (
    <x.div display="flex" alignItems="center">
      {hasDescription && <Typography {...typographyProps}>{PITCHED_BY_TEXT}</Typography>}
      {pitchedBy && (
        <Avatar
          size="small"
          ref={ref}
          stylingProps={{
            cursor: "pointer",
            marginRight: spacing.inner.base,
            minWidth: spacing.inner.base06,
            ...stylingProps,
          }}
          lead={pitchedBy}
          dataTestId={DATA_TEST_IDS.avatar}
        />
      )}
      <Typography {...typographyProps}>
        <FormattedDateTime date={pitchedAt} format="d LLL yyyy" />
      </Typography>
    </x.div>
  );
};

interface ProjectLeadAvatarProps {
  lead: UserData | PtoUser;
  size?: AvatarProps["size"];
  stylingProps?: Record<string, string>;
  dataTestId?: string;
}

const DEFAULT_THUMB = "default_thumb.png";

const ProjectLeadAvatar = forwardRef(
  ({ lead, size = "medium", stylingProps, dataTestId }: ProjectLeadAvatarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const hasThumb = !!lead.avatar?.thumbUrl && !lead.avatar.thumbUrl?.match(DEFAULT_THUMB);

    const additionalProps = hasThumb ? { src: lead.avatar!.thumbUrl } : { text: lead.name };
    return <Avatar size={size} ref={ref} data-testid={dataTestId} {...stylingProps} {...additionalProps} />;
  }
);

export default PitchedDetails;
