import { Button, useAlphaToast } from "@alphasights/alphadesign-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { SynthesisModule } from "@alphasights/portal-api-client";

export const RegenerationFailedToast = ({ module }: { module: SynthesisModule }) => {
  const { prevRevision, reprocess } = useProjectSynthesisContext();
  const { toast } = useAlphaToast();
  toast.error({
    message: "Regeneration failed.",
    customAction: (
      <Button variant="ghost-inverse" onClick={() => reprocess({ moduleId: module.id })}>
        Try Again
      </Button>
    ),
  });
  prevRevision();
  return null;
};
