import { INTERVIEWER_SPEAKER } from "./constants";

const getSpeakerName = (speaker: string, expertCompany?: string, expertPosition?: string) => {
  if (speaker === INTERVIEWER_SPEAKER) {
    return INTERVIEWER_SPEAKER;
  }
  if (expertCompany && expertPosition) {
    return `${expertCompany} - ${expertPosition}`;
  }

  return speaker;
};

export { getSpeakerName };
