import { useThemeTokens, XStyledProps } from "@alphasights/alphadesign-components";
import FeaturedCompanyPill from "components/FeaturedCompanyPill";

type CompetitorPillProps = {
  iconUrl: string;
  label: string;
  isSelected?: boolean;
  isGrayscale?: boolean;
  isLeading?: boolean;
  nameMaxLength?: number;
  isHighlighted?: boolean;
  onClick?: () => void;
} & XStyledProps;

const CompetitorPill = ({
  iconUrl,
  label,
  isSelected = false,
  isGrayscale = false,
  nameMaxLength = Number.MAX_VALUE,
  isHighlighted = false,
  onClick,
  ...props
}: CompetitorPillProps) => {
  const { spacing } = useThemeTokens();

  return (
    <FeaturedCompanyPill
      size="large"
      dataTestId={`leadingCompetitor${label}`}
      iconUrl={iconUrl}
      label={label}
      padding={spacing.inner.base04}
      isSelected={isSelected}
      isGrayscale={isGrayscale}
      onClick={onClick}
      nameMaxLength={nameMaxLength}
      isHighlighted={isHighlighted}
      {...props}
    />
  );
};

export default CompetitorPill;
