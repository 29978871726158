import { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { SelectMenu, SelectOption, Link, ComponentSize } from "@alphasights/alphadesign-components";
import { Label } from "components/Label";

export const MessengerExpertSelect = ({
  helperText = "",
  error = null,
  disabled = false,
  required = false,
  items = [] as { key: string; labelElement: JSX.Element; label: string; disabled?: boolean; hideSelected?: boolean }[],
  value = undefined as string[] | undefined,
  onChange = (selectedValues?: string[]) => {},
  externalLinkLabel = "",
  onClickExternalLink = () => {},
  open = false,
  size = "medium" as ComponentSize,
}) => {
  const [selectedItems, setSelectedItems] = useState(value);

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  useEffect(() => {
    const filteredValues = value?.filter((v) => items.some((item) => item.key === v && !item.disabled));

    if (filteredValues?.length !== value?.length) {
      onChange(filteredValues);
    }

    setSelectedItems(filteredValues);
    // eslint-disable-next-line
  }, [value]);

  return (
    <x.div data-testid="multiple-select">
      <SelectMenu
        input
        allowMultiple
        error={error ?? undefined}
        disabled={disabled}
        value={selectedItems}
        placeholder={"Select experts..."}
        noResultsMessage={"No experts found"}
        dataAttributes={{ "data-testid": "select" }}
        label={
          <x.div {...labelStyles}>
            <Label text={"Expert(s)"} required={required} />
            {externalLinkLabel && <Link onClick={onClickExternalLink}>{externalLinkLabel}</Link>}
          </x.div>
        }
        onChange={(selectedValues) =>
          onChange(Array.isArray(selectedValues) ? (selectedValues as string[]) : [selectedValues as string])
        }
        helperText={helperText}
        open={open}
        size={size}
      >
        {items.map((item) => (
          <SelectOption
            disabled={item.disabled}
            key={item.key}
            value={item.key}
            label={item.label}
            className="option"
            listOptionAccessories={{ type: "text", inputType: "checkbox" }}
            data-testid="select-option"
          >
            {item.labelElement}
          </SelectOption>
        ))}
      </SelectMenu>
    </x.div>
  );
};
