import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import CompetitorPill from "../../Overview/CompetitorPill";

interface CompetitorDynamicsTabContentProps {
  segments: CompetitorDynamicsEntry[];
}

const CompetitorDynamicsTabContent = ({ segments }: CompetitorDynamicsTabContentProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div py={inner.base04} display="flex" flexDirection="row" gap="20px" flexWrap="wrap">
      {segments.map(({ companyName, logoLink }) => (
        <CompetitorPill iconUrl={logoLink} label={companyName} />
      ))}
    </x.div>
  );
};

export default CompetitorDynamicsTabContent;
