import { useCallback } from "react";
import * as React from "react";
import { Button, Popover, Typography, Option, ButtonProps } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import styled, { x } from "@xstyled/styled-components";
import { useDropdownButtonStyles } from "./DropdownButton.styles";
import { withLoginWall } from "components/LoginWall/LoginWall";

const DropdownButtonPopover = styled(Popover)`
  & > div {
    width: 100%;
  }
`;

export const OptionWithLoginWall = withLoginWall(Option);

type DropdownButtonProps = {
  popoverPlacement?: "bottom-start" | "bottom-end";
  label: string;
  children?: React.ReactNode;
  options?: { action: (event: React.MouseEvent) => void; label: string; requireLogin?: boolean }[];
  popoverContent?: React.ReactNode;
  size?: "small" | "medium";
  optionsSize?: "small" | "medium";
} & ButtonProps;

export const DropdownButton = ({
  popoverPlacement = "bottom-start",
  label,
  children = undefined,
  options = undefined,
  popoverContent = undefined,
  size = "medium",
  optionsSize = undefined,
  ...props
}: DropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>(undefined);
  const [buttonWidth, setButtonWidth] = React.useState<number | undefined>(undefined);
  const ref = React.useRef(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const { buttonMainStyle, popoverStyle, buttonIconStyle } = useDropdownButtonStyles({
    size,
    open,
    variant: props.variant,
  });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    if (!anchorEl) {
      if (buttonRef.current) {
        setButtonWidth(buttonRef.current.getBoundingClientRect().width);
      }
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(undefined);
    }
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, action: (event: React.MouseEvent) => void) => {
      event.stopPropagation();
      handleClose();
      action(event);
    },
    []
  );

  const renderOptions = useCallback(() => {
    return options?.map((option, index) => {
      const { action, label, requireLogin, ...optionProps } = option || {};
      const OptionComponent = requireLogin ? OptionWithLoginWall : Option;
      return (
        <OptionComponent
          key={`dropdown-button-item-${index}`}
          onClick={(event) => handleMenuItemClick(event, action)}
          label={label}
          size={optionsSize ?? size}
          {...optionProps}
        >
          {label}
        </OptionComponent>
      );
    });
  }, [handleMenuItemClick, options, optionsSize, size]);

  return (
    <>
      <Button
        endIcon={<x.div {...buttonIconStyle}>{open ? <ChevronUp /> : <ChevronDown />}</x.div>}
        onClick={handleClick}
        ref={buttonRef}
        size={size}
        {...buttonMainStyle}
        {...props}
      >
        <Typography variant={size === "small" ? "body-small-em" : "body-em"}>{label}</Typography>
      </Button>
      {open && (
        <DropdownButtonPopover
          className="dropdown-button"
          ref={ref}
          anchorEl={anchorEl}
          placement={popoverPlacement}
          open={open}
          onClose={handleClose}
          minWidth={buttonWidth}
          data-testid="dropdown-button-popover"
          {...popoverStyle}
        >
          <>
            {renderOptions()}
            {popoverContent}
            {children}
          </>
        </DropdownButtonPopover>
      )}
    </>
  );
};
