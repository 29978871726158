import { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";
import ReactDOMServer from "react-dom/server";
import { ChartLegend, ChartTooltip } from "pages/AlphaNowPage/primers/components";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

type StackedSingleBarChartProps = {
  chartColors: string[];
  ranks: CitableValue<number>[];
  seriesNames: string[];
  sectionName: string;
};

const StackedSingleBarChart = ({ chartColors, ranks, seriesNames, sectionName }: StackedSingleBarChartProps) => {
  const [selectedSerie, setSelectedSerie] = useState<number | null>(null);
  const [chartKey, setChartKey] = useState<number>(0);
  const { icons, spacing, shape, color, typography, font } = useThemeTokens();
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const isSidebarExpanded = useCustomerPrimersStore(({ isSidebarExpanded }) => isSidebarExpanded);
  const expertCount = usePrimersStore(({ expertCount }) => expertCount);
  const expertsMentionedSection = useMentionedExpertsStore(({ expertsMentionedSection }) => expertsMentionedSection);
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );

  const ranksValues = ranks.map((rank) => rank.value ?? 0);
  const maxValueIndex = ranksValues.indexOf(Math.max(...ranksValues));

  const { getChartColors } = useGetChartColors({ colors: chartColors });

  useEffect(() => {
    // we need this because, due to component memoization, the chart was
    // not resizing when the search bar collapsed at first load.
    //
    // to solve this we get the expanded status of the search bar and
    // when it changes we enforce a new key to the chart. We use a setTimeout
    // to defer this change to when the animation finishes + half a second,
    // to ensure the size is correctly calculated
    let timer = setTimeout(
      () => setChartKey((currentState) => currentState + 1),
      parseFloat(SIDEBAR_ANIMATION_SPEED.substring(0, SIDEBAR_ANIMATION_SPEED.length - 1)) * 1000 + 500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [isSidebarExpanded]);

  useEffect(() => {
    if (expertsMentionedSection !== sectionName && selectedSerie !== null) {
      setSelectedSerie(null);
    }
  }, [expertsMentionedSection, sectionName, selectedSerie]);

  // creates an array with numbers from 0 to n to assist the series creation
  const serieData = [
    ...[...Array(seriesNames.length).keys()].map((val) => ({ name: seriesNames[val], data: [ranksValues[val]] })),
  ];
  const options = {
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      stacked: true,
      fontFamily: font.family.text.regular,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      parentHeightOffset: 0,
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          const newSelectedSerie = config.seriesIndex;

          if (newSelectedSerie === selectedSerie) {
            resetExpertsMentioned();
            setSelectedSerie(null);
          } else {
            const speakersIds = ranks[newSelectedSerie].citedBy;

            setExpertsMentionedSectionAndExpertsIds(sectionName, speakersIds);
            setSelectedSerie(newSelectedSerie);
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: false,
        },
        barHeight: "36px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      tickAmount: expertCount / 2,
      max: expertCount,
      tickPlacement: "on",
      categories: [""],
      title: {
        text: "Customers",
        style: {
          fontWeight: typography.body.small.fontWeight,
          fontSize: typography.body.small.fontSize,
          color: color.text.strong._,
        },
        offsetY: 15,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 0,
        right: 0,
      },
    },
    yaxis: {
      max: expertCount,
      title: {
        text: undefined,
      },
    },
    tooltip: {
      custom: function ({
        series,
        seriesIndex,
        dataPointIndex,
      }: {
        series: any;
        seriesIndex: number;
        dataPointIndex: number;
      }) {
        // examples of possible tooltips
        // 9 experts responded somewhat difficult
        // 1 expert responsed somewhat difficult
        return ReactDOMServer.renderToString(
          <ChartTooltip>
            {serieData[seriesIndex].name} - {series[seriesIndex][dataPointIndex]} expert
            {series[seriesIndex][dataPointIndex] === 1 ? "" : "s"}
          </ChartTooltip>
        );
      },
    },
    colors: getChartColors(selectedSerie),
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
  };

  return (
    <x.div display="flex" mt={spacing.inner.base06}>
      <x.div flex="0 1 auto" minWidth="100px">
        <x.div {...typography.body.small}>Average response</x.div>
        <x.div
          borderRadius={shape.border.radius.small}
          padding={`${spacing.inner.base} ${spacing.inner.base02}`}
          backgroundColor={color.background.neutral.default}
          display="inline-flex"
          columnGap={spacing.inner.base02}
          alignItems="center"
          mt={spacing.inner.base05}
        >
          <x.div
            borderRadius="50%"
            w={icons.size.xs}
            h={icons.size.xs}
            display="inline-flex"
            backgroundColor={chartColors[maxValueIndex]}
          ></x.div>
          <x.div {...typography.body.small} color={color.text.strong._} display="inline-flex">
            {seriesNames[maxValueIndex]}
          </x.div>
        </x.div>
      </x.div>
      <x.div flex="1 1 auto">
        <Chart key={chartKey} options={options as ApexOptions} series={serieData} type="bar" height={150} />
        <ChartLegend
          elements={serieData.map(({ name }, index) => ({
            color: getChartColors(null)[index],
            label: name,
          }))}
        />
      </x.div>
    </x.div>
  );
};

export default StackedSingleBarChart;
