import { useState, useCallback, useEffect, useMemo } from "react";
import useTableStyles from "./tableStyles";
import { x } from "@xstyled/styled-components";
import { AlphaTable, useAlphaTable } from "@alphasights/alphadesign-table";
import { columnDefinitions } from "./surveysColumns/ColumnDefinitions";
import { tableOptions } from "./TableOptions";
import { buildSurveyData } from "./helpers";
import _ from "lodash";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { usePreference } from "../../hooks/usePreference";
import { Row } from "@tanstack/react-table";
import styled from "@xstyled/styled-components";
import { PreferenceType } from "providers/types";
import { ActionBar } from "views/AdvisorsTableView";
import { EmptySearchResults } from "components/InteractionsPage/EmptySearchResults";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useShowCid } from "pages/SurveysPage/useShowCid";

export interface SurveyMainContentProps {
  surveyType: SurveyType;
  selectedSurveyId: string;
  selectedInteractionId: string;
  interactions: Interaction[];
  isLoading: boolean;
  onSelectCard: (interaction: Interaction) => void;
  selectedExpertIds: string[];
  setSelectedExpertIds: any;
  onDownloadCid: () => void;
}
type SurveyRow = { interaction: Interaction; followUps: Interaction[] };
type AppliedFilters = { [key: string]: string[] };

export const SurveysMainContent = ({
  surveyType,
  selectedSurveyId,
  selectedInteractionId,
  interactions,
  isLoading,
  onSelectCard,
  selectedExpertIds,
  setSelectedExpertIds,
  onDownloadCid,
}: SurveyMainContentProps) => {
  const styles = useTableStyles();
  const showCid = useShowCid();
  const { project } = useCurrentProjectContext();
  const [hideHeader, setHideHeader] = useState(false);
  const {
    onFiltersChange,
    allInteractionsForProject,
    state: { appliedFilters },
  } = useProjectInteractionsContext() as {
    onFiltersChange: (newFilters: AppliedFilters, searchQuery?: string[]) => void;
    allInteractionsForProject: Interaction[];
    state: { appliedFilters: AppliedFilters };
  };

  const { preference: preferences, updatePreference, isLoading: isPrefLoading } = usePreference(
    PreferenceType.SURVEY_TABLE_COLUMNS,
    {
      token: project?.token,
    }
  );

  const doubleBlinded = surveyType === "double_blinded";

  const totalResults = useMemo(
    () =>
      buildSurveyData({
        interactions: allInteractionsForProject,
        selectedSurveyId,
        doubleBlinded,
      }).length,
    [allInteractionsForProject, selectedSurveyId, doubleBlinded]
  );

  const surveysData = useMemo(() => {
    return buildSurveyData({
      interactions,
      selectedSurveyId,
      doubleBlinded,
    });
  }, [interactions, selectedSurveyId, doubleBlinded]);

  const columns = useMemo(() => {
    const showCallFollowUps = surveysData.some((s) => s.followUps.length > 0 || s.interaction.state === "completed");
    return columnDefinitions({ showCallFollowUps, doubleBlinded, showCid });
  }, [doubleBlinded, surveysData, showCid]);

  const onRowClicked = useCallback(
    (row: Row<SurveyRow>) => {
      const {
        original: { interaction, followUps },
      } = row;
      const selected =
        followUps.length === 0
          ? interaction
          : followUps.find((it: Interaction) => it.previousAdvisorshipId && !it.followUpId);
      onSelectCard(selected!);
    },
    [onSelectCard]
  );

  const rowClassRules = [
    {
      className: "selected-interaction",
      ruleFunction: (row: Row<SurveyRow>) =>
        [row.original.interaction.id, ...(row.original.followUps || []).map((it: Interaction) => it.id)].indexOf(
          selectedInteractionId
        ) >= 0,
    },
  ];

  const dataIdxIdDoubleMap: { [idx: string]: string | number } = useMemo(
    () =>
      surveysData.reduce(
        (agg, cur, idx) => ({
          ...agg,
          [idx.toString()]: cur.interaction.advisorId,
          [cur.interaction.advisorId]: idx,
        }),
        {}
      ),
    [surveysData]
  );

  const setRowsSelected = useCallback(
    (fromTable: Map<number, boolean>) => {
      const selectedRowPositions = Object.keys(fromTable);
      const ids = selectedRowPositions.map((pos) => dataIdxIdDoubleMap[pos]);
      setSelectedExpertIds(ids);
      setHideHeader(selectedRowPositions.length > 0);
    },
    [dataIdxIdDoubleMap, setSelectedExpertIds]
  );

  const rowsSelected = useMemo(() => {
    const selectedIdxs = selectedExpertIds.map((id) => dataIdxIdDoubleMap[id]);
    return selectedIdxs.reduce((agg, cur) => ({ ...agg, [cur]: true }), {});
  }, [dataIdxIdDoubleMap, selectedExpertIds]);

  const onColumnPinningChange = (updatedColumnPinning: any) => {
    if (
      preferences &&
      Object.keys(updatedColumnPinning).length > 0 &&
      !_.isEqual(preferences?.attributes.columnPinning, updatedColumnPinning)
    ) {
      updatePreference({ columnPinning: updatedColumnPinning });
    }
  };

  const onColumnOrderChange = (updatedColumnOrders: any) => {
    if (preferences && JSON.stringify(preferences?.attributes.columnOrder) !== JSON.stringify(updatedColumnOrders)) {
      updatePreference({ columnOrder: updatedColumnOrders });
    }
  };

  const onColumnVisibilityChange = (updatedColumnVisibilities: any) => {
    if (
      updatedColumnVisibilities &&
      Object.keys(updatedColumnVisibilities).length > 0 &&
      !_.isEqual(preferences?.attributes.columnVisibility, updatedColumnVisibilities)
    ) {
      updatePreference({ columnVisibility: updatedColumnVisibilities });
    }
  };

  const options = tableOptions({
    isLoading,
    isPrefLoading,
    onRowClicked,
    onColumnPinningChange,
    onColumnOrderChange,
    onColumnVisibilityChange,
    rowsSelected,
    setRowsSelected,
    rowClassRules,
    showSelectColumn: project?.cidEnabled ?? false,
    hideHeader,
  });

  const table = useAlphaTable(surveysData, columns, options);

  useEffect(() => {
    if (preferences?.attributes.columnPinning) {
      table.setColumnPinning({ ...preferences.attributes.columnPinning });
    }
    if (preferences?.attributes.columnOrder) {
      table.setColumnOrder([...preferences.attributes.columnOrder]);
    }
    table.setColumnVisibility({ ...preferences?.attributes.columnVisibility });
  }, [preferences]); // eslint-disable-line react-hooks/exhaustive-deps

  return surveysData.length > 0 ? (
    <x.div data-testid="surveys-main-content" {...styles.wrapper}>
      <SelectableTableWrapper {...styles.surveysMainContent} data-testid="surveys-table">
        {hideHeader && (
          <ActionBar
            interactions={surveysData.map((s) => s.interaction)}
            selectedInteractions={surveysData
              .filter((s) => selectedExpertIds.includes(s.interaction.advisorId))
              .map((s) => s.interaction)}
            onDownloadCid={onDownloadCid}
            isActiveProject={project?.active ?? false}
            onSelectAll={() => table.toggleAllRowsSelected(true)}
            onUnselectAll={() => table.resetRowSelection(true)}
            isProjectCidEnabled={project?.cidEnabled ?? false}
            showLabelButton={false}
          />
        )}
        <AlphaTable table={table} />
      </SelectableTableWrapper>
    </x.div>
  ) : (
    <x.div display="flex" minH="100%" alignItems="center" justifyContent="center">
      <EmptySearchResults
        onResetAll={() => onFiltersChange({}, [])}
        hasAppliedFilter={Object.values(appliedFilters ?? []).some((it) => it.length > 0)}
        onResetTextFilter={() => onFiltersChange(appliedFilters, [])}
        totalResults={totalResults}
        projectLeadName={project?.lead.name}
      />
    </x.div>
  );
};

interface SelectableTableWrapperProps {
  selectedBackgroundColor: string;
  selectedBorderColor: string;
  backgroundColor: string;
}

const SelectableTableWrapper = styled.div<SelectableTableWrapperProps>`
  display: grid;
  tbody tr {
    background-color: ${(props) => props.backgroundColor};
    &.selected-interaction {
      background-color: ${(props) => props.selectedBackgroundColor};
      border-color: ${(props) => props.selectedBorderColor};
    }
    &:hover {
      background-color: ${(props) => props.selectedBackgroundColor};
      border-color: ${(props) => props.selectedBorderColor};
    }
  }
`;
