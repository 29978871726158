import { useState } from "react";
import {
  ActionsWrapper,
  AlertContainer,
  MessengerSuggestionWrapper,
  StyledMarkdownDisplay,
} from "./MessengerSuggestion.styles";
import { Alert, Button, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { MessengerSuggestionConfirmationModal } from "./MessengerSuggestionConfirmationModal/MessengerSuggestionConfirmationModal";
import { useIsInternalUser } from "@alphasights/portal-auth-react";

export type MessengerSuggestionProps = {
  content?: string;
  onDeclineSuggestion?: () => Promise<void>;
  onAcceptSuggestion?: () => Promise<void>;
};

export const MessengerSuggestion = ({ content, onDeclineSuggestion, onAcceptSuggestion }: MessengerSuggestionProps) => {
  const isInternalUser = useIsInternalUser();
  const [loading, setLoading] = useState(false);
  const [pressedButton, setPressedButton] = useState(undefined as "Decline" | "Accept" | undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<(() => Promise<void>) | undefined>(undefined);

  const handleSuggestionClick = (origin: "Decline" | "Accept", callback?: () => Promise<void>) => {
    if (loading || !callback) return;

    setPressedButton(origin);

    if (isInternalUser) {
      setIsConfirmationModalOpen(true);
      setConfirmationCallback(() => callback);

      return;
    }

    setLoading(true);

    callback().finally(() => {
      setLoading(false);
      setPressedButton(undefined);
    });
  };

  const handleConfirmationClick = () => {
    setLoading(true);

    confirmationCallback?.().finally(() => {
      setLoading(false);
      setPressedButton(undefined);
      setIsConfirmationModalOpen(false);
    });
  };

  return (
    <MessengerSuggestionWrapper>
      <Typography variant="body-em">AlphaSights Suggestion</Typography>

      {content && <StyledMarkdownDisplay markdown={content} />}

      <MessengerSuggestionConfirmationModal
        open={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
          setPressedButton(undefined);
        }}
        onConfirmAction={handleConfirmationClick}
        action={pressedButton}
        loading={loading}
      />

      <Alert variant="warning" size="small" w="100%">
        <AlertContainer>
          AlphaSights has reviewed your question and has suggested edits to ensure it is compliant. Please accept to
          send your message to the expert.
          <ActionsWrapper>
            <Tooltip title="Your AlphaSights contact will be in touch if this suggestion is declined">
              <Button
                onClick={() => handleSuggestionClick("Decline", onDeclineSuggestion)}
                size="small"
                variant="outline"
                loading={pressedButton === "Decline"}
                disabled={loading}
              >
                Decline
              </Button>
            </Tooltip>
            <Button
              onClick={() => handleSuggestionClick("Accept", onAcceptSuggestion)}
              size="small"
              variant="secondary"
              loading={pressedButton === "Accept"}
              disabled={loading}
            >
              Accept
            </Button>
          </ActionsWrapper>
        </AlertContainer>
      </Alert>
    </MessengerSuggestionWrapper>
  );
};
