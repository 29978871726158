import { memo, useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { DataVisualizationContainer, Header, Sidebar } from "./components";
import { Spinner } from "@alphasights/client-portal-shared";
import { useParams } from "react-router-dom";

const SurveysResultsEntry = memo(() => {
  const { id } = useParams<{ id: string }>();

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const setSurveyId = useSurveysResultsStore(({ setSurveyId }) => setSurveyId);
  const { isLoading, isFetched } = useSurveyMetadata(surveyId);

  useEffect(() => {
    if (!!id && surveyId !== id) {
      setSurveyId(id);
    }
  }, [id, surveyId, setSurveyId]);

  if (isLoading || !isFetched) {
    return (
      <x.div display="flex" justifyContent="center" alignItems="center" h="inherit">
        <Spinner size="medium" />
      </x.div>
    );
  }

  return (
    <x.div display="flex" data-testid="surveys-results-entry-view-page" h="100%">
      <x.div
        display="flex"
        flexDirection="column"
        flex="1 1 auto"
        minWidth="0"
        data-testid="surveys-results-entry-view-container"
      >
        <Header />
        <DataVisualizationContainer />
      </x.div>
      <Sidebar />
    </x.div>
  );
});

export default SurveysResultsEntry;
