import React from "react";
import styled from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const Container = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base02};
  `;
});

const ErrorMessage = styled(Typography).attrs({ variant: "body-small", component: "span" })(() => {
  const { color } = useThemeTokens();
  return `
    color: ${color.text.danger};
  `;
});

type ComponentWithErrorMessageProps = {
  children: React.ReactNode;
  errorMessage?: string;
};

const ComponentWithErrorMessage = ({ children, errorMessage }: ComponentWithErrorMessageProps) => (
  <Container>
    <>{children}</>
    {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </Container>
);

const withErrorMessage = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const { errorMessage } = props;
    return (
      <ComponentWithErrorMessage errorMessage={errorMessage}>
        <WrappedComponent {...props} />
      </ComponentWithErrorMessage>
    );
  };
};

export default withErrorMessage;
