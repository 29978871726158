import { useTheme } from "@alphasights/alphadesign-components";

const useInteractionDeliverablesStyles = ({ isActive }: { isActive: boolean }) => {
  const {
    tokens: { color },
  } = useTheme();

  return {
    sound: {
      ...(isActive
        ? {
            color: color.text.info,
            bg: color.background.info,
          }
        : {
            bg: {
              _: color.background.info,
              hover: color.background.secondary,
            },
          }),
    },
  };
};

export { useInteractionDeliverablesStyles };
