import { FC } from "react";
import styled, { x } from "@xstyled/styled-components";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { useThemeTokens } from "@alphasights/alphadesign-components";

import { DocumentRow } from "views/DeliverablesView/ThirdPartyAssignDocsModal/DocumentsTable/types";
import { isSuccessStatus } from "views/DeliverablesView/ThirdPartyDocUploadModal/utils";

import TruncatedTextCell from "./TruncatedTextCell";

const TitleCell: FC<AlphaTableTypes.CellContext<DocumentRow, any>> = ({ row: { original: selectedFile } }) => {
  const { color } = useThemeTokens();
  let textColor = color.text.strong._;
  if (!isSuccessStatus(selectedFile.status)) {
    textColor = color.text.disabled;
  }
  return (
    <CellWrapper>
      <TruncatedTextCell text={selectedFile.title} testId={`filename-${selectedFile.id}`} textColor={textColor} />
    </CellWrapper>
  );
};

const CellWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();
  return `
    padding-left: ${spacing.inner.base02}
  `;
});

export default TitleCell;
