import { useEffect, useState } from "react";
import { Select, Option, Icon, Avatar, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaDesignTheme, AlphaDesignThemeContextProvider } from "@alphasights/alphadesign-components";
import { FLAG_MAP } from "../../constants/Country/flags";
import { COUNTRY_CODES } from "../../constants/Country/index.js";
import { ChevronDown, Search } from "@alphasights/alphadesign-icons";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { x } from "@xstyled/styled-components";
import * as S from "./InputPhoneNumber.styled";

// moving usa and gb to the top of the list
export const COUNTRIES = COUNTRY_CODES.sort((a, b) => {
  const byOrder = a.order - b.order;
  if (byOrder === 0) {
    return a.name - b.name;
  } else {
    return byOrder;
  }
}).map((item, index) => {
  const flag = FLAG_MAP[item.isoCode];
  return {
    value: `${item.name}`,
    children: `${item.name} +${item.code}`,
    isoCode: item.isoCode,
    countryCode: item.code,
    example: item.example,
    index,
    flag,
  };
});

const options = COUNTRIES.map((country) => {
  return <Option key={country.index} {...country} img={country.flag} paddingLeft="12px" onMouseOver={() => {}} />;
});

export const InputPhoneNumber = ({
  value,
  onChange = () => {},
  showExtensionField = false,
  inputPhoneErrorMessage,
}) => {
  const { isMobile } = useCheckScreen();

  // allows passing only the phone number as initial value
  const phone = value && value.number ? value : { number: value };

  // separating country code from phone number
  const valueArray = phone && phone.number ? phone.number.trim().split(" ") : "";

  const countryCode = valueArray && valueArray.length > 1 ? valueArray[0].trim().replace("+", "") : null;

  if (valueArray) {
    valueArray.shift();
  }
  const { spacing } = useThemeTokens();
  const number = valueArray ? (valueArray.length === 1 ? valueArray[0] : valueArray.join(" ")) : "";

  const enteredCountry = COUNTRIES.find((country) => country.countryCode === countryCode);

  const [phoneNumber, setPhoneNumber] = useState(number);
  const [extNumber, setExtNumber] = useState(value ? value.extension : undefined);
  const [selectedOption, setSelectedOption] = useState(enteredCountry ? enteredCountry.value : COUNTRIES[0].value);
  const [selectedCountry, setSelectedCountry] = useState(enteredCountry ? enteredCountry : COUNTRIES[0]);
  const [isSearchMode, setIsSearchMode] = useState(false);

  const renderSeletedItem = (item) => {
    const country = COUNTRIES.find((country) => country.value === item.value);

    return (
      <x.div display="flex" alignItems="center">
        {country && country.flag && <Avatar src={country.flag} size="small" mr={spacing.layout.base} />}
        {country ? <div>{`${country.value} +${country.countryCode}`}</div> : <div>{item.value}</div>}
      </x.div>
    );
  };

  const selectCountry = (value) => {
    setSelectedOption(value);

    const country = COUNTRIES.find((country) => country.value === value);
    setSelectedCountry(country);
    setIsSearchMode(false);
  };

  useEffect(() => {
    // hiding the country lookup select by clicking outside the select
    const selectOnFocusOut = (e) => {
      const searchElements = ["countrySeachSelect", "countrySearchIcon"];
      if (!searchElements.includes(e.target.id) && isSearchMode) {
        setIsSearchMode(false);
        document.removeEventListener("click", selectOnFocusOut, false);
      }
    };

    if (isSearchMode) {
      const select = document.getElementById("countrySeachSelect");

      // opening the country list when presenting the country select using the click event
      select.click();
      document.addEventListener("click", selectOnFocusOut, false);
    } else {
      document.removeEventListener("click", selectOnFocusOut, false);
    }
  }, [isSearchMode]);

  useEffect(() => {
    if (!selectCountry || !phoneNumber) {
      onChange(undefined);
    } else {
      onChange({
        number: !selectCountry || !phoneNumber ? "" : `+${selectedCountry.countryCode} ${phoneNumber}`,
        extension: extNumber ? extNumber : "",
      });
    }
  }, [selectedCountry, phoneNumber, extNumber, onChange]);

  return (
    <AlphaDesignThemeContextProvider theme={AlphaDesignTheme.portal}>
      <x.div display="flex" flexDirection="column" w="100%" data-testid="input-phone-number-outer-wrapper">
        <x.div position="static" data-testid="input-phone-number-inner-wrapper">
          <x.div display="flex" data-testid="input-flex-wrapper">
            <S.PhoneInputWrapper hasError={!!inputPhoneErrorMessage} data-testid="phone-input" data-cy="phone-input">
              <S.CountrySelector data-testid="countrySelector">
                {selectedCountry?.flag && <Avatar src={selectedCountry.flag} size="small" mr="8px" />}
                <Typography data-testid="country-code">{`+${selectedCountry.countryCode}`}</Typography>
                <S.CountrySearchIconWrapper onClick={() => setIsSearchMode(!isSearchMode)}>
                  <Icon data-testid="countrySearchIcon">
                    <ChevronDown />
                  </Icon>
                </S.CountrySearchIconWrapper>
              </S.CountrySelector>
              <S.PhoneNumberInput
                data-testid="phone-number-input"
                value={phoneNumber}
                onChange={(event) => {
                  setPhoneNumber(event.currentTarget.value);
                }}
                placeholder={selectedCountry?.example}
              />
            </S.PhoneInputWrapper>
            {showExtensionField && (
              <S.ExtensionFieldWrapper data-testid="extension-field-wrapper">
                <S.ExtensionInput
                  data-testid="extension-input"
                  value={extNumber}
                  placeholder="Ext."
                  onChange={(e) => setExtNumber(e.currentTarget.value)}
                />
              </S.ExtensionFieldWrapper>
            )}

            {!isSearchMode && inputPhoneErrorMessage && <S.ErrorMessage>{inputPhoneErrorMessage}</S.ErrorMessage>}

            {isSearchMode && (
              <S.SearchModeWrapper isMobile={isMobile} data-testid="search-mode-wrapper">
                <Select
                  id="countrySeachSelect"
                  value={selectedOption}
                  onChange={selectCountry}
                  children={options}
                  allowMultiple={false}
                  input={true}
                  rightAccessory={
                    <Icon>
                      <Search />
                    </Icon>
                  }
                  allowRightAccessoryRotation={false}
                  renderSelectedValue={(value) => renderSeletedItem(value)}
                  zIndex={700}
                />
              </S.SearchModeWrapper>
            )}
          </x.div>
        </x.div>
      </x.div>
    </AlphaDesignThemeContextProvider>
  );
};
