import React from "react";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import FeaturedExpertCard from "pages/AlphaNowPage/components/FeaturedExpertCard";
import styled from "styled-components";
import { FeaturedExpertProps } from "pages/AlphaNowPage/components/FeaturedExpertCard/types";

const FeaturedExpertCards = ({
  speakers,
  open = true,
  requestExpertEnabled = false,
  contentId,
}: {
  speakers: FeaturedExpertProps[];
  open?: boolean;
  requestExpertEnabled?: boolean;
  contentId?: string;
}) => {
  return (
    <FeaturedExpertContainer>
      {speakers.map((speaker) => (
        <>
          <FeaturedExpertCard
            speaker={speaker}
            open={open}
            requestExpertEnabled={requestExpertEnabled}
            contentId={contentId}
          />
          <Divider />
        </>
      ))}
    </FeaturedExpertContainer>
  );
};

const FeaturedExpertContainer = styled.div(() => {
  const { color, shape, spacing } = useThemeTokens();
  return {
    borderWidth: shape.border.width.sm,
    borderRadius: shape.border.radius[8],
    borderColor: color.border.neutral.default,
    li: {
      borderWidth: "0 !important",
      "& > div:first-of-type": {
        paddingBlock: spacing.inner.base04,
      },
    },
    "hr:last-of-type": {
      display: "none",
    },
  };
});

export default FeaturedExpertCards;
