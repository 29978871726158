import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import SurveysResultsEntry from "./SurveysResultsEntry";
import SurveysResultsView from "./SurveysResultsView";

const SurveysResultsPage = memo(() => {
  // TODO [REDIS2-502]: Remove Surveys Results Badge
  const { hasUserBadge } = useUserBadgeContext();
  const hasSurveysResultsPageBadge = hasUserBadge(Badge.surveysResults);

  if (!hasSurveysResultsPageBadge) {
    return null;
  }

  return (
    <Routes>
      <Route path="" element={<SurveysResultsView />} />
      <Route path=":id/*" element={<SurveysResultsEntry />} />
    </Routes>
  );
});

export default SurveysResultsPage;
