import { useCallback } from "react";
import { Drawer, Icon, IconButton, Typography } from "@alphasights/alphadesign-components";
import { AttachmentResponse } from "types";
import { x } from "@xstyled/styled-components";
import { Attachment as AttachmentIcon, Download } from "@alphasights/alphadesign-icons";
import { useDownloadAttachmentsDrawerStyles } from "./DownloadAttachmentsDrawer.styles";
import { messageAttachmentService } from "services/messageAttachment";
import { useMessengerContext } from "providers/MessengerProvider";
import { useNotifications } from "@alphasights/client-portal-shared";
import { withAccessControl } from "components/AccessControl/AccessControl";

export interface DownloadAttachmentsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  attachments: AttachmentResponse[];
}

export const DownloadAttachmentsDrawer = ({ isOpen, onClose, attachments }: DownloadAttachmentsDrawerProps) => {
  const styles = useDownloadAttachmentsDrawerStyles();

  return (
    <Drawer open={isOpen} title="Download Attachments" onClose={onClose}>
      <x.div {...styles.drawerContentWrapper}>
        {attachments.map((attachment) => (
          <AttachmentItem attachment={attachment} key={attachment.id} />
        ))}
      </x.div>
    </Drawer>
  );
};

const AttachmentItem = ({ attachment }: { attachment: AttachmentResponse }) => {
  const { projectToken } = useMessengerContext();
  const styles = useDownloadAttachmentsDrawerStyles();
  const { showSuccessBanner, showErrorBanner } = useNotifications();

  const onClickDownload = useCallback(() => {
    messageAttachmentService
      .downloadMessageAttachment(projectToken, attachment)
      .then(() => showSuccessBanner("Attachment download successful"))
      .catch(() =>
        showErrorBanner("The attachment could not be downloaded. Please try again or contact your project lead")
      );
  }, [attachment, projectToken, showSuccessBanner, showErrorBanner]);

  return (
    <x.div {...styles.attachmentItemWrapper}>
      <x.div {...styles.attachmentInfo}>
        <Icon size="small" color="secondary">
          <AttachmentIcon />
        </Icon>
        <Typography variant="body-small">{attachment.filename}</Typography>
        <Typography variant="body-small" color="secondary">
          {attachment.type} • {attachment.fileSize}
        </Typography>
      </x.div>
      <IconButtonWithAccess
        accessControl={{ allowedPermissions: ["follow-up-messages"] }}
        variant="basic"
        onClick={onClickDownload}
        color="secondary"
        size="small"
        testId="download-attachment-button"
      >
        <Download />
      </IconButtonWithAccess>
    </x.div>
  );
};

const IconButtonWithAccess = withAccessControl(IconButton);
