import { Chat, AddComment } from "@alphasights/alphadesign-icons";
import { Icon, IconButton, Tooltip } from "@alphasights/alphadesign-components";
import { useCommentExpert } from "./hooks";
import * as S from "./CommentIcon.styled";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ProjectFeature } from "@alphasights/portal-api-client";

export const CommentIcon = ({ interaction }: { interaction: Interaction }) => {
  const { locked, hasNewMessages, onClick } = useCommentExpert(interaction);
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isDisabled = isFeatureDisabled(ProjectFeature.Comments) || locked;

  return (
    <S.CommentIconWrapper
      data-testid="comment-icon"
      onClick={isDisabled ? undefined : onClick}
      cursor={isDisabled ? "not-allowed" : "pointer"}
    >
      <Icon size="small" color={isDisabled ? "disabled" : "secondary"} badged={hasNewMessages}>
        {hasNewMessages ? <Chat /> : <AddComment />}
      </Icon>
    </S.CommentIconWrapper>
  );
};

export const NewCommentIcon = ({ interaction }: { interaction: Interaction }) => {
  const { locked, hasNewMessages, onClick } = useCommentExpert(interaction);
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isDisabled = isFeatureDisabled(ProjectFeature.Comments) || locked;

  if (!hasNewMessages) return null;

  return (
    <Tooltip title="New Comment" variant="dark" size="small">
      <S.NewCommentIconWrapper data-testid="new-comment-icon" onClick={isDisabled ? undefined : onClick}>
        <IconButton disabled={isDisabled} variant="ghost" onClick={onClick} color="info" size="small">
          <Chat />
        </IconButton>
      </S.NewCommentIconWrapper>
    </Tooltip>
  );
};
