import { parseISO } from "providers/TimezoneProvider";
import { ScheduledCallTime } from "../PopupRenderers/common";
import { Clock } from "@alphasights/alphadesign-icons";
import { Typography, Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const InteractionRequestReschedulingSection = ({ interaction, requestPending, onCancelPendingRequest }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const requestedTime = parseISO(requestPending.payload.requestedTime);

  return (
    <x.div data-testid="rescheduling-section">
      <x.div px={inner.base04} mt={inner.base02}>
        <Typography variant="body" color="secondary">
          New proposed time
        </Typography>
      </x.div>
      <ScheduledCallTime
        icon={<Clock />}
        date={requestedTime}
        duration={requestPending.payload.duration}
        addMarginTop={false}
      />
      {requestPending.reason && (
        <x.div px={inner.base04} mt={inner.base02}>
          <Typography variant="body">"{requestPending.reason}"</Typography>
        </x.div>
      )}
      <x.div px={inner.base04} mt={inner.base02}>
        <Typography variant="body-small" color="secondary">
          You have requested your AlphaSights project lead to reschedule this interaction.
        </Typography>

        <Button
          variant="outline"
          loading={interaction.runningAction === "requestChangeInteraction"}
          onClick={() => onCancelPendingRequest(requestPending)}
          data-testid="cancel-pending-request-button"
          w="100%"
          mt={inner.base04}
        >
          Retract Rescheduling Request
        </Button>
      </x.div>
    </x.div>
  );
};
