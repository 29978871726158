import { debounce } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState, useCallback } from "react";

const Audio = forwardRef(({ src, children, onEnded, onTimeUpdate, onLoadedMetadata, onError, onRateChange }, ref) => {
  const audioRef = useRef(null);

  const withAudio = (fn) => {
    if (audioRef.current) {
      return fn(audioRef.current);
    }
  };

  useEffect(() => {
    withAudio((audio) => {
      audio.addEventListener("ended", onEnded);
      audio.addEventListener("timeupdate", onTimeUpdate);
      audio.addEventListener("loadedmetadata", onLoadedMetadata);
      audio.addEventListener("error", onError);
      audio.addEventListener("ratechange", onRateChange);
    });

    return () => {
      withAudio((audio) => {
        audio.removeEventListener("ended", onEnded);
        audio.removeEventListener("timeupdate", onTimeUpdate);
        audio.removeEventListener("loadedmetadata", onLoadedMetadata);
        audio.removeEventListener("error", onError);
        audio.removeEventListener("ratechange", onRateChange);
      });
    };
  }, [onTimeUpdate, onEnded, onLoadedMetadata, onError, onRateChange]);

  // Exposed functions
  const seek = (val) => {
    return withAudio((audio) => {
      return new Promise((resolve) => {
        const onSeeked = () => {
          audio.removeEventListener("seeked", onSeeked);
          resolve();
        };

        audio.addEventListener("seeked", onSeeked);
        audio.currentTime = val;
      });
    });
  };

  const changeSpeed = (val) => {
    withAudio((audio) => (audio.playbackRate = val));
  };

  const play = () => {
    withAudio((audio) => audio.play());
  };

  const pause = () => {
    withAudio((audio) => audio.pause());
  };

  const onDragEnded = () => {
    withAudio((audio) => {
      audio.addEventListener("timeupdate", onTimeUpdate);
    });
  };

  const onDragWillStart = () => {
    withAudio((audio) => {
      audio.removeEventListener("timeupdate", onTimeUpdate);
    });
  };

  useImperativeHandle(ref, () => {
    return {
      seek: seek,
      changeSpeed: changeSpeed,
      play: play,
      pause: pause,
      onDragEnded: onDragEnded,
      onDragWillStart: onDragWillStart,
    };
  });

  return (
    <audio nocontrols="true" ref={audioRef} src={src} data-testid="use-audio-player-audio-element">
      {children}
    </audio>
  );
});

const Track = ({ onCueChange, onLoad, ...props }) => {
  const trackRef = useRef(null);
  useEffect(() => {
    const track = trackRef.current;

    if (track) {
      track.addEventListener("cuechange", onCueChange);
      track.addEventListener("load", onLoad);
    }

    return () => {
      if (track) {
        track.removeEventListener("cuechange", onCueChange);
        track.removeEventListener("load", onLoad);
      }
    };
  });

  return <track ref={trackRef} {...props} />;
};

export const parseCue = (cue) => {
  const cueParts = cue.text.match(/<v(.+?)>(\s.+)<\/v>/);

  const speaker = cueParts ? cueParts[1].trim() : null;
  const text = cueParts ? cueParts[2] : cue.text;

  return {
    id: cue.id,
    startTime: cue.startTime,
    endTime: cue.endTime,
    text: text,
    speaker: speaker,
  };
};

export const useAudioPlayer = ({ audioUrl, vttUrl, disabled }) => {
  const [activeCues, setActiveCues] = useState(null);
  const [transcriptSections, setTranscriptSections] = useState(null);
  const [draggingValue, setDraggingValue] = useState(null);

  const onCueChange = (event) => {
    setActiveCues(Object.values(event.target.track.activeCues).map(parseCue));
  };

  const onTrackLoad = (event) => {
    const parsedCues = Object.values(event.target.track.cues).map(parseCue);

    let transcriptSections = [];

    let speakerChange = null;
    let cues = [];

    parsedCues.forEach((cue) => {
      if (cue.speaker) {
        if (speakerChange && cues.length > 0) {
          transcriptSections.push({ speakerChange: speakerChange, cues: cues });
        }

        speakerChange = { speaker: cue.speaker, timestamp: cue.startTime };
        cues = [];
      }

      cues.push(cue);
    });

    transcriptSections.push({ speakerChange: speakerChange, cues: cues });
    setTranscriptSections(transcriptSections);
  };

  const audioRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [speed, setSpeed] = useState(1);

  const toggle = () => {
    if (!disabled) {
      const value = !playing;

      if (value) audioRef.current.play();
      else audioRef.current.pause();

      setPlaying(value);
    }
  };

  const onTimeUpdate = useCallback(
    (event) => {
      const { currentTime } = event.target;

      setCurrentTime(currentTime);
    },
    [setCurrentTime]
  );

  const onRateChange = useCallback(
    (event) => {
      const { playbackRate } = event.target;

      setSpeed(playbackRate);
    },
    [setSpeed]
  );

  const onEnded = useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  const onLoadedMetadata = useCallback(
    (event) => {
      setDuration(event.target.duration);
      setIsLoading(false);
    },
    [setDuration, setIsLoading]
  );

  const onError = useCallback(() => {
    setIsLoading(false);
    if (!disabled) setIsError(true);
  }, [setIsLoading, disabled, setIsError]);

  const withAudio = useCallback((fn) => {
    if (audioRef.current) {
      return fn(audioRef.current);
    }
  }, []);

  const seek = (val) => {
    if (!disabled) {
      return withAudio((audio) => {
        return audio.seek(val);
      });
    }
  };

  const transcriptProps = {
    setActiveCues, // use only if you are controlling readalong without the vtt file
    activeCues,
    currentTime,
    duration,
    transcriptSections: transcriptSections,
    movePlayerTo: (timestamp) => {
      withAudio((audio) => {
        setActiveCues(null);
        audio.seek(timestamp);
      });
    },
  };

  const controlsProps = {
    speed,
    playing,
    onChangeSpeed: (val) => {
      withAudio((audio) => audio.changeSpeed(val));
    },
    onClickForward: (amount) => () => seek(currentTime + amount),
    onClickRewind: (amount) => () => seek(currentTime - amount),
    onTogglePlay: toggle,
    onStopPlay: () => {
      setPlaying(false);
      audioRef.current && audioRef.current.pause();
    },
    onClickRefocus: () => {
      const active = document.querySelector("[data-transcript-active]");

      if (!active) return;

      active.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  };

  const eventPercentageInProgressBar = (ref, event) => {
    const clientX = event.clientX || event.changedTouches[0].clientX;
    const eventOffset = clientX - ref.getBoundingClientRect().left;
    const percent = eventOffset / ref.offsetWidth;

    return Math.max(0, Math.min(percent, 1)) * 100;
  };

  useEffect(() => {
    setIsLoading(true);
    setDuration(0);
    setPlaying(false);
    setCurrentTime(0);
  }, [audioUrl]);

  const onMouseDown = useCallback(
    (event) => {
      const ref = event.currentTarget;
      const userSelectPreviousValue = document.body.style.userSelect;

      withAudio((audio) => {
        audio.onDragWillStart();
      });

      document.body.style.userSelect = "none";

      const onMouseMove = (event) => {
        setDraggingValue((eventPercentageInProgressBar(ref, event) * duration) / 100);
      };

      const onMouseUp = (event) => {
        const newValue = (eventPercentageInProgressBar(ref, event) * duration) / 100;
        withAudio((audio) => {
          audio.onDragEnded();
        });
        seek(newValue).then(() => {
          setDraggingValue(null);

          document.body.style.userSelect = userSelectPreviousValue;
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);

          ref.removeEventListener("touchmove", onMouseMove);
          ref.removeEventListener("touchend", onMouseUp);
        });
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);

      ref.addEventListener("touchmove", onMouseMove);
      ref.addEventListener("touchend", onMouseUp);
    },
    [duration] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const progressBarProps = {
    duration,
    draggingValue,
    value: currentTime,
    onChange: seek,
    onMouseDown,
  };

  const playbackProps = {
    duration,
    currentTime,
  };

  return {
    isLoading,
    isError,
    transcriptProps,
    controlsProps,
    progressBarProps,
    playbackProps,
    renderElements: (
      <>
        <Audio
          key={audioUrl}
          ref={audioRef}
          src={disabled ? "" : audioUrl}
          onEnded={onEnded}
          onTimeUpdate={onTimeUpdate}
          onRateChange={onRateChange}
          onLoadedMetadata={onLoadedMetadata}
          onError={onError}
        >
          {vttUrl ? (
            <Track src={vttUrl} default onCueChange={onCueChange} onLoad={onTrackLoad} kind="subtitles" />
          ) : null}
        </Audio>
      </>
    ),
  };
};

/**
 * Wrapper around useAudioPlayer to automatically scroll the transcript
 * The scrollable div around the transcript needs to contain `data-transcript-scrollable-area` tag
 * to try to detect when sroll was done automatically or by the user, in this case,
 * disabling the auto scroll.
 * Each transcript cue needs to contain `data-transcript-id=X` tag.
 */
export const useAutoScroll = ({ audioPlayer, onNonAutomaticScrollEnded }) => {
  const autoScroll = useRef(true);
  const scrollingAutomatically = useRef(true);
  const cbRef = useRef();

  cbRef.current = onNonAutomaticScrollEnded;

  const controlsProps = audioPlayer.controlsProps;
  const progressBarProps = audioPlayer.progressBarProps;
  const transcriptProps = audioPlayer.transcriptProps;
  const activeCues = audioPlayer.transcriptProps.activeCues;

  useEffect(() => {
    if (!activeCues) return;
    if (activeCues.length === 0) return;
    if (!autoScroll.current) return;

    const transcript = document.querySelector(`[data-transcript-id='${activeCues[0].id}']`);

    if (!transcript) return;

    transcript.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    scrollingAutomatically.current = true;
  }, [activeCues]);

  useEffect(() => {
    const scrollableArea = document.querySelector("[data-transcript-scrollable-area]");

    if (!scrollableArea) return;

    const handleScrollingEnding = debounce(() => {
      if (autoScroll.current && scrollingAutomatically.current) {
        scrollingAutomatically.current = false;
        return;
      }

      autoScroll.current = false;
      cbRef.current && cbRef.current();
    }, 200);

    scrollableArea.addEventListener("scroll", handleScrollingEnding);

    return () => {
      scrollableArea.removeEventListener("scroll", handleScrollingEnding);
    };
  }, [audioPlayer.isLoading]);

  return {
    ...audioPlayer,
    controlsProps: {
      ...controlsProps,
      onTogglePlay: () => {
        if (!controlsProps.playing) autoScroll.current = true;

        controlsProps.onTogglePlay();
      },
    },
    progressBarProps: {
      ...progressBarProps,
      onMouseUp: (e) => {
        autoScroll.current = true;

        progressBarProps.onMouseUp(e);
      },
    },
    transcriptProps: {
      ...transcriptProps,
      autoScroll: autoScroll.current,
      movePlayerTo: (timestamp) => {
        autoScroll.current = true;

        transcriptProps.movePlayerTo(timestamp);
      },
    },
  };
};
