import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const TranscriptSearchContainer = styled.div`
  display: flex;
  flex: 1 0 0;
`;

export const TranscriptSearchBarContainer = styled.div`
  overflow: hidden;
  flex: 1 0 0;
`;

export const KeywordFilterContainer = styled(Typography).attrs({ component: "span", variant: "body-em" })(() => {
  const { color } = useThemeTokens();
  return {
    display: "flex",
    alignItems: "center",
    color: color.text.secondary,
  };
});

export const SearchKeywordContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "inherit",
    paddingLeft: spacing.inner.base03,
    gap: spacing.inner.base03,
  };
});

export const StyledResultsContainer = styled.div(() => {
  const { color, spacing } = useThemeTokens();
  return {
    color: color.text.secondary,
    paddingLeft: spacing.inner.base03,
  };
});

export const StyledNumResultsContainer = styled.span(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.danger,
  };
});

export const StyledNoResultsContainer = styled.span(() => {
  const { color, spacing } = useThemeTokens();
  return {
    color: color.text.danger,
    paddingLeft: spacing.inner.base04,
  };
});
