import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { currentProjectView } from "helpers/currentView";
import { useLocation } from "router-utils";

const NAVIGATION_TABLET_WIDTH = 1287;

const useIsTabletNavigationLayout = () => {
  const { isTablet } = useCheckScreen({ tabletWidth: NAVIGATION_TABLET_WIDTH });
  return isTablet;
};

const useRenderSearchBarInsideOverlay = () => {
  const { pathname } = useLocation();
  const currentView = currentProjectView(pathname);
  const isTabletNavigationLayout = useIsTabletNavigationLayout();
  const isProjectPage = !!(currentView || pathname.match("/projects/"));
  return isTabletNavigationLayout && isProjectPage;
};

export { useIsTabletNavigationLayout, useRenderSearchBarInsideOverlay };
