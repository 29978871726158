import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const Wrapper = styled(x.div)(() => {
  const { spacing, color } = useThemeTokens();
  return `
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    height: ${spacing.layout.base08};
    gap: ${spacing.inner.base06};
    padding: 0 ${spacing.inner.base06};
    background: ${color.background.inverse};
    border-bottom: 1px solid ${color.border.divider};
  `;
});

export const TopbarTitleLoading = () => {
  const { spacing } = useThemeTokens();

  return (
    <x.div w="70%" maxW="500px">
      <Skeleton variant="noMargin" width="100%" height={spacing.layout.base03} />
    </x.div>
  );
};

export const TopbarActionsLoading = () => {
  const { spacing } = useThemeTokens();

  return (
    <x.div w="30%" maxW="335px">
      <Skeleton variant="noMargin" width="100%" height={spacing.layout.base04} />
    </x.div>
  );
};

export const TopbarContent = styled(x.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TitleWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base02};
    flex-grow: 1;
  `;
});

export const ButtonsWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-shrink: 0;
    gap: ${spacing.inner.base02};
  `;
});
