import { useMemo } from "react";
import * as React from "react";
import { Icon, RoundedButton, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Color, Theme, x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ExpertBadge } from "views/MobileInteractionsPage/MobileExpertsList";
import styled from "styled-components";

type BadgeProps = {
  tooltipText?: string | React.ReactNode;
  value?: string;
  icon?: JSX.Element;
  backgroundColor?: string;
  characterLimit?: number;
  useFullWidth?: boolean;
  textColor?: string;
} & Partial<React.ComponentProps<typeof RoundedButton>>;

export const Badge = ({
  tooltipText: tooltipTextInput,
  value: valueInput,
  icon,
  backgroundColor = undefined,
  characterLimit = undefined,
  useFullWidth = false,
  textColor = undefined,
  ...props
}: BadgeProps) => {
  const { isMobile } = useCheckScreen();
  const {
    color: { border, text, background },
    spacing: { inner, layout },
  } = useThemeTokens();

  const componentColor = useMemo(() => backgroundColor ?? background.neutral.default, [
    backgroundColor,
    background.neutral.default,
  ]);
  const value = useMemo(
    () =>
      characterLimit && valueInput && valueInput.length > characterLimit
        ? valueInput.slice(0, characterLimit) + "..."
        : valueInput,
    [characterLimit, valueInput]
  );
  const tooltipText = useMemo(() => tooltipTextInput ?? valueInput, [tooltipTextInput, valueInput]);

  const shouldShowTooltip = tooltipText !== value;

  const badgeContent = (
    <x.span w={useFullWidth ? "100%" : undefined}>
      <StyledRoundedButtonWithCustomColor
        color={componentColor}
        borderColor={border.neutral.default}
        h={layout.base03}
        minWidth={"32px"}
        w={useFullWidth ? "100%" : undefined}
        paddingLeft={inner.base02}
        paddingRight={inner.base02}
        {...props}
      >
        <Typography
          variant={"body-small"}
          color={textColor || text.strong._}
          display="flex"
          alignItems="center"
          gap={inner.base}
        >
          {icon && <Icon size={"small"}>{icon}</Icon>}
          {value}
        </Typography>
      </StyledRoundedButtonWithCustomColor>
    </x.span>
  );

  return isMobile ? (
    <ExpertBadge text={value} icon={icon && <Icon size={"medium"}>{icon}</Icon>} isMobile={isMobile} />
  ) : shouldShowTooltip ? (
    <Tooltip title={tooltipText} position="bottom">
      {badgeContent}
    </Tooltip>
  ) : (
    badgeContent
  );
};

const StyledRoundedButtonWithCustomColor = styled(RoundedButton)(({ color }: { color: Color<Theme> }) => {
  return `
    background-color: ${String(color)};
  `;
});
