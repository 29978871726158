import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const Wrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    display: flex;
    flex-direction: column;
    gap:${spacing.inner.base04};
    flex-grow: 1;
    overflow-y: auto;
    padding: ${spacing.inner.base04} ${spacing.inner.base06};
  `;
});
