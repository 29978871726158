import { FC, useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Icon, Loading, Tooltip } from "@alphasights/alphadesign-components";
import { Upload } from "@alphasights/alphadesign-icons";

import { useQuery } from "query-utils";
import useModal from "hooks/useModal";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { EmailUploadRequestResponse } from "services/thirdPartyDocumentsService";
import { ThirdPartyDocument } from "types";
import ThirdPartyDocUploadModal, {
  FileObject,
  FileStatus,
  SelectedThirdPartyFileToUpload,
} from "views/DeliverablesView/ThirdPartyDocUploadModal";
import ThirdPartyAssignDocsModal from "views/DeliverablesView/ThirdPartyAssignDocsModal";
import { ADD_DOCUMENTS, TOOLTIP_TEXT, FILE_TYPES } from "./constants";
import { createFileName, readFile } from "./utils";

import * as S from "./AddDocumentsCard.styled";

const DataTestIds = {
  INPUT: "add-documents-input",
};

interface AddDocumentsCardProps {
  refetchDocuments: (documents: Partial<ThirdPartyDocument>[]) => void;
}

const AddDocumentsCard: FC<AddDocumentsCardProps> = ({ refetchDocuments }) => {
  const [selectedFiles, setSelectedFiles] = useState<SelectedThirdPartyFileToUpload[]>([]);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const { fetchUnassignedEmailUploads } = useDeliverableContext();
  const { isVisible: isUploadModalVisible, onOpen: onOpenUploadModal, onClose: onCloseUploadModal } = useModal();
  const {
    isVisible: isAssignDocumentsModalVisible,
    onOpen: onOpenAssignDocumentsModal,
    onClose: onCloseAssignDocumentsModal,
  } = useModal();

  const uploadInputRef = useRef<HTMLInputElement>(null);
  const lastFileId = useRef(-1);

  const { data: unassignedEmailUploads, isFetching: isFetchingUnassignedUploads } = useQuery(
    ["fetchUnassignedEmailUploads"],
    () => fetchUnassignedEmailUploads(),
    {
      select: (data) => data.map((doc: EmailUploadRequestResponse) => doc.document),
    }
  );

  const openFileExplorer = useCallback(() => {
    uploadInputRef.current?.click();
  }, [uploadInputRef]);

  const triggerOpenModal = useCallback(() => {
    if (unassignedEmailUploads?.length > 0) {
      onOpenAssignDocumentsModal();
    } else {
      openFileExplorer();
    }
  }, [unassignedEmailUploads, onOpenAssignDocumentsModal, openFileExplorer]);

  const onCardClick = useCallback(() => {
    if (isFetchingUnassignedUploads) {
      setShowLoadingSpinner(true);
    } else {
      triggerOpenModal();
    }
  }, [isFetchingUnassignedUploads, setShowLoadingSpinner, triggerOpenModal]);

  const handleFilesSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ?? [];
    const filesArray = Array.from(files);

    const newFiles = await Promise.all(
      filesArray.map(async (file) => {
        const { name } = file;
        let data = null;
        let status = FileStatus.processing;
        try {
          data = await readFile(file);
        } catch (error) {
          status = FileStatus.failed;
        }
        const res = { name, data, status, date: new Date() };
        return res;
      })
    ).then((results: FileObject[]) => {
      const selectedFileNames = selectedFiles.map((file) => file.name);
      return results.map((res) => {
        const { name, data, status, date } = res;
        const uniqueName = createFileName(selectedFileNames, name);
        selectedFileNames.push(uniqueName);
        return {
          name: uniqueName,
          title: uniqueName,
          data,
          status,
          date,
          id: ++lastFileId.current,
          experts: [],
        };
      });
    });
    setSelectedFiles((prevFiles) => [...newFiles, ...prevFiles]);
    if (isAssignDocumentsModalVisible) {
      onCloseAssignDocumentsModal();
    }
    onOpenUploadModal();
    uploadInputRef.current!.value = "";
  };

  const handleCloseUploadModal = () => {
    setSelectedFiles([]);
    onCloseUploadModal();
    lastFileId.current = 0;
  };

  useEffect(() => {
    if (!isFetchingUnassignedUploads && showLoadingSpinner) {
      setShowLoadingSpinner(false);
      triggerOpenModal();
    }
  }, [isFetchingUnassignedUploads, showLoadingSpinner, setShowLoadingSpinner, triggerOpenModal]);

  return (
    <>
      <Tooltip title={TOOLTIP_TEXT} size="small">
        <S.Wrapper onClick={onCardClick}>
          {showLoadingSpinner ? (
            <Loading size="sm" />
          ) : (
            <S.StyledTypography variant="body-small-em">
              <Icon size="small">
                <Upload />
              </Icon>
              {ADD_DOCUMENTS}
            </S.StyledTypography>
          )}
          <x.input
            data-testid={DataTestIds.INPUT}
            ref={uploadInputRef}
            type="file"
            multiple
            accept={FILE_TYPES.toString()}
            onChange={handleFilesSelected}
            hidden
          />
        </S.Wrapper>
      </Tooltip>
      {isUploadModalVisible && (
        <ThirdPartyDocUploadModal
          files={selectedFiles}
          setFiles={setSelectedFiles}
          selectFiles={openFileExplorer}
          onClose={handleCloseUploadModal}
          refetchDocuments={refetchDocuments}
        />
      )}
      {isAssignDocumentsModalVisible && (
        <ThirdPartyAssignDocsModal
          unassignedDocuments={unassignedEmailUploads}
          onClose={onCloseAssignDocumentsModal}
          onClickUpload={openFileExplorer}
        />
      )}
    </>
  );
};

export { AddDocumentsCard as default, DataTestIds };
