import { useEffect, useMemo, useState } from "react";
import { ProjectSynthesisProvider, useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { x } from "@xstyled/styled-components";
import { SynthesisModuleHeader } from "./SynthesisModuleHeader";
import { useStyles } from "./ProjectSynthesisView.styles";
import { SynthesisSidebarHeader } from "./SynthesisSidebarHeader";
import { SynthesisModuleContent } from "./SynthesisModuleContent";
import { SynthesisSidebarContent } from "./SynthesisSidebarContent";
import { Skeleton } from "@alphasights/alphadesign-components";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import useQueryParams from "hooks/useQueryParams";
import _ from "lodash";

const POLLING_INTERVAL = 30 * 1000;

export interface ProjectSynthesisViewProps {
  project: Project;
  pollingTimeout: number;
}

const countExpertsByAngleName = (interactions: Interaction[], angleName: string) => {
  return _.uniqBy(
    interactions.filter((i) => i.group.name === angleName),
    (i) => i.advisorId
  ).length;
};

const computeExpertCountByAngleName = (interactions: Interaction[]) => {
  const angles = _.uniqBy(
    interactions
      .map((i) => i.group)
      .map((g) => ({ angleName: g.parent?.name ?? g.name, subAngleName: g.parent ? g.name : null })),
    (el) => el.angleName + el.subAngleName
  );
  const groupedAngles = _.groupBy(angles, (a) => a.angleName);
  return _.values(groupedAngles).map((v) => {
    const angleName = v[0].angleName;
    return {
      name: angleName,
      count: countExpertsByAngleName(interactions, angleName),
      subAngles: v
        .filter((a) => a.subAngleName)
        .map((a) => ({
          name: a.subAngleName!,
          count: countExpertsByAngleName(interactions, a.subAngleName!),
        })),
    };
  });
};

const computeTranscriptIdsByAngleType = (interactions: Interaction[], angleType: string) => {
  const relevantInteractions = interactions.filter((i) => i.group.angleTypeName === angleType);
  return _.uniq(
    relevantInteractions.flatMap((interaction) =>
      interaction.recordings.flatMap((r) => r.transcriptRequests.map((tr) => tr.id))
    )
  );
};

export const ProjectSynthesisView = ({ project, pollingTimeout = POLLING_INTERVAL }: ProjectSynthesisViewProps) => {
  const { allInteractionsForProject, allInteractionsForProjectIsNull: loading } = useProjectInteractionsContext() as {
    allInteractionsForProject: Interaction[];
    allInteractionsForProjectIsNull: boolean;
  };
  const user = useCurrentUser();

  const angleTypeData = useMemo(() => {
    const interactionsWithTranscripts = allInteractionsForProject.filter(
      (i) =>
        (i.recordings ?? [])
          .flatMap((r: Recording) => r.transcriptRequests ?? [])
          .filter((tr) => !tr.purgedAt && tr.completed).length > 0
    );
    const angleTypes = _.compact(_.uniq(interactionsWithTranscripts.map((i) => i.group).map((g) => g.angleTypeName)));

    const map = angleTypes.map((angleType) => ({
      angleType,
      expertCountByAngle: computeExpertCountByAngleName(
        interactionsWithTranscripts.filter((i) => i.group.angleTypeName === angleType)
      ),
      transcriptIds: computeTranscriptIdsByAngleType(interactionsWithTranscripts, angleType),
    }));
    return map;
  }, [allInteractionsForProject]);

  if (loading) {
    return <LoadingBars />;
  }

  return (
    <ProjectSynthesisProvider project={project} angleTypes={angleTypeData} user={user} pollingTimeout={pollingTimeout}>
      <ProjectSynthesisViewNoCtx />
    </ProjectSynthesisProvider>
  );
};

const ProjectSynthesisViewNoCtx = () => {
  const styles = useStyles();
  const queryParams = useQueryParams();
  const { revision, selectedModule } = useProjectSynthesisContext();

  useEffect(
    function pushSelectedModuleToUrl() {
      if (!selectedModule) return;
      selectedModule.id
        ? queryParams.set("selectedQuestion", selectedModule?.id)
        : queryParams.delete("selectedQuestion");
      revision?.id ? queryParams.set("selectedRevision", revision?.revision) : queryParams.delete("selectedRevision");
    },
    [queryParams, revision, selectedModule]
  );

  useEffect(function saveScrollOnDestroy() {
    return () => {
      const scrollToSave = document.querySelector("[data-synthesis-scrollable-area]")?.scrollTop ?? 0;
      sessionStorage.setItem("synthesisPosition", scrollToSave.toString());
    };
  }, []);

  return (
    <x.div {...styles.viewWrapper}>
      <SynthesisSidebarWrapper />
      <SynthesisModuleWrapper />
    </x.div>
  );
};

const SynthesisSidebarWrapper = () => {
  const styles = useStyles();
  const [showSuggested, setShowSuggested] = useState(true);
  const { loading } = useProjectSynthesisContext();

  return (
    <x.div {...styles.sidebarWrapper}>
      {loading ? (
        <LoadingBars />
      ) : (
        <>
          <SynthesisSidebarHeader showSuggested={showSuggested} setShowSuggested={setShowSuggested} />
          <SynthesisSidebarContent showSuggested={showSuggested} />
        </>
      )}
    </x.div>
  );
};

const SynthesisModuleWrapper = () => {
  const styles = useStyles();

  const { loading } = useProjectSynthesisContext();

  return (
    <x.div {...styles.moduleWrapper}>
      {loading ? (
        <LoadingBars />
      ) : (
        <>
          <SynthesisModuleHeader />
          <SynthesisModuleContent />
        </>
      )}
    </x.div>
  );
};

const LoadingBars = () => {
  const styles = useStyles();
  return (
    <x.div {...styles.loadingBars}>
      {[...Array(10)].map((_, idx) => (
        <Skeleton key={idx} variant="noMargin" width="100%" height="50px" />
      ))}
    </x.div>
  );
};
