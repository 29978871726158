import { ReactElement } from "react";
import { x } from "@xstyled/styled-components";
import { Typography } from "@alphasights/alphadesign-components";
import { useStyles } from "./DeliverablesPage.styles";

export const GenericMessagePage = ({
  title,
  message,
  pre,
}: {
  title: String;
  message: String;
  pre?: ReactElement | undefined;
}) => {
  const styles = useStyles();
  return (
    <x.div {...styles.messagePage}>
      <x.div {...styles.messagePageTitle}>
        {pre}
        <Typography color="secondary" variant="body-em">
          {title}
        </Typography>
      </x.div>
      <Typography color="secondary" variant="body">
        {message}
      </Typography>
    </x.div>
  );
};
