import styled from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const OpenEditModalLink = styled(Typography)(() => {
  const { color } = useThemeTokens();
  return `
    cursor: pointer;
    color: ${color.text.info}
  `;
});

const DetailsWrapper = styled.div(() => {
  const { color } = useThemeTokens();
  return `
    display: flex;
    align-items: center;
    color: ${color.text.secondary};
  `;
});

export { OpenEditModalLink, DetailsWrapper };
