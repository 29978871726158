import { useCallback, useMemo } from "react";
import { x } from "@xstyled/styled-components";
import { Button, IconButton, SelectOption, Switch, Tooltip } from "@alphasights/alphadesign-components";
import { Download } from "@alphasights/alphadesign-icons";
import { useStyles } from "./SynthesisSidebarHeader.styles";
import { SynthesisReprocessButton } from "../SynthesisModuleHeader/SynthesisReprocessButton";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import _, { noop } from "lodash";
import { HitAction, SynthesisModule } from "@alphasights/portal-api-client";

type ModuleData = { label: string; hitAction: HitAction };

type HiddenTypes = Exclude<SynthesisModule["contentType"], "QUESTION" | "SUGGESTED_INQUIRY">;

const structuredModules: Record<HiddenTypes, ModuleData> = {
  VENDORS: {
    label: "Vendor List",
    hitAction: HitAction.projectSynthesisAddVendors,
  },
  KPC: {
    label: "KPC",
    hitAction: HitAction.projectSynthesisAddKpcs,
  },
  NPS: {
    label: "NPS",
    hitAction: HitAction.projectSynthesisAddVendors,
  },
  VENDOR_SWITCHING: {
    label: "Vendor Switching",
    hitAction: HitAction.projectSynthesisAddVendorSwitchng,
  },
};

export const SynthesisSidebarHeader = ({
  showSuggested = true,
  setShowSuggested,
}: {
  showSuggested: boolean;
  setShowSuggested: (showSuggested: boolean) => void;
}) => {
  const styles = useStyles();

  const user = useCurrentUser();
  const {
    projectSynthesis,
    setMode,
    onPromoteAllModules,
    modulesToPromote,
    downloadSynthesis,
    synthesisLogHit,
    nothingToShow,
    readOnly,
  } = useProjectSynthesisContext();

  const hasDownloadableModules = useMemo(() => {
    const downloadReadyModules = projectSynthesis
      .flatMap((angleSynthesis) => angleSynthesis.modules)
      .filter(
        (module) => module.visibility === "ADDED" && _.maxBy(module.revisions, "revision")?.status === "COMPLETED"
      );
    return downloadReadyModules.length > 0;
  }, [projectSynthesis]);

  const availableModules = useMemo(() => projectSynthesis.flatMap((angleSynthesis) => angleSynthesis.modules), [
    projectSynthesis,
  ]);

  const onNewModuleClick = () => {
    setMode(Mode.NEW);
    synthesisLogHit({ action: HitAction.projectSynthesisCreateModuleClicked });
  };

  const handleSuggestedToggle = () => {
    synthesisLogHit({ action: HitAction.projectSynthesisShowSuggested, details: { showSuggested: !showSuggested } });
    setShowSuggested(!showSuggested);
  };

  const handleDownload = () => {
    synthesisLogHit({ action: HitAction.projectSynthesisDownload });
    downloadSynthesis();
  };

  const handleAddAllModules = useCallback(() => {
    const ids = modulesToPromote.map((ps) => ps.id);
    onPromoteAllModules(ids).then(() => {
      synthesisLogHit({
        action: HitAction.projectSynthesisModulePromoted,
        references: { moduleIds: ids },
        details: { cta: "add-all" },
      });
    });
  }, [modulesToPromote, onPromoteAllModules, synthesisLogHit]);

  const handleAddHiddenModules = useCallback(
    (hiddenModules: SynthesisModule[], hitAction: HitAction) => {
      const hiddenModulesIds = hiddenModules.map((m) => m.id);
      onPromoteAllModules(hiddenModulesIds).then(() => {
        synthesisLogHit({
          action: hitAction,
          references: { moduleIds: hiddenModulesIds },
        });
      });
    },
    [onPromoteAllModules, synthesisLogHit]
  );

  const newModuleOptions = _.compact([
    {
      label: "Add new theme",
      onClick: onNewModuleClick,
      disabled: false,
    },
    modulesToPromote.length > 0 && {
      label: "Add all suggested themes",
      onClick: handleAddAllModules,
      disabled: false,
    },
    ..._.compact(
      Object.entries(structuredModules).map(([key, value]) => {
        const m = availableModules.find((module) => module.contentType === key);

        if (m?.visibility === "ADDED") {
          return {
            label: `${value.label} - added`,
            onClick: noop,
            disabled: true,
          };
        } else if (m?.visibility === "HIDDEN") {
          return {
            label: `Add ${value.label}`,
            onClick: () => handleAddHiddenModules([m!], value.hitAction),
            disabled: false,
          };
        }
        return undefined;
      })
    ),
  ]);

  const SplitOptions = (
    <>
      {newModuleOptions.map((option, index) => (
        <SelectOption key={index} onClick={option.onClick} disabled={option.disabled}>
          {option.label}
        </SelectOption>
      ))}
    </>
  );

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <x.div data-testid="synthesis-sidebar-header">
      {nothingToShow ? (
        <EmptySynthesisSidebarHeader />
      ) : (
        <x.div {...styles.headerWrapper} data-testid="syntesis-header-wrapper">
          <x.div {...styles.leftGroup}>
            {user?.enableAiInteractivity ? (
              <Button
                key={JSON.stringify(newModuleOptions)}
                variant="primary"
                splitPopoverContent={SplitOptions}
                splitPopoverProps={{ placement: "bottom-start" }}
                size="small"
                onClick={onNewModuleClick}
                dataAttributes={{ "data-testid": "new-module-btn" }}
                disabled={internalUserNoAccess || readOnly}
              >
                Add Theme
              </Button>
            ) : modulesToPromote.length > 0 ? (
              <Button
                variant="primary"
                size="small"
                onClick={handleAddAllModules}
                disabled={internalUserNoAccess || readOnly}
                dataAttributes={{ "data-testid": "add-all-suggested-btn" }}
              >
                Add all suggested themes
              </Button>
            ) : (
              <></>
            )}
            <SynthesisReprocessButton />

            <Tooltip variant="dark" title="Download added themes">
              <IconButton
                onClick={handleDownload}
                disabled={internalUserNoAccess || !hasDownloadableModules || readOnly}
                variant="outline"
                size="small"
                testId="download-synthesis"
              >
                <Download />
              </IconButton>
            </Tooltip>
          </x.div>

          <x.div {...styles.rightGroup}>
            <Switch
              leftText="Suggested"
              size="small"
              isSelected={showSuggested}
              onClick={handleSuggestedToggle}
              dataAttributes={{ "data-testid": "toggle-show-extracted" }}
            />
          </x.div>
        </x.div>
      )}
      <x.div {...styles.separator} />
    </x.div>
  );
};

export const EmptySynthesisSidebarHeader = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.headerWrapper} data-testid="empty-sidebar-header">
      <x.div {...styles.leftGroup}>
        <Button variant="primary" size="small" disabled dataAttributes={{ "data-testid": "new-module-btn" }}>
          Add new theme
        </Button>
        <SynthesisReprocessButton />
      </x.div>

      <x.div {...styles.rightGroup}></x.div>
    </x.div>
  );
};
