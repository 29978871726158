import { fetch as _fetch, FetchProps } from "hooks/useApi";
import { RequestQuestionsResponse, ThirdPartyDocument, ThirdPartyDocumentAttributes } from "types";
import { DocumentSummary } from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentSummaryTab/types";
import {
  DocumentQuestion,
  ProcessingStatus,
} from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentQuestionsTab/types";
const defaultOptions = {
  handleForbidden: true,
  skipAlert: true,
};

const fetch = (options: FetchProps) => _fetch({ ...defaultOptions, ...options });

type RequestUploadUrlResponse = {
  id: string;
  presignedUrl: string;
};

type TranscriptFragment = {
  sanitized_source: string;
  content: string;
  start: number | null;
  end: number | null;
  index: number;
};

type TranscriptResponse = {
  parts: TranscriptFragment[];
};

type EmailUploadRequestResponse = {
  id: string;
  clientContactId: number;
  status: string;
  document: ThirdPartyDocument;
};

const thirdPartyDocumentsService = {
  async fetchDocumentUploadUrl(projectToken: string, fileName: string): Promise<RequestUploadUrlResponse> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/document-upload-requests`,
      method: "POST",
      body: JSON.stringify({ fileName }),
    }).then((res) => res.json());
  },

  async uploadDocumentToS3(url: string, file: ArrayBuffer | string | null | undefined): Promise<Response> {
    return fetch({
      url,
      method: "PUT",
      body: file,
      headers: {},
    });
  },

  async createDocument(projectToken: string, documentUploadRequestId: string): Promise<ThirdPartyDocument> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents`,
      method: "POST",
      body: JSON.stringify({ documentUploadRequestId }),
    }).then((res) => res.json());
  },

  async addProjectIdToDocument(projectToken: string, documentId: string): Promise<void> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}`,
      method: "PUT",
      body: JSON.stringify({ documentId }),
    }).then((res) => res.json());
  },

  async updateDocumentAttributes(
    projectToken: string,
    documentId: string,
    attributes: Partial<ThirdPartyDocumentAttributes> = {}
  ) {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/attributes`,
      method: "PUT",
      body: JSON.stringify(attributes),
    }).then((res) => res.json());
  },

  async deleteDocument(projectToken: string, documentId: string): Promise<Response> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}`,
      method: "DELETE",
    });
  },

  async fetchDocument(projectToken: string, documentId: string): Promise<ThirdPartyDocument> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}`,
    }).then((res) => res.json());
  },

  async fetchDocuments(projectToken: string): Promise<ThirdPartyDocument[]> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents`,
    }).then((res) => res.json());
  },

  async fetchDocumentSummary(projectToken: string, documentId: string): Promise<DocumentSummary> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/summary`,
    }).then((res) => res.json());
  },

  async fetchDocumentSummaryStatus(projectToken: string, documentId: string): Promise<ProcessingStatus> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/summary/status`,
    }).then((res) => res.json());
  },

  async fetchDocumentQuestions(projectToken: string, documentId: string): Promise<DocumentQuestion[]> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/questions`,
    }).then((res) => res.json());
  },

  async fetchDocumentQuestionStatus(projectToken: string, documentId: string): Promise<ProcessingStatus> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/questions/status`,
    }).then((res) => res.json());
  },

  async submitDocumentQuestion(
    projectToken: string,
    documentId: string,
    question: string = ""
  ): Promise<RequestQuestionsResponse> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/questions`,
      method: "POST",
      body: JSON.stringify({ question }),
    }).then((res) => res.json());
  },

  async fetchFile(projectToken: string, documentId: string): Promise<Response> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/file`,
    });
  },

  async fetchFileAsPdf(projectToken: string, documentId: string): Promise<Response> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/pdf`,
    });
  },

  async fetchJson(projectToken: string, documentId: string): Promise<TranscriptResponse> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/json`,
    }).then((res) => res.json());
  },

  async searchDocuments(projectToken: string, keywords = []): Promise<ThirdPartyDocument[]> {
    const searchParams = new URLSearchParams();
    keywords.forEach((keyword) => searchParams.append("keywords", keyword));
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/search?${searchParams.toString()}`,
    }).then((res) => res.json());
  },

  async fetchUnassignedEmailUploads(projectToken: string): Promise<EmailUploadRequestResponse> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/email-upload-requests`,
    }).then((res) => res.json());
  },
};

export default thirdPartyDocumentsService;
export type { RequestUploadUrlResponse, TranscriptResponse, TranscriptFragment, EmailUploadRequestResponse };
