import { Avatar, Divider } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useInteractionDeliverablesContext } from "providers/InteractionDeliverableProvider";
import { useCallback, useMemo } from "react";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { useStyles } from "./MentionsTab.styles";
import { useStyles as deliverablesStyles } from "../DeliverablesPage.styles";
import { ArrowRight, Expert } from "@alphasights/alphadesign-icons";
import _ from "lodash";
import { FloatingActions, useHelperActions } from "components/FloatingActions";
import { HitOrigin, HitAction } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ViewType } from "views/DeliverablesView/AiSummaries/AiSummaries.types";
import { GenericMessagePage } from "../GenericMessagePage";
import { useHideDeliverablesContent } from "../useHideDeliverablesContent";

export const MentionsTab = () => {
  const { mentions } = useInteractionDeliverablesContext()!;
  const { contentStyle } = useHideDeliverablesContent();
  const deliverablesStyle = deliverablesStyles();

  if (!mentions || mentions?.length === 0) {
    return (
      <GenericMessagePage
        title={"Search for Mentions"}
        message={"Add a keyword to the search above to view all mentions of that keyword within this transcript."}
      />
    );
  }

  return (
    <x.div data-testid="mentions-tab" {...contentStyle} {...deliverablesStyle.outerContent}>
      <MentionsRender mentions={mentions}></MentionsRender>
    </x.div>
  );
};

const MentionsRender = ({ mentions }: { mentions: any[] }) => {
  const style = useStyles();
  const deliverablesStyle = deliverablesStyles();
  const { sentences, keywordSearchTargetEl, selectedView } = useInteractionDeliverablesContext()!;
  const orderedUniqueSpeakers = useMemo(() => _.uniq(_.map(sentences, "speaker")), [sentences]);
  const avatarColors = ["base", "base02", "base03", "base04", "base05", "base06"];

  return (
    <x.div
      data-testid={"rendered-mentions-tab"}
      {...deliverablesStyle.innerContent}
      ref={selectedView === ViewType.Mentions ? keywordSearchTargetEl : undefined}
    >
      {mentions?.map((mention, index) => {
        return (
          <>
            <x.div {...style.mentionsRender}>
              <Mention
                mention={mention.value}
                color={avatarColors[orderedUniqueSpeakers.indexOf(mention.speaker) % avatarColors.length]}
                position={mention.position}
                index={index}
              />
            </x.div>
            {index !== mentions.length - 1 && <Divider />}
          </>
        );
      })}
    </x.div>
  );
};

const Mention = ({
  mention,
  color,
  position,
  index,
}: {
  mention: string;
  color: any;
  position: number;
  index: number;
}) => {
  const style = useStyles();
  const { logHit } = useTrackUserAction();
  const { project } = useCurrentProjectContext();
  const { copyNoFormatTextAction } = useHelperActions();
  const { interaction, focusOnTranscriptIndex, setSelectedView } = useInteractionDeliverablesContext()!;

  const onCopy = useCallback(() => {
    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.transcriptMentionsCopy,
      advisorshipId: interaction.id,
      projectToken: project?.token,
    });
  }, [interaction, project, logHit]);
  const navTo = useCallback(
    (idx: number, linkType: string, text: string) => ({
      icon: <ArrowRight />,
      tooltip: "View in transcript",
      testid: "nav",
      onClick: () => {
        focusOnTranscriptIndex({ transcriptIx: idx, text });
        setSelectedView(ViewType.Transcript);
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptMentionsViewInTranscript,
          advisorshipId: interaction.id,
          projectToken: project?.token,
          details: { linkType },
        });
      },
    }),
    [interaction, project, focusOnTranscriptIndex, setSelectedView, logHit]
  );

  return (
    <x.div {...style.mentionsObject}>
      <FloatingActions
        data-testid="floating-actions-mention"
        actions={[
          copyNoFormatTextAction(mention, "Copy quote", onCopy),
          navTo(position, "answer", mention.replaceAll("<em>", "<em data-transcript-highlight>").trim()),
        ]}
      >
        <x.div {...style.mentionsTextWrapper}>
          <Avatar size="small" color={color}>
            <Expert />
          </Avatar>
          <x.div {...style.mentionsText}>
            <EmphasisTypography
              component={"span"}
              variant="body"
              data-testid={`mention-${index}`}
              id={`mention-${index}`}
            >
              {/* Temporary approach: removing BE highlights and using FE highlights only */}
              {`"${mention.replaceAll("<em>", "").replaceAll("</em>", "").trim()}"`}
            </EmphasisTypography>
          </x.div>
        </x.div>
      </FloatingActions>
    </x.div>
  );
};
