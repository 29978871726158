import {
  Button,
  Collapsible,
  IconButton,
  MobileTopBar,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useState } from "react";
import { RequestExpertsButton } from "../MobileContentCommons.component";
import { useMobileDeliverablesStyles } from "../MobileDeliverablesView.styles";

import { ArrowLeft, ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { groupAndSortExpertsByAngleTypeName } from "components/Experts/CategorizedByAngle";
import BulletPoints from "components/Experts/ExpertCard/RelevanceStatements/BulletPoints/BulletPoints";
import { CompanyPrimerContentFlexible } from "./MobilePrimerSections.component";

export const PrimerContributingExperts = ({
  onClose,
  primer,
  onSelectExpert,
  selectedExpert,
}: {
  onClose: () => void;
  primer: CompanyPrimerContentFlexible;
  onSelectExpert: (speaker: Speaker) => void;
  selectedExpert?: Speaker;
}) => {
  const { color, spacing } = useThemeTokens();
  const { fullScreenFixedWrapper, stickyAtTheTop, flexColumn } = useMobileDeliverablesStyles();
  const angleTypes = groupAndSortExpertsByAngleTypeName(primer.speakers);

  return (
    <x.div {...fullScreenFixedWrapper} {...flexColumn} data-testid="mobile-primer-expert-list-view">
      <x.div {...stickyAtTheTop}>
        <MobileTopBar
          title={primer.externalTitle}
          mainButton={
            <IconButton size="large" variant="ghost" onClick={onClose} testId="close-primer-list-view-btn">
              <ArrowLeft />
            </IconButton>
          }
        />
      </x.div>
      <x.div {...flexColumn} background={color.background.surface.recessed}>
        <x.div padding={spacing.inner.base04}>
          <Typography variant="body-em" color={color.text.secondary}>
            Contributing Experts
          </Typography>
          <Typography>Click on an expert to see their insights.</Typography>
        </x.div>
        {[...angleTypes.entries()].map(([angle, experts]) => (
          <ContributorAngle
            angleName={angle}
            speakers={experts}
            onSelectExpert={onSelectExpert}
            selectedExpert={selectedExpert}
          />
        ))}
      </x.div>
    </x.div>
  );
};

const ContributorAngle = ({
  angleName,
  speakers,
  onSelectExpert,
  selectedExpert,
}: {
  angleName: string;
  speakers: Speaker[];
  onSelectExpert: (speaker: Speaker) => void;
  selectedExpert?: Speaker;
}) => {
  const { color, spacing } = useThemeTokens();
  return (
    <x.div display={"flex"} flexDirection={"column"} background={color.background.surface.page.default}>
      <x.div
        borderBottom={`1px solid ${color.border.divider}`}
        background={color.background.surface.recessed}
        padding={`${spacing.inner.base03} ${spacing.inner.base04}`}
      >
        <Typography variant="body-small-em" color={color.text.secondary}>
          {angleName}
        </Typography>
      </x.div>
      {speakers.map((speaker) => (
        <x.div borderBottom={`1px solid ${color.border.divider}`}>
          <Contributor speaker={speaker} onSelectExpert={onSelectExpert} selectedExpert={selectedExpert} />
        </x.div>
      ))}
    </x.div>
  );
};

const Contributor = ({
  speaker,
  onSelectExpert,
  selectedExpert,
}: {
  speaker: Speaker;
  onSelectExpert: (speaker: Speaker) => void;
  selectedExpert?: Speaker;
}) => {
  const { color, spacing } = useThemeTokens();
  const [isOpen, setIsOpen] = useState(selectedExpert?.id === speaker.id);
  return (
    <x.div padding={`${spacing.inner.base03} ${spacing.inner.base04}`}>
      <x.div display={"flex"}>
        <x.div flexGrow={1} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
          <Typography variant="body-small-em">
            {speaker.company} - {speaker.jobTitle}
          </Typography>
          <Typography variant="body-small" color={color.text.secondary}>
            {speaker.jobDuration}
          </Typography>
        </x.div>
        <IconButton
          dataAttributes={{ "data-testid": `toggle-${speaker.id}` }}
          variant="ghost"
          size="small"
          onClick={() => setIsOpen((a) => !a)}
          children={isOpen ? <ChevronUp /> : <ChevronDown />}
        />
      </x.div>
      <Collapsible open={isOpen}>
        <x.div paddingTop={spacing.inner.base04} fontSize="smaller">
          <Typography variant="body-small-em" color={color.text.secondary}>
            Relevant Experience
          </Typography>
          <BulletPoints items={speaker.bio} small />
          <x.div
            display={"grid"}
            gridTemplateColumns="1fr 1fr"
            gap={spacing.inner.base04}
            paddingTop={spacing.inner.base04}
          >
            <Button
              variant="outline"
              flexGrow={1}
              size="small"
              dataAttributes={{ "data-testid": "see-insights-btn" }}
              onClick={() => onSelectExpert(speaker)}
            >
              See Insights
            </Button>
            <RequestExpertsButton speakers={[speaker]} variant="secondary" />
          </x.div>
        </x.div>
      </Collapsible>
    </x.div>
  );
};
