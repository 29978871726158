import { useCallback, useEffect, useState } from "react";
import { Alert } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { usePreference } from "hooks/usePreference";
import { PreferenceType } from "providers/types";
import { utcToZonedTime } from "date-fns-tz";
import { ENABLE_AI_SUMMARIZATION, useProjectBadgeContext } from "providers/BadgeProvider";
import { fetch } from "hooks/useApi";

export const NewAiModelBanner = ({ projectToken }: { projectToken: string }) => {
  const [showBanner, setShowBanner] = useState(false);
  const { preference, updatePreference, isLoading } = usePreference(PreferenceType.NEW_AI_MODEL_BANNER);
  const { hasProjectBadge } = useProjectBadgeContext();

  const updateBannerPreference = useCallback(() => {
    if (preference) {
      updatePreference({ dismissed: true });
    }
  }, [preference, updatePreference]);

  useEffect(() => {
    const limitDate = utcToZonedTime(new Date("2025-05-01"), "UTC");
    const nowUtc = utcToZonedTime(new Date(), "UTC");
    if (isLoading || !projectToken) return;
    if (
      !hasProjectBadge(ENABLE_AI_SUMMARIZATION) ||
      (preference && preference.attributes.dismissed) ||
      nowUtc > limitDate
    ) {
      setShowBanner(false);
      return;
    }
    fetch({
      url: `/api/projects/${projectToken}/interactions/find`,
      body: JSON.stringify({
        filters: {
          profile_activity: ["HiddenOrNot"],
          transcript_status: ["Available", "Requested", "Completed"],
        },
      }),
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        const interactions: Interaction[] = data.edges.map((it: InteractionEdge) => it.node);
        const hasTranscripts = interactions.some((interaction) =>
          interaction.recordings?.flatMap((r) => r.transcriptRequests ?? []).find((tr) => !tr.purgedAt && tr.completed)
        );
        setShowBanner(hasTranscripts);
      });
  }, [hasProjectBadge, isLoading, preference, projectToken]);

  if (!showBanner) return null;

  return (
    <x.div position="absolute" bottom={24} left={14} w="390px" data-testid="new-ai-model-banner">
      <Alert variant="info" onClose={updateBannerPreference}>
        AI summaries have been enhanced with a new model, delivering even more accurate and actionable insights.
      </Alert>
    </x.div>
  );
};
