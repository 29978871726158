import { isEqual } from "lodash";

type AppliedFilters = { [key: string]: string[] };

/**
 * It checks if the object contains a key named "profile_activity". If it does, the function filters out any values in the "profile_activity" array that are equal to "HiddenOrNot".
 * If the key is not present, the original filters object is returned unchanged.
 *
 * @param filters
 */
export const ignoreHiddenOrNot = (filters: AppliedFilters) => {
  if (!filters) return filters;
  return Object.keys(filters).includes("profile_activity")
    ? { ...filters, profile_activity: filters["profile_activity"].filter((it: string) => it !== "HiddenOrNot") }
    : filters;
};

/**
 * Takes an object of filters as input and returns a new object with all empty or hidden filters removed.
 *
 * @param filters
 */
export const cleanEmptyFilters = (filters: AppliedFilters) => {
  if (!filters) return filters;
  const cleanedFilter = ignoreHiddenOrNot(filters);
  return Object.keys(cleanedFilter).reduce(
    (acc, key) => ({
      ...acc,
      ...((Array.isArray(cleanedFilter[key]) && cleanedFilter[key].length > 0) ||
      (!Array.isArray(cleanedFilter[key]) && cleanedFilter[key] !== null)
        ? { [key]: cleanedFilter[key] }
        : {}),
    }),
    {}
  );
};

/**
 * Checks if two filter objects are equal by comparing their cleaned versions.
 */
export const areFiltersEquals = (before: AppliedFilters, after: AppliedFilters) =>
  isEqual(cleanEmptyFilters(before), cleanEmptyFilters(after));
