import { IconButton, IconButtonVariant, useThemeTokens, Popover } from "@alphasights/alphadesign-components";
import { Search } from "@alphasights/alphadesign-icons";

export const DataTestIds = Object.freeze({
  transcriptSearchButton: "searchButton",
});

interface ReturnButtonProps {
  setAnchorEl: (anchorEl: Element) => void;
  anchorEl: Element;
  variant?: IconButtonVariant;
  popOverBody: any;
}

const TranscriptSearchButton = ({ setAnchorEl, anchorEl, variant = "outline", popOverBody }: ReturnButtonProps) => {
  const {
    color: { icon },
  } = useThemeTokens();

  const handleOpenPopOver = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleOpenPopOver} variant={variant} color={icon.secondary}>
        <Search data-testid={DataTestIds.transcriptSearchButton} />
      </IconButton>
      <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} size="medium">
        {popOverBody}
      </Popover>
    </>
  );
};

export default TranscriptSearchButton;
