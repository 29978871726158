import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const NewQuestionContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base03,
  };
});
