import { useState } from "react";
import * as React from "react";
import {
  Icon,
  IconButton,
  SelectOption,
  SelectSearch,
  SelectValue,
  Typography,
} from "@alphasights/alphadesign-components";
import { flatMap, debounce, compact } from "lodash";
import { Company, Delete } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { ExternalAlphaCompaniesService } from "services/externalAlphaCompaniesService";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { ExternalAlphaCompany } from "models/ExternalAlphaCompany";
import { useCompanyLogoStyles, useCompanySearchStyles } from "./CompanySearch.styles";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useProjectLauncherContext } from "providers/ProjectLauncherProvider";

type CompanySearchProps = {
  error?: string;
  label?: React.ReactNode;
  selectedCompanyRightSlot?: ({ company }: { company: ExternalAlphaCompany }) => JSX.Element | null;
  allowAddingNonExistingCompanies?: boolean;
  onChange?: (companies: ExternalAlphaCompany[]) => void;
};

export const CompanySearch = React.forwardRef<HTMLDivElement, CompanySearchProps>(
  ({ error, label, selectedCompanyRightSlot, allowAddingNonExistingCompanies, onChange }, ref) => {
    const [options, setOptions] = useState<ExternalAlphaCompany[]>([]);
    const currentUser = useCurrentUser();
    const { logHit } = useTrackUserAction();
    const { companies, onCompaniesChange } = useProjectLauncherContext();

    const searchCompaniesFunction =
      currentUser?.consultancyClient || !currentUser?.alphaNowEnabled
        ? (_query: string): Promise<ExternalAlphaCompany[]> => Promise.resolve([])
        : ExternalAlphaCompaniesService.search;

    const searchCompanies = debounce(async (value: string) => {
      setOptions([]);
      const data = value ? await searchCompaniesFunction(value) : [];
      const resultContainsQuery = data.some(({ name }) => name.toUpperCase() === value.toUpperCase());
      setOptions([
        ...data,
        ...(!resultContainsQuery && allowAddingNonExistingCompanies && value
          ? [
              {
                id: -1,
                name: value,
              },
            ]
          : []),
      ]);
    }, 200);

    const handleChange = (names: SelectValue | SelectValue[]) => {
      const allKnownCompanies = companies.concat(options);
      const selectedCompanyNames = flatMap([names]);
      const selectedCompanies = compact(
        selectedCompanyNames.map((name) => allKnownCompanies.find((c) => c.name === name))
      );
      onCompaniesChange(selectedCompanies);
      onChange?.(selectedCompanies);
    };

    const onRemoveCompany = (company: ExternalAlphaCompany) => {
      logHit({
        origin: HitOrigin.projectLaunchPage,
        action: HitAction.projectLaunchCompanyRemoved,
        details: {
          company,
        },
      });
      handleChange(companies.filter((c) => c.name !== company.name).map(({ name }) => name));
    };

    return (
      <div ref={ref}>
        <SelectSearch
          size="small"
          onInputValueChange={(value) => searchCompanies(value.trim())}
          label={label}
          allowMultiple
          isClearButtonEnabled={false}
          placeholder="Search for company"
          allowAddNewItems
          shouldDisplayDropdownPopover
          hideResultsIfSearchEmpty
          keepInputFocusedAfterSelect={false}
          matchFunction={() => true}
          resetInputValueAfterSelect
          onChange={handleChange}
          value={companies.map(({ name }) => name)}
          dataAttributes={{ "data-testid": "companies-search" }}
          error={error}
        >
          {options.length ? (
            options.map(({ name, logo }) => (
              <SelectOption key={name} value={name}>
                <CompanyOption name={name} logo={logo} />
              </SelectOption>
            ))
          ) : (
            <SelectOption disabled>Searching...</SelectOption>
          )}
        </SelectSearch>
        <SelectedCompanies selectedCompanyRightSlot={selectedCompanyRightSlot} onRemoveCompany={onRemoveCompany} />
      </div>
    );
  }
);

const SelectedCompanies = ({
  onRemoveCompany,
  selectedCompanyRightSlot,
}: {
  onRemoveCompany: (company: ExternalAlphaCompany) => void;
  selectedCompanyRightSlot?: ({ company }: { company: ExternalAlphaCompany }) => JSX.Element | null;
}) => {
  const { selectedCompaniesWrapper } = useCompanySearchStyles();
  const { companies } = useProjectLauncherContext();

  if (!companies.length) return null;

  return (
    <x.ul {...selectedCompaniesWrapper}>
      {companies.map((company) => (
        <SelectedCompany
          key={company.name}
          company={company}
          RightSlot={selectedCompanyRightSlot}
          onRemoveCompany={onRemoveCompany}
        />
      ))}
    </x.ul>
  );
};

const SelectedCompany = ({
  company,
  RightSlot,
  onRemoveCompany,
}: {
  company: ExternalAlphaCompany;
  RightSlot?: ({ company }: { company: ExternalAlphaCompany }) => JSX.Element | null;
  onRemoveCompany: (company: ExternalAlphaCompany) => void;
}) => {
  const { selectedCompanyWrapper, selectedCompanyActionButtons } = useCompanySearchStyles();

  return (
    <x.li data-testid={`selected-${company.name}`} {...selectedCompanyWrapper}>
      <CompanyLogo logoUrl={company.logo} size="medium" />
      <Typography variant="body-em" color="strong">
        {company.name}
      </Typography>
      <x.div {...selectedCompanyActionButtons}>
        {RightSlot && <RightSlot company={company} />}
        <IconButton testId="delete-button" variant="outline" size="small" onClick={() => onRemoveCompany(company)}>
          <Delete />
        </IconButton>
      </x.div>
    </x.li>
  );
};

const CompanyOption = ({ name, logo }: { name: string; logo?: string }) => {
  const { optionWrapper } = useCompanySearchStyles();
  return (
    <x.div {...optionWrapper}>
      <CompanyLogo logoUrl={logo} /> <span>{name}</span>
    </x.div>
  );
};

export const CompanyLogo = ({ size = "small", logoUrl }: { size?: "small" | "medium"; logoUrl?: string }) => {
  const { wrapper } = useCompanyLogoStyles({ size });
  return (
    <x.div {...wrapper}>
      {logoUrl ? (
        <x.img src={logoUrl} maxW="100%" maxH="100%" />
      ) : (
        <Icon size="small" color="secondary">
          <Company />
        </Icon>
      )}
    </x.div>
  );
};
