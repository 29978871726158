import { useState } from "react";
import { useAlphaGPTConversationListItemStyles } from "./AlphaGPTConversationListItem.styles";
import { Icon, IconButton as IconButtonAds, Typography } from "@alphasights/alphadesign-components";
import { Chat, Delete } from "@alphasights/alphadesign-icons";
import styled, { x } from "@xstyled/styled-components";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export const AlphaGPTConversationListItem = ({ selected = false, title = "Title", conversation }) => {
  const { wrapper, icon, text, titleDiv } = useAlphaGPTConversationListItemStyles({
    selected,
  });
  const currentUser = useCurrentUser();
  const [isHovered, setIsHovered] = useState(false);
  const { selectConversation, deleteConversation, hasAlphaGPTPurchase } = useAlphaGPTContext();

  const onDelete = (event) => {
    event.stopPropagation();
    deleteConversation(conversation.id);
  };
  const isConversationFromCurrentUser = conversation.clientProfile.id === currentUser.clientProfile.id;

  return (
    <x.li
      {...wrapper}
      onMouseLeave={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
      onClick={() => hasAlphaGPTPurchase && selectConversation(conversation)}
    >
      <x.div {...titleDiv}>
        <x.div {...icon}>
          <Icon data-testid="conversation-list-chat-icon" color="secondary">
            <Chat />
          </Icon>
        </x.div>
        <Typography color="strong" style={text}>
          {title}
        </Typography>
      </x.div>
      {isHovered && isConversationFromCurrentUser && hasAlphaGPTPurchase && (
        <IconButton variant="ghost" size="small" onClick={onDelete}>
          <Delete />
        </IconButton>
      )}
    </x.li>
  );
};

const IconButton = styled(IconButtonAds)`
   {
    width: ${tokens.spacing.inner.base05};
    height: ${tokens.spacing.inner.base05};
  }
`;
