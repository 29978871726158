import { FC } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

type SecondaryExpertDetailsProps = {
  secondaryCompany?: string;
  secondaryJobDuration?: string;
  secondaryJobTitle?: string;
};

const SecondaryExpertDetails: FC<SecondaryExpertDetailsProps> = ({
  secondaryCompany,
  secondaryJobDuration,
  secondaryJobTitle,
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const hasSecondaryDetails = Boolean(secondaryCompany && secondaryJobTitle && secondaryJobDuration);

  if (!hasSecondaryDetails) {
    return null;
  }

  return (
    <x.div display="flex" mt={isMobile && inner.base04} mb={isMobile && inner.base05}>
      <Typography component="div" color={text.secondary} variant={isMobile ? "body" : "body-small"}>
        <Typography component="span" variant={isMobile ? "body-em" : "body-small-em"}>
          {secondaryCompany}
        </Typography>
        {` - ${secondaryJobTitle} `}
        &bull;
        {` ${secondaryJobDuration}`}
      </Typography>
    </x.div>
  );
};

export default SecondaryExpertDetails;
