import { useCallback, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { useSize, x } from "@xstyled/styled-components";
import { MessageCantBeSent } from "components/MessageCantBeSent";
import { Alert, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ExpertLabel } from "components/ExpertLabel";
import { MessengerExpertSelect } from "components/MessengerExpertSelect";
import { TextBox } from "components/TextBox";
import { useOnClick } from "hooks/useOnClickHooks";
import { SendClarificationModal } from "components/SendMessageModals";
import { Expert } from "types";
import { SURVEY_WARNING_THRESHOLD } from "components/MessengerPage/helper";
import { MobileTextBox } from "components/MobileTextBox";
import { getCharacterCount } from "components/TextBox/utils";

export const CLARIFICATION_SIZE_LIMIT = 300;

interface ClarificationSectionProps {
  allExperts: Expert[];
  selectedExperts: Expert[];
  setSelectedExperts: (selectedExperts: Expert[]) => void;
  handleSubmit: (...args: any) => Promise<void>;
  switchToWorkRequest: () => void;
  messageText: string;
  setMessageText: (messageText: string) => void;
  isLoading: boolean;
  blinded: boolean;
  sendMessageButtonRef?: React.MutableRefObject<HTMLButtonElement | null>;
  showInfoBanner: boolean;
  showNonEligibleExperts: boolean;
  externalLinkLabel?: string;
  onClickExternalLink?: () => void;
  mobileVariant?: boolean;
  onMessageSent?: () => void;
  attachTextBoxToNavbar?: boolean;
  onMobileTextBoxHeightChange?: (newHeight: number) => void;
}

export const ClarificationSection: React.FC<ClarificationSectionProps> = ({
  allExperts = [],
  selectedExperts = [],
  setSelectedExperts,
  handleSubmit,
  switchToWorkRequest,
  messageText,
  setMessageText,
  isLoading,
  blinded,
  sendMessageButtonRef,
  showInfoBanner: showInfoBannerInput = true,
  showNonEligibleExperts = true,
  externalLinkLabel = "",
  onClickExternalLink = () => {},
  mobileVariant = false,
  onMessageSent,
  attachTextBoxToNavbar = false,
  onMobileTextBoxHeightChange,
}) => {
  const [expertErrors, setExpertErrors] = useState<string>();
  const [messageErrors, setMessageErrors] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [showCantSendModal, setShowCantSendModal] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(showInfoBannerInput);
  const [nonEligibleExperts, setNonEligibleExperts] = useState<Expert[]>([]);
  const [validSelectedExperts, setValidSelectedExperts] = useState<Expert[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { spacing } = useThemeTokens();

  const expertItems = useMemo(() => {
    setNonEligibleExperts([]);
    return buildExpertItems(allExperts, selectedExperts, showNonEligibleExperts, setNonEligibleExperts);
  }, [allExperts, showNonEligibleExperts, selectedExperts]);

  useEffect(() => {
    const expertIds = expertItems.map((expertItem) => expertItem.key);
    const validSelectedExperts = selectedExperts.filter(
      (selectedExpert) =>
        expertIds.includes(selectedExpert.id) &&
        !selectedExpert.noWrittenWork &&
        !(selectedExpert.advisorLockState?.locked ?? false)
    );
    setSelectedItems(validSelectedExperts.map((expert) => expert.id));
  }, [selectedExperts, expertItems]);

  useEffect(() => {
    const nonEligibleExpertsIds = nonEligibleExperts.map((expertItem) => expertItem.id);
    const validSelectedExperts = selectedExperts.filter(
      (selectedExpert) =>
        !nonEligibleExpertsIds.includes(selectedExpert.id) &&
        !selectedExpert.noWrittenWork &&
        !(selectedExpert.advisorLockState?.locked ?? false)
    );
    setValidSelectedExperts(validSelectedExperts);
  }, [selectedExperts, nonEligibleExperts]);

  const enteredInputs = useMemo(() => {
    const selectedExpertEntered = selectedExperts?.length > 0;
    const messageEntered = messageText?.length > 0;
    return selectedExpertEntered && messageEntered;
  }, [messageText, selectedExperts]);

  useOnClick(
    ({ didClickInsideRef }) => {
      if (sendMessageButtonRef && didClickInsideRef(sendMessageButtonRef) && (!mobileVariant || enteredInputs)) {
        openModal();
      }
    },
    [validSelectedExperts, messageText, mobileVariant, enteredInputs]
  );

  const onSendClarification = () => {
    if (performValidation()) {
      handleSubmit({
        advisors: validSelectedExperts.map((expert) => ({
          id: expert.id,
          name: expert.label,
        })),
        content: messageText,
      }).then(() => {
        setShowModal(false);
        onMessageSent && onMessageSent();
      });
    }
  };

  const performValidation = () => {
    let isOk = true;
    isOk = checkExpertError(isOk);
    isOk = checkMessageError(isOk);
    return isOk;
  };

  const checkExpertError = useCallback(
    (isOk: boolean) => {
      if (!validSelectedExperts || validSelectedExperts.length === 0) {
        setExpertErrors("No experts selected");
        return false;
      } else {
        setExpertErrors(undefined);
      }
      return isOk;
    },
    [validSelectedExperts]
  );

  const checkMessageError = useCallback(
    (isOk: boolean) => {
      if (!messageText || messageText.length === 0) {
        setMessageErrors("The message is empty");
        return false;
      } else {
        if (messageText.length > CLARIFICATION_SIZE_LIMIT) {
          setMessageErrors("You have exceeded the character limit for a Clarification.");
          return false;
        }
        setMessageErrors(undefined);
      }
      return isOk;
    },
    [messageText]
  );

  const openModal = () => {
    if (performValidation()) {
      if (validSelectedExperts.some((expert) => !expert.hasCompletedInteractions)) {
        setShowCantSendModal(true);
      } else {
        (document.activeElement as HTMLInputElement)?.blur();
        setShowModal(true);
      }
    }
  };

  useEffect(() => {
    if (expertErrors) {
      checkExpertError(false);
    }
    if (messageErrors) {
      checkMessageError(false);
    }
  }, [validSelectedExperts, messageText, expertErrors, messageErrors, checkExpertError, checkMessageError]);

  const onSelectedExpertsChange = (values?: string[]) => {
    const allSelectedExperts = allExperts.filter((expert) => values && values.includes(expert.id));
    setSelectedExperts(allSelectedExperts);
  };

  return (
    <>
      <x.div gap={spacing.layout.base02} w={useSize("full")} display="flex" flexDirection="column">
        {showInfoBanner && (
          <Alert mb={spacing.inner.base04}>
            Ask the expert a short follow-up question below. If you would like to ask a longer question or add an
            attachment or deadline, send a Work Request.{" "}
            <Link onClick={() => setShowInfoBanner(false)} component="button">
              <Typography variant="body-em">Dismiss</Typography>
            </Link>
          </Alert>
        )}
        {nonEligibleExperts.length > 0 && (
          <Alert mb={spacing.inner.base04} variant="warning">
            This Clarification cannot be sent to {nonEligibleExperts.length} expert(s) because they have not completed a
            call.
          </Alert>
        )}

        <MessengerExpertSelect
          error={expertErrors as any}
          required={true}
          value={selectedItems as any}
          items={expertItems as never[]}
          onChange={onSelectedExpertsChange}
          externalLinkLabel={externalLinkLabel}
          onClickExternalLink={onClickExternalLink as any}
          size={mobileVariant ? "small" : "medium"}
        />

        {validSelectedExperts && validSelectedExperts.length > SURVEY_WARNING_THRESHOLD && (
          <Alert variant="warning" mt={spacing.inner.base04}>
            Before contacting multiple experts, explore <Typography variant="body-em">AlphaSights Surveys</Typography>
            to receive validated and structured answers faster and with a higher response rate.
          </Alert>
        )}

        {!mobileVariant ? (
          <TextBox
            error={messageErrors as any}
            required
            label="Message"
            placeholder="Begin typing your message here..."
            onChange={setMessageText as any}
            defaultValue={messageText as any}
            maxLength={CLARIFICATION_SIZE_LIMIT}
          />
        ) : (
          <MobileTextBox
            name="message-content"
            error={messageErrors as any}
            placeholder="Begin typing your message here..."
            maxLength={CLARIFICATION_SIZE_LIMIT as any}
            onChange={setMessageText as any}
            defaultValue={messageText as any}
            resizable={false}
            sendMessageButtonRef={sendMessageButtonRef}
            checkMessageError={checkMessageError}
            disableSend={!enteredInputs}
            attachToNavbar={attachTextBoxToNavbar}
            onHeightChange={onMobileTextBoxHeightChange}
          />
        )}

        {messageText && getCharacterCount(messageText) >= CLARIFICATION_SIZE_LIMIT && !mobileVariant && (
          <Alert mt={spacing.inner.base} variant="warning">
            <x.div data-testid="clarification-size-limit-exceeded">
              <TextExceeded switchToWorkRequest={switchToWorkRequest} />
            </x.div>
          </Alert>
        )}
      </x.div>
      {showModal && (
        <SendClarificationModal
          isOpen={true}
          onCancel={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
          onSend={onSendClarification}
          experts={validSelectedExperts.length}
          message={messageText}
          isLoading={isLoading}
          blinded={blinded}
        />
      )}
      {showCantSendModal && (
        <MessageCantBeSent
          isOpen={showCantSendModal}
          setIsOpen={setShowCantSendModal}
          selectedExperts={validSelectedExperts}
          setSelectedExperts={setSelectedExperts}
        />
      )}
    </>
  );
};

const TextExceeded = ({ switchToWorkRequest }: { switchToWorkRequest: Function }) => {
  return (
    <>
      You have reached the character limit for a Clarification. If you need to exceed over 300 characters typed, switch
      to a{" "}
      <Link onClick={switchToWorkRequest as any} component="button">
        <Typography variant="body-em">Work Request</Typography>
      </Link>{" "}
      for larger requests.
    </>
  );
};

export const buildExpertItems = (
  allExperts: Expert[],
  selectedExperts: Expert[],
  showNonEligibleExperts: boolean,
  setNonEligibleExperts: Function
) => {
  return allExperts
    .filter((expert) => {
      const noWrittenWork = expert.noWrittenWork;
      const locked = expert.advisorLockState?.locked;
      const hasCompletedInteractions = expert.hasCompletedInteractions;
      const canSendMessage = expert.canSendMessage;
      const canBeSelected =
        showNonEligibleExperts || (canSendMessage && !noWrittenWork && !locked && !expert.doubleBlinded);
      if (canBeSelected && !hasCompletedInteractions && selectedExperts.includes(expert))
        setNonEligibleExperts((value: Expert[]) => [...value, expert]);
      return canBeSelected;
    })
    .map((expert) => {
      const noWrittenWork = expert.noWrittenWork;
      const locked = expert.advisorLockState?.locked;
      const hasCompletedInteractions = expert.hasCompletedInteractions;
      return {
        key: expert.id,
        labelElement: (
          <ExpertLabel
            expert={expert}
            noWrittenWork={noWrittenWork}
            locked={locked}
            customDisabled={!hasCompletedInteractions ? "Expert has not completed a call" : undefined}
            showSecondaryInformation
          />
        ),
        label: expert.name,
        disabled: noWrittenWork || locked || !hasCompletedInteractions,
        hideSelected: !hasCompletedInteractions,
      };
    });
};
