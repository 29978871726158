import * as React from "react";
import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { useCompanyPrimerV3Store } from "../../store/store";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import {
  OverviewDetails,
  MarketContext,
  ServicesAndProducts,
  NotableEvents,
} from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/Overview";
import SectionWrapper from "../styled";

const Overview = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { logoLink, companyName, businessSummary } = useCompanyPrimerV3Store(({ overview }) => overview);

  return (
    <SectionWrapper ref={ref}>
      <x.div display="flex" flexDirection="column" gap={inner.base06} px={inner.base10}>
        <x.div display="flex" alignItems="center" gap={inner.base02}>
          <PrimerCompanyLogo padding={inner.base} src={logoLink} />
          <Typography variant="h3" shouldCapitalizeHeadline={false}>
            {companyName}
          </Typography>
        </x.div>
        <CitationTextWrapper value={businessSummary} />
        <OverviewDetails />
      </x.div>
      <Divider my={inner.base08} />
      <MarketContext />
      <Divider my={inner.base08} />
      <ServicesAndProducts />
      <Divider my={inner.base08} />
      <NotableEvents />
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Overview));
