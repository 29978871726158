import { useState } from "react";
import { validatePassword } from "../validators";
import { Icon as IconAds } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useFormStyles } from "../Form.styles";
import { Hide, Show } from "@alphasights/alphadesign-icons";
import { FormField } from "./FormField";

export const PasswordField = ({ validationRules, validate, size = "medium", isMobile = false, ...props }) => {
  const { showPasswordButton } = useFormStyles(!isMobile);

  const [showPassword, setShowPassword] = useState(false);

  const callbackValidatePassword = (value) => {
    return validatePassword(value, validationRules);
  };

  const ShowPasswordButton = ({ showPassword, onClick }) => (
    <x.div {...showPasswordButton}>
      <IconAds
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        onClick={onClick}
      >
        {showPassword ? <Hide /> : <Show />}
      </IconAds>
    </x.div>
  );

  return (
    <x.div position="relative">
      <FormField
        name="password"
        placeholder="Password"
        size={size}
        validate={validate || callbackValidatePassword}
        {...props}
        type={showPassword ? "text" : "password"}
        endAdornment={<ShowPasswordButton showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />}
      />
    </x.div>
  );
};
