import { useEffect, useMemo } from "react";
import { Pill, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useMobileMessageCardStyles } from "./MobileMessageList.styles";
import { InboxResponse } from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { isSameDay } from "date-fns";
import { getRequestTypeName } from "pages/MessengerPage/utils";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { useLocation } from "router-utils";
import { MarkdownDisplay } from "@alphasights/alphadesign-rte";

export const MobileMessageList = ({ onCardClick }: { onCardClick: () => void }) => {
  const { inboxes, onSelectThread, onSelectCard } = useMessengerContext();

  const location = useLocation();

  useEffect(() => {
    const selectedThread = new URLSearchParams(location.search).get("selectedThread");

    if (selectedThread) {
      onSelectThread(selectedThread);
    }
  }, [location.search, inboxes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectCard = (inbox: InboxResponse) => {
    onSelectCard(inbox.groupId);
    onCardClick();
  };

  if (inboxes.length === 0) return null;

  return (
    <x.div data-testid="mobile-message-list" w={"100%"}>
      {inboxes.map((inbox) => (
        <MessageListItem inbox={inbox} onClick={() => handleSelectCard(inbox)} key={inbox.groupId} />
      ))}
    </x.div>
  );
};

const MessageListItem = ({ inbox, onClick }: { inbox: InboxResponse; onClick: () => void }) => {
  const styles = useMobileMessageCardStyles();

  const { experts } = useMessengerContext();

  const isLastMessageFromToday = useMemo(() => isSameDay(new Date(), new Date(inbox.mostRecentMessage.sentAt)), [
    inbox.mostRecentMessage.sentAt,
  ]);

  const numParticipants = useMemo(() => inbox.threads.length, [inbox.threads.length]);

  const mostRecentExpert = useMemo(() => experts.find((e) => e.id === inbox.mostRecentAdvisor.id), [
    experts,
    inbox.mostRecentAdvisor.id,
  ]);

  const requestType = useMemo(() => getRequestTypeName(inbox.requestType), [inbox.requestType]);

  return (
    <x.li {...styles.messageWrapper} onClick={onClick} data-testid={`inbox-list-item`}>
      <x.div>
        <Typography variant="body-small" color="secondary" float="right">
          {isLastMessageFromToday && "Today "}
          <FormattedDateTime
            date={inbox.mostRecentMessage.sentAt}
            format={isLastMessageFromToday ? "HH:mm" : "d LLL yyyy, HH:mm"}
          />
        </Typography>
        <Pill size="small">{requestType}</Pill>
      </x.div>
      <x.div>
        <x.div {...styles.senderWrapper}>
          <Typography variant="body-em" {...styles.companyAndRole}>
            {mostRecentExpert?.relevantCompany} - {mostRecentExpert?.relevantPosition}
          </Typography>
          {numParticipants > 1 && <Typography variant="body-em">&nbsp;{`+ ${numParticipants - 1} more`}</Typography>}
        </x.div>
        <x.div
          style={
            {
              filter: inbox.mostRecentMessage.obfuscated ? "blur(2.5px)" : "none",
              userSelect: inbox.mostRecentMessage.obfuscated ? "none" : "auto",
              ...styles.messagePreviewLabel,
            } as any
          }
        >
          {inbox.mostRecentMessage.sanitizedContent && (
            <MarkdownDisplay
              dataTestid="message-preview"
              size="small"
              markdown={inbox.mostRecentMessage.sanitizedContent}
              customStyles={styles.markdownStyles}
            />
          )}
        </x.div>
      </x.div>
    </x.li>
  );
};
