import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { suggestionsInfo } from "views/DeliverablesView/helpers";
import TruncatedTextCell from "./TruncatedTextCell";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const TitleCell = ({ row: { original: content } }: AlphaTableTypes.CellContext<ContentResults, any>) => {
  const { color } = useThemeTokens();
  const { title } = suggestionsInfo(content);
  return <TruncatedTextCell text={title} testId={`content-title-${content.id}`} textColor={color.text.info} />;
};

export default TitleCell;
