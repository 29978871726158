import { NPSCategory, NPSCompany, ProjectFeature } from "@alphasights/portal-api-client";
import { ChartData, SynthesisBarChart } from "../../components";
import { ThirdPartyInteraction } from "types";
import { buildBarChartQuote } from "../../KpcModuleContent/KpcChart";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import tokens from "@alphasights/alphadesign-tokens";

export const NPSChart = ({
  companies,
  thirdPartyDocuments,
}: {
  companies: NPSCompany[];
  thirdPartyDocuments: ThirdPartyInteraction[];
}) => {
  const { project, isFeatureDisabled } = useCurrentProjectContext();
  const chartData = getChartData(companies, project!.token, thirdPartyDocuments);
  const categories = companies.map((item) => item.companyName);
  const isToolbarEnabled = !isFeatureDisabled(ProjectFeature.ProjectSynthesis);

  return (
    <SynthesisBarChart
      chartData={chartData}
      categories={categories}
      extraChartProps={{
        id: "nps-stacked-bar-chart",
        horizontal: true,
        popOverPlacement: "bottom",
      }}
      toolbarEnabled={isToolbarEnabled}
    />
  );
};

const getChartData = (
  companies: NPSCompany[],
  projectToken: string,
  thirdPartyDocuments: ThirdPartyInteraction[]
): ChartData[] => {
  const validSentiments = [NPSCategory.DETRACTOR, NPSCategory.PASSIVE, NPSCategory.PROMOTER];
  return validSentiments.map((sentiment) => {
    return {
      name: sentimentLabels[sentiment],
      color: sentimentColors[sentiment],
      data: companies.map((company, idx) => {
        const sentimentData = company.categorySummaries.find((s) => s.category === sentiment);
        return {
          y: sentimentData?.count ?? 0,
          x: idx.toString(),
          color: sentimentColors[sentiment],
          sentiment: sentimentLabels[sentiment],
          quotes: sentimentData?.quotes.map((q) => buildBarChartQuote(q, projectToken, thirdPartyDocuments)) ?? [],
        };
      }),
    };
  });
};

const sentimentColors = {
  [NPSCategory.DETRACTOR]: tokens.color.chart.categorical.base04,
  [NPSCategory.PROMOTER]: tokens.color.chart.categorical.base03,
  [NPSCategory.PASSIVE]: tokens.color.chart.categorical.base09,
};

const sentimentLabels = {
  [NPSCategory.DETRACTOR]: "Detractor",
  [NPSCategory.PROMOTER]: "Promoter",
  [NPSCategory.PASSIVE]: "Passive",
};
