import { useEffect, useState } from "react";
import { Button, Icon, TextField, Typography } from "@alphasights/alphadesign-components";
import { Edit } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { getValidationRules, validatePasswordForm } from "../Login/validators";
import { fetch } from "../../hooks/useApi";
import { PasswordField } from "../Login/components/PasswordField";
import { PasswordConfirmationField } from "pages/Login/components/PasswordConfirmationField";
import { isEmpty } from "lodash";
import { useLoginStyles } from "../Login/index.styles";
import { useMyProfilePageStyles } from "./index.styles";
import { FormContainer } from "pages/Login/components/FormContainer";

export const MobilePasswordField = ({ size = "small", onEditing, onPasswordChange }) => {
  const { validationMessage } = useLoginStyles();
  const { formLabel, formLabelDisabled } = useMyProfilePageStyles();

  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [validationRules, setValidationRules] = useState([]);

  const differentNewPasswordError = "Must differ from previous password.";

  const errorMapping = {
    Incorrect: { currentPassword: "Your current password is incorrect" },
    "new password must be different": {
      password: differentNewPasswordError,
      passwordConfirmation: differentNewPasswordError,
    },
  };

  useEffect(() => {
    getValidationRules().then((res) => setValidationRules(res));
  }, []);

  const changeEditing = (editing) => {
    setIsEditing(editing);
    onEditing && onEditing(editing);
  };

  const updatePassword = async (input, { setErrors }) => {
    setLoading(true);
    setErrorMessage(null);
    fetch({
      url: `/api/auth-user/update-password`,
      method: "POST",
      body: JSON.stringify({
        currentPassword: input.currentPassword,
        newPassword: input.password,
      }),
      skipAlert: true,
    })
      .then(() => {
        onPasswordChange();
        changeEditing(false);
      })
      .catch((response) =>
        response.json().then((json) => {
          const errorKey = Object.keys(errorMapping).find((key) => json.message.includes(key));

          if (errorKey) {
            setErrors(errorMapping[errorKey]);
          } else {
            setErrorMessage(json.message);
          }
        })
      )
      .finally(() => setLoading(false));
  };

  const validationCallback = (props) => validatePasswordForm({ ...props, validationRules });

  return (
    <x.div display="flex" flexDirection="column" gap="8px">
      <Typography {...(isEditing ? formLabel : formLabelDisabled)}>Password</Typography>
      {!isEditing ? (
        <TextField
          dataTestId="disabled-password-form-field"
          size={size}
          value="password"
          disabled={true}
          type="password"
          endAdornment={
            <Icon
              data-testid="email-form-field-change-icon"
              size="small"
              color="secondary"
              onClick={() => changeEditing(true)}
            >
              <Edit />
            </Icon>
          }
        />
      ) : (
        <FormContainer
          initialValues={{
            currentPassword: "",
            password: "",
            passwordConfirmation: "",
          }}
          onSubmit={updatePassword}
          isLoading={isLoading}
          validate={validationCallback}
        >
          {({ touched, errors, submitForm }) => (
            <x.div display="flex" flexDirection="column" gap="6px">
              {errorMessage && (
                <x.header>
                  <Typography>{errorMessage}</Typography>
                </x.header>
              )}
              <PasswordField
                name="currentPassword"
                size={size}
                placeholder="Current password"
                dataTestId="current-password-field"
                isMobile={true}
                showErrorText={false}
              />
              <PasswordField dataTestId="new-password-field" size={size} showErrorText={false} isMobile={true} />
              <x.div>
                <PasswordConfirmationField
                  dataTestId="new-password-confirm-field"
                  size={size}
                  showErrorText={false}
                  isMobile={true}
                />
                <Typography variant="body-small" {...validationMessage}>
                  {!isEmpty(touched) && (errors.currentPassword || errors.password)}
                </Typography>
              </x.div>
              <x.div display="flex" justifyContent="space-around" w="100%" gap="9px">
                <Button
                  data-testid="cancel-profile-btn"
                  w="100%"
                  size={size}
                  type="button"
                  variant="outline"
                  onClick={() => changeEditing(false)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="update-profile-btn"
                  w="100%"
                  size={size}
                  type="button"
                  variant="secondary"
                  onClick={submitForm}
                >
                  Update Password
                </Button>
              </x.div>
            </x.div>
          )}
        </FormContainer>
      )}
    </x.div>
  );
};
