import { x } from "@xstyled/styled-components";
import { Modal, Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { WarningFilled } from "@alphasights/alphadesign-icons";
import { expiredAccessPlanDescription, expiredAccessPlanTitle } from "content/AlphaNow";
import { useNavigate } from "router-utils";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";

const ExpiredServicePlanModal = () => {
  const {
    spacing: { inner, layout },
    color: { background },
  } = useThemeTokens();

  const navigate = useNavigate();

  return (
    <x.div textAlign="center">
      <x.img
        h="100%"
        w="100%"
        objectFit="fill"
        maxWidth="100%"
        src={require("pages/AlphaNowPage/images/blurred-alphanow.png")}
      />
      <Modal
        title={
          <x.div display="flex" flexDirection="row" alignItems="center">
            <Icon size="medium" color={background.decorative.base03}>
              <WarningFilled />
            </Icon>
            <Typography variant="body-large-em" component="span" pl={inner.base02} textTransform="capitalize">
              {expiredAccessPlanTitle}
            </Typography>
          </x.div>
        }
        open
        variant="complex"
        slotHeight={layout.base12}
        onClose={() => navigate(`/${myAlphaSightsBaseUrl}`)}
      >
        <Typography>{expiredAccessPlanDescription}</Typography>
      </Modal>
    </x.div>
  );
};

export default ExpiredServicePlanModal;
