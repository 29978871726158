import { Formik } from "formik";
import { LoadingOverlay } from "@alphasights/client-portal-shared";
import { x } from "@xstyled/styled-components";
import { useFormStyles } from "../Form.styles";

export const FormContainer = ({ className = "", isLoading, children, ...props }) => {
  const { formContainerDiv } = useFormStyles();

  return (
    <x.div {...formContainerDiv}>
      <Formik validateOnBlur={false} {...props}>
        {(renderProps) => <>{children instanceof Function ? children(renderProps) : children}</>}
      </Formik>
      {isLoading && <LoadingOverlay />}
    </x.div>
  );
};
