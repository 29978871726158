import surveysService from "services/surveysService";
import {
  FetchSurveysProps,
  PaginatedAggregatedResults,
  Survey,
  SurveyInsights,
  SurveyMetadata,
  TransformedSurveyMetadata,
  TransformedTab,
} from "./types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useQuery } from "query-utils";

const GET_SURVEY_METADATA_KEY = "SURVEY_METADATA_KEY";
const GET_SURVEY_RESULTS_KEY = "SURVEY_RESULTS_KEY";
const GET_SURVEY_INSIGHTS_KEY = "SURVEY_INSIGHTS_KEY";
const GET_SURVEYS_KEY = "GET_SURVEYS_KEY";

const defaultMetadata = {
  companyDetails: [],
  id: "",
  decipherId: "",
  projectId: "",
  status: "draft",
  startDate: null,
  endDate: null,
  type: "",
  name: "",
  tabs: [],
  questions: [],
};

// Method to transform the data from the API to the frontend
const transformSurveyData = (data: SurveyMetadata): TransformedTab[] => {
  const tabMap: { [key: string]: TransformedTab } = {};

  data.questions.forEach((question, index) => {
    const { tab, subTab, ...rest } = question;
    const { name: tabName, display: tabDisplay } = tab;
    const { name: subTabName, display: subTabDisplay } = subTab;

    if (!tabMap[tabName]) {
      tabMap[tabName] = {
        name: tabName,
        display: tabDisplay,
        subTabs: [],
      };
    }

    const currentTab = tabMap[tabName];
    let currentSubTab = currentTab.subTabs.find((st) => st.name === subTabName);

    if (!currentSubTab) {
      currentSubTab = {
        name: subTabName,
        display: subTabDisplay,
        questions: [],
      };
      currentTab.subTabs.push(currentSubTab);
    }

    currentSubTab.questions.push({
      questionIndex: index,
      ...rest,
    });
  });

  return Object.values(tabMap);
};

const fetchSurveyMetadata = async (surveyId: string) => {
  try {
    const rawData: SurveyMetadata = await surveysService.readSurveyMetadata(surveyId);
    const { tabs, ...rest } = rawData;
    const transformedData = transformSurveyData(rawData);

    return { ...rest, tabs: transformedData };
  } catch {
    console.log("Error fetching survey metadata");
    return defaultMetadata as TransformedSurveyMetadata;
  }
};

const fetchSurveyResults = async ({
  surveyId,
  questionIds,
  pageSize,
  page,
}: {
  surveyId: string;
  questionIds: string[];
  pageSize: number;
  page: number;
}) => {
  try {
    const results: PaginatedAggregatedResults = await surveysService.readSurveyResults(
      surveyId,
      questionIds,
      pageSize,
      page
    );
    return results;
  } catch {
    console.log("Error fetching survey results");
    return { total: 0, page: 0, pageSize: 0, items: [] };
  }
};

const fetchSurveyInsights = async ({
  surveyId,
  page,
  pageSize,
}: {
  surveyId: string;
  page: number;
  pageSize: number;
}) => {
  try {
    const results: SurveyInsights = await surveysService.readSurveyInsights(surveyId, page, pageSize);
    return results;
  } catch {
    console.log("Error fetching survey insights");
    return { total: 0, page: 0, pageSize: 0, items: [] };
  }
};

const fetchSurveys = async ({ page, pageSize, columnToFilter, order, projectId, token }: FetchSurveysProps) => {
  try {
    const survey = await surveysService.getSurveys({ page, pageSize, columnToFilter, order, projectId, token });
    return survey;
  } catch {
    console.log("Error fetching surveys");
    return { total: 0, page: 0, pageSize: 0, items: [] };
  }
};

// GET THE METADATA OF THE SURVEY
export const useSurveyMetadata = (surveyId: string) => {
  return useQuery<TransformedSurveyMetadata>([GET_SURVEY_METADATA_KEY, surveyId], () => fetchSurveyMetadata(surveyId), {
    enabled: !!surveyId,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};

// GET THE RESULTS OF THE SURVEY GIVEN THE QUESTION IDS
export const useSurveyResults = (surveyId: string, questionIds: string[]) => {
  return useQuery<PaginatedAggregatedResults>(
    [GET_SURVEY_RESULTS_KEY, surveyId, questionIds],
    () => fetchSurveyResults({ surveyId, questionIds, pageSize: questionIds.length, page: 0 }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      enabled: !!questionIds.length,
    }
  );
};

// GET THE INFORMATION TO USE IN THE EXPERTS SIDEBAR
const PAGE_SIZE = 20;
export const useSurveyInsights = (surveyId: string, pageSize = PAGE_SIZE) => {
  return useInfiniteQuery<SurveyInsights>(
    [GET_SURVEY_INSIGHTS_KEY, surveyId],
    ({ pageParam = 0 }) => fetchSurveyInsights({ surveyId, page: pageParam, pageSize }),
    {
      enabled: !!surveyId,
      staleTime: 1000 * 60 * 15, // 10 minutes
      getNextPageParam: (lastPage) => {
        if (lastPage.page < lastPage.total / pageSize) {
          return lastPage.page + 1;
        }
        return undefined;
      },
      keepPreviousData: true,
    }
  );
};

// GET THE LIST OF SURVEYS GIVEN THE PROJECT ID
export const useGetSurveys = ({ page, pageSize, columnToFilter, order, projectId, token }: FetchSurveysProps) => {
  return useQuery<Survey>(
    [GET_SURVEYS_KEY, token],
    () => fetchSurveys({ page, pageSize, columnToFilter, order, projectId, token }),
    {
      enabled: !!token,
    }
  );
};
