import { useState } from "react";
import { x } from "@xstyled/styled-components";
import { Button, Checkbox, Modal, Typography } from "@alphasights/alphadesign-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { HitAction } from "@alphasights/portal-api-client";

export const DeleteEmptyModuleModal = ({
  isOpen,
  setIsOpen,
  onSave,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSave: () => void;
}) => {
  const { selectedModule, deleteModule } = useProjectSynthesisContext();

  const handleDeleteModule = () => {
    setIsOpen(false);
    deleteModule(selectedModule!);
  };

  return (
    <Modal title="Delete module?" open={isOpen} onClose={() => setIsOpen(false)} slotHeight="112px" slotWidth="534px">
      <Typography variant="body-small">
        You have removed all expert insights from this module. Would you like to delete the whole theme?
      </Typography>
      <x.div display="flex" alignItems="center" justifyContent="flex-end" py="16px" gap="16px">
        <Button
          onClick={onSave}
          variant="outline"
          size="small"
          dataAttributes={{ "data-testid": "modal-save-empty-module" }}
        >
          Save Theme
        </Button>
        <Button
          onClick={handleDeleteModule}
          variant="secondary"
          size="small"
          dataAttributes={{ "data-testid": "modal-delete-empty-module" }}
        >
          Delete Theme
        </Button>
      </x.div>
    </Modal>
  );
};

export const SaveAndRegenerateModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  const { saveModuleChanges, selectedModule, updateSynthesisPreferences } = useProjectSynthesisContext();
  const [dontNotify, setDontNotify] = useState(false);

  const handleRegenerateInsights = () => {
    setIsOpen(false);
    updateSynthesisPreferences({ showSaveAndRegeneratePrompt: !dontNotify });
    const details = {
      originator: "save-and-regenerate-btn",
      promptShown: true,
    };
    saveModuleChanges({ ...selectedModule!, aiRegenerationEnabled: true }, details);
  };

  return (
    <Modal
      title="Save and regenerate insights?"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      slotHeight="112px"
      slotWidth="534px"
    >
      <Typography variant="body-small">
        You have edited the theme question. Confirming you would like to AI-regenerate the theme insights, based on the
        edited question?
      </Typography>
      <x.div display="flex" alignItems="center" justifyContent="space-between" py="16px">
        <Checkbox
          checked={dontNotify}
          onChange={() => setDontNotify((prev) => !prev)}
          size="small"
          dataAttributes={{ "data-testid": "regenerate-dont-notify-again" }}
        >
          Don't notify me again
        </Checkbox>
        <x.div display="flex" gap="16px">
          <Button
            onClick={() => setIsOpen(false)}
            variant="outline"
            size="small"
            dataAttributes={{ "data-testid": "modal-continue-editing" }}
          >
            Continue Editing
          </Button>
          <Button
            onClick={handleRegenerateInsights}
            variant="secondary"
            size="small"
            dataAttributes={{ "data-testid": "modal-regenerate-insights" }}
          >
            Regenerate Insights
          </Button>
        </x.div>
      </x.div>
    </Modal>
  );
};

export const DisableAutoRegenerationModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  const { saveModuleChanges, selectedModule, updateSynthesisPreferences } = useProjectSynthesisContext();
  const [dontNotify, setDontNotify] = useState(false);

  const handleSave = () => {
    setIsOpen(false);
    updateSynthesisPreferences({
      showSavePrompt: !dontNotify,
      savePromptSelectedOption: "save",
    });
    const details = {
      originator: "save-btn",
      promptShown: true,
      chosenOption: "save",
    };
    saveModuleChanges(selectedModule!, details);
  };

  const handleSaveAndDisable = () => {
    setIsOpen(false);
    updateSynthesisPreferences({
      showSavePrompt: !dontNotify,
      savePromptSelectedOption: "saveAndDisable",
    });
    const details = {
      originator: "save-btn",
      promptShown: true,
      chosenOption: "saveAndDisable",
    };
    saveModuleChanges(
      { ...selectedModule!, aiRegenerationEnabled: false },
      details,
      true,
      "Module locked. Edits saved."
    );
  };

  return (
    <Modal
      title="Disable auto regeneration?"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      slotHeight="160px"
      slotWidth="534px"
    >
      <x.div display="flex" flexDirection="column" gap="16px">
        <Typography variant="body-small">
          If auto regeneration is enabled, this theme will AI-regenerate when new transcripts become available.
        </Typography>
        <Typography variant="body-small">
          Disable auto regeneration to fix this theme’s contents. You can reenable auto regeneration at any point.
        </Typography>
      </x.div>
      <x.div display="flex" alignItems="center" justifyContent="space-between" py="16px">
        <Checkbox
          checked={dontNotify}
          onChange={() => setDontNotify((prev) => !prev)}
          size="small"
          dataAttributes={{ "data-testid": "disable-dont-notify-again" }}
        >
          Don't notify me again
        </Checkbox>
        <x.div display="flex" gap="16px">
          <Button
            onClick={handleSave}
            variant="outline"
            size="small"
            dataAttributes={{ "data-testid": "modal-save-dont-disable" }}
          >
            Save
          </Button>
          <Button
            onClick={handleSaveAndDisable}
            variant="secondary"
            size="small"
            dataAttributes={{ "data-testid": "modal-save-and-disable" }}
          >
            Save And Disable
          </Button>
        </x.div>
      </x.div>
    </Modal>
  );
};

export const DeleteModuleModal = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (value: boolean) => void }) => {
  const { deleteModule, selectedModule, synthesisLogHit } = useProjectSynthesisContext();

  const handleDeleteModule = () => {
    setIsOpen(false);
    deleteModule(selectedModule!);
    synthesisLogHit({
      action: HitAction.projectSynthesisModuleDeleted,
      references: { moduleId: selectedModule?.id },
      details: { moduleType: selectedModule?.contentType },
    });
  };

  const confirmationText =
    selectedModule?.contentType === "QUESTION"
      ? "Are you sure you want to delete this module? Deleting will permanently delete this module from view."
      : "Are you sure you want to delete this theme? You can add this data visualization back through the dropdown at the top left.";

  return (
    <Modal title="Delete module?" open={isOpen} onClose={() => setIsOpen(false)} slotHeight="112px" slotWidth="534px">
      <Typography variant="body-small">{confirmationText}</Typography>
      <x.div display="flex" alignItems="center" justifyContent="flex-end" py="16px" gap="16px">
        <Button
          onClick={() => setIsOpen(false)}
          variant="outline"
          size="small"
          dataAttributes={{ "data-testid": "delete-modal-cancel" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteModule}
          variant="secondary"
          size="small"
          dataAttributes={{ "data-testid": "delete-modal-confirm" }}
        >
          Delete
        </Button>
      </x.div>
    </Modal>
  );
};
