import { FC, ReactElement } from "react";
import { Button, TypographyVariation, ComponentSize } from "@alphasights/alphadesign-components";
import "./index.css";
import { RequestExpertButtonType } from "../AlphaNowContentActions/constants";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { DISABLED_BUTTON_STYLES, MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const ButtonWithAccess = withAccessControl(Button);

interface RequestExpertButtonProps {
  onClick: () => void;
  buttonActionVariant: string;
  text?: string;
  variant?: TypographyVariation;
  loading?: boolean;
  startIcon?: ReactElement;
  disabled?: boolean;
  size?: ComponentSize;
}

const RequestExpertButton: FC<RequestExpertButtonProps> = ({
  onClick,
  buttonActionVariant,
  text,
  loading,
  startIcon,
  disabled = false,
  size = "medium",
}) => {
  const { isMobile } = useCheckScreen();
  const buttonSize = isMobile ? "small" : size;
  const isLoading = loading && buttonActionVariant === RequestExpertButtonType.openModal;

  return (
    <ButtonWithAccess
      accessControl={{ allowedPermissions: [MANAGE_CONTENT_PERMISSION] }}
      className="request-expert-button"
      data-testid={buttonActionVariant}
      size={buttonSize}
      onClick={onClick}
      variant="secondary"
      whiteSpace="nowrap"
      startIcon={startIcon}
      disabled={disabled}
      loading={isLoading}
      {...(disabled ? DISABLED_BUTTON_STYLES : {})}
    >
      {text}
    </ButtonWithAccess>
  );
};

export default RequestExpertButton;
