import { FC, useCallback, useEffect, useRef } from "react";
import { IconButton } from "@alphasights/alphadesign-components";
import { ChevronRight } from "@alphasights/alphadesign-icons";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";

import { logTimeSpent, logScrollPercentage } from "pages/AlphaNowPage/utils/logHit";
import ErrorMessage from "pages/AlphaNowPage/components/ErrorMessage";
import { View } from "models/Header";
import { errorLoadingQuestionView, questionUnavailableTryAgainContent, generatedByAITag } from "content/AlphaNow";
import { useAppSearchContext } from "providers/AppSearchProvider";
import ResearchContainer from "components/ResearchContainer";
import { HEADER } from "./constants";
import { QuestionContainer, StyledDivider, StyledTypography } from "./QuestionView.styled";

interface QuestionViewProps {
  questionsData: any[];
  contentId: string;
  isSuccess: boolean;
  setViewMode: (value: string) => void;
}

const QuestionView: FC<QuestionViewProps> = ({ questionsData = [], isSuccess, setViewMode, contentId }) => {
  const { logHit } = useTrackUserAction();
  const maxScrollPercentage = useRef(0);
  const { updateQuery } = useAppSearchContext();

  const handleClick = useCallback(
    (fragmentId: any) => {
      setViewMode(View.Transcript);
      updateQuery({ fragmentIds: [fragmentId] });

      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowViewQuestion,
        details: { contentId, fragmentPosition: fragmentId },
      });
    },
    [updateQuery, setViewMode, contentId, logHit]
  );

  useEffect(() => {
    const startTime = Date.now();
    const scrollPercentage = maxScrollPercentage.current;
    const timeSpentAction = HitAction.alphaNowTimeSpentOnQuestionView;
    const scrollAction = HitAction.alphaNowQuestionViewScrollPercentage;

    return () => {
      logHit({
        origin: HitOrigin.alphaNow,
        action: HitAction.alphaNowOpenQuestionView,
        details: { contentId },
      });

      logTimeSpent(startTime, { contentId, userId: "" }, logHit, timeSpentAction);

      logScrollPercentage(
        logHit,
        {
          contentId,
          scrollPercentage,
          userId: "",
        },
        scrollAction
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSuccess) {
    return <ErrorMessage header={errorLoadingQuestionView} body={questionUnavailableTryAgainContent} />;
  }

  return (
    <ResearchContainer header={HEADER} secondaryLabel={generatedByAITag}>
      {questionsData.map((data) => {
        const { question, fragmentIds } = data;
        return (
          <>
            <QuestionContainer>
              <StyledTypography variant="body">{question}</StyledTypography>
              <IconButton
                onClick={() => handleClick(fragmentIds[0])}
                disabled={false}
                size="small"
                variant="outline"
                key="rounded-button"
                testId="select-question-button"
              >
                <ChevronRight />
              </IconButton>
            </QuestionContainer>
            <StyledDivider />
          </>
        );
      })}
    </ResearchContainer>
  );
};

export default QuestionView;
