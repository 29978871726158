import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

const UploadMoreButtonWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base02,
    alignItems: "center",
  };
});

const TextAndIconWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
  display: flex;
  gap: ${spacing.inner.base02};
  align-items: center;
`;
});

const StyledInteractiveIcon = styled(Icon).attrs({ size: "x-small" })`
  :hover {
    cursor: pointer;
  }
`;

export { UploadMoreButtonWrapper, TextAndIconWrapper, StyledInteractiveIcon };
