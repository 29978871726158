import { Icon, PillVariants, useThemeTokens } from "@alphasights/alphadesign-components";
import { StatusFull, StatusHalf, StatusOneQuarter, StatusPending } from "@alphasights/alphadesign-icons";

const useFollowUpStyles = ({ newChipCount }: { newChipCount?: boolean } = {}) => {
  const {
    flex: { gap },
    spacing: { inner },
  } = useThemeTokens();

  const pillVariantMap: Record<string, PillVariants> = {
    available: "light",
    requested: newChipCount ? "light" : "yellow",
    scheduled: newChipCount ? "light" : "violet",
    completed: newChipCount ? "light" : "green",
  };

  return {
    followUpDiv: {
      display: "flex",
      gap: gap.sm,
    },
    chipIcons: {
      available: (
        <Icon size="small" color="primary">
          <StatusPending />
        </Icon>
      ),
      requested: (
        <Icon size="small" color="warning">
          <StatusOneQuarter />
        </Icon>
      ),
      scheduled: (
        <Icon size="small" color="processing">
          <StatusHalf />
        </Icon>
      ),
      completed: (
        <Icon size="small" color="success">
          <StatusFull />
        </Icon>
      ),
    },
    pillVariantMap, // Include pillVariantMap for reuse
    chipStyle: {
      textTransform: "capitalize",
      padding: `${inner.base} ${inner.base02}`,
      borderRadius: inner.base02,
      cursor: "pointer",
    },
  };
};

export default useFollowUpStyles;
