import { memo, useEffect, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import Tab from "views/SurveysResultsView/components/DataVisualization/components/Tab";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyMetadata } from "views/SurveysResultsView/api/useSurveysAPI";
import { TransformedSurveyMetadata } from "views/SurveysResultsView/api/types";

const DataVisualizationContainer = memo(() => {
  const { spacing } = useThemeTokens();
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const sectionsRefs = useSurveysResultsStore(({ sectionsRefs }) => sectionsRefs);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { tabs } = surveyMetadata as TransformedSurveyMetadata;

  const setIsScrollOnClickedTab = useSurveysResultsStore(({ setIsScrollOnClickedTab }) => setIsScrollOnClickedTab);

  const navigateToSectionIndex = useSurveysResultsStore(({ navigateToSectionIndex }) => navigateToSectionIndex);

  useEffect(() => {
    if (navigateToSectionIndex !== null && sectionsRefs[navigateToSectionIndex].current) {
      sectionsRefs[navigateToSectionIndex].current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToSectionIndex]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      scrollTimeoutRef.current = setTimeout(() => {
        setIsScrollOnClickedTab(false);
      }, 100);
    };

    const scrollableContainer = scrollableContainerRef.current;
    scrollableContainer?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableContainer?.removeEventListener("scroll", handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [setIsScrollOnClickedTab]);

  return (
    <x.div
      ref={scrollableContainerRef}
      data-testid="data-visualization-container"
      padding={spacing.layout.base03}
      display="flex"
      flexDirection="column"
      gap={spacing.inner.base06}
      h="100%"
      overflowY="auto"
    >
      <x.div display="flex" flexDirection="column" gap={spacing.inner.base06}>
        {tabs.map((tab, index) => (
          <Tab key={tab.display} tab={tab} tabIndex={index} />
        ))}
      </x.div>
    </x.div>
  );
});

export default DataVisualizationContainer;
