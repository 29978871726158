import { FileData } from "../ThirdPartyDocUploadModal";

const createFileName = (fileNames: string[], newFileName: string): string => {
  // Remove the extension and keep it separate
  const nameParts = newFileName.split(".");
  const extension = nameParts.length > 1 ? "." + nameParts.pop() : "";
  let baseName = nameParts.join(".");

  // Initialize the unique name with the original name
  let uniqueFileName = baseName + extension;
  let counter = 1;

  // Keep modifying the name until it is unique
  while (fileNames.includes(uniqueFileName)) {
    uniqueFileName = `${baseName}(${counter++})${extension}`;
  }
  return uniqueFileName;
};

const readFile = async (file: File): Promise<FileData> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target?.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export { createFileName, readFile };
