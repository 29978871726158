import React, { FC, RefObject, useEffect, useMemo, useRef, useState, useCallback } from "react";
import { isEqual } from "lodash";
import { offset } from "@floating-ui/react-dom";
import { Button, Modal, useThemeTokens } from "@alphasights/alphadesign-components";
import { CalendarToday } from "@alphasights/alphadesign-icons";

import { ThirdPartyDocumentAttributes, ThirdPartyDocumentExpertWithId } from "types/common-types";
import useOnClickOutside from "hooks/useOnClickHooks";
import { MODAL_TITLE, MODAL_WIDTH, SAVE, BACK, DUMMY_EXPERT, FormFieldLabel } from "./constants";
import { formatDate, getPopoverOffset, getExpertIdsWithErrors, getSanitisedForm } from "./utils";
import { DocumentAttributesForm } from "./types";
import Experts from "./Experts";

import * as S from "./EditDocumentModal.styled";

const DataTestIds = {
  SAVE_BUTTON: "save-button",
  BACK_BUTTON: "back-button",
};

type EditDocumentModalProps = {
  onClickSave: (attributes: ThirdPartyDocumentAttributes) => void;
  onClickCancel: () => void;
  fileName: string;
  isTranscript?: boolean;
} & ThirdPartyDocumentAttributes;

const EditDocumentModal: FC<EditDocumentModalProps> = ({
  onClickSave,
  onClickCancel,
  isTranscript = false,
  fileName,
  ...props
}) => {
  const uniqueExpertId = useRef(0);
  const initialFormState: RefObject<DocumentAttributesForm> = useRef({
    ...props,
    title: props.title ?? fileName,
    experts: props.experts?.map((expert) => ({ ...expert, expertId: uniqueExpertId.current++ })) ?? [],
  });
  const [formState, setFormState] = useState<DocumentAttributesForm>({
    ...(initialFormState.current as DocumentAttributesForm),
  });
  const [expertsWithErrors, setExpertsWithErrors] = useState<number[]>([]);

  const dateFieldRef = useRef<HTMLInputElement>(null);
  const datePopoverRef = useRef<HTMLDivElement>(null);
  const calendarIconRef = useRef<HTMLDivElement>(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement>();

  const isSaveDisabled = useMemo(() => {
    return isEqual(formState, initialFormState.current);
  }, [formState]);
  const formattedDate = useMemo(() => formatDate(new Date(formState.documentDate!)), [formState.documentDate]);
  const experts = useMemo(() => (formState.experts.length > 0 ? formState.experts : [DUMMY_EXPERT]), [
    formState.experts,
  ]);

  useOnClickOutside(datePopoverRef, (event: MouseEvent | TouchEvent) => {
    const target = event.target as Node;
    if (!dateFieldRef.current?.contains(target) && !calendarIconRef.current?.contains(target)) {
      setIsDatePickerOpen(false);
    }
  });

  useEffect(() => {
    if (dateFieldRef?.current) {
      setPopoverAnchor(dateFieldRef.current);
    }
  }, [dateFieldRef]);

  const updateFormState = useCallback(
    (newState: Partial<DocumentAttributesForm>) => {
      setFormState({ ...formState, ...newState });
    },
    [formState]
  );

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormState({ title: e.target.value });
  };

  const handleRelevantDateChange = (date: Date) => {
    updateFormState({ documentDate: date.toISOString() });
    setIsDatePickerOpen(false);
  };

  const handleClickDateField = (_: React.MouseEvent) => {
    setIsDatePickerOpen(true);
  };

  const handleSaveEdits = () => {
    const sanitisedForm = getSanitisedForm(formState);

    if (sanitisedForm.experts.length === 1) {
      onClickSave(sanitisedForm);
      return;
    }

    const expertIdsWithErrors = getExpertIdsWithErrors(experts);
    if (expertIdsWithErrors.length > 0) {
      setExpertsWithErrors(expertIdsWithErrors);
    } else {
      setExpertsWithErrors([]);
      onClickSave(sanitisedForm);
    }
  };

  const handleUpdateExpert = useCallback(
    (value: ThirdPartyDocumentExpertWithId, isNewExpert?: boolean) => {
      let updatedExpert: ThirdPartyDocumentExpertWithId;
      if (isNewExpert) {
        const { expertId, ...rest } = value;
        const finalExpertId = expertId === 0 ? ++uniqueExpertId.current : expertId;
        updatedExpert = { ...rest, expertId: finalExpertId };
      } else {
        updatedExpert = value;
      }

      let hasExpertBeenUpdated = false;
      const updatedExperts = experts.map((expert) => {
        const isExpertMatch = expert.expertId === value.expertId;
        if (isExpertMatch) {
          hasExpertBeenUpdated = true;
          return updatedExpert;
        }
        return expert;
      });
      if (!hasExpertBeenUpdated) {
        updatedExperts.push(updatedExpert);
      }
      updateFormState({ experts: updatedExperts });
    },
    [updateFormState, experts]
  );

  const handleAddNewExpert = () => {
    updateFormState({ experts: [...experts, { expertId: ++uniqueExpertId.current }] });
  };

  const handleDeleteExpert = (expertId: number) => {
    updateFormState({ experts: experts.filter((expert) => expert.expertId !== expertId) });
  };

  return (
    <Modal
      title={MODAL_TITLE}
      size="small"
      variant="complex"
      open
      slotWidth={MODAL_WIDTH}
      slotHeight="auto"
      transition="opacity 0.3s ease-out"
      shouldShowFooter
      onClose={onClickCancel}
      primaryButton={<SaveButton onClick={handleSaveEdits} disabled={isSaveDisabled} />}
      secondaryButton={<BackButton onClick={onClickCancel} />}
    >
      <S.FormContainer>
        <S.FormRow>
          <S.FormItem>
            <S.Label disabled={isTranscript}>{FormFieldLabel.title}</S.Label>
            <S.StyledTextField
              value={formState.title}
              disabled={isTranscript}
              isControlled
              onChange={handleTitleChange}
            />
          </S.FormItem>
          <S.FormItem w="198px">
            <S.Label>{FormFieldLabel.relevantDate}</S.Label>
            <S.DateField
              ref={dateFieldRef}
              value={formattedDate}
              isControlled
              onClick={handleClickDateField}
              endAdornment={
                <div onClick={handleClickDateField} ref={calendarIconRef}>
                  <S.StyledCalendarIcon>
                    <CalendarToday />
                  </S.StyledCalendarIcon>
                </div>
              }
            />
            {isDatePickerOpen && (
              <S.DatePopover
                ref={datePopoverRef}
                open={isDatePickerOpen}
                anchorEl={popoverAnchor}
                middleware={[offset(getPopoverOffset())]}
                placement="bottom"
              >
                <S.StyledDatePicker
                  firstDate={new Date(formState.documentDate!)}
                  onChange={handleRelevantDateChange}
                  disableFutureDays
                />
              </S.DatePopover>
            )}
          </S.FormItem>
        </S.FormRow>
        <Experts
          experts={experts}
          onUpdate={handleUpdateExpert}
          onDelete={handleDeleteExpert}
          onAddNewExpert={handleAddNewExpert}
          expertIdsWithErrors={expertsWithErrors}
          isTranscript={isTranscript}
        />
      </S.FormContainer>
    </Modal>
  );
};

type ModalButtonProps = {
  onClick: () => void;
};
const SaveButton: FC<ModalButtonProps & { disabled: boolean }> = ({ onClick, disabled }) => {
  const { spacing } = useThemeTokens();
  return (
    <Button
      size="small"
      variant="outline"
      disabled={disabled}
      onClick={onClick}
      ml={spacing.inner.base04}
      dataAttributes={{ "data-testid": DataTestIds.SAVE_BUTTON }}
    >
      {SAVE}
    </Button>
  );
};

const BackButton: FC<ModalButtonProps> = ({ onClick }) => (
  <Button size="small" variant="ghost" onClick={onClick} dataAttributes={{ "data-testid": DataTestIds.BACK_BUTTON }}>
    {BACK}
  </Button>
);

export { EditDocumentModal as default, DataTestIds };
export type { EditDocumentModalProps };
