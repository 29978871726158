import { default as Content } from "react-content-loader";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const ContentLoader = ({ ...props }) => {
  const {
    spacing: { inner },
    color: { border },
  } = useThemeTokens();

  return (
    <Content
      viewBox="0 0 900 400"
      style={{
        marginBottom: inner.base06,
        display: "flex",
        backgroundColor: "white",
        borderRadius: inner.base02,
        border: "1px solid " + border.divider,
      }}
      {...props}
    >
      {/* Header */}
      <rect x="17" y="17" width="150" height="14" />
      <rect x="17" y="50" width="350" height="22" />
      <rect x="17" y="77" width="200" height="22" />
      <rect x="220" y="77" width="50" height="22" />

      {/* Badges */}
      <rect x="17" y="118" width="150" height="15" />
      <rect x="17" y="140" width="80" height="15" />

      {/* Relevance Statement */}
      <circle cx="21" cy="200" r="4" />
      <rect x="35" y="195" width="830" height="10" />
      <rect x="35" y="215" width="600" height="10" />

      <circle cx="21" cy="240" r="4" />
      <rect x="35" y="235" width="800" height="10" />

      <circle cx="21" cy="260" r="4" />
      <rect x="35" y="255" width="830" height="10" />
      <rect x="35" y="275" width="300" height="10" />

      {/* Actions */}
      <rect x="0" y="370" width="900" height="30" />
    </Content>
  );
};
