import { fetch } from "hooks/useApi";
import {
  FetchSurveysProps,
  PaginatedAggregatedResults,
  Survey,
  SurveyInsights,
  SurveyMetadata,
} from "views/SurveysResultsView/api/types";

const surveysService = {
  async readSurveyMetadata(surveyId: string): Promise<SurveyMetadata> {
    return fetch({
      url: `/api/survey/${surveyId}/metadata`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async readSurveyResults(
    surveyId: string,
    questionIds: string[],
    pageSize: number,
    page: number
  ): Promise<PaginatedAggregatedResults> {
    return fetch({
      url: `/api/survey/${surveyId}/results/aggregated?pageSize=${pageSize}&page=${page}&questionIds=${questionIds.join(
        ","
      )}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async readSurveyInsights(surveyId: string, page: number, pageSize: number): Promise<SurveyInsights> {
    return fetch({
      url: `/api/survey/${surveyId}/results/insights?page=${page}&pageSize=${pageSize}`,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
  async getSurveys({ page, pageSize, columnToFilter, order, projectId, token }: FetchSurveysProps): Promise<Survey> {
    const params = new URLSearchParams();

    if (!!page) params.append("page", page.toString());
    if (!!pageSize) params.append("pageSize", pageSize.toString());
    if (!!columnToFilter) params.append("columnToFilter", columnToFilter);
    if (!!order) params.append("order", order);
    if (!!projectId) params.append("projectId", projectId);
    if (!!token) params.append("token", token);

    const url = `/api/survey?${params.toString()}`;

    return fetch({
      url,
      method: "GET",
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
};
export default surveysService;
