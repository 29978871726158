import { useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  IconButton,
  ListOption,
  MobileTopBar,
  ToggleButton,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import {
  ArrowLeft,
  BookmarkSelected,
  BookmarkUnselected,
  MoreVert,
  Search,
  Tick,
  VolumeUp,
} from "@alphasights/alphadesign-icons";
import { useInteractionDeliverablesContext } from "providers/InteractionDeliverableProvider";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { deliverablesNames } from "views/DeliverablesView/Cards";
import { useNoDrag } from "utils/mobileHacks";
import { DeliverableContent } from "./InteractionDeliverable.component";
import { useInteractionDeliverablesStyles } from "./InteractionDeliverables.styles";

export const InteractionDeliverablesView = ({ onClose }: { onClose: () => void }) => {
  const { searchCriteria, transcriptFeaturesEnabled } = useInteractionDeliverablesContext()!;
  const [selectedTab, setSelectedTab] = useState(() => {
    return transcriptFeaturesEnabled ? "transcript" : "aiSummary";
  });
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [showPlayer, setShowPlayer] = useState(true);
  const headerRef = useRef<HTMLDivElement>(null);
  const { fullScreenFixedWrapper, topbarFixedWrapper } = useMobileDeliverablesStyles();

  return (
    <x.div {...fullScreenFixedWrapper}>
      {!viewMoreOpen && (
        <>
          <x.div {...topbarFixedWrapper} ref={headerRef}>
            <DeliverableTopBar
              onViewMore={() => setViewMoreOpen(true)}
              onClose={onClose}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              onToggleAudio={() => setShowPlayer((a) => !a)}
              showPlayer={showPlayer}
            />
          </x.div>
          <DeliverableContent
            selectedTab={selectedTab}
            headerRef={headerRef}
            showPlayer={showPlayer}
            onHidePlayer={() => setShowPlayer(false)}
            keywords={searchCriteria}
          />
        </>
      )}
      {viewMoreOpen && <DeliverableViewMore onBack={() => setViewMoreOpen(false)} />}
    </x.div>
  );
};

const DeliverableViewMore = ({ onBack }: { onBack: () => void }) => {
  const { currentTranscript, transcripts, selectTranscript, interaction } = useInteractionDeliverablesContext()!;
  const { toggleInteractionBookmark } = useDeliverableContext();
  const Bookmarked = interaction.bookmarked ? BookmarkSelected : BookmarkUnselected;
  const { spacing } = useThemeTokens();
  return (
    <>
      <MobileTopBar
        mainButton={
          <IconButton size="large" variant="ghost" onClick={onBack} testId="close-deliverable-btn">
            <ArrowLeft />
          </IconButton>
        }
      />
      <x.div padding={spacing.inner.base04}>
        {transcripts.length > 1 && (
          <>
            <ListOption type="title" label="Transcript Type" />
            {transcripts.map((tr) => (
              <ListOption
                type="text"
                label={deliverablesNames[tr.transcriptType].name}
                selected={tr === currentTranscript}
                rightIcon={tr === currentTranscript ? <Tick /> : undefined}
                onChange={() => {
                  selectTranscript(tr);
                  onBack();
                }}
                dataAttributes={{ "data-testid": `view-more-select-${tr.id}` }}
              />
            ))}
            <ListOption type="divider" />
          </>
        )}
        <ListOption
          dataAttributes={{ "data-testid": "view-more-bookmark-btn" }}
          type="text"
          leftIcon={<Bookmarked />}
          label={interaction.bookmarked ? "Unbookmark Transcript" : "Bookmark Transcript"}
          onChange={() => toggleInteractionBookmark(interaction)}
        />
      </x.div>
    </>
  );
};

const DeliverableTopBar = ({
  onViewMore,
  onClose,
  selectedTab,
  setSelectedTab,
  onToggleAudio,
  showPlayer,
}: {
  onViewMore: () => void;
  onClose: () => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  onToggleAudio: () => void;
  showPlayer: boolean;
}) => {
  const {
    interaction,
    currentTranscript,
    canPlayAudio,
    searchCriteria,
    onSearch,
    transcriptFeaturesEnabled,
    aiFeaturesEnabled,
  } = useInteractionDeliverablesContext()!;
  const [topBarVariant, setTopBarVariant] = useState(searchCriteria.length > 0 ? "search" : "regular");
  const ref = useRef<HTMLDivElement>(null);
  useNoDrag({ ref });

  const { sound } = useInteractionDeliverablesStyles({ isActive: showPlayer });

  const handleSearchClose = () => {
    setTopBarVariant("regular");
    onSearch([]);
  };

  return (
    <x.div ref={ref} data-testid={"interaction-deliverable-topbar"}>
      <MobileTopBar
        keywords={searchCriteria}
        mainButton={
          <IconButton size="large" variant="ghost" onClick={onClose} testId="close-deliverable-btn">
            <ArrowLeft />
          </IconButton>
        }
        onKeywordsMatch={onSearch}
        autoFocusSearch
        title={`${interaction.advisorCompany}- ${interaction.role}`}
        // @ts-ignore
        variant={topBarVariant}
        onSearchClose={handleSearchClose}
        defaultNavValue={selectedTab}
        regularButtons={
          <>
            <IconButton
              size="large"
              variant="ghost"
              onClick={() => setTopBarVariant("search")}
              testId="mobile-deliverable-search-open-btn"
            >
              <Search />
            </IconButton>
            {canPlayAudio && (
              <IconButton
                size="large"
                variant="ghost"
                {...sound}
                onClick={onToggleAudio}
                testId="mobile-toggle-audio-player-btn"
              >
                <VolumeUp color="green" />
              </IconButton>
            )}
            <IconButton size="large" variant="ghost" onClick={onViewMore} testId="mobile-deliverables-view-more-btn">
              <MoreVert />
            </IconButton>
          </>
        }
      >
        {/* @ts-ignore */}
        <>
          {transcriptFeaturesEnabled && currentTranscript && (
            <ToggleButton value="transcript" sizeVariant="small" onClick={() => setSelectedTab("transcript")}>
              Transcript
            </ToggleButton>
          )}
          {aiFeaturesEnabled && currentTranscript && (
            <ToggleButton value="aiSummary" sizeVariant="small" onClick={() => setSelectedTab("aiSummary")}>
              AI Summary
            </ToggleButton>
          )}
        </>
      </MobileTopBar>
    </x.div>
  );
};
