import { ExpertCharge } from "types";
import useSWR from "swr";

export type WorkRequestCreditsRequest = {
  projectToken: string;
  duration: number;
  shouldFetch?: boolean;
};

export const useWorkRequestCredits = ({ projectToken, duration, shouldFetch = true }: WorkRequestCreditsRequest) => {
  const key =
    shouldFetch && duration
      ? `/api/projects/${projectToken}/message-threads/work-requests/duration/${duration}/credits`
      : null;
  const { data, isLoading, isValidating, error } = useSWR<number>(key);

  const defaultCredits = duration / 60.0;

  const calculatePerExperts = (selectedExperts: ExpertCharge[]) => {
    const preCallCredits = data ?? 0;

    let totalCredits = 0;

    selectedExperts.forEach((expert: ExpertCharge) => {
      const isPrecall = !(expert.hasInteractionOccurred ?? false);
      const creditToApply = isPrecall ? preCallCredits : defaultCredits;
      totalCredits += creditToApply * (expert.alphaCircleMultiplier ?? 1.0);
    });

    return totalCredits;
  };

  const convertCreditsToString = (credits?: number) => {
    return !credits || credits === 0
      ? "No charge"
      : credits === 1
      ? "1 credit"
      : `${credits.toFixed(2).replace(/\.?0+$/, "")} credits`;
  };

  return {
    preCallCredits: data,
    defaultCredits: defaultCredits,
    isCreditsLoading: isLoading || isValidating,
    creditsError: error,
    calculatePerExperts,
    convertCreditsToString,
  };
};
