import { useQuery } from "query-utils";
import { useState, useEffect } from "react";
import { contentService } from "services/content";

const useTranscriptAudio = ({ contentId, enableAudioFetch }: { contentId: string; enableAudioFetch: boolean }) => {
  const [contentAudio, setContentAudio] = useState<string | null>(null);
  const [contentVtt, setContentVtt] = useState<string | null>(null);
  const [isAudioAvailable, setIsAudioAvailable] = useState(false);
  const [audioDownloadStatus, setAudioDownloadStatus] = useState("idle");

  useQuery(["audioRequest", contentId], () => contentService.fetchAudioMetadata(contentId), {
    enabled: enableAudioFetch,
    onSuccess: ({ isAudioPresent, isVttPresent }) => {
      setIsAudioAvailable(isAudioPresent && isVttPresent);
    },
  });

  const { isFetching: fetchingAudio } = useQuery(
    ["contentAudio", contentId],
    () => contentService.fetchContentAudio(contentId),
    {
      enabled: !!isAudioAvailable,
      onSuccess: (res) => {
        const audioUrl = URL.createObjectURL(new Blob([res], { type: "audio/mp3" }));
        setContentAudio(audioUrl);
      },
      onError: () => setAudioDownloadStatus("error"),
    }
  );

  const { isFetching: fetchingVtt } = useQuery(
    ["contentVtt", contentId],
    () => contentService.fetchContentVtt(contentId),
    {
      enabled: !!isAudioAvailable,
      onSuccess: (res) => {
        const vttUrl = URL.createObjectURL(new Blob([res], { type: "text/vtt" }));
        setContentVtt(vttUrl);
      },
      onError: () => setAudioDownloadStatus("error"),
    }
  );

  useEffect(() => {
    if (Boolean(contentVtt && contentAudio)) {
      setAudioDownloadStatus("success");
    }

    if (fetchingAudio && fetchingVtt) {
      setAudioDownloadStatus("loading");
    }
  }, [contentVtt, contentAudio, fetchingAudio, fetchingVtt, setAudioDownloadStatus]);

  return {
    contentAudio,
    contentVtt,
    isAudioAvailable,
    audioDownloadStatus,
  };
};

export default useTranscriptAudio;
