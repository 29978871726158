import { FC, createContext, useContext, useMemo, ReactNode, useRef, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "router-utils";
import { Location } from "history";
import { cloneDeep } from "lodash";
import { useNewNavigation } from "hooks/useNewNavigationUnbadge";

import useAlphaNowQuery, { Query } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { isInStorybookContext } from "utils/storybook";
import useBrowserStorage, { StorageType } from "hooks/useBrowserStorage";

import { isLocationEqual } from "./utils";
import { INITIAL_CONTEXT_STATE } from "./constants";
import { AppSearchState } from "./types";

const initialContextState = cloneDeep(INITIAL_CONTEXT_STATE);

const AppSearchContext = createContext<AppSearchState>(initialContextState);

export type AppSearchProviderProps = {
  children: ReactNode;

  value?: AppSearchState;
};

const AppSearchProvider: FC<AppSearchProviderProps> = ({ children, ...props }) => {
  const [searchErrorMessage, setSearchErrorMessage] = useState("");

  // TODO [RD1-209] remove old navigation
  const newNavigationEnabled = useNewNavigation();
  const location = useLocation();
  const navigateTo = useNavigate();

  // TODO [RD1-209] remove old navigation
  const {
    query,
    updateQuery: updateAlphaNowQuery,
    navigate,
    goBack: goBackAlphaNow,
    goForward: goForwardAlphaNow,
    isBackDisabled: isOldNavBackDisabled,
    isForwardDisabled: isOldNavForwardDisabled,
  } = useAlphaNowQuery();

  // the navigation history is stored in the session storage to persist the history across page reloads
  const [navigationHistory, setNavigationHistory] = useBrowserStorage<Location[]>(
    "navigationHistory",
    // we need to always rely on history.location because the location returned by useLocation does not contain the search params
    [location],
    StorageType.SESSION
  );
  const [navigationIndex, setNavigationIndex] = useBrowserStorage<number>("navigationIndex", 0, StorageType.SESSION);

  const lastNavigationIndex = useMemo(() => (navigationHistory.length === 0 ? 0 : navigationHistory.length - 1), [
    navigationHistory,
  ]);

  const isNavigating = useRef(false);

  const updateHistory = useCallback(() => {
    // If the location pointer is not pointing to the last location in the history, it means that
    // the user has navigated through the history and is now navigating to an entirely new location
    // In this case, we need to remove all the locations after the current location pointer.
    const { replace } = (location.state as Record<string, boolean> | undefined) ?? {};
    const newIndex = replace ? navigationIndex : navigationIndex + 1;
    const updatedHistory = [...navigationHistory.slice(0, newIndex), location];
    setNavigationHistory(updatedHistory);
    setNavigationIndex(newIndex);
  }, [location, navigationHistory, navigationIndex, setNavigationHistory, setNavigationIndex]);

  useEffect(() => {
    // If the location changes due to a navigation event, we do not need to update the history
    if (isNavigating.current) {
      isNavigating.current = false;
      return;
    }
    // If the location changes for any other reason and is different from the current location, we need to update the history
    // This is not going to be the case when the AlphaNow query is updated because the location change is handled in the updateQuery function first
    // And when this effect runs, the new location will be the same as the current location in the history
    if (newNavigationEnabled && !isLocationEqual(navigationHistory[navigationIndex], location)) {
      updateHistory();
    }
  }, [location, newNavigationEnabled, navigationHistory, navigationIndex, updateHistory]);

  const updateQuery = useCallback(
    (queryUpdate: Partial<Query>) => {
      setSearchErrorMessage("");
      updateAlphaNowQuery(queryUpdate);
      const currentQuery = navigationHistory[navigationIndex];
      if (newNavigationEnabled && !isNavigating.current && !isLocationEqual(currentQuery, location)) {
        updateHistory();
      }
    },
    [newNavigationEnabled, updateAlphaNowQuery, location, navigationHistory, navigationIndex, updateHistory]
  );

  const isBackDisabled = useMemo(() => {
    if (newNavigationEnabled) {
      // do not allow the user to navigate back to the sign-in page
      const isDisabled = navigationIndex === 0 || navigationHistory[navigationIndex - 1]?.pathname === "/sign-in";
      return isDisabled;
    }
    return isOldNavBackDisabled;
  }, [newNavigationEnabled, isOldNavBackDisabled, navigationIndex, navigationHistory]);

  const isForwardDisabled = useMemo(() => {
    if (newNavigationEnabled) {
      const isDisabled = navigationIndex === lastNavigationIndex;
      return isDisabled;
    }
    return isOldNavForwardDisabled;
  }, [newNavigationEnabled, isOldNavForwardDisabled, lastNavigationIndex, navigationIndex]);

  const _navigateNew = useCallback(
    (newIndex: number) => {
      isNavigating.current = true;
      setNavigationIndex(newIndex);
      // we do not want to use history.goBack() because it might not work as expected
      // if the browser history is not in sync with the navigation history
      navigateTo(cloneDeep(navigationHistory[newIndex]));
    },
    [setNavigationIndex, navigateTo, navigationHistory]
  );

  const goBack = useCallback(() => {
    if (newNavigationEnabled) {
      _navigateNew(navigationIndex - 1);
      return;
    }
    goBackAlphaNow();
  }, [newNavigationEnabled, navigationIndex, _navigateNew, goBackAlphaNow]);

  const goForward = useCallback(() => {
    if (newNavigationEnabled) {
      _navigateNew(navigationIndex + 1);
      return;
    }
    goForwardAlphaNow();
  }, [newNavigationEnabled, navigationIndex, _navigateNew, goForwardAlphaNow]);

  const memoizedContextValue = useMemo(
    () => ({
      query,
      updateQuery,
      navigate,
      goBack,
      goForward,
      isBackDisabled,
      isForwardDisabled,
      searchErrorMessage,
      setSearchErrorMessage,
      isNavigating: isNavigating.current,
    }),
    [
      query,
      updateQuery,
      navigate,
      goBack,
      goForward,
      isBackDisabled,
      isForwardDisabled,
      searchErrorMessage,
      setSearchErrorMessage,
      isNavigating,
    ]
  );

  return (
    <AppSearchContext.Provider value={memoizedContextValue} {...props}>
      {children}
    </AppSearchContext.Provider>
  );
};

const useAppSearchContext = (): AppSearchState => {
  const searchContext = useContext(AppSearchContext);
  if (searchContext === undefined) {
    throw new Error("useAppSearchContext must be used within a AppSearchProvider");
  }
  return searchContext;
};

const withAppSearchProvider = (Story: FC<any>, value?: AppSearchState) => {
  if (!isInStorybookContext()) {
    throw Error("withAppSearchProvider can only be used within Storybook");
  }

  return (
    <AppSearchProvider value={value ?? cloneDeep(INITIAL_CONTEXT_STATE)}>
      <Story />
    </AppSearchProvider>
  );
};

export { AppSearchContext, AppSearchProvider, useAppSearchContext, withAppSearchProvider };
