import PropTypes from "prop-types";

import { x } from "@xstyled/styled-components";

import { Avatar, Tooltip, useThemeTokens } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";
import { CONTENT_TYPE } from "@alphasights/client-portal-shared";

const ICON_BACKGROUND = Object.freeze({
  Interviewer: "base",
  Expert: "base03",
  "Expert 1": "base03",
  "Expert 2": "base04",
  "Expert 3": "base05",
  "Expert 4": "base06",
  "Expert 5": "base02",
  "Expert 6": "base02",
  "Expert 7": "base02",
  "Expert 8": "base02",
  "Expert 9": "base02",
});

const AVATAR_TEXT = Object.freeze({
  Interviewer: "",
  Expert: "",
  "Expert 1": "A",
  "Expert 2": "B",
  "Expert 3": "C",
  "Expert 4": "D",
  "Expert 5": "E",
  "Expert 6": "F",
  "Expert 7": "G",
  "Expert 8": "H",
  "Expert 9": "I",
});

const SpeakerIcon = ({ speaker, contentType, isMentionsView }) => {
  const {
    color: { background, icon },
    font,
  } = useThemeTokens();

  const { transcriptParticipantRole: role, company, jobTitle, isModerator } = speaker;
  const iconBackground = ICON_BACKGROUND[role];
  const avatarText = contentType === CONTENT_TYPE.alphaview ? AVATAR_TEXT[role] : "";

  const speakerName =
    speaker && isModerator ? (
      role
    ) : (
      <x.span>
        <x.span fontWeight="bold">{company}</x.span>
        {` - ${jobTitle}`}
      </x.span>
    );

  return (
    <x.div position="relative" display="flex" alignItems="center">
      <Tooltip
        disabled={!isMentionsView}
        position="bottom"
        customBody={
          <x.div
            bg={icon.strong._}
            maxWidth="172px"
            color={background.surface.page.default}
            fontSize={font.size["02"]}
            lineHeight={font.lineHeight["01"]}
            width="156px"
          >
            {speakerName}
          </x.div>
        }
      >
        <Avatar data-testid="speaker-icon" size="small" color={iconBackground} text={avatarText}>
          <Expert />
        </Avatar>
      </Tooltip>
    </x.div>
  );
};

SpeakerIcon.propTypes = {
  speaker: PropTypes.shape({
    role: PropTypes.string,
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    isModerator: PropTypes.bool,
  }),
  style: PropTypes.shape({
    iconBackground: PropTypes.string.isRequired,
  }),
};

export default SpeakerIcon;
