import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { noop } from "lodash";
import { Button, Icon, Modal, useThemeTokens } from "@alphasights/alphadesign-components";
import { Upload } from "@alphasights/alphadesign-icons";

import usePrevious from "hooks/usePrevious";
import useModal from "hooks/useModal";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { TITLE, MODAL_WIDTH, ADD_TO_PROJECT, CANCEL, UPLOAD_MORE } from "./constants";
import { SelectedThirdPartyFileToUpload } from "./types";
import { isSuccessStatus, getEditDocumentProps } from "./utils";
import FileTable from "./FileTable";
import DocUploadExitModal from "./DocUploadExitModal";
import ConfirmRemoveDocModal from "./ConfirmRemoveDocModal";
import EditDocumentModal from "./EditDocumentModal";
import useDocumentRequests from "./useDocumentRequests";
import { ThirdPartyDocument, ThirdPartyDocumentExpert, ThirdPartyDocumentAttributes } from "types";

import * as S from "./ThirdPartyDocUploadModal.styled";

const DataTestIds = {
  addToProjectButton: "upload-modal-add-to-project-button",
  cancelButton: "upload-modal-cancel-button",
  uploadMoreButton: "upload-modal-upload-more-button",
};

type ThirdPartyDocUploadModalProps = {
  files: SelectedThirdPartyFileToUpload[];
  setFiles: Dispatch<SetStateAction<SelectedThirdPartyFileToUpload[]>>;
  selectFiles?: () => void;
  onClose: (options?: { confirm: boolean }) => void;
  editMode?: boolean;
  refetchDocuments?: (documents: Partial<ThirdPartyDocument>[]) => void;
};

const ThirdPartyDocUploadModal: FC<ThirdPartyDocUploadModalProps> = ({
  files,
  setFiles,
  onClose,
  selectFiles = noop,
  editMode = false,
  refetchDocuments = noop,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [fileIdToRemove, setFileIdToRemove] = useState<number | null>(null);
  const [editedFileId, setEditedFileId] = useState<number | null>(null);

  const previousFilesLength = usePrevious(files.length);

  const isAddDisabled: boolean = useMemo(
    () => !files.some(({ status, documentId }) => isSuccessStatus(status) && documentId),
    [files]
  );

  const { uploadDocument, deleteDocument, updateDocumentAttributes, assignToProject } = useDocumentRequests({
    files,
    setFiles,
  });

  const { isVisible: isExitModalVisible, onOpen: onOpenExitModal, onClose: onCloseExitModal } = useModal();
  const {
    isVisible: isRemoveConfirmationModalVisible,
    onOpen: onOpenRemoveConfirmationModal,
    onClose: onCloseRemoveConfirmationModal,
  } = useModal();
  const { isVisible: isEditModalVisible, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useModal();

  const editedFile = useMemo(() => files.find((file) => file.id === editedFileId), [files, editedFileId]);

  const uploadFiles = (filesToUpload: SelectedThirdPartyFileToUpload[]) =>
    Promise.all(filesToUpload.map(({ id, name }) => uploadDocument({ id, name })));

  useDeepCompareEffect(() => {
    const numNewFiles = files.length - (previousFilesLength ?? 0);
    if (numNewFiles > 0 && !editMode) {
      uploadFiles(files.slice(0, numNewFiles));
    }
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddToProject = () => {
    const documentIdsToAdd = files
      .filter(({ status, documentId }) => documentId && isSuccessStatus(status))
      .map(({ documentId }) => documentId!);
    Promise.all(documentIdsToAdd.map((documentId) => assignToProject(documentId)))
      .then(() => {
        const docsAddedToProject = files
          .filter(({ status, documentId }) => documentId && isSuccessStatus(status))
          .map(({ documentId, date }) => ({ id: documentId, date }));
        refetchDocuments(docsAddedToProject);
      })
      .catch((error) => {
        console.error("An error occurred adding document(s) to the project", error);
      });
    onClose({ confirm: false });
  };

  const deleteFile = (id: number) => {
    try {
      const documentId = files.find((file) => file.id === id)?.documentId;
      if (documentId) {
        deleteDocument(documentId);
      } else {
        // when the file is deleted mid upload, before there is a document ID
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
      }
    } catch (error) {
      console.error("Unable to delete document");
    } finally {
      setFileIdToRemove(null);
    }
  };

  const saveFileEdits = (attributes: ThirdPartyDocumentAttributes) => {
    const documentId = files.find((file) => file.id === editedFileId)?.documentId!;
    try {
      updateDocumentAttributes({ id: editedFileId!, documentId, attributes });
    } catch (error) {
      console.error("Unable to save edits");
    } finally {
      setEditedFileId(null);
    }
  };

  const hideModal = () => setIsOpen(false);
  const unhideModal = () => setIsOpen(true);

  const handleCloseUploadModal = () => {
    if (files.length > 0 && !editMode) {
      hideModal();
      onOpenExitModal();
    } else {
      onClose();
    }
  };

  const handleCancelExit = () => {
    onCloseExitModal();
    unhideModal();
  };

  const handleConfirmExit = () => {
    onCloseExitModal();
    onClose();
  };

  const handleCloseRemoveConfirmationModal = () => {
    onCloseRemoveConfirmationModal();
    setFileIdToRemove(null);
    unhideModal();
  };

  const handleConfirmRemove = () => {
    handleCloseRemoveConfirmationModal();
    deleteFile(fileIdToRemove!);
  };

  const handleCloseEditModal = () => {
    onCloseEditModal();
    unhideModal();
  };

  const handleSaveEdits = (attributes: ThirdPartyDocumentAttributes) => {
    handleCloseEditModal();
    saveFileEdits(attributes);
  };

  const handleClickDelete = (fileId: number) => {
    setFileIdToRemove(fileId);
    hideModal();
    onOpenRemoveConfirmationModal();
  };

  const handleSelectAngle = (id: number, value: string) => {
    const file = files.find((file) => file.id === id);
    const logError = () => console.error("Unable to assign angle");
    if (file) {
      const { documentId, experts } = file;
      const updatedExperts = experts?.map((expert: ThirdPartyDocumentExpert, index: number) =>
        index === 0 ? { ...expert, angle: value } : expert
      );
      try {
        updateDocumentAttributes({ id, documentId: documentId as string, attributes: { experts: updatedExperts } });
      } catch (error) {
        logError();
      }
    } else {
      logError();
    }
  };

  const handleClickEdit = (id: number) => {
    setEditedFileId(id);
    hideModal();
    onOpenEditModal();
  };

  return (
    <>
      <Modal
        title={TITLE}
        size="small"
        variant="complex"
        slotWidth={MODAL_WIDTH}
        slotPadding="0"
        transition="opacity 0.3s ease-out"
        open={isOpen}
        onClose={handleCloseUploadModal}
        shouldShowFooter={!editMode}
        primaryButton={<AddToProjectButton onClick={handleAddToProject} disabled={isAddDisabled} />}
        secondaryButton={<CancelButton onClick={handleCloseUploadModal} />}
        tertiaryButton={<UploadMoreButton onClick={selectFiles} />}
      >
        <FileTable
          files={files}
          onClickDelete={handleClickDelete}
          onSelectAngle={handleSelectAngle}
          onClickEdit={handleClickEdit}
          editMode={editMode}
        />
      </Modal>
      {isExitModalVisible && <DocUploadExitModal onClickConfirm={handleConfirmExit} onClickCancel={handleCancelExit} />}
      {isRemoveConfirmationModalVisible && (
        <ConfirmRemoveDocModal
          onClickConfirm={handleConfirmRemove}
          onClickCancel={handleCloseRemoveConfirmationModal}
        />
      )}
      {isEditModalVisible && (
        <EditDocumentModal
          onClickSave={handleSaveEdits}
          onClickCancel={handleCloseEditModal}
          {...getEditDocumentProps(editedFile!)}
        />
      )}
    </>
  );
};

type ModalButtonProps = {
  onClick: () => void;
};

const AddToProjectButton: FC<ModalButtonProps & { disabled: boolean }> = ({ onClick, disabled = false }) => {
  const { spacing } = useThemeTokens();
  return (
    <Button
      size="small"
      variant="secondary"
      disabled={disabled}
      onClick={onClick}
      ml={spacing.inner.base04}
      dataAttributes={{ "data-testid": DataTestIds.addToProjectButton }}
    >
      {ADD_TO_PROJECT}
    </Button>
  );
};

const CancelButton: FC<ModalButtonProps> = ({ onClick }) => (
  <Button size="small" variant="ghost" onClick={onClick} dataAttributes={{ "data-testid": DataTestIds.cancelButton }}>
    {CANCEL}
  </Button>
);

const UploadMoreButton: FC<ModalButtonProps> = ({ onClick }) => (
  <Button
    size="small"
    variant="outline"
    onClick={onClick}
    dataAttributes={{ "data-testid": DataTestIds.uploadMoreButton }}
  >
    <S.UploadMoreButtonWrapper>
      <Icon size="small">
        <Upload />
      </Icon>
      {UPLOAD_MORE}
    </S.UploadMoreButtonWrapper>
  </Button>
);

export { ThirdPartyDocUploadModal as default, DataTestIds };
