import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const TotalCredits = ({ totalCredits = 0 }: { totalCredits?: number }) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div
      alignItems="center"
      display="grid"
      columnGap="10px"
      gridTemplateColumns="160px 140px 174px 58px 87px"
      py={inner.base05}
      ml={inner.base06}
    >
      <x.div display="flex" gap="2" alignItems="center">
        <Typography variant="pre-title" color={text.strong._}>
          TOTAL
        </Typography>
      </x.div>
      <x.div />
      <x.div />
      <x.div />
      <Typography color={text.strong._} variant="body-small" textAlign="center">
        {totalCredits}
      </Typography>
      <x.div />
    </x.div>
  );
};

export default TotalCredits;
