import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { CommentSkeleton } from "./CommentSkeleton";

export const NoCommentsYet = ({ showMobileView, children }) => {
  const { color } = useThemeTokens();
  const { container } = useNoCommentsYetStyles();

  if (showMobileView) {
    return (
      <x.div {...container}>
        <Typography variant="body-em" color="secondary">
          No comments yet
        </Typography>
        <Typography variant="body" color="secondary" textAlign="center">
          Send your AlphaSights team a comment about this expert.
        </Typography>
      </x.div>
    );
  }

  return (
    <x.div data-testid="no-comments-section" display="flex" flexDirection="column">
      <x.div display="flex">
        <x.div display="grid" gridTemplateColumns="55px" gridTemplateRows="64px" mx="auto" pt="10px">
          <x.div>
            <CommentSkeleton />
          </x.div>
          <x.div gridColumn="2" gridRow="2">
            <CommentSkeleton />
          </x.div>
        </x.div>
      </x.div>
      <Typography component="div" variant="body-small" color={color.text.secondary} mx="auto" pt="11px">
        {children || "No comments yet"}
      </Typography>
    </x.div>
  );
};

const useNoCommentsYetStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const container = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    gap: inner.base03,
    px: inner.base06,
  };

  return {
    container,
  };
};
