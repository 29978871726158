import {
  Button,
  ContentCard,
  Icon,
  IconButton,
  Pill,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { Angle, Expert, MoreVert } from "@alphasights/alphadesign-icons";
import { HitOrigin } from "@alphasights/portal-api-client";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";
import { compact } from "lodash";
import { useMemo, useRef, useState } from "react";
import { getStateName } from "../../components/AdvisorCard/stateFunctions";
import { groupedRepresentationForExperts } from "../../components/InteractionsPage/helpers/Interaction";
import * as S from "./InteractionsList.styled";
import { CancelRequestPopup } from "./RequestModal";
import { x } from "@xstyled/styled-components";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export const InteractionsList = ({
  experts,
  onCancelRequest,
  onRequestCancel,
  onRequestReschedule,
  onRequestFollowUp,
  onSchedule,
  loading,
  selectedInteractionIds,
  onToggleInteractionId,
  onRequestMoreAvailability,
  onSelectCard,
  clientRequests,
  selectedTab,
  appliedFilters = {},
  onResetAllFilters,
  footer,
  allowMenuActions = true,
}) => {
  const [cancellingId, setCancellingId] = useState(null);
  const hasFiltersApplied = Object.keys(appliedFilters).find(
    (key) => appliedFilters[key] && appliedFilters[key].length > 0
  );

  const cards = groupedRepresentationForExperts(experts);

  return (
    <>
      {loading && (
        <S.LoadingWrapper>
          <CardLoader />
          <CardLoader />
          <CardLoader />
        </S.LoadingWrapper>
      )}
      {!loading && (
        <S.MainContainer data-testid="main-container">
          {cards.length > 0 && (
            <S.CardsContainer data-testid="cards-container">
              {cards.map((card) => (
                <InteractionCard
                  key={`card-${card.id}`}
                  interaction={card}
                  onCancelRequest={onCancelRequest}
                  onRequestReschedule={onRequestReschedule}
                  onRequestCancel={() => setCancellingId(card.id)}
                  selectedInteractionIds={selectedInteractionIds}
                  onToggleInteractionId={onToggleInteractionId}
                  onRequestFollowUp={onRequestFollowUp}
                  onSchedule={onSchedule}
                  onRequestMoreAvailability={onRequestMoreAvailability}
                  onSelectCard={onSelectCard}
                  othersCounts={card.othersCounts}
                  pendingRequest={clientRequests && clientRequests.find((req) => req.interactionId === card.id)}
                  allowMenuActions={allowMenuActions}
                />
              ))}
            </S.CardsContainer>
          )}
          {cards.length === 0 && (
            <S.EmptyListContainer data-testid="calendar-empty-list">
              <div>{emptyMessages[selectedTab]}</div>
              {hasFiltersApplied && (
                <S.EmptyListButton data-testid="clear-filters" onClick={onResetAllFilters}>
                  Clear Filters
                </S.EmptyListButton>
              )}
            </S.EmptyListContainer>
          )}
          {footer}
        </S.MainContainer>
      )}
      {cancellingId && (
        <CancelRequestPopup
          id={cancellingId}
          onClose={() => setCancellingId(null)}
          onSubmit={onRequestCancel}
          origin={HitOrigin.calendarView}
        />
      )}
    </>
  );
};

const InteractionCard = ({
  interaction,
  onCancelRequest,
  onRequestCancel,
  onRequestReschedule,
  selectedInteractionIds,
  onRequestFollowUp,
  onToggleInteractionId,
  onRequestMoreAvailability,
  pendingRequest,
  onSelectCard,
  allowMenuActions = true,
}) => {
  const [hoveringCard, setHoveringCard] = useState(false);
  const ref = useRef();
  const companyRef = useRef(null);
  const roleRef = useRef(null);
  const isCompanyOverflow = useIsOverflow(companyRef);
  const isRoleOverflow = useIsOverflow(roleRef);

  const capitalizeFirstLetter = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const getStatePillVariant = (state) =>
    ({
      proposed: "light",
      requested: "yellow",
      completed: "green",
      scheduled: "violet",
    }[state]);

  const { color, spacing } = useThemeTokens();
  const isSelected = selectedInteractionIds.includes(interaction.id);
  const pillVariant = getStatePillVariant(interaction.state);

  const menuActions = useMemo(() => {
    if (!allowMenuActions) return [];
    return compact([
      interaction.canCancel && interaction.state === "requested" && cancelLabel,
      interaction.state === "scheduled" && requestCancelLabel,
      interaction.state === "scheduled" && requestRescheduleLabel,
    ]);
  }, [allowMenuActions, interaction.canCancel, interaction.state]);

  const runAction = (actionName, event) => {
    event.stopPropagation();

    actionName === cancelLabel && onCancelRequest && onCancelRequest(interaction.id, HitOrigin.calendarView);
    actionName === requestCancelLabel && onRequestCancel && onRequestCancel(interaction.id);
    actionName === requestRescheduleLabel && onRequestReschedule && onRequestReschedule(interaction.id);
  };

  const openFlyout = onSelectCard
    ? () => {
        if (!isSelected) {
          onToggleInteractionId && onToggleInteractionId(interaction.id);
        }
        onSelectCard && onSelectCard(interaction);
      }
    : undefined;

  const stopPropagation = (cb) => (e) => {
    e.stopPropagation();
    cb && cb();
  };

  const angle = interaction.angles[0];

  const onMouseEnter = () => setHoveringCard(true);
  const onMouseLeave = () => setHoveringCard(false);

  return (
    <ContentCard
      ref={ref}
      style={{
        position: "relative",
        cursor: "pointer",
      }}
      data-testid={`card-${interaction.id}`}
      onClick={() => onToggleInteractionId && onToggleInteractionId(interaction.id)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      selected={isSelected}
      aria-selected={isSelected}
    >
      <S.StyledBetweenDiv data-testid="between-div">
        <div>
          <x.div maxWidth="380px" display="flex" flexWrap="wrap" alignItems="center">
            <Tooltip title={`${interaction.advisorCompany}`} size="small" disabled={!isCompanyOverflow}>
              <x.div ref={companyRef} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" minWidth={0}>
                <Typography variant="body-em" component="span" color={color.text.strong._}>
                  {interaction.advisorCompany}&nbsp;
                </Typography>
              </x.div>
            </Tooltip>

            <Tooltip title={interaction.role} size="small" disabled={!isRoleOverflow}>
              <x.div
                ref={roleRef}
                flex="1 1 70%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                minWidth={0}
              >
                <Typography variant="body" component="span" color={color.text.strong._}>
                  {` - ${interaction.role}`}
                </Typography>
              </x.div>
            </Tooltip>
          </x.div>
          <Typography variant="body-small" color={color.text.secondary} component="div">
            {interaction.rolePeriod}
          </Typography>

          <S.StyledDiv>
            <Icon color={color.text.assistive} size="medium">
              <Angle />
            </Icon>
            <Typography
              marginLeft={spacing.layout.base}
              color={color.text.assistive}
              variant="body-small"
              component="span"
            >
              {angle?.parent?.title ? `${angle.parent.title}, ` : ""}
              {angle?.title}&nbsp;&nbsp;|&nbsp;&nbsp;
            </Typography>

            <TooltipStateDetail interaction={interaction} pendingRequest={pendingRequest}>
              <Pill variant={pillVariant} size="x-small">
                <Typography variant="body-small" component="span">
                  {capitalizeFirstLetter(getStateName({ ...interaction, pendingRequest, showCalendarView: true }))}
                </Typography>
              </Pill>
            </TooltipStateDetail>

            <Typography color={color.text.assistive} variant="body-small" component="span">
              &nbsp;&nbsp;•&nbsp;&nbsp;{interaction.advisorName}
            </Typography>
          </S.StyledDiv>

          <div
            style={{
              position: "absolute",
              top: 0,
              right: "3px",
              padding: "16px",
              display: hoveringCard ? "flex" : "none",
              lineHeight: 0,
            }}
            data-testid="menus"
          >
            <div
              data-testid="interaction-outer-div"
              style={{
                width: 20,
                height: 24,
              }}
            />
            <div data-testid="interaction-div" style={{ height: 24, width: 16 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: 35,
              }}
            >
              {openFlyout && (
                <div style={{ paddingTop: 6 }}>
                  <div
                    style={{
                      width: 30,
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 4,
                      margin: -4,
                    }}
                    data-testid="open-flyout"
                    onClick={stopPropagation(openFlyout)}
                  >
                    <x.div display="flex" alignItems="center">
                      <Tooltip title="View expert profile" size="small">
                        <IconButton variant="ghost" color={color.icon.assistive} size="large">
                          <Expert />
                        </IconButton>
                      </Tooltip>
                    </x.div>
                  </div>
                </div>
              )}

              {menuActions.length === 0 && <div />}

              {menuActions.length > 0 && (
                <div
                  style={{
                    display: hoveringCard ? "block" : "none",
                  }}
                  data-testid="options"
                >
                  <Button
                    data-testid="options-button"
                    size="small"
                    variant="icon"
                    dropdown
                    splitPopoverContent={
                      <div>
                        {menuActions.map((actionName) => (
                          <S.MenuAction
                            key={actionName}
                            onClick={(event) => {
                              event.stopPropagation();
                              runAction(actionName, event);
                            }}
                            role="menuitem"
                          >
                            {actionName}
                          </S.MenuAction>
                        ))}
                      </div>
                    }
                    startIcon={
                      <IconButton color="assistive" variant="ghost" size="small">
                        <MoreVert data-testid="dots-button" />
                      </IconButton>
                    }
                    aria-label="Options"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </S.StyledBetweenDiv>

      {interaction.showFollowUp && onRequestFollowUp && (
        <Button
          size="small"
          onClick={stopPropagation(() => onRequestFollowUp({ id: interaction.id, origin: HitOrigin.calendarView }))}
          marginTop="4px"
        >
          <Typography variant="body-em" component="span">
            Follow-up
          </Typography>
        </Button>
      )}
      {isSelected && interaction.state === "requested" && onRequestMoreAvailability && (
        <Button
          size="small"
          variant="secondary"
          onClick={stopPropagation(onRequestMoreAvailability)}
          marginTop={spacing.layout.base}
        >
          <Typography variant="body-em" component="span">
            {interaction.clientTimeslots.length > 0 ? "Edit my availability" : "Request more availability"}
          </Typography>
        </Button>
      )}
    </ContentCard>
  );
};

const cancelLabel = "Cancel Request";
const requestRescheduleLabel = "Reschedule Interaction...";
const requestCancelLabel = "Cancel Interaction...";

const CardLoader = () => (
  <S.StyledContent viewBox="0 0 460 80" data-testid="interaction-card-placeholder">
    <rect x="17" y="15" width="150" height="18" />
    <rect x="177" y="15" width="265" height="18" />

    <rect x="17" y="50" width="100" height="18" />
    <rect x="127" y="50" width="100" height="18" />
    <rect x="237" y="50" width="205" height="18" />
  </S.StyledContent>
);

const emptyMessages = {
  requested: "No requested experts available",
  today: "No experts available today",
  "this week": "No experts available this week",
  all: "No experts available",
};
