import { forwardRef, useEffect, useState } from "react";
import { Accordion, AccordionItem, Button, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Close } from "@alphasights/alphadesign-icons";

import { filterDefinitions, mergeStatusOptions } from "./ExpertFilters";
import { useInteractionsFilterStyles } from "./InteractionsFilter.styles";
import { FilterItemOptions } from "./FilterItemOptions";

export const InteractionsFilter = forwardRef(
  (
    { transcriptEnabled, options, appliedFilters, onSubmit, onClose, onResetAll, project, workstream, currentView },
    ref
  ) => {
    const [filters, setFilters] = useState({ ...appliedFilters });
    const [filterOptions, setFilterOptions] = useState([]);

    const hasSurveyAndCallFilters = (options) =>
      options.find((o) => o.name === "survey_response_status") && options.find((o) => o.name === "status");

    const genericOptionsFor = (options) => {
      return !hasSurveyAndCallFilters(options)
        ? options
        : options.filter((o) => !["status", "survey_response_status"].includes(o.name));
    };

    useEffect(() => {
      setFilterOptions(
        filterDefinitions({
          options,
          appliedFilters,
          project,
          moreFilters: true,
          workstream,
          isComparisonView: currentView === "comparison-view",
        })
      );
      setFilters({ ...appliedFilters });
    }, [
      currentView,
      options,
      appliedFilters,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...Object.keys(appliedFilters).map((key) => appliedFilters[key]),
      project,
      workstream,
    ]);

    const filterApplied = (values, option) => {
      filters[option.name] = values;
    };

    const {
      wrapper,
      headerWrapper,
      contentWrapper,
      filterLabelOnly,
      closeIcon,
      filterAccordionItem,
    } = useInteractionsFilterStyles();

    return options ? (
      <x.div {...wrapper} data-testid="interactions-filters" ref={ref}>
        <x.div {...headerWrapper}>
          <Typography {...filterLabelOnly} variant="body-em">
            FILTERS
          </Typography>
          <x.span
            onClick={() => {
              appliedFilters = filters;
              onClose();
            }}
            {...closeIcon}
          >
            <Close data-testid="close-filter-sidebar" />
          </x.span>
        </x.div>
        <x.div {...contentWrapper}>
          <Accordion w="359">
            {hasSurveyAndCallFilters(filterOptions) && (
              <SurveyAndCallStatusAccordionItem
                appliedFilters={appliedFilters}
                filterOptions={filterOptions}
                filterAppliedCallback={filterApplied}
              />
            )}
            {genericOptionsFor(filterOptions).map((option, index) => (
              <AccordionItem
                key={`item-${index}`}
                data-testid={`interaction-filter-${index}`}
                title={
                  <Typography variant="body-large-em">
                    {option.title +
                      getAppliedFiltersCountFormatter({
                        appliedFilters: option.value,
                      })}
                  </Typography>
                }
                {...filterAccordionItem}
              >
                <FilterContent
                  key={`filter-content-${index}`}
                  filterOption={option}
                  filterAppliedCallback={(values) => filterApplied(values, option)}
                ></FilterContent>
              </AccordionItem>
            ))}
          </Accordion>
        </x.div>
        <InteractionsFilterFormButtons
          onSubmit={() => {
            onSubmit(filters);
          }}
          onResetAll={onResetAll}
        />
      </x.div>
    ) : null;
  }
);

export const FilterContent = ({ filterOption, filterAppliedCallback }) => {
  const [selectedItems, setSelectedItems] = useState(filterOption.value);
  useEffect(() => {
    setSelectedItems(filterOption.value);
  }, [filterOption]);

  useEffect(() => {
    filterAppliedCallback(selectedItems);
  }, [filterAppliedCallback, selectedItems]);

  return (
    <FilterItemOptions
      filterName={filterOption.name}
      filterOptions={filterOption.options}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
    />
  );
};

const InteractionsFilterFormButtons = ({ onSubmit, onResetAll }) => {
  const { buttonsWrapper, clearAll } = useInteractionsFilterStyles();

  const buttonSize = "medium";

  return (
    <x.div {...buttonsWrapper}>
      <Button
        data-testid="interactions-filters-clear-button"
        variant="ghost"
        size={buttonSize}
        onClick={() => {
          onResetAll();
        }}
        {...clearAll}
      >
        Reset
      </Button>
      <Button
        data-testid="interactions-filters-apply-button"
        flexGrow={0}
        variant="secondary"
        onClick={onSubmit}
        size={buttonSize}
      >
        Apply
      </Button>
    </x.div>
  );
};

export const SurveyAndCallStatusAccordionItem = ({
  appliedFilters,
  filterOptions,
  filterAppliedCallback,
  size = "medium",
}) => {
  const { filterAccordionItem } = useInteractionsFilterStyles();

  const mergedStatusOption = mergeStatusOptions(filterOptions, appliedFilters);
  return (
    <AccordionItem
      size={size}
      title={
        <Typography variant="body-large-em">
          {"Status" +
            getAppliedFiltersCountFormatter({
              appliedFilters: [...(appliedFilters.status || []), ...(appliedFilters.survey_response_status || [])],
            })}
        </Typography>
      }
      {...filterAccordionItem}
    >
      <FilterContent
        filterOption={mergedStatusOption}
        filterAppliedCallback={(values) => {
          filterAppliedCallback(
            values.filter((v) => v.startsWith("call+")).map((v) => v.replace("call+", "")),
            { name: "status" }
          );
          filterAppliedCallback(
            values.filter((v) => v.startsWith("survey+")).map((v) => v.replace("survey+", "")),
            { name: "survey_response_status" }
          );
        }}
      ></FilterContent>
    </AccordionItem>
  );
};

const getAppliedFiltersCountFormatter = ({ appliedFilters }) =>
  appliedFilters.length > 0 ? ` (${appliedFilters.length})` : "";
