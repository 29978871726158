import { Modal } from "@alphasights/alphadesign-components";
import styled, { th } from "@xstyled/styled-components";

export const DateTimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${th.space("inner-base04")};
  padding-bottom: ${th.space("inner-base03")};
`;

export const DateTimePickerButtonsWrapper = styled.div`
  display: flex;
  gap: ${th.space("inner-base02")};
  padding: ${th.space("inner-base04")};
`;

export const MobileModal = styled(Modal).attrs(() => ({
  slotWidth: "312px",
  slotHeight: "auto",
  slotPadding: "0px",
}))``;

export const TextInputClone = styled.div`
  font-size: ${th.fontSize("01")};
  line-height: ${th.size("4")};
  border: 1px solid ${th.color("border-neutral-default")};
  border-radius: ${th.radius("default")};
  padding: ${th.space("inner-base02")};
  padding-left: ${th.space("inner-base04")};
  cursor: pointer;
`;
