import { FC, useMemo, useState } from "react";
import { Button, Modal, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useModal } from "@alphasights/ads-community-hooks";
import { Info } from "@alphasights/alphadesign-icons";

import { useMutation, useQueryClient } from "query-utils";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { ThirdPartyDocument, ThirdPartyDocumentAttributes } from "types";
import EditDocumentModal from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal";
import ConfirmRemoveDocModal from "views/DeliverablesView/ThirdPartyDocUploadModal/ConfirmRemoveDocModal";

import DocumentsTable from "./DocumentsTable";
import UploadDocsButton from "./UploadDocsButton";
import { ADD_TO_PROJECT, CANCEL, MODAL_WIDTH, OR, TABLE_TITLE, TITLE, TOOLTIP_TITLE } from "./consts";
import { getEditDocumentProps } from "./utils";

import * as S from "./ThirdPartyAssignDocsModal.styled";

export const DataTestIds = {
  addToProjectButton: "add-docs-modal-add-to-project-button",
  cancelButton: "add-docs-modal-cancel-button",
  uploadDocsButton: "add-docs-modal-upload-docs-button",
};

type ThirdPartyAssignDocsModalProps = {
  unassignedDocuments: ThirdPartyDocument[];
  onClose: () => void;
  onClickUpload: () => void;
};

const ThirdPartyAssignDocsModal: FC<ThirdPartyAssignDocsModalProps> = ({
  unassignedDocuments,
  onClose,
  onClickUpload,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [editedDocumentId, setEditedDocumentId] = useState<string | null>(null);
  const [documentIdToRemove, setDocumentIdToRemove] = useState<string | null>(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);

  const { color } = useThemeTokens();
  const queryClient = useQueryClient();
  const { updateDocumentAttributes, deleteDocument, addProjectIdToDocument } = useDeliverableContext();
  const { isVisible: isEditModalVisible, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useModal();
  const {
    isVisible: isRemoveConfirmationModalVisible,
    onOpen: onOpenRemoveConfirmationModal,
    onClose: onCloseRemoveConfirmationModal,
  } = useModal();

  const editedDocument = useMemo(() => unassignedDocuments.find((doc) => doc.id === editedDocumentId), [
    unassignedDocuments,
    editedDocumentId,
  ]);
  const isAddDisabled = !selectedDocumentIds.length;

  const refetchDocuments = () => {
    queryClient.invalidateQueries(["fetchUnassignedEmailUploads"]);
  };

  const updateThirdPartyDocumentAttributesMutation = useMutation(
    ({ documentId, attributes }: { documentId: string; attributes: Partial<ThirdPartyDocumentAttributes> }) => {
      const {
        attribute: { documentDate, title, experts },
      } = unassignedDocuments.find((doc) => doc.id === documentId)!;
      const updatedAttributes = {
        title,
        documentDate,
        experts,
        ...attributes,
      };
      return updateDocumentAttributes(documentId, updatedAttributes);
    },
    {
      onSettled: () => {
        refetchDocuments();
      },
    }
  );

  const deleteDocumentMutation = useMutation((documentId: string) => deleteDocument(documentId), {
    onSuccess: () => {
      refetchDocuments();
    },
    onSettled: () => {
      setDocumentIdToRemove(null);
    },
  });

  const assignToProjectMutation = useMutation((documentId: string) => addProjectIdToDocument(documentId));

  const saveFileEdits = (attributes: ThirdPartyDocumentAttributes) => {
    const documentId = unassignedDocuments.find((doc) => doc.id === editedDocumentId)!.id;
    try {
      updateThirdPartyDocumentAttributesMutation.mutateAsync({ documentId, attributes });
    } catch (error) {
      console.error("Unable to save edits");
    } finally {
      setEditedDocumentId(null);
    }
  };

  const hideModal = () => setIsOpen(false);
  const unhideModal = () => setIsOpen(true);

  const handleSelectAngle = (id: string, value: string) => {
    const doc = unassignedDocuments.find((doc) => doc.id === id)!;
    // always assign angle to first expert
    const experts = doc.attribute.experts?.map((expert, index) => (index === 0 ? { ...expert, angle: value } : expert));
    updateThirdPartyDocumentAttributesMutation.mutateAsync({ documentId: id, attributes: { experts } });
  };

  const handleClickDelete = (id: string) => {
    setDocumentIdToRemove(id);
    hideModal();
    onOpenRemoveConfirmationModal();
  };

  const handleClickEdit = (id: string) => {
    setEditedDocumentId(id);
    hideModal();
    onOpenEditModal();
  };

  const handleCloseRemoveConfirmationModal = () => {
    onCloseRemoveConfirmationModal();
    unhideModal();
    setDocumentIdToRemove(null);
  };

  const handleConfirmRemove = () => {
    handleCloseRemoveConfirmationModal();
    deleteDocumentMutation.mutateAsync(documentIdToRemove!);
  };

  const handleCloseEditModal = () => {
    onCloseEditModal();
    unhideModal();
  };

  const handleSaveEdits = (attributes: ThirdPartyDocumentAttributes) => {
    handleCloseEditModal();
    saveFileEdits(attributes);
  };

  const handleAddToProject = () => {
    Promise.all(selectedDocumentIds.map((documentId) => assignToProjectMutation.mutateAsync(documentId)))
      .catch((error) => {
        console.error("An error occurred adding documents to the project", error);
      })
      .then(() => {
        queryClient.invalidateQueries(["fetchThirdPartyDocuments", "fetchUnassignedEmailUploads"]);
      });
    onClose();
  };

  return (
    <>
      <Modal
        title={
          <Typography variant="body-large-em" color={color.text.strong._}>
            {TITLE}
          </Typography>
        }
        size="small"
        variant="complex"
        slotWidth={MODAL_WIDTH}
        slotPadding="0"
        transition="opacity 0.3s ease-out"
        open={isOpen}
        onClose={onClose}
        shouldShowFooter={true}
        primaryButton={<AddToProjectButton onClick={handleAddToProject} disabled={isAddDisabled} />}
        secondaryButton={<CancelButton onClick={onClose} />}
      >
        <UploadDocsButton onClick={onClickUpload} dataAttributes={{ "data-testid": DataTestIds.uploadDocsButton }} />
        <S.SeparatorSection>
          <S.StyledDivider />
          <Typography variant="body-small-em" color={color.text.secondary}>
            {OR}
          </Typography>
          <S.StyledDivider />
        </S.SeparatorSection>
        <S.TableWrapper>
          <S.TitleWrapper>
            <Typography variant="body-small-em">{TABLE_TITLE}</Typography>
            <Tooltip title={TOOLTIP_TITLE} variant="dark" size="small">
              <S.StyledIcon>
                <Info />
              </S.StyledIcon>
            </Tooltip>
          </S.TitleWrapper>
          <DocumentsTable
            documents={unassignedDocuments}
            onSelectionChange={setSelectedDocumentIds}
            onSelectAngle={handleSelectAngle}
            onClickDelete={handleClickDelete}
            onClickEdit={handleClickEdit}
          />
        </S.TableWrapper>
      </Modal>
      {isEditModalVisible && (
        <EditDocumentModal
          onClickSave={handleSaveEdits}
          onClickCancel={handleCloseEditModal}
          {...getEditDocumentProps(editedDocument!)}
        />
      )}
      {isRemoveConfirmationModalVisible && (
        <ConfirmRemoveDocModal
          onClickConfirm={handleConfirmRemove}
          onClickCancel={handleCloseRemoveConfirmationModal}
        />
      )}
    </>
  );
};

type ModalButtonProps = {
  onClick: () => void;
};

const AddToProjectButton: FC<ModalButtonProps & { disabled: boolean }> = ({ onClick, disabled = false }) => {
  const { spacing } = useThemeTokens();
  return (
    <Button
      size="small"
      variant="secondary"
      disabled={disabled}
      onClick={onClick}
      ml={spacing.inner.base04}
      dataAttributes={{ "data-testid": DataTestIds.addToProjectButton }}
    >
      {ADD_TO_PROJECT}
    </Button>
  );
};

const CancelButton: FC<ModalButtonProps> = ({ onClick }) => (
  <Button size="small" variant="ghost" onClick={onClick} dataAttributes={{ "data-testid": DataTestIds.cancelButton }}>
    {CANCEL}
  </Button>
);

export default ThirdPartyAssignDocsModal;
