import { FC, useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { offset } from "@floating-ui/react-dom";
import { ChevronDown, ChevronUp, Close, Search, MoreVert } from "@alphasights/alphadesign-icons";
import { Icon, useThemeTokens, IconButton, Popover, Typography } from "@alphasights/alphadesign-components";
import BookmarkButton from "components/BookmarkButton";
import { RequestExpertButton } from "pages/AlphaNowPage/components";
import { RequestExpertButtonType } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions/constants";
import { TranscriptSearchBarWithAccess } from "components/SearchBar";
import { CONTENT_TYPE, SEARCH_SUGGESTION_TYPES, AlphaNowContentType } from "@alphasights/client-portal-shared";
import { Variant, withAccessControl } from "components/AccessControl/AccessControl";
import { editRequestContent, requestExpertContent } from "content/AlphaNow";
import { View } from "models/Header";
import { noResultsContent, searchWithinTranscript } from "content/AlphaNow";
import usePopover from "hooks/usePopover";
import { useTranscriptActionBarStyles } from "./TranscriptActionBar.styles";
import CopyLinkButton from "components/CopyLinkButton";
import DownloadButton from "pages/AlphaNowPage/components/AlphaNowTranscript/DownloadButton";
import ReturnButton from "pages/AlphaNowPage/components/ReturnButton/ReturnButton";
import AudioButton from "components/AudioButton";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import useContentApi from "components/BookmarkButton/useContentApi";
import useContentClientSettings from "hooks/useContentClientSettings";
import { MANAGE_CONTENT_PERMISSION } from "constants/AlphaNow";
import { OnBookmarkChangedProps } from "components/BookmarkButton/useContentApi";

const DataTestIds = {
  morevertButton: "morevert-mobile-button",
};

const ButtonWithAccess = withAccessControl(IconButton);

type ValueOf<T> = T[keyof T];

interface SearchQueryType {
  label: string;
  value: string;
  type: ValueOf<typeof SEARCH_SUGGESTION_TYPES>;
}
interface keywordSearchPropsType {
  totalKeywordMatches?: number;
  selectedMentionIndex?: number;
  setSelectedMentionIndex: (value: number) => void;
}

interface Rects {
  reference: {
    width: number;
    height: number;
  };
}

interface TranscriptActionBarProps {
  isTranscriptDataReady?: boolean;
  hasKeywords?: boolean;
  searchQuery?: SearchQueryType[];
  keywordSearchProps: keywordSearchPropsType;
  viewMode: string;
  contentTypePermission: string;
  detailsCollapsed: boolean;
  showTranscript: boolean;
  contentType: AlphaNowContentType;
  contentId: string;
  downloadFileName: string;
  isAccessible: boolean;
  audioDownloadStatus: string;
  isAudioAvailable: boolean;
  isRequestExpertLoading: boolean;
  expertRequestExists: boolean;
  setSearchQuery: (query: SearchQueryType[]) => void;
  setDetailsCollapsed: (value: boolean) => void;
  onReturnToMobileSearch: () => void;
  onBookmarkChanged: (props: OnBookmarkChangedProps) => void;
  onAudioSelect: () => void;
  openRequestExpertOverlay: () => void;
}

const TranscriptActionBarMobile: FC<TranscriptActionBarProps> = ({
  isTranscriptDataReady = false,
  hasKeywords = false,
  searchQuery = [],
  keywordSearchProps: { totalKeywordMatches = 0, selectedMentionIndex = 0, setSelectedMentionIndex },
  viewMode,
  contentTypePermission,
  detailsCollapsed,
  showTranscript,
  contentType,
  contentId,
  downloadFileName,
  isAccessible,
  audioDownloadStatus,
  isAudioAvailable,
  isRequestExpertLoading,
  expertRequestExists,
  setSearchQuery,
  setDetailsCollapsed,
  onReturnToMobileSearch,
  onBookmarkChanged,
  onAudioSelect,
  openRequestExpertOverlay,
}) => {
  const {
    anchor: anchorMobileButtons,
    onClose: onClosePopover,
    onClick: handlePopoverClick,
    ref: mobileButtonsExpandRef,
  } = usePopover();

  const [isMobileSearchActive, setIsMobileSearchActive] = useState(false);

  const isTranscriptView = viewMode === View.Transcript;
  const isKeywordFilterEnabled = isTranscriptView;

  const { hasUserBadge } = useUserBadgeContext();
  const { clientSettings } = useContentClientSettings();

  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();
  // TODO [CON-2281][CON-2282][CON-2283] add tests once buttons are added back
  const {
    transcriptActionBarContainer: transcriptActionBarContainerStyles,
    chevronContainer: chevronContainerStyles,
  } = useTranscriptActionBarStyles({ isMobile: true });
  // TODO [CON-2281][CON-2282][CON-2283] temporarily hide transcript actions
  const isAlphaView = contentType === CONTENT_TYPE.alphaview;
  const isPrivateLibrary = contentType === CONTENT_TYPE.privateEnterprise;
  const hasPrivateTranscriptDownloadBadge = hasUserBadge(Badge.privateTranscriptDownload);
  const isDownloadButtonAccessible = isAccessible && !!clientSettings?.downloadResearch;
  const isDownloadButtonVisible =
    (isAlphaView || (isPrivateLibrary && hasPrivateTranscriptDownloadBadge)) && isDownloadButtonAccessible;
  const isRequestExpertButtonDisabled = !clientSettings?.requestExperts;

  const loadKeywords = async (typedTerm: string) => {
    return [
      {
        label: typedTerm,
        value: typedTerm,
      },
    ];
  };

  const onOptionSelect = async (searchQuery: SearchQueryType[]) => {
    const query = searchQuery ?? [];
    setSearchQuery(query);
  };

  const findNextMatch = () => {
    const isLastKeywordSelected = selectedMentionIndex === totalKeywordMatches - 1;
    const nextIndex = isLastKeywordSelected ? 0 : selectedMentionIndex + 1;
    setSelectedMentionIndex(nextIndex);
  };

  const findPreviousMatch = () => {
    const isFirstKeywordSelected = selectedMentionIndex === 0;
    const previousIndex = isFirstKeywordSelected ? totalKeywordMatches - 1 : selectedMentionIndex - 1;
    setSelectedMentionIndex(previousIndex);
  };

  const handleMobilePopoverOffset = ({ rects }: { rects: Rects }) => {
    //Values necessary to pin content on the upperLeftCorner
    const mainAxisWidth = 60;
    const crossAxisHeight = 100;

    return {
      mainAxis: -rects.reference.width + mainAxisWidth,
      crossAxis: rects.reference.height - crossAxisHeight,
    };
  };

  const onHandleMobileSearchView = (isVisible: boolean) => {
    setIsMobileSearchActive(isVisible);
  };

  useEffect(() => {
    if (totalKeywordMatches !== null && totalKeywordMatches < selectedMentionIndex) {
      setSelectedMentionIndex(0);
    }
  }, [totalKeywordMatches]); // eslint-disable-line react-hooks/exhaustive-deps

  const TranscriptSearchBar = () => (
    <x.div display="flex" flex="1 0 0">
      <x.span flex="1 0 0" overflow="hidden">
        <TranscriptSearchBarWithAccess
          accessControl={{ allowedPermissions: [contentTypePermission] }}
          loadOptions={loadKeywords}
          onChange={onOptionSelect}
          placeholder={searchWithinTranscript}
          showSeparator={isTranscriptView && hasKeywords}
          value={searchQuery}
        />
      </x.span>
      {isKeywordFilterEnabled && isTranscriptDataReady && hasKeywords && totalKeywordMatches !== null && (
        <Typography component="span" color={text.secondary} variant="body-em" display="flex" alignItems="center">
          {totalKeywordMatches > 1 && (
            <x.div display="inherit" pl={inner.base03} gap={inner.base03}>
              <Icon size="medium" onClick={findNextMatch}>
                <ChevronDown />
              </Icon>
              <Icon size="medium" onClick={findPreviousMatch}>
                <ChevronUp />
              </Icon>
            </x.div>
          )}

          {totalKeywordMatches > 0 ? (
            <x.div color={text.secondary} pl={inner.base03}>
              <x.span color={text.danger}>{selectedMentionIndex + 1}</x.span>
              <x.span>/{totalKeywordMatches}</x.span>
            </x.div>
          ) : (
            <x.span color={text.danger} pl={inner.base04}>
              {noResultsContent}
            </x.span>
          )}
        </Typography>
      )}
    </x.div>
  );

  const { isBookmarked, onToggleBookmark } = useContentApi(contentId, contentType, onBookmarkChanged);

  if (isMobileSearchActive) {
    return (
      <x.div {...transcriptActionBarContainerStyles}>
        <x.div flex row alignItems="center" my={inner.base06} spaceX={inner.base04}>
          <TranscriptSearchBar />
        </x.div>
        <x.div pl={inner.base04} display="flex" alignItems="center">
          <IconButton
            onClick={() => onHandleMobileSearchView(false)}
            key="rounded-button"
            color={text.secondary}
            testId="close-mobile-button"
            variant="ghost"
          >
            <Close />
          </IconButton>
        </x.div>
      </x.div>
    );
  }

  return (
    <x.div {...transcriptActionBarContainerStyles} justifyContent="space-between">
      <ReturnButton
        onReturn={onReturnToMobileSearch}
        variant="ghost"
        style={{ ml: `-${inner.base}`, display: "flex" }}
      />
      {showTranscript && (
        <IconButton
          onClick={() => onHandleMobileSearchView(true)}
          color={text.secondary}
          testId="search-mobile-button"
          variant="ghost"
        >
          <Search />
        </IconButton>
      )}
      {isAudioAvailable && (
        <AudioButton onAudioClick={onAudioSelect} audioDownloadStatus={audioDownloadStatus} variant="ghost" />
      )}
      <BookmarkButton
        permissions={[MANAGE_CONTENT_PERMISSION]}
        isBookmarked={isBookmarked}
        onToggleBookmark={onToggleBookmark}
        variant="ghost"
      />
      <IconButton
        variant="ghost"
        onClick={handlePopoverClick}
        color={text.secondary}
        testId={DataTestIds.morevertButton}
      >
        <MoreVert />
      </IconButton>
      <Popover
        closeOnMouseLeave={false}
        ref={mobileButtonsExpandRef}
        anchorEl={anchorMobileButtons}
        open={!!anchorMobileButtons}
        onClose={onClosePopover}
        middleware={[offset(handleMobilePopoverOffset)]}
      >
        <x.div p={inner.base}>
          <CopyLinkButton
            contentId={contentId}
            contentType={contentType}
            asListOption
            onCloseMobilePopover={onClosePopover}
          />
          {isDownloadButtonVisible && (
            <DownloadButton
              asListOption
              contentId={contentId}
              downloadFileName={downloadFileName}
              onCloseMobilePopover={onClosePopover}
              size="large"
            />
          )}
        </x.div>
      </Popover>
      <RequestExpertButton
        buttonActionVariant={RequestExpertButtonType.openModal}
        text={expertRequestExists ? editRequestContent : requestExpertContent}
        onClick={openRequestExpertOverlay}
        loading={isRequestExpertLoading}
        disabled={isRequestExpertButtonDisabled}
      />
      {showTranscript && (
        <x.div {...chevronContainerStyles}>
          <ButtonWithAccess
            accessControl={{
              allowedPermissions: [contentTypePermission],
              variant: Variant.Opaque,
            }}
            color={text.secondary}
            onClick={() => setDetailsCollapsed(!detailsCollapsed)}
            variant="ghost"
          >
            {detailsCollapsed ? <ChevronDown data-testid="chevron-down" /> : <ChevronUp data-testid="chevron-up" />}
          </ButtonWithAccess>
        </x.div>
      )}
    </x.div>
  );
};

export { TranscriptActionBarMobile as default, DataTestIds };
