import { FC } from "react";

import {
  TITLE,
  HEADCOUNT_MARKS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HeadcountFilter/constants";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { SliderFilter } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import { SliderRange } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/SliderFilter";
import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";

import "./index.css";

interface HeadcountFilterProps extends FilterProps {
  value: SliderRange;
}

const HeadcountFilter: FC<HeadcountFilterProps> = ({ value, onChange }) => (
  <SliderFilter
    id="headcount-filter"
    filterKey={SearchFilters.headcount}
    title={TITLE}
    value={value}
    options={HEADCOUNT_MARKS}
    onChange={onChange}
    enableOverlap
    isAccordion
  />
);

export default HeadcountFilter;
export type { HeadcountFilterProps };
