import { ContentCard } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

interface CustomHoverContentCardProps {
  isMobile?: boolean;
}

export const CustomHoverContentCard = styled(ContentCard)<CustomHoverContentCardProps>`
  ${({ isMobile }) =>
    isMobile &&
    `
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
  `}
`;
