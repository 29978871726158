import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as React from "react";
import { TextArea, Typography } from "@alphasights/alphadesign-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { x } from "@xstyled/styled-components";
import { removeSurroundQuotes } from "../../../../utils/quotes";

export const EditableInput = ({
  input,
  quoted = false,
  onChange,
  onEnterPressed,
  errorMessage,
  placeholder,
}: {
  input: string;
  onChange?: ((event: ChangeEvent<HTMLTextAreaElement>) => void) | undefined;
  quoted?: boolean;
  onEnterPressed?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  errorMessage?: string;
  placeholder?: string;
}) => {
  const { saveInProgress, revision, readOnly } = useProjectSynthesisContext();
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [content, setContent] = useState(input);
  useEffect(() => setContent(input), [input]);
  useEffect(() => {
    if (revision?.status === "FAILED" && ref.current) {
      ref.current.focus();
      ref.current.select();
    }
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div display="flex" flexDirection="column" w="100%">
      <TextArea
        ref={ref}
        value={quoted ? `"${removeSurroundQuotes(content)}"` : content}
        onSubmit={onEnterPressed}
        onChange={(e) => {
          const value = quoted ? removeSurroundQuotes(e.target.value) : e.target.value;
          const target = { ...e.target, value };
          onChange && onChange({ ...e, target });
          setContent(value);
        }}
        isControlled={true}
        width="100%"
        height="unset"
        isDynamic={true}
        disabled={saveInProgress || readOnly}
        error={!!errorMessage?.trim()}
        placeholder={placeholder ? placeholder : undefined}
      />
      {!!errorMessage?.trim() && <Typography color="danger">{errorMessage}</Typography>}
    </x.div>
  );
};
