import { useEffect, useState } from "react";
import { Button, ListOption, ListOptionType, Tooltip } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";
import { useAlphaGPTContext } from "providers/AlphaGPTProvider";

export const AlphaGPTLibrarySelector = () => {
  const { setSelectedLibraries, libraryOptions: libraries } = useAlphaGPTContext();
  const [selectedOption, setSelectedOption] = useState(libraries.find((options) => !options.disabled)?.label);

  useEffect(() => {
    setSelectedLibraries(libraries.find((option) => option.label === selectedOption)?.libraries);
  }, [libraries, selectedOption, setSelectedLibraries]);

  const customContent = libraries.map((library) => ({
    type: "text" as ListOptionType,
    label: library.label,
    secondaryText: library.secondaryText,
    onChange: () => !library.disabled && setSelectedOption(library.label),
    selected: selectedOption === library.label,
    disabled: library.disabled,
    tooltip: library.tooltip,
  }));

  return (
    <div>
      <Button
        data-testid="library-selector-button"
        variant="outline"
        dropdown
        splitPopoverContent={
          <ListOptionContainer>
            {customContent.map(({ tooltip, ...listOptionProps }) => (
              <>
                {tooltip ? (
                  <Tooltip title={tooltip}>
                    <ListOption {...listOptionProps} />
                  </Tooltip>
                ) : (
                  <ListOption {...listOptionProps} />
                )}
              </>
            ))}
          </ListOptionContainer>
        }
        splitPopoverProps={{
          w: "360px",
          placement: "bottom-start",
        }}
      >
        Search {selectedOption}
      </Button>
    </div>
  );
};

export const ListOptionContainer = styled(x.div)`
  & > div > div:first-child {
    max-width: 90%;
    span {
      width: 100%;
      white-space: wrap;
    }
  }
  div div {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;
