import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const SearchContainer = styled.div(() => {
  const { shape, color } = useThemeTokens();
  return {
    display: "flex",
    overflow: "auto",
    flexDirection: "column" as "column",
    minH: "0",
    borderRight: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
  };
});

const SearchBarWrapper = styled.div(({ activeSearchBar }: { activeSearchBar: boolean }) => {
  const { spacing } = useThemeTokens();
  return {
    position: activeSearchBar ? ("static" as "static") : ("sticky" as "sticky"),
    display: "flex",
    flexWrap: "wrap" as "wrap",
    gap: spacing.inner.base,
  };
});

const SearchToolsContainer = styled.div(({ showSearchBar }: { showSearchBar: boolean }) => {
  const { spacing, color } = useThemeTokens();

  return {
    flex: "0 0 auto",
    backgroundColor: color.background.surface.raised,
    //TODO [RD1-209]: Remove when new Navigation enabled
    paddingTop: showSearchBar ? spacing.inner.base06 : 0,
    paddingInline: showSearchBar ? spacing.inner.base08 : spacing.inner.base06,
  };
});

export { SearchBarWrapper, SearchToolsContainer, SearchContainer };
