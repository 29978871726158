import { useRef, useState } from "react";
import * as React from "react";
import { Button, Popover, RoundedButtonProps, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

import { useExpertFiltersStyles } from "./ExpertFilters.styles";
import { ButtonWithMargin } from "@alphasights/client-portal-shared";
import { FilterChip } from "./FilterChip";
import { FilterItemOptions } from "./FilterItemOptions";

export type FilterItemProps = {
  filterDefinition: FilterDefinition;
  onApplyFilter: (values: string[]) => void;
  onClick?: () => void;
  testId?: string;
  getAppliedFiltersCountFormatter?: (props: { appliedFilters: string[] }) => string;
} & RoundedButtonProps;

export const FilterItem = ({
  filterDefinition,
  onApplyFilter: onApplyFilterInput,
  onClick,
  ...props
}: FilterItemProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const ref = useRef<HTMLLIElement>(null);

  const togglePopover = (event?: React.MouseEvent) => {
    if (onClick) return onClick();
    setAnchorEl(!anchorEl ? event?.currentTarget : undefined);
  };

  const closePopover = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);

  const onApplyFilter = (values: string[]) => {
    onApplyFilterInput(values);
    closePopover();
  };

  return (
    <>
      <FilterChip
        title={filterDefinition.title}
        onClick={togglePopover}
        appliedFilters={filterDefinition.value}
        testId={`toggle-filter-${filterDefinition.title}`}
        open={open}
        ref={ref}
        {...props}
      />
      {open && (
        <NoPaddingPopover
          topNavBarHeight={ref.current?.getBoundingClientRect().bottom ?? 0}
          animationFrame={true}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          enterDelay={0}
          portal={false}
        >
          <PopoverContent
            key={`expert-content-${filterDefinition.name}`}
            filterDefinition={filterDefinition}
            onApplyFilter={onApplyFilter}
          />
        </NoPaddingPopover>
      )}
    </>
  );
};

type PopoverContentProps = {
  filterDefinition: FilterDefinition;
  onApplyFilter: (values: string[]) => void;
};

const PopoverContent = ({ filterDefinition, onApplyFilter }: PopoverContentProps) => {
  const [selectedItems, setSelectedItems] = useState(filterDefinition.value);

  const saveFilter = () => {
    onApplyFilter(selectedItems);
  };

  const clearFilter = () => {
    setSelectedItems([]);
    onApplyFilter([]);
  };

  const { buttonsWrapper } = useExpertFiltersStyles();

  return (
    <x.div data-testid="filter-dropdown-content">
      <FilterItemOptions
        filterName={filterDefinition.name}
        filterOptions={filterDefinition.options}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      <x.div {...buttonsWrapper}>
        <ButtonWithMargin variant="ghost" onClick={clearFilter} data-testid="clear-filter-button">
          Reset
        </ButtonWithMargin>
        <Button ml="auto" variant="secondary" onClick={saveFilter} data-testid="save-filter-button">
          Apply
        </Button>
      </x.div>
    </x.div>
  );
};

const NoPaddingPopover = styled(Popover)(({ topNavBarHeight }: { topNavBarHeight: number }) => {
  const {
    spacing: { layout },
  } = useThemeTokens();

  return `
      max-height: calc(100vh - ${topNavBarHeight}px - ${layout.base02});
      overflow-y: auto;
      z-index: 30;

      .body-content {
        padding: 0;
      }
    `;
});
