import * as S from "./ImpersonateTopbar.styled";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Show } from "@alphasights/alphadesign-icons";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";

export const ImpersonateTopbar = () => {
  const user = useCurrentUser();
  const { color, spacing } = useThemeTokens();
  const { logOutCurrentUser } = useClientPortalOperations();

  return (
    <S.ImpersonateTopBarWrapper id="impersonate-topbar">
      <x.div display="flex" alignItems="center" flexDirection="row">
        <Icon size="medium" color="inverse">
          <Show />
        </Icon>
        <Typography marginLeft={spacing.layout.base} color={color.text.inverse} variant="body-small">
          Viewing as {user?.name}
        </Typography>
      </x.div>

      <S.SignOutButton size="small" variant="ghost" onClick={logOutCurrentUser} color={color.text.inverse}>
        Sign Out
      </S.SignOutButton>
    </S.ImpersonateTopBarWrapper>
  );
};
