import { Icon, Tooltip } from "@alphasights/alphadesign-components";
import { Project, ThirdParty } from "@alphasights/alphadesign-icons";
import { useIsOverflow } from "@alphasights/client-portal-shared";
import { useRef } from "react";
import * as S from "./ProjectTitle.styled";
import { ViewInSystem } from "components/DebugSupport";

export const ProjectTitle = ({
  projectTitle,
  isSharedProject = false,
}: {
  projectTitle: string;
  isSharedProject?: boolean;
}) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const isTitleOverflow = useIsOverflow(titleRef);

  return (
    <S.Wrapper data-testid="project-title-wrapper">
      <Icon color="inverse">{isSharedProject ? <ThirdParty /> : <Project />}</Icon>
      <Tooltip disabled={!isTitleOverflow} title={projectTitle}>
        <S.ProjectTitle ref={titleRef}>{projectTitle}</S.ProjectTitle>
      </Tooltip>
      <ViewInSystem />
    </S.Wrapper>
  );
};
