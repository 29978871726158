import { useEffect, useRef, useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import ExpandToggle from "views/SurveysResultsView/components/DataVisualization/components/ExpandToggle";

const ExpandCollapseContainer = ({ children }: { children: JSX.Element }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(true);
  const expandableInnerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const maxHeight = 590;

  useEffect(() => {
    const checkDivHeight = () => {
      if (expandableInnerRef.current && expandableInnerRef.current.offsetHeight > maxHeight) {
        setIsExpandable(true);
      } else {
        setIsExpandable(false);
      }
    };

    checkDivHeight();
    window.addEventListener("resize", checkDivHeight);

    return () => {
      window.removeEventListener("resize", checkDivHeight);
    };
  }, [maxHeight]);

  const toggleExpanded = (isExpanded: boolean) => {
    setIsExpanded(isExpanded);

    if (!isExpanded) expandableInnerRef.current?.scrollIntoView();
  };

  return (
    <x.div
      w="100%"
      maxHeight={isExpanded ? "auto" : `${maxHeight}px`}
      overflow="hidden"
      position="relative"
      transition="max-height 0.3s"
    >
      <div ref={expandableInnerRef}>{children}</div>
      {isExpandable && <ExpandToggle isExpanded={isExpanded} setIsExpanded={toggleExpanded} />}
    </x.div>
  );
};

export default ExpandCollapseContainer;
