import { useCallback } from "react";
import { PrivateLibraryConversation, PrivateLibraryItem, privateLibraryApi } from "@alphasights/portal-api-client";
import { useQuery } from "query-utils";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ENABLE_PAST_PROJECT_DELIVERABLES, useProjectBadgeContext } from "providers/BadgeProvider";

export const usePrivateLibrary = () => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const { project } = useCurrentProjectContext();
  const { data: privateLibraryItems } = useQuery<PrivateLibraryItem[]>(["privateLibrary", project?.token], () =>
    hasProjectBadge(ENABLE_PAST_PROJECT_DELIVERABLES) && project?.token
      ? privateLibraryApi.getPrivateLibrary(project.token)
      : Promise.resolve([])
  );

  const getPrivateLibraryItemFromInteraction = useCallback(
    (interaction: Interaction) => {
      const transcriptRequestIds = interaction.recordings.flatMap((r) => r.transcriptRequests.map((tr) => tr.id));
      return privateLibraryItems?.find((item) => transcriptRequestIds.includes(item.transcriptRequestId));
    },
    [privateLibraryItems]
  );

  return {
    privateLibraryItems: privateLibraryItems ?? [],
    getPrivateLibraryItemFromInteraction,
  };
};

export const usePrivateLibraryContent = ({ interactionId }: { interactionId: string }) => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const { project } = useCurrentProjectContext();
  const isEnabled = hasProjectBadge(ENABLE_PAST_PROJECT_DELIVERABLES) && !!project?.token;

  const { data: privateLibraryContent } = useQuery<PrivateLibraryConversation>(
    ["privateLibraryContent", project?.token, interactionId],
    () => privateLibraryApi.getPrivateLibraryContent(project!.token, interactionId),
    {
      enabled: isEnabled,
    }
  );

  if (!isEnabled) {
    throw new Error("Private library is not enabled for this project");
  }

  return privateLibraryContent;
};
