import { uniq } from "lodash";

type Highlight = {
  fieldName: string;
  terms: string[];
};

export const extractHighlightsTerms = (highlights: Highlight[], filters: string[] = []) => {
  const terms = (filters.length > 0
    ? highlights.filter(({ fieldName }) => filters.includes(fieldName))
    : highlights
  ).flatMap((i) => i.terms);

  const matchRegex = /<em[^>]*>(.*?)<\/em>/g;
  const replaceRegex = /<\/?em[^>]*>/g;
  return uniq(
    terms.flatMap((i) => i.match(matchRegex)?.map((tag) => tag.replace(replaceRegex, "").toLowerCase()) || [])
  );
};

// Helper function to escape special characters in the keywords
const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const wrapTerms = (text: string, keywords: string[]) => {
  // Create a regex pattern that matches any of the keywords, ignoring case
  const pattern = new RegExp("\\b(" + keywords.map((keyword) => escapeRegExp(keyword)).join("|") + ")\\b", "gi");

  // Substitute the matches with <em> tags
  return text.replace(pattern, '<em class="highlighted-text">$1</em>');
};

export const totalFlyoutHighlights = (highlights: Highlight[]) => {
  const flyoutHighlights = [
    "advisor.name.concat",
    "relevantPrimaryJob.companyName.concat",
    "relevantPrimaryJob.jobTitle.concat",
    "relevanceStatement.concat",
  ];

  return highlights
    .filter(({ fieldName }) => flyoutHighlights.includes(fieldName))
    .flatMap((it) => it.terms.map((t) => t.match(/<em[^>]*>(.*?)<\/em>/g)?.length || 0))
    .reduce((acc, it) => acc + it, 0);
};
