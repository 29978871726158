import styled, { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const Separator = styled(x.div)(() => {
  const {
    spacing: { inner },
    shape,
    color,
  } = useThemeTokens();

  return `
    height: ${inner.base};
    border-top: ${shape.border.width.small} solid ${color.border.divider};
    background: ${color.background.neutral.subtle};
  `;
});
