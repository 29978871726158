import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { LinkableSectionResult } from "../hooks/useLinkableSection";

type storeState = {
  isScrollOnClickedTab: boolean;
  surveyId: string;
  selectedSectionIndex: number | null;
  navigateToSectionIndex: number | null;
  sectionsRefs: React.MutableRefObject<HTMLDivElement | null>[];
  linkableSectionsRefs: LinkableSectionResult[];
  setSelectedSectionIndex: (newIndex: number) => void;
  setNavigateToSectionIndex: (newIndex: number | null) => void;
  setSurveyId: (newSurveyId: string) => void;
  setIsScrollOnClickedTab: (newIsScroll: boolean) => void;
  addToSectionsRefs: (sectionsRef: React.MutableRefObject<HTMLDivElement | null>) => void;
  addToLinkableSectionsRefs: (linkableSectionsRefs: LinkableSectionResult) => void;
};

export const useSurveysResultsStore = create(
  immer<storeState>((set) => ({
    surveyId: "",
    isScrollOnClickedTab: false,
    selectedSectionIndex: null,
    navigateToSectionIndex: null,
    sectionsRefs: [],
    linkableSectionsRefs: [],
    setSelectedSectionIndex: (newIndex: number) => set({ selectedSectionIndex: newIndex }),
    setNavigateToSectionIndex: (newIndex: number | null) => set({ navigateToSectionIndex: newIndex }),
    setSurveyId: (newSurveyId: string) => set({ surveyId: newSurveyId }),
    setIsScrollOnClickedTab: (newIsScroll: boolean) => set({ isScrollOnClickedTab: newIsScroll }),
    addToSectionsRefs: (sectionsRef: React.MutableRefObject<HTMLDivElement | null>) => {
      set((state) => ({
        sectionsRefs: [...state.sectionsRefs, sectionsRef],
      }));
    },
    addToLinkableSectionsRefs: (linkableSectionsRef: LinkableSectionResult) => {
      set((state) => ({
        linkableSectionsRefs: [...state.linkableSectionsRefs, linkableSectionsRef],
      }));
    },
  }))
);
