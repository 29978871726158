import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const {
    color: { background, border, base },
    spacing: { inner },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      bg: background.surface.page.default,
      overflow: "auto",
      flexGrow: 1,
      w: "full",
      h: "100%",
      pt: 0,
      position: "relative",
      overflowX: "hidden",
    },
    mainContent: {
      overflow: "hidden",
      h: "100%",
      bg: background.surface.recessed,
    },
    tabContentWrapper: {
      h: "100%",
      overflowY: "scroll",
    },
    messagePage: {
      margin: inner.base08,
      gap: inner.base03,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    messagePageTitle: {
      gap: inner.base03,
      display: "flex",
      alignItems: "center",
    },
    transcriptRender: {
      display: "flex",
      flexDirection: "column",
      gap: inner.base04,
      px: inner.base06,
      py: inner.base06,
    },
    transcriptParagraph: {
      display: "flex",
      gap: inner.base03,
    },
    transcriptParagraphSpeaker: {
      display: "flex",
      gap: inner.base02,
      alignItems: "baseline",
    },
    transcriptParagraphContent: {
      display: "flex",
      flexDirection: "column",
      gap: inner.base03,
    },
    playerWrapper: {
      py: inner.base04,
      px: inner.base08,
    },
    innerContent: {
      border: "1px solid",
      borderRadius: inner.base03,
      borderColor: border.neutral.default,
      bg: base.white,
      w: "75%",
    },
    outerContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: inner.base05,
      py: inner.base06,
    },
  };
};
