import { PropsWithChildren, useRef } from "react";
import { ThirdPartyInteraction } from "types";
import { getAuthor } from "views/DeliverablesView/ThirdPartyDocumentCard/utils";
import { FloatingAction, FloatingActions, useHelperActions } from "components/FloatingActions";
import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { Icon, Typography } from "@alphasights/alphadesign-components";
import { GenerativeAi } from "@alphasights/alphadesign-icons";
import { Summary, SummaryTheme } from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentSummaryTab/types";
import {
  SummaryCopyablePointContainer,
  SummaryVerbatimContainer,
  ThemeContainer,
  ThemeContentContainer,
  ThemeDisplayContainer,
  ThemePointsContainer,
  ThemeQuotesContainer,
} from "./ThemeDisplay.styled";

const dataTestIds = {
  themeTitle: "theme-title",
};

const ThemeDisplay = ({
  theme,
  ix,
  interaction,
}: {
  theme: SummaryTheme;
  ix: number;
  interaction: ThirdPartyInteraction;
}) => {
  const author = getAuthor(interaction);

  const ref = useRef<HTMLDivElement>(null);

  const { copyRefAction, copyQuoteAction } = useHelperActions();
  return (
    <ThemeDisplayContainer ref={ref}>
      <FloatingActions highlightOnHover={false} actions={[copyRefAction(ref)]}>
        <ThemeContainer data-testid={`theme-${ix}`}>
          <EmphasisTypography component="strong" data-testid={`theme-title-${ix}`} variant="body-em">
            {theme.title}
          </EmphasisTypography>
          <ThemeContentContainer>
            <Icon size="large" color="ai">
              <GenerativeAi />
            </Icon>
            <ThemePointsContainer>
              <div>
                {theme.points.map((p, idx) => {
                  return (
                    <SummaryCopyablePointContainer>
                      <SummaryCopyablePoint position={idx} part={p} themeIx={ix} />
                    </SummaryCopyablePointContainer>
                  );
                })}
              </div>
              <ThemeQuotesContainer>
                {theme.quotes.map((p, idx) => {
                  return (
                    <ActionablePart key={`part-${idx}`} copy={copyQuoteAction(p.text, author)}>
                      <SummaryVerbatim position={idx} part={p} themeIx={ix} />
                    </ActionablePart>
                  );
                })}
              </ThemeQuotesContainer>
            </ThemePointsContainer>
          </ThemeContentContainer>
        </ThemeContainer>
      </FloatingActions>
    </ThemeDisplayContainer>
  );
};

type ItemRendererProps = {
  part: Summary;
  position: number;
  themeIx: number;
};

const SummaryCopyablePoint = ({ part, position, themeIx }: ItemRendererProps) => {
  return (
    <EmphasisTypography component="li" data-testid={`point-${themeIx}-${position}`}>
      {part.keyword ? (
        <>
          <Typography component="strong" variant="body-small-em">
            {part.keyword}:{" "}
          </Typography>
          {part.text}
        </>
      ) : (
        <>{part.text}</>
      )}
    </EmphasisTypography>
  );
};

const ActionablePart = ({
  children,
  copy,
}: {
  copy?: FloatingAction;
} & PropsWithChildren) => {
  const actions = [copy].flatMap((it) => (it ? [it] : []));

  return (
    <FloatingActions highlightOnHover={true} actions={actions}>
      {children}
    </FloatingActions>
  );
};

const SummaryVerbatim = ({ position, part, themeIx }: ItemRendererProps) => {
  return (
    <SummaryVerbatimContainer data-testid={`quote-${themeIx}-${position}`}>
      <EmphasisTypography component="i">"{part.text}"</EmphasisTypography>
    </SummaryVerbatimContainer>
  );
};

export { ThemeDisplay as default, dataTestIds };
