import { Checkbox, Popover } from "@alphasights/alphadesign-components";
import styled, { th } from "@xstyled/styled-components";

export const StyledPopover = styled(Popover)`
  padding: 0;
  z-index: 30;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: ${th.space("inner-base02")};
`;
