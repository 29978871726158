import { useMemo } from "react";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import { useCurrentWorkstreamContext } from "providers/CurrentWorkstreamProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { filterSurveysWithInteractions, fromWorkstream } from "components/InteractionsPage/helpers/Workstreams";
import { first } from "lodash";

export const useShowCid = () => {
  const {
    state: { interactions },
    allInteractionsForProject,
  } = useProjectInteractionsContext();

  const { project, selectedWorkstream: workstream, selectedSurveyId } = useCurrentWorkstreamContext();
  const userCidEnabled = useUserCidEnabled();

  const validSurveys = useMemo(
    () => (workstream ? filterSurveysWithInteractions(allInteractionsForProject, workstream.clientSurveys) : []),
    [allInteractionsForProject, workstream]
  );

  const selectedOrFirstSurveyId = useMemo(
    () => validSurveys.find((s) => s.id === selectedSurveyId)?.id || first(validSurveys)?.id,
    [validSurveys, selectedSurveyId]
  );

  const surveyType = useMemo(() => {
    const clientSurvey =
      workstream?.clientSurveys && workstream.clientSurveys.find((e) => e.id === selectedOrFirstSurveyId);
    return clientSurvey?.surveyType;
  }, [selectedOrFirstSurveyId, workstream]);

  const showCid =
    surveyType &&
    selectedOrFirstSurveyId &&
    !shouldHideCid({
      surveyType,
      interactions: workstream?.id ? fromWorkstream(interactions, workstream.id) : [],
      project,
      selectedSurveyId: selectedOrFirstSurveyId,
      userCidEnabled,
    });

  return !!showCid;
};

const shouldHideCid = ({
  surveyType,
  interactions,
  project,
  selectedSurveyId,
  userCidEnabled,
}: {
  surveyType: SurveyType;
  interactions: Interaction[];
  project: Project;
  selectedSurveyId: string;
  userCidEnabled: boolean;
}) => {
  const hideCid = () =>
    (surveyType === "single_blinded" &&
      !interactions.find(
        (i) => i.surveyResponse?.clientSurveyId === selectedSurveyId && i.surveyResponse.state === "completed"
      )) ||
    (surveyType === "premium" && !interactions.find((i) => i.surveyResponse?.clientSurveyId === selectedSurveyId));
  return !project.active || !project.cidEnabled || !userCidEnabled || hideCid();
};
