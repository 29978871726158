import Search, { DataTestIds as SearchTestIds } from "./Search";
import { DataTestIds as SearchItemTestIds } from "components/Search/components/SearchItem";
import { DataTestIds as SearchOptionTestIds } from "components/Search/components/Option";
import { DataTestIds as InputTestIds } from "components/Search/components/core/Input";
import { DataTestIds as SearchPopoverTestIds } from "components/Search/components/core/OptionsPopover";

const DataTestIds = {
  search: SearchTestIds,
  searchItem: SearchItemTestIds,
  searchOption: SearchOptionTestIds,
  popover: SearchPopoverTestIds,
  input: InputTestIds,
};

export type {
  SearchProps,
  SearchOption,
  SearchOptionWithType,
  OptionProps,
  OptionsPopoverFooterProps,
  OptionalOptionProps,
  SearchItemProps,
} from "./types";
export { SearchVariant, SearchSizeVariant, SearchError, Operator, Symbol, BooleanTypes } from "./consts";
export { getDefaultComponents } from "./components";
export { Search as default, DataTestIds };
