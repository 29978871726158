import { useThemeTokens } from "@alphasights/alphadesign-components";

const useDeliverablesFiltersStyles = () => {
  const {
    color: { background, border },
    spacing: { inner },
    shape,
  } = useThemeTokens();

  return {
    filters: {
      display: "flex",
      flexWrap: "nowrap",
    },
    noHoverNoClick: {
      pointerEvents: "none",
      color: {
        hover: null,
      },
    },
    bookmarkFilter: {
      h: "100%",
    },
    filtersRight: {
      display: "flex",
      marginLeft: "auto",
      alignItems: "center",
    },
    filtersSortBy: {
      h: inner.base08,
      ml: inner.base02,
    },
    filtersSortBySelected: {
      bg: background.action.outline.pressed,
    },
    booleanFilter: {
      px: inner.base,
    },
    buttonsWrapper: {
      display: "flex",
      borderTopWidth: shape.border.width.sm,
      borderTopStyle: "solid",
      borderTopColor: border.divider,
      px: inner.base04,
      py: inner.base03,
      alignItems: "center",
      justifyContent: "space-between",
    },
  };
};

export default useDeliverablesFiltersStyles;
