import { useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useMessengerContext } from "providers/MessengerProvider";
import { Close } from "@alphasights/alphadesign-icons";
import { CtaButton } from "pages/InteractionPage/sections/Topbar/Topbar";
import { noop } from "lodash";
import { WorkRequestMaximumCharge } from "pages/InteractionPage/sections/Topbar/WorkRequestMaximumCharge";
import { ExpertChargesList } from "components/MessengerPage/ExpertChargesList";
import { ExpertCharge } from "types";

export const NewMessageHeader = ({
  buttonRef: buttonRefInput,
}: {
  buttonRef?: React.MutableRefObject<HTMLElement | null>;
}) => {
  const {
    newMessageType,
    onSendNewMessage,
    selectedExperts,
    maximumTaskDuration,
    inboxes,
    projectToken,
  } = useMessengerContext();
  const { header, title, container } = useNewHeaderStyles();
  const [isExpertsListOpen, setIsExpertsListOpen] = useState(false);
  const [expertsCharge, setExpertsCharge] = useState<ExpertCharge[]>([]);

  if (!newMessageType) return null;

  const buttonRef: any = buttonRefInput;
  return (
    <x.div {...container}>
      <x.div {...header}>
        {inboxes?.length > 0 && (
          <x.div data-testid="close-icon" onClick={() => onSendNewMessage(undefined)}>
            <Icon color="secondary" onClick={noop}>
              <Close />
            </Icon>
          </x.div>
        )}
        <x.div {...title}>
          <Typography color="strong" variant="body-em">
            New {newMessageType}
          </Typography>
          <x.div data-testid="maximum-charge">
            <WorkRequestMaximumCharge
              projectToken={projectToken}
              selectedExperts={selectedExperts}
              maximumTaskDuration={maximumTaskDuration}
              messageType={newMessageType}
              setExpertsCharge={setExpertsCharge}
              isExpertsListOpen={isExpertsListOpen}
              setIsExpertsListOpen={setIsExpertsListOpen}
            />
          </x.div>
        </x.div>
        <x.div alignSelf="center">
          <CtaButton buttonRef={buttonRef} data-testid="send-message-button">
            Send Message
          </CtaButton>
        </x.div>
      </x.div>
      {isExpertsListOpen && selectedExperts?.length > 1 && (
        <x.div pl="28px">
          <ExpertChargesList experts={expertsCharge} />
        </x.div>
      )}
    </x.div>
  );
};

export const useNewHeaderStyles = () => {
  const {
    color: { border, background },
    spacing: { inner },
  } = useThemeTokens();

  const header = {
    display: "flex",
    gap: inner.base02,
  };

  const title = {
    display: "flex",
    flexDirection: "column",
    gap: inner.base02,
    flexGrow: 1,
  };

  const container = {
    backgroundColor: background.surface.page.default,
    zIndex: 1,
    p: inner.base08,
    pb: inner.base04,
    borderBottomWidth: 1,
    borderColor: border.divider,
  };

  return {
    title,
    header,
    container,
  };
};
