import { useEffect, useLayoutEffect, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { TransformedTab } from "views/SurveysResultsView/api/types";
import SubTab from "views/SurveysResultsView/components/DataVisualization/components/SubTab";
import useLinkableSection, { LinkableSectionResult } from "views/SurveysResultsView/hooks/useLinkableSection";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";

type TabProps = {
  tab: TransformedTab;
  tabIndex: number;
};

const Tab = ({ tab, tabIndex }: TabProps) => {
  const { color, spacing, shape } = useThemeTokens();
  const { name, display, subTabs } = tab;
  const addToSectionsRefs = useSurveysResultsStore(({ addToSectionsRefs }) => addToSectionsRefs);
  const addToLinkableSectionsRefs = useSurveysResultsStore(
    ({ addToLinkableSectionsRefs }) => addToLinkableSectionsRefs
  );
  const navigateToSectionIndex = useSurveysResultsStore(({ navigateToSectionIndex }) => navigateToSectionIndex);
  const setSelectedSectionIndex = useSurveysResultsStore(({ setSelectedSectionIndex }) => setSelectedSectionIndex);
  const setNavigateToSectionIndex = useSurveysResultsStore(
    ({ setNavigateToSectionIndex }) => setNavigateToSectionIndex
  );

  const ref = useRef<HTMLDivElement | null>(null);
  let linkableSectionsRef: LinkableSectionResult = useLinkableSection({ sectionRef: ref });
  const { setRefs, inView } = linkableSectionsRef;

  useLayoutEffect(() => {
    if (ref) addToSectionsRefs(ref);
    if (linkableSectionsRef) addToLinkableSectionsRefs(linkableSectionsRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView) {
      if (navigateToSectionIndex === null || navigateToSectionIndex === tabIndex) {
        setSelectedSectionIndex(tabIndex);
        if (navigateToSectionIndex) setNavigateToSectionIndex(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <x.div
      ref={setRefs}
      display="flex"
      flexDirection="column"
      data-testid={`tab-section-${name}`}
      backgroundColor={color.background.surface.page.default}
      borderRadius={shape.border.radius[8]}
      border={`1px solid ${color.border.neutral.default}`}
    >
      <x.div
        display="flex"
        data-testid="tab-title"
        alignItems="center"
        padding={`${spacing.layout.base03} ${spacing.layout.base05}`}
      >
        <Typography variant="body-large-em">{display}</Typography>
      </x.div>
      {subTabs.map((subTab) => (
        <SubTab key={subTab.display} subTab={subTab} />
      ))}
    </x.div>
  );
};

export default Tab;
