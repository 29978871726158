import { useCallback } from "react";
import { useInView } from "react-intersection-observer";

type ReferenceLinkableSectionProps = {
  sectionRef?: React.MutableRefObject<HTMLDivElement | null>;
  root?: React.MutableRefObject<HTMLDivElement | null>;
};

export type LinkableSectionResult = {
  setRefs: (node: HTMLDivElement) => void;
  inView: boolean;
};

const useLinkableSection = ({ sectionRef, root }: ReferenceLinkableSectionProps) => {
  let internalSectionRef = sectionRef;

  const [ref, inView] = useInView({
    root: root ? root.current : null,
    rootMargin: "-25%",
  });

  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      // Ref's from useRef needs to have the node assigned to `current`
      if (internalSectionRef) {
        internalSectionRef.current = node;

        // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
        ref(node);
      }
    },
    [ref, internalSectionRef]
  );

  const setSectionRef = (sectionRef: React.MutableRefObject<HTMLDivElement | null>) => {
    internalSectionRef = sectionRef;
  };

  return { setRefs, setSectionRef, inView };
};

export default useLinkableSection;
