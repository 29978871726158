import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const ModalContentContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    gap: spacing.inner.base05,
    display: "flex",
    flexDirection: "column" as "column",
    paddingTop: spacing.inner.base04,
  };
});
