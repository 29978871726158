import { ReactNode } from "react";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import CitedMarkdown from "components/CitedMarkdown";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";

type CitationWrapperProps = {
  value: any;
  children?: ReactNode;
  dataTestId?: string;
  style?: any;
};

const CitationTextWrapper = ({
  value,
  children,
  dataTestId = "citationTextWrapper",
  style = {},
}: CitationWrapperProps) => {
  return (
    <SensitiveDataContainer isSensitive={hasSensitiveData(value)} data-testid={dataTestId}>
      <div>
        {children ? (
          children
        ) : (
          <CitedMarkdown data-testid={`${dataTestId}Description`} value={value as CitableValue<string>} style={style} />
        )}
      </div>
    </SensitiveDataContainer>
  );
};

export default CitationTextWrapper;
