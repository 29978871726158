import { FC } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Tile, useThemeTokens } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";

const DataTestIds = {
  placeHolderIcon: "placeholder-icon",
};

interface CompanyLogoProps {
  logo: string | null;
  companyName: string | null;
}

const CompanyLogo: FC<CompanyLogoProps> = ({ logo, companyName }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  if (logo)
    return (
      <LogoContainer>
        <x.img src={logo} maxW={inner.base10} maxH={inner.base10} alt={`${companyName ?? "company"} logo`} />
      </LogoContainer>
    );

  return (
    <div data-testid={DataTestIds.placeHolderIcon}>
      <Tile variant="icon" size="x-large" icon={<Company />} />
    </div>
  );
};

const LogoContainer = styled.div(() => {
  const {
    color: { border },
    shape: {
      border: { radius, width },
    },
    spacing: { layout },
  } = useThemeTokens();
  return {
    width: layout.base08,
    height: layout.base08,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `${width.sm} solid ${border.disabled}`,
    borderRadius: radius.medium,
  };
});

export { CompanyLogo as default, DataTestIds };
