import { useEffect, useState } from "react";
import { Select, Option, Icon, Avatar } from "@alphasights/alphadesign-components";
import { AlphaDesignTheme, AlphaDesignThemeContextProvider } from "@alphasights/alphadesign-components";
import { Search } from "@alphasights/alphadesign-icons";
import { FLAG_MAP } from "../../constants/Country/flags";
import { x } from "@xstyled/styled-components";

export const SelectCountry = ({
  value,
  onChange = {},
  children = [],
  allowMultiple = false,
  input = true,
  placeholder,
}) => {
  const [options, setOptions] = useState([]);
  const [countries, setCountries] = useState([]);

  const renderSeletedItem = (item) => {
    const country = countries.find((country) => country.children === item.label);

    if (country && country.flag) {
      return (
        <x.div display="flex" alignItems="center">
          <Avatar src={country.flag} size="small" mr="8px" />
          <div>{item.label}</div>
        </x.div>
      );
    } else {
      return <>{item.label}</>;
    }
  };

  useEffect(() => {
    if (children) {
      setCountries(
        children.map((item, index) => {
          const flag = FLAG_MAP[item.code];
          return { ...item, index, flag };
        })
      );
    } else {
      setCountries([]);
    }
  }, [children]);

  useEffect(() => {
    setOptions(
      countries.map((country) => {
        return <Option key={country.index} {...country} img={country.flag} paddingLeft="12px" onMouseOver={() => {}} />;
      })
    );
  }, [countries]);

  return (
    <AlphaDesignThemeContextProvider theme={AlphaDesignTheme.portal}>
      <Select
        value={value}
        onChange={onChange}
        children={options}
        allowMultiple={allowMultiple}
        input={input}
        placeholder={placeholder}
        rightAccessory={
          <Icon>
            <Search />
          </Icon>
        }
        allowRightAccessoryRotation={false}
        renderSelectedValue={renderSeletedItem}
      />
    </AlphaDesignThemeContextProvider>
  );
};
