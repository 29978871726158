import { SharedProject } from "models/SharedProjects";
import { fetch } from "../hooks/useApi";

export const sharedProjectsService = {
  async findSharedProject(token: string): Promise<SharedProject | undefined> {
    return fetch({
      url: `/api/shared-projects/${token}`,
      skipAlert: true,
    })
      .then((res) => res.json())
      .catch((err) => {
        if (err.status === 404) {
          return undefined;
        }
        throw err;
      });
  },

  async submitBillingAgreement(
    token: string,
    data: {
      firstName: string;
      lastName: string;
      email: string;
    }
  ): Promise<void> {
    return fetch({
      url: `/api/shared-projects/${token}/billing-agreement`,
      method: "POST",
      body: JSON.stringify(data),
    }).then(() => {});
  },

  async openPDFConfirmation(token: string): Promise<void> {
    try {
      const response = await fetch({
        url: `/api/shared-projects/${token}/confirmation`,
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error to load the confirmation file: ${response.statusText}`);
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.error("Error to open the confirmation file:", error);
    }
  },
};
