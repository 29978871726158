import styled from "styled-components";
import { th } from "@xstyled/styled-components";

export const TemporaryScheduledSlot = styled.div`
  top: ${({ top }) => top};
  height: ${({ height }) => height};
  left: 0;
  width: 100%;
  line-height: 1;
  padding: ${th.space(2)};
  z-index: 10;
  position: absolute;
  border-radius: ${th.radius("base")};
  border: ${th.borderWidth(1)} solid ${th.color("white")};
  overflow: hidden;
  background-color: ${({ isMutual }) =>
    isMutual ? th.color("background-successStrong-default") : th.color("background-selected-strong-default")};
  color: ${th.color("white")};
`;

export const OverlappingSlotStyled = styled.div`
  top: ${({ top }) => top};
  height: ${({ height }) => height};
  width: calc(100% + 2px);
  left: -1px;
  padding: ${th.space(2)};
  position: absolute;
  border-radius: ${th.radius("base")};
  border: ${th.borderWidth(2)} solid ${th.color("success.2")};
  overflow: hidden;
`;

export const ExpertAvailabilityEventStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${th.fontSize("02")};
  overflow: hidden;
`;

export const EventDescriptionStyled = styled.div`
  font-weight: ${th.fontWeight("semiBold")};
  white-space: nowrap;
`;

export const EventPeriodStyled = styled.div`
  font-size: ${th.fontSize("02")};
`;
