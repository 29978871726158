import { memo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetSurveys } from "./api";
import { Spinner } from "@alphasights/client-portal-shared";
import { x } from "@xstyled/styled-components";

const SurveysResultsView = memo(() => {
  const { token } = useParams<{ token: string; id: string }>();
  const navigate = useNavigate();

  const { isLoading, data: survey } = useGetSurveys({ token });

  useEffect(() => {
    if (survey?.items && survey.items.length > 0) {
      navigate(`/${token}/surveys-results-view/${survey.items[0].id}`, { replace: true });
    }
  }, [survey, token, navigate]);

  if (isLoading) {
    return (
      <x.div display="flex" justifyContent="center" alignItems="center" h="inherit">
        <Spinner size="medium" />
      </x.div>
    );
  }

  // TODO: uncomment this code when the landing page mockup is ready
  // const availableSurveys = survey.items;
  // return (
  //   <>
  //     {availableSurveys.map((survey) => (
  //       <div>{survey.id}</div>
  //     ))}
  //   </>
  // );
  return null;
});

export default SurveysResultsView;
