import { Icon, Pill, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { getGeographicKnowledgeText } from "pages/InteractionPage/sections/InteractionHeader/InteractionHeader";
import useFollowUpStyles from "components/SurveysPage/surveysColumns/followUpStyles";
import { useMobileExpertCardStyles } from "./MobileExpertsList.styles";
import { usePlusMore } from "hooks/usePlusMore";
import { AlphaCircle, Angle, Globe, Hide, Interpreted, Recommended } from "@alphasights/alphadesign-icons";
import { useLabelsContext } from "providers/LabelsProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ProjectFeature } from "@alphasights/portal-api-client";

export interface MobileExpertsListProps {
  interactions: Interaction[];
  isProjectCidEnabled: boolean;
  userCidEnabled: boolean;
  onSelectInteraction: (interaction: Interaction) => void;
}

export const MobileExpertsList = ({
  interactions,
  isProjectCidEnabled,
  userCidEnabled,
  onSelectInteraction,
}: MobileExpertsListProps) => {
  if (!interactions || interactions.length === 0) return null;

  return (
    <x.ul data-testid="mobile-experts-list">
      {interactions.map((interaction) => (
        <ExpertListItem
          key={interaction.id}
          interaction={interaction}
          isProjectCidEnabled={isProjectCidEnabled}
          userCidEnabled={userCidEnabled}
          onClick={() => onSelectInteraction(interaction)}
        />
      ))}
    </x.ul>
  );
};

const ExpertListItem = ({
  interaction,
  isProjectCidEnabled,
  userCidEnabled,
  onClick,
}: {
  interaction: Interaction;
  isProjectCidEnabled: boolean;
  userCidEnabled: boolean;
  onClick: () => void;
}) => {
  const styles = useMobileExpertCardStyles({ hidden: interaction.hidden });
  const { advisorCompany, role, rolePeriod } = interaction;

  return (
    <x.li {...styles.expertWrapper} onClick={onClick} data-testid={`expert-list-item-${interaction.id}`}>
      <MobileExpertBadges
        interaction={interaction}
        isProjectCidEnabled={isProjectCidEnabled}
        userCidEnabled={userCidEnabled}
      />
      <x.div>
        <Typography variant="body-em" {...styles.companyAndRole}>
          {advisorCompany} - {role}
        </Typography>
        <x.div {...styles.roleAndHide}>
          {rolePeriod && rolePeriod.length > 0 && (
            <Typography variant="body-small" color="secondary" {...styles.role}>
              {interaction.advisorName} | {rolePeriod}
            </Typography>
          )}
          {interaction.hidden && (
            <Icon color="secondary" size="small">
              <Hide />
            </Icon>
          )}
        </x.div>
      </x.div>
    </x.li>
  );
};

const MobileExpertBadges = ({
  interaction,
  isProjectCidEnabled,
  userCidEnabled,
}: {
  interaction: Interaction;
  isProjectCidEnabled: boolean;
  userCidEnabled: boolean;
}) => {
  const followupStyles = useFollowUpStyles({ newChipCount: false });
  const { isFeatureDisabled } = useCurrentProjectContext();
  const styles = useMobileExpertCardStyles();
  const { getLabelledExpert } = useLabelsContext();

  const expertId = interaction.advisorId;
  const angleId = interaction.angles[0].id;
  const labelledExpert = angleId ? getLabelledExpert(expertId, angleId) : undefined;
  const label = labelledExpert?.label?.text;

  const adjustedState = interaction.state.replace(/proposed/, "available");

  const showAlphaCircleMultiplier =
    interaction.isActiveProject &&
    (interaction.alphaCircleMultiplier ?? 1.0) > 1.0 &&
    !isFeatureDisabled(ProjectFeature.AlphaCircleMultiplier);

  const badges = [
    {
      text: interaction.externalCidStatus?.toLowerCase(),
      isShown: interaction.isActiveProject && isProjectCidEnabled && interaction.externalCidStatus && userCidEnabled,
      // @ts-ignore
      ...styles.cid[interaction.externalCidStatus?.toLowerCase()],
    },
    {
      text: adjustedState,
      // @ts-ignore
      icon: followupStyles.chipIcons[adjustedState],
      // @ts-ignore
      variant: followupStyles.pillVariantMap[adjustedState],

      isShown: true,
    },
    {
      text: "Expert has availability",
      variant: "green",
      isShown: (interaction?.advisorAvailability || []).length,
    },
    {
      text: interaction.group?.name,
      icon: (
        <Icon size="small" color="secondary">
          <Angle />
        </Icon>
      ),
      isShown: interaction.group?.name,
    },
    {
      text: " ",
      icon: (
        <Icon size="small" color="secondary" dataAttributes={{ "data-testid": "needs-interpreter-badge" }}>
          <Interpreted />
        </Icon>
      ),
      isShown: interaction.needsInterpreter,
    },
    {
      text: " ",
      icon: (
        <Icon size="small" color="secondary" dataAttributes={{ "data-testid": "recommended-badge" }}>
          <Recommended />
        </Icon>
      ),
      isShown: interaction.highlyRecommended,
    },
    {
      text: interaction.alphaCircleMultiplier + "",
      icon: (
        <Icon size="small" color="secondary">
          <AlphaCircle />
        </Icon>
      ),
      variant: "light",
      isShown: showAlphaCircleMultiplier,
      testId: "alpha-circle-multiplier",
    },
    {
      text: getGeographicKnowledgeText(interaction.geographies),
      variant: "light",
      icon: (
        <Icon size="small" color="secondary">
          <Globe />
        </Icon>
      ),
      isShown: interaction.geographies && interaction.geographies.length > 0,
    },
    {
      text: label,
      isShown: label,
    },
  ].filter(({ isShown }) => isShown);

  const badgesTexts = badges.map((badge) => badge.text!);
  const { setContainerEl, regularPills, morePill, steady } = usePlusMore({
    labels: badgesTexts,
  });

  return (
    <x.div
      {...styles.badgesWrapper}
      ref={setContainerEl}
      overflow="hidden"
      zIndex={steady ? "auto" : -1}
      data-testid="badges-container"
    >
      {regularPills.map(({ text }, index) => (
        <ExpertBadge
          key={index}
          text={text}
          hidden={interaction.hidden}
          icon={badges[index].icon}
          variant={badges[index].variant}
          testId={badges[index].testId}
        />
      ))}
      {morePill.length > 0 && <ExpertBadge hidden={interaction.hidden} text={`+${morePill.length} more`} />}
    </x.div>
  );
};

export const ExpertBadge = ({
  text,
  hidden = false,
  icon,
  isMobile,
  variant,
  testId,
}: {
  text?: string;
  hidden?: boolean;
  icon?: JSX.Element;
  isMobile?: boolean;
  variant?: string;
  testId?: string;
}) => {
  const styles = useMobileExpertCardStyles({ hidden });

  return (
    <x.div data-testid={`expert-badge-${testId}`} display="flex">
      {isMobile || (typeof text === "string" && text.trim().length > 0) ? (
        <Pill
          whiteSpace="nowrap"
          size={"small"}
          isInteractive={false}
          leftAccessories={icon}
          // @ts-ignore
          variant={variant ? variant : "light"}
          textTransform="capitalize"
        >
          <x.span {...styles.pillText}>{text}</x.span>
        </Pill>
      ) : (
        <x.div {...styles.iconOnly}>{icon}</x.div>
      )}
    </x.div>
  );
};
