import { ExpertCompareQuestion } from "models/ExpertCompare";
import { fetch } from "../hooks/useApi";

export interface CreateQuestionRequest {
  id?: number | string;
  text: string;
  angleId: string;
}
export interface DeleteQuestionRequest {
  id: number;
  reason?: string;
  angleId: string;
}

export interface UpdateQuestionsRequest {
  toAdd: CreateQuestionRequest[];
  toDelete: DeleteQuestionRequest[];
}

export interface UpdateQuestionsResponse {
  deletedIds: number[];
  addedIds: number[];
}

export const expertCompareService = {
  async findQuestions(projectToken: string, loadResponses = true): Promise<ExpertCompareQuestion[]> {
    return fetch({
      url: `/api/projects/${projectToken}/expert-compare/questions?loadResponses=${loadResponses}`,
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async updateQuestions(projectToken: string, questions: UpdateQuestionsRequest): Promise<UpdateQuestionsResponse> {
    return fetch({
      url: `/api/projects/${projectToken}/expert-compare/questions/update`,
      handleForbidden: true,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify(questions),
    }).then((res) => res.json());
  },

  async countQuestions(projectToken: string): Promise<number> {
    return fetch({
      url: `/api/projects/${projectToken}/expert-compare/questions/count`,
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },

  async countPendingCommands(projectToken: string): Promise<number> {
    return fetch({
      url: `/api/projects/${projectToken}/expert-compare/question-generation-commands/executions/count`,
      handleForbidden: true,
      skipAlert: true,
    }).then((res) => res.json());
  },
};
