import { KpcItem, ProjectFeature, SynthesisQuote } from "@alphasights/portal-api-client";
import { BarChartQuote, ChartData, SynthesisBarChart } from "../../components";
import { ThirdPartyInteraction } from "types";
import { getCompanyNameForQuote, getExpertRoleForQuote } from "../../QuestionModuleContent/helpers";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import tokens from "@alphasights/alphadesign-tokens";

export const KpcChart = ({
  kpcItems,
  thirdPartyDocuments,
}: {
  kpcItems: KpcItem[];
  thirdPartyDocuments: ThirdPartyInteraction[];
}) => {
  const { project, isFeatureDisabled } = useCurrentProjectContext();
  const chartData = getChartData(kpcItems, project!.token, thirdPartyDocuments);
  const categories = kpcItems.map((item) => item.name);
  const isToolbarEnabled = !isFeatureDisabled(ProjectFeature.ProjectSynthesis);

  return (
    <SynthesisBarChart
      chartData={chartData}
      categories={categories}
      extraChartProps={{
        id: "kpc-stacked-bar-chart",
        horizontal: false,
        popOverPlacement: "right",
      }}
      toolbarEnabled={isToolbarEnabled}
    />
  );
};

const getChartData = (
  kpcItems: KpcItem[],
  projectToken: string,
  thirdPartyDocuments: ThirdPartyInteraction[]
): ChartData[] => {
  const validSentiments = ["VERY_IMPORTANT", "IMPORTANT", "SOMEWHAT_IMPORTANT"];
  return validSentiments.map((sentiment) => ({
    name: sentimentLabels[sentiment as keyof typeof sentimentColors],
    color: sentimentColors[sentiment as keyof typeof sentimentColors],
    data: kpcItems.map((item, idx) => {
      const sentimentData = item.sentiments.find((s) => s.sentiment === sentiment);
      return {
        y: sentimentData?.count ?? 0,
        x: idx.toString(),
        color: sentimentColors[sentiment as keyof typeof sentimentColors],
        sentiment: sentimentLabels[sentiment as keyof typeof sentimentColors],
        quotes: sentimentData?.quotes.map((q) => buildBarChartQuote(q, projectToken, thirdPartyDocuments)) ?? [],
      };
    }),
  }));
};

export const buildBarChartQuote = (
  quote: SynthesisQuote,
  projectToken: string,
  thirdPartyDocuments: ThirdPartyInteraction[]
): BarChartQuote => {
  const navToLink = quote.interactionId
    ? `/${projectToken}/experts/deliverables-view/?transcriptType=Transcript&selectedInteraction=${quote.interactionId}`
    : quote.thirdPartyDocumentId
    ? `/${projectToken}/experts/deliverables-view/?transcriptType=Transcript&selectedUploadedDocument=${quote.thirdPartyDocumentId}`
    : "";

  const document = quote.thirdPartyDocumentId
    ? thirdPartyDocuments.find((tpd) => tpd.id === quote.thirdPartyDocumentId)
    : undefined;
  const label = `${getCompanyNameForQuote(quote, document)} - ${getExpertRoleForQuote(quote, document)}`;

  return {
    label,
    navToLink,
  };
};

const sentimentLabels = {
  VERY_IMPORTANT: "Critical",
  IMPORTANT: "Important",
  SOMEWHAT_IMPORTANT: "Discussed",
};

const sentimentColors = {
  VERY_IMPORTANT: tokens.color.chart.sequential.base,
  IMPORTANT: tokens.color.chart.sequential.base03,
  SOMEWHAT_IMPORTANT: tokens.color.chart.sequential.base05,
};
