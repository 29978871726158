import { createContext, ReactNode, useContext, useState } from "react";

type BackdropContextType = {
  hasBackdrop: boolean;
  showBackdrop: () => void;
  hideBackdrop: () => void;
};

export const BackdropContext = createContext<BackdropContextType | undefined>(undefined);

export const BackdropProvider = ({ children }: { children: ReactNode }) => {
  const [hasBackdrop, setHasBackdrop] = useState(false);

  const showBackdrop = () => {
    setHasBackdrop(true);
  };

  const hideBackdrop = () => {
    setHasBackdrop(false);
  };

  return (
    <BackdropContext.Provider value={{ hasBackdrop, showBackdrop, hideBackdrop }}>{children}</BackdropContext.Provider>
  );
};

export const useBackdropContext = () => {
  const context = useContext(BackdropContext);

  if (!context) throw new Error("BackdropContext should only be used within the BackdropProvider");

  return context;
};
