import { Children, cloneElement, useContext, useState } from "react";
import { InteractionEvent, interactionEventProps, InteractionWrapper } from "./Interaction";
import {
  ExpertAvailabilityEvent,
  useExpertAvailabilityEventProps,
  ExpertAvailabilityWrapper,
} from "./ExpertAvailability";
import { ClientAvailabilityEvent, clientAvailabilityEventProps, ClientAvailabilityWrapper } from "./ClientAvailability";
import { CalendarContext } from "../CalendarContext";
import { popupOpen, popupStateCleanUp, popupStateUpdate } from "../reducer";
import { RequestEvent, requestEventProps, RequestWrapper } from "./Request";
import { UpcomingCallEvent, upcomingCallEventProps, UpcomingCallWrapper } from "./UpcomingCall";

export const Wrapper = ({ event, children, ...props }) => {
  const [referenceElement, setReferenceElement] = useState(null);

  const { state, dispatch } = useContext(CalendarContext);

  const isEventPopupOpen = state.popup.interactionId === event?.interaction?.id;
  const Component = components[event.source].wrapper;

  const onPopupOpen = async (initialState = {}) => {
    if (event.deps.onOpenPopup) {
      const showPopup = event.deps.onOpenPopup(event, initialState);

      if (showPopup === false) return;
    }

    const angle = event.interaction.angles?.at(0);
    dispatch(
      popupOpen({
        interactionId: event.interaction.id,
        token: event.interaction.projectToken,
        source: event.source,
        angleId: angle?.id,
        ...initialState,
      })
    );
  };

  return (
    <Component
      event={event}
      isEventPopupOpen={isEventPopupOpen}
      popupState={state.popup}
      onUpdatePopupState={(data) => dispatch(popupStateUpdate(data))}
      onPopupOpen={onPopupOpen}
      onPopupClose={() => dispatch(popupStateCleanUp())}
      onPopupForwardTo={(start, interactionId = event.interaction.id) =>
        dispatch(
          popupStateUpdate({
            interactionId,
            selectedStart: start,
          })
        )
      }
      referenceElement={referenceElement}
      isFlyout={event.isFlyout}
      {...props}
    >
      {setRefOnFirstGrandchild(children, setReferenceElement)}
    </Component>
  );
};

export const Event = ({ event, ...props }) => {
  const Component = components[event.source].event;

  return <Component event={event} {...props} />;
};

export const eventProps = (event) => {
  return components[event.source].props(event);
};

const components = {
  interaction: {
    wrapper: InteractionWrapper,
    event: InteractionEvent,
    props: interactionEventProps,
  },
  expertAvailability: {
    wrapper: ExpertAvailabilityWrapper,
    event: ExpertAvailabilityEvent,
    props: useExpertAvailabilityEventProps,
  },
  clientAvailability: {
    wrapper: ClientAvailabilityWrapper,
    event: ClientAvailabilityEvent,
    props: clientAvailabilityEventProps,
  },
  request: {
    wrapper: RequestWrapper,
    event: RequestEvent,
    props: requestEventProps,
  },
  pending: {
    wrapper: InteractionWrapper,
    event: InteractionEvent,
    props: interactionEventProps,
  },
  upcomingCall: {
    wrapper: UpcomingCallWrapper,
    event: UpcomingCallEvent,
    props: upcomingCallEventProps,
  },
  ghost: {
    wrapper: ({ children }) => <div style={{ visibility: "hidden" }}>{children}</div>,
    event: () => null,
    props: () => null,
  },
};

/** Necessary for event modal */
const setRefOnFirstGrandchild = (parent, ref) =>
  Children.map(parent, (child) => {
    if (Children.count(child.props.children) === 0) return child;

    if (Children.count(child.props.children) > 1 && process.env.NODE_ENV === "development") {
      throw new Error(
        `Multiple children not supported when injecting ref.
        This function hacks grandchildren to inject it a ref
        to allow the 'popper' popups on the calendar.
        If you faced this error verify if your custom component is returning
        a single element in its render instead of a list (maybe the list
        is the 'children' prop you are just passing through).
        If you update this function, remember to add tests to cover
        your specific change.`
      );
    }

    return cloneElement(child, {
      children: cloneElement(child.props.children, { ref }),
    });
  });
