import { FC, useMemo } from "react";
import * as React from "react";
import styled, { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { sortBy } from "lodash";

import { groupAndSortExpertsByAngleTypeName } from "../CategorizedByAngle";
import ExpertCard from "../ExpertCard";
import ExpertCount from "../ExpertCount";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import "components/Experts/index.css";

export interface ContributedByProps {
  contentId: string;
  selectedExperts?: Speaker[];
  showInstructions?: boolean;
  isPaymentRequired?: boolean;
}

export const ContributedBy: FC<ContributedByProps> = ({
  contentId,
  selectedExperts,
  showInstructions = false,
  isPaymentRequired = false,
}) => {
  const hasSelection = Boolean(selectedExperts?.length);
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  /**
   * Sorts the list of selected experts by their angle type overall and, within each angle type,
   * by their order index.
   *
   * example (simplified):
   *    input: ["Competitor 1", "End Customer 1", "Competitor 2"]
   *    output: ["Competitor 1", "Competitor 2", "End Customer 1"]
   */
  const sortedContributers = useMemo(
    () =>
      Array.from(groupAndSortExpertsByAngleTypeName(selectedExperts || [])).flatMap(([_, angleTypeExperts]) =>
        sortBy(angleTypeExperts, (angleTypeExpert) => angleTypeExpert.order || 0)
      ),
    [selectedExperts]
  );

  if (!hasSelection) {
    if (showInstructions) {
      return <Instructions isPaymentRequired={isPaymentRequired} className="alphanow-fade-in" />;
    } else {
      return null;
    }
  }

  return (
    <>
      <Typography variant={isMobile ? "body-em" : "pre-title"} color={text.secondary} pb={inner.base02}>
        {isMobile ? "INSIGHT CONTRIBUTED BY" : "Insight contributed by"}
        <ExpertCount className="alphanow-fade-in" count={selectedExperts!.length} />
      </Typography>
      <ContributedByContainer>
        {sortedContributers.map((expert) => (
          <ExpertCard
            className="alphanow-fade-in"
            contentId={contentId}
            key={expert.speakerId}
            speaker={expert}
            isSelected
          />
        ))}
      </ContributedByContainer>
    </>
  );
};

const ContributedByContainer = styled.div`
  div:first-child {
    margin-top: 0;
  }
`;

interface InstructionsProps extends React.HTMLAttributes<HTMLDivElement> {
  isPaymentRequired?: boolean;
}

const Instructions = ({ isPaymentRequired, ...unconsumedProps }: InstructionsProps): JSX.Element | null => {
  const { spacing, color } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <x.div {...unconsumedProps} paddingBottom={spacing.inner.base06}>
      <Typography color={color.text.strong._} variant={isMobile ? "body-large" : "body"}>
        {`Click on an expert to see their ${isPaymentRequired ? "relevant experience" : "insights"}`}
      </Typography>
    </x.div>
  );
};
