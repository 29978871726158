import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";

const RelevantPositionCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;

  return <div data-testid={`survey-relevant-position-column-${interaction.id}`}>{props.getValue()}</div>;
};

export const RelevantPositionColumn = {
  id: "RelevantPositionColumn",
  accessorKey: "interaction.role",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <RelevantPositionCell props={props} />,
  header: "Relevant Position",
  minSize: 50,
};
