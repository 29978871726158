import { FC } from "react";
import { Info } from "@alphasights/alphadesign-icons";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { Loading, Tooltip, useThemeTokens } from "@alphasights/alphadesign-components";

import {
  FILE_STATUS_DISPLAY,
  FILE_STATUS_TOOLTIP,
  FileStatus,
} from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";
import { FileRow } from "views/DeliverablesView/ThirdPartyDocUploadModal/FileTable/types";

import * as S from "./StatusCell.styled";

const DataTestIds = {
  loadingSpinner: "status-cell-loading-spinner",
};

const InfoIcon: FC<{ status: FileStatus }> = ({ status }) => {
  if ([FileStatus.failed, FileStatus.informationRequired].includes(status)) {
    const tooltipTitle = FILE_STATUS_TOOLTIP[status];
    return (
      <Tooltip title={tooltipTitle} variant="dark" size="small" disabled={!tooltipTitle}>
        <S.StyledIcon>
          <Info />
        </S.StyledIcon>
      </Tooltip>
    );
  }
  return null;
};

const StatusCell: FC<AlphaTableTypes.CellContext<FileRow, any>> = ({ row: { original: selectedFile } }) => {
  const { color } = useThemeTokens();
  const status = selectedFile.status;
  const textColor = status === FileStatus.failed ? color.text.danger : color.text.strong._;
  const displayedStatus = FILE_STATUS_DISPLAY[status];

  if (selectedFile.status === FileStatus.processing) {
    return <Loading size="sm" data-testid={DataTestIds.loadingSpinner} />;
  }

  return (
    <S.Wrapper>
      <S.StyledTypography color={textColor}>{displayedStatus}</S.StyledTypography>
      <InfoIcon status={status} />
    </S.Wrapper>
  );
};

export { StatusCell as default, DataTestIds };
