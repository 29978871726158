import { FC } from "react";
import { Icon } from "@alphasights/alphadesign-components";
import { BookmarkUnselected } from "@alphasights/alphadesign-icons";

import { FilterButton } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";

const DataTestIds = {
  selectedBookmarkButton: "selected-filter-button",
  unselectedBookmarkButton: "unselected-filter-button",
};

type BookmarkFilterProps = {
  onChange: (value: Record<SearchFilters.bookmarkFilter, boolean>) => void;
  value?: boolean;
};

const BookmarkFilter: FC<BookmarkFilterProps> = ({ value = false, onChange = () => {} }) => {
  const handleFilterChange = () => {
    onChange({ [SearchFilters.bookmarkFilter]: !value });
  };

  return (
    <FilterButton
      data-testid={value ? DataTestIds.selectedBookmarkButton : DataTestIds.unselectedBookmarkButton}
      onClick={handleFilterChange}
      selected={value}
    >
      <Icon>
        <BookmarkUnselected />
      </Icon>
    </FilterButton>
  );
};

export { BookmarkFilter as default, DataTestIds };
