import { useEffect, useState } from "react";
import { useNavigate } from "router-utils";
import { Typography, Button } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { fetch } from "../../hooks/useApi";
import { useNotifications } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";

export const ProjectAccessForbiddenPage = ({ projectToken, error, showLogin = false }) => {
  const navigate = useNavigate();
  const [projectTitle, setProjectTitle] = useState();
  const currentUser = useCurrentUser();

  useEffect(() => {
    const getProjectTitle = async () => {
      const data = await error.json();
      setProjectTitle(data.project);
    };
    if (error) getProjectTitle();
  }, [error]);

  const redirectToMyProjects = () => {
    navigate("/my-projects");
  };

  if (currentUser?.internalUser) {
    return (
      <ForbiddenInternalUser
        projectToken={projectToken}
        projectTitle={projectTitle}
        showLogin={showLogin}
        onRedirectToProjects={redirectToMyProjects}
      ></ForbiddenInternalUser>
    );
  } else {
    return (
      <ForbiddenExternalUser
        projectToken={projectToken}
        projectTitle={projectTitle}
        showLogin={showLogin}
        onRedirectToProjects={redirectToMyProjects}
      ></ForbiddenExternalUser>
    );
  }
};

const ForbiddenInternalUser = ({ projectToken, projectTitle, onRedirectToProjects }) => {
  const onRedirectToDelivery = async () => {
    window.open(`/api/projects/${projectToken}/delivery-manage`);
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    h: "90vh",
    px: "24px",
  };

  const buttoBarStyle = {
    display: "flex",
    gap: "16px",
    marginTop: "24px",
  };

  return (
    <x.div {...divStyle}>
      <x.div maxWidth={"640px"}>
        <Typography variant="h2">You need permission</Typography>

        <Typography variant="body-large">
          You currently don't have access to this project: {projectTitle}. Get access by viewing the project within
          Delivery and clicking on the Client Platform link. This will log you in as the correct client user.
        </Typography>

        <x.div {...buttoBarStyle}>
          <Button variant="secondary" onClick={() => onRedirectToDelivery()}>
            View Project In Delivery
          </Button>
          <Button variant="outline" onClick={onRedirectToProjects}>
            View All Projects
          </Button>
        </x.div>
      </x.div>
    </x.div>
  );
};

const ForbiddenExternalUser = ({ projectToken, projectTitle, onRedirectToProjects }) => {
  const [isLoading, setLoading] = useState(false);
  const { showSuccessBanner } = useNotifications();

  const onCreateAccessRequest = async () => {
    try {
      setLoading(true);
      const res = await fetch({
        url: `/api/projects/${projectToken}/access-requests`,
        method: "POST",
      });
      const data = await res.json();
      setLoading(false);
      showSuccessBanner(`Request sent to ${data.recipient}.`);
    } catch (err) {
      setLoading(false);
    }
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    h: "90vh",
    px: "24px",
  };

  const buttoBarStyle = {
    display: "flex",
    gap: "16px",
    marginTop: "24px",
  };

  return (
    <x.div {...divStyle}>
      <div>
        <Typography variant="h2">You need permission</Typography>

        <Typography variant="body-large">You currently don't have access to this project: {projectTitle}.</Typography>

        <x.div {...buttoBarStyle}>
          <Button variant="secondary" onClick={() => onCreateAccessRequest()} loading={isLoading}>
            Request Access
          </Button>
          <Button variant="outline" onClick={onRedirectToProjects}>
            My Projects
          </Button>
        </x.div>
      </div>
    </x.div>
  );
};
