import { useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens, Tabs, TabItem } from "@alphasights/alphadesign-components";
import { DotLabel } from "pages/AlphaNowPage/primers/components";
import CompetitivePerformanceKPCsTab from "./CompetitivePerformanceKPCsTab";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state/store";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";
import { withNumberParam } from "helpers/tabHelper";

type CompetitivePerformanceKPCsTabsProps = {
  chartColors: string[];
  selectedChartSegment: number | null;
  onClick: (index: number) => void;
};

const CompetitivePerformanceKPCsTabs = ({
  chartColors,
  selectedChartSegment,
  onClick,
}: CompetitivePerformanceKPCsTabsProps) => {
  const { spacing, color } = useThemeTokens();
  const keyPurchasingCriteria = useCustomerPrimersStore(({ keyPurchasingCriteria }) => keyPurchasingCriteria);
  const { vendors } = keyPurchasingCriteria;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    setSelectedTab(selectedChartSegment ?? 0);
  }, [selectedChartSegment]);

  return (
    <x.div pt={spacing.inner.base04}>
      <Tabs
        data-testid="customerPrimerKPCsTabs"
        colorVariant="primary"
        index={selectedTab || 0}
        onChange={withNumberParam((index) => {
          handleTabChange(index);
          onClick(index);
        })}
      >
        {vendors.map(({ companyName }, i) => {
          const [companyNameTruncated, tooltip] = getTruncatedTextAndTooltip(companyName, 30);

          return (
            <TabItem
              key={companyName}
              label={
                <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                  <DotLabel
                    dotColor={chartColors[i]}
                    label={companyNameTruncated as string}
                    tableEntry
                    labelColor={i === selectedTab ? color.text.strong._ : color.text.secondary}
                  />
                </ConditionalTooltip>
              }
            />
          );
        })}
      </Tabs>

      {vendors.length > 0 && <CompetitivePerformanceKPCsTab vendor={vendors[selectedTab ?? 0]} />}
    </x.div>
  );
};

export default CompetitivePerformanceKPCsTabs;
