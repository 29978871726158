import styled, { x } from "@xstyled/styled-components";
import { MIN_SEARCH_WIDTH, MAX_SEARCH_WIDTH } from "./constants";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { DataTestIds } from "components/Search";

const SearchBarWrapper = styled(x.div)(({ isSearchBarActive }: { isSearchBarActive: boolean }) => {
  const { color } = useThemeTokens();
  return `
  min-width: ${MIN_SEARCH_WIDTH};
  max-width: ${MAX_SEARCH_WIDTH};
  flex: 0 1 ${MAX_SEARCH_WIDTH};
  #${DataTestIds.input.Input} {
  ${
    !isSearchBarActive &&
    `
    color: ${color.text.inverse};
    &::placeholder {
      color: ${color.text.inverse} !important;
    }
    `
  }
  }
`;
});

export { SearchBarWrapper };
