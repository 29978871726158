import { useEffect, useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Info } from "@alphasights/alphadesign-icons";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { CitationWrapper, LabeledIcon } from "pages/AlphaNowPage/primers/components";
import CompetitorPill from "pages/AlphaNowPage/primers/MarketPrimer/Sections/Overview/CompetitorPill";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { useGetCompanyProperties } from "pages/AlphaNowPage/primers/hooks";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const Overview = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const sectionName = "OVERVIEW";
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const { color, spacing, typography } = useThemeTokens();
  const primerTitle = usePrimersStore(({ primerTitle }) => primerTitle);
  const companies = useCustomerPrimersStore(({ companies }) => companies);
  const companiesV2 = useCustomerPrimersStore(({ companiesV2 }) => companiesV2);
  const expertsMentionedSection = useMentionedExpertsStore(({ expertsMentionedSection }) => expertsMentionedSection);
  const overview = useCustomerPrimersStore(({ overview }) => overview);
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const usageAndAwareness = useCustomerPrimersStore(({ usageAndAwareness }) => usageAndAwareness);
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );
  const { getCompanyProperties } = useGetCompanyProperties(companies, companiesV2);

  const competitorClicked = (companyName: string) => {
    setSelectedCompany(selectedCompany !== companyName ? companyName : "");

    if (selectedCompany !== companyName) {
      const vendor = usageAndAwareness.vendors.find((vendor) => vendor.companyName === companyName);
      const speakersIds = vendor ? [...vendor.uses, ...vendor.churned, ...vendor.evaluated] : [];

      setExpertsMentionedSectionAndExpertsIds(sectionName, speakersIds);
    } else {
      resetExpertsMentioned();
    }
  };

  useEffect(() => {
    if (expertsMentionedSection !== sectionName && selectedCompany.length > 0) {
      setSelectedCompany("");
    }
  }, [expertsMentionedSection, selectedCompany.length]);

  return (
    <SectionWrapper ref={ref} px={spacing.inner.base06}>
      <x.div {...typography.body.em} color={color.text.secondary}>
        FEATURED VENDORS
      </x.div>
      <LabeledIcon
        labelText={`Contributing experts identified leading vendors in the ${primerTitle} market. Click a vendor to see its customer experts.`}
        iconItem={<Info />}
        mt={spacing.inner.base}
        iconColor={color.text.assistive}
        labelColor={color.text.assistive}
      />
      <x.div
        display="flex"
        gap={spacing.inner.base02}
        flexWrap="wrap"
        mt={spacing.inner.base02}
        mb={spacing.inner.base06}
      >
        {overview.featuredVendors.map(({ companyName }, index) => {
          const { logoLink } = getCompanyProperties(companyName);

          return (
            <CompetitorPill
              key={companyName ?? index.toString()}
              iconUrl={logoLink ?? ""}
              label={companyName ?? "-"}
              isSelected={companyName === selectedCompany}
              isGrayscale={selectedCompany.length > 0 && companyName !== selectedCompany}
              nameMaxLength={50}
              onClick={() => competitorClicked(companyName)}
            />
          );
        })}
      </x.div>

      {overview?.marketContext && (
        <x.div color={color.text.strong._}>
          <CitationWrapper
            verticalLabel
            value={overview?.marketContext.value}
            mt={spacing.inner.base02}
            titleToContentGap={"0"}
            label={"market context"}
          />
        </x.div>
      )}
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Overview));
