import { memo, useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import { TabItem, Tabs, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCustomerPrimersStore } from "../../../state/store";
import { useGetCompanyProperties } from "pages/AlphaNowPage/primers/hooks";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import NpsTab from "./NpsTab";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";
import { withNumberParam } from "helpers/tabHelper";

interface CompetitorDynamicsTabsProps {
  selectedChartSegment: number | null;
  onClick: (index: number) => void;
}

const NpsTabs = ({ selectedChartSegment, onClick }: CompetitorDynamicsTabsProps) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  const [selectedTab, setSelectedTab] = useState<number | null>(0);
  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const companies = useCustomerPrimersStore(({ companies }) => companies);
  const companiesV2 = useCustomerPrimersStore(({ companiesV2 }) => companiesV2);
  const nps = useCustomerPrimersStore(({ nps }) => nps);
  const { getCompanyProperties } = useGetCompanyProperties(companies, companiesV2);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  useEffect(() => {
    setSelectedTab(selectedChartSegment || 0);
  }, [selectedChartSegment]);

  if (!nps) return null;

  return (
    <x.div pt={inner.base04}>
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <Tabs
          data-testid="marketPrimerSegmentationTabs"
          colorVariant="primary"
          index={selectedTab || 0}
          onChange={withNumberParam((index) => {
            handleTabChange(index);
            onClick(index);
          })}
        >
          {nps.map(({ companyName }, index) => {
            const { logoLink } = getCompanyProperties(companyName);

            const [companyNameTruncated, tooltip] = getTruncatedTextAndTooltip(companyName, 30);

            return (
              <TabItem
                key={companyName}
                label={
                  <x.div display="flex" gap="2" alignItems="start">
                    <PrimerCompanyLogo size={inner.base05} src={logoLink} />
                    <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                      <Typography
                        variant="body-em"
                        color={index === selectedTab ? color.text.strong._ : color.text.secondary}
                      >
                        {companyNameTruncated as string}
                      </Typography>
                    </ConditionalTooltip>
                  </x.div>
                }
              />
            );
          })}
        </Tabs>
      </SensitiveDataContainer>
      <x.div display="flex" gap={inner.base05} flexDirection="column">
        <NpsTab
          summary={nps[selectedTab ?? 0]?.summary}
          expertsResponses={nps[selectedTab ?? 0]?.npsExpertsResponses}
        />
      </x.div>
    </x.div>
  );
};

export default memo(NpsTabs);
