import { useModal } from "@alphasights/ads-community-hooks";
import { Link } from "@alphasights/alphadesign-components";
import { ProjectFeature } from "@alphasights/portal-api-client";
import _ from "lodash";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useQueryClient } from "query-utils";
import React, { useMemo } from "react";
import { ThirdPartyInteraction, ThirdPartyDocumentAttributes } from "types";
import { getDefaultExpert } from "utils/thirdPartyDocumentsUtils";
import { useUpdateDocumentAttributes } from "views/DeliverablesView/ThirdPartyDocumentPage/PageHeader/useUpdateDocumentAttributes";
import EditDocumentModal from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal";
import { createFormPropsFromDocument } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/utils";

export const EditThirdPartyDocumentExpert = ({ document }: { document: ThirdPartyInteraction | undefined }) => {
  const { isVisible: isEditModalVisible, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useModal();
  const { project, isFeatureDisabled } = useCurrentProjectContext();
  const queryClient = useQueryClient();
  const projectToken = project!.token;
  const { onSaveEdits } = useUpdateDocumentAttributes({
    interaction: document,
    projectToken,
    onSuccess: () => queryClient.invalidateQueries(["fetchThirdPartyDocuments"]),
  });
  const readOnly = useMemo(() => isFeatureDisabled(ProjectFeature.ProjectSynthesis), [isFeatureDisabled]);

  // always use first expert's info if available
  const firstDocumentExpert = getDefaultExpert(document?.attribute?.experts);
  const alreadySet = firstDocumentExpert?.companyName && firstDocumentExpert?.position;

  if (!document || alreadySet || readOnly) return null;

  const handleSaveEdits = (attributes: ThirdPartyDocumentAttributes) => {
    try {
      onSaveEdits({ documentId: document.id, attributes });
    } catch (error) {
      console.error("Unable to save edits", error);
    }
    onCloseEditModal();
  };

  return (
    <>
      <Link component="button" size="small" onClick={onOpenEditModal}>
        Add expert information
      </Link>
      {isEditModalVisible && (
        <EditDocumentModal
          onClickCancel={onCloseEditModal}
          onClickSave={handleSaveEdits}
          {...createFormPropsFromDocument(_.omit(document, ["scheduledCallTime", "origin"]))}
        />
      )}
    </>
  );
};
