import styled, { x } from "@xstyled/styled-components";

const TableWrapper = styled(x.div)(() => {
  return `
    table {
      thead {
        th:first-child {
          padding-left: 16px;
        }
        border-right: 0px !important;
      }
      tbody {
        border-right: 0px !important;
      }
    }
    table.table-pinned-left {
        border-right: 0px !important;
        tbody {
          border-right: 0px !important;
        }
      }
    }
  `;
});

export { TableWrapper };
