import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Alert, Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { InputPhoneNumber } from "components/InputPhoneNumber";
import { COUNTRY_CODES } from "constants/Country/index.js";
import { useCallMe, useTwilioContext } from "providers/TwilioProvider";
import { useState } from "react";
import { CallUnavailableAlert } from "./CallUnavailableAlert.js";
import { CallAvailabilityStatus } from "./constants";

export const RequestCallBack = ({
  isOpen,
  projectToken,
  interactionId,
  callAvailabilityStatus,
  projectLead,
  accessCode,
  isWebConnectionActive,
}) => {
  const twilio = useTwilioContext();

  const [phone, setPhone] = useState(() => {
    if (twilio.preference.phoneNumber.length > 0) {
      const code = COUNTRY_CODES.find(({ isoCode }) => isoCode === twilio.preference.countryIsoCode).code;

      return `+${code} ${twilio.preference.phoneNumber}`;
    }

    return "";
  });
  const [extension, setExtension] = useState("");

  const { error, isLoading, dispatch } = useCallMe({ projectToken, interactionId });

  const { isMobile } = useCheckScreen();

  const { color, spacing } = useThemeTokens();

  const CallButton = () => (
    <Button
      onClick={() => {
        const [countryCode, phoneNumber] = phone.split(" ");
        const countryIsoCode = COUNTRY_CODES.find(({ code }) => code === countryCode.substring(1))?.isoCode;

        if (countryIsoCode && phoneNumber) {
          twilio.setPreference({
            countryIsoCode,
            phoneNumber,
          });
        }

        dispatch({ phone, extension });
      }}
      disabled={
        isLoading ||
        !accessCode ||
        !callAvailabilityStatus ||
        callAvailabilityStatus.availability !== CallAvailabilityStatus.AVAILABLE ||
        isWebConnectionActive ||
        phone === ""
      }
      variant={`${!isLoading ? "secondary" : "outline"}`}
      size="medium"
    >
      <Typography color={color.text.primary} style={{ whiteSpace: "nowrap" }}>
        {isLoading ? "Calling..." : "Initiate Call"}
      </Typography>
    </Button>
  );

  const onChangePhoneNumber = (phone) => {
    if (!phone) return;
    setExtension(phone?.extension);
    setPhone(phone?.number ? phone.number : "");
  };

  return (
    <div style={!isOpen ? { display: "none" } : {}}>
      {callAvailabilityStatus && callAvailabilityStatus.availability !== CallAvailabilityStatus.AVAILABLE && (
        <CallUnavailableAlert availability={callAvailabilityStatus.availability} projectLead={projectLead} />
      )}

      {error?.type === "general" && (
        <x.div marginTop={spacing.layout.base03}>
          <Alert variant="danger" alignItems="center">
            {error.message}
          </Alert>
        </x.div>
      )}

      <Typography variant="body-small-spaced" marginTop={spacing.layout.base03} color={color.text.assistive}>
        Please enter your phone number to have our conferencing system initiate a call to you now.
      </Typography>

      <x.div display="flex" marginTop={spacing.layout.base04}>
        <InputPhoneNumber
          value={phone}
          onChange={onChangePhoneNumber}
          inputPhoneErrorMessage={error?.type === "phone" ? error.message : null}
          showExtensionField={true}
        />
        {!isMobile && (
          <x.div marginLeft={spacing.layout.base02}>
            <CallButton />
          </x.div>
        )}
      </x.div>

      {isMobile && (
        <x.div marginTop={spacing.layout.base02}>
          <CallButton />
        </x.div>
      )}
    </div>
  );
};
