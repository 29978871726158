import { Typography } from "@alphasights/alphadesign-components";
import styled, { th } from "@xstyled/styled-components";

export const ProjectTitle = styled(Typography).attrs({
  variant: "body-em",
  component: "span",
  color: "inverse",
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${th.space("inner-base02")};
`;

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
`;
