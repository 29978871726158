import { x } from "@xstyled/styled-components";
import Chart from "react-apexcharts";
import YAxis from "./YAxis";
import ChartLegend from "./ChartLegend";
import { TwoDimensionalStackedBarChartProps, ChartTypeToResults, useChartConfig } from "./useChartConfig";
import { CHART_TYPES } from "views/SurveysResultsView/api/types";
import { Icon, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import RangeChartLegend from "./RangeChartLegend";
import { Info } from "@alphasights/alphadesign-icons";

const TwoDimensionalStackedBarChart = <T extends keyof ChartTypeToResults>({
  chartType,
  results,
}: TwoDimensionalStackedBarChartProps<T>) => {
  const { color, spacing } = useThemeTokens();
  const { chartHeight, config, labels, legendData, values } = useChartConfig({ chartType, results });

  const is1DStackedBarChartLikert = chartType === CHART_TYPES.ONE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT;

  const is2DStackedBarChartMedianCategory = [
    CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING,
    CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL,
  ].includes(chartType);
  const isRangedChartLegend = [
    CHART_TYPES.ONE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT,
    CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING,
    CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL,
    CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC,
    CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT,
    CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT,
    CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL,
    CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL,
  ].includes(chartType);

  const ChartLegendComponent = isRangedChartLegend ? RangeChartLegend : ChartLegend;

  const meanScoreTitle = [
    null,
    <Typography variant="body-small-em" color={color.text.strong._}>
      Mean score
    </Typography>,
    null,
    null,
  ];

  const titles = {
    [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_NET_PROMOTER_SCORE]: [
      null,
      null,
      <x.div display="flex" gap={spacing.inner.base}>
        <Typography variant="body-small-em" color={color.text.strong._}>
          NPS %
        </Typography>
        <Tooltip title="NPS % = % Promoters - % Detractors">
          <Icon color={color.icon.secondary} size="small">
            <Info />
          </Icon>
        </Tooltip>
      </x.div>,
      <Typography variant="body-small" color={color.text.strong._} textAlign="right">
        N
      </Typography>,
    ],
    [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING]: [
      null,
      null,
      <Typography variant="body-small-em" color={color.text.strong._}>
        Median response
      </Typography>,
      <Typography variant="body-small" color={color.text.strong._} textAlign="right">
        N
      </Typography>,
    ],
    [CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL]: [
      null,
      null,
      <Typography variant="body-small-em" color={color.text.strong._}>
        Median response
      </Typography>,
      <Typography variant="body-small" color={color.text.strong._} textAlign="right">
        N
      </Typography>,
    ],
    [CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC]: meanScoreTitle,
    [CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT]: meanScoreTitle,
  };

  const renderMedianLabel = () => (
    <x.div position="absolute" left="50%" transform="translateX(-50%)" top={0}>
      <Typography variant="body-small" color={color.text.strong._}>
        Median
      </Typography>
    </x.div>
  );

  const renderChart = () => (
    <>
      <Chart options={config} series={values} type="bar" height={chartHeight} />
      <x.div display="flex" alignItems="center" justifyContent="center" padding={spacing.inner.base02}>
        <Typography variant="body-small" color={color.text.strong._}>
          % Customers
        </Typography>
      </x.div>
      <ChartLegendComponent elements={legendData} />
    </>
  );

  return (
    <>
      {is1DStackedBarChartLikert && (
        <x.div mb={spacing.inner.base02}>
          <Typography variant="body-small-em" color={color.text.strong._} whiteSpace="nowrap">
            Mean response
          </Typography>
        </x.div>
      )}
      <x.div display="flex" w="100%" data-testid="two-dimensional-bar-chart-container">
        <YAxis labels={labels} titles={titles[chartType as keyof typeof titles]} />
        {is2DStackedBarChartMedianCategory ? (
          <x.div position="relative" paddingTop={spacing.inner.base05} flex="1">
            {renderMedianLabel()}
            {renderChart()}
          </x.div>
        ) : (
          <x.div
            display="flex"
            flexDirection="column"
            flex="1 1 auto"
            data-testid="two-dimensional-bar-chart"
            pt={
              [
                CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_NET_PROMOTER_SCORE,
                CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC,
                CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT,
              ].includes(chartType) && spacing.inner.base05
            }
          >
            {renderChart()}
          </x.div>
        )}
      </x.div>
    </>
  );
};

export default TwoDimensionalStackedBarChart;
