import { FC } from "react";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";
import { Pill, Typography } from "@alphasights/alphadesign-components";

import { CustomHoverContentCard } from "components/CustomHoverContentCard";
import { useTimezone } from "providers/TimezoneProvider";
import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { MentionsFlag } from "views/DeliverablesView/TranscriptCard/MentionsFlag";

import { getSubtitle, getTitle } from "./utils";
import { UPLOADED } from "./constants";

import * as S from "./ThirdPartyDocumentCard.styled";

export const DataTestIds = {
  uploadedDocumentCard: "uploaded-document-card",
};

type ThirdPartyDocumentCardProps = {
  interaction: ThirdPartyInteraction;
  selected: boolean;
  onSelect: () => void;
};

const Title = ({ interaction }: { interaction: ThirdPartyInteraction }) => {
  const isTranscript = interaction.documentType === ThirdPartyDocumentType.transcript;
  const hasMultipleExperts = interaction.attribute.experts?.length > 1;

  let contents;
  const title = getTitle(interaction);
  if (isTranscript && !hasMultipleExperts) {
    const [expertCompanyName, expertPosition] = title.split(" - ");
    contents = (
      <>
        <S.TitleTypography>{expertCompanyName}</S.TitleTypography> - {expertPosition}
      </>
    );
  } else {
    contents = <S.TitleTypography>{title}</S.TitleTypography>;
  }

  return (
    <LimitedLinesTypography tooltip={title} component="span">
      {contents}
    </LimitedLinesTypography>
  );
};

const Subtitle = ({ interaction }: { interaction: ThirdPartyInteraction }) => {
  const subtitleText = getSubtitle(interaction);
  const numMentions = interaction.hitCount ?? 0;
  return (
    <S.SubtitleWrapper>
      <LimitedLinesTypography variant="body-small" color="secondary" component="span" tooltip={subtitleText}>
        {subtitleText}
      </LimitedLinesTypography>
      {!!numMentions && <MentionsFlag hitCount={numMentions} />}
    </S.SubtitleWrapper>
  );
};

const ThirdPartyDocumentCard: FC<ThirdPartyDocumentCardProps> = ({ interaction, selected, onSelect }) => {
  const timezone = useTimezone();
  const formattedDate = timezone.format(interaction.scheduledCallTime, "dd MMM yyyy");
  return (
    <CustomHoverContentCard
      selected={selected}
      onClick={onSelect}
      data-testid={`${DataTestIds.uploadedDocumentCard}-${interaction.id}`}
    >
      <S.CardHeader>
        <Pill size="small" variant="outline">
          {UPLOADED}
        </Pill>
        <Typography variant="body-small" color="secondary">
          {formattedDate}
        </Typography>
      </S.CardHeader>
      <S.TextWrapper>
        <Title interaction={interaction} />
        <Subtitle interaction={interaction} />
      </S.TextWrapper>
    </CustomHoverContentCard>
  );
};

export default ThirdPartyDocumentCard;
