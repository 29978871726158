import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import TruncatedTextCell from "./TruncatedTextCell";
import { getCompaniesText } from "../helpers";

const RelevantCompanyCell = ({ row: { original: content } }: AlphaTableTypes.CellContext<ContentResults, any>) => {
  const companiesText = getCompaniesText(content.companies);
  return <TruncatedTextCell text={companiesText} testId={`content-company-${content.id}`} />;
};

export default RelevantCompanyCell;
