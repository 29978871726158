import { FC, useMemo, useRef } from "react";
import { ListOptionGroup, SelectedOptions, useThemeTokens } from "@alphasights/alphadesign-components";

import Filter from "components/Filter";
import { TITLE } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HqLocationFilter/constants";
import {
  createHqLocationOptions,
  getCountries,
  getSelectedOptions,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HqLocationFilter/utils";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";

interface HqLocationFilterProps extends FilterProps {
  value: string[];
}

const HqLocationFilter: FC<HqLocationFilterProps> = ({ value = [], onChange }) => {
  const { color } = useThemeTokens();

  const options = useMemo(() => createHqLocationOptions(value), [value]);
  const selectedOptions = useRef(getSelectedOptions(options));

  const handleChange = (selected: SelectedOptions[]) => {
    onChange({ [SearchFilters.hqLocation]: getCountries(selected.map(({ label }) => label)) });
  };

  return (
    <Filter
      title={TITLE}
      isAccordion
      accordionProps={{ maxContentsHeight: "350px" }}
      color={color.text.secondary}
      border="0"
    >
      <ListOptionGroup defaultSelectedOptions={selectedOptions.current} listOptions={options} onChange={handleChange} />
    </Filter>
  );
};

export default HqLocationFilter;
export type { HqLocationFilterProps };
