import { CommissionPrimerCredits, CommissionPrivatePrimerCredits, PrimerTypes } from "models/primers/constants";
import { AlphaNowSRMProductType, CONTENT_TYPE, CONTENT_TYPE_DISPLAY_NAME } from "@alphasights/client-portal-shared";
import { ModalPrimerRow, RequestForm } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal/types";
import { MODERATED_TRANSCRIPT } from "../constants";

const primersOrderMap = {
  [AlphaNowSRMProductType.companyPrimer]: [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: true,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: true,
    },
    {
      format: MODERATED_TRANSCRIPT,
      credits: CommissionPrimerCredits.MODERATED_TRANSCRIPT,
      isRecommended: true,
    },
  ],
  [AlphaNowSRMProductType.marketPrimer]: [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      isRecommended: true,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: true,
    },
    {
      format: MODERATED_TRANSCRIPT,
      credits: CommissionPrimerCredits.MODERATED_TRANSCRIPT,
      isRecommended: true,
    },
  ],
  [AlphaNowSRMProductType.customerPrimer]: [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      isRecommended: true,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: true,
    },
    {
      format: MODERATED_TRANSCRIPT,
      credits: CommissionPrimerCredits.MODERATED_TRANSCRIPT,
      isRecommended: true,
    },
  ],
};

// Default primersOrder
const defaultOrder = [
  {
    format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
    credits: CommissionPrimerCredits.COMPANY_PRIMER,
    isRecommended: false,
  },
  {
    format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
    credits: CommissionPrimerCredits.MARKET_PRIMER,
    isRecommended: false,
  },
  {
    format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
    credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
    isRecommended: false,
  },
  {
    format: MODERATED_TRANSCRIPT,
    credits: CommissionPrimerCredits.MODERATED_TRANSCRIPT,
    isRecommended: false,
  },
];

export const getCreditsValue = (format: string, type: string, isRush?: boolean) => {
  let formatKey = format?.toUpperCase().split(" ").join("_");

  if (isRush) formatKey = `${formatKey}_RUSH`;

  if (type === PrimerTypes.ALPHA_NOW) {
    return CommissionPrimerCredits[formatKey as keyof typeof CommissionPrimerCredits];
  }
  return CommissionPrivatePrimerCredits[formatKey as keyof typeof CommissionPrivatePrimerCredits];
};

export const createRequestFormEntry = ({
  companyName,
  companyId,
  type,
  format,
  credits,
  logoLinks,
  rush,
  isRecommended,
  availableLink = "",
  isLoadingAvailable = false,
}: {
  companyName: string;
  companyId?: string | string[];
  type: string;
  format: string;
  credits: number;
  logoLinks: string[];
  rush: boolean;
  isRecommended: boolean;
  availableLink?: string | null;
  isLoadingAvailable?: boolean;
}) => {
  return {
    requestedCompanyId: companyId,
    requestedCompanyName: companyName,
    requestedCompanyType: type,
    requestedCompanyFormat: format,
    logoLinks,
    credits,
    rush,
    isRecommended,
    availableLink,
    isLoadingAvailable,
  };
};

const arraysMatch = (arr1: string[], arr2: string[]): boolean => {
  if (arr1?.length !== arr2?.length) return false;
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

const getAvailablePrimerLink = (
  availablePrimers: { cdsAlphaCompanyIds: string[]; format: string; link: string }[],
  companyIds: string[],
  currentFormat?: string
): string | null => {
  const matchingPrimer = availablePrimers?.find(
    ({ cdsAlphaCompanyIds, format }) => arraysMatch(cdsAlphaCompanyIds, companyIds) && format === currentFormat
  );
  return matchingPrimer ? matchingPrimer.link : null;
};

export const mapModeratedTranscriptInitialForm = ({
  requestForm,
  setRequestForm,
  companyName,
  companyLogo,
  companyId,
  availablePrimers,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  companyName: string;
  companyLogo: string;
  companyId: string;
  availablePrimers: { cdsAlphaCompanyIds: string[]; format: string; link: string }[];
}) => {
  let updatedRows: {
    requestedCompanyId?: string | string[];
    requestedCompanyName: string;
    requestedCompanyType: string;
    requestedCompanyFormat: string;
    logoLinks: string[];
    credits: number;
    rush: boolean;
    isRecommended: boolean;
    availableLink: string | null;
    isLoadingAvailable: boolean;
  }[] = [];

  let primersOrder = [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: false,
    },
  ];

  primersOrder.forEach(({ format, credits }) => {
    updatedRows.push(
      createRequestFormEntry({
        companyId,
        companyName,
        type: PrimerTypes.ALPHA_NOW,
        format,
        credits,
        logoLinks: [companyLogo],
        rush: false,
        isRecommended: false,
        availableLink: getAvailablePrimerLink(availablePrimers, [companyId]),
        isLoadingAvailable: false,
      })
    );
  });

  setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapMarketPrimerInitialForm = ({
  requestForm,
  competitorDynamics,
  setRequestForm,
  availablePrimers,
}: {
  requestForm: RequestForm;
  competitorDynamics: CompetitorDynamicsEntry[];
  setRequestForm: (requestForm: RequestForm) => void;
  availablePrimers: { cdsAlphaCompanyIds: string[]; format: string; link: string }[];
}) => {
  let updatedRows = [...requestForm.requestedPrimers];
  const leadingCompetitors: { companyName: string; logoLink: string; cdsAlphaCompanyId: string }[] = [];

  updatedRows = competitorDynamics.map((competitor) => {
    const { companyName, logoLink, otherNotableCompetitor, cdsAlphaCompanyId } = competitor;

    if (!otherNotableCompetitor) {
      leadingCompetitors.push({ companyName, logoLink, cdsAlphaCompanyId });
    }

    const availableLink = getAvailablePrimerLink(
      availablePrimers,
      [cdsAlphaCompanyId],
      CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer]
    );

    // Anchor competitors default individual company primers
    return createRequestFormEntry({
      companyName,
      companyId: cdsAlphaCompanyId,
      type: PrimerTypes.ALPHA_NOW,
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: availableLink ? 0 : CommissionPrimerCredits.COMPANY_PRIMER,
      logoLinks: [logoLink],
      rush: false,
      isRecommended: false,
      availableLink: availableLink,
      isLoadingAvailable: false,
    });
  });

  if (leadingCompetitors.length > 0) {
    // Anchor competitors default customer primer
    const leadingCompetitorsIds = leadingCompetitors.map(({ cdsAlphaCompanyId }) => cdsAlphaCompanyId);

    const leadingCompetitorsAvailableLink = getAvailablePrimerLink(
      availablePrimers,
      leadingCompetitorsIds,
      CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer]
    );

    updatedRows.push(
      createRequestFormEntry({
        companyId: leadingCompetitors.map(({ cdsAlphaCompanyId }) => cdsAlphaCompanyId),
        companyName: leadingCompetitors.map(({ companyName }) => companyName).join(", "),
        type: PrimerTypes.ALPHA_NOW,
        format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
        credits: leadingCompetitorsAvailableLink ? 0 : CommissionPrimerCredits.CUSTOMER_PRIMER,
        logoLinks: leadingCompetitors.map(({ logoLink }) => logoLink),
        rush: false,
        isRecommended: false,
        availableLink: leadingCompetitorsAvailableLink,
        isLoadingAvailable: false,
      })
    );
  }

  return setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapCompanyPrimerInitialForm = ({
  requestForm,
  setRequestForm,
  companyName,
  companyLogo,
  companyId,
  availablePrimers,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  companyName: string;
  companyLogo: string;
  companyId: string;
  availablePrimers: { cdsAlphaCompanyIds: string[]; format: string; link: string }[];
}) => {
  let updatedRows: {
    requestedCompanyId?: string | string[];
    requestedCompanyName: string;
    requestedCompanyType: string;
    requestedCompanyFormat: string;
    logoLinks: string[];
    credits: number;
    isRecommended: boolean;
    availableLink: string | null;
    rush: boolean;
    isLoadingAvailable: boolean;
  }[] = [];

  let primersOrder = [
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      isRecommended: false,
    },
    {
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.customerPrimer],
      credits: CommissionPrimerCredits.CUSTOMER_PRIMER,
      isRecommended: false,
    },
    {
      format: MODERATED_TRANSCRIPT,
      credits: CommissionPrimerCredits.MODERATED_TRANSCRIPT,
      isRecommended: false,
    },
  ];

  primersOrder.forEach(({ format, credits }) => {
    updatedRows.push(
      createRequestFormEntry({
        companyId,
        companyName,
        type: PrimerTypes.ALPHA_NOW,
        format,
        credits,
        logoLinks: [companyLogo],
        isRecommended: false,
        availableLink: getAvailablePrimerLink(availablePrimers, [companyId], format),
        rush: false,
        isLoadingAvailable: false,
      })
    );
  });

  setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapCustomerPrimerInitialForm = ({
  requestForm,
  setRequestForm,
  featuredVendors,
  availablePrimers,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  featuredVendors: CustomerPrimerFeaturedVendor[];
  availablePrimers: { cdsAlphaCompanyIds: string[]; format: string; link: string }[];
}) => {
  let updatedRows = [];

  updatedRows = featuredVendors.map((vendor) => {
    const { companyName: companyVendorName, logoLink, cdsAlphaCompanyId } = vendor;

    const availableLink = getAvailablePrimerLink(
      availablePrimers,
      [cdsAlphaCompanyId?.toString()],
      CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer]
    );

    return createRequestFormEntry({
      companyId: cdsAlphaCompanyId ? cdsAlphaCompanyId.toString() : "",
      companyName: companyVendorName,
      type: PrimerTypes.ALPHA_NOW,
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.companyPrimer],
      credits: CommissionPrimerCredits.COMPANY_PRIMER,
      logoLinks: [logoLink],
      rush: false,
      isRecommended: false,
      availableLink,
      isLoadingAvailable: false,
    });
  });

  const vendorsIds = featuredVendors.map(({ cdsAlphaCompanyId }) => cdsAlphaCompanyId?.toString());

  const leadingCompetitorsAvailableLink = getAvailablePrimerLink(
    availablePrimers,
    vendorsIds,
    CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer]
  );

  updatedRows.push(
    createRequestFormEntry({
      companyId: featuredVendors.map(({ cdsAlphaCompanyId }) =>
        cdsAlphaCompanyId ? cdsAlphaCompanyId.toString() : ""
      ),
      companyName: featuredVendors.map(({ companyName }) => companyName).join(", "),
      type: PrimerTypes.ALPHA_NOW,
      format: CONTENT_TYPE_DISPLAY_NAME[CONTENT_TYPE.marketPrimer],
      credits: CommissionPrimerCredits.MARKET_PRIMER,
      logoLinks: featuredVendors.map(({ logoLink }) => logoLink),
      rush: false,
      isRecommended: false,
      availableLink: leadingCompetitorsAvailableLink,
      isLoadingAvailable: false,
    })
  );

  return setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapCompanyPageInitialForm = ({
  productType,
  requestForm,
  setRequestForm,
  companyName,
  companyLogo,
  companyId,
  availablePrimers,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  companyName: string;
  companyLogo: string;
  companyId: string;
  productType?: AlphaNowSRMProductType;
  availablePrimers: { cdsAlphaCompanyIds: string[]; format: string; link: string }[];
}) => {
  let updatedRows: ModalPrimerRow[] = [];

  const primersOrder = primersOrderMap[productType!] ?? defaultOrder;

  primersOrder.forEach(({ format, credits, isRecommended }) => {
    updatedRows.push(
      createRequestFormEntry({
        companyId,
        companyName,
        type: PrimerTypes.ALPHA_NOW,
        format,
        credits,
        logoLinks: [companyLogo],
        rush: false,
        isRecommended,
        availableLink: getAvailablePrimerLink(availablePrimers, [companyId], format),
        isLoadingAvailable: false,
      })
    );
  });

  return setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};

export const mapProjectsPageInitialForm = ({
  requestForm,
  setRequestForm,
  companyName,
  companyLogo,
  companyId,
  availablePrimers,
}: {
  requestForm: RequestForm;
  setRequestForm: (requestForm: RequestForm) => void;
  companyName: string;
  companyLogo: string;
  companyId: string;
  availablePrimers: { cdsAlphaCompanyIds: string[]; format: string; link: string }[];
}) => {
  let updatedRows: ModalPrimerRow[] = [];

  const primersOrder = defaultOrder;

  primersOrder.forEach(({ format, credits, isRecommended }) => {
    updatedRows.push(
      createRequestFormEntry({
        companyId,
        companyName,
        type: PrimerTypes.ALPHA_NOW,
        format,
        credits,
        logoLinks: [companyLogo],
        rush: false,
        isRecommended,
        availableLink: getAvailablePrimerLink(availablePrimers, [companyId]),
      })
    );
  });

  return setRequestForm({ ...requestForm, requestedPrimers: updatedRows });
};
