import _ from "lodash";
import { addDays, endOfWeek, isAfter, isBefore, parseISO, startOfDay, startOfWeek } from "date-fns";
import { CONTENT_TYPE, AlphaNowContentType, AlphaNowProductType } from "@alphasights/client-portal-shared";

import { getAngleType } from "content/AlphaNow/utils";
import { COMPANY_RELATIONSHIP, SPEAKER_ANGLE_TYPE_NAME } from "../../constants/AlphaNow";
import { isContentAccessible } from "../../pages/AlphaNowPage/utils/isContentAccessible";
import { distinctBy } from "helpers/arrayHelpers";
import { InteractionOrigin, MIME_TYPE_TO_EXTENSION } from "./constants";

const priority = ["regular", "ai"];

export const isRecordingOnly = (interaction) =>
  !interaction?.recordings.flatMap((r) => r.transcriptRequests).find((tr) => tr.completed);

export const isEmptyTranscriptRequests = (recording) => !recording?.transcriptRequests?.length;

export const getTranscriptForType = (recording, transcriptType) => {
  if (!recording || !transcriptType) return null;

  return recording.transcriptRequests.find((tr) => tr.completed && tr.transcriptType === transcriptType);
};

export const getDefaultTranscript = (recording) => {
  return getAvailableTranscripts(recording)[0];
};

export const getRecordingForTranscript = (interaction, transcript) => {
  return interaction.recordings.find((r) => r.transcriptRequests.find((tr) => tr === transcript));
};

export const getDefaultTranscriptForInteraction = (interaction) => {
  const visibleTranscripts =
    interaction.recordings?.flatMap((r) => r.transcriptRequests).filter((tr) => tr.completed) || [];

  const mainTranscript =
    visibleTranscripts?.find((tr) => tr.transcriptType === "regular") ||
    visibleTranscripts?.find((tr) => tr.transcriptType === "ai") ||
    visibleTranscripts?.find((tr) => tr);

  return mainTranscript;
};

export const getDefaultRecording = (interaction) => {
  return getAvailableRecordings(interaction)[0];
};

export const getAvailableRecordings = (interaction) => {
  const availableRecordings = interaction.recordings.filter((recording) => {
    return (
      (recording.visibleToClient && !recording.purgedAt) ||
      recording.transcriptRequests.find((tr) => tr.completed && !tr.purgedAt)
    );
  });

  return _.sortBy(availableRecordings, (r) => parseISO(r.createdAt).getTime()).reverse();
};

export const getAvailableTranscripts = (recording) => {
  const requests = recording?.transcriptRequests || [];
  return requests
    .filter((tr) => tr.completed)
    .sort((a, b) => priority.indexOf(a.transcriptType) - priority.indexOf(b.transcriptType));
};

const localFilters = (bookmarked, transcriptTypes) => (inter) => {
  const isBookmarkedFiltered = bookmarked && bookmarked[0] === "true" ? inter.bookmarked : true;

  const noAppliedFilters = !transcriptTypes || transcriptTypes.length === 0;

  const passRecordingFilter =
    noAppliedFilters ||
    (transcriptTypes.includes("recording") && inter.recordings.find((rec) => rec.visibleToClient && !rec.purgedAt));

  const passRegularFilter =
    noAppliedFilters ||
    (transcriptTypes.includes("regular") &&
      inter.recordings
        .flatMap((rec) => rec.transcriptRequests)
        .find((transcript) => !transcript.purgedAt && transcript.transcriptType === "regular" && transcript.completed));

  const passAiFilter =
    noAppliedFilters ||
    (transcriptTypes.includes("ai") &&
      inter.recordings
        .flatMap((rec) => rec.transcriptRequests)
        .find((transcript) => !transcript.purgedAt && transcript.transcriptType === "ai" && transcript.completed));

  const passesTranscriptTypeFilter = passRecordingFilter || passRegularFilter || passAiFilter;

  return isBookmarkedFiltered && passesTranscriptTypeFilter;
};

const flattenTranscriptTypes = ({ recordings }) => {
  const flattenTypes = recordings.flatMap(({ transcriptRequests, visibleToClient }) => {
    const types = transcriptRequests.map(({ transcriptType }) => transcriptType);
    const recording = visibleToClient ? ["recording"] : [];
    return [...types, ...recording];
  });
  return [...new Set(flattenTypes)];
};

export const deliverablesInteractions = (
  interactions,
  extraFilters = {},
  sortCriteria = SORT_KEYS.callDateDesc,
  bookmarkedIds = []
) => {
  return interactions
    .filter(getDefaultRecording)
    .map((inter) => ({
      ...inter,
      bookmarked: (bookmarkedIds || []).includes(inter.id),
      transcriptTypes: flattenTranscriptTypes(inter),
    }))
    .filter((inter) => inter.callQuality == null || !inter.callQuality.includes("bad"))
    .filter(localFilters(extraFilters.bookmarked, extraFilters.transcript_types))
    .sort(sorterByCriteria(sortCriteria));
};

export const suggestionsInfo = (suggestion) => {
  const isPrimer = suggestion.contentType === AlphaNowProductType.companyPrimer;
  const isInvestorLedContent = suggestion.contentType === AlphaNowContentType.pcc;

  const isAccessible = isContentAccessible(suggestion.purchaseStatus, suggestion.approvalStatus);

  const anchorCompanies = suggestion.companies.filter(({ companyType }) => companyType === "ANCHOR");
  const nonModeratorSpeakers = suggestion.speakers.filter((s) => !s.isModerator);

  const primerCompanyLogoSrc = suggestion.primer?.logoLink?.value;

  const investorLedContentTitleOverride = isInvestorLedContent ? suggestion.displayTitle : suggestion.externalTitle;
  const primerTitleOverride = isPrimer && anchorCompanies.length > 0 && anchorCompanies[0].companyName;
  const nonPrimerTitleOverride = !isPrimer && nonModeratorSpeakers.length === 1 && nonModeratorSpeakers[0].company;

  const title =
    investorLedContentTitleOverride || primerTitleOverride || nonPrimerTitleOverride || suggestion.externalTitle;

  const typeTitle = isPrimer
    ? "Primer"
    : suggestion.contentType === AlphaNowContentType.pcc
    ? "Investor-led"
    : "Moderated Calls";

  return {
    ...suggestion,
    isPrimer,
    anchorCompanies,
    nonModeratorSpeakers,
    primerCompanyLogoSrc,
    title,
    typeTitle,
    isAccessible,
  };
};

export const sorterByCriteria = (sortCriteria) => (a, b) => {
  const [aTime, bTime] = [a, b].map((it) => new Date(it.scheduledCallTime).getTime());

  if (SORT_KEYS.callDateDesc === sortCriteria) {
    return bTime - aTime;
  } else if (SORT_KEYS.callDateAsc === sortCriteria) {
    return aTime - bTime;
  } else {
    return b.score - a.score;
  }
};

export const trySingularAngleName = (interaction) => {
  const group = interaction.group;
  if (group && Object.keys(group).length > 0) {
    const name = group.name;
    return SPEAKER_ANGLE_TYPE_NAME[name] || name;
  } else {
    return "";
  }
};

export const selectIndexHighlight = (selectedIndex, totalMatches, opts = {}) => {
  if (selectedIndex <= totalMatches) {
    const highlights = document.querySelectorAll("[data-transcript-highlight]");
    highlights.forEach((highlight, idx) => {
      if (idx === selectedIndex) {
        highlight.classList.add("selected");
        if (!opts.noScroll) {
          highlight.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      } else {
        highlight.classList.remove("selected");
      }
    });
  }
};

export const getCompanyRelationship = (contentType, speakers) => {
  const isAlphaView = contentType === CONTENT_TYPE.alphaview;
  const manySpeakers = speakers.filter((s) => !s.isModerator).length > 1;
  const showAngleType = (isAlphaView && !manySpeakers) || !isAlphaView;

  let companyRelationship;
  if (showAngleType) {
    const angleType = getAngleType(speakers);
    companyRelationship = COMPANY_RELATIONSHIP[angleType];
  } else {
    companyRelationship = COMPANY_RELATIONSHIP.Panel;
  }
  return companyRelationship;
};

export const SORT_KEYS = {
  callDateAsc: "callDateAsc",
  callDateDesc: "callDateDesc",
  relevance: "relevance",
};

export const SORT_LABELS = {
  // ordering affects the order shown in the Sort dropdown
  [SORT_KEYS.relevance]: "Relevance",
  [SORT_KEYS.callDateDesc]: "Date (newest first)",
  [SORT_KEYS.callDateAsc]: "Date (oldest first)",
};

const innerContent = (content, wrapper = "'") => {
  return content.startsWith(wrapper) && content.endsWith(wrapper) ? content.substring(1, content.length - 1) : content;
};

const cleanKeyQuote = (quote) => {
  const cleaned = innerContent(innerContent(quote.trim()), '"');
  return (cleaned.endsWith(".") ? cleaned.substring(0, cleaned.length - 1) : cleaned).toLowerCase();
};

export const getSentencesIds = (accumulatedCues, textToSearch) => {
  const quoteContent = cleanKeyQuote(textToSearch);

  return accumulatedCues.flatMap((part) => {
    if (part.body.trim().toLowerCase().includes(quoteContent)) {
      return part.cues
        .filter((cue) => {
          const cueContent = cue.text.trim().toLowerCase();
          return cueContent.indexOf(quoteContent) >= 0 || quoteContent.indexOf(cueContent) >= 0;
        })
        .map(({ id }) => id);
    } else {
      return [];
    }
  });
};

const filterContent = (alphaNowContent, filterValues, func) => {
  const { suggestedContent = [], privateContent = [], pitchedContent = [] } = alphaNowContent;

  const toFilter = {
    suggestedContent: suggestedContent.map((c) => [c, false]),
    privateContent: privateContent.map((c) => [c, false]),
    pitchedContent: pitchedContent.map((c) => [c, false]),
  };

  const mapped = filterValues.reduce((acc, filterValue) => {
    const { suggestedContent = [], privateContent = [], pitchedContent = [] } = acc;

    return {
      ...acc,
      suggestedContent: suggestedContent.map(([c, bool]) => [c, bool || func(c, filterValue)]),
      privateContent: privateContent.map(([c, bool]) => [c, bool || func(c, filterValue)]),
      pitchedContent: pitchedContent.map(([c, bool]) => [c, bool || func(c, filterValue)]),
    };
  }, toFilter);

  return {
    ...alphaNowContent,
    suggestedContent: mapped.suggestedContent
      .filter(([, bool]) => bool)
      .map(([c]) => c)
      .filter(distinctBy("id")),
    privateContent: mapped.privateContent
      .filter(([, bool]) => bool)
      .map(([c]) => c)
      .filter(distinctBy("id")),
    pitchedContent: mapped.pitchedContent
      .filter(([, bool]) => bool)
      .map(([c]) => c)
      .filter(distinctBy("id")),
  };
};

const suggestionsFilters = {
  bookmarked: (filterValues, alphaNowContent) => {
    return filterContent(alphaNowContent, filterValues, (c) => {
      return c.bookmarked;
    });
  },
  scheduled_call_period: (filterValues, alphaNowContent, tz) => {
    const periods = {
      today: {
        start: new Date(),
        end: addDays(new Date(), 1),
      },
      yesterday: {
        start: addDays(new Date(), -1),
        end: new Date(),
      },
      "this week": {
        start: startOfWeek(new Date()),
        end: endOfWeek(new Date()),
      },
      "before this week": {
        start: null,
        end: startOfWeek(new Date()),
      },
    };

    const zoned = Object.entries(periods).reduce((acc, [key, { start, end }]) => {
      return {
        ...acc,
        [key]: {
          start: start ? tz.zonedMidnightToUtc(startOfDay(start)) : null,
          end: end ? tz.zonedMidnightToUtc(startOfDay(end)) : null,
        },
      };
    }, {});

    const isBetween = (content, { start, end }) => {
      const scheduled = parseISO(content.scheduledTimeUTC);

      if (start && isBefore(scheduled, start)) return false;

      if (end && isAfter(scheduled, end)) return false;

      return true;
    };

    return filterContent(alphaNowContent, filterValues, (c, filterValue) => {
      const startEnd = zoned[filterValue];

      return isBetween(c, startEnd);
    });
  },
  transcript_types: (filterValues, alphaNowContent) => {
    return filterContent(alphaNowContent, filterValues, (c, filterValue) => {
      if (filterValue === "primer") return c.contentType === AlphaNowContentType.srm;

      if (filterValue === "moderated-content") return c.contentType === AlphaNowContentType.alphaview;

      if (filterValue === "investor-led-content") return c.contentType === AlphaNowContentType.pcc;

      return false;
    });
  },
};

const shouldHideSuggestions = (appliedFilters) => {
  const whitelist = ["bookmarked", "groups", "scheduled_call_period", "transcript_types"];

  return Object.keys(appliedFilters).find((key) => !whitelist.includes(key) && appliedFilters[key]?.length);
};

export const filterSuggestions = ({ appliedFilters, alphaNowContent, tz }) => {
  if (shouldHideSuggestions(appliedFilters)) {
    return {
      suggestedContent: [],
      privateContent: [],
    };
  }

  return Object.entries(appliedFilters).reduce((acc, [key, value]) => {
    if (value.length === 0) return acc;

    return (suggestionsFilters[key] ?? (() => acc))(value, acc, tz);
  }, alphaNowContent);
};

export const shouldHideUploadedDocs = (filters) => {
  const typeFilter = filters["transcript_types"];
  return typeFilter?.length > 0 && !typeFilter.includes("uploaded");
};

export const createThirdPartyInteraction = (thirdPartyDocument) => ({
  ...thirdPartyDocument,
  origin: InteractionOrigin.thirdParty,
  scheduledCallTime: thirdPartyDocument.attribute.documentDate ?? thirdPartyDocument.createdAt,
  fileExtension: MIME_TYPE_TO_EXTENSION[thirdPartyDocument.fileType],
});
