import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const Wrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base04};
  `;
});

const ButtonContainer = styled.div(() => {
  return `
    display: flex;
    justify-content: flex-end;
  `;
});

export { Wrapper, ButtonContainer };
