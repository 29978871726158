import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const Wrapper = styled.div(() => {
  return `
    display: flex;
    flex-direction: column;
    height: 100%;
  `;
});

export const ContentWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${spacing.inner.base06};
  `;
});
