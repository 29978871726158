import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import ReactDOMServer from "react-dom/server";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { ChartLegend, ChartTooltip, LabeledIcon, SectionTitle } from "pages/AlphaNowPage/primers/components";
import NpsTabs from "./nps-tabs/NpsTabs";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { netPromoterScore } from "pages/AlphaNowPage/primers/CustomerPrimer/utils/constants";
import { SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";
import YAxis from "./chart/YAxis";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const NetPromoterScore = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const sectionName = "NET_PROMOTER_SCORE";
  const [selectedSerie, setSelectedSerie] = useState<number | null>(null);
  const [chartKey, setChartKey] = useState<number>(0);
  const { spacing, color, typography, font } = useThemeTokens();
  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const expertCount = usePrimersStore(({ expertCount }) => expertCount);
  const isSidebarExpanded = useCustomerPrimersStore(({ isSidebarExpanded }) => isSidebarExpanded);
  const nps = useCustomerPrimersStore(({ nps }) => nps);
  const expertsMentionedSection = useMentionedExpertsStore(({ expertsMentionedSection }) => expertsMentionedSection);
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );

  const [selectedChartSegment, setSelectedChartSegment] = useState<number[] | null>([0, 1, 2]);
  const selectedVendorIndex = selectedChartSegment ? parseInt(`${selectedChartSegment[0] / 3}`) : 0;

  const getCompaniesNames = useMemo(() => () => nps.map(({ companyName }) => companyName), [nps]);
  const getYAxisLabelsData = useMemo(
    () => () =>
      nps.map(({ companyName, score }) => ({
        companyName,
        score,
      })),
    [nps]
  );

  const npsColors = [color.chart.categorical.base04, color.chart.categorical.base09, color.chart.categorical.base03];
  const { getChartColors } = useGetChartColors({
    colors: npsColors,
    isStacked: true,
    numberOfBars: getCompaniesNames().length,
  });

  const getSeries = useMemo(
    () => () => {
      const valuesPerCompany = nps.map((entry) => {
        const nonNullValues = entry.expertVotes
          .filter(({ value }) => !!value || value === 0)
          .map((value) => value.value!);

        return {
          detractor: nonNullValues.filter((value) => value >= 0 && value <= 6).length,
          passive: nonNullValues.filter((value) => value >= 7 && value <= 8).length,
          promoter: nonNullValues.filter((value) => value >= 9 && value <= 10).length,
        };
      });

      return [
        {
          name: netPromoterScore.topics.detractor,
          data: valuesPerCompany.map((entry) => entry.detractor),
        },
        {
          name: netPromoterScore.topics.passive,
          data: valuesPerCompany.map((entry) => entry.passive),
        },
        {
          name: netPromoterScore.topics.promoter,
          data: valuesPerCompany.map((entry) => entry.promoter),
        },
      ];
    },
    [nps]
  );

  useEffect(() => {
    // we need this because, due to component memoization, the chart was
    // not resizing when the search bar collapsed at first load.
    //
    // to solve this we get the expanded status of the search bar and
    // when it changes we enforce a new key to the chart. We use a setTimeout
    // to defer this change to when the animation finishes + half a second,
    // to ensure the size is correctly calculated
    let timer = setTimeout(
      () => setChartKey((currentState) => currentState + 1),
      parseFloat(SIDEBAR_ANIMATION_SPEED.substring(0, SIDEBAR_ANIMATION_SPEED.length - 1)) * 1000 + 500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [isSidebarExpanded]);

  useEffect(() => {
    if (expertsMentionedSection !== sectionName && selectedSerie !== null) {
      setSelectedSerie(null);
    }
  }, [expertsMentionedSection, selectedSerie]);

  const chartOptions = {
    chart: {
      id: "netPromoterScoreChart",
      fontFamily: font.family.text.regular,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          const newSelectedSerie = getSeries().length * config.dataPointIndex + config.seriesIndex;

          setSelectedChartSegment([newSelectedSerie]);

          if (newSelectedSerie === selectedSerie) {
            resetExpertsMentioned();
            setSelectedSerie(null);
          } else {
            const vendorName = getCompaniesNames()[config.dataPointIndex];
            const serie = getSeries()[config.seriesIndex].name;
            const vendorData = nps.find(({ companyName }) => companyName === vendorName);

            let minThreshold = -1;
            let maxThreshold = -1;
            switch (serie) {
              case netPromoterScore.topics.detractor:
                minThreshold = 0;
                maxThreshold = 6;
                break;
              case netPromoterScore.topics.passive:
                minThreshold = 7;
                maxThreshold = 8;
                break;
              case netPromoterScore.topics.promoter:
                minThreshold = 9;
                maxThreshold = 10;
                break;
            }

            if (vendorData && minThreshold >= 0 && maxThreshold >= 0) {
              const nonNullValues = vendorData.expertVotes.filter(({ value }) => !!value);

              const speakersIds = nonNullValues
                .filter((value) => value.value! >= minThreshold && value.value! <= maxThreshold)
                .map((value) => value.citedBy)
                .flat();

              setExpertsMentionedSectionAndExpertsIds(sectionName, speakersIds);
              setSelectedSerie(newSelectedSerie);
            }
          }
        },
      },
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: false,
        },
        barHeight: "25px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: getCompaniesNames(),
      title: {
        text: "Customers",
        style: {
          fontFamily: typography.body.small.fontFamily,
          fontWeight: typography.body.small.fontWeight,
          color: color.text.strong._,
        },
        offsetY: 5,
      },
      tickAmount: expertCount / 2,
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      labels: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        return ReactDOMServer.renderToString(
          <ChartTooltip>
            {getSeries()[seriesIndex].name} - {series[seriesIndex][dataPointIndex]} expert
            {series[seriesIndex][dataPointIndex] === 1 ? "" : "s"}
          </ChartTooltip>
        );
      },
    },
    colors: [
      function ({ seriesIndex, dataPointIndex }: { seriesIndex: any; dataPointIndex: any }) {
        // because the chart is stacked, we replicate the colors per lines so that we can have only one selected
        return getChartColors(selectedChartSegment)[getSeries().length * dataPointIndex + seriesIndex];
      },
    ],
    fill: {
      opacity: 1,
    },
    title: {
      text: undefined,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    // this avoids the color to be darker when clicking on an entry
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
  } as ApexOptions;

  const onHighlightChartSegment = (index: number): void => {
    const min = index * 3;
    // we select the all line
    setSelectedChartSegment([min, min + 1, min + 2]);
    ApexCharts.exec("netPromoterScoreChart", "toggleDataPointSelection", index);

    resetExpertsMentioned();
    setSelectedSerie(null);
  };

  const onVendorClick = (companyName: string) => {
    const index = getCompaniesNames().findIndex((name) => name === companyName);

    if (index >= 0) {
      onHighlightChartSegment(index);
    }
  };

  console.log("getSeries", getSeries());

  return (
    <SectionWrapper ref={ref} px={spacing.inner.base06}>
      <SectionTitle dataTestId="netPromoterScore" text="Net Promoter Score" />
      <LabeledIcon
        labelText="Customers rated their satisfaction with vendors on a scale 1-10 (Detractor: 0-6, Passive: 7-8, Promoter: 9-10). NPS = % Promoters - % Detractors. Click into a bar to see a vendor's NPS score and the breakdown of each customer's rating."
        iconItem={<Info />}
        mt={spacing.inner.base}
        labelColor={color.text.assistive}
        iconColor={color.text.assistive}
      />
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <>
          <x.div id="chart" display="flex">
            <YAxis
              vendorsAndScores={getYAxisLabelsData()}
              selectedVendor={getCompaniesNames()[selectedVendorIndex]}
              onVendorClick={onVendorClick}
            />
            <x.div flex="1 1 auto" marginTop="32px">
              <Chart
                key={chartKey}
                options={chartOptions}
                series={getSeries()}
                type="bar"
                height={50 * getCompaniesNames().length + 100}
              />
              <ChartLegend
                elements={getSeries().map(({ name }, index) => ({
                  color: getChartColors(null)[index],
                  label: name,
                }))}
              />
            </x.div>
          </x.div>
        </>
      </SensitiveDataContainer>
      <NpsTabs selectedChartSegment={selectedVendorIndex} onClick={onHighlightChartSegment} />
    </SectionWrapper>
  );
};
export default React.memo(React.forwardRef(NetPromoterScore));
