import { TextArea, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { INFORMATION, INFORMATION_PLACEHOLDER } from "../../constants";
import { RequestForm } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal/types";

const InfoContent = ({
  onChange,
  requestForm,
}: {
  onChange: (property: "caseCode" | "information", e: string) => void;
  requestForm: RequestForm;
}) => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();

  return (
    <>
      <x.div display="flex" flexDirection="column" gap={inner.base02} data-testid="section-info-content">
        <Typography variant="body-small" color={text.secondary}>
          {INFORMATION}
        </Typography>
        <TextArea
          width="100%"
          onChange={({ target: { value } }) => {
            onChange("information", value);
          }}
          placeholder={INFORMATION_PLACEHOLDER}
          value={requestForm?.information}
          isDynamic
          height="60px"
          fontSize={size["02"]}
        />
      </x.div>
    </>
  );
};

export default InfoContent;
