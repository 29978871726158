import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useInteractionHeaderStyles = ({ isMobile } = {}) => {
  const {
    color,
    spacing: { inner, layout },
  } = useThemeTokens();

  const primaryPositionDiv = {
    marginTop: inner.base03,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  const advisorStatus = {
    textTransform: "capitalize",
    flexgrow: 2,
  };

  const secondaryPositionDiv = {
    marginTop: inner.base02,
  };

  const secondaryPositionTitle = {
    color: color.text.secondary,
  };

  const advisorBadges = {
    display: "flex",
    alignItems: "center",
    gap: inner.base,
    flexWrap: "wrap",
  };

  const container = {
    pb: layout.base03,
  };

  const reEngageBannerStyles = {
    mt: inner.base08,
  };

  const firstLineStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: isMobile ? "column-reverse" : "row",
    gap: isMobile ? inner.base06 : inner.base04,
  };

  const actionsIconsStyles = {
    minHeight: inner.base06,
    alignSelf: isMobile ? "flex-end" : undefined,
  };

  const labelStyles = {
    marginTop: inner.base03,
  };

  return {
    primaryPositionDiv,
    advisorStatus,
    secondaryPositionDiv,
    secondaryPositionTitle,
    advisorBadges,
    container,
    reEngageBannerStyles,
    firstLineStyles,
    actionsIconsStyles,
    labelStyles,
  };
};
