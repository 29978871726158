import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const ThemeDisplayContainer = styled.div`
  max-width: 636px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ThemeContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    alignSelf: "center",
    width: "100%",
    gap: spacing.inner.base06,
  };
});

export const ThemeContentContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    gap: spacing.inner.base03,
    width: "100%",
  };
});

export const ThemePointsContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base06,
    width: "100%",
  };
});

export const ThemeQuotesContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base02,
    width: "100%",
  };
});

export const SummaryCopyablePointContainer = styled.ul(() => {
  const { spacing } = useThemeTokens();
  return `
  list-style-type: "•";
  margin-left: ${spacing.inner.base02};
  li {
  padding-left: 10px;
  }
  `;
});

export const SummaryVerbatimContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base02,
    width: "100%",
  };
});
