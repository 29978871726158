import { IconButton, ListOption, Popover } from "@alphasights/alphadesign-components";
import { Copy, Edit, MoreVert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import * as React from "react";
import { DeleteItemWithConfirmationBtn } from "./DeleteItemWithConfirmationBtn";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";

export const MoreActions = ({
  enterEditMode,
  onDelete,
  showEdit = true,
  disabled = false,
  onCopy = undefined,
}: {
  enterEditMode: () => void;
  onDelete: () => void;
  showEdit?: boolean;
  disabled?: boolean;
  onCopy?: () => Promise<void>;
}) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement>();
  const onTogglePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (popoverAnchorEl) setPopoverAnchorEl(undefined);
    else setPopoverAnchorEl(event.target as HTMLElement);
  };
  const { readOnly } = useProjectSynthesisContext();

  const [copyLabel, setCopyLabel] = React.useState("Copy");
  const handleCopy = () => {
    onCopy &&
      onCopy().then(() => {
        setCopyLabel("Copied!");
        setTimeout(() => {
          setCopyLabel("Copy");
        }, 1000);
      });
  };

  return (
    <>
      <IconButton
        size="small"
        variant="outline"
        onClick={onTogglePopover}
        testId="more-actions"
        disabled={disabled || readOnly}
      >
        <MoreVert />
      </IconButton>
      <Popover
        anchorEl={popoverAnchorEl}
        open={Boolean(popoverAnchorEl)}
        onClose={() => setPopoverAnchorEl(undefined)}
        placement="bottom-end"
      >
        <x.div minWidth="200px" data-testid="more-actions-popover">
          {onCopy && <ListOption type="text" label={copyLabel} onChange={handleCopy} leftIcon={<Copy />} />}
          {showEdit && <ListOption type="text" label="Edit" onChange={enterEditMode} leftIcon={<Edit />} />}
          <DeleteItemWithConfirmationBtn onDeleteItem={onDelete} variant="listOption" />
        </x.div>
      </Popover>
    </>
  );
};
