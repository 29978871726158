import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const OverviewContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    maxWidth: "636px",
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    marginInline: spacing.inner.base10,
    marginTop: spacing.inner.base10,
    alignSelf: "center",
  };
});
