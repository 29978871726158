import "./index.css";

import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { Icon, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Companies, TickCircleFilled } from "@alphasights/alphadesign-icons";
import { formatDate } from "utils/dates";
import { x } from "@xstyled/styled-components";
import { AlphaNowContentActions } from "pages/AlphaNowPage/components";
import { BookmarkButtonProps } from "components/BookmarkButton";
import { lastUpdatedAtHeader } from "content/AlphaNow";
import { AlphaNowContentType, ContentPurchaseStatus } from "@alphasights/client-portal-shared";
import { companyPrimerDefinition } from "content/AlphaNow";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { freePrimers } from "pages/AlphaNowPage/primers/utils/constants";
import { getAnchorCompanyIds } from "content/AlphaNow/utils";
import PrimerCompanyDateContent from "./PrimerDateContent";

export const DataTestIds = {
  companyPrimerDetails: "company-primer-details",
  lastUpdatedAtDate: "last-updated-at-date",
};

interface CompanyPrimerNameHeaderProps {
  primerCompanyName: string;
  imageSrc?: string;
  isPurchased: boolean;
  formattedDate: string;
}

interface CompanyPrimerSubheaderProps {
  dateTypeLabelText: string;
  formattedDate: string;
  purchaseStatus: ContentPurchaseStatus;
  isPurchased: boolean;
  showCompanyPrimerFullHeader: boolean;
}

interface CompanyPrimerDetailsProps {
  bookmarkButtonProps: BookmarkButtonProps;
  contentId: string;
  contentType: AlphaNowContentType;
  purchaseStatus: ContentPurchaseStatus;
  paymentRequired: boolean;
  logoLink?: CitableValue<string>;
  lastUpdatedAt?: string;
  isExpertsView: boolean;
  isShrinkedPaywallHeader: boolean;
  downloadFileName: string;
  setIsExpertsView: (value: boolean) => void;
  onReturnToMobileSearch: () => void;
  mainPrimerCompanyName: string;
  companies: Company[];
}

const CompanyPrimerNameHeader: FC<CompanyPrimerNameHeaderProps> = ({
  primerCompanyName,
  imageSrc,
  isPurchased,
  formattedDate,
}) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();

  return (
    <x.div
      display="flex"
      flexDirection="row"
      flexGrow="1"
      gap={inner.base02}
      alignItems="end"
      mb={{ xs: inner.base03, sm: 0 }}
    >
      <x.div display="flex" alignItems="center">
        {imageSrc ? (
          <x.img alt={`${primerCompanyName || "company"} logo`} src={imageSrc} maxH={inner.base08} />
        ) : (
          <Icon size="x-large">
            <Companies />
          </Icon>
        )}
      </x.div>
      <x.div display="flex" overflow="hidden" textOverflow="ellipsis">
        {isPurchased ? (
          <Typography color={color.text.strong._} component="span" mr={inner.base02} textTransform="none" variant="h3">
            {primerCompanyName}
          </Typography>
        ) : (
          <x.div display="flex" flexDirection="row" alignItems="center" gap="2">
            <Typography color={color.text.strong._} component="span" textTransform="none" variant="h3">
              {primerCompanyName}
            </Typography>
            |
            <PrimerCompanyDateContent
              lastUpdatedAtHeader={lastUpdatedAtHeader}
              formattedDate={formattedDate}
              dataTestId={DataTestIds.lastUpdatedAtDate}
            />
          </x.div>
        )}
      </x.div>
    </x.div>
  );
};

const CompanyPrimerSubheader: FC<CompanyPrimerSubheaderProps> = ({
  dateTypeLabelText,
  formattedDate,
  purchaseStatus,
  isPurchased,
  showCompanyPrimerFullHeader,
}) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const PrimerAvailableContent = () => (
    <>
      <x.span px={inner.base}>
        <Icon color="success">
          <TickCircleFilled />
        </Icon>
      </x.span>
      <Typography color={text.success} component="span">
        {purchaseStatus}
      </Typography>
    </>
  );

  const ViewFreePrimerLink = () => {
    return (
      <>
        <Typography component="span" variant="body-em">
          <Link href={freePrimers.companyPrimer} size="medium" target="_blank">
            View a free Company Primer
          </Link>
        </Typography>
      </>
    );
  };

  return (
    <x.div row alignItems="center" cursor="default" flexWrap="nowrap" color={text.secondary}>
      {isPurchased && (
        <>
          <PrimerCompanyDateContent
            lastUpdatedAtHeader={dateTypeLabelText}
            formattedDate={formattedDate}
            dataTestId={DataTestIds.lastUpdatedAtDate}
          />
          <Typography component="span" pl={inner.base} pr={inner.base02}>
            |
          </Typography>
          <PrimerAvailableContent />
        </>
      )}
      {showCompanyPrimerFullHeader && <ViewFreePrimerLink />}
    </x.div>
  );
};

const CompanyPrimerDefinition: FC = () => {
  const {
    color: { text },
    spacing: { inner },
    font: { size },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const fontSize = isMobile ? size["base"] : size["03"];

  return (
    <x.div id="markdown" mt={inner.base06} mb={inner.base03} data-testid="company-primer-definition">
      <Typography variant="body-em" color={text.secondary} fontSize={fontSize}>
        What is a Company Primer?
      </Typography>
      <Typography mt={inner.base02} color={text.strong._} fontSize={fontSize}>
        <ReactMarkdown source={companyPrimerDefinition} />
      </Typography>
    </x.div>
  );
};

const CompanyPrimerDetails: FC<CompanyPrimerDetailsProps> = ({
  companies,
  bookmarkButtonProps,
  contentId,
  contentType,
  purchaseStatus,
  lastUpdatedAt,
  logoLink,
  paymentRequired,
  isExpertsView,
  isShrinkedPaywallHeader,
  downloadFileName,
  setIsExpertsView,
  onReturnToMobileSearch,
  mainPrimerCompanyName,
}) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const formattedDate = formatDate(lastUpdatedAt, "d MMM yyyy");
  const showCompanyPrimerFullHeader = paymentRequired && !isShrinkedPaywallHeader;
  const isPurchased =
    purchaseStatus !== ContentPurchaseStatus.PurchaseRequired && purchaseStatus !== ContentPurchaseStatus.AllAvailable;

  return (
    <x.div
      data-testid={DataTestIds.companyPrimerDetails}
      top={0}
      bg={color.background.surface.page.default}
      zIndex="10"
    >
      <x.div
        className="overflow-ellipsis"
        mb={showCompanyPrimerFullHeader || isPurchased ? inner.base06 : 0}
        overflow="visible"
      >
        <x.div
          {...(!isMobile && {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-between",
          })}
          alignItems="center"
          mb={{ sm: inner.base04 }}
        >
          {!isMobile && (
            <CompanyPrimerNameHeader
              primerCompanyName={mainPrimerCompanyName}
              imageSrc={logoLink?.value}
              isPurchased={isPurchased}
              formattedDate={formattedDate}
            />
          )}

          <AlphaNowContentActions
            downloadFileName={downloadFileName}
            paymentRequired={paymentRequired}
            contentId={contentId}
            contentType={contentType}
            companyIds={getAnchorCompanyIds(companies ?? [])}
            bookmarkButtonProps={bookmarkButtonProps}
            isExpertsView={isExpertsView}
            setIsExpertsView={setIsExpertsView}
            onReturnToMobileSearch={onReturnToMobileSearch}
          />
        </x.div>
        {isMobile && (
          <CompanyPrimerNameHeader
            primerCompanyName={mainPrimerCompanyName}
            imageSrc={logoLink?.value}
            isPurchased={isPurchased}
            formattedDate={formattedDate}
          />
        )}

        {showCompanyPrimerFullHeader && <CompanyPrimerDefinition />}
        <CompanyPrimerSubheader
          isPurchased={isPurchased}
          dateTypeLabelText={lastUpdatedAtHeader}
          formattedDate={formattedDate}
          purchaseStatus={purchaseStatus}
          showCompanyPrimerFullHeader={showCompanyPrimerFullHeader}
        />
      </x.div>
    </x.div>
  );
};

export default CompanyPrimerDetails;
