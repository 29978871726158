import { x } from "@xstyled/styled-components";
import { Icon, Tooltip, useThemeTokens } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";

type PrimerCompanyLogoProps = {
  isGrayscale?: boolean;
  padding?: string;
  size?: string;
  src?: string;
  tooltip?: string;
  whiteBackground?: boolean;
};

const CompanyLogo = ({ src }: { src?: string }) => {
  const { color } = useThemeTokens();

  if (src) {
    return <x.img src={src} alt="" maxW="100%" maxHeight="100%" />;
  }

  return (
    <Icon size="large" color={color.text.secondary}>
      <Company />
    </Icon>
  );
};

const PrimerCompanyLogo = ({
  isGrayscale = false,
  whiteBackground,
  padding,
  size,
  src,
  tooltip,
}: PrimerCompanyLogoProps) => {
  const {
    color,
    shape: { border },
  } = useThemeTokens();

  // this divs maybe seem repetition, but tooltip does not accept a custom component as child
  // creating a component for this div would result in breaking the tooltip
  return !!tooltip ? (
    <Tooltip title={tooltip} variant="dark">
      <x.div
        backgroundColor={whiteBackground ? "white" : "transparent"}
        w={size ?? "90px"}
        h={size ?? "90px"}
        padding={padding}
        border={`1px solid ${color.border.divider}`}
        borderRadius={border.radius.small}
        display="inline-flex"
        overflow="hidden"
        alignItems="center"
        justifyContent="center"
        style={{
          filter: isGrayscale ? "grayscale(100%)" : "none",
          opacity: isGrayscale ? "0.3" : "1",
        }}
      >
        <CompanyLogo src={src} />
      </x.div>
    </Tooltip>
  ) : (
    <x.div
      data-testid="primer-company-logo"
      backgroundColor={whiteBackground ? "white" : "transparent"}
      w={size ?? "90px"}
      h={size ?? "90px"}
      padding={padding}
      border={`1px solid ${color.border.divider}`}
      borderRadius={border.radius.small}
      display="inline-flex"
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      style={{
        filter: isGrayscale ? "grayscale(100%)" : "none",
        opacity: isGrayscale ? "0.3" : "1",
      }}
    >
      <CompanyLogo src={src} />
    </x.div>
  );
};

export default PrimerCompanyLogo;
