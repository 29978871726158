import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Skeleton, useThemeTokens, Icon, Typography, ContentCard } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { MessengerThreadCard } from "../MessengerThreadCard/MessengerThreadCard";
import { useMessengerSidebarStyles } from "./MessengerSidebar.styles";
import { DropdownButton } from "components/DropdownButton";
import { Inbox, Mail } from "@alphasights/alphadesign-icons";
import { useMessengerContext } from "providers/MessengerProvider";
import { useLocation } from "router-utils";
import { MessageType, RequestType } from "types";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";
import { useIsOverflow } from "@alphasights/client-portal-shared";
import { HeaderPortal } from "components/InteractionsPage/HeaderPortal";
import { ToggleButton } from "components/ToggleButton/ToggleButton";
import { isEqual } from "lodash";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ProjectFeature } from "@alphasights/portal-api-client";

export const MessengerSidebar = () => {
  const { inboxes, inboxLoading, isFlyoutOpened } = useMessengerContext();

  const { container } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
  });

  const location = useLocation();

  const { onSelectThread, onSendNewMessage } = useMessengerContext();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const selectedThread = search.get("selectedThread");
    const newMessage = Object.values(MessageType).includes(search.get("newMessage") as MessageType)
      ? (search.get("newMessage") as MessageType)
      : undefined;

    if (newMessage) {
      onSendNewMessage(newMessage, true);
    } else if (selectedThread) {
      onSelectThread(selectedThread);
    }
  }, [location.search, inboxes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div {...container} data-testid="messenger-sidebar">
      <ExpandSidebarButton />
      <ExpandedSidebar showSkeleton={inboxLoading} />
    </x.div>
  );
};

const ExpandSidebarButton = () => {
  const {
    setIsSettingsOpened,
    onCloseAdvisorFlyout,
    isFlyoutOpened,
    isSettingsOpened,
    isAdvisorFlyoutOpened,
  } = useMessengerContext();

  const { sidebarCollapsedContent } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
  });

  const onExpandSidebar = () => {
    if (isSettingsOpened) {
      setIsSettingsOpened(false);
    } else if (isAdvisorFlyoutOpened) {
      onCloseAdvisorFlyout();
    }
  };

  return (
    <x.div {...sidebarCollapsedContent}>
      <Icon size="large" color="secondary" onClick={isFlyoutOpened ? onExpandSidebar : undefined}>
        <Inbox />
      </Icon>
    </x.div>
  );
};

const ExpandedSidebar = ({ showSkeleton }: { showSkeleton: boolean }) => {
  const {
    inboxes,
    selectedInbox,
    onSelectCard,
    onSendNewMessage,
    isFlyoutOpened,
    newMessageType,
  } = useMessengerContext();
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isSendMessagesDisabled = isFeatureDisabled(ProjectFeature.SendMessages);

  const [messageTypeFilter, setMessageTypeFilter] = useState<RequestType[]>([]);
  const listRef = useRef(null);
  const isOverflow = useIsOverflow(listRef, [showSkeleton, inboxes], "vertical");

  const isNewMessageMode = useMemo(() => !!newMessageType, [newMessageType]);

  const { sidebarExpandedContent, messagesList, messagesListViewPort, topBar } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
    isOverflow,
  });

  const filteredInboxes = useMemo(
    () =>
      messageTypeFilter.length > 0 ? inboxes.filter((inbox) => messageTypeFilter.includes(inbox.requestType)) : inboxes,
    [messageTypeFilter, inboxes]
  );

  useEffect(
    function selectFirstInboxOnFiltersChange() {
      const selectedInboxShowing = selectedInbox && filteredInboxes.some((i) => i.groupId === selectedInbox.groupId);

      if (!selectedInboxShowing && !isNewMessageMode) {
        const newSelectedInbox = filteredInboxes.at(0);
        newSelectedInbox && onSelectCard(newSelectedInbox.groupId);
      }
    },
    [filteredInboxes, selectedInbox, onSelectCard, isNewMessageMode]
  );

  const newMessageOptions = [
    {
      label: "Work Request",
      action: () => onSendNewMessage(MessageType.WorkRequest, true),
    },
    {
      label: "Clarification",
      action: () => onSendNewMessage(MessageType.Clarification, true),
    },
    {
      label: "Call Guide",
      action: () => onSendNewMessage(MessageType.CallGuide, true),
    },
  ];

  return (
    <x.div {...sidebarExpandedContent}>
      <HeaderPortal>
        <x.div {...topBar}>
          <div>
            {!isSendMessagesDisabled && (
              <DropdownButton
                size="small"
                label="New Message"
                startIcon={<Mail />}
                options={newMessageOptions}
                data-testid="new-message-button"
              />
            )}
          </div>
          <MessageTypeFilter
            appliedFilter={messageTypeFilter}
            onFilterChange={(newFilter) => setMessageTypeFilter(newFilter)}
          />
        </x.div>
      </HeaderPortal>
      {showSkeleton ? (
        <MessengerSidebarSkeleton />
      ) : filteredInboxes.length > 0 ? (
        <x.div ref={listRef} {...messagesListViewPort}>
          <x.div {...messagesList}>
            {filteredInboxes.map((inbox) => (
              <MessengerThreadCard
                inbox={inbox}
                isSelected={selectedInbox?.groupId === inbox.groupId}
                onClick={() => onSelectCard(inbox.groupId)}
                key={inbox.groupId}
              />
            ))}
          </x.div>
        </x.div>
      ) : (
        <NoMessagesPanel />
      )}
    </x.div>
  );
};

const NoMessagesPanel = () => {
  const { isFlyoutOpened } = useMessengerContext();

  const { noMessagesPanel } = useMessengerSidebarStyles({
    isSidebarCollapsed: isFlyoutOpened,
  });

  return (
    <x.div {...noMessagesPanel}>
      <Typography variant="body-em" color="secondary">
        No messages
      </Typography>
      <Typography variant="body-small" color="secondary">
        Messages sent to experts will display here after they have been sent
      </Typography>
    </x.div>
  );
};

const MessengerSidebarSkeleton = () => {
  const {
    spacing: { layout },
  } = useThemeTokens();

  const listRef = useRef(null);
  const isOverflow = useIsOverflow(listRef, [], "vertical");

  const { messagesList, messagesListViewPort, topBar, sidebarExpandedContent } = useMessengerSidebarStyles({
    isSidebarCollapsed: false,
    isOverflow,
  });

  return (
    <x.div {...sidebarExpandedContent}>
      <x.div {...topBar}>
        <x.div ml="auto">
          <NoXMarginSkeleton width={layout.base15} />
        </x.div>
      </x.div>
      <x.div ref={listRef} {...messagesListViewPort}>
        <x.div {...messagesList}>
          <InboxCardSkeleton />
          <InboxCardSkeleton />
          <InboxCardSkeleton />
          <InboxCardSkeleton />
          <InboxCardSkeleton />
        </x.div>
      </x.div>
    </x.div>
  );
};

const InboxCardSkeleton = () => {
  return (
    <ContentCard w="100%">
      <x.div display="flex" flexDirection="column" gap="4px">
        <Skeleton variant="noMargin" width="30%" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" width="70%" />
      </x.div>
    </ContentCard>
  );
};

const MessageTypeFilter = ({
  appliedFilter,
  onFilterChange,
}: {
  appliedFilter: RequestType[];
  onFilterChange: (filters: RequestType[]) => void;
}) => {
  const { inboxes } = useMessengerContext();
  const { filterToggleButtons } = useMessengerSidebarStyles();

  const toggleButtonOptions = useMemo(
    () =>
      [{ value: [] as RequestType[], label: "All" }].concat(
        [
          { value: [RequestType.WorkRequest], label: "Work Request" },
          { value: [RequestType.Clarification], label: "Clarification" },
          { value: [RequestType.CallGuide], label: "Call Guide" },
        ].filter((option) => inboxes.some((inbox) => option.value.includes(inbox.requestType)))
      ),
    [inboxes]
  );

  return (
    <x.div {...filterToggleButtons} data-testid="message-type-filter">
      {toggleButtonOptions.map((option) => (
        <Fragment key={option.label}>
          <ToggleButton
            title={option.label}
            selected={isEqual(appliedFilter, option.value)}
            onClick={() => onFilterChange(option.value)}
          />
        </Fragment>
      ))}
    </x.div>
  );
};
