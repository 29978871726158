import { useMemo } from "react";
import * as React from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { LabeledIcon, PrimerFieldTitle, SectionTitle } from "pages/AlphaNowPage/primers/components";
import { SectionWithTopics, StackedSingleBarChart } from "pages/AlphaNowPage/primers/CustomerPrimer/components";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state/store";
import AllocationChart from "./AllocationChart";
import OutlookEmergingVendors from "./OutlookEmergingVendors";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const Outlook = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { color, spacing } = useThemeTokens();

  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const outlook = useCustomerPrimersStore(({ outlook }) => outlook);
  const { allocation, change, concerned, emerging, excited } = outlook;

  const changeCharSeriesNames = ["< -20%", "< -10%", "< -5%", "< -5% to 5%", "> 5%", "> 10%", "> 20%"];
  const changeChartColors = [
    color.base.red[600],
    color.base.red[500],
    color.base.yellow[600],
    color.base.yellow[500],
    "#10B77F",
    "#08875F",
    "#047756",
  ];

  const calculatedRanks = useMemo(
    () => [
      ...[...Array(7).keys()].map((i) => ({
        citedBy: change.ranks
          .filter(({ value }) => value === i + 1)
          .map(({ citedBy }) => citedBy)
          .flat(),
        isSensitive: false,
        value: change.ranks.filter(({ value }) => value === i + 1).length,
      })),
    ],
    [change.ranks]
  );

  return (
    <SectionWrapper ref={ref} px={spacing.inner.base06}>
      <SectionTitle dataTestId="OutlookSectionTitle" text="Outlook" heading />

      <PrimerFieldTitle text="How will market spend change in the next three years?" mt={spacing.inner.base05} />

      <LabeledIcon
        labelText="Customers estimated how market spend would change in the next three years. Click into a bar to see different customers' insights."
        iconItem={<Info />}
        mt={spacing.inner.base}
        labelColor={color.text.assistive}
        iconColor={color.text.assistive}
      />
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <StackedSingleBarChart
          ranks={calculatedRanks}
          seriesNames={changeCharSeriesNames}
          chartColors={changeChartColors}
          sectionName="OUTLOOK_SPEND_CHART"
        />
      </SensitiveDataContainer>
      <SectionWithTopics
        sectionTitle={"Describe why market spend will change in the next three years."}
        summary={change.summary}
        categories={change.categories}
        mt={spacing.layout.base03}
        useDivider
      />

      <PrimerFieldTitle text="Market Spend Allocation" />
      <LabeledIcon
        labelText={`Customers estimated how market spend on each vendor will change in the next three years.`}
        iconItem={<Info />}
        mt={spacing.inner.base}
        labelColor={color.text.assistive}
        iconColor={color.text.assistive}
      />
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <AllocationChart ranks={allocation} />
      </SensitiveDataContainer>

      <PrimerFieldTitle text="Who are the emerging vendors in the market?" />

      <SensitiveDataContainer isSensitive={!isAccessible}>
        <OutlookEmergingVendors emerging={emerging} />
      </SensitiveDataContainer>

      <SectionWithTopics
        sectionTitle={"What are you most excited about in the market?"}
        summary={excited.summary}
        categories={excited.categories}
        mt={spacing.layout.base05}
      />

      <SectionWithTopics
        sectionTitle={"What are you most concerned about in the market?"}
        summary={concerned.summary}
        categories={concerned.categories}
        mt={spacing.layout.base03}
      />
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Outlook));
