import { Typography } from "@alphasights/alphadesign-components";
import styled, { th } from "@xstyled/styled-components";

export const BannerWrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 100;
`;

export const AbsoluteBottomWrapper = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  pointer-events: none;
`;

export const CenteredColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  pointer-events: auto;
`;

export const StyledTypography = styled(Typography).attrs({
  variant: "body-small-em",
})`
  padding-right: ${th.space("inner-base02")};
`;
