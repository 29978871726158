import { FC } from "react";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";

import { DocumentRow } from "views/DeliverablesView/ThirdPartyAssignDocsModal/DocumentsTable/types";
import { formatDate } from "views/DeliverablesView/ThirdPartyAssignDocsModal/DocumentsTable/utils";

const DateCell: FC<AlphaTableTypes.CellContext<DocumentRow, any>> = ({
  row: {
    original: { date },
  },
}) => {
  return <>{formatDate(date)}</>;
};

export default DateCell;
