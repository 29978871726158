import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const MentionContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base03,
    justifyContent: "space-between",
    alignItems: "center",
  };
});

export const MentionTextContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    gap: spacing.inner.base03,
    display: "inline-flex",
  };
});

export const StyledDivider = styled(Divider)(() => {
  const { spacing } = useThemeTokens();
  return {
    marginBlock: spacing.inner.base04,
  };
});
