import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

import { SearchSizeVariant, STYLE_CONFIG } from "components/Search/consts";

const StyledInput = styled(x.input)(
  ({ sizeVariant, isLastItem }: { sizeVariant: SearchSizeVariant; isLastItem: boolean }) => {
    const { color, spacing, font } = useThemeTokens();
    const {
      typography: typographyStyles,
      input: { height },
    } = STYLE_CONFIG[sizeVariant];
    return {
      outline: "none",
      minHeight: height,
      borderRadius: "inherit",
      background: "inherit",
      marginRight: isLastItem ? 0 : spacing.inner.base,
      "&::placeholder": {
        color: color.text.secondary,
        fontWeight: font.weight.regular,
      },
      "&:focus": {
        outline: "none",
      },
      "&:focus::placeholder": {
        opacity: 1,
      },
      ...typographyStyles,
    };
  }
);

export { StyledInput };
