const ERROR_OCCURRED_MESSAGE = "Error occurred:";
const ERROR_GENERATING_QUESTION_VIEW_MESSAGE = "unable to generate questions view. Please try refreshing the page.";

const NO_QUESTIONS_MESSAGE = "The document is too small to generate questions view.";

const GENERATING_QUESTION_TITLE = "Questions view is generating";
const GENERATING_QUESTION_MESSAGE = "Check back in a few minutes...";

const MINIUM_INPUT_CHAR_LENGTH = 25;
const REPHRASE_QUESTION_MESSAGE = "Input too brief. Please rephrase the question and try again.";
const SUBMIT_QUESTION_MESSAGE = "Add new question";
const ERROR_SUBMITTING_QUESTION_MESSAGE = "Unable to generate response. Please try again.";

const COPY_SUMMARY_MESSAGE = "Copy summary";
const COPY_QUOTE_MESSAGE = "Copy quote";

const PROCESSING_QUESTION_MESSAGE = "Searching for expert insights...";

const ERROR_PROCESSING_QUESTION_MESSAGE = "unable to process this question. Please try rephrasing it.";

const DOCUMENT_QUESTIONS_KEY = "documentQuestions";

enum QuestionProcessionStatus {
  completed = "completed",
  pending = "pending",
  failed = "failed",
}

export {
  GENERATING_QUESTION_TITLE,
  GENERATING_QUESTION_MESSAGE,
  REPHRASE_QUESTION_MESSAGE,
  SUBMIT_QUESTION_MESSAGE,
  MINIUM_INPUT_CHAR_LENGTH,
  ERROR_SUBMITTING_QUESTION_MESSAGE,
  COPY_SUMMARY_MESSAGE,
  COPY_QUOTE_MESSAGE,
  ERROR_OCCURRED_MESSAGE,
  ERROR_GENERATING_QUESTION_VIEW_MESSAGE,
  NO_QUESTIONS_MESSAGE,
  PROCESSING_QUESTION_MESSAGE,
  ERROR_PROCESSING_QUESTION_MESSAGE,
  DOCUMENT_QUESTIONS_KEY,
  QuestionProcessionStatus,
};
