import styled, { x } from "@xstyled/styled-components";

const TableWrapper = styled(x.div)(() => {
  return `
    table {
      thead {
        th:first-child {
          padding-left: 16px;
        }
      }
    }
  `;
});

export { TableWrapper };
