import { MouseEvent, useLayoutEffect, useRef } from "react";
import * as React from "react";
import {
  Icon,
  Pill,
  PillSize,
  PillVariants,
  Typography,
  TypographyVariation,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { CSSProperties, Props, x } from "@xstyled/styled-components";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";

import { SearchOption } from "components/Search/types";
import useForwardedRef from "pages/AlphaNowPage/hooks/useForwardedRef";
import { STYLE_CONFIG, SearchSizeVariant } from "components/Search/consts";
import { PILL_CONFIG } from "./consts";

const DataTestIds = {
  SearchItemPill: "search-item-pill",
  PillStartIcon: "pill-start-icon",
  SearchItemText: "search-item-text",
};

export interface SearchItemProps extends Props {
  index: number;
  isLastItem: boolean;
  data: SearchOption;
  onRemove: () => void;

  onClick?: (e: MouseEvent<HTMLElement>) => void;
  size?: SearchSizeVariant;
  isBoolean?: boolean;
  asPlainText?: boolean;
  variant?: PillVariants;
  accentColor?: CSSProperties["color"];
}

const SearchItem = React.forwardRef(
  (
    {
      index,
      data: { label, value, StartIcon },
      isLastItem,
      isBoolean = false,
      asPlainText = false,
      onRemove,
      onClick,
      size = SearchSizeVariant.Medium,
      variant = "blue",
      accentColor,
      ...props
    }: SearchItemProps,
    ref: React.ForwardedRef<HTMLElement>
  ) => {
    const { spacing } = useThemeTokens();

    const { ref: innerRef, updateRef: updateSearchItemRef } = useForwardedRef(ref);
    const displayLabel = label ?? value;

    const { current: styles } = useRef(STYLE_CONFIG[size]);
    const {
      icon: { size: iconSize },
      pill: { size: pillSize, xStyledProps: pillStyles },
      typographyVariant,
    } = styles;

    const { current: pillConfig } = useRef(PILL_CONFIG[variant as PillVariants]);
    const { iconColor: pillIconColor, pillVariant } = pillConfig;

    useLayoutEffect(() => {
      const observer = new ResizeObserver(() => {
        if (innerRef?.current) {
          if (!asPlainText) {
            const [wrapper] = innerRef.current.children as HTMLCollectionOf<HTMLElement>;
            wrapper.style.whiteSpace = "nowrap";
            observer.unobserve(innerRef.current);
          }
        }
      });
      !!innerRef?.current && observer.observe(innerRef.current);
      return () => {
        observer.disconnect();
      };
    }, [innerRef, asPlainText]);

    if (asPlainText) {
      return (
        <x.div display="flex" gap={spacing.inner.base02} alignItems="center">
          {StartIcon && !isBoolean && <StartIcon />}
          <Typography
            id={`search-item-${index}`}
            ref={updateSearchItemRef}
            data-testid={DataTestIds.SearchItemText}
            variant={typographyVariant as TypographyVariation}
            component="span"
            minWidth={spacing.inner.base}
            outline="none"
            onClick={onClick}
            {...(accentColor && { color: accentColor })}
            {...props}
          >
            {label}
          </Typography>
        </x.div>
      );
    }

    return (
      <Pill
        id={`search-item-${index}`}
        data-testid={DataTestIds.SearchItemPill}
        variant={pillVariant as PillVariants}
        size={pillSize as PillSize}
        ref={updateSearchItemRef}
        onClick={onClick}
        onClose={onRemove}
        cursor="grab"
        py={`${spacing.inner.base} !important`}
        pl={`${StartIcon ? spacing.inner.base : spacing.inner.base02} !important`}
        pr={`${spacing.inner.base} !important`}
        leftAccessories={
          StartIcon && (
            <span data-testid={DataTestIds.PillStartIcon}>
              <Icon color={pillIconColor} size={iconSize}>
                <StartIcon />
              </Icon>
            </span>
          )
        }
        {...pillStyles}
        {...props}
      >
        <LimitedLinesTypography
          variant={typographyVariant as TypographyVariation}
          component="span"
          tooltip={displayLabel}
        >
          {displayLabel}
        </LimitedLinesTypography>
      </Pill>
    );
  }
);

export { SearchItem as default, DataTestIds };
