import { ForwardedRef, forwardRef } from "react";
import { ContentCard, ContentCardProps } from "@alphasights/alphadesign-components";

const Card = forwardRef(({ children, ...props }: ContentCardProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <ContentCard ref={ref} {...props}>
      {children}
    </ContentCard>
  );
});

export default Card;
