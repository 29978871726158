import { useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import { SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";
import { ChartLegend, ChartTooltip } from "pages/AlphaNowPage/primers/components";
import YAxis from "./chart/YAxis";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

const UsageAndAwarenessChart = () => {
  const sectionName = "USAGE_AND_AWARENESS_CHART";
  const [selectedSerie, setSelectedSerie] = useState<number | null>(null);
  const { color, typography, font } = useThemeTokens();
  const [chartKey, setChartKey] = useState<number>(0);
  const isSidebarExpanded = useCustomerPrimersStore(({ isSidebarExpanded }) => isSidebarExpanded);
  const usageAndAwareness = useCustomerPrimersStore(({ usageAndAwareness }) => usageAndAwareness);
  const expertCount = usePrimersStore(({ expertCount }) => expertCount);

  const expertsMentionedSection = useMentionedExpertsStore(({ expertsMentionedSection }) => expertsMentionedSection);
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );
  const usageAndAwarenessColors = [
    color.chart.categorical.base03,
    color.chart.categorical.base04,
    color.chart.categorical.base07,
    color.chart.categorical.base,
    color.chart.categorical.base09,
  ];
  const { getChartColors } = useGetChartColors({
    colors: usageAndAwarenessColors,
    isStacked: true,
    numberOfBars: usageAndAwareness.vendors.length,
  });
  const seriesNames = useMemo(() => ["Uses", "Churned", "Evaluated, did not select", "Aware", "Unaware"], []);
  const tooltipSeriesNames = ["Uses", "Churned", "Evaluated, did not select", "Aware", "Unaware"];

  const getSeries = useMemo(
    () => () => {
      return [
        { name: seriesNames[0], data: usageAndAwareness.vendors.map((entry) => entry.uses.length ?? 0) },
        { name: seriesNames[1], data: usageAndAwareness.vendors.map((entry) => entry.churned.length ?? 0) },
        {
          name: seriesNames[2],
          data: usageAndAwareness.vendors.map((entry) => entry.evaluated.length ?? 0),
        },
        { name: seriesNames[3], data: usageAndAwareness.vendors.map((entry) => entry.aware.length ?? 0) },
        { name: seriesNames[4], data: usageAndAwareness.vendors.map((entry) => entry.unaware.length ?? 0) },
      ];
    },
    [seriesNames, usageAndAwareness]
  );

  const getCompaniesNames = useMemo(
    () => () => usageAndAwareness.vendors.map(({ companyName }) => companyName ?? "-"),
    [usageAndAwareness]
  );

  useEffect(() => {
    // we need this because, due to component memoization, the chart was
    // not resizing when the search bar collapsed at first load.
    //
    // to solve this we get the expanded status of the search bar and
    // when it changes we enforce a new key to the chart. We use a setTimeout
    // to defer this change to when the animation finishes + half a second,
    // to ensure the size is correctly calculated
    let timer = setTimeout(
      () => setChartKey((currentState) => currentState + 1),
      parseFloat(SIDEBAR_ANIMATION_SPEED.substring(0, SIDEBAR_ANIMATION_SPEED.length - 1)) * 1000 + 500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [isSidebarExpanded]);

  useEffect(() => {
    if (expertsMentionedSection !== sectionName && selectedSerie !== null) {
      setSelectedSerie(null);
    }
  }, [expertsMentionedSection, selectedSerie]);

  const getVendorUsageTypeSpeakers = (vendorName: string, usageType: string) => {
    const vendor = usageAndAwareness.vendors.find((vendor) => vendor.companyName === vendorName);

    switch (usageType) {
      case seriesNames[0]:
        return vendor?.uses;
      case seriesNames[1]:
        return vendor?.churned;
      case seriesNames[2]:
        return vendor?.evaluated;
      case seriesNames[3]:
        return vendor?.aware;
      case seriesNames[4]:
        return vendor?.unaware;
      default:
        return [];
    }
  };

  const options = {
    chart: {
      fontFamily: font.family.text.regular,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          const newSelectedSerie = seriesNames.length * config.dataPointIndex + config.seriesIndex;

          if (newSelectedSerie === selectedSerie) {
            resetExpertsMentioned();
            setSelectedSerie(null);
          } else {
            const company = getCompaniesNames()[config.dataPointIndex];
            const serieType = getSeries()[config.seriesIndex].name;
            const speakersIds = getVendorUsageTypeSpeakers(company, serieType) ?? [];

            setExpertsMentionedSectionAndExpertsIds(sectionName, speakersIds);
            setSelectedSerie(newSelectedSerie);
          }
        },
      },
      parentHeightOffset: 0,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: false,
        },
        barHeight: "25px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: getCompaniesNames(),
      title: {
        text: "Customers",
        style: {
          fontWeight: typography.body.small.fontWeight,
          fontSize: typography.body.small.fontSize,
          color: color.text.strong._,
        },
        offsetY: 15,
      },
      tickAmount: expertCount / 2,
      max: expertCount,
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      style: {
        fontWeight: typography.body.small.fontWeight,
        fontSize: typography.body.small.fontSize,
        color: color.text.strong._,
      },
      labels: {
        show: false,
      },
    },
    tooltip: {
      followCursor: true,
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        return ReactDOMServer.renderToString(
          <ChartTooltip>
            {tooltipSeriesNames[seriesIndex]} - {series[seriesIndex][dataPointIndex]} expert
            {series[seriesIndex][dataPointIndex] === 1 ? "" : "s"}
          </ChartTooltip>
        );
      },
    },
    colors: [
      function ({ seriesIndex, dataPointIndex }: { seriesIndex: any; dataPointIndex: any }) {
        // because the chart is stacked, we replicate the colors per lines so that we can have only one selected
        return getChartColors(selectedSerie)[seriesNames.length * dataPointIndex + seriesIndex];
      },
    ],
    fill: {
      opacity: 1,
    },
    title: {
      text: undefined,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    // this avoids the color to be darker when clicking on an entry
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
  } as ApexOptions;

  return (
    <x.div id="chart" display="flex">
      <YAxis vendors={getCompaniesNames()} />
      <x.div flex="1 1 auto">
        <Chart
          key={chartKey}
          options={options}
          series={getSeries()}
          type="bar"
          height={50 * getCompaniesNames().length + 100}
        />
        <ChartLegend
          elements={getSeries().map(({ name }, index) => ({
            color: getChartColors(null)[index],
            label: name,
          }))}
        />
      </x.div>
    </x.div>
  );
};

export default UsageAndAwarenessChart;
