import { ThirdPartyDocument, ThirdPartyDocumentType } from "types";

const getEditDocumentProps = (document: ThirdPartyDocument) => {
  const {
    attribute: { documentDate, title, experts },
    createdAt,
    documentType,
    redactedFilename,
  } = document;
  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const date = documentDate ?? createdAt;
  return {
    documentDate: date,
    isTranscript,
    fileName: redactedFilename,
    title,
    experts,
  };
};

export { getEditDocumentProps };
