import React, { useCallback, useEffect, useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  AlphaDesignTheme,
  AlphaDesignThemeContextProvider,
  Icon,
  Typography,
  useThemeTokens,
  SelectMenu,
  SelectOption,
} from "@alphasights/alphadesign-components";
import { CalendarToday } from "@alphasights/alphadesign-icons";
import { getHours, getMinutes, set } from "date-fns";
import { InputsContainer } from "./styled";
import { TextInputWithDatePicker } from "components/TextInputWithDatePicker/TextInputWithDatePicker";

export const WorkRequestDeadline = ({
  value,
  label = "Deadline",
  onChange = (value) => {},
  labelWithRequired = false,
  errorMessage = undefined,
  disabled = false,
  mobileVariant = false,
}: {
  value?: Date;
  label?: string;
  onChange?: (value: Date) => void;
  labelWithRequired?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  mobileVariant?: boolean;
}) => {
  const [selectedHour, setSelectedHour] = useState<number>();
  const [selectedMinutes, setSelectedMinutes] = useState<number>();
  const [selectedDayOption, setSelectedDayOption] = useState<Date>();

  useEffect(() => {
    const initialDate = value
      ? set(new Date(value.getTime()), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
      : undefined;

    const initialHour = value ? getHours(value) : undefined;
    const initialMinute = value ? getMinutes(value) : undefined;

    setSelectedHour(initialHour === 0 ? -1 : initialHour);
    setSelectedMinutes(initialMinute === 0 ? -1 : initialMinute);
    setSelectedDayOption(initialDate);
  }, [value]);

  const onSelectDay = useCallback(
    (date: Date) => {
      setSelectedDayOption(date);

      if (selectedHour === undefined && selectedMinutes === undefined) {
        setSelectedHour(23);
        setSelectedMinutes(45);
      }
    },
    [selectedHour, selectedMinutes]
  );

  useEffect(() => {
    if (selectedDayOption && selectedHour && selectedMinutes) {
      onChange(
        set(selectedDayOption, {
          hours: selectedHour === -1 ? 0 : selectedHour,
          minutes: selectedMinutes === -1 ? 0 : selectedMinutes,
          seconds: 0,
          milliseconds: 0,
        })
      );
    }
  }, [selectedDayOption, selectedHour, selectedMinutes, onChange]);

  const hoursValues = Array.from({ length: 24 }, (x, i) => ({
    value: i === 0 ? -1 : i,
    label: `0${i}`.slice(-2),
  }));

  const minutesValues = Array.from({ length: 4 }, (x, i) => ({
    value: i === 0 ? -1 : i * 15,
    label: `0${i * 15}`.slice(-2),
  }));

  const { spacing, color } = useThemeTokens();

  const style = {
    borderColor: errorMessage ? color.border.danger : color.border.neutral.default,
    cursor: disabled ? "not-allowed" : "pointer",
  };

  return (
    <AlphaDesignThemeContextProvider theme={AlphaDesignTheme.portal}>
      {label && (
        <x.div pb={spacing.inner.base}>
          <Typography color="assistive" component="span">
            {label}
          </Typography>
          {labelWithRequired && (
            <Typography color="danger" component="span">
              {"*"}
            </Typography>
          )}
        </x.div>
      )}

      <InputsContainer data-testid="work-request-deadline">
        <TextInputWithDatePicker
          format="D MMM YYYY"
          value={selectedDayOption}
          size={mobileVariant ? "small" : "medium"}
          endAdornment={
            <Icon color="secondary" size={mobileVariant ? "small" : "medium"}>
              <CalendarToday />
            </Icon>
          }
          isMobile={mobileVariant}
          onChange={onSelectDay}
          error={errorMessage ? { message: undefined } : undefined}
          applyOnClick={!mobileVariant}
          disablePastDays={true}
          placeholder="Select date"
          disabled={disabled}
        />

        <SelectMenu
          input={mobileVariant ? false : true}
          dataAttributes={{ "data-testid": "work-request-deadline-select-hour" }}
          style={style}
          value={selectedHour}
          placeholder="12"
          hasError={errorMessage !== undefined}
          minWidth="120px"
          maxWidth="120px"
          onChange={(value) => setSelectedHour(value as number)}
          scrollToSelectedOnOpen={true}
          isClearButtonEnabled={false}
          disabled={disabled}
          size={mobileVariant ? "small" : "medium"}
        >
          {hoursValues.map((option) => (
            <SelectOption
              key={option.value}
              {...option}
              data-testid={`work-request-deadline-select-hour-option-${option.value}`}
            >
              {option.label}
            </SelectOption>
          ))}
        </SelectMenu>

        <SelectMenu
          input={mobileVariant ? false : true}
          dataAttributes={{ "data-testid": "work-request-deadline-select-minute" }}
          style={style}
          value={selectedMinutes}
          hasError={errorMessage !== undefined}
          placeholder="00"
          onChange={(value) => setSelectedMinutes(value as number)}
          minWidth="120px"
          maxWidth="120px"
          scrollToSelectedOnOpen={true}
          isClearButtonEnabled={false}
          disabled={disabled}
          size={mobileVariant ? "small" : "medium"}
        >
          {minutesValues.map((option) => (
            <SelectOption
              key={option.value}
              {...option}
              data-testid={`work-request-deadline-select-minute-option-${option.value}`}
            >
              {option.label}
            </SelectOption>
          ))}
        </SelectMenu>
      </InputsContainer>
      {errorMessage && (
        <Typography component="span" variant="body-small" color="danger">
          {errorMessage}
        </Typography>
      )}
    </AlphaDesignThemeContextProvider>
  );
};
