export const withNumberParam = (fn: (param: number) => void) => (param: unknown) => {
  const parsedParam = typeof param === "string" ? parseInt(param, 10) : param;

  if (typeof parsedParam !== "number" || isNaN(parsedParam)) {
    console.warn("Provided param is not a number:", param);
    return;
  }

  return fn(parsedParam);
};
