import { addSeconds, isFuture, isWithinInterval, subMinutes } from "date-fns";
import { distinctBy } from "../../../helpers/arrayHelpers";
import { parseISO } from "providers/TimezoneProvider";
import { ExpertTableInteractionType, InteractionGroup, InteractionWithOtherCountsAndStates } from "models/ExpertTable";

export const pendingRequestLabels: Record<string, string> = {
  CANCEL_REQUEST: "Cancellation",
  RESCHEDULE_REQUEST: "Reschedule",
  SCHEDULE_REQUEST: "Schedule",
};

export const pendingRequestTooltip: Record<string, string> = {
  CANCEL_REQUEST: "cancel",
  RESCHEDULE_REQUEST: "reschedule",
  SCHEDULE_REQUEST: "schedule",
};

export const enrichInteraction = (interaction: Interaction, isInteractionActionsDisabled: boolean) => {
  const nonCallInteractionTypes = [
    "Scheduling Issues",
    "Referral Payment",
    "Miscellaneous Payment",
    "Expert Expense",
    "Consultant Payment",
  ];

  const isSurvey = interaction.interactionType === "Industry Survey";
  const isNonCallInteraction = nonCallInteractionTypes.includes(interaction.interactionType);
  const hasAdvisorAvailability = interaction.advisorAvailability?.length > 0;
  const hasClientAvailability = interaction.clientTimeslots?.find(({ startsAt }) => isFuture(parseISO(startsAt)));
  const requestedSchedulable = interaction.state === "requested" && !isSurvey;
  const showInstantSchedule =
    interaction.state === "proposed" &&
    interaction.autoBookEnabled &&
    hasAdvisorAvailability &&
    !isSurvey &&
    !isInteractionActionsDisabled;
  const showRequest = interaction.state === "proposed" && !showInstantSchedule && !isInteractionActionsDisabled;
  const showSchedule = requestedSchedulable && hasAdvisorAvailability && !isInteractionActionsDisabled;
  const showLeaveAvailability = requestedSchedulable && !isInteractionActionsDisabled;
  const showFollowUp =
    /completed/.test(interaction.state) &&
    !(interaction.interactionType === "Industry Survey" ? interaction.hasPendingFollowUp : interaction.followUpId) &&
    interaction.isActiveProject &&
    !isInteractionActionsDisabled;

  return {
    isSurvey,
    isNonCallInteraction,
    hasAdvisorAvailability,
    hasClientAvailability,
    showInstantSchedule,
    showRequest,
    showSchedule,
    showLeaveAvailability,
    showFollowUp,
    ...interaction,
  };
};

export const groupByInteractionGroupAndExpertAngle = (experts: Expert[]) =>
  groupByInteractionGroups(groupedRepresentationForExperts(experts));

const applicableDate = (interaction: Interaction) =>
  parseISO(interaction.scheduledCallTime).getTime() || parseISO(interaction.requestedAt).getTime() || 0;

export const sortInteractionChain = (interactions: Interaction[]) =>
  interactions.sort(
    (first, second) => second.priorityHit - first.priorityHit || applicableDate(second) - applicableDate(first)
  );

export const filterOtherInteractions = (interactions: Interaction[], mainInteraction?: Interaction) =>
  interactions
    .filter(
      (interaction) => interaction.interactionType !== "Work Product" && interaction.interactionType !== "Work Request"
    )
    .filter((interaction) => interaction !== mainInteraction);

export const mainInteractionFor = (interactions: Interaction[]) => sortInteractionChain(interactions)[0];

const countStates = (interactions: Interaction[]) =>
  interactions.reduce(
    (acc: { [key: string]: number }, curr) => ({
      ...acc,
      [curr.state]: (acc[curr.state] || 0) + 1,
    }),
    {}
  );
// should this be deprecated in favor of groupByInteractionGroupAndExpertAngle?
export const groupedRepresentationForExperts = (experts: Expert[], chainIdSelected?: string) =>
  experts.map(({ interactions }) => groupedRepresentationFor(interactions, chainIdSelected));

export const groupedRepresentationFor = (
  interactions: Interaction[],
  chainIdSelected?: string
): InteractionWithOtherCountsAndStates => {
  let mainInteraction =
    (chainIdSelected && interactions.find((i) => i.id === chainIdSelected)) || mainInteractionFor(interactions);

  let otherInteractions = filterOtherInteractions(interactions, mainInteraction);

  if (
    (mainInteraction?.interactionType === "Work Product" || mainInteraction?.interactionType === "Work Request") &&
    otherInteractions.length !== 0
  ) {
    mainInteraction = otherInteractions[0];
    otherInteractions = filterOtherInteractions(interactions, mainInteraction);
  }

  const othersStates = otherInteractions.map(({ state, requestedAt, scheduledCallTime, completedDate }) => ({
    description: state,
    period: [completedDate, scheduledCallTime, requestedAt].find((ts) => ts),
  }));

  return {
    ...mainInteraction,
    othersCounts: countStates(otherInteractions),
    othersStates: othersStates,
  };
};

export const flatExpertsWithRequests = (
  experts: Expert[],
  clientRequests: InteractionRequest[]
): ExpertTableInteractionType[] => {
  return groupByInteractionGroupAndExpertAngle(experts)
    .flatMap((group) =>
      group.interactions.map((interaction) => ({
        ...interaction,
        group: group,
        pendingRequest: clientRequests.find((cr) => cr.interactionId === interaction.id),
      }))
    )
    .filter(distinctBy("id"));
};

export const groupByInteractionGroups = <T extends Interaction>(interactions: T[]): InteractionGroup<T>[] =>
  interactions
    .flatMap((interaction) => interaction.group)
    .filter(distinctBy("id"))
    .map((group) => ({
      ...group,
      type: group.parent ? group.parent.name : undefined,
      interactions: interactions.filter((interaction) => interaction.group.id === group.id).filter(distinctBy("id")),
    }))
    .filter(({ interactions }) => interactions.length > 0);

export const isDuringCall = ({
  state,
  scheduledCallTime,
  expectedDuration,
}: {
  state: string;
  scheduledCallTime: string;
  expectedDuration?: number;
}) =>
  state === "scheduled" &&
  scheduledCallTime &&
  expectedDuration &&
  isWithinInterval(new Date(), {
    start: parseISO(scheduledCallTime),
    end: addSeconds(parseISO(scheduledCallTime), expectedDuration),
  });

export const isCallAboutToStart = ({ state, scheduledCallTime }: { state: string; scheduledCallTime: string }) =>
  !!(
    state === "scheduled" &&
    scheduledCallTime &&
    isWithinInterval(new Date(), {
      start: subMinutes(parseISO(scheduledCallTime), 10),
      end: parseISO(scheduledCallTime),
    })
  );

export const isCallAvailable = ({
  state,
  scheduledCallTime,
  expectedDuration,
}: {
  state: string;
  scheduledCallTime: string;
  expectedDuration?: number;
}) => isCallAboutToStart({ state, scheduledCallTime }) || isDuringCall({ state, scheduledCallTime, expectedDuration });

export const mapExperts = (interactions: Interaction[]) => {
  const groupsByAdvisorAngle = interactions.reduce((map: { [key: string]: Expert }, curr, index) => {
    const angle = curr.angles?.at(0);
    const angleId = angle?.id;
    const angleTypeName = angle?.type?.name;
    const advisorId = curr.advisorId;
    const workstreamId = curr.workstreamId;
    const key = `${advisorId}-${angleId}`;
    if (map[key]) {
      map[key] = {
        ...map[key],
        interactions: [...map[key].interactions, curr],
      };
    } else {
      map[key] = {
        interactions: [curr],
        index,
        advisorId,
        angleId,
        workstreamId,
        angleTypeName,
      };
    }
    return map;
  }, {});

  const experts = Object.values(groupsByAdvisorAngle).sort(({ index: indexA }, { index: indexB }) => indexA - indexB);

  return experts;
};
