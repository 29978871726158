import { useState, FC, useRef, useEffect } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import { ChevronLeft, ChevronRight } from "@alphasights/alphadesign-icons";

const NAVIGATION_BUTTON_WIDTH = 53;

interface CarouselProps {
  children: React.ReactNode[];
}

export const Carousel: FC<CarouselProps> = ({ children }) => {
  const { itemWrapper } = useCarouselStyles({});

  const carrouselRef = useRef<HTMLDivElement | null>(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [carrouselWidth, setCarrouselWidth] = useState(1);
  const [scrollWidth, setScrollWidth] = useState(1);

  const numberOfCarouselSlides = Math.ceil(scrollWidth / carrouselWidth);

  const isFirstSlide = activeSlideIndex === 0;
  const isFinalSlide = activeSlideIndex === numberOfCarouselSlides - 1;

  const nextSlide = () => {
    setActiveSlideIndex((prevIndex) => prevIndex + 1);
  };

  const prevSlide = () => {
    setActiveSlideIndex((prevIndex) => prevIndex - 1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setCarrouselWidth(carrouselRef.current?.offsetWidth ?? 1);
    setScrollWidth(carrouselRef.current?.scrollWidth ?? 1);
  });

  return (
    <x.div position="relative" overflow="hidden" data-testid={`carousel-slide-${activeSlideIndex}`}>
      <x.div
        display="flex"
        transition="transform 0.5s ease-in-out"
        transform={`translateX(-${activeSlideIndex * (carrouselWidth - NAVIGATION_BUTTON_WIDTH * 2)}px)`}
        ref={carrouselRef}
      >
        {children.map((child: React.ReactNode, index: number) => (
          <x.div key={index} data-testid="carousel-item" {...itemWrapper}>
            {child}
          </x.div>
        ))}
      </x.div>
      {!isFirstSlide && <CarouselButton variant="previous" onClick={prevSlide} />}
      {!isFinalSlide && <CarouselButton variant="next" onClick={nextSlide} />}
    </x.div>
  );
};

interface CarouselButtonProps {
  variant: "next" | "previous";
  onClick: () => void;
}

const CarouselButton: FC<CarouselButtonProps> = ({ variant, onClick }) => {
  const isNextButton = variant === "next";
  const { wrapper, buttonWrapper } = useCarouselStyles({ isNextButton });

  return (
    <>
      <x.div {...wrapper}>
        <Button
          onClick={onClick}
          data-testid={isNextButton ? "next-button" : "prev-button"}
          variant="ghost"
          {...buttonWrapper}
        >
          {isNextButton ? <ChevronRight /> : <ChevronLeft />}
        </Button>
      </x.div>
    </>
  );
};

const useCarouselStyles = ({ isNextButton = false }: { isNextButton?: boolean }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const wrapper = {
    position: "absolute",
    top: 0,
    w: `${NAVIGATION_BUTTON_WIDTH}px`,
    h: "44px",
    background: `linear-gradient(${isNextButton ? "90deg" : "270deg"}, #ffffff00 0%, #ffffff00 0%, #f8f8f8 50%)`,
    right: isNextButton ? 0 : "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: isNextButton ? "row-reverse" : "row",
  };

  const itemWrapper = {
    boxSizing: "border-box",
    paddingRight: inner.base02,
  };

  const buttonWrapper = {
    h: inner.base08,
    w: inner.base08,
    padding: 0,
    right: isNextButton ? inner.base : undefined,
    left: isNextButton ? undefined : inner.base,
  };

  return {
    wrapper,
    itemWrapper,
    buttonWrapper,
  };
};
