import { useCurrentUser } from "@alphasights/portal-auth-react";
import { ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { messageThreadService } from "services/messageThread";
import { MessageThreadHeaderResponse } from "types";

interface MessageThreadContextState {
  threadHeaders: MessageThreadHeaderResponse[];
  getWorkRequestStatus: ({ expertId }: { expertId: string }) => MessageThreadHeaderResponse[];
  isLoading: boolean;
  fetchThreadHeaders: () => void;
}

export const MessageThreadContext = createContext<MessageThreadContextState | undefined>(undefined);

export const MessageThreadProvider = ({
  project,
  messageService = messageThreadService,
  children,
}: {
  project: any;
  messageService: typeof messageThreadService;
  children: JSX.Element;
}) => {
  const currentUser = useCurrentUser();
  const { hasProjectBadge } = useProjectBadgeContext();
  const portalMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES);

  const [threadHeaders, setThreadHeaders] = useState<MessageThreadHeaderResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchThreadHeaders = useCallback(() => {
    if (currentUser && project && portalMessagesEnabled) {
      messageService
        .fetchThreadHeaders(project.token)
        .then((response: MessageThreadHeaderResponse[]) => {
          setThreadHeaders(response);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentUser, messageService, portalMessagesEnabled, project]);

  useEffect(() => {
    fetchThreadHeaders();
  }, [fetchThreadHeaders]);

  const getWorkRequestStatus = ({ expertId }: { expertId: string }) =>
    threadHeaders.filter((thread) => thread.advisor.id === expertId);

  const context = {
    threadHeaders,
    getWorkRequestStatus,
    fetchThreadHeaders,
    isLoading,
  };

  return <MessageThreadContext.Provider children={children} value={context} />;
};

export const useMessageThreadContext = () => {
  const context = useContext(MessageThreadContext);

  if (!context) throw new Error("MessageThreadContext should only be used within the MessageThreadProvider");

  return context;
};
