import { ReactNode } from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

const OverviewDetailsRow = ({
  label,
  labelWidth,
  spaceBetween,
  value,
  children,
}: {
  label: string;
  labelWidth?: string;
  spaceBetween?: string;
  value?: string;
  children?: ReactNode | string;
}) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();

  return (
    <x.div display="flex" pb={inner.base02} lineHeight={inner.base06}>
      <Typography
        component="div"
        color={color.text.secondary}
        flex={`0 0 ${labelWidth ?? "205px"}`}
        mr={spaceBetween ?? inner.base04}
        variant={"body"}
      >
        {label}
      </Typography>
      {children ?? (
        <Typography variant="body" color={color.text.strong._}>
          {value}
        </Typography>
      )}
    </x.div>
  );
};

export default OverviewDetailsRow;
