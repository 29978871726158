import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { useAdvisorFlyOutStyles } from "./AdvisorFlyOut.styles";
import { Accordion } from "@alphasights/alphadesign-components";
import { Topbar } from "./sections/Topbar/Topbar";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { useLocation, useNavigate } from "router-utils";
import {
  changeInteractionChainDate,
  loadAdvisorInteractions,
  loadAdvisorResearchRequest,
  loadUpgrades,
  requestChangeInteraction,
  setFlyoutMode,
} from "components/InteractionsPage/reducer";
import {
  enrichInteraction as enrichInteractionHelper,
  groupedRepresentationFor,
} from "components/InteractionsPage/helpers/Interaction";
import { InteractionDetails } from "./sections/InteractionDetails/InteractionDetails";
import { InteractionUpgrades } from "./sections/InteractionUpgrades/InteractionUpgrades";
import { InteractionSettings } from "./sections/InteractionSettings/InteractionSettings";
import { CommentThread } from "components/CommentThread";
import useWindowDimensions from "hooks/useWindowDimensions";
import { AdvisorFlyoutCalendar } from "components/AdvisorFlyOut/AdvisorFlyoutCalendar";
import { RelevantExperience } from "./sections/RelevantExperience/RelevantExperience";
import { InteractionHeader } from "./sections/InteractionHeader/InteractionHeader";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import { AdvisorAvailability } from "./sections/Availability/AdvisorAvailability";
import { useNotifications } from "@alphasights/client-portal-shared";
import { parseISO } from "providers/TimezoneProvider";
import { EmploymentHistoryContainer } from "./sections/EmploymentHistory/EmploymentHistory";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { FlyoutProvider, useFlyoutContext } from "../../providers/FlyoutProvider";
import { useAdvisorFlyoutReducer } from "./AdvisorFlyOutReducer";
import { useLockedExpert } from "hooks/useLockedExpert";
import { ErrorBanner } from "components/Banner";
import { getFirstName } from "./utils";
import { isPotentialSurveyResponse } from "components/SurveysPage/helpers";
import { FlyoutConfiguration, FlyoutMode } from "./enums";
import { MessagesFlyout } from "./MessagesFlyout";
import { isCallAvailable } from "components/InteractionsPage/helpers/Interaction";
import { useCurrentUser, useIsAuthenticated } from "@alphasights/portal-auth-react";
import { CUSTOMER_KNOWLEDGE, useProjectBadgeContext } from "providers/BadgeProvider";
import { CustomerKnowledgeShowcase } from "pages/CustomerExpertPage/CustomerKnowledgeShowcase/CustomerKnowledgeShowcase";
import { HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";
import { MobileExpertsFlyoutNav } from "views/MobileInteractionsPage/MobileExpertsFlyout";
import { MessengerProvider } from "providers/MessengerProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";

const STANDARD_CONTENT_WIDTH = 499;
const EXTENDED_CONTENT_WIDTH = 358;
const MIN_CONTENT_WIDTH = 300;

export const AdvisorSidebar = ({ isOpen, onClose, flyoutMode, showMobileView, showChainList = false, ...props }) => {
  const width = useFlyoutWidth(flyoutMode);
  const { sidebar } = useAdvisorFlyOutStyles();

  return (
    <>
      {isOpen && props.interaction && (
        <x.div w={`${width}px`} {...sidebar}>
          <AdvisorFlyout
            flyoutMode={flyoutMode}
            onClose={onClose}
            showChainList={showChainList}
            showMobileView={showMobileView}
            {...props}
          />
        </x.div>
      )}
    </>
  );
};

export const NewMessageSidebar = ({ onClose, ...props }) => {
  const width = useFlyoutWidth(FlyoutMode.NewMessage);
  const { sidebar } = useAdvisorFlyOutStyles();
  return (
    <x.div w={`${width}px`} {...sidebar}>
      <NewMessageFlyout onClose={onClose} showChainList={false} {...props} />
    </x.div>
  );
};

const AdvisorFlyout = ({
  flyoutMode: flyoutModeInput,
  chainInteractions,
  interaction: originalInteraction,
  newMessageType,
  newMessageOrigin,
  onOpenComments,
  onSendNewMessage,
  onSelectCard,
  onSchedule,
  onRequest,
  showChainList,
  showAvailabilities,
  upgrades,
  loadingUpgrades,
  deliverablesViewEnabled,
  requiresComplianceApproval,
  isPTLEnabledClient = false,
  isPCCEnabledClient = false,
  selectedExpertsIds,
  showMobileView,
  isScheduleModalOpen,
  onCloseScheduling,
  ...props
}) => {
  const {
    token: projectToken,
    pcc: clientPccFlag,
    isActiveProject,
    researchRequest,
    projectLead,
    transcriptEnabled,
    onCancelRequestTranscript,
    onRequestTranscript,
    onRequestTranscriptPostCall,
    onChainIdSelectedUpdated,
    flyoutSectionFocus,
    hasClientPortalMessages,
    allInteractions,
    onClose,
  } = props;
  const interactionUpgradesRef = useRef(null);
  const availabilitiesRef = useRef(null);
  const relevantExperienceRef = useRef(null);
  const employmentHistoryRef = useRef(null);
  const userCidEnabled = useUserCidEnabled();
  const dispatch = useContext(DispatchContext);
  const [flyoutState, dispatchFlyout] = useAdvisorFlyoutReducer();
  const { isMobile } = useCheckScreen();
  const location = useLocation();
  const navigate = useNavigate();
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isInteractionActionsDisabled = isFeatureDisabled(ProjectFeature.InteractionActions);

  const { chainIdSelected, onUpdateChainIdSelected } = useChainedInteractions({
    chainInteractions,
    interaction: originalInteraction,
    projectToken,
    onChainIdSelectedUpdated,
    backgroundSyncRunning: props.backgroundSyncRunning,
  });

  const pendingRequest = useMemo(
    () =>
      props.clientRequests?.find(
        (request) => request.interactionId === chainIdSelected && request.type !== "AVAILABILITY_REQUEST"
      ),
    [props, chainIdSelected]
  );

  const otherInteractionsCounts = useMemo(() => {
    const { othersCounts } = groupedRepresentationFor(chainInteractions ?? [], chainIdSelected);
    return othersCounts;
  }, [chainIdSelected, chainInteractions]);

  const interaction = useMemo(
    () =>
      enrichInteraction({
        interaction: chainInteractions.find((i) => i.id === chainIdSelected) ?? originalInteraction,
        clientPccFlag,
        isActiveProject,
        pendingRequest,
        isMobile,
        hasClientPortalMessages,
        othersCounts: otherInteractionsCounts,
        isInteractionActionsDisabled,
      }),
    [
      chainIdSelected,
      chainInteractions,
      clientPccFlag,
      hasClientPortalMessages,
      isActiveProject,
      isInteractionActionsDisabled,
      isMobile,
      originalInteraction,
      otherInteractionsCounts,
      pendingRequest,
    ]
  );

  const [request, updateResearchRequest, isLoadingResearchRequest] = useResearchRequest({
    researchRequest,
    interaction,
    isActiveProject,
    projectToken,
  });
  const { locked } = useLockedExpert(interaction);
  const [availabilities, setAvailabilites] = useState();
  const [removedAvailabilities, setRemovedAvailabilities] = useState([]);
  const [reschedulingRequested, setReschedulingRequested] = useState(false);
  const [maximumTaskDuration, setMaximumTaskDuration] = useState();
  const [selectedExperts, setSelectedExperts] = useState([]);
  const [selectedTimespans, setSelectedTimespans] = useState(props.selectedTimespans || []);
  const sendMessageButtonRef = useRef();
  const { showErrorBanner } = useNotifications();

  const isStatusAvailable = interaction.state === "proposed";
  const showInteractionSettingsSection = (isPCCEnabledClient || isPTLEnabledClient) && !isStatusAvailable;

  const { hasProjectBadge } = useProjectBadgeContext();
  const { getCustomerKnowledgeByInteraction } = useCurrentProjectContext();
  const currentUser = useCurrentUser();
  const customerViewEnabled = currentUser?.portalCustomerKnowledgeEnabled || hasProjectBadge(CUSTOMER_KNOWLEDGE);

  const interactionDetailsPosition = useMemo(
    () => (interaction.state === "scheduled" ? "beforeAdvisorAvailability" : "afterInteractionSettings"),
    [interaction]
  );

  const customerKnowledgePosition = useMemo(() => {
    if (
      ["proposed", "requested"].includes(interaction.state) ||
      (interaction.state === "scheduled" && !isCallAvailable(interaction))
    ) {
      return "afterAdvisorAvailability";
    } else {
      return "afterInteractionDetails";
    }
  }, [interaction]);

  const onSelectTimespan = (span) => {
    const isSelected = selectedTimespans.includes(span);

    if (isSelected) {
      setSelectedTimespans([]);
    } else {
      setSelectedTimespans([span]);
    }
  };

  const pendingAvailabilityRequest = useMemo(() => {
    return props.clientRequests?.find(
      (request) => request.advisorId === interaction.advisorId && request.type === "AVAILABILITY_REQUEST"
    );
  }, [props, interaction.advisorId]);

  const convertAvailabilityToSave = (availability) => {
    return {
      start: availability.start,
      end: availability.end,
      angleIds: availability.subtype === "angle" ? availability.angleIds : [],
      advisorshipIds: availability.subtype === "expert" ? availability.advisorshipIds : [],
    };
  };
  const onSaveAvailability = () => {
    const addedAvailabilitiesConverted = (availabilities ?? [])
      .filter((a) => a.edited)
      .map((availability) => convertAvailabilityToSave(availability));
    const removedAvailabilitiesConverted = (removedAvailabilities ?? []).map((availability) =>
      convertAvailabilityToSave(availability)
    );
    const args = {
      projectToken: props.token,
      before: removedAvailabilitiesConverted,
      after: addedAvailabilitiesConverted,
      origin: HitOrigin.flyout,
      interactionIds: [interaction.id],
    };

    return props
      .onSaveClientAvailability(args)
      .then(() => {
        dispatch(setFlyoutMode(FlyoutMode.Interaction));
        setRemovedAvailabilities([]);
        setAvailabilites([]);
        return [true, addedAvailabilitiesConverted.length, removedAvailabilitiesConverted.length];
      })
      .catch(() => {
        showErrorBanner("Error. Availability could not be saved.");
        return [false, 0, 0];
      });
  };

  const onRequestRescheduleInteractionWithoutDate = () => {
    dispatch(
      requestChangeInteraction({
        id: interaction.id,
        type: "RESCHEDULE_REQUEST",
      })
    ).then(() => setReschedulingRequested(true));
  };

  const scrollToSection = (sectionRef) => {
    sectionRef.current &&
      setTimeout(() => {
        sectionRef.current.scrollIntoView();
      }, 0);
  };

  const onReschedulingDone = () => {
    setReschedulingRequested(true);
  };

  const flyoutMode = flyoutModeInput ?? FlyoutMode.Interaction;
  const flyoutConfiguration = useFlyoutConfiguration(flyoutMode);
  const isFullScreenMode = flyoutConfiguration === FlyoutConfiguration.Fullscreen;

  const changeFlyoutMode = (mode) => {
    const pathname = location.pathname;
    if (mode !== FlyoutMode.Comments && pathname.endsWith("/comments")) {
      navigate({ ...location, ...{ pathname: pathname.replace("/comments", "") } });
    }

    dispatch(setFlyoutMode(mode));
  };

  const isInteractionUpgradesVisible = useCallback(() => {
    return !interaction.isSurvey && isActiveProject && interaction.state !== "proposed";
  }, [isActiveProject, interaction]);

  const [listOpened, setListOpenend] = React.useState(showChainList);

  useEffect(
    function flyoutInitialScroll() {
      const readyToScroll = !loadingUpgrades;
      if (readyToScroll) {
        if (flyoutSectionFocus === "bio") {
          scrollToSection(relevantExperienceRef?.current ? relevantExperienceRef : employmentHistoryRef);
        } else if (flyoutSectionFocus === "upgrades" && interactionUpgradesRef?.current) {
          scrollToSection(interactionUpgradesRef);
        } else if (showAvailabilities && availabilitiesRef.current) {
          scrollToSection(availabilitiesRef);
        }
      }
    },
    [
      flyoutSectionFocus,
      relevantExperienceRef,
      employmentHistoryRef,
      availabilitiesRef,
      interactionUpgradesRef,
      showAvailabilities,
      loadingUpgrades,
    ]
  );

  const showCallSection =
    interaction.interactionType !== "Industry Survey" &&
    !interaction.surveyResponse &&
    !(props.currentView === "survey-view" && isPotentialSurveyResponse(interaction));

  const flyoutBodyRef = useRef();
  const [plusXInteractionsElem, setPlusXInteractionsElem] = useState(null);

  const [mobileCtasRef, setMobileCtasRef] = useState();
  const mobileCtasHeight = mobileCtasRef?.getBoundingClientRect().height;
  const showExpertAvailability = !isFeatureDisabled(ProjectFeature.ExpertAvailability);

  return (
    <FlyoutProvider
      dispatch={dispatchFlyout}
      state={flyoutState}
      projectToken={projectToken}
      selectedInteraction={interaction}
      upgrades={upgrades}
      flyoutAction={props.flyoutAction}
      flyoutMode={flyoutMode}
      flyoutConfiguration={flyoutConfiguration}
      hasClientPortalMessages={hasClientPortalMessages}
      newMessageType={newMessageType}
      newMessageOrigin={newMessageOrigin}
      allInteractions={allInteractions}
      changeFlyoutMode={changeFlyoutMode}
      selectedExpertsIds={selectedExpertsIds}
    >
      <Container>
        <Body
          showMobileView={showMobileView}
          ref={flyoutBodyRef}
          mb={showMobileView && flyoutMode !== FlyoutMode.Comments ? `${mobileCtasHeight}px` : undefined}
        >
          {![FlyoutMode.Messages, FlyoutMode.NewMessage].includes(flyoutMode) && (
            <StandardContent showMobileView={showMobileView}>
              <InteractionHeader
                interaction={interaction}
                isProjectCidEnabled={props.isProjectCidEnabled}
                pendingRequest={pendingRequest}
                showCalendarView={props.showCalendarView}
                userCidEnabled={userCidEnabled}
                researchRequest={request}
                locked={locked}
                onRequest={onRequest}
                currentView={props.currentView}
                workstreamId={props.workstreamId}
                setPlusXInteractionsElem={setPlusXInteractionsElem}
              />
              {locked ? (
                <LockedExpertBanner projectLead={projectLead} />
              ) : (
                <Accordion flexGrow="1">
                  {showCallSection && (
                    <>
                      {interactionDetailsPosition === "beforeAdvisorAvailability" && (
                        <InteractionDetails
                          projectToken={props.token}
                          interaction={interaction}
                          onSendNewMessage={onSendNewMessage}
                          showMobileView={showMobileView}
                        />
                      )}
                      {showExpertAvailability && (
                        <AdvisorAvailability
                          {...props}
                          isVisible={!showMobileView && isAvailabilityVisible(isActiveProject, interaction)}
                          interaction={interaction}
                          originalInteractionId={originalInteraction.id}
                          isSelectable={props.isInstantScheduleEnabled}
                          selectedTimespans={selectedTimespans}
                          onSelectTimespan={onSelectTimespan}
                          onSchedule={onSchedule}
                          onSaveAvailability={onSaveAvailability}
                          flyoutMode={flyoutMode}
                          pendingRequest={pendingRequest}
                          pendingAvailabilityRequest={pendingAvailabilityRequest}
                          reschedulingRequested={reschedulingRequested}
                          setReschedulingRequested={setReschedulingRequested}
                          onRequestRescheduleInteractionWithoutDate={onRequestRescheduleInteractionWithoutDate}
                          ref={availabilitiesRef}
                        />
                      )}

                      {customerViewEnabled && customerKnowledgePosition === "afterAdvisorAvailability" && (
                        <CustomerKnowledgeShowcase knowledges={getCustomerKnowledgeByInteraction(interaction.id)} />
                      )}

                      {!(showMobileView && isScheduleModalOpen) && (
                        <InteractionUpgrades
                          isVisible={isInteractionUpgradesVisible()}
                          ref={interactionUpgradesRef}
                          projectToken={projectToken}
                          projectLead={projectLead}
                          interaction={interaction}
                          aiTranscriptEnabled={props.aiTranscriptEnabled}
                          recordedEnabled={props.recordedEnabled}
                          videoCallAvailable={props.videoCallAvailable}
                          blindedUpgradeAvailable={props.blindedUpgradeAvailable}
                          humanTranscriptEnabled={props.transcriptEnabled}
                          transcriptEnabled={transcriptEnabled}
                          onCancelRequestTranscript={onCancelRequestTranscript}
                          onRequestTranscript={onRequestTranscript}
                          onRequestTranscriptPostCall={onRequestTranscriptPostCall}
                          upgrades={upgrades}
                          loadingUpgrades={loadingUpgrades}
                          deliverablesViewEnabled={deliverablesViewEnabled}
                          requiresComplianceApproval={requiresComplianceApproval}
                          expanded={flyoutSectionFocus === "upgrades"}
                          showMobileView={showMobileView}
                        />
                      )}

                      {showInteractionSettingsSection && (
                        <InteractionSettings
                          projectToken={props.token}
                          interaction={originalInteraction}
                          upgrades={upgrades}
                          isPTLEnabledClient={isPTLEnabledClient}
                          isPCCEnabledClient={isPCCEnabledClient}
                        />
                      )}

                      {interactionDetailsPosition === "afterInteractionSettings" && (
                        <InteractionDetails
                          projectToken={props.token}
                          interaction={interaction}
                          onSendNewMessage={onSendNewMessage}
                        />
                      )}

                      {customerViewEnabled && customerKnowledgePosition === "afterInteractionDetails" && (
                        <CustomerKnowledgeShowcase knowledges={getCustomerKnowledgeByInteraction(interaction.id)} />
                      )}
                    </>
                  )}

                  <RelevantExperience
                    isVisible={isActiveProject}
                    relevanceStatement={interaction.relevanceStatement}
                    relevantStatementType={props.relevantStatementType}
                    expanded={flyoutSectionFocus === "bio"}
                    ref={relevantExperienceRef}
                    interaction={interaction}
                    showCompareExpertsButton
                  />

                  <EmploymentHistoryContainer
                    isVisible={isActiveProject}
                    interaction={interaction}
                    isProjectCidEnabled={props.isProjectCidEnabled}
                    projectToken={projectToken}
                    userCidEnabled={userCidEnabled}
                    expanded={flyoutSectionFocus === "bio"}
                    ref={employmentHistoryRef}
                  />
                </Accordion>
              )}
            </StandardContent>
          )}
          {[FlyoutMode.Messages, FlyoutMode.NewMessage].includes(flyoutMode) && (
            <StandardContent>
              <x.div data-testid="messages-flyout">
                <MessagesFlyout
                  isProjectCidEnabled={props.isProjectCidEnabled}
                  sendMessageButtonRef={sendMessageButtonRef}
                  maximumTaskDuration={maximumTaskDuration}
                  setMaximumTaskDuration={setMaximumTaskDuration}
                  setSelectedExperts={setSelectedExperts}
                />
              </x.div>
            </StandardContent>
          )}
          {isActiveProject && (
            <ExtendedContent flyoutConfiguration={flyoutConfiguration} showMobileView={showMobileView}>
              <CommentThread
                expertId={interaction.advisorId}
                angleId={interaction.angleId}
                projectId={projectToken}
                onClose={() => changeFlyoutMode(FlyoutMode.Interaction)}
                newFlyout={true}
                showMobileView={showMobileView}
              />
            </ExtendedContent>
          )}
          {isFullScreenMode && isActiveProject && (
            <FullScreenContent flyoutConfiguration={flyoutConfiguration}>
              <AdvisorFlyoutCalendar
                onSelectCard={(interaction) => {
                  const chained = chainInteractions.find(({ id }) => id === interaction.id);
                  if (chained) {
                    onUpdateChainIdSelected(chained.id);
                  } else {
                    onSelectCard(interaction);
                  }
                }}
                pendingRequest={pendingRequest}
                flyoutMode={flyoutMode}
                availabilities={availabilities}
                setAvailabilites={setAvailabilites}
                onSaveAvailability={onSaveAvailability}
                onReschedulingDone={onReschedulingDone}
                removedAvailabilities={removedAvailabilities}
                setRemovedAvailabilities={setRemovedAvailabilities}
                onRequestRescheduleInteractionWithoutDate={onRequestRescheduleInteractionWithoutDate}
                onRequest={onRequest}
                {...props}
                {...interaction}
              />
            </FullScreenContent>
          )}
        </Body>

        <Header listOpened={listOpened} showMobileView={showMobileView}>
          {showMobileView && (
            <MessengerProvider
              projectToken={projectToken}
              interactions={allInteractions}
              messagesEnabled={hasClientPortalMessages}
            >
              <MobileExpertsFlyoutNav
                ref={setMobileCtasRef}
                interaction={interaction}
                pendingRequest={pendingRequest}
                clientRequests={props.clientRequests}
                onToggleComments={onOpenComments}
                onClose={onClose}
                chainInteractions={chainInteractions}
                onSelectInteraction={onSelectCard}
                onRequest={onRequest}
                onRequestFollowUp={props.onRequestFollowUp}
                onSchedule={onSchedule}
                onCloseScheduling={onCloseScheduling}
                hasClientPortalMessages={hasClientPortalMessages}
                isActiveProject={isActiveProject}
                flyoutMode={flyoutMode}
                changeFlyoutMode={changeFlyoutMode}
                isScheduleView={isScheduleModalOpen}
                requestedScheduleDate={props.requestedScheduleDate}
                allInteractions={allInteractions}
                clientAvailability={props.clientAvailability}
                advisorAvailability={interaction.advisorAvailability}
                onSubmitSchedule={props.onSubmitSchedule}
                flyoutBodyRef={flyoutBodyRef}
                plusXInteractionsElem={plusXInteractionsElem}
                projectToken={projectToken}
                backgroundSyncRunning={props.backgroundSyncRunning}
              />
            </MessengerProvider>
          )}
          {!showMobileView && (
            <Topbar
              {...props}
              researchRequest={request}
              isLoadingResearchRequest={isLoadingResearchRequest}
              updateResearchRequest={updateResearchRequest}
              interaction={interaction}
              chainIdSelected={chainIdSelected}
              chainInteractions={chainInteractions}
              onUpdateChainIdSelected={onUpdateChainIdSelected}
              onRequestUpgradeInteraction={() => scrollToSection(interactionUpgradesRef)}
              onRequest={onRequest}
              onSchedule={onSchedule}
              onToggleComments={onOpenComments}
              clientRequests={props.clientRequests}
              showCalendarView={props.showCalendarView}
              listOpened={listOpened}
              setListOpened={setListOpenend}
              locked={locked}
              onClose={onClose}
              onSendNewMessage={onSendNewMessage}
              sendMessageButtonRef={sendMessageButtonRef}
              maximumTaskDuration={maximumTaskDuration}
              selectedExperts={selectedExperts}
            />
          )}
          {/*
              Below is rendered by CalendarFull/CustomToolbar. Since it is part of the calendar library
              but the designers moved it out of the UI calendar component, this is what you get.
           */}
          <x.div id="calendar-controls-placeholder" />
        </Header>
      </Container>
    </FlyoutProvider>
  );
};

const NewMessageFlyout = ({ newMessageType, newMessageOrigin, onSendNewMessage, selectedExpertsIds, ...props }) => {
  const { token: projectToken, hasClientPortalMessages, allInteractions } = props;
  const [flyoutState, dispatchFlyout] = useAdvisorFlyoutReducer();

  const [maximumTaskDuration, setMaximumTaskDuration] = useState();
  const [selectedExperts, setSelectedExperts] = useState([]);

  const sendMessageButtonRef = useRef();

  const flyoutConfiguration = useFlyoutConfiguration(FlyoutMode.NewMessage);

  return (
    <FlyoutProvider
      dispatch={dispatchFlyout}
      state={flyoutState}
      projectToken={projectToken}
      flyoutAction={props.flyoutAction}
      flyoutMode={FlyoutMode.NewMessage}
      flyoutConfiguration={flyoutConfiguration}
      hasClientPortalMessages={hasClientPortalMessages}
      newMessageType={newMessageType}
      newMessageOrigin={newMessageOrigin}
      allInteractions={allInteractions}
      selectedExpertsIds={selectedExpertsIds}
    >
      <Container>
        <Body>
          <StandardContent>
            <x.div data-testid="messages-flyout">
              <MessagesFlyout
                isProjectCidEnabled={props.isProjectCidEnabled}
                sendMessageButtonRef={sendMessageButtonRef}
                maximumTaskDuration={maximumTaskDuration}
                setMaximumTaskDuration={setMaximumTaskDuration}
                setSelectedExperts={setSelectedExperts}
              />
            </x.div>
          </StandardContent>
        </Body>
        <Header>
          <Topbar
            {...props}
            chainInteractions={[]}
            onSendNewMessage={onSendNewMessage}
            sendMessageButtonRef={sendMessageButtonRef}
            maximumTaskDuration={maximumTaskDuration}
            selectedExperts={selectedExperts}
          />
        </Header>
      </Container>
    </FlyoutProvider>
  );
};

export const LockedExpertBanner = ({ projectLead }) => (
  <ErrorBanner data-testid="locked-expert-banner">
    Expert is no longer available. Reach out to your project lead {getFirstName(projectLead.name)} for more information.
  </ErrorBanner>
);

const useChainedInteractions = ({
  chainInteractions,
  interaction,
  projectToken,
  backgroundSyncRunning,
  onChainIdSelectedUpdated,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useIsAuthenticated();
  const [chainIdSelected, setChainIdSelected] = useState();
  const dispatch = useContext(DispatchContext);
  const {
    state: { runningAction },
  } = useProjectInteractionsContext();

  const { id: interactionId, advisorId, group } = interaction;

  useEffect(() => {
    if (dispatch && !backgroundSyncRunning) {
      onUpdateChainIdSelected(interactionId);
      dispatch(
        loadAdvisorInteractions({
          interactionId,
          advisorId,
          projectToken,
          groupId: group?.id,
        })
      );
    }
  }, [interaction.id, backgroundSyncRunning]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chainInteractions.length === 0 || runningAction === "loadingAdvisorInteractions" || !location) return;

    const selected = new URLSearchParams(location.search).get("selected");

    if (chainInteractions.find(({ id }) => id === selected)) {
      onUpdateChainIdSelected(selected);
    } else {
      onUpdateChainIdSelected(interaction.id);
    }
  }, [JSON.stringify(chainInteractions)]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClearSelectedIdChainURL = useCallback(() => {
    if (!location || !navigate) {
      return;
    }
    const search = new URLSearchParams(location.search);
    search.delete("selected");
    navigate(
      {
        search: "?" + search.toString(),
      },
      { replace: true }
    );
  }, [location, navigate]);

  useEffect(() => {
    onClearSelectedIdChainURL();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setInteractionChainDate = useCallback((currentDate) => dispatch(changeInteractionChainDate(currentDate)), [
    dispatch,
  ]);

  const onUpdateChainIdSelected = useCallback(
    (id) => {
      if (id !== chainIdSelected) {
        setChainIdSelected(id);
        onChainIdSelectedUpdated && onChainIdSelectedUpdated(id);
        isLoggedIn && dispatch(loadUpgrades({ projectToken, interactionId: id }));
      }

      if (id === interaction.id) {
        interaction.scheduledCallTime && setInteractionChainDate(parseISO(interaction.scheduledCallTime));
        onClearSelectedIdChainURL();
      } else {
        const period = chainInteractions.find((chain) => chain.id === id)?.scheduledCallTime;
        chainInteractions.length > 1 && period && setInteractionChainDate(parseISO(period));

        const search = new URLSearchParams(location.search);
        search.set("selected", id);
        if (navigate) {
          navigate(
            {
              search: "?" + search.toString(),
            },
            { replace: true }
          );
        }
      }
    },
    [
      isLoggedIn,
      chainIdSelected,
      chainInteractions,
      dispatch,
      location,
      interaction.id,
      interaction.scheduledCallTime,
      onChainIdSelectedUpdated,
      onClearSelectedIdChainURL,
      projectToken,
      setInteractionChainDate,
      navigate,
    ]
  );

  return { chainIdSelected, onUpdateChainIdSelected };
};

const Body = React.forwardRef(({ children, showMobileView, ...props }, ref) => {
  const { flyoutMode } = useFlyoutContext();
  const { body } = useAdvisorFlyOutStyles({ showMobileView, flyoutMode });

  return (
    <x.div ref={ref} {...body} {...props}>
      {children}
    </x.div>
  );
});

const Container = ({ children }) => {
  const { container } = useAdvisorFlyOutStyles();

  return (
    <x.div {...container} data-testid="advisor-flyout">
      {children}
    </x.div>
  );
};

const Header = ({ listOpened, children, showMobileView }) => {
  const { header } = useAdvisorFlyOutStyles({ listOpened, showMobileView });
  return <x.div {...header}>{children}</x.div>;
};

const StandardContent = React.forwardRef(({ children, showMobileView, ...props }, ref) => {
  const { flyoutConfiguration } = useFlyoutContext();
  const { standardContent } = useAdvisorFlyOutStyles({ flyoutConfiguration, showMobileView });

  return (
    <x.div ref={ref} {...standardContent} w={`${STANDARD_CONTENT_WIDTH}px`} {...props}>
      {children}
    </x.div>
  );
});

const ExtendedContent = ({ flyoutConfiguration, showMobileView, children }) => {
  const { extendedContent, extendedContentMobile } = useAdvisorFlyOutStyles({
    flyoutConfiguration,
    showMobileView,
  });
  const { isMobile } = useCheckScreen();

  const width = flyoutConfiguration === FlyoutConfiguration.Extended ? EXTENDED_CONTENT_WIDTH : 0;

  return (
    <x.div
      {...extendedContent}
      {...(isMobile && flyoutConfiguration === FlyoutConfiguration.Extended ? extendedContentMobile : {})}
      w={!isMobile && `${width}px`}
    >
      {width > 0 && children}
    </x.div>
  );
};

const FullScreenContent = ({ flyoutConfiguration, children }) => {
  const { fullScreenContent } = useAdvisorFlyOutStyles({ flyoutConfiguration });
  return <x.div {...fullScreenContent}>{children}</x.div>;
};

export const useFlyoutWidth = (flyoutMode) => {
  const configuration = useFlyoutConfiguration(flyoutMode);
  const width =
    configuration === FlyoutConfiguration.Standard
      ? STANDARD_CONTENT_WIDTH
      : STANDARD_CONTENT_WIDTH + EXTENDED_CONTENT_WIDTH;

  const [windowWidth] = useWindowDimensions();

  if (configuration === FlyoutConfiguration.Fullscreen || windowWidth <= width + MIN_CONTENT_WIDTH) {
    return windowWidth;
  }

  return width;
};

export const useFlyoutConfiguration = (flyoutMode) => {
  switch (flyoutMode) {
    case FlyoutMode.Schedule:
    case FlyoutMode.RequestReschedule:
    case FlyoutMode.RequestAvailability:
    case FlyoutMode.ProvideAvailability:
    case FlyoutMode.Calendar:
      return FlyoutConfiguration.Fullscreen;

    case FlyoutMode.Comments:
      return FlyoutConfiguration.Extended;

    default:
      return FlyoutConfiguration.Standard;
  }
};

const useResearchRequest = ({ researchRequest, interaction, isActiveProject, projectToken }) => {
  const dispatch = useContext(DispatchContext);
  const [request, updateResearchRequest] = useState(researchRequest);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    updateResearchRequest(researchRequest);
  }, [interaction.id, researchRequest]);

  useEffect(() => {
    setIsLoading(true);
    if (!isActiveProject && interaction.id && dispatch) {
      dispatch(
        loadAdvisorResearchRequest({
          projectToken,
          interactionId: interaction.id,
        })
      ).then(() => setIsLoading(false));
    }
  }, [interaction.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return [request, updateResearchRequest, isLoading];
};

export const enrichInteraction = ({
  interaction,
  clientPccFlag,
  isActiveProject = true,
  pendingRequest,
  isMobile,
  hasClientPortalMessages,
  isSendMessagesDisabled,
  isInteractionActionsDisabled,
  ...props
}) => {
  const richInteraction = enrichInteractionHelper(interaction, isInteractionActionsDisabled);

  const cancelInteractionRequest = pendingRequest?.type === "CANCEL_REQUEST" ? pendingRequest : null;
  return {
    ...richInteraction,
    angleId: interaction.angles?.at(0)?.id,
    showFollowUpMessageButton:
      richInteraction.state === "completed" &&
      richInteraction.isActiveProject &&
      !richInteraction.isSurvey &&
      (richInteraction.showEmailButton || (richInteraction.canSendFollowUpMessage && hasClientPortalMessages)) &&
      !isSendMessagesDisabled,
    showLeaveAvailability:
      richInteraction.showLeaveAvailability &&
      !richInteraction.hasAdvisorAvailability &&
      !isMobile &&
      !isInteractionActionsDisabled,
    showCancelRequestMenu:
      richInteraction.canCancel && richInteraction.state === "requested" && !isInteractionActionsDisabled,
    showPccMenu: richInteraction.state !== "proposed" && clientPccFlag && !cancelInteractionRequest,
    showRequestToReEngage: !isActiveProject,
    showUpgradeInteraction:
      isActiveProject &&
      richInteraction.state === "scheduled" &&
      !cancelInteractionRequest &&
      !richInteraction.previousAdvisorshipId,
    showMessagesLink: !richInteraction.isSurvey && richInteraction.isActiveProject && !richInteraction.noWrittenWork,
    showMessage:
      (richInteraction.state === "requested" && !richInteraction.showSchedule) || richInteraction.state === "scheduled",
    ...props,
  };
};

export const isAvailabilityVisible = (isActiveProject, interaction) =>
  isActiveProject &&
  (["requested", "scheduled"].includes(interaction.state) ||
    (["proposed", "completed"].includes(interaction.state) && interaction.advisorAvailability?.length > 0)) &&
  !interaction.isSurvey;
