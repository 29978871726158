import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const styles = {
    mentionsWrapper: {
      gap: inner.base06,
      w: "100%",
    },
    mentionsRender: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      px: inner.base06,
    },
    mentionsObject: {
      py: inner.base03,
    },
    mentionsTextWrapper: {
      display: "flex",
      gap: inner.base03,
      padding: inner.base,
    },
    mentionsText: {
      display: "block",
      w: "600px",
    },
  };

  return styles;
};
