import styled from "@xstyled/styled-components";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const CardHeader = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: ${spacing.inner.base02};
  `;
});

const TextWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base};
  `;
});

const SubtitleWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base};
    align-items: center;
    justify-content: space-between;
  `;
});

const TitleTypography = styled(Typography).attrs({
  variant: "body-em",
  component: "span",
  color: tokens.color.text.strong._,
})(() => ({}));

export { CardHeader, TextWrapper, SubtitleWrapper, TitleTypography };
