import { FC } from "react";
import { Divider } from "@alphasights/alphadesign-components";
import { ArrowLeft, ArrowRight } from "@alphasights/alphadesign-icons";

import {
  HeadcountFilter,
  RevenueFilter,
  OwnershipFilter,
  HqLocationFilter,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters";
import {
  BACK,
  ENTRY_TITLE,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/CompanyDataFilters/constants";
import { CompanyData } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/CompanyDataFilters/types";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { HqLocationFilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HqLocationFilter/HqLocationFilter";
import { HeadcountFilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/HeadcountFilter/HeadcountFilter";
import { RevenueFilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/RevenueFilter/RevenueFilter";
import { OwnershipFilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/OwnershipFilter/OwnershipFilter";

import {
  CompanyDataFiltersNavigationRowWrapper,
  StyledNavIcon,
  FilterTitle,
  FilterViewWrapper,
} from "./CompanyDataFilters.styled";

const DataTestIds = {
  filters: "company-data-filters",
};

type FiltersConfig = {
  key: keyof CompanyData;
  Filter: FC<any>;
}[];

//TODO RD1-309: Enable revenue filter
const displayRevenueFilter = false;

const filters: FiltersConfig = [
  {
    key: SearchFilters.hqLocation,
    Filter: HqLocationFilter as FC<HqLocationFilterProps>,
  },
  {
    key: SearchFilters.headcount,
    Filter: HeadcountFilter as FC<HeadcountFilterProps>,
  },
  ...((displayRevenueFilter
    ? [
        {
          key: SearchFilters.revenue,
          Filter: RevenueFilter as FC<RevenueFilterProps>,
        },
      ]
    : []) as FiltersConfig),
  {
    key: SearchFilters.ownershipType,
    Filter: OwnershipFilter as FC<OwnershipFilterProps>,
  },
];

type CompanyDataFiltersProps = {
  value: CompanyData;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onFilterChange: (value: Record<string, any>) => void;
};

const CompanyDataFilters: FC<CompanyDataFiltersProps> = ({ value, isOpen = false, setIsOpen, onFilterChange }) => {
  const navigate = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <CompanyDataFiltersNavigationRowWrapper isCompanyDataFiltersView={isOpen} onClick={navigate}>
        <FilterTitle>{isOpen ? BACK : ENTRY_TITLE}</FilterTitle>
        <StyledNavIcon key={isOpen.toString()}>{isOpen ? <ArrowLeft /> : <ArrowRight />}</StyledNavIcon>
      </CompanyDataFiltersNavigationRowWrapper>
      <Divider />
      <FilterViewWrapper data-testid={DataTestIds.filters} isVisible={isOpen}>
        {isOpen &&
          filters.map(({ key, Filter }, index) => {
            const showDivider = index !== filters.length - 1;
            return (
              <>
                <Filter key={key} value={value[key]} onChange={onFilterChange} />
                {showDivider && <Divider />}
              </>
            );
          })}
      </FilterViewWrapper>
    </>
  );
};

export { CompanyDataFilters as default, DataTestIds };
