import * as React from "react";
import { Button, Drawer, Icon, Popover, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { MoreVert } from "@alphasights/alphadesign-icons";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useState, useCallback } from "react";
import { flyOutOptIn, flyOutOptOut, interactionSettings } from "content/ProjectsPage";
import PccSettingsModal from "components/PccSettingsModal";
import { useEnv } from "@alphasights/client-portal-shared";
import styled, { x } from "@xstyled/styled-components";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { FlyoutMode } from "pages/InteractionPage/enums";
import { useNavigate } from "router-utils";
import { noop } from "lodash";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ProjectFeature } from "@alphasights/portal-api-client";

const MoreOptions = ({
  interaction,
  onCancelInteraction,
  onCancelRequest,
  onRequestRescheduleInteraction,
  pccOptOutWindow,
  closeOnScroll,
  token,
  isCentered,
  ...props
}) => {
  const internalUser = useEnv().internalUser;
  const isLoggedIn = useIsAuthenticated();
  const { isMobile } = useCheckScreen();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget.lastElementChild);
    } else {
      setAnchorEl(null);
    }
  };

  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const { popoverSpapcing, menuIcon } = useDataDropdownStyles({ open });

  const [isPccEditOpen, setPccEditOpen] = useState(false);
  const { onUpdateInteractionPCCFlag } = useProjectInteractionsContext();

  const onUpdatePccFlag = (value) => {
    updateState(value);
    setPccEditOpen(false);
  };

  const updateState = useCallback((value) => onUpdateInteractionPCCFlag(interaction.id, value), [
    interaction.id,
    onUpdateInteractionPCCFlag,
  ]);

  if (
    !(interaction.showCancelInteractionMenu && isLoggedIn) &&
    !interaction.showCancelRequestMenu &&
    !interaction.showPccMenu &&
    !(interaction.showRescheduleMenu && isLoggedIn) &&
    !internalUser
  ) {
    return null;
  }

  const menuContent = (
    <MenuContent
      handlePopoverClose={handlePopoverClose}
      onCancelInteraction={onCancelInteraction}
      onCancelRequest={onCancelRequest}
      onChangePccFlag={() => setPccEditOpen(true)}
      onRequestRescheduleInteraction={onRequestRescheduleInteraction}
      interaction={interaction}
      internalUser={internalUser}
      token={token}
    />
  );

  return (
    <>
      <x.div
        onClick={(e) => {
          e.stopPropagation();
          handlePopoverOpen(e);
        }}
        data-testid="interaction-more-options-button"
      >
        <Icon size="small" color="secondary" onClick={noop} {...menuIcon} {...props}>
          <MoreVert />
        </Icon>
      </x.div>
      {!isMobile ? (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          closeOnScroll={closeOnScroll}
          placement={isCentered ? "bottom" : "bottom-start"}
          zIndex="100"
          portal={false}
          {...popoverSpapcing}
        >
          {menuContent}
        </Popover>
      ) : (
        <Drawer title="Data" open={open} onClose={handlePopoverClose} zIndex={5}>
          {menuContent}
        </Drawer>
      )}
      {interaction.showPccMenu && (
        <PccSettingsModal
          header={interactionSettings}
          isOpen={isPccEditOpen}
          isPccOptIn={interaction.peerContentContributor}
          onClose={() => setPccEditOpen(false)}
          pccOptOutWindow={pccOptOutWindow}
          scheduledCallTime={interaction.scheduledCallTime}
          updatePccStatus={(value) => onUpdatePccFlag(value)}
        />
      )}
    </>
  );
};

const DropdownButtonItem = styled(Button)`
  padding-left: ${tokens.spacing.inner.base04};
  padding-right: ${tokens.spacing.inner.base04};
  justify-content: flex-start;
  color: ${tokens.color.text.strong._};
`;

export const MenuContent = ({
  handlePopoverClose,
  onCancelInteraction,
  onCancelRequest,
  onChangePccFlag,
  onRequestRescheduleInteraction,
  interaction,
  internalUser,
  token,
}) => {
  const navigate = useNavigate();
  const flyoutContext = useFlyoutContext() ?? {};
  const { flyoutMode, selectedMessageThread } = flyoutContext;
  const { isMobile } = useCheckScreen();
  const isLoggedIn = useIsAuthenticated();
  const typographyVariant = isMobile ? "body-large" : "body";
  const isMessagesFlyout = flyoutMode === FlyoutMode.Messages;
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isInteractionActionsDisabled = isFeatureDisabled(ProjectFeature.InteractionActions);

  const handleOpenMenu = (onOpenCallback, e) => {
    onOpenCallback(e);
    handlePopoverClose();
  };

  const viewInSystem = (e) => {
    const deliveryUrl = `/api/projects/${token}/interactions/${interaction.id}/delivery-manage`;

    e.stopPropagation();
    window.open(deliveryUrl);
  };

  const onViewMessageInInbox = () => {
    navigate(`/${token}/experts/messages-view?selectedThread=${selectedMessageThread.id}`);
  };

  return (
    <x.div display="flex" flexDirection="column">
      {isMessagesFlyout && (
        <DropdownButtonItem onClick={onViewMessageInInbox} variant="ghost">
          <Typography variant={typographyVariant}>View Message In Inbox</Typography>
        </DropdownButtonItem>
      )}
      {interaction.showCancelRequestMenu && onCancelRequest && !isMessagesFlyout && !isInteractionActionsDisabled && (
        <DropdownButtonItem
          onClick={() => handleOpenMenu(onCancelRequest)}
          variant="ghost"
          data-testid="cancel-request-button"
        >
          <Typography variant={typographyVariant}>Cancel request</Typography>
        </DropdownButtonItem>
      )}
      {interaction.showCancelInteractionMenu &&
        onCancelInteraction &&
        isLoggedIn &&
        !isMessagesFlyout &&
        !isInteractionActionsDisabled && (
          <DropdownButtonItem onClick={() => handleOpenMenu(onCancelInteraction)} variant="ghost">
            <Typography variant={typographyVariant}>Request to cancel</Typography>
          </DropdownButtonItem>
        )}
      {interaction.showRescheduleMenu &&
        onRequestRescheduleInteraction &&
        isLoggedIn &&
        !isMessagesFlyout &&
        !isInteractionActionsDisabled && (
          <DropdownButtonItem onClick={() => handleOpenMenu(onRequestRescheduleInteraction)} variant="ghost">
            <Typography variant={typographyVariant}>Request to reschedule</Typography>
          </DropdownButtonItem>
        )}
      {interaction.showPccMenu && onChangePccFlag && !isMessagesFlyout && (
        <DropdownButtonItem onClick={() => handleOpenMenu(onChangePccFlag)} variant="ghost">
          <Typography variant={typographyVariant}>
            {interaction.peerContentContributor ? flyOutOptOut : flyOutOptIn}
          </Typography>
        </DropdownButtonItem>
      )}
      {internalUser && (
        <DropdownButtonItem onClick={(e) => handleOpenMenu(viewInSystem, e)} variant="ghost">
          <Typography variant={isMobile ? "body-large" : "body"}>View in system</Typography>
        </DropdownButtonItem>
      )}
    </x.div>
  );
};

const useDataDropdownStyles = () => {
  const {
    color: { text },
    font,
  } = useThemeTokens();

  const popoverSpapcing = {
    padding: 0,
  };

  const menuIcon = {
    color: text.secondary,
    fontSize: font.size["06"],
  };

  return {
    popoverSpapcing,
    menuIcon,
  };
};

export default MoreOptions;
