import { FC, useState, useEffect } from "react";
import { useNavigate } from "router-utils";
import { useQuery } from "query-utils";
import { isArray, isEqual } from "lodash";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { SEARCH_SUGGESTION_TYPES, PromiseStatus } from "@alphasights/client-portal-shared";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";

import Search, {
  getDefaultComponents,
  SearchOption,
  SearchSizeVariant,
  SearchVariant,
  OptionProps,
  SearchItemProps,
} from "components/Search";
import { contentService, projectService } from "services/content";
import {
  INACTIVE_SEARCHBAR_COLOR,
  NUMBER_OPTIONS_PER_SECTION,
  PLACEHOLDER,
  SEARCH_ERROR_MESSAGE_PER_SECTION,
  SEARCH_FAILURE_MESSAGE,
} from "components/NavigationContainer/SearchBar/constants";
import { useAppSearchContext } from "providers/AppSearchProvider";
import useAlphaNowSearch from "pages/AlphaNowPage/hooks/useAlphaNowSearch";
import {
  SEARCH_QUERY_ITEM_TYPE,
  SEARCH_SECTIONS,
  SEARCH_SUGGESTION_TYPE_ICONS,
  SEARCH_SUGGESTION_TYPE_NAMES,
} from "constants/AlphaNow/Search";
import {
  AlphaNowSearchResult,
  CompanySuggestion,
  SearchHistory,
  SearchSuggestion,
  SettledSearchResult,
} from "pages/AlphaNowPage/components/AlphaNowSearch/types";
import { AlphaNowProjectSearchQuery, AlphaNowSearchQuery, Query } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import {
  cleanUpQuery,
  getProjectSearchQuery,
  getSearchSuggestion,
  processProjectSearchQuery,
} from "pages/AlphaNowPage/components/AlphaNowSearch/utils";
import { processSearchQuery, QueryItem } from "pages/AlphaNowPage/components/AlphaNowSearch/boolean-expression-utils";
import { BooleanExpressionError, isBooleanSearch, isBooleanSearchTerm } from "components/Search/utils";
import { SearchStyleVariant } from "components/Search/consts";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { CustomOptionProps, SearchBarProps, SearchErrorsPerSection } from "./types";
import ComplexOption from "components/NavigationContainer/SearchBar/ComplexOption";
import * as S from "./SearchBar.styled";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";

const DataTestIds = {
  topBarSearch: "top-bar-search",
};

const getSecondaryLabel = (option: SearchSuggestion) => {
  if (option.type === SEARCH_SUGGESTION_TYPES.Project) {
    const [firstTargetCompany] = option.targetCompanies ?? [];
    const companyName = firstTargetCompany?.name;
    if (companyName) {
      return companyName;
    }
  }
  return SEARCH_SUGGESTION_TYPE_NAMES[option.type];
};

const enhanceOption = (option: SearchSuggestion) => {
  const enhancedOption: SearchOption = {
    ...option,
    secondaryLabel: getSecondaryLabel(option),
  };
  if ((option as CompanySuggestion).logo) {
    enhancedOption.avatar = { src: (option as CompanySuggestion).logo } as SearchOption["avatar"];
  } else {
    enhancedOption.StartIcon = SEARCH_SUGGESTION_TYPE_ICONS[option.type];
  }
  return enhancedOption;
};

const SearchItem: FC<SearchItemProps> = ({ data, ...props }) => {
  const { SearchItem: BaseSearchItem } = getDefaultComponents();
  const enhancedData = enhanceOption(data as SearchSuggestion);
  return <BaseSearchItem data={enhancedData} {...props} />;
};

const CustomOption: FC<CustomOptionProps> = ({ data, ...props }) => {
  const { Option: BaseOption } = getDefaultComponents();
  const isRecentSearch = data.type === SEARCH_SUGGESTION_TYPES.RecentSearch && isArray(data.value);
  if (isRecentSearch) {
    const enhancedComplexOption: SearchOption[] = data.value.map((option: SearchOption) => {
      return isBooleanSearchTerm(option.type as string) ? option : enhanceOption(option as SearchSuggestion);
    });
    return <ComplexOption data={enhancedComplexOption} {...(props as Omit<OptionProps, "data">)} />;
  } else {
    const enhancedOption: SearchOption = enhanceOption(data as SearchSuggestion);
    return <BaseOption data={enhancedOption} {...(props as Omit<OptionProps, "data">)} />;
  }
};

const SearchBar = ({ onNavigateToProject }: SearchBarProps) => {
  const [isActive, setIsActive] = useState(false);
  const [searchFailureMessage, setSearchFailureMessage] = useState<string>();
  const [searchErrorsPerSection, setSearchErrorsPerSection] = useState<SearchErrorsPerSection>();

  const { color, spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const {
    query: { searchQuery },
    updateQuery,
  } = useAppSearchContext();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isAlphaNowEnabled = currentUser?.alphaNowEnabled;
  const isInternalUser = !!currentUser?.internalUser;

  const { hasUserBadge } = useUserBadgeContext();

  //TODO: [RD1-196]: Remove Search History Badge,
  const hasSearchHistoryEnabled = hasUserBadge(Badge.searchHistory) && !isInternalUser;

  const { loadSearchSuggestions } = useAlphaNowSearch();

  const backgroundColor = isActive ? undefined : `${INACTIVE_SEARCHBAR_COLOR}!important`;
  const accentColor = isActive ? undefined : color.icon.inverse;

  const resetSearchErrorMessage = () => {
    setSearchFailureMessage("");
    setSearchErrorsPerSection({});
  };

  const handleSearchErrorPerSection = (searchSection: SEARCH_SECTIONS) => {
    setSearchErrorsPerSection((prevState) => ({
      ...prevState,
      [searchSection]: SEARCH_ERROR_MESSAGE_PER_SECTION[searchSection],
    }));
  };

  const { data: searchHistory = [], refetch: refetchSearchHistory } = useQuery<SearchHistory[]>(
    ["SearchHistory"],
    () => contentService.getSearchHistory(),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    hasSearchHistoryEnabled && refetchSearchHistory();
  }, [searchQuery, hasSearchHistoryEnabled, refetchSearchHistory]);

  const processSearchHistoryResult = (recentSearchResult: SearchHistory): AlphaNowSearchQuery[] => {
    const { projectSearchResult, searchQueryResult } = recentSearchResult;

    if (projectSearchResult) {
      return [
        getSearchSuggestion({
          type: SEARCH_SUGGESTION_TYPES.Project,
          searchResult: projectSearchResult,
        }) as AlphaNowSearchQuery,
      ];
    }

    return (searchQueryResult ?? []).map((result) => ({
      ...result,
      type: SEARCH_QUERY_ITEM_TYPE[result.type as keyof typeof SEARCH_QUERY_ITEM_TYPE] || result.type,
    }));
  };

  const defaultOptions = searchHistory.map((recentSearch) => {
    return getSearchSuggestion({
      type: SEARCH_SUGGESTION_TYPES.RecentSearch,
      searchResult: processSearchHistoryResult(recentSearch),
    });
  });

  const loadOptions = async (inputValue: string): Promise<any> => {
    resetSearchErrorMessage();

    let options: AlphaNowSearchResult[] = [];

    try {
      const results = (await loadSearchSuggestions(inputValue)) as SettledSearchResult[];

      if (results.length === 0) return [];

      const { hasFulfilled } = results.reduce(
        (acc, result: SettledSearchResult) => {
          if (result.status === PromiseStatus.Fulfilled && result.value) {
            acc.options.push(...result.value);
            acc.hasFulfilled = true;
          } else if (result.section) {
            handleSearchErrorPerSection(result.section);
          }
          return acc;
        },
        { options, hasFulfilled: false }
      );

      if (!hasFulfilled) {
        throw new Error("Failed to return suggestions");
      }
    } catch (error) {
      setSearchFailureMessage(SEARCH_FAILURE_MESSAGE);
    }

    return options;
  };

  const validateQuery = (query: AlphaNowSearchQuery[]) => {
    if (isBooleanSearch(query)) {
      try {
        const booleanExpressionTree = processSearchQuery(query as QueryItem[]);
        return booleanExpressionTree;
      } catch (e) {
        if (e instanceof BooleanExpressionError) {
          return;
        } else {
          throw e;
        }
      }
    }
    return query;
  };
  const onProjectSearch = async (query: AlphaNowProjectSearchQuery) => {
    navigate(`/${myAlphaSightsBaseUrl}/projects/${query.token}`, { replace: true });
    onNavigateToProject?.();
    const projectSearchResult = processProjectSearchQuery(query);
    try {
      await projectService.logProjectSearch(projectSearchResult);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const projectSearchQuery = getProjectSearchQuery(searchQuery);
    if (!!projectSearchQuery) {
      onProjectSearch(projectSearchQuery);
    }
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchChange = (newQuery: SearchOption[] = []) => {
    const cleanQuery = cleanUpQuery(newQuery);

    if (isEqual(cleanQuery, searchQuery) || !validateQuery(cleanQuery as AlphaNowSearchQuery[])) return;

    updateQuery({
      searchQuery: cleanQuery,
      selectedContentId: undefined,
    } as Query);
  };

  const handleFocus = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);

  const optionSections = [
    { title: SEARCH_SECTIONS.RecentSearch, errorMessage: searchErrorsPerSection?.[SEARCH_SECTIONS.RecentSearch] },
    {
      title: SEARCH_SECTIONS.ResearchLibrary,
      numOptionsPerSection: NUMBER_OPTIONS_PER_SECTION,
      errorMessage: searchErrorsPerSection?.[SEARCH_SECTIONS.ResearchLibrary],
    },
    { title: SEARCH_SECTIONS.Project, errorMessage: searchErrorsPerSection?.[SEARCH_SECTIONS.Project] },
  ];

  if (!isAlphaNowEnabled) {
    return null;
  }

  return (
    <S.SearchBarWrapper data-testid={DataTestIds.topBarSearch} isSearchBarActive={isActive}>
      <Search
        options={defaultOptions as SearchOption[]}
        variant={SearchVariant.Complex}
        size={isMobile ? SearchSizeVariant.Large : SearchSizeVariant.Medium}
        styleVariant={SearchStyleVariant.V2}
        query={searchQuery}
        placeholder={PLACEHOLDER}
        debounceSearch={true}
        loadOptions={loadOptions}
        optionSections={optionSections}
        onChange={onSearchChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        components={{
          SearchItem,
          Option: CustomOption,
        }}
        optionProps={{
          inputEmphasis: ({ type }) =>
            [SEARCH_SUGGESTION_TYPES.Company, SEARCH_SUGGESTION_TYPES.Colleague].includes(
              type as SEARCH_SUGGESTION_TYPES
            ),
          anywhereEmphasis: ({ type }) => type === SEARCH_SUGGESTION_TYPES.Colleague,
        }}
        allowSingleCharSearch={false}
        allowBooleanOperators={isAlphaNowEnabled}
        autoHideSearchIcon={isMobile}
        style={{ top: spacing.inner.base02, background: backgroundColor }}
        accentColor={accentColor}
        booleanSearchProps={{
          section: SEARCH_SECTIONS.ResearchLibrary,
        }}
        showClearButton
        searchFailureMessage={searchFailureMessage}
      />
    </S.SearchBarWrapper>
  );
};
export { SearchBar as default, DataTestIds };
