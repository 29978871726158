import { useLocation, useNavigate } from "router-utils";
import useQuery from "hooks/useQuery";
import { useMemo } from "react";

const getSearchString = (query: URLSearchParams) => (query.toString() ? `?${query}` : undefined);

const useQueryParams = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useQuery();

  const value = useMemo(
    () => ({
      get: (key: string) => query.get(key),
      set: (key: string, value: string | number, replace = false) => {
        value.toString() ? query.set(key, value.toString()) : query.delete(key);
        navigate(
          {
            pathname,
            search: getSearchString(query),
          },
          { replace }
        );
      },
      delete: (key: string, replace = false) => {
        query.delete(key);
        navigate(
          {
            pathname,
            search: getSearchString(query),
          },
          { replace }
        );
      },
    }),
    [pathname, navigate, query]
  );

  return value;
};

export default useQueryParams;
