import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback: () => void, dependencies: unknown[]) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize)); // eslint-disable-line react-hooks/exhaustive-deps
}
