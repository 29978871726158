import { IconButton, IconButtonVariant, useThemeTokens, XStyledProps } from "@alphasights/alphadesign-components";
import { ArrowLeft } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

interface ReturnButtonProps {
  onReturn: () => void;
  variant?: IconButtonVariant;
  style?: XStyledProps;
}

const DataTestIds = {
  returnButton: "return-arrow",
};

const ReturnButton = ({ onReturn, variant = "ghost", style }: ReturnButtonProps) => {
  const {
    color: { text },
  } = useThemeTokens();

  return (
    <x.div {...style}>
      <IconButton
        onClick={onReturn}
        variant={variant}
        key="return-button"
        color={text.secondary}
        testId={DataTestIds.returnButton}
      >
        <ArrowLeft />
      </IconButton>
    </x.div>
  );
};

export { ReturnButton as default, DataTestIds };
