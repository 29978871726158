import { useCallback, useRef, useState } from "react";
import {
  ActionGroup,
  Icon,
  IconButton,
  IconGroup,
  ListOption,
  Popover,
  Skeleton,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import { Add, Copy, Delete, DragIndicator, MoreVert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import * as S from "./QuestionThemeCard.styled";
import { AngleQuestion, AngleQuestionTheme } from "models/AngleQuestions";
import { useAngleQuestionsContext } from "providers/AngleQuestionsProvider";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { TextField } from "../TextField";

export const QuestionThemeCardLoading = () => (
  <S.CardWrapper>
    <S.CardHeaderWrapper>
      <S.CardTitleWrapper>
        <x.div w="50%" maxW="400px" paddingLeft="12px">
          <Skeleton variant="noMargin" height="20px" width="100%" />
        </x.div>
      </S.CardTitleWrapper>
    </S.CardHeaderWrapper>
    <S.CardContentWrapper>
      <S.QuestionLoading />
      <S.QuestionLoading />
      <S.QuestionLoading />
      <AddQuestionButton disabled />
    </S.CardContentWrapper>
  </S.CardWrapper>
);

export const QuestionThemeCard = ({ theme, index }: { theme: AngleQuestionTheme; index: number }) => {
  const {
    deleteAngleQuestionTheme,
    copyAngleThemeQuestions,
    editingQuestionThemeId,
    addingQuestionThemeId,
    setAddingQuestionThemeId,
    setEditingQuestionTheme,
    reorderAngleQuestions,
    readOnly,
  } = useAngleQuestionsContext();
  const { toast } = useAlphaToast();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const ref = useRef<HTMLDivElement>(null);
  const [hoveredItemId, setHoveredItemId] = useState<number | null>(null);
  const [externalThemeError, setExternalThemeError] = useState<boolean>(false);
  const isEditing = editingQuestionThemeId === theme.id;

  const handleThemeCardOptionsClick = ({ currentTarget }: { currentTarget: Element }) => {
    if (!anchorEl) {
      setAnchorEl(currentTarget);
    } else {
      setAnchorEl(undefined);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(undefined);
  };

  const handleCopyThemeQuestions = () => {
    copyAngleThemeQuestions([theme.id]).then(() => toast.success({ message: "Questions copied to clipboard." }));
    handleClosePopover();
  };

  const handleDeleteTheme = () => {
    deleteAngleQuestionTheme(theme.id).then(() => toast.success({ message: "Topic deleted." }));
    handleClosePopover();
  };

  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = () => setIsDragging(true);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      setIsDragging(false);

      if (!result.destination) return;

      const movedQuestion = theme.questions[result.source.index];
      reorderAngleQuestions(theme.id, movedQuestion.id, result.destination.index);
    },
    [reorderAngleQuestions, theme.id, theme.questions]
  );

  const open = Boolean(anchorEl);

  const popover = (
    <Popover ref={ref} anchorEl={anchorEl} open={open} onClose={handleClosePopover} placement="bottom-end">
      <ListOption
        type="text"
        leftIcon={
          <Icon color="secondary">
            <Copy />
          </Icon>
        }
        onChange={handleCopyThemeQuestions}
      >
        Copy topic
      </ListOption>
      <ListOption
        type="text"
        leftIcon={
          <Icon color="danger">
            <Delete />
          </Icon>
        }
        onChange={handleDeleteTheme}
        disabled={readOnly}
        dataAttributes={{ "data-testid": "delete-theme" }}
      >
        Delete topic
      </ListOption>
    </Popover>
  );

  const showDragHandle = hoveredItemId === theme.id && !readOnly && !isEditing;
  return (
    <Draggable key={theme.id} draggableId={theme.id.toString()} index={index}>
      {(provided, snapshot) => (
        <S.CardWrapper ref={provided.innerRef} {...provided.draggableProps}>
          <S.CardHeaderWrapper
            onMouseEnter={() => setHoveredItemId(theme.id)}
            onMouseLeave={() => setHoveredItemId(null)}
          >
            <x.div
              key={module.id}
              opacity={showDragHandle || snapshot.isDragging ? 1 : 0}
              data-testid={`theme-drag-handle-${theme.id}`}
              pointerEvents={showDragHandle ? "auto" : "none"}
              {...provided.dragHandleProps}
              w={isEditing ? 0 : "auto"}
            >
              <Icon size="small">
                <DragIndicator />
              </Icon>
            </x.div>
            {isEditing ? (
              <S.CardEditTitleWrapper>
                <EditThemeField
                  theme={theme}
                  handleDeleteTheme={handleDeleteTheme}
                  externalError={externalThemeError}
                  setExternalError={setExternalThemeError}
                />
              </S.CardEditTitleWrapper>
            ) : (
              <>
                <S.CardTitleWrapper
                  onClick={() => {
                    if (!readOnly) {
                      setEditingQuestionTheme(theme);
                    }
                  }}
                  readOnly={readOnly}
                >
                  <S.CardTitle variant="body-em" color="strong">
                    {theme.title}
                  </S.CardTitle>
                </S.CardTitleWrapper>
                <IconButton size="small" variant="basic" onClick={handleThemeCardOptionsClick} testId={"more-options"}>
                  <MoreVert />
                </IconButton>
                {popover}
              </>
            )}
          </S.CardHeaderWrapper>
          <S.CardContentWrapper>
            <S.QuestionListWrapper>
              <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <Droppable droppableId="question-droppable">
                  {(provided) => (
                    <x.div ref={provided.innerRef} {...provided.droppableProps}>
                      {theme.questions.map((question, index) => (
                        <Question key={question.id} question={question} index={index} isDragging={isDragging} />
                      ))}
                      <>{provided.placeholder}</>
                    </x.div>
                  )}
                </Droppable>
              </DragDropContext>
              {addingQuestionThemeId === theme.id && (
                <AddQuestionField
                  themeId={theme.id}
                  themeTitle={theme.title}
                  setExternalThemeError={setExternalThemeError}
                />
              )}
            </S.QuestionListWrapper>
            <AddQuestionButton
              disabled={readOnly || theme.id === -1}
              onClick={() => setAddingQuestionThemeId(theme.id)}
            />
          </S.CardContentWrapper>
        </S.CardWrapper>
      )}
    </Draggable>
  );
};

const EditThemeField = ({
  theme,
  handleDeleteTheme,
  externalError,
  setExternalError,
}: {
  theme: AngleQuestionTheme;
  handleDeleteTheme: () => void;
  externalError: boolean;
  setExternalError: (value: boolean) => void;
}) => {
  const { createOrUpdateAngleQuestionTheme, questionThemeTitle, setQuestionThemeTitle } = useAngleQuestionsContext();
  const { toast } = useAlphaToast();
  const [hasError, setHasError] = useState<boolean>(false);

  const handleEditTheme = () => {
    if (!questionThemeTitle) {
      setHasError(true);
      setExternalError(true);
      return;
    }
    setHasError(false);
    setExternalError(false);
    createOrUpdateAngleQuestionTheme(theme.id, questionThemeTitle).then(() => {
      toast.success({ message: "Topic updated." });
    });
  };

  return (
    <TextField
      value={questionThemeTitle}
      onChange={setQuestionThemeTitle}
      onSubmit={handleEditTheme}
      onDelete={handleDeleteTheme}
      errorText={hasError || externalError ? "Enter topic name." : undefined}
      placeholder=""
      maxLength={255}
      testId="edit-theme-field"
    />
  );
};

const AddQuestionField = ({
  themeId,
  themeTitle,
  setExternalThemeError,
}: {
  themeId: number;
  themeTitle: string;
  setExternalThemeError: (value: boolean) => void;
}) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const { addAngleQuestion, setAddingQuestionThemeId, questionText, setQuestionText } = useAngleQuestionsContext();
  const { toast } = useAlphaToast();

  const handleAddQuestion = () => {
    if (!questionText) {
      setHasError(true);
      return;
    }
    if (!themeTitle.trim()) {
      setExternalThemeError(true);
      return;
    }
    setHasError(false);
    addAngleQuestion(themeId, questionText).then(() => {
      toast.success({ message: "Question added." });
    });
  };

  return (
    <S.AddQuestionFieldWrapper>
      <TextField
        value={questionText}
        onChange={setQuestionText}
        onSubmit={handleAddQuestion}
        onDelete={() => setAddingQuestionThemeId(undefined)}
        errorText={hasError ? "Enter a question." : undefined}
        placeholder="Enter question here..."
      />
    </S.AddQuestionFieldWrapper>
  );
};

const Question = ({ question, index, isDragging }: { question: AngleQuestion; index: number; isDragging: boolean }) => {
  const { deleteAngleQuestion, readOnly, editingQuestionId, setEditingQuestion } = useAngleQuestionsContext();
  const { toast } = useAlphaToast();
  const [hoveredItemId, setHoveredItemId] = useState<number | undefined>();

  const isEditing = editingQuestionId === question.id;

  const handleDeleteQuestion = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    deleteAngleQuestion(question.id).then(() => {
      toast.success({ message: "Question deleted." });
    });
  };

  const handleEditQuestion = () => {
    if (!readOnly) {
      setEditingQuestion(question);
    }
  };

  const questionActionslist: IconGroup[] = [
    {
      tooltipTitle: "Delete",
      icon: (
        <Icon size="small">
          <Delete />
        </Icon>
      ),
      onClick: handleDeleteQuestion,
    },
  ];

  const questionButtons = (
    <S.QuestionButtons data-testid="action-buttons">
      <ActionGroup iconGroup={questionActionslist} />
    </S.QuestionButtons>
  );

  const showDragHandle = hoveredItemId === question.id && !readOnly && !isEditing;
  return (
    <Draggable key={question.id} draggableId={question.id.toString()} index={index}>
      {(provided, snapshot) => (
        <S.QuestionWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          onMouseEnter={() => !isDragging && setHoveredItemId(question.id)}
          onMouseLeave={() => setHoveredItemId(undefined)}
          onClick={handleEditQuestion}
        >
          <x.div
            opacity={showDragHandle || snapshot.isDragging ? 1 : 0}
            key={module.id}
            data-testid={`question-drag-handle-${question.id}`}
            pointerEvents={showDragHandle ? "auto" : "none"}
            {...provided.dragHandleProps}
          >
            <Icon size="small">
              <DragIndicator />
            </Icon>
          </x.div>
          {isEditing ? (
            <EditQuestionField question={question} handleDeleteQuestion={handleDeleteQuestion} />
          ) : (
            <S.Question
              label={question.text}
              type="text"
              isInteractive={!readOnly}
              rightIcon={hoveredItemId === question.id && !readOnly ? questionButtons : null}
            />
          )}
        </S.QuestionWrapper>
      )}
    </Draggable>
  );
};

const EditQuestionField = ({
  question,
  handleDeleteQuestion,
}: {
  question: AngleQuestion;
  handleDeleteQuestion: () => void;
}) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const { updateAngleQuestion, questionText: editingQuestionText, setQuestionText } = useAngleQuestionsContext();
  const { toast } = useAlphaToast();

  const handleEditQuestion = () => {
    if (!editingQuestionText) {
      setHasError(true);
      return;
    }
    setHasError(false);
    updateAngleQuestion(question.id, editingQuestionText).then(() => {
      toast.success({ message: "Question updated." });
    });
  };

  return (
    <TextField
      value={editingQuestionText}
      onChange={setQuestionText}
      onSubmit={handleEditQuestion}
      onDelete={handleDeleteQuestion}
      errorText={hasError ? "Enter a question." : undefined}
      placeholder=""
    />
  );
};

const AddQuestionButton = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <S.AddQuestionButton variant="ghost-padded" startIcon={<Add />} size="small" disabled={disabled} onClick={onClick}>
      Add question
    </S.AddQuestionButton>
  );
};
