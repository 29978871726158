import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { AccordionItem, Alert, Icon, IconButton, List, Popover, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { Lead } from "@alphasights/portal-api-client";
import { compact } from "lodash";
import { AddCall, Info } from "@alphasights/alphadesign-icons";
import { useInteractioUpgradesStyles } from "./InteractionUpgrades.styles";
import { fetch } from "hooks/useApi";
import { UpgradeItem } from "./UpgradeItem";
import { getFirstName } from "pages/InteractionPage/utils";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { DeliverablesInformation, EnhancementsInformation } from "./InformationBox";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { InteractionUpgradesProvider, useInteractionUpgradesContext } from "./InteractionUpgradesProvider";
import { useComputeDeliverables } from "./useComputeDeliverables";
import { isPostScheduledCallTime, isRecordingExpired } from "./helpers";
import { useComputeEnhancements } from "./useComputeEnhancements";

interface InteractionUpgradesProps {
  isVisible?: boolean;
  expanded?: boolean;
  interaction: Interaction;
  projectToken: string;
  projectLead: Lead;
  upgrades?: InteractionUpgrade[];
  loadingUpgrades: boolean;
  aiTranscriptEnabled: boolean;
  recordedEnabled: boolean;
  humanTranscriptEnabled: boolean;
  deliverablesViewEnabled: boolean;
  showMobileView: boolean;
  requiresComplianceApproval: boolean;
  videoCallAvailable: boolean;
  blindedUpgradeAvailable: boolean;
}
export const InteractionUpgrades = React.forwardRef(
  ({ upgrades, isVisible, expanded, ...props }: InteractionUpgradesProps, ref) => {
    return (
      <InteractionUpgradesProvider {...props} upgrades={upgrades ?? []}>
        <InteractionUpgradesSection ref={ref} isVisible={isVisible} expanded={expanded} />
      </InteractionUpgradesProvider>
    );
  }
);

const InteractionUpgradesSection = React.forwardRef(
  ({ isVisible = true, expanded }: { isVisible?: boolean; expanded?: boolean }, ref) => {
    const isLoggedIn = useIsAuthenticated();
    const { isExpandedUpgrade } = useFlyoutContext();
    const {
      loading,
      interaction,
      selectedUpgrades,
      terms,
      duringInteraction,
      postInteraction,
    } = useInteractionUpgradesContext();
    const deliverables = useComputeDeliverables({
      selected: selectedUpgrades,
      transcriptTargetLanguage: interaction.transcriptTargetLanguage,
    });
    const enhancements = useComputeEnhancements();
    const showDeliverables =
      deliverables.length > 0 &&
      interaction.callQuality !== "bad" &&
      (terms?.transcriptConsentAccepted || (!isPostScheduledCallTime(interaction) && !duringInteraction));
    const showEnhancements =
      enhancements.filter((e) => e.selected).length > 0 || (!duringInteraction && !postInteraction);

    if (!isVisible) return null;
    if (!loading && !showDeliverables && !showEnhancements) return null;

    return (
      <AccordionItemWithLoginWall
        ref={ref}
        title="Interaction Upgrades"
        open={isExpandedUpgrade || expanded}
        disabled={!isLoggedIn}
      >
        {isLoggedIn && (
          <InteractionUpgradesSectionContent showDeliverables={showDeliverables} showEnhancements={showEnhancements} />
        )}
      </AccordionItemWithLoginWall>
    );
  }
);

const InteractionUpgradesSectionContent = ({
  showDeliverables,
  showEnhancements,
}: {
  showDeliverables: boolean;
  showEnhancements: boolean;
}) => {
  const { loading, interaction } = useInteractionUpgradesContext();

  const { sectionStyle } = useInteractioUpgradesStyles({});
  return (
    <>
      {loading ? (
        <NoXMarginSkeleton count={5} />
      ) : (
        <x.div {...sectionStyle} data-testid={`interaction-upgrades-${interaction.id}`}>
          {showDeliverables && <Deliverables />}
          {showEnhancements && <Enhancements />}
        </x.div>
      )}
    </>
  );
};

const Deliverables = () => {
  const {
    recording,
    interaction,
    postInteraction,
    deliverablesViewEnabled,
    terms,
    projectToken,
    requiresComplianceApproval,
    selectedUpgrades: selectedInput,
    onRequestAddUpgrade: onAddUpgradeInput,
    onRequestRemoveUpgrade: onRemoveUpgradeInput,
  } = useInteractionUpgradesContext();
  const { updgradeTypeHeaderStyle, upgradeListStyle } = useInteractioUpgradesStyles();
  const recordingExpired = useMemo(() => isRecordingExpired(recording), [recording]);
  const [transcriptTargetLanguage, setTranscriptTargetLanguage] = useState(interaction.transcriptTargetLanguage);
  const [selected, setSelected] = useState(selectedInput);
  const [pending, setPending] = useState<string[]>([]);
  const transcriptRequests = useMemo(() => recording?.transcriptRequests ?? [], [recording]);
  const [transcriptRequestsStatus, setTranscriptRequestsStatus] = useState<TranscriptStatusList[]>([]);
  const consentGiven = terms?.transcriptConsentAccepted;

  useEffect(
    function loadTranscriptRequestsStatus() {
      if (transcriptRequests?.length > 0) {
        fetch({
          url: `/api/projects/${projectToken}/transcripts/status?transcriptIds=${transcriptRequests
            .map((tr) => tr.id)
            .join(",")}`,
          method: "GET",
        })
          .then((res) => res.json())
          .then((status) => {
            setTranscriptRequestsStatus(status);
          });
      } else {
        setTranscriptRequestsStatus([]);
      }
    },
    [projectToken, transcriptRequests]
  );

  const deliverables = useComputeDeliverables({
    selected,
    transcriptTargetLanguage,
  });

  const hasAnyTranscriptPending = transcriptRequestsStatus?.some((trs) => {
    const transcriptRequest = transcriptRequests.find((tr) => tr.id === trs.transcriptId);
    const transcriptProcessingFinished = trs.status.some((s) => s.value === "FINISHED");
    return (
      transcriptRequest &&
      transcriptRequest.transcriptType !== "ai" &&
      !transcriptRequest.completed &&
      !transcriptRequest.purgedAt &&
      transcriptProcessingFinished
    );
  });

  const needsConsent = useMemo(
    () => ["recorded", "transcript", "transcript_ai", "translated"].some((upgrade) => selected.includes(upgrade)),
    [selected]
  );

  const showDeliverableConsentBanner = !consentGiven && needsConsent;
  const showDeliberablePendingBanner =
    interaction.state === "completed" && requiresComplianceApproval && hasAnyTranscriptPending;

  const onAddUpgrade = (type: string, locale?: string) => {
    if (type === "translated") {
      setTranscriptTargetLanguage(locale);
    }

    const deliverable = deliverables.find((d) => d.type === type)!;
    setSelected(compact([...selected, deliverable.dependsOn, deliverable.type]));
    setPending(pending.filter((p) => p !== deliverable.dependsOn));
    onAddUpgradeInput(type, locale);
  };

  const onRemoveUpgrade = (type: string) => {
    const deliverable = deliverables.find((d) => d.type === type)!;
    const dependents = deliverable.dependents ?? [];
    setSelected(selected.filter((e) => ![...dependents, deliverable.dependsOn, deliverable.type].includes(e)));

    onRemoveUpgradeInput(type);
  };

  return (
    <x.div>
      <x.div {...updgradeTypeHeaderStyle} data-testid="deliverables-section">
        <Typography variant="body-large-em" color="secondary">
          Deliverables
        </Typography>
        <InteractionUpgradesHelp>
          <DeliverablesInformation upgrades={deliverables.map((d) => d.type)} />
        </InteractionUpgradesHelp>
      </x.div>
      {showDeliverableConsentBanner && <DeliverablesConsentBanner />}
      {showDeliberablePendingBanner && <DeliverablePendingBanner />}
      <List {...upgradeListStyle}>
        {deliverables.map((deliverable) => (
          <UpgradeItem
            key={`deliverable-${deliverable.type}`}
            postInteraction={postInteraction}
            interaction={interaction}
            transcriptRequests={transcriptRequests}
            recordingExpired={recordingExpired}
            onAddUpgrade={onAddUpgrade}
            onRemoveUpgrade={onRemoveUpgrade}
            selectedUpgrades={selected}
            pendingUpgrades={pending}
            setPendingUpgrades={setPending}
            deliverablesViewEnabled={deliverablesViewEnabled}
            projectToken={projectToken}
            {...deliverable}
          />
        ))}
      </List>
    </x.div>
  );
};

const DeliverablesConsentBanner = () => {
  const { bannerStyle } = useInteractioUpgradesStyles();
  const { duringInteraction, projectLead } = useInteractionUpgradesContext();

  return (
    <x.div {...bannerStyle}>
      {duringInteraction ? (
        <Alert variant="danger">The expert did not give consent to be recorded and/or transcribed.</Alert>
      ) : (
        <Alert variant="info">
          Audio Recordings & Transcripts require expert consent. Your project lead {getFirstName(projectLead.name)} is
          working on this.
        </Alert>
      )}
    </x.div>
  );
};

const DeliverablePendingBanner = () => {
  const { bannerStyle } = useInteractioUpgradesStyles();

  return (
    <x.div {...bannerStyle}>
      <Alert variant="warning">
        Your transcript(s) have been processed and are awaiting review from your internal compliance team.
      </Alert>
    </x.div>
  );
};

const Enhancements = () => {
  const {
    duringInteraction,
    postInteraction,
    onRequestAddUpgrade: onAddUpgrade,
    onRequestRemoveUpgrade: onRemoveUpgrade,
    interaction,
    projectToken,
  } = useInteractionUpgradesContext();

  const {
    updgradeTypeHeaderStyle,
    upgradeListStyle,
    upgradeDisabledListStyle,
    lockedEnhancementsTitleStyle,
    lockedEnhancementsDetailStyle,
    upgradeIconStyle,
  } = useInteractioUpgradesStyles({});

  const enhancements = useComputeEnhancements();

  return (
    <x.div>
      <x.div {...updgradeTypeHeaderStyle}>
        <Typography variant="body-large-em" color="secondary">
          Enhancements
        </Typography>
        {!duringInteraction && !postInteraction && (
          <InteractionUpgradesHelp>
            <EnhancementsInformation upgrades={enhancements.map((d) => d.type)} />
          </InteractionUpgradesHelp>
        )}
      </x.div>
      {!duringInteraction && !postInteraction ? (
        <List {...upgradeListStyle}>
          {enhancements.map((enhancement) => (
            <UpgradeItem
              key={`enhancement-${enhancement.type}`}
              interaction={interaction}
              postInteraction={postInteraction}
              onAddUpgrade={onAddUpgrade}
              onRemoveUpgrade={onRemoveUpgrade}
              projectToken={projectToken}
              {...enhancement}
            />
          ))}
        </List>
      ) : (
        <x.div {...upgradeDisabledListStyle}>
          <Icon {...upgradeIconStyle}>
            <AddCall />
          </Icon>
          <x.div>
            <Typography {...lockedEnhancementsTitleStyle}>Interaction enhancements</Typography>
            <Typography variant="body-small" {...lockedEnhancementsDetailStyle}>
              {enhancements.filter((e) => e.selected).length > 0
                ? enhancements
                    .filter((e) => e.selected)
                    .map((e) => e.name)
                    .join(" • ")
                : "None"}
            </Typography>
          </x.div>
        </x.div>
      )}
    </x.div>
  );
};

const InteractionUpgradesHelp = ({ children }: { children: JSX.Element }) => {
  const { isMobile } = useCheckScreen();
  const { helpButtonStyle, popoverStyle } = useInteractioUpgradesStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const ref = useRef<HTMLDivElement>(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(undefined);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        variant="ghost"
        onMouseEnter={!isMobile ? handlePopoverOpen : undefined}
        onClick={isMobile ? (event) => (anchorEl ? handlePopoverClose() : handlePopoverOpen(event)) : undefined}
        {...helpButtonStyle}
      >
        <Info />
      </IconButton>
      <Popover
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        placement="bottom"
        closeOnMouseLeave={!isMobile}
        {...popoverStyle}
      >
        {children}
      </Popover>
    </>
  );
};

const AccordionItemWithLoginWall = withLoginWall(AccordionItem);
