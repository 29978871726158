import { useThemeTokens } from "@alphasights/alphadesign-components";

const useStyles = ({
  obfuscated = false,
  isSearchFocused = false,
  currentSelection = null,
  hasTranscriptSearch = false,
  isCardsSidebarCollapsed = false,
  previousSentenceType = "",
  isContentFlyout = false,
  isCollapsible = false,
} = {}) => {
  const {
    color: { base, border, background, text, icon },
    spacing: { inner, layout },
    typography: { body },
    shape,
    font,
  } = useThemeTokens();

  const obfuscatedStyle = {
    userSelect: "none",
    style: { filter: "blur(2.5px) opacity(0.5)" },
    pointerEvents: "none",
  };

  return {
    view: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      h: "full",
      ...(isCardsSidebarCollapsed && {
        borderRight: `solid ${shape.border.width.sm}`,
        borderColor: border.neutral.default,
      }),
    },
    sidebar: {
      transition: "all 0.5s ease 0s",
      cursor: "default",
      flexDirection: "column",
      bg: background.surface.recessed,
      col: {
        _: 1,
        md: "375px",
        xl: "475px",
      },
      ml: {
        md: isCardsSidebarCollapsed ? `-375px` : null,
        xl: isCardsSidebarCollapsed ? `-475px` : null,
      },
      display: {
        _: currentSelection ? "none" : "flex",
        md: "flex",
      },
      ...(isCardsSidebarCollapsed && {
        h: "full",
        display: "flex",
        borderRight: `solid ${shape.border.width.sm}`,
        borderColor: border.neutral.default,
      }),
      position: "relative",
    },
    sidebarExpandedContent: {
      transition: "all 0.2s ease-in 0s",
      opacity: isCardsSidebarCollapsed ? 0 : 1,
      pointerEvents: isCardsSidebarCollapsed ? "none" : null,
      h: "100%",
      display: "flex",
      flexDirection: "column",
    },
    sidebarCollapsedContent: {
      transition: "all 0.2s ease-in 0s",
      opacity: isCardsSidebarCollapsed ? 1 : 0,
      position: "absolute",
      right: "30px",
      top: "30px",
      zIndex: 1,
      pointerEvents: isCardsSidebarCollapsed ? null : "none",
    },
    searchBar: {
      w: "-webkit-fill-available",
      display: "flex",
      alignItems: "center",
      background: background.input.default,
      border: `${shape.border.width.sm} solid;`,
      borderColor: isSearchFocused ? border.selected : border.neutral.default,
      borderRadius: `${shape.border.radius.small}`,
      justifyContent: "space-between",
      paddingLeft: isCollapsible ? inner.base03 : 0,
      paddingRight: inner.base03,
      ...(isCollapsible && {
        flexShrink: 0,
      }),
    },
    sideSearchWrapper: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      borderRight: `solid ${shape.border.width.sm} ${border.neutral.default}`,
    },
    searchBarDiv: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      gap: "8px",
      px: isCollapsible ? inner.base03 : inner.base04,
      w: "1px",
      h: isCollapsible ? inner.base08 : "44px",
    },
    searchBarDivChip: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      gap: "8px",
      overflow: "auto",
    },
    searchBarTextField: {
      border: "0px",
      padding: 0,
      color: text.strong._,
      background: "transparent",
      outline: "none",
      flexGrow: 1,
      ...body.regular,
    },
    noHoverNoClick: {
      pointerEvents: "none",
      color: {
        hover: null,
      },
    },
    cardsContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "auto",
      w: "full",
      alignItems: "center",
      px: {
        _: 0,
        md: inner.base08,
      },
      pt: {
        _: 0,
        md: inner.base03,
      },
    },
    cardsList: {
      display: "flex",
      flexDirection: "column",
      rowGap: {
        _: 0,
        md: inner.base03,
      },
      px: {
        _: 0,
        md: inner.base06,
      },
      w: "100%",
      alignSelf: "start",
      overflowY: "auto",
      scrollbarWidth: "thin",
      flex: 1,
      pt: inner.base06,
      bg: base.white,
    },
    card: {
      border: `solid ${border.neutral.default}`,
      borderWidth: {
        md: shape.border.width.sm,
        _: `0 0 ${shape.border.width.sm} 0`,
      },
      borderRadius: { md: inner.base02 },
      display: "flex",
      flexDirection: "column",
      rowGap: inner.base,
      padding: inner.base04,
      bg: background.surface.raised,
    },
    cardSelected: {
      borderColor: border.selected,
    },
    cardRow: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      columnGap: inner.base,
      rowGap: inner.base,
    },
    suggestionCardRow: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "clip",
      alignItems: "center",
      columnGap: inner.base,
      rowGap: inner.base,
    },
    cardRowDate: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "visible",
      whiteSpace: "nowrap",
      alignItems: "center",
      columnGap: inner.base,
      rowGap: inner.base,
      h: inner.base05,
    },
    cardBookmarkRow: {
      h: "20px",
    },
    availableContent: {
      ...body.smallEm,
      border: `solid ${shape.border.width.sm}`,
      borderColor: border.neutral.default,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      rowGap: inner.base02,
      gap: "10px",
      borderRadius: inner.base02,
      padding: inner.base04,
      bg: background.surface.raised,
      h: "36px",
    },
    mainContentWrapper: {
      flexGrow: 1,
      overflow: "auto",
      display: {
        _: currentSelection ? "flex" : "none",
        md: "flex",
      },
    },
    mainContent: {
      display: "flex",
      flexDirection: "column",
      bg: background.surface.page.default,
      overflow: "auto",
      flexGrow: 1,
      w: "full",
      pt: inner.base08,
      position: "relative",
      overflowX: "hidden",
    },
    topBar: {
      display: "flex",
      flexDirection: "column",
      borderBottom: `solid ${shape.border.width.sm}`,
      borderColor: border.neutral.default,
      px: inner.base08,
    },
    topBarRow: {
      display: "flex",
      rowGap: inner.base,
      columnGap: inner.base02,
      alignItems: "center",
    },

    topBarTitleRow: {
      display: "flex",
      rowGap: inner.base,
      columnGap: inner.base03,
      alignItems: {
        _: "start",
        md: "center",
      },
      flexDirection: {
        _: "column-reverse",
        md: "row",
      },
    },
    topBarTitleActionsWrapper: {
      display: "flex",
      rowGap: inner.base,
      alignItems: "center",
      alignSelf: "end",
      w: { _: "100%", md: "initial" },
    },
    topBarButton: {
      minH: "32px",
      minW: "20px",
      h: "32px",
      w: "20px",
      mr: layout.base02,
    },
    topBarSearchResultsActions: {
      display: "flex",
      gap: inner.base05,
    },
    topBarSearch: {
      flexGrow: 1,
      pr: inner.base04,
      order: 1,
    },
    topBarSearchResultsRow: {
      display: "flex",
      gap: inner.base03,
      alignItems: "center",
      justifyContent: "center",
      pr: {
        _: 0,
        md: inner.base04,
      },
      order: {
        _: 5,
        md: 2,
      },
    },
    flexBreak: {
      w: "100%",
      h: inner.base04,
      display: {
        _: hasTranscriptSearch ? "block" : "none",
        md: "none",
      },
      order: 3,
    },
    topBarSearchMentionsToggleWrapper: {
      order: {
        _: 4,
        md: 4,
      },
      mr: inner.base04,
      flexGrow: {
        _: 1,
        md: 0,
      },
    },
    topBarToggleActions: {
      display: "flex",
      gap: inner.base04,
      justifyContent: "center",
      order: {
        _: 2,
        md: 5,
      },
    },
    topBarMoreOptionsDropdown: {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline",
      rowGap: inner.base,
    },
    topBarMoreOptionsSeparator: {
      borderBottom: `solid ${shape.border.width.sm}`,
      borderColor: border.neutral.default,
      w: "full",
      my: inner.base,
    },
    topBarMoreOptionsButton: {
      p: inner.base02,
      color: text.strong._,
      h: "36px",
      bg: { hover: background.action.outline.hover },
      w: "full",
      justifyContent: "flex-start",
    },
    topBarPopover: {
      display: "flex",
      flexDirection: "column",
      p: inner.base02,
    },
    topBarFollowUpOption: {
      px: inner.base,
      py: 0,
      h: "36px",
      justifyContent: "flex-start",
      textAlign: "left",
      variant: "ghost",
      color: {
        _: text.strong._,
        hover: text.strong._,
      },
      bg: { hover: background.surface.page.hover },
    },
    topBarDeliverableButton: {
      px: inner.base,
      border: "none",
      color: text.secondary,
      h: "15px",
    },
    topBarSearchRow: {
      display: "flex",
      alignItems: "center",
      py: inner.base08,
      flexWrap: "wrap",
    },
    topBarAudioPlayer: {
      mt: layout.base02,
      mb: layout.base04,
      gap: inner.base02,
      display: "flex",
      alignItems: "center",
    },
    transcriptContentText: {
      overflow: "auto",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      px: inner.base08,
    },
    player: {
      borderTop: `solid ${shape.border.width.sm}`,
      borderColor: border.neutral.default,
      py: inner.base04,
      px: inner.base06,
      ...(obfuscated && obfuscatedStyle),
      mt: "auto",
    },
    sidebarMobile: {
      display: "flex",
      flexDirection: "column",
      bg: background.surface.recessed,
      w: "full",
      alignItems: "center",
    },
    viewMobile: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      h: "full",
      bg: background.surface.page.default,
    },
    iconSecondary: {
      color: icon.secondary,
    },
    textSecondary: {
      color: text.secondary,
    },
    summaryWrapper: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      maxWidth: "600px",
    },
    summaryOverview: {
      mt: inner.base,
      mb: inner.base,
    },
    summarySection: {
      mt: inner.base06,
      mb: inner.base,
    },
    summaryQuestion: {
      ...body.em,
      mt: inner.base05,
      mb: inner.base03,
    },
    summaryPoint: {
      ...body.regular,
    },
    iconButtonStyles: {
      h: inner.base05,
      w: inner.base05,
      fontSize: font.size["06"],
    },
    topBarMoreOptionsDownloadLabel: {
      pt: inner.base02,
      px: inner.base02,
    },
    mentionsCursor: {
      color: {
        _: text.danger,
      },
      ml: "auto",
    },
    mentionsTotal: {
      color: text.assistive,
      mr: "auto",
    },
    mentionsRow: {
      display: "flex",
      alignItems: "center",
    },
    suggestedContentSeparator: {
      borderTop: `${shape.border.width.sm} solid`,
      borderTopColor: border.divider,
      mt: inner.base03,
    },
    hideSuggestedContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: text.secondary,
      mb: inner.base03,
      h: "20px",
    },
    hideSuggestedContentRight: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexGrow: 1,
      h: "20px",
    },
    suggestedContentAlphaLabel: {
      color: text.info,
    },
    hideSeparator: {
      w: "100%",
      mx: inner.base02,
      borderTop: `${shape.border.width.sm} solid`,
      borderTopColor: border.neutral.default,
    },
    primerWrapper: {
      w: "72px",
      h: "72px",
      minWidth: "72px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `${shape.border.width.sm} solid`,
      borderRadius: inner.base,
      borderColor: border.neutral.default,
      backgroundColor: background.surface.page.default,
    },
    primerImg: {
      maxW: inner.base10,
      maxH: inner.base10,
    },
    primerNoLogo: {
      color: icon.secondary,
    },
    primerContent: {
      flexGrow: 1,
      ml: inner.base02,
      flexDirection: "column",
      display: "flex",
      rowGap: inner.base,
    },
    suggestionHeaderSeparator: {
      borderBottomWidth: shape.border.width.sm,
      borderBottomColor: border.divider,
    },
    suggestionHeaderPaddings: {
      mt: inner.base06,
      px: inner.base06,
    },
    suggestionHeaderDisplay: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
    suggestionHeaderTitle: {
      display: "flex",
      flexDirection: "row",
      gap: inner.base02,
      alignItems: "center",
      color: text.strong._,
    },
    suggestionHeaderLogo: {
      maxH: inner.base08,
    },
    suggestionHeaderActions: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: text.secondary,
      spaceX: inner.base02,
    },
    suggestionHeaderSubTitle: {
      display: "flex",
      alignItems: "center",
      color: text.secondary,
      mb: inner.base02,
    },
    suggestionHeaderPurchased: {
      color: text.success,
    },
    suggestionPurchaseBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      py: inner.base04,
      mx: inner.base06,
      borderTop: `solid ${shape.border.width.sm} ${border.neutral.default}`,
      ...(isContentFlyout && {
        background: background.surface.page.default,
        px: inner.base08,
        mx: 0,
      }),
    },
    suggestionSubHeader: {
      color: text.secondary,
    },
    showHideButton: {
      alignSelf: "flex-end",
      p: 0,
      gap: 0,
      h: "20px",
      color: text.secondary,
    },
    primerTopBar: {
      display: "flex",
      flexDirection: "column",
      my: inner.base02,
      rowGap: inner.base05,
    },
    primerFeaturedExperts: {
      display: "flex",
      flexDirection: "column",
      rowGap: inner.base02,
      mb: inner.base02,
    },
    primerDateProduced: {
      display: "flex",
      flexDirection: "column",
      rowGap: inner.base02,
      mb: inner.base04,
    },
    primerWhatIs: {
      display: "flex",
      flexDirection: "column",
      rowGap: inner.base02,
    },
    collapsedSidebarSearchIcon: {
      width: inner.base06,
      height: inner.base06,
      style: { margin: inner.base08 },
      cursor: "pointer",
    },
    purchaseInDeliverablesErrorBanner: {
      display: "flex",
      p: isContentFlyout ? 0 : inner.base06,
      pt: isContentFlyout ? inner.base06 : 0,
      ...(isContentFlyout && {
        background: background.surface.page.default,
      }),
    },
    suggestionCollapsedTranscriptCTAs: {
      pr: inner.base02,
      display: "flex",
    },
    requestExpertsTextArea: {
      ...body.regular,
    },
    transcriptSelect: {
      mr: inner.base04,
      minWidth: "200px",
    },
    fixedTopStyles: {
      display: "flex",
      w: {
        _: "100%",
        md: "600px",
      },
      mx: "auto",
      pt: "32px",
    },
    transcriptMainContent: {
      w: {
        _: "100%",
        md: "600px",
      },
      mx: "auto",
      mt: inner.base08,
      mb: inner.base04,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    scrollToTop: {
      position: "absolute",
      bottom: "80px",
      right: "32px",
      boxShadow: "0px 8px 24px rgba(0, 29, 58, 0.12)",
    },
    summaryVerbatimWrapper: {
      display: "flex",
      flexDirection: "column",
      ...(previousSentenceType &&
        previousSentenceType !== "quote" && {
          marginTop: inner.base03,
        }),
    },
    summaryVerbatim: {
      borderStyle: "solid !important",
      borderColor: border.divider,
      borderLeftWidth: shape.border.width.sm,
      ml: inner.base02,
      pl: inner.base03,
      pt: inner.base,
      pb: inner.base,
    },
    summaryCopyablePoint: {
      listStyleType: "disc",
      marginLeft: inner.base06,
    },
    disabledAiSummarizationWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: inner.base,
    },
    searchSkeleton: {
      mt: inner.base08,
      ml: inner.base08,
      mr: inner.base08,
      mb: inner.base03,
    },
    feedbackButtonsWrapper: {
      display: "flex",
      gap: inner.base02,
      marginTop: inner.base08,
      marginLeft: inner.base12,
    },
    contentView: {
      ...(obfuscated && obfuscatedStyle),
    },
    aiContentWrapper: {
      ...(obfuscated && obfuscatedStyle),
    },
    summaryCitationWrapper: {
      mb: inner.base03,
    },
  };
};

export default useStyles;
