import { Alert, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import PropTypes from "prop-types";

import useBannerData from "./useBannerData";

const AlphaNowBanner = ({ contentType, useCase, onClose, styles = {} }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { bannerVariant, bannerTitle, bannerMessage } = useBannerData(contentType, useCase);

  if (!bannerVariant) {
    return null;
  }

  return (
    <x.div margin="auto" mt={inner.base02} w="fit-content" position="sticky" {...styles}>
      <Alert variant={bannerVariant} onClose={onClose}>
        {Boolean(bannerTitle) && (
          <Typography component="span" variant="body-em">
            {bannerTitle}
          </Typography>
        )}
        {bannerMessage}
      </Alert>
    </x.div>
  );
};

AlphaNowBanner.propTypes = {
  contentType: PropTypes.string,
  useCase: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  styles: PropTypes.object,
};

export default AlphaNowBanner;
