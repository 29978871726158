import { ReactNode } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Divider, Typography } from "@alphasights/alphadesign-components";
import { Citation } from "components/CitationContext/Citation";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import GrowthSymbol from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import CompetitorDynamicsTableHeaderItem from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/CompetitorDynamicsHeader";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { CustomerDynamicsLabels } from "models/primers/constants";
import RevenuePieChart from "./RevenuePieChart";
import styles from "./styles/DynamicSection.module.css";

type CustomerDynamicsSegment = {
  segment: CitableValue<string>;
  revenueSplit: CitableValue<number>;
  penetration: CitableValue<string>;
  trend: CitableValue<string>;
  keyCustomers: CitableValue<string>;
  hasRevenueSplit: boolean;
  hasKeyCustomers: boolean;
  numberOfColumns: number;
};

interface MetricWrapperProps {
  isSensitive?: boolean;
  children: ReactNode;
}

const MetricWrapper = ({ isSensitive = false, children }: MetricWrapperProps) => {
  const { spacing } = useThemeTokens();

  const Container = () => <x.div minH={spacing.inner.base14}>{children}</x.div>;
  if (isSensitive) {
    return (
      <SensitiveDataContainer isSensitive={isSensitive} style={{ minHeight: spacing.inner.base14 }}>
        <Container />
      </SensitiveDataContainer>
    );
  }
  return <Container />;
};

const CustomerDynamicsSectionRow = ({
  segment,
  revenueSplit,
  penetration,
  trend,
  keyCustomers,
  hasRevenueSplit,
  hasKeyCustomers,
  numberOfColumns,
}: CustomerDynamicsSegment) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  if (isMobile) {
    return (
      <>
        <Typography component="div" color={text.strong._} variant="body-large">
          <x.div display="grid" pt={inner.base06} gap={inner.base04} gridTemplateColumns="repeat(2, 1fr)">
            <MetricWrapper>
              <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.SEGMENT} />
              <Typography color={text.strong._} variant="body-em">
                <Citation value={segment} />
              </Typography>
            </MetricWrapper>

            <MetricWrapper>
              <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.PENETRATION} />
              <Citation value={penetration} />
            </MetricWrapper>

            {hasRevenueSplit && (
              <MetricWrapper isSensitive={revenueSplit.isSensitive}>
                <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.REVENUE_SPLIT} />
                <x.div>
                  {revenueSplit.value ? (
                    <Citation
                      value={revenueSplit}
                      renderer={() => <RevenuePieChart value={revenueSplit.value || 0} />}
                    />
                  ) : (
                    "—"
                  )}
                </x.div>
              </MetricWrapper>
            )}

            <MetricWrapper isSensitive={trend.isSensitive}>
              <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.TREND} />

              <Citation value={trend} renderer={({ value: trend }) => <GrowthSymbol {...{ growth: trend }} />} />
            </MetricWrapper>

            {hasKeyCustomers && (
              <MetricWrapper isSensitive={keyCustomers.isSensitive}>
                <CompetitorDynamicsTableHeaderItem label={CustomerDynamicsLabels.KEY_CUSTOMERS} />
                {keyCustomers.value ? <Citation value={keyCustomers} /> : "—"}
              </MetricWrapper>
            )}
          </x.div>
        </Typography>
        <Divider mt={inner.base06} />
      </>
    );
  }

  return (
    <Typography color={text.strong._} variant="body">
      <x.div
        alignItems="center"
        display="grid"
        columnGap="10px"
        className={styles[`segment${numberOfColumns}Columns`]}
        w="100%"
      >
        <SensitiveDataContainer isSensitive={segment.isSensitive}>
          <Typography color={text.strong._} variant="body-em">
            <Citation value={segment} isEnabled={false} />
          </Typography>
        </SensitiveDataContainer>
        {hasRevenueSplit && (
          <SensitiveDataContainer isSensitive={revenueSplit.isSensitive}>
            <Citation value={revenueSplit} renderer={() => <RevenuePieChart value={revenueSplit.value || 0} />} />
          </SensitiveDataContainer>
        )}
        <SensitiveDataContainer isSensitive={penetration.isSensitive}>
          <Citation value={penetration} />
        </SensitiveDataContainer>
        <SensitiveDataContainer isSensitive={trend.isSensitive}>
          <Citation value={trend} renderer={({ value: trend }) => <GrowthSymbol {...{ growth: trend }} />} />
        </SensitiveDataContainer>
        {hasKeyCustomers && (
          <SensitiveDataContainer isSensitive={keyCustomers.isSensitive}>
            <Citation value={keyCustomers} />
          </SensitiveDataContainer>
        )}
      </x.div>
    </Typography>
  );
};

export default CustomerDynamicsSectionRow;
