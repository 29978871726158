import { Logo, LogoVariation, PulseBar } from "@alphasights/alphadesign-components";
import * as S from "./Header.styled";

export const Header = ({ percentage }: { percentage: number }) => (
  <div>
    <S.LogoWrapper>
      <Logo variation={LogoVariation.ColorName} size="xxs" />
    </S.LogoWrapper>
    <S.PulseBarWrapper>
      <PulseBar percentage={percentage} color="linear-gradient(268.84deg, #E8236A 0%, #FD3001 31.25%, #FC8300 100%)" />
    </S.PulseBarWrapper>
  </div>
);
