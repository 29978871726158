import PropTypes from "prop-types";

import useKeywordSearch from "hooks/useKeywordSearch";

import Conversation from "./Conversation";
import PlainTranscript from "./PlainTranscript";

const TranscriptView = ({ conversation, contentType, selectedMentionIndex, speakers, transcript }) => {
  const { addRefToKeyword } = useKeywordSearch(selectedMentionIndex);

  return conversation?.length ? (
    <Conversation
      addRefToMention={addRefToKeyword}
      conversation={conversation}
      speakers={speakers}
      contentType={contentType}
    />
  ) : (
    <PlainTranscript addRefToMention={addRefToKeyword} transcript={transcript} />
  );
};

TranscriptView.defaultProps = {
  selectedMentionIndex: 0,
  speakers: [],
  transcript: {},
};

TranscriptView.propTypes = {
  conversation: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      participant: PropTypes.string,
      speechContent: PropTypes.string,
    })
  ),
  selectedMentionIndex: PropTypes.number.isRequired,
  speakers: PropTypes.array,
  transcript: PropTypes.object,
};

export default TranscriptView;
