import {
  ProjectFeature,
  VendorSwitchingCategory,
  VendorSwitchingTranscriptAnalysis,
} from "@alphasights/portal-api-client";
import { ThirdPartyInteraction } from "types";
import { BarChartQuote, ChartData, SynthesisBarChart } from "../../components";
import { getCompanyNameForQuote, getExpertRoleForQuote } from "../../QuestionModuleContent/helpers";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import tokens from "@alphasights/alphadesign-tokens";
import _ from "lodash";

export const VendorSwitchingChart = ({
  transcriptAnalyses,
  thirdPartyDocuments,
}: {
  transcriptAnalyses: VendorSwitchingTranscriptAnalysis[];
  thirdPartyDocuments: ThirdPartyInteraction[];
}) => {
  const { color, font } = useThemeTokens();
  const { project, isFeatureDisabled } = useCurrentProjectContext();
  const chartData = getChartData(transcriptAnalyses, project!.token, thirdPartyDocuments);

  const isToolbarEnabled = !isFeatureDisabled(ProjectFeature.ProjectSynthesis);

  const averageResponse =
    _(transcriptAnalyses)
      .map("category")
      .filter((c) => validSentiments.includes(c))
      .countBy()
      .toPairs()
      .maxBy(1)?.[0] || null;
  const categories = averageResponse ? [sentimentLabels[averageResponse as VendorSwitchingCategory]] : [];

  const hasNothingToPlot = chartData.every((data) => data.data[0].y === 0) || _.compact(categories).length === 0;
  if (hasNothingToPlot) return null;

  return (
    <SynthesisBarChart
      chartData={chartData}
      categories={categories}
      extraChartProps={{
        id: "vendor-switching-stacked-bar-chart",
        horizontal: true,
        popOverPlacement: "bottom",
        height: 140,
        optimalColumnWidthPercent: 80,
        yAxisTitle: {
          text: "Average Response",
          rotate: 0,
          offsetX: 0,
          offsetY: -20,
          style: {
            color: color.text.secondary,
            fontWeight: font.weight.regular,
          },
        },
        xAxisTitle: {
          text: "Customers",
          rotate: 0,
          offsetX: -75,
          style: {
            color: color.text.secondary,
            fontWeight: font.weight.regular,
          },
        },
      }}
      toolbarEnabled={isToolbarEnabled}
    />
  );
};

const getChartData = (
  transcriptAnalyses: VendorSwitchingTranscriptAnalysis[],
  projectToken: string,
  thirdPartyDocuments: ThirdPartyInteraction[]
): ChartData[] => {
  return validSentiments.map((sentiment) => {
    const sentimentData = transcriptAnalyses.filter((transcript) => transcript.category === sentiment);
    const quotes = sentimentData.flatMap((data) => buildBarChartQuote({ ...data, thirdPartyDocuments, projectToken }));
    return {
      name: sentimentLabels[sentiment],
      color: sentimentColors[sentiment],
      data: [
        {
          y: sentimentData.length ?? 0,
          x: "0",
          color: sentimentColors[sentiment],
          sentiment: sentimentLabels[sentiment],
          thirdPartyDocuments: thirdPartyDocuments,
          quotes: quotes ?? [],
        },
      ],
    };
  });
};

const buildBarChartQuote = ({
  interactionId,
  thirdPartyDocumentId,
  projectToken,
  companyName,
  role,
  thirdPartyDocuments,
}: {
  interactionId?: string;
  thirdPartyDocumentId?: string;
  projectToken: string;
  companyName?: string;
  role?: string;
  thirdPartyDocuments: ThirdPartyInteraction[];
}): BarChartQuote => {
  const navToLink = interactionId
    ? `/${projectToken}/experts/deliverables-view/?transcriptType=Transcript&selectedInteraction=${interactionId}`
    : thirdPartyDocumentId
    ? `/${projectToken}/experts/deliverables-view/?selectedUploadedDocument=${thirdPartyDocumentId}`
    : "";

  const document = thirdPartyDocumentId
    ? thirdPartyDocuments.find((tpd) => tpd.id === thirdPartyDocumentId)
    : undefined;
  const label = `${getCompanyNameForQuote({ thirdPartyDocumentId, companyName }, document)} - ${getExpertRoleForQuote(
    { thirdPartyDocumentId, role },
    document
  )}`;

  return {
    label,
    navToLink,
  };
};

const sentimentColors = {
  [VendorSwitchingCategory.EASY]: tokens.color.chart.categorical.base03,
  [VendorSwitchingCategory.NEUTRAL]: tokens.color.chart.categorical.base05,
  [VendorSwitchingCategory.DIFFICULT]: tokens.color.chart.categorical.base04,
};

const sentimentLabels = {
  [VendorSwitchingCategory.EASY]: "Easy",
  [VendorSwitchingCategory.NEUTRAL]: "Neutral",
  [VendorSwitchingCategory.DIFFICULT]: "Difficult",
};

const validSentiments = [
  VendorSwitchingCategory.EASY,
  VendorSwitchingCategory.NEUTRAL,
  VendorSwitchingCategory.DIFFICULT,
];
