import { FC, useState } from "react";
import * as React from "react";
import { CardContainer, CardStackContainer, HeaderContainer, styledStackedCard } from "./CardStack.styled";
import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

const DataTestIds = {
  cardStack: "card-stack",
};

interface CardStackProps {
  children: React.ReactNode[];

  title?: string;
  numCollapsedCards?: number;
}

export const hideCardsLabel = "Hide";
export const displayCardsLabel = "See all";

const CardStack: FC<CardStackProps> = ({ title, numCollapsedCards = 3, children }) => {
  const [expanded, setExpanded] = useState(false);
  const { color, font } = useThemeTokens();

  const numCards = children.length;
  const numDisplayedCards = expanded || numCards < numCollapsedCards ? children.length : numCollapsedCards;

  return (
    <CardContainer data-testId={DataTestIds.cardStack}>
      <HeaderContainer hasMultipleCards={numCards > 1}>
        <Typography color={color.text.secondary} variant="body-em">
          {title}
        </Typography>
        <Button
          variant="ghost"
          endIcon={expanded ? <ChevronUp /> : <ChevronDown />}
          onClick={() => setExpanded(!expanded)}
          size="small"
          color={color.text.secondary}
          style={{ fontWeight: font.weight.regular }}
        >
          {expanded ? hideCardsLabel : displayCardsLabel}
        </Button>
      </HeaderContainer>
      <CardStackContainer>
        {children.slice(0, numDisplayedCards).map((card, index) => (
          <x.div
            key={index}
            style={styledStackedCard(index, expanded, numDisplayedCards)}
            onClick={() => setExpanded(true)}
          >
            {card}
          </x.div>
        ))}
      </CardStackContainer>
    </CardContainer>
  );
};

export { CardStack as default, DataTestIds };
