import { Dispatch, FC, SetStateAction } from "react";
import { Alert, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { dismissContent } from "content/AlphaNow";
import { COLLAPSED_LEFT_SIDEBAR_WIDTH, EXPERTS_SIDEBAR_WIDTH, SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";

interface AlphaNowSuccessfulExpertRequestAlertProps {
  isSidebarExpanded: boolean;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  successMessage: string;
}

const AlphaNowSuccessfulExpertRequestAlert: FC<AlphaNowSuccessfulExpertRequestAlertProps> = ({
  isSidebarExpanded,
  setSuccessMessage,
  successMessage,
}) => {
  const { spacing, color } = useThemeTokens();
  return (
    <x.div
      position="fixed"
      bottom={spacing.inner.base08}
      zIndex={10}
      left={
        isSidebarExpanded
          ? "calc(2 / 3 * 100%)"
          : `calc(${COLLAPSED_LEFT_SIDEBAR_WIDTH}px + ((100% - ${
              EXPERTS_SIDEBAR_WIDTH + COLLAPSED_LEFT_SIDEBAR_WIDTH
            }px) / 2))`
      }
      transition={`left ${SIDEBAR_ANIMATION_SPEED}`}
      transform="translate(-50%, 0%)"
    >
      <Alert variant="info">
        <Typography component="span" variant="body-em">
          Info:{" "}
        </Typography>
        {successMessage}
        <x.span onClick={() => setSuccessMessage("")}>
          <Typography color={color.text.info} component="span" cursor="pointer" variant="body-em">
            {" "}
            {dismissContent}
          </Typography>
        </x.span>
      </Alert>
    </x.div>
  );
};

export default AlphaNowSuccessfulExpertRequestAlert;
