import styled from "styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

export const TranscriptHeaderContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBlock: spacing.inner.base03,
    paddingInline: spacing.inner.base08,
    height: spacing.layout.base08,
  };
});

export const StyledTypography = styled(Typography).attrs({
  variant: "body-large-em",
})`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
