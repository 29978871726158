import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useCookiePolicy, useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

import { currentProjectView } from "helpers/currentView";
import useQueryParams from "hooks/useQueryParams";
import { Badge } from "models/Badge";
import deliverablesService from "views/DeliverablesView/deliverablesService";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { useLocation } from "router-utils";
import { PARAM_CONTENT_SELECTED } from "views/DeliverablesView/DeliverablesView";
import { usePrivateLibrary } from "hooks/usePrivateLibrary";

export const DeliverableContext = createContext();

export const DeliverableProvider = ({ project, service = deliverablesService, ...props }) => {
  const { logHit } = useTrackUserAction();
  const { privateLibraryItems } = usePrivateLibrary();
  const [bookmarkedInteractionIds, setBookmarkedInteractionIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasInteractionDeliverables, setHasInteractionDeliverables] = useState(false);
  const [hasOtherDeliverables, setHasOtherDeliverables] = useState(false);
  const { pathname } = useLocation();
  const queryParams = useQueryParams();
  const isDeliverablesView = currentProjectView(pathname) === "deliverables-view";

  const projectToken = project?.token;
  const currentUser = useCurrentUser();
  const { isPolicyAccepted } = useCookiePolicy();
  const { hasUserBadge } = useUserBadgeContext();

  const projectAccessible = project && currentUser && (project.active || currentUser.pastProjectsEnabled);
  const hasThirdPartyDocumentsPermissions =
    hasUserBadge(Badge.thirdPartyDocUpload) && currentUser?.documentUploadsEnabled;
  const canFetch = projectAccessible && isPolicyAccepted && !!currentUser;
  const hasDeliverables = hasInteractionDeliverables || hasOtherDeliverables;

  useEffect(
    function checkDeliverablesExist() {
      if (!projectToken) return;
      if (!canFetch) {
        setIsLoading(false);
        setHasInteractionDeliverables(false);
        setHasOtherDeliverables(false);
        return;
      }
      service
        .getInteractionsWithDeliverables(projectToken)
        .then((res) => {
          if (res?.edges?.length > 0) {
            setHasInteractionDeliverables(true);
          } else if (currentUser) {
            const param = { project: { token: projectToken } };
            const interactionPromises = [
              service.fetchAlphaNowContent(param),
              hasThirdPartyDocumentsPermissions ? service.fetchDocuments(projectToken) : Promise.resolve([]),
              Promise.resolve(privateLibraryItems),
            ];
            return Promise.allSettled(interactionPromises).then((settledPromises) => {
              const alphaNowContent = settledPromises[0]?.value || [];
              const thirdPartyDocuments = settledPromises[1]?.value || [];
              const privateLibraryInteractions = settledPromises[2]?.value || [];
              setHasOtherDeliverables(
                (previousValue) =>
                  previousValue ||
                  !!alphaNowContent.suggestedContent?.length ||
                  !!alphaNowContent.privateContent?.length ||
                  !!alphaNowContent.pitchedContent?.length ||
                  !!thirdPartyDocuments?.length ||
                  !!privateLibraryInteractions?.length
              );
            });
          }
        })
        .finally(() => setIsLoading(false));
    },
    [projectToken, currentUser, service, canFetch, hasThirdPartyDocumentsPermissions, privateLibraryItems]
  );

  useEffect(
    function makeDeliverablesAvailableWhenFetchingContent() {
      const contentId = queryParams.get(PARAM_CONTENT_SELECTED);
      if (isDeliverablesView && contentId) {
        setHasOtherDeliverables(true);
      }
    },
    [isDeliverablesView, queryParams]
  );

  useEffect(
    function fetchDeliverableBookmark() {
      if (canFetch) {
        service.getBookmarks(project).then((ids) => setBookmarkedInteractionIds(ids));
      }
    },
    [service, project, canFetch]
  );

  const toggleInteractionBookmark = useCallback(
    (interaction) => {
      const { id, bookmarked, projectToken } = interaction;
      const toggleTo = !bookmarked;
      interaction.bookmarked = !interaction.bookmarked;
      service.setBookmark({ project, id, bookmarked: toggleTo }).then((bookmarked) => {
        const newBookmarkedIds = bookmarked
          ? [id, ...bookmarkedInteractionIds]
          : bookmarkedInteractionIds.filter((it) => it !== id);
        setBookmarkedInteractionIds(newBookmarkedIds);
      });

      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.transcriptBookmark,
        advisorshipId: id,
        projectToken,
        details: {
          toggleTo,
        },
      });
    },
    [service, project, bookmarkedInteractionIds, logHit]
  );

  const fetchDeliverable = useCallback(
    (transcriptRequest, keywords) => {
      return service.fetchDeliverable({
        project,
        transcriptRequest,
        keywords,
      });
    },
    [service, project]
  );

  const fetchAlphaNowContent = useCallback(
    (keywords) => {
      return service.fetchAlphaNowContent({
        project,
        keywords,
      });
    },
    [service, project]
  );

  const fetchAlphaNowContentById = useCallback(
    (params) => {
      return service.fetchAlphaNowContentById({
        project,
        ...params,
      });
    },
    [service, project]
  );

  const fetchContentById = useCallback(
    (id) => {
      return service.fetchContentById(id, project?.token);
    },
    [service, project]
  );

  const fetchContentCredits = useCallback(
    (contentId) => {
      return service.fetchContentCredits(project?.token, contentId);
    },
    [service, project]
  );

  const getAiSummaries = useCallback(
    (interactionId, recordingId) => {
      return service.getAiSummaries(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const getAiSummariesStatus = useCallback(
    (interactionId, recordingId) => {
      return service.getAiSummariesStatus(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const generateAiSummary = useCallback(
    (interactionId, recordingId) => {
      return service.generateAiSummary(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const deleteAiSummaries = useCallback(
    (interactionId, recordingId) => {
      return service.deleteAiSummaries(project?.token, interactionId, recordingId);
    },
    [service, project]
  );

  const submitAiSummaryFeedback = useCallback(
    (interactionId, recordingId, transcriptId, summaryType, feedbackType) => {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.aiSummaryGiveFeedback,
        projectToken: project?.token,
        advisorshipId: interactionId,
        details: {
          aiSummaryType: summaryType,
          feedbackType: feedbackType,
        },
        references: { transcriptId: transcriptId },
      });

      return service.submitAiSummaryFeedback(project?.token, interactionId, recordingId, summaryType, feedbackType);
    },
    [service, project, logHit]
  );

  const removeAiSummaryFeedback = useCallback(
    (interactionId, recordingId, transcriptId, summaryType) => {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.aiSummaryGiveFeedback,
        projectToken: project?.token,
        advisorshipId: interactionId,
        details: {
          aiSummaryType: summaryType,
          feedbackType: "neutral",
        },
        references: { transcriptId: transcriptId },
      });

      return service.removeAiSummaryFeedback(project?.token, interactionId, recordingId, summaryType);
    },
    [service, project, logHit]
  );

  const createContentVisit = useCallback(
    ({ recommendationId, contentId }) => {
      return service.createContentVisit({ recommendationId, contentId, projectToken });
    },
    [service, projectToken]
  );

  const fetchDocumentUploadUrl = useCallback(
    (fileName) => {
      return service.fetchDocumentUploadUrl(projectToken, fileName);
    },
    [service, projectToken]
  );

  const uploadDocumentToS3 = useCallback(
    (url, file) => {
      return service.uploadDocumentToS3(url, file);
    },
    [service]
  );

  const createDocument = useCallback(
    (id) => {
      return service.createDocument(projectToken, id);
    },
    [service, projectToken]
  );

  const deleteDocument = useCallback(
    (documentId) => {
      return service.deleteDocument(projectToken, documentId);
    },
    [service, projectToken]
  );

  const updateDocumentAttributes = useCallback(
    (documentId, attributes) => {
      return service.updateDocumentAttributes(projectToken, documentId, attributes);
    },
    [service, projectToken]
  );

  const addProjectIdToDocument = useCallback(
    (documentId) => {
      return service.addProjectIdToDocument(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchDocument = useCallback(
    (documentId) => {
      return service.fetchDocument(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchDocuments = useCallback(() => {
    return service.fetchDocuments(projectToken);
  }, [service, projectToken]);

  const fetchDocumentSummary = useCallback(
    (documentId) => {
      return service.fetchDocumentSummary(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchDocumentSummaryStatus = useCallback(
    (documentId) => {
      return service.fetchDocumentSummaryStatus(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchDocumentQuestions = useCallback(
    (documentId) => {
      return service.fetchDocumentQuestions(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchDocumentQuestionStatus = useCallback(
    (documentId) => {
      return service.fetchDocumentQuestionStatus(projectToken, documentId);
    },
    [service, projectToken]
  );

  const submitDocumentQuestion = useCallback(
    (documentId, question) => {
      return service.submitDocumentQuestion(projectToken, documentId, question);
    },
    [service, projectToken]
  );

  const fetchFile = useCallback(
    (documentId) => {
      return service.fetchFile(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchFileAsPdf = useCallback(
    (documentId) => {
      return service.fetchFileAsPdf(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchDocumentJson = useCallback(
    (documentId) => {
      return service.fetchDocumentJson(projectToken, documentId);
    },
    [service, projectToken]
  );

  const fetchUnassignedEmailUploads = useCallback(() => {
    return service.fetchUnassignedEmailUploads(projectToken);
  }, [service, projectToken]);

  const context = {
    project,
    bookmarkedInteractionIds,
    toggleInteractionBookmark,
    fetchDeliverable,
    fetchAlphaNowContent,
    fetchAlphaNowContentById,
    fetchContentById,
    hasDeliverables,
    hasInteractionDeliverables,
    isLoading,
    fetchContentCredits,
    getAiSummaries,
    getAiSummariesStatus,
    generateAiSummary,
    deleteAiSummaries,
    submitAiSummaryFeedback,
    removeAiSummaryFeedback,
    createContentVisit,
    fetchDocumentUploadUrl,
    uploadDocumentToS3,
    createDocument,
    deleteDocument,
    updateDocumentAttributes,
    addProjectIdToDocument,
    fetchDocument,
    fetchDocuments,
    fetchDocumentSummary,
    fetchDocumentQuestions,
    fetchFile,
    fetchFileAsPdf,
    submitDocumentQuestion,
    fetchDocumentJson,
    fetchUnassignedEmailUploads,
    fetchDocumentSummaryStatus,
    fetchDocumentQuestionStatus,
  };

  return <DeliverableContext.Provider value={context} {...props} />;
};

export const useDeliverableContext = () => useContext(DeliverableContext);
