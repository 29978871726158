const useGetCompanyProperties = (
  companies: { id: string; name: string; logoLink?: string }[],
  companiesV2: {
    name: string;
    logoLink?: string | undefined;
  }[]
) => {
  return {
    getCompanyProperties: (companyIdOrName: string | number | undefined) => {
      if (!companyIdOrName)
        return {
          name: "",
          logoLink: "",
        };

      const company = companies.find(({ id }) => id === companyIdOrName.toString());

      if (!company) {
        const companyV2 = companiesV2.find(({ name }) => name === companyIdOrName.toString());

        return {
          name: companyV2 ? companyV2.name : "",
          logoLink: companyV2 && companyV2.logoLink ? companyV2.logoLink : "",
        };
      }

      return {
        name: company ? company.name : "",
        logoLink: company && company.logoLink ? company.logoLink : "",
      };
    },
  };
};
export default useGetCompanyProperties;
