import { useThemeTokens } from "@alphasights/alphadesign-components";
import { css } from "@xstyled/styled-components";

export const useMessengerThreadCardStyles = () => {
  const {
    color: { text, icon },
    spacing: { inner },
  } = useThemeTokens();

  const dateLabel = {
    color: text.secondary,
  };

  const expertCompanyLabel = {
    color: text.strong._,
  };

  const expertPositionLabel = {
    color: text.strong._,
  };

  const sendersLabel = {
    color: text.strong._,
  };

  const messagePreviewLabel = {
    color: text.secondary,
    marginTop: inner.base,
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    lineClamp: "2",
    WebkitLineClamp: 2,
    boxOrient: "vertical",
    WebkitBoxOrient: "vertical" as any,
  };

  const unreadMark = {
    display: "inline-flex",
    w: inner.base02,
    h: inner.base02,
    borderRadius: inner.base02,
    backgroundColor: icon.info,
    marginRight: inner.base02,
  };

  const topContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const contentContainer = {
    display: "flex",
    whiteSpace: "nowrap",
    marginTop: inner.base02,
    alignItems: "baseline",
  };

  const expertInfoContainer = {
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const markdownStyles = css`
    padding: 0;

    ul,
    ol {
      margin: 0;
    }

    code {
      padding: 0 ${inner.base};
      margin: 0;
    }
  `;

  return {
    dateLabel,
    expertCompanyLabel,
    expertPositionLabel,
    sendersLabel,
    messagePreviewLabel,
    unreadMark,
    topContainer,
    contentContainer,
    expertInfoContainer,
    markdownStyles,
  };
};
