import { useMemo } from "react";
import { ContentTypeToAccessLevel } from "./useContentAccessLevel";
import { hasAnyPrimer } from "content/AlphaNow/utils";

export const usePrimerData = (contentTypeMapping: ContentTypeToAccessLevel[]) => {
  return useMemo(() => {
    const hasPrimers = hasAnyPrimer(contentTypeMapping);

    if (!hasPrimers || !contentTypeMapping) {
      return { primerTypes: [] };
    }

    const primerTypes = contentTypeMapping
      .filter(({ contentType }) => contentType.toLowerCase().includes("primer"))
      .map(({ contentType }) => contentType.toLowerCase());

    return { primerTypes };
  }, [contentTypeMapping]);
};

export default usePrimerData;
