import { useThemeTokens } from "@alphasights/alphadesign-components";
import { css } from "@xstyled/styled-components";

export const useMobileMessageCardStyles = () => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  return {
    messageWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: inner.base04,
      gap: inner.base03,
      borderColor: color.border.neutral.default,
      borderBottomWidth: "1px",
      bg: color.background.surface.page.default,
    },
    companyAndRole: {
      w: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    timeWrapper: {
      top: "0px",
      right: "0px",
      float: "right",
    },
    senderWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    messageQuestionContextWrapper: {
      display: "flex",
      flexDirection: "column",
      bg: color.background.surface.page.default,
    },
    messagePreviewLabel: {
      color: color.text.secondary,
      marginTop: inner.base,
      overflow: "hidden",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      lineClamp: "2",
      WebkitLineClamp: 2,
      boxOrient: "vertical",
      WebkitBoxOrient: "vertical" as any,
    },
    markdownStyles: css`
      padding: 0;
    `,
  };
};
