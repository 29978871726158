import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { LabeledIcon, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import ChartKPCGradeExplanation from "./ChartKPCGradeExplanation";
import CompetitivePerformanceKPCsTabs from "./CompetitivePerformanceKPCsTabs";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";
import { useCustomerPrimersStore, useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { labelsToMultiline } from "pages/AlphaNowPage/primers/utils/charts";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { SIDEBAR_ANIMATION_SPEED } from "constants/AlphaNow";
import CompetitivePerformanceXAxis from "./chart/CompetitivePerformanceXAxis";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

const yAxisCategories = ["", "Very Weak", "Somewhat Weak", "Neutral", "Somewhat Strong", "Very Strong"];

const CompetitivePerformanceKPCs = () => {
  const sectionName = "COMPETITIVE_PERFORMANCE_KPCS";
  const [chartKey, setChartKey] = useState<number>(0);
  const { color, spacing, typography } = useThemeTokens();
  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const isSidebarExpanded = useCustomerPrimersStore(({ isSidebarExpanded }) => isSidebarExpanded);
  const keyPurchasingCriteria = useCustomerPrimersStore(({ keyPurchasingCriteria }) => keyPurchasingCriteria);
  const { vendors } = keyPurchasingCriteria;
  const setExpertsMentionedSectionAndExpertsIds = useMentionedExpertsStore(
    ({ setExpertsMentionedSectionAndExpertsIds }) => setExpertsMentionedSectionAndExpertsIds
  );
  const { getChartColors } = useGetChartColors({
    numberOfBars: vendors.length,
  });
  const [selectedChartSegment, setSelectedChartSegment] = useState<number | null>(0);

  const series = useMemo(() => {
    return vendors.map(({ companyName, importances }) => ({
      name: companyName,
      data: importances.map(({ values }) => {
        const importancesValues = values.map(({ value }) => value ?? -1).filter((value) => value >= 0);

        // each point in the chart is  the average value of the importances voted for this KPC on this company
        return importancesValues.reduce((a, b) => a + b, 0) / importancesValues.length;
      }),
    }));
  }, [vendors]);

  // this returns the KPCs as arrays of strings to make use of multiline labels
  const getKPCsNames = useMemo(
    () => () => labelsToMultiline(vendors.length > 0 ? vendors[0].importances.map(({ kpcName }) => kpcName) : [], 15),
    [vendors]
  );

  useEffect(() => {
    // we need this because, due to component memoization, the chart was
    // not resizing when the search bar collapsed at first load.
    //
    // to solve this we get the expanded status of the search bar and
    // when it changes we enforce a new key to the chart. We use a setTimeout
    // to defer this change to when the animation finishes + half a second,
    // to ensure the size is correctly calculated
    let timer = setTimeout(
      () => setChartKey((currentState) => currentState + 1),
      parseFloat(SIDEBAR_ANIMATION_SPEED.substring(0, SIDEBAR_ANIMATION_SPEED.length - 1)) * 1000 + 500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [isSidebarExpanded]);

  const options = {
    chart: {
      id: "KPCCompetitivePerformance",
      toolbar: {
        show: false,
      },
      width: "100%",
      events: {
        markerClick: function (
          event: any,
          chartContext: any,
          { seriesIndex, dataPointIndex, config }: { seriesIndex: any; dataPointIndex: any; config: any }
        ) {
          setSelectedChartSegment(seriesIndex);

          const speakersIds = vendors[seriesIndex].kpcs
            .map((kpc) => kpc.expertsResponses)
            .flat()
            .map(({ response }) => response.citedBy)
            .flat();

          setExpertsMentionedSectionAndExpertsIds(sectionName, speakersIds);
        },
      },
      fontFamily: typography.body.regular.fontFamily,
      zoom: {
        enabled: false,
      },
      parentHeightOffset: 0,
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      min: 1,
      max: 5,
      forceNiceScale: false,
      labels: {
        style: {
          colors: [`${color.text.strong._}`],
          ...typography.body.small,
          fontSize: "12px",
        },
        align: "left",
        formatter: (value: any) => {
          return yAxisCategories[value];
        },
      },
      axisBorder: {
        show: false,
      },
      tickAmount: 4,
    },
    colors: getChartColors(selectedChartSegment),
    markers: {
      size: 5,
      strokeWidth: 0,
      hover: {
        sizeOffset: 0,
      },
    },
    grid: {
      show: true,
      padding: {
        left: 30,
        right: 30,
      },
    },
    legend: {
      show: false,
    },
    // this needs to be here, otherwise we cannot get seriesIndex in the onClick
    tooltip: {
      custom: function (option: any) {
        return "";
      },
    },
  };

  const onHighlightChartSegment = (index: number): void => {
    setSelectedChartSegment(index);
    ApexCharts.exec("KPCCompetitivePerformance", "toggleDataPointSelection", index);
  };

  return (
    <>
      <PrimerFieldTitle text="COMPETITIVE PERFORMANCE ON KPCs" transform="none" mt={spacing.inner.base05} />
      <LabeledIcon
        labelText="Customer decision makers were asked to rate vendor performance against KPCs, from very strong to very weak. Click a line to see a vendor's performance."
        iconItem={<Info />}
        mt={spacing.inner.base}
        labelColor={color.text.assistive}
        iconColor={color.text.assistive}
      />
      <SensitiveDataContainer isSensitive={!isAccessible}>
        <div>
          <Chart key={chartKey} options={options as ApexCharts.ApexOptions} series={series} type="line" height={300} />
          <CompetitivePerformanceXAxis kpcs={getKPCsNames()} />
          <ChartKPCGradeExplanation />
          <CompetitivePerformanceKPCsTabs
            chartColors={getChartColors(null)}
            selectedChartSegment={selectedChartSegment}
            onClick={onHighlightChartSegment}
          />
        </div>
      </SensitiveDataContainer>
    </>
  );
};

export default CompetitivePerformanceKPCs;
