import { useMemo } from "react";
import { Alert, Link, Typography } from "@alphasights/alphadesign-components";
import { AccessControl } from "components/AccessControl/AccessControl";
import { CidStatus } from "components/CidBadge/cid-status";
import { useOneOffApprovalPanelStyles } from "./OneOffApprovalPanel.styles";

export const OneOffApprovalPanel = ({ currentJobs, findJobCidStatus, persistOneOffApproval }) => {
  const approvedJob = currentJobs.some((job) => findJobCidStatus(job.id) === CidStatus.APPROVED);

  return (
    <>
      {approvedJob ? (
        <AmendOneOffApproval persistOneOffApproval={persistOneOffApproval}></AmendOneOffApproval>
      ) : (
        <OneOffApproval
          currentJobs={currentJobs}
          findJobCidStatus={findJobCidStatus}
          persistOneOffApproval={persistOneOffApproval}
        ></OneOffApproval>
      )}
    </>
  );
};

const OneOffApproval = ({ currentJobs, findJobCidStatus, persistOneOffApproval }) => {
  const { oneOffAlert } = useOneOffApprovalPanelStyles();

  const unactionedJob = useMemo(() => currentJobs.some((job) => !findJobCidStatus(job.id)), [
    currentJobs,
    findJobCidStatus,
  ]);
  return (
    <Alert variant="warning" {...oneOffAlert}>
      {`To interact with this expert, please confirm one-off approval. `}
      <Link
        component="button"
        onClick={persistOneOffApproval}
        disabled={unactionedJob}
        data-tip={
          unactionedJob
            ? "Please complete all CID checks in the career history in order one-off approve this expert."
            : ""
        }
      >
        <Typography variant="body-em">One-Off Approve</Typography>
      </Link>
    </Alert>
  );
};

const AmendOneOffApproval = ({ persistOneOffApproval }) => {
  const { amendAlert } = useOneOffApprovalPanelStyles();

  return (
    <Alert variant="warning" {...amendAlert}>
      {`You have one-off approved one or more of the advisor's current companies. `}
      <AccessControl allowedPermissions={["cid"]}>
        <Link component="button" onClick={persistOneOffApproval}>
          <Typography variant="body-em">Amend</Typography>
        </Link>
      </AccessControl>
    </Alert>
  );
};
