import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Button, Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Phone, Mail } from "@alphasights/alphadesign-icons";

interface CentralizedBoxProps {
  children: React.ReactNode;
  flexDirection?: "row" | "column";
  marginTop?: string;
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  marginX?: string | { [key: string]: string };
  justifyContent?: string | { [key: string]: string };
}

const CentralizedBox = ({
  children,
  flexDirection = "row",
  marginTop = "0",
  flexWrap = "nowrap",
  marginX = "0",
  justifyContent = "center",
}: CentralizedBoxProps) => (
  <x.div
    display="flex"
    justifyContent={justifyContent}
    flexDirection={flexDirection}
    flexWrap={flexWrap}
    marginTop={marginTop}
    mx={marginX}
  >
    {children}
  </x.div>
);

interface InformationSectionProps {
  children: React.ReactNode;
  marginBottom?: string | { [key: string]: string };
  marginLeft?: string | { [key: string]: string };
}

const InformationSection = ({ children, marginBottom = "0", marginLeft = "0" }: InformationSectionProps) => {
  const { spacing } = useThemeTokens();
  return (
    <x.div
      w={{
        _: "83.33%",
        md: "60%",
        lg: "33.33%",
      }}
      textAlign="left"
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    >
      <x.div w={spacing.layout.base08} margin="1.25rem auto" />
      {children}
    </x.div>
  );
};

interface ContactDetailProps {
  IconComponent: React.ComponentType;
  children: React.ReactNode;
  email?: string;
  emailLinkText?: object;
}

const ContactDetail: React.FC<ContactDetailProps> = ({ IconComponent, children, email, emailLinkText }) => {
  const { color, spacing } = useThemeTokens();

  return (
    <x.div display="flex" alignItems="center" my={spacing.layout.base}>
      <Icon color={color.icon.secondary}>
        <IconComponent />
      </Icon>
      {email ? (
        <a
          href={`mailto:${email}`}
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Typography marginLeft={spacing.layout.base02} {...emailLinkText}>
            {children}
          </Typography>
        </a>
      ) : (
        <Typography marginLeft={spacing.layout.base02}>{children}</Typography>
      )}
    </x.div>
  );
};

interface LargeTextProps {
  children: React.ReactNode;
}

const LargeText = ({ children }: LargeTextProps) => <x.div fontSize="155px">{children}</x.div>;

const UnauthorizedBlock = () => {
  const { spacing } = useThemeTokens();
  return (
    <CentralizedBox justifyContent="center" marginTop={spacing.layout.base08}>
      <x.div display="flex" justifyContent="around" mx={spacing.layout.base08} marginBottom={spacing.layout.base04}>
        <LargeText>403</LargeText>
      </x.div>
    </CentralizedBox>
  );
};

export const UnauthorizedPage: React.FC = () => {
  const { color, spacing } = useThemeTokens();

  return (
    <>
      <CentralizedBox
        data-testid="unauthorized-page-container"
        flexDirection="column"
        marginTop={spacing.layout.base02}
      >
        <UnauthorizedBlock />
        <Typography color={color.text.danger} variant="h1" style={{ textAlign: "center" }}>
          Sorry, you are not allowed to access this resource.
        </Typography>
        <CentralizedBox
          data-testid="information-sections-container"
          flexWrap="wrap"
          marginX={{ md: "5rem", lg: "8.5rem" }}
          justifyContent={{ _: "center", lg: "space-evenly" }}
        >
          <InformationSection data-testid="information-section-1" marginBottom={spacing.layout.base07}>
            <Typography>AlphaSights connects the world's top professionals with the world's best knowledge.</Typography>
            <Button
              marginTop={spacing.layout.base02}
              variant="secondary"
              onClick={() => window.open("https://www.alphasights.com", "_self")}
            >
              Learn More
            </Button>
          </InformationSection>
          <InformationSection
            data-testid="information-section-2"
            marginLeft={{ _: "0", md: spacing.layout.base05 }}
            marginBottom={spacing.layout.base10}
          >
            <Typography>
              Can’t find what you’re looking for? <br />
              You can contact us using any of the following methods and we will do our best to get the information you
              are looking for.
            </Typography>
            <ContactDetail IconComponent={Phone}> +44 20 7399 9750</ContactDetail>
            <ContactDetail IconComponent={Mail} email="clientsupport@alphasights.com">
              clientsupport@alphasights.com
            </ContactDetail>
          </InformationSection>
        </CentralizedBox>
      </CentralizedBox>
    </>
  );
};
