import { fetch } from "hooks/useApi";

const projectSummariesService = {
  getProjectSummariesStatus: (projectToken: string) => {
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/status`,
      handleForbidden: true,
      skipAlert: false,
      method: "GET",
    }).then((res) => res.json());
  },
  generateProjectSummaries: (projectToken: string) => {
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries`,
      handleForbidden: true,
      skipAlert: false,
      method: "POST",
    }).then((res) => res.json());
  },
  getProjectSummary: (projectToken: string, projectSummaryId: string) => {
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/${projectSummaryId}`,
      handleForbidden: true,
      skipAlert: false,
      method: "GET",
    }).then((res) => res.json());
  },
  generateProjectSummariesByAngle: (projectToken: string, angleId?: string) => {
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/angles/${angleId}`,
      handleForbidden: true,
      skipAlert: false,
      method: "POST",
    }).then((res) => res.json());
  },
  generateProjectWideSummary: (projectToken: string) => {
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/project-wide`,
      handleForbidden: true,
      skipAlert: false,
      method: "POST",
    }).then((res) => res.json());
  },
  regenerateOverview: (projectToken: string, projectSummaryId: string, themeTitle?: string) => {
    const searchParams = new URLSearchParams();
    themeTitle && searchParams.set("themeTitle", themeTitle);
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/${projectSummaryId}/regenerate-overview?${searchParams.toString()}`,
      handleForbidden: true,
      skipAlert: false,
      method: "POST",
    }).then((res) => res.json());
  },
  deleteProjectSummary: (projectToken: string, projectSummaryId: string) => {
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/${projectSummaryId}`,
      handleForbidden: true,
      skipAlert: false,
      method: "DELETE",
    });
  },
  updateCustomThemes: (projectToken: string, payload: any, summaryId?: string, angleId?: string) => {
    const searchParams = new URLSearchParams();
    angleId && searchParams.set("angleId", angleId);

    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/${summaryId}/themes?${searchParams.toString()}`,
      method: "POST",
      body: JSON.stringify(payload),
      skipAlert: false,
      handleForbidden: true,
    }).then((res) => res.json());
  },
  getValidInteractionsByAngles: (projectToken: string) => {
    return fetch({
      url: `/api/projects/${projectToken}/project-summaries/valid-interactions-by-angles`,
      handleForbidden: true,
      skipAlert: false,
      method: "GET",
    }).then((res) => res.json());
  },
  downloadSummaryUrl: (projectToken: string, id: string) => {
    return `/api/projects/${projectToken}/project-summaries/${id}/export/pdf`;
  },
};
export default projectSummariesService;
