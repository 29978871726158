import { useCallback } from "react";
import { produce } from "immer";
import { SynthesisModule, VendorSwitchingItem } from "@alphasights/portal-api-client";
import { vendorSwitching } from "../synthesisTypeGuards";

export const useEditVendorSwitchingOperations = ({ originalModule }: { originalModule?: SynthesisModule }) => {
  const updateVendorSwitchingItem = useCallback(
    (newItem: VendorSwitchingItem, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = vendorSwitching(draft.revisions[revisionIdx].contents);

        const driversIndex = content.drivers.findIndex((item) => item.id === newItem.id);
        if (driversIndex !== -1) {
          content.drivers[driversIndex] = newItem;
        }

        const barriersIndex = content.barriers.findIndex((item) => item.id === newItem.id);
        if (barriersIndex !== -1) {
          content.barriers[barriersIndex] = newItem;
        }
      });
    },
    [originalModule]
  );

  const deleteVendorSwitchingItem = useCallback(
    (newItem: VendorSwitchingItem, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = vendorSwitching(draft.revisions[revisionIdx].contents);

        const driversIndex = content.drivers.findIndex((item) => item.id === newItem.id);
        if (driversIndex !== -1) {
          content.drivers.splice(driversIndex, 1);
        }

        const barriersIndex = content.barriers.findIndex((item) => item.id === newItem.id);
        if (barriersIndex !== -1) {
          content.barriers.splice(barriersIndex, 1);
        }
      });
    },
    [originalModule]
  );

  return {
    updateVendorSwitchingItem,
    deleteVendorSwitchingItem,
  };
};
