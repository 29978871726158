import { isEmpty } from "lodash";

const extractSentences = (text = "", numSentences = 1) => {
  // Split the input by new lines and remove any leading dashes followed by spaces

  const lines = text.split("\n").slice(0, numSentences);
  const trimmedLines = lines.map((line) => line.replace(/^-+\s*/, "").trim());

  const sentenceEnders = /[.!?]$/;

  const linesFormattedAsSentences = trimmedLines.map((line) => {
    // Ensure the line ends with a period
    const withPeriod = isEmpty(line) || sentenceEnders.test(line) ? line : line + ".";
    return withPeriod.charAt(0).toUpperCase() + withPeriod.slice(1);
  });
  return linesFormattedAsSentences.join(" ");
};

const highlightText = (text: string) => {
  // Split text by bold markers and filter out empty strings
  const segments = text.split(/(\*\*.*?\*\*)/g).filter(Boolean);

  return segments.map((segment, index) => {
    if (segment.startsWith("**") && segment.endsWith("**")) {
      return <strong key={index}>{segment.slice(2, -2)}</strong>;
    }
    return segment;
  });
};

export { extractSentences, highlightText };
