import { x } from "@xstyled/styled-components";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { CitationWrapper, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { CustomerPrimerTopics } from "..";

type SectionWithTopicsProps = {
  sectionTitle: string;
  summary?: PrimerSummary;
  categories: CustomerPrimerTopic[];
  useDivider?: boolean;
  mt?: string;
};

const SectionWithTopics = ({
  sectionTitle,
  summary,
  categories,
  useDivider = false,
  mt = "",
}: SectionWithTopicsProps) => {
  const { spacing, typography, color } = useThemeTokens();

  return (
    <>
      {summary?.value ? (
        <x.div {...typography.body.regular} color={color.text.strong._}>
          <CitationWrapper
            titleProperties={{ variant: "navigation" }}
            verticalLabel
            value={summary.value}
            mt={mt ?? spacing.inner.base06}
            titleToContentGap={sectionTitle.length > 0 ? spacing.inner.base02 : "0"}
            label={sectionTitle}
          />
        </x.div>
      ) : (
        <PrimerFieldTitle text={sectionTitle} mt={mt ?? spacing.inner.base06} />
      )}
      <CustomerPrimerTopics categories={categories} />

      {useDivider && <Divider m={`${spacing.layout.base05} 0`} />}
    </>
  );
};

export default SectionWithTopics;
