import styled from "@xstyled/styled-components";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const Wrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
      display: flex;
      gap: ${spacing.inner.base};
      align-items: center;
    `;
});

const StyledTypography = styled(Typography).attrs({ variant: "body-small", component: "span" })`
  white-space: nowrap;
  overflow: hidden;
`;

const StyledIcon = styled(Icon).attrs({ size: "small" })`
  &:hover {
    cursor: pointer;
  }
`;

export { Wrapper, StyledTypography, StyledIcon };
