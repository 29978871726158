import { RequestSurveyCompanyPage, RequestSurveyCustomerPrimer } from "./index";

export enum RequestSurveyBannerViewModes {
  CompanyPage = "COMPANY_PAGE",
  CustomerPrimer = "CUSTOMER_PRIMER",
  MyAlphaSightsPage = "MY_ALPHASIGHTS_PAGE",
}

type RequestSurveyBannerProps = {
  companyName?: string;
  viewMode: RequestSurveyBannerViewModes;
};

const RequestSurveyBanner = ({ companyName, viewMode }: RequestSurveyBannerProps) => {
  switch (viewMode) {
    case RequestSurveyBannerViewModes.CompanyPage:
      return <RequestSurveyCompanyPage companyName={companyName} />;
    case RequestSurveyBannerViewModes.CustomerPrimer:
      return <RequestSurveyCustomerPrimer />;
    default:
      return null;
  }
};

export default RequestSurveyBanner;
