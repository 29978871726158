import { x } from "@xstyled/styled-components";
import { Pill, useThemeTokens } from "@alphasights/alphadesign-components";

type XAxisProps = {
  kpcs: {
    name: string;
    multilinedName: string[];
    score: string;
  }[];
};

// we need an extra padding on the first and last column
const getXAxisPadding = (numColumns: number): string => {
  switch (numColumns) {
    case 1:
    case 2:
    case 3:
      return "0";
    case 4:
      return "0.46%";
    case 5:
      return "2.79%";
    case 6:
      return "1.8%";
    case 7:
      return "1.2%";
    case 8:
      return "1.1%";
    case 9:
      return "0.8%";
    case 10:
      return "1%";
    default:
      return "8%";
  }
};

const getRowColumnsConfig = (numColumns: number) => {
  if (numColumns === 1) return { gridTemplateColumns: "100%" };

  return { gridTemplateColumns: `repeat(${numColumns}, 1fr)` };
};

const XAxis = ({ kpcs }: XAxisProps) => {
  const { color, spacing, typography } = useThemeTokens();

  return (
    <x.div display="flex">
      <x.div flex="0 0 auto" {...typography.body.small} marginTop="14px" fontWeight="400">
        Rank (avg.)
      </x.div>
      <x.div
        flex="1 1 auto"
        display="grid"
        margin="10px 10px 0 0"
        padding={`0 ${getXAxisPadding(kpcs.length)}`}
        alignItems="start"
        {...getRowColumnsConfig(kpcs.length)}
      >
        {kpcs.map(({ multilinedName, name, score }, index) => (
          <x.div
            key={`${name}-${index}`}
            w="100%"
            alignItems="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            columnGap={spacing.inner.base02}
            {...typography.body.small}
            color={color.text.strong._}
            rowGap={spacing.inner.base04}
          >
            <Pill size="small" variant="light">
              {score}
            </Pill>
            <div>
              {multilinedName.map((line, index) => (
                <x.div key={`${score}-${index}`} textAlign="center">
                  {line}
                </x.div>
              ))}
            </div>
          </x.div>
        ))}
      </x.div>
    </x.div>
  );
};

export default XAxis;
