enum SummaryType {
  overview = "overview",
  theme = "theme",
  quote = "quote",
  point = "point",
}

const DEFAULT_SUMMARY = { parts: [] };

const SUMMARY_LOADING_TITLE = "Summary view is generating";
const SUMMARY_LOADING_MESSAGE = "Check back in a few minutes...";

const SUMMARY_FAILED_MESSAGE = "Generation was unsuccessful. Please try again.";

const NO_SUMMARY_MESSAGE = "The document is too small to generate summary view.";

const OVERVIEW_TITLE = "Overview:";

const DOCUMENT_SUMMARY_KEY = "documentSummaries";

export {
  SummaryType,
  SUMMARY_LOADING_TITLE,
  SUMMARY_LOADING_MESSAGE,
  SUMMARY_FAILED_MESSAGE,
  OVERVIEW_TITLE,
  DEFAULT_SUMMARY,
  NO_SUMMARY_MESSAGE,
  DOCUMENT_SUMMARY_KEY,
};
