import React, { useEffect, useState } from "react";
import { ToggleButton } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { View } from "models/Header";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { RESEARCH_VIEWS_DISPLAY_NAMES } from "./constants";
import TranscriptSearch from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/TranscriptSearch";
import { TranscriptToolsProps } from "./types";
import { StyledToggleButtonGroup, TranscriptToolbarContainer } from "./TranscriptToolar.styled";
import TranscriptTools from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptControlPanel/TranscriptTools";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";

const DataTestIds = {
  transcriptToolbar: "transcript-toolbar",
};

const TranscriptToggleButton = ({ view, setViewMode }: { view: string; setViewMode: (view: string) => void }) => {
  return (
    <ToggleButton value={view} onClick={() => setViewMode(view)}>
      {RESEARCH_VIEWS_DISPLAY_NAMES[view]}
    </ToggleButton>
  );
};

const TranscriptToolbar = ({
  content,
  viewMode,
  setViewMode,
  transcriptSearchProps,
  transcriptToolsProps,
}: TranscriptToolsProps) => {
  const [toggleValue, setToggleValue] = useState(View.Transcript);

  const { updateQuery } = useAppSearchContext();
  const { isMobile } = useCheckScreen();

  const { purchaseStatus, approvalStatus, published } = content;

  const isAccessible = isContentAccessible(purchaseStatus, approvalStatus);
  const showTranscript = isAccessible && published;
  const displayTranscriptActions = showTranscript && !isMobile;
  const defaultView = showTranscript ? View.Transcript : View.ExpertDetails;

  const onTranscriptViewSelect = () => {
    setViewMode(View.Transcript);
    updateQuery({ fragmentIds: [] });
  };

  useEffect(() => {
    const toggleViews = [View.ExpertDetails, View.Question, View.Summary, View.Mentions, View.Transcript];
    const newViewMode = toggleViews.find((view) => view === viewMode) ?? defaultView;
    setToggleValue(newViewMode);
  }, [viewMode, defaultView]);

  return (
    <TranscriptToolbarContainer data-testid={DataTestIds.transcriptToolbar}>
      {displayTranscriptActions && <TranscriptSearch {...transcriptSearchProps} />}
      <StyledToggleButtonGroup value={toggleValue}>
        <TranscriptToggleButton view={View.ExpertDetails} setViewMode={setViewMode} />
        <TranscriptToggleButton view={View.Question} setViewMode={setViewMode} />
        <TranscriptToggleButton view={View.Summary} setViewMode={setViewMode} />
        {showTranscript ? <TranscriptToggleButton view={View.Mentions} setViewMode={setViewMode} /> : <></>}
        <TranscriptToggleButton view={View.Transcript} setViewMode={onTranscriptViewSelect} />
      </StyledToggleButtonGroup>
      {displayTranscriptActions && <TranscriptTools {...transcriptToolsProps} />}
    </TranscriptToolbarContainer>
  );
};

export { TranscriptToolbar as default, DataTestIds };
