import { Skeleton, Typography } from "@alphasights/alphadesign-components";
import { NOT_YET_ANSWERED } from "models/ExpertCompare";

export type QuestionColumnProps = {
  text: string;
  isLoading?: boolean;
};

export const QuestionColumn = ({ text, isLoading }: QuestionColumnProps) => {
  const textToDisplay = text === NOT_YET_ANSWERED ? "" : text;
  return (
    <Typography variant="body-small-spaced" component="span">
      <span className={isLoading ? "response-loading" : ""}>
        {isLoading ? (
          <span data-testid="skeleton">
            <Skeleton height="32px" variant="noMargin" />
          </span>
        ) : (
          <>{textToDisplay}</>
        )}
      </span>
    </Typography>
  );
};
