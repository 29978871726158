import { FC, useState } from "react";
import * as React from "react";
import { useMutation } from "query-utils";
import { Modal, useAlphaToast, ToastContainer, TextArea } from "@alphasights/alphadesign-components";
import { RequestExpertButton } from "pages/AlphaNowPage/components";
import { RequestExpertButtonType } from "pages/AlphaNowPage/components/AlphaNowContent/AlphaNowContentActions/constants";
import {
  primerExpertRequestNotePlaceholder,
  requestContent,
  requestMultipleExperts,
  successfulRequest,
  unsuccessfulRequest,
} from "content/AlphaNow";
import { requestExperts } from "content/AlphaNow/utils";
import CancelButton from "pages/AlphaNowPage/components/CancelButton";
import { contentService } from "services/content";
import useModal from "hooks/useModal";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import useContentClientSettings from "hooks/useContentClientSettings";
import FeaturedExpertCards from "pages/AlphaNowPage/components/FeaturedExpertCards";
import { ModalContentContainer } from "./RequestExpertModal.styled";

const DataTestIds = {
  ExpertDetails: "expert-details",
  RequestExpertNote: "request-expert-note",
};

export type Speaker = {
  bio: string;
  jobTitle: string;
  angleType: string;
  logo: string;
  companyName: string;
  speakerId: number;

  jobDuration?: string;
};

interface RequestExpertModalProps {
  selectedSpeakers: Speaker[];
  handleSubmitExpertRequest?: () => void;

  origin?: string;
  contentId?: string;
}

const RequestExpertModal: FC<RequestExpertModalProps> = ({
  selectedSpeakers,
  handleSubmitExpertRequest = () => {},

  origin = HitOrigin.alphaNow,
  contentId,
}) => {
  const { toast } = useAlphaToast();
  const { isVisible, onOpen, onClose } = useModal();
  const { logHit } = useTrackUserAction();
  const { clientSettings, isLoading: isClientSettingsLoading } = useContentClientSettings();
  const [note, setNote] = useState("");

  const createClientExpertInquiry = useMutation(
    (clientExpertInquiry: ClientExpertInquiry) => contentService.postExpertRequest(clientExpertInquiry as any),
    {
      onSuccess: () => toast.success({ message: successfulRequest }),
      onError: () => toast.error({ message: unsuccessfulRequest }),
    }
  );

  const selectedSpeakerIds = selectedSpeakers.map((expert) => expert.speakerId);
  const isRequestExpertsButtonEnabled = Boolean(clientSettings?.requestExperts) && selectedSpeakerIds.length > 0;

  const clientExpertInquiry = {
    note,
    speakerIds: selectedSpeakerIds,
  };

  const handleRequestExpert = () => {
    createClientExpertInquiry.mutate(clientExpertInquiry);
    onClose();
    handleSubmitExpertRequest();
    logHit({
      origin,
      action: HitAction.alphaNowRequestAnExpert,
      details: { ...(contentId && { contentId }), expertIds: selectedSpeakerIds },
    });
  };

  const handleOpenModal = () => {
    onOpen();
    logHit({
      origin,
      action: HitAction.alphaNowRequestAnExpertAttempt,
      details: {
        ...(contentId && { contentId }),
        expertIds: selectedSpeakerIds,
      },
    });
  };

  const handleOnChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value;
    setNote(newNote);
  };

  return (
    <>
      <RequestExpertButton
        buttonActionVariant={RequestExpertButtonType.openModal}
        text={requestMultipleExperts}
        onClick={handleOpenModal}
        loading={isClientSettingsLoading}
        disabled={!isRequestExpertsButtonEnabled}
        size="small"
      />
      {isVisible && (
        <Modal
          title={requestExperts(selectedSpeakerIds.length)}
          variant="complex"
          slotHeight="384px"
          slotWidth="553px"
          zIndex={10}
          open={isVisible}
          onClose={onClose}
          shouldShowFooter={true}
          primaryButton={
            <RequestExpertButton
              buttonActionVariant={RequestExpertButtonType.submitRequest}
              text={requestContent}
              onClick={handleRequestExpert}
              size="small"
            />
          }
          secondaryButton={<CancelButton isExistingExpertRequest={false} handleCancelRequest={onClose} size="small" />}
        >
          <ModalContentContainer>
            <FeaturedExpertCards speakers={selectedSpeakers} open={false} />
            <TextArea
              dataAttributes={{ "data-testid": DataTestIds.RequestExpertNote }}
              width="100%"
              height="212px"
              placeholder={primerExpertRequestNotePlaceholder}
              onChange={handleOnChangeNote}
            />
          </ModalContentContainer>
        </Modal>
      )}
      <ToastContainer />
    </>
  );
};

export { RequestExpertModal as default, DataTestIds };
