import styled, { x } from "@xstyled/styled-components";
import { find, map, unary, uniq } from "lodash/fp";
import { FC, ReactNode, useEffect, useRef } from "react";
import * as React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { useCitationContext } from "components/CitationContext/CitationContext";
import { useMentionedExpertsStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state/mentionedExpertsStore";
import ExpertsCategorizedByAngle from "../CategorizedByAngle";
import { ContributedBy } from "../ContributedBy";

export const DataTestIds = {
  expertsSidebarView: "experts-sidebar-view",
  expertsMobileView: "experts-mobile-view",
};

export interface ExpertsInfoViewProps {
  contentId: string;
  productType?: string;
  speakers: Speaker[];
  setIsExpertsView?: (value: boolean) => void;
  isAccessible?: boolean;
}

export const ExpertsInfoView: FC<ExpertsInfoViewProps> = ({
  contentId,
  productType,
  speakers,
  isAccessible = true,
  setIsExpertsView = () => {},
}) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  const { onSelectSpeakers, selectedSpeakerIds, selectedCitation } = useCitationContext();
  const selectedMentionedExpertsIds = useMentionedExpertsStore(
    ({ selectedMentionedExpertsIds }) => selectedMentionedExpertsIds
  );
  const resetExpertsMentioned = useMentionedExpertsStore(({ resetExpertsMentioned }) => resetExpertsMentioned);
  const { isMobile } = useCheckScreen();

  const expertsViewRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Only scroll on selecting a new citation - not de-selecting a citation
    if (selectedCitation) {
      expertsViewRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [selectedCitation]);

  const findSpeakerById = (id: number) => find<Speaker>((it) => it.speakerId === id)(speakers);

  const selectedExperts = map(findSpeakerById)(
    uniq([...selectedSpeakerIds, ...selectedMentionedExpertsIds, ...(selectedCitation?.citedBy || [])])
  ).filter(unary(Boolean)) as Speaker[];

  const unselectedExperts = speakers.filter(
    (speaker) =>
      !selectedSpeakerIds.includes(speaker.speakerId) &&
      !selectedMentionedExpertsIds.includes(speaker.speakerId) &&
      !selectedCitation?.citedBy?.includes(speaker.speakerId)
  );

  useEffect(() => {
    // experts were selected on the sidebar or citations and we had mentioned experts selected
    if (selectedSpeakerIds.length > 0 && selectedMentionedExpertsIds.length > 0) {
      // reset mentioned experts selection
      resetExpertsMentioned();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpeakerIds]);

  useEffect(() => {
    // mentioned experts were selected and we had experts selected by the sidebar or citations
    if (selectedSpeakerIds.length > 0 && selectedMentionedExpertsIds.length > 0) {
      // reset experts selection
      onSelectSpeakers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMentionedExpertsIds]);

  return (
    <ExpertsContainer ref={expertsViewRef}>
      <Typography
        variant={isMobile ? "h3" : "body-large-em"}
        mb={{ xs: inner.base05, sm: inner.base06 }}
        color={color.background.action.secondary.hover}
      >
        Contributing experts
      </Typography>

      <ContributedBy
        contentId={contentId}
        showInstructions
        {...{
          selectedExperts: isAccessible ? selectedExperts : undefined,
        }}
      />

      {isAccessible && Boolean(selectedExperts.length) && <x.br mb={{ xs: inner.base05, sm: inner.base06 }} />}
      <HorizontalRule />
      <ExpertsCategorizedByAngle
        {...{
          contentId,
          productType,
          selectedExperts,
          unselectedExperts,
          isAccessible,
          setIsExpertsView,
        }}
      />
    </ExpertsContainer>
  );
};

const Panel = styled.div(() => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();

  return {
    display: "flex",
    "flex-direction": "column",
    "background-color": color.background.surface.raised,
    "border-left": `1px solid ${color.border.neutral.default}`,
    overflow: "auto",
    height: "100%",
    padding: `${inner.base06} ${inner.base08} ${inner.base10} ${inner.base05}`,
  };
});

interface ExpertsContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode[];
}

const ExpertsContainer = React.forwardRef<HTMLDivElement, ExpertsContainerProps>(({ children }, ref) => {
  const { isMobile } = useCheckScreen();
  if (isMobile) {
    return (
      <x.div data-testid={DataTestIds.expertsMobileView} ref={ref}>
        {children}
      </x.div>
    );
  }

  return (
    <Panel data-testid={DataTestIds.expertsSidebarView} ref={ref}>
      {children}
    </Panel>
  );
});

const HorizontalRule = () => {
  const { spacing, color, shape } = useThemeTokens();

  return (
    <x.hr
      borderColor={color.border.neutral.default}
      borderBottomWidth={shape.border.width.small}
      marginBottom={spacing.inner.base06}
    />
  );
};
