import { memo, useState, useEffect } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import ExpertCard from "./ExpertCard/ExpertCard";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyInsights } from "views/SurveysResultsView/api/useSurveysAPI";
import { useInView } from "react-intersection-observer";
import { Spinner } from "@alphasights/client-portal-shared";

const Experts = memo(() => {
  const { color, spacing, shape } = useThemeTokens();
  const [selectedAdvisorId, setSelectedAdvisorId] = useState<string>();
  const { ref, inView } = useInView({
    threshold: 1.0,
  });

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useSurveyInsights(surveyId);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const handleOnCardClick = (newAdvisorSelectedId: string) => {
    if (selectedAdvisorId === newAdvisorSelectedId) {
      setSelectedAdvisorId(undefined);
    } else {
      setSelectedAdvisorId(newAdvisorSelectedId);
    }
  };

  return (
    <x.div
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      p={spacing.inner.base06}
      paddingRight={spacing.inner.base04}
      overflowY="scroll"
      gap={spacing.inner.base03}
      borderTop={`${shape.border.width.sm} solid ${color.border.neutral.default}`}
    >
      {data?.pages.map((page) =>
        page.items.map((element) => (
          <ExpertCard
            key={element.resultSetId}
            element={element}
            isCardOpen={element.advisorData.id === selectedAdvisorId}
            handleOnCardClick={handleOnCardClick}
          />
        ))
      )}
      <div ref={ref} />
      {isFetchingNextPage && (
        <x.div display="flex" justifyContent="center" alignItems="center" h="inherit">
          <Spinner size="small" />
        </x.div>
      )}
    </x.div>
  );
});

export default Experts;
