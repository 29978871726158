import { CONTENT_TYPE } from "@alphasights/client-portal-shared";

export * from "./ActionTypes";
export * from "./Search";

export const CONTENT_PERMISSION = {
  ManageContent: "manage_content",
  ViewContent: "view_content",
};

export const CONTENT_TYPE_PERMISSION = {
  [CONTENT_TYPE.alphaview]: "alphaviews",
  [CONTENT_TYPE.pcc]: "peer_contributed_content",
  [CONTENT_TYPE.privateEnterprise]: "private_enterprise_content",
};

export const ModeratedTranscriptDisplayName = "Moderated Transcript";

export const SPEAKER_ANGLE_TYPE = Object.freeze({
  Competitors: "Competitors",
  EndCustomers: "End Customers",
  IndustryAuthorities: "Industry Authorities",
  Intermediaries: "Intermediaries",
  Suppliers: "Suppliers",
  TargetCompany: "Target Company",
  Other: "Other",
});

export const SPEAKER_ANGLE_TYPE_NAME = Object.freeze({
  [SPEAKER_ANGLE_TYPE.Competitors]: "Competitor",
  [SPEAKER_ANGLE_TYPE.EndCustomers]: "Customer",
  [SPEAKER_ANGLE_TYPE.IndustryAuthorities]: "Industry Authority",
  [SPEAKER_ANGLE_TYPE.Intermediaries]: "Intermediary",
  [SPEAKER_ANGLE_TYPE.Suppliers]: "Supplier",
  [SPEAKER_ANGLE_TYPE.TargetCompany]: "Former",
  [SPEAKER_ANGLE_TYPE.Other]: "Other perspective",
});

export const COMPANY_RELATIONSHIP = Object.freeze({
  // angle
  [SPEAKER_ANGLE_TYPE.Competitors]: `${SPEAKER_ANGLE_TYPE_NAME.Competitors} of`,
  [SPEAKER_ANGLE_TYPE.EndCustomers]: `${SPEAKER_ANGLE_TYPE_NAME["End Customers"]} of`,
  [SPEAKER_ANGLE_TYPE.IndustryAuthorities]: `${SPEAKER_ANGLE_TYPE_NAME["Industry Authorities"]} on`,
  [SPEAKER_ANGLE_TYPE.Intermediaries]: `${SPEAKER_ANGLE_TYPE_NAME.Intermediaries} of`,
  [SPEAKER_ANGLE_TYPE.Suppliers]: `${SPEAKER_ANGLE_TYPE_NAME.Suppliers} of`,
  [SPEAKER_ANGLE_TYPE.TargetCompany]: `${SPEAKER_ANGLE_TYPE_NAME["Target Company"]} of`,
  [SPEAKER_ANGLE_TYPE.Other]: `${SPEAKER_ANGLE_TYPE_NAME.Other} on`,
  // anchor
  Panel: "Panel on",
});

export const MANAGE_CONTENT_PERMISSION = "manage_content";
export const VIEW_CONTENT_PERMISSION = "view_content";

export const VIEW_ALPHA_GPT_PERMISSION = "view_alpha_gpt";

export const CLIENT_ROLE_PERMISSION = "role:client";

export const COLLAPSED_LEFT_SIDEBAR_WIDTH = 40;
export const EXPERTS_SIDEBAR_WIDTH = 400;
export const SIDEBAR_ANIMATION_SPEED = "0.5s";

export const SPEAKER = Object.freeze({
  Interviewer: "Interviewer",
  Expert: "Expert",
});

export const SPEAKER_FOREIGN_TRANSLATIONS = {
  [SPEAKER.Interviewer]: [
    "采访者",
    "質問者",
    // TODO [Fran]: remove space from French translations
    "Intervieweur ",
    "진행자",
    "Intervistatore",
    "Intervjuare",
    "Interviewer",
    "Entrevistador/a",
  ],
  [SPEAKER.Expert]: [
    "专家",
    "専門家",
    // TODO [Fran]: remove space from French translations
    "Expert/e ",
    "자문가",
    "Esperto",
    "Rådgivare",
    "Experte",
    "Experto/a",
  ],
};

export const SPEAKER_ENGLISH_TRANSLATIONS = {
  // Chinese
  采访者: SPEAKER.Interviewer,
  专家: SPEAKER.Expert,
  // Japanese
  質問者: SPEAKER.Interviewer,
  専門家: SPEAKER.Expert,
  // French
  // TODO [Fran]: remove space from French translations
  "Intervieweur ": SPEAKER.Interviewer,
  "Expert/e ": SPEAKER.Expert,
  // Korean
  진행자: SPEAKER.Interviewer,
  자문가: SPEAKER.Expert,
  // Italian
  Intervistatore: SPEAKER.Interviewer,
  Esperto: SPEAKER.Expert,
  // Swedish
  Intervjuare: SPEAKER.Interviewer,
  Rådgivare: SPEAKER.Expert,
  // German
  Interviewer: SPEAKER.Interviewer,
  Experte: SPEAKER.Expert,
  // Spanish
  "Entrevistador/a": SPEAKER.Interviewer,
  "Experto/a": SPEAKER.Expert,
};

export const TOO_MANY_REQUESTS_STATUS = 429;

export const desktopContentContainerWidth = "606px";

export const UpcomingContentAccessRequest = {
  REQUIRED: "REQUIRED",
  NOT_REQUIRED: "NOT_REQUIRED",
};

export const CONTENT_REQUEST = {
  dialInAndTranscript: "CALL_AND_TRANSCRIPT",
  transcript: "TRANSCRIPT",
};

export const UPCOMING_REQUEST_DISPLAY_NAME = {
  [CONTENT_REQUEST.dialInAndTranscript]: "Both dial-in and transcript",
  [CONTENT_REQUEST.transcript]: "Transcript only",
};

export const DISABLED_BUTTON_STYLES = {
  opacity: 0.6,
  pointerEvents: "none",
};

export const LEVER_BEHAVIOUR = {
  NO_APPROVAL: "NO_APPROVAL",
  REQUEST_APPROVAL: "REQUEST_APPROVAL",
  HIDE: "HIDE",
};
