import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const ProcessingQuestionContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base03,
  };
});

export const LoadingMessageContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    gap: spacing.inner.base04,
    alignItems: "center",
    paddingBlock: spacing.inner.base04,
  };
});

export const LoadingSkeletonContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base02,
    paddingLeft: spacing.inner.base08,
  };
});

export const StyledIcon = styled(Icon)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.icon.ai,
  };
});
