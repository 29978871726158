import { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import { CONTENT_TYPE_TABS } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Insights/consts";
import { contentService } from "services/content";
import { ContentTypeTab, InsightsResponse } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Insights/types";
import { PromiseStatus } from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Insights/consts";

const useInsightsData = (companyId: number, insightsTypes?: string[]) => {
  const [insights, setInsights] = useState<InsightsResponse[]>([]);
  const [contentTypeTabs, setContentTypeTabs] = useState<ContentTypeTab[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataError, setIsDataError] = useState(false);

  const fetchInsights = async () => {
    setIsLoading(true);
    const filteredContentTypeTabs = CONTENT_TYPE_TABS.filter((item) => !!insightsTypes?.includes(item.type));
    return Promise.allSettled(
      filteredContentTypeTabs.map(async (tab) => contentService.fetchCompanyInsights(companyId, tab.type))
    )
      .then((results) => {
        const insightsData = results
          .map((result, index) => {
            const contentType = filteredContentTypeTabs[index].type;
            let data = {} as InsightsResponse;
            if (result.status === PromiseStatus.Fulfilled) {
              const isDataValid = Object.values(result.value).every((entryValue) => !isEmpty(entryValue));
              if (isDataValid) {
                data = result.value;
              } else {
                console.warn(`Invalid ${contentType} insights data`);
              }
            } else {
              console.error(`Unable to fetch ${contentType} insights data`);
            }
            return { data, contentType, isHidden: !data || isEmpty(data) };
          })
          .filter(({ isHidden }) => !isHidden);

        const tabs = insightsData.map(({ contentType }) => ({
          ...(CONTENT_TYPE_TABS.find(({ type }) => type === contentType) as ContentTypeTab),
        }));
        setContentTypeTabs(tabs);

        if (!insightsData.length) {
          setIsDataError(true);
        } else {
          setIsDataError(false);
        }
        const normalisedData = insightsData.map(({ data }) => ({ ...data }));
        setInsights(normalisedData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchInsights();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { insights, contentTypeTabs, isLoading, isDataError };
};

export default useInsightsData;
