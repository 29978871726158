import { memo } from "react";
import { x } from "@xstyled/styled-components";
import { Divider, TabItem, Tabs, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { TransformedSurveyMetadata } from "views/SurveysResultsView/api/types";
import SurveyStatus from "./components/SurveyStatus";
import { DownloadButton } from "views/SurveysResultsView/components";
import { withNumberParam } from "helpers/tabHelper";

const Header = memo(() => {
  const { color, spacing } = useThemeTokens();
  const selectedSectionIndex = useSurveysResultsStore(({ selectedSectionIndex }) => selectedSectionIndex);
  const setNavigateToSectionIndex = useSurveysResultsStore(
    ({ setNavigateToSectionIndex }) => setNavigateToSectionIndex
  );

  const setIsScrollOnClickedTab = useSurveysResultsStore(({ setIsScrollOnClickedTab }) => setIsScrollOnClickedTab);

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { name, startDate, endDate, status, tabs } = surveyMetadata as TransformedSurveyMetadata;

  const handleTabChange = (index: number) => {
    setNavigateToSectionIndex(index);
    setIsScrollOnClickedTab(true);
  };

  return (
    <x.div backgroundColor={color.background.surface.page.default}>
      <x.div padding={`${spacing.layout.base02} ${spacing.layout.base03}`}>
        <x.div display="flex" alignItems="center">
          <x.div flex="1 1 auto" display="flex" columnGap={spacing.inner.base02} mr={spacing.inner.base04}>
            <Typography variant="body-small-em">{name}</Typography>
            <SurveyStatus startDate={startDate} endDate={endDate} status={status} />
          </x.div>
          <x.div flex="0 0 auto">
            <DownloadButton variant="outline" size="small" />
          </x.div>
        </x.div>
      </x.div>
      <Divider />
      <Tabs
        p={`0 ${spacing.inner.base06}`}
        index={selectedSectionIndex || 0}
        onChange={withNumberParam(handleTabChange)}
      >
        {tabs.map(({ display, name }) => (
          <TabItem key={name} label={display} />
        ))}
      </Tabs>
    </x.div>
  );
});

export default Header;
