import { ThirdPartyDocumentExpertWithId } from "types/common-types";
import { EXPERT_FIELDS } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/Experts/constants";
import { MISSING_FIELD } from "./constants";

const getFieldError = (field: string, errorFields: string[]) =>
  errorFields.includes(field) ? MISSING_FIELD : undefined;

const getFalsyValueFields = (expert: ThirdPartyDocumentExpertWithId) => {
  const errorFields: string[] = [];
  EXPERT_FIELDS.forEach((field) => {
    if (!expert[field as keyof ThirdPartyDocumentExpertWithId]) {
      errorFields.push(field);
    }
  });
  return errorFields;
};

export { getFieldError, getFalsyValueFields };
