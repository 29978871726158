import * as React from "react";
import { Carousel } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Typography } from "@alphasights/alphadesign-components";
import {
  ContentCardCarouselContainer,
  StyledContentCard,
  TitleContainer,
} from "pages/AlphaNowPage/components/ContentCardCarousel/ContentCardCarousel.styled";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import {
  NUMBER_CONTENT_CARDS_VISIBLE_DESKTOP,
  NUMBER_CONTENT_CARDS_VISIBLE_ULTRA_WIDE,
} from "pages/AlphaNowPage/components/ContentCardCarousel/consts";

const DATA_TEST_IDS = {
  contentCardCarousel: "content-card-carousel",
};

type SelectedContentProps = {
  content: Content;
  cardPosition: number;
  title: string;
};

type ContentCardCarouselProps = {
  title: string;
  data: Content[];
  onClick: (input: SelectedContentProps) => void;

  dataTestId?: string;
  rightAccessories?: React.ReactNode;
  customCard?: React.ReactNode;
};

const ContentCardCarousel = ({
  title,
  data,
  rightAccessories,
  customCard,
  onClick,
  dataTestId = DATA_TEST_IDS.contentCardCarousel,
}: ContentCardCarouselProps) => {
  const { hasOnlyPremiumContent } = useAlphaNowContentAccessLevelContext();
  const { isUltraWide } = useCheckScreen();

  const numberOfItemsDisplayed = isUltraWide
    ? NUMBER_CONTENT_CARDS_VISIBLE_ULTRA_WIDE
    : NUMBER_CONTENT_CARDS_VISIBLE_DESKTOP;

  const isResultAccessible = (searchResult: any) => {
    const { purchaseStatus, approvalStatus } = searchResult;
    return isContentAccessible(purchaseStatus, approvalStatus);
  };

  const handleSelectContent = (content: Content, cardPosition: number) => onClick({ content, cardPosition, title });

  const Title = () => (
    <TitleContainer>
      <Typography variant="body-large-em">{title}</Typography>
      {rightAccessories}
    </TitleContainer>
  );

  const contentCards = data.map((content, index) => (
    <StyledContentCard
      key={content.id}
      content={content}
      isSelected={false}
      onClick={() => handleSelectContent(content, index)}
      isBookmarked={!!content.clientBookmark}
      showPurchasedFlag={!hasOnlyPremiumContent && isResultAccessible(content)}
    />
  ));

  const allCards = customCard ? [...contentCards, customCard] : contentCards;

  return (
    <ContentCardCarouselContainer data-testid={dataTestId}>
      <Carousel numberOfItemsDisplayed={numberOfItemsDisplayed} title={<Title />}>
        {allCards}
      </Carousel>
    </ContentCardCarouselContainer>
  );
};

export { ContentCardCarousel as default, DATA_TEST_IDS };
