import styled, { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const Form = styled(x.form)(({ isCollapsible }: { isCollapsible?: boolean }) => {
  const { shape, spacing, color } = useThemeTokens();
  return `
    transition: width 0.3s ease-in-out;
    width: 200px;
    [data-testid="text-input-container"] {
      border-radius: ${shape.border.radius.xlarge};
    }
    [data-testid="clear-search"] {
      display: none;
    }
    &:has(input:not(:placeholder-shown)) {
      &:hover,
      &:has(input:focus) {
        [data-testid="clear-search"] {
          display: block;
        }
      }
    }
    input:autofill {
      box-shadow: 0 0 0 ${spacing.inner.base08} ${color.background.surface.page.default} inset;
    }
    div:has(> input:-internal-autofill-selected) {
      background-color: transparent;
    }
    ${
      isCollapsible &&
      `
      input:placeholder-shown:not(:focus) {
        padding: 0;
        width: 0;
      }
      &:has(input:placeholder-shown:not(:focus)) {
        width: 40px;
        [data-testid="text-input-container"] {
          cursor: pointer !important;
        }
      }
    `
    }
  `;
});
