import { useEnv } from "@alphasights/client-portal-shared";
import { InteractionExecutivePartnershipEligibility, projects } from "@alphasights/portal-api-client";
import { useQuery } from "query-utils";

export const useExecutivePartnershipsEligibility = ({
  projectToken,
  interactionId,
}: {
  projectToken?: string;
  interactionId: string;
}) => {
  const env = useEnv();
  const { data, isLoading } = useQuery<InteractionExecutivePartnershipEligibility[]>(
    ["executivePartnershipsEligibility", projectToken],
    () => projects.getExecutivePartnershipsEligibility(projectToken!!),
    {
      enabled: env?.enableExecutivePartnerships && !!projectToken,
      refetchOnMount: false,
    }
  );

  const isEligible =
    !isLoading && (data ?? []).find((interaction) => interaction.id === interactionId)?.eligibleForExecutivePartnership;

  return {
    isLoading,
    isEligible: !!isEligible,
  };
};
