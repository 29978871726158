import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const StyledSectionHeader = styled(Typography).attrs({ variant: "body-large-em" })(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.strong._,
  };
});

export const ExpertDetailsContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    gap: spacing.inner.base06,
    display: "flex",
    flexDirection: "column" as "column",
  };
});

export const FeaturedCompanyPillsContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    gap: spacing.inner.base02,
    flexWrap: "wrap" as "wrap",
  };
});

export const SectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SecondaryLabelContainer = styled(Typography)(() => {
  const { color, spacing } = useThemeTokens();
  return {
    color: color.text.secondary,
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base,
    alignItems: "center",
  };
});
