import { create } from "zustand";

type storeState = {
  isResearchEntryLoading: boolean;
  unfocusSearch: boolean;
  setIsResearchEntryLoading: (data: boolean) => void;
  setUnfocusSearch: (data: boolean) => void;
};

export const useAlphaNowPageStore = create<storeState>((set) => ({
  isResearchEntryLoading: false,
  unfocusSearch: false,
  setIsResearchEntryLoading: (data: boolean) => set({ isResearchEntryLoading: data }),
  setUnfocusSearch: (data: boolean) => set({ unfocusSearch: data }),
}));
