import { useEffect, useMemo, useReducer, useState } from "react";
import { useQuery } from "query-utils";
import { cloneDeep, isEmpty } from "lodash";
import styled from "@xstyled/styled-components";
import { AlphaTable, useAlphaTable, AlphaTableTypes } from "@alphasights/alphadesign-table";
import { HitOrigin } from "@alphasights/portal-api-client";

import { contentService } from "services/content";
import {
  SectionHeader,
  SectionWrapper,
  SECTION_TITLES,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components";
import RequestExpertModal from "pages/AlphaNowPage/components/RequestExpertModal";
import {
  Speaker,
  CachedExpertData,
  ExpertRow,
  ExpertDataAction,
  EXPERT_ACTION,
  SpeakerContributionsResponse,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Experts/types";
import * as ColumnDefsBuilder from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Experts/ColumnDefsBuilder";
import useHiddenBodyOverflow from "pages/AlphaNowPage/hooks/useHiddenBodyOverflow";
import { SPEAKER_ANGLE_TYPE_NAME } from "constants/AlphaNow";

import "./index.css";

const DataTestIds = {
  Section: "experts-section",
};

const reducer = (state: CachedExpertData, actionType: ExpertDataAction) => {
  const { type, payload } = actionType;
  const speakers = payload?.speakers as ExpertRow[];
  let newState: CachedExpertData = cloneDeep(state);

  switch (type) {
    case EXPERT_ACTION.Add:
      if (!speakers.every((speaker: ExpertRow) => String(speaker.speakerId) in state)) {
        speakers.forEach((speaker: ExpertRow) => {
          newState[speaker.speakerId] = { ...speaker, isSelected: false };
        });
      }
      break;
    case EXPERT_ACTION.Select:
      speakers.forEach((speaker: ExpertRow) => {
        newState[speaker.speakerId].isSelected = true;
      });
      break;
    case EXPERT_ACTION.Deselect:
      speakers.forEach((speaker: ExpertRow) => {
        newState[speaker.speakerId].isSelected = false;
      });
      break;
    default:
      return state;
  }
  return newState;
};

const initialExpertData = {
  currentPage: 1,
  content: [],
  totalElements: 0,
  hasPrevious: false,
  hasNext: false,
};

const NUM_ITEMS_PER_PAGE = 5;

const enhanceExpertData = (expertData: SpeakerContributionsResponse, companyName: string) => {
  return {
    ...cloneDeep(expertData),
    content: expertData.content.map(
      (expert: Speaker) =>
        ({
          ...expert,
          isSelected: false,
          isSelectedCompany: expert.companyName === companyName,
          //@ts-ignore
          angleType: SPEAKER_ANGLE_TYPE_NAME[expert.angleType],
          companyId: expert.cdsAlphaCompanyId,
        } as ExpertRow)
    ),
  };
};

enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

type ExpertsProps = {
  companyId: number;
  companyName: string;
  onDataFetch: (data: any) => void;
};

const Experts = ({ companyId, companyName, onDataFetch }: ExpertsProps) => {
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState<AlphaTableTypes.SortingState>([]);
  const [hasPageChanged, setHasPageChanged] = useState(false);
  const [expertDataCache, dispatchExpertAction] = useReducer(reducer, {});
  const [currentPageExpertData, setCurrentPageExpertData] = useState<SpeakerContributionsResponse | undefined>();

  const { isSuccess, data, isError } = useQuery(
    ["experts", companyId, page, sorting],
    () => {
      const hasSorting = sorting?.length > 0;
      const sortingParam = {
        sortBy: hasSorting ? sorting[0].id : "",
        sortDirection: hasSorting ? (sorting[0].desc ? SortDirection.DESC : SortDirection.ASC) : "",
      };
      return contentService.fetchExpertsByCompany(companyId, sortingParam, page);
    },
    {
      enabled: !!companyId,
    }
  );

  const isSettled = isSuccess || isError;

  useEffect(() => {
    if (isSuccess) {
      if (!isEmpty(data)) {
        const enhancedExpertData = enhanceExpertData(data, companyName);
        setCurrentPageExpertData(enhancedExpertData);
        const speakers = data.content ?? [];
        if (speakers.length > 0) {
          dispatchExpertAction({ type: EXPERT_ACTION.Add, payload: { speakers } });
        }
      }
      setHasPageChanged(true);
    }
  }, [isSuccess, data, companyName]);

  useEffect(() => {
    setPage(1);
  }, [sorting]);

  useEffect(() => {
    if (onDataFetch && isSettled) {
      onDataFetch({});
    }
  }, [isSettled]); // eslint-disable-line react-hooks/exhaustive-deps

  const { currentPage, content: experts = [], totalElements, hasPrevious: hasPreviousPage, hasNext: hasNextPage } =
    currentPageExpertData ?? initialExpertData;

  // this is required to hide the body overflow which causes a double vertical scrollbar
  // the overflow is generated by adding the sorting popover to the DOM
  // on clicking the chevron icon in the column headers
  useHiddenBodyOverflow(!isEmpty(experts));

  const getNextPage = async () => hasNextPage && setPage(page + 1);
  const getPreviousPage = async () => hasPreviousPage && setPage(page - 1);

  // This function is required so that the experts cache is updated when row selection changes
  // It differentiates between selecting and deselecting rows and fires separate actions
  // so that the isSelected value on the rows with the matching speaker IDs are updated correctly
  const onRowSelectionChange: AlphaTableTypes.OnChangeFn<AlphaTableTypes.RowSelectionState> = (updatedRowSelection) => {
    if (!isEmpty(expertDataCache)) {
      const currentPageRows = table.getRowModel().rows;
      const selectedRows: ExpertRow[] = [];
      const deselectedRows: ExpertRow[] = [];

      currentPageRows.forEach((row, index) => {
        const isSelected = !!(updatedRowSelection as AlphaTableTypes.RowSelectionState)[index];
        if (isSelected) {
          selectedRows.push(row.original as ExpertRow);
        } else {
          deselectedRows.push(row.original as ExpertRow);
        }
      });

      deselectedRows.length !== 0 &&
        dispatchExpertAction({ type: EXPERT_ACTION.Deselect, payload: { speakers: deselectedRows } });
      selectedRows.length !== 0 &&
        dispatchExpertAction({ type: EXPERT_ACTION.Select, payload: { speakers: selectedRows } });
    }
  };

  const tableOptions = {
    state: { sorting },
    manualSorting: true,
    onSortingChange: setSorting,
    onRowSelectionChange,
    meta: {
      rowHoverHighlight: true,
      fullWidth: true,
      isEmpty: isEmpty(experts),
      paginationProps: {
        page: currentPage,
        size: NUM_ITEMS_PER_PAGE,
        total: totalElements,
        onNext: getNextPage,
        onPrevious: getPreviousPage,
        hideVisibilitiesColumn: true,
      },
    },
  };

  const columnDefs = useMemo(() => ColumnDefsBuilder.buildColumnDefs(), []);
  const table = useAlphaTable(experts, columnDefs, tableOptions);

  const selectedSpeakers = useMemo(() => {
    return Object.values(expertDataCache)
      .filter((expert) => expert.isSelected)
      .map(({ bio, position, angleType, companyName, companyLogo, dateHeld, speakerId }) => ({
        speakerId,
        bio,
        jobTitle: position,
        angleType,
        companyName,
        logo: companyLogo,
        jobDuration: dateHeld,
      }));
  }, [expertDataCache]);

  useEffect(() => {
    const hasCache = !isEmpty(expertDataCache);
    if (hasPageChanged && hasCache) {
      const cachedSelection = experts
        .map((expert: Speaker) => expert.speakerId)
        .reduce((acc: Record<number, boolean>, speakerId: number, currentIndex: number) => {
          return { ...acc, [currentIndex]: expertDataCache[speakerId]?.isSelected };
        }, {});
      table.setRowSelection(cachedSelection);
      setHasPageChanged(false);
    }
  }, [hasPageChanged, expertDataCache]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isEmpty(experts)) return null;

  const handleSubmitExpertRequest = () => {
    table.toggleAllRowsSelected(false);
    dispatchExpertAction({ type: EXPERT_ACTION.Deselect, payload: { speakers: Object.values(expertDataCache) } });
    setPage(1);
  };

  return (
    <SectionWrapper data-testid={DataTestIds.Section} id="experts-section" style={{ width: "fit-content" }}>
      <SectionHeaderWrapper>
        <SectionHeader title={SECTION_TITLES.Experts} companyName={companyName}>
          <RequestExpertModal
            selectedSpeakers={selectedSpeakers}
            handleSubmitExpertRequest={handleSubmitExpertRequest}
            origin={HitOrigin.companyPage}
          />
        </SectionHeader>
      </SectionHeaderWrapper>
      <AlphaTable table={table} />
    </SectionWrapper>
  );
};

const SectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export { Experts as default, DataTestIds };
