import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

import { fromPx, toPx } from "pages/AlphaNowPage/utils";
import { SearchSizeVariant, SearchStyleVariant } from "components/Search/consts";

const ADJUSTMENT_BY_SIZE = {
  [SearchSizeVariant.Small]: {
    width: tokens.spacing.inner.base02,
    height: tokens.spacing.inner.base03,
  },
  [SearchSizeVariant.Medium]: {
    width: tokens.spacing.inner.base04,
    height: tokens.spacing.inner.base06,
  },
  [SearchSizeVariant.Large]: {
    width: tokens.spacing.inner.base06,
    height: tokens.spacing.inner.base10,
  },
};

const bringToFront = (
  el: HTMLElement | null | undefined,
  sizeVariant: SearchSizeVariant,
  styleVariant: SearchStyleVariant
) => {
  if (el) {
    el.style.zIndex = "50";
    if (styleVariant === SearchStyleVariant.V2) {
      let { width, height } = el.getBoundingClientRect();
      const { width: widthAdjustment, height: heightAdjustment } = ADJUSTMENT_BY_SIZE[sizeVariant];
      const newWidth = width + fromPx(widthAdjustment);
      const newHeight = height + fromPx(heightAdjustment);
      const translateX = toPx(fromPx(widthAdjustment) / 2);

      el.style.setProperty("position", "absolute");
      el.style.setProperty("width", toPx(newWidth));
      el.style.setProperty("height", toPx(newHeight));
      el.style.setProperty("transform", `translateX(-${translateX})`);
    }
  }
};

const sendToBack = (
  el: HTMLElement | null | undefined,
  styleVariant: SearchStyleVariant,
  inactiveSearchBarHeight: number | undefined
) => {
  if (el && inactiveSearchBarHeight) {
    el.style.zIndex = "0";
    if (styleVariant === SearchStyleVariant.V2) {
      el.style.removeProperty("position");
      el.style.removeProperty("width");
      el.style.setProperty("height", toPx(inactiveSearchBarHeight));
      el.style.setProperty("transform", `translateX(0)`);
    }
  }
};

export { bringToFront, sendToBack };
