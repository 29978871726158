import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { ResponsiveModal } from "components/ResponsiveModal";

export type MessengerSuggestionConfirmationModalProps = {
  onConfirmAction?: () => void;
  action?: string;
  open: boolean;
  loading: boolean;
  onClose: () => void;
};

export const MessengerSuggestionConfirmationModal = ({
  action,
  onConfirmAction,
  open,
  onClose,
  loading,
}: MessengerSuggestionConfirmationModalProps) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();

  return (
    <ResponsiveModal
      variant="fullscreen"
      title={<x.div color={text.strong._}>{action} Suggestions</x.div>}
      open={open}
      onClose={onClose}
      isDialog={true}
      primaryButton={
        <Button
          variant="secondary"
          mr={spacing.inner.base04}
          onClick={onConfirmAction}
          disabled={loading}
          loading={loading}
          data-testid="confirm-button"
        >
          {action} Suggestions
        </Button>
      }
      secondaryButton={
        <Button
          variant="ghost"
          mr={spacing.inner.base04}
          disabled={loading}
          onClick={onClose}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
      }
      testId="send-message-modal"
    >
      <x.div maxWidth="640px" minWidth={!isMobile ? "640px" : undefined}>
        <Typography variant="body">
          Please do not {action?.toLocaleLowerCase()} any suggested edits to any client questions or messages without
          explicit permission from the client. Are you sure?
        </Typography>
      </x.div>
    </ResponsiveModal>
  );
};
