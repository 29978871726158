export const COMMISSION_MP_INFO = Object.freeze({
  title: "Commission types",
  listItems: [
    {
      label: "AlphaNow: ",
      description: "these Primers can be purchased by other AlphaSights clients",
      bullets: [],
    },
    {
      label: "Private: ",
      description:
        "these Primers are hidden from other AlphaSights clients and we put a publish embargo in place on publishing research on the same subject matter:",
      bullets: [
        "Private Company Primers - no Company Primers or Moderated Transcripts published to AlphaNow for the next 3 months on the same company",
        "Private Market Primers - no Market Primers or Moderated Transcripts published to AlphaNow for the next 3 months on the same market",
        "Private Customer Primers - no Customer Primers or Moderated Transcripts published to AlphaNow for the next 3 months on the same market",
      ],
    },
  ],
});

export const PRIMER_INFO = Object.freeze({
  companyPrimer: "Synthesized insights on the company from former executives, competitors and customers.",
  marketPrimer: "Synthesized insights on the market from industry executives.",
  customerPrimer: "Synthesized insights on vendors from key customer decision makers.",
  moderatedTranscript: "Transcribed 60-minute expert call moderated by a former buyside investor",
});

export const COMMISSION_BUTTON_CANCEL = "Cancel";
export const COMMISSION_BUTTON_SUBMIT = "Commission Now";

export const PRIMER_WORKING_DAYS = Object.freeze({
  companyPrimer: "6 - 10 business days",
  marketPrimer: "9 - 12 business days",
  customerPrimer: "6 - 10 business days",
  moderatedTranscript: "3 business days",
});
export const PRIMER_WORKING_DAYS_RUSH = Object.freeze({
  companyPrimer: "<6 business days",
  marketPrimer: "<9 business days",
  customerPrimer: "<6 business days",
  moderatedTranscript: "3 business days",
});

export enum CommissionPrimerProperties {
  //COMMISSION PRIMER
  REQUESTED_COMPANY_ID = "requestedCompanyId",
  REQUESTED_COMPANY_NAME = "requestedCompanyName",
  REQUESTED_COMPANY_FORMAT = "requestedCompanyFormat",
  REQUESTED_COMPANY_TYPE = "requestedCompanyType",
  REQUESTED_COMPANY_RUSH = "rush",
  REQUESTED_COMPANY_LOGO = "logoLink",

  //REQUEST PRIMER
  REQUESTED_COMPANY_WEBSITE = "requestedCompanyWebsite",
}

export const COMMISSION_MODAL_FIT = "150px 164px 154px 76px 58px 47px";
export const COMMISSION_MODAL_INPUT_MIN = 13;

export const COMMISSION_URL_PARAM = "commissionPrimers";

export const SUCCESS_COMMISSION = "Your request to commission research has been received";
export const CASE_CODE = "Case code (optional)";
export const CASE_CODE_PLACEHOLDER = "Input case code";

export const INFORMATION = "Add information (optional)";
export const INFORMATION_PLACEHOLDER = "e.g. a specific research area of focus";

export const companyErrorMessage = "Please add a company";
export const formatErrorMessage = "Please select format";

export const MODERATED_TRANSCRIPT = "Moderated Transcript";
