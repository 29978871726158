import styled from "styled-components";
import { ToggleButtonGroup, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

export const TranscriptToolbarContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return {
    display: "flex",
    flexDirection: "row" as "row",
    flexWrap: "nowrap" as "nowrap",
    alignItems: "center",
    flexGrow: 0,
    marginInline: isMobile ? spacing.inner.base02 : spacing.inner.base06,
    height: isMobile ? spacing.layout.base08 : "unset",
    gap: spacing.inner.base04,
    paddingBlock: spacing.inner.base04,
  };
});

export const StyledToggleButtonGroup = styled(ToggleButtonGroup).attrs({
  size: "small",
  allowUnselection: false,
  wrapperProps: {
    flex: 1,
    border: `${tokens.shape.border.width.sm} solid ${tokens.color.border.neutral.default}`,
  },
})(() => {
  const { spacing, font } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  return {
    height: isMobile ? "28px" : spacing.inner.base08,
    "& button": {
      height: isMobile ? "28px" : spacing.inner.base08,
      ...(isMobile && {
        paddingInline: "10px",
        paddingBlock: "6px",
        fontSize: font.size["02"],
      }),
    },
    ...(isMobile && {
      gap: 0,
    }),
  };
});
