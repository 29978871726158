import { createContext, useContext, useEffect, useState } from "react";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { alphaGPTService } from "services/alphaGPTService";
import { useDeliverableContext } from "./DeliverableProvider";
import { Citation, CitationContentType, Conversation, ProjectAlphaGptServicePlan } from "models/AlphaGpt";
import { useProjectMembersContext } from "providers/ProjectMembersProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { compact } from "lodash";

export interface ProjectAlphaGPTContextState {
  projectToken: string;
  hideLibraryContext: boolean;
  selectedContent?: ContentResults;
  selectedTranscriptRequest?: TranscriptRequest;
  onCitationClick?: (citation: Citation, contentType: CitationContentType) => void;
  selectedUsersIds: number[];
  setSelectedUsersIds: (ids: number[]) => void;
  onStartNewConversation: () => void;
  isAlphaGPTPurchaseExpired: boolean;
  purchaseAccess: (caseCode?: string) => Promise<void>;
  hasAlphaGPTPurchase: boolean;
  hasValidAlphaGPTPurchase: boolean;
  setSelectedContent: (content: ContentResults | undefined) => void;
  setSelectedTranscriptRequest: (transcriptRequest: TranscriptRequest | undefined) => void;
  interactions?: Interaction[];
  isLoadingAlphaGPTPurchase: boolean;
  alphaGPTServicePlan?: ProjectAlphaGptServicePlan;
  allConversations: Conversation[];
  selectedCitation: Citation | undefined;
  hideSearchSuggestions: boolean;
}

export const ProjectAlphaGPTContext = createContext<undefined | ProjectAlphaGPTContextState>(undefined);

export const ProjectAlphaGPTProvider = ({
  service = alphaGPTService,
  projectToken,
  interactions = [],
  children,
  ...props
}: {
  service: typeof alphaGPTService;
  projectToken: string;
  interactions?: Interaction[];
  children: JSX.Element;
}) => {
  const currentUser = useCurrentUser();
  const { fetchAlphaNowContentById } = useDeliverableContext();
  const [selectedContent, setSelectedContent] = useState<ContentResults | undefined>();
  const [selectedTranscriptRequest, setSelectedTranscriptRequest] = useState<TranscriptRequest | undefined>();
  const [selectedCitation, setSelectedCitation] = useState<Citation | undefined>();
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>(
    currentUser ? [currentUser?.clientProfile.id] : []
  );
  const [isAlphaGPTPurchaseExpired, setIsAlphaGPTPurchaseExpired] = useState(false);
  const [hasAlphaGPTPurchase, setHasAlphaGPTPurchase] = useState(false);
  const [isLoadingAlphaGPTPurchase, setIsLoadingAlphaGPTPurchase] = useState(false);
  const [allConversations, setAllConversations] = useState<Conversation[]>([]);
  const [alphaGPTServicePlan, setAlphaGptServicePlan] = useState<ProjectAlphaGptServicePlan>();
  const { clients: members = [] } = useProjectMembersContext();
  const { logHit } = useTrackUserAction();

  const onCitationClick = (citation: Citation, contentType: CitationContentType) => {
    setSelectedCitation(citation);
    if (contentType === "PRIVATE_TRANSCRIPT") {
      const selectedTranscriptRequest = interactions
        ?.flatMap((interaction) => interaction.recordings.flatMap((recording) => recording.transcriptRequests))
        .find((tr) => tr.id === citation.id);
      setSelectedTranscriptRequest(selectedTranscriptRequest);
      setSelectedContent(undefined);
    } else {
      fetchAlphaNowContentById({ id: citation.id }).then((content: ContentResults) => {
        setSelectedContent(content);
        setSelectedTranscriptRequest(undefined);
      });
    }
  };

  const onStartNewConversation = () => {
    if (selectedUsersIds && currentUser && !selectedUsersIds.includes(currentUser?.clientProfile.id)) {
      setSelectedUsersIds((selectedUsersIds) => {
        return [currentUser.clientProfile.id, ...(selectedUsersIds ?? [])];
      });
    }
  };

  const purchaseAccess = (caseCode?: string) => {
    return service
      .createAlphaGPTAccess(projectToken, caseCode)
      .then(() => {
        setIsAlphaGPTPurchaseExpired(false);
        setHasAlphaGPTPurchase(true);
      })
      .then((res) => {
        logHit({
          origin: HitOrigin.projectAlphaGptPage,
          action: HitAction.projectAlphaGptPurchase,
          projectToken: projectToken,
          details: { caseCode },
        });
      });
  };

  useEffect(() => {
    setIsLoadingAlphaGPTPurchase(true);
    service.getAlphaGPTServicePlan(projectToken).then((servicePlan) => {
      setAlphaGptServicePlan(servicePlan);
      if (servicePlan.charge === 0) {
        setIsAlphaGPTPurchaseExpired(false);
        setHasAlphaGPTPurchase(true);
        setIsLoadingAlphaGPTPurchase(false);
        return;
      }

      service
        .getCurrentAlphaGPTPurchase(projectToken)
        .then((response) => {
          setIsAlphaGPTPurchaseExpired(new Date(response.expiresAt) < new Date());
          setHasAlphaGPTPurchase(true);
        })
        .catch((error: { status: number }) => {
          if (error.status === 404) {
            setHasAlphaGPTPurchase(false);
          }
        })
        .finally(() => {
          setIsLoadingAlphaGPTPurchase(false);
        });
    });
  }, [service, projectToken]);

  useEffect(() => {
    let ignoreResponse = false;

    function loadAllConversations() {
      const allClientProfileIds: number[] = [
        ...(currentUser ? [currentUser.clientProfile.id] : []),
        ...compact(members.map((c) => c.clientProfile?.id)),
      ];
      if (hasAlphaGPTPurchase && service.listConversations && projectToken && allClientProfileIds.length) {
        service.listConversations(projectToken, allClientProfileIds).then((response) => {
          if (!ignoreResponse) setAllConversations(response);
        });
      }
    }

    loadAllConversations();
    return () => {
      ignoreResponse = true;
    };
  }, [members, currentUser, hasAlphaGPTPurchase, projectToken, service]);

  const context = {
    projectToken,
    hideLibraryContext: true,
    showUserFilter: true,
    hideSearchSuggestions: true,
    selectedContent,
    setSelectedContent,
    selectedTranscriptRequest,
    setSelectedTranscriptRequest,
    selectedUsersIds,
    setSelectedUsersIds,
    onStartNewConversation,
    isAlphaGPTPurchaseExpired,
    purchaseAccess,
    hasAlphaGPTPurchase,
    hasValidAlphaGPTPurchase: hasAlphaGPTPurchase && !isAlphaGPTPurchaseExpired,
    interactions,
    isLoadingAlphaGPTPurchase,
    alphaGPTServicePlan,
    ...(hasAlphaGPTPurchase && { onCitationClick }),
    allConversations,
    selectedCitation,
    name: "Search",
  };

  return <ProjectAlphaGPTContext.Provider value={context} children={children} {...props} />;
};

export const useProjectAlphaGPTContext = () => {
  const context = useContext(ProjectAlphaGPTContext);
  return context;
};
