import { useEffect, useRef } from "react";
import { IconButton } from "@alphasights/alphadesign-components";
import { Delete, Tick } from "@alphasights/alphadesign-icons";
import * as S from "./TextField.styled";

export const TextField = ({
  value,
  onChange,
  onSubmit,
  onDelete,
  placeholder,
  errorText,
  maxLength,
  testId,
}: {
  value: string;
  onChange: (text: string) => void;
  onSubmit: () => void;
  onDelete: () => void;
  errorText?: string;
  placeholder?: string;
  maxLength?: number;
  testId?: string;
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(function focusOnMount() {
    const element = ref.current;
    if (element) {
      element.focus();
      element.setSelectionRange(element.value.length, element.value.length);
    }
  }, []);

  const hasError = !!errorText;

  return (
    <S.Wrapper>
      <S.TextArea
        ref={ref}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onSubmit={onSubmit}
        isDynamic
        error={hasError}
        helperText={hasError ? errorText : undefined}
        placeholder={placeholder}
        maxLength={maxLength}
        dataAttributes={{
          "data-testid": testId ?? "text-field",
        }}
      />
      <S.ButtonsWrapper hasPaddingBottom={hasError}>
        <IconButton size="small" variant="basic" onClick={onSubmit} testId="confirm-submit">
          <Tick />
        </IconButton>
        <IconButton size="small" variant="basic" onClick={onDelete} testId="confirm-delete">
          <Delete />
        </IconButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};
