import { FC } from "react";
import { CheckboxGroup, Checkbox, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { CheckboxOptionsWrapper } from "./CheckboxFilter.styled";

type CheckboxFilterProps = {
  filterKey: string;
  value: string[];
  options: { label: string; value: string }[];
  onChange: (value: Record<string, any>) => void;
};

const CheckboxFilter: FC<CheckboxFilterProps> = ({ filterKey, value, options, onChange }) => {
  const { color } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const handleChange = (payload: string[]) => {
    onChange({ [filterKey]: payload });
  };

  return (
    <CheckboxGroup
      value={value}
      onChange={(payload: any) => handleChange(payload as string[])}
      variant={isMobile ? "boxed" : "default"}
      size={isMobile ? "regular" : "small"}
    >
      <CheckboxOptionsWrapper>
        {options.map(({ label, value }) => (
          <Checkbox
            data-testid={`checkbox-${filterKey}-${value}`}
            key={`${filterKey}-${value}`}
            value={value}
            color={color.text.strong._}
          >
            {label}
          </Checkbox>
        ))}
      </CheckboxOptionsWrapper>
    </CheckboxGroup>
  );
};

export default CheckboxFilter;
