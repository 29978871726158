import { useState, useContext, useRef } from "react";
import { useTimezone } from "../../providers/TimezoneProvider";
import { InteractionsList } from "./InteractionsList";
import { RequestPopup } from "./RequestModal";
import { DispatchContext } from "../../components/InteractionsPage/DispatchContext";
import { RequestAvailabilityCalendar, mergeIntersecting } from "./RequestAvailabilityCalendar";
import { isFuture } from "date-fns";
import { parseISO } from "../../providers/TimezoneProvider";
import { requestChangeInteraction } from "components/InteractionsPage/reducer";
import { useIsOverflow, useNotifications } from "@alphasights/client-portal-shared";
import { Button, Checkbox, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { HitOrigin } from "@alphasights/portal-api-client";

export const RescheduleRequestPopup = ({ id, onClose, onSubmit, interaction }) => {
  return (
    <RequestPopup
      id={id}
      onClose={onClose}
      onSubmit={(reason) => onSubmit({ id, reason, type: "RESCHEDULE_REQUEST" })}
      actionName="Reschedule"
      actionPastName="rescheduled"
      interaction={interaction}
    />
  );
};

export const RequestAvailabilityView = ({
  selectedInteractions,
  token,
  events,
  reschedulingInteractionId,
  onGoBack,
  onSubmitClientAvailability,
  currentDate,
  setCurrentDate,
  experts,
  ...props
}) => {
  const dispatch = useContext(DispatchContext);
  const listRef = useRef();
  const isListOverflowing = useIsOverflow(listRef, [], "vertical");
  const { wrapper, calendarWrapper, interactionsList, title, buttons, cancel } = useRequestAvailabilityViewStyles({
    isListOverflowing,
  });

  const savedValidAvailabilities = selectedInteractions
    .map((i) => i.clientTimeslots)
    .filter(Boolean)
    .flat()
    .map((ct) => ({
      start: parseISO(ct.startsAt),
      end: parseISO(ct.endsAt),
      source: "request",
    }))
    .filter((ct) => isFuture(ct.start));
  const [availabilities, setAvailabilites] = useState(mergeIntersecting(savedValidAvailabilities));
  const [projectWide, setProjectWide] = useState(false);
  const [requestPopupOpen, setRequestPopupOpen] = useState(false);
  const showingEvents = events
    .filter((event) => event.source === "interaction")
    .map((event) => ({
      ...event,
      highlight: event.interaction.id === reschedulingInteractionId,
    }));

  const tz = useTimezone();
  const { showSuccessBanner } = useNotifications();

  const onSubmit = async () => {
    const interactionIds = selectedInteractions.map(({ id }) => id);
    const payload = {
      slots: availabilities.map(({ start, end }) => ({
        startsAt: start,
        endsAt: end,
      })),
      timezone: tz.currentTimezone,
      interactionIds,
      projectWide,
      origin: HitOrigin.calendarView,
    };

    await onSubmitClientAvailability(payload);

    if (reschedulingInteractionId) {
      setRequestPopupOpen(true);
    } else {
      showSuccessBanner(
        "Thank you for submitting your availability. We will relay this to the expert and be in touch as soon as possible."
      );
      onGoBack();
    }
  };

  return (
    <>
      <x.div {...wrapper}>
        <Typography data-testid="request-availability-text" {...title}>
          Let us know what times you're available for a call with the selected experts.
        </Typography>
        <x.div ref={listRef} {...interactionsList}>
          <InteractionsList
            selectedInteractionIds={selectedInteractions.map(({ id }) => id)}
            {...props}
            onRequestFollowUp={null}
            experts={experts}
            allowMenuActions={false}
          />
        </x.div>
        <x.div {...buttons}>
          {!reschedulingInteractionId && (
            <Checkbox checked={projectWide} onChange={({ currentTarget: { checked } }) => setProjectWide(checked)}>
              Save this availability for all Experts on the project (this will encourage Experts to work within your
              availability).
            </Checkbox>
          )}
          <Button
            variant="secondary"
            onClick={onSubmit}
            w="100%"
            dataAttributes={{ "data-testid": "save-availability-button" }}
          >
            Save availability
          </Button>
          <Button variant="ghost" onClick={onGoBack} {...cancel}>
            Cancel
          </Button>
        </x.div>
      </x.div>
      <x.div {...calendarWrapper}>
        <RequestAvailabilityCalendar
          showingEvents={showingEvents}
          availabilities={availabilities}
          setAvailabilites={setAvailabilites}
          token={token}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          {...props}
        />
      </x.div>
      {requestPopupOpen && (
        <RescheduleRequestPopup
          id={reschedulingInteractionId}
          onClose={() => {
            setRequestPopupOpen(false);
            onGoBack();
          }}
          onSubmit={(state) => dispatch(requestChangeInteraction(state))}
        />
      )}
    </>
  );
};

const useRequestAvailabilityViewStyles = ({ isListOverflowing }) => {
  const {
    spacing: { inner },
    color: { border, background },
    shape,
  } = useThemeTokens();
  return {
    wrapper: {
      borderRight: `${shape.border.width.sm} ${border.divider} solid`,
      flex: "525px 0 0",
      display: "flex",
      flexDirection: "column",
      bg: background.surface.recessed,
    },
    title: {
      p: inner.base06,
      pb: inner.base02,
    },
    buttons: {
      p: inner.base06,
      pt: inner.base02,
      borderTop: isListOverflowing ? `${shape.border.width.sm} ${border.divider} solid` : undefined,
      bg: isListOverflowing ? background.surface.page.default : undefined,
      display: "flex",
      flexDirection: "column",
      gap: inner.base02,
    },
    cancel: {
      w: "100%",
      py: inner.base02,
    },
    calendarWrapper: {
      w: "100%",
      h: "100%",
    },
    interactionsList: {
      overflowY: "auto",
      px: inner.base06,
      py: inner.base02,
      flexGrow: 1,
    },
  };
};
