import { Icon, XStyledProps } from "@alphasights/alphadesign-components";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";
import { Company } from "@alphasights/alphadesign-icons";
import {
  CompanyPillContainer,
  StartAdornmentContainer,
  StyledStartAdornment,
  StyledTypography,
} from "./FeaturedCompanyPill.styled";

type FeaturedCompanyPillProps = {
  iconUrl: string;
  label: string;
  size: "small" | "medium" | "large";

  isSelected?: boolean;
  isGrayscale?: boolean;
  nameMaxLength?: number;
  onClick?: () => void;
  dataTestId?: string;
  isHighlighted?: boolean;
} & XStyledProps;

const FeaturedCompanyPill = ({
  iconUrl,
  label,
  isSelected = false,
  isGrayscale = false,
  isHighlighted = false,
  nameMaxLength = Number.MAX_VALUE,
  dataTestId = "",
  size,
  onClick,
  ...props
}: FeaturedCompanyPillProps) => {
  const [labelTruncated, tooltip] = getTruncatedTextAndTooltip(label, nameMaxLength);

  return (
    <CompanyPillContainer
      isHighlighted={isHighlighted}
      isInteractive={!!onClick}
      isSelected={isSelected}
      onClick={onClick}
      data-testid={dataTestId}
      {...props}
    >
      <StartAdornmentContainer size={size}>
        <StyledStartAdornment isGrayscale={isGrayscale} backgroundImage={`url(${iconUrl})`}>
          {!iconUrl && (
            <Icon color="secondary" size={size}>
              <Company />
            </Icon>
          )}
        </StyledStartAdornment>
      </StartAdornmentContainer>
      <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
        <StyledTypography size={size} isGrayscale={isGrayscale}>
          {labelTruncated as string}
        </StyledTypography>
      </ConditionalTooltip>
    </CompanyPillContainer>
  );
};

export default FeaturedCompanyPill;
