import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useNotifications } from "@alphasights/client-portal-shared";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as React from "react";
import { alphaGPTService } from "services/alphaGPTService";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { sampleConversation, sampleMessage } from "content/AlphaGPT";
import { Conversation, Message, ClientProfile, Citation, AlphaGptLibrary } from "models/AlphaGpt";
import { ProjectAlphaGPTContextState } from "./ProjectAlphaGPTProvider";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import _ from "lodash";
import { useDeliverableContext } from "./DeliverableProvider";
import { AlphaNowContentType } from "@alphasights/client-portal-shared";

export type AlphaGPTContextState = {
  conversation?: Conversation;
  conversations: Conversation[];
  deleteConversation: (conversationId: string) => Promise<void>;
  errorMessage: string | undefined;
  hasAlphaGPTPurchase: boolean;
  hasValidAlphaGPTPurchase: boolean;
  isConversationFromCurrentUser: boolean;
  isLoading: boolean;
  isLoadingConversations: boolean;
  loadingMessage?: LoadingMessage;
  messages: Message[];
  selectConversation: (conversation: Conversation) => Promise<void>;
  service: typeof alphaGPTService;
  startNewConversation: () => void;
  submitQuery: (query: string) => Promise<void>;
  selectedLibraries: AlphaGptLibrary[] | undefined;
  query?: string;
  setQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedLibraries: React.Dispatch<React.SetStateAction<AlphaGptLibrary[] | undefined>>;
  libraryOptions: LibraryOption[];
  freePrimerIds: string[];
  searchContent: (
    keywords: string[],
    pageSize?: number,
    contentTypes?: AlphaNowContentType[]
  ) => Promise<ContentResults[]>;
  name: string;
  requestedSpeakersIds: number[];
  setRequestedSpeakersIds: React.Dispatch<React.SetStateAction<number[]>>;
} & Partial<ProjectAlphaGPTContextState>;

export interface AlphaGPTContextProps {
  service?: typeof alphaGPTService;
  errorMessage?: string;
  conversation?: Conversation;
  children?: JSX.Element;
  projectToken?: string;
  selectedUsersIds?: number[];
  onStartNewConversation?: () => void;
  hasAlphaGPTPurchase?: boolean;
}

interface LoadingMessage {
  message: string;
  duration?: number;
}

interface LibraryOption {
  label: string;
  secondaryText: string;
  disabled: boolean;
  libraries: AlphaGptLibrary[];
  isAllLibraries?: Boolean;
  tooltip?: string;
}

export const AlphaGPTContext = React.createContext<undefined | AlphaGPTContextState>(undefined);

const FETCH_RESPONSE_INTERVAL = 3000;

const LOADING_MESSAGES: LoadingMessage[] = [
  {
    message:
      "It will take up to a minute to search through all available content to provide you with the most accurate response...",
    duration: 10000,
  },
  {
    message: "Processing query...",
    duration: 20000,
  },
  {
    message: "Interrogating expert research library...",
    duration: 20000,
  },
  {
    message: "Assimilating expert insights...",
    duration: 20000,
  },
  {
    message: "Finalising answer and checking citations...",
  },
];

export const AlphaGPTProvider = ({
  service = alphaGPTService,
  errorMessage: errorMessageInput = undefined,
  conversation: conversationInput = undefined,
  children,
  projectToken = undefined,
  selectedUsersIds = undefined,
  onStartNewConversation = undefined,
  hasAlphaGPTPurchase = true,
  ...props
}: AlphaGPTContextProps) => {
  const currentUser = useCurrentUser()!;
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [conversation, setConversation] = useState<Conversation | undefined>(conversationInput);
  const [messages, setMessages] = useState<Message[]>([]);
  const [requestedSpeakersIds, setRequestedSpeakersIds] = useState<number[]>([]);
  const lastMessage = useMemo(() => messages[messages.length - 1], [messages]);
  const [query, setQuery] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchResponseTimer, setFetchResponseTimer] = useState<NodeJS.Timer | undefined>();
  const [errorMessage, setErrorMessage] = useState(errorMessageInput);
  const [loadingMessages, setLoadingMessages] = useState<{
    [key: string]: LoadingMessage | undefined;
  }>({});
  const [loadingMessagesTimers, setLoadingMessagesTimers] = useState<{
    [key: string]: NodeJS.Timeout | undefined;
  }>({});
  const [isLoadingConversations, setIsLoadingConversations] = useState(false);
  const [freePrimerIds, setFreePrimerIds] = useState<string[]>([]);
  const [selectedLibraries, setSelectedLibraries] = useState<AlphaGptLibrary[]>();
  const { hasInteractionDeliverables } = useDeliverableContext() ?? {};

  const trackUserAction = useTrackUserAction();
  const { showErrorBanner } = useNotifications();

  useEffect(
    function getFreePrimerIds() {
      service.fetchFreePrimerIds().then((res) => setFreePrimerIds(res));
    },
    [service]
  );

  const sendConversationToTop = useCallback(() => {
    const thisConversation = conversations.find((c) => c.id === conversation?.id);
    if (thisConversation) {
      setConversations([thisConversation, ...conversations.filter((c) => c.id !== thisConversation.id)]);
    }
  }, [conversation, conversations]);

  const fetchFollowUpQuestions = useCallback(
    (conversationId: string, message: Message) =>
      service.getFollowUpQuestions(conversationId, message.id).then((response) => {
        setMessages((messages) =>
          messages.map((m) => {
            if (m.id === message.id) {
              return { ...m, followUpQuestions: response };
            }
            return m;
          })
        );
      }),
    [service]
  );

  const fetchResponse = useCallback(() => {
    if (!conversation?.id || !lastMessage?.id) return;

    const stopLoading = () => {
      setIsLoading(false);
      setLoadingMessages((prevState) => ({
        ...prevState,
        [conversation.id]: undefined,
      }));
      const timer = loadingMessagesTimers[conversation.id];
      if (timer) {
        clearTimeout(timer);
        setLoadingMessagesTimers((prevState) => ({
          ...prevState,
          [conversation.id]: undefined,
        }));
      }
    };

    service
      .findMessages(conversation.id, lastMessage.id)
      .then((response) => {
        if (response?.messages?.length > 0) {
          const newMessages = response.messages.map((message) => ({
            ...message,
            sender: "GPT",
          }));

          setMessages([...messages, ...newMessages]);
          setErrorMessage((response?.error?.length ?? 0) > 0 ? response.error : undefined);
          stopLoading();
          return newMessages;
        }
      })
      .then((newMessages) => {
        if (newMessages && newMessages.length > 0) {
          fetchFollowUpQuestions(conversation.id, newMessages[newMessages.length - 1]);
        }
      })
      .catch((error) => {
        if (error && error.json) {
          error.json().then((e: { status: number; message: string }) => {
            setErrorMessage(e.status === 429 ? e.message : `There was an error processing the request: ${e.message}`);
          });
        } else {
          setErrorMessage("There was an error processing the request");
        }
        stopLoading();
      });
  }, [conversation, lastMessage, service, loadingMessagesTimers, messages, fetchFollowUpQuestions]);

  const updateLoadingMessage = useCallback(
    (conversationId: string, previousLoadingMessage: LoadingMessage | undefined = undefined) => {
      const loadingMessage =
        LOADING_MESSAGES[previousLoadingMessage ? LOADING_MESSAGES.indexOf(previousLoadingMessage) + 1 : 0];
      setLoadingMessages((prevState) => ({
        ...prevState,
        [conversationId]: loadingMessage,
      }));
      if (loadingMessage && loadingMessage.duration) {
        const timer = setTimeout(() => updateLoadingMessage(conversationId, loadingMessage), loadingMessage.duration);
        setLoadingMessagesTimers((prevState) => ({
          ...prevState,
          [conversationId]: timer,
        }));
      }
    },
    []
  );

  const sendMessage = useCallback(
    (conversationId: string, text: string) =>
      service
        .sendMessage(conversationId, text)
        .then(({ id }) => {
          setMessages([...messages, { sender: "User", text, id }]);
          setIsLoading(true);
          sendConversationToTop();
          return id;
        })
        .then((id) => {
          trackUserAction.logHit({
            action: HitAction.alphaGptSendMessage,
            origin: HitOrigin.alphaGptPage,
            projectToken,
            details: { conversationId, messageId: id, projectToken },
          });
        }),
    [messages, trackUserAction, service, sendConversationToTop, projectToken]
  );

  const startNewConversation = useCallback(() => {
    setConversation(undefined);
    setMessages([]);
    setErrorMessage(undefined);
    setIsLoading(false);
    onStartNewConversation && onStartNewConversation();
  }, [onStartNewConversation]);

  const deleteConversation = useCallback(
    (conversationId: string) => {
      setIsLoading(false);
      return service
        .deleteConversation(conversationId)
        .then(() => {
          setConversations((conversations) => conversations.filter((c) => c.id !== conversationId));
          startNewConversation();
        })
        .catch((error) => {
          if (error && error.json) {
            error.json().then((e: { message: string }) => {
              showErrorBanner(`There was an error deleting the conversation: ${e.message}`);
            });
          } else {
            showErrorBanner("There was an error deleting the conversation");
          }
        });
    },
    [service, startNewConversation, showErrorBanner]
  );

  const submitQuery = useCallback(
    (query: string) => {
      if (conversation?.id) {
        return sendMessage(conversation.id, query).catch(() => {
          showErrorBanner("Error sending message, please try again");
        });
      }
      return new Promise<void>((resolve) => {
        service
          .createConversation(query, projectToken, selectedLibraries)
          .then((conversationResponse) => {
            const conversation = {
              id: conversationResponse.id,
              title: query,
              clientProfile: currentUser.clientProfile as ClientProfile,
              libraries: selectedLibraries,
            };
            setConversation(conversation);
            setConversations([conversation, ...conversations]);
            trackUserAction.logHit({
              origin: HitOrigin.alphaGptPage,
              action: HitAction.alphaGptCreateConversation,
              details: { conversationId: conversation.id },
            });
            resolve(
              sendMessage(conversation.id, query).catch(() => {
                deleteConversation(conversation.id);
                showErrorBanner("Error starting conversation, please try again");
              })
            );
          })
          .catch(() => {
            showErrorBanner("Error starting conversation, please try again");
          });
      });
    },
    [
      conversations,
      conversation,
      sendMessage,
      trackUserAction,
      service,
      currentUser,
      projectToken,
      selectedLibraries,
      showErrorBanner,
      deleteConversation,
    ]
  );

  const selectConversation = useCallback(
    (conversation: Conversation) => {
      setIsLoading(false);
      setQuery("");
      return service
        .findMessages(conversation.id)
        .then((response) => {
          if (response?.messages?.length > 0) {
            const messages = response.messages.map((message) => ({
              ...message,
              sender: message.sender === "ai" ? "GPT" : "User",
            }));
            const lastMessage = messages[messages.length - 1];
            const error = (response?.error?.length ?? 0) > 0 ? response.error : undefined;

            setConversation(conversation);
            setErrorMessage(error);
            setMessages(messages);
            setIsLoading(!error && lastMessage.sender === "User");
          }
        })
        .catch((error) => {
          setConversation(conversation);
          setMessages([]);
          if (error && error.json) {
            error.json().then((e: { message: string }) => {
              setErrorMessage(`There was an error loading the conversation: ${e.message}`);
            });
          } else {
            setErrorMessage("There was an error loading the conversation");
          }
        });
    },
    [service]
  );

  const searchContent = useCallback(
    (keywords: string[], pageSize?: number, contentTypes?: AlphaNowContentType[]) => {
      return service.searchContent(keywords, pageSize, contentTypes, projectToken);
    },
    [service, projectToken]
  );

  useDeepCompareEffect(() => {
    if (!hasAlphaGPTPurchase) {
      const conversation = {
        ...sampleConversation,
        clientProfile: currentUser.clientProfile as ClientProfile,
      };
      sampleMessage.forEach((message) => {
        message.citations
          ?.filter((citation) => citation.showLink)
          .forEach(
            (citation: Citation) =>
              (citation.link = `/${projectToken}/experts/deliverables-view/?selectedContentId=${freePrimerIds[0]}`)
          );
      });
      setConversations([conversation]);
      setConversation(conversation);
      setMessages(sampleMessage);
    } else if (service.listConversations) {
      setConversations([]);
      setConversation(undefined);
      setMessages([]);
      setIsLoadingConversations(true);
      service
        .listConversations(projectToken, selectedUsersIds)
        .then((response) => {
          setIsLoading(false);
          setConversation(undefined);
          setMessages([]);
          setConversations(response);
        })
        .finally(() => {
          setIsLoadingConversations(false);
        });
    }
  }, [service, projectToken, selectedUsersIds, hasAlphaGPTPurchase, currentUser, freePrimerIds]);

  useEffect(() => {
    clearInterval(fetchResponseTimer);
    if (isLoading) {
      setFetchResponseTimer(setInterval(fetchResponse, FETCH_RESPONSE_INTERVAL));
      conversation && !loadingMessages[conversation.id] && updateLoadingMessage(conversation.id);
    } else if (fetchResponseTimer) {
      setFetchResponseTimer(undefined);
    }
  }, [isLoading, conversation, updateLoadingMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const isConversationFromCurrentUser = useMemo(() => {
    return conversation?.clientProfile.id === currentUser.clientProfile.id;
  }, [conversation, currentUser]);

  const libraryOptions: LibraryOption[] = useMemo(() => {
    const libraries = [
      {
        label: "AlphaSights Library",
        secondaryText: "Search AlphaSights' proprietary expert knowledge base.",
        disabled: false,
        order: 2,
        libraries: [AlphaGptLibrary.AlphaSights],
        hidden: false,
      },
      {
        label: "Private Library",
        secondaryText: "Search your organization's private library.",
        disabled: !currentUser.privateTranscriptLibraryEnabled,
        order: 3,
        libraries: [AlphaGptLibrary.Private],
        hidden: projectToken !== undefined,
      },
      {
        label: "Project Transcripts",
        disabled: !hasInteractionDeliverables,
        secondaryText: "Search through your project transcripts.",
        libraries: [AlphaGptLibrary.Project],
        hidden: projectToken === undefined,
        tooltip: !hasInteractionDeliverables ? "You don’t currently have any transcripts on this project." : undefined,
      },
    ]
      .filter((l) => !l.hidden)
      .map(({ hidden, ...props }) => props);

    const allLibraries = {
      label: "All Libraries",
      disabled: libraries.filter((library) => !library.disabled).length <= 1,
      secondaryText: `Search your ${
        projectToken === undefined ? "organization's private library" : "project transcripts"
      } and AlphaSights' proprietary expert knowledge base.`,
      order: 1,
      libraries: libraries.filter((library) => !library.disabled).flatMap((library) => library.libraries),
      isAllLibraries: true,
    };

    libraries.push(allLibraries);

    return _.sortBy(libraries, "order").map(({ order, ...otherProps }) => otherProps);
  }, [currentUser.privateTranscriptLibraryEnabled, projectToken, hasInteractionDeliverables]);

  const context = {
    conversation,
    conversations,
    isLoading,
    messages,
    startNewConversation,
    submitQuery,
    errorMessage,
    selectConversation,
    deleteConversation,
    loadingMessage: conversation && loadingMessages[conversation?.id],
    service,
    isConversationFromCurrentUser,
    hasValidAlphaGPTPurchase: true,
    hasAlphaGPTPurchase,
    projectToken,
    selectedUsersIds,
    onStartNewConversation,
    isLoadingConversations,
    selectedLibraries,
    setSelectedLibraries,
    libraryOptions,
    query,
    setQuery,
    freePrimerIds,
    hideLibraryContext: true,
    searchContent,
    name: "AlphaGPT",
    requestedSpeakersIds,
    setRequestedSpeakersIds,
    ...props,
  };

  return <AlphaGPTContext.Provider value={context} children={children} />;
};

export const useAlphaGPTContext = () => {
  const context = useContext(AlphaGPTContext);

  if (!context) throw new Error("AlphaGPTContext should only be used within the AlphaGPTProvider");

  return context;
};
