import { x } from "@xstyled/styled-components";
import { Typography } from "@alphasights/alphadesign-components";
import { Badge } from "components/Badge";
import { AlphaCircle } from "@alphasights/alphadesign-icons";
import { useExpertChargesListStyles } from "./ExpertChagesList.styles";
import { ExpertCharge } from "types";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useWorkRequestCredits } from "hooks/messenger/useWorkRequestCredits";
import { ProjectFeature } from "@alphasights/portal-api-client";

export const ExpertChargesList = ({
  experts = [],
  duration,
  projectToken,
}: {
  experts?: ExpertCharge[];
  duration: number;
  projectToken: string;
}) => {
  const { container, expertName, expertDiv } = useExpertChargesListStyles();
  const { isFeatureDisabled } = useCurrentProjectContext();

  const { isCreditsLoading, calculatePerExperts, convertCreditsToString } = useWorkRequestCredits({
    projectToken,
    shouldFetch: true,
    duration,
  });

  if (isCreditsLoading || experts == null || experts.length === 0) return null;

  const expertsCharges = experts.map((expert) => {
    return {
      ...expert,
      credits: convertCreditsToString(calculatePerExperts([expert])),
    };
  });

  return (
    <x.div {...container} data-testid="expert-charges-list">
      {expertsCharges.map((expert, index: number) => (
        <x.div {...expertDiv} key={`expert-charge-${index}`}>
          <Typography variant="body" {...expertName}>
            {`${expert.credits}  •  ${expert.name}`}
          </Typography>
          {(expert.alphaCircleMultiplier ?? 1) > 1 && !isFeatureDisabled(ProjectFeature.AlphaCircleMultiplier) && (
            <Badge
              tooltipText={`Available at a rate of ${expert.alphaCircleMultiplier} credits per hour`}
              value={expert.alphaCircleMultiplier?.toString()}
              icon={<AlphaCircle />}
              data-testid="alphacircle-badge"
            />
          )}
        </x.div>
      ))}
    </x.div>
  );
};
