import { x } from "@xstyled/styled-components";
import { Divider, Link, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaNowProductType } from "@alphasights/client-portal-shared";
import useModal from "hooks/useModal";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import ReturnButton from "../../ReturnButton/ReturnButton";
import { freePrimers } from "pages/AlphaNowPage/primers/utils/constants";
import { CommissionModal, CommissionFooter } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";

const RequestPrimerView = ({ onReturnToMobileSearch }: { onReturnToMobileSearch: () => void }) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const { isVisible, onOpen, onClose } = useModal();
  const { isMobile } = useCheckScreen();

  const freePrimerLink = (ProductType: AlphaNowProductType) => {
    let type = "";
    let freeLink = "";
    switch (ProductType) {
      case AlphaNowProductType.companyPrimer:
        type = "Company";
        freeLink = freePrimers.companyPrimer;
        break;
      case AlphaNowProductType.marketPrimer:
        type = "Market";
        freeLink = freePrimers.marketPrimer;
        break;
      case AlphaNowProductType.customerPrimer:
        type = "Customer";
        freeLink = freePrimers.customerPrimer;
        break;
    }

    return (
      <x.div pb={inner.base06} pt={inner.base03} pl={inner.base08}>
        <Link href={freeLink} size="medium" target="_blank">
          View a free {type} Primer
        </Link>
      </x.div>
    );
  };

  const requestPrimerInfoList = [
    {
      title: "What is a Company Primer?",
      listItems: [
        {
          value: "Company Primers are expert-led company overviews. Replace ‘day 1’ desktop research",
          property: "root",
        },

        {
          value: "We synthesize expert insights on a company's background, competitive dynamics, and outlook",
          property: "root",
        },

        {
          value: "Click on a sentence to see the contributing expert(s) and request a call",
          property: "root",
        },
      ],
      freeLink: freePrimerLink(AlphaNowProductType.companyPrimer),
    },
    {
      title: "What is a Market Primer?",
      listItems: [
        {
          value: "Market Primers are expert-led market overviews that replace 'day 1' desktop research",
          property: "root",
        },

        {
          value:
            "We synthesize expert insights on a market's size and growth, competitor dynamics, segmentation and outlook",
          property: "root",
        },

        {
          value: "Click on a sentence to see the contributing expert(s) and request a call",
          property: "root",
        },
      ],
      freeLink: freePrimerLink(AlphaNowProductType.marketPrimer),
    },
    {
      title: "What is a Customer Primer?",
      listItems: [
        {
          value: "Customer Primers are collected customer insights on a set of vendors",
          property: "root",
        },

        {
          value: "We exhibit customer insights on vendors' usage, performance on KPCs, NPS, churn and outlook",
          property: "root",
        },

        {
          value: "Click on a sentence or into a graph to see the contributing expert(s) and request a call",
          property: "root",
        },
      ],
      freeLink: freePrimerLink(AlphaNowProductType.customerPrimer),
    },
    {
      title: "Information",
      listItems: [
        {
          value:
            "Private Primers are visible to you and colleagues on your project. AlphaNow Primers can be purchased by other AlphaSights clients",
          property: "root",
        },
        {
          value:
            "You will receive Company Primers within 10 working days, and Market and Customer Primers within 15 working days",
          property: "root",
        },

        {
          value: "Payment is taken on delivery",
          property: "root",
        },
      ],
    },
  ];

  return (
    <>
      <x.div pl={inner.base06}>
        {isMobile && <ReturnButton onReturn={onReturnToMobileSearch} style={{ ml: `-${inner.base03}` }} />}
      </x.div>
      <x.div p={inner.base06}>
        {requestPrimerInfoList.map((list) => {
          const { title, listItems, freeLink } = list;

          return (
            <x.div key={list.title} pb={inner.base02}>
              <Typography variant="body-em" color={text.secondary}>
                {title}
              </Typography>

              <x.ul listStyleType="initial" pl={inner.base06}>
                {listItems.map((item) => {
                  return item.property === "root" ? (
                    <>
                      <x.ul listStyleType="initial" pl={inner.base06}>
                        <x.li key={item.value}>
                          <Typography>{item.value}</Typography>
                        </x.li>
                      </x.ul>
                    </>
                  ) : (
                    <x.ul pl={inner.base06}>
                      <x.li key={item.value}>
                        <x.ul listStyleType="initial" pl={inner.base06}>
                          <x.li>
                            <Typography>{item.value}</Typography>
                          </x.li>
                        </x.ul>
                      </x.li>
                    </x.ul>
                  );
                })}
              </x.ul>
              {freeLink}
            </x.div>
          );
        })}

        <Divider />
        <CommissionFooter onOpen={onOpen} />
        <Divider />
      </x.div>
      {isVisible && <CommissionModal onClose={onClose} isEmptyResults />}
    </>
  );
};

export default RequestPrimerView;
