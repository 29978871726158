import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  min,
  parseISO,
  isFuture,
  startOfDay,
  endOfWeek,
  addDays,
  endOfDay,
  isSameDay,
  isWithinInterval,
  isBefore,
  addSeconds,
} from "date-fns";
import { Alert, Typography, Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { Clock, ClockPending, Close } from "@alphasights/alphadesign-icons";
import { FormattedDateTime, FormattedTimeRange } from "providers/TimezoneProvider";
import * as S from "./CalendarBanners.styled";
import { x } from "@xstyled/styled-components";

const CalendarBanner = ({
  children,
  actionLabel,
  actionOnClick,
  className,
  color = "highlight-1",
  showIcon = true,
  icon,
  permanent = false,
  ...props
}) => {
  const [isShowing, setIsShowing] = useState(true);
  const { spacing } = useThemeTokens();

  return (
    isShowing && (
      <Alert variant="info" style={{ alignItems: "center", marginBottom: spacing.layout.base }} {...props}>
        <x.div display="flex" alignItems="center">
          {children}

          {actionLabel && (
            <Typography
              data-testid="banner-action"
              variant="body-em"
              onClick={actionOnClick}
              cursor="pointer"
              color="info"
              px={2}
            >
              {actionLabel}
            </Typography>
          )}

          {!permanent && (
            <Icon onClick={() => setIsShowing(false)}>
              <Close />
            </Icon>
          )}
        </x.div>
      </Alert>
    )
  );
};

/**
 * Check if a date is part of the weekend of a given week
 * (the given week is informed by it's starting date)
 *
 * @param {Date} date - date to be checked.
 * @param {Date} start - start date of the given week.
 * @param {boolean} sundayWorkday - must be true if sunday a workday.
 */
const isWeekend = (date, start, sundayWorkday) => {
  const weekStartsOn = sundayWorkday ? 6 : 0;

  const firstWeekendDay = startOfDay(endOfWeek(start, { weekStartsOn }));
  const secondWeekendDay = endOfDay(addDays(firstWeekendDay, 1));

  return isSameDay(date, firstWeekendDay) || isSameDay(date, secondWeekendDay);
};

const futureWeekendAvailability = (interactions, start, sundayWorkday) => {
  const availabilitiesOnWeekend = interactions
    .filter(({ state, advisorAvailability }) => state !== "scheduled" && advisorAvailability)
    .flatMap(({ advisorAvailability }) => advisorAvailability)
    .map(({ startsAt }) => parseISO(startsAt))
    .filter((date) => isFuture(date))
    .filter((date) => isWeekend(date, start, sundayWorkday));

  const firstDate = min(availabilitiesOnWeekend);
  return !isNaN(firstDate) ? firstDate : null;
};

/**
 * Checks any element of a list of dates is inside a given interval (range).
 * This function also checks if the first weekend should be considered in the interval.
 * If `sundayWorkday` (null by default) is a valid boolean it will not consider
 * the first weekend to be part of the interval.
 *
 * @param {Array<Date>} dates - list of dates to be checked.
 * @param {Date} start - start date of the given interval.
 * @param {Date} end - end date of the given interval.
 * @param {boolean} sundayWorkday - (default null) must be true if sunday a workday
 * or null if the first weekend should be considered.
 */
const anyInside = (dates, start, end, sundayWorkday = null) => {
  const onlyWeekdays = sundayWorkday != null;
  return (
    start &&
    end &&
    dates.some(
      (date) => isWithinInterval(date, { start, end }) && (onlyWeekdays ? !isWeekend(date, start, sundayWorkday) : true)
    )
  );
};

const UpcomingAvailabilityBanner = ({
  interactions,
  weekendAvailability,
  onViewUpcoming,
  start,
  end,
  className,
  sundayWorkday = null,
}) => {
  const [forceShow, setForceShow] = useState(false);
  const dates = interactions
    .filter(
      ({ state, followUpId, advisorAvailability }) =>
        advisorAvailability && state !== "scheduled" && !(state === "completed" && followUpId)
    )
    .flatMap(({ advisorAvailability }) => advisorAvailability)
    .map(({ startsAt }) => parseISO(startsAt))
    .filter((date) => isFuture(date))
    .filter((date) => (weekendAvailability ? isBefore(date, weekendAvailability) : true));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const anyVisible = entries.find(({ isIntersecting }) => isIntersecting);
        setForceShow(!anyVisible);
      },
      {
        rootMargin: "0px",
      }
    );

    setTimeout(() => {
      const availabilities = [
        ...document.querySelectorAll("[id^=availability-wrapper] .rbc-event"),
        ...document.querySelectorAll("[id^=expert-availability-wrapper] .rbc-event"),
      ];
      availabilities.map((event) => observer.observe(event));
    }, 50);

    return () => {
      observer.disconnect();
      setForceShow(false);
    };
  }, [start, end, interactions]);

  if (dates.length === 0) return null;
  if (anyInside(dates, start, end, sundayWorkday) && !forceShow) return null;

  return (
    <CalendarBanner
      data-testid="calendar-banner-availability"
      className={className}
      actionLabel="View"
      actionOnClick={() => onViewUpcoming(new Date(min(dates).getTime() + Math.random() * 100))}
    >
      Jump to first available day.
    </CalendarBanner>
  );
};

const ShowCompletedInteractionBanner = ({
  interactions,
  onViewUpcoming,
  start,
  end,
  className,
  sundayWorkday = null,
}) => {
  const dates = interactions
    .filter(({ state }) => state === "completed")
    .map(({ scheduledCallTime }) => scheduledCallTime)
    .map(parseISO);

  if (dates.length === 0) return null;

  const inside = anyInside(dates, start, end, sundayWorkday);

  return (
    !inside && (
      <CalendarBanner
        data-testid="calendar-banner-completed"
        className={className}
        actionLabel="View"
        actionOnClick={() => onViewUpcoming(min(dates))}
      >
        View Scheduled Interaction
      </CalendarBanner>
    )
  );
};

const UpcomingInteractionBanner = ({ interactions, onViewUpcoming, start, end, className, sundayWorkday = null }) => {
  const dates = interactions
    .filter(({ state }) => state === "scheduled")
    .map(({ scheduledCallTime }) => scheduledCallTime)
    .map(parseISO);

  if (dates.length === 0) return null;

  const inside = anyInside(dates, start, end, sundayWorkday);

  return (
    !inside && (
      <CalendarBanner
        data-testid="calendar-banner-scheduled"
        className={className}
        actionLabel="View"
        actionOnClick={() => onViewUpcoming(min(dates))}
      >
        View Booked Call
      </CalendarBanner>
    )
  );
};

const WeekendAvailabilityHint = ({ weekendAvailability, onViewUpcoming, showWeekend, start, className }) => {
  if (showWeekend || !start) return null;

  return (
    weekendAvailability && (
      <CalendarBanner
        data-testid="calendar-banner-weekend-availability"
        className={className}
        actionLabel="View"
        actionOnClick={() => onViewUpcoming(weekendAvailability)}
      >
        Show weekend availability.
      </CalendarBanner>
    )
  );
};

const TeamAvailabilityBanner = ({ onHideTeamAvailability }) => {
  return (
    <CalendarBanner
      data-testid="calendar-banner-team-availability"
      actionLabel="Hide team availability"
      actionOnClick={onHideTeamAvailability}
    >
      <x.div display="flex" alignItems="center">
        <S.StyledTypography>Saved!</S.StyledTypography> Showing team availability.
      </x.div>
    </CalendarBanner>
  );
};

const ReschedulingSelectBanner = () => {
  return (
    <CalendarBanner data-testid="calendar-banner-rescheduling-select" permanent icon={<Clock />}>
      Select a time for the rescheduled interaction.
    </CalendarBanner>
  );
};

const ProvideAvailabilityBanner = ({ onHideProvideAvailabilityBanner }) => {
  return (
    <CalendarBanner
      data-testid="calendar-banner-provide-availability"
      actionLabel="Dismiss"
      permanent
      icon={<ClockPending />}
      actionOnClick={onHideProvideAvailabilityBanner}
    >
      Drag and drop your team's availability in the calendar and click 'Save Availability'.
    </CalendarBanner>
  );
};

const ReschedulingRequestBanner = ({ reschedulingRequest }) => {
  if (!reschedulingRequest) return null;

  const requested = parseISO(reschedulingRequest.payload.requestedTime);
  const duration = reschedulingRequest.payload.duration;
  const end = addSeconds(requested, duration);

  return (
    <CalendarBanner key={reschedulingRequest.id} data-testid="calendar-banner-rescheduling-request">
      <S.StyledTypography>Requested</S.StyledTypography>
      <FormattedDateTime date={requested} format="iiii d MMM yyyy" />
      {" at "}
      <FormattedTimeRange start={requested} end={end} />
    </CalendarBanner>
  );
};

export const BannerContainer = ({
  relativeTo,
  offsetProps,
  interactions,
  start,
  end,
  onViewUpcoming,
  showWeekend,
  sundayWorkday,
  isExpanded = true,
  showTeamAvailabilityBanner,
  onHideTeamAvailability,
  showReschedulingSelectBanner,
  showProvideAvailabilityBanner,
  onHideProvideAvailabilityBanner,
  reschedulingRequest,
}) => {
  const weekendAvailability = futureWeekendAvailability(interactions, start, sundayWorkday);
  const workOnSunday = !isExpanded ? sundayWorkday : null;

  const component = (
    <S.BannerWrapper>
      <S.AbsoluteBottomWrapper>
        {offsetProps && <div {...offsetProps} />}
        <S.CenteredColumnWrapper>
          <ShowCompletedInteractionBanner
            interactions={interactions}
            onViewUpcoming={onViewUpcoming}
            start={start}
            end={end}
            sundayWorkday={workOnSunday}
          />

          <UpcomingInteractionBanner
            interactions={interactions}
            onViewUpcoming={onViewUpcoming}
            start={start}
            end={end}
            sundayWorkday={workOnSunday}
          />

          <UpcomingAvailabilityBanner
            start={start}
            end={end}
            sundayWorkday={workOnSunday}
            weekendAvailability={weekendAvailability}
            interactions={interactions}
            onViewUpcoming={onViewUpcoming}
          />

          <WeekendAvailabilityHint
            weekendAvailability={weekendAvailability}
            start={start}
            end={end}
            showWeekend={showWeekend}
            onViewUpcoming={onViewUpcoming}
          />

          {showTeamAvailabilityBanner && <TeamAvailabilityBanner onHideTeamAvailability={onHideTeamAvailability} />}

          {showReschedulingSelectBanner && <ReschedulingSelectBanner />}

          {showProvideAvailabilityBanner && (
            <ProvideAvailabilityBanner onHideProvideAvailabilityBanner={onHideProvideAvailabilityBanner} />
          )}

          <ReschedulingRequestBanner reschedulingRequest={reschedulingRequest} />
        </S.CenteredColumnWrapper>
      </S.AbsoluteBottomWrapper>
    </S.BannerWrapper>
  );

  if (relativeTo) {
    const location = document.querySelector(relativeTo);

    return location && ReactDOM.createPortal(component, location);
  }

  return component;
};
