import { x } from "@xstyled/styled-components";
import ReactHtmlParser from "react-html-parser";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { Citation } from "components/CitationContext/Citation";
import styles from "./styles/customerPrimerTopics.module.css";
import CitedMarkdown from "components/CitedMarkdown";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";

type PropTypes = {
  categories: CustomerPrimerTopic[];
};

const CustomerPrimerTopics = ({ categories }: PropTypes) => {
  const isAccessible = usePrimersStore(({ isAccessible }) => isAccessible);
  const { color, spacing, typography } = useThemeTokens();

  return (
    <x.div mt={spacing.inner.base06} className={styles.topicsContainer} rowGap={spacing.inner.base06}>
      {categories.map((category, categoryIndex) => {
        const { title, expertsResponses, summary } = category;

        if (!summary && expertsResponses.length === 0) return null;

        return (
          <SensitiveDataContainer key={`${title}-${categoryIndex.toString()}`} isSensitive={!isAccessible}>
            <x.div className={styles.topicGroup} mt={categoryIndex === 0 ? 0 : spacing.inner.base06}>
              <x.div
                className="mentions-container"
                {...typography.body.em}
                color={color.text.strong._}
                mb={spacing.inner.base02}
              >
                <>{ReactHtmlParser(title)}</>
              </x.div>
              {summary && (
                <x.div
                  data-testid="customer-primer-topics-summary"
                  {...typography.body.regular}
                  color={color.text.strong._}
                  m={`${spacing.inner.base02} 0`}
                >
                  <CitedMarkdown value={summary.value} />
                </x.div>
              )}
              <x.ul listStyleType="disc" paddingLeft={spacing.layout.base03}>
                {expertsResponses.map(({ response }, responseIndex) => {
                  return (
                    <x.li
                      key={responseIndex.toString()}
                      {...typography.body.regular}
                      color={color.text.strong._}
                      fontStyle="italic"
                    >
                      <Citation
                        value={{
                          ...response,
                          value: `‘${response.value}’`,
                        }}
                      />
                    </x.li>
                  );
                })}
              </x.ul>
            </x.div>
          </SensitiveDataContainer>
        );
      })}
    </x.div>
  );
};

export default CustomerPrimerTopics;
