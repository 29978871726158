import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ApexOptions } from "apexcharts";
import { YAxisValueType } from "./YAxis";
import { CHART_TYPES, OneDimensionalBarChartResults } from "views/SurveysResultsView/api/types";

type Props = {
  chartType:
    | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART
    | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_LOGO
    | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_CATEGORICAL;
  expertsCount?: number;
  results: OneDimensionalBarChartResults;
};

type Data = {
  logos?: { values: string[]; valueType: YAxisValueType; align: string };
  vendors: { values: string[]; valueType: YAxisValueType; align: string };
  quantities: string[];
};

const transformToArrays = (
  chartType:
    | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART
    | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_LOGO
    | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_CATEGORICAL,
  data: Data
) => {
  const { logos, vendors } = data;

  if (chartType === CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_LOGO) {
    return [
      { values: logos!.values, valueType: logos!.valueType, align: logos!.align },
      { values: vendors.values, valueType: vendors.valueType, align: vendors.align },
    ];
  }

  return [{ values: vendors.values, valueType: vendors.valueType, align: vendors.align }];
};

const getValuesAndLabels = ({ chartType, expertsCount, results }: Props) => {
  switch (chartType) {
    case CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART:
    case CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_LOGO:
    case CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_CATEGORICAL:
      const shouldIncludeLogos = chartType === CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_LOGO;

      const { answerText, quantity } = results.aggregated.reduce(
        (acc, result) => {
          acc.answerText.push(result.answerText);
          acc.quantity.push(result.quantity.toString());
          return acc;
        },
        { answerText: [], quantity: [] } as { answerText: string[]; quantity: string[] }
      );

      return {
        ...(shouldIncludeLogos && { logos: { values: answerText, valueType: YAxisValueType.Logo, align: "left" } }),
        vendors: { values: answerText, valueType: YAxisValueType.Text, align: shouldIncludeLogos ? "left" : "right" },
        quantities: quantity.map((value) => Math.round((Number(value) * 100) / (expertsCount ?? 1)).toString()),
      };
    default:
      return {
        logos: { values: [], valueType: YAxisValueType.Logo, align: "left" },
        vendors: { values: [], valueType: YAxisValueType.Text, align: "left" },
        quantities: [],
      };
  }
};

export const useChartConfig = ({ chartType, expertsCount, results }: Props) => {
  const { color, font, typography } = useThemeTokens();
  const values = getValuesAndLabels({ chartType, expertsCount, results });
  const quantities = values.quantities;

  const labelsArray = transformToArrays(chartType, values);

  return {
    labels: labelsArray,
    values: [{ data: [...quantities.map((value) => Number(value))] }],
    chartHeight: quantities.length * 40 + 30,
    config: {
      chart: {
        animations: { enabled: false },
        fontFamily: font.family.text.regular,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        parentHeightOffset: 0,
        offsetX: 0,
        offsetY: 0,
      },
      tooltip: {
        enabled: false,
      },
      colors: [color.chart.sequential.base03],
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: { left: 0, right: 0, top: -30, bottom: -15 },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "28px",
        },
      },
      dataLabels: {
        enabled: false,
      },
      /**
       * X Axis options for the chart
       * https://apexcharts.com/docs/options/xaxis/
       */
      xaxis: {
        title: {
          txt: "Customers",
          style: {
            fontWeight: typography.body.small.fontWeight,
            fontSize: typography.body.small.fontSize,
            color: color.text.strong._,
          },
          offsetY: 15,
        },
        tickAmount: Math.ceil(Math.max(...quantities.map(Number)) / 5), // Ensure ticks are 5 units apart
        labels: {
          formatter: (value: any) => (Math.floor(value / 5) * 5).toString(), // Format labels to be multiples of 5
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: undefined,
      },
      legend: {
        show: false,
      },
      // this avoids the color to be darker when clicking on an entry
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
      },
    } as ApexOptions,
  };
};
