import styled, { x, th } from "@xstyled/styled-components";
import { Button as BaseButton } from "@alphasights/alphadesign-components";

export const ImpersonateTopBarWrapper = styled(x.div)`
  flex-shrink: 0;
  display: flex;
  height: ${th.space("inner-base10")};
  justify-content: center;
  align-items: center;
  background: ${th.color("background-dark")};
  gap: ${th.space("inner-base08")};
  padding-right: ${th.space("inner-base02")};
  padding-left: ${th.space("inner-base02")};
  gap: ${th.space("inner-base06")};
`;

export const SignOutButton = styled(BaseButton)`
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
`;
