import { useEffect, useMemo, useRef } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { useStyles } from "./SynthesisModuleContent.styles";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { KpcModuleContent } from "./KpcModuleContent";
import { VendorModuleContent } from "./VendorModuleContent";
import { QuestionModuleContent } from "./QuestionModuleContent";
import { NewSynthesisModuleContent } from "./NewSynthesisModuleContent";
import { HitAction, SynthesisModule } from "@alphasights/portal-api-client";
import { NPSModuleContent } from "./NPSModuleContent";
import { question } from "../synthesisTypeGuards";
import _ from "lodash";
import { GenerativeAiDisclaimer } from "components/GenerativeAiDisclaimer";
import { VendorSwitchingModuleContent } from "./VendorSwitchingModuleContent";

export const SynthesisModuleContent = () => {
  const { selectedModule, revision, selectedRevisionIdx, mode, synthesisLogHit } = useProjectSynthesisContext();
  const { moduleWrapper } = useStyles({
    isEditing: mode === Mode.EDIT,
  });
  const { contentStyle } = useHideDeliverablesContent();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedModuleId = useMemo(() => selectedModule?.id, [selectedModule]);

  useEffect(() => {
    const position = parseInt(sessionStorage.getItem("synthesisPosition") ?? "0");
    sessionStorage.removeItem("synthesisPosition");

    wrapperRef.current?.scrollTo(0, position);
  }, [selectedModuleId, selectedRevisionIdx]);

  useEffect(
    function logHitModuleViewed() {
      if (!revision || !selectedModule) return;
      const logHitDetails = {
        revision: revision.revision,
        angleType: selectedModule.angleType,
        moduleType: selectedModule.contentType,
        ...(selectedModule.contentType === "QUESTION" && { question: question(revision?.contents)?.question }),
      };
      if (revision.status === "COMPLETED") {
        synthesisLogHit({
          action: HitAction.projectSynthesisModuleViewed,
          details: logHitDetails,
          references: { moduleId: selectedModule.id },
        });
      } else if (revision.status === "FAILED") {
        synthesisLogHit({
          action: HitAction.projectSynthesisModuleFailed,
          details: logHitDetails,
          references: { moduleId: selectedModule.id },
        });
      }
    },
    [revision?.revision, selectedModule?.id, synthesisLogHit] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const maxScrollReached = useRef(0);
  const openedAt = useRef(Date.now());
  useEffect(
    function logHitModuleRead() {
      if (!revision || !selectedModule) return;
      if (mode !== Mode.VIEW) return;
      maxScrollReached.current = 0;
      openedAt.current = Date.now();
      const scrollableArea = document.querySelector("[data-synthesis-scrollable-area]");

      const computeMaxScrollReached = () => {
        if (!scrollableArea) return;
        const maxBottomVisible = scrollableArea.getBoundingClientRect().height + scrollableArea.scrollTop;
        maxScrollReached.current =
          _.max([maxScrollReached.current, maxBottomVisible / scrollableArea.scrollHeight]) ?? 0;
      };

      const logHitDuration = (openedAt: number, maxScrollReached: number, action: HitAction) => {
        const closedAt = Date.now();
        const durationInSeconds = (closedAt - openedAt) / 1000;
        synthesisLogHit({
          action,
          details: {
            revision: revision.revision,
            question: question(revision?.contents).question,
            angleType: selectedModule.angleType,
            moduleType: selectedModule.contentType,
            durationInSeconds,
            maxScrollReached,
          },
          references: {
            moduleId: selectedModule.id,
          },
        });
      };

      scrollableArea?.addEventListener("scroll", computeMaxScrollReached);

      window.addEventListener("beforeunload", () =>
        logHitDuration(openedAt.current, maxScrollReached.current, HitAction.projectSynthesisModuleRead)
      );

      return () => {
        window.removeEventListener("beforeunload", () =>
          logHitDuration(openedAt.current, maxScrollReached.current, HitAction.projectSynthesisModuleRead)
        );
        scrollableArea?.removeEventListener("scroll", computeMaxScrollReached);
        logHitDuration(openedAt.current, maxScrollReached.current, HitAction.projectSynthesisModuleRead);
      };
    },
    [revision?.id, synthesisLogHit, mode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!revision || !selectedModule) return <x.div {...moduleWrapper} data-testid="empty-module-content"></x.div>;

  const handleSelectionCopy = (ev: React.ClipboardEvent<HTMLDivElement>) => {
    ev.preventDefault();

    const selectedText = window.getSelection();
    if (ev.clipboardData && selectedText) {
      const formattedFragment = formatHtmlSelection(selectedText);
      const div = document.createElement("div");
      div.appendChild(formattedFragment);

      ev.clipboardData.setData("text/html", div.innerHTML);
      ev.clipboardData.setData("text/plain", div.innerText);
    }
  };

  return (
    <x.div
      ref={wrapperRef}
      {...moduleWrapper}
      {...contentStyle}
      data-testid="synthesis-module-content"
      data-synthesis-scrollable-area
      onCopy={handleSelectionCopy}
    >
      <GenerativeAiDisclaimer />
      <ModuleContentSwitch mode={mode} selectedModule={selectedModule} />
    </x.div>
  );
};

const ModuleContentSwitch = ({ mode, selectedModule }: { mode: Mode; selectedModule: SynthesisModule }) => {
  if (mode === Mode.NEW) return <NewSynthesisModuleContent />;

  if (selectedModule.contentType === "KPC") return <KpcModuleContent />;
  if (selectedModule.contentType === "VENDORS") return <VendorModuleContent />;
  if (selectedModule.contentType === "NPS") return <NPSModuleContent />;
  if (selectedModule.contentType === "VENDOR_SWITCHING") return <VendorSwitchingModuleContent />;

  return <QuestionModuleContent />;
};

const formatHtmlSelection = (selectedText: Selection): DocumentFragment => {
  const clonedSelection = selectedText.getRangeAt(0).cloneContents();

  const spaceAfter = (query: string) =>
    clonedSelection.querySelectorAll(query).forEach((el) => el?.after(document.createElement("br")));

  const removeElements = (query: string) => clonedSelection.querySelectorAll(query).forEach((el) => el.remove());

  const replaceElement = (query: string, newElType: string, style: any = {}, callback?: (el: string) => string) =>
    clonedSelection.querySelectorAll(query).forEach((el) => {
      if (el.innerHTML) {
        const newEl = document.createElement(newElType);
        Object.assign(newEl.style, style);
        newEl.innerHTML = el.innerHTML;
        if (callback && newEl.textContent) {
          newEl.textContent = callback(newEl.textContent);
        }
        el.before(newEl);
        el.remove();
      }
    });

  // remove undesired components
  [
    "div[data-testid='revision-toggler']",
    "div[data-testid='company-logo']",
    "span[data-testid='expert-counter']",
    "button[data-testid='icon-button-ghost']",
  ].forEach(removeElements);

  // adds space after elements
  ["div[data-testid='company-name']", "div[data-testid='expert-answer']", "div[id='answer-block']"].forEach(spaceAfter);

  // replace elements
  replaceElement("h3", "p", { fontSize: "18px", fontStyle: "italic" });
  replaceElement("div[data-testid='overview-topic']", "strong", {}, (str) => `${str}: `);
  replaceElement("div[data-testid='overview-list']", "ul", { paddingLeft: "16px", fontSize: "14px" });
  replaceElement("div[data-testid='overview-item']", "li");
  replaceElement("div[data-testid='company-name']", "div", { color: "#666B7A", fontSize: "13px" });
  replaceElement("div[data-testid='expert-answer']", "div", { fontSize: "13px" });
  replaceElement("span[data-testid^='quote-']", "span", { fontSize: "13px" });
  replaceElement("span[data-testid='quoted-text']", "span", { fontStyle: "italic" });

  return clonedSelection;
};
