import { FC } from "react";
import { noop } from "lodash";

import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { SliderFilter } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components";
import {
  DATE,
  DATE_MARKS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/DateFilter/constants";
import { SliderRange } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/SliderFilter";
import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";

import "./index.css";

interface DateFilterProps extends FilterProps {
  value: SliderRange;
}

const DateFilter: FC<DateFilterProps> = ({ value, onChange, onClear = noop }) => {
  const handleClear = () => onClear(SearchFilters.callDate, { min: 0, max: DATE_MARKS.length - 1 });
  return (
    <SliderFilter
      id="date-filter"
      filterKey={SearchFilters.callDate}
      title={DATE}
      value={value}
      options={DATE_MARKS}
      onChange={onChange}
      onClear={handleClear}
    />
  );
};
export default DateFilter;
