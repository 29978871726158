import styled from "styled-components";
import { Button, ButtonVariant, IconButton, useThemeTokens } from "@alphasights/alphadesign-components";

const StyledUploadButton = styled(Button).attrs({ variant: "outline" as ButtonVariant })(() => {
  const { spacing } = useThemeTokens();
  const defaultMarginValue = spacing.inner.base06;
  return `
    display: flex;
    padding: ${spacing.inner.base04};
    justify-content: flex-start;
    width: -webkit-fill-available;
    margin: ${defaultMarginValue} ${defaultMarginValue} ${spacing.inner.base08} ${defaultMarginValue};
  `;
});

const ContentsWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base02};
  `;
});

const StyledIconButton = styled(IconButton).attrs({ variant: "outline", size: "small" })(() => {
  const { color } = useThemeTokens();
  return `
    &:hover {
      background-color: ${color.background.surface.page.default};
    }
  `;
});

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export { ContentsWrapper, StyledUploadButton, StyledIconButton, TextWrapper };
