import { FC, useEffect, useMemo, useState } from "react";
import { AlphaTable, useAlphaTable, AlphaTableTypes } from "@alphasights/alphadesign-table";
import { Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";

import { ANGLE_INFO_TOOLTIP_TEXT } from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";
import { ThirdPartyDocument } from "types";

import { ColumnIds, COLLAPSED_ANGLE_COLUMN_SIZE, COLUMN_NAMES, COLUMN_METADATA, TABLE_OPTIONS } from "./consts";
import {
  TitleCell,
  StatusCell,
  DeleteCell,
  AngleCell,
  EditCell,
  EditCellProps,
  AngleCellProps,
  DeleteCellProps,
  DateCell,
} from "./cells";
import { DocumentRow } from "./types";
import { getStatus } from "./utils";
import { getDefaultExpert } from "utils/thirdPartyDocumentsUtils";

import * as ThirdPartyDocUploadModalStyles from "views/DeliverablesView/ThirdPartyDocUploadModal/ThirdPartyDocUploadModal.styled";
import * as DocumentsTableStyles from "./DocumentsTable.styled";

const S = { ...ThirdPartyDocUploadModalStyles, ...DocumentsTableStyles };

type DocumentsTableProps = {
  documents: ThirdPartyDocument[];
  onSelectAngle: (id: string, value: string) => void;
  onClickDelete: (id: string) => void;
  onClickEdit: (id: string) => void;
  onSelectionChange: (selectedDocumentIds: string[]) => void;
};

const DocumentsTable: FC<DocumentsTableProps> = ({
  documents,
  onSelectAngle,
  onClickDelete,
  onClickEdit,
  onSelectionChange,
}) => {
  const [rows, setRows] = useState<DocumentRow[]>([]);
  const [angleColumnWidth, setAngleColumnWidth] = useState<number | undefined>(COLLAPSED_ANGLE_COLUMN_SIZE);

  const onRowSelectionChange: AlphaTableTypes.OnChangeFn<AlphaTableTypes.RowSelectionState> = (updatedRowSelection) => {
    const currentPageRows = table.getRowModel().rows;
    const selectedDocumentIds: string[] = [];

    currentPageRows.forEach((row, index) => {
      const isSelected = !!(updatedRowSelection as AlphaTableTypes.RowSelectionState)[index];
      if (isSelected) {
        selectedDocumentIds.push(row.original.id);
      }
    });

    onSelectionChange(selectedDocumentIds);
  };

  useEffect(() => {
    const documentsAsRows: DocumentRow[] = [];

    let isAnyAngleSelected = false;
    let maxStatusNumChars = COLUMN_NAMES[ColumnIds.status].length;
    let maxAngleNumChars = COLUMN_NAMES[ColumnIds.angle].length;

    documents.forEach((document) => {
      const { id, redactedFilename, attribute, createdAt } = document;
      const status = getStatus(document);
      const angle = getDefaultExpert(attribute?.experts).angle;

      documentsAsRows.push({
        id,
        title: attribute?.title ?? redactedFilename,
        status,
        date: new Date(attribute?.documentDate ?? createdAt),
        angle,
      });

      if (angle) {
        isAnyAngleSelected = true;
      }
      if (angle && angle?.length > maxAngleNumChars) {
        maxAngleNumChars = angle.length;
      }
      if (status.length > maxStatusNumChars) {
        maxStatusNumChars = status.length;
      }
    });

    setRows(documentsAsRows);

    // set column widths based on content
    if (isAnyAngleSelected) {
      setAngleColumnWidth(undefined);
    } else {
      setAngleColumnWidth(COLLAPSED_ANGLE_COLUMN_SIZE);
    }
  }, [documents, setRows, setAngleColumnWidth]);

  const columnDefs = useMemo(
    () => [
      {
        id: ColumnIds.title,
        accessorKey: "title",
        header: COLUMN_NAMES[ColumnIds.title],
        cell: TitleCell,
        size: 271,
        maxSize: 271,
        meta: COLUMN_METADATA,
      },
      {
        id: ColumnIds.angle,
        accessorKey: "angle",
        header: AngleHeader,
        cell: (props: AngleCellProps) => <AngleCell {...props} onSelect={onSelectAngle} />,
        ...(angleColumnWidth && { size: angleColumnWidth, minSize: angleColumnWidth }),
        meta: COLUMN_METADATA,
      },
      {
        id: ColumnIds.date,
        accessorKey: "date",
        header: COLUMN_NAMES[ColumnIds.date],
        cell: DateCell,
        size: 134,
        minSize: 134,
        meta: COLUMN_METADATA,
      },
      {
        id: ColumnIds.status,
        accessorKey: "status",
        header: COLUMN_NAMES[ColumnIds.status],
        cell: StatusCell,
        meta: COLUMN_METADATA,
      },
      {
        id: ColumnIds.edit,
        cell: (props: EditCellProps) => <EditCell {...props} onClickEdit={onClickEdit} />,
        size: 48,
        minSize: 42,
        meta: COLUMN_METADATA,
      },
      {
        id: ColumnIds.delete,
        cell: (props: DeleteCellProps) => <DeleteCell {...props} onClick={onClickDelete} />,
        size: 68,
        minSize: 68,
        meta: COLUMN_METADATA,
      },
    ],
    [angleColumnWidth, onSelectAngle, onClickDelete, onClickEdit]
  );

  const tableOptions = { onRowSelectionChange, ...TABLE_OPTIONS };
  const table = useAlphaTable(rows, columnDefs, tableOptions);

  return (
    <S.TableWrapper>
      <AlphaTable table={table} enableHeaderActions={false} enableStickyHeader />
    </S.TableWrapper>
  );
};

const AngleHeader = () => {
  const { color } = useThemeTokens();
  return (
    <S.TextAndIconWrapper>
      <Typography variant="body-small-em" color={color.text.secondary}>
        {COLUMN_NAMES[ColumnIds.angle]}
      </Typography>
      <Tooltip variant="dark" size="small" title={ANGLE_INFO_TOOLTIP_TEXT}>
        <S.StyledInteractiveIcon color={color.icon.strong._}>
          <Info />
        </S.StyledInteractiveIcon>
      </Tooltip>
    </S.TextAndIconWrapper>
  );
};

export default DocumentsTable;
