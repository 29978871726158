import { Fragment, useMemo } from "react";
import { ListOption, OnChangeProps } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useExpertFiltersStyles } from "./ExpertFilters.styles";
import { intersection, uniq } from "lodash";

export type FilterItemContentOptionsProps = {
  filterName?: string;
  filterOptions: FilterOption[];
  selectedItems: string[];
  setSelectedItems: (values: string[]) => void;
};
export const FilterItemOptions = ({
  filterName,
  filterOptions,
  selectedItems,
  setSelectedItems,
}: FilterItemContentOptionsProps) => {
  const { itemsWrapper } = useExpertFiltersStyles();

  const sentenceCase = (str: string) => {
    const lower = str.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  };

  return (
    <x.div {...itemsWrapper}>
      {filterOptions.map((option, index) => (
        <Fragment key={`filter-item-${index}`}>
          {option.elementType === "textOnly" && <ListOption type="title" label={sentenceCase(option.label)} />}
          {option.elementType === "separator" && <ListOption type="divider" />}
          {!option.elementType && (
            <OptionCheckbox
              option={option}
              allOptions={filterOptions}
              index={index}
              filterName={filterName}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}
        </Fragment>
      ))}
    </x.div>
  );
};

type OptionCheckboxProps = {
  filterName?: string;
  allOptions: FilterOption[];
  option: FilterOption;
  index: number;
  selectedItems: string[];
  setSelectedItems: (values: string[]) => void;
};

const OptionCheckbox = ({
  filterName,
  option,
  index,
  allOptions,
  selectedItems,
  setSelectedItems,
}: OptionCheckboxProps) => {
  const toggleCheckbox = ({ event, option }: { event: OnChangeProps; option: FilterOption }) => {
    if (event.isSelected) {
      const newSelectedItems = [...selectedItems, option.value, ...(option.autoSelect ?? [])];
      setSelectedItems(uniq(newSelectedItems));
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== option.value));
    }
  };

  const isSelected = ({ option }: { option: FilterOption }) => {
    return selectedItems.includes(option.value);
  };

  const isDisabled = useMemo(() => {
    const optionsAutoSelectThis = allOptions
      .filter((o) => (o.autoSelect ?? []).includes(option.value))
      .map((o) => o.value);

    return intersection(selectedItems, optionsAutoSelectThis).length > 0;
  }, [selectedItems, allOptions, option.value]);

  return (
    <>
      <ListOption
        key={`checkbox-${index}`}
        type="text"
        role="checkbox"
        inputType="checkbox"
        dataAttributes={{ "data-testid": `checkbox-${option.value}` }}
        label={`${option.label} ${filterName === "recency" && option.label !== "Current" ? "Months" : ""}`}
        selected={isSelected({ option })}
        onChange={(event) => toggleCheckbox({ event, option })}
        disabled={isDisabled}
      />
      {(option.children?.length ?? 0) > 0 && (
        <>
          {option.children?.map((childOption, childIndex) => (
            <ListOption
              key={`checkbox-${index}-${childIndex}`}
              type="text"
              role="checkbox"
              inputType="checkbox"
              dataAttributes={{ "data-testid": `checkbox-nested-${childOption.value}` }}
              label={childOption.label}
              selected={isSelected({ option: childOption })}
              onChange={(event) =>
                toggleCheckbox({
                  event,
                  option: childOption,
                })
              }
              indent={1}
            />
          ))}
        </>
      )}
    </>
  );
};
