import { differenceInMinutes, isWithinInterval, parseISO } from "date-fns";

export const getFirstName = (name) => {
  return name ? name.toString().split(" ")[0] : "";
};

export const getThreadStateColorFlyout = (state, colorTokens) => {
  if (state) {
    const colors = [
      { states: ["REPLIED"], color: colorTokens.icon.success },
      { states: ["SENT"], color: colorTokens.icon.success },
      {
        states: ["WAITING_RESPONSE", "PENDING_UPDATE", "AWAITING_APPROVAL"],
        color: colorTokens.icon.processing,
      },
    ];

    return colors.find((color) => color.states.includes(state.code))?.color ?? colorTokens.icon.secondary;
  }
};

export const onGracePeriod = (interaction) => {
  return interaction?.scheduledAt && differenceInMinutes(new Date(), parseISO(interaction.scheduledAt)) < 6;
};

export const dateIntervalContains = (start, end, date) => {
  if (!start || !end || !date) return false;

  return isWithinInterval(date, {
    start: start instanceof Date ? start : parseISO(start),
    end: end instanceof Date ? end : parseISO(end),
  });
};
