import { apiClient } from "@alphasights/portal-api-client";
import { ExternalAlphaCompany } from "models/ExternalAlphaCompany";

export const ExternalAlphaCompaniesService = {
  search(query: string) {
    return apiClient.get<ExternalAlphaCompany[]>(`/api/external-alpha-companies/search?query=${query}`);
  },

  companyMatch(query: string): Promise<ExternalAlphaCompany[]> {
    return apiClient.post<CompanyMatchRequest, ExternalAlphaCompany[]>("/api/external-alpha-companies/company-match", {
      query,
    });
  },
};

interface CompanyMatchRequest {
  query: string;
}
