import { useCallback } from "react";
import { produce } from "immer";
import { SynthesisModule, VendorItem } from "@alphasights/portal-api-client";
import { vendors } from "../synthesisTypeGuards";

export const useEditVendorOperations = ({ originalModule }: { originalModule?: SynthesisModule }) => {
  const updateVendorItem = useCallback(
    (newVendorItem: VendorItem, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = vendors(draft.revisions[revisionIdx].contents);

        const featuredIndex = content.featuredVendors.findIndex((kpcItem) => kpcItem.id === newVendorItem.id);
        if (featuredIndex !== -1) {
          content.featuredVendors[featuredIndex] = newVendorItem;
        }

        const emergingIndex = content.emergingVendors.findIndex((kpcItem) => kpcItem.id === newVendorItem.id);
        if (emergingIndex !== -1) {
          content.emergingVendors[emergingIndex] = newVendorItem;
        }
      });
    },
    [originalModule]
  );

  const addVendorItem = useCallback(
    (newVendorItem: VendorItem, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = vendors(draft.revisions[revisionIdx].contents);

        const featuredIndex = content.featuredVendors.findIndex((kpcItem) => kpcItem.id === newVendorItem.id);
        if (featuredIndex === -1) {
          content.featuredVendors.unshift(newVendorItem);
        }
      });
    },
    [originalModule]
  );

  const deleteVendorItem = useCallback(
    (vendorItem: VendorItem, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = vendors(draft.revisions[revisionIdx].contents);

        const featuredIndex = content.featuredVendors.findIndex((item) => item.id === vendorItem.id);
        if (featuredIndex !== -1) {
          content.featuredVendors.splice(featuredIndex, 1);
        }

        const emergingIndex = content.emergingVendors.findIndex((item) => item.id === vendorItem.id);
        if (emergingIndex !== -1) {
          content.emergingVendors.splice(emergingIndex, 1);
        }
      });
    },
    [originalModule]
  );

  return {
    updateVendorItem,
    addVendorItem,
    deleteVendorItem,
  };
};
