import { forwardRef, ChangeEvent, ClipboardEvent, KeyboardEvent, Ref } from "react";
import { SearchSizeVariant } from "components/Search/consts";
import { StyledInput } from "./Input.styled";

export const DataTestIds = {
  Input: "search-input",
};

export type InputProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;

  size?: SearchSizeVariant;
  placeholder?: string;
  value?: string;
  isLastItem?: boolean;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
};

const Input = forwardRef(
  (
    {
      size = SearchSizeVariant.Medium,
      placeholder = "",
      value = "",
      isLastItem = false,
      onChange,
      onKeyDown,
      onPaste,
    }: InputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const width = placeholder ? "-webkit-fill-available" : `${value.length + 1}ch`;

    const handlePaste = async (event: ClipboardEvent<HTMLInputElement>) => {
      if (onPaste) {
        event.preventDefault();
        onPaste(event);
      }
    };

    return (
      <StyledInput
        id={DataTestIds.Input}
        data-testid={DataTestIds.Input}
        ref={ref}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        onChange={onChange}
        onKeyDown={onKeyDown}
        onPaste={handlePaste}
        size={0}
        sizeVariant={size}
        isLastItem={isLastItem}
        w={width}
      />
    );
  }
);

export default Input;
