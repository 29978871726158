import { FC, ReactElement, useLayoutEffect, useRef } from "react";
import * as React from "react";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { isEmpty, some, values } from "lodash";

import CitedMarkdown from "components/CitedMarkdown";
import { OVERVIEW_PROPERTIES, OVERVIEW_PROPERTY_ORDER, LABEL_TYPES } from "./constants";
import { CURRENCY_SYMBOL } from "pages/AlphaNowPage/primers/utils/constants";
import { Citation } from "components/CitationContext/Citation";
import {
  currencyRendererWithSymbol,
  dateOf,
  NumberRenderer,
  PercentRenderer,
  rangeOf,
  renderRange,
} from "components/CitationContext/PropertyRenderers";
import { CompanyPrimerSectionCard } from "pages/AlphaNowPage/primers/CompanyPrimer/components";
import DirectionalArrow from "components/DirectionalArrow";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const DataTestIds = {
  revenue: "company-primers-revenue",
  upArrow: "company-primers-up-arrow",
  downArrow: "company-primers-down-arrow",
};

const SECTION_TITLE = "Company Overview";
const TITLE_ID = "overview-title";
const GROWTH_SIGN_ID = "growth-sign";

const OverviewLabel: FC<{ label: string }> = ({ label }) => {
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <Typography
      component="div"
      color={color.text.secondary}
      flex={{ sm: "0 0 90px", md: "0 0 150px" }}
      mr={inner.base06}
      variant={isMobile ? "body-large" : "body"}
    >
      {LABEL_TYPES[label]}
    </Typography>
  );
};

interface OverviewRowProps extends Pick<React.HTMLAttributes<HTMLDivElement>, "children"> {
  index: number;
  overviewProperty: string;
  isSensitive: boolean;
}

const OverviewRow: FC<OverviewRowProps> = ({ index, overviewProperty, children, isSensitive }) => {
  const { spacing, color, shape } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <x.div
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      w={{
        md: "100%",
      }}
      pt={{ xs: spacing.inner.base06, sm: 0 }}
      pb={{ xs: spacing.inner.base05, sm: spacing.inner.base04 }}
      borderTop={{
        xs: index === 0 ? 0 : `${shape.border.width.sm} solid ${color.border.divider}`,
        sm: 0,
      }}
      lineHeight={spacing.inner.base06}
    >
      <OverviewLabel label={overviewProperty} />
      <SensitiveDataContainer isSensitive={isSensitive}>
        <Typography component="div" color={color.text.strong._} variant={isMobile ? "body-large" : "body"}>
          {children}
        </Typography>
      </SensitiveDataContainer>
    </x.div>
  );
};

interface RevenueRowDetailsProps {
  currency: CitableValue<string>;
  revenue: CitableRevenue;
}

const RevenueRowDetails: FC<RevenueRowDetailsProps> = ({
  currency,
  revenue: { expertRevenueGrowthYear, expertRevenueYear, growthMax, growthMin, max, min },
}): ReactElement => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  const ref = useRef<Element | null>(null);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries: any) => {
      const baseHeight = document.getElementById(TITLE_ID)?.clientHeight ?? 0;
      for (const entry of entries) {
        if (ref.current && entry?.contentRect.height > baseHeight) {
          const growthSign = document.getElementById(GROWTH_SIGN_ID);
          if (growthSign) {
            growthSign.style.marginLeft = "0px";
            observer.unobserve(ref.current);
          }
        }
      }
    });

    !!ref.current && observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  // Revenue growth (positive, neutral, negative) is defined by the midpoint value compared to 0
  const growthSign = (() => {
    const growthDifference = (growthMax.value ?? 0) - (growthMin.value ?? 0);

    const growthMidpoint = growthDifference / 2 + (growthMin.value ?? 0);

    return Math.sign(growthMidpoint);
  })();

  const currencySymbol = currency.value ? CURRENCY_SYMBOL[currency.value] : "";

  const CurrencyRenderer = currencyRendererWithSymbol(currencySymbol);

  const growthIcon = growthSign !== 0 && (
    <x.span h={inner.base05} mr={inner.base} w={inner.base05}>
      <DirectionalArrow
        data-testid={growthSign === 1 ? DataTestIds.upArrow : DataTestIds.downArrow}
        display="inline"
        isUp={growthSign === 1}
      />
    </x.span>
  );

  return (
    <x.div
      // @ts-ignore
      ref={ref}
      display={{ xs: "flex", sm: "inline-flex" }}
      flexDirection={{ xs: "column", sm: "row" }}
      flexWrap="wrap"
      data-testid={DataTestIds.revenue}
    >
      <span>
        <Citation
          value={rangeOf(min, max)}
          renderer={renderRange({
            renderer: CurrencyRenderer,
            desc: expertRevenueYear.value ?? undefined,
          })}
        />
      </span>
      <x.span
        id={GROWTH_SIGN_ID}
        display="flex"
        flexDirection="column"
        color={
          {
            [-1]: text.danger,
            0: text.secondary,
            1: text.success,
          }[growthSign]
        }
        marginLeft={inner.base02}
      >
        <span>
          <Citation
            value={rangeOf(growthMin, growthMax)}
            renderer={renderRange({
              before: growthIcon,
              renderer: PercentRenderer,
              after: " p.a.",
              shouldRenderFullRangeWhenNoChange: false,
              desc: expertRevenueGrowthYear.value ?? undefined,
            })}
          />
        </span>
      </x.span>
    </x.div>
  );
};

interface FullTimeEquivalentRowDetailsProps {
  fullTimeEquivalent: CitableFullTimeEquivalent;
  updatedAt: string;
}

const FullTimeEquivalentRowDetails: FC<FullTimeEquivalentRowDetailsProps> = ({
  fullTimeEquivalent: { max, min },
  updatedAt,
}) => (
  <x.div spaceY="24px">
    {min.value && (
      <>
        <Citation value={rangeOf(min, max)} renderer={renderRange({ renderer: NumberRenderer })} /> (
        {new Date(updatedAt).getFullYear()})
      </>
    )}
  </x.div>
);

interface MarketsRowDetailsProps {
  markets: CitableMarket[];
}

const MarketsRowDetails: FC<MarketsRowDetailsProps> = ({ markets }) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  return (
    <x.div display="flex" flexDirection="column" spaceY={inner.base02}>
      {markets.map((market) => {
        const location = market.country.value ? market.country : market.region;
        const hasMultipleMarkets = markets.length > 1;

        return (
          <Typography variant={isMobile ? "body-large" : "body"} key={market.id.value}>
            <Citation value={market.industryName} />
            {location && (
              <>
                <Typography component="span" pl={inner.base}>
                  (
                </Typography>
                <Citation value={location} />)
              </>
            )}
            {market.isPrimary.value && hasMultipleMarkets && (
              <>
                <Typography component="span" color={text.secondary}>
                  {" "}
                  •{" "}
                </Typography>
                <Citation
                  value={market.isPrimary}
                  renderer={() => (
                    <Typography component="span" color={text.secondary}>
                      Primary
                    </Typography>
                  )}
                />
              </>
            )}
          </Typography>
        );
      })}
    </x.div>
  );
};

interface LocationRowDetailsProps {
  location: CitableLocation;
}

function insertBetweenEachElement<T>(list: T[], elementGen: (index: number) => T): T[] {
  const output: T[] = [];

  for (let i = 0; i < list.length - 1; i++) {
    output.push(list[i]);
    output.push(elementGen(i));
  }

  output.push(list[list.length - 1]);

  return output;
}

const LocationRowDetails: FC<LocationRowDetailsProps> = ({ location: { city, country, state } }) => (
  <x.div spaceY="24px">
    {insertBetweenEachElement<React.ReactNode>(
      [city, state, country]
        .filter((it) => it.value)
        .map((property, index) => <Citation key={index} value={property} />),
      (index) => (
        /* Negative to ensure unique keys, +1 because -0 is 0 */
        <span key={-(index + 1)}>, </span>
      )
    )}
  </x.div>
);

export type CompanyPrimerOverview = {
  id: CitableValue<string>;
  currency: CitableValue<string>;
  customerDescription: CitableValue<string> | null;
  description: CitableValue<string>;
  differentiation: CitableValue<string> | null;
  history: CitableValue<string>;
  exampleUseCase: CitableValue<string>;
  monetization: CitableValue<string> | null;
  ownershipType: CitableValue<string>;
  revenue: CitableRevenue;
  fullTimeEquivalent: CitableFullTimeEquivalent;
  location: CitableLocation;
  markets: CitableMarket[];
};

type ValueOf<T> = T[keyof T];

interface OverviewSectionProps {
  companyPrimerOverview: CompanyPrimerOverview;
  contentCreatedAt: string;
  ownershipLastChangeAtQuarter: CitableValue<number>;
  ownershipLastChangeAtYear: CitableValue<number>;
  ownerName: CitableValue<string>;
}

function isCitableValue(value: any): value is CitableValue<any> {
  return "value" in value && "citedBy" in value;
}

const OverviewSectionV1: FC<OverviewSectionProps> = ({
  companyPrimerOverview: overview,
  contentCreatedAt,
  ownershipLastChangeAtQuarter,
  ownershipLastChangeAtYear,
  ownerName,
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const generateDetailByKey = (key: keyof CompanyPrimerOverview) => {
    function renderIfPopulated<CitationType extends ValueOf<CompanyPrimerOverview>>(
      contentFactory: (entity: CitationType) => JSX.Element
    ) {
      return () => {
        const entity = overview[key] as CitationType;

        const isMissingProperties = some(values(entity), (propertyValue) => {
          if (!isCitableValue(propertyValue)) return false;
          // @ts-ignore
          return [undefined, null].includes(propertyValue.value);
        });

        if (isMissingProperties) return null;

        return contentFactory(entity);
      };
    }

    function renderFteIfPopulated(contentFactory: (entity: CitableFullTimeEquivalent) => JSX.Element) {
      return () => {
        const entity = overview[key] as CitableFullTimeEquivalent;
        return !entity.min.value ? null : contentFactory(entity);
      };
    }

    const contentFactoriesByProperty = {
      [OVERVIEW_PROPERTIES.revenue]: renderIfPopulated<CitableRevenue>((revenue) => (
        <RevenueRowDetails {...{ revenue }} currency={overview.currency} />
      )),
      [OVERVIEW_PROPERTIES.fullTimeEquivalent]: renderFteIfPopulated((fte) => (
        <FullTimeEquivalentRowDetails fullTimeEquivalent={fte} updatedAt={contentCreatedAt} />
      )),
      [OVERVIEW_PROPERTIES.markets]: () => <MarketsRowDetails markets={overview[key] as CitableMarket[]} />,
      [OVERVIEW_PROPERTIES.ownershipType]: () => {
        const date = dateOf(ownershipLastChangeAtQuarter, ownershipLastChangeAtYear);

        const hasOwner = !!ownerName.value && !isEmpty(ownerName.value.trim());
        const hasDate = !!date.value && !isEmpty(date.value.trim());

        const ownershipType = overview.ownershipType;

        if (!ownershipType.value || isEmpty(ownershipType.value.trim())) {
          return null;
        }

        return (
          <x.div display="flex" spaceY="24px">
            <x.div {...(!isMobile && { display: "flex", alignItems: "center" })}>
              <Citation value={ownershipType} style={{ marginRight: inner.base }} />
              {hasOwner && (
                <>
                  (<Citation value={ownerName} />
                  {hasDate && (
                    <>
                      <x.span pr={inner.base}>,</x.span>
                      <Citation value={date} />
                    </>
                  )}
                  )
                </>
              )}
            </x.div>
          </x.div>
        );
      },
      [OVERVIEW_PROPERTIES.location]: () => (
        <LocationRowDetails key={key} location={overview[key] as CitableLocation} />
      ),
      [OVERVIEW_PROPERTIES.description]: () => <CitedMarkdown value={overview[key] as CitableValue<string>} />,
      [OVERVIEW_PROPERTIES.history]: () => <CitedMarkdown value={overview[key] as CitableValue<string>} />,
      [OVERVIEW_PROPERTIES.exampleUseCase]: () => <CitedMarkdown value={overview[key] as CitableValue<string>} />,
      [OVERVIEW_PROPERTIES.customerDescription]: () =>
        overview[key] === null ? null : <CitedMarkdown value={overview[key] as CitableValue<string>} />,
      [OVERVIEW_PROPERTIES.monetization]: () =>
        overview[key] === null ? null : <CitedMarkdown value={overview[key] as CitableValue<string>} />,
      [OVERVIEW_PROPERTIES.differentiation]: () =>
        overview[key] === null ? null : <CitedMarkdown value={overview[key] as CitableValue<string>} />,
    };

    const contentFactory = contentFactoriesByProperty[key];

    if (contentFactory) return contentFactory();

    return <Citation value={overview[key] as CitableValue<string>} />;
  };

  const order = OVERVIEW_PROPERTY_ORDER as (keyof CompanyPrimerOverview)[];

  return (
    <CompanyPrimerSectionCard title={SECTION_TITLE} titleId={isMobile ? TITLE_ID : undefined}>
      {overview &&
        order.map((key: keyof CompanyPrimerOverview, index: number) => {
          const content = generateDetailByKey(key);
          const isSensitive = hasSensitiveData(overview[key]);

          if (!content) return null;

          return (
            <OverviewRow {...{ key, index }} overviewProperty={key} isSensitive={isSensitive}>
              {content}
            </OverviewRow>
          );
        })}
    </CompanyPrimerSectionCard>
  );
};

export default OverviewSectionV1;
