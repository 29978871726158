import { MarkdownDisplay } from "@alphasights/alphadesign-rte";
import styled, { th } from "@xstyled/styled-components";

export const MessengerSuggestionWrapper = styled.div`
  padding-top: ${th.space("inner-base02")};
  display: flex;
  flex-direction: column;
  gap: ${th.space("inner-base02")};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: ${th.space("inner-base02")};
  align-items: center;
  flex-basis: content;
`;

export const AlertContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${th.space("inner-base")};
`;

export const StyledMarkdownDisplay = styled(MarkdownDisplay)`
  padding: 0;
`;
