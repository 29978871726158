import { FC, useRef, useState, useEffect } from "react";
import { Icon, IconButton, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Add, Edit, Tick } from "@alphasights/alphadesign-icons";

import useOnClickOutside from "hooks/useOnClickHooks";
import { Cell } from "views/DeliverablesView/ThirdPartyAssignDocsModal/DocumentsTable/types";
import { isSuccessStatus } from "views/DeliverablesView/ThirdPartyDocUploadModal/utils";
import { Angle, ANGLE_DISPLAY } from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";
import { POPOVER_WIDTH, TOOLTIP_TEXT } from "./constants";

import { StyledPopover, StyledList, StyledListItem, StyledAngleCell } from "./AngleCell.styled";

type AngleCellProps = Cell & {
  onSelect: (id: string, value: string) => void;
};

const AngleCell: FC<AngleCellProps> = ({
  row: {
    original: { angle, id, status },
  },
  onSelect,
}) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);

  const addButtonRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const angleCellRef = useRef<HTMLDivElement>(null);

  const { color } = useThemeTokens();

  useOnClickOutside(popoverRef, () => {
    setIsPopoverOpen(false);
    setShowEditIcon(false);
  });

  useEffect(() => {
    if (addButtonRef?.current) {
      setPopoverAnchor(addButtonRef.current);
    }
  }, [addButtonRef]);

  const handleClick = () => {
    setIsPopoverOpen(true);
  };

  const handleSelect = (id: string, angle: string) => {
    onSelect(id, angle);
    setIsPopoverOpen(false);
  };

  const handleMouseEnter = () => {
    setShowEditIcon(true);
  };

  const handleMouseLeave = () => {
    if (!isPopoverOpen) {
      setShowEditIcon(false);
    }
  };

  const handleClickEdit = () => {
    if (angleCellRef?.current) {
      setPopoverAnchor(angleCellRef.current);
    }
    setIsPopoverOpen(true);
  };

  const renderAngle = () =>
    !!angle ? (
      <StyledAngleCell
        ref={angleCellRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClickEdit}
      >
        {angle}
        {showEditIcon && (
          <Icon size="small" color={color.icon.secondary}>
            <Edit />
          </Icon>
        )}
      </StyledAngleCell>
    ) : (
      <Tooltip variant="dark" size="small" position="bottom" title={TOOLTIP_TEXT}>
        <IconButton ref={addButtonRef} variant="outline" size="small" onClick={handleClick}>
          <Add />
        </IconButton>
      </Tooltip>
    );

  if (!isSuccessStatus(status)) {
    return <></>;
  }

  return (
    <>
      {renderAngle()}
      <StyledPopover ref={popoverRef} open={isPopoverOpen} anchorEl={popoverAnchor} w={POPOVER_WIDTH}>
        <StyledList>
          {Object.values(Angle).map((angleOption) => {
            const isSelected = angleOption === angle;
            return (
              <StyledListItem key={angleOption} onClick={() => handleSelect(id, angleOption)} isSelected={isSelected}>
                <Typography variant="body-small" component="span">
                  {ANGLE_DISPLAY[angleOption]}
                </Typography>
                {isSelected && (
                  <Icon size="small" color={color.icon.info}>
                    <Tick />
                  </Icon>
                )}
              </StyledListItem>
            );
          })}
        </StyledList>
      </StyledPopover>
    </>
  );
};

export default AngleCell;
export type { AngleCellProps };
