import { IconButton } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const StyledIconButton = styled(IconButton)(({ rowHasError }: { rowHasError: boolean }) => {
  return `
    display: flex;
    align-self: ${rowHasError ? "center" : "flex-end"};
  `;
});

export { StyledIconButton };
