import { FC } from "react";
import * as React from "react";
import { AlphaNowContentType, People } from "@alphasights/client-portal-shared";
import { Typography } from "@alphasights/alphadesign-components";
import { TitleContainer } from "pages/AlphaNowPage/components/Experts/Experts.styled";
import { getNonModeratorSpeakers } from "pages/AlphaNowPage/utils";

const renderPrimerSpeakersTitle = (description: string) => {
  const pattern = /(.*? from)\s(.*)/;
  const [, numExperts, expertCompanies] = description.match(pattern) || [];
  return [
    <TitleContainer key={AlphaNowContentType.srm}>
      <Typography variant="body-small" component="small">
        {numExperts}
      </Typography>
      <Typography variant="body-small-em" component="small">
        {expertCompanies}
      </Typography>
    </TitleContainer>,
  ];
};

const renderSpeakersTitle = (speakers: Speaker[]) => {
  const nonModeratorSpeakers = getNonModeratorSpeakers(speakers) as Speaker[];
  return nonModeratorSpeakers.map(({ jobTitle, company }, index) => (
    <TitleContainer key={index}>
      <Typography variant="body-small-em" component="small">
        {company}
      </Typography>
      <Typography variant="body-small" component="small">
        {`- ${jobTitle}`}
      </Typography>
    </TitleContainer>
  ));
};

type ExpertProps = {
  contentType: AlphaNowContentType;
  speakers: Speaker[];

  primerSpeakerTitle?: string;
  containerRef?: React.Ref<unknown>;
  customBuffer?: number;
};

const Experts: FC<ExpertProps> = ({
  contentType,
  speakers,

  primerSpeakerTitle,
  containerRef,
  customBuffer,
}) => {
  const experts =
    contentType === AlphaNowContentType.srm && primerSpeakerTitle
      ? renderPrimerSpeakersTitle(primerSpeakerTitle)
      : renderSpeakersTitle(speakers);
  return <People people={experts} containerRef={containerRef} customBuffer={customBuffer} />;
};

export { Experts as default };
