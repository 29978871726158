import { x } from "@xstyled/styled-components";
import { Pill, useThemeTokens } from "@alphasights/alphadesign-components";
import { Globe, Industry, Products } from "@alphasights/alphadesign-icons";
import CompetitorPill from "./CompetitorPill";
import { CompetitorRelatedData, Filter } from "./types";

type CompetitorsListProps = {
  competitors: CompetitorRelatedData[];
  filterMatchingCompanies: string[];
  mt?: string;
  selectedCompany: string | null;
  selectedFilter?: Filter | null;
  title: string;
  isLeading?: boolean;
  onClick: (companyId: string) => void;
};

const CompetitorsList = ({
  competitors,
  filterMatchingCompanies,
  mt = "",
  selectedCompany,
  selectedFilter,
  title,
  isLeading,
  onClick,
}: CompetitorsListProps) => {
  const { color, spacing, typography } = useThemeTokens();

  const getFilterIcon = () => {
    switch (selectedFilter?.type) {
      case "customer":
        return <Industry />;
      case "product":
        return <Products />;
      case "region":
        return <Globe />;
      default:
        return null;
    }
  };

  return (
    <>
      <x.div mb={spacing.layout.base} mt={mt} alignItems="center" display="flex" columnGap={spacing.inner.base02}>
        <x.div
          {...typography.preTitle}
          color={color.text.assistive}
          display="inline-flex"
          lineHeight="28px"
        >{`${title}${!!selectedFilter ? " -" : ""}`}</x.div>
        {!!selectedFilter && (
          <Pill size="small" display="flex">
            <x.div display="flex" alignItems="center" columnGap={spacing.inner.base}>
              {getFilterIcon()}
              {selectedFilter.value}
            </x.div>
          </Pill>
        )}
      </x.div>
      <x.div display="flex" columnGap="10px" rowGap="10px" flexWrap="wrap">
        {competitors.map(({ name, logoLink }) => (
          <CompetitorPill
            key={name}
            iconUrl={logoLink}
            label={name}
            padding={isLeading ? spacing.inner.base04 : spacing.inner.base02}
            isSelected={name === selectedCompany}
            isGrayscale={!!selectedCompany && selectedCompany?.length > 0 && name !== selectedCompany}
            nameMaxLength={50}
            onClick={() => onClick(name)}
            isHighlighted={filterMatchingCompanies.includes(name)}
          />
        ))}
      </x.div>
    </>
  );
};

export default CompetitorsList;
