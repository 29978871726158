import React from "react";
import { CONTENT_TYPE } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Divider } from "@alphasights/alphadesign-components";
import TranscriptHeader from "./TranscriptHeader";
import useContentApi from "components/BookmarkButton/useContentApi";
import { useUserBadgeContext } from "providers/BadgeProvider";
import { Badge } from "models/Badge";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import useContentClientSettings from "hooks/useContentClientSettings";
import useTranscriptAudio from "hooks/useTranscriptAudio";
import { getAnchorCompanyIds } from "content/AlphaNow/utils";
import TranscriptToolbar from "./TranscriptToolbar";
import TranscriptPurchasableHeader from "pages/AlphaNowPage/components/AlphaNowPurchasableHeader";
import { TranscriptControlPanelProps } from "./types";
import TranscriptMobileActionBar from "./TranscriptMobileActionBar";
import { ContentStatus } from "@alphasights/client-portal-shared";
import { isDateInTheFuture } from "pages/AlphaNowPage/utils";
const DataTestIds = {
  transcriptControlPanel: "transcript-control-panel",
};

const TranscriptControlPanel = ({
  content,
  viewMode,
  setViewMode,
  transcriptPurchaseProps,
  transcriptSearchProps,
  transcriptToolsProps,
}: TranscriptControlPanelProps) => {
  const { hasUserBadge } = useUserBadgeContext();
  const { clientSettings } = useContentClientSettings();
  const { isMobile } = useCheckScreen();
  const {
    externalTitle,
    displayTitle,
    contentType,
    id: contentId,
    productType,
    downloadFileName,
    purchaseStatus,
    approvalStatus,
    published,
    companies,
    scheduledTimeUTC,
    status,
  } = content;

  const isAlphaView = contentType === CONTENT_TYPE.alphaview;
  const isPrivateLibrary = contentType === CONTENT_TYPE.privateEnterprise;
  const isUpcoming = status === ContentStatus.upcoming;

  const isAccessible = isContentAccessible(purchaseStatus, approvalStatus);
  const showTranscript = isAccessible && published;
  const showTranscriptMobileActions = !isUpcoming && isMobile;
  const transcriptTitle = isAlphaView ? externalTitle : displayTitle || "";

  const { onBookmarkChanged, onAudioSelect, onReturnToMobileSearch } = transcriptToolsProps;
  const { isBookmarked, onToggleBookmark } = useContentApi(contentId, contentType, onBookmarkChanged);
  const { audioDownloadStatus } = useTranscriptAudio({
    contentId,
    enableAudioFetch: showTranscript,
  });

  const hasPrivateTranscriptDownload = hasUserBadge(Badge.privateTranscriptDownload) && isPrivateLibrary;
  const isDownloadButtonAccessible = isAccessible && !!clientSettings?.downloadResearch;
  const hasDownloadButton = (isAlphaView || hasPrivateTranscriptDownload) && isDownloadButtonAccessible;

  const commonTranscriptToolsProps = {
    copyLinkButtonProps: { contentId, productType },
    bookmarkButtonProps: { isBookmarked, onToggleBookmark },
  };

  const transcriptToolbarProps = {
    ...commonTranscriptToolsProps,
    downloadButtonProps: { downloadFileName, hasDownloadButton, productType, contentId },
    audioButtonProps: { audioDownloadStatus, onAudioSelect },
  };

  const transcriptHeaderToolsProps = {
    ...(!isAccessible && commonTranscriptToolsProps),
    watchlistButtonProps: {
      hasWatchlistButton: !isMobile,
      contentId,
      cdsAlphaCompanyIds: getAnchorCompanyIds(companies ?? []),
    },
  };

  return (
    <div data-testid={DataTestIds.transcriptControlPanel}>
      {showTranscriptMobileActions && (
        <>
          <TranscriptMobileActionBar
            onReturnToMobileSearch={onReturnToMobileSearch}
            transcriptToolsProps={transcriptToolbarProps}
            transcriptSearchProps={transcriptSearchProps}
          />
          <Divider />
        </>
      )}
      <TranscriptHeader title={transcriptTitle} transcriptHeaderToolsProps={transcriptHeaderToolsProps} />
      <Divider />
      {!showTranscript && (
        <>
          <TranscriptPurchasableHeader
            content={content}
            contentTitle={externalTitle}
            scheduledTimeUTC={scheduledTimeUTC}
            isContentPreScheduledTime={isDateInTheFuture(scheduledTimeUTC)}
            contentApprovalStatus={approvalStatus}
            contentPurchaseStatus={purchaseStatus}
            {...transcriptPurchaseProps}
          />
          <Divider />
        </>
      )}
      {!isUpcoming && (
        <TranscriptToolbar
          content={content}
          viewMode={viewMode}
          setViewMode={setViewMode}
          transcriptToolsProps={transcriptToolbarProps}
          transcriptSearchProps={transcriptSearchProps}
        />
      )}
      <Divider />
    </div>
  );
};

export default TranscriptControlPanel;
