import { FC } from "react";
import { useThemeTokens } from "@alphasights/alphadesign-components";

import Filter from "components/Filter";
import {
  TITLE,
  OPTIONS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/company-data-filters/OwnershipFilter/constants";
import CheckboxFilter from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/CheckboxFilter";
import { SearchFilters } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { FilterProps } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/filters/types";

interface OwnershipFilterProps extends FilterProps {
  value: string[];
}

const OwnershipFilter: FC<OwnershipFilterProps> = ({ value = [], onChange }) => {
  const { color } = useThemeTokens();

  return (
    <Filter title={TITLE} isAccordion color={color.text.secondary} border="0">
      <CheckboxFilter filterKey={SearchFilters.ownershipType} value={value} options={OPTIONS} onChange={onChange} />
    </Filter>
  );
};

export default OwnershipFilter;
export type { OwnershipFilterProps };
