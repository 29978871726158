import { FC, ReactElement, CSSProperties, Key } from "react";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

const OverlayAlert: FC<{
  isVisible: boolean;
  icon: ReactElement;
  text: string;
  iconStyles?: object;
  keyInfo?: Key;
}> = ({ isVisible, icon, text, keyInfo: key, iconStyles = {} }) => {
  const {
    spacing: { inner },
    color: {
      background,
      text: { info },
    },
  } = useThemeTokens();
  return (
    <x.div
      {...{ key }}
      backgroundColor={background.info}
      padding="8px 16px"
      alignItems="center"
      justifyContent="center"
      display="flex"
      gap={inner.base02}
      zIndex="9999"
      borderRadius={inner.base}
      opacity={isVisible ? 1 : 0}
      pointerEvents={isVisible ? "all" : "none"}
    >
      <x.div {...iconStyles}>
        <Icon color={info}>{icon}</Icon>
      </x.div>
      <Typography color={info} variant="body-em">
        {text}
      </Typography>
    </x.div>
  );
};

export const AlertContainer = styled.divBox(({ isVisible, style }: { isVisible: boolean; style?: CSSProperties }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return {
    transition: "opacity 0.25s",
    "pointer-events": "none",
    opacity: isVisible ? 1 : 0,
    bottom: inner.base05,
    width: "100%",
    "z-index": "9999",
    display: "flex",
    "justify-content": "center",
    cursor: "pointer",
    ...style,
  };
});

export default OverlayAlert;
