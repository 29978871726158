import { forwardRef, useEffect, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { Accordion, Button, Typography, Switch } from "@alphasights/alphadesign-components";
import { filterDefinitions } from "../../../components/InteractionsPage/ExpertFilters";
import {
  FilterContent,
  SurveyAndCallStatusAccordionItem,
} from "../../../components/InteractionsPage/InteractionsFilter";
import { MobileAccordionItem, MobileFilterFormButtons, useMobileFilterStyles } from "./MobileInteractionsFilter.styles";
import { Project } from "@alphasights/portal-api-client";

export interface MobileInteractionsFilterProps {
  project: Project;
  workstream?: Workstream;
  appliedFilters: any;
  options: any;
  hasAvailability: boolean;
  onFilterApply: () => void;
  onResetAllFilters: () => void;
  onSubmitFilters: (filters: any) => void;
  onToggleAvailabilityFilter: (hasAvailability: boolean) => void;
}

export const MobileInteractionsFilter = forwardRef<HTMLDivElement, any>(
  (
    {
      project,
      workstream,
      appliedFilters,
      options,
      hasAvailability,
      onFilterApply,
      onResetAllFilters,
      onSubmitFilters,
      onToggleAvailabilityFilter,
    }: MobileInteractionsFilterProps,
    ref
  ) => {
    const { wrapper, hasAvailabilityWrapper, filterButtonsPanel } = useMobileFilterStyles();

    const [filters, setFilters] = useState<any>({ ...appliedFilters });
    const [hasAvailabilityFilter, setHasAvailabilityFilter] = useState<boolean>(hasAvailability);

    const filterOptions = useMemo(
      () =>
        filterDefinitions({
          options,
          appliedFilters: filters,
          project,
          moreFilters: true,
          workstream,
          isDoubleBlindedSurvey: false,
          isSurveyPage: false,
        }).map(removeFilterOptions),
      [options, filters, project, workstream]
    );

    const filterApplied = (values: any, option: any) => {
      filters[option.name] = values;
    };

    const hasSurveyAndCallFilters = (options: any[]) =>
      options.find((o) => o.name === "survey_response_status") && options.find((o) => o.name === "status");

    const genericOptionsFor = (options: any[]) => {
      return !hasSurveyAndCallFilters(options)
        ? options
        : options.filter((o) => !["status", "survey_response_status"].includes(o.name));
    };

    const handleFilterApply = () => {
      onSubmitFilters(filters);
      onFilterApply();
      onToggleAvailabilityFilter && onToggleAvailabilityFilter(hasAvailabilityFilter);
    };

    const handleClear = () => {
      onResetAllFilters();
      setFilters({});
      setHasAvailabilityFilter(false);
    };

    const handleAvailabilityFilter = () => {
      setHasAvailabilityFilter((prev) => {
        return !prev;
      });
    };

    useEffect(() => {
      setFilters({ ...appliedFilters });
    }, [setFilters, appliedFilters]);

    return (
      <x.div {...wrapper} data-testid="interactions-filters" ref={ref}>
        <x.div {...hasAvailabilityWrapper}>
          <Typography variant="body">Has availability</Typography>
          <Switch
            dataAttributes={{ "data-testid": "has-availability-filter" }}
            size="medium"
            isSelected={hasAvailabilityFilter}
            onClick={handleAvailabilityFilter}
          />
        </x.div>
        <Accordion>
          {hasSurveyAndCallFilters(filterOptions) && (
            <SurveyAndCallStatusAccordionItem
              appliedFilters={appliedFilters}
              filterOptions={filterOptions}
              size="medium"
              filterAppliedCallback={filterApplied}
            />
          )}
          {genericOptionsFor(filterOptions).map((option, index) => (
            <MobileAccordionItem
              key={`item-${index}`}
              data-testid={`interaction-filter-${index}`}
              size="medium"
              titleContent={
                <Typography variant="body-em">
                  {option.title +
                    getAppliedFiltersCountFormatter({
                      appliedFilters: option.value,
                    })}
                </Typography>
              }
              open={option.value.length > 0}
            >
              <FilterContent
                key={`filter-content-${index}`}
                filterOption={option}
                filterAppliedCallback={(values: any) => filterApplied(values, option)}
              ></FilterContent>
            </MobileAccordionItem>
          ))}
        </Accordion>
        <x.div {...filterButtonsPanel}>
          <MobileFilterFormButtons>
            <Button data-testid="interactions-filter-btn-clear" variant="outline" size="small" onClick={handleClear}>
              Reset
            </Button>
            <Button
              data-testid="interactions-filter-btn-apply"
              variant="secondary"
              size="small"
              onClick={handleFilterApply}
            >
              Apply
            </Button>
          </MobileFilterFormButtons>
        </x.div>
      </x.div>
    );
  }
);

const removeFilterOptions = (filterDef: any) => {
  return filterDef.name === "profile_activity"
    ? { ...filterDef, options: (filterDef.options || []).filter(({ value }: any) => value !== "Hidden") }
    : filterDef.name === "date_added"
    ? { ...filterDef, options: (filterDef.options || []).filter(({ value }: any) => value !== "New") }
    : filterDef;
};

const getAppliedFiltersCountFormatter = ({ appliedFilters }: { appliedFilters: unknown[] }) =>
  appliedFilters.length > 0 ? ` (${appliedFilters.length})` : "";
