import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const DataArea = ({ icon, children, ...others }) => {
  const { color, spacing } = useThemeTokens();

  return (
    <x.div display="flex" {...others}>
      <x.div pr={spacing.inner.base02}>{icon && <Icon color={color.icon.secondary}>{icon}</Icon>}</x.div>
      <x.div flex="1">{children}</x.div>
    </x.div>
  );
};
