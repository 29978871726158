import { FC, useEffect, useRef } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCitationContext } from "./CitationContext";
import { CitationRendererProps, ToStringRenderer } from "./PropertyRenderers";
import { isEmpty, isEqual, isString, last, sum, values } from "lodash";
import { useCitationTrackingContext } from "./CitationTrackingContext";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ContributedBy } from "components/Experts/ContributedBy";

export const DataTestIds = Object.freeze({
  keyword: "primers--citation--keyword",
  disabled: "Citation-disabled",
  contribution: "citation-contribution",
});

export const MarkdownReplacements = {
  "<em>": "{em}",
  "</em>": "{/em}",
};

const renderKeywordHighlight = (
  citation: CitableValue<unknown>,
  renderer?: (props: CitationRendererProps<any>) => React.ReactNode
) => {
  if (
    renderer !== ToStringRenderer ||
    !isString(citation.value) ||
    !citation.value.includes(MarkdownReplacements["<em>"])
  ) {
    return null;
  }

  const regex = /(?<before>.*?){em}(?<keyword>.*?){\/em}/g;
  const matches = [...citation.value.matchAll(regex)];

  if (isEmpty(matches)) return null;

  const lastMatch = last(matches);

  const endOfGroupIndex = (match: RegExpMatchArray) =>
    match.index! +
    sum(values(match.groups).map((group) => group.length)) +
    MarkdownReplacements["</em>"].length +
    MarkdownReplacements["<em>"].length;

  return (
    <>
      {matches.map((match, index) => (
        <>
          {ToStringRenderer({ value: match.groups!.before })}
          <em data-testid={DataTestIds.keyword}>{ToStringRenderer({ value: match.groups!.keyword })}</em>
        </>
      ))}
      {ToStringRenderer({
        value: citation.value.substring(endOfGroupIndex(lastMatch!), citation.value.length),
      })}
    </>
  );
};

export interface CitationProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  value: CitableValue<T>;

  /**
   * An optional custom renderer.
   *
   * Defaults to calling `toString()` on the {@link values.value}.
   **/
  renderer?: (props: CitationRendererProps<T>) => React.ReactNode;

  /**
   * If the citation should function as a citation or not.
   *
   * If false, all citation features (highlighting, scroll to nearest)
   * will be disabled and it will only render the value.
   *
   * Default: [true]
   */
  isEnabled?: boolean;
}

/**
 * Utility to render highlights for speaker citations.
 *
 * Automatically handles highlighting and selection of citations.
 */
export function Citation<T>({
  isEnabled = true,
  renderer = ToStringRenderer,
  value: citation,
  ...unconsumedProps
}: CitationProps<T>): JSX.Element | null {
  const {
    spacing: { inner },
    font: { lineHeight },
  } = useThemeTokens();

  const { citationsEnabled } = useCitationContext();

  if (citationsEnabled && isEnabled) {
    return <EnabledCitation {...{ renderer, ...unconsumedProps }} value={citation} />;
  }

  return (
    <x.span
      {...unconsumedProps}
      flexShrink={1}
      lineHeight={lineHeight["02"]}
      paddingTop={inner.base}
      paddingBottom={inner.base}
      data-testid={DataTestIds.disabled}
    >
      {renderKeywordHighlight(citation, renderer) ?? renderer({ value: citation.value! })}
    </x.span>
  );
}

interface EnabledCitationProps<T> extends CitationProps<T> {}

function EnabledCitation<T>({
  value: citation,
  renderer = ToStringRenderer,
  isEnabled = true,
  ...unconsumedProps
}: EnabledCitationProps<T>) {
  const {
    isCitationClicked,
    selectedContentId,
    selectedSpeakers,
    selectedSpeakerIds,
    selectedCitation,
    onSelectCitation,
  } = useCitationContext();
  const {
    spacing: { inner },
    color,
  } = useThemeTokens();
  const { registerCitation } = useCitationTrackingContext();
  const { isMobile } = useCheckScreen();

  const citationRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    registerCitation(citation, citationRef);
  }, [citation]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!citation?.value) return null;

  const isExpertSelected = citation.citedBy.some((speakerIds) => selectedSpeakerIds.includes(speakerIds));

  const hasCitation = !isEmpty(citation.citedBy);

  const isCitationSelected = isEqual(selectedCitation, citation);
  const isHighlighted = hasCitation && ((isExpertSelected && !isCitationClicked) || isCitationSelected);

  const isUnderlinable = isMobile && isEnabled && !!citation?.citedBy?.length;
  const showContribution = isMobile && selectedContentId && isCitationSelected && selectedSpeakers;

  const citations = parseInlineCitations(citation);

  if (!isEmpty(citations)) {
    return <InlineCitation {...unconsumedProps} citations={citations!} />;
  }

  const highlightColorHex = color.background.highlightBase;
  const underlineColorHex = color.border.warning;
  const opacityHex = Math.floor(0.45 * 255).toString(16); // 45% opacity
  const transparentColor = "transparent";

  return (
    <x.span
      ref={citationRef}
      {...unconsumedProps}
      flexShrink={1}
      cursor={hasCitation ? "pointer" : undefined}
      background={{
        _: isHighlighted ? highlightColorHex : transparentColor,
        hover: hasCitation
          ? isHighlighted
            ? highlightColorHex
            : `${highlightColorHex}${opacityHex}`
          : transparentColor,
      }}
      textDecoration={isUnderlinable ? (isHighlighted ? "none" : `underline ${underlineColorHex}`) : "none"}
      marginTop={inner.base}
      marginBottom={inner.base}
      onClick={() => onSelectCitation(citation, isEnabled)}
    >
      {renderKeywordHighlight(citation, renderer) ?? renderer({ value: citation.value! })}
      {showContribution && (
        <x.div my={inner.base05} data-testid={DataTestIds.contribution}>
          <ContributedBy contentId={selectedContentId!} selectedExperts={selectedSpeakers} />
        </x.div>
      )}
    </x.span>
  );
}

export const parseInlineCitations = (citation: CitableValue<any>) => {
  if (!citation || typeof citation.value !== "string") return null;

  const citationValue = citation.value!;

  const inlineCitationMatches = [...(citationValue.matchAll(/(?<text>.*?)(?<labels>{{.*?}})/g) ?? [])];

  if (isEmpty(inlineCitationMatches)) return null;

  // Finds all sentences marked as a citation:
  // "Text 1 {{a}} Text 2 {{b}} Text 3" --> "Text 1 {{a}}" "Text 2 {{b}}"
  // however the ending text that isn't part of a citation is still left out: "Text 3"
  const citations: SpacedCitation[] = inlineCitationMatches.map((match) => {
    const citedBy = parseCitationExperts(match.groups!.labels);

    // characters before & after the citation labels 👉 {{a}} 👈
    // E.g. Hello World{{4}}. -- characterBefore: 'd', characterAfter: '.'
    const characterBefore = citationValue[match.index! + match.groups!.text!.length - 1];
    const characterAfter = citationValue[match.index! + match[0].length];

    const citedText = match.groups!.text.trimEnd();

    return {
      spacingAfterText: /[!?,;."')/~>%*|\]]/.test(characterAfter) || /["'([/~<$[)]/.test(characterBefore) ? "" : " ",
      citation: {
        value: citedText,
        citedBy,
      },
    };
  });

  // Handle any remaining text after the final citation:
  // "Text 1 {{a}} Text 2 {{b}} Text 3" --> Text 3
  const closingCurlyPosition = citationValue.lastIndexOf("}}");

  // Only include a value for the text if:
  // the string contains citations & the string does not end with a closing citation
  if (![-1, citationValue.length].includes(closingCurlyPosition)) {
    const remainingText = citationValue.substring(
      closingCurlyPosition + 2, // +2 because we need to include the two characters for }} (index starts at the beginning)
      citationValue.length
    );

    citations.push({
      citation: { value: remainingText.trim(), citedBy: [] },
      spacingAfterText: "",
    });
  }

  return citations;
};

interface SpacedCitation {
  /**
   * The character to use for spacing between cited text and the following text.
   *
   * The character does **not** get rendered inside the highlighted part of the text.
   *
   * Example: "Hello {{4}} World" { spacingAfterText: "#" } => "Hello#World"
   *
   * Usually '' or ' '.
   **/
  spacingAfterText: string;
  citation: CitableValue<string>;
}

interface InlineCitationProps extends React.HTMLAttributes<HTMLDivElement> {
  citations: SpacedCitation[];
}

/**
 * Renders inline citations, handling hovering and highlighting for each citation within the text.
 *
 * Inline citations are represented by {{speakerIds}}.
 *
 * Example of an inline citation:
 * Uncited text before {{}} lorem ipsum dolor. {{1}} Another sentence. {{2, 3}} Uncited text after
 * ^   citedBy: []   ^      ^  citedBy: [1]  ^       ^citedBy: [2, 3]^          ^   citedBy: []  ^
 */
export const InlineCitation: FC<InlineCitationProps> = ({ citations, ...unconsumedProps }) => {
  if (isEmpty(citations)) return null;

  return (
    <span>
      {citations.map(({ spacingAfterText: spacing, citation: { value, citedBy } }, index) => (
        <span key={index}>
          <Citation {...unconsumedProps} value={{ value, citedBy }} isEnabled={!isEmpty(citedBy)} />
          {spacing}
        </span>
      ))}
    </span>
  );
};

const parseCitationExperts = (text: string) => {
  const output = text
    .replace("{{", "")
    .replace("}}", "")
    .split(",")
    .map((it) => parseInt(it.trim()));

  // No citations were found
  if (output.length === 1 && isNaN(output[0])) return [];

  return output;
};
