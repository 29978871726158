import * as React from "react";
import { useMarketPrimersStore } from "../../state/store";
import OverviewCompetitorsDiagram from "./OverviewCompetitorsDiagram";
import { CitationWrapper, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import SectionWrapper from "pages/AlphaNowPage/primers/CompanyPrimer/versions/v3/sections/styled";

const Overview = (_: unknown, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { overview } = useMarketPrimersStore();
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <SectionWrapper ref={ref} px={inner.base06}>
      <x.div display="flex" gap={inner.base06} flexDirection="column" data-testid="overviewSectionWrapper">
        <x.div display="flex" gap={inner.base04} flexDirection="column">
          <PrimerFieldTitle text="Scope of Market Primer" />
          <OverviewCompetitorsDiagram />
        </x.div>
        <CitationWrapper value={overview.description} label="Description" verticalLabel dataTestId="overview" />
        <CitationWrapper
          dataTestId="exampleUseCases"
          value={overview.exampleUseCases}
          label="Example Use Cases"
          verticalLabel
        />
        <CitationWrapper value={overview.customers} label="Customers" verticalLabel />
        <CitationWrapper value={overview.products} label="Products" verticalLabel />
        <CitationWrapper value={overview.regions} label="Regions" verticalLabel />
        {overview.keyOperatingMetrics.value && overview.keyOperatingMetrics.value.length > 0 && (
          <CitationWrapper value={overview.keyOperatingMetrics} label="Key Operating Metrics" verticalLabel />
        )}
      </x.div>
    </SectionWrapper>
  );
};

export default React.memo(React.forwardRef(Overview));
