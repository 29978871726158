import { createContext, ReactNode, useCallback, useContext, useMemo } from "react";

// context for user badges
interface UserBadgeProviderProps {
  children: ReactNode;
  badges: string[];
}

interface UserBadgeProviderState {
  hasUserBadge: (badge: string) => boolean;
}

const UserBadgeContext = createContext<undefined | UserBadgeProviderState>(undefined);

const UserBadgeProvider = ({ badges = [], ...props }: UserBadgeProviderProps) => {
  const _getQueryParam = (queryParam: string): string | null =>
    new URLSearchParams(window.location.search).get(queryParam);

  const _hasQueryParamOverride = (queryParam: string) => _getQueryParam(queryParam) !== null;

  /**
   * Determines if a feature is enabled for the current user.
   *
   * Allows overriding the feature via a query parameter for testing.
   */
  const hasUserBadge = useCallback(
    (badge: string): boolean => {
      if (_hasQueryParamOverride(badge)) {
        return ["", "true"].includes(_getQueryParam(badge)!!);
      }

      return Boolean(badges.indexOf(badge) >= 0);
    },
    [badges] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const memoizedValue: UserBadgeProviderState = useMemo(() => ({ hasUserBadge }), [hasUserBadge]);

  return <UserBadgeContext.Provider value={memoizedValue} {...props} />;
};

const UserBadgeContextError = new Error("useUserBadgeContext should only be used within the UserBadgeProvider.");

const useUserBadgeContext = () => {
  const context = useContext(UserBadgeContext);
  if (context === undefined) throw UserBadgeContextError;
  return context;
};

interface UserBadgeProps {
  badge: string;
  children: JSX.Element;

  fallback?: JSX.Element | null;
}

const UserBadged = ({
  badge,
  children,

  fallback = null,
}: UserBadgeProps) => {
  const { hasUserBadge } = useUserBadgeContext();

  if (hasUserBadge(badge)) {
    return children;
  }

  return fallback;
};

export { useUserBadgeContext, UserBadged, UserBadgeContext, UserBadgeContextError, UserBadgeProvider };

// context for project badges
interface ProjectBadgeProviderProps {
  children: ReactNode;
  badges: string[];
}

interface ProjectBadgeProviderState {
  hasProjectBadge: (badge: string) => boolean;
}

export const ENABLE_PORTAL_COMMENTS = "enablePortalComments";
export const CUSTOMER_KNOWLEDGE = "enablePortalCustomerKnowledge";
export const PORTAL_WORKSTREAM_BADGE = "enablePortalWorkstreams";
export const ENABLE_PORTAL_MESSAGES = "enablePortalMessages";
export const ENABLE_PROJECT_MESSENGER_APPROVAL_FLOW = "enableProjectMessengerApprovalFlow";
export const ENABLE_COMPLIANCE_REVIEW_WORK_REQUESTS = "enableComplianceReviewWorkRequests";
export const ENABLE_COMPLIANCE_REVIEW_CLARIFICATIONS = "enableComplianceReviewClarifications";
export const ENABLE_COMPLIANCE_REVIEW_CALL_GUIDES = "enableComplianceReviewCallGuides";
export const ENABLE_SIX_YEARS_MESSAGE_RETENTION_POLICY = "enableSixYearsMessageRetentionPolicy";
export const ENABLE_AI_SUMMARIZATION = "enableAiSummarization";
export const SHOW_DISABLED_AI_SUMMARIZATION = "showDisabledAiSummarization";
export const ENABLE_PROJECT_SUMMARIZATION = "enableProjectSummarization";
export const ENABLE_COMPARISON_VIEW = "enableComparisonView";
export const ENABLE_AI_TRANSCRIPT_DOWNLOAD = "enableDownloadAiTranscriptsFromPlatform";
export const ENABLE_REGULAR_TRANSCRIPT_DOWNLOAD = "enableDownloadRegularTranscriptsFromPlatform";
export const ENABLE_PAST_PROJECT_DELIVERABLES = "enablePastProjectsDeliverables";

// TODO - define a global project type in the models folder
const normalizeProjectBadges = (project: any) => {
  if (!project) return [];

  const enabled = [
    ENABLE_PORTAL_COMMENTS,
    PORTAL_WORKSTREAM_BADGE,
    ENABLE_AI_SUMMARIZATION,
    SHOW_DISABLED_AI_SUMMARIZATION,
    CUSTOMER_KNOWLEDGE,
    ENABLE_PORTAL_MESSAGES,
    ENABLE_PROJECT_MESSENGER_APPROVAL_FLOW,
    ENABLE_COMPLIANCE_REVIEW_WORK_REQUESTS,
    ENABLE_COMPLIANCE_REVIEW_CLARIFICATIONS,
    ENABLE_COMPLIANCE_REVIEW_CALL_GUIDES,
    ENABLE_SIX_YEARS_MESSAGE_RETENTION_POLICY,
    ENABLE_PROJECT_SUMMARIZATION,
    ENABLE_COMPARISON_VIEW,
    ENABLE_AI_TRANSCRIPT_DOWNLOAD,
    ENABLE_REGULAR_TRANSCRIPT_DOWNLOAD,
    ENABLE_PAST_PROJECT_DELIVERABLES,
  ].filter((prop) => project[prop]);

  return [...project.badges, ...enabled];
};

const ProjectBadgeContext = createContext<undefined | ProjectBadgeProviderState>(undefined);

const ProjectBadgeProvider = ({ badges = [], ...props }: ProjectBadgeProviderProps) => {
  const badgesMap = useMemo(() => {
    const map = new Map<string, boolean>();
    badges.forEach((badge) => map.set(badge, true));
    return map;
  }, [badges]);
  const hasProjectBadge = useCallback(
    (badge: string): boolean => badgesMap.has(badge),
    [badges] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const memoizedValue: ProjectBadgeProviderState = useMemo(
    () => ({
      badges,
      hasProjectBadge,
    }),
    [badges, hasProjectBadge]
  );

  return <ProjectBadgeContext.Provider value={memoizedValue} {...props} />;
};

const useProjectBadgeContext = () => {
  const context = useContext(ProjectBadgeContext);
  if (context === undefined)
    return {
      badges: [],
      hasProjectBadge: () => false,
    };
  return context;
};

interface BadgedProps {
  badge: string;
  children: JSX.Element;
  not?: boolean;
  fallback?: JSX.Element | null;
}

const ProjectBadged = ({ badge, children, not = false, fallback = null }: BadgedProps) => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const hasBadge = hasProjectBadge(badge);

  if (not ? !hasBadge : hasBadge) {
    return children;
  }

  return fallback;
};

export { ProjectBadged, ProjectBadgeContext, ProjectBadgeProvider, normalizeProjectBadges, useProjectBadgeContext };
