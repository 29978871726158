import { useEffect } from "react";
import * as React from "react";
import { useRecommendedResearchPanelStyles } from "./RecommendedResearchPanel.styles";
import { x } from "@xstyled/styled-components";
import { Info } from "@alphasights/alphadesign-icons";
import {
  AlphaNowContentType,
  ContentCard,
  ContentContainer,
  useTrackUserAction,
} from "@alphasights/client-portal-shared";
import { useProjectLauncherContext } from "providers/ProjectLauncherProvider";
import { Divider, Icon, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { ResearchSummary } from "models/ResearchSummary";
import ResearchSummarizationCard from "../ResearchSummarizationCard";
import styled, { th } from "@xstyled/styled-components";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";

export const RecommendedResearchPanel: React.FC = () => {
  const { logHit } = useTrackUserAction();
  const { companies, searchRecommendedContent, recommendedContent } = useProjectLauncherContext();
  const { researchList } = useRecommendedResearchPanelStyles();

  useEffect(() => {
    searchRecommendedContent();
  }, [companies, searchRecommendedContent]);

  const logClick = (details: Record<string, any>) => {
    logHit({
      origin: HitOrigin.projectLaunchPage,
      action: HitAction.projectLaunchRecommendedResearchClicked,
      details,
    });
  };

  const onClickContent = (content: ContentResults) => {
    logClick({ contentId: content.id });
  };

  const onClickSummary = (researchSummary: ResearchSummary) => {
    logClick({ companyId: researchSummary.companyId });
  };

  const renderRecommendedResearch = () =>
    recommendedContent.map((content, index) => (
      <Tooltip key={`tooltip-${content.id}`} title="Launch project to gain access.">
        <ContentCardStyled key={index} content={content} isSelected={false} onClick={() => onClickContent(content)} />
      </Tooltip>
    ));

  const renderResearchSummarization = () => {
    const researchSummaryMap: Record<number, ResearchSummary> = {};
    const companyIds = new Set(companies.map((company) => company.id));
    recommendedContent.forEach((content) => {
      content.companies
        .filter((company) => companyIds.has(company.companyId))
        .forEach((company) => {
          if (!researchSummaryMap[company.companyId]) {
            researchSummaryMap[company.companyId] = {
              companyId: company.companyId,
              moderatedCalls: 0,
              investorLedCalls: 0,
              primers: 0,
            };
          }
          const researchSummary = researchSummaryMap[company.companyId];
          computeResearchSummary(researchSummary, content.contentType);
        });
    });

    return Object.values(researchSummaryMap).map((summary) => (
      <Tooltip key={`tooltip-${summary.companyId}`} title="Launch project to gain access.">
        <div>
          <ResearchSummarizationCard
            company={companies.find((c) => c.id === summary.companyId)!!}
            recommendedResearch={summary}
            onClick={() => onClickSummary(summary)}
          />
        </div>
      </Tooltip>
    ));
  };

  const recommendedResearch =
    recommendedContent.length <= 2 ? renderRecommendedResearch() : renderResearchSummarization();

  return (
    <ContentContainerStyled title="Recommended Research">
      <>
        <DividerStyled />
        <x.div {...researchList}>
          {recommendedResearch.length === 0 ? <NoRecommendedResearch /> : recommendedResearch}
        </x.div>
      </>
    </ContentContainerStyled>
  );
};

const DividerStyled = styled(Divider)`
  border-bottom: 0.5px solid $th.color("border-ai-subtle");
  width: calc(100% + 48px);
  margin: 0 -24px;
`;

const NoRecommendedResearch = () => {
  const { noRecommendedContentContainer, noRecommendedContentIcon } = useRecommendedResearchPanelStyles();

  return (
    <x.div {...noRecommendedContentContainer}>
      <x.div {...noRecommendedContentIcon}>
        <Icon>
          <Info />
        </Icon>
      </x.div>

      <Typography variant="body-small" color="secondary">
        Add details to suggest recommended research
      </Typography>
    </x.div>
  );
};

function computeResearchSummary(researchSummary: ResearchSummary, productType: AlphaNowContentType | undefined): void {
  switch (productType) {
    case AlphaNowContentType.alphaview:
      researchSummary.moderatedCalls++;
      break;
    case AlphaNowContentType.pcc:
      researchSummary.investorLedCalls++;
      break;
    case AlphaNowContentType.srm:
      researchSummary.primers++;
      break;
  }
}

const ContentContainerStyled = styled(ContentContainer)`
  padding: ${th.space("inner-base06")};
  height: 100%;
  border-radius: ${th.space("inner-base03")};
  overflow: auto;
`;

const ContentCardStyled = styled(ContentCard)`
  cursor: not-allowed;
  &:hover {
    background-color: ${th.color("background-surface-page-hover")};
  }
`;
