import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";

const PrimerCompanyDateContent = ({
  lastUpdatedAtHeader,
  formattedDate,
  dataTestId,
}: {
  lastUpdatedAtHeader: string;
  formattedDate: string;
  dataTestId: string;
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <Typography component="span" data-testid={dataTestId} pr={inner.base} flexWrap="nowrap" color={text.secondary}>
      {lastUpdatedAtHeader}
      {formattedDate}
    </Typography>
  );
};

export default PrimerCompanyDateContent;
