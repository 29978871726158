import { Fragment, useEffect, useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  MessengerAdvisorThreadCard,
  MessengerQuestionCard,
} from "../MessengerAdvisorThreadCard/MessengerAdvisorThreadCard";
import { Alert, Divider, Link, Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";
import { InboxResponse, ThreadResponse, ResponseStatusType, ComplianceReviewStatus } from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { useLocation } from "router-utils";
import { getRequestTypeName } from "pages/MessengerPage/utils";

export const MessengerBody = ({ inbox }: { inbox: InboxResponse }) => {
  const location = useLocation();
  const [highlightSelectedThread, setHighlightSelectedThread] = useState(true);
  const { selectedInbox } = useMessengerContext();
  const { container, divider } = useMessengerBodyStyles();
  const question = inbox?.threads[0]?.messages[0];

  useEffect(() => {
    const selectedThread = new URLSearchParams(location.search).get("selectedThread");
    if (selectedThread && selectedInbox && highlightSelectedThread) {
      const element = document.querySelector(`div[data-messenger-card-thread-id='${selectedThread}']`) as HTMLElement;
      if (element) {
        setTimeout(() => {
          setHighlightSelectedThread(false);
          element.scrollIntoView({ behavior: "smooth" });
          element.animate(
            {
              backgroundColor: ["rgba(49, 129, 255, 0)", "rgba(49, 129, 255, 0.5)", "rgba(49, 129, 255, 0)"],
            },
            1000
          );
        }, 0);
      }
    }
  }, [selectedInbox, location.search, highlightSelectedThread]);

  const hideResponseStatus = useMemo(
    () =>
      question.complianceReviewStatus !== undefined &&
      [
        ComplianceReviewStatus.PENDING,
        ComplianceReviewStatus.REJECTED,
        ComplianceReviewStatus.REJECTED_WITH_SUGGESTION,
        ComplianceReviewStatus.SUGGESTION_DECLINED,
        ComplianceReviewStatus.SUGGESTION_PENDING,
      ].includes(question.complianceReviewStatus),
    [question]
  );

  return (
    <x.div {...container}>
      <DeadlineExpiredBanner threads={inbox.threads} />
      <MessengerQuestionCard question={question} messageTypeName={getRequestTypeName(inbox?.threads[0].requestType)} />
      <Divider {...divider} />
      {inbox?.threads.map((thread, index) => (
        <Fragment key={index}>
          <MessengerAdvisorThreadCard
            key={thread.id}
            thread={thread}
            isFirstThread={index === 0}
            hideStatus={hideResponseStatus}
          />
          <Divider {...divider} />
        </Fragment>
      ))}
    </x.div>
  );
};

const useMessengerBodyStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const container = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pb: inner.base06,
    overflowY: "auto",
    pt: inner.base04,
  };
  const skeletonContainer = {
    w: "80%",
    p: inner.base04,
    ml: "auto",
    mr: "auto",
    display: "flex",
    flexDirection: "column",
    gap: inner.base04,
  };

  const divider = {
    w: "100%",
    mt: inner.base03,
    mb: inner.base03,
  };

  return {
    container,
    skeletonContainer,
    divider,
  };
};

export const FullWidthBanner = styled(Alert)`
  width: 80%;
`;

const DeadlineExpiredBanner = ({ threads }: { threads: ThreadResponse[] }) => {
  const { onOpenSettings } = useMessengerContext();
  const { banner } = useDeadlineExpiredBannerStyles();
  const expiredExperts = useMemo(
    () =>
      threads
        .filter(
          (thread) =>
            thread.state.description === ResponseStatusType.Expired ||
            thread.state.description === ResponseStatusType.ExpiredAwaitingApproval
        )
        .map((thread) => thread.advisor.id),
    [threads]
  );

  return (
    <>
      {expiredExperts.length > 0 && (
        <FullWidthBanner {...banner} variant="warning" data-testid="deadline-expired-banner">
          Deadline expired for {expiredExperts.length} expert
          {expiredExperts.length > 1 ? "s" : ""}. Extend the deadline by setting a new date and time in message
          settings.{" "}
          <Link onClick={() => onOpenSettings(expiredExperts)} component="button">
            Extend deadline
          </Link>
        </FullWidthBanner>
      )}
    </>
  );
};

const useDeadlineExpiredBannerStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const banner = {
    mt: inner.base06,
  };

  return {
    banner,
  };
};

export const MessengerBodySkeleton = () => {
  const { skeletonContainer } = useMessengerBodyStyles();

  return (
    <x.div {...skeletonContainer}>
      <x.div>
        <Skeleton width="25%" />
        <Skeleton count={2} />
      </x.div>
      <MessengerAdvisorThreadCardSkeleton />
      <MessengerAdvisorThreadCardSkeleton />
      <MessengerAdvisorThreadCardSkeleton />
    </x.div>
  );
};

const MessengerAdvisorThreadCardSkeleton = () => {
  return (
    <x.div>
      <x.div display="flex">
        <x.div>
          <Skeleton circle height="52px" />
        </x.div>
        <Skeleton width="25%" count={2} />
      </x.div>
      <Skeleton count={2} />
    </x.div>
  );
};
