import { Button, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const TranscriptSearchPopoverContainer = styled.span`
  display: flex;
  align-items: center;
  width: 350px;
`;

export const StyledCloseSearchButton = styled(Button).attrs({ variant: "icon" })(() => {
  const { spacing } = useThemeTokens();
  return {
    marginInline: spacing.inner.base03,
  };
});
