import { useIsAuthenticated, useProjectsIsReady, useUserAuthIsReady } from "@alphasights/portal-auth-react";

/*
  This hook is used to check if the user projects are loaded.
  We can only ensure that the projects are loaded when we have tried to authenticate the user
  and the user is either not authenticated or their projects are ready.
*/

export const useProjectsLoaded = () => {
  const isAuthReady = useUserAuthIsReady();
  const isAuthenticated = useIsAuthenticated();
  const projectsReady = useProjectsIsReady();
  return isAuthReady && (projectsReady || !isAuthenticated);
};
