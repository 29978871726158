import { Accordion, AccordionItem, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const FeaturedExpertTitleContainer = styled.div(() => {
  const { spacing, color, shape } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    gap: spacing.inner.base02,
    lineHeight: spacing.inner.base04,
    alignItems: "center",
    "& > div:first-of-type": {
      backgroundColor: color.background.surface.page.default,
      border: `${shape.border.width.sm} solid ${color.border.neutral.default}`,
    },
  };
});

export const JobTitleContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const JobDetailsContainer = styled.div(() => {
  const { color } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "row" as "row",
    color: color.text.secondary,
  };
});

export const StyledSectionHeader = styled(Typography)(() => {
  const { color } = useThemeTokens();
  return {
    color: color.text.strong._,
  };
});

export const AccordionItemContentContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    gap: spacing.inner.base04,
  };
});

export const RequestExpertContainer = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: spacing.inner.base04,
  };
});
export const StyledAccordion = styled(Accordion)(() => {
  const { spacing } = useThemeTokens();
  return {
    paddingInline: spacing.inner.base06,
  };
});

export const StyledAccordionItem = styled(AccordionItem)(() => ({
  "& > div:last-of-type": {
    paddingBottom: 0,
  },
}));
