import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  ProjectSummaryAnglePill,
  ProjectSummaryContent,
  ProjectSummaryStatus,
  ProjectSummaryStatusResponse,
  SelectedInsight,
} from "views/DeliverablesView/ProjectSummaries/ProjectSummaries.types";
import projectSummariesService from "services/projectSummariesService";
import { ENABLE_PROJECT_SUMMARIZATION, useProjectBadgeContext } from "./BadgeProvider";
import _ from "lodash";
import { HitAction, HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentProjectContext } from "./CurrentProjectProvider";

export const PROJECT_SUMMARY_DELIVERABLES_THRESHOLD = 3;

export const ProjectSummariesContext = createContext<ProjectSummariesProviderState | undefined>(undefined);
const REFRESH_INTERVAL = 10000;

export interface CustomThemePayload {
  themesToRemove: string[];
  newThemes: {
    title: string;
    description: string;
  }[];
}

export interface ProjectSummariesProviderProps {
  projectToken: string;
  interactions: Interaction[];
  service?: typeof projectSummariesService;
  children?: JSX.Element;
  filterOptions: any;
}

export interface ProjectSummariesProviderState {
  isSummaryStatusLoading: boolean;
  selectedSummary: ProjectSummaryContent | undefined;
  onSelectSummary: (projectSummaryId: string) => void;
  generateProjectSummaries: () => Promise<void>;
  isSelectedSummaryLoading: boolean;
  getInteractionByAdvisorId: (advisorId: string) => Interaction | undefined;
  getInteractionById: (advisorId: string) => Interaction | undefined;
  angles: ProjectSummaryAnglePill[];
  generateSingleProjectSummary: (angleId?: string) => Promise<ProjectSummaryStatusResponse>;
  onChangeSelectedAngle: (angle: ProjectSummaryAnglePill) => void;
  onRegenerateOverview: (themeTitle?: string) => void;
  onDeleteCurrentSummary: () => void;
  onSubmitCustomTheme: (payload: CustomThemePayload) => Promise<ProjectSummaryContent>;
  onSelectInsight: (insight: SelectedInsight) => void;
  selectedInsight?: SelectedInsight;
  projectSummaryLogHit: (props: ProjectSummaryLogHitProps | ProjectSummaryLogHitProps[]) => void;
  uniqSummariesStatusByAngle: ProjectSummaryStatusResponse[];
  isAnySummaryGenerating: boolean;
  downloadSummaryUrl: string;
  readOnly: boolean;
}

export const ProjectSummariesProvider = ({
  projectToken,
  interactions,
  service = projectSummariesService,
  children,
  filterOptions,
}: ProjectSummariesProviderProps) => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const { logHit, bulkLogHit } = useTrackUserAction();
  const [currentSummariesStatus, setCurrentSummariesStatus] = useState<ProjectSummaryStatusResponse[]>([]);
  const [isSummaryStatusLoading, setIsSummaryStatusLoading] = useState(true);
  const [selectedSummary, setSelectedSummary] = useState<ProjectSummaryContent>();
  const [selectedInsight, onSelectInsight] = useState<SelectedInsight>();
  const [isSelectedSummaryLoading, setIsSelectedSummaryLoading] = useState(false);
  const [summaryReadyAngles, setSummaryReadyAngles] = useState<Record<string, string[]>>({});
  const [deliverablesReadyAngles, setDeliverablesReadyAngles] = useState<Record<string, string[]>>({});
  const [isRegenerationStarting, setIsRegenerationStarting] = useState(false);

  const { isFeatureDisabled } = useCurrentProjectContext();

  const readOnly = useMemo(() => isFeatureDisabled(ProjectFeature.ProjectSummary), [isFeatureDisabled]);

  const hasEnableProjectSummarizationBadge = useMemo(() => hasProjectBadge(ENABLE_PROJECT_SUMMARIZATION), [
    hasProjectBadge,
  ]);

  const uniqSummariesStatusByAngle = useMemo(() => {
    const statusByPriority = [
      ProjectSummaryStatus.Complete,
      ProjectSummaryStatus.Error,
      ProjectSummaryStatus.Processing,
      ProjectSummaryStatus.Pending,
    ];
    const getStatusPriority = (status: ProjectSummaryStatusResponse) => statusByPriority.indexOf(status.status);

    return _(currentSummariesStatus)
      .orderBy([getStatusPriority, "lastUpdatedAt"], ["asc", "desc"])
      .uniqBy("angleId")
      .value();
  }, [currentSummariesStatus]);

  const isAnySummaryGenerating = useMemo(() => {
    const isGenerating = currentSummariesStatus.some(
      (summaryStatus) =>
        summaryStatus.status === ProjectSummaryStatus.Pending ||
        summaryStatus.status === ProjectSummaryStatus.Processing
    );

    return isGenerating || isRegenerationStarting;
  }, [currentSummariesStatus, isRegenerationStarting]);

  const getInteractionByAdvisorId = useCallback(
    (advisorId: string) => {
      const interaction = interactions?.find((interaction) => interaction.advisorId === advisorId);
      return interaction;
    },
    [interactions]
  );

  const getInteractionById = useCallback(
    (id: string) => {
      const interaction = interactions?.find((interaction) => interaction.id === id);
      return interaction;
    },
    [interactions]
  );

  const getSummaryReadyAngles = useCallback(
    (statuses: ProjectSummaryStatusResponse[]) => {
      const grouped = _.groupBy(statuses, (resp) => resp.angleId || "project-wide");

      return service.getValidInteractionsByAngles(projectToken).then((response: Record<string, string[]>) => {
        const filtered = Object.keys(response).reduce((acc: Record<string, string[]>, key: string) => {
          const interactionIds = (_.get(grouped, key) || []).flatMap((status) => status.interactionIds);
          return {
            ...acc,
            [key]: response[key].filter((id) => interactionIds.includes(id)),
          };
        }, {});
        setSummaryReadyAngles(filtered);
        setDeliverablesReadyAngles(response);
      });
    },
    [projectToken, service]
  );

  useEffect(() => {
    if (hasEnableProjectSummarizationBadge) {
      getSummaryReadyAngles(uniqSummariesStatusByAngle);
    }
  }, [hasEnableProjectSummarizationBadge, getSummaryReadyAngles, uniqSummariesStatusByAngle]);

  const deliverablesOnAngle = useCallback(
    (angleId: string): string[] => {
      return deliverablesReadyAngles[angleId] ?? [];
    },
    [deliverablesReadyAngles]
  );

  const summariesOnAngle = useCallback(
    (angleId: string): string[] => {
      return summaryReadyAngles[angleId] ?? [];
    },
    [summaryReadyAngles]
  );

  const angles = useMemo<ProjectSummaryAnglePill[]>(() => {
    const groupsFilters: FilterOption[] = filterOptions?.groups ?? [];

    if (!groupsFilters.length) return [];

    const validAngles = _.compact(
      groupsFilters.map((angle: FilterOption) => {
        const parentDeliverables = deliverablesOnAngle(angle.value).length;
        const parentSummaries = summariesOnAngle(angle.value).length;
        const childrenDeliverables =
          angle.children?.map((sub: FilterOption) => deliverablesOnAngle(sub.value).length) ?? [];
        const childrenSummaries = angle.children?.map((sub: FilterOption) => summariesOnAngle(sub.value).length) ?? [];

        if (parentDeliverables >= PROJECT_SUMMARY_DELIVERABLES_THRESHOLD) {
          const anglePill: ProjectSummaryAnglePill = {
            label: angle.label,
            id: angle.value,
            transcriptCount: parentDeliverables,
            summariesCount: parentSummaries,
            children:
              childrenDeliverables.length === 1 && childrenDeliverables[0] === parentDeliverables
                ? []
                : angle.children
                    ?.map((child: FilterOption, ix: number) => ({
                      label: child.label,
                      id: child.value,
                      children: [],
                      transcriptCount: childrenDeliverables[ix],
                      summariesCount: childrenSummaries[ix],
                    }))
                    .filter((child) => child.transcriptCount >= PROJECT_SUMMARY_DELIVERABLES_THRESHOLD) ?? [],
          };
          return anglePill;
        }

        return null;
      })
    );

    return [
      {
        label: "All transcripts",
        id: "project-wide",
        children: [],
        transcriptCount: deliverablesOnAngle("project-wide").length,
        summariesCount: summariesOnAngle("project-wide").length,
      },
      ...(validAngles.length === 1 && validAngles[0].children.length === 0 ? [] : validAngles),
    ];
  }, [filterOptions, deliverablesOnAngle, summariesOnAngle]);

  const buildLogHitPayload = useCallback(
    (props: ProjectSummaryLogHitProps) => {
      const { action, origin, extraDetails, extraReferences } = props;
      return {
        origin: origin ?? HitOrigin.deliverablesView,
        action,
        projectToken,
        references: {
          ...(selectedSummary && {
            angleId: selectedSummary.angleId,
            summaryId: selectedSummary.id,
          }),
          ...(extraReferences && extraReferences),
        },
        details: {
          ...(extraDetails && extraDetails),
        },
      };
    },
    [projectToken, selectedSummary]
  );

  const projectSummaryLogHit = useCallback(
    (props: ProjectSummaryLogHitProps | ProjectSummaryLogHitProps[]) => {
      if (Array.isArray(props)) {
        bulkLogHit(props.map(buildLogHitPayload));
      } else {
        logHit(buildLogHitPayload(props));
      }
    },
    [buildLogHitPayload, bulkLogHit, logHit]
  );

  const getProjectSummariesStatus = useCallback(() => {
    return service.getProjectSummariesStatus(projectToken).then((response: ProjectSummaryStatusResponse[]) => {
      setCurrentSummariesStatus(response);
      return response;
    });
  }, [projectToken, service]);

  const loadSummariesStatus = useCallback(() => {
    setIsSummaryStatusLoading(true);
    return getProjectSummariesStatus().finally(() => {
      setIsSummaryStatusLoading(false);
    });
  }, [getProjectSummariesStatus]);

  const generateProjectSummaries = useCallback(() => {
    setIsRegenerationStarting(true);
    return service
      .generateProjectSummaries(projectToken)
      .then((response: ProjectSummaryStatusResponse[]) => {
        setCurrentSummariesStatus((p) => [...p, ...response]);
      })
      .catch((err) => {
        projectSummaryLogHit({
          action: HitAction.projectSummarySubmisionError,
          extraDetails: { submissionType: "all-summaries", themeTitle: null },
        });
        return Promise.reject({});
      })
      .finally(() => setIsRegenerationStarting(false));
  }, [service, projectToken, projectSummaryLogHit]);

  const getProjectSummary = useCallback(
    (projectSummaryId: string) => {
      return service.getProjectSummary(projectToken, projectSummaryId).then((response: ProjectSummaryContent) => {
        setSelectedSummary(response);
      });
    },
    [projectToken, service]
  );

  const onSelectSummary = useCallback(
    (projectSummaryId: string) => {
      setIsSelectedSummaryLoading(true);
      getProjectSummary(projectSummaryId).finally(() => {
        setIsSelectedSummaryLoading(false);
      });
    },
    [getProjectSummary]
  );

  const generateSingleProjectSummary = useCallback(
    (angleId?: string): Promise<ProjectSummaryStatusResponse> => {
      setIsRegenerationStarting(true);
      const generateToCall = angleId ? service.generateProjectSummariesByAngle : service.generateProjectWideSummary;
      return generateToCall(projectToken, angleId)
        .then((response: ProjectSummaryStatusResponse) => {
          setCurrentSummariesStatus((p) => [...p, response]);
          return response;
        })
        .catch((err) => {
          projectSummaryLogHit({
            action: HitAction.projectSummarySubmisionError,
            extraDetails: { submissionType: "single-summary", themeTitle: null },
            extraReferences: { angleId },
          });
          return Promise.reject({});
        })
        .finally(() => {
          setIsRegenerationStarting(false);
        });
    },
    [projectToken, service, projectSummaryLogHit]
  );

  useEffect(() => {
    if (hasEnableProjectSummarizationBadge) {
      loadSummariesStatus();
    }
  }, [hasEnableProjectSummarizationBadge, loadSummariesStatus]);

  const isAnySummaryLoading = useMemo(() => {
    return currentSummariesStatus.some(
      (status) => status.status === ProjectSummaryStatus.Processing || status.status === ProjectSummaryStatus.Pending
    );
  }, [currentSummariesStatus]);

  useEffect(
    function updateSummariesStatus() {
      if (!hasEnableProjectSummarizationBadge) return;
      if (!isAnySummaryLoading) return;
      const interval = setInterval(
        () =>
          getProjectSummariesStatus().then((summariesStatus) => {
            if (!selectedSummary) return;
            const selectedSummaryExists = !!summariesStatus.find((status) => status.id === selectedSummary.id);
            if (!selectedSummaryExists) {
              const summaryToSelect = summariesStatus.find((status) => status.angleId === selectedSummary.angleId);
              summaryToSelect && getProjectSummary(summaryToSelect.id);
            }
          }),
        REFRESH_INTERVAL
      );
      return () => {
        clearInterval(interval);
      };
    },
    [
      getProjectSummariesStatus,
      getProjectSummary,
      hasEnableProjectSummarizationBadge,
      isAnySummaryLoading,
      selectedSummary,
    ]
  );

  const selectedSummaryHasLoading = useMemo(() => {
    if (selectedSummary) {
      const isWholeSummaryLoading =
        selectedSummary.status === ProjectSummaryStatus.Processing ||
        selectedSummary.status === ProjectSummaryStatus.Pending;
      const isOverviewLoading =
        selectedSummary.overview.status === ProjectSummaryStatus.Processing ||
        selectedSummary.overview.status === ProjectSummaryStatus.Pending;
      const isThemeOverviewLoading = selectedSummary.themes.some(
        (theme) =>
          theme.overview.status === ProjectSummaryStatus.Processing ||
          theme.overview.status === ProjectSummaryStatus.Pending
      );
      return isWholeSummaryLoading || isOverviewLoading || isThemeOverviewLoading;
    }
    return false;
  }, [selectedSummary]);

  useEffect(
    function updateSelectedSummary() {
      if (!hasEnableProjectSummarizationBadge) return;
      if (!selectedSummaryHasLoading) return;
      const interval = setInterval(() => getProjectSummary(selectedSummary!!.id), REFRESH_INTERVAL);
      return () => {
        clearInterval(interval);
      };
    },
    [getProjectSummary, hasEnableProjectSummarizationBadge, selectedSummaryHasLoading, selectedSummary]
  );

  const onChangeSelectedAngle = useCallback(
    (angle: ProjectSummaryAnglePill) => {
      if (angle.id === "project-wide") {
        const status = uniqSummariesStatusByAngle.find((status) => !status.angleId);
        if (status) {
          onSelectSummary(status.id);
        } else {
          setIsSelectedSummaryLoading(true);
          generateSingleProjectSummary()
            .then((response) => onSelectSummary(response.id))
            .catch(() => setIsSelectedSummaryLoading(false));
        }
      } else {
        const status = uniqSummariesStatusByAngle.find((status) => status.angleId === angle.id);

        if (status) {
          onSelectSummary(status.id);
        } else {
          setIsSelectedSummaryLoading(true);
          generateSingleProjectSummary(angle.id)
            .then((response) => onSelectSummary(response.id))
            .catch(() => setIsSelectedSummaryLoading(false));
        }
      }
    },
    [uniqSummariesStatusByAngle, generateSingleProjectSummary, onSelectSummary]
  );

  const onRegenerateOverview = useCallback(
    (themeTitle?: string) => {
      service
        .regenerateOverview(projectToken, selectedSummary!!.id, themeTitle)
        .then((response: ProjectSummaryContent) => {
          setSelectedSummary((v) => ({ ...v, ...response }));
        })
        .catch((err) => {
          projectSummaryLogHit({
            action: HitAction.projectSummarySubmisionError,
            summary: selectedSummary,
            extraDetails: { submissionType: "refresh-overview", themeTitle: themeTitle ?? "Overview" },
          });
        });
    },
    [selectedSummary, projectToken, service, projectSummaryLogHit]
  );

  const onDeleteCurrentSummary = useCallback(() => {
    const summaryIds = selectedSummary?.id ? [selectedSummary?.id] : currentSummariesStatus.map((s) => s.id);
    summaryIds.forEach((summaryId) =>
      service.deleteProjectSummary(projectToken, summaryId).then(() => {
        setCurrentSummariesStatus((v) => v.filter((s) => s.id !== summaryId));
        // @ts-ignore
        setSelectedSummary(null);
      })
    );
  }, [currentSummariesStatus, selectedSummary, projectToken, service]);

  const onSubmitCustomTheme = useCallback(
    (customThemePayload: CustomThemePayload) => {
      return service
        .updateCustomThemes(projectToken, customThemePayload, selectedSummary?.id, selectedSummary?.angleId)
        .then((content: ProjectSummaryContent) => {
          setSelectedSummary(content);
          return content;
        })
        .catch((err) => {
          customThemePayload.newThemes.forEach((theme) => {
            projectSummaryLogHit({
              action: HitAction.projectSummarySubmisionError,
              summary: selectedSummary,
              extraDetails: { submissionType: "custom-theme", themeTitle: theme.title },
            });
          });
          return Promise.reject({});
        });
    },
    [service, projectToken, selectedSummary, projectSummaryLogHit]
  );

  const downloadSummaryUrl = useMemo(() => {
    if (!selectedSummary) return "";
    return service.downloadSummaryUrl(projectToken, selectedSummary.id);
  }, [service, selectedSummary, projectToken]);

  const context: ProjectSummariesProviderState = useMemo(
    () => ({
      isSummaryStatusLoading,
      selectedSummary,
      onSelectSummary,
      generateProjectSummaries,
      isSelectedSummaryLoading,
      getInteractionByAdvisorId,
      getInteractionById,
      angles,
      generateSingleProjectSummary,
      onChangeSelectedAngle,
      onRegenerateOverview,
      onDeleteCurrentSummary,
      onSubmitCustomTheme,
      projectSummaryLogHit,
      selectedInsight,
      onSelectInsight,
      uniqSummariesStatusByAngle,
      isAnySummaryGenerating,
      downloadSummaryUrl,
      readOnly,
    }),
    [
      isSummaryStatusLoading,
      selectedSummary,
      onSelectSummary,
      generateProjectSummaries,
      isSelectedSummaryLoading,
      getInteractionByAdvisorId,
      getInteractionById,
      angles,
      generateSingleProjectSummary,
      onChangeSelectedAngle,
      onRegenerateOverview,
      onDeleteCurrentSummary,
      onSubmitCustomTheme,
      projectSummaryLogHit,
      selectedInsight,
      onSelectInsight,
      uniqSummariesStatusByAngle,
      isAnySummaryGenerating,
      downloadSummaryUrl,
      readOnly,
    ]
  );

  return <ProjectSummariesContext.Provider value={context} children={children} />;
};

export const useProjectSummariesContext = () => {
  const context = useContext(ProjectSummariesContext);

  if (!context) throw new Error("ProjectSummariesContext should only be used within the ProjectSummariesProvider");

  return context;
};

interface ProjectSummaryLogHitProps {
  action: HitAction;
  origin?: HitOrigin;
  summary?: ProjectSummaryContent;
  extraDetails?: any;
  extraReferences?: any;
}
