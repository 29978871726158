import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";

const AngleCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;

  const groupOf = (interaction: Interaction) => {
    const { group } = interaction;
    return group.parent ? group.parent.name : group.name;
  };

  return <div data-testid={`survey-angle-column-${interaction.id}`}>{groupOf(interaction)}</div>;
};

export const AngleColumn = {
  id: "AngleColumn",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <AngleCell props={props} />,
  header: "Angle",
  minSize: 50,
};
