import { FC, useRef, useState, useMemo } from "react";
import { omit } from "lodash";
import { IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";
import { MoreVert } from "@alphasights/alphadesign-icons";
import { useModal } from "@alphasights/ads-community-hooks";

import { useMutation } from "query-utils";
import { ThirdPartyDocumentType, ThirdPartyInteraction, ThirdPartyDocumentAttributes } from "types";
import { getTitle } from "views/DeliverablesView/ThirdPartyDocumentCard/utils";
import EditDocumentModal from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal";
import { createFormPropsFromDocument } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/utils";
import { useDeliverableContext } from "providers/DeliverableProvider";
import ConfirmRemoveDocModal from "views/DeliverablesView/ThirdPartyDocUploadModal/ConfirmRemoveDocModal";
import DocumentOptionsPopover from "./DocumentOptionsPopover";
import DetailsRow from "./DetailsRow";
import DocumentActionsPanel from "./DocumentActionsPanel";
import { ViewType } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";
import { useUpdateDocumentAttributes } from "./useUpdateDocumentAttributes";

import * as S from "./PageHeader.styled";

type PageHeaderProps = {
  interaction: ThirdPartyInteraction;
  searchTargetEl: HTMLDivElement | null;
  selectedView: ViewType;
  onChangeView: (view: ViewType) => void;
  refetchDocuments: () => void;
  searchQuery: string[];
};

const PageHeader: FC<PageHeaderProps> = ({
  interaction,
  searchTargetEl,
  selectedView,
  onChangeView,
  refetchDocuments,
  searchQuery,
}) => {
  const { color } = useThemeTokens();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const popoverAnchor = useRef(null);
  const { project, deleteDocument } = useDeliverableContext();
  const { isVisible: isEditModalVisible, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useModal();
  const {
    isVisible: isRemoveConfirmationModalVisible,
    onOpen: onOpenRemoveConfirmationModal,
    onClose: onCloseRemoveConfirmationModal,
  } = useModal();

  const { id: documentId, documentType } = interaction;
  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const title = useMemo(() => getTitle(interaction), [interaction]);

  const handleClosePopover = () => setIsOptionsOpen(false);

  const { onSaveEdits } = useUpdateDocumentAttributes({
    interaction,
    projectToken: project.token,
    onSuccess: refetchDocuments,
  });

  const deleteDocumentMutation = useMutation((documentId: string) => deleteDocument(documentId), {
    onSuccess: () => {
      refetchDocuments();
    },
  });

  const handleSaveEdits = (attributes: ThirdPartyDocumentAttributes) => {
    try {
      onSaveEdits({ documentId, attributes });
    } catch (error) {
      console.error("Unable to save edits", error);
    }
    onCloseEditModal();
  };

  const editDocumentModalFileProps = createFormPropsFromDocument(omit(interaction, ["scheduledCallTime", "origin"]));

  const handleClickEditOption = () => {
    setIsOptionsOpen(false);
    onOpenEditModal();
  };

  const handleClickDeleteOption = () => {
    setIsOptionsOpen(false);
    onOpenRemoveConfirmationModal();
  };

  const handleConfirmRemove = () => {
    onCloseRemoveConfirmationModal();
    deleteDocumentMutation.mutate(documentId);
  };

  return (
    <S.HeaderWrapper>
      <S.ContentsWrapper>
        <S.InfoWrapper>
          <LimitedLinesTypography tooltip={title} component="span">
            <Typography color={color.text.strong._} variant="body-large-em" component="span">
              {title}
            </Typography>
          </LimitedLinesTypography>
          <DetailsRow interaction={interaction} onOpenEditModal={onOpenEditModal} />
        </S.InfoWrapper>
        <IconButton ref={popoverAnchor} onClick={() => setIsOptionsOpen((a) => !a)} variant="ghost" color="secondary">
          <MoreVert />
        </IconButton>
        {isOptionsOpen && (
          <DocumentOptionsPopover
            anchorRef={popoverAnchor}
            onClose={handleClosePopover}
            onClickEdit={handleClickEditOption}
            onClickDelete={handleClickDeleteOption}
          />
        )}
      </S.ContentsWrapper>
      {isTranscript && (
        <DocumentActionsPanel
          searchQuery={searchQuery}
          searchTargetEl={searchTargetEl}
          selectedView={selectedView}
          onChangeView={onChangeView}
        />
      )}
      {isEditModalVisible && (
        <EditDocumentModal
          onClickCancel={onCloseEditModal}
          onClickSave={handleSaveEdits}
          {...editDocumentModalFileProps}
        />
      )}
      {isRemoveConfirmationModalVisible && (
        <ConfirmRemoveDocModal onClickConfirm={handleConfirmRemove} onClickCancel={onCloseRemoveConfirmationModal} />
      )}
    </S.HeaderWrapper>
  );
};

export default PageHeader;
