import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import showdown from "showdown";

import { x } from "@xstyled/styled-components";
import { Tooltip, TooltipContent, Typography, useThemeTokens, IconButton } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";

import { makeParagraphs } from "pages/AlphaNowPage/utils";
import SpeakerIcon from "../../../../SpeakerIcon";
import { CONTENT_TYPE } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const ConversationFragment = ({ addRefToMention, speakerInfo, text, contentType, isFragmentHighlighted }) => {
  const {
    color,
    spacing: { inner },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const { isModerator, transcriptParticipantRole, company, jobTitle, speakerDummyName } = speakerInfo;

  const converter = new showdown.Converter();
  const paragraphs = makeParagraphs(text);
  const parsedText = converter.makeHtml(paragraphs);
  const isPrivate = contentType === CONTENT_TYPE.privateEnterprise;
  const speakerDisplayName = isModerator ? transcriptParticipantRole : `${company} - ${jobTitle}`;
  const hideSpeakerNamePlaceholder = isModerator || isPrivate;

  const placeholder = hideSpeakerNamePlaceholder ? null : (
    <x.span display="inline-flex" flexDirection="row" color={color.text.secondary} alignItems="center">
      <Typography component="span" variant="body" px={inner.base}>
        |
      </Typography>
      <Typography component="span" variant="body" data-testid="placeholder-name" pr={inner.base}>
        {speakerDummyName}
      </Typography>
      {!isMobile && (
        <Tooltip maxWidth="unset">
          <TooltipContent>
            <Typography>Placeholder name to retain the expert's anonymity.</Typography>
          </TooltipContent>
          <IconButton size="small" variant="basic" color={color.text.secondary} testId="information-icon">
            <Info />
          </IconButton>
        </Tooltip>
      )}
    </x.span>
  );

  return (
    <x.div display="flex" flexDirection="column" spaceX={{ xs: 0, sm: inner.base06 }}>
      <x.div
        display="flex"
        flexDirection="row"
        spaceX={{ xs: inner.base04, sm: inner.base06 }}
        alignItems="center"
        mb={inner.base}
      >
        <SpeakerIcon speaker={speakerInfo} contentType={contentType} isMentionsView={false} />
        <div>
          <Typography component="span" flexWrap="wrap" variant="body-em" color={color.text.strong._}>
            {speakerDisplayName}
          </Typography>
          {placeholder}
        </div>
      </x.div>
      <Typography
        className="mentions-container"
        component="span"
        variant="body"
        transition="all 1s"
        backgroundColor={isFragmentHighlighted ? color.background.surface.page.hover : null}
        borderRadius={inner.base02}
        pl={{ xs: 0, sm: inner.base06 }}
        pt={{ xs: inner.base, sm: 0 }}
        color={color.text.strong._}
        lineHeight={inner.base06}
      >
        {ReactHtmlParser(parsedText, {
          transform: addRefToMention,
        })}
      </Typography>
    </x.div>
  );
};

ConversationFragment.propTypes = {
  addRefToMention: PropTypes.func.isRequired,
  speakerInfo: PropTypes.shape({
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    transcriptParticipantRole: PropTypes.string,
    isModerator: PropTypes.bool,
    speakerDummyName: PropTypes.string,
  }).isRequired,
  text: PropTypes.string.isRequired,
};

export default ConversationFragment;
