import { useCallback, useMemo } from "react";
import { Alert } from "@alphasights/alphadesign-components";
import { ChargeUnit } from "models/Interaction";
import { AiTranscript, AudioRecording, HumanTranscript, TranslatedTranscript } from "@alphasights/alphadesign-icons";
import { isBefore, subHours } from "date-fns";
import { parseISO } from "providers/TimezoneProvider";
import { getTranscriptReadyWithinText } from "./transcriptReadyWithinMap";
import { getLanguageFromCode, languages } from "helpers/displayHelpers";
import pluralize from "pluralize";
import { useInteractionUpgradesContext } from "./InteractionUpgradesProvider";
import { isRecordingAvaiable, isRecordingExpired } from "./helpers";
import { DeliverableUpgradeType } from "./types";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ProjectFeature } from "@alphasights/portal-api-client";

const translatedTranscriptLanguages = [
  "en",
  "zh",
  "fr",
  "de",
  "es",
  "ko",
  "jp",
  "it",
  "pt",
  "nl",
  "sv",
  "ru",
  "zh-HK",
  "id",
  "vi",
];

const TRANSCRIPT_WINDOWS = new Map<string, number>([
  ["transcript", 8],
  ["transcript_ai", 1],
  ["translated", 0],
]);

export const useComputeDeliverables = ({
  selected,
  transcriptTargetLanguage,
}: {
  selected: string[];
  transcriptTargetLanguage?: string;
}): DeliverableUpgradeType[] => {
  const {
    accessPlan,
    recording,
    postInteraction,
    interaction,
    aiTranscriptEnabled,
    recordedEnabled,
    humanTranscriptEnabled,
    deliverablesViewEnabled,
    showMobileView,
  } = useInteractionUpgradesContext();
  const { isFeatureDisabled } = useCurrentProjectContext();

  const { peerContentContributor, privateTranscriptContributor } = interaction;

  const recordingExpired = useMemo(() => isRecordingExpired(recording), [recording]);
  const recordingAvailable = useMemo(() => isRecordingAvaiable(recording), [recording]);

  const translationAvailable = useMemo(
    () => interaction.language && translatedTranscriptLanguages.includes(interaction.language),
    [interaction.language]
  );

  const transcriptLanguage = useMemo(
    () =>
      selected.includes("translated") && interaction.language && transcriptTargetLanguage
        ? `${getLanguageFromCode(interaction.language)} to ${getLanguageFromCode(transcriptTargetLanguage)}`
        : translationAvailable
        ? "Multiple language options"
        : "No options available in the Platform. Reach out to your project lead.",
    [selected, interaction.language, translationAvailable, transcriptTargetLanguage]
  );

  const getDescriptionText = () => {
    if (peerContentContributor && privateTranscriptContributor) {
      return "neither be eligible for the peer contribution discount, nor added to your organization's private library";
    } else if (peerContentContributor) {
      return "not be eligible for the peer contribution discount";
    } else {
      return "not be added to your organization's private library";
    }
  };

  const transcriptRequests = useMemo(() => recording?.transcriptRequests ?? [], [recording]);

  const transcriptForType = useCallback(
    (transcriptRequestType: TranscriptType) =>
      transcriptRequests.find((ta) => ta.transcriptType === transcriptRequestType),
    [transcriptRequests]
  );

  const transcriptExpired = useCallback(
    (transcriptRequestType: TranscriptType) => !!transcriptForType(transcriptRequestType)?.purgedAt,
    [transcriptForType]
  );

  const transcriptAvailable = useCallback(
    (transcriptRequestType: TranscriptType) =>
      !transcriptExpired(transcriptRequestType) && transcriptForType(transcriptRequestType)?.completed,
    [transcriptExpired, transcriptForType]
  );

  const transcriptableUntilFor = useCallback(
    (type: string) => {
      if (!recording || TRANSCRIPT_WINDOWS.get(type) === undefined) return true;

      if (recording.purgedAt) return false;

      return isBefore(new Date(), subHours(parseISO(recording.expiresAt!), TRANSCRIPT_WINDOWS.get(type)!));
    },
    [recording]
  );

  const deliverableTypes: DeliverableUpgradeType[] = [];
  if (
    postInteraction &&
    recordedEnabled &&
    selected.includes("recorded") &&
    selected.includes("transcript_ai") &&
    !(
      (recordingExpired && !transcriptExpired("ai")) ||
      (!recordingExpired && transcriptExpired("ai")) ||
      (!recordingAvailable && transcriptAvailable("ai"))
    )
  ) {
    deliverableTypes.push({
      type: "transcript_and_recorded",
      name: "AI Transcript & Audio Recording",
      price: "Free",
      language: "English",
      icon: <AiTranscript />,
      disabledInPost: true,
      availableMessage: transcriptAvailable("ai")
        ? "Transcript and recording available"
        : "Recording available, transcript ready within 20 minutes",
      expiredMessage: "Transcript and recording expired",
      transcriptRequestType: "ai",
      downloadable: false,
      available: recordingAvailable,
      shareLink: true,
      deliveryTime: "Ready within 20 minutes",
      visible: true,
      recording: recording,
      expired: transcriptExpired("ai"),
    });
  } else {
    deliverableTypes.push({
      type: "recorded",
      name: "Audio Recording",
      price: "Free",
      language: interaction.language,
      icon: <AudioRecording />,
      disabled: true,
      visible: recordedEnabled && selected?.includes("recorded"),
      disabledInPost: true,
      availableMessage: "Recording available",
      expiredMessage: "Recording expired",
      downloadable: false,
      available: recordingAvailable,
      shareLink: true,
      deliveryTime: "Ready within 5 minutes | Expires after 21 days",
      recording: recording,
      expired: recordingExpired,
    });
    deliverableTypes.push({
      type: "transcript_ai",
      name: "AI Transcript",
      price: "Free",
      language: interaction.language,
      icon: <AiTranscript />,
      availableMessage: "Transcript available",
      expiredMessage: "Transcript expired",
      transcriptRequestType: "ai",
      downloadable: false,
      shareLink: true,
      visible: aiTranscriptEnabled && interaction.language === "en" && !transcriptAvailable("regular"),
      recording: recording,
      deliveryTime: "Ready within 20 minutes",
    });
  }

  deliverableTypes.push({
    type: "transcript",
    name: "Human Transcript",
    price: interaction.peerContentContributor
      ? ""
      : `+${accessPlan?.transcriptRate} ${pluralize("credit", accessPlan?.transcriptRate)}${
          accessPlan?.transcriptChargeUnit === ChargeUnit.hour ? "/h" : ""
        }`,
    language: interaction.language,
    description: "Professionally transcribed",
    icon: <HumanTranscript />,
    availableMessage: "Transcript available",
    expiredMessage: "Transcript expired",
    transcriptRequestType: "regular",
    downloadable: true,
    shareLink: !deliverablesViewEnabled,
    deliveryTime: interaction.language ? getTranscriptReadyWithinText("regular", interaction.language) : "",
    visible: humanTranscriptEnabled,
    requireRemovalConfirmation: peerContentContributor || privateTranscriptContributor,
    removalConfirmationText: `A transcript of this call will not be created. As such, this call will ${getDescriptionText()}.`,
    disabled: !translationAvailable && selected.includes("translated"),
  });
  deliverableTypes.push({
    type: "translated",
    name: "Translated Transcript",
    language: transcriptLanguage,
    sourceLanguage: getLanguageFromCode(interaction.language),
    icon: <TranslatedTranscript />,
    availableMessage: "Transcript available via email",
    expiredMessage: "Transcript expired",
    transcriptRequestType: "regular",
    price: "+1 credit",
    downloadable: true,
    visible: humanTranscriptEnabled && !showMobileView,
    requireLanguageSelection: true,
    disabled: !translationAvailable,
    notAvailableOnPlatformMessage:
      "Translated Transcripts are unavailable on the Platform. Please check your email inbox.",
    languageNotAvailableMessage: "No options available in the Platform. Reach out to your project lead.",
    languages: languages.filter(
      (l) =>
        translatedTranscriptLanguages.includes(l.locale) &&
        l.locale !== interaction.language &&
        (interaction.language === "en" || l.locale === "en")
    ),
    innerBanner: <Alert variant="info">A human transcript is required alongside a translated transcript.</Alert>,
    indeterminateStateHint: getLanguageFromCode(interaction.language) + " to (select translated language)",
    dependsOn: "transcript",
  });

  let deliverables = deliverableTypes.map((d) => ({
    ...d,
    selected: selected?.includes(d.type),
    available: d.available ?? (d.transcriptRequestType && transcriptAvailable(d.transcriptRequestType)),
    expired: d.expired ?? (d.transcriptRequestType && transcriptExpired(d.transcriptRequestType)),
    visible: d.visible && (transcriptableUntilFor(d.type) || selected?.includes(d.type)),
    transcript: d.transcriptRequestType ? transcriptForType(d.transcriptRequestType) : undefined,
    dependents: deliverableTypes.filter((dep) => dep.dependsOn === d.type).map((dep) => dep.type),
    disabled: d.disabled || isFeatureDisabled(ProjectFeature.EditInteractionUpgrades),
  }));

  deliverables = deliverables
    .filter((d) => d.visible)
    .filter((d) => {
      if (!d.dependsOn) return true;
      const dependency = deliverables.find((dep) => dep.type === d.dependsOn);
      return dependency && dependency.visible;
    });

  return deliverables;
};
