import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";
import { useDimensions } from "hooks/useDimensions";
import useQuery from "hooks/useQuery";
import useQueryParams from "hooks/useQueryParams";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import ReactTooltip from "react-tooltip";
import { DispatchContext } from "./DispatchContext";
import { AdvisorsTableView, AdvisorsTableViewSkeleton } from "views/AdvisorsTableView";
import { ProjectCalendarView } from "../../views/ProjectCalendarView";
import { SurveysResultsPage } from "views/SurveysResultsView";
import { CostOverviewModalContainer, DownloadsModal, useEnv } from "@alphasights/client-portal-shared";
import { ErrorPage } from "../ErrorPage";
import { Outlet, useOutletContext, useLocation, useParams, useNavigate } from "router-utils";
import { SchedulingModal } from "../SchedulingModal";
import { Toolbar } from "./Toolbar";
import { AdvisorsPage } from "../../views/AdvisorsPage";
import { EmptySearchResults } from "./EmptySearchResults";
import { buildDownloadCompaniesCidUrl } from "../../helpers/urlHelpers";
import { useTimezone } from "../../providers/TimezoneProvider";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { ProjectSettingsModal } from "../ProjectSettingsModal";
import { getFiltersFromURL } from "../InteractionsFilter/serializer";
import {
  cancelRequestAdvisor,
  cancelRequestChangeInteraction,
  findInteraction,
  INTERACTIONS_FIELDS,
  markAsViewed,
  setProjectSettingsMode,
  submitInvitation,
  useInteractionsPageReducer,
  useReducerCallbacks,
} from "./reducer";
import { DeliverablesView } from "views/DeliverablesView";
import { InteractionsFilter } from "./InteractionsFilter";
import { x } from "@xstyled/styled-components";
import { CommentProvider } from "components/CommentThread/CommentContext";
import { AdvisorSidebar, NewMessageSidebar, useFlyoutWidth } from "../../pages/InteractionPage";
import { currentProjectView } from "../../helpers/currentView";
import { PageFooter } from "@alphasights/client-portal-shared";
import { AlertContextProvider } from "./AlertContextProvider";
import { HideExpertProvider } from "providers/HideExpertProvider";
import { LabelsProvider } from "providers/LabelsProvider";
import { MessengerPage } from "pages/MessengerPage/MessengerPage";
import { SurveysPage } from "pages/SurveysPage";
import { ProjectInteractionsProvider, useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { fromWorkstream, hasOnlySurveyWorkstreams } from "./helpers/Workstreams";
import { MessageThreadProvider } from "pages/MessengerPage/context/MessageThreadContext";
import { CurrentWorkstreamProvider } from "providers/CurrentWorkstreamProvider";
import { ProjectAlphaGPTPage } from "pages/ProjectAlphaGPTPage/ProjectAlphaGPTPage";
import { CustomerExpertPage } from "pages/CustomerExpertPage";
import { useCurrentUser, useIsAuthenticated, useUserAuthIsReady } from "@alphasights/portal-auth-react";
import { HitAction, HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";
import {
  CUSTOMER_KNOWLEDGE,
  ENABLE_PAST_PROJECT_DELIVERABLES,
  useProjectBadgeContext,
  useUserBadgeContext,
} from "providers/BadgeProvider";
import { isEndCustomerInteraction } from "pages/CustomerExpertPage/helpers";
import { MobileMessengerPage } from "views/MobileMessengerPage";
import { MobileInteractionsPage } from "views/MobileInteractionsPage";
import { MobileDeliverablesView } from "views/MobileDeliverablesView";
import { MobileNavBar } from "views/MobileNavBar";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ComparisonView } from "views/ComparisonView/ComparisonView";
import { StarExpertProvider } from "providers/StarExpertProvider";
import { BackdropProvider, useBackdropContext } from "providers/BackdropProvider";
import Backdrop from "components/Backdrop";
import { ProjectMoreOptionsProvider } from "providers/ProjectMoreOptionsProvider";
import { MyAlphasightsPage } from "components/App/realtime/MyAlphasightsPage";
import { CurrentViewHeader } from "./CurrentViewHeader";
import { ProjectSynthesisView } from "views/ProjectSynthesisView";
import { Loading, useThemeTokens } from "@alphasights/alphadesign-components";
import { areFiltersEquals } from "utils/compareFilter";
import { useShowCid as useShowCidOnSurveyView } from "pages/SurveysPage/useShowCid";
import { AngleQuestionsView } from "views/AngleQuestionsView";
import { Badge } from "models/Badge";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import useThirdPartyDocsPermissions from "hooks/useThirdPartyDocsPermissions";
import { PastProjectsBanner } from "../PastProjectsBanner";

export const ProjectContext = React.createContext({});

const LoadingPage = () => {
  const { isMobile } = useCheckScreen();

  if (!isMobile) {
    return <AdvisorsTableViewSkeleton />;
  }

  return (
    <x.div display="flex" alignItems="center" justifyContent="center" w="100%" h="100%">
      <Loading size="sm" />
    </x.div>
  );
};

const InteractionsPageComponent = ({ project, currentView: currentViewInput = undefined }) => {
  const userCidEnabled = useUserCidEnabled();
  const params = useParams();
  const env = useEnv();
  const trackUserAction = useTrackUserAction();
  const currentView = params.currentView ?? currentViewInput;
  const currentUser = useCurrentUser();
  const isLoggedIn = useIsAuthenticated();
  const location = useLocation();
  const appliedFilters = getFiltersFromURL(location);
  const { isMobile } = useCheckScreen();
  const isUserReady = useUserAuthIsReady();
  const hasThirdPartyDocumentsPermissions = useThirdPartyDocsPermissions();

  const userHasBeenReadyRef = useRef(isUserReady);
  const isFirstLoad = useRef(true);

  const { newMessageType, newMessageOrigin, selectedExpertsIds } = location.state ?? {};

  const initialState = useMemo(
    () => ({
      appliedFilters,
      project: { ...project, token: params.token },
      token: params.token,
      env,
      trackUserAction,
      currentView,
      currentUser: currentUser,
      showMobileView: isMobile,
      workstreamId: params.workstreamId,
      selectedExpertsIds: selectedExpertsIds ?? [],
      newMessageType,
      newMessageOrigin,
      hasThirdPartyDocumentsPermissions,
    }),
    [
      appliedFilters,
      env,
      trackUserAction,
      project,
      currentView,
      params.token,
      currentUser,
      isMobile,
      params.workstreamId,
      newMessageType,
      newMessageOrigin,
      selectedExpertsIds,
      hasThirdPartyDocumentsPermissions,
    ]
  );

  const [state, dispatch, originalDispatch] = useInteractionsPageReducer(initialState);
  const { searchProjectsLoading } = state;

  useEffect(() => {
    if (isUserReady) {
      userHasBeenReadyRef.current = true;
    }
  }, [isUserReady]);

  useEffect(() => {
    if (!searchProjectsLoading && isFirstLoad.current) {
      isFirstLoad.current = false;
    }
  }, [searchProjectsLoading]);

  const isFlyoutOpen = state.isFlyoutOpen;
  const flyoutMode = state.flyoutMode;
  const token = params.token;

  const {
    onToggleHidden,
    onSaveHideExpertReason,
    onToggleStar,
    onRequestTranscript,
    onCancelRequestTranscript,
    onRequestTranscriptPostCall,
    onRequest,
    onRequestRescheduleInteraction,
    onUpdateFollowUp,
    onCancelFollowUp,
    onCloseFollowUpModal,
    onSubmitClientAvailability,
    onSubmitSchedule,
    onCloseSchedulingModal,
    onStartSchedule,
    onSelectCard,
    onCancelRequest,
    onFiltersChange,
    onLeaveAvailability,
    onSaveClientAvailability,
    onOpenComments,
    initialInteractionsLoad,
    onRequestFollowUp,
    onSendNewMessage,
    onSendNewMessageFromTableView,
    onSearch,
    onCloseFlyout,
    onChangeLayout,
    onRefetchThirdPartyDocuments,
  } = useReducerCallbacks({ dispatch, state, originalDispatch });

  const [firstLoad, setFirstLoad] = useState(true);
  const { hasProjectBadge } = useProjectBadgeContext();
  // removes invalid angles from filters
  useEffect(() => {
    if (
      !firstLoad ||
      state.isBackgroundSyncRunning ||
      state.isFilterOptionsLoading ||
      !state.filterOptions?.groups?.length
    )
      return;
    setFirstLoad(false);
    const anglesSelected = (getFiltersFromURL(location) || {}).groups || [];
    const invalidAngles = anglesSelected.filter(
      (a) => !state.filterOptions.groups.find((o) => (o.parentId || o.id) === a || o.children?.find((c) => c.id === a))
    );
    if (invalidAngles.length) {
      const newAppliedFilter = {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          groups: anglesSelected.filter((a) => !invalidAngles.includes(a)),
        },
      };
      onSearch(newAppliedFilter, { updateURL: true, replace: true });
    }
  }, [state, firstLoad, onSearch, location]);

  useEffect(
    function resetInteractionsPageOnProjectTokenOrWorkstreamIdChange() {
      originalDispatch({ type: "reset", data: initialState }); // Reset reducer
      initialInteractionsLoad(); // Load interactions
    },
    [project.token, params.workstreamId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const flyoutShowsCalendar = state.showCalendarView && !isMobile && isLoggedIn;

  useEffect(
    function searchInteractionsOnFiltersChangeOnUrl() {
      const appliedFilters = state.appliedFilters;
      const newFilters = getFiltersFromURL(location) || {};

      if (!areFiltersEquals(appliedFilters, newFilters)) {
        onSearch({ searchQuery: state.searchQuery, appliedFilters: newFilters });
      }
    },
    [location] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (state.postSyncSearch) {
      onSearch({
        searchQuery: state.searchQuery,
        appliedFilters: state.appliedFilters,
      });
    }
  }, [state.postSyncSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTextSearch = useCallback(
    (searchQuery) => {
      if (isFlyoutOpen) {
        onSelectCard(null);
      }
      return onSearch({
        searchQuery,
        appliedFilters: state.appliedFilters,
      });
    },
    [onSearch, state.appliedFilters, isFlyoutOpen, onSelectCard]
  );

  const onCancelPendingRequest = useCallback(
    (request) =>
      dispatch(cancelRequestChangeInteraction(request)).then(() => {
        if (request.type === "SCHEDULE_REQUEST") {
          return dispatch(cancelRequestAdvisor({ id: request.interactionId }));
        }
      }),
    [dispatch]
  );

  const onSetProjectSettingsMode = useCallback((mode) => dispatch(setProjectSettingsMode(mode)), [dispatch]);

  const onSubmitInvitation = useCallback((props) => dispatch(submitInvitation(props)), [dispatch]);

  const onDownloadCompaniesCid = useCallback(
    (selectedRows) => {
      const ids = selectedRows.map((row) => row.advisorId);
      window.open(buildDownloadCompaniesCidUrl(token, ids));
    },
    [token]
  );

  const onRefetchThirdPartyDocumentsWrapped = useCallback(() => {
    onRefetchThirdPartyDocuments({ searchQuery: state.searchQuery });
  }, [onRefetchThirdPartyDocuments, state.searchQuery]);

  useEffect(ReactTooltip.rebuild.bind(ReactTooltip)); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    transcriptEnabled = false,
    aiTranscriptEnabled = false,
    recordedEnabled = false,
    videoCallAvailable = false,
    blindedUpgradeAvailable = true,
  } = project || {};

  const interactionFields = Object.fromEntries(
    Object.entries(state).filter(([field]) => INTERACTIONS_FIELDS.includes(field))
  );

  const { hasDeliverables, isLoading: isDeliverablesLoading } = useDeliverableContext();

  const isPastProjectDeliverablesEnabled = hasProjectBadge(ENABLE_PAST_PROJECT_DELIVERABLES);
  const hoursSinceProjectClosed = Math.abs(new Date() - new Date(project.closedAt)) / 36e5;
  const isDeliverablesViewEnabled = project.active || isPastProjectDeliverablesEnabled || hoursSinceProjectClosed <= 48;
  const showDeliverablesView = isDeliverablesViewEnabled && (isDeliverablesLoading || hasDeliverables);

  const interactions = useMemo(() => state.experts.flatMap(({ interactions }) => interactions), [state.experts]);
  const isThirdPartyDocumentsLoading = currentView === "deliverables-view" && state.isThirdPartyDocumentsLoading;
  const isViewDataLoading = state.isInteractionsLoading || isThirdPartyDocumentsLoading;

  const viewProps = {
    ...params,
    location,
    project,
    transcriptEnabled,
    aiTranscriptEnabled,
    recordedEnabled,
    videoCallAvailable,
    blindedUpgradeAvailable,
    currentView,
    loading: isViewDataLoading,
    isFilterOptionsLoading: state.isFilterOptionsLoading,
    backgroundSyncRunning: state.isBackgroundSyncRunning,
    experts: state.experts,
    ...interactionFields,
    interactions,
    thirdPartyDocuments: state.thirdPartyDocuments,
    isProjectSettingsOpen: state.isProjectSettingsOpen,
    isUpdatingProjectLevelSettings: state.isUpdatingProjectLevelSettings,
    clientRequests: state.clientRequests || [],
    clientAvailability: state.clientAvailability || [],
    structuredAngles: state.structuredAngles,
    schedulingAdvisor: state.schedulingAdvisor,
    totalResults: state.totalResults,
    filterOptions: state.filterOptions,
    showCalendarView: state.showCalendarView,
    showMessagesView: state.showMessagesView,
    showDeliverablesView,
    relevantStatementType: state.relevantStatementType,
    showAngleTypes: state.showAngleTypes,
    appliedFilters: state.appliedFilters,
    selectedCardId: state.selectedCardId,
    scrollToCardId: state.scrollToCardId,
    isFlyoutOpen: isFlyoutOpen,
    isFilterDefaultOpen: false,
    flyout: state.flyout,
    flyoutMode: flyoutMode,
    newMessageType: state.newMessageType,
    newMessageOrigin: state.newMessageOrigin,
    newMessageContent: state.newMessageContent,
    requestedScheduleDate: state.requestedScheduleDate,
    onFiltersChange,
    onLeaveAvailability,
    onRequest,
    onRequestRescheduleInteraction,
    onRequestFollowUp,
    onCancelRequest,
    onRequestTranscript,
    onCancelRequestTranscript,
    onRequestTranscriptPostCall,
    onUpdateFollowUp,
    onCancelFollowUp,
    onCloseFollowUpModal,
    onTextSearch,
    onCloseSchedulingModal,
    onSetProjectSettingsMode,
    onStartSchedule,
    onSubmitSchedule,
    onSubmitInvitation,
    onSelectCard,
    onSubmitClientAvailability,
    onChangeLayout,
    onCloseFlyout,
    onDownloadCompaniesCid,
    onCancelPendingRequest,
    onSaveClientAvailability,
    onSendNewMessage,
    onSendNewMessageFromTableView,
    onRefetchThirdPartyDocuments: onRefetchThirdPartyDocumentsWrapped,
    userCidEnabled,
    isProjectCidEnabled: project.cidEnabled,
    followUpModalProps: state.followUpModal,
    isLoggedIn,
    flyoutShowsCalendar,
    isMobile,
    preferencesEnabled: !!(isLoggedIn && !isMobile),
    hasClientPortalMessages: project.enablePortalMessages,
    deliverablesViewEnabled: isDeliverablesViewEnabled, //is hits used?
    scheduledInteractions: state.scheduledInteractions,
    researchRequest: state.researchRequest,
    pcc: currentUser?.peerContentContributor,
    pccOptOutWindow: currentUser?.csaSettings.peerContentContributorOptOutWindow,
    interactionChainDate: state.interactionChainDate,
    projectWithInteractions: state.projectWithInteractions,
    onOpenComments,
    pastProjectsEnabled: currentUser?.pastProjectsEnabled,
    preselectedExpertId: state.preselectedExpertId,
    upgrades: state.upgrades,
    loadingUpgrades: state.loadingUpgrades,
    flyoutAction: state.flyoutAction,
    selectedExpertsIds: state.selectedExpertsIds,
  };

  const isReady =
    (userHasBeenReadyRef.current || isUserReady) && !!env && (!isFirstLoad.current || !searchProjectsLoading);

  if (!isReady) {
    return <LoadingPage />;
  }

  return state.interactionsError ? (
    <ErrorPage error={state.interactionsError} />
  ) : (
    <InteractionsPageView
      {...{
        originalDispatch,
        dispatch,
        project,
        onToggleHidden,
        onToggleStar,
        onSaveHideExpertReason,
        viewProps,
        state,
      }}
    />
  );
};

const InteractionsPageView = React.memo(
  ({ dispatch, state, project, onToggleHidden, onToggleStar, onSaveHideExpertReason, viewProps, originalDispatch }) => {
    return (
      <DispatchContext.Provider value={dispatch}>
        <ProjectContext.Provider value={project}>
          <ProjectInteractionsProvider
            state={state}
            dispatch={dispatch}
            project={project}
            workstreamId={viewProps.workstreamId}
            originalDispatch={originalDispatch}
            viewProps={viewProps}
          >
            <CommentProvider project={project}>
              <MessageThreadProvider project={project}>
                <HideExpertProvider onToggleHidden={onToggleHidden} onSaveHideExpertReason={onSaveHideExpertReason}>
                  <StarExpertProvider onToggleStar={onToggleStar}>
                    <CurrentWorkstreamProvider project={project} workstreamId={viewProps.workstreamId}>
                      <LabelsProvider project={project}>
                        <Page {...viewProps} />
                      </LabelsProvider>
                    </CurrentWorkstreamProvider>
                  </StarExpertProvider>
                </HideExpertProvider>
              </MessageThreadProvider>
            </CommentProvider>
          </ProjectInteractionsProvider>
        </ProjectContext.Provider>
      </DispatchContext.Provider>
    );
  }
);

export const InteractionsPage = React.memo(InteractionsPageComponent);

const Content = ({ children, showMobileView }) => {
  const { color } = useThemeTokens();
  return (
    <x.div
      data-testid="interactions-page-content"
      flexGrow={1}
      minHeight={0}
      position="relative"
      background={color.background.neutral.subtle}
      children={children}
      flexBasis="0"
      overflow={showMobileView ? undefined : "auto"}
    />
  );
};

const Wrapper = ({ children }) => {
  const { hasBackdrop } = useBackdropContext();
  return (
    <>
      <x.div display="flex" flexDirection="row" minHeight="0" flexGrow="1">
        {hasBackdrop && <Backdrop zIndex="30" data-testid="interactions-page-backdrop" />}
        {children}
      </x.div>
    </>
  );
};

const MainContent = ({ flyoutMode, isFlyoutOpen, children }) => {
  const [windowWidth] = useWindowDimensions();
  const hidden = useFlyoutWidth(flyoutMode) === windowWidth && isFlyoutOpen;

  return (
    <x.div
      style={{ transition: "width 0.2s ease-in-out" }}
      h="100%"
      w={hidden ? 0 : undefined}
      display={hidden ? "none" : "flex"}
      children={children}
    />
  );
};

const AdvisorFlyout = ({ isScheduleModalOpen, onSchedule, ...props }) => {
  const trackUserAction = useTrackUserAction();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { token, interaction, onChangeLayout } = props;
  const currentView = currentProjectView(location.pathname);
  const dispatch = useContext(DispatchContext);
  const currentUser = useCurrentUser();
  const tz = useTimezone();
  const [isScheduling, setScheduling] = useState(true);
  const query = useQuery();
  const [flyoutSectionFocus, setFlyoutSectionFocus] = useState();
  const { hasProjectBadge } = useProjectBadgeContext();

  useEffect(() => {
    if (token && interaction?.id) {
      trackUserAction.trackUserViewed("interactions", token, interaction.id, "track-viewed-profile", {
        origin: currentView,
      });

      trackUserAction.logHit({
        origin: HitOrigin.flyout,
        action: "VIEW_ADVISOR_PROFILE",
        advisorshipId: interaction.id,
        projectToken: token,
        details: { hidden: interaction.hidden },
        references: {
          expertId: interaction.advisorId,
          angleId: interaction.group?.id,
        },
      });

      const source = queryParams.get("source");
      if (source !== null) {
        queryParams.delete("source");

        trackUserAction.logHit({
          origin: HitOrigin.flyout,
          action: "VIEW_ADVISOR_EMAIL_SCHEDULE",
          projectToken: token,
          advisorshipId: interaction.id,
          details: { source },
        });
      }

      dispatch(markAsViewed(interaction, currentUser));

      const params = new URLSearchParams(location.search);
      const schedulingAt = params.get("schedulingAt");
      const canSchedule = !!interaction && interaction.scheduledCallTime == null && isScheduling && !!schedulingAt;
      if (canSchedule) {
        const selectedSlot = tz.parseDateZoned(schedulingAt);

        const preSelectedSlot = interaction.advisorAvailability
          ?.map((slot) => ({
            ...slot,
            startsAt: tz.parseDateZoned(slot.startsAt),
            endsAt: tz.parseDateZoned(slot.endsAt),
          }))
          .find((slot) => tz.parseDateZoned(slot.startsAt).getTime() === selectedSlot.getTime());

        preSelectedSlot &&
          onSchedule({
            id: interaction.id,
            requestedScheduleDate: preSelectedSlot.startsAt,
          });

        setScheduling(false);
      }

      if (params.has("open_profile") || params.has("expand_section")) {
        const isOpenProfile = params.has("open_profile");
        const section = params.get("expand_section") || "bio";
        const hitMap = {
          bio: "DIRECT_LINK_BIO",
          upgrades: "DIRECT_LINK_UPGRADES",
        };
        hitMap[section] &&
          trackUserAction.logHit({
            origin: currentView,
            action: hitMap[section],
            advisorshipId: interaction.id,
            projectToken: token,
          });
        setFlyoutSectionFocus(section);
        params.delete("open_profile");
        params.delete("expand_section");
        navigate(
          {
            search: params.toString(),
          },
          { replace: true }
        );

        const customerViewEnabled = currentUser?.portalCustomerKnowledgeEnabled || hasProjectBadge(CUSTOMER_KNOWLEDGE);
        if (isOpenProfile && customerViewEnabled && isEndCustomerInteraction(interaction)) {
          onChangeLayout("customer-view");
        }
      } else {
        setFlyoutSectionFocus(null);
      }
    }
  }, [interaction?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const sidebarProps = {
    ...props,
    showChainList: !!query.get("showChainList"),
    showAvailabilities: !!query.get("showAvailabilities"),
    isScheduleModalOpen,
    flyoutSectionFocus,
    showMobileView: props.isMobile,
  };
  return <AdvisorSidebar onSchedule={onSchedule} {...sidebarProps} />;
};

const CurrentViewContainer = React.forwardRef(({ showMobileView, children, currentView, ...props }, ref) => {
  const interactionsMainContent = (
    <x.div
      h="100%"
      id="interactions-main-content"
      w="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      position="relative"
      overflow={showMobileView ? undefined : currentView === "comparison-view" ? "hidden" : "auto"}
      maxW="100dvw"
      children={children}
      bg={["list-view", "table-view", "survey-view"].includes(currentView) ? "white" : undefined}
      ref={ref}
      {...props}
    />
  );

  return (
    <x.div display="flex" flexDirection="column" minHeight="0" minWidth="0" flexGrow="1">
      <x.div id="interaction-page-header-container" background="white" />
      {interactionsMainContent}
    </x.div>
  );
});

const getCards = ({ interactions, outOfFilterInteractions, chainInteractions, ...props }, selectedCardId) => {
  const defaultValue = {
    selectedCard: null,
    nextCard: null,
    previousCard: null,
    followUpCard: null,
  };
  if (!interactions || interactions.length === 0) {
    return defaultValue;
  }

  const selectedCardIndex = interactions.findIndex(({ id }) => id === selectedCardId);

  if (selectedCardIndex === -1) {
    return {
      ...defaultValue,
      selectedCard: findInteraction(selectedCardId, {
        outOfFilterInteractions,
        chainInteractions,
      }),
    };
  }

  const selectedCard = interactions[selectedCardIndex];

  if (interactions.length === 1) {
    return Object.assign(defaultValue, { selectedCard });
  }

  const nextCard = selectedCardIndex < interactions.length - 1 ? interactions[selectedCardIndex + 1] : interactions[0];
  const previousCard =
    selectedCardIndex > 0 ? interactions[selectedCardIndex - 1] : interactions[interactions.length - 1];

  const followUpCard = findInteraction(selectedCard?.followUpId, {
    interactions,
    ...props,
  });

  return {
    ...defaultValue,
    selectedCard,
    nextCard,
    previousCard,
    followUpCard,
  };
};

const ActionMap = {
  "list-view": HitAction.viewAdvisorList,
  "table-view": HitAction.viewAdvisorTable,
  "calendar-view": HitAction.viewCalendarView,
  "surveys-results-view": HitAction.viewSurveysResults,
  "deliverables-view": HitAction.viewDeliverablesPage,
  synthesis: HitAction.viewSynthesisPage,
};

const Page = (props) => {
  const {
    currentView,
    token,
    project,
    interactions: originalInteractions,
    thirdPartyDocuments,
    isProjectSettingsOpen,
    isUpdatingProjectLevelSettings,
    selectedCardId,
    transcriptEnabled,
    aiTranscriptEnabled,
    recordedEnabled,
    videoCallAvailable,
    blindedUpgradeAvailable,
    relevantStatementType,
    showAngleTypes,
    showCalendarView,
    showDeliverablesView,
    showMessagesView,
    schedulingAdvisor = null,
    isFlyoutOpen,
    flyoutMode,
    loading,
    appliedFilters,
    filterOptions,
    backgroundSyncRunning,
    onCancelRequest,
    onRequestTranscript,
    onCancelRequestTranscript,
    onCloseSchedulingModal,
    onSubmitSchedule,
    onLeaveAvailability,
    onSubmitInvitation,
    onSelectCard,
    onRequest,
    onRequestRescheduleInteraction,
    onCancelPendingRequest,
    onRequestFollowUp,
    onUpdateFollowUp,
    onCancelFollowUp,
    onCloseFollowUpModal,
    onStartSchedule,
    onSubmitClientAvailability,
    onTrackViewedProfile,
    onTextSearch,
    onFiltersChange,
    isMobile,
    flyoutShowsCalendar,
    clientRequests,
    hasClientPortalMessages,
    onRequestTranscriptPostCall,
    chainInteractions,
    scheduledInteractions,
    researchRequest,
    pcc,
    pccOptOutWindow,
    interactionChainDate,
    clientAvailability,
    onSaveClientAvailability,
    projectWithInteractions,
    onOpenComments,
    pastProjectsEnabled,
    requestedScheduleDate,
    experts: originalExperts,
    totalResults,
    upgrades,
    loadingUpgrades,
    flyoutAction,
    deliverablesViewEnabled,
    onSendNewMessage,
    newMessageType,
    newMessageOrigin,
    selectedExpertsIds,
    workstreamId,
    onChangeLayout,
  } = props;

  // TODO [REDIS2-502]: Remove Surveys Results Badge
  const { hasUserBadge } = useUserBadgeContext();
  const hasSurveysResultsPageBadge = hasUserBadge(Badge.surveysResults);

  const applyWorkstreamFilter =
    project.enablePortalWorkstreams &&
    ["table-view", "list-view", "survey-view", "customer-view", "comparison-view"].includes(currentView);
  const interactions = applyWorkstreamFilter
    ? fromWorkstream(originalInteractions, workstreamId)
    : originalInteractions;
  const experts = applyWorkstreamFilter ? fromWorkstream(originalExperts, workstreamId) : originalExperts;
  const { logHit } = useTrackUserAction();
  const showCidOnSurveyView = useShowCidOnSurveyView();
  const { performSearchSync } = useProjectInteractionsContext();
  const currentUser = useCurrentUser();
  const showNewMobileView = isMobile;
  const isScheduleModalOpen = !!schedulingAdvisor && schedulingAdvisor.hasOwnProperty("id");

  const isPCCEnabledClient = currentUser?.csaSettings.peerContentContributor;
  const isPTLEnabledClient = currentUser?.csaSettings.privateTranscriptContributor;

  const [headerRef, { height: headerHeight }] = useDimensions();
  const cardRefs = getCards(props, selectedCardId);

  const [isFilterOpen, openFilter] = useState(props.isFilterDefaultOpen);
  const [interactionsFilterRef, { width: sidebarWidth }] = useDimensions();
  const [isDownloadsOpen, setDownloadsOpen] = useState(false);
  const [isCostOverviewOpen, setCostOverviewOpen] = useState(false);
  const [chainIdSelected, setChainIdSelected] = useState(null);
  const [isSurveyWithoutFollowUp, setIsSurveyWithoutFollowUp] = useState(false);
  const [isPrivateTranscriptContributor, setIsPrivateTranscriptContributor] = useState(
    project.privateTranscriptContributor
  );
  const [isPeerContentContributor, setIsPeerContentContributor] = useState(project.peerContentContributor);
  const [showMobileNav, setShowMobileNav] = useState(showNewMobileView);
  const mobileEmptyResult = useMemo(() => (showNewMobileView ? showMobileNav : true), [
    showNewMobileView,
    showMobileNav,
  ]);

  const [searchQuery, onSearchQueryChange] = useState("");

  const onResetTextFilter = () => {
    onSearchQueryChange("");
    onTextSearch([]);
  };

  useEffect(() => {
    openFilter(isMobile === false && props.isFilterDefaultOpen);
  }, [isMobile, props.isFilterDefaultOpen]);

  useEffect(() => {
    if (isFlyoutOpen) {
      openFilter(false);
    }
  }, [isFlyoutOpen]);

  useEffect(() => {
    const action = ActionMap[currentView];
    if (action) {
      logHit({
        origin: currentView,
        action: action,
        projectToken: token,
      });
    }
  }, [token, currentView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsSurveyWithoutFollowUp(
      interactions.length > 0 && interactions.every((interaction) => interaction.interactionType === "Industry Survey")
    );
  }, [interactions, token]);

  const onSubmitFilters = (filters) => {
    if (isMobile) {
      openFilter(false);
    }

    onFiltersChange(filters);
  };

  const onResetAllFilters = (clearSearchQuery = true) => {
    const isComparisonView = currentView === "comparison-view";
    const filters = isComparisonView
      ? {
          groups: appliedFilters.groups,
        }
      : {};

    if (clearSearchQuery) {
      onSearchQueryChange("");
      onFiltersChange(filters, []);
    } else {
      onFiltersChange(filters);
    }
  };

  const onCloseFlyout = () => {
    setChainIdSelected(null);
    props.onCloseFlyout();
  };

  const onToggleFilter = () => {
    if (!isFilterOpen && isFlyoutOpen) {
      onCloseFlyout();
    }
    openFilter(!isFilterOpen);
  };

  const DesktopViewMap = {
    "list-view": AdvisorsPage,
    "table-view": AdvisorsTableView,
    "customer-view": CustomerExpertPage,
    "survey-view": SurveysPage,
    "calendar-view": ProjectCalendarView,
    "surveys-results-view": SurveysResultsPage,
    "messages-view": MessengerPage,
    "deliverables-view": DeliverablesView,
    "alphagpt-view": ProjectAlphaGPTPage,
    "comparison-view": ComparisonView,
    "my-alphasights-view": MyAlphasightsPage,
    synthesis: ProjectSynthesisView,
    questions: AngleQuestionsView,
  };

  const MobileViewMap = {
    "list-view": MobileInteractionsPage,
    "messages-view": MobileMessengerPage,
    "deliverables-view": MobileDeliverablesView,
  };

  const ViewMap = showNewMobileView ? MobileViewMap : DesktopViewMap;

  const CurrentView = React.useMemo(() => ViewMap[currentView] || ViewMap["list-view"], [currentView, ViewMap]);

  const isCalendarView = currentView === "calendar-view";
  const isSurveysResultsView = currentView === "surveys-results-view";
  const isMessagesView = currentView === "messages-view";
  const isDeliverablesView = currentView === "deliverables-view";
  const isProjectAlphaGPTView = currentView === "alphagpt-view";
  const isComparisonView = currentView === "comparison-view";
  const isMyAlphaSightsView = currentView === "my-alphasights-view";
  const isSynthesisView = currentView === "synthesis";
  const isCustomerViewEnabled = currentView === "customer-view";
  const isSurveyView = currentView === "survey-view";
  const isAngleQuestionsView = currentView === "questions";

  const showing = experts.length;
  const hasDeliverables = interactions.length > 0 || thirdPartyDocuments.length > 0;
  const isEmptyResults = (isDeliverablesView ? !hasDeliverables : interactions.length === 0) && !loading;

  const shouldShowEmptyResults =
    isEmptyResults &&
    !isCalendarView &&
    !isSurveysResultsView &&
    !isDeliverablesView &&
    !isMessagesView &&
    !isProjectAlphaGPTView &&
    !isSynthesisView &&
    !isMyAlphaSightsView &&
    !isSurveyView &&
    mobileEmptyResult &&
    !isAngleQuestionsView;
  const shouldShowPageFooter =
    !isCalendarView &&
    !isSurveysResultsView &&
    !isMessagesView &&
    !isDeliverablesView &&
    !isProjectAlphaGPTView &&
    !isCustomerViewEnabled &&
    !isMyAlphaSightsView &&
    !showNewMobileView &&
    !isSynthesisView &&
    !isAngleQuestionsView &&
    !isComparisonView;

  const interactionIds = React.useMemo(() => interactions.map(({ id }) => id), [interactions]);

  const onChainIdSelectedUpdated = React.useCallback(
    (id) => {
      setChainIdSelected(id);
    },
    [setChainIdSelected]
  );

  const onSetProjectSettingsMode = React.useCallback(
    (mode) => {
      props.onSetProjectSettingsMode(mode);
    },
    [props]
  );

  const isSurveyOnlyProject = useMemo(() => hasOnlySurveyWorkstreams(project), [project]);

  const changeLayout = (view) => {
    if (currentView === "comparison-view" || view === "comparison-view") {
      onSearchQueryChange("");
      onFiltersChange({}, [], { keepFlyoutOpen: true, replace: true });
    }
    onChangeLayout(view);
  };

  const isMessagesViewEnabled = showMessagesView && !isSurveyWithoutFollowUp && project.interactionsCount;

  const outletContext = {
    workstreamId,
    isFlyoutOpen,
    showNewMobileView,
    isScheduleModalOpen,
    onCloseFlyout,
    token,
    transcriptEnabled,
    aiTranscriptEnabled,
    recordedEnabled,
    videoCallAvailable,
    blindedUpgradeAvailable,
    relevantStatementType,
    showAngleTypes,
    cardRefs,
    onRequest,
    onRequestRescheduleInteraction,
    onRequestFollowUp,
    onCancelRequest,
    onChangeLayout,
    onRequestTranscript,
    onCancelRequestTranscript,
    onUpdateFollowUp,
    onCancelFollowUp,
    onCloseFollowUpModal,
    onStartSchedule,
    onSelectCard,
    onTrackViewedProfile,
    onLeaveAvailability,
    backgroundSyncRunning,
    project,
    flyoutShowsCalendar,
    currentView,
    onSubmitSchedule,
    onSubmitClientAvailability,
    showCalendarView,
    flyoutMode,
    onCancelPendingRequest,
    clientRequests,
    clientAvailability,
    interactions,
    hasClientPortalMessages,
    onRequestTranscriptPostCall,
    chainInteractions,
    scheduledInteractions,
    researchRequest,
    pcc,
    pccOptOutWindow,
    interactionChainDate,
    onSaveClientAvailability,
    projectWithInteractions,
    onOpenComments,
    requestedScheduleDate,
    onChainIdSelectedUpdated,
    upgrades,
    loadingUpgrades,
    flyoutAction,
    deliverablesViewEnabled,
    isPTLEnabledClient,
    isPCCEnabledClient,
    onSendNewMessage,
    newMessageType,
    newMessageOrigin,
    selectedExpertsIds,
    isMobile,
    onCloseSchedulingModal,
  };

  const {
    color,
    spacing: { inner },
  } = useThemeTokens();
  const { isFeatureDisabled } = useCurrentProjectContext();

  const origin = currentView === HitOrigin.listView ? HitOrigin.listView : HitOrigin.tableView;

  return (
    <x.div
      display="flex"
      flexDirection="column"
      flexGrow={1}
      overflow={isMobile ? undefined : "auto"}
      data-testid="interactions-page-wrapper"
    >
      <AlertContextProvider>
        <ProjectMoreOptionsProvider
          onOpenDownloads={() => setDownloadsOpen(true)}
          onOpenCostOverview={() => setCostOverviewOpen(true)}
          onOpenProjectSettings={() => onSetProjectSettingsMode(true)}
          onChangeLayout={changeLayout}
          currentView={currentView}
          showCidOnSurveyView={showCidOnSurveyView}
          performSearchSync={performSearchSync}
        >
          <Header ref={headerRef} background={color.background.none}>
            {!showNewMobileView && (
              <Toolbar
                token={token}
                currentView={currentView}
                isMobile={isMobile}
                lead={project.lead}
                projectTitle={project.title}
                isCalendarViewEnabled={showCalendarView && !isSurveyWithoutFollowUp}
                isMessagesViewEnabled={isMessagesViewEnabled}
                isSurveyOnlyProject={isSurveyOnlyProject}
                isSurveysResultsViewEnabled={hasSurveysResultsPageBadge}
                isDeliverablesViewEnabled={showDeliverablesView}
                workstreamId={workstreamId}
                isActiveProject={project.active}
              />
            )}
          </Header>

          <>
            <BackdropProvider>
              <Wrapper>
                <Content isFlyoutOpen={isFlyoutOpen} flyoutMode={flyoutMode} showMobileView={isMobile}>
                  <MainContent isFlyoutOpen={isFlyoutOpen} flyoutMode={flyoutMode}>
                    {isFilterOpen && !isMobile && (
                      <InteractionsFilter
                        ref={interactionsFilterRef}
                        loading={loading}
                        transcriptEnabled={transcriptEnabled}
                        options={filterOptions}
                        appliedFilters={appliedFilters}
                        onSubmit={onSubmitFilters}
                        onResetAll={onResetAllFilters}
                        project={project}
                        onClose={onToggleFilter}
                        currentView={currentView}
                        workstream={project.workstreams.find((w) => w.id === workstreamId)}
                      />
                    )}
                    <CurrentViewContainer showMobileView={isMobile} currentView={currentView}>
                      <CurrentViewHeader
                        currentView={currentView}
                        experts={experts}
                        loading={loading}
                        onChangeLayout={onChangeLayout}
                        onResetAllFilters={onResetAllFilters}
                        onSearchQueryChange={onSearchQueryChange}
                        onSubmitFilters={onSubmitFilters}
                        onToggleFilter={onToggleFilter}
                        pastProjectsEnabled={pastProjectsEnabled}
                        project={project}
                        searchQuery={searchQuery}
                        showing={showing}
                        onSearch={onTextSearch}
                      />
                      <CurrentView
                        {...props}
                        experts={experts}
                        interactions={interactions}
                        thirdPartyDocuments={thirdPartyDocuments}
                        isPTLEnabledClient={isPTLEnabledClient}
                        isPCCEnabledClient={isPCCEnabledClient}
                        isMobile={isMobile}
                        isFlyoutOpen={isFlyoutOpen}
                        flyoutMode={flyoutMode}
                        isFilterOpen={isFilterOpen}
                        onCloseFlyout={onCloseFlyout}
                        closeFilter={() => openFilter(false)}
                        headerHeight={headerHeight}
                        sidebarWidth={isFilterOpen ? sidebarWidth : 0}
                        filterOptions={filterOptions}
                        appliedFilters={appliedFilters}
                        onSubmitFilters={onSubmitFilters}
                        flyoutShowsCalendar={flyoutShowsCalendar}
                        onResetAllFilters={onResetAllFilters}
                        onSearchQueryChange={onSearchQueryChange}
                        onSearch={onTextSearch}
                        searchQuery={searchQuery}
                        onChangeLayout={onChangeLayout}
                        onToggleFilter={onToggleFilter}
                        onToggleMobileNav={(isShowing) => setShowMobileNav(isShowing)}
                        pastProjectsEnabled={pastProjectsEnabled}
                        showing={showing}
                        isActiveProject={project.active}
                        isProjectCidEnabled={project.cidEnabled}
                        chainIdSelected={chainIdSelected}
                        onCancelPendingRequest={onCancelPendingRequest}
                        allInteractions={interactions}
                        selectedExpertsIds={selectedExpertsIds}
                        newMessageOrigin={newMessageOrigin}
                        onSendNewMessage={onSendNewMessage}
                      />
                      {shouldShowEmptyResults && (
                        <x.div data-testid="empty-results" display="flex" m="auto" mb="300px">
                          <EmptySearchResults
                            onResetAll={() => onResetAllFilters(false)}
                            hasAppliedFilter={Object.values(appliedFilters ?? []).some((it) => it.length > 0)}
                            onResetTextFilter={isComparisonView ? undefined : onResetTextFilter}
                            totalResults={totalResults}
                            projectLeadName={project.lead.name}
                          />
                        </x.div>
                      )}
                      {pastProjectsEnabled && project?.inactive && (
                        <x.div
                          w="100%"
                          display="flex"
                          position="absolute"
                          justifyContent="center"
                          bottom="0px"
                          mb={inner.base06}
                        >
                          <PastProjectsBanner project={project} origin={origin} />
                        </x.div>
                      )}
                      {shouldShowPageFooter && <PageFooter />}
                    </CurrentViewContainer>
                  </MainContent>
                </Content>

                {!showNewMobileView && (
                  <SchedulingModal
                    key={isScheduleModalOpen}
                    isOpen={isScheduleModalOpen}
                    onCancelRequest={onCancelRequest}
                    onRequestTranscript={onRequestTranscript}
                    onCancelRequestTranscript={onCancelRequestTranscript}
                    onClose={onCloseSchedulingModal}
                    onSubmit={onSubmitSchedule}
                    transcriptEnabled={transcriptEnabled}
                    currentView={currentView}
                    neverShowSlots={flyoutShowsCalendar}
                    allInteractions={interactions}
                    clientAvailability={clientAvailability}
                    requestedScheduleDate={requestedScheduleDate}
                    onSubmitInvitation={onSubmitInvitation}
                    userEmail={currentUser?.email}
                    {...(schedulingAdvisor || {})}
                  />
                )}
                <Outlet context={outletContext} />
                <DownloadsModal
                  interactionIds={interactionIds}
                  token={token}
                  csvDownloadContent={project.csvDownloadContent}
                  isOpen={isDownloadsOpen}
                  onClose={() => setDownloadsOpen(false)}
                  onLinkOpened={(link) => {
                    logHit({
                      origin: currentView,
                      action: link.action,
                      projectToken: project.token,
                      details: { completedProfilesOnly: link.completedProfilesOnly },
                    });
                  }}
                />

                {isProjectSettingsOpen && (
                  <ProjectSettingsModal
                    token={token}
                    isPTLEnabledClient={isPTLEnabledClient}
                    isPCCEnabledClient={isPCCEnabledClient}
                    isUpdatingProjectLevelSettings={isUpdatingProjectLevelSettings}
                    isPeerContentContributor={isPeerContentContributor}
                    isPrivateTranscriptContributor={isPrivateTranscriptContributor}
                    setIsPeerContentContributor={setIsPeerContentContributor}
                    setIsPrivateTranscriptContributor={setIsPrivateTranscriptContributor}
                    onClose={() => onSetProjectSettingsMode(false)}
                  />
                )}

                <CostOverviewModalContainer
                  token={token}
                  isOpen={isCostOverviewOpen}
                  onClose={() => setCostOverviewOpen(false)}
                  showChargedPrice={!isFeatureDisabled(ProjectFeature.UsageModalCharge)}
                />
              </Wrapper>
            </BackdropProvider>
          </>
        </ProjectMoreOptionsProvider>
      </AlertContextProvider>
      {showMobileNav && !isFlyoutOpen && (
        <MobileNavBar
          project={project}
          isMessagesViewEnabled={isMessagesViewEnabled}
          isDeliverablesViewEnabled={showDeliverablesView}
        />
      )}
    </x.div>
  );
};

export const NewMessageRoute = () => {
  const {
    onCloseFlyout,
    token,
    interactions,
    hasClientPortalMessages,
    onSendNewMessage,
    newMessageType,
    newMessageOrigin,
    selectedExpertsIds,
  } = useOutletContext();

  return (
    <NewMessageSidebar
      onClose={onCloseFlyout}
      token={token}
      allInteractions={interactions}
      hasClientPortalMessages={hasClientPortalMessages}
      onSendNewMessage={onSendNewMessage}
      newMessageType={newMessageType}
      newMessageOrigin={newMessageOrigin}
      selectedExpertsIds={selectedExpertsIds}
    />
  );
};

export const AdvisorFlyoutRoute = () => {
  const {
    workstreamId,
    isFlyoutOpen,
    showNewMobileView,
    isScheduleModalOpen,
    onCloseFlyout,
    token,
    transcriptEnabled,
    aiTranscriptEnabled,
    recordedEnabled,
    videoCallAvailable,
    blindedUpgradeAvailable,
    relevantStatementType,
    showAngleTypes,
    cardRefs,
    onRequest,
    onRequestRescheduleInteraction,
    onRequestFollowUp,
    onCancelRequest,
    onChangeLayout,
    onRequestTranscript,
    onCancelRequestTranscript,
    onUpdateFollowUp,
    onCancelFollowUp,
    onCloseFollowUpModal,
    onStartSchedule,
    onSelectCard,
    onTrackViewedProfile,
    onLeaveAvailability,
    backgroundSyncRunning,
    project,
    flyoutShowsCalendar,
    currentView,
    onSubmitSchedule,
    onSubmitClientAvailability,
    showCalendarView,
    flyoutMode,
    onCancelPendingRequest,
    clientRequests,
    clientAvailability,
    interactions,
    hasClientPortalMessages,
    onRequestTranscriptPostCall,
    chainInteractions,
    scheduledInteractions,
    researchRequest,
    pcc,
    pccOptOutWindow,
    interactionChainDate,
    onSaveClientAvailability,
    projectWithInteractions,
    onOpenComments,
    requestedScheduleDate,
    onChainIdSelectedUpdated,
    upgrades,
    loadingUpgrades,
    flyoutAction,
    deliverablesViewEnabled,
    isPTLEnabledClient,
    isPCCEnabledClient,
    onSendNewMessage,
    newMessageType,
    newMessageOrigin,
    selectedExpertsIds,
    isMobile,
    onCloseSchedulingModal,
    isCalendarView,
  } = useOutletContext();

  return (
    <AdvisorFlyout
      workstreamId={workstreamId}
      isOpen={isFlyoutOpen}
      showNewMobileView={showNewMobileView}
      isScheduleModalOpen={isScheduleModalOpen}
      onClose={onCloseFlyout}
      onDidClose={() => onSelectCard(null)}
      token={token}
      transcriptEnabled={transcriptEnabled}
      aiTranscriptEnabled={aiTranscriptEnabled}
      recordedEnabled={recordedEnabled}
      videoCallAvailable={videoCallAvailable}
      blindedUpgradeAvailable={blindedUpgradeAvailable}
      relevantStatementType={relevantStatementType}
      showAngleTypes={showAngleTypes}
      nextCard={cardRefs.nextCard}
      previousCard={cardRefs.previousCard}
      onRequest={onRequest}
      onRequestRescheduleInteraction={onRequestRescheduleInteraction}
      onRequestFollowUp={onRequestFollowUp}
      onCancelRequest={onCancelRequest}
      onChangeLayout={onChangeLayout}
      onRequestTranscript={onRequestTranscript}
      onCancelRequestTranscript={onCancelRequestTranscript}
      onUpdateFollowUp={onUpdateFollowUp}
      onCancelFollowUp={onCancelFollowUp}
      onCloseFollowUpModal={onCloseFollowUpModal}
      onSchedule={onStartSchedule}
      onSelectCard={onSelectCard}
      onTrackViewedProfile={onTrackViewedProfile}
      onLeaveAvailability={onLeaveAvailability}
      backgroundSyncRunning={backgroundSyncRunning}
      isProjectCidEnabled={project.cidEnabled}
      flyoutShowsCalendar={flyoutShowsCalendar}
      hidePrimaryContent={(flyoutShowsCalendar && isCalendarView) || project.inactive}
      requiresComplianceApproval={project.requiresComplianceApproval ?? false}
      currentView={currentView}
      onSubmitSchedule={onSubmitSchedule}
      onSubmitClientAvailability={onSubmitClientAvailability}
      followUpCard={cardRefs.followUpCard}
      showCalendarView={showCalendarView}
      flyoutMode={flyoutMode}
      onCancelPendingRequest={onCancelPendingRequest}
      clientRequests={clientRequests}
      clientAvailability={clientAvailability}
      interaction={cardRefs.selectedCard}
      allInteractions={interactions}
      hasClientPortalMessages={hasClientPortalMessages}
      onRequestTranscriptPostCall={onRequestTranscriptPostCall}
      chainInteractions={chainInteractions}
      scheduledInteractions={scheduledInteractions}
      researchRequest={researchRequest}
      pcc={pcc}
      pccOptOutWindow={pccOptOutWindow}
      interactionChainDate={interactionChainDate}
      onSaveClientAvailability={onSaveClientAvailability}
      projectWithInteractions={projectWithInteractions}
      onOpenComments={onOpenComments}
      requestedScheduleDate={requestedScheduleDate}
      isActiveProject={project.active}
      projectLead={project.lead}
      onChainIdSelectedUpdated={onChainIdSelectedUpdated}
      upgrades={upgrades}
      loadingUpgrades={loadingUpgrades}
      flyoutAction={flyoutAction}
      deliverablesViewEnabled={deliverablesViewEnabled}
      isPTLEnabledClient={isPTLEnabledClient}
      isPCCEnabledClient={isPCCEnabledClient}
      onSendNewMessage={onSendNewMessage}
      newMessageType={newMessageType}
      newMessageOrigin={newMessageOrigin}
      selectedExpertsIds={selectedExpertsIds}
      isMobile={isMobile}
      onCloseScheduling={onCloseSchedulingModal}
    />
  );
};

const Header = React.forwardRef(({ className, children }, ref) => {
  return (
    <x.div ref={ref}>
      <x.div className={className}>{children}</x.div>
    </x.div>
  );
});
