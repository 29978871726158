import styled, { css, th, x } from "@xstyled/styled-components";
import { Icon, Typography, useThemeTokens, Tooltip, TooltipContent, Link } from "@alphasights/alphadesign-components";
import { Company, Info, TickCircleFilled } from "@alphasights/alphadesign-icons";
import { formatDate } from "utils/dates";
import { browsersLocalTime } from "content/AlphaNow/utils";
import { COMPANY_TYPE, CONTENT_TYPE, ContentPurchaseStatus, ContentStatus } from "@alphasights/client-portal-shared";

import Tag from "components/Tag";
import { getCompanyRelationship } from "../helpers";
import { MarkdownDisplay } from "@alphasights/alphadesign-rte";

const IconWrapper = styled(x.div).attrs({
  color: { _: "neutral700", hover: "icon-strong-hover" },
})``;

const DATA_TEST_IDS = {
  titleContent: "content-card-title",
};

const CompanyPill = ({ name, style = {} }) => {
  const tagStyle = { ...style };
  return <Tag data-testid="content-card-company-tag" name={name} style={tagStyle} icon={<Company />} />;
};

const SpeakerTitles = ({ speakers, typographyVariant = "body" }) => (
  <>
    {speakers.map(({ jobTitle, company }, index) => (
      <Typography key={index} variant={typographyVariant} component="span">
        {index !== 0 && " • "}
        <Typography variant={`${typographyVariant}-em`} component="span">
          {company}
        </Typography>
        <Typography variant={typographyVariant} component="span">
          {` - ${jobTitle}`}
        </Typography>
      </Typography>
    ))}
  </>
);

const CardTitle = ({ speakers = [], typographyVariant = "body", isAlphaView, isPrimer, title, ...props }) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();
  const manySpeakers = speakers.length > 1;

  const TitleContent = () => {
    if (isPrimer) {
      return (
        <Typography component="span" variant={`${typographyVariant}-em`}>
          Company Primer
        </Typography>
      );
    } else if (isAlphaView && manySpeakers) {
      return <Typography variant={`${typographyVariant}-em`}>{title}</Typography>;
    }
    return <SpeakerTitles speakers={speakers} typographyVariant={typographyVariant} />;
  };

  return (
    <x.div data-testid={DATA_TEST_IDS.titleContent} lineHeight={inner.base05} color={text.strong._} {...props?.style}>
      <TitleContent />
    </x.div>
  );
};

const Spacer = () => (
  <Typography component="span" px="300">
    |
  </Typography>
);

const SuggestedDetailsForTranscript = ({ content, detailsCollapsed = true, transcriptActions, freeLink }) => {
  const {
    agenda,
    contentType,
    externalTitle,
    status,
    speakers,
    scheduledTimeUTC,
    companies,
    isAccessible,
    purchaseStatus,
  } = content;
  const isAlphaView = contentType === CONTENT_TYPE.alphaview;
  const isPrivate = contentType === CONTENT_TYPE.privateEnterprise;
  const formattedDate = formatDate(browsersLocalTime(scheduledTimeUTC), "d MMM yyyy");
  const nonModeratorSpeakers = speakers.filter((s) => !s.isModerator);
  const manySpeakers = nonModeratorSpeakers.length > 1;
  const {
    color: { text },
    spacing: { inner },
    font,
  } = useThemeTokens();

  const purchaseStatusMap = {
    [ContentPurchaseStatus.AllAvailable]: "Available",
    [ContentPurchaseStatus.Available]: "Available",
    [ContentPurchaseStatus.FreePilotAccess]: "Available",
    [ContentPurchaseStatus.Purchased]: "Purchased",
  };

  const purchaseMessage = (purchaseStatus && purchaseStatusMap[purchaseStatus]) || "Purchased";

  const companyRelationship = getCompanyRelationship(contentType, speakers);

  let popOver;
  if (isAlphaView) {
    popOver = "Moderated expert call transcripts";
  } else if (isPrivate) {
    popOver = "Transcripts of your organization's expert calls";
  } else {
    popOver = "Transcripts contributed by your peers in the investment community";
  }

  const markdownContent = nonModeratorSpeakers
    .map(({ company, jobTitle }) => `* **${company}** - ${jobTitle}`)
    .join("\n");

  const customStyles = css`
    ul {
      margin-left: calc(-1 * ${th.space("layout-base04")});
      margin-top: calc(-1 * ${th.space("layout-base03")});
    }
  `;

  return (
    <>
      {!detailsCollapsed && (
        <x.div top="0" bg="light" pb={inner.base04}>
          <x.div pb={inner.base04} color={text.strong._}>
            <x.div display="flex" alignItems="start">
              <x.div flex="1 1 auto">
                <CardTitle
                  isAlphaView={isAlphaView}
                  title={externalTitle}
                  speakers={nonModeratorSpeakers}
                  typographyVariant="body-large"
                  style={{
                    mt: "10px",
                  }}
                />
              </x.div>
              {transcriptActions}
            </x.div>
          </x.div>
          {!isPrivate && (
            <x.div color={text.strong._} pb={inner.base04} fontSize={font.size["03"]} spaceY={inner.base04}>
              <x.div id="markdown">
                {isAlphaView && manySpeakers ? (
                  <>
                    <Typography variant="body-em" component="span" color={text.secondary}>
                      Featured experts
                    </Typography>
                    <MarkdownDisplay markdown={markdownContent} customStyles={customStyles} />
                  </>
                ) : (
                  <MarkdownDisplay markdown={nonModeratorSpeakers[0].bio} customStyles={customStyles} />
                )}
              </x.div>
              {agenda && (
                <x.div id="markdown">
                  <Typography variant="body-em" component="span" color={text.secondary}>
                    Topics
                  </Typography>
                  <MarkdownDisplay markdown={agenda} customStyles={customStyles} />
                </x.div>
              )}
            </x.div>
          )}

          <x.div display="inline-flex" alignItems="center" color={text.secondary}>
            {companies.length ? (
              <>
                <Typography component="span" pr={inner.base02}>
                  {companyRelationship}
                </Typography>
                <x.div spaceX={inner.base} display="inherit">
                  {companies
                    .filter(({ companyType }) => companyType === COMPANY_TYPE.anchor)
                    .map(({ companyName }) => (
                      <CompanyPill name={companyName} />
                    ))}
                </x.div>
              </>
            ) : (
              <Tag name={"Industry Perspective"} />
            )}
            <Spacer />
            {status === ContentStatus.upcoming && (
              <>
                <Typography component="span" color={text.danger}>
                  Upcoming
                </Typography>
                <Spacer />
              </>
            )}
            <Typography component="span">{formattedDate}</Typography>
            <x.div display="inherit" alignItems="inherit" pr={inner.base02}>
              <Spacer />
              <Typography component="span">{content.typeTitle}</Typography>
              <x.div col={{ sm: inner.base05, md: inner.base04 }} alignItems="inherit" paddingLeft={inner.base}>
                <Tooltip
                  position="bottom"
                  style={{
                    maxWidth: "unset",
                  }}
                >
                  <TooltipContent>
                    <Typography variant="body">{popOver}</Typography>
                  </TooltipContent>
                  <IconWrapper>
                    <Icon size="small">
                      <Info />
                    </Icon>
                  </IconWrapper>
                </Tooltip>
              </x.div>
            </x.div>
            {isAccessible && (
              <x.div display="inherit" alignItems="inherit">
                <Spacer />
                <x.div display="inherit" alignItems="inherit" spaceX={inner.base} color={text.success}>
                  <Icon>
                    <TickCircleFilled />
                  </Icon>
                  <Typography component="span">{purchaseMessage}</Typography>
                </x.div>
              </x.div>
            )}
            {!isAccessible && freeLink && (
              <x.div>
                <Spacer />
                <Link href={freeLink} target="_blank" data-testid="free-sample-link">
                  <Typography variant="body-em">View sample Moderated Content</Typography>
                </Link>
              </x.div>
            )}
          </x.div>
        </x.div>
      )}
    </>
  );
};

export { SuggestedDetailsForTranscript };
