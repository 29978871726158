import { useCallback, useMemo, useRef } from "react";
import * as React from "react";
import { BarChart } from "@alphasights/alphadesign-charts";
import { x } from "@xstyled/styled-components";
import { Icon, IconButton, Popover, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ArrowRight, Dot, Expert } from "@alphasights/alphadesign-icons";
import { useNavigate } from "react-router-dom";

export interface ChartData {
  name: string;
  color: string;
  data: BarChartDataPoint[];
}

export interface BarChartQuote {
  label: string;
  navToLink: string;
}

export interface BarChartDataPoint {
  x: string;
  y: number;
  color: string;
  sentiment: string;
  quotes: BarChartQuote[];
}

export interface ExtraBarChartProps {
  id: string;
  horizontal: boolean;
  popOverPlacement: "top" | "bottom" | "left" | "right" | undefined;
  height?: number;
  optimalColumnWidthPercent?: number;
  yAxisTitle?: Record<string, any>;
  xAxisTitle?: Record<string, any>;
}

export const SynthesisBarChart = ({
  chartData,
  categories,
  extraChartProps,
  toolbarEnabled,
}: {
  chartData: ChartData[];
  categories: string[];
  extraChartProps: ExtraBarChartProps;
  toolbarEnabled: boolean;
}) => {
  const [popoverSettings, setPopoverSettings] = React.useState<PopoverSettings>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);
  const ghostElementRef = React.useRef(null);
  const canClosePopover = useRef(true);
  const popoverData = useMemo(
    () =>
      popoverSettings.seriesIndex !== undefined &&
      popoverSettings.dataPointIndex !== undefined &&
      chartData[popoverSettings.seriesIndex ?? 0].data[popoverSettings.dataPointIndex ?? 0],
    [chartData, popoverSettings.dataPointIndex, popoverSettings.seriesIndex]
  );

  const handleClosePopover = useCallback(() => {
    if (!canClosePopover.current) return;
    setPopoverSettings({ top: 0, left: 0, width: 0, height: 0, seriesIndex: undefined, dataPointIndex: undefined });
  }, []);

  const handleDataPointMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    _chartContext: unknown,
    opts: { seriesIndex: number; dataPointIndex: number }
  ) => {
    const dataBoudingRect = (event.target as HTMLElement).getBoundingClientRect();
    clearTimeout(timeoutId.current);
    setTimeout(() => {
      setPopoverSettings({
        top: dataBoudingRect.top,
        left: dataBoudingRect.left,
        width: dataBoudingRect.width,
        height: dataBoudingRect.height,
        seriesIndex: opts.seriesIndex,
        dataPointIndex: opts.dataPointIndex,
      });
    });
  };

  const handleDataPointMouseLeave = () => {
    timeoutId.current = setTimeout(() => handleClosePopover(), 200);
  };

  const chartOptions = getChartOptions({
    categories,
    handleDataPointMouseEnter,
    handleDataPointMouseLeave,
    extraChartProps,
    toolbarEnabled,
  });

  return (
    <>
      <BarChart options={chartOptions} series={chartData} height={extraChartProps.height ?? 370} />
      <x.div
        ref={ghostElementRef}
        position="fixed"
        background="transparent"
        top={popoverSettings.top}
        left={popoverSettings.left}
        w={popoverSettings.width}
        h={popoverSettings.height}
        pointerEvents="none"
      >
        <Popover
          animationFrame={true}
          anchorEl={ghostElementRef?.current ?? undefined}
          open={!!popoverData}
          placement={extraChartProps.popOverPlacement}
          onMouseEnter={() => (canClosePopover.current = false)}
          closeOnMouseLeave
          onClose={() => {
            canClosePopover.current = true;
            handleClosePopover();
          }}
        >
          {popoverData && <BarChartPopoverContent dataPoint={popoverData} />}
        </Popover>
      </x.div>
    </>
  );
};

const BarChartPopoverContent = ({ dataPoint }: { dataPoint: BarChartDataPoint }) => {
  const { popoverItemWrapper, iconGroup, popoverContentWrapper } = useSynthesisChartStyles();
  const navigate = useNavigate();

  const navigateToTranscript = (quote: BarChartQuote) => {
    navigate(quote.navToLink);
  };

  return (
    <x.ul {...popoverContentWrapper} data-testid="chart-popover">
      <x.li {...popoverItemWrapper}>
        <x.div {...iconGroup}>
          <Icon color={dataPoint.color} size="medium">
            <Dot />
          </Icon>
          <Typography variant="body-small-em">{dataPoint.sentiment}</Typography>
        </x.div>
        <Typography variant="body-small" color="secondary">
          {dataPoint.y}
        </Typography>
      </x.li>
      {dataPoint.quotes
        .filter((quote) => quote.navToLink && quote.label)
        .map((quote, idx) => (
          <x.li key={idx} {...popoverItemWrapper}>
            <x.div {...iconGroup}>
              <Icon color="secondary" size="medium">
                <Expert />
              </Icon>
              <Typography variant="body-small">{quote.label}</Typography>
            </x.div>
            <Tooltip title="Go to transcript" position="bottom" zIndex={1700}>
              <IconButton
                color="secondary"
                size="small"
                variant="basic"
                onClick={() => navigateToTranscript(quote)}
                testId={`view-in-transcript`}
              >
                <ArrowRight />
              </IconButton>
            </Tooltip>
          </x.li>
        ))}
    </x.ul>
  );
};

const getChartOptions = ({
  categories,
  handleDataPointMouseEnter,
  handleDataPointMouseLeave,
  extraChartProps,
  toolbarEnabled,
}: {
  categories: string[];
  handleDataPointMouseEnter: (
    event: React.MouseEvent<HTMLElement>,
    _chartContext: unknown,
    opts: { seriesIndex: number; dataPointIndex: number }
  ) => void;
  handleDataPointMouseLeave: () => void;
  extraChartProps: ExtraBarChartProps;
  toolbarEnabled: boolean;
}) => {
  // Since we can't control the column width direcly in px, we calculate the optimal column width in percent.
  // This is a simple formula that makes the chart look good with different number of categories.
  const dataLength = categories.length;
  const optimalColumnWidthPercent = !!extraChartProps.optimalColumnWidthPercent
    ? extraChartProps.optimalColumnWidthPercent
    : dataLength <= 3
    ? 10 * dataLength
    : 100 / Math.pow(dataLength, 0.7);

  return {
    chart: {
      id: extraChartProps.id,
      fontFamily: "Proxima Nova, Arial, sans-serif",
      events: {
        dataPointMouseEnter: handleDataPointMouseEnter,
        dataPointMouseLeave: handleDataPointMouseLeave,
        click: (event: MouseEvent) => {
          event.stopPropagation();
        },
        dataPointSelection: (
          _: MouseEvent,
          ctx: any,
          cfg: { selectedDataPoints: any[]; seriesIndex: number; dataPointIndex: number }
        ) => {
          // could not disable "selecting", so here we forcibly "unselect"
          const anythingSelected = cfg.selectedDataPoints.filter((arr: any) => arr[0] !== undefined).length > 0;
          if (anythingSelected) ctx.toggleDataPointSelection(cfg.seriesIndex, cfg.dataPointIndex);
        },
      },
      type: "bar",
      stacked: true,
      toolbar: {
        show: toolbarEnabled,
      },
    },
    plotOptions: {
      bar: {
        horizontal: extraChartProps.horizontal,
        columnWidth: optimalColumnWidthPercent + "%",
        barHeight: optimalColumnWidthPercent + "%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      title: extraChartProps.xAxisTitle || { text: "" },
      forceNiceScale: true,
      categories,
      labels: {
        formatter: (value: number) => {
          return (value.toFixed && value.toFixed(0)) || value;
        },
        style: {
          fontSize: 14,
        },
      },
    },
    yaxis: {
      title: extraChartProps.yAxisTitle || { text: "" },
      forceNiceScale: true,
      labels: {
        formatter: (value: number) => {
          return (value.toFixed && value.toFixed(0)) || value;
        },
        style: {
          fontSize: 14,
        },
      },
    },
    legend: {
      markers: {
        shape: "circle",
      },
      fontSize: 14,
    },
    tooltip: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
        },
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: extraChartProps.horizontal,
        },
      },
      yaxis: {
        lines: {
          show: !extraChartProps.horizontal,
        },
      },
    },
  };
};

export const useSynthesisChartStyles = ({ chartHeight }: { chartHeight?: number } = {}) => {
  const { spacing } = useThemeTokens();

  const popoverContentWrapper = {
    maxH: "274px",
    w: "260px",
    overflow: "auto",
  };

  const popoverItemWrapper = {
    display: "flex",
    justifyContent: "space-between",
    p: spacing.inner.base02,
    alignItems: "center",
  };

  const iconGroup = {
    display: "flex",
    alignItems: "center",
    gap: spacing.inner.base02,
  };

  return {
    popoverContentWrapper,
    popoverItemWrapper,
    iconGroup,
  };
};

interface PopoverSettings {
  top: number;
  left: number;
  width: number;
  height: number;
  seriesIndex?: number;
  dataPointIndex?: number;
}
