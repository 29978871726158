import {
  AccordionItem,
  Icon,
  Pill,
  Tile,
  Tooltip,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { Info, TickCircleFilled } from "@alphasights/alphadesign-icons";
import { AlphaNowContentType, AlphaNowProductType, useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import {
  FREE_RESEARCH_CONTENT_IDS,
  FREE_RESEARCH_TYPE_LABEL,
  ORDERED_PRODUCT_TYPES,
  OUR_RESEARCH_SUMMARY_DESCRIPTION,
  OUR_RESEARCH_TITLE,
  RESEARCH_TYPE_DESCRIPTIONS,
  RESEARCH_TYPE_ICONS,
} from "pages/AlphaNowPage/components/LandingPage/OurResearchSection/constants";
import {
  OurResearchSectionContainer,
  HeaderContainer,
  StyledAccordion,
  ResearchContainer,
  ResearchTitleContainer,
  ResearchDescriptionContainer,
  AccordionItemContainer,
  CommissionPrimerContainer,
} from "pages/AlphaNowPage/components/LandingPage/OurResearchSection/OurResearchSection.styled";
import { useAlphaNowContentAccessLevelContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { Filters } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { CommissionButton, CommissionModal } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";
import useModal from "hooks/useModal";

const DATA_TEST_IDS = {
  OurResearchSection: "our-research-section",
};

const ResearchHeader = () => (
  <HeaderContainer>
    <Typography variant="body-em">{OUR_RESEARCH_TITLE}</Typography>
    <Tooltip title={OUR_RESEARCH_SUMMARY_DESCRIPTION} variant="dark">
      <Icon size="small">
        <Info />
      </Icon>
    </Tooltip>
  </HeaderContainer>
);

const CommissionPrimerButton = () => {
  const { isVisible, onOpen, onClose } = useModal();
  const { logHit } = useTrackUserAction();

  const handleOnClick = () => {
    onOpen();
    logHit({
      origin: HitOrigin.landingPage,
      action: HitAction.alphaNowRequestPrimerAttempt,
    });
  };
  return (
    <CommissionPrimerContainer>
      <CommissionButton onClick={handleOnClick} variant="secondary" />
      {isVisible && <CommissionModal onClose={onClose} origin={HitOrigin.landingPage} />}
    </CommissionPrimerContainer>
  );
};

const ResearchSummary = ({ title, contentType }: { title: string; contentType: string }) => {
  const { color } = useThemeTokens();
  const {
    query: { filters: queryFilters },
    updateQuery,
  } = useAppSearchContext();
  const researchDescription = RESEARCH_TYPE_DESCRIPTIONS[contentType];
  const ResearchIcon = RESEARCH_TYPE_ICONS[contentType];
  const displayFreeResearchPill = [AlphaNowProductType.privateEnterprise, AlphaNowProductType.pcc].includes(
    contentType as AlphaNowProductType
  );

  const handleSelectResearch = (productType: AlphaNowProductType) =>
    updateQuery({
      selectedContentId: FREE_RESEARCH_CONTENT_IDS[productType],
      filters: { ...queryFilters, contentType: [productType] } as Filters,
    });

  return (
    <ResearchContainer onClick={() => handleSelectResearch(contentType as AlphaNowProductType)}>
      <Tile variant="icon" size="small" icon={<ResearchIcon />} />
      <ResearchDescriptionContainer>
        <ResearchTitleContainer>
          <Typography variant="body-small-em" color={color.text.info}>
            {title}
          </Typography>
          {displayFreeResearchPill && (
            <Pill
              size="x-small"
              variant="green"
              leftAccessories={<TickCircleFilled color={color.icon.success} size="small" />}
              isInteractive={false}
            >
              {FREE_RESEARCH_TYPE_LABEL}
            </Pill>
          )}
        </ResearchTitleContainer>
        <Typography color={color.text.secondary} variant="body-small">
          {researchDescription}
        </Typography>
      </ResearchDescriptionContainer>
    </ResearchContainer>
  );
};

const OurResearchSection = () => {
  const { contentTypeMapping = [] } = useAlphaNowContentAccessLevelContext();

  const displayedProductTypes =
    contentTypeMapping
      .filter((accessLevel) => accessLevel.contentType !== AlphaNowContentType.alphaviewDialIn)
      .sort(
        (a, b) =>
          ORDERED_PRODUCT_TYPES.indexOf(a.contentType as AlphaNowProductType) -
          ORDERED_PRODUCT_TYPES.indexOf(b.contentType as AlphaNowProductType)
      ) ?? [];

  return (
    <div data-testId={DATA_TEST_IDS.OurResearchSection}>
      <StyledAccordion>
        <AccordionItem titleContent={<ResearchHeader />} open>
          <AccordionItemContainer>
            <OurResearchSectionContainer>
              {displayedProductTypes.map(({ contentTypeDisplayName, contentType }) => (
                <ResearchSummary contentType={contentType} title={contentTypeDisplayName} />
              ))}
            </OurResearchSectionContainer>
          </AccordionItemContainer>
        </AccordionItem>
      </StyledAccordion>
      <CommissionPrimerButton />
    </div>
  );
};

export { OurResearchSection as default, DATA_TEST_IDS };
