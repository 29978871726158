export enum ViewType {
  Summary = "Summary",
  Questions = "Questions",
  Transcript = "Transcript",
}

export enum QueryStatus {
  Loading = "loading",
  Success = "success",
  Error = "error",
}

export enum ProcessingStatus {
  completed = "completed",
  pending = "pending",
  failed = "failed",
}

export const DEFAULT_PROCESSING_STATUS = { status: ProcessingStatus.pending };
