import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { color, spacing } = useThemeTokens();
  return {
    moduleTopBarWrapper: {
      display: "flex",
      h: spacing.layout.base08,
      w: "100%",
      justifyContent: "space-between",
      px: spacing.inner.base06,
      py: spacing.inner.base05,
      bg: color.background.surface.raised,
    },
    leftGroup: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base04,
    },
    rightGroup: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base02,
    },
    regeneratedAt: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base02,
    },
    separator: {
      borderBottom: `1px solid ${color.border.divider}`,
      bg: color.background.surface.recessed,
    },
    keyWrapper: {
      border: "0.5px solid #00000080",
      borderRadius: "4px",
      background:
        "linear-gradient(0deg, #282A39, #282A39), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.08) 100%)",
      h: spacing.inner.base04,
      minW: spacing.inner.base04,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    flexCentered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
};
