import { Alert, useThemeTokens } from "@alphasights/alphadesign-components";

export const OneHourMinimumAlert = (props) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <Alert variant="info" data-testid="one-hour-min-alert" mt={inner.base04} {...props}>
      You may schedule a 30 minute call with this expert, but you will be billed for the full hour.
    </Alert>
  );
};
