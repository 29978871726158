import { useCallback } from "react";
import { produce } from "immer";
import { NPSCompany, SynthesisModule } from "@alphasights/portal-api-client";
import { nps } from "../synthesisTypeGuards";

export const useEditNpsOperations = ({ originalModule }: { originalModule?: SynthesisModule }) => {
  const updateNpsItem = useCallback(
    (newNpsItem: NPSCompany, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = nps(draft.revisions[revisionIdx].contents);
        const npsItemIndex = content.companies.findIndex((company) => company.id === newNpsItem.id);
        if (npsItemIndex !== -1) {
          content.companies[npsItemIndex] = newNpsItem;
        }
      });
    },
    [originalModule]
  );

  const deleteNpsItem = useCallback(
    (newNpsItem: NPSCompany, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = nps(draft.revisions[revisionIdx].contents);
        const npsItemIndex = content.companies.findIndex((company) => company.id === newNpsItem.id);
        if (npsItemIndex === -1) return;
        content.companies.splice(npsItemIndex, 1);
      });
    },
    [originalModule]
  );

  return {
    updateNpsItem,
    deleteNpsItem,
  };
};
