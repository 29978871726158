import { x } from "@xstyled/styled-components";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { Loading } from "@alphasights/alphadesign-components";
import { SurveyTableView } from "./SurveyTableView";
import useStyles from "./styles";
import { CommentProvider } from "components/CommentThread/CommentContext";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useFlyoutWidth } from "pages/InteractionPage";
import { AlertContextProvider } from "components/InteractionsPage/AlertContextProvider";
import { useCurrentWorkstreamContext } from "providers/CurrentWorkstreamProvider";

export const SurveysPage = (props) => {
  const { selectedCardId: interactionId, onToggleFilter } = props;
  const { project, isLoading } = useCurrentProjectContext();
  const { selectedWorkstream } = useCurrentWorkstreamContext();
  const {
    state: { flyoutMode, isFlyoutOpen },
  } = useProjectInteractionsContext();
  const [windowWidth] = useWindowDimensions();

  const contentHidden = useFlyoutWidth(flyoutMode) === windowWidth && isFlyoutOpen;
  const styles = useStyles({ contentHidden });

  if (isLoading || !selectedWorkstream) {
    return <Loading data-testid="workstream-page-load" />;
  }

  return (
    <AlertContextProvider>
      <CommentProvider project={project}>
        <x.div {...styles.pageWrapper}>
          <x.div {...styles.surveyTableWrapper}>
            <SurveyTableView selectedInteractionId={interactionId} onToggleFilter={onToggleFilter} />
          </x.div>
        </x.div>
      </CommentProvider>
    </AlertContextProvider>
  );
};
