import React, { useMemo } from "react";
import { Icon, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import { BulletPoints, CONTENT_TYPE, DATE_FORMAT, SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import ResearchContainer from "components/ResearchContainer";
import {
  EXPERT_DESCRIPTION,
  FEATURED_COMPANY_HEADER,
  FEATURED_EXPERT_HEADER,
  FEATURED_EXPERT_SECONDARY_LABEL,
  HEADER,
} from "./constants";
import { browsersLocalTime, buildSearchQuery, getCompaniesFromSearchQuery } from "content/AlphaNow/utils";
import { formatDate } from "utils/dates";
import {
  ExpertDetailsContainer,
  FeaturedCompanyPillsContainer,
  SecondaryLabelContainer,
  SectionHeaderContainer,
  StyledSectionHeader,
} from "./ExpertView.styled";
import { useAppSearchContext } from "providers/AppSearchProvider";
import FeaturedCompanyPill from "components/FeaturedCompanyPill/FeaturedCompanyPill";
import FeaturedExpertCards from "pages/AlphaNowPage/components/FeaturedExpertCards";

const DataTestIds = {
  FeaturedCompanyPill: "featured-company-pill",
  FeaturedExpertInfoIcon: "featured-expert-info-icon",
};

const ExpertView = ({ content }: { content: Content }) => {
  const {
    updateQuery,
    query: { searchQuery },
  } = useAppSearchContext();
  const {
    contentType,
    createdAt,
    scheduledTimeUTC,
    agenda,
    externalTitle,
    displayTitle,
    companies,
    speakers,
    id: contentId,
  } = content;
  const publishedDate = formatDate(scheduledTimeUTC ?? browsersLocalTime(createdAt!), DATE_FORMAT);

  const speakerDetails = useMemo(() => {
    return speakers
      .filter((speaker) => !speaker.isModerator)
      .map(({ bio, jobTitle, angleTypes, company, companyLogo, jobDuration, speakerId }) => ({
        speakerId,
        bio,
        jobTitle,
        angleType: angleTypes[0]?.name,
        companyName: company,
        logo: companyLogo,
        jobDuration,
      }));
  }, [speakers]);

  const handleSelectCompanyPill = (companyId: number, companyName: string) => {
    const newSearchQuery = buildSearchQuery({
      type: SEARCH_SUGGESTION_TYPES.Company,
      value: companyName,
      id: companyId,
    });
    updateQuery({
      selectedContentId: undefined,
      searchQuery: newSearchQuery,
    });
  };

  const searchedCompanyIds = useMemo(() => {
    return getCompaniesFromSearchQuery(searchQuery).map(({ id }: { id: number }) => id);
  }, [searchQuery]);

  return (
    <ResearchContainer header={HEADER} secondaryLabel={publishedDate}>
      <ExpertDetailsContainer>
        {agenda && (
          <div>
            <StyledSectionHeader>
              {contentType === CONTENT_TYPE.alphaview ? externalTitle : displayTitle}
            </StyledSectionHeader>
            <BulletPoints items={agenda} />
          </div>
        )}
        <StyledSectionHeader>{FEATURED_COMPANY_HEADER}</StyledSectionHeader>
        <FeaturedCompanyPillsContainer>
          {companies.map(({ companyName, logo, companyId }) => (
            <FeaturedCompanyPill
              dataTestId={DataTestIds.FeaturedCompanyPill}
              size="medium"
              iconUrl={logo}
              label={companyName}
              isHighlighted={searchedCompanyIds.includes(companyId)}
              onClick={() => handleSelectCompanyPill(companyId, companyName)}
              nameMaxLength={50}
            />
          ))}
        </FeaturedCompanyPillsContainer>
        <SectionHeaderContainer>
          <StyledSectionHeader>{FEATURED_EXPERT_HEADER}</StyledSectionHeader>
          <SecondaryLabelContainer>
            <Typography variant="body-small">{FEATURED_EXPERT_SECONDARY_LABEL}</Typography>
            <Tooltip title={EXPERT_DESCRIPTION} variant="dark" size="small">
              <Icon size="small" dataAttributes={{ "data-testid": DataTestIds.FeaturedExpertInfoIcon }}>
                <Info />
              </Icon>
            </Tooltip>
          </SecondaryLabelContainer>
        </SectionHeaderContainer>
        <FeaturedExpertCards speakers={speakerDetails} requestExpertEnabled contentId={contentId} />
      </ExpertDetailsContainer>
    </ResearchContainer>
  );
};

export { ExpertView as default, DataTestIds };
