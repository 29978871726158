import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { omit } from "lodash";

import { ThirdPartyDocument, ThirdPartyDocumentType, ThirdPartyDocumentExpertWithId } from "types/common-types";
import { fromPx } from "pages/AlphaNowPage/utils";
import { DocumentAttributesForm } from "./types";
import { DUMMY_EXPERT } from "./constants";

const getPopoverOffset = () => ({
  mainAxis: fromPx(tokens.spacing.inner.base02),
  crossAxis: fromPx(tokens.spacing.inner.base04),
});

const createFormPropsFromDocument = (documentResponse: ThirdPartyDocument) => {
  const {
    originalFilename,
    redactedFilename,
    documentType,
    attribute: { documentDate, title, experts = [] },
    createdAt,
  } = documentResponse;
  return {
    documentDate: documentDate ?? createdAt,
    title,
    fileName: redactedFilename ?? originalFilename,
    isTranscript: documentType === ThirdPartyDocumentType.transcript,
    experts,
  };
};

const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const sanitiseExperts = (experts: ThirdPartyDocumentExpertWithId[]) => {
  return experts.map((expert) => omit(expert, "expertId"));
};

const hasAllRequiredKeys = (keys: string[]) => {
  const requiredKeys = Object.keys(DUMMY_EXPERT).filter((key) => key !== "expertId");
  return requiredKeys.every((key) => keys.includes(key));
};

const getExpertIdsWithErrors = (experts: ThirdPartyDocumentExpertWithId[]) =>
  experts
    .map((expert, index) => {
      if (index !== 0) {
        const { expertId, ...rest } = expert;
        const keys = Object.keys(rest) as Array<keyof typeof rest>;
        // it does not matter if cdsAlphaCompanyId is missing, a company name can be provided without it
        if (!hasAllRequiredKeys(keys) || keys.filter((key) => key !== "cdsAlphaCompanyId").some((key) => !rest[key])) {
          return expertId;
        }
      }
      return null;
    })
    .filter(Boolean) as number[];

const getSanitisedForm = (form: DocumentAttributesForm) => {
  return {
    ...form,
    experts: sanitiseExperts(form.experts),
  };
};

export {
  getPopoverOffset,
  createFormPropsFromDocument,
  formatDate,
  sanitiseExperts,
  getExpertIdsWithErrors,
  getSanitisedForm,
};
