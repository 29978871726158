import { x } from "@xstyled/styled-components";
import { Button, useThemeTokens } from "@alphasights/alphadesign-components";

export const EditStructuredModuleItemCTAs = ({
  onCancelEdit,
  onSaveEdit,
  saveDisabled,
}: {
  onCancelEdit: () => void;
  onSaveEdit: () => void;
  saveDisabled: boolean;
}) => {
  const { spacing } = useThemeTokens();
  return (
    <x.div display="flex" gap={spacing.inner.base02} justifyContent="flex-end">
      <Button
        onClick={onCancelEdit}
        variant="ghost"
        size="small"
        dataAttributes={{ "data-testid": "cancel-item-changes" }}
      >
        Cancel
      </Button>
      <Button
        disabled={saveDisabled}
        onClick={onSaveEdit}
        variant="secondary"
        size="small"
        dataAttributes={{ "data-testid": "save-item-changes" }}
      >
        Save Changes
      </Button>
    </x.div>
  );
};
