import { Loading } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

const AlphaNowSpinner = () => (
  <x.div alignItems="center">
    <Loading size="sm" margin="0 auto" />
  </x.div>
);

export default AlphaNowSpinner;
