import { useCallback, useMemo } from "react";
import * as React from "react";
import { Icon, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { Props, x } from "@xstyled/styled-components";
import { ActionColumn } from "views/AdvisorsTableView";
import { StarSelected } from "@alphasights/alphadesign-icons";
import { isTablet } from "browser";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

export const ExpertColumn = ({ interaction }: { interaction: Interaction }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hidden = useMemo(() => interaction.hidden as boolean, [interaction]);
  const starred = useMemo(() => interaction.starred as boolean, [interaction]);
  const isTabletDevice = useMemo(() => isTablet(), []);

  const onClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (isTabletDevice && !isHovered) {
        event.stopPropagation();
        setIsHovered(true);
      }
    },
    [isHovered, isTabletDevice]
  );

  return (
    <ExpertColumnWrapper
      onMouseEnter={() => !isTabletDevice && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid={`expert-column-${interaction.id}`}
      onClick={onClick}
      isTabletDevice={isTabletDevice}
    >
      <ExpertName interaction={interaction} hidden={hidden} starred={starred} isHovered={isHovered} />
      <x.div className="visible-on-hover" display={isHovered ? "block" : "none"}>
        <ExpertActions interaction={interaction} hidden={hidden} />
      </x.div>
      {!hidden && (
        <x.div display={isHovered ? "none" : "block"} className="hidden-on-hover">
          <LimitedLinesTypography
            tooltip={`${interaction.advisorCompany} - ${interaction.role}`}
            variant="body-small-spaced-em"
            color="strong"
          >
            {interaction.advisorCompany} - {interaction.role}
          </LimitedLinesTypography>
          <Typography variant="body-small-spaced-em" color="secondary">
            {interaction.rolePeriod}
          </Typography>
        </x.div>
      )}
    </ExpertColumnWrapper>
  );
};

const ExpertName = ({
  interaction,
  hidden,
  starred,
  isHovered,
}: {
  interaction: Interaction;
  hidden: boolean;
  starred: boolean;
  isHovered: boolean;
}) => (
  <x.div display="flex" alignItems="center" justifyContent="space-between">
    <LimitedLinesTypography
      variant="body-small-spaced-em"
      color={hidden ? "disabled" : "link"}
      tooltip={interaction.advisorName}
    >
      {interaction.advisorName}
    </LimitedLinesTypography>
    {starred && !isHovered && (
      <x.div data-testid="star-badge" className="hidden-on-hover">
        <Icon size="small" color="strong">
          <StarSelected />
        </Icon>
      </x.div>
    )}
  </x.div>
);

const ExpertActions = ({ interaction, hidden }: { interaction: Interaction; hidden: boolean }) => {
  const { actionsWrapper } = useExpertColumnStyles();
  const { isMobile } = useCheckScreen();

  return (
    <x.div {...actionsWrapper} data-testid="expert-actions">
      {!hidden && (
        <ActionColumnWrapper>
          <ActionColumn interaction={interaction} origin="comparison-view" isMobile={isMobile} />
        </ActionColumnWrapper>
      )}
    </x.div>
  );
};

const ExpertColumnWrapper = styled(x.div)<{
  isTabletDevice: boolean;
}>`
  height: 100%;
  position: relative;
  min-height: 84px;

  &:hover {
    .visible-on-hover {
      ${(props: Props) => (props.isTabletDevice ? "" : "display: block !important")};
    }
    .hidden-on-hover {
      ${(props: Props) => (props.isTabletDevice ? "" : "display: none !important")};
    }
  }
`;

const ActionColumnWrapper = styled(x.div)`
  width: 100%;
  display: flex;
  & > div {
    width: 100%;
  }
  button:first-child {
    flex-grow: 1;
  }
`;

const useExpertColumnStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const actionsWrapper = {
    display: "flex",
    gap: inner.base02,
  };

  return {
    actionsWrapper,
  };
};
