import { ThreeDimensionalScatterChartKpcYElement } from "views/SurveysResultsView/api/types";

const mapThreeDimensionalKpcChartResultsInto2DResults = (results: ThreeDimensionalScatterChartKpcYElement[]) => {
  return {
    aggregated: results.map((item) => ({
      x: item.y,
      yElements: item.zElements,
      average: item.average,
    })),
    average: null,
  };
};

export default mapThreeDimensionalKpcChartResultsInto2DResults;
