import * as React from "react";
import { Button, Tooltip } from "@alphasights/alphadesign-components";
import { Chat } from "@alphasights/alphadesign-icons";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { SurveyCellProps } from "../customTypes";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

const CommentCell = ({ props }: SurveyCellProps) => {
  const interaction: Interaction = props.row.original.interaction;

  const { onOpenComments } = useProjectInteractionsContext();

  return (
    <div data-testid={`survey-comment-column-${interaction.id}`}>
      {/*@ts-ignore*/}
      <Tooltip title={"Comment"}>
        <Button
          variant="icon"
          h="20px"
          {...props}
          data-testid={`survey-comment-button-${interaction.id}`}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onOpenComments(interaction);
          }}
        >
          <Chat />
        </Button>
      </Tooltip>
    </div>
  );
};

export const CommentColumn = {
  id: "CommentColumn",
  cell: (props: AlphaTableTypes.Cell<any, any>) => <CommentCell props={props} />,
  header: "",
  minSize: 25,
  maxSize: 50,
  meta: {
    enableSorting: false,
  },
};
