import { FC, useEffect, useRef, useState, ClipboardEvent } from "react";
import { useQueryClient } from "query-utils";
import { omit } from "lodash";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";

import Search, {
  SearchVariant,
  SearchSizeVariant,
  SearchOption,
  OptionProps,
  getDefaultComponents,
} from "components/Search";
import { TRIGGER_TYPE_ICONS, TRIGGER_TYPE_NAMES } from "components/WatchlistModal/consts";
import useWatchlist, { TRIGGER_TYPE } from "hooks/useWatchlist";
import { contentService } from "services/content";
import {
  CdsCompany,
  OptionWithLogoType,
  EnhancedOptionType,
  QueryItem,
  NewTrigger,
} from "components/WatchlistModal/SearchBar/types";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { getTriggerValues } from "components/WatchlistModal/utils";

const enhanceOption = (option: OptionWithLogoType, displayLogo: boolean = true) => {
  const enhancedData = {
    ...option,
    ...(displayLogo && option.logo ? { avatar: { src: option.logo } } : { StartIcon: TRIGGER_TYPE_ICONS[option.type] }),
    secondaryLabel: TRIGGER_TYPE_NAMES[option.type],
  };

  return omit(enhancedData, ["logo"]);
};

const SearchItem: FC<EnhancedOptionType> = ({ data, ...props }) => {
  const enhancedData = enhanceOption(data, false);
  const { SearchItem: BaseSearchItem } = getDefaultComponents();
  return <BaseSearchItem data={enhancedData} {...props} variant="light" />;
};

const Option: FC<EnhancedOptionType> = ({ data, ...props }) => {
  const { Option: BaseOption } = getDefaultComponents();
  const enhancedProps = { data: enhanceOption(data), ...props } as OptionProps;
  return <BaseOption {...enhancedProps} />;
};

type SearchBarProps = {
  watchlistId: string;
  handleError: (isError: boolean) => void;
};

const SearchBar: FC<SearchBarProps> = ({ watchlistId, handleError }) => {
  const { color } = useThemeTokens();

  const [searchQuery, setSearchQuery] = useState<SearchOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isClipboardPaste = useRef(false);
  const isAutoSearchEnabled = useRef(true);

  const queryClient = useQueryClient();
  const { updateWatchlistTriggers, createWatchlist } = useWatchlist();
  const { logHit } = useTrackUserAction();

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement && event.target.id === "search-input") {
        if (isClipboardPaste.current) {
          if (!["Enter", "Backspace", "ArrowLeft", "ArrowRight"].includes(event.key)) {
            event.preventDefault();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeydown, { capture: true });
    return () => window.removeEventListener("keydown", handleKeydown, { capture: true });
  }, []);

  useEffect(() => {
    if (searchQuery.length === 0 && isClipboardPaste.current) {
      isClipboardPaste.current = false;
    }
  }, [searchQuery]);

  const handleUpdateTriggers = async (triggers: NewTrigger[]) => {
    try {
      let res;
      if (watchlistId) {
        res = await updateWatchlistTriggers({
          watchlistId,
          watchlistTriggers: triggers,
        });
        logHit({
          origin: HitOrigin.watchlist,
          action: HitAction.addWatchlistTriggers,
          details: {
            watchlistId: res?.id,
            triggers: getTriggerValues(triggers),
          },
        });
      } else {
        res = await createWatchlist({ triggers });
        logHit({
          origin: HitOrigin.watchlist,
          action: HitAction.createWatchlist,
          details: {
            watchlistId: res?.id,
            triggers: getTriggerValues(triggers),
          },
        });
      }
      const newTriggers = res?.triggers;
      if (newTriggers) {
        setSearchQuery([]);
        if (isClipboardPaste.current) {
          isClipboardPaste.current = false;
        }
      } else throw new Error();
    } catch {
      console.error("Failed to add Watchlist Trigger(s).");
    }
  };

  const handleSearch = async (query: SearchOption[]) => {
    if (query.length > 0) {
      setSearchQuery(query);
      const newTriggers = (query as QueryItem[]).map(({ type, id, value }) => {
        return {
          type: type as TRIGGER_TYPE,
          ...(type === TRIGGER_TYPE.company
            ? {
                cdsAlphaCompanyId: id as number,
              }
            : { token: value as string }),
        };
      });
      await handleUpdateTriggers(newTriggers as NewTrigger[]);
    } else {
      isClipboardPaste.current = false;
      setSearchQuery([]);
    }
    isAutoSearchEnabled.current = true;
  };

  const handlePaste = async (event: ClipboardEvent<HTMLInputElement>) => {
    setIsLoading(true);
    let newTriggers = [];

    isAutoSearchEnabled.current = false;
    isClipboardPaste.current = true;
    const inputString = event.clipboardData.getData("text");
    const items = inputString
      .split(/,\s*/)
      .map((item) => item.trim())
      .filter(Boolean);

    try {
      const { companyWithSearchResultsDto, keywords } = await contentService.bulkSearchWatchlistCompanies(
        items.toString()
      );

      const indexMap = items.reduce((acc: Record<string, number>, text: string, index: number) => {
        acc[text.toLowerCase()] = index;
        return acc;
      }, {});

      newTriggers.push(
        ...companyWithSearchResultsDto.map(({ cdsAlphaCompanyId, primaryName }: CdsCompany) => ({
          type: TRIGGER_TYPE.company,
          id: cdsAlphaCompanyId,
          value: primaryName,
          label: primaryName,
        }))
      );
      newTriggers.push(
        ...keywords.map((keyword: string) => ({
          type: TRIGGER_TYPE.keyword,
          value: keyword,
          label: keyword,
        }))
      );
      newTriggers.sort((a, b) => indexMap[a.value.toLowerCase()] - indexMap[b.value.toLowerCase()]);
      setSearchQuery([...searchQuery, ...newTriggers]);
      isClipboardPaste.current = false;
    } catch {
      handleError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadSearchOptions = async (inputValue: string) => {
    if (inputValue.length <= 1) return [];
    const companies = await queryClient.fetchQuery(["search-companies", inputValue], () =>
      contentService.searchWatchlistCompanies(inputValue)
    );

    const options = [
      {
        label: inputValue[0].toUpperCase() + inputValue.substring(1),
        value: inputValue,
        type: TRIGGER_TYPE.keyword,
      },
      ...companies.map(({ cdsAlphaCompanyId, primaryName, logo }: CdsCompany) => ({
        label: primaryName,
        value: primaryName,
        inputValue,
        id: cdsAlphaCompanyId,
        type: TRIGGER_TYPE.company,
        logo,
      })),
    ];

    return options;
  };

  return (
    <Search
      variant={SearchVariant.Complex}
      size={SearchSizeVariant.Small}
      placeholder="Add companies, keywords, or paste a list..."
      debounceSearch
      allowSingleCharSearch={false}
      loadOptions={loadSearchOptions}
      query={searchQuery}
      onChange={handleSearch}
      onClipboardPaste={handlePaste}
      autoSearchOnChange={isAutoSearchEnabled.current}
      showLoadingAnimation={isLoading}
      isMultiLine
      optionProps={{
        inputEmphasis: ({ type }: Pick<SearchOption, "type">) => type === TRIGGER_TYPE.company,
      }}
      optionsPopoverFooterProps={{
        text: "Add multiple entries by pasting in a comma-separated list and pressing ENTER",
        startAdornment: (
          <Icon color={color.icon.secondary}>
            <Info />
          </Icon>
        ),
      }}
      components={{
        Option,
        SearchItem,
      }}
    />
  );
};

export default SearchBar;
