import { useCallback } from "react";
import { produce } from "immer";
import { kpc } from "../synthesisTypeGuards";
import { KpcItem, SynthesisModule } from "@alphasights/portal-api-client";

export const useEditKpcOperations = ({ originalModule }: { originalModule?: SynthesisModule }) => {
  const updateKpcItem = useCallback(
    (newKpcItem: KpcItem, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = kpc(draft.revisions[revisionIdx].contents);
        const kpcItemIndex = content.kpcItems.findIndex((kpcItem) => kpcItem.id === newKpcItem.id);
        if (kpcItemIndex === -1) {
          content.kpcItems.unshift(newKpcItem);
        } else {
          content.kpcItems[kpcItemIndex] = newKpcItem;
        }
      });
    },
    [originalModule]
  );

  const deleteKpcItem = useCallback(
    (kpcItem: KpcItem, revisionIdx: number) => {
      if (!originalModule) return undefined;

      return produce(originalModule, (draft) => {
        const content = kpc(draft.revisions[revisionIdx].contents);
        const kpcItemIndex = content.kpcItems.findIndex((item) => item.id === kpcItem.id);
        if (kpcItemIndex === -1) return;
        content.kpcItems.splice(kpcItemIndex, 1);
      });
    },
    [originalModule]
  );

  return {
    deleteKpcItem,
    updateKpcItem,
  };
};
