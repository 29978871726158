import styled, { th } from "@xstyled/styled-components";
import { default as Content } from "react-content-loader";
import { IconButton } from "@alphasights/alphadesign-components";

export const RoundInitialsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${th.space("layout-base05")};
  height: ${th.space("layout-base05")};
  border-radius: ${th.radius("large")};
  margin-right: ${th.space("layout-base")};
  background-color: ${th.color("background-neutral-hover")};
`;

export const StyledContent = styled(Content)`
  display: flex;
  box-sizing: border-box;
  background-color: white;
`;

export const AttendeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: ${th.space("inner-base02")};

  &:hover .hover-icon {
    opacity: 1;
    visibility: visible;
  }
`;

export const AttendeeInfo = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ isAlphasightsEmail }) => (isAlphasightsEmail ? "var(--warning-2)" : "transparent")};
  padding: ${({ isAlphasightsEmail }) => (isAlphasightsEmail ? "0 0.5rem" : "0")};
`;

export const AlertIconWrapper = styled.div`
  margin-right: ${th.space("layout-base02")};
  margin-top: ${th.space("layout-base")};
  z-index: 100;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionalToggle = styled.div`
  display: flex;
  align-items: center;
`;

export const HiddenIconButton = styled(IconButton)`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;
