import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "styled-components";

export const ToolbarActionsContainer = styled.div(() => {
  const { spacing } = useThemeTokens();

  return {
    display: "inline-flex",
    flex: "0 1 auto",
    gap: spacing.inner.base04,
    alignItems: "center",
  };
});
