import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const TextAndIconWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    gap: ${spacing.inner.base02};
    align-items: center;
  `;
});

const StyledInteractiveIcon = styled(Icon)`
  :hover {
    cursor: pointer;
  }
`;

export { TextAndIconWrapper, StyledInteractiveIcon };
