import { Company as CompanyIcon, Expert, Quote, Settings, Industry, Folder } from "@alphasights/alphadesign-icons";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";
import { BooleanTypes } from "components/Search/consts";

export const SEARCH_SUGGESTION_TYPE_ICONS: Record<number, Function> = {
  [SEARCH_SUGGESTION_TYPES.Company]: CompanyIcon,
  [SEARCH_SUGGESTION_TYPES.Keyword]: Quote,
  [SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch]: Quote,
  [SEARCH_SUGGESTION_TYPES.Colleague]: Expert,
  [SEARCH_SUGGESTION_TYPES.Market]: Industry,
  [SEARCH_SUGGESTION_TYPES.Project]: Folder,
  [BooleanTypes.AND]: Settings,
  [BooleanTypes.NOT]: Settings,
  [BooleanTypes.OR]: Settings,
  [BooleanTypes.LEFT_BRACKET]: Settings,
  [BooleanTypes.RIGHT_BRACKET]: Settings,
};

export const SEARCH_SUGGESTION_TYPE_NAMES: Record<number, string> = {
  [SEARCH_SUGGESTION_TYPES.Company]: "Company",
  [SEARCH_SUGGESTION_TYPES.Keyword]: "Keyword",
  [SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch]: "Keyword",
  [SEARCH_SUGGESTION_TYPES.Colleague]: "Colleague",
  [SEARCH_SUGGESTION_TYPES.Market]: "Market",
  [SEARCH_SUGGESTION_TYPES.Project]: "Project",
  [BooleanTypes.AND]: "Boolean",
  [BooleanTypes.NOT]: "Boolean",
  [BooleanTypes.OR]: "Boolean",
  [BooleanTypes.LEFT_BRACKET]: "Boolean",
  [BooleanTypes.RIGHT_BRACKET]: "Boolean",
};

export const SEARCH_QUERY_ITEM: Record<number, string> = {
  [SEARCH_SUGGESTION_TYPES.Company]: "COMPANY",
  [SEARCH_SUGGESTION_TYPES.Keyword]: "KEYWORD",
  [SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch]: "COMPANY",
  [SEARCH_SUGGESTION_TYPES.Colleague]: "COLLEAGUE",
  [SEARCH_SUGGESTION_TYPES.Market]: "MARKET",
  [SEARCH_SUGGESTION_TYPES.Project]: "PROJECT",
};

export const SEARCH_QUERY_ITEM_TYPE: Record<string, number> = {
  COMPANY: SEARCH_SUGGESTION_TYPES.Company,
  KEYWORD: SEARCH_SUGGESTION_TYPES.Keyword,
  COLLEAGUE: SEARCH_SUGGESTION_TYPES.Colleague,
  MARKET: SEARCH_SUGGESTION_TYPES.Market,
};

export enum SEARCH_SECTIONS {
  Project = "Project",
  ResearchLibrary = "Research Library",
  RecentSearch = "Recent Searches",
}

export const SEARCH_SUGGESTION_SECTIONS: Record<number, string> = {
  [SEARCH_SUGGESTION_TYPES.Project]: SEARCH_SECTIONS.Project,
  [SEARCH_SUGGESTION_TYPES.Company]: SEARCH_SECTIONS.ResearchLibrary,
  [SEARCH_SUGGESTION_TYPES.Keyword]: SEARCH_SECTIONS.ResearchLibrary,
  [SEARCH_SUGGESTION_TYPES.CompanyKeywordMatch]: SEARCH_SECTIONS.ResearchLibrary,
  [SEARCH_SUGGESTION_TYPES.Colleague]: SEARCH_SECTIONS.ResearchLibrary,
  [SEARCH_SUGGESTION_TYPES.Market]: SEARCH_SECTIONS.ResearchLibrary,
  [BooleanTypes.AND]: SEARCH_SECTIONS.ResearchLibrary,
  [BooleanTypes.NOT]: SEARCH_SECTIONS.ResearchLibrary,
  [BooleanTypes.OR]: SEARCH_SECTIONS.ResearchLibrary,
  [BooleanTypes.LEFT_BRACKET]: SEARCH_SECTIONS.ResearchLibrary,
  [BooleanTypes.RIGHT_BRACKET]: SEARCH_SECTIONS.ResearchLibrary,
};
