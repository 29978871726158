import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import { useMessengerContext } from "providers/MessengerProvider";
import { x } from "@xstyled/styled-components";
import { Close, Info } from "@alphasights/alphadesign-icons";
import { MessengerExpertSelect } from "components/MessengerExpertSelect";
import { Expert, RequestType, ThreadResponse } from "types";
import { WorkRequestMaxDuration } from "components/WorkRequestMaxDuration";
import { WorkRequestDeadline } from "components/WorkRequestDeadline/WorkRequestDeadline";
import { getRequestTypeName } from "pages/MessengerPage/utils";
import { useMessengerSettingsStyles } from "./MessageSettings.styles";
import { ExpertLabel } from "components/ExpertLabel";
import { useWorkRequestCredits } from "hooks/messenger/useWorkRequestCredits";

export const MessengerSettings = () => {
  const { isSettingsOpened } = useMessengerContext();
  const { container } = useMessengerSettingsStyles();

  return (
    <>
      {isSettingsOpened && (
        <Container {...container}>
          <SettingsHeader />
          <SettingsContent />
        </Container>
      )}
    </>
  );
};

const SettingsHeader = () => {
  const { setIsSettingsOpened, settingsAddExpertsMode } = useMessengerContext();
  const { headerContainer } = useMessengerSettingsStyles();

  const title = useMemo(() => (settingsAddExpertsMode ? "Add Experts" : "Message Settings"), [settingsAddExpertsMode]);

  return (
    <x.div {...headerContainer}>
      <IconButton onClick={() => setIsSettingsOpened(false)} variant="basic">
        <Close />
      </IconButton>
      <Typography variant="body-large-em">{title}</Typography>
    </x.div>
  );
};

const SettingsContent = () => {
  const { toast } = useAlphaToast();

  const [selectedExperts, setSelectedExperts] = useState<Expert[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedMaximumTimeAllowed, setSelectedMaximumTimeAllowed] = useState<number | undefined>(undefined);
  const [selectedDeadline, setSelectedDeadline] = useState<Date>();
  const [isLoading, setIsLoading] = useState(false);

  const [hasLockedExperts, setHasLockedExperts] = useState(false);
  const [hasNoWrittenWorkExperts, setHasNoWrittenWorkExperts] = useState(false);

  const {
    projectToken,
    setIsSettingsOpened,
    experts,
    adjustDeadlineExperts,
    settingsAddExpertsMode,
    selectedInbox,
    onUpdateWorkRequest,
    onUpdatedWorkRequestSuccess,
    onAddExperts,
    onAddExpertsSuccess,
  } = useMessengerContext();

  const usedExperts = useMemo(() => selectedInbox?.threads.map((thread) => thread.advisor.id) ?? [], [selectedInbox]);

  const requestType = useMemo(() => selectedInbox?.requestType, [selectedInbox]);

  const selectedExpertsThreads = useMemo(
    () =>
      selectedExperts
        .map((e) => selectedInbox?.threads.find((t) => t.advisor.id === e.id))
        .filter((t): t is ThreadResponse => t !== undefined),
    [selectedExperts, selectedInbox]
  );

  const maxTaskDuration = useMemo(
    () => Math.max(...selectedExpertsThreads.map((t) => t.workRequest?.maximumTimeAllowed ?? 0)),
    [selectedExpertsThreads]
  );

  const hasDifferentDuration = useMemo(
    () =>
      new Set(
        selectedExpertsThreads.map((t) => t.workRequest?.maximumTimeAllowed).filter((d): d is number => d !== undefined)
      ).size > 1,
    [selectedExpertsThreads]
  );

  const hasDifferentDeadline = useMemo(
    () =>
      new Set(selectedExpertsThreads.map((t) => t.workRequest?.deadline).filter((d): d is string => d !== undefined))
        .size > 1,
    [selectedExpertsThreads]
  );

  const starterMaximumTimeAllowed = useMemo(
    () =>
      !settingsAddExpertsMode && !hasDifferentDuration && selectedExpertsThreads[0]?.workRequest
        ? selectedExpertsThreads[0].workRequest.maximumTimeAllowed
        : undefined,
    [hasDifferentDuration, selectedExpertsThreads, settingsAddExpertsMode]
  );

  const starterSelectedDeadline = useMemo(
    () =>
      !settingsAddExpertsMode && !hasDifferentDeadline && selectedExpertsThreads[0]?.workRequest
        ? new Date(selectedExpertsThreads[0].workRequest.deadline)
        : undefined,
    [hasDifferentDeadline, selectedExpertsThreads, settingsAddExpertsMode]
  );

  const { isCreditsLoading, calculatePerExperts, convertCreditsToString } = useWorkRequestCredits({
    projectToken,
    shouldFetch: true,
    duration: selectedMaximumTimeAllowed ?? 0,
  });

  useEffect(() => {
    if (requestType === RequestType.WorkRequest) {
      setSelectedMaximumTimeAllowed(starterMaximumTimeAllowed);
    }
  }, [requestType, setSelectedMaximumTimeAllowed, starterMaximumTimeAllowed]);

  const expertItems = useMemo(() => {
    const lockedExperts: Expert[] = [];
    const noWrittenWorkExperts: Expert[] = [];
    const filteredExperts = experts
      .filter((expert) => {
        if (!settingsAddExpertsMode) {
          return adjustDeadlineExperts?.includes(expert.id);
        } else {
          const noWrittenWork = expert.noWrittenWork && requestType !== RequestType.CallGuide;
          const locked = expert.advisorLockState?.locked;
          const alreadyUsed = usedExperts.includes(expert.id);
          const incompatibleMessageType =
            (requestType === RequestType.Clarification && !expert.hasCompletedInteractions) ||
            (requestType === RequestType.CallGuide && expert.hasOnlyCompletedInteractions);

          if (noWrittenWork) {
            noWrittenWorkExperts.push(expert);
          }
          if (locked) {
            lockedExperts.push(expert);
          }
          return !noWrittenWork && !locked && !alreadyUsed && !incompatibleMessageType;
        }
      })
      .map((expert) => {
        return {
          key: expert.id,
          labelElement: <ExpertLabel expert={expert} showSecondaryInformation />,
          label: expert.name,
        };
      });

    setHasLockedExperts(lockedExperts.length > 0);
    setHasNoWrittenWorkExperts(noWrittenWorkExperts.length > 0);
    return filteredExperts;
  }, [experts, settingsAddExpertsMode, adjustDeadlineExperts, requestType, usedExperts]);

  useEffect(() => {
    setSelectedItems(selectedExperts.map((expert) => expert.id));
  }, [selectedExperts]);

  useEffect(() => {
    const includedExperts = experts.filter(
      (expert) => !settingsAddExpertsMode && adjustDeadlineExperts && adjustDeadlineExperts.includes(expert.id)
    );
    setSelectedExperts(includedExperts);
  }, [adjustDeadlineExperts, experts, settingsAddExpertsMode]);

  const onSelectedExpertsChange = useCallback(
    (values?: string[]) => {
      const includedExperts = experts.filter((expert) => values && values.includes(expert.id));
      setSelectedExperts(includedExperts);
    },
    [experts]
  );

  const onConfirmChanges = useCallback(() => {
    setIsLoading(true);
    if (settingsAddExpertsMode) {
      const advisors = selectedExperts.map((expert) => {
        return {
          id: expert.id,
          name: expert.name,
          multiplier: expert.alphaCircleMultiplier,
        };
      });

      onAddExperts(selectedInbox?.threads[0].id!, {
        advisors: advisors,
        maximumTimeAllowed: selectedMaximumTimeAllowed,
        deadline: selectedDeadline,
      })
        .then((response) => {
          toast.success({
            message: `The ${getRequestTypeName(requestType || RequestType.WorkRequest)} has been sent to ${
              advisors.length
            } more expert${advisors.length > 1 ? "s" : ""}.`,
          });
          onAddExpertsSuccess(response);
          setIsSettingsOpened(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const params = selectedExperts.map((expert) => {
        const thread = selectedInbox?.threads.find((thread) => thread.advisor.id === expert.id);
        return {
          token: thread!.token,
          maximumTimeAllowed: selectedMaximumTimeAllowed,
          deadline: selectedDeadline,
        };
      });

      onUpdateWorkRequest({ threads: params })
        .then((response) => {
          toast.success({
            message: `The deadline/duration has been extended for ${params.length} expert${
              params.length > 1 ? "s" : ""
            }.`,
          });
          onUpdatedWorkRequestSuccess(response);
          setIsSettingsOpened(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    settingsAddExpertsMode,
    onAddExperts,
    onAddExpertsSuccess,
    onUpdateWorkRequest,
    onUpdatedWorkRequestSuccess,
    selectedDeadline,
    selectedExperts,
    selectedInbox,
    selectedMaximumTimeAllowed,
    setIsSettingsOpened,
    toast,
    requestType,
  ]);

  const { contentContainer, buttonsContainer, creditButtonContainer } = useMessengerSettingsStyles();

  const differentSettingsText =
    hasDifferentDuration && hasDifferentDeadline
      ? "task durations / deadlines"
      : hasDifferentDuration
      ? "task durations"
      : hasDifferentDeadline
      ? "deadlines"
      : "";

  const isConfirmChangesDisabled = !(
    ((selectedDeadline && selectedMaximumTimeAllowed) || requestType !== RequestType.WorkRequest) &&
    selectedExperts.length > 0
  );

  if (isCreditsLoading || experts == null || experts.length === 0) return null;

  return (
    <x.div {...contentContainer}>
      <MessengerExpertSelect
        error={null}
        required={true}
        value={selectedItems as any}
        items={expertItems as never[]}
        onChange={onSelectedExpertsChange}
        helperText={
          settingsAddExpertsMode
            ? `Select the experts you would like to send this ${getRequestTypeName(requestType!).toLowerCase()} to.`
            : "Select the experts you would like to adjust the deadline and duration for."
        }
        open={settingsAddExpertsMode}
      />
      {settingsAddExpertsMode && (
        <NotSeeingExpertsReason hasLockedExperts={hasLockedExperts} hasNoWrittenWorkExperts={hasNoWrittenWorkExperts} />
      )}
      {requestType === RequestType.WorkRequest && (
        <x.div>
          <WorkRequestMaxDuration
            labelWithRequired
            showCallGuideOption={false}
            allowOnlyIncrease={!settingsAddExpertsMode}
            value={starterMaximumTimeAllowed}
            onChange={setSelectedMaximumTimeAllowed}
            minValue={maxTaskDuration}
          />
        </x.div>
      )}
      {requestType === RequestType.WorkRequest && (
        <x.div>
          <WorkRequestDeadline labelWithRequired value={starterSelectedDeadline} onChange={setSelectedDeadline} />
        </x.div>
      )}
      {(hasDifferentDeadline || hasDifferentDuration) && (
        <Alert data-testid="different-settings-banner" variant="info">
          {`The experts you have selected have different ${differentSettingsText}.
          By changing the ${differentSettingsText} here, all experts will be updated.`}
        </Alert>
      )}
      <x.div {...creditButtonContainer}>
        {requestType === RequestType.WorkRequest && (
          <Typography variant="body-small" color="secondary" component="span">
            Maximum charge: {convertCreditsToString(calculatePerExperts(selectedExperts))}
          </Typography>
        )}
        <x.div {...buttonsContainer}>
          <Button variant="ghost" onClick={() => setIsSettingsOpened(false)}>
            Cancel
          </Button>
          <Button
            data-testid="message-settings-confirm-changes-button"
            variant="outline"
            loading={isLoading}
            onClick={() => onConfirmChanges()}
            disabled={isConfirmChangesDisabled}
          >
            Confirm Changes
          </Button>
        </x.div>
      </x.div>
    </x.div>
  );
};

const NotSeeingExpertsReason = ({
  hasLockedExperts,
  hasNoWrittenWorkExperts,
}: {
  hasLockedExperts: boolean;
  hasNoWrittenWorkExperts: boolean;
}) => {
  if (!hasLockedExperts && !hasNoWrittenWorkExperts) {
    return null;
  }
  const tooltip = `${hasNoWrittenWorkExperts ? "Some experts have opted out of Written Work" : ""}
    ${hasLockedExperts ? "Some experts are locked" : ""}`;

  return (
    <x.div display="flex" gap="4px" alignItems="center">
      <Tooltip title={tooltip}>
        <Icon size="small" color="secondary">
          <Info />
        </Icon>
      </Tooltip>
      <Typography color="secondary" variant="body-small">
        Why am I not seeing all experts?
      </Typography>
    </x.div>
  );
};
