import { CoreCompetitor } from "../../../types/competitors";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import OverviewDetailsRow from "../../Overview/OverviewDetails/OverviewDetailsRow";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";
import RevenueDetails from "../../Overview/OverviewDetails/RevenueDetails";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import TrendPill from "pages/AlphaNowPage/primers/components/TrendPill/TrendPill";
import { CompetitorGrowth } from "pages/AlphaNowPage/primers/CompanyPrimer/sections/competitor-dynamics/Growth";
import { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";

const CoreCompetitorDetails = ({ competitor }: { competitor: CoreCompetitor }) => {
  const {
    spacing: { inner },
    font,
    color: { text },
  } = useThemeTokens();

  const { currency } = usePrimersStore();

  const {
    logoLink,
    companyName,
    fullTimeEquivalent: { min: fteMin, max: fteMax, year: fteYear },
    revenue,
    ownership: { ownershipType, vcBackedSeries, ownershipYear, ownershipQuarter, companyName: ownershipCompany },
    overlap,
    markets,
    commentary,
  } = competitor;

  const getOwnershipValue = () => {
    if (ownershipType.value === "VC backed") {
      return (
        <x.div display="flex" columnGap={inner.base}>
          <x.div display="flex">
            <CitationTextWrapper value={ownershipType} />
          </x.div>
          {Boolean(vcBackedSeries?.value) && (
            <x.div display="flex">
              {"("}
              <CitationTextWrapper value={vcBackedSeries} />
              {")"}
            </x.div>
          )}
        </x.div>
      );
    }

    if (
      ownershipType.value === "Publicly held" ||
      ownershipType.value === "Privately held" ||
      !ownershipCompany.value ||
      !ownershipQuarter ||
      !ownershipYear
    ) {
      return <CitationTextWrapper value={ownershipType} />;
    }

    return (
      <x.div display="flex" columnGap={inner.base}>
        <x.div display="flex">
          <CitationTextWrapper value={ownershipType} />,
        </x.div>
        <x.div display="flex">
          {"("}
          <CitationTextWrapper value={ownershipCompany} />,
        </x.div>
        <x.div
          display="flex"
          fontSize={font.size["03"]}
          alignItems="center"
        >{`Q${ownershipQuarter} ${ownershipYear})`}</x.div>
      </x.div>
    );
  };

  const coreCompetitorsRows = [
    {
      label: "FTEs",
      value: fteMax.value ? `${fteMin.value} to ${fteMax.value} (${fteYear})` : `${fteMin.value}+ (${fteYear})`,
    },
    {
      label: revenue.min.value && revenue.max.value ? "Revenue" : null,
      value: <RevenueDetails revenue={revenue} currency={currency} />,
    },
    {
      label: "Ownership",
      value: getOwnershipValue(),
    },
    {
      label: "Overlap",
      value: <CitationTextWrapper value={overlap} />,
    },
    {
      label: "Markets",
      value: (
        <x.div display="flex" gap={inner.base02} flexWrap="wrap">
          {markets.map((market, index) => {
            const [marketName, tooltip] = getTruncatedTextAndTooltip(market.name, 20);

            return (
              <x.div key={index}>
                <TrendPill trend={market.trend.value as CompetitorGrowth} label="" tooltip={tooltip as string}>
                  <CitationTextWrapper
                    value={marketName}
                    style={{ fontSize: font.size["02"], lineHeight: font.lineHeight["02"] }}
                  />
                </TrendPill>
              </x.div>
            );
          })}
        </x.div>
      ),
    },
  ];

  return (
    <>
      <x.div display="flex" gap={inner.base02} pb={inner.base03}>
        <PrimerCompanyLogo size={inner.base06} src={logoLink} padding="0" />
        <Typography variant="h3" color={text.strong._} shouldCapitalizeHeadline={false}>
          {companyName.value}
        </Typography>
      </x.div>
      <x.div display="flex" gap={inner.base03} flexDirection="column" pb={inner.base04}>
        {coreCompetitorsRows.map((row, index) => {
          const hasChildrenValue = typeof row.value === "object";

          return hasChildrenValue ? (
            !row.label ? null : (
              <OverviewDetailsRow key={index} label={row.label ?? ""} labelWidth="116px" spaceBetween={inner.base06}>
                {row.value}
              </OverviewDetailsRow>
            )
          ) : (
            <OverviewDetailsRow
              key={index}
              label={row.label ?? ""}
              labelWidth="116px"
              value={row.value as string}
              spaceBetween={inner.base06}
            />
          );
        })}
      </x.div>
      <CitationTextWrapper value={commentary.content} />
    </>
  );
};

export default CoreCompetitorDetails;
