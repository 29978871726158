import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { HitOrigin } from "@alphasights/portal-api-client";

type OnToggleStar = (interaction: Interaction, origin: HitOrigin, newValue?: boolean) => Promise<boolean>;

interface StarExpertProviderState {
  onToggleStar: OnToggleStar;
  isLoading: (interactionId: string) => boolean;
}

interface StarExpertProviderProps {
  children: ReactNode;
  onToggleStar: (interactionId: string, isStarred: boolean, origin: HitOrigin) => Promise<void>;
}

export const StarExpertContext = createContext<StarExpertProviderState | null>(null);

export const StarExpertProvider = ({ children, onToggleStar, ...props }: StarExpertProviderProps) => {
  const [interactionIdsLoading, setInteractionIdsLoading] = useState<string[]>([]);

  const toggleStar: OnToggleStar = useCallback(
    (interaction, origin, newValueInput) => {
      setInteractionIdsLoading((prev) => [...prev, interaction.id]);

      const interactionId = interaction.id;
      const newValue = newValueInput ?? !interaction.starred;

      return onToggleStar(interactionId, newValue, origin)
        .then(() => newValue)
        .finally(() => {
          setInteractionIdsLoading((prev) => prev.filter((id) => id !== interaction.id));
        });
    },
    [onToggleStar]
  );

  const value: StarExpertProviderState = {
    onToggleStar: toggleStar,
    isLoading: (interactionId) => interactionIdsLoading.includes(interactionId),
  };

  return (
    <StarExpertContext.Provider value={value} {...props}>
      {children}
    </StarExpertContext.Provider>
  );
};

export const useStarExpertContext = () => {
  const context = useContext(StarExpertContext);
  if (!context) {
    throw new Error("useStarExpertContext must be used within a StarExpertProvider");
  }
  return context;
};
