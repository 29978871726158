import { useCallback, useEffect, useState } from "react";
import { SchedulingSlots } from "./SchedulingSlots";
import { FormattedDateTime, localTimezone, useTimezone } from "../../providers/TimezoneProvider";
import { ResponsiveModal } from "components/ResponsiveModal";
import { x } from "@xstyled/styled-components";
import { Button, Icon as IconAds, TextField, Typography } from "@alphasights/alphadesign-components";
import { CalendarAvailable, CalendarToday } from "@alphasights/alphadesign-icons";
import { useSchedulingModalStyles } from "./SchedulingModal.styles";
import { useAvailabilitySlots } from "pages/InteractionPage/sections/Availability/AvailabilitySelector";
import { useCallDurationOptions } from "./CallDurationSelector";
import { differenceInMinutes, isSameMinute } from "date-fns";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { formatSlot } from "components/AvailabilitySelector";
import { ErrorBanner } from "components/Banner";
import { validateEmail } from "pages/Login/validators";
import { ButtonWithMargin } from "@alphasights/client-portal-shared";
import { HitOrigin } from "@alphasights/portal-api-client";

const splitEmailsByCommaOrSemicolon = (email) => {
  return email.split(/(?:,| |;)+/);
};

const validateEmails = (email) => {
  return splitEmailsByCommaOrSemicolon(email).some((e) => !!validateEmail(e));
};

export const SchedulingModal = localTimezone(
  ({
    advisorAvailability = [],
    allowNonHourAutoBook,
    allInteractions,
    clientAvailability,
    id,
    isOpen,
    onClose,
    onSubmit,
    onSubmitInvitation,
    previousAdvisorshipId,
    requestedScheduleDate,
    autoBookEnabled,
    userEmail = "",
    oneHourMinimum,
  }) => {
    const tz = useTimezone();
    const { isMobile } = useCheckScreen();
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [isRunning, setRunning] = useState(false);
    const [email, setEmail] = useState(userEmail);
    const [emailError, setEmailError] = useState(false);
    const [slots, setSlots] = useState([]);
    const [mutualSlots, setMutualSlots] = useState([]);
    const [selectedBulkAvailability, setSelectedBulkAvailability] = useState(undefined);
    const [error, setError] = useState(false);
    const durationOptions = useCallDurationOptions({ allowNonHourAutoBook, isFollowUpCall: !!previousAdvisorshipId });
    const [expectedDuration, setExpectedDuration] = useState(60);
    const { selectedTimeStyles, modalStyles, alertContainerStyles } = useSchedulingModalStyles({
      selectedTime: selectedSlots.length > 0,
    });

    const { availabilities, mutualAvailabilites, refetch } = useAvailabilitySlots({
      allInteractions,
      clientAvailability,
      advisorAvailability,
      interactionId: id,
      timezone: tz,
    });

    useEffect(() => {
      if (isOpen) {
        setSlots(availabilities);
        setMutualSlots(mutualAvailabilites);
        setSelectedSlots([]);
        setEmail(userEmail);
        setSelectedBulkAvailability(undefined);
        setError(false);
        setEmailError(false);
        refetch();

        if (requestedScheduleDate) {
          const preSelectedSlot = availabilities
            .concat(mutualAvailabilites)
            .find((s) => isSameMinute(s.startsAt, requestedScheduleDate));
          onSelectSlot(preSelectedSlot);
        }
      }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const isSlotSelected = useCallback(
      (slot) =>
        isSameMinute(slot?.startsAt, selectedSlots && selectedSlots[0]?.startsAt) ||
        isSameMinute(slot?.startsAt, selectedBulkAvailability?.startsAt),
      [selectedBulkAvailability, selectedSlots]
    );

    const isSlotLongEnough = useCallback(
      ({ startsAt, endsAt }, duration = expectedDuration) => differenceInMinutes(endsAt, startsAt) >= duration,
      [expectedDuration]
    );

    const onSelectSlot = useCallback(
      ({ startsAt, endsAt } = {}, clearBulkSelected = true, selectedDuration = expectedDuration) => {
        if (!startsAt || !endsAt) return;
        const duration = differenceInMinutes(endsAt, startsAt);
        if (duration > selectedDuration) {
          setSelectedSlots([]);
          setSelectedBulkAvailability({ startsAt, endsAt });
        } else {
          setSelectedSlots([{ startsAt, endsAt }]);
          if (clearBulkSelected) {
            setSelectedBulkAvailability(undefined);
          }
        }
        setError(false);
      },
      [expectedDuration]
    );

    const onChangeExpectedDuration = useCallback(
      (duration) => {
        setExpectedDuration(duration);
        const currentSelectedSlot = slots
          .concat(mutualSlots)
          .find((s) => isSlotSelected(s) && isSlotLongEnough(s, duration));
        if (currentSelectedSlot) {
          onSelectSlot(currentSelectedSlot, true, duration);
        } else {
          setSelectedSlots([]);
          setSelectedBulkAvailability(undefined);
        }
      },
      [
        isSlotSelected,
        isSlotLongEnough,
        setSelectedSlots,
        setSelectedBulkAvailability,
        onSelectSlot,
        mutualSlots,
        slots,
      ]
    );

    const onSubmitSchedule = useCallback(() => {
      const noSelectedSlot = selectedSlots.length === 0;
      const invalidEmail = autoBookEnabled && validateEmails(email);

      setError(noSelectedSlot);
      setEmailError(invalidEmail);
      if (noSelectedSlot || invalidEmail) {
        return;
      }

      setRunning(true);
      onSubmit({ mode: "slots", slots: selectedSlots, timezone: tz.currentTimezone }, HitOrigin.schedulingModal).then(
        () => {
          autoBookEnabled
            ? onSubmitInvitation({
                id,
                attendees: splitEmailsByCommaOrSemicolon(email).map((e) => ({
                  emailAddress: e,
                })),
              }).finally(() => setRunning(false))
            : setRunning(false);
        }
      );
    }, [onSubmit, selectedSlots, tz.currentTimezone, email, id, onSubmitInvitation, autoBookEnabled]);

    const SelectedSlot = () => {
      const { startsAt } = selectedSlots[0];

      return (
        <>
          <FormattedDateTime date={startsAt} format={"eeee d MMM,"} /> {formatSlot(tz, selectedSlots[0])}{" "}
          <FormattedDateTime date={startsAt} format={"(O)"} />
        </>
      );
    };

    return (
      <ResponsiveModal
        open={isOpen}
        title="Schedule interaction"
        onClose={onClose}
        {...modalStyles}
        variant="drawer"
        data-testid="scheduling-modal"
        slotWidth="600px"
        tertiaryButton={
          <x.div {...selectedTimeStyles}>
            <IconAds>
              <CalendarToday />
            </IconAds>
            <Typography>
              {selectedSlots.length > 0 ? <SelectedSlot /> : <>Select Time ({tz.format(new Date(), "OOO")})</>}
            </Typography>
          </x.div>
        }
        secondaryButton={
          !isMobile && (
            <ButtonWithMargin variant="ghost" onClick={onClose} data-testid="cancel-button">
              Cancel
            </ButtonWithMargin>
          )
        }
        primaryButton={
          <Button
            onClick={onSubmitSchedule}
            loading={isRunning}
            startIcon={<CalendarAvailable />}
            data-testid="schedule-button"
          >
            Schedule
          </Button>
        }
        zIndex={10}
      >
        <x.div>
          <SchedulingSlots
            slots={slots}
            mutualSlots={mutualSlots.map((s) => ({
              ...s,
              selected: isSlotSelected(s),
            }))}
            expectedDuration={expectedDuration}
            oneHourMinimum={oneHourMinimum}
            onSelectSlot={onSelectSlot}
            selectedBulkAvailability={selectedBulkAvailability}
            onChangeExpectedDuration={onChangeExpectedDuration}
            durationOptions={durationOptions}
            durationOptionValue={expectedDuration}
          />
          {autoBookEnabled && (
            <InviteEmail
              value={email}
              onChange={(e) => {
                const newValue = e.target.value;
                setEmailError(newValue && validateEmails(newValue));
                setEmail(newValue);
              }}
              error={emailError}
            />
          )}
          {error && (
            <x.div {...alertContainerStyles}>
              <ErrorBanner>Please select a time to schedule this interaction.</ErrorBanner>
            </x.div>
          )}
        </x.div>
      </ResponsiveModal>
    );
  }
);

const InviteEmail = ({ value, onChange, error }) => {
  const { emailContainerStyles, emailStyles, emailLabelStyles } = useSchedulingModalStyles();

  return (
    <x.div {...emailContainerStyles}>
      <TextField
        label={
          <x.div {...emailLabelStyles}>
            <Typography component="span">Email addresses</Typography>
            <Typography component="span" color="danger">
              *
            </Typography>
          </x.div>
        }
        placeholder="Enter the email addresses separed by comma or semicolon (required)"
        data-testid="invite-email-field"
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={error && "Please enter a valid email."}
        {...emailStyles}
      />
    </x.div>
  );
};
