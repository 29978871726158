import { useEffect, useRef } from "react";
import { Icon, IconButton, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { ContentContainer } from "@alphasights/client-portal-shared";
import { StarSelected, StarUnselected } from "@alphasights/alphadesign-icons";
import { ExternalAlphaCompany } from "models/ExternalAlphaCompany";
import { useProjectLauncherContext } from "providers/ProjectLauncherProvider";
import { CompanySearch } from "../CompanySearch";
import styled, { th } from "@xstyled/styled-components";

type CompaniesOfInterestProps = {
  validate: boolean;
  onValidate: (fieldEl?: HTMLElement, error?: string) => void;
};

export const CompaniesOfInterest = ({ validate, onValidate }: CompaniesOfInterestProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { companies, mainCompany, onMainCompanyChange } = useProjectLauncherContext();
  const errorMessage = !companies.length ? "Company is required" : undefined;

  useEffect(
    function validateField() {
      onValidate(ref.current ?? undefined, errorMessage);
    },
    [errorMessage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  function scrollIntoViewWhenCompaniesChanged(companies: ExternalAlphaCompany[]) {
    if (companies?.length) {
      ref.current?.scrollIntoView?.({ behavior: "smooth" });
    }
  }

  const MarkAsMainButton = ({ company }: { company: ExternalAlphaCompany }) => {
    const isMainCompany = mainCompany?.name === company?.name;
    if (companies.length <= 1) return null;

    return (
      <Tooltip title={isMainCompany ? "Main company of interest" : "Mark as main company of interest"}>
        <IconButton
          testId="mark-as-main-button"
          variant="outline"
          size="small"
          onClick={() => onMainCompanyChange(isMainCompany ? undefined : company)}
        >
          {isMainCompany ? (
            <Icon dataAttributes={{ "data-testid": "star-selected" }}>
              <StarSelected />
            </Icon>
          ) : (
            <Icon dataAttributes={{ "data-testid": "star-unselected" }}>
              <StarUnselected />
            </Icon>
          )}
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <ContentContainerStyled>
      <CompanySearch
        ref={ref}
        error={validate ? errorMessage : undefined}
        onChange={scrollIntoViewWhenCompaniesChanged}
        label={
          <>
            <Typography variant="body-em" component="span">
              Company(s) of Interest
            </Typography>
            <Typography component="span" color="danger">
              {" "}
              *
            </Typography>
          </>
        }
        selectedCompanyRightSlot={MarkAsMainButton}
        allowAddingNonExistingCompanies
      />
    </ContentContainerStyled>
  );
};

const ContentContainerStyled = styled(ContentContainer)`
  border-top: transparent;
  border-bottom: transparent;
  padding: ${th.space("inner-base06")} ${th.space("inner-base06")} ${th.space("inner-base")};
`;
