import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

export const LogoWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    padding: ${spacing.layout.base03} ${spacing.layout.base04}; 
  `;
});

export const PulseBarWrapper = styled.div(() => {
  const { spacing, color } = useThemeTokens();
  return `
    & > div > div {
      height: ${spacing.inner.base};
      border-radius: 0;
      & > div:first-child {
        background: ${color.border.divider};
      }
    }
  `;
});
