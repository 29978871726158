import { useEffect, useMemo } from "react";
import { AlphaGPTProvider, useAlphaGPTContext } from "providers/AlphaGPTProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import styled from "@xstyled/styled-components";
import { AlphaGPTSidebar, AlphaGPTSidebarSkeleton } from "pages/AlphaGPTPage/components/AlphaGPTSidebar";
import {
  AlphaGPTConversation,
  AlphaGPTConversationSkeleton,
} from "pages/AlphaGPTPage/components/AlphaGPTConversation/AlphaGPTConversation";
import { ProjectAlphaGPTProvider, useProjectAlphaGPTContext } from "providers/ProjectAlphaGPTProvider";
import { Sidebar } from "views/DeliverablesView/Sidebar/Sidebar";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { x } from "@xstyled/styled-components";
import { Button, Typography } from "@alphasights/alphadesign-components";
import { useProjectAlphaGPTPageStyles } from "./ProjectAlphaGPTPage.styles";
import useModal from "hooks/useModal";
import { alphaGPTService } from "services/alphaGPTService";
import { PurchaseAlphaGPTAccessModal } from "./PurchaseAlphaGPTAccessModal/PurchaseAlphaGPTAccessModal";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import useContentAccessLevel from "pages/AlphaNowPage/hooks/useContentAccessLevel";
import { AlphaNowContentContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";

export const ProjectAlphaGPTPage = ({ token, interactions }: { token: string; interactions?: Interaction[] }) => {
  const trackUserAction = useTrackUserAction();

  useEffect(() => {
    trackUserAction.logHit({
      origin: HitOrigin.projectAlphaGptPage,
      action: HitAction.projectAlphaGptViewPage,
      projectToken: token,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contentAccessLevel = useContentAccessLevel();

  return (
    <Container>
      <ProjectAlphaGPTProvider projectToken={token} service={alphaGPTService} interactions={interactions}>
        <WithAlphaGPTProvider>
          <AlphaNowContentContext.Provider value={contentAccessLevel}>
            <AlphaGPTPageContent />
          </AlphaNowContentContext.Provider>
        </WithAlphaGPTProvider>
      </ProjectAlphaGPTProvider>
    </Container>
  );
};

const WithAlphaGPTProvider = ({ children }: any) => {
  const projectAlphaGPTContext = useProjectAlphaGPTContext();

  return <AlphaGPTProvider {...projectAlphaGPTContext}>{children}</AlphaGPTProvider>;
};

const AlphaGPTPageContent = () => {
  const {
    selectedContent,
    setSelectedContent,
    selectedTranscriptRequest,
    setSelectedTranscriptRequest,
    isAlphaGPTPurchaseExpired,
    hasAlphaGPTPurchase,
    interactions,
    isLoadingAlphaGPTPurchase,
    isLoadingConversations,
    selectedCitation,
  } = useAlphaGPTContext();
  const { isUltraWide } = useCheckScreen();

  const { isVisible: isPurchaseAlphaGPTAccessModal, onOpen: onOpenModal, onClose: onCloseModal } = useModal();

  const onCloseContentSidebar = () => {
    setSelectedContent && setSelectedContent(undefined);
    setSelectedTranscriptRequest && setSelectedTranscriptRequest(undefined);
  };

  const updateDeliverableSuggestion = (contentId: string, changes: any) => {
    if (selectedContent?.id === contentId) {
      setSelectedContent &&
        setSelectedContent({
          ...selectedContent,
          ...changes,
        });
    }
  };

  const selectedInteraction = useMemo(
    () =>
      selectedTranscriptRequest &&
      interactions?.find((interaction) =>
        interaction.recordings
          .flatMap((recording) => recording.transcriptRequests.map((tr) => tr.id))
          .includes(selectedTranscriptRequest.id)
      ),
    [interactions, selectedTranscriptRequest]
  );

  const isContentFlyoutOpen = selectedContent !== undefined || selectedTranscriptRequest !== undefined;

  return isLoadingAlphaGPTPurchase || isLoadingConversations ? (
    <x.div data-testid="project-alpha-gpt-page-loading" display="flex" flexGrow="1">
      <AlphaGPTSidebarSkeleton />
      <AlphaGPTConversationSkeleton />
    </x.div>
  ) : (
    <x.div data-testid="project-alpha-gpt-page-loaded" display="flex" flexGrow="1">
      {(!isContentFlyoutOpen || isUltraWide) && <AlphaGPTSidebar />}
      <AlphaGPTConversation />
      {isContentFlyoutOpen && (
        <Sidebar
          isOpen={isContentFlyoutOpen}
          onClose={onCloseContentSidebar}
          content={selectedContent}
          fragmentIds={selectedCitation?.fragmentIds}
          interaction={selectedInteraction}
          transcriptRequest={selectedTranscriptRequest}
          updateDeliverableSuggestion={updateDeliverableSuggestion}
        />
      )}
      {isAlphaGPTPurchaseExpired && <AccessExpiredBanner onRenewAccess={onOpenModal} />}
      {!hasAlphaGPTPurchase && <PurchaseAccessBanner onPurchaseAccess={onOpenModal} />}
      {isPurchaseAlphaGPTAccessModal && <PurchaseAlphaGPTAccessModal onCloseModal={onCloseModal} />}
    </x.div>
  );
};

const AccessExpiredBanner = ({ onRenewAccess }: { onRenewAccess: () => void }) => {
  const { noAccessBanner, noAccessBannerContent } = useProjectAlphaGPTPageStyles();
  return (
    <x.div {...noAccessBanner} data-testid="access-expired-banner">
      <x.div {...noAccessBannerContent}>
        <BannerTitle variant="h1">Your Search access has expired</BannerTitle>
        <Typography>
          If you would like to enhance your research using Search, please purchase access below. This will enable you to
          query your project's transcripts and gain access to our proprietary expert content.
        </Typography>
        <Button size="small" onClick={onRenewAccess}>
          Renew Access
        </Button>
      </x.div>
    </x.div>
  );
};

const PurchaseAccessBanner = ({ onPurchaseAccess }: { onPurchaseAccess: () => void }) => {
  const { project } = useCurrentProjectContext();
  const { noAccessBanner, noAccessBannerContent } = useProjectAlphaGPTPageStyles();
  return (
    <x.div {...noAccessBanner} data-testid="purchase-access-banner">
      <x.div {...noAccessBannerContent}>
        <BannerTitle variant="h1">Purchase Search Access</BannerTitle>
        <Typography>
          If you would like to enhance your research using Search, please purchase access below. This will enable you to
          query your project's transcripts and gain access to our proprietary expert content. If you are interested in
          asking Search a trial question, please reach out to {project?.lead.name}.
        </Typography>
        <Button size="small" onClick={onPurchaseAccess}>
          Purchase Access
        </Button>
      </x.div>
    </x.div>
  );
};

const BannerTitle = styled(Typography)`
  text-transform: none;
  margin-bottom: 0;
`;

const Container = styled.div(() => ({
  display: "flex",
  flex: "1 1 auto",
  "flex-direction": "row",
  "min-height": "0",
}));
