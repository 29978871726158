import styled from "styled-components";
import { useThemeTokens, Typography, Popover } from "@alphasights/alphadesign-components";

const StyledTypography = styled(Typography)(({ height }: { height: string }) => {
  const { color, spacing } = useThemeTokens();
  return {
    paddingLeft: spacing.inner.base04,
    paddingBottom: spacing.inner.base,
    color: color.text.assistive,
    display: "flex",
    alignItems: "flex-end",
    height,
  };
});

const PopoverContentContainer = styled.div(({ maxHeight, paddingTop }: { maxHeight: string; paddingTop: string }) => ({
  paddingTop,
  maxHeight,
  overflow: "auto",
  paddingLeft: 0,
}));

const StyledPopover = styled(Popover)`
  .body-content {
    padding: 1px;
  }
`;

export { StyledTypography, PopoverContentContainer, StyledPopover };
