import { Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const GenerativeAiDisclaimer = ({ ...props }) => (
  <x.div display="flex" justifyContent="flex-end" {...props}>
    <Typography color="assistive" variant="body-small-em">
      Summarized by Generative AI
    </Typography>
  </x.div>
);
