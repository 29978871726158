import styled, { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

export const Wrapper = styled(x.div)(() => {
  const { color } = useThemeTokens();

  return `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 500px;
    background-color: ${color.background.inverse};
    border-right: 1px solid ${color.border.divider};
  `;
});
