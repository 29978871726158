import { useState } from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { Info } from "@alphasights/alphadesign-icons";
import Chart from "react-apexcharts";
import SegmentationTabs from "./CustomersInfo/SegmentationTabs";
import { useMarketPrimersStore } from "../../state/store";
import { LabeledIcon, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import ChartLegend from "../../components/ChartLegend/ChartLegend";
import { useGetChartColors } from "pages/AlphaNowPage/primers/hooks";

type SegmentationSegmentProps = {
  title: string;
  segments: Segment[];
};

const SegmentationSegment = ({ title, segments }: SegmentationSegmentProps) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const { overview } = useMarketPrimersStore();
  const { getChartColors } = useGetChartColors({
    numberOfBars: segments.length,
  });

  const [selectedChartSegment, setSelectedChartSegment] = useState<number | null>(null);

  const otherLabel = "Other";

  const chartOptions = {
    chart: {
      id: "SegmentationChart",
      toolbar: {
        show: false,
      },
      parentHeightOffset: 0,
      events: {
        dataPointSelection: function (event: any, chartContext: any, config: any) {
          setSelectedChartSegment(config.dataPointIndex);
        },
      },
      animations: {
        enabled: false,
      },
    },
    colors: getChartColors(selectedChartSegment ?? 0),
    labels: segments.map((segment) => segment.name.value ?? ""),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = segments
    .filter((segment) => segment.marketShareSplit.value !== undefined)
    .map((segment) => segment.marketShareSplit.value ?? 0);

  const onHighlightChartSegment = (index: number): void => {
    setSelectedChartSegment(index);
    ApexCharts.exec("SegmentationChart", "toggleDataPointSelection", index);
  };

  if (segments.length === 0) return null;

  const getSecondColumnLabel = () => {
    if (segments.length === 0) return "Split";
    const { marketShareQuarter, marketShareYear } = segments[0];

    if (marketShareQuarter.value && marketShareYear.value) {
      return `Share (Q${marketShareQuarter.value}, ${marketShareYear.value})`;
    }

    if (marketShareQuarter.value) {
      return `Share (Q${marketShareQuarter.value})`;
    }

    if (marketShareYear.value) {
      return `Share (${marketShareYear.value})`;
    }

    return "Share";
  };

  return (
    <div>
      <x.div w="100%">
        <x.div display="flex" flexDirection="column" gap={inner.base03} pb={inner.base05}>
          <PrimerFieldTitle text={title} />
          <LabeledIcon labelText="Click a chart section to view the profile of a sub-segment" iconItem={<Info />} />
        </x.div>

        <x.div display="flex" gap={inner.base12}>
          <Chart options={chartOptions} series={series} type="donut" height={300} width={300} />
          <x.div display="flex" flex="1 1 auto" justifyContent="space-between" alignItems="center">
            <ChartLegend
              segmentInfo={segments.map((entry, index) => ({
                leader: entry.other ? otherLabel : entry.name.value,
                share: entry.marketShareSplit.value,
                color: getChartColors(null)[index],
              }))}
              onClick={onHighlightChartSegment}
              firstColumnLabel={title}
              secondColumnLabel={getSecondColumnLabel()}
            />
          </x.div>
        </x.div>
        <SegmentationTabs
          currency={overview.currency}
          segments={segments}
          segmentLabels={segments.map(({ name, other }) => (other ? otherLabel : name.value) ?? "")}
          chartColors={getChartColors(null)}
          onClick={onHighlightChartSegment}
          selectedChartSegment={selectedChartSegment}
        />
      </x.div>
    </div>
  );
};

export default SegmentationSegment;
