import styled, { th } from "@xstyled/styled-components";
import * as React from "react";

const HideableCellContainer = styled.div<{ hidden: boolean }>`
  display: contents;
  color: ${({ hidden }) => (hidden ? th.color("text-disabled") : th.color("text-strong"))};
  pointer-events: ${({ hidden }) => (hidden ? "none" : "auto")};
`;

export const HideableCell = ({
  hidden,
  value,
  children,
}: {
  hidden: boolean;
  value?: string;
  children?: React.ReactNode;
}) => {
  return <HideableCellContainer hidden={hidden}>{children || value}</HideableCellContainer>;
};
