import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

const Wrapper = styled(x.div)(() => {
  const { color, shape, spacing } = useThemeTokens();
  return {
    height: spacing.layout.base08,
    minHeight: spacing.layout.base08,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: color.background.neutral.default,
    border: `${shape.border.width.lg} dashed ${color.border.neutral.default}`,
    borderRadius: shape.border.radius.mediumLarge,
    "&:hover": {
      cursor: "pointer",
      background: color.background.neutral.hover,
    },
  };
});

const StyledTypography = styled(Typography)(() => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    gap: spacing.inner.base02,
    alignItems: "center",
  };
});

export { Wrapper, StyledTypography };
