import { memo } from "react";
import { Divider, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { CitationWrapper, DotLabel, PrimerFieldTitle } from "pages/AlphaNowPage/primers/components";
import { useMarketPrimersStore } from "../../state/store";
import { Citation } from "components/CitationContext/Citation";
import SensitiveDataContainer from "pages/AlphaNowPage/helpers/SensitiveDataContainer";
import { hasSensitiveData } from "pages/AlphaNowPage/utils";
import { CURRENCY_SYMBOL } from "pages/AlphaNowPage/primers/utils/constants";
import { currencyRendererWithSymbol } from "components/CitationContext/PropertyRenderers";

export type SizeAndGrowthExpert = {
  id: string;
  name: string;
  relevantEmployment: {
    company: string;
    position: string;
    dates: string;
  };
  currentMarketSize: CitableValue<number>;
  currentMarketSizeMin: CitableValue<number>;
  currentMarketSizeMax: CitableValue<number>;
  previousGrowth: CitableValue<number>;
  predictedGrowth: CitableValue<number>;
  commentary: CitableValue<string>;
};

type SizeAndGrowthTabContentProps = {
  selectedTab: number;
  tabsData: SizeAndGrowthExpert[];
  expertColors: string[];
};

const SizeAndGrowthTabContent = ({ selectedTab, tabsData, expertColors }: SizeAndGrowthTabContentProps) => {
  const { color, spacing, typography } = useThemeTokens();
  const { overview } = useMarketPrimersStore();
  const tableHeaders = [
    "Expert",
    "Relevant Employment",
    "Current Market Size",
    "Previous Growth (3YR CAGR)",
    "Predicted Growth (3YR CAGR)",
  ];
  const currencySymbol = CURRENCY_SYMBOL[overview.currency];
  const CurrencyRenderer = currencyRendererWithSymbol(currencySymbol);

  if (selectedTab === 0)
    return (
      <Typography component="div" py={spacing.inner.base04} display="flex" gap="12px">
        <x.table w="100%" data-testid="overviewTabEntries">
          <thead>
            <tr>
              {tableHeaders.map((label) => (
                <th>
                  <PrimerFieldTitle text={label} variant="navigation" />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tabsData.map(
              (
                {
                  currentMarketSize,
                  currentMarketSizeMin,
                  currentMarketSizeMax,
                  name,
                  predictedGrowth,
                  previousGrowth,
                  relevantEmployment,
                },
                index
              ) => {
                return (
                  <>
                    <tr>
                      <td colSpan={5}>
                        <Divider />
                      </td>
                    </tr>
                    <tr data-testid={`overviewTabEntry-${index}`}>
                      <x.td whiteSpace="nowrap" paddingRight={spacing.inner.base02}>
                        <DotLabel tableEntry dotColor={expertColors[index]} label={name} />
                      </x.td>
                      <td>
                        <Typography component="span" variant="body-em">
                          {`${relevantEmployment.company} -  `}
                        </Typography>
                        <Typography component="span">{relevantEmployment.position}</Typography>
                        <br />
                        <Typography {...typography.body.small} color={color.text.secondary}>
                          {relevantEmployment.dates}
                        </Typography>
                      </td>
                      <td>
                        <SensitiveDataContainer isSensitive={hasSensitiveData(currentMarketSize)}>
                          {currentMarketSizeMin.value && currentMarketSizeMax.value ? (
                            <>
                              <Citation value={{ ...currentMarketSizeMin }} renderer={CurrencyRenderer} />
                              <span>&nbsp;to&nbsp;</span>
                              <Citation value={{ ...currentMarketSizeMax }} renderer={CurrencyRenderer} />
                            </>
                          ) : (
                            <Citation
                              value={{
                                ...currentMarketSize,
                                value: "—",
                              }}
                            />
                          )}
                        </SensitiveDataContainer>
                      </td>
                      <td>
                        <SensitiveDataContainer isSensitive={hasSensitiveData(previousGrowth)}>
                          <Citation
                            value={{
                              ...previousGrowth,
                              value: previousGrowth.value ? `${previousGrowth.value}%` : "—",
                            }}
                          />
                        </SensitiveDataContainer>
                      </td>
                      <td>
                        <SensitiveDataContainer isSensitive={hasSensitiveData(predictedGrowth)}>
                          <Citation
                            value={{
                              ...predictedGrowth,
                              value: predictedGrowth.value ? `${predictedGrowth.value}%` : "—",
                            }}
                          />
                        </SensitiveDataContainer>
                      </td>
                    </tr>
                  </>
                );
              }
            )}
          </tbody>
        </x.table>
      </Typography>
    );

  if (selectedTab > 0) {
    const tabData = tabsData[selectedTab - 1];

    return (
      <x.div py={spacing.inner.base04} display="flex" flexDirection="column" gap="12px" data-testid="tabEntries">
        <CitationWrapper label="RELEVANT EMPLOYMENT" labelWidth="250px" value={tabData.relevantEmployment.company}>
          <x.div>
            <x.span {...typography.body.em}>{tabData.relevantEmployment.company}</x.span>
            <x.span {...typography.body.regular}>
              {tabData.relevantEmployment.position.length > 0 ? ` - ${tabData.relevantEmployment.position}` : ""}
            </x.span>
          </x.div>
          <Typography {...typography.body.small} color={color.text.secondary}>
            {tabData.relevantEmployment.dates}
          </Typography>
        </CitationWrapper>
        <CitationWrapper value={tabData.currentMarketSize} label="CURRENT MARKET SIZE" labelWidth="250px">
          {tabData.currentMarketSize.value ? (
            <>
              <Citation value={{ ...tabData.currentMarketSizeMin }} renderer={CurrencyRenderer} />
              <span>&nbsp;to&nbsp;</span>
              <Citation value={{ ...tabData.currentMarketSizeMax }} renderer={CurrencyRenderer} />
            </>
          ) : (
            "—"
          )}
        </CitationWrapper>
        <CitationWrapper value={tabData.previousGrowth} label="PREVIOUS GROWTH (3YR CAGR)" labelWidth="250px">
          {tabData.previousGrowth.value ? `${tabData.previousGrowth.value}%` : "—"}
        </CitationWrapper>
        <CitationWrapper value={tabData.predictedGrowth} label="PREDICTED GROWTH (3YR CAGR)" labelWidth="250px">
          {tabData.predictedGrowth.value ? `${tabData.predictedGrowth.value}%` : "—"}
        </CitationWrapper>
        <CitationWrapper value={tabData.commentary} label="COMMENTARY" verticalLabel />
      </x.div>
    );
  }

  return null;
};

export default memo(SizeAndGrowthTabContent);
