import { EmphasisTypography } from "views/DeliverablesView/transcripts/EmphasisTypography";
import { Loading, Skeleton, Typography } from "@alphasights/alphadesign-components";
import {
  LoadingMessageContainer,
  LoadingSkeletonContainer,
  ProcessingQuestionContainer,
  StyledIcon,
} from "./ProcessingQuestion.styled";
import { PROCESSING_QUESTION_MESSAGE } from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentQuestionsTab/consts";
import { DocumentQuestion } from "views/DeliverablesView/ThirdPartyDocumentPage/DocumentQuestionsTab/types";

const DataTestIds = {
  ProccessingQuestion: "processing-question",
};

const ProcessingQuestion = ({ question }: { question: DocumentQuestion }) => {
  return (
    <ProcessingQuestionContainer data-testid={DataTestIds.ProccessingQuestion}>
      <EmphasisTypography component="span" variant="body-em">
        {question.question}
      </EmphasisTypography>
      <LoadingMessageContainer>
        <StyledIcon>
          <Loading />
        </StyledIcon>
        <Typography>{PROCESSING_QUESTION_MESSAGE}</Typography>
      </LoadingMessageContainer>
      <LoadingSkeletonContainer>
        <Skeleton variant="noMargin" width="70%" />
        <Skeleton variant="noMargin" />
        <Skeleton variant="noMargin" />
      </LoadingSkeletonContainer>
    </ProcessingQuestionContainer>
  );
};

export { ProcessingQuestion as default, DataTestIds };
