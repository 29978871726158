import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { x } from "@xstyled/styled-components";
import { Experience } from "../../../models/Interaction";

const roleColumnPrefix = "role-column-";

const LABELS = {
  kdm: "KDM",
  evaluation_team: "Evaluation Team",
  user: "User",
};

export const RoleColumn = ({ row }: AlphaTableTypes.Cell<any, any>) => {
  const interaction: Interaction = row.original;

  const knowledges = interaction.customerKnowledges;

  const { role } = knowledges.find((it) => it.rank === 1 && it.experience === Experience.Uses) || knowledges[0] || {};

  if (knowledges.length === 0) {
    return null;
  }

  return (
    <x.div data-testid={`${roleColumnPrefix}${interaction.id}`}>
      {/* @ts-ignore */}
      {LABELS[role] || role}
    </x.div>
  );
};
