import { ReactNode } from "react";
import { Divider } from "@alphasights/alphadesign-components";
import {
  HeaderContainer,
  MainBodyContainer,
  ResearchContainerBackground,
  ResearchContentContainer,
  ResearchMobileContentContainer,
  StyledHeader,
  StyledSecondaryLabel,
} from "./ResearchContainer.styled";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

const ResearchContainer = ({
  children,
  header,
  secondaryLabel,
}: {
  children: ReactNode;

  header?: string;
  secondaryLabel?: string;
}) => {
  const { isMobile } = useCheckScreen();

  if (isMobile) return <ResearchMobileContentContainer>{children}</ResearchMobileContentContainer>;

  return (
    <ResearchContainerBackground>
      <ResearchContentContainer>
        {header && (
          <>
            <HeaderContainer>
              <StyledHeader variant="body-large-em">{header}</StyledHeader>
              <StyledSecondaryLabel>{secondaryLabel}</StyledSecondaryLabel>
            </HeaderContainer>
            <Divider w="100%" />
          </>
        )}
        <MainBodyContainer>{children}</MainBodyContainer>
      </ResearchContentContainer>
    </ResearchContainerBackground>
  );
};

export default ResearchContainer;
