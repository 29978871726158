import { useMemo, useState } from "react";
import * as React from "react";
import { x } from "@xstyled/styled-components";
import { Alert, Avatar, Button, IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  InboxResponse,
  ThreadResponse,
  MessageResponse,
  ParticipantRole,
  AttachmentResponse,
  ResponseStatusType,
  ComplianceReviewStatus,
} from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { useMobileMessageCardStyles } from "../MobileMessageList/MobileMessageList.styles";
import { isSameDay } from "date-fns";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { Attachment, Reply } from "@alphasights/alphadesign-icons";
import { useMobileQuestionContextStyles } from "./MobileQuestionContext.styles";
import { DownloadAttachmentsDrawer } from "../DownloadAttachmentsDrawer";
import { statusVariantMap } from "pages/MessengerPage/components/MessengerHeader/MessengerHeader";
import { useMessengerStatusPillStyles } from "pages/MessengerPage/components/MessengerStatusPill/MessengerStatusPill.styles";
import { getRequestTypeName, sanitizeMessage } from "pages/MessengerPage/utils";
import { useComplianceReviewContent } from "hooks/useComplianceReviewContent";
import { MessengerSuggestion } from "components/MessengerSuggestion/MessengerSuggestion";
import { MessengerComplianceReviewApprovalStatus } from "components/MessengerComplianceReviewApprovalStatus/MessengerComplianceReviewApprovalStatus";
import { MarkdownDisplay } from "@alphasights/alphadesign-rte";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { ProjectFeature } from "@alphasights/portal-api-client";

export const MobileQuestionContext = ({
  inbox,
  setSelectedThreadId,
  setReplyingTo,
}: {
  inbox: InboxResponse;
  projectToken: string;
  setSelectedThreadId: (threadId: string | undefined) => void;
  setReplyingTo: (value: boolean) => void;
}) => {
  const styles = useMobileMessageCardStyles();
  const firstMessage = inbox?.threads[0]?.messages[0];

  const isFirstMessageFromToday = useMemo(() => isSameDay(new Date(), new Date(firstMessage.sentAt)), [
    firstMessage.sentAt,
  ]);

  const allThreads = useMemo(() => {
    const threadsWithReplies = inbox?.threads.filter((thread) => thread.messages.length > 1);
    const threadsWithoutReplies = inbox?.threads.filter((thread) => thread.messages.length === 1);
    return [...threadsWithReplies, ...threadsWithoutReplies];
  }, [inbox]);

  return (
    <x.div {...styles.messageQuestionContextWrapper}>
      <QuestionCard
        message={firstMessage}
        isMessageFromToday={isFirstMessageFromToday}
        messageTypeName={getRequestTypeName(inbox?.threads[0].requestType)}
      />
      {allThreads.map((thread, index) => (
        <MobileMessengerAdvisorCard
          key={thread.id}
          thread={thread}
          isFirstThread={inbox?.threads.length === 1}
          setSelectedThreadId={setSelectedThreadId}
          setReplyingTo={setReplyingTo}
        />
      ))}
    </x.div>
  );
};

export const QuestionCard = ({
  message,
  messageTypeName,
  isMessageFromToday,
}: {
  message: MessageResponse;
  messageTypeName: string;
  isMessageFromToday: boolean;
}) => {
  const {
    onAcceptSuggestion: originalOnAcceptSuggestion,
    onDeclineSuggestion: originalOnDeclineSuggestion,
  } = useMessengerContext();

  const { originalMessage, suggestionMessage, onAcceptSuggestion, onDeclineSuggestion } = useComplianceReviewContent({
    message,
    onAcceptSuggestion: originalOnAcceptSuggestion,
    onDeclineSuggestion: originalOnDeclineSuggestion,
  });

  const { sanitizedContent } = useMemo(() => sanitizeMessage(originalMessage), [originalMessage]);

  const styles = useMobileQuestionContextStyles();

  return (
    <x.div {...styles.questionWrapper} data-testid="question-card">
      <x.div>
        <Typography variant="body-em" display="inline">
          Question <MessengerComplianceReviewApprovalStatus status={message.complianceReviewStatus} />
        </Typography>
        <Typography variant="body-small" color="secondary" float="right" display="inline">
          {isMessageFromToday && "Today "}
          <FormattedDateTime date={message.sentAt} format={isMessageFromToday ? "HH:mm" : "d LLL yyyy, HH:mm"} />
        </Typography>
      </x.div>
      <MarkdownDisplay markdown={sanitizedContent} customStyles={styles.messageMarkdownStyle} />
      {message.attachments.length > 0 && <MessageDownloadAttachment attachments={message.attachments} />}

      {message.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_PENDING && suggestionMessage && (
        <MessengerSuggestion
          content={suggestionMessage.sanitizedContent}
          onAcceptSuggestion={onAcceptSuggestion}
          onDeclineSuggestion={onDeclineSuggestion}
        />
      )}

      {message.complianceReviewStatus === ComplianceReviewStatus.SUGGESTION_DECLINED && (
        <Alert size="small" width="100%">
          Suggested edits were declined. The {messageTypeName} has not been sent to expert(s)
        </Alert>
      )}
    </x.div>
  );
};

export const MobileMessengerAdvisorCard = ({
  thread,
  setSelectedThreadId,
  setReplyingTo,
  shouldShowReplies,
}: {
  thread: ThreadResponse;
  isFirstThread: boolean;
  setReplyingTo: (value: boolean) => void;
  setSelectedThreadId?: (threadId: string | undefined) => void;
  shouldShowReplies?: boolean;
}) => {
  const styles = useMobileQuestionContextStyles();
  const { experts } = useMessengerContext();
  const advisor = useMemo(() => experts.find((e) => e.id === thread.advisor.id), [experts, thread.advisor.id]);
  const replies = thread.messages.slice(2);

  return (
    <x.div {...styles.advisorCardWrapper}>
      {thread.messages.length > 1 ? (
        <>
          <MessengerMessage
            message={thread.messages[1]}
            isLastMessage={replies.length === 0}
            onSeeRepliesClick={shouldShowReplies ? undefined : () => setSelectedThreadId?.(thread.id)}
            onReplyClick={() => {
              if (!shouldShowReplies) setSelectedThreadId?.(thread.id);

              setReplyingTo(true);
            }}
            repliesLength={replies.length}
          />

          {shouldShowReplies && (
            <>
              {replies.map((message, index) => (
                <MessengerMessage
                  key={message.id}
                  message={message}
                  isLastMessage={index === replies.length - 1}
                  onSeeRepliesClick={shouldShowReplies ? undefined : () => setSelectedThreadId?.(thread.id)}
                  onReplyClick={() => {
                    if (!shouldShowReplies) setSelectedThreadId?.(thread.id);

                    setReplyingTo(true);
                  }}
                />
              ))}
            </>
          )}

          {replies.length > 0 && shouldShowReplies && (
            <x.div {...styles.hideRepliesWrapper}>
              <Button onClick={() => setSelectedThreadId?.(undefined)} size="small" variant="ghost">
                Hide {replies.length > 1 ? "replies" : "reply"}
              </Button>
            </x.div>
          )}
        </>
      ) : (
        <x.div {...styles.headerOnlyMessageWrapper}>
          <MessengerMessageHeader
            name={advisor?.name ?? ""}
            relevantCompany={advisor?.relevantCompany}
            relevantPosition={advisor?.relevantPosition}
            role={thread.messages[0].sender.role}
            sentAt={thread.messages[0].sentAt}
            hasReplies={false}
            status={thread.state.description}
            complianceReviewStatus={thread.messages[0].complianceReviewStatus}
          />
        </x.div>
      )}
    </x.div>
  );
};

const MessengerMessageHeader = ({
  name,
  role,
  relevantCompany,
  relevantPosition,
  sentAt,
  hasReplies = true,
  status,
  complianceReviewStatus,
}: {
  name: string;
  role: ParticipantRole;
  sentAt: string;
  relevantCompany?: string;
  relevantPosition?: string;
  hasReplies?: boolean;
  status?: ResponseStatusType;
  complianceReviewStatus?: ComplianceReviewStatus;
}) => {
  const styles = useMobileQuestionContextStyles();
  const { spacing } = useThemeTokens();
  const isCurrentMessageFromToday = useMemo(() => isSameDay(new Date(), new Date(sentAt)), [sentAt]);
  const avatarColor = !hasReplies ? "base02" : role === ParticipantRole.Advisor ? "base05" : "base03";

  const { variantStyle } = useMessengerStatusPillStyles({ variant: statusVariantMap[status as ResponseStatusType] });

  const hideStatus = useMemo(
    () =>
      complianceReviewStatus !== undefined &&
      [
        ComplianceReviewStatus.PENDING,
        ComplianceReviewStatus.REJECTED,
        ComplianceReviewStatus.REJECTED_WITH_SUGGESTION,
        ComplianceReviewStatus.SUGGESTION_DECLINED,
        ComplianceReviewStatus.SUGGESTION_PENDING,
      ].includes(complianceReviewStatus),
    [complianceReviewStatus]
  );

  return (
    <x.div display="flex" justifyContent="space-between" gap={spacing.inner.base02}>
      <x.div display="flex" gap={spacing.inner.base02}>
        <Avatar text={name} color={avatarColor} flexShrink="0" />
        <x.div display="flex" flexDirection="column">
          <x.div display="flex">
            <Typography variant="body-small-em" component="span" style={styles.companyAndRole}>
              {name}
            </Typography>

            {status !== undefined && !hideStatus && (
              <Typography variant="body-small-em" component="span" flexShrink={0}>
                &nbsp;•&nbsp;
                <Typography variant="body-small" component="span" color={variantStyle.styles.iconColor as any}>
                  {status}
                </Typography>
              </Typography>
            )}
          </x.div>
          <Typography variant="body-small" color="secondary" component="span" style={styles.companyAndRole}>
            {relevantCompany} - {relevantPosition}
          </Typography>
        </x.div>
      </x.div>

      <x.div>
        <Typography variant="body-small" color="secondary" float="right">
          {isCurrentMessageFromToday && "Today "}
          <FormattedDateTime date={sentAt} format={isCurrentMessageFromToday ? "HH:mm" : "d LLL yyyy, HH:mm"} />
        </Typography>
      </x.div>
    </x.div>
  );
};

const MessengerMessage = ({
  message,
  isLastMessage,
  onSeeRepliesClick,
  onReplyClick,
  repliesLength = 0,
}: {
  message: MessageResponse;
  isLastMessage: boolean;
  onSeeRepliesClick?: () => void;
  onReplyClick?: () => void;
  repliesLength?: number;
}) => {
  const { experts } = useMessengerContext();
  const isAdvisor = message.sender.role === ParticipantRole.Advisor;
  const { isFeatureDisabled } = useCurrentProjectContext();
  const isSendMessagesDisabled = isFeatureDisabled(ProjectFeature.SendMessages);

  const [seeMore, setSeeMore] = useState(false);

  const { content, sanitizedContent, isContentSanitized } = useMemo(() => sanitizeMessage(message), [message]);

  const styles = useMobileQuestionContextStyles();

  const expert = useMemo(() => experts.find((e) => e.id === message.sender.id), [experts, message.sender.id]);

  const hasReplyButton = !message.obfuscated && onReplyClick && isLastMessage && isAdvisor;
  const hasViewRepliesButton = repliesLength > 0 && onSeeRepliesClick;
  const hasSeeMoreButton = isContentSanitized;

  const actionsJustifyContent = useMemo(() => {
    if ((hasReplyButton || hasViewRepliesButton) && hasSeeMoreButton) return "space-between";

    if (hasReplyButton || hasViewRepliesButton) return "flex-end";

    return "flex-start";
  }, [hasReplyButton, hasSeeMoreButton, hasViewRepliesButton]);

  return (
    <x.div data-testid="thread-message" {...styles.messageWrapper}>
      <MessengerMessageHeader
        name={message.sender.name ?? ""}
        relevantCompany={expert?.relevantCompany}
        relevantPosition={expert?.relevantPosition}
        role={message.sender.role}
        sentAt={message.sentAt}
        complianceReviewStatus={message.complianceReviewStatus}
      />
      <x.div
        style={{
          filter: message.obfuscated ? "blur(2.5px)" : "none",
          userSelect: message.obfuscated ? "none" : "auto",
        }}
      >
        <MarkdownDisplay
          dataTestid={`message-content-${message.id}`}
          markdown={seeMore ? content : sanitizedContent}
          customStyles={styles.messageMarkdownStyle}
        />
      </x.div>

      {message.attachments.length > 0 && <MessageDownloadAttachment attachments={message.attachments} />}

      {(hasReplyButton || hasSeeMoreButton || hasViewRepliesButton) && (
        <x.div data-testid="reply-message-data" display="flex" justifyContent={actionsJustifyContent}>
          {hasSeeMoreButton && (
            <x.div data-testid="reply-message-button">
              <Button variant="ghost" size="small" onClick={() => setSeeMore(!seeMore)} h="16px">
                {seeMore ? "See less" : "See More"}
              </Button>
            </x.div>
          )}

          {hasViewRepliesButton && (
            <x.div {...styles.showRepliesWrapper}>
              <Button onClick={onSeeRepliesClick} size="small" variant="ghost">
                Show {repliesLength > 1 ? "replies" : "reply"}
              </Button>
            </x.div>
          )}

          {hasReplyButton && !isSendMessagesDisabled && (
            <x.div data-testid="reply-message-button">
              <Button variant="ghost" size="small" onClick={onReplyClick} h="16px" startIcon={<Reply />}>
                Reply
              </Button>
            </x.div>
          )}
        </x.div>
      )}
    </x.div>
  );
};

const MessageDownloadAttachment = ({ attachments }: { attachments: AttachmentResponse[] }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <>
      {attachments.length > 0 && (
        <x.div display="flex" gap="8px">
          <IconButton
            size="small"
            color="secondary"
            variant="basic"
            onClick={() => setIsDrawerOpen(true)}
            testId="download-attachments-btn"
          >
            <Attachment />
          </IconButton>
          <Typography variant="body-small-em">{attachments.length}</Typography>
        </x.div>
      )}
      <DownloadAttachmentsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        attachments={attachments}
      />
    </>
  );
};
