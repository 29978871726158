import { apiClient, interactions as interactionsApi, RelevantCompany } from "@alphasights/portal-api-client";
import { compact, uniqBy } from "lodash";

export const InteractionsService = {
  getEstimatedPrice(projectToken: string, interactionId: string) {
    return apiClient.get<EstimatedPrice>(`/api/projects/${projectToken}/interactions/${interactionId}/estimated-price`);
  },
  getAccessPlan(projectToken: string, interactionId: string) {
    return apiClient.get<AccessPlan>(`/api/projects/${projectToken}/interactions/${interactionId}/access-plan`);
  },
  getRelevantCompanies(projectToken: string, interactionIds: string[]) {
    return interactionsApi.getRelevantCompanies(projectToken, interactionIds);
  },
  async getUniqueRelevantCompanies(projectToken: string, interactionIds: string[]) {
    const response = await this.getRelevantCompanies(projectToken, interactionIds);
    return uniqBy(
      compact<AlphaCompany>(response.flatMap((i: RelevantCompany) => [i.primaryCompany, i.secondaryCompany])),
      "id"
    );
  },
};
